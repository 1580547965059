import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Cell, Button, FontIcon } from 'react-md';
import moment from 'moment';
import _ from 'lodash';

import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';
import InfiniteScrollContainer from 'components/Common/InfiniteScrollContainer';

const getDateFormatted = (utcDate) => {
    const fDate = moment.utc(utcDate).local();
    return `${fDate.format('hh:mm A')} on ${fDate.format('MM/DD/YYYY')}`;
};

const CHANNEL_MAP = {
    website: 'Website',
    mobile: 'Mobile',
    ivr2: 'IVR',
};

export const RefillTable = ({
    refillRequests,
    sortColumn,
    sorting,
    loading,
    selectRefill,
    selectedRefill,
    onLoadMore,
}) => {
    // All of this to help align spacing of header cols and content cols when content has a scrollbar
    const headerContainer = useRef(null);
    const contentContainer = useRef(null);
    const rowRefs = useRef([]);
    const headerClientWidth = _.get(headerContainer, 'current.clientWidth', 0);
    const contentClientWidth = _.get(contentContainer, 'current.clientWidth', 0);
    const tableScrollbarBuffer = headerClientWidth - contentClientWidth;
    const [activeRowIndex, setActiveRowIndex] = useState(0);

    const handleKeyDown = useCallback(
        (event) => {
            const { key } = event;
            const isNavigating = Boolean(selectedRefill);

            if (!isNavigating) return;

            const maximumRowIndex = refillRequests && refillRequests.length ? refillRequests.length - 1 : 0;
            let nextActiveIndex = activeRowIndex;

            switch (key) {
                case 'ArrowUp':
                    nextActiveIndex = Math.max(0, activeRowIndex - 1);
                    break;
                case 'ArrowDown':
                    nextActiveIndex = Math.min(maximumRowIndex, activeRowIndex + 1);
                    break;
                case 'PageUp':
                    nextActiveIndex = Math.max(0, activeRowIndex - 10);
                    break;
                case 'PageDown':
                    nextActiveIndex = Math.min(maximumRowIndex, activeRowIndex + 10);
                    break;
                default:
                    break;
            }

            if (nextActiveIndex !== activeRowIndex) {
                event.preventDefault();
            }

            const rowRef = rowRefs.current[nextActiveIndex];
            if (rowRef)
                rowRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

            setActiveRowIndex(nextActiveIndex);
            selectRefill(refillRequests[nextActiveIndex]);
        },
        [refillRequests, activeRowIndex, selectedRefill]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleRowClick = (refill, index) => {
        setActiveRowIndex(index);
        selectRefill(refill);
    };

    return (
        <div className="grid-container refill-table">
            <div className="refills-table-header" ref={headerContainer}>
                <Cell
                    size={2}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title refills-title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'rx_num' })}
                >
                    <FontIcon>
                        {sorting['rx_num'] ? (sorting['rx_num'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up') : ''}
                    </FontIcon>
                    Prescription
                </Cell>
                <Cell
                    size={2}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'patient_name' })}
                >
                    Patient Name
                    <FontIcon>
                        {sorting['patient_name']
                            ? sorting['patient_name'] === 'desc'
                                ? 'arrow_drop_down'
                                : 'arrow_drop_up'
                            : ''}
                    </FontIcon>
                </Cell>
                <Cell
                    size={2}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'source_type' })}
                >
                    Channel
                    <FontIcon>
                        {sorting['source_type']
                            ? sorting['source_type'] === 'desc'
                                ? 'arrow_drop_down'
                                : 'arrow_drop_up'
                            : ''}
                    </FontIcon>
                </Cell>
                <Cell
                    size={2}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'delivery_method' })}
                >
                    Delivery Method
                    <FontIcon>
                        {sorting['delivery_method']
                            ? sorting['delivery_method'] === 'desc'
                                ? 'arrow_drop_down'
                                : 'arrow_drop_up'
                            : ''}
                    </FontIcon>
                </Cell>
                <Cell
                    size={2}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'created_date' })}
                >
                    Date Submitted
                    <FontIcon>
                        {sorting['created_date']
                            ? sorting['created_date'] === 'desc'
                                ? 'arrow_drop_down'
                                : 'arrow_drop_up'
                            : ''}
                    </FontIcon>
                </Cell>
                <Cell
                    size={1}
                    phoneHidden
                    tabletHidden
                    className="flex-middle title status-title user-clickable"
                    onClick={sortColumn.bind(this, { column: 'status' })}
                >
                    Status
                    <FontIcon>
                        {sorting['status'] ? (sorting['status'] === 'desc' ? 'arrow_drop_down' : 'arrow_drop_up') : ''}
                    </FontIcon>
                </Cell>
                <Cell size={1} phoneHidden tabletHidden className="flex-middle title"></Cell>
                <span
                    className="scrollbar-buffer"
                    style={{
                        width: `${tableScrollbarBuffer}px`,
                    }}
                />
            </div>

            <div id="scrollableRefillBody" className="body" ref={contentContainer}>
                <InfiniteScrollContainer ref={contentContainer} offset={10} onScrollBottom={onLoadMore}>
                    {loading ? (
                        <TabularTypePlaceholder legendsCount={7} />
                    ) : (
                        refillRequests.map((r, i) => {
                            const backgroundClass = i % 2 === 0 ? 'even-background' : '';
                            const isSelected =
                                selectedRefill &&
                                selectedRefill.submitted_refill_medication_id === r.submitted_refill_medication_id;
                            const selectedClass = isSelected ? 'selected' : '';
                            return (
                                <div
                                    key={i}
                                    className={`grid-container refill-row ${backgroundClass} ${selectedClass}`}
                                    onClick={() => handleRowClick(r, i)}
                                    ref={(el) => (rowRefs.current[i] = el)}
                                >
                                    <Cell size={2} phoneSize={2} tabletSize={4} className="flex-middle">
                                        <span className="rx-number user-clickable overflow-elipsis">
                                            {r.rx_num || '-'}
                                        </span>
                                    </Cell>
                                    <Cell size={2} phoneSize={2} tabletSize={4} className="flex-middle">
                                        {r.patient_info.first_name && r.patient_info.last_name ? (
                                            <div className="overflow-elipsis">
                                                <span className="patient-name user-clickable">
                                                    {r.patient_info.first_name} {r.patient_info.last_name}
                                                </span>
                                            </div>
                                        ) : (
                                            <span> - </span>
                                        )}
                                    </Cell>
                                    <Cell size={2} phoneSize={2} tabletSize={4} className="flex-middle">
                                        {CHANNEL_MAP[r.source_type]}
                                    </Cell>
                                    <Cell
                                        size={2}
                                        phoneSize={2}
                                        tabletSize={4}
                                        className={`flex-middle md-text-capitalize`}
                                    >
                                        {r.delivery_method || '-'}
                                    </Cell>
                                    <Cell size={2} phoneSize={2} tabletSize={4} className="flex-middle">
                                        {getDateFormatted(r.created_date)}
                                    </Cell>
                                    <Cell
                                        size={1}
                                        phoneSize={2}
                                        tabletSize={4}
                                        className={`flex-middle status status-${r.statusCodeEnum}`}
                                    >
                                        {r.status || '-'}
                                    </Cell>
                                    <Cell size={1} phoneSize={2} tabletSize={4} className="btn-col">
                                        {r.comment !== null && r.comment !== '' && (
                                            <Button icon data-comment-icon>
                                                assignment
                                            </Button>
                                        )}
                                    </Cell>
                                </div>
                            );
                        })
                    )}
                </InfiniteScrollContainer>
            </div>
        </div>
    );
};

export default RefillTable;
