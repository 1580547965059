import React, { Fragment } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

export default ({ resource, onClick }) => (
    <Fragment>
        <a href={resource.link} target="_blank" rel="noopener noreferrer" className="appBar__resourcesPopdownLink">
            <MenuItem dense className="appBar__resourcesPopdownMenu" onClick={onClick}>
                <div className="appBar__resourcesPopdownLink--Text">{resource.title}</div>
            </MenuItem>
        </a>
    </Fragment>
);
