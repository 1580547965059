export const inboxFilterOptions = {
    has_message: {
        filter: 'has_message',
        text: 'Any messages',
        icon: 'message',
    },
    has_no_message: {
        filter: 'has_no_message',
        text: 'No messages',
        icon: 'speaker_notes_off',
    },
    unread_patient: {
        filter: 'unread_patient',
        text: 'Messages unread by pharmacy',
        icon: 'local_pharmacy',
    },
    unread_pharmacist: {
        filter: 'unread_pharmacist',
        text: 'Messages unread by patient',
        icon: 'person',
    },
};

export const inboxFilterList = ['has_message', 'has_no_message', 'unread_patient', 'unread_pharmacist'];

export const searchType = {
    name: 'name',
    dateOfBirth: 'date_of_birth',
};
