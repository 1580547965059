const subjectTypePrefix = 'Patients';

export const restrictedPatientsResources = {
    patients: {
        subject: subjectTypePrefix,
        general: {
            subject: `${subjectTypePrefix} - Search`,
            fields: {},
        },
        bulkMessages: {
            subject: `${subjectTypePrefix} - Bulk Messages`,
            fields: {},
        },
    },
};
