import { get, put } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import Actions from 'redux/actions';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../utils/log';

export const fetchVoicemails = (queryParams) => (dispatch) => {
    const apiPathVoicemail = API_PATH.VOICE_MAILS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.msg_url + apiPathVoicemail;
    const header = { params: queryParams };
    const fetchVoicemailsReq = get(apiUrl, header);

    return fetchVoicemailsReq
        .then((data) => {
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchVoicemailsReq', err);
            return { err };
        });
};

export const fetchVoicemailDetail = (queryParams) => (dispatch) => {
    const apiPathVoicemail = API_PATH.VOICE_MAIL_DETAILS.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{vmID}', queryParams.vmId);
    const apiUrl = Config.msg_url + apiPathVoicemail;
    dispatch({ type: Actions.FETCH_VOICEMAIL_DETAIL_STARTED });

    const fetchVoicemailDetailReq = get(apiUrl);
    return fetchVoicemailDetailReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_VOICEMAIL_DETAIL_SUCCESS, payload: { type: queryParams.type, data } });
            return { data };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_VOICEMAIL_DETAIL_FAILED });
            logHttpError('An error occured on fetchVoicemailDetail', err);
            return { err };
        });
};

export const fetchReadVoicemails = (queryParams) => (dispatch) => {
    const loadMore = queryParams.loadMore ? true : false;

    dispatch({ type: Actions.FETCH_READ_VOICEMAIL_STARTED, payload: { loadMore } });
    dispatch(fetchVoicemails(queryParams)).then((resp) => {
        if (resp.data) {
            dispatch({ type: Actions.FETCH_READ_VOICEMAIL_SUCCESS, payload: { data: resp.data, loadMore } });
        } else {
            dispatch({ type: Actions.FETCH_READ_VOICEMAIL_FAILED, payload: { loadMore } });
        }
    });
};

export const fetchUnreadVoicemails = (queryParams) => (dispatch, getState) => {
    const loadMore = queryParams.loadMore ? true : false;

    dispatch({ type: Actions.FETCH_UNREAD_VOICEMAIL_STARTED, payload: { loadMore } });
    return dispatch(fetchVoicemails(queryParams)).then((resp) => {
        if ('data' in resp) {
            dispatch({ type: Actions.FETCH_UNREAD_VOICEMAIL_SUCCESS, payload: { data: resp.data, loadMore } });
            return { data: resp.data };
        } else {
            dispatch({ type: Actions.FETCH_UNREAD_VOICEMAIL_FAILED, payload: { loadMore } });
            return { err: resp.err };
        }
    });
};

export const fetchVoicemailAudio = (details) => (dispatch) => {
    const apiPathVoicemailAudio = API_PATH.VOICE_MAIL_AUDIO.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{vmID}', details.vmId);
    const apiUrl = Config.msg_url + apiPathVoicemailAudio;
    const headers = { responseType: 'blob' };
    dispatch({ type: Actions.FETCH_VOICEMAIL_AUDIO_STARTED, payload: { vmId: details.vmId, type: details.type } });
    const fetchVoicemailAudioReq = get(apiUrl, headers);

    return fetchVoicemailAudioReq
        .then((data) => {
            dispatch({
                type: Actions.FETCH_VOICEMAIL_AUDIO_SUCCESS,
                payload: { data, vmId: details.vmId, type: details.type },
            });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchVoicemailAudioReq', err);
            dispatch({
                type: Actions.FETCH_VOICEMAIL_AUDIO_FAILED,
                payload: { vmId: details.vmId, type: details.type },
            });
            return { err };
        });
};

export const markRead = (data) => (dispatch) => {
    const apiPathVoicemail = API_PATH.VOICE_MAILS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.msg_url + apiPathVoicemail + '/' + data.vmId;
    const msgTypePrefix = data.type === 'read' ? 'UNREAD' : 'READ';

    dispatch({ type: Actions[`${msgTypePrefix}_VOICEMAIL_STARTED`], payload: data });
    const readVoicemailAudioReq = put(apiUrl, { isRead: data.type === 'read' ? 0 : 1 });

    return readVoicemailAudioReq
        .then((resp) => {
            dispatch(displayToast({ text: `Voicemail marked as ${msgTypePrefix.toLowerCase()}`, type: 'success' }));
            dispatch({ type: Actions[`${msgTypePrefix}_VOICEMAIL_SUCCESS`], payload: data });
            return { data: resp };
        })
        .catch((err) => {
            logHttpError('An error occured on readVoicemailAudioReq', err);
            dispatch(
                displayToast({ text: `Failed to mark voicemail as ${msgTypePrefix.toLowerCase()}`, type: 'error' })
            );
            dispatch({ type: Actions[`${msgTypePrefix}_VOICEMAIL_FAILED`], payload: data });
            return { err };
        });
};

export const sortVoicemail = (data) => ({
    type: Actions.SORT_VOICEMAIL,
    payload: data,
});
