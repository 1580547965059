import { waitlistTypes } from './type';

export const initialState = {
    isLoading: false,
    downloadingWaitlistStatusById: {},
    removingFromWaitlists: null,
    updatingWaitlistStatus: null,
    patient_count: 0,
};

export const waitlistReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case waitlistTypes.FETCH_WAITLISTS:
            return {
                ...state,
                isLoading: true,
            };
        case waitlistTypes.FETCH_WAITLISTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                patient_count: payload.patient_count,
            };
        case waitlistTypes.FETCH_WAITLISTS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case waitlistTypes.DOWNLOAD_WAITLIST_CSV:
            return {
                ...state,
                downloadingWaitlistStatusById: {
                    ...state.downloadingWaitlist,
                    [payload.waitlistName]: true,
                },
            };
        case waitlistTypes.DOWNLOAD_WAITLIST_CSV_SUCCESS:
            return {
                ...state,
                downloadingWaitlistStatusById: {
                    ...state.downloadingWaitlistStatusById,
                    [payload.waitlistName]: false,
                },
            };
        case waitlistTypes.DOWNLOAD_WAITLIST_CSV_FAILED:
            return {
                ...state,
                downloadingWaitlistStatusById: {
                    ...state.downloadingWaitlistStatusById,
                    [payload.waitlistName]: false,
                },
            };

        case waitlistTypes.REMOVE_FROM_WAITLIST_REQUEST:
            return {
                ...state,
                removingFromWaitlists: { loading: true, ...payload },
            };

        case waitlistTypes.REMOVE_FROM_WAITLIST_SUCCESS:
            return {
                ...state,
                removingFromWaitlists: { loading: false, ...payload },
            };

        case waitlistTypes.REMOVE_FROM_WAITLIST_FAILURE:
            return {
                ...state,
                removingFromWaitlists: { loading: false, ...payload },
            };

        case waitlistTypes.UPDATE_WAITLIST_STATUS_REQUEST:
            return {
                ...state,
                updatingWaitlistStatus: { loading: true, ...payload },
            };

        case waitlistTypes.UPDATE_WAITLIST_STATUS_SUCCESS:
            return {
                ...state,
                updatingWaitlistStatus: { loading: false, ...payload },
            };

        case waitlistTypes.UPDATE_WAITLIST_STATUS_FAILURE:
            return {
                ...state,
                updatingWaitlistStatus: { loading: false, ...payload },
            };

        default:
            return state;
    }
};

export default waitlistReducer;
