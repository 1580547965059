import queryString from 'query-string';
import _ from 'lodash';

export function updateQueryParameters(location, parameter, value) {
    const queryParams = queryString.parse(location.search);

    queryParams[parameter] = value;

    const sorted = _.filter(
        _.map(_.sortBy(_.keys(queryParams)), (p) => {
            if (p !== parameter) {
                return `${p}=${encodeURIComponent(queryParams[p])}`;
            } else if (_.isNil(value)) {
                return null;
            } else {
                return `${p}=${encodeURIComponent(value)}`;
            }
        })
    );

    return _.isEmpty(sorted) ? '' : `?${sorted.join('&')}`;
}
