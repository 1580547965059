import React from 'react';
import Button from '@material-ui/core/Button';
import { newsletterActions } from 'redux/Newsletter/action';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const CardDelete = (props) => {
    const { cardData, handleCardDeleteReset, deletingCard } = props;

    const handleCommitDelete = () => {
        props.deleteCard(cardData.card_id);
    };

    return (
        <div className="confirmation">
            <div className="message">Delete card "{cardData.card_title}?"</div>
            <div className="message" style={{ fontWeight: 'normal' }}>
                Will be removed from any future sends
            </div>
            <div style={{ display: 'flex' }}>
                <Button onClick={handleCardDeleteReset} className="button">
                    Cancel
                </Button>
                <div className="position-relative">
                    <Button variant="contained" color="secondary" onClick={handleCommitDelete} disabled={deletingCard}>
                        Delete
                    </Button>
                    {deletingCard && <CircularProgress size={24} className="circular-icon-within-button" />}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.newsletter,
    deletingCard: state.newsletter.deletingCard,
});

const mapDispatchToProps = {
    deleteCard: newsletterActions.deleteCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDelete);
