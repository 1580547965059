import Config from '../config';

export const logHttpError = (internalErrMsg, err) => {
    const httpErrObj = {
        message: internalErrMsg,
        errMsg: err.message,
        stack: err.stack,
        respUrl: err.request ? err.request.responseURL : '',
        status: err.request ? err.request.status : '',
        pharmacyId: Config.X_PharmacyID || '',
        locationId: Config.X_LocationID || '',
    };

    console.error(httpErrObj);
};

export const logError = (internalErrMsg, err) => {
    const errObj = {
        message: internalErrMsg,
        errMsg: err.message,
        stack: err.stack,
        pharmacyId: Config.X_PharmacyID || '',
        locationId: Config.X_LocationID || '',
    };

    console.error(errObj);
};

export const logUtil = {
    logHttpError,
    logError,
};
