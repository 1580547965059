import Actions from 'redux/actions';
import _ from 'lodash';

const addressByZipEmptyState = {
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    postCodeLocalities: [],
    notFound: false,
};

const initialState = {
    updatingPharmacyDetails: false,
    addressByZipLoading: false,
    addressByZip: _.cloneDeep(addressByZipEmptyState),
};

const contains = (arrayToCheck, needle, operation) => {
    const isPresent = _.some(arrayToCheck, (el) => {
        if (operation === 'EQUALS') {
            if (el === needle) {
                return true;
            }
        } else if (operation === 'LIKE') {
            if (el.indexOf(needle) !== -1) {
                return true;
            }
        }
    });
    return isPresent || false;
};

export default function PharmacyDetailsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED:
            return {
                ...state,
                updatingPharmacyDetails: true,
            };
        case Actions.GET_ADDRESS_BY_ZIP_STARTED:
            return {
                ...state,
                addressByZipLoading: true,
            };
        case Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS:
        case Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED:
            return {
                ...state,
                updatingPharmacyDetails: false,
            };
        case Actions.GET_ADDRESS_BY_ZIP_FAILED:
            return {
                ...state,
                addressByZipLoading: false,
            };
        case Actions.GET_ADDRESS_BY_ZIP_SUCCESS:
            const addressByZip = _.cloneDeep(addressByZipEmptyState);
            const { results } = payload;

            if (results.length === 0) {
                return {
                    ...state,
                    addressByZip: { ...addressByZip, notFound: true },
                    addressByZipLoading: false,
                };
            }

            const addrComponents = results[0].address_components;
            const geometry = results[0].geometry;
            var postCodeLocalities = results[0].postcode_localities;

            if (!_.isUndefined(addrComponents)) {
                _.forEach(addrComponents, (comp, i) => {
                    const types = _.cloneDeep(comp.types);
                    if (contains(types, 'locality', 'LIKE') || contains(types, 'sublocality', 'EQUALS')) {
                        addressByZip.city = addrComponents[i].long_name;
                    } else if (contains(types, 'administrative_area_level_1', 'EQUALS')) {
                        addressByZip.state = addrComponents[i].long_name;
                    } else if (contains(types, 'country', 'EQUALS')) {
                        addressByZip.country = addrComponents[i].short_name;
                    }
                });
            }

            if (!_.isUndefined(geometry)) {
                const { location } = geometry;
                addressByZip.latitude = location.lat;
                addressByZip.longitude = location.lng;
            }

            if (!_.isUndefined(postCodeLocalities)) {
                addressByZip.postCodeLocalities = postCodeLocalities;
            }

            return {
                ...state,
                addressByZip,
                addressByZipLoading: false,
            };
        case Actions.GET_LOCATION_IDENTIFIER_SUCCESS:
            const { pms_identifier} = payload;

            return {
                ...state,
                pms_identifier,
            };
        default:
            return state;
    }
}
