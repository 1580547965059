import React from 'react';
import { connect } from 'react-redux';

import CourseContent from '../CourseContent';
import changeSelectedCourseContent from 'redux/actionCreators/Classes';

const HighCholesterol = ({ courseTitle, courseContent, selectedCourseContent, changeSelectedCourseContent }) => (
    <CourseContent
        courseTitle={courseTitle}
        courseContent={courseContent}
        selectedCourseContent={selectedCourseContent}
        changeSelectedCourseContent={(selectedCourseContent) => {
            const payload = {
                healthCondition: 'highcholesterol',
                selectedCourseContent,
            };
            changeSelectedCourseContent(payload);
        }}
    />
);

const mapStateToProps = ({ classes: { highcholesterol } }) => ({
    courseTitle: highcholesterol.title,
    courseContent: highcholesterol.courseContent,
    selectedCourseContent: highcholesterol.selectedCourseContent,
});

const mapDispatchToProps = (dispatch) => ({
    changeSelectedCourseContent: (payload) => dispatch(changeSelectedCourseContent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighCholesterol);
