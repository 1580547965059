import './WaitlistSchedulingNavBar.sass';
import React from 'react';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import moment from 'moment';
import { VACCINES_AND_LABELS } from './VaccineDoseInfoContent';
import { getHowToScheduleConfigFromInboxConversationTemplate } from '../schedulingUtil';
import { adaptReservationSessionToTimeRange } from './ClinicalSchedulingContent';

export default function WaitlistSchedulingNavBar({
    focusedTab,
    timeRangeSelected,
    timeRange,
    availableSlots,
    doseInfo,
    chosenPatients,
    patientChooseMethod,
    orderingOption,
    history,
    template,
    reservationSession,
}) {
    const { vaccine_type, number_of_doses } = _.get(
        reservationSession,
        'metadata.covid_dose_info',
        _.isNil(doseInfo) ? {} : doseInfo
    );

    const { scheduling_type } = getHowToScheduleConfigFromInboxConversationTemplate(template);

    const bookedCount = _.reduce(_.values(_.get(reservationSession, 'reservation_counts')), (sum, c) => sum + c, 0);
    const availableCount = number_of_doses - bookedCount;

    const convertedTimeRange = adaptReservationSessionToTimeRange(reservationSession);

    return (
        <div className="waitlist-scheduling-nav-bar">
            {scheduling_type === '3rd-party' ? null : (
                <WaitlistSchedulingTab
                    focused={focusedTab === 'when'}
                    number={1}
                    stepLabel={'Choose appointment block'}
                    completed={!_.isNil(reservationSession)}
                    completedMainText={
                        <div className="appt-block-main-text">
                            <span className="date-range">
                                {formatTimeRange(adaptReservationSessionToTimeRange(reservationSession))}
                            </span>
                            {convertedTimeRange.from.date && convertedTimeRange.to.date && (
                                <span className="slot-count">
                                    {numeral(availableSlots).format('0,0')} available{' '}
                                    {pluralize('slot', availableSlots)}
                                </span>
                            )}
                        </div>
                    }
                    completedSubText={
                        _.isNil(reservationSession) ? null : (
                            <div className="appt-block-sub-text">
                                <span className="vaccine-type">{_.get(VACCINES_AND_LABELS, vaccine_type)}</span>
                                <span className="dose-counts">
                                    {numeral(availableCount).format('0,0')} remaining doses
                                </span>
                            </div>
                        )
                    }
                    canClick={true}
                    onClick={() => {
                        history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling`);
                    }}
                />
            )}

            {scheduling_type !== '3rd-party' ? null : (
                <WaitlistSchedulingTab
                    focused={focusedTab === 'vaccine-dose-info'}
                    number={1}
                    stepLabel={'Vaccine/Dose Info'}
                    completed={_.isString(vaccine_type) && _.isFinite(number_of_doses)}
                    completedMainText={`${numeral(number_of_doses).format('0,0')} available ${pluralize(
                        'dose',
                        number_of_doses
                    )}`}
                    completedSubText={`of ${VACCINES_AND_LABELS[vaccine_type]} vaccine`}
                    canClick={timeRangeSelected || scheduling_type === '3rd-party'}
                    onClick={() => {
                        history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling`);
                    }}
                />
            )}

            <WaitlistSchedulingTab
                focused={focusedTab === 'who'}
                number={scheduling_type === '3rd-party' ? 1 : 2}
                stepLabel={'Who to Contact'}
                completed={!_.isEmpty(chosenPatients)}
                completedMainText={`will contact ${numeral(_.size(chosenPatients)).format('0,0')} ${pluralize(
                    'patient',
                    _.size(chosenPatients)
                )}`}
                completedSubText={
                    patientChooseMethod === 'manual'
                        ? 'that were manually chosen'
                        : orderingOption === 'prioritize-known'
                        ? 'prioritizing known patients'
                        : 'in the order they signed up'
                }
                canClick={_.isString(vaccine_type) && _.isFinite(number_of_doses)}
                onClick={() => {
                    history.push(
                        `/workflows/${template.inbox_conversation_template_id}/scheduling/who${
                            patientChooseMethod === 'manual' ? '/manual' : ''
                        }`
                    );
                }}
            />
            <WaitlistSchedulingTab
                focused={focusedTab === 'review'}
                number={scheduling_type === '3rd-party' ? 2 : 3}
                stepLabel={'Confirm & Send'}
                canClick={!_.isEmpty(chosenPatients)}
                onClick={() => {
                    history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling/review`);
                }}
            />
        </div>
    );
}

function WaitlistSchedulingTab({
    focused,
    number,
    completed,
    stepLabel,
    completedMainText,
    completedSubText,
    canClick,
    onClick,
}) {
    return (
        <div
            className={classnames({ 'waitlist-scheduling-tab': true, focused, completed, clickable: canClick })}
            onClick={() => {
                if (canClick) {
                    onClick();
                }
            }}
        >
            {completed ? (
                <span className="check-wrapper">
                    <CheckCircleIcon />
                </span>
            ) : (
                <span className="step-number">{number}</span>
            )}

            {completed ? (
                <span className="completed-label">
                    <div className="main-line">{completedMainText}</div>
                    <div className="sub-line">{completedSubText}</div>
                </span>
            ) : (
                <span className="step-label">{stepLabel}</span>
            )}
        </div>
    );
}

function formatTimeRange(timeRange) {
    const from = _.isNil(timeRange.from.date) ? null : moment(_.get(timeRange, 'from.date'));
    const to = _.isNil(timeRange.to.date) ? null : moment(_.get(timeRange, 'to.date'));

    if (_.isNil(from) && _.isNil(to)) {
        return 'Available anytime';
    } else if (_.isNil(from)) {
        return `until ${to.format('MMM Do')}`;
    } else if (_.isNil(to)) {
        return `starting on ${from.format('MMM Do')}`;
    }

    if (from.isSame(to, 'day')) {
        return from.format('MMM Do');
    }
    return `${from.format('MMM Do')} - ${to.format('MMM Do')}`;
}
