import './AppointmentsPage.sass';
import React, { useState, useEffect } from 'react';
import DateSelectionCalendar from './DateSelectionCalendar';
import moment from 'moment';
import queryString from 'query-string';
import _ from 'lodash';
import { updateQueryParameters } from 'utils/urlHelper';
import { getAbbreviatedDateInputFormatByCountryCode, getLocationFromPharmacy } from 'utils/helper';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { connect } from 'react-redux';
import {
    getDefaultAppointmentHoursFromPharmacy,
    getAppointmentTypesFromConversationTemplates,
} from 'components/Patients/scheduling/schedulingUtil';
import { Button, Checkbox, CircularProgress } from '@material-ui/core';
import CreateOrEditAppointmentCalendarDialog from './CreateOrEditAppointmentCalendarDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateOrEditAppointmentDialog from './appointment/CreateOrEditAppointmentDialog';
import {
    fetchAvailableAppointmentCalendars,
    createNewCalendar,
    deleteCalendar,
    updateCalendar,
    getAvailableCalendarAppointmentSlots,
    getCalendarReservations,
    clearCalendarReservations,
    clearAvailableAppointmentSlots,
    getCalendarAppointmentCounts,
} from 'redux/Appt/Calendar/action';
import {
    createNewReservation,
    updateReservation,
    checkinReservation,
    uncheckinReservation,
    getLiveReusableReservationSessions,
    updateReservationSession,
    createNewReservationSession,
} from 'redux/Appt/Reservation/action';
import { adaptSavedCalendarToInitialConfiguration } from './CreateOrEditAppointmentCalendarDialog';
import AppointmentsAgenda from './AppointmentsAgenda';
import { loadPatientsByInboxUserIds } from 'redux/Patient/PatientData/action';
import VacationIcon from './VacationIcon';
import { getDisplayedDateRange } from './DateSelectionCalendar';
import Snowplow from 'snowplow';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';
import ActiveAppointmentsIndicator from './types/ActiveAppointmentsIndicator';
import AppointmentTypesPage from './types/AppointmentTypesPage';

function AppointmentsPage({
    location,
    history,
    activeLocationCountryCode,
    defaultAppointmentHours,
    appointmentTypes,
    apptCalendar,
    fetchAvailableAppointmentCalendars,
    getLiveReusableReservationSessions,
    clearCalendarReservations,
    createNewCalendar,
    deleteCalendar,
    updateCalendar,
    getCalendarReservations,
    getCalendarAppointmentCounts,
    fullPepEnabled,
    apptReservation,
    showApptTypesManage,
}) {
    const props = arguments[0];
    const [showingCreateNew, setShowingCreateNew] = useState(false);
    const [showingEditCalendar, setShowingEditCalendar] = useState(null);
    const [showingAddAppointment, setShowingAddAppointment] = useState(false);
    const [showingEditAppointment, setShowingEditAppointment] = useState(null);
    const [showingUpgrade, setShowingUpgrade] = useState(false);
    const [patients, setPatients] = useState(null);

    const calendars = _.filter(
        _.get(apptCalendar, 'availableCalendars.calendars', []),
        ({ status }) => status === 'ACTIVE'
    );
    const loadingCalendars =
        _.isEmpty(calendars) ||
        _.isNil(_.get(apptCalendar, 'availableCalendars.calendars')) ||
        _.get(apptCalendar, 'loadingAvailableCalendars');

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    useEffect(() => {
        if (
            !_.get(apptReservation, 'liveReusableSessions.loading') &&
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response'))
        ) {
            getLiveReusableReservationSessions();
        }
    }, [apptReservation]);

    useEffect(() => {
        Snowplow.pageView('Appointments');
    }, []);

    const queryParams = queryString.parse(location.search);

    const focusedDate = moment(_.get(queryParams, 'date', moment().format('YYYY-MM-DD')), 'YYYY-MM-DD');
    const hiddenCalendars = new Set(_.filter(_.get(queryParams, 'hidden', '').split(',')));

    useEffect(() => {
        if (!_.isEmpty(calendars)) {
            const { startDate, endDate } = getDisplayedDateRange(focusedDate);
            const calendarIds = _.map(
                _.filter(calendars, ({ calendar_id }) => !hiddenCalendars.has(calendar_id)),
                ({ calendar_id }) => calendar_id
            );

            if (
                !_.isEqual(_.get(apptCalendar.appointmentCounts, 'calendarIds'), calendarIds) ||
                _.get(apptCalendar.appointmentCounts, 'startDate') !== startDate ||
                _.get(apptCalendar.appointmentCounts, 'endDate') !== endDate
            ) {
                getCalendarAppointmentCounts(calendarIds, startDate, endDate);
            }
        }
    }, [apptCalendar, hiddenCalendars, calendars]);

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    // fetching the reservations when the state is cleared
    useEffect(() => {
        const calendarReservations = _.get(apptCalendar, `calendarReservations`);
		// Fetch all the calendars only when there is nothing in the calendarReservations state
        if (!_.isNil(calendarReservations)) return;

        _.each(calendars, ({ calendar_id }) => {
            const loadedReservations = _.get(apptCalendar, `calendarReservations.${calendar_id}`);

            const startAt = moment.utc(moment(`${focusedDate.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'));
            const endAt = moment.utc(
                moment(`${moment(focusedDate).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            );

            const startDate = startAt.format('YYYY-MM-DD');
            const startTime = startAt.format('HH:mm:ss');
            const endDate = endAt.format('YYYY-MM-DD');
            const endTime = endAt.format('HH:mm:ss');

            const isReservationLoading = _.get(loadedReservations, 'loading');
            if (isReservationLoading) return;

            if (
                _.isNil(_.get(loadedReservations, 'response')) ||
                loadedReservations.startDate !== startDate ||
                loadedReservations.startTime !== startTime ||
                loadedReservations.endDate !== endDate ||
                loadedReservations.endTime !== endTime
            ) {
                getCalendarReservations(
                    calendar_id,
                    startAt.format('YYYY-MM-DD'),
                    startAt.format('HH:mm:ss'),
                    endAt.format('YYYY-MM-DD'),
                    endAt.format('HH:mm:ss')
                );
            }
        });
    }, [apptCalendar.calendarReservations]);

    // triggering the fetch of reservations on user-actions:
    // - selecting a different date from the calendar widget
    // - creating/deleting a calendar from the list
    useEffect(() => {
        return () => {
            clearCalendarReservations();
        };
    }, [focusedDate.format('YYYY-MM-DD'), JSON.stringify(_.map(calendars, ({ calendar_id }) => calendar_id))]);

    // triggering the fetch of reservations if the state is empty
    useEffect(() => {
        return () => {
            clearCalendarReservations();
        };
    }, []);

    if (location.pathname === '/appointments/types') {
        return <AppointmentTypesPage />;
    }

    return (
        <div className="appointments-page" style={{ height: `${window.innerHeight - 80}px` }}>
            <div className="appointments-left-bar">
                <div className="clinical-appointments-and-list-of-calendars">
                    <div className="clinical-appointments">
                        <div className="heading">Clinical Appointments</div>
                        <DateSelectionCalendar
                            focusedDate={focusedDate}
                            appointmentCounts={_.get(apptCalendar, 'appointmentCounts.response.appointment_counts')}
                            onDateChange={(date) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(
                                        location,
                                        'date',
                                        date.format('YYYY-MM-DD')
                                    )}`
                                );
                            }}
                        />
                    </div>

                    <div className="visible-calendars" data-cy="visible-calendars">
                        <div className="heading">Visible Calendars</div>
                        {loadingCalendars ? (
                            <div className="list-of-calendars">
                                <div className="calendar-slot loading">
                                    <CircularProgress size={20} />
                                </div>
                            </div>
                        ) : _.isEmpty(calendars) ? (
                            <div className="list-of-calendars">
                                <div className="calendar-slot empty">No clinical appointment calendars.</div>
                            </div>
                        ) : (
                            <div data-cy="calendar-list" className="list-of-calendars">
                                {_.map(calendars, (calendar) => {
                                    const vacationSummary = getVacationTimesSummaryForDateOnCalendar(
                                        calendar,
                                        focusedDate
                                    );
                                    return (
                                        <div
                                            key={calendar.calendar_id}
                                            className="calendar-slot"
                                            data-cy="calendar-slot"
                                        >
                                            <div className="check-wrapper">
                                                <Checkbox
                                                    data-cy="calendar-slot-checkbox"
                                                    color="primary"
                                                    checked={!hiddenCalendars.has(calendar.calendar_id)}
                                                    style={{ color: calendar.color }}
                                                    onChange={() => {
                                                        const id = calendar.calendar_id;
                                                        const clone = new Set(hiddenCalendars);
                                                        if (clone.has(id)) {
                                                            clone.delete(id);
                                                        } else {
                                                            clone.add(id);
                                                        }
                                                        const params = updateQueryParameters(
                                                            location,
                                                            'hidden',
                                                            clone.size === 0 ? null : Array.from(clone).join(',')
                                                        );
                                                        history.push(`${location.pathname}${params}`);
                                                    }}
                                                />
                                            </div>
                                            <div className="text">
                                                <div className="name" data-cy="calendar-name">
                                                    {calendar.name}
                                                </div>
                                                <div className="sub-line">
                                                    <Button
                                                        onClick={() =>
                                                            !fullPepEnabled
                                                                ? setShowingUpgrade(true)
                                                                : setShowingEditCalendar(calendar)
                                                        }
                                                        data-cy="edit-calendar-button"
                                                    >
                                                        edit calendar
                                                    </Button>
                                                </div>
                                            </div>
                                            {_.isNil(vacationSummary) ? null : (
                                                <VacationIcon
                                                    size={28}
                                                    stroke="#555"
                                                    fill="#777"
                                                    title={`Vacation ${vacationSummary} on this calendar for ${focusedDate.format(
                                                        displayedDateFormat
                                                    )}`}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div className="buttons">
                            <Button
                                onClick={() => (!fullPepEnabled ? setShowingUpgrade(true) : setShowingCreateNew(true))}
                                data-cy="create-new-calendar"
                                disabled={loadingCalendars}
                            >
                                Create new Calendar
                            </Button>
                        </div>
                    </div>
                </div>

                {showApptTypesManage && <ActiveAppointmentsIndicator />}
            </div>
            <div className="appointments-main-content" style={{ width: `${window.innerWidth - 220}px` }}>
                {loadingCalendars ? (
                    <CircularProgress className="main-loading-indicator" />
                ) : _.isEmpty(calendars) ? (
                    <NoCalendarsMessage setShowingCreateNew={setShowingCreateNew} />
                ) : (
                    <AppointmentsAgenda
                        {...props}
                        height={window.innerHeight - 80}
                        focusedDate={focusedDate}
                        calendars={_.filter(calendars, ({ calendar_id }) => !hiddenCalendars.has(calendar_id))}
                        displayedDateFormat={displayedDateFormat}
                        onEditAppointment={(appointment) => {
                            setShowingEditAppointment(appointment);
                        }}
                        topActionContent={
                            <Button
                                className="add-appt-button"
                                onClick={() =>
                                    !fullPepEnabled ? setShowingUpgrade(true) : setShowingAddAppointment(true)
                                }
                                data-cy="add-appt-button"
                            >
                                <AddCircleOutlineIcon /> Add new appointment
                            </Button>
                        }
                        patients={patients}
                        setPatients={setPatients}
                        noAppointmentsSubLineText={
                            'You can add some yourself by clicking ADD NEW APPOINTMENT above, or by sending out scheduling workflows to allow patients to schedule their preferred times to have clinical services performed.'
                        }
                    />
                )}
            </div>

            {!showingCreateNew ? null : (
                <CreateOrEditAppointmentCalendarDialog
                    open={showingCreateNew}
                    onClose={() => (!fullPepEnabled ? setShowingUpgrade(true) : setShowingCreateNew(false))}
                    isCreate={showingCreateNew}
                    displayedDateFormat={displayedDateFormat}
                    initialConfiguration={defaultAppointmentHours}
                    createNewCalendar={createNewCalendar}
                    isSaving={!_.isNil(apptCalendar.creatingNewCalendar)}
                    newCalendarColor={getNextCalendarColor(calendars)}
                />
            )}

            {_.isNil(showingEditCalendar) ? null : (
                <CreateOrEditAppointmentCalendarDialog
                    open={true}
                    onClose={() => setShowingEditCalendar(null)}
                    isCreate={false}
                    canDelete={_.size(calendars) > 1} // don't allow users to delete the last calendar
                    deleteCalendar={deleteCalendar}
                    displayedDateFormat={displayedDateFormat}
                    updateCalendar={updateCalendar}
                    initialConfiguration={adaptSavedCalendarToInitialConfiguration(showingEditCalendar)}
                />
            )}

            {!showingAddAppointment && !showingEditAppointment ? null : (
                <CreateOrEditAppointmentDialog
                    {...props}
                    open={true}
                    onClose={() =>
                        showingAddAppointment ? setShowingAddAppointment(false) : setShowingEditAppointment(null)
                    }
                    isCreate={showingAddAppointment}
                    initialOverwrittenCalendarId={showingEditAppointment ? showingEditAppointment.calendar_id : null}
                    displayedDateFormat={displayedDateFormat}
                    initialAppointmentTypes={appointmentTypes}
                    calendars={calendars}
                    editing={showingEditAppointment}
                    initialSelectedDate={focusedDate}
                    initialFocusedTab={showingAddAppointment ? 'patient' : 'time'}
                    initialConfiguration={
                        !showingEditAppointment
                            ? null
                            : {
                                  patient: _.get(patients, `${showingEditAppointment.inbox_user_id}`),
                                  appointmentType: {
                                      ...getMatchingAppointmentType(appointmentTypes, showingEditAppointment),
                                      appointment_calendar_id: showingEditAppointment.calendar_id,
                                      appointment_length_minutes: moment(
                                          showingEditAppointment.end_date,
                                          'YYYY-MM-DDTHH:mm:SS'
                                      ).diff(
                                          moment(showingEditAppointment.start_date, 'YYYY-MM-DDTHH:mm:SS'),
                                          'minutes'
                                      ),
                                      appointment_label: showingEditAppointment.label,
                                      image_ref: _.get(
                                          getMatchingAppointmentType(appointmentTypes, showingEditAppointment),
                                          'image_ref',
                                          'one-off-appt.v2.jpg'
                                      ),
                                  },
                                  datetime: moment.tz(
                                      moment.utc(showingEditAppointment.start_date, 'YYYY-MM-DDTHH:mm:SS'),
                                      _.find(calendars, (c) => c.calendar_id === showingEditAppointment.calendar_id)
                                          .timezone
                                  ),
                              }
                    }
                />
            )}

            {!showingUpgrade ? null : <FullPepUpgrade asDialog onClose={() => setShowingUpgrade(false)} />}
        </div>
    );
}

export function getVacationTimesSummaryForDateOnCalendar(calendar, date) {
    const vacationRanges = [];
    _.each(calendar.vacations, ({ start_date, end_date }) => {
        const from = moment.tz(moment.utc(start_date, 'YYYY-MM-DD HH:mm:ss'), _.get(calendar, 'timezone'));
        const to = moment.tz(moment.utc(end_date, 'YYYY-MM-DD HH:mm:ss'), _.get(calendar, 'timezone'));

        if (date.isBetween(from, to, 'minute', '[]') || from.isSame(date, 'day') || to.isSame(date, 'day')) {
            vacationRanges.push({ from, to });
        }
    });

    let summary = [];
    _.each(vacationRanges, ({ from, to }) => {
        const startTime = from.format('h:mma') === '12:00am' ? 'start' : from.format('h:mma');
        const endTime = to.format('h:mma') === '11:59pm' ? 'end' : to.format('h:mma');

        if (startTime === 'start' && endTime === 'end') {
            summary.length = 0;
            summary.push('all day');
            return false;
        }

        if (startTime === 'start') {
            summary.push(`before ${endTime}`);
        } else if (endTime === 'end') {
            summary.push(`after ${startTime}`);
        } else {
            summary.push(`from ${startTime} - ${endTime}`);
        }
    });

    return !_.isEmpty(summary) ? summary.join(_.size(summary) === 2 ? ' and ' : ', ') : null;
}

export function getMatchingAppointmentType(appointmentTypes, appointment) {
    const typeMatch = _.find(appointmentTypes, ({ appointment_label }) => {
        const { label } = appointment;
        return appointment_label === label;
    });

    if (_.isObject(typeMatch)) {
        return typeMatch;
    }
}

function NoCalendarsMessage({ setShowingCreateNew }) {
    return (
        <div className="no-calendars-message">
            There are no clinical appointment calendars available,
            <Button onClick={() => setShowingCreateNew(true)}>click here</Button>
            to create one.
        </div>
    );
}

export function getNextCalendarColor(calendars) {
    const colors = ['#37a1db', '#53bb7c', '#f35937', '#ffd044', '#005679', '#9a7ac8', '#ff961b', '#86DEFB'];

    const used = _.groupBy(calendars, ({ color }) => color);

    return _.first(_.sortBy(colors, (c) => _.size(_.get(used, c, []))));
}

function mapStateToProps(state) {
    const {
        pharmacy,
        inboxConversationTemplate,
        apptCalendar,
        apptReservation,
        patientData,
        auth,
        launchDarkly,
    } = state;
    return {
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        defaultAppointmentHours: getDefaultAppointmentHoursFromPharmacy(pharmacy),
        defaultTimeZone: _.get(getLocationFromPharmacy(pharmacy), 'timezone'),
        appointmentTypes: getAppointmentTypesFromConversationTemplates(
            inboxConversationTemplate,
            _.get(apptCalendar, 'availableCalendars.calendars')
        ),
        apptCalendar,
        apptReservation,
        patientData,
        userId: _.get(auth, 'userAccount.user_id'),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
        showApptTypesManage: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {
    fetchAvailableAppointmentCalendars,
    createNewCalendar,
    deleteCalendar,
    updateCalendar,
    getAvailableCalendarAppointmentSlots,
    createNewReservation,
    getCalendarReservations,
    loadPatientsByInboxUserIds,
    updateReservation,
    clearCalendarReservations,
    clearAvailableAppointmentSlots,
    checkinReservation,
    uncheckinReservation,
    getCalendarAppointmentCounts,
    getLiveReusableReservationSessions,
    updateReservationSession,
    createNewReservationSession,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentsPage);
