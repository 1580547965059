const typePrefix = 'reviews/';

export const reviewType = {
    FETCH_SUMMARY_REQUEST: `${typePrefix}FETCH_SUMMARY_REQUEST`,
    FETCH_SUMMARY_SUCCESS: `${typePrefix}FETCH_SUMMARY_SUCCESS`,
    FETCH_SUMMARY_FAILURE: `${typePrefix}FETCH_SUMMARY_FAILURE`,
    FETCH_AVERAGE_REQUEST: `${typePrefix}FETCH_AVERAGE_REQUEST`,
    FETCH_AVERAGE_SUCCESS: `${typePrefix}FETCH_AVERAGE_SUCCESS`,
    FETCH_AVERAGE_FAILURE: `${typePrefix}FETCH_AVERAGE_FAILURE`,
    FETCH_LIST_REQUEST: `${typePrefix}FETCH_LIST_REQUEST`,
    FETCH_LIST_SUCCESS: `${typePrefix}FETCH_LIST_SUCCESS`,
    FETCH_LIST_FAILURE: `${typePrefix}FETCH_LIST_FAILURE`,
};

export default reviewType;
