import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Config2Constants from 'constants/Config2';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { pharmacyAction } from 'redux/Pharmacy/action';

import { getExternalIdentifier } from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/ThirdParty';
import {
    getPackageByCode,
    addCustPackage,
    updateCustPackage,
} from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2Packages';

import {
    getPackageAttributes,
    addCustPackageAttribute,
    updateCustPackageAttribute,
} from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2PackageAttributes';

import BooleanAttributesMixin from './Mixins/BooleanCustPackageAttributes';
import StringAttributesMixin from './Mixins/StringCustPackageAttributes';
import TextboxDialog from './TextboxDialog';

function checkIfShouldBeDisabled(props, checkDisabled) {
    // if this function is not defined, we are not disabled
    if (!checkDisabled) {
        return false;
    }
    // check if we should be disabled
    const pharmacyId = props.pharmacy && props.pharmacy.id;
    const locationId = props.activeLocationId;
    return checkDisabled(pharmacyId, locationId);
}

const WithCustPackageAttributes = (PackageAttributeComponent, data) => {
    class CustPackageAttributes extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                isUpdating: false,
                openTextboxDialog: false,
            };
            this.package = {
                ...data.package,
            };
            this.title = data.title;
            this.description = data.description;
            this.checkDisabled = data.checkDisabled;
            this.isPackageAddedToCust = () => !!this.getCustPackage();
            this.getPackageByCode = _.memoize(getPackageByCode);
            this.openTextboxDialog = () => this.setState({ openTextboxDialog: true });
            this.closeTextboxDialog = () => this.setState({ openTextboxDialog: false });
            this.resetLocalAttributeValue = () => (this.attributeValue = undefined);

            // Add mixins based on attribute data type
            const { dataType, thirdPartyName } = data.package.attribute;
            if (dataType === Config2Constants.dataType.boolean) {
                Object.assign(this, BooleanAttributesMixin);
            } else if (dataType === Config2Constants.dataType.string) {
                Object.assign(this, StringAttributesMixin(thirdPartyName));
            }
        }

        getCustPackage = () => _.find(this.props.pharmacy.package, (pkg) => pkg.code === this.package.code);

        getCustPackageAttribute = () => {
            const { activeLocationId } = this.props;
            const custPackage = this.getCustPackage() || {};
            const { name, isLocationLevelAttr } = this.package.attribute;
            const isDesiredProperty = (p) =>
                p.name === name && (!isLocationLevelAttr || p.locationId === activeLocationId);
            return _.find(custPackage.properties, (p) => isDesiredProperty(p));
        };

        getPackageUpdatedWithStatus = (status) => {
            const custPackage = { ...this.getCustPackage() };
            const {
                status: { enabled, disabled },
            } = Config2Constants;

            custPackage.statusId = status === 'enabled' ? enabled : disabled;
            return custPackage;
        };

        isPackageAttributeAddedToCust = () => {
            const custPackageAttribute = this.getCustPackageAttribute();
            return !_.isEmpty(custPackageAttribute) && !_.isEmpty(custPackageAttribute.id);
        };

        isCustPackageActive = () => {
            const custPackage = this.getCustPackage();
            return custPackage && custPackage.statusId === Config2Constants.status.enabled;
        };

        addCustPackage = async () => {
            const { pharmacy, addCustPackage } = this.props;
            const payload = {
                statusId: Config2Constants.status.enabled,
            };
            const package_ = {
                id: await this.getPackageByCode(this.package.code),
                name: this.package.name,
            };

            return await addCustPackage(pharmacy.id, package_, payload);
        };

        activateCustPackage = async () => {
            const { pharmacy, getPharmacy, updateCustPackage } = this.props;

            try {
                if (!this.isPackageAddedToCust()) {
                    await this.addCustPackage();
                    await getPharmacy(pharmacy.id);
                } else if (!this.isCustPackageActive()) {
                    const custPackage = this.getPackageUpdatedWithStatus('enabled');
                    await updateCustPackage(pharmacy.id, custPackage);
                }
            } catch (e) {
                throw e;
            }
        };

        getValueFromTextbox = async () => {
            this.setState({ openTextboxDialog: true });
            await this.waitUntilTextboxDialogClosed();
            return this.attributeValue;
        };

        waitUntilTextboxDialogClosed = async () => {
            return await new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (this.state.openTextboxDialog === false) {
                        resolve();
                        clearInterval(interval);
                    }
                }, 100);
            });
        };

        addCustPackageAttribute = async () => {
            const { pharmacy, addCustPackageAttribute, activeLocationId } = this.props;
            const { thirdPartyName } = this.package.attribute;
            const title = thirdPartyName ? `${thirdPartyName} ID` : this.title;
            const custPackage = this.getCustPackage();
            const packageAttribute = await getPackageAttributes(custPackage.pkgId, this.package.attribute.name);
            const cust = {
                id: pharmacy.id,
                packageID: custPackage.id,
            };

            const payload = {
                title: title,
                dataTypeId: packageAttribute.dataTypeId,
                packageAttrId: packageAttribute.id,
                value: this.getAttributeValue ? await this.getAttributeValue() : await this.getValueFromTextbox(),
            };

            if (this.package.attribute.isLocationLevelAttr) {
                payload.locationId = activeLocationId;
            }

            !_.isUndefined(payload.value) && (await addCustPackageAttribute(cust, payload));
        };

        updateCustPackageAttribute = async (status) => {
            const { pharmacy, updateCustPackageAttribute } = this.props;
            const { thirdPartyName } = this.package.attribute;
            const custPackage = this.getCustPackage();
            const custPackageAttribute = await this.getPackageAttrUpdatedWithStatus(status);
            const title = thirdPartyName ? `${thirdPartyName} ID` : this.title;

            const cust = {
                id: pharmacy.id,
                packageID: custPackage.id,
            };
            const payload = {
                title: title,
                id: custPackageAttribute.id,
                value: custPackageAttribute.value,
                statusTypeId: custPackageAttribute.statusId,
            };

            !_.isUndefined(payload.value) && (await updateCustPackageAttribute(cust, payload));
        };

        activateCustPackageAttribute = async () => {
            const { pharmacy, getPharmacy, displayToast } = this.props;
            this.setState({ isUpdating: true });

            try {
                await this.activateCustPackage();
                if (!this.isPackageAttributeAddedToCust()) {
                    await this.addCustPackageAttribute();
                } else {
                    await this.updateCustPackageAttribute('enabled');
                }
                this.resetLocalAttributeValue();
            } catch (e) {
                displayToast({ text: `Failed To Activate ${this.title}!`, type: 'error' });
            }

            // Refresh the pharmacy to have the latest values, irrespective of success or failure
            await getPharmacy(pharmacy.id);
            this.setState({ isUpdating: false });
        };

        deactivateCustPackageAttribute = async () => {
            const { pharmacy, getPharmacy, displayToast } = this.props;
            this.setState({ isUpdating: true });

            try {
                await this.updateCustPackageAttribute('disabled');
            } catch (e) {
                displayToast({ text: `Failed To Deactivate ${this.title}!`, type: 'error' });
            }

            // Refresh the pharmacy to have the latest values, irrespective of success or failure
            await getPharmacy(pharmacy.id);
            this.setState({ isUpdating: false });
        };

        onTextSubmit = (text) => {
            this.attributeValue = text;
            this.closeTextboxDialog();
        };

        render = () => {
            const { isUpdating, openTextboxDialog } = this.state;
            const { dialogBox } = this.package.attribute;
            const isActive = this.isCustPackageActive() && this.isCustPackageAttributeActive();
            const isDisabled = checkIfShouldBeDisabled(this.props, this.checkDisabled);

            return (
                <Fragment>
                    {dialogBox && (
                        <TextboxDialog
                            open={openTextboxDialog}
                            onSubmit={this.onTextSubmit}
                            onClose={this.closeTextboxDialog}
                            dialogBox={dialogBox}
                        />
                    )}
                    <PackageAttributeComponent
                        isActive={isActive}
                        isUpdating={isUpdating}
                        onActivate={this.activateCustPackageAttribute}
                        onDeactivate={this.deactivateCustPackageAttribute}
                        disabled={isDisabled}
                    />
                </Fragment>
            );
        };
    }

    const mapStateToProps = (state) => ({
        pharmacy: state.pharmacy.pharmacy,
        activeLocationId: state.pharmacy.activeLocationId,
    });

    const mapDispatchToProps = (dispatch) => ({
        displayToast: (data) => dispatch(displayToast(data)),
        getPharmacy: (custID) => dispatch(pharmacyAction.getPharmacy(custID)),

        addCustPackage: (custID, package_, payload) => dispatch(addCustPackage(custID, package_, payload, false)),

        addCustPackageAttribute: (cust, payload) => dispatch(addCustPackageAttribute(cust, payload)),

        updateCustPackage: (custID, custPackage) => dispatch(updateCustPackage(custID, custPackage, false)),

        updateCustPackageAttribute: (cust, custPackageAttribute) =>
            dispatch(updateCustPackageAttribute(cust, custPackageAttribute)),

        getExternalIdentifier: (url, params, thirdPartyName) =>
            dispatch(getExternalIdentifier(url, params, thirdPartyName)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(CustPackageAttributes);
};

export default WithCustPackageAttributes;
