import { get } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { logHttpError } from 'utils/log';

const fetchSummary = () => {
    const summaryUrl = Config.status_page_url + API_PATH.STAUS_PAGE_SUMMARY;

    return get(summaryUrl, { withCredentials: false })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logHttpError('An error occurred on statuspage fetchSummary', err);
            return Promise.reject(err);
        });
};

export const statusPageService = {
    fetchSummary,
};

export default statusPageService;
