import axios from 'axios';

import Config from 'config';
import { restActionCreators } from 'redux/actionCreators/rest';
import { logHttpError } from '../../utils/log';

const CancelToken = axios.CancelToken;
let cancelNewsletterDetailsReq;
let cancelGetCardsReq;
let cancelNewslettersReq;
let cancelSubscriberRequest;

export const getPackageAttributes = (packageID) => {
    const url = `${Config.config2_url}/package/${packageID}/packageAttr`;
    const args = {
        params: {
            numRows: 35,
        },
    };

    return restActionCreators
        .get(url, args)
        .then(({ result }) => result)
        .catch((error) => {
            logHttpError(`An error occured on getPackageAttributes for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

const addCustPackageAttribute = (cust, payload) => {
    const url = `${Config.config2_url}/cust/${cust.id}/custPackage/${cust.packageID}/custPackageAttr`;

    return restActionCreators
        .post(url, payload)
        .then(({ result }) => result)
        .catch((error) => {
            logHttpError(
                `An error occured on addCustPackageAttribute for pharmacyId: ${cust.id}, packageId: ${cust.packageID}`,
                error
            );
            return Promise.reject(error);
        });
};

const updateCustPackageAttribute = (cust, payload) => {
    const url = `${Config.config2_url}/cust/${cust.id}/custPackage/${cust.packageID}/custPackageAttr/${payload.id}`;

    return restActionCreators
        .put(url, payload)
        .then(({ result }) => result)
        .catch((error) => {
            logHttpError(
                `An error occured on updateCustPackageAttribute for pharmacyId: ${cust.id}, packageId: ${cust.packageID}`,
                error
            );
            return Promise.reject(error);
        });
};

const saveSubscriber = (payload) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/subscribers`;

    return restActionCreators.post(url, payload).catch((error) => {
        logHttpError(`An error occurred on saveSubscriber for pharmacyId: ${Config.X_PharmacyID}`, error);
        return Promise.reject(error);
    });
};

const removeSubscriber = (subscriberId) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/subscribers/${subscriberId}`;

    return restActionCreators.deleteReq(url, { delete_from_all_list: false }).catch((error) => {
        logHttpError(`An error occured on removeSubscriber for subscribersId: ${subscriberId}`, error);
        return Promise.reject(error);
    });
};

export const getSubscriberList = (requestParams, pharmacyId) => {
    // const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/subscribers`;
    const url = `${Config.apiweb_url}/newsletters/${pharmacyId}/subscribers`;

    if (cancelSubscriberRequest) {
        cancelSubscriberRequest('cancel');
    }

    const cancelToken = new CancelToken(function executor(c) {
        cancelSubscriberRequest = c;
    });

    const cancelableRequestParams = Object.assign({}, { cancelToken }, requestParams);

    return restActionCreators
        .get(url, cancelableRequestParams)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occured on getSubscriberList for pharmacyId: ${pharmacyId}`, error);
            return Promise.reject(error);
        });
};

export const getHistoryList = (configObj) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/emails`;

    return restActionCreators
        .get(url, configObj)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occured on getHistoryList for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

export const getNewsletters = (configObj) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/newsletters`;
    // cancel any previous requests
    if (cancelNewslettersReq) {
        cancelNewslettersReq('cancel');
    }
    configObj.cancelToken = new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelNewslettersReq = c;
    });
    return restActionCreators
        .get(url, configObj)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occured on getHistoryList for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

export const getNewsletterDetails = (newsletterId) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/emails/${newsletterId}`;

    // cancel any previous requests
    if (cancelNewsletterDetailsReq) {
        cancelNewsletterDetailsReq('cancel');
    }

    return restActionCreators
        .get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancelNewsletterDetailsReq = c;
            }),
        })
        .then((result) => result)
        .catch((error) => {
            logHttpError(
                `An error occured on getNewsletterDetails for pharmacyId: ${Config.X_PharmacyID} newsletterId: ${newsletterId}`,
                error
            );
            return Promise.reject(error);
        });
};

const addCard = (payload) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/cards`;
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    return restActionCreators
        .post(url, payload, config)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occured on addCard for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

const deleteCard = (card_id) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/cards/${card_id}`;

    return restActionCreators
        .deleteReq(url)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occured on deleteCard for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

export const getCards = (payload) => {
    const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/cards`;

    // Cancel any previous search requests
    if (cancelGetCardsReq) {
        cancelGetCardsReq('cancel_request');
    }

    return restActionCreators
        .get(url, {
            params: { ...payload },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancelGetCardsReq = c;
            }),
        })
        .then((response) => response)
        .catch((error) => {
            logHttpError(`An error occured on getCards for pharmacyId: ${Config.X_PharmacyID}`, error);
            return Promise.reject(error);
        });
};

export const saveEventDetails = (payload) => {
    const eventData = {
        cards: payload.cards,
        scheduled_date: payload.scheduled_date,
    };

    // if event id is not present then create a event
    if (!payload.event_id) {
        // post event details
        const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/newsletters`;
        return restActionCreators
            .post(url, eventData)
            .then((result) => result)
            .catch((error) => {
                logHttpError(`An error occured on saveEventDetails for pharmacyId: ${Config.X_PharmacyID}`, error);
                return Promise.reject(error);
            });
    } else {
        // put event details
        const url = `${Config.apiweb_url}/newsletters/${Config.X_PharmacyID}/newsletters/${payload.event_id}`;
        return restActionCreators
            .put(url, eventData)
            .then((result) => result)
            .catch((error) => {
                logHttpError(`An error occured on saveEventDetails for pharmacyId: ${Config.X_PharmacyID}`, error);
                return Promise.reject(error);
            });
    }
};

const updateSettings = (payload) => {
    const url = `${Config.apiweb_url}/newsletters/settings`;
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    return restActionCreators
        .post(url, payload, config)
        .then((result) => result)
        .catch((error) => {
            logHttpError(`An error occurred on updateSettings`, error);
            return Promise.reject(error);
        });
};

export const newsletterService = {
    getPackageAttributes,
    addCustPackageAttribute,
    updateCustPackageAttribute,
    saveSubscriber,
    removeSubscriber,
    getSubscriberList,
    getHistoryList,
    getCards,
    addCard,
    getNewsletters,
    getNewsletterDetails,
    saveEventDetails,
    updateSettings,
    deleteCard,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
