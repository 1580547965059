import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';

import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { getPharmacyUserRoleDetails } from './helper';
import { pluralize } from '../../../../utils/formatTime';
import { ALL_LOCATIONS } from 'constants/Common';

// DpDialog.Dialog has 800px width. Since this dialog does not have much content, a width of 600px should be enough.
// const Dialog = withStyles({
//     paper: {
//         width: '600px',
//     },
//     paperWidthMd: {
//         width: '600px !important',
//     },
// })(DpDialog.Dialog);

const MoreInfoDialog = ({ open, onDelete, onEdit, onCancel, user, pharmacyId, selectedLocalLocation }) => {
    const dialogHeading = 'User Details';
    const { name, email, lastActive, roles } = user;

    const pharmacyUserRoleDetails = getPharmacyUserRoleDetails(pharmacyId, roles ? roles : []);
    const locationAccessCount =
        pharmacyUserRoleDetails.locationRoles.length - pharmacyUserRoleDetails.locationNoneRoleCount;
    const locationAccessText =
        pharmacyUserRoleDetails.pharmacyRole && pharmacyUserRoleDetails.locationNoneRoleCount === 0
            ? 'All locations'
            : `${locationAccessCount} location${pluralize(locationAccessCount)}`;

    return (
        <DpDialog.Dialog id="user-info-dialog" open={open} onClose={onCancel}>
            <DpDialog.Content style={{ minHeight: '15%' }}>
                <DpDialog.ContentHeader heading={dialogHeading} onClose={onCancel} />
                <div style={{ paddingTop: '8px' }}>
                    <div className="title">Name</div>
                    <div className="info">{name}</div>
                    <div className="title">Email</div>
                    <div className="info">{email}</div>
                    {selectedLocalLocation === ALL_LOCATIONS && (
                        <Fragment>
                            <div className="title">Location Access</div>
                            <div className="info">{locationAccessText}</div>
                        </Fragment>
                    )}
                    <div className="title">Last Active</div>
                    <div className="info">{lastActive}</div>
                </div>
            </DpDialog.Content>
            <DpDialog.Actions>
                <Button onClick={onDelete} size="small" variant="outlined" color="secondary">
                    Delete User
                </Button>
                <Button onClick={onEdit} size="small" variant="outlined" color="primary" style={{ marginLeft: '16px' }}>
                    Edit
                </Button>
                <Button
                    autoFocus
                    onClick={onCancel}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '16px' }}
                >
                    Done
                </Button>
            </DpDialog.Actions>
        </DpDialog.Dialog>
    );
};

export default MoreInfoDialog;
