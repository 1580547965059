// Note: These are the restricted resources defined in the excel sheet attached on PHAR-1355
import { restrictedIVRResources } from './CommunicationPlatformIVR';
import { restrictedCoronavirusResources } from './Coronavirus';
import { restrictedLoginResources } from './Login';
import { restrictedInboxResources } from './Inbox';
import { restrictedLeadsResources } from './Leads';
import { restrictedDpResources } from './Resources';
import { restrictedRefillResources } from './Refills';
import { restrictedReportResources } from './Reports';
import { restrictedVoicemailResources } from './Voicemail';
import { restrictedGeneralSettingResources } from './GeneralSettings';
import { restrictedSettingsPopoverResources } from './SettingsPopover';
import { restrictedPatientsResources } from './Patients';
import { restrictedPharmacyAppResources } from './PharmacyApps';
import { restrictedNewsletterResources } from './Newsletter';

export const restrictedResources = {
    ...restrictedDpResources,
    ...restrictedIVRResources,
    ...restrictedCoronavirusResources,
    ...restrictedLoginResources,
    ...restrictedInboxResources,
    ...restrictedLeadsResources,
    ...restrictedRefillResources,
    ...restrictedReportResources,
    ...restrictedVoicemailResources,
    ...restrictedGeneralSettingResources,
    ...restrictedSettingsPopoverResources,
    ...restrictedPatientsResources,
    ...restrictedPharmacyAppResources,
    ...restrictedNewsletterResources,
};
