import React from 'react';
import FilteringOptionGroup from './FilteringOptionGroup';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

export default function PatientTypePopover({ value, onChange }) {
    return (
        <div className="filter-popover patient-type-popover">
            <FilteringOptionGroup
                value={value}
                onChange={onChange}
                options={[
                    {
                        value: 'existing',
                        icon: <LocalPharmacyIcon />,
                        label: PatientTypePopover.OPTION_LABELS['existing'],
                    },
                    {
                        value: 'waitlist',
                        icon: <PersonOutlineIcon />,
                        label: PatientTypePopover.OPTION_LABELS['waitlist'],
                    },
                ]}
            />
        </div>
    );
}

PatientTypePopover.OPTION_LABELS = {
    existing: 'Existing pharmacy patients',
    waitlist: 'Waitlist only patients',
};
