import './AppointmentsPopover.sass';
import React, { useState } from 'react';
import FilteringOptionGroup from './FilteringOptionGroup';
import EventIcon from '@material-ui/icons/Event';
import DateSelectionCalendar from 'components/Appointments/DateSelectionCalendar';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import _ from 'lodash';

export default function AppointmentsPopover({ value, onChange, setOpen }) {
    const [showCustom, setShowCustom] = useState(false);
    const [dateRange, setDateRange] = useState(
        !_.startsWith(value, 'custom:')
            ? null
            : {
                  from: moment(value.split(':')[1], 'YYYY-MM-DD'),
                  to: moment(value.split(':')[2], 'YYYY-MM-DD'),
              }
    );

    return (
        <div className="filter-popover appointments-filter-popover">
            <FilteringOptionGroup
                value={value}
                onChange={(value) => {
                    if (value === 'custom') {
                        setShowCustom(true);
                    } else {
                        onChange(value);
                    }
                }}
                options={[
                    { value: 'today', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.today },
                    { value: 'tomorrow', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.tomorrow },
                    { value: 'this_week', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.this_week },
                    { value: 'next_week', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.next_week },
                    { value: 'custom', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.custom },
                    { value: 'none', icon: <EventIcon />, label: AppointmentsPopover.OPTION_LABELS.no_appointments },
                ]}
            />

            {showCustom && (
                <Dialog
                    className="appointments-popover-custom-dates-dialog"
                    open={true}
                    onClose={() => setShowCustom(false)}
                >
                    <DialogTitle>Set specified appointment dates</DialogTitle>

                    <DialogContent>
                        <div className="sub-heading">
                            This filter will limit to patients that have (or had) appointments scheduled between the
                            from and to dates you specify below
                        </div>

                        <div className="calendars">
                            <div className="calendar-wrapper">
                                <div className="calendar-heading">
                                    <div>From...</div>
                                    <div className="date">
                                        {_.isNil(_.get(dateRange, 'from'))
                                            ? null
                                            : _.get(dateRange, 'from').format('MMM Do, YYYY')}
                                    </div>
                                </div>
                                <DateSelectionCalendar
                                    initiallyFocusedMonth={moment()}
                                    focusedDate={_.get(dateRange, 'from')}
                                    onDateChange={(from) => {
                                        setDateRange({ ...dateRange, from });
                                    }}
                                />
                            </div>

                            <div className="calendar-wrapper">
                                <div className="calendar-heading">
                                    <div>To...</div>
                                    <div className="date">
                                        {_.isNil(_.get(dateRange, 'to'))
                                            ? null
                                            : _.get(dateRange, 'to').format('MMM Do, YYYY')}
                                    </div>
                                </div>
                                <DateSelectionCalendar
                                    initiallyFocusedMonth={moment()}
                                    focusedDate={_.get(dateRange, 'to')}
                                    onDateChange={(to) => {
                                        setDateRange({ ...dateRange, to });
                                    }}
                                />
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setShowCustom(false)}>Cancel</Button>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={_.isNil(_.get(dateRange, 'from')) || _.isNil(_.get(dateRange, 'to'))}
                            onClick={() => {
                                onChange(
                                    `custom:${dateRange.from.format('YYYY-MM-DD')}:${dateRange.to.format('YYYY-MM-DD')}`
                                );
                                setDateRange(null);
                                setShowCustom(null);
                                setOpen(false);
                            }}
                        >
                            Add Filter
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

AppointmentsPopover.OPTION_LABELS = {
    today: 'Today',
    tomorrow: 'Tomorrow',
    this_week: 'This week',
    next_week: 'Next week',
    custom: 'During specified dates',
    no_appointments: 'No Appointments',
};

export function formatAppointmentsDateRange(apptsQueryParam) {
    if (_.startsWith(apptsQueryParam, 'custom:')) {
        const [, startStr, endStr] = apptsQueryParam.split(':');

        const start = moment(startStr, 'YYYY-MM-DD');
        const end = moment(endStr, 'YYYY-MM-DD');

        if (start.isSame(end, 'month')) {
            return `${start.format('MMM Do')} - ${end.format('Do')}`;
        } else if (start.isSame(end, 'year')) {
            return `${start.format('MMM Do')} - ${end.format(
                `MMM Do${start.isSame(moment(), 'year') ? '' : ', YYYY'}`
            )}`;
        } else {
            return `${start.format('MMM Do, YYYY')} - ${end.format('MMM Do, YYYY')}`;
        }
    }
    return apptsQueryParam;
}
