export default {
    FETCH_TIGER_PISTOL_METRIC_STARTED: 'FETCH_TIGER_PISTOL_METRIC_STARTED',
    FETCH_TIGER_PISTOL_METRIC_SUCCESS: 'FETCH_TIGER_PISTOL_METRIC_SUCCESS',
    FETCH_TIGER_PISTOL_METRIC_FAILED: 'FETCH_TIGER_PISTOL_METRIC_FAILED',
    FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_STARTED: 'FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_STARTED',
    FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_SUCCESS: 'FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_SUCCESS',
    FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_FAILED: 'FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_FAILED',
    FETCH_TIGER_PISTOL_CAMPAIGN_STARTED: 'FETCH_TIGER_PISTOL_CAMPAIGN_STARTED',
    FETCH_TIGER_PISTOL_CAMPAIGN_SUCCESS: 'FETCH_TIGER_PISTOL_CAMPAIGN_SUCCESS',
    FETCH_TIGER_PISTOL_CAMPAIGN_FAILED: 'FETCH_TIGER_PISTOL_CAMPAIGN_FAILED',
    FETCH_TIGER_PISTOL_PREVIEW_STARTED: 'FETCH_TIGER_PISTOL_PREVIEW_STARTED',
    FETCH_TIGER_PISTOL_PREVIEW_SUCCESS: 'FETCH_TIGER_PISTOL_PREVIEW_SUCCESS',
    FETCH_TIGER_PISTOL_PREVIEW_FAILED: 'FETCH_TIGER_PISTOL_PREVIEW_FAILED',
    UPDATE_CAMPAIGN_PREVIEWS: 'UPDATE_CAMPAIGN_PREVIEWS',
};
