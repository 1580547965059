import Actions from 'redux/actions';
import ivrLang from 'constants/Languages';

const initialState = {
    ivrLang: [],
    ivrLangMap: {},
    langChoices: [],
    loadingLangChoices: false,
    loadingGreetingWithLangChoice: false,
    savingInProgress: false,
    sysLang: [],
    loadingSysLang: false,
    savingLang: false,
    removingLang: false,
    ttsEngines: [],
    ttsEnginesLoading: false,
};

const getIvrLangMap = (ivrLangs) => {
    const ivrLangMap = {};
    ivrLangs.forEach((ivrLang) => {
        ivrLangMap[ivrLang.langTypeId] = { ...ivrLang };
        ivrLangMap[ivrLang.langTypeId].voiceMap = {};
        ivrLang.voice.forEach((voice) => {
            ivrLangMap[ivrLang.langTypeId].voiceMap[voice.voiceTypeId] = { ...voice };
        });
    });
    return ivrLangMap;
};

const addNewIvrLang = (state) => {
    const { langChoices } = { ...state };
    const ivrLangModel = { ...ivrLang };
    langChoices.push(ivrLangModel);
    return {
        ...state,
        langChoices,
    };
};

const updateLangChange = (state, payload) => {
    const { langChoices } = { ...state };
    langChoices[payload.idx].langTypeId = payload.data;
    langChoices[payload.idx].voiceTypeId = '';
    langChoices[payload.idx].error = '';
    return {
        ...state,
        langChoices,
    };
};

const updateVoiceChange = (state, payload) => {
    const { langChoices } = { ...state };
    langChoices[payload.idx].voiceTypeId = payload.data;
    langChoices[payload.idx].error = '';
    return {
        ...state,
        langChoices,
    };
};

const removeIvrLangChoice = (state, payload) => {
    const { langChoices } = { ...state };
    langChoices.splice(payload.idx, 1);
    return {
        ...state,
        langChoices,
    };
};

const removeLang = (state, payload) => {
    const { ivrLangMap } = { ...state };
    delete ivrLangMap[payload];
    return {
        ...state,
        ivrLangMap,
    };
};

const addLang = (state, payload) => {
    const { ivrLangMap, ivrLang } = { ...state };
    const lang = ivrLang.find((lang) => lang.langTypeId === payload);

    if (lang) {
        ivrLangMap[lang.langTypeId] = lang;
        ivrLangMap[lang.langTypeId].voiceMap = {};
        lang.voice.forEach((voice) => {
            ivrLangMap[lang.langTypeId].voiceMap[voice.voiceTypeId] = { ...voice };
        });
    } else {
        ivrLangMap[payload] = {};
    }

    return {
        ...state,
        ivrLangMap,
    };
};

const removeIvrLangChoiceProcessing = (state, payload, isProcessing) => {
    const { langChoices } = { ...state };
    langChoices[payload.idx].processing = isProcessing;
    return {
        ...state,
        langChoices,
    };
};

export default function LanguagesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_IVR_LANGUAGES_STARTED:
            return { ...state, loadingLangChoices: true };
        case Actions.FETCH_IVR_LANGUAGES_SUCCESS:
            return {
                ...state,
                loadingLangChoices: false,
                ivrLang: payload,
                ivrLangMap: getIvrLangMap(payload),
            };
        case Actions.FETCH_IVR_LANGUAGES_FAILED:
            return { ...state, loadingLangChoices: false };
        case Actions.FETCH_IVR_LANGUAGE_CHOICES_SUCCESS:
            return { ...state, langChoices: payload.result, loadingGreetingWithLangChoice: false };
        case Actions.FETCH_SYS_LANGUAGE_STARTED:
            return { ...state, loadingSysLang: true };
        case Actions.FETCH_SYS_LANGUAGE_SUCCESS:
            return { ...state, sysLang: payload, loadingSysLang: false };
        case Actions.FETCH_SYS_LANGUAGE_FAILED:
            return { ...state, loadingSysLang: false };
        case Actions.FETCH_IVR_LANGUAGE_CHOICES_FAILED:
            return { ...state, loadingGreetingWithLangChoice: false };
        case Actions.FETCH_IVR_LANG_GREETING_STARTED:
            return { ...state, loadingGreetingWithLangChoice: true };
        case Actions.ADD_IVR_LANGUAGE:
            return addNewIvrLang(state);
        case Actions.UPDATE_IVR_LANGUAGE_VOICE:
            return updateVoiceChange(state, payload);
        case Actions.UPDATE_IVR_LANGUAGE_CHOICE:
            return updateLangChange(state, payload);
        case Actions.REMOVE_IVR_LANGUAGE_CHOICE_SUCCESS:
            return removeIvrLangChoice(state, payload);
        case Actions.REMOVE_IVR_LANGUAGE_CHOICE_STARTED:
            return removeIvrLangChoiceProcessing(state, payload, true);
        case Actions.REMOVE_IVR_LANGUAGE_CHOICE_FAILED:
            return removeIvrLangChoiceProcessing(state, payload, false);
        case Actions.UPDATE_VALIDATION_MSG:
            return { ...state, langChoices: payload.langChoices };
        case Actions.SAVE_IVR_LANGUAGE_CHOICES_STARTED:
            return { ...state, savingInProgress: true };
        case Actions.SAVE_IVR_LANGUAGE_CHOICES_SUCCESS:
        case Actions.SAVE_IVR_LANGUAGE_CHOICES_FAILED:
            return { ...state, savingInProgress: false };
        case Actions.ADD_LANGUAGE:
            return addLang(state, payload);
        case Actions.REMOVE_LANGUAGE:
            return removeLang(state, payload);
        case Actions.SAVE_LANGUAGES_STARTED:
            return { ...state, savingLang: true };
        case Actions.SAVE_LANGUAGES_SUCCESS:
        case Actions.SAVE_LANGUAGES_FAILED:
            return { ...state, savingLang: false };
        case Actions.REMOVE_LANGUAGES_STARTED:
            return { ...state, removingLang: true };
        case Actions.REMOVE_LANGUAGES_SUCCESS:
        case Actions.REMOVE_LANGUAGES_FAILED:
            return { ...state, removingLang: false };
        case Actions.FETCH_IVR_TTS_STARTED:
            return { ...state, ttsEnginesLoading: true };
        case Actions.FETCH_IVR_TTS_SUCCESS:
            return { ...state, ttsEngines: payload, ttsEnginesLoading: false };
        case Actions.FETCH_IVR_TTS_FAILED:
            return { ...state, ttsEnginesLoading: false };
        default:
            return state;
    }
}
