import './CanMessagePopover.sass';
import React from 'react';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import FilteringOptionGroup from './FilteringOptionGroup';

export default function CanMessagePopover({ value, onChange }) {
    return (
        <div className="filter-popover can-message-popover">
            <FilteringOptionGroup
                value={value}
                onChange={onChange}
                options={[
                    { value: 'yes', icon: <MobileFriendlyIcon />, label: CanMessagePopover.OPTION_LABELS['yes'] },
                    { value: 'no', icon: <MobileOffIcon />, label: CanMessagePopover.OPTION_LABELS['no'] },
                    {
                        value: 'unknown',
                        icon: <DeviceUnknownIcon />,
                        label: CanMessagePopover.OPTION_LABELS['unknown'],
                    },
                ]}
            />
        </div>
    );
}
CanMessagePopover.OPTION_LABELS = {
    yes: 'Can receive messages',
    no: 'Can not receive messages',
    unknown: 'Unknown if messageable',
};
