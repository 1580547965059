import React from 'react';
import { Grid, Cell } from 'react-md';

const CustomTab = (props) => {
    const { activeTab, onTabClick, data } = props;
    return (
        <Grid noSpacing className="customTab">
            {data.map((t, i) => (
                <Cell key={t} className={`btn ${i === activeTab && 'active'}`} onClick={() => onTabClick(i)}>
                    {t}
                </Cell>
            ))}
        </Grid>
    );
};

export default CustomTab;
