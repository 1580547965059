import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { onboardingTypes } from './type';
import { onboardingService } from './service';
import { onboardingSelectors } from './selector';
import config from '../../../config';
import Snowplow, { EventRefContext } from 'snowplow';

const resetInboxLocationOnboarding = () => {
    return { type: onboardingTypes.RESET_INBOX_LOCATION_ONBOARDING };
};

const getInboxLocationOnboarding = ({ locationChanged } = {}) => {
    return (dispatch, getState) => {
        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;
        const currentState = getState();

        if (!locationChanged && !onboardingSelectors.locationOnboardingIsPendingSelector(currentState)) {
            // if this is not an initial request due to a location change
            // and onboarding is not in a pending state
            // short circuit to avoid unnecessary api calls
            return Promise.resolve();
        }

        if (locationChanged) {
            dispatch(onboardingActions.resetInboxLocationOnboarding());
        }

        dispatch({ type: onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_REQUEST });

        return onboardingService
            .getInboxLocationOnboarding({ pharmacyId, locationId })
            .then((onboardingData) => {
                dispatch({ type: onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_SUCCESS, payload: onboardingData });
                dispatch(onboardingActions.updateInboxLocationOnboardingClientSteps());
            })
            .catch((error) => {
                dispatch({ type: onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_FAILURE, payload: { error } });
            });
    };
};

const updateInboxLocationOnboardingServerSteps = ({ new_patient_count = 0 }) => {
    return (dispatch, getState) => {
        const currentState = getState();
        if (!onboardingSelectors.locationOnboardingIsPendingSelector(currentState)) {
            // onboarding is not pending do not proceed
            return Promise.resolve();
        }

        dispatch({
            type: onboardingTypes.UPDATE_INBOX_LOCATION_ONBOARDING_SERVER_STEPS,
            payload: {
                new_patient_count,
            },
        });
    };
};

const updateInboxLocationOnboardingClientSteps = () => {
    // if the value is not equal to default the user has chosen to either grant or deny push
    // selecting a preference satisfies the onboarding status, we do not want to nag if they denied permissions (for now)
    // if Notification.permission is falsey, this means Notifications are not available at all
    let permissionValue =
        _.get(window, 'Notification.permission', null) && window.Notification.permission !== 'default';

    // do not show notification step for mobile devices
    if (isMobile) {
        permissionValue = null;
    }

    return {
        type: onboardingTypes.UPDATE_INBOX_LOCATION_ONBOARDING_CLIENT_STEPS,
        payload: { notificationPermission: permissionValue },
    };
};

const trackInboxLocationOnboardingAction = ({ action, value = 'clicked' }) => {
    return (dispatch, getState) => {
        const { location_event_ref, location_parent_event_ref } = getState().inboxOnboarding;
        Snowplow.structEvent(
            'Inbox',
            `onboarding-${_.kebabCase(action)}`,
            [
                new EventRefContext()
                    .setEventRef(location_event_ref)
                    .setParentEventRef(location_parent_event_ref)
                    .build(),
            ],
            null,
            value
        );
    };
};

export const onboardingActions = {
    resetInboxLocationOnboarding,
    getInboxLocationOnboarding,
    updateInboxLocationOnboardingClientSteps,
    updateInboxLocationOnboardingServerSteps,
    trackInboxLocationOnboardingAction,
};
