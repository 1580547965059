import React from 'react';

import EmbeddedContent from '../EmbeddedContent';
import CourseContentUrls from 'constants/CourseContentUrls';

// See https://developers.google.com/youtube/iframe_api_reference for details of
// the implementation
const YoutubeVideo = ({ video }) => (
    <EmbeddedContent id={`video-player-${video.id}`} src={`${CourseContentUrls.prefixes.youtube}${video.id}`} />
);

export default YoutubeVideo;
