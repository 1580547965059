import AudioContext from './AudioContext';
import convertWav from './audioConvertWav';

let analyser;
let audioCtx;
let mediaRecorder;
let chunks = [];
let startTime;
let stream;
let mediaOptions;
let blobObject;
let onStartCallback;
let onStopCallback;
let onDataCallback;

const constraints = { audio: true, video: false }; // constraints - only audio needed

navigator.getUserMedia =
    navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

export class MicrophoneRecorder {
    constructor(onStart, onStop, onData, options) {
        onStartCallback = onStart;
        onStopCallback = onStop;
        mediaOptions = options;
        onDataCallback = onData;
    }

    audioWav = () => {
        let fr = new FileReader();
        let wavBlob = null;
        fr.onload = (e) => {
            audioCtx.decodeAudioData(e.target.result).then((sourceAudioBuffer) => {
                var offlineCtx = new OfflineAudioContext({
                    numberOfChannels: 1,
                    length: 8000 * sourceAudioBuffer.duration,
                    sampleRate: 8000,
                });
                var soundSource = offlineCtx.createBufferSource();
                soundSource.buffer = sourceAudioBuffer;
                soundSource.connect(offlineCtx.destination);

                offlineCtx.oncomplete = function (e) {
                    wavBlob = new Blob([new DataView(convertWav(e.renderedBuffer))], {
                        type: 'audio/wav',
                    });
                    const audioBlobObject = {
                        blob: wavBlob,
                        startTime: startTime,
                        stopTime: Date.now(),
                        options: mediaOptions,
                        blobURL: window.URL.createObjectURL(blobObject),
                    };
                    if (onStopCallback) {
                        onStopCallback(audioBlobObject);
                    }
                };
                offlineCtx.startRendering();
                soundSource.start(0);
            });
        };
        fr.readAsArrayBuffer(blobObject);
    };

    startRecording = () => {
        startTime = Date.now();

        if (navigator.mediaDevices) {
            console.log('getUserMedia supported.');

            navigator.mediaDevices.getUserMedia(constraints).then((str) => {
                stream = str;

                if (MediaRecorder.isTypeSupported(mediaOptions.mimeType)) {
                    mediaRecorder = new MediaRecorder(str, mediaOptions);
                } else {
                    mediaRecorder = new MediaRecorder(str);
                }

                if (onStartCallback) {
                    onStartCallback();
                }

                mediaRecorder.onstop = this.onStop;
                mediaRecorder.ondataavailable = (event) => {
                    chunks.push(event.data);
                    if (onDataCallback) {
                        onDataCallback(event.data);
                    }
                };

                audioCtx = AudioContext.getAudioContext();
                analyser = AudioContext.getAnalyser();
                mediaRecorder.start(10);

                const source = audioCtx.createMediaStreamSource(stream);
                source.connect(analyser);
            });
        } else {
            alert('Your browser does not support audio recording');
        }
    };

    stopRecording = () => {
        if (mediaRecorder) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
            mediaRecorder.stop();
            audioCtx.close();
            mediaRecorder = null;
        }
    };

    onStop = () => {
        blobObject = new Blob(chunks, { type: mediaOptions.mimeType });
        chunks = [];

        if (mediaOptions.mimeType.indexOf('wav') >= 0) {
            this.audioWav();
        } else {
            const audioBlobObject = {
                blob: blobObject,
                startTime: startTime,
                stopTime: Date.now(),
                options: mediaOptions,
                blobURL: window.URL.createObjectURL(blobObject),
            };
            if (onStopCallback) {
                onStopCallback(audioBlobObject);
            }
        }
    };
}
