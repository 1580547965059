import { Grid, Divider } from '@material-ui/core';

import { ChecklistWidget, MessageWidget, VoicemailWidget, BannerWidget } from './Widgets';
import StatsWidgetContainer from './StatsWidgetContainer';

import React from 'react';
import 'styles/components/_homepage.scss';

export const HomePage = () => (
    <div className="homepage-container">
        <Grid container>
            <Grid container item xs={5} className="centered-content">
                <Grid xs={10}>
                    <MessageWidget />
                </Grid>
                <Grid xs={10}>
                    <ChecklistWidget />
                </Grid>
                <Grid xs={10}>
                    <VoicemailWidget />
                </Grid>
            </Grid>
            <div className="divider-container">
                <Divider orientation="vertical" />
            </div>
            <Grid container item xs={6} className="centered-content">
                <BannerWidget />
                <StatsWidgetContainer />
            </Grid>
        </Grid>
    </div>
);
