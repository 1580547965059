import socketType from './type';

export const initialState = {
    connected: false,
    rooms: [],
    message: {},
    error: {},
};

export const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case socketType.SOCKET_CONNECTED:
            return {
                ...state,
                connected: true,
            };
        case socketType.SOCKET_DISCONNECTED:
            return {
                ...state,
                connected: false,
            };
        case socketType.SOCKET_JOIN_ROOM:
            return {
                ...state,
            };
        case socketType.SOCKET_LEAVE_ROOM:
            return {
                ...state,
            };
        case socketType.SOCKET_RECEIVE_MESSAGE:
            return {
                ...state,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default socketReducer;
