import axios from 'axios';
import { logout } from 'redux/actionCreators/Auth';
import { LOG_OUT_ON_BACKEND_INACTIVITY } from 'constants/Logout';

const cancelToken = axios.CancelToken;

export default {
    setupInterceptors: (store) => {
        // Add a request interceptor
        axios.interceptors.request.use((config) => {
            config.headers['X-Pharmacy-Id'] = '123'; //dummy val for IE fix
            return config;
        });
        // Add a response interceptor
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                // Login failure response is also 403 but we want to persist the message to client
                // Thus we prevent logout method from being called for login specifically
                // All other 403 mean session has ended
                if (error.response && error.response.status === 403 && !error.response.config.url.includes('/login')) {
                    store.dispatch(logout(LOG_OUT_ON_BACKEND_INACTIVITY));
                }
                return Promise.reject(error);
            }
        );
    },
    cancelToken,
    isCancel: axios.isCancel,
};
