import axios from 'axios';
import { get, post, put } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logHttpError } from '../../../utils/log';

const activateTemplate = (templateData) => {
    return put(
        `${config.apiweb_url}${api.INBOX_BASE}/template/${templateData.inbox_conversation_template_id}/activate`,
        templateData
    )
        .then((response) => {
            if (response.success) {
                return true;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on activateTemplate for pharmacyId: ${templateData.pharmacyId} on locationId: ${templateData.locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const deactivateTemplate = (templateData) => {
    return put(
        `${config.apiweb_url}${api.INBOX_BASE}/template/${templateData.inbox_conversation_template_id}/deactivate`,
        templateData
    )
        .then((response) => {
            if (response.success) {
                return true;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on deactivateTemplate for pharmacyId: ${templateData.pharmacyId} on locationId: ${templateData.locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getTemplates = (pharmacyId, locationId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/templates/${pharmacyId}/${locationId}`)
        .then((response) => {
            if (response.success) {
                return response.templates;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on getTemplates for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const createTemplate = (templateData) => {
    return post(`${config.apiweb_url}${api.INBOX_BASE}/template`, templateData)
        .then((response) => {
            if (response.success) {
                return true;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError('An error occured on createTemplate', error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const deleteTemplate = (templateData) => {
    return axios
        .delete(`${config.apiweb_url}${api.INBOX_BASE}/template/${templateData.inbox_conversation_template_id}`, {
            data: templateData,
        })
        .then((response) => {
            if (response.data && response.data.success) {
                return true;
            } else if (response.data && response.data.message) {
                return Promise.reject({
                    message: response.data.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError('An error occured on deleteTemplate', error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const updateTemplate = (templateData) => {
    return put(
        `${config.apiweb_url}${api.INBOX_BASE}/template/${templateData.inbox_conversation_template_id}`,
        templateData
    )
        .then((response) => {
            if (response.success) {
                return true;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on updateTemplate for templateId: ${templateData.inbox_conversation_template_id} at pharmacyId: ${templateData.pharmacy_id} and locationId: ${templateData.location_id}`,
                error
            );
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

export const templateService = {
    getTemplates,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    activateTemplate,
    deactivateTemplate,
};

export default templateService;
