import React from 'react';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';

import EmptySubscriber from './Subscriber/EmptySubscriber';
import Subscriber from './Subscriber/Subscriber';
import { CARD_MODE, NEWSLETTER_MODE, DRAWER_STATES } from 'constants/Newsletter';
import EmptyCalendar from './Calendar/EmptyCalendar';
import NewsletterCardPreview from '../Drawer/Calendar/NewsletterCardEditPreview';
import EditNewsletter from './Calendar/EditNewsletter';
import EmptyHistory from './History/EmptyHistory';
import NewsletterDetails from './History/NewsletterDetails';

const Drawer = (props) => {
    const { drawerState } = props;
    const drawerComponents = {
        [DRAWER_STATES.EMPTY_SUBSCRIBER]: <EmptySubscriber />,
        [DRAWER_STATES.ADD_SUBSCRIBER]: <Subscriber />,
        [DRAWER_STATES.EMPTY_CALENDAR]: <EmptyCalendar />,
        [DRAWER_STATES.ADD_CARD]: <NewsletterCardPreview mode={CARD_MODE.ADD} />,
        [DRAWER_STATES.EDIT_CARD]: <NewsletterCardPreview mode={CARD_MODE.EDIT} />,
        [DRAWER_STATES.EDIT_NEWSLETTER]: <EditNewsletter mode={NEWSLETTER_MODE.EDIT} />,
        [DRAWER_STATES.VIEW_NEWSLETTER]: <EditNewsletter mode={NEWSLETTER_MODE.VIEW} />,
        [DRAWER_STATES.EMPTY_HISTORY]: <EmptyHistory />,
        [DRAWER_STATES.NEWSLETTER_DETAILS]: <NewsletterDetails />,
    };

    return <Card className="drawer">{drawerComponents[drawerState]}</Card>;
};

const mapStateToProps = (state) => ({
    drawerState: state.newsletter.drawerState,
});

export default connect(mapStateToProps, null)(Drawer);
