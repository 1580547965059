import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { TextField, Button } from '@material-ui/core';
import { SettingTitle } from '../Common/SettingTitle';
import PropTypes from 'prop-types';
import goBackService from 'utils/goBackService';
import LabeledSwitch from './LabeledSwitch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bannerConfigActions } from 'redux/BannerConfiguration/banner-config-actions';
import { bannerConfigSelectors } from 'redux/BannerConfiguration/banner-config-selector';

const BannerConfigurationPropTypes = {
    pharmacy: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const commonProps = {
    variant: 'outlined',
    autoComplete: 'off',
    fullWidth: true,
    disabled: false,
};

const BannerConfiguration = (props) => {
    const { config, pharmacyLoading, saveBannerConfigurationSettings, savingBannerConfigSettings } = props;
    const onBack = () => {
        const { history } = props;
        goBackService.toGeneralHome(history);
    };

    // // Variable naming to match attributes in C2
    const [state, setState] = useState({
        HealthsiteBannerEnabled: false,
        HealthsiteBannerTitle: '',
        HealthsiteBannerCTA: '',
    });

    useEffect(() => {
        setState(config);
    }, [config]);

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const { HealthsiteBannerEnabled, HealthsiteBannerTitle, HealthsiteBannerCTA } = state;

    const onSave = async () => {
        saveBannerConfigurationSettings(state);
    };

    const formInvalid = !(HealthsiteBannerTitle.length && HealthsiteBannerCTA.length);
    const isLoading = pharmacyLoading || savingBannerConfigSettings;

    const isSaveDisabled = _.isEqual(config, state) || formInvalid || isLoading;

    return (
        <Grid className="settings-content--grid-container">
            <SettingTitle
                title="Website Banner Configuration"
                subtitle="Allows the user to configure the custom website banner"
                onBack={onBack}
            />
            <Card variant="outlined" className="bordered-box settings-content--card">
                <div className="grid-container newsletter-mobile-grid">
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <LabeledSwitch
                                title="Enable Custom Banner"
                                description="If enabled, the default coronavirus banner is replaced by a custom banner
                                            that links to the pharmacy's appointment scheduling form."
                                switchValue={HealthsiteBannerEnabled}
                                onChange={() =>
                                    setState({
                                        ...state,
                                        HealthsiteBannerEnabled: !HealthsiteBannerEnabled,
                                    })
                                }
                            ></LabeledSwitch>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="HealthsiteBannerTitle"
                                label="Custom Title *"
                                value={HealthsiteBannerTitle}
                                onChange={onChange}
                                {...commonProps}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="HealthsiteBannerCTA"
                                label="Custom Call-To-Action Text *"
                                value={HealthsiteBannerCTA}
                                onChange={onChange}
                                {...commonProps}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="position-relative">
                                <Button
                                    id="save"
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={onSave}
                                    disabled={isSaveDisabled}
                                    fullWidth
                                >
                                    Save
                                </Button>
                                {isLoading && <CircularProgress size={24} className="circular-icon-within-button" />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </Grid>
    );
};

BannerConfiguration.propTypes = BannerConfigurationPropTypes;

const mapStateToProps = (state) => ({
    config: bannerConfigSelectors.getBannerConfig(state),
    pharmacyLoading: state.pharmacy.loading,
    savingBannerConfigSettings: state.bannerConfig.savingBannerConfigSettings,
});

const mapDispatchToProps = {
    saveBannerConfigurationSettings: bannerConfigActions.saveBannerConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerConfiguration);
