import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DRAWER_STATES } from 'constants/Newsletter';
import { newsletterActions } from 'redux/Newsletter/action';
import { isEmptyString, validateEmail } from 'utils/helper';
import Snowplow, { StructEventCategories } from 'snowplow';

const Subscriber = (props) => {
    const { newsletter } = props;
    const { savingSubscriber } = newsletter;
    const [validationError, setValidationError] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const { newsletter } = props;
        const { activeSubscriber } = newsletter;
        if (activeSubscriber) {
            setEmail(activeSubscriber.email);
        }
    }, [_.get(newsletter, 'activeSubscriber.email')]);

    const handleInputChange = (event) => {
        setEmail(event.target.value);
        setValidationError('');
    };

    const onCancelClick = () => {
        const { setDrawerState } = props;
        setDrawerState(DRAWER_STATES.EMPTY_SUBSCRIBER);
    };

    const validateEmailId = (email) => {
        if (!validateEmail(email)) {
            return 'Please enter a valid email id';
        }

        return false;
    };

    const getValidationError = () => {
        const emptyMessage = 'This field is required';

        return isEmptyString(email) ? emptyMessage : validateEmailId(email);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { saveSubscriber } = props;
        const validationError = getValidationError();

        if (Boolean(validationError)) {
            setValidationError(validationError);
            Snowplow.structEvent(
                StructEventCategories.newsletters,
                'error-saving-subscriber',
                null,
                'error',
                'exception',
                validationError
            );
        } else {
            const payload = {
                recipients: [
                    {
                        email,
                    },
                ],
            };
            saveSubscriber(payload)
                .then((response) => {
                    if (response.success) {
                        setEmail('');
                    }
                })
                .then(() => Snowplow.structEvent(StructEventCategories.newsletters, 'saved-subscriber'));
        }
    };

    // const deleteSubscriber = (subscriber) => {
    //     const { deleteSubscriber } = props;
    //     deleteSubscriber(subscriber);
    // };

    return (
        <div className="add-edit-subscriber">
            <Typography className="title"> Add Subscriber </Typography>
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <Grid xs={12} item container className="content" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label="Email"
                            value={email}
                            variant="outlined"
                            fullWidth
                            onChange={handleInputChange}
                            error={!!validationError}
                            helperText={validationError}
                            autoComplete="off"
                            required
                        />
                    </Grid>
                </Grid>
                <Grid spacing={2} container item xs={12} justify="flex-end" className="btn-container">
                    <Button variant="text" onClick={onCancelClick} color="primary" style={{ marginRight: '15px' }}>
                        Cancel
                    </Button>
                    <div className="position-relative">
                        <Button variant="contained" color="primary" type="submit" disabled={savingSubscriber}>
                            Save changes
                        </Button>
                        {savingSubscriber && <CircularProgress size={24} className="circular-icon-within-button" />}
                    </div>
                </Grid>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletter: state.newsletter,
});

const mapDispatchToProps = {
    setDrawerState: newsletterActions.setDrawerState,
    saveSubscriber: newsletterActions.saveSubscriber,
    deleteSubscriber: newsletterActions.deleteSubscriber,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);
