import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';

export const Snackbar = (props) => {
    useEffect(() => {
        const { message, type, enqueueSnackbar } = props;
        // Make info as black, default is black in notistack.
        const variant = type === 'info' ? 'default' : type;

        if (!_.isEmpty(message)) {
            enqueueSnackbar(message, {
                variant,
                preventDuplicate: true,
            });
        }
    }, [props.key]);

    return <div id="app-snackbar" />;
};

const mapStateToProps = ({ snackbar }) => ({
    ...snackbar,
});

export default connect(mapStateToProps, null)(withSnackbar(Snackbar));
