import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import { caseInsensitiveIncludes as includes, convertDateTimeToDate, getFullName } from './helper';
import { ALL_LOCATIONS, ALL_USERS, INTERNAL_USERS, PHARMACY_USERS } from 'constants/Common';
import { constants } from './constants';
import { userRoles } from 'casl';
import Config2Constants from '../../constants/Config2';

const getSortBy = (state) => state.userManagement.sortBy;
const getPageLength = (state) => state.userManagement.pageLength;
const getSearchString = (state) => state.userManagement.searchString;
const getReduxStoreUsers = (state) => state.userManagement.users;
const isInternalUserLoggedIn = (state) => state.auth.isInternalUser;
const getSelectedLocalLocation = (state) => state.userManagement.selectedLocalLocation;
const getSelectedUserType = (state) => state.userManagement.selectedUserType;
const getUserDetails = (state) => state.auth.userDetails;
const getPharmacyDetails = (state) => state.pharmacy.pharmacy;

const getUsers = createSelector([getReduxStoreUsers], (users) =>
    _.map(users, (user) => ({
        ...user,
        name: getFullName(user),
        lastActive: convertDateTimeToDate(user.last_login),
    }))
);

const getDomainFilteredUsers = createSelector([getUsers, isInternalUserLoggedIn], (users, isInternalUserLoggedIn) => {
    const domainsToFilter = [
        'dp.com',
        'rxwiki.com',
        'digitalpharmacist.com',
        // some emails are soft deleted the notion used is to suffix the email with .deleted
        'deleted',
    ];
    const emailEndsWithListedDomain = (email) => _.some(domainsToFilter, (domain) => _.endsWith(email, domain));
    return isInternalUserLoggedIn ? users : _.filter(users, (user) => !emailEndsWithListedDomain(user.email));
});

const getFilteredUsers = createSelector(
    [getDomainFilteredUsers, getSearchString, getSelectedLocalLocation, getSelectedUserType],
    (users, searchString, selectedLocalLocation, selectedUserType) => {
        if (_.isEmpty(searchString) && selectedLocalLocation === ALL_LOCATIONS && selectedUserType === ALL_USERS) {
            return users;
        }
        return users.filter((user) => {
            let userMatchLocation = true;
            const userMatchSearchKey = includes(user.name, searchString) || includes(user.email, searchString);

            if (selectedLocalLocation !== ALL_LOCATIONS) {
                const found = user.roles.find(
                    (roleDetail) =>
                        roleDetail.entityId === selectedLocalLocation && roleDetail.role !== userRoles.noAccess
                );
                userMatchLocation = Boolean(found);
            }

            const userMatchUserType =
                selectedUserType === ALL_USERS ||
                (selectedUserType === INTERNAL_USERS && Boolean(user.isInternal)) ||
                (selectedUserType === PHARMACY_USERS && !Boolean(user.isInternal));
            return userMatchLocation && userMatchUserType && userMatchSearchKey;
        });
    }
);

const getSortedUsers = createSelector([getFilteredUsers, getSortBy, getPageLength], (users, sortBy, pageLength) => {
    const key = _.camelCase(sortBy.column);
    const sortOrder = sortBy.ascendingOrder ? 'asc' : 'desc';
    const convertValue = (user) => {
        const value = user[key];
        if (key === 'lastActive') {
            // Product wants all the blank dates to be on top when sorting ascending
            return value === '—' ? new moment('1800-01-01') : moment(value, constants.dateFormat);
        }
        return _.toLower(value);
    };

    return _.chunk(_.orderBy(users, [convertValue], [sortOrder]), pageLength);
});

// Show all locations that a user has either 'owner' or 'IT support' access to
// unless user isInternal which allows them to see all locations
const getFilteredLocationMap = createSelector(
    [isInternalUserLoggedIn, getUserDetails, getPharmacyDetails],
    (isInternalUser, userDetails, pharmacyDetails) => {
        const { entityType } = Config2Constants;
        const availableLocations = {};

        if (pharmacyDetails.location) {
            const locationIds = [];
            userDetails.forEach((userLocationDetails) => {
                // TODO need to figure out how to do the following check using casl
                if (
                    userLocationDetails.entityType === entityType.location &&
                    _.includes([userRoles.owner, userRoles.itSupport], userLocationDetails.role)
                ) {
                    locationIds.push(userLocationDetails.entityId);
                }
            });
            pharmacyDetails.location.forEach((locationDetails) => {
                // Show all locations for internal users
                // Otherwise restrict locations to those the user has access to
                if (_.includes(locationIds, locationDetails.id) || isInternalUser) {
                    availableLocations[locationDetails.id] = locationDetails;
                }
            });
        }

        return availableLocations;
    }
);

export const userManagementSelectors = {
    getFilteredUsers,
    getSortedUsers,
    getFilteredLocationMap,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
