import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Title from './Title';
import Description from './Description';
import AvailableClasses from './AvailableClasses';

const styles = {
    description: {
        fontSize: '15px',
    },
    unorderedList: {
        fontSize: '15px',
        fontWeight: '500',
    },
};

const ClassesDescription = ({ classes }) => (
    <Fragment>
        <Title />
        <Description className={classes.description} />
        <AvailableClasses className={classes.unorderedList} />
    </Fragment>
);

export default withStyles(styles)(ClassesDescription);
