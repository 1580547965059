import { userManagementTypes } from './type';
import _ from 'lodash';

import { ALL_LOCATIONS, PHARMACY_USERS } from 'constants/Common';

export const initialState = {
    isLoading: false,
    users: [],
    totalUsers: 0,
    page: 0,
    pageLength: 25,
    searchString: '',
    selectedUserType: PHARMACY_USERS,
    sortBy: {
        column: 'Last Active',
        ascendingOrder: false,
    },
    selectedLocalLocation: ALL_LOCATIONS,
    userRolesLoading: false,
    userRoles: [],
    addOrEditUserLoading: false,
};

export const userManagementReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case userManagementTypes.RESET_USER_MANAGEMENT_STORE:
            return {
                ...initialState,
            };
        case userManagementTypes.GET_USERS_REQUEST:
            return { ...state, isLoading: true };
        case userManagementTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case userManagementTypes.GET_USERS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case userManagementTypes.PAGINATE:
            return {
                ...state,
                page: payload.page,
            };
        case userManagementTypes.SEARCH:
            return {
                ...state,
                page: 0,
                searchString: payload.searchString,
            };
        case userManagementTypes.CLEAR_SEARCH:
            return {
                ...state,
                page: 0,
                searchString: '',
            };
        case userManagementTypes.SORT_USERS:
            return {
                ...state,
                page: 0,
                sortBy: payload.sortBy,
            };
        case userManagementTypes.SET_LOCAL_LOCATION:
            return {
                ...state,
                selectedLocalLocation: payload.selectedLocalLocation,
            };
        case userManagementTypes.SET_USER_TYPE:
            return {
                ...state,
                selectedUserType: payload.selectedUserType,
            };
        case userManagementTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: _.filter(state.users, (user) => user.user_api_user_id !== payload.user_id),
            };
        case userManagementTypes.GET_USER_ROLES_REQUEST:
            return {
                ...state,
                userRolesLoading: true,
            };
        case userManagementTypes.GET_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRoles: payload.userRoles,
                userRolesLoading: false,
            };
        case userManagementTypes.GET_USER_ROLES_FAILED:
            return {
                ...state,
                userRolesLoading: false,
            };
        case userManagementTypes.ADD_OR_EDIT_USER_REQUEST:
            return {
                ...state,
                addOrEditUserLoading: true,
            };
        case userManagementTypes.ADD_OR_EDIT_USER_SUCCESS:
            return {
                ...state,
                addOrEditUserLoading: false,
            };
        case userManagementTypes.ADD_OR_EDIT_USER_FAILED:
            return {
                ...state,
                addOrEditUserLoading: false,
            };
        default:
            return state;
    }
};
