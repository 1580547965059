import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { withRouter } from 'react-router-dom';

export const ListItem = (props) => {
    const { action, subActions = [], navigationLink, isLoading, history } = props;

    const navigate = () => {
        history.push({ pathname: navigationLink });
    };

    return (
        <Grid container className="list-item-container">
            <Grid xs={1} style={{ position: 'relative' }}>
                {/* TODO: Need an endpoint to support checked functionality. Need to tackle this in future. */}
                <Radio
                    checked={false}
                    color="default"
                    name="checklist-radio"
                    disableRipple={true}
                    className="radio-btn"
                    disabled={true}
                />
            </Grid>
            <Grid xs={10}>
                {isLoading ? (
                    <div className="centered-content">
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    <Fragment>
                        <Typography className="action"> {action} </Typography>
                        {subActions.map((subAction) => (
                            <Typography>
                                <span> - </span>
                                {subAction}
                            </Typography>
                        ))}
                    </Fragment>
                )}
            </Grid>
            <Grid xs={1}>
                <ArrowForwardIcon className="user-clickable" onClick={navigate} />
            </Grid>
        </Grid>
    );
};

export default withRouter(ListItem);
