import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Zoom from '@material-ui/core/Zoom';
import { AbilityContext, userActions, restrictedResources } from 'casl';

const ContactMobileHeader = ({
    fullName,
    onBack,
    newConversationDisabled,
    toggleNewConversationDialog,
    toggleEditContactDialog,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'patient-popover' : null;
    const ability = useContext(AbilityContext);
    const { start, update } = userActions;
    const { patient, newConversation } = restrictedResources.inbox;
    const canStartNewConversation = ability.can(start, newConversation.subject);
    const canUpdatePatientDetails = ability.can(update, patient.subject);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const patientDetailsClick = () => {
        handleClose();
        canUpdatePatientDetails && toggleEditContactDialog();
    };

    const newConversationClick = () => {
        handleClose();
        canStartNewConversation && toggleNewConversationDialog();
    };

    const showActions = canUpdatePatientDetails && canStartNewConversation;
    return (
        <div className="flex-middle contact-mobile-header">
            <IconButton onClick={onBack}>
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" color="inherit" style={{ fontSize: '24px', color: '#4a4a4a' }}>
                {fullName}
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
                {showActions && (
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                )}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    style={{ width: '100%' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    TransitionComponent={Zoom}
                >
                    <MenuItem onClick={patientDetailsClick}>Patient Details</MenuItem>
                    {!newConversationDisabled && <MenuItem onClick={newConversationClick}>New Conversation</MenuItem>}
                </Popover>
            </div>
        </div>
    );
};

export default ContactMobileHeader;
