import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Config from 'config';

const styles = {
    message: {
        display: 'flex',
        alignItems: 'center',
    },
};

const MySnackbarContentWrapper = (props) => {
    const classes = styles;
    const { className, children, variant } = props;

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            style={{ backgroundColor: 'rgb(255,209,200)', boxShadow: 'none', maxWidth: '100%', marginBottom: '10px' }}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message} style={{ color: '#808080' }}>
                    {children}
                </span>
            }
        />
    );
};

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    variant: PropTypes.oneOf(['error']).isRequired,
};

const styles2 = {
    margin: {
        margin: '10px',
    },
};

const OptOutSnackbar = () => {
    const classes = styles2;

    return (
        <div>
            <MySnackbarContentWrapper variant="error" className={classes.root}>
                This user has opted out of messaging and will not receive any future PEP messages. To opt back in, this
                user must text <strong>UNSTOP</strong> to {Config.hftn_phone_number}
            </MySnackbarContentWrapper>
        </div>
    );
};

export default OptOutSnackbar;
