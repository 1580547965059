import './AssessedAttributesDropdownContent.sass';
import React, { useState, useMemo, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, RadioGroup, FormControlLabel, Radio, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { sortAvailableAssessedAttributesAlphabetically } from '../assessmentUtil';
import { updateQueryParameters } from '../../../utils/urlHelper';
import numeral from 'numeral';
import pluralize from 'pluralize';

export default function AssessedAttributesDropdownContent({
    assessment,
    queryParams,
    attributeIndex,
    location,
    history,
}) {
    const attributes = useMemo(() => getAttributesFromQueryParams(queryParams), [queryParams.attributes]);

    const [chosenAttribute, setChosenAttribute] = useState(
        getInitialChosenAttribute(attributes, attributeIndex, assessment)
    );
    const [chosenValue, setChosenValue] = useState(_.get(attributes, `[${attributeIndex}].value`, null));

    const sorted = useMemo(
        () =>
            sortAvailableAssessedAttributesAlphabetically(_.get(assessment, 'availableAssessedAttributes.attributes')),
        [_.get(assessment, 'availableAssessedAttributes.attributes')]
    );

    useEffect(() => {
        if (!_.isNil(chosenAttribute) && !_.isNil(chosenValue)) {
            let param = _.map(attributes, (attr, i) => {
                if (i === attributeIndex) {
                    return `${chosenAttribute.id}|${chosenValue}`;
                }
                return `${attr.attribute}|${attr.value}`;
            }).join(',');

            if (attributeIndex === _.size(attributes)) {
                param += `,${chosenAttribute.id}|${chosenValue}`;
            }

            history.push(`${location.pathname}${updateQueryParameters(location, 'attributes', param)}`);
        }
    }, [chosenAttribute, chosenValue]);

    // clear our internal state when the query attribute is cleared
    useEffect(() => {
        const { attribute, value } = _.get(attributes, `[${attributeIndex}]`, {});
        if (_.isNil(attribute) && _.isNil(value)) {
            setChosenAttribute(null);
            setChosenValue(null);
        }
    }, [queryParams.attributes]);

    return (
        <div className="assessed-attributes-dropdown-content">
            <div className="explanation">
                Filter your patients based on assessed attributes that result from completed patient assessments.
            </div>

            <Autocomplete
                className="assessed-attribute-autocomplete"
                options={sorted}
                value={_.isEmpty(chosenAttribute) ? null : _.find(sorted, ({ id }) => id === chosenAttribute.id)}
                getOptionLabel={({ label }) => label}
                renderInput={(params) => (
                    <TextField {...params} label="Assessed attribute to filter" variant="outlined" />
                )}
                onChange={(e, value) => {
                    setChosenAttribute(value);
                    setChosenValue(null);
                    if (_.isNil(value)) {
                        onAttributesClear(queryParams, attributeIndex, history, location);
                    }
                }}
            />

            {_.isNil(chosenAttribute) ? null : (
                <div className="chosen-attribute-options">
                    <RadioGroup value={chosenValue} onChange={(e, value) => setChosenValue(value)}>
                        {_.map(_.sortBy(chosenAttribute.values), ({ value, count }) => {
                            return (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    control={<Radio color="primary" />}
                                    label={
                                        <div className="radio-label">
                                            <span className="value">{value}</span>
                                            <Tooltip
                                                title={`${numeral(count).format('0,0')} ${pluralize(
                                                    'patient',
                                                    count
                                                )} with this assessed value`}
                                            >
                                                <span className="count">{numeral(count).format('0,0')}</span>
                                            </Tooltip>
                                        </div>
                                    }
                                />
                            );
                        })}

                        <div className="existence-options">
                            <FormControlLabel
                                value={AssessedAttributesDropdownContent.EXISTENCE_FILTERS.exists}
                                control={<Radio color="primary" />}
                                label={<div className="radio-label existence">patients with this attribute</div>}
                            />

                            <FormControlLabel
                                value={AssessedAttributesDropdownContent.EXISTENCE_FILTERS.not_exists}
                                control={<Radio color="primary" />}
                                label={<div className="radio-label existence">patients without this attribute</div>}
                            />
                        </div>
                    </RadioGroup>
                </div>
            )}
        </div>
    );
}

AssessedAttributesDropdownContent.EXISTENCE_FILTERS = {
    exists: '__exists__',
    not_exists: '__not_exists__',
};

export function onAttributesClear(queryParams, attributeIndex, history, location) {
    if (_.isString(queryParams.attributes)) {
        const pieces = queryParams.attributes.split(',');
        pieces.splice(attributeIndex, 1);
        history.push(
            `${location.pathname}${updateQueryParameters(
                location,
                'attributes',
                _.isEmpty(pieces) ? null : pieces.join(',')
            )}`
        );
    }
}

export function getAttributesActiveLabel(queryParams, attributeIndex, availableAssessedAttributes) {
    const { attribute, value } = _.get(getAttributesFromQueryParams(queryParams), `[${attributeIndex}]`, {});
    if (!_.isNil(attribute) && !_.isNil(availableAssessedAttributes[attribute])) {
        if (value === AssessedAttributesDropdownContent.EXISTENCE_FILTERS.exists) {
            return (
                <div className="attributes-active-label">
                    <span className="label">has</span> {availableAssessedAttributes[attribute].label}
                </div>
            );
        } else if (value === AssessedAttributesDropdownContent.EXISTENCE_FILTERS.not_exists) {
            return (
                <div className="attributes-active-label">
                    <span className="label">does not have</span> {availableAssessedAttributes[attribute].label}
                </div>
            );
        } else {
            return `${availableAssessedAttributes[attribute].label}: ${value}`;
        }
    }
}

export function getNumberOfAttributeFiltersToRender(queryParams) {
    if (!_.isString(queryParams.attributes)) {
        return 1;
    }
    return _.size(queryParams.attributes.split(','));
}

function getInitialChosenAttribute(attributes, attributeIndex, assessment) {
    const attributeKey = _.get(attributes, `[${attributeIndex}].attribute`);
    if (_.isNil(attributeKey)) {
        return {};
    }
    return {
        ..._.get(assessment, `availableAssessedAttributes.attributes.${attributeKey}`, {}),
        id: attributeKey,
    };
}

function getAttributesFromQueryParams(queryParams) {
    if (_.isNil(queryParams.attributes)) {
        return [{}];
    }

    return _.map(queryParams.attributes.split(','), (rowString) => {
        const split = rowString.split('|');

        if (_.size(split) === 2) {
            return {
                attribute: split[0],
                value: split[1],
            };
        }

        return {};
    });
}
