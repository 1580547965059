import React, { Component } from 'react';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import MessageInput from '../Message/MessageInput';
import MessageList from '../Message/MessageList';
import { formatUTCToRelative } from '../../../utils/formatTime';
import { stringToColor } from '../../../utils/helper';
import PatientAvatar from 'components/Patients/PatientAvatar';
import { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

class Conversation extends Component {
    static getContentPreview({ latestMessage = {} }) {
        if (latestMessage.content_type === 'attachment') {
            return `Attachment: ${latestMessage.content.file_name}`;
        }

        if (latestMessage.content_type === 'call_to_action') {
            return latestMessage.content.action_title;
        }

        if (_.isString(latestMessage.content)) {
            return latestMessage.content;
        }

        // handle unknown content format, or missing content
        return latestMessage.content ? 'n/a' : '';
    }

    toggleActiveConversation = () => {
        const conversationId = this.props.conversation.inbox_conversation_id;

        if (this.props.active) {
            this.props.clearActive();
        } else {
            this.props.setActive(conversationId);
        }
    };

    computeAvatarStyle = (fullName) => {
        return {
            width: '24px',
            height: '24px',
            fontSize: '.7rem',
            backgroundColor: stringToColor(`${fullName}`),
        };
    };

    render() {
        const { conversation, active, contact, activeLocationCountryCode, canMessage } = this.props;
        const latestMessage = conversation.latest_message;
        const unread = latestMessage.user_type === 'patient' && !latestMessage.viewed;
        const isPatient = latestMessage.inbox_user_id === contact.inbox_user_id;

        return (
            <div className={`contact-conversation-item ${active ? 'active' : ''} ${unread ? 'unread' : ''}`}>
                <div className="contact-conversation-item-header" onClick={this.toggleActiveConversation}>
                    <div className="contact-conversation-item-header__row">
                        <div className="contact-conversation-item-header__subject">{conversation.subject}</div>
                        <div className="contact-conversation-item-header__time">
                            <Typography variant="caption" noWrap className="contact-conversation-item__activity-time">
                                {conversation.activity_date &&
                                    formatUTCToRelative({
                                        inputTimeString: conversation.activity_date,
                                        countryCode: activeLocationCountryCode,
                                    })}
                            </Typography>
                        </div>
                    </div>
                    <div
                        className={`contact-conversation-item-header__row latest-message ${
                            isPatient ? 'patient-message' : 'pharmacist-message'
                        }`}
                    >
                        <div className="contact-conversation-item-header__column latest-message__avatar">
                            <Badge
                                color="secondary"
                                variant="dot"
                                invisible={!unread}
                                classes={{
                                    badge: 'inbox-conversation-avatar-badge',
                                }}
                            >
                                {isPatient ? (
                                    <PatientAvatar seed={getPatientAvatarSeedFromContact(contact)} />
                                ) : (
                                    <div className="pharmacy-icon">
                                        <LocalPharmacyIcon />
                                    </div>
                                )}
                            </Badge>
                        </div>
                        <div className="contact-conversation-item-header__column latest-message__content">
                            <Typography
                                variant="body2"
                                noWrap
                                className="contact-conversation-item-header__latest-message-content"
                            >
                                {Conversation.getContentPreview({ latestMessage })}
                            </Typography>
                        </div>
                    </div>
                </div>
                <Hidden smDown>
                    {active && (
                        <div className="contact-conversation-item-body">
                            <MessageList
                                contact={contact}
                                active={active}
                                activeLocationCountryCode={activeLocationCountryCode}
                            />
                            <MessageInput contact={contact} canMessage={canMessage} />
                        </div>
                    )}
                </Hidden>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    canMessage:
        pharmacySelectors.pharmacyHasFullPepEnabled(state) || pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
