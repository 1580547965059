import './CsvColumnSummary.sass';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { getColumnName, getLineCount } from './csvUtil';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { Tooltip } from '@material-ui/core';

export default function CsvColumnSummary({ fileData, fileColumns, colIndex, onClick, selected, mappedLabels }) {
    const { hasHeaders } = fileData;

    const lines = getLineCount(fileData);
    const rowsToShow = Math.min(5, hasHeaders ? lines - 1 : lines);

    const fileColumn = fileColumns[colIndex];

    const derivedColumn =
        fileColumn.sourceType === 'fileData' ? null : fileColumn.source.derivedColumns[fileColumn.sourceIndex];

    const getMainLineText = () => {
        switch (derivedColumn.type) {
            case 'concat':
                return 'Auto-merged data column';
            default:
                return 'Auto-generated data column';
        }
    };
    const getSubLineText = () => {
        switch (derivedColumn.deriviationType) {
            case 'area-xchg-nbr':
                return 'It was automatically generated by combining the previous 3 split phone number fields together into a new column.';
            default:
                return 'This column resulted from combining the contents of other columns';
        }
    };

    return (
        <div
            className={classnames({ 'csv-column-summary': true, selected, 'no-headers': !hasHeaders })}
            onClick={() => onClick(colIndex)}
        >
            <div className="header">
                <div className="column-label">
                    {fileColumn.sourceType === 'fileData' ? null : (
                        <Tooltip
                            title={
                                <div className="derived-column-tooltip-text">
                                    <div className="main-line">{getMainLineText()}</div>

                                    <div className="sub-line-1">This column was not present in the imported file.</div>

                                    <div className="sub-line-2">{getSubLineText()}</div>
                                </div>
                            }
                        >
                            <span className="icon-wrapper">
                                <NewReleasesIcon />
                            </span>
                        </Tooltip>
                    )}
                    {getColumnName(fileColumns, colIndex, fileData.hasHeaders)}
                </div>
                {_.isEmpty(mappedLabels) ? null : (
                    <div className="mapped-label">
                        <CheckCircleIcon /> <span>{mappedLabels.join(', ')}</span>
                    </div>
                )}
            </div>
            <div className="rows">
                {_.map(_.times(rowsToShow), (i) => {
                    let value = _.get(
                        fileColumn,
                        `source.data.[${i + (hasHeaders ? 1 : 0)}].[${fileColumn.sourceIndex}]`
                    );
                    if (_.isEmpty(value)) {
                        value = <span className="empty">(empty)</span>;
                    }
                    return (
                        <div key={i} className="row">
                            {value}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
