import React, { Fragment } from 'react';

const TextContent = ({ content: { title, content } }) => (
    <Fragment>
        <h5 style={{ fontWeight: 500 }}> {title} </h5>
        <p> {content} </p>
    </Fragment>
);

export default TextContent;
