const typePrefix = 'patient/assessment/';

const assessmentTypes = {
    AVAILABLE_ASSESSMENT_ATTRIBUTES_REQUEST: `${typePrefix}AVAILABLE_ASSESSMENT_ATTRIBUTES_REQUEST`,
    AVAILABLE_ASSESSMENT_ATTRIBUTES_SUCCESS: `${typePrefix}AVAILABLE_ASSESSMENT_ATTRIBUTES_SUCCESS`,
    AVAILABLE_ASSESSMENT_ATTRIBUTES_FAILURE: `${typePrefix}AVAILABLE_ASSESSMENT_ATTRIBUTES_FAILURE`,
    CLEAR_AVAILABLE_ASSESSED_ATTRIBUTES: `${typePrefix}CLEAR_AVAILABLE_ASSESSED_ATTRIBUTES`,

    GET_ASSESSMENT_TEMPLATE_REQUEST: `${typePrefix}GET_ASSESSMENT_TEMPLATE_REQUEST`,
    GET_ASSESSMENT_TEMPLATE_SUCCESS: `${typePrefix}GET_ASSESSMENT_TEMPLATE_SUCCESS`,
    GET_ASSESSMENT_TEMPLATE_FAILURE: `${typePrefix}GET_ASSESSMENT_TEMPLATE_FAILURE`,
};

export default assessmentTypes;
