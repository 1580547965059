import React, { useEffect, useRef } from 'react';
import './WorkflowSchedulingPage.sass';
import WorkflowTopBar from './WorkflowTopBar';
import _ from 'lodash';
import ClinicalSchedulingContent from './schedule/ClinicalSchedulingContent';
import AppointmentsContent from './AppointmentsContent';
import ConfigureContent from './ConfigureContent';
import WaitlistManageContent from './WaitlistManageContent';
import { connect } from 'react-redux';
import {
    fetchAvailableAppointmentCalendars,
    getAvailableCalendarAppointmentSlots,
    clearAvailableAppointmentSlots,
    getCalendarReservations,
    clearCalendarReservations,
    getCalendarAppointmentCounts,
} from 'redux/Appt/Calendar/action';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import {
    createNewReservationSession,
    updateReservationSession,
    getAvailableAppointmentSlots,
    chooseWaitlistPatientsByCount,
    checkinReservation,
    uncheckinReservation,
    updateReservation,
    getLiveReusableReservationSessions,
} from 'redux/Appt/Reservation/action';
import { waitlistActions } from 'redux/Waitlist/action';
import { Switch, Route } from 'react-router-dom';
import { loadPatientsByInboxUserIds } from 'redux/Patient/PatientData/action';
import { clearPatientOpportunities, sendBulkMessage } from 'redux/Patient/Connect/action';
import {
    getAppointmentTypesFromConversationTemplates,
    getNumberOfPatientsOnWaitlistsFromTemplate,
    isSchedulingWaitlistEnabled,
} from './schedulingUtil';

const WORKFLOW_PAGE_HEIGHT_HEADER_ETC_OFFSET = 180;

function WorkflowSchedulingPage({
    template,
    history,
    goBack,
    apptCalendar,
    fetchAvailableAppointmentCalendars,
    activeLocationCountryCode,
    fetchWaitlists,
    waitlist,
    connect,
}) {
    const props = arguments[0];

    const calendars = _.filter(
        _.get(apptCalendar, 'availableCalendars.calendars', []),
        ({ status }) => status === 'ACTIVE'
    );

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    useEffect(() => {
        if (_.isEmpty(waitlist.waitlists)) {
            fetchWaitlists();
        }
    }, []);

    const path = _.get(window.location.pathname.split('/'), '[3]');
    const height = Math.max(window.innerHeight - WORKFLOW_PAGE_HEIGHT_HEADER_ETC_OFFSET, 580);

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    // store a ref to the previous count so this doesn't flicker while we are loading...
    const waitlistCountRef = useRef(0);

    // get this from the stored previous value if opportunities are empty since this implies we are currently loading it
    const waitlistCount = _.isEmpty(connect.opportunities)
        ? waitlistCountRef.current
        : getNumberOfPatientsOnWaitlistsFromTemplate(template, connect.opportunities);

    if (!_.isEmpty(connect.opportunities)) {
        waitlistCountRef.current = waitlistCount;
    }

    if (_.isNil(template)) {
        return null;
    }

    return (
        <div className="workflow-scheduling-page">
            <WorkflowTopBar
                {...props}
                waitlistCount={waitlistCount}
                template={template}
                history={history}
                goBack={goBack}
                path={path}
            />

            <div className="workflow-content" style={{ height: `${height}px` }}>
                <Switch>
                    <Route
                        path="/workflows/:templateId/scheduling"
                        render={({ match }) => {
                            return (
                                <ClinicalSchedulingContent
                                    {...props}
                                    template={template}
                                    calendars={calendars}
                                    displayedDateFormat={displayedDateFormat}
                                    waitlistCount={waitlistCount}
                                    height={height}
                                />
                            );
                        }}
                    />
                    <Route
                        path="/workflows/:templateId/appointments"
                        render={({ match }) => {
                            return <AppointmentsContent {...props} template={template} height={height} />;
                        }}
                    />
                    <Route
                        path="/workflows/:templateId/customize"
                        render={({ match }) => {
                            return <ConfigureContent {...props} template={template} calendars={calendars} />;
                        }}
                    />

                    <Route
                        path="/workflows/:templateId/waitlist"
                        render={({ match }) => {
                            return <WaitlistManageContent {...props} template={template} />;
                        }}
                    />
                </Switch>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const {
        apptCalendar,
        apptReservation,
        inboxConversationTemplate,
        auth,
        pharmacy,
        waitlist,
        patientData,
        connect,
    } = state;
    return {
        apptCalendar,
        apptReservation,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        waitlist,
        appointmentTypes: getAppointmentTypesFromConversationTemplates(
            inboxConversationTemplate,
            _.get(apptCalendar, 'availableCalendars.calendars')
        ),
        patientData,
        connect,
        isCovidWaitlistEnabled: isSchedulingWaitlistEnabled(auth, pharmacy),
    };
}

const bindActionsToDispatch = {
    fetchAvailableAppointmentCalendars,
    createNewReservationSession,
    updateReservationSession,
    getAvailableAppointmentSlots,
    getAvailableCalendarAppointmentSlots,
    chooseWaitlistPatientsByCount,
    clearAvailableAppointmentSlots,
    loadPatientsByInboxUserIds,
    getCalendarReservations,
    fetchWaitlists: waitlistActions.fetchWaitlists,
    removeFromWaitlists: waitlistActions.removeFromWaitlists,
    updateWaitlistStatus: waitlistActions.updateWaitlistStatus,
    clearPatientOpportunities,
    updateReservation,
    checkinReservation,
    uncheckinReservation,
    clearCalendarReservations,
    sendBulkMessage,
    getCalendarAppointmentCounts,
    getLiveReusableReservationSessions,
};

export default connect(mapStateToProps, bindActionsToDispatch)(WorkflowSchedulingPage);
