import { formatRawDate, toDigits } from './helper';
import { searchType } from '../constants/Inbox';

export const determineSearchType = (searchString) => {
    return toDigits(searchString).length > 0 ? searchType.dateOfBirth : searchType.name;
};

export const formatSearchDate = (searchString) => {
    return formatRawDate(searchString, 'MM/DD/YYYY', 'YYYY-MM-DD');
};
