import './UpgradesSettingsPage.sass';
import React from 'react';
import FullPepUpgrade from './FullPepUpgrade/FullPepUpgrade';

export default function UpgradesSettingsPage() {
    return (
        <div className="upgrades-settings-page">
            <div className="upgrades-heading">
                <div className="main-line">Upgrades / Add-ons</div>

                <div className="sub-line">
                    Choose which upgrades and add-ons you would like to add to your products.
                </div>
            </div>

            <FullPepUpgrade />
        </div>
    );
}
