import React from 'react';
import moment from 'moment';

import { userPropType, logPropType, ENTITY_TYPE, determineLogMessage } from './helper';

function UserAuditLog({ user, log }) {
    if (log.entity_type === ENTITY_TYPE.grp) {
        // grp act as a wrapper for grp_mbr and aren't worth displaying on their own
        return null;
    }

    const logMoment = moment.utc(log.date);
    const logMessage = determineLogMessage(user, log);

    if (!logMessage) {
        console.error('Failed to create log message for', log);
        return null;
    }

    return (
        <div className="user-audit-log">
            <div className="user-audit-log__date">{logMoment.format('MM/DD/YYYY')}</div>
            <div className="user-audit-log__text">{logMessage}</div>
        </div>
    );
}

UserAuditLog.propTypes = {
    user: userPropType.isRequired,
    log: logPropType.isRequired,
};

export default UserAuditLog;
