import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
    TextField,
    Select,
    FormHelperText,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';
import { isEmptyString } from '../../../utils/helper';
import Snowplow from '../../../snowplow';
import { withRouter } from 'react-router-dom';
import MessageCTACard from '../Message/MessageCTACard';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { conversationAction } from 'redux/Inbox/Conversation';
import { templateAction } from 'redux/Inbox/Template';
import { getTemplateTitle } from 'components/Patients/patientsUtil';
import { preprocessMessageTemplateText } from 'components/Patients/messageTemplateUtil';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

const baseConversationState = {
    subject: '',
    message: '',
    errors: {},
    selectedTemplate: false,
};

class NewConversationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...baseConversationState,
        };

        this.commonProps = {
            fullWidth: true,
            variant: 'outlined',
            margin: 'normal',
            onChange: this.handleChange,
        };
    }

    componentDidMount = () => {
        this.props.getTemplates();
    };

    componentDidUpdate = (prevProps) => {
        const { visible } = this.props;
        if (visible && !prevProps.visible) {
            // Regenerate the templates
            this.props.getTemplates();
        }
        if (!visible && prevProps.visible) {
            this.resetState();
        }
    };

    resetState = () => {
        this.setState({ ...baseConversationState });
    };

    handleClose = ({ success } = {}) => {
        const { closeDialog } = this.props;

        closeDialog({ success });
    };

    getValidationErrors = () => {
        const { subject, message } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.subject = isEmptyString(subject) ? emptyMessage : false;
        errors.message = isEmptyString(message) ? emptyMessage : false;

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);

        return errors;
    };

    buildPayload = () => {
        const { subject, message, selectedTemplate } = this.state;
        const { inbox_user_id } = this.props.contact;
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            patient_inbox_user_id: inbox_user_id,
            subject,
            message: message.trim().length > 0 ? message.trim() : null,
            inbox_conversation_template_id: selectedTemplate ? selectedTemplate.inbox_conversation_template_id : null,
        };

        return payload;
    };

    trackTemplates = () => {
        const { selectedTemplate, message, subject } = this.state;

        // is a template being used?
        if (selectedTemplate && selectedTemplate.subject && selectedTemplate.subject.length > 0) {
            const templateSubjectEdited = selectedTemplate.subject !== subject;
            const templateMessageEdited = selectedTemplate.message !== message;

            let label = templateSubjectEdited || templateMessageEdited ? 'edited' : null;
            if (templateSubjectEdited) {
                label = `${label}-subject`;
            }
            if (templateMessageEdited) {
                label = `${label}-message`;
            }

            Snowplow.structEvent('Inbox', 'template-selected', null, label, selectedTemplate.id);
        }
    };

    handleSubmit = () => {
        const { createConversation } = this.props;
        // Validate inputs
        const errors = this.getValidationErrors();
        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            // Validation passed
            createConversation(this.buildPayload(), this.handleClose);
            this.trackTemplates();
        }
    };

    convertTemplate = (messageTpl, params) => {
        let renderedMessage = messageTpl;
        for (let key in params) {
            /* eslint-disable */
            renderedMessage = renderedMessage.replace('${' + `${key}` + '}', params[key]);
            /* eslint-enable */
        }
        return renderedMessage;
    };

    handleNewTemplate = () => {
        this.props.history.push('/settings/inbox/template');
    };

    handleSelectChange = (event) => {
        if (!event.target.value) {
            this.resetState();
        } else if (event.target.value.type === 'new') {
            this.handleNewTemplate();
        } else if (event.target.value.subject) {
            const { first_name } = this.props.contact;
            const selectedTemplate = event.target.value;
            const subject = selectedTemplate.subject;
            const message = this.convertTemplate(
                preprocessMessageTemplateText(selectedTemplate.message, this.props.auth),
                {
                    first_name,
                }
            );

            this.setState({
                subject,
                message,
                selectedTemplate,
            });
        } else {
            this.resetState();
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        const { visible, conversation, canUseWorkflowTemplates } = this.props;
        const { first_name } = this.props.contact;
        const { subject, message, errors, selectedTemplate } = this.state;
        const templateStore = this.props.template;

        /* eslint-disable */
        const isTemplateAvailable = (template) => {
            if (template.status === 'active') {
                if (canUseWorkflowTemplates) {
                    // don't allow adding any scheduling or assessment templates since they are more complex than just text messages
                    // that can be edited in the text field
                    if (
                        _.get(template, 'message_content.messaging_form.has_scheduling') ||
                        _.isString(_.get(template, 'message_content.messaging_form.assessment_id')) ||
                        _.isString(_.get(template, 'message_content.messaging_form.appt_type_id'))
                    ) {
                        return false;
                    }

                    return _.isNil(template.workflow_status) || template.workflow_status === 'active';
                }
                return _.isNil(template.workflow_status);
            }
            return false;
        };
        /* eslint-enable */

        const templateComponents = templateStore.templates.map((template) => {
            if (isTemplateAvailable(template)) {
                return (
                    <MenuItem
                        key={template.inbox_conversation_template_id}
                        className="template-item-list"
                        value={template}
                    >
                        {getTemplateTitle(template)}
                    </MenuItem>
                );
            }
            return <></>;
        });
        const templateIsCTA = _.get(selectedTemplate, 'message_content_type') === 'call_to_action';

        return (
            <DpDialog.Dialog id="createUserDialog" open={visible} onClose={this.handleClose} maxWidth="md">
                <DpDialog.Content>
                    <Grid container>
                        <Hidden smDown>
                            <Grid container justify="center" md={5}>
                                <img
                                    width={236}
                                    height={314}
                                    src={`/inbox/onboarding-message.png`}
                                    srcSet={`/inbox/onboarding-message.png 1x, /inbox/onboarding-message_2x.png 2x, /inbox/onboarding-message_3x.png 3x`}
                                    alt="Start a Conversation"
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={7}>
                            <DpDialog.ContentHeader
                                heading={`Start a new conversation with ${first_name}`}
                                onClose={this.handleClose}
                            >
                                {' '}
                            </DpDialog.ContentHeader>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="conversation-template-label">Conversation Template</InputLabel>
                                <Select
                                    labelId="conversation-template-label"
                                    value={selectedTemplate}
                                    onChange={this.handleSelectChange}
                                    input={
                                        <OutlinedInput
                                            labelWidth={145}
                                            name="custom-conversation"
                                            id="custom-conversation"
                                        />
                                    }
                                >
                                    <MenuItem key="custom" value={false}>
                                        Custom Conversation
                                    </MenuItem>
                                    {templateComponents}
                                    <MenuItem key="new" value={{ type: 'new' }} style={{ color: '#3793ff' }}>
                                        + Add a new template
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                type="text"
                                id="subject"
                                name="subject"
                                label="Subject"
                                value={subject || ''}
                                autoFocus
                                required
                                inputProps={{
                                    maxLength: 512,
                                }}
                                error={!!errors.subject}
                                helperText={errors.subject}
                                {...this.commonProps}
                            />
                            <div
                                className={`create-conversation-dialog__message-wrapper ${
                                    templateIsCTA ? 'with-message-cta' : ''
                                } ${errors.message ? 'with-error' : ''}`}
                            >
                                <TextField
                                    type="text"
                                    id="message"
                                    name="message"
                                    label="Message"
                                    value={message || ''}
                                    required
                                    multiline
                                    rows={3}
                                    rowsMax={10}
                                    inputProps={{}}
                                    error={!!errors.message}
                                    helperText={errors.message}
                                    {...this.commonProps}
                                />
                                {templateIsCTA && (
                                    <div className="create-conversation-dialog__message-cta-container">
                                        <MessageCTACard messageContent={selectedTemplate.message_content} />
                                    </div>
                                )}
                            </div>
                            <Typography style={{ width: '100%', textAlign: 'justify' }}>
                                <b>Note</b>: Inbox messages should be clinical in nature. Sending marketing messages
                                through this platform could violate TCPA and FCC regulations. Do not request credit card
                                information.
                            </Typography>
                            {conversation.createConversationMessage && (
                                <FormHelperText error>{conversation.createConversationMessage}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </DpDialog.Content>
                <DpDialog.Actions>
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        disabled={conversation.sending}
                        variant="contained"
                        disableElevation
                    >
                        {conversation.sending ? (
                            <CircularProgress size={20} className="create-conversation-dialog__progress" />
                        ) : (
                            'Done'
                        )}
                    </Button>
                </DpDialog.Actions>
            </DpDialog.Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { inboxConversationTemplate, inboxConversation, inboxUser, auth } = state;

    // We have decided to make the workflow templates avaliable for 1:1 inbox messaging, so this prop
    // determines whether they should appear in the list or not
    const canUseWorkflowTemplates =
        pharmacySelectors.pharmacyHasFullPepEnabled(state) || pharmacySelectors.pharmacyHasBasicMessagingEnabled(state);

    return {
        template: inboxConversationTemplate,
        conversation: inboxConversation,
        user: inboxUser,
        canUseWorkflowTemplates,
        auth,
    };
}

const bindActionsToDispatch = {
    createConversation: conversationAction.createConversation,
    getTemplates: templateAction.getTemplates,
};

export default withRouter(connect(mapStateToProps, bindActionsToDispatch)(NewConversationDialog));
