import React from 'react';
import { Card, CardText, ExpansionList, ExpansionPanel } from 'react-md';

import GDocsFile from './GDocsFile';
import GDocsDocument from './GDocsDocument';

const Handouts = ({ content }) => (
    <Card className="outer-card">
        <CardText>
            <ExpansionList className="md-cell md-cell--12">
                {content.map((handout) => (
                    <ExpansionPanel
                        key={handout.id}
                        id={handout.id}
                        label={handout.title}
                        footer={null}
                        defaultExpanded={content.length === 1}
                    >
                        {handout.type === 'file' ? <GDocsFile file={handout} /> : <GDocsDocument document={handout} />}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </CardText>
    </Card>
);

export default Handouts;
