import React from 'react';
import { connect } from 'react-redux';
import { authSelectors } from '../../redux/Auth/selector';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Actions from 'redux/actions';
import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, DialogContent } from '@material-ui/core';

const Dialog = withStyles({
    paper: {
        width: '400px',
        maxWidth: '100%',
    },
    paperWidthMd: {
        width: '400px !important',
        maxWidth: '100%',
    },
})(MuiDialog);

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        padding: '16px 24px',
        alignItems: 'center',
    },
    headerTitle: {
        flex: 1,
        margin: 0,
        fontWeight: 500,
        fontSize: '1.25rem',
    },
}));

const IVRRedirectErrorDialog = (props) => {
    const classes = useStyles();
    const redirectUrl = props.redirectUrl;
    const isOpen = !!redirectUrl;

    const handleClose = () => {
        props.dispatch({ type: Actions.SET_VOW_REDIRECT_ERROR_LOCATION, payload: undefined });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <div className={classes.header}>
                <h2 className={classes.headerTitle}>Redirection Failed</h2>
                <IconButton arial-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>Ensure popups are allowed for this page or go directly by clicking below</DialogContent>
            <DialogActions>
                <Button href={redirectUrl} target="_blank" variant="contained" color="primary">
                    Go to IVR
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        redirectUrl: authSelectors.getVowRedirectErrorLocation(state),
    };
};

export default connect(mapStateToProps)(IVRRedirectErrorDialog);
