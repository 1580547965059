const typePrefix = 'inbox/message/';

export const messageType = {
    RESET_MESSAGE_STORE: `${typePrefix}RESET_MESSAGE_STORE`,
    CREATE_MESSAGE_REQUEST: `${typePrefix}CREATE_MESSAGE_REQUEST`,
    CREATE_MESSAGE_SUCCESS: `${typePrefix}CREATE_MESSAGE_SUCCESS`,
    CREATE_MESSAGE_FAILURE: `${typePrefix}CREATE_MESSAGE_FAILURE`,
    CREATE_MESSAGE_APPOINTMENT_REQUEST: `${typePrefix}CREATE_MESSAGE_APPOINTMENT_REQUEST`,
    CREATE_MESSAGE_APPOINTMENT_SUCCESS: `${typePrefix}CREATE_MESSAGE_APPOINTMENT_SUCCESS`,
    CREATE_MESSAGE_APPOINTMENT_FAILURE: `${typePrefix}CREATE_MESSAGE_APPOINTMENT_FAILURE`,
    GET_MESSAGES_REQUEST: `${typePrefix}GET_MESSAGES_REQUEST`,
    GET_MESSAGES_SUCCESS: `${typePrefix}GET_MESSAGES_SUCCESS`,
    GET_MESSAGES_FAILURE: `${typePrefix}GET_MESSAGES_FAILURE`,
    CREATE_ATTACHMENT_REQUEST: `${typePrefix}CREATE_ATTACHMENT_REQUEST`,
    CREATE_ATTACHMENT_SUCCESS: `${typePrefix}CREATE_ATTACHMENT_SUCCESS`,
    CREATE_ATTACHMENT_FAILURE: `${typePrefix}CREATE_ATTACHMENT_FAILURE`,
    GET_LATEST_MESSAGE_REQUEST: `${typePrefix}GET_LATEST_MESSAGE_REQUEST`,
    GET_LATEST_MESSAGE_SUCCESS: `${typePrefix}GET_LATEST_MESSAGE_SUCCESS`,
    GET_LATEST_MESSAGE_FAILURE: `${typePrefix}GET_LATEST_MESSAGE_FAILURE`,
    UPDATE_MESSAGE_READ_STATUS_REQUEST: `${typePrefix}UPDATE_MESSAGE_READ_STATUS_REQUEST`,
    UPDATE_MESSAGE_READ_STATUS_SUCCESS: `${typePrefix}UPDATE_MESSAGE_READ_STATUS_SUCCESS`,
    UPDATE_MESSAGE_READ_STATUS_FAILURE: `${typePrefix}UPDATE_MESSAGE_READ_STATUS_FAILURE`,
};

export default messageType;
