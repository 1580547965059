import React from 'react';
import { Can, userActions, restrictedResources } from 'casl';
import NoAccess from 'components/Common/NoAccess';
import Inbox from './Inbox';

function InboxWrapper() {
    const { view } = userActions;
    const { subject: caslSubject } = restrictedResources.inbox;

    return (
        <Can I={view} this={caslSubject} passThrough>
            {(allowed) =>
                allowed ? (
                    <Inbox />
                ) : (
                    <div className="inbox-grid-wrap" style={{ width: '100%' }}>
                        <NoAccess />
                    </div>
                )
            }
        </Can>
    );
}

export default InboxWrapper;
