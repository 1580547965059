import Actions from 'redux/actions';
import huntGroup from 'constants/SpeakWithPharmacist';

const initialState = {
    huntGroupLoading: false,
    savingInProgress: false,
    huntGroups: [],
    overflowSetting: {},
    savingOverflowSetting: false,
};

const addNewGroup = (state) => {
    const { huntGroups } = { ...state };
    const huntGroupModel = { ...huntGroup };
    huntGroupModel.seq = huntGroups.length + 1;
    huntGroupModel.error = '';
    huntGroups.push(huntGroupModel);
    return {
        ...state,
        huntGroups,
    };
};

const changeOverflowSetting = (state, payload) => {
    const { overflowSetting } = { ...state };
    overflowSetting.xfer_end_type_id = payload;
    return {
        ...state,
        overflowSetting,
    };
};

const removeGroup = (state, payload) => {
    const { huntGroups } = { ...state };
    huntGroups.splice(payload.idx, 1);
    huntGroups.forEach((hGrp, idx) => {
        huntGroups[idx].seq = idx + 1;
    });
    return {
        ...state,
        huntGroups,
    };
};

const removeGroupProcessing = (state, payload, isProcessing) => {
    const { huntGroups } = { ...state };
    huntGroups[payload.idx].processing = isProcessing;
    return {
        ...state,
        huntGroups,
    };
};

export default function SpeakWithPharmacistReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_HUNT_GROUP_STARTED:
            return { ...state, huntGroupLoading: true };
        case Actions.FETCH_HUNT_GROUP_SUCCESS:
            return { ...state, huntGroupLoading: false, huntGroups: payload.huntGroups };
        case Actions.SET_OVERFLOW_SETTING:
            return { ...state, overflowSetting: payload };
        case Actions.FETCH_HUNT_GROUP_FAILED:
            return { ...state, huntGroupLoading: false };
        case Actions.ADD_HUNT_GROUP:
            return addNewGroup(state);
        case Actions.CHANGE_OVERFLOW_SETTING:
            return changeOverflowSetting(state, payload);
        case Actions.SET_HUNT_GROUP_CHANGES:
            return { ...state, huntGroups: payload.huntGroups };
        case Actions.SAVE_HUNT_GROUP_STARTED:
            return { ...state, savingInProgress: true };
        case Actions.REMOVE_HUNT_GROUP_SUCCESS:
            return removeGroup(state, payload);
        case Actions.REMOVE_HUNT_GROUP_STARTED:
            return removeGroupProcessing(state, payload, true);
        case Actions.REMOVE_HUNT_GROUP_FAILED:
            return removeGroupProcessing(state, payload, false);
        case Actions.SAVE_OVERFLOW_STARTED:
            return { ...state, savingOverflowSetting: true };
        case Actions.SAVE_OVERFLOW_FINISHED:
            return { ...state, savingOverflowSetting: false };
        case Actions.SAVE_HUNT_GROUP_SUCCESS:
        case Actions.SAVE_HUNT_GROUP_FAILED:
            return { ...state, savingInProgress: false };
        default:
            return state;
    }
}
