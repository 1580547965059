import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { CircularProgress, Cell } from 'react-md';
import Button from '@material-ui/core/Button';

import AudioPlayer from 'components/Common/AudioPlayer';

export class Greetings extends Component {
    getActionButtons = () => {
        const { handleDelete, handleEdit, handleDefault, isSpecial, hideActions } = this.props;
        const menuItems = [];

        // User with read only permissions cannot see the actions
        if (hideActions) {
            return menuItems;
        }

        if (isSpecial) {
            menuItems.push(
                <Button id="btn-make-default" key={1} onClick={handleDefault} variant="outlined" color="primary">
                    MAKE DEFAULT
                </Button>
            );
        }

        menuItems.push(
            <Button id="btn-edit" key={3} onClick={handleEdit} variant="outlined" disabled={hideActions}>
                EDIT
            </Button>
        );

        if (handleDelete) {
            menuItems.push(
                <Button id="btn-delete" key={2} onClick={handleDelete} variant="outlined" color="secondary">
                    DELETE
                </Button>
            );
        }

        return menuItems;
    };

    render() {
        const { greetingDetail, greetingFileList, isDeleting, className, isSpecial, type, track } = this.props;
        let { setCurrentTrack } = this.props;
        const actionButtons = this.getActionButtons();
        const greetingType = type ? `${type}_` : '';
        const greetingID = greetingDetail.id;
        const greetingName = greetingFileList[greetingDetail[`${greetingType}greeting_id`]];
        const greetingTypeID = greetingDetail[`${greetingType}greeting_type`];
        if (!setCurrentTrack) {
            setCurrentTrack = () => {};
        }

        return (
            <div className={`grid-container table-data ${className}`}>
                {greetingTypeID !== 'text/plain' && (
                    <Cell size={1} tabletHidden phoneHidden className="flex-middle audio-player">
                        <AudioPlayer
                            fileSource={greetingDetail[`${greetingType}greeting_url`]}
                            pauseAudio={track && track.previous === greetingID}
                            audioStarted={() => setCurrentTrack(greetingID)}
                        />
                    </Cell>
                )}
                <Cell
                    size={greetingTypeID !== 'text/plain' ? 3 : 4}
                    tabletSize={8}
                    phoneSize={4}
                    className="flex-middle greeting-name"
                >
                    {greetingName}
                </Cell>
                <Cell size={1} tabletSize={8} phoneSize={4} className="flex-middle greeting-status">
                    {greetingDetail.status}
                </Cell>
                <Cell desktopHidden tabletSize={4} phoneSize={2} className="flex-middle greeting-date-header">
                    Start Date
                </Cell>
                <Cell desktopHidden tabletSize={4} phoneSize={2} className="flex-middle greeting-date-header">
                    End Date
                </Cell>
                {['dr', 'patient', 'closed', 'aa_welcome', 'new_patient_flow'].indexOf(type) < 0 && (
                    <Fragment>
                        <Cell size={1} tabletSize={4} phoneSize={2} className="flex-middle greeting-start-date">
                            {isSpecial
                                ? greetingDetail.startDate
                                    ? moment(greetingDetail.startDate).format('M/D/YY')
                                    : 'N/A'
                                : 'Default'}
                        </Cell>
                        <Cell size={1} tabletSize={4} phoneSize={2} className="flex-middle greeting-end-date">
                            {isSpecial
                                ? greetingDetail.endDate
                                    ? moment(greetingDetail.endDate).format('M/D/YY')
                                    : 'N/A'
                                : 'Default'}
                        </Cell>
                    </Fragment>
                )}

                <Cell phoneSize={4} tabletSize={8} desktopHidden className="flex-middle audio-player">
                    <AudioPlayer
                        fileSource={greetingDetail[`${greetingType}greeting_url`]}
                        labelText="Preview"
                        pauseAudio={track && track.previous === greetingID}
                        audioStarted={() => setCurrentTrack(greetingID)}
                    />
                </Cell>
                <Cell
                    size={['dr', 'patient', 'closed', 'aa_welcome', 'new_patient_flow'].indexOf(type) < 0 ? 5 : 7}
                    tabletHidden
                    phoneHidden
                    className="flex-middle flex-right"
                >
                    {isDeleting ? <CircularProgress id="greeting-remove-spinner" /> : actionButtons}
                </Cell>

                {actionButtons.map((button, idx) => (
                    <Cell key={idx} desktopHidden tabletSize={8} phoneSize={4} className="flex-middle flex-right">
                        {button}
                    </Cell>
                ))}
            </div>
        );
    }
}

export default Greetings;
