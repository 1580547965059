const typePrefix = 'inbox/onboarding/';

export const onboardingTypes = {
    RESET_INBOX_LOCATION_ONBOARDING: `${typePrefix}RESET_INBOX_LOCATION_ONBOARDING`,
    GET_INBOX_LOCATION_ONBOARDING_REQUEST: `${typePrefix}GET_INBOX_LOCATION_ONBOARDING_REQUEST`,
    GET_INBOX_LOCATION_ONBOARDING_SUCCESS: `${typePrefix}GET_INBOX_LOCATION_ONBOARDING_SUCCESS`,
    GET_INBOX_LOCATION_ONBOARDING_FAILURE: `${typePrefix}GET_INBOX_LOCATION_ONBOARDING_FAILURE`,
    UPDATE_INBOX_LOCATION_ONBOARDING_CLIENT_STEPS: `${typePrefix}UPDATE_INBOX_LOCATION_ONBOARDING_CLIENT_STEPS`,
    UPDATE_INBOX_LOCATION_ONBOARDING_SERVER_STEPS: `${typePrefix}UPDATE_INBOX_LOCATION_ONBOARDING_SERVER_STEPS`,
};
