import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
};

const CallRailsSelectionDialog = (props) => (
    <Dialog onClose={props.onClose} open={props.open}>
        <DialogTitle id="call-rail-dialog">
            <p className="section-title">Conflict Detected - Multiple Records Found</p>
            <p className="sub-title-text">
                To set the call rail for currently selected location, click on the appropriate value in the list.
            </p>
        </DialogTitle>

        <DialogContent>
            <List>
                {props.companies.map((company) => (
                    <ListItem button onClick={() => props.onSelect(company)} key={company.name}>
                        <ListItemAvatar>
                            <Avatar className={props.classes.avatar}>
                                <LocationCityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={company.name} secondary={company.id} />
                    </ListItem>
                ))}
            </List>
        </DialogContent>

        <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
);

export default withStyles(styles)(CallRailsSelectionDialog);
