import { homeTypes } from './type';

export const initialState = {
    isRefillsCountLoading: false,
    isPatientTransfersLoading: false,
    isMessagesSentLoading: false,
    refillsCount: null,
    patientTransfers: null,
    messagesSent: null,
    sendQuickReplyLoading: false,
    checklist: {
        patients: {
            isLoading: false,
            birthdaysToday: null,
        },
        voicemails: {
            isLoading: false,
            unread: null,
        },
        messages: {
            isLoading: false,
            unread: null,
        },
        leads: {
            isLoading: false,
            internal: null,
            callrails: null,
            withStatus: null,
        },
    },
};

export const homeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case homeTypes.RESET_HOME_STORE:
            return {
                ...initialState,
            };
        case homeTypes.GET_REFILL_COUNT_REQUEST:
            return {
                ...state,
                isRefillsCountLoading: true,
            };
        case homeTypes.GET_REFILL_COUNT_SUCCESS:
            return {
                ...state,
                refillsCount: [payload.data.data.web[0], payload.data.data.mobile[0]],
                isRefillsCountLoading: false,
            };
        case homeTypes.GET_REFILL_COUNT_FAILED:
            return {
                ...state,
                isRefillsCountLoading: false,
            };
        case homeTypes.GET_PATIENT_TRANSFERS_REQUEST:
            return {
                ...state,
                isPatientTransfersLoading: true,
            };
        case homeTypes.GET_PATIENT_TRANSFERS_SUCCESS:
            return {
                ...state,
                patientTransfers: payload.data.transfers,
                isPatientTransfersLoading: false,
            };
        case homeTypes.GET_PATIENT_TRANSFERS_FAILED:
            return {
                ...state,
                isPatientTransfersLoading: false,
            };
        case homeTypes.GET_MESSAGES_SENT_REQUEST:
            return {
                ...state,
                isMessagesSentLoading: true,
            };
        case homeTypes.GET_MESSAGES_SENT_SUCCESS:
            return {
                ...state,
                messagesSent: payload.data.sent,
                isMessagesSentLoading: false,
            };
        case homeTypes.GET_MESSAGES_SENT_FAILED:
            return {
                ...state,
                isMessagesSentLoading: false,
            };
        case homeTypes.GET_PATIENTS_WITH_BIRTHDAY_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    patients: {
                        ...state.checklist.patients,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_PATIENTS_WITH_BIRTHDAY_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    patientsWithBirthday: payload.data.count,
                    patients: {
                        birthdaysToday: payload.data.count,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_PATIENTS_WITH_BIRTHDAY_FAILED:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    patients: {
                        ...state.checklist.patients,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_UNREAD_VOICEMAILS_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    voicemails: {
                        ...state.checklist.voicemails,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_UNREAD_VOICEMAILS_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    voicemails: {
                        unread: payload,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_UNREAD_VOICEMAILS_FAILED:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    voicemails: {
                        ...state.checklist.voicemails,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_UNREAD_MESSAGES_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    messages: {
                        ...state.checklist.messages,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_UNREAD_MESSAGES_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    messages: {
                        unread: payload.data,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_UNREAD_MESSAGES_FAILED:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    messages: {
                        ...state.checklist.messages,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_INTERNAL_LEADS_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_INTERNAL_LEAD_COUNT_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        internal: payload,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_INTERNAL_LEAD_COUNT_FAILED:
            return {
                ...state,
                checklist: {
                    leads: {
                        ...state.checklist.leads,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_CALLRAIL_LEADS_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_CALLRAIL_LEAD_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        callrails: payload,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_CALLRAIL_LEAD_FAILED:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_LEADS_STATUS_REQUEST:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        isLoading: true,
                    },
                },
            };
        case homeTypes.GET_LEADS_STATUS_SUCCESS:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        withStatus: payload,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_LEADS_STATUS_FAILED:
            return {
                ...state,
                checklist: {
                    ...state.checklist,
                    leads: {
                        ...state.checklist.leads,
                        isLoading: false,
                    },
                },
            };
        case homeTypes.GET_CONVERSATIONS_REQUEST:
        case homeTypes.SEND_QUICK_REPLY_REQUEST:
            return {
                ...state,
                sendQuickReplyLoading: true,
            };
        case homeTypes.GET_CONVERSATIONS_SUCCESS:
        case homeTypes.SEND_QUICK_REPLY_SUCCESS:
        case homeTypes.GET_CONVERSATIONS_FAILURE:
        case homeTypes.SEND_QUICK_REPLY_FAILED:
            return {
                ...state,
                sendQuickReplyLoading: false,
            };
        default:
            return state;
    }
};
