import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Full Patient Engagement Platform';
const description = 'Enable or disable user access full Patient Engagement Platform for this location';

const FullPepToggle = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(FullPepToggle, {
    package: {
        name: 'Inbox',
        code: 'INBX',
        attribute: {
            name: 'FullPepEnabled',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
