import React, { PureComponent } from 'react';
import { Card, Cell, Grid, CardText, Checkbox, CircularProgress } from 'react-md';

import { connect } from 'react-redux';

import {
    saveLang,
    deleteLang,
    fetchLangWithGreeting,
    fetchSysLangs,
    addLanguage,
    removeLanguage,
} from 'redux/actionCreators/Settings/Languages';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { CardHeader, SectionTitle } from 'components/Common/index';
import goBackService from 'utils/goBackService';

class Language extends PureComponent {
    componentDidMount() {
        const { fetchSysLangs, fetchLangWithGreeting } = this.props;

        fetchSysLangs();
        fetchLangWithGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, fetchLangWithGreeting } = this.props;
        if (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) {
            fetchLangWithGreeting();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    handleChange = (check, langTypeId) => {
        const { addLanguage, removeLanguage } = this.props;

        if (check) {
            addLanguage(langTypeId);
        } else {
            removeLanguage(langTypeId);
        }
    };

    onSave = async () => {
        const {
            saveLang,
            deleteLang,
            fetchLangWithGreeting,
            displayToast,
            languageChoices: { ivrLangMap, ivrLang },
        } = this.props;

        if (Object.keys(ivrLangMap).length) {
            const savePayload = [];
            const removePayload = [];

            for (let key in ivrLangMap) {
                if (ivrLangMap[key].id === undefined) {
                    savePayload.push({ id: '0', langTypeId: key });
                }
            }

            ivrLang.forEach((lang) => {
                if (ivrLangMap[lang.langTypeId] === undefined) {
                    removePayload.push(lang.id);
                }
            });

            if (removePayload.length) {
                await deleteLang(removePayload);
            }

            if (savePayload.length) {
                await saveLang(savePayload);
            }

            if (removePayload.length || savePayload.length) {
                fetchLangWithGreeting();
            }
        } else {
            displayToast({ text: 'Select atleast one language', type: 'error' });
        }
    };

    render() {
        const {
            languageChoices: { sysLang, savingLang, loadingSysLang, removingLang, loadingLangChoices, ivrLangMap },
        } = this.props;

        return (
            <Grid className="account-details">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Languages"
                            disabled={savingLang || removingLang}
                            loading={savingLang || removingLang}
                            onBack={this.onBack}
                            onSave={this.onSave}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="Available Languages" />
                                    {loadingSysLang || loadingLangChoices ? (
                                        <CircularProgress id="language-spinner" />
                                    ) : (
                                        sysLang.map((lang, idx) => (
                                            <Checkbox
                                                key={idx}
                                                id={`syslang_${idx}`}
                                                name={`syslang_${idx}`}
                                                label={lang.langName}
                                                value={lang.langTypeId}
                                                className="language-check"
                                                disabled={lang.langName === 'English'}
                                                checked={ivrLangMap[lang.langTypeId] !== undefined}
                                                onChange={(check) => this.handleChange(check, lang.langTypeId)}
                                            />
                                        ))
                                    )}
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    languageChoices: state.settings.languageChoices,
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSysLangs: () => dispatch(fetchSysLangs()),
    fetchLangWithGreeting: () => dispatch(fetchLangWithGreeting()),
    deleteLang: (data) => dispatch(deleteLang(data)),
    saveLang: (data) => dispatch(saveLang(data)),
    addLanguage: (data) => dispatch(addLanguage(data)),
    removeLanguage: (data) => dispatch(removeLanguage(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Language);
