import Actions from 'redux/actions';

const initialState = { phoneMenus: {}, loadingMenuConfig: false, phoneMenuEdit: {} };

const setMenuEditinProcess = (state, payload, processing) => {
    const { phoneMenuEdit } = { ...state };
    phoneMenuEdit[payload.item] = processing;
    return {
        ...state,
        phoneMenuEdit,
    };
};

const setMenuEditFinish = (state, payload) => {
    const { phoneMenuEdit, phoneMenus } = { ...state };
    delete phoneMenuEdit[payload.item];
    phoneMenus[payload.item] = payload.value;
    return {
        ...state,
        phoneMenuEdit,
        phoneMenus,
    };
};

export default function PhoneLineReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_PHONE_MENU_STARTED:
            return { ...state, loadingMenuConfig: true };
        case Actions.FETCH_PHONE_MENU_SUCCESS:
            return { ...state, phoneMenus: payload, loadingMenuConfig: false };
        case Actions.FETCH_PHONE_MENU_FAILED:
            return { ...state, loadingMenuConfig: false };
        case Actions.SAVE_MENU_STATUS_FAILED:
            return setMenuEditinProcess(state, payload, false);
        case Actions.SAVE_MENU_STATUS_STARTED:
            return setMenuEditinProcess(state, payload, true);
        case Actions.SAVE_MENU_STATUS_SUCCESS:
            return setMenuEditFinish(state, payload);
        default:
            return state;
    }
}
