import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import { pharmacyAction } from '../../redux/Pharmacy/index';
import { getPharmacyPackage, getLocationPackageAttribute } from '../../utils/helper';
import config from '../../config';
import { displayToast } from '../../redux/actionCreators/Snackbar';
import { getPackageAttributes } from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2PackageAttributes';

const styles = {
    tooltip: {
        fontSize: 12,
    },
};

const PackageAttributeTogglePropTypes = {
    pharmacy: PropTypes.object.isRequired,
    packageCode: PropTypes.string.isRequired,
    attributeName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export class PackageAttributeToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packageId: null,
            packageData: null,
            attributeId: null,
            attributeData: null,
            requiredInboxAttributeData: null,
            value: false,
        };
    }

    componentDidMount = () => {
        this.initialize();
    };

    componentDidUpdate = (prevProps) => {
        const {
            pharmacy,
            auth: { selectedLocation },
        } = this.props;
        const isPharmacyUpdated = prevProps.pharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isLocationChanged = prevProps.auth.selectedLocation !== selectedLocation;

        if ((isLocationChanged || isPharmacyUpdated) && !pharmacy.loading) {
            this.initialize();
        }
    };

    getCustPackage = (packageCode) => _.find(this.props.pharmacy.pharmacy.package, (pkg) => pkg.code === packageCode);

    getCustPackageAttribute = async () => {
        const { packageCode, attributeName, isLocationLevelAttr } = this.props;
        const custPackage = this.getCustPackage(packageCode);
        const packageAttribute = await getPackageAttributes(custPackage.pkgId, attributeName);

        const payload = {
            title: packageAttribute.title,
            dataTypeId: packageAttribute.dataTypeId,
            packageAttrId: packageAttribute.id,
        };

        if (isLocationLevelAttr) {
            payload.locationId = config.X_LocationID;
        }

        return payload;
    };

    initialize = async () => {
        const {
            pharmacy: { pharmacy: pharmacyData },
            packageCode,
            attributeName,
            requiresInboxPackageAttributeName,
        } = this.props;

        const inboxPackage = getPharmacyPackage(packageCode, pharmacyData);
        let inboxPackageAttribute = getLocationPackageAttribute(
            packageCode,
            config.X_LocationID,
            attributeName,
            pharmacyData
        );

        // Some inbox attributes are dependant on sibling attributes being enabled
        const requiredInboxAttributeData = requiresInboxPackageAttributeName
            ? getLocationPackageAttribute(
                  packageCode,
                  config.X_LocationID,
                  requiresInboxPackageAttributeName,
                  pharmacyData
              )
            : null;

        if (_.isEmpty(inboxPackageAttribute)) {
            // need to fetch cust package attribute in case of non required one
            inboxPackageAttribute = await this.getCustPackageAttribute();
        }

        if (inboxPackage) {
            this.setState({
                packageId: inboxPackage.id,
                packageData: inboxPackage,
                attributeId: inboxPackageAttribute.id,
                attributeData: inboxPackageAttribute,
                requiredInboxAttributeData: requiredInboxAttributeData,
                value: Boolean(parseInt(inboxPackageAttribute.value)), // value comes in as string 0/1 - have to cast to int before converting to boolean
            });
        }
    };

    toggleValue = () => {
        const { packageId, attributeId, attributeData, requiredInboxAttributeData, value } = this.state;
        const { createPackageAttribute, updatePackageAttribute, displayToast } = this.props;
        const newValue = !value;

        // To enable the value, we need to ensure the required attribute from the inbox package is enabled too
        if (requiredInboxAttributeData && newValue && !Boolean(parseInt(requiredInboxAttributeData.value))) {
            const requiredAttributeId = requiredInboxAttributeData.id;
            const requiredAttributePayload = _.cloneDeep(requiredInboxAttributeData);
            if (!requiredAttributeId) {
                // We're only interested in processing errors, as we haven't started enabling the main attribute yet
                createPackageAttribute(packageId, requiredAttributePayload).catch((error) => {
                    displayToast({ text: 'Update failed', type: 'error' });
                    return;
                });
            } else {
                updatePackageAttribute(packageId, requiredAttributeId, newValue).catch((error) => {
                    displayToast({ text: 'Update failed', type: 'error' });
                    return;
                });
            }
        }

        // Note: no attributeId will exist if using inherited default value - need to create instead of update
        if (!attributeId) {
            const attributePayload = _.cloneDeep(attributeData);
            attributePayload.value = newValue;

            // Will return 400 error if package already exists (might occur if local config state got stale)
            createPackageAttribute(packageId, attributePayload).then(
                () => {
                    displayToast({ text: 'Update successful', type: 'success' });
                },
                (error) => {
                    displayToast({ text: 'Update failed', type: 'error' });
                }
            );
        } else {
            displayToast({ text: 'Update in progress', type: 'info' });
            updatePackageAttribute(packageId, attributeId, newValue).then(
                () => {
                    displayToast({ text: 'Update successful', type: 'success' });
                },
                (error) => {
                    displayToast({ text: 'Update failed', type: 'error' });
                }
            );
        }

        this.setState({
            value: newValue,
        });
    };

    render() {
        const { title, description, pharmacy, divider, auth, classes, disabled, containerStyles } = this.props;
        const { value } = this.state;
        const hasEditPermission = _.includes(auth.selectedLocation.perms, 'PUT'); // TODO need to centralize logic for permissions
        const classOverride =
            value && !hasEditPermission
                ? {
                      root: 'disabled-checked-switch__override',
                      thumb: 'disabled-checked-switch__override-thumb',
                      track: 'disabled-checked-switch__override-track',
                  }
                : undefined;

        return (
            <ListItem divider={divider || false} style={containerStyles}>
                <ListItemText
                    primary={
                        <Typography
                            variant="body1"
                            style={{
                                fontWeight: '400',
                                fontSize: '16px',
                                color: '#1d1d1d',
                            }}
                        >
                            {title}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            variant="body2"
                            style={{
                                color: '#5d5d5d',
                            }}
                        >
                            {description}
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Tooltip
                            title={!hasEditPermission ? 'You do not have access to change this setting' : ''}
                            classes={classes}
                        >
                            <span>
                                <Switch
                                    checked={value}
                                    classes={classOverride}
                                    disabled={!hasEditPermission || disabled}
                                    onChange={(e) => !pharmacy.sending && !pharmacy.loading && this.toggleValue()}
                                    color="primary"
                                />
                            </span>
                        </Tooltip>
                    }
                />
            </ListItem>
        );
    }
}

PackageAttributeToggle.propTypes = PackageAttributeTogglePropTypes;

PackageAttributeToggle.defaultProps = {
    isLocationLevelAttr: false,
    containerStyles: null,
    requiresInboxPackageAttributeName: null,
};

function mapStateToProps(state) {
    const { pharmacy, auth } = state;
    return {
        pharmacy,
        auth,
    };
}

const bindActionsToDispatch = {
    createPackageAttribute: pharmacyAction.createPackageAttribute,
    updatePackageAttribute: pharmacyAction.updatePackageAttribute,
    displayToast: displayToast,
};

export default connect(mapStateToProps, bindActionsToDispatch)(withStyles(styles)(PackageAttributeToggle));
