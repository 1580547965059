import './ScheduleByManualSelectContent.sass';
import PatientsSelectionPane from 'components/Patients/PatientsSelectionPane';
import React, { useMemo, useState, useEffect } from 'react';
import LocalStorageService from 'utils/localStorageService';
import { getWaitlistFilterOptionsForTemplate, getWaitlistIdsFromTemplate } from '../schedulingUtil';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import { canMessagePatient } from 'components/Patients/patientsUtil';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentWorkflowSessionContext } from 'snowplow/contexts';

export default function ScheduleByManualSelectContent({
    template,
    setChosenPatients,
    history,
    waitlist,
    setPatientChooseMethod,
    listToSearch,
    isCovidWaitlistEnabled,
}) {
    const props = arguments[0];

    const [selectedPatients, setSelectedPatients] = useState({});

    useEffect(() => {
        Snowplow.pageView(`Schedule Workflow - Who manual from ${listToSearch}`);
    }, [listToSearch]);

    const messageablePatients = useMemo(() => {
        const messagable = {};
        _.forIn(selectedPatients, (patient) => {
            if (canMessagePatient(patient, true)) {
                messagable[patient.inbox_user_id] = patient;
            }
        });
        return messagable;
    }, [selectedPatients]);

    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    const waitlist_id = getWaitlistIdsFromTemplate(template);

    const waitlistFilterOptions = getWaitlistFilterOptionsForTemplate(template, waitlist);

    const tableHeight = Math.max(window.innerHeight - (isCovidWaitlistEnabled ? 310 : 270), 460);

    const onMainButtonClick = (selectedPatients) => {
        Snowplow.structEvent(StructEventCategories.appointments, 'schedule-workflow-who-next-clicked', [
            new AppointmentWorkflowSessionContext()
                .setTemplateId(template.inbox_conversation_template_id)
                .setPatientChooseMethod('manual')
                .setNumberOfPatientsContacted(_.size(selectedPatients))
                .build(),
        ]);

        setPatientChooseMethod('manual');
        setChosenPatients(_.values(selectedPatients));
        history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling/review`);
    };

    return (
        <div className="schedule-by-manual-select-content">
            {!isCovidWaitlistEnabled ? null : (
                <div className="heading">
                    <span className="prefix">Viewing patients from the</span>

                    <Button
                        className={classnames({
                            'waitlist-button': true,
                            'selected-button': listToSearch === 'waitlist',
                        })}
                        disabled={listToSearch === 'waitlist'}
                        onClick={() => {
                            history.push(
                                `/workflows/${template.inbox_conversation_template_id}/scheduling/who/waitlist`
                            );
                        }}
                    >
                        Waitlist
                    </Button>

                    <Button
                        className={classnames({
                            'pharmacy-button': true,
                            'selected-button': listToSearch === 'pharmacy',
                        })}
                        disabled={listToSearch === 'pharmacy'}
                        onClick={() => {
                            history.push(
                                `/workflows/${template.inbox_conversation_template_id}/scheduling/who/pharmacy`
                            );
                        }}
                    >
                        Pharmacy
                    </Button>
                </div>
            )}

            {listToSearch === 'waitlist' ? (
                <React.Fragment>
                    <PatientsSelectionPane
                        {...props}
                        selectedPatients={selectedPatients}
                        setSelectedPatients={setSelectedPatients}
                        height={tableHeight}
                        hideActivityColumn
                        showAgeOnly
                        hidePhoneColumn
                        hidePatientActions
                        showPatientTypeColumn
                        showAddedToWaitlistColumn
                        showWaitlistStatusColumn
                        waitlistIdToQuery={waitlist_id}
                        nameFormat={nameFormat}
                        setNameFormat={setNameFormat}
                        mainButtonLabel={'Use these patients'}
                        showConversationStatusFilter={false}
                        showMessageableStatusFilter={false}
                        showWaitlistStatusFilter={true}
                        showPatientTypeFilter={true}
                        showWaitlistLabel={true}
                        showWaitlistNameFilter={true}
                        waitlistFilterOptions={waitlistFilterOptions}
                        mayOnlySelectMessageablePatients
                        onMainButtonClick={onMainButtonClick}
                    />

                    <Button
                        className="to-by-count-button"
                        onClick={() => {
                            history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling/who`);
                        }}
                    >
                        I will specify how many patients to contact
                    </Button>
                </React.Fragment>
            ) : (
                <PatientsSelectionPane
                    {...props}
                    selectedPatients={selectedPatients}
                    setSelectedPatients={setSelectedPatients}
                    height={tableHeight}
                    nameFormat={nameFormat}
                    setNameFormat={setNameFormat}
                    mainButtonLabel="Use these patients"
                    hideViewWaitlistsButton={true}
                    messageablePatients={messageablePatients}
                    mayOnlySelectMessageablePatients
                    onMainButtonClick={onMainButtonClick}
                />
            )}
        </div>
    );
}
