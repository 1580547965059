// Note: This is not consumed, this is merely added to avoid casl warning mentioned in ../UserAbilityBuilders/BaseAbilityBuilder.js
const subjectTypePrefix = 'Login';

export const restrictedLoginResources = {
    login: {
        subject: subjectTypePrefix,
        fields: {
            username: 'User Name',
            password: 'Password',
        },
    },
};
