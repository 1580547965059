import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionsMenu } from './ActionsMenu';
import { pharmacyAppsActions } from 'redux/PharmacyApps/action';
import { NonBrandedAppsDialog } from './NonBrandedAppsDialog';
import { NewBrandedAppsDialog } from './NewBrandedAppsDialog';
import { ExistingBrandedAppsDialog } from './ExistingBrandedAppDialog';
import { DeleteConfirmDialog } from './AppDeletionConfirmDialog';
import { pharmacyAppsSelector } from 'redux/PharmacyApps/selector';
import { NoApps } from './NoApps';
import { Toolbar } from './Toolbar';
import { webServiceURL, brandedAppTypeId } from './Constants';
import { AppNameWithAlert } from './AppNameWithAlert';
import ReactPlaceholder from 'react-placeholder';

class PharmacyApps extends PureComponent {
    constructor(props) {
        super(props);
        this.dialogs = {
            none: '',
            brandedAppDialog: 'Branded App Dialog',
            nonBrandedAppDialog: 'Non Branded App Dialog',
            existingAppDialog: 'Existing App Dialog',
            deleteAppConfirmDialog: 'Confirm App Deletion',
        };
        this.state = {
            activeDialog: this.dialogs.none,
            editMode: false,
        };
        this.showNonBrandedAppsDialog = () => this.setState({ activeDialog: this.dialogs.nonBrandedAppDialog });
        this.showBrandedAppsDialog = () => this.setState({ activeDialog: this.dialogs.brandedAppDialog });
        this.showExistingAppDialog = () => this.setState({ activeDialog: this.dialogs.existingAppDialog });
        this.showAppDeletionConfirmDialog = () => this.setState({ activeDialog: this.dialogs.deleteAppConfirmDialog });
        this.hideDialog = () => this.setState({ activeDialog: this.dialogs.none, editMode: false });
    }

    componentDidMount() {
        const { pharmacyApps, dpNonBrandedApps, fetchNonBrandedApps, fetchPharmacyApps } = this.props;
        if (_.isEmpty(dpNonBrandedApps.apps)) {
            fetchNonBrandedApps();
        }
        if (_.isEmpty(pharmacyApps.brandedApps) && _.isEmpty(pharmacyApps.nonBrandedApps)) {
            fetchPharmacyApps();
        }
    }

    componentDidUpdate(prevProps) {
        const { pharmacy: previousPharmacy } = prevProps;
        const { pharmacy: currentPharmacy, fetchPharmacyApps } = this.props;

        if (previousPharmacy.id !== currentPharmacy.id) {
            fetchPharmacyApps();
        }
    }

    onAddNonBrandedApps = (appsToAdd) => {
        const { addAppsToPharmacy } = this.props;
        addAppsToPharmacy({ appIds: _.map(appsToAdd, (app) => app.id) });
        this.hideDialog();
    };

    onSaveBrandedApp = (payload) => {
        const { pharmacy, addBrandedAppToPharmacy } = this.props;
        payload.appTypeId = brandedAppTypeId;
        payload.mConfigId = _.get(pharmacy, 'attr.appCode.value', null);
        payload.webServiceURL = webServiceURL;
        const branchPayload = {};

        _.forEach(payload, (value, key) => {
            if (key.startsWith('branch')) {
                const branchKey = _.replace(key, 'branch.', '');
                branchPayload[branchKey] = value;
                delete payload[key];
            }
        });
        addBrandedAppToPharmacy({ payload, branchPayload });
        this.hideDialog();
    };

    onMoreInfo = (app) => {
        this.currentApp = app;
        this.showExistingAppDialog();
    };

    onEditApp = (app) => {
        this.currentApp = app;
        this.setState({ activeDialog: this.dialogs.existingAppDialog, editMode: true });
    };

    onDeleteApp = (app) => {
        this.currentApp = app;
        this.showAppDeletionConfirmDialog();
    };

    deleteApp = () => {
        const { deletePharmacyApp } = this.props;
        deletePharmacyApp({ appId: this.currentApp.id });
        this.hideDialog();
        this.currentApp = null;
    };

    updateApp = ({ appId, payload }) => {
        const { updateBrandedApp } = this.props;
        updateBrandedApp({ appId, payload });
    };

    pharmacyHasMissingInfo = () => {
        const { pharmacy } = this.props;
        const missingLogo = _.isEmpty(_.get(pharmacy, 'file.Logo.publicURL'));
        const missingColors = _.isEmpty(_.get(pharmacy, 'color'));
        return missingColors || missingLogo;
    };

    render() {
        const { pharmacyApps, dpNonBrandedApps, pharmacy, isProcessing, isLoading } = this.props;
        const availableNonBrandedApps = _.differenceBy(dpNonBrandedApps.apps, pharmacyApps.nonBrandedApps, 'name');
        const hasNoAppsAdded = _.isEmpty(pharmacyApps.brandedApps) && _.isEmpty(pharmacyApps.nonBrandedApps);
        const onAction = {
            onMoreInfo: this.onMoreInfo,
            onEdit: this.onEditApp,
            onDelete: this.onDeleteApp,
        };

        const isIncompletePharmacyInfo = this.pharmacyHasMissingInfo();
        // Right now we do not have the ability to add more than one branded app to the pharmacy.
        const brandedAppAdditionDisabled = !_.isEmpty(pharmacyApps.brandedApps) || isIncompletePharmacyInfo;
        const nonBrandedAppAdditionDisabled = _.isEmpty(availableNonBrandedApps);
        const skeleton = _.times(3, (i) => (
            <div className="table--row table--body" key={`app-row-skeleton-${i}`}>
                {_.times(6, (j) => (
                    <span key={`app-col-skeleton-${j}`}>
                        <ReactPlaceholder type="text" rows={1} ready={false} showLoadingAnimation firstLaunchOnly>
                            <span />
                        </ReactPlaceholder>
                    </span>
                ))}
            </div>
        ));

        return (
            <Fragment>
                <NonBrandedAppsDialog
                    show={this.state.activeDialog === this.dialogs.nonBrandedAppDialog}
                    onClose={this.hideDialog}
                    onAdd={this.onAddNonBrandedApps}
                    dpNonBrandedApps={availableNonBrandedApps}
                />

                <NewBrandedAppsDialog
                    show={this.state.activeDialog === this.dialogs.brandedAppDialog}
                    onClose={this.hideDialog}
                    onSave={this.onSaveBrandedApp}
                    pharmacyName={pharmacy.name}
                />

                <ExistingBrandedAppsDialog
                    show={this.state.activeDialog === this.dialogs.existingAppDialog}
                    onClose={this.hideDialog}
                    onSave={this.updateApp}
                    onEdit={this.onEditApp}
                    editMode={this.state.editMode}
                    app={this.currentApp}
                    isProcessing={isProcessing}
                />

                <DeleteConfirmDialog
                    show={this.state.activeDialog === this.dialogs.deleteAppConfirmDialog}
                    onCancel={this.hideDialog}
                    onConfirm={this.deleteApp}
                    appName={_.get(this, 'currentApp.name', '')}
                />

                <Backdrop open={isProcessing} style={{ zIndex: 9999999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Toolbar
                    className="toolbar"
                    onAddNonBrandedAppButtonClick={this.showNonBrandedAppsDialog}
                    onAddBrandedAppsButtonClick={this.showBrandedAppsDialog}
                    brandedAppAdditionDisabled={brandedAppAdditionDisabled}
                    nonBrandedAppAdditionDisabled={nonBrandedAppAdditionDisabled}
                    pharmacyHasMissingInfo={isIncompletePharmacyInfo}
                    isLoading={isLoading}
                />

                <br />

                <div className="table--row table--header">
                    <span> Name </span>
                    <span> Android Version </span>
                    <span> Android Status </span>
                    <span> Apple Version </span>
                    <span> Apple Status </span>
                    <span />
                </div>

                <div className="table--content-container">
                    {isLoading ? (
                        skeleton
                    ) : hasNoAppsAdded ? (
                        <NoApps />
                    ) : (
                        _.map(pharmacyApps, (appType) =>
                            _.map(appType, (app) => (
                                <div className="table--row table--body" key={app.name}>
                                    <AppNameWithAlert app={app} pharmacy={pharmacy} />
                                    <span>{_.get(app, 'appRelease.android[0].versionName', '-')}</span>
                                    <span>{_.startCase(_.get(app, 'androidAppStatus', '-'))}</span>
                                    <span>{_.get(app, 'appRelease.apple[0].versionName', '-')}</span>
                                    <span>{_.startCase(_.get(app, 'appleAppStatus', '-'))}</span>
                                    <ActionsMenu app={app} onAction={onAction} />
                                </div>
                            ))
                        )
                    )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    pharmacy: state.pharmacy.pharmacy,
    dpNonBrandedApps: state.pharmacyApps.dpNonBrandedApps,
    isLoading: state.pharmacyApps.pharmacyApps.isLoading,
    isProcessing: state.pharmacyApps.pharmacyApps.isProcessing,
    pharmacyApps: pharmacyAppsSelector.getPharmacyApps(state),
});

const bindActionsToDispatch = {
    fetchNonBrandedApps: pharmacyAppsActions.fetchNonBrandedApps,
    fetchPharmacyApps: pharmacyAppsActions.fetchPharmacyApps,
    addAppsToPharmacy: pharmacyAppsActions.addAppsToPharmacy,
    addBrandedAppToPharmacy: pharmacyAppsActions.addBrandedAppToPharmacy,
    deletePharmacyApp: pharmacyAppsActions.deletePharmacyApp,
    updateBrandedApp: pharmacyAppsActions.updateBrandedApp,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PharmacyApps);
