import Actions from 'redux/actions';
import { get, post } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../utils/log';
import { MAX_RECORDS } from 'constants/Leads';

export const fetchLeads = (data) => (dispatch, getState) => {
    const leadsState = getState().leads;
    const { currentPage } = leadsState;

    const loadMore = !!data.loadMore;
    const page = loadMore ? currentPage + 1 : 1;

    dispatch({ type: Actions.FETCH_LEADS_STARTED, payload: { loadMore } });

    const apiUrl = `${Config.apiweb_url}${API_PATH.LEADS}`;
    const payload = {
        ...data,
        // Add in pagination
        page,
        per_page: MAX_RECORDS,
    };
    const leadsReq = post(apiUrl, payload);

    return leadsReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_LEADS_SUCCESS, payload: { data: resp, loadMore, page } });
        })
        .catch((err) => {
            logHttpError('An error occurred on fetchLeads', err);
            dispatch(displayToast({ text: 'Error while fetching leads data!', type: 'error' }));
            dispatch({ type: Actions.FETCH_LEADS_FAILED, payload: err });
        });
};

export const fetchLeadsCount = (data) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.LEADS_COUNT}`;
    const leadsCountReq = post(apiUrl, data);

    dispatch({ type: Actions.FETCH_LEADS_COUNT_STARTED });

    return leadsCountReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_LEADS_COUNT_SUCCESS, payload: { data: resp } });
        })
        .catch((err) => {
            logHttpError('An error occurred on fetchLeadsCount', err);
            dispatch(displayToast({ text: 'Error while fetching leads count data!', type: 'error' }));
            dispatch({ type: Actions.FETCH_LEADS_COUNT_FAILED, payload: err });
        });
};

export const resetLeadsCount = () => ({
    type: Actions.FETCH_LEADS_COUNT_FAILED,
});

export const fetchLeadAudio = (details) => (dispatch) => {
    const recordingUrl = `${Config.apiweb_url}${API_PATH.CALLRAIL_CALLS}/${details.leadId}/recording`;
    dispatch({ type: Actions.FETCH_LEAD_AUDIO_STARTED, payload: { leadId: details.leadId } });
    const fetchLeadAudioReq = get(recordingUrl);

    return fetchLeadAudioReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_LEAD_AUDIO_SUCCESS, payload: { data, leadId: details.leadId } });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchVoicemailAudioReq', err);
            dispatch({ type: Actions.FETCH_LEAD_AUDIO_FAILED, payload: { leadId: details.leadId } });
            return { err };
        });
};

export const sortLeads = (data) => ({
    type: Actions.SORT_LEADS,
    payload: data,
});

export const resetLeads = () => ({
    type: Actions.RESET_LEADS,
});

export const fetchLeadNotes = (payload) => (dispatch) => {
    const getNotesUrl = `${Config.apiweb_url}/leads/read/${Config.X_PharmacyID}`;
    dispatch({ type: Actions.FETCH_LEAD_NOTES_STARTED });

    const getNotesReq = post(getNotesUrl, payload);

    return getNotesReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_LEAD_NOTES_SUCCESS, payload: { data } });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on getNotesReq', err);
            dispatch({ type: Actions.FETCH_LEAD_NOTES_FAILED, payload: { err } });
            return { err };
        });
};

export const saveLeadNote = (payload) => (dispatch) => {
    const saveNoteUrl = `${Config.apiweb_url}/leads/${payload.action}/${Config.X_PharmacyID}/${payload.leadId}`;
    dispatch({ type: Actions.SAVE_LEAD_NOTE_STARTED, payload });
    const saveNoteReq = post(saveNoteUrl, payload.data);

    return saveNoteReq
        .then((data) => {
            dispatch({ type: Actions.SAVE_LEAD_NOTE_SUCCESS, payload });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on saveLeadNote', err);
            dispatch({ type: Actions.SAVE_LEAD_NOTE_FAILED, payload });
            return { err };
        });
};

export const setDraftNotes = (payload) => (dispatch) => {
    dispatch({ type: Actions.SET_DRAFT_NOTE, payload });
};

export const fetchLatestLeads = (payload) => (dispatch) => {
    dispatch({ type: Actions.FETCH_LATEST_LEADS });
};
