import assessmentTypes from './type';

export const initialState = {
    availableAssessedAttributes: null,
    assessmentTemplates: {},
};

export function assessmentReducer(state = initialState, { type, payload }) {
    switch (type) {
        case assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_REQUEST:
            return {
                ...state,
                availableAssessedAttributes: { loading: true },
            };

        case assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                availableAssessedAttributes: {
                    loading: false,
                    attributes: payload.attributes,
                },
                sentAssessments: payload.sentAssessments,
            };

        case assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_FAILURE:
            return {
                ...state,
                availableAssessedAttributes: {
                    loading: false,
                    error: payload.error,
                },
            };

        case assessmentTypes.CLEAR_AVAILABLE_ASSESSED_ATTRIBUTES:
            return {
                ...state,
                availableAssessedAttributes: null,
            };

        case assessmentTypes.GET_ASSESSMENT_TEMPLATE_REQUEST:
            return {
                ...state,
                assessmentTemplates: {
                    ...state.assessmentTemplates,
                    [payload.assessmentId]: {
                        loading: true,
                    },
                },
            };

        case assessmentTypes.GET_ASSESSMENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                assessmentTemplates: {
                    ...state.assessmentTemplates,
                    [payload.assessmentId]: {
                        ...payload,
                    },
                },
            };

        case assessmentTypes.GET_ASSESSMENT_TEMPLATE_FAILURE:
            return {
                ...state,
                assessmentTemplates: {
                    ...state.assessmentTemplates,
                    [payload.assessmentId]: {
                        ...payload,
                    },
                },
            };

        default:
            return state;
    }
}
