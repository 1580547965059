import React, { Fragment } from 'react';

const UnorderedList = ({ content: { title, content } }) => (
    <Fragment>
        <h5 style={{ fontWeight: 500 }}> {title} </h5>
        <ul>
            {content.map((item, idx) => (
                <li key={`overview_section_${idx}`}> {item} </li>
            ))}
        </ul>
    </Fragment>
);

export default UnorderedList;
