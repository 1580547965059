import React from 'react';
import _ from 'lodash';
import {
    Card,
    CardHeader,
    CardActions,
    CardContent,
    IconButton,
    Divider,
    Typography,
    Box,
    Grid,
    Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PhoneIcon from '@material-ui/icons/Phone';
import ForumIcon from '@material-ui/icons/Forum';
import CachedIcon from '@material-ui/icons/Cached';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { formatRawDate, formatPhoneNo } from '../../utils/helper';

const CHANNEL_MAP = {
    website: 'Website',
    mobile: 'Mobile',
    ivr2: 'IVR',
};

const GenerateAddressStr = ({ street_one, street_two, city, state, zip }) => {
    const street = _.join(_.compact([street_one, street_two]), ' ');
    const stateZip = _.join(_.compact([state, zip]), ' ');
    const fullAddress = _.join(_.compact([street, city, stateZip]), ', ');

    return fullAddress || '—';
};

const GenerateDateStr = ({ date_of_birth }) => {
    if (!date_of_birth) {
        return '—';
    }
    return formatRawDate(date_of_birth, 'YYYY-MM-DD', 'MM/DD/YYYY');
};

const GeneratePhoneStr = ({ phone }) => {
    if (!phone) {
        return '—';
    }
    // Note we have several helper fns that format a raw phone, however
    // this is the only one that currently handles numbers with more than 10 digits
    return formatPhoneNo(phone);
};

const GenerateFullNameStr = ({ first_name, last_name }) => {
    if (!first_name) {
        return '—';
    }
    return `${first_name} ${last_name}`;
};

const useStyles = makeStyles({
    dialog: {
        maxWidth: '450px',
    },
    sidebar: {
        marginTop: '40px',
        marginLeft: '16px',
        width: '450px',
        overflow: 'hidden',
        display: 'flex',
    },
    card: {
        overflowY: 'auto',
        flex: '1',
    },
    title: {
        fontWeight: '500',
    },
    subtitle: {
        color: '#808080',
    },
    actionSection: {
        // TODO hide for now until we implement these actions
        display: 'none',
    },
    sectionTitle: {
        color: '#292929',
        fontSize: '16px',
        marginBottom: '24px',
    },
    fieldContainer: {
        marginBottom: '16px',
    },
    fieldLabel: {
        color: '#4a4a4a',
        marginBottom: '8px',
    },
    fieldValue: {
        color: '#808080',
    },
});

const RefillDetailsItem = ({ label, value, noWrap = true }) => {
    const classes = useStyles();
    return (
        <Box mb={1} className={classes.fieldContainer}>
            <Typography variant="body1" className={classes.fieldLabel}>
                {label}
            </Typography>
            <Typography variant="body2" className={classes.fieldValue} title={value} noWrap={noWrap}>
                {value}
            </Typography>
        </Box>
    );
};

const RefillDetailsContainer = ({ open, onClose, children }) => {
    const classes = useStyles();
    if (isMobile) {
        return (
            <Dialog id="refill-dialog" open={open} onClose={onClose} fullWidth>
                {children}
            </Dialog>
        );
    }
    return (
        <div id="refill-sidebar" className={classes.sidebar}>
            <Card variant="outlined" className={classes.card}>
                {children}
            </Card>
        </div>
    );
};

const RefillDetails = ({ refillData, onClose }) => {
    const classes = useStyles();

    if (!refillData) {
        return null;
    }

    return (
        <RefillDetailsContainer open={!!refillData} onClose={onClose}>
            <CardHeader
                action={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={refillData.rx_num}
                titleTypographyProps={{ className: classes.title }}
                subheader={_.capitalize(refillData.delivery_method)}
                subheaderTypographyProps={{ className: classes.subtitle }}
            />
            <CardActions disableSpacing className={classes.actionSection}>
                <IconButton>
                    <PersonAddIcon />
                </IconButton>
                <IconButton>
                    <PhoneIcon />
                </IconButton>
                <IconButton>
                    <ForumIcon />
                </IconButton>
                <IconButton>
                    <CachedIcon />
                </IconButton>
            </CardActions>
            <Divider />
            <CardContent>
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    Prescription Information
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <RefillDetailsItem label="Name" value={refillData.rx_name || '—'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RefillDetailsItem label="Number" value={refillData.rx_num} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RefillDetailsItem label="Channel" value={CHANNEL_MAP[refillData.source_type]} />
                    </Grid>
                </Grid>
                <RefillDetailsItem label="Status" value={refillData.message_displayed_to_user} noWrap={false} />
                <RefillDetailsItem label="Notes" value={refillData.comment || '—'} noWrap={false} />
            </CardContent>
            <Divider />
            <CardContent>
                <Typography variant="subtitle1" className={classes.sectionTitle}>
                    Patient Information
                </Typography>
                <RefillDetailsItem label="Name" value={GenerateFullNameStr(refillData.patient_info)} />
                <RefillDetailsItem label="Phone" value={GeneratePhoneStr(refillData.patient_info)} />
                <RefillDetailsItem label="DOB" value={GenerateDateStr(refillData.patient_info)} />
                <RefillDetailsItem label="Address" value={GenerateAddressStr(refillData.patient_info)} noWrap={false} />
            </CardContent>
        </RefillDetailsContainer>
    );
};

RefillDetails.propTypes = {
    refillData: PropTypes.shape({
        rx_name: PropTypes.string,
        rx_num: PropTypes.string,
        source_type: PropTypes.string,
        status: PropTypes.string,
        comment: PropTypes.string,
        delivery_method: PropTypes.string,
        patient_info: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            date_of_birth: PropTypes.string,
            email: PropTypes.string,
            phone: PropTypes.string,
            city: PropTypes.string,
            country: PropTypes.string,
            state: PropTypes.string,
            street_one: PropTypes.string,
            street_two: PropTypes.string,
            zip: PropTypes.string,
            submitted_refill_id: PropTypes.string,
            patient_profile_id: PropTypes.string,
        }),
    }),
    onClose: PropTypes.func,
};

export default RefillDetails;
