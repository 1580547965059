import { restrictedResources } from 'casl';

const icon = {
    right: 'keyboard_arrow_right',
};

const { generalSettings } = restrictedResources;
export const GeneralSettingsMenuItems = [
    {
        text: 'Account Details',
        to: '/settings/general/account-details',
        icon,
        isInternal: false,
        caslSubject: generalSettings.accountDetails.subject,
    },
    {
        text: 'Pharmacy Details',
        to: '/settings/general/pharmacy-details',
        icon,
        isInternal: false,
        caslSubject: generalSettings.pharmacyDetails.subject,
    },
    {
        text: 'Refill Details',
        to: '/settings/general/refill-details',
        icon,
        isInternal: false,
        caslSubject: generalSettings.refillDetails.subject,
    },
    {
        text: 'Language',
        to: '/settings/general/language',
        icon,
        isInternal: true,
        caslSubject: generalSettings.language.subject,
    },
    {
        text: 'Features and Functions',
        to: '/settings/general/features',
        icon,
        isInternal: true,
        caslSubject: generalSettings.featuresAndFunctions.subject,
    },
    {
        text: 'Website Banner Configuration',
        to: '/settings/general/banner-configuration',
        icon,
        isInternal: true,
        caslSubject: generalSettings.bannerConfiguration.subject,
    },
    {
        text: 'Notifications',
        to: '/settings/general/notification',
        icon,
        isInternal: false,
        caslSubject: generalSettings.notifications.subject,
    },
    {
        text: 'User Management',
        to: '/settings/general/users',
        icon,
        caslSubject: generalSettings.userManagement.subject,
        ldFlag: 'npeUserManagementEnabled',
    },
];
