export default {
    LOGIN_STARTED: 'LOGIN_STARTED',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT: 'LOGOUT',
    // TODO: Move account related information to actions/Account.js
    FETCH_USER_ACCOUNT_STARTED: 'FETCH_USER_ACCOUNT_STARTED', // User account details
    FETCH_USER_ACCOUNT_SUCCESS: 'FETCH_USER_ACCOUNT_SUCCESS',
    FETCH_USER_ACCOUNT_FAILED: 'FETCH_USER_ACCOUNT_FAILED',
    UPDATE_USER_ACCOUNT_STARTED: 'UPDATE_USER_ACCOUNT_STARTED', // User account details
    UPDATE_USER_ACCOUNT_SUCCESS: 'UPDATE_USER_ACCOUNT_SUCCESS',
    UPDATE_USER_ACCOUNT_FAILED: 'UPDATE_USER_ACCOUNT_FAILED',
    FETCH_USER_DETAIL_STARTED: 'FETCH_USER_DETAIL_STARTED', // User role details - TODO think about renaming
    FETCH_USER_DETAIL_SUCCESS: 'FETCH_USER_DETAIL_SUCCESS',
    FETCH_USER_DETAIL_FAILED: 'FETCH_USER_DETAIL_FAILED',
    SET_LOGIN_ERR: 'SET_LOGIN_ERR',
    FETCH_IVR_PHONE_STARTED: 'FETCH_IVR_PHONE_STARTED',
    FETCH_IVR_PHONE_SUCCESS: 'FETCH_IVR_PHONE_SUCCESS',
    FETCH_IVR_PHONE_FAILED: 'FETCH_IVR_PHONE_FAILED',
    UPDATE_IVR_PHONE_STARTED: 'UPDATE_IVR_PHONE_STARTED',
    UPDATE_IVR_PHONE_SUCCESS: 'UPDATE_IVR_PHONE_SUCCESS',
    UPDATE_IVR_PHONE_FAILED: 'UPDATE_IVR_PHONE_FAILED',
    FETCH_USER_MEMBER_STARTED: 'FETCH_USER_MEMBER_STARTED',
    FETCH_USER_MEMBER_SUCCESS: 'FETCH_USER_MEMBER_SUCCESS',
    FETCH_USER_MEMBER_FAILED: 'FETCH_USER_MEMBER_FAILED',
    UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS',
    UPDATE_LOCATION: 'UPDATE_LOCATION',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    UPDATE_NEWPWD_STARTED: 'UPDATE_NEWPWD_STARTED',
    UPDATE_NEWPWD_FAILED: 'UPDATE_NEWPWD_FAILED',
    UPDATE_NEWPWD_SUCCESS: 'UPDATE_NEWPWD_SUCCESS',
    FETCH_PHARMACIES_STARTED: 'FETCH_PHARMACIES_STARTED',
    FETCH_PHARMACIES_SUCCESS: 'FETCH_PHARMACIES_SUCCESS',
    FETCH_PHARMACIES_FAILED: 'FETCH_PHARMACIES_FAILED',
    CLEAR_PHARMACIES: 'CLEAR_PHARMACIES',
    FETCH_PHARMACY_STARTED: 'FETCH_PHARMACY_STARTED',
    FETCH_PHARMACY_SUCCESS: 'FETCH_PHARMACY_SUCCESS',
    FETCH_PHARMACY_FAILED: 'FETCH_PHARMACY_FAILED',
    FETCH_PHARMACY_LOCATIONS_SUCCESS: 'FETCH_PHARMACY_LOCATIONS_SUCCESS',
    FETCH_PHARMACY_LOCATIONS_FAILED: 'FETCH_PHARMACY_LOCATIONS_FAILED',
    FETCH_LOCATION_DETAIL_STARTED: ' FETCH_LOCATION_DETAIL_STARTED',
    FETCH_LOCATION_DETAIL_SUCCESS: ' FETCH_LOCATION_DETAIL_SUCCESS',
    FETCH_LOCATION_DETAIL_FAILED: ' FETCH_LOCATION_DETAIL_FAILED',
    FETCH_USER_PREFERENCES_STARTED: 'FETCH_USER_PREFERENCES_STARTED',
    FETCH_USER_PREFERENCES_SUCCESS: 'FETCH_USER_PREFERENCES_SUCCESS',
    FETCH_USER_PREFERENCES_FAILED: 'FETCH_USER_PREFERENCES_FAILED',
    ADD_USER_PREFERENCES_STARTED: 'ADD_USER_PREFERENCES_STARTED',
    ADD_USER_PREFERENCES_SUCCESS: 'ADD_USER_PREFERENCES_SUCCESS',
    ADD_USER_PREFERENCES_FAILED: 'ADD_USER_PREFERENCES_FAILED',
    UPDATE_USER_PREFERENCES_STARTED: 'UPDATE_USER_PREFERENCES_STARTED',
    UPDATE_USER_PREFERENCES_SUCCESS: 'UPDATE_USER_PREFERENCES_SUCCESS',
    UPDATE_USER_PREFERENCES_FAILED: 'UPDATE_USER_PREFERENCES_FAILED',
    SET_VOW_REDIRECT_ERROR_LOCATION: 'SET_VOW_REDIRECT_ERROR_LOCATION',
};
