import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const EmptyHistory = () => (
    <div className="empty-history">
        <Typography className="title"> History </Typography>
        <Grid justify="center" container className="content">
            <img
                width={148}
                height={148}
                src={`/newsletter/empty_history.png`}
                alt="Empty history icon"
                className="standard-margin-bottom-10"
            />
            <Typography className="description" gutterBottom>
                Select an eNewsletter to view the content and stats
            </Typography>
        </Grid>
    </div>
);

export default EmptyHistory;
