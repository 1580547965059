import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import NavigationDrawer from 'react-md/lib/NavigationDrawers';

import NavLink from 'components/NavLink';
import IconMenus from 'components/IconMenus';
import { ToolbarChildren } from 'components/AppBar';

import Asthma from './HealthConditions/Asthma';
import WeightLoss from './HealthConditions/WeightLoss';
import Hypertension from './HealthConditions/Hypertension';
import Type2Diabetes from './HealthConditions/Type2Diabetes';
import HighCholesterol from './HealthConditions/HighCholesterol';
import SmokingCessation from './HealthConditions/SmokingCessation';
import EmptyStatePage from './EmptyStatePage';

const Classes = ({ location: { pathname }, classesEnabled, history }) => {
    useEffect(() => (!classesEnabled ? history.push('/classes/default') : undefined), [pathname]);

    const navItems = [
        {
            primaryText: 'Type 2 Diabetes',
            to: '/classes/type2Diabetes',
            component: Type2Diabetes,
        },
        {
            primaryText: 'Smoking Cessation',
            to: '/classes/smokingCessation',
            component: SmokingCessation,
        },
        {
            primaryText: 'Weight Loss',
            to: '/classes/weightLoss',
            component: WeightLoss,
        },
        {
            primaryText: 'Hypertension',
            to: '/classes/hypertension',
            component: Hypertension,
        },
        {
            primaryText: 'High Cholesterol',
            to: '/classes/highCholesterol',
            component: HighCholesterol,
        },
        {
            primaryText: 'Asthma',
            to: '/classes/asthma',
            component: Asthma,
        },
    ];

    const isInvalidRoute = (route) => !_.some(navItems, (item) => item.to === route);

    return (
        <Route
            render={() =>
                isInvalidRoute(pathname) || pathname.endsWith('default') ? (
                    <EmptyStatePage />
                ) : (
                    <NavigationDrawer
                        className="settings__menu"
                        includeDrawerHeader={false}
                        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
                        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
                        toolbarActions={<IconMenus />}
                        toolbarChildren={<ToolbarChildren />}
                        navItems={navItems.map((props) => (
                            <NavLink {...props} key={props.primaryText} />
                        ))}
                    >
                        <Switch>
                            {navItems.map(({ primaryText, to, component }) => (
                                <Route key={primaryText} path={to} component={component} />
                            ))}
                        </Switch>
                    </NavigationDrawer>
                )
            }
        />
    );
};

const mapStateToProps = ({ pharmacy: { classesEnabled } }) => ({
    classesEnabled,
});

export default connect(mapStateToProps, null)(withRouter(Classes));
