import React from 'react';
import MaskedInput from 'react-text-mask';

export const BirthdayMask = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            showMask={props.showMask}
        />
    );
};

export const PhoneNumberMask = (props) => {
    const { inputRef, countryCode, ...other } = props;
    const mask =
        countryCode === 'GB'
            ? [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
            : ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar="_"
            showMask={props.showMask}
        />
    );
};
