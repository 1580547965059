import React from 'react';
import { Dialog } from '@material-ui/core';
import WaitlistTable from '../Settings/Coronavirus/WaitlistTable';

const ViewWaitlistsDialog = ({ onClose, open }) => {
    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <div style={{ width: 650, padding: 25 }}>
                <WaitlistTable />
            </div>
        </Dialog>
    );
};

export default ViewWaitlistsDialog;
