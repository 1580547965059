import './DesktopMenuItems.sass';
import Config from '../config';
import React from 'react';
import { Cell, Button } from 'react-md';
import { NavLink } from 'react-router-dom';
import ResourcesPopover from './Resources';
import Badge from '@material-ui/core/Badge';
import { Can, userActions, restrictedResources } from 'casl';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { redirectToVow } from 'redux/actionCreators/Auth';
import IVRRedirectErrorDialog from './Header/IVRRedirectErrorDialog';

class DesktopMenuItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voicemailBadge: false,
            refillsBadge: false,
            workflowsBadge: false,
            leadBadge: false,
            menuAnchor: null,
        };
    }

    setBadgeOnMessage = () => {
        const { socketMessage, location } = this.props;

        if (
            location.pathname &&
            location.pathname.indexOf('voicemail') === -1 &&
            socketMessage.messageType === 'ivrVm'
        ) {
            this.setState({
                voicemailBadge: true,
            });
        }

        if (
            location.pathname &&
            location.pathname.indexOf('refills') === -1 &&
            socketMessage.messageType === 'newRefill'
        ) {
            this.setState({
                refillsBadge: true,
            });
        }

        if (location.pathname && location.pathname.indexOf('leads') === -1 && socketMessage.messageType === 'newLead') {
            this.setState({
                leadBadge: true,
            });
        }
    };

    setBadgeOnLocationChange = () => {
        const { location } = this.props;
        const { voicemailBadge, refillsBadge, leadBadge } = this.state;

        if (location.pathname.indexOf('voicemail') > -1 && voicemailBadge) {
            // On voicemail now
            this.setState({
                voicemailBadge: false,
            });
        }

        if (location.pathname.indexOf('refills') > -1 && refillsBadge) {
            // On refill now
            this.setState({
                refillsBadge: false,
            });
        }

        if (location.pathname.indexOf('leads') > -1 && leadBadge) {
            // On lead now
            this.setState({
                leadBadge: false,
            });
        }
    };

    componentDidUpdate = (prevProps) => {
        const {
            socketMessage,
            location,
            fetchPatientOpportunities,
            workflowOpportunities,
            activeOpportunityCount,
        } = this.props;

        if (Object.keys(socketMessage).length > 1 && socketMessage.id !== prevProps.socketMessage.id) {
            this.setBadgeOnMessage();
        }

        if (location.pathname && prevProps.location.pathname && location.pathname !== prevProps.location.pathname) {
            // Location has changed
            this.setBadgeOnLocationChange();
        }

        if (_.isNil(workflowOpportunities)) {
            fetchPatientOpportunities(moment().format('YYYY-MM-DD'));
        } else {
            // store this into state so it doesn't flicker when we reload it
            const workflowsBadge = _.isFinite(activeOpportunityCount) && activeOpportunityCount > 0;
            if (workflowsBadge !== this.state.workflowsBadge) {
                this.setState({ workflowsBadge });
            }
        }
    };

    render() {
        const { tigerPistolEnabled, unreadMessageCount, history, npeLandingPageEnabled, isVowIVREnabled } = this.props;
        const { voicemailBadge, refillsBadge, leadBadge } = this.state;
        const { view } = userActions;
        const { inbox, refills, reports, voicemail, leads, resources, patients } = restrictedResources;
        const isUK = Config.environ === 'prod-uk';

        const handleRedirectToVow = () => {
            this.props.redirectToVow();
            // TODO: a different ticket -> https://rxwiki.atlassian.net/browse/ATLAS-2776
        };

        return (
            <>
                <Cell size={12} phoneHidden tabletHidden className="flex-middle">
                    <img
                        style={{ maxHeight: 48 }}
                        src="/dp-white-icon.png"
                        alt=""
                        className={npeLandingPageEnabled ? 'dp-toolbar-image' : ''}
                        onClick={() => npeLandingPageEnabled && history.push('/home')}
                    />

                    <Can I={view} this={inbox.subject}>
                        <NavLink to="/inbox" activeClassName="nav-active">
                            <Button flat className="top-menu-button">
                                <Badge
                                    variant="dot"
                                    overlap="circle"
                                    color="secondary"
                                    invisible={!unreadMessageCount}
                                    classes={{ badge: 'nav-badge accent-badge', root: 'top-menu-badge-button' }}
                                >
                                    Inbox
                                </Badge>
                            </Button>
                        </NavLink>
                    </Can>

                    <Can I={view} this={patients.subject}>
                        <NavLink to="/patients" activeClassName="nav-active">
                            <Button flat className="top-menu-button">
                                Patients
                            </Button>
                        </NavLink>
                    </Can>

                    <Can I={view} this={patients.subject}>
                        <NavLink to="/workflows" activeClassName="nav-active">
                            <Button flat className="top-menu-button">
                                <Badge
                                    variant="dot"
                                    overlap="circle"
                                    color="primary"
                                    invisible={!this.state.workflowsBadge}
                                    classes={{ badge: 'nav-badge accent-badge', root: 'top-menu-badge-button' }}
                                >
                                    Workflows
                                </Badge>
                            </Button>
                        </NavLink>
                    </Can>

                    <Can I={view} this={patients.subject}>
                        <NavLink to="/appointments" activeClassName="nav-active">
                            <Button flat className="top-menu-button">
                                <Badge
                                    variant="dot"
                                    overlap="circle"
                                    color="primary"
                                    invisible={true}
                                    classes={{ badge: 'nav-badge accent-badge', root: 'top-menu-badge-button' }}
                                >
                                    Appointments
                                </Badge>
                            </Button>
                        </NavLink>
                    </Can>

                    {isUK ? null : (
                        <Can I={view} this={reports.subject}>
                            <NavLink to="/reports" activeClassName="nav-active">
                                <Button flat className="top-menu-button">
                                    Reports
                                </Button>
                            </NavLink>
                        </Can>
                    )}

                    {isUK ? null : (
                        <Can I={view} this={voicemail.subject}>
                            {isVowIVREnabled && (
                                <Button flat className="top-menu-button" onClick={handleRedirectToVow}>
                                    IVR
                                </Button>
                            )}

                            {!isVowIVREnabled && (
                                <NavLink to="/voicemail" activeClassName="nav-active">
                                    <Button flat className="top-menu-button">
                                        <Badge
                                            variant="dot"
                                            overlap="circle"
                                            color="primary"
                                            invisible={!voicemailBadge}
                                            classes={{ badge: 'nav-badge', root: 'top-menu-badge-button' }}
                                        >
                                            Voicemail
                                        </Badge>
                                    </Button>
                                </NavLink>
                            )}
                        </Can>
                    )}

                    {isUK ? null : (
                        <Can I={view} this={refills.subject}>
                            <NavLink to="/refills" activeClassName="nav-active">
                                <Button flat className="top-menu-button">
                                    <Badge
                                        variant="dot"
                                        overlap="circle"
                                        color="primary"
                                        invisible={!refillsBadge}
                                        classes={{ badge: 'nav-badge', root: 'top-menu-badge-button' }}
                                    >
                                        Refills
                                    </Badge>
                                </Button>
                            </NavLink>
                        </Can>
                    )}

                    {isUK ? null : (
                        <Can I={view} this={leads.subject}>
                            <NavLink to="/leads" activeClassName="nav-active">
                                <Button flat className="top-menu-button">
                                    <Badge
                                        variant="dot"
                                        overlap="circle"
                                        color="primary"
                                        invisible={!leadBadge}
                                        classes={{ badge: 'nav-badge', root: 'top-menu-badge-button' }}
                                    >
                                        Leads
                                    </Badge>
                                </Button>
                            </NavLink>
                        </Can>
                    )}

                    {isUK ? null : (
                        <Can I={view} this={resources.subject}>
                            <ResourcesPopover tigerPistolEnabled={tigerPistolEnabled} />
                        </Can>
                    )}
                </Cell>
                <IVRRedirectErrorDialog />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isVowIVREnabled: state.pharmacy.vowIVREnabled,
});

const mapDispatchToProps = (dispatch) => ({
    redirectToVow: () => dispatch(redirectToVow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopMenuItems);
