import React, { Fragment } from 'react';

import TextContent from './TextContent';
import UnorderedList from './UnorderedList';

const Sections = ({ sections }) => (
    <Fragment>
        {sections.map((section) => (
            <Fragment key={section.title}>
                {section.type === 'text' ? <TextContent content={section} /> : <UnorderedList content={section} />}
                <br />
            </Fragment>
        ))}
    </Fragment>
);

export default Sections;
