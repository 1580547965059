export default {
    FETCH_AA_MAIN_MENU_STARTED: 'FETCH_AA_MAIN_MENU_STARTED',
    FETCH_AA_MAIN_MENU_SUCCESS: 'FETCH_AA_MAIN_MENU_SUCCESS',
    FETCH_AA_MAIN_MENU_FAILED: 'FETCH_AA_MAIN_MENU_FAILED',
    SET_AA_MENU_ITEM_CHANGES: 'SET_AA_MENU_ITEM_CHANGES',
    ADD_AA_MENU_ITEM: 'ADD_AA_MENU_ITEM',
    REMOVE_AA_MENU_ITEM_STARTED: 'REMOVE_AA_MENU_ITEM_STARTED',
    REMOVE_AA_MENU_ITEM_SUCCESS: 'REMOVE_AA_MENU_ITEM_SUCCESS',
    REMOVE_AA_MENU_ITEM_FAILED: 'REMOVE_AA_MENU_ITEM_FAILED',
    SAVE_AA_MAIN_MENU_STARTED: 'SAVE_AA_MAIN_MENU_STARTED',
    SAVE_AA_MAIN_MENU_SUCCESS: 'SAVE_AA_MAIN_MENU_SUCCESS',
    SAVE_AA_MAIN_MENU_FAILED: 'SAVE_AA_MAIN_MENU_FAILED',
    SET_AA_MENU_ITEM_IVRTRANSFER: 'SET_AA_MENU_ITEM_IVRTRANSFER',
};
