export default {
    ADD_DOCTOR_GREETING: 'ADD_DOCTOR_GREETING',
    UPDATE_DOCTOR_GREETING: 'UPDATE_DOCTOR_GREETING',
    CLOSE_DOCTOR_GREETING_EDITOR: 'CLOSE_DOCTOR_GREETING_EDITOR',
    SAVE_DOCTOR_GREETING_STARTED: 'SAVE_DOCTOR_GREETING_STARTED',
    SAVE_DOCTOR_GREETING_SUCCESS: 'SAVE_DOCTOR_GREETING_SUCCESS',
    SAVE_DOCTOR_GREETING_FAILED: 'SAVE_DOCTOR_GREETING_FAILED',
    EDIT_DOCTOR_GREETING: 'EDIT_DOCTOR_GREETING',
    CHANGE_DOCTOR_GREETING_TYPE: 'CHANGE_DOCTOR_GREETING_TYPE',
    CHANGE_DOCTOR_GREETING_SCRIPT: 'CHANGE_DOCTOR_GREETING_SCRIPT',
};
