import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import isEmpty from 'lodash/isEmpty';
import { string, bool, shape, func } from 'prop-types';

class TextboxDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            error: '',
        };
        this.setText = (text) => this.setState({ text });
        this.setError = (error) => this.setState({ error });
        this.clearError = () => this.setState({ error: '' });
        this.clearText = () => this.setState({ text: '' });
    }

    getValidateError = () => {
        let error = '';

        if (isEmpty(this.state.text)) {
            error = 'Please enter a value';
        }
        return error;
    };

    onClose = () => {
        const { onClose } = this.props;
        this.clearError();
        this.clearText();
        onClose();
    };

    onChange = (e) => {
        this.clearError();
        this.setText(e.target.value);
    };

    onSubmit = () => {
        const { onSubmit } = this.props;
        const error = this.getValidateError();

        if (isEmpty(error)) {
            onSubmit(this.state.text);
            // Since closing the dialog does not unmount it, clear the text
            this.clearText();
        } else {
            this.setError(error);
        }
    };

    render() {
        const {
            open,
            dialogBox: { title, label },
        } = this.props;
        return (
            <Dialog onClose={this.onClose} open={open}>
                <DialogTitle id="attribute-value-dialog">{title}</DialogTitle>
                <DialogContent>
                    <TextField
                        error={!isEmpty(this.state.error)}
                        required
                        autoFocus
                        fullWidth
                        margin="dense"
                        id="attribute-value"
                        label={label}
                        value={this.state.text}
                        onChange={this.onChange}
                        helperText={this.state.error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.onSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TextboxDialog.propTypes = {
    open: bool.isRequired,
    dialogBox: shape({
        label: string.isRequired,
        title: string.isRequired,
    }).isRequired,
    onSubmit: func.isRequired,
    onClose: func.isRequired,
};

export default TextboxDialog;
