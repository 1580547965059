/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'newsletter/';

export const newsletterTypes = {
    RESET_NEWSLETTER_STORE: `${typePrefix}RESET_NEWSLETTER_STORE`,

    SAVE_NEWSLETTER_SETTINGS_REQUEST: `${typePrefix}SAVE_NEWSLETTER_SETTINGS_REQUEST`,
    SAVE_NEWSLETTER_SETTINGS_SUCCESS: `${typePrefix}SAVE_NEWSLETTER_SETTINGS_SUCCESS`,
    SAVE_NEWSLETTER_SETTINGS_FAILED: `${typePrefix}SAVE_NEWSLETTER_SETTINGS_FAILED`,

    SET_DRAWER_STATE: `${typePrefix}SET_DRAWER_STATE`,
    SET_ACTIVE_SUBSCRIBER: `${typePrefix}SET_ACTIVE_SUBSCRIBER`,
    RESET_ACTIVE_SUBSCRIBER: `${typePrefix}RESET_ACTIVE_SUBSCRIBER`,

    ADD_SUBSCRIBER_REQUEST: `${typePrefix}ADD_SUBSCRIBER_REQUEST`,
    ADD_SUBSCRIBER_SUCCESS: `${typePrefix}ADD_SUBSCRIBER_SUCCESS`,
    ADD_SUBSCRIBER_FAILED: `${typePrefix}ADD_SUBSCRIBER_FAILED`,

    DELETE_SUBSCRIBER_REQUEST: `${typePrefix}DELETE_SUBSCRIBER_REQUEST`,
    DELETE_SUBSCRIBER_SUCCESS: `${typePrefix}DELETE_SUBSCRIBER_SUCCESS`,
    DELETE_SUBSCRIBER_FAILED: `${typePrefix}DELETE_SUBSCRIBER_FAILED`,

    FETCH_NEWSLETTER_SUBSCRIBER_REQUEST: `${typePrefix}FETCH_NEWSLETTER_SUBSCRIBER_REQUEST`,
    FETCH_NEWSLETTER_SUBSCRIBER_SUCCESS: `${typePrefix}FETCH_NEWSLETTER_SUBSCRIBER_SUCCESS`,
    FETCH_NEWSLETTER_SUBSCRIBER_FAILED: `${typePrefix}FETCH_NEWSLETTER_SUBSCRIBER_FAILED`,

    UPDATE_NEWSLETTER_SUBSCRIBER_STATE: `${typePrefix}UPDATE_NEWSLETTER_SUBSCRIBER_STATE`,

    FETCH_NEWSLETTER_HISTORY_REQUEST: `${typePrefix}FETCH_NEWSLETTER_HISTORY_REQUEST`,
    FETCH_NEWSLETTER_HISTORY_SUCCESS: `${typePrefix}FETCH_NEWSLETTER_HISTORY_SUCCESS`,
    FETCH_NEWSLETTER_HISTORY_FAILED: `${typePrefix}FETCH_NEWSLETTER_HISTORY_FAILED`,

    SET_ACTIVE_EVENT: `${typePrefix}SET_ACTIVE_EVENT`,
    RESET_ACTIVE_EVENT: `${typePrefix}RESET_ACTIVE_EVENT`,

    SET_ACTIVE_CARD: `${typePrefix}SET_ACTIVE_CARD`,
    RESET_ACTIVE_CARD: `${typePrefix}RESET_ACTIVE_CARD`,

    FETCH_NEWSLETTER_DETAILS_REQUEST: `${typePrefix}FETCH_NEWSLETTER_DETAILS_REQUEST`,
    FETCH_NEWSLETTER_DETAILS_SUCCESS: `${typePrefix}FETCH_NEWSLETTER_DETAILS_SUCCESS`,
    FETCH_NEWSLETTER_DETAILS_FAILED: `${typePrefix}FETCH_NEWSLETTER_DETAILS_FAILED`,

    SET_ACTIVE_NEWSLETTER: `${typePrefix}SET_ACTIVE_NEWSLETTER`,
    RESET_ACTIVE_NEWSLETTER: `${typePrefix}RESET_ACTIVE_NEWSLETTER`,

    FETCH_CARDS_REQUEST: `${typePrefix}FETCH_CARDS_REQUEST`,
    FETCH_CARDS_SUCCESS: `${typePrefix}FETCH_CARDS_SUCCESS`,
    FETCH_CARDS_FAILED: `${typePrefix}FETCH_CARDS_FAILED`,

    RESET_CARDS: `${typePrefix}RESET_CARDS`,

    ADD_CARDS_REQUEST: `${typePrefix}ADD_CARDS_REQUEST`,
    ADD_CARDS_SUCCESS: `${typePrefix}ADD_CARDS_SUCCESS`,
    ADD_CARDS_FAILED: `${typePrefix}ADD_CARDS_FAILED`,

    FETCH_NEWSLETTER_REQUEST: `${typePrefix}FETCH_NEWSLETTER_REQUEST`,
    FETCH_NEWSLETTER_SUCCESS: `${typePrefix}FETCH_NEWSLETTER_SUCCESS`,
    FETCH_NEWSLETTER_FAILED: `${typePrefix}FETCH_NEWSLETTER_FAILED`,

    SAVE_EVENT_DETAILS_REQUEST: `${typePrefix}SAVE_EVENT_DETAILS_REQUEST`,
    SAVE_EVENT_DETAILS_SUCCESS: `${typePrefix}SAVE_EVENT_DETAILS_SUCCESS`,
    SAVE_EVENT_DETAILS_FAILED: `${typePrefix}SAVE_EVENT_DETAILS_FAILED`,

    SET_DELETE_CARD_STATE: `${typePrefix}SET_DELETE_CARD_STATE`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
