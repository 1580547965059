import React, { Component, Fragment } from 'react';
import { Grid, Cell, Card, CardText, CircularProgress, Button, SelectField } from 'react-md';
import { connect } from 'react-redux';
import { TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import goBackService from 'utils/goBackService';
import {
    fetchGreetingWithHuntGroup,
    fetchHuntGroup,
    saveHuntGroup,
    addHuntGroup,
    setHuntGroupChanges,
    changeOverflowSetting,
    removeGroup,
    deleteHuntGroup,
    saveOverflowSetting,
} from 'redux/actionCreators/Settings/SpeakWithPharmacist';
import { pharmacyAction } from 'redux/Pharmacy/action';
import { getPharmacyPackage, getLocationPackageAttribute } from 'utils/helper';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { PhoneNumberMask } from 'utils/mask';
import { toDigits } from 'utils/helper';
import config from 'config';
import { userActions, restrictedResources, AbilityContext } from 'casl';

const AWS_HUNTGROUP_LIMIT = 10;

export class PhoneTransfer extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.timeoutList = [15, 30, 45, 60];
        this.huntGroupToSave = [];
    }

    componentDidMount() {
        const { fetchGreetingWithHuntGroup } = this.props;

        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);
        fetchGreetingWithHuntGroup(canCreateGreeting);
    }

    componentDidUpdate(prevProps) {
        const { auth, fetchGreetingWithHuntGroup } = this.props;
        if (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id) {
            const { create } = userActions;
            const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
            const canCreateGreeting = this.context.can(create, greetingSubject);
            fetchGreetingWithHuntGroup(canCreateGreeting);
        }
    }

    onSave = () => {
        const { displayToast } = this.props;
        if (this.validate()) {
            this.saveHuntGroup();
        } else {
            displayToast({ text: 'Fill the details', type: 'error' });
        }
    };

    onBack = () => {
        const { history, ivrEnabled, callTrackingEnabled } = this.props;
        if (!ivrEnabled && !callTrackingEnabled) {
            history.push('/settings/general/features');
        } else {
            goBackService.toIVRHome(history);
        }
    };

    validate = () => {
        const {
            speakWithPharmacist: { huntGroups },
            setHuntGroupChanges,
        } = this.props;
        let isValid = true;
        this.huntGroupToSave = [];
        for (let grpIdx = 0; grpIdx < huntGroups.length; grpIdx++) {
            if (huntGroups[grpIdx].phone === '') {
                huntGroups[grpIdx].error = 'Required';
            } else if (toDigits(huntGroups[grpIdx].phone).length < 10) {
                huntGroups[grpIdx].error = 'Enter a 10 digit phone number';
            }

            if (isValid && huntGroups[grpIdx].error) {
                isValid = false;
            }
            huntGroups[grpIdx].phone = toDigits(huntGroups[grpIdx].phone);
            this.huntGroupToSave.push(huntGroups[grpIdx]);
        }

        if (!isValid) {
            setHuntGroupChanges({ huntGroups });
        }
        return isValid;
    };

    handleInputChange = (e, idx) => {
        const {
            speakWithPharmacist: { huntGroups },
            setHuntGroupChanges,
        } = this.props;
        huntGroups[idx].phone = e.target.value;
        huntGroups[idx].error = '';
        if (!e.target.value) {
            huntGroups[idx].error = 'Required';
        }
        setHuntGroupChanges({ huntGroups });
    };

    handleTimeoutChange = (e, idx) => {
        const {
            speakWithPharmacist: { huntGroups },
            setHuntGroupChanges,
        } = this.props;
        huntGroups[idx].maxSeconds = e.target.value;
        setHuntGroupChanges({ huntGroups });
    };

    saveHuntGroup() {
        const {
            speakWithPharmacist: { huntGroups, overflowSetting },
            saveHuntGroup,
            fetchHuntGroup,
            saveOverflowSetting,
        } = this.props;

        if (huntGroups.length) {
            saveHuntGroup({ id: overflowSetting.id, huntGroups: this.huntGroupToSave }).then((saveResp) => {
                if (!saveResp.err) {
                    fetchHuntGroup(overflowSetting);
                }
            });
        }

        const newGreeting = { id: overflowSetting.id, xfer_end_type_id: overflowSetting.xfer_end_type_id };

        saveOverflowSetting({ newGreeting, type: 'MAIN' }).then((resp) => {
            if (!resp.err) {
                displayToast({ text: 'Overflow Settings saved', type: 'success' });
            }
        });
    }

    deleteGroup = (details) => {
        const {
            removeGroup,
            deleteHuntGroup,
            saveHuntGroup,
            fetchHuntGroup,
            ivrEnabled,
            speakWithPharmacist: { huntGroups, overflowSetting },
            displayToast,
            callTrackingEnabled,
        } = this.props;

        if (details.huntGroup.id === '0') {
            removeGroup(details);
        } else {
            if (huntGroups.length === 1 && !ivrEnabled) {
                displayToast({ text: 'This is a required component and cannot be deleted.', type: 'info' });
            } else {
                deleteHuntGroup({ ...details, overflowSetting }).then((resp) => {
                    if (!resp.err) {
                        const newHuntGroups = [];
                        let cnt = 1;
                        huntGroups.forEach((hGrp) => {
                            if (details.huntGroup.id !== hGrp.id && hGrp.id !== '0') {
                                newHuntGroups.push(hGrp);
                                newHuntGroups[newHuntGroups.length - 1].seq = cnt;
                                cnt++;
                            }
                        });
                        if (newHuntGroups.length) {
                            saveHuntGroup({ id: overflowSetting.id, huntGroups: newHuntGroups }).then((saveResp) => {
                                if (!saveResp.err) {
                                    fetchHuntGroup(overflowSetting);
                                }
                            });
                        } else {
                            if (callTrackingEnabled) {
                                this.disableCallTracking();
                            }
                        }
                    }
                });
            }
        }
    };

    disableCallTracking = () => {
        const packageCode = 'ICFG';
        const attributeName = 'CallTrackingEnabled';
        const {
            pharmacy: { pharmacy: pharmacyData },
        } = this.props;

        const IVRPackage = getPharmacyPackage(packageCode, pharmacyData);
        const IVRPackageAttribute = getLocationPackageAttribute(
            packageCode,
            config.X_LocationID,
            attributeName,
            pharmacyData
        );

        const packageId = IVRPackage.id;
        const attributeId = IVRPackageAttribute.id;
        const attributeData = IVRPackageAttribute;
        const value = false;

        const { createPackageAttribute, updatePackageAttribute, displayToast } = this.props;

        // Note: no attributeId will exist if using inherited default value - need to create instead of update
        if (!attributeId) {
            const attributePayload = _.cloneDeep(attributeData);
            attributePayload.value = value;

            // Will return 400 error if package already exists (might occur if local config state got stale)
            createPackageAttribute(packageId, attributePayload).then(
                () => {
                    displayToast({ text: 'Call Tracking deactivate successful', type: 'success' });
                },
                (error) => {
                    displayToast({ text: 'Call Tracking deactivate failed', type: 'error' });
                }
            );
        } else {
            updatePackageAttribute(packageId, attributeId, value).then(
                () => {
                    displayToast({ text: 'Call Tracking deactivate successful', type: 'success' });
                },
                (error) => {
                    displayToast({ text: 'Call Tracking deactivate failed', type: 'error' });
                }
            );
        }
    };

    isHuntGroupAddBtnVisible = () => {
        const {
            ivrEnabled,
            speakWithPharmacist: { huntGroups },
        } = this.props;

        if (!ivrEnabled) {
            return huntGroups.length === 0;
        } else {
            return huntGroups.length < AWS_HUNTGROUP_LIMIT;
        }
    };

    render() {
        const {
            speakWithPharmacist: {
                huntGroups,
                huntGroupLoading,
                savingInProgress,
                overflowSetting,
                savingOverflowSetting,
            },
            changeOverflowSetting,
            addHuntGroup,
            ivrEnabled,
        } = this.props;
        const { write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.callTransferDuringHours;
        // Note, write here means the user role can create, update, delete call transfer during hours
        const canWriteTransferHours = this.context.can(write, caslSubject);

        return (
            <Grid className="hunt-group-container">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Call Transfer  During Hours"
                            disabled={savingInProgress || savingOverflowSetting}
                            loading={savingInProgress || savingOverflowSetting}
                            onBack={this.onBack}
                            onSave={canWriteTransferHours && this.onSave}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="Add your phone lines" />
                                    <SectionSubTitle
                                        text="The IVR will send calls to these numbers if a caller chooses
                    the option to transfer to the pharmacy during pharmacy hours"
                                    />
                                    {huntGroupLoading ? (
                                        <CircularProgress id="hunt-group-spinner" />
                                    ) : (
                                        <div className="hunt-group-container">
                                            {huntGroups.map((huntGroup, idx) => (
                                                <div key={idx} style={{ marginTop: idx ? '14px' : '0' }}>
                                                    <div className="grid-container">
                                                        <div className="grid-cell-12 section-title">
                                                            Line {huntGroup.seq}
                                                        </div>
                                                    </div>
                                                    <div className="grid-container">
                                                        <Cell size={3} className="phoneno">
                                                            <TextField
                                                                id="outlined-pno"
                                                                name="phone"
                                                                label="Phone Number"
                                                                disabled={!canWriteTransferHours || savingInProgress}
                                                                value={
                                                                    toDigits(huntGroup.phone).length > 10
                                                                        ? huntGroup.phone.substring(1)
                                                                        : huntGroup.phone
                                                                }
                                                                onChange={(e) => this.handleInputChange(e, idx)}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    inputComponent: PhoneNumberMask,
                                                                }}
                                                                error={'error' in huntGroup && huntGroup.error !== ''}
                                                                fullWidth
                                                            />
                                                        </Cell>

                                                        <Cell size={3} className="timeout">
                                                            <FormControl variant="outlined" fullWidth>
                                                                <InputLabel htmlFor="outlined-timeout">
                                                                    Timeout
                                                                </InputLabel>
                                                                <Select
                                                                    id="select-seconds"
                                                                    value={huntGroup.maxSeconds}
                                                                    disabled={
                                                                        !canWriteTransferHours || savingInProgress
                                                                    }
                                                                    onChange={(e) => this.handleTimeoutChange(e, idx)}
                                                                    input={
                                                                        <OutlinedInput
                                                                            fullWidth
                                                                            labelWidth={52}
                                                                            name="timeout"
                                                                            id="outlined-timeout"
                                                                        />
                                                                    }
                                                                >
                                                                    {this.timeoutList.map((tVal, tIdx) => (
                                                                        <MenuItem
                                                                            key={`timeout${tIdx}`}
                                                                            value={tVal}
                                                                        >{`${tVal} sec`}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Cell>
                                                        {canWriteTransferHours && (
                                                            <Cell size={1} className="flex-middle">
                                                                {huntGroup.processing ? (
                                                                    <CircularProgress id="remove-huntgroup-spinner" />
                                                                ) : (
                                                                    <Button
                                                                        id="delete-group-btn"
                                                                        icon
                                                                        onClick={() =>
                                                                            this.deleteGroup({ huntGroup, idx })
                                                                        }
                                                                    >
                                                                        delete
                                                                    </Button>
                                                                )}
                                                            </Cell>
                                                        )}
                                                    </div>
                                                    <FormHelperText error style={{ marginTop: '0' }}>
                                                        {huntGroup.error}
                                                    </FormHelperText>
                                                </div>
                                            ))}

                                            {huntGroups.length === 0 && (
                                                <div className="grid-container">
                                                    <Cell size={12} className="centered-content no-hunt-grp-msg">
                                                        No Hunt Group Configured
                                                    </Cell>
                                                </div>
                                            )}

                                            {canWriteTransferHours ? (
                                                this.isHuntGroupAddBtnVisible() && (
                                                    <div className="grid-container add-line-btn">
                                                        <div className="grid-cell-12 button-control">
                                                            <Button
                                                                className="add-btn"
                                                                flat
                                                                secondary
                                                                onClick={addHuntGroup}
                                                            >
                                                                + ADD ANOTHER LINE
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="grid-container">
                                                    <Cell size={12} className="centered-content">
                                                        You don't have permission to add/update Hunt Group
                                                    </Cell>
                                                </div>
                                            )}
                                            {ivrEnabled && (
                                                <Fragment>
                                                    <SectionTitle text="Overflow Management" />
                                                    <SectionSubTitle text="This is where your users will go if none of the above numbers answer the call" />
                                                    <div className="grid-container">
                                                        <Cell
                                                            size={3}
                                                            phoneSize={3}
                                                            className="bordered-select standard-margin-top-10"
                                                        >
                                                            <SelectField
                                                                id="select-overflow"
                                                                placeholder="Select a Item"
                                                                disabled={!canWriteTransferHours || savingInProgress}
                                                                fullWidth
                                                                position={SelectField.Positions.BELOW}
                                                                itemLabel="name"
                                                                itemValue="id"
                                                                menuItems={[
                                                                    { id: 1, name: 'Voice Mail' },
                                                                    { id: 2, name: 'Main Menu' },
                                                                ]}
                                                                value={overflowSetting.xfer_end_type_id}
                                                                onChange={(value) => changeOverflowSetting(value)}
                                                                simplifiedMenu={false}
                                                            />
                                                        </Cell>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    )}
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    speakWithPharmacist: { ...state.settings.speakWithPharmacists },
    auth: { ...state.auth },
    pharmacy: state.pharmacy,
    callTrackingEnabled: state.pharmacy.callTrackingEnabled,
    ivrEnabled: state.pharmacy.ivrEnabled,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGreetingWithHuntGroup: (data) => dispatch(fetchGreetingWithHuntGroup(data)),
    fetchHuntGroup: (data) => dispatch(fetchHuntGroup(data)),
    saveOverflowSetting: (data) => dispatch(saveOverflowSetting(data)),
    setHuntGroupChanges: (data) => dispatch(setHuntGroupChanges(data)),
    addHuntGroup: () => dispatch(addHuntGroup()),
    changeOverflowSetting: (data) => dispatch(changeOverflowSetting(data)),
    saveHuntGroup: (data) => dispatch(saveHuntGroup(data)),
    removeGroup: (data) => dispatch(removeGroup(data)),
    deleteHuntGroup: (data) => dispatch(deleteHuntGroup(data)),
    createPackageAttribute: (packageId, attributePayload) => {
        return dispatch(pharmacyAction.createPackageAttribute(packageId, attributePayload));
    },
    updatePackageAttribute: (packageId, attributeId, updatedValue) => {
        return dispatch(pharmacyAction.updatePackageAttribute(packageId, attributeId, updatedValue));
    },
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneTransfer);
