import React, { Fragment } from 'react';
import { Cell, FontIcon, CircularProgress } from 'react-md';
import { Button, Tooltip } from '@material-ui/core';

import { SectionTitle, SectionSubTitle } from 'components/Common';

const ConfigurableService = (props) => {
    const navigate = () => {
        props.history.push({ pathname: props.pathname, state: props.history.location.state });
    };

    return (
        <div className="grid-container">
            <Cell size={10}>
                <SectionTitle text={props.title} />
                <SectionSubTitle text={props.description} />
            </Cell>

            <Cell size={2} className="flex-middle flex-right">
                {props.loading ? (
                    <CircularProgress id="transfer-cnt-loader" />
                ) : (
                    <Fragment>
                        <Button variant="outlined" color="primary" onClick={navigate}>
                            Configure
                        </Button>
                        <Tooltip
                            classes={{ tooltip: 'info-tooltip' }}
                            title={props.tooltipText}
                            placement="top"
                            enterTouchDelay={0}
                        >
                            <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                                info
                            </FontIcon>
                        </Tooltip>
                    </Fragment>
                )}
            </Cell>
        </div>
    );
};

export default ConfigurableService;
