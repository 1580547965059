import React, { Component, Fragment } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import 'react-placeholder/lib/reactPlaceholder.css';
import ReactPlaceholder from 'react-placeholder';
import Snowplow from '../../snowplow';
import Config from 'config';

class NoContactSection extends Component {
    startAddContactTour = () => {
        window.Intercom('startTour', Config.intercom_inbox_tour_id);
        Snowplow.structEvent('Inbox', 'inbox-tour-started', null);
    };

    render() {
        const { loadingContacts } = this.props;

        return (
            <Fragment>
                {loadingContacts ? (
                    <Fragment>
                        <div className="contact-header-bar">
                            <div className="contact-header-bar__avatar">
                                <ReactPlaceholder
                                    type="round"
                                    ready={false}
                                    color="#E0E0E0"
                                    showLoadingAnimation
                                    style={{ width: 64, height: 64 }}
                                >
                                    <div></div>
                                </ReactPlaceholder>
                            </div>
                            <div className="contact-header-bar__details">
                                <div className="contact-header-bar__details-name">
                                    <ReactPlaceholder
                                        type="textRow"
                                        delay={5000}
                                        showLoadingAnimation
                                        color="#E0E0E0"
                                        ready={false}
                                        style={{ width: '40%' }}
                                    >
                                        <div></div>
                                    </ReactPlaceholder>
                                </div>
                                <div className="contact-header-bar__details-meta">
                                    <ReactPlaceholder
                                        type="textRow"
                                        delay={5000}
                                        style={{ width: '40%' }}
                                        showLoadingAnimation
                                        color="#E0E0E0"
                                        ready={false}
                                    >
                                        <div></div>
                                    </ReactPlaceholder>
                                </div>
                            </div>
                            <div className="contact-header-bar__conversation">
                                <ReactPlaceholder
                                    type="rect"
                                    style={{ width: 220, height: 40 }}
                                    delay={5000}
                                    showLoadingAnimation
                                    ready={false}
                                >
                                    <div></div>
                                </ReactPlaceholder>
                            </div>
                        </div>
                        <Grid container style={{ padding: '0 16px' }}>
                            <Grid item xs={12} md={12}>
                                <div className="contact-conversations" style={{ padding: '0 16px' }}>
                                    <div className="contact-section-title">
                                        <Typography
                                            variant="overline"
                                            color="inherit"
                                            style={{
                                                fontWeight: 500,
                                                fontSize: '12px',
                                            }}
                                        >
                                            Conversations
                                        </Typography>
                                    </div>
                                    <div className="contact-conversations-list">
                                        <div className="contact-conversation-item">
                                            <ReactPlaceholder
                                                type="rect"
                                                color="#E0E0E0"
                                                style={{ height: 90 }}
                                                showLoadingAnimation
                                                ready={false}
                                            >
                                                <div></div>
                                            </ReactPlaceholder>
                                        </div>
                                        <div className="contact-conversation-item">
                                            <ReactPlaceholder
                                                type="rect"
                                                color="#E0E0E0"
                                                style={{ height: 90 }}
                                                showLoadingAnimation
                                                ready={false}
                                            >
                                                <div></div>
                                            </ReactPlaceholder>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="no-contact-container">
                            <PersonAddIcon className="contact-conversation-list__empty__icon" />
                            <Typography
                                variant="h6"
                                style={{
                                    color: '#ABABAB',
                                    textAlign: 'center',
                                    maxWidth: '320px',
                                    fontWeight: '400',
                                }}
                            >
                                You don't have any contacts. Add a contact or learn more to get started.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ maxWidth: '50%', marginTop: '25px' }}
                                onClick={this.startAddContactTour}
                            >
                                Learn about Inbox
                            </Button>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default NoContactSection;
