const newGreetingTemplate = {
    greeting_id: '',
    greeting_url: '',
};

const GREETING_TYPE = {
    CUSTOM_TEXT: 3,
    CUSTOM_AUDIO: 2,
    DEFAULT: 1,
};

export { newGreetingTemplate, GREETING_TYPE };
