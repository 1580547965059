import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { getFormattedMailupDate } from 'utils/helper';
import ReactPlaceholder from 'react-placeholder';

const Placeholder = () => (
    <Grid container spacing={2} style={{ marginTop: '24px ' }}>
        {[1, 2, 3, 4, 5].map((item) => (
            <ReactPlaceholder
                showLoadingAnimation
                type="textRow"
                rows={5}
                ready={false}
                key={item}
                style={{ marginBottom: '5px' }}
            >
                <span></span>
            </ReactPlaceholder>
        ))}
    </Grid>
);

const NewsletterDetails = (props) => {
    const { date_sent, email, message_id, recipients, bounces, unsubscribes, loadingNewsletterDetails } = props;

    return (
        <div className="newsletter-details">
            <Typography className="title"> eNewsletter Details </Typography>
            {loadingNewsletterDetails ? (
                <Placeholder />
            ) : (
                <Grid justify="center" container spacing={2} style={{ marginTop: '24px' }}>
                    <Grid item xs={12} className="flex-space-between">
                        <Typography> Date Sent </Typography>
                        <Typography> {getFormattedMailupDate(date_sent)} </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex-space-between">
                        <Typography> Email </Typography>
                        <Typography className="ellipsis" title={email}>
                            {email}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex-space-between" style={{ marginBottom: '50px' }}>
                        <Typography> Message ID </Typography>
                        <Typography> {message_id} </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex-space-between">
                        <Typography> Recipients </Typography>
                        <Typography> {recipients} </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex-space-between">
                        <Typography> Bounces </Typography>
                        <Typography> {bounces} </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex-space-between">
                        <Typography> Unsubscribes </Typography>
                        <Typography> {unsubscribes} </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.newsletter.activeNewsletter,
    loadingNewsletterDetails: state.newsletter.loadingNewsletterDetails,
});

export default connect(mapStateToProps, null)(NewsletterDetails);
