export const SORT_MODE = {
    DATE: 'date',
    CHANNEL: 'source_name',
    CAMPAIGN: 'campaign',
    PATIENT_NAME: 'customer_name',
    PHONE: 'customer_phone_number',
};

export const MAX_RECORDS = 25;

export const LEAD_STATUS = {
    NEW_LEADS: 'new_leads',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    UNASSIGNED: 'unassigned',
};

export const LEADS_STATUS_OPTIONS = {
    [LEAD_STATUS.NEW_LEADS]: 'New Lead',
    [LEAD_STATUS.IN_PROGRESS]: 'In Progress',
    [LEAD_STATUS.COMPLETED]: 'Completed',
};

export default SORT_MODE;
