import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { copyTextToClipboard } from 'utils/helper';

const FormRowTextFields = ({ field1, field2, handleTextChange, displayToast }) => {
    const [contentVisibility, setContentVisibility] = React.useState({ field1: false, field2: false });
    const field1Id = `text-field-1-${field1.key}`;
    const field2Id = `text-field-2-${field2.key}`;

    const toggleContentVisibility = (key) => {
        setContentVisibility({ ...contentVisibility, [key]: !contentVisibility[key] });
    };

    const copyToClipboard = (elementId) => {
        copyTextToClipboard(elementId);
        displayToast({
            text: 'Copied to clipboard',
            type: 'info',
        });
    };

    const VisibilityAdornment = ({ field }) => (
        <InputAdornment position="end">
            <IconButton aria-label="Toggle content visibility" onClick={() => toggleContentVisibility(field)}>
                {contentVisibility[field] ? (
                    <Tooltip title="Hide content">
                        <VisibilityOff />
                    </Tooltip>
                ) : (
                    <Tooltip title="Show content">
                        <Visibility />
                    </Tooltip>
                )}
            </IconButton>
        </InputAdornment>
    );

    return (
        <React.Fragment>
            <Grid item xs={5}>
                <TextField
                    type={field1.isPassword && !contentVisibility.field1 ? 'password' : 'text'}
                    id={field1Id}
                    name={field1.key}
                    label={field1.label}
                    error={!!(!field1.disabled && field1.error)}
                    value={field1.value || ''}
                    onChange={!field1.disabled ? handleTextChange : undefined}
                    fullWidth
                    required={!field1.disabled && field1.required}
                    variant="outlined"
                    multiline={field1.rows ? true : false}
                    rows={field1.rows}
                    helperText={!field1.disabled && field1.error}
                    disabled={field1.disabled}
                    InputProps={
                        field1.isPassword
                            ? {
                                  endAdornment: <VisibilityAdornment field="field1" />,
                              }
                            : {}
                    }
                />
            </Grid>

            {field1.allowTextCopy && !_.isEmpty(field1.value) ? (
                <Grid item xs={1} className="flex-middle">
                    <Tooltip title="Copy to clipboard">
                        <IconButton
                            aria-label="copy to clipboard"
                            onClick={() => copyToClipboard(field1Id)}
                            color="primary"
                        >
                            <AssignmentIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            ) : (
                <Grid item xs={1} />
            )}

            <Grid item xs={5}>
                <TextField
                    type={field2.isPassword && !contentVisibility.field2 ? 'password' : 'text'}
                    id={field2Id}
                    name={field2.key}
                    label={field2.label}
                    error={!!(!field2.disabled && field2.error)}
                    value={field2.value || ''}
                    onChange={!field2.disabled ? handleTextChange : undefined}
                    fullWidth
                    required={!field2.disabled && field2.required}
                    variant="outlined"
                    multiline={field2.rows ? true : false}
                    rows={field2.rows}
                    helperText={!field2.disabled && field2.error}
                    disabled={field2.disabled}
                    InputProps={
                        field2.isPassword
                            ? {
                                  endAdornment: <VisibilityAdornment field="field2" />,
                              }
                            : {}
                    }
                />
            </Grid>

            {field2.allowTextCopy && !_.isEmpty(field2.value) ? (
                <Grid item xs={1}>
                    <Tooltip title="Copy to clipboard">
                        <IconButton
                            aria-label="copy to clipboard"
                            onClick={() => copyToClipboard(field2Id)}
                            color="primary"
                        >
                            <AssignmentIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            ) : (
                <Grid item xs={1} />
            )}
        </React.Fragment>
    );
};

const bindActionsToDispatch = {
    displayToast: displayToast,
};

export default connect(null, bindActionsToDispatch)(FormRowTextFields);
