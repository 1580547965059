const subjectTypePrefix = 'Voicemail';

export const restrictedVoicemailResources = {
    voicemail: {
        subject: subjectTypePrefix,
        fields: {
            play: 'Play',
            markAsRead: 'Mark as Read',
            download: 'Download',
        },
    },
};
