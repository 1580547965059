import authType from '../actions/Auth';
import { userManagementActions } from './action';

export const userManagementMiddleware = (store) => (next) => (action) => {
    if (action.type === authType.UPDATE_CUSTOMER) {
        const {
            auth: { isAuthenticated },
        } = store.getState();

        if (isAuthenticated) {
            store.dispatch(userManagementActions.resetUserManagementStore());
        }
    }

    next(action);
};
