const typePrefix = 'apptReservation/';

const apptReservationDataTypes = {
    CREATE_RESERVATION_REQUEST: `${typePrefix}CREATE_RESERVATION_REQUEST`,
    CREATE_RESERVATION_SUCCESS: `${typePrefix}CREATE_RESERVATION_SUCCESS`,
    CREATE_RESERVATION_FAILURE: `${typePrefix}CREATE_RESERVATION_FAILURE`,

    UPDATE_RESERVATION_REQUEST: `${typePrefix}UPDATE_RESERVATION_REQUEST`,
    UPDATE_RESERVATION_SUCCESS: `${typePrefix}UPDATE_RESERVATION_SUCCESS`,
    UPDATE_RESERVATION_FAILURE: `${typePrefix}UPDATE_RESERVATION_FAILURE`,

    CHECKIN_RESERVATION_REQUEST: `${typePrefix}CHECKIN_RESERVATION_REQUEST`,
    CHECKIN_RESERVATION_SUCCESS: `${typePrefix}CHECKIN_RESERVATION_SUCCESS`,
    CHECKIN_RESERVATION_FAILURE: `${typePrefix}CHECKIN_RESERVATION_FAILURE`,

    CREATE_RESERVATION_SESSION_REQUEST: `${typePrefix}CREATE_RESERVATION_SESSION_REQUEST`,
    CREATE_RESERVATION_SESSION_SUCCESS: `${typePrefix}CREATE_RESERVATION_SESSION_SUCCESS`,
    CREATE_RESERVATION_SESSION_FAILURE: `${typePrefix}CREATE_RESERVATION_SESSION_FAILURE`,

    UPDATE_RESERVATION_SESSION_REQUEST: `${typePrefix}UPDATE_RESERVATION_SESSION_REQUEST`,
    UPDATE_RESERVATION_SESSION_SUCCESS: `${typePrefix}UPDATE_RESERVATION_SESSION_SUCCESS`,
    UPDATE_RESERVATION_SESSION_FAILURE: `${typePrefix}UPDATE_RESERVATION_SESSION_FAILURE`,

    GET_AVAILABLE_APPOINTMENT_SLOTS_REQUEST: `${typePrefix}GET_AVAILABLE_APPOINTMENT_SLOTS_REQUEST`,
    GET_AVAILABLE_APPOINTMENT_SLOTS_SUCCESS: `${typePrefix}GET_AVAILABLE_APPOINTMENT_SLOTS_SUCCESS`,
    GET_AVAILABLE_APPOINTMENT_SLOTS_FAILURE: `${typePrefix}GET_AVAILABLE_APPOINTMENT_SLOTS_FAILURE`,

    CHOOSE_WAITLIST_PATIENTS_BY_COUNT_REQUEST: `${typePrefix}CHOOSE_WAITLIST_PATIENTS_BY_COUNT_REQUEST`,
    CHOOSE_WAITLIST_PATIENTS_BY_COUNT_SUCCESS: `${typePrefix}CHOOSE_WAITLIST_PATIENTS_BY_COUNT_SUCCESS`,
    CHOOSE_WAITLIST_PATIENTS_BY_COUNT_FAILURE: `${typePrefix}CHOOSE_WAITLIST_PATIENTS_BY_COUNT_FAILURE`,

    GET_LIVE_REUSABLE_RESERVATION_SESSIONS_REQUEST: `${typePrefix}GET_LIVE_REUSABLE_RESERVATION_SESSIONS_REQUEST`,
    GET_LIVE_REUSABLE_RESERVATION_SESSIONS_SUCCESS: `${typePrefix}GET_LIVE_REUSABLE_RESERVATION_SESSIONS_SUCCESS`,
    GET_LIVE_REUSABLE_RESERVATION_SESSIONS_FAILURE: `${typePrefix}GET_LIVE_REUSABLE_RESERVATION_SESSIONS_FAILURE`,

    GET_RESERVATIONS_FOR_INBOX_USER_ID_REQUEST: `${typePrefix}GET_RESERVATIONS_FOR_INBOX_USER_ID_REQUEST`,
    GET_RESERVATIONS_FOR_INBOX_USER_ID_SUCCESS: `${typePrefix}GET_RESERVATIONS_FOR_INBOX_USER_ID_SUCCESS`,
    GET_RESERVATIONS_FOR_INBOX_USER_ID_FAILURE: `${typePrefix}GET_RESERVATIONS_FOR_INBOX_USER_ID_FAILURE`,

    RESET_RESERVATIONS: `${typePrefix}RESET_RESERVATIONS`,

    UPDATE_LIVE_REUSABLE_RESERVATION_SESSIONS_FROM_RESPONSE: `${typePrefix}UPDATE_LIVE_REUSABLE_RESERVATION_SESSIONS_FROM_RESPONSE`,
};

export default apptReservationDataTypes;
