import React from 'react';
import Typography from '@material-ui/core/Typography';

export const Title = ({ title, subtitle, className }) => (
    <div className={`flex-vertical-space-between title-wrapper ${className}`}>
        <div className="flex-middle">
            <Typography variant="subtitle2" className="title">
                {title}
            </Typography>
        </div>
        {subtitle && (
            <div className="flex-middle flex-center">
                <Typography variant="caption" className="subtitle">
                    {subtitle}
                </Typography>
            </div>
        )}
    </div>
);
