import React, { Component } from 'react';
import { TextField } from 'react-md';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

// TODO: This can be refactored, there seems to be duplicate code in this directory and in ../ROICalculatorPage
class EditableBackendValues extends Component {
    handleChange = (value, event) => {
        this.props.valueChange(value, event);
    };

    // Within TextField, we have to convert the props to strings using .toString() for the value
    // attribute because there are certain numbers that are seen as identical by React's diffing engine
    // such as 012 and 12. Since strings are immutable, generating a new string guarantees a rerender
    render() {
        const { usingPharmacyData, toggleUsePharmacyData, ldValueCalculatorEnabled } = this.props;
        const buttonText = usingPharmacyData ? 'Manually Enter Data' : 'Use My Data';
        const tooltipText = usingPharmacyData
            ? 'Click to enter the data manually'
            : 'Click to load the pharmacy data for past 30 days';

        return (
            <div className="ROICalculator__EditableBackendValues">
                <div className="ROICalculator__EditableBackendValues--title">Step 1: Add Data</div>
                <div className="ROICalculator__EditableBackendValues--data">
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="refillCountValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.refillCountValue.toString()}
                                onChange={this.handleChange}
                                disabled={usingPharmacyData}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">Refill Count</div>
                    </div>
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="newPatientTransfersValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.newPatientTransfersValue.toString()}
                                onChange={this.handleChange}
                                disabled={usingPharmacyData}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">New Patient Transfers</div>
                    </div>
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="outboundAlertsValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.outboundAlertsValue.toString()}
                                onChange={this.handleChange}
                                disabled={usingPharmacyData}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">Outbound Alerts</div>
                    </div>
                    {ldValueCalculatorEnabled && (
                        <div>
                            <Tooltip
                                placement="bottom"
                                enterTouchDelay={0}
                                classes={{ tooltip: 'info-tooltip' }}
                                title={tooltipText}
                            >
                                <Button fullWidth color="primary" variant="contained" onClick={toggleUsePharmacyData}>
                                    {buttonText}
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default EditableBackendValues;
