import moment from 'moment';
import _ from 'lodash';
import { logUtil } from 'utils/log';

import Actions from 'redux/actions';

const initialState = {
    digitalPlatformReports: {
        isLoading: false,
        months: [],
        last7Days: {},
        last30Days: {},
    },
    communicationsPlatformReports: {
        isLoading: false,
    },
    mobileDownloadsReports: {},
    newsletterReports: {},
    digitalPlatformPatientsReports: {
        isLoading: false,
        yesterday: {},
        last30Days: {},
        last90Days: {},
        allTime: {},
    },
    digitalMarketingReports: {
        last7Days: {},
        last30Days: {},
        allTime: {},
    },
    reputationManagementReports: {
        last7Days: {},
        last30Days: {},
        allTime: {},
    },
    directMessagingReports: {
        last7Days: {},
        last30Days: {},
        allTime: {},
    },
    websiteTrafficReports: {},
    error: null,
    sendNpeInterestLoading: {},
    outboundAlertsCount: {
        isLoading: false,
        last30Days: {},
    },
    homePage: {
        digital_platform: {
            isLoading: false,
        },
        digital_platform_patients: {
            isLoading: false,
        },
        direct_messaging: {
            isLoading: false,
        },
    },
};

const REPORTS_MAP = {
    direct_messaging: 'directMessagingReports',
    digital_marketing: 'digitalMarketingReports',
    digital_platform_patients: 'digitalPlatformPatientsReports',
    mobile_downloads: 'mobileDownloadsReports',
    website_traffic: 'websiteTrafficReports',
    newsletter: 'newsletterReports',
    digital_platform: 'digitalPlatformReports',
    ivr: 'communicationsPlatformReports',
    reputation_management: 'reputationManagementReports',
    outbound_alert_count: 'outboundAlertsCount',
};

const formatWebsiteTraffic = (data) => {
    data.forEach((d) => {
        const perViewsVisitor = _.isFinite(d.visitors) && d.visitors !== 0 ? d.pageViewsPerVisitor / d.visitors : 0;
        d.pageViewsPerVisitor = parseFloat(perViewsVisitor).toFixed(2);
    });
};

const formatNewsLetter = (dataToFormat) => {
    if (_.isEmpty(dataToFormat.data)) {
        return {
            ...dataToFormat,
            isEmptyResponse: true,
            data: {
                sendDate: 'No Newsletter Sent',
                recipients: '-',
                views: '-',
                unsubscribers: '-',
                bounces: '-',
            },
        };
    }

    const {
        data: { sendDate },
    } = dataToFormat;

    return {
        ...dataToFormat,
        isEmptyResponse: false,
        data: {
            ...dataToFormat.data,
            sendDate: sendDate !== null ? moment(sendDate).format('MM/DD/YYYY [\nat] h:mmA') : sendDate,
        },
    };
};

const formatReputationManagement = (data) => {
    const { reputationManagementReports } = _.cloneDeep(initialState);
    if (data.length) {
        try {
            const sortedData = _.sortBy(data, (d) => -new Date(d.date_range_begin));
            const keys = Object.keys(reputationManagementReports);
            keys.forEach((k, i) => {
                delete sortedData[i].date_range_begin;
                delete sortedData[i].date_range_end;
                reputationManagementReports[k] = sortedData[i];
            });
        } catch (error) {
            logUtil.logError('Error while formatting reputation management data -', error);
            return reputationManagementReports;
        }
    }
    return reputationManagementReports;
};

const formatDigitalPlatform = (data) => {
    try {
        const result = _.cloneDeep(initialState.digitalPlatformReports);
        const { requestParams: request, data: response } = data;

        request.date_ranges.forEach((date1, requestIndex) => {
            const responseIndex = _.findIndex(response.date_ranges, (date2) => {
                return (
                    moment(date1.begin).isSame(date2.begin.toString()) && moment(date1.end).isSame(date2.end.toString())
                );
            });

            const refills = {
                mobile: response.data.mobile[responseIndex],
                web: response.data.web[responseIndex],
            };

            // requestIndex 0 - 6 are months, 7th index is last 7 day data, 8th index is last 30 days
            if (requestIndex >= 0 && requestIndex < 7) {
                const month = request.labels[requestIndex];
                result.months.push({ ...refills, month });
            } else if (requestIndex === 7) {
                result.last7Days = refills;
            } else if (requestIndex === 8) {
                result.last30Days = refills;
            }
        });

        return result;
    } catch (error) {
        logUtil.logError('Error while formatting digital platform data ', error);
        return _.cloneDeep(initialState.digitalPlatformReports);
    }
};

const updateReportsData = (state, payload) => {
    switch (payload.type) {
        case 'direct_messaging':
            return {
                ...state,
                directMessagingReports: {
                    isLoading: false,
                    ...state.directMessagingReports,
                    [payload.requestParams.filter_type]: payload.data,
                },
            };
        case 'digital_marketing':
            return {
                ...state,
                digitalMarketingReports: {
                    isLoading: false,
                    ...state.digitalMarketingReports,
                    [payload.requestParams.filter_type]: payload.data,
                },
            };
        case 'digital_platform_patients':
            return {
                ...state,
                digitalPlatformPatientsReports: {
                    ...state.digitalPlatformPatientsReports,
                    isLoading: false,
                    [payload.requestParams.filter_type]: payload.data,
                },
            };
        case 'mobile_downloads':
            return { ...state, isLoading: false, mobileDownloadsReports: payload };
        case 'website_traffic': {
            formatWebsiteTraffic(payload.data.data);
            return { ...state, isLoading: false, websiteTrafficReports: payload };
        }
        case 'newsletter': {
            const newsletterReports = formatNewsLetter(payload);
            return { ...state, isLoading: false, newsletterReports };
        }
        case 'digital_platform': {
            const digitalPlatformReports = formatDigitalPlatform(payload);
            return { ...state, digitalPlatformReports: { ...digitalPlatformReports, isLoading: false } };
        }
        case 'ivr':
            return { ...state, communicationsPlatformReports: { ...payload.data, isLoading: false } };
        case 'reputation_management': {
            const reputationManagementReports = formatReputationManagement(payload.data.data);
            return { ...state, isLoading: false, reputationManagementReports };
        }
        case 'outbound_alert_count': {
            const key = REPORTS_MAP[payload.type];
            const outbound_count = _.sumBy(payload.data.outbound_alerts, (mode) => mode.outbound_count);
            return { ...state, [key]: { isLoading: false, last30Days: { outbound_count } } };
        }
        default:
            return { ...state };
    }
};

const updateHomePageData = (state, payload) => {
    switch (payload.type) {
        case 'digital_platform': {
            return {
                ...state,
                homePage: {
                    ...state.homePage,
                    digital_platform: {
                        isLoading: false,
                        data: [payload.data.data.web[0], payload.data.data.mobile[0]],
                    },
                },
            };
        }
        case 'digital_platform_patients': {
            return {
                ...state,
                homePage: {
                    ...state.homePage,
                    digital_platform_patients: {
                        isLoading: false,
                        data: payload.data.transfers,
                    },
                },
            };
        }
        case 'direct_messaging': {
            return {
                ...state,
                homePage: {
                    ...state.homePage,
                    direct_messaging: {
                        isLoading: false,
                        data: payload.data.sent,
                    },
                },
            };
        }
        default:
            return state;
    }
};

export default function ReportsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_REPORTS_SUCCESS:
            const { currentQueryParams } = state;
            const { location_id } = payload.requestParams;
            if (currentQueryParams.location_id === location_id) {
                if (!payload.isHomePage) return updateReportsData(state, payload);
                return updateHomePageData(state, payload);
            }
            return state;
        case Actions.FETCH_REPORTS_STARTED: {
            const key = REPORTS_MAP[payload.type];

            if (!payload.isHomePage) {
                return {
                    ...state,
                    [key]: {
                        ..._.cloneDeep(initialState[key]),
                        isLoading: true,
                    },
                    currentQueryParams: payload,
                };
            } else {
                return {
                    ...state,
                    homePage: {
                        ...state.homePage,
                        [payload.type]: {
                            isLoading: true,
                        },
                    },
                    currentQueryParams: payload,
                };
            }
        }
        case Actions.FETCH_REPORTS_FAILED: {
            const key = REPORTS_MAP[payload.type];
            if (!payload.isHomePage) {
                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        isLoading: false,
                    },
                    errors: payload,
                };
            } else {
                return {
                    ...state,
                    homePage: {
                        ...state.homePage,
                        [payload.type]: {
                            isLoading: false,
                        },
                    },
                };
            }
        }
        case Actions.SEND_NPE_INTEREST_STARTED:
            return {
                ...state,
                sendNpeInterestLoading: {
                    ...state.sendNpeInterestLoading,
                    [payload.appType]: true,
                },
            };
        case Actions.SEND_NPE_INTEREST_SUCCESS:
        case Actions.SEND_NPE_INTEREST_FAILED:
            return {
                ...state,
                sendNpeInterestLoading: {
                    ...state.sendNpeInterestLoading,
                    [payload.appType]: false,
                },
            };
        default:
            return state;
    }
}
