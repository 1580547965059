import statusPageType from './type';

export const initialState = {
    loadingSummary: false,
    summary: {},
};

export const statusPageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case statusPageType.FETCH_SUMMARY_REQUEST:
            return {
                ...state,
                loadingSummary: true,
                summary: {},
            };
        case statusPageType.FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingSummary: false,
                summary: payload || initialState.summary,
            };
        case statusPageType.FETCH_SUMMARY_FAILURE:
            return {
                ...state,
                loadingSummary: false,
                summary: {},
            };
        default:
            return state;
    }
};

export default statusPageReducer;
