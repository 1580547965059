import _ from 'lodash';

import Actions from 'redux/actions';
import { post } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../utils/log';
import NetworkService from 'utils/networkService';

let reportsSource;

export const fetchNewReports = (data) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.NEW_REPORTS}/${data.report_type}`;
    const keysToOmitFromPayload = ['selectedLocalLocation', 'filter_type', 'report_type'];

    dispatch({
        type: Actions.FETCH_REPORTS_STARTED,
        payload: {
            location_id: data.selectedLocalLocation,
            type: data.report_type,
            isHomePage: Boolean(data.isHomePage),
        },
    });

    if (!reportsSource) {
        reportsSource = NetworkService.cancelToken.source();
    }

    const reportReq = post(apiUrl, _.omit(data, keysToOmitFromPayload), { cancelToken: reportsSource.token });
    return reportReq
        .then((resp) => {
            dispatch({
                type: Actions.FETCH_REPORTS_SUCCESS,
                payload: {
                    data: resp,
                    requestParams: { ...data, location_id: data.selectedLocalLocation },
                    type: data.report_type,
                    isHomePage: Boolean(data.isHomePage),
                },
            });
        })
        .catch((err) => {
            if (!NetworkService.isCancel(err)) {
                logHttpError('An error occured on fetchReportReq', err);
                dispatch(displayToast({ text: 'Error while fetching reports data!!', type: 'error' }));
                dispatch({
                    type: Actions.FETCH_REPORTS_FAILED,
                    payload: { ...err, type: data.report_type, isHomePage: Boolean(data.isHomePage) },
                });
            }
        });
};

export const cancelReportsRequests = () => {
    if (reportsSource) reportsSource.cancel();
    reportsSource = undefined;
};

export const sendNpeInterest = (data) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.SEND_NPE_INTEREST}`;
    const appType = data.appType;

    delete data.appType;

    dispatch({
        type: Actions.SEND_NPE_INTEREST_STARTED,
        payload: { appType },
    });

    const sendNpeInterestReq = post(apiUrl, data);
    return sendNpeInterestReq
        .then(() => {
            dispatch({
                type: Actions.SEND_NPE_INTEREST_SUCCESS,
                payload: { appType },
            });
            return { success: true };
        })
        .catch((err) => {
            logHttpError('An error occured on sendNpeInterestReq', err);
            dispatch(displayToast({ text: 'Error while sending npe interest!!', type: 'error' }));
            dispatch({ type: Actions.SEND_NPE_INTEREST_FAILED, payload: { err, appType } });
            return { err };
        });
};
