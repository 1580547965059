import React from 'react';
import { connect } from 'react-redux';
import './PatientActions.sass';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Tooltip } from '@material-ui/core';
import MessageStatusIcon from './MessageStatusIcon';
import _ from 'lodash';
import AppointmentStatusIcon from './AppointmentStatusIcon';

function PatientActions({
    patient,
    onEditPatient,
    onMessagePatient,
    assessment,
    onShowAppointments,
    allowPepPatientEditing,
}) {
    return (
        <div
            className="patient-actions"
            onMouseDown={(e) => e.stopPropagation()} // stop propagation to prevent row selection
        >
            {/*<AssessmentStatusIcon patient={patient} assessment={assessment} />*/}

            <AppointmentStatusIcon patient={patient} onClick={onShowAppointments} />

            <MessageStatusIcon patient={patient} onMessagePatient={onMessagePatient} />

            <Tooltip
                className={allowPepPatientEditing ? '' : 'patient-actions-disabled'}
                title={allowPepPatientEditing ? 'Edit patient details' : 'Patients must be edited in your PMS'}
            >
                <div>
                    <IconButton onClick={() => onEditPatient(_.clone(patient))} disabled={!allowPepPatientEditing}>
                        <EditIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        allowPepPatientEditing: state.pharmacy.allowPepPatientEditing,
    };
}

const bindActionsToDispatch = (dispatch) => ({});

export default connect(mapStateToProps, bindActionsToDispatch)(PatientActions);
