import React, { Fragment } from 'react';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const questionMap = {
    question1: 'Trustworthy',
    question2: 'Fast and Efficient',
    question3: 'Friendly',
    question4: 'Good Value',
    question5: 'What do you like the most about our pharmacy?',
    question6: 'Anything else?',
};

export function ReviewRating({ question, rating, showRatingNumber = false }) {
    const ratingFloat = parseFloat(rating);
    if (isNaN(ratingFloat)) return null; // Rating is a non-int/float string; Cannot render component
    return (
        <div className="review-rating">
            <div className="review-rating__question">
                {question}
                {showRatingNumber && <span className="review-rating__rating-number">{ratingFloat.toFixed(1)}/5.0</span>}
            </div>
            <div className="review-rating__rating">
                <Rating
                    readOnly
                    value={Math.round(ratingFloat)}
                    size={'large'}
                    emptyIcon={<StarBorderIcon fontSize="inherit" className="empty-star" />}
                    icon={<StarIcon fontSize="inherit" className="filled-star" />}
                />
            </div>
        </div>
    );
}

ReviewRating.propTypes = {
    question: PropTypes.string.isRequired,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Annoying but average rating call returns int while listing call returns string for rating
    showRatingNumber: PropTypes.bool,
};

const StyledCardHeader = withStyles({
    action: {
        margin: '0',
    },
})(CardHeader);

const StyledCardFooter = withStyles({
    spacing: {
        padding: '16px',
    },
})(CardActions);

function ReviewLoading() {
    return (
        <div className="reviews-loading">
            <CircularProgress className="reviews-loading__spinner" />
        </div>
    );
}

export function ReviewCard({ title, children, footer, headerAction = null, loadingContent = false }) {
    return (
        <Card>
            <StyledCardHeader title={title} action={headerAction} />
            <Divider />
            <CardContent style={{ position: 'relative' }}>
                <Fragment>
                    {children}
                    {loadingContent && <ReviewLoading />}
                </Fragment>
            </CardContent>
            <Divider />
            <StyledCardFooter>{footer}</StyledCardFooter>
        </Card>
    );
}

ReviewCard.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node.isRequired,
    headerAction: PropTypes.node,
    loadingContent: PropTypes.bool,
};

export function FooterText(text, value) {
    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <span style={{ fontSize: '16px', fontWeight: '500', color: '#5d5d5d' }}>
                {text}:&nbsp;{value}
            </span>
        </div>
    );
}

FooterText.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export function ReviewQuestionAnswerSet({ question, answer }) {
    return (
        <div className="review-question-set">
            <div className="review-question-set__question">{question}</div>
            <div className="review-question-set__answer">{answer || '—'}</div>
        </div>
    );
}

ReviewQuestionAnswerSet.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
};
