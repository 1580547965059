import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Snackbar, Button } from '@material-ui/core';
import UploadSelectionFileContent from './UploadSelectionFileContent';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    getLikelyFirstNameColIndex,
    getLikelyLastNameColIndex,
    getLikelyPhoneColIndex,
    getLikelyDOBColIndex,
    findEmptyColumns,
    doesFileSeemToHaveHeaders,
    isAttributeMapped,
    findDerivedCombinedColumns,
    buildDerivedColumnsFromFileData,
    getOrderedFileColumns,
} from '../csvUtil';
import MapFileAttributesContent from '../MapFileAttributesContent';
import ConfirmSelectedPatientsContent from './ConfirmSelectedPatientsContent';
import { searchPatientList } from '../../../../redux/Patient/PatientData/action';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getLocationNameFromAuth } from '../../../../utils/helper';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { userAction } from '../../../../redux/Inbox/User';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const REQUIRED_FIELDS = [
    {
        name: 'First Name',
        value: 'first_name',
    },
    {
        name: 'Last Name',
        value: 'last_name',
    },
];
const OPTIONAL_FIELDS = [
    {
        name: 'Phone',
        value: 'phone',
    },
    {
        name: 'Date of Birth',
        value: 'date_of_birth',
    },
];

function PatientSelectionImportDialog({
    open,
    onClose,
    activeLocationCountryCode,
    searchPatientList,
    setSelectedPatients,
    selectedPatients,
    createBulkUsers,
}) {
    const [currentStep, setCurrentStep] = useState('upload');
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [attributesMapping, setAttributesMapping] = useState({});
    const [autoMappedCount, setAutoMappedCount] = useState(0);
    const [isMappingComplete, setIsMappingComplete] = useState(false);
    const [selectedCount, setSelectedCount] = useState({});
    const [fileColumns, setFileColumns] = useState(null);
    const [searchError, setSearchError] = useState(null);

    useEffect(() => {
        if (!_.isNil(file)) {
            Papa.parse(file, {
                complete: (results) => {
                    const emptyColumns = findEmptyColumns(results);
                    const hasHeaders = doesFileSeemToHaveHeaders(results, emptyColumns);

                    results.emptyColumns = emptyColumns;
                    results.hasHeaders = hasHeaders;

                    const derivedFileData = buildDerivedColumnsFromFileData(
                        results,
                        findDerivedCombinedColumns(results, hasHeaders),
                        hasHeaders
                    );
                    const fileColumns = getOrderedFileColumns(results, derivedFileData);
                    setFileColumns(fileColumns);

                    const likelyFirstNameIndex = getLikelyFirstNameColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyLastNameIndex = getLikelyLastNameColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyPhoneIndex = getLikelyPhoneColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyDOBIndex = getLikelyDOBColIndex(fileColumns, emptyColumns, hasHeaders);
                    const mapping = {
                        first_name: {
                            colIndex: _.isFinite(likelyFirstNameIndex) ? likelyFirstNameIndex : undefined,
                        },
                        last_name: {
                            colIndex: _.isFinite(likelyLastNameIndex) ? likelyLastNameIndex : undefined,
                        },
                        phone: {
                            colIndex: _.isFinite(likelyPhoneIndex) ? likelyPhoneIndex : undefined,
                        },
                        date_of_birth: {
                            colIndex: _.isFinite(likelyDOBIndex) ? likelyDOBIndex : undefined,
                        },
                    };

                    setAutoMappedCount(
                        _.size(
                            _.filter([
                                _.isFinite(likelyFirstNameIndex),
                                _.isFinite(likelyLastNameIndex),
                                _.isFinite(likelyPhoneIndex),
                                _.isFinite(likelyDOBIndex),
                            ])
                        )
                    );

                    setAttributesMapping(mapping);
                    setFileData(results);
                    setCurrentStep('mapping');
                },
            });
        }
    }, [file]);

    useEffect(() => {
        setIsMappingComplete(
            _.isEmpty(_.filter(REQUIRED_FIELDS, ({ value }) => !isAttributeMapped(value, attributesMapping)))
        );
    }, [attributesMapping]);

    const getCurrentStepContent = () => {
        switch (currentStep) {
            case 'upload':
                return <UploadSelectionFileContent handleFileUpload={(f) => setFile(f[0])} />;
            case 'mapping':
                return (
                    <MapFileAttributesContent
                        file={file}
                        fileData={fileData}
                        fileColumns={fileColumns}
                        requiredFields={REQUIRED_FIELDS}
                        optionalFields={OPTIONAL_FIELDS}
                        attributesMapping={attributesMapping}
                        setAttributesMapping={setAttributesMapping}
                        isMappingComplete={isMappingComplete}
                        onDone={() => setCurrentStep('confirm')}
                        onGoBack={() => setCurrentStep('upload')}
                        autoMappedAttributeCount={autoMappedCount}
                        doneLabel={'Find patients in this file'}
                        optionalFieldsAreForAddingPatients={true}
                    />
                );
            case 'confirm':
                return (
                    <ConfirmSelectedPatientsContent
                        file={file}
                        fileData={fileData}
                        fileColumns={fileColumns}
                        requiredFields={REQUIRED_FIELDS}
                        optionalFields={OPTIONAL_FIELDS}
                        attributesMapping={attributesMapping}
                        onDone={(selectedPatientsFromFile, emptyPatientsFromFile, previouslySelectedFromFile) => {
                            if (!_.isNil(selectedPatientsFromFile) && !_.isNil(emptyPatientsFromFile)) {
                                setSelectedCount({
                                    selected: _.size(selectedPatientsFromFile),
                                    empty: _.size(emptyPatientsFromFile),
                                    previouslySelected: _.size(previouslySelectedFromFile),
                                });
                                setCurrentStep('done');
                            } else {
                                setCurrentStep('post-done');
                            }
                            onCloseWrapper(_.isNil(selectedPatientsFromFile));
                        }}
                        onClose={onCloseWrapper}
                        selectedPatients={selectedPatients}
                        setSelectedPatients={setSelectedPatients}
                        onGoBack={() => setCurrentStep('mapping')}
                        activeLocationCountryCode={activeLocationCountryCode}
                        searchPatientList={searchPatientList}
                        createBulkUsers={createBulkUsers}
                        searchError={searchError}
                        setSearchError={setSearchError}
                    />
                );
            default:
                return <></>;
        }
    };

    const onCloseWrapper = (resetStep = true) => {
        onClose();
        setSearchError(null);

        // clear internal state after the close animation
        setTimeout(() => {
            setFile(null);
            setFileData(null);
            setAttributesMapping(null);
            if (resetStep) {
                setCurrentStep('upload');
            }
        }, 500);
    };

    return (
        <span>
            <Dialog
                maxWidth="lg"
                className="patient-selection-import-dialog"
                open={open && _.isNil(searchError)}
                onClose={onCloseWrapper}
            >
                <DialogContent>{getCurrentStepContent()}</DialogContent>
            </Dialog>

            <Snackbar
                className="bulk-patient-finished-snackbar"
                open={currentStep === 'done'}
                onClose={() => setCurrentStep('upload')}
                autoHideDuration={5000}
                message={
                    <div className="bulk-selection-finished-snackbar-content">
                        <CheckCircleIcon />
                        <div className="text">
                            <div className="main-line">
                                Selected {numeral(selectedCount.selected).format('0,0')}{' '}
                                {pluralize('patient', selectedCount.selected)} from this file.
                                {selectedCount.previouslySelected === 0 ? null : (
                                    <span>
                                        {' '}
                                        ({numeral(selectedCount.previouslySelected).format('0,0')} already selected)
                                    </span>
                                )}
                            </div>
                            {selectedCount.empty === 0 ? null : (
                                <div className="sub-line">
                                    {numeral(selectedCount.empty).format('0,0')} patient{' '}
                                    {pluralize('record', selectedCount.empty)} had incomplete data and did not match any
                                    known patients.
                                </div>
                            )}
                        </div>
                    </div>
                }
            />

            <Snackbar
                className="search-patient-list-error-snackbar"
                open={!_.isNil(searchError)}
                onClose={() => onCloseWrapper()}
                autoHideDuration={5000}
                message={
                    <div className="error-message">
                        <ErrorIcon />
                        <div className="text">
                            <div className="main-line">
                                An unexpected error occurred searching patients from this file
                            </div>
                            <div className="sub-line">
                                Please try again later or contact Digital Pharmacist support if this continues.
                            </div>
                        </div>
                        <Button className="error-ok-button" variant="text" onClick={onCloseWrapper}>
                            OK
                        </Button>
                    </div>
                }
            />
        </span>
    );
}

function mapStateToProps(state) {
    const { inboxUser, pharmacy, auth, patientData } = state;
    return {
        user: inboxUser,
        bulkUploadEnabled: pharmacy.bulkUploadEnabled,
        locationName: getLocationNameFromAuth(auth),
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        duplicateNewPatientsState: patientData.duplicateNewPatients,
    };
}

const bindActionsToDispatch = {
    searchPatientList,
    createBulkUsers: userAction.createBulkUsers,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientSelectionImportDialog);
