import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Tooltip, Typography, Button } from '@material-ui/core';
import { MinimizedSchedulerForm, FullScreenStackedForm, SavingButton } from '@digitalpharmacist/messaging-forms';
import _ from 'lodash';

MessageCTACard.propTypes = {
    messageContent: PropTypes.shape({
        action_title: PropTypes.string.isRequired,
        action_text: PropTypes.string.isRequired,
        action_public_image_url: PropTypes.string,
        action_type: PropTypes.string.isRequired,
    }).isRequired,
};

function MessageCTACard(props) {
    const { action_title, action_text, action_public_image_url, action_type, messaging_form } = props.messageContent;

    if (action_type === 'messaging_form' && _.isString(_.get(messaging_form, 'reservation_session_id'))) {
        return (
            <div className="conversation-message-cta__container">
                <Tooltip
                    title={'The patient may schedule a clinical appointment using the Appointment Scheduler'}
                    placement="bottom"
                    arrow
                >
                    <div className="conversation-message-cta__bubble">
                        <div className="conversation-message-cta__content">
                            <MinimizedSchedulerForm primaryColor="#00B18F" setView={() => {}} />
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    } else if (action_type === 'messaging_form' && _.get(messaging_form, 'is_checkin', false)) {
        return (
            <div className="conversation-message-cta__container">
                <Tooltip title={'The patient may check-in once they arrive at the pharmacy.'} placement="bottom" arrow>
                    <div className="conversation-message-cta__bubble">
                        <div className="conversation-message-cta__content" style={{ pointerEvents: 'none' }}>
                            <SavingButton primaryColor="#00B18F" label={'Check in'} />
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    } else if (action_type === 'messaging_form' && _.get(messaging_form, 'is_reservation_confirmation', false)) {
        return (
            <div className="conversation-message-cta__container">
                <Tooltip title={'The patient may report they do not have insurance.'} placement="bottom" arrow>
                    <div className="conversation-message-cta__bubble">
                        <div className="conversation-message-cta__content" style={{ pointerEvents: 'none' }}>
                            <SavingButton primaryColor="#00B18F" label={'I am uninsured'} />
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    } else if (action_type === 'messaging_form' && _.isString(_.get(messaging_form, 'assessment_id'))) {
        const assessment = messaging_form.assessment;
        const ignoreFirst = false; //_.isString(messaging_form.yes_no_prompt_question);
        const formDefinition = {
            questions: _.filter(
                _.map(_.get(assessment, 'questions'), (q, i) => {
                    if (ignoreFirst && i === 0) {
                        return;
                    }
                    return {
                        id: q.patient_assessment_template_question_id,
                        type: q.question_type,
                        prompt: q.question_text,
                    };
                })
            ),
            answers: {},
        };

        return (
            <div className="conversation-message-cta__container">
                <Tooltip title={'The patient may fill out the contained assessment.'} placement="bottom" arrow>
                    <div className="conversation-message-cta__bubble">
                        <div className="conversation-message-cta__content">
                            <div
                                style={{ fontSize: '13px', fontWeight: '400', color: '#4a4a4a', pointerEvents: 'none' }}
                            >
                                <div style={{ marginBottom: '10px' }}>{messaging_form.assessment_cta_prompt}</div>
                                <FullScreenStackedForm
                                    title={action_title}
                                    formDefinition={{
                                        ...formDefinition,
                                        answers: messaging_form.saved_assessment_answers,
                                    }}
                                    onFullScreenOpen={() => {}}
                                    onSend={() => {
                                        return Promise.resolve();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    } else if (action_type === 'messaging_form' && _.isString(_.get(messaging_form, 'appt_type_id'))) {
        return (
            <div className="conversation-message-cta__container">
                <Tooltip
                    title={'Clicking this button will open the appointment scheduler on the patients mobile device'}
                    placement="bottom"
                    arrow
                >
                    <div className="conversation-message-cta__bubble">
                        <div className="conversation-message-cta__content" style={{ pointerEvents: 'none' }}>
                            <Button
                                style={{ marginRight: '8px' }}
                                variant="contained"
                                primaryColor="#00B18F"
                                color="primary"
                                fullWidth
                            >
                                {action_title}
                            </Button>
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    }

    const tooltipMessage = 'The patient can interact with this card';

    return (
        <div className="conversation-message-cta__container">
            <Tooltip title={tooltipMessage} placement="bottom" arrow>
                <div className="conversation-message-cta__bubble">
                    <div className="conversation-message-cta__content">
                        {action_public_image_url && (
                            <img src={action_public_image_url} alt="public" height="40" width="40" />
                        )}

                        <div className="conversation-message-cta__text">
                            <Typography variant="subtitle1">{action_title}</Typography>
                            <Typography variant="body2">{action_text}</Typography>
                        </div>

                        <ArrowForwardIcon fontSize="small" style={{ alignSelf: 'center' }} />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}

export default MessageCTACard;
