import React from 'react';
import FilteringOptionGroup from './FilteringOptionGroup';
import MessageIcon from '@material-ui/icons/Message';
import NoMessagesIcon from '@material-ui/icons/SpeakerNotesOff';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PersonIcon from '@material-ui/icons/Person';

export default function ConversationStatusPopover({ value, onChange }) {
    return (
        <div className="filter-popover conversation-status-popover">
            <FilteringOptionGroup
                value={value}
                onChange={onChange}
                options={[
                    {
                        value: 'unread-pharmacy',
                        icon: <LocalPharmacyIcon />,
                        label: ConversationStatusPopover.OPTION_LABELS['unread-pharmacy'],
                    },
                    {
                        value: 'unread-patient',
                        icon: <PersonIcon />,
                        label: ConversationStatusPopover.OPTION_LABELS['unread-patient'],
                    },
                    {
                        value: 'messages',
                        icon: <MessageIcon />,
                        label: ConversationStatusPopover.OPTION_LABELS['messages'],
                    },
                    {
                        value: 'no-messages',
                        icon: <NoMessagesIcon />,
                        label: ConversationStatusPopover.OPTION_LABELS['no-messages'],
                    },
                ]}
            />
        </div>
    );
}

ConversationStatusPopover.OPTION_LABELS = {
    'unread-pharmacy': 'Messages unread by pharmacy',
    'unread-patient': 'Messages unread by patient',
    messages: 'Have messaged this patient',
    'no-messages': 'No messages with this patient',
};
