import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { computeAvatarStyle } from 'utils/helper';
import _ from 'lodash';

export const Title = (props) => {
    const avatarStyle = computeAvatarStyle(props.name, '32px');
    const title = props.title || 'New message from';
    return (
        <div className="inbox-message-widget__title" style={{ display: 'flex' }}>
            <Avatar className="avatar" style={avatarStyle}>
                {props.noAvatarInitial ? '' : _.toUpper(props.name[0])}
            </Avatar>

            <span className="text">{`${title} ${props.name}`}</span>

            <IconButton className="forward-icon" style={{ marginLeft: 'auto' }} onClick={props.onArrowClick}>
                <ArrowForwardIcon fontSize="inherit" />
            </IconButton>
        </div>
    );
};
