import Actions from 'redux/actions';
import { getFileContent } from 'redux/actionCreators/Settings/MainGreeting';
import { GREETING_TYPE } from 'constants/MainGreeting';

export const addDoctorLine = () => ({
    type: Actions.ADD_DOCTOR_GREETING,
});

export const updateDoctorGreetingMsg = (payload) => (dispatch) => {
    if (payload.contentType === 'text/plain') {
        dispatch(getFileContent(payload)).then((resp) => {
            dispatch(changeDoctorGreetingScript(resp));
        });
    }

    dispatch({
        type: Actions.UPDATE_DOCTOR_GREETING,
        payload,
    });
};

export const closeDoctorGreetingEditor = () => ({
    type: Actions.CLOSE_DOCTOR_GREETING_EDITOR,
});

export const editDoctorGreeting = (payload) => (dispatch) => {
    dispatch({
        type: Actions.EDIT_DOCTOR_GREETING,
        payload,
    });

    if (payload.dr_greeting_type === 'text/plain') {
        dispatch(changeDoctorGreetingType(GREETING_TYPE['CUSTOM_TEXT']));
        dispatch(getFileContent({ id: payload.dr_greeting_id })).then((resp) => {
            dispatch(changeDoctorGreetingScript(resp));
        });
    } else {
        dispatch(changeDoctorGreetingType(GREETING_TYPE['CUSTOM_AUDIO']));
    }
};

export const changeDoctorGreetingScript = (payload) => ({
    type: Actions.CHANGE_DOCTOR_GREETING_SCRIPT,
    payload,
});

export const changeDoctorGreetingType = (payload) => ({
    type: Actions.CHANGE_DOCTOR_GREETING_TYPE,
    payload,
});
