import _ from 'lodash';
import { createSelector } from 'reselect';

const pharmacyActiveLocationIdSelector = (state) => {
    const { activeLocationId } = state.pharmacy;
    return activeLocationId;
};

const pharmacyLocationsSelector = (state) => {
    const { location } = state.pharmacy.pharmacy;
    return location;
};

const pharmacyActiveLocationSelector = createSelector(
    pharmacyActiveLocationIdSelector,
    pharmacyLocationsSelector,
    (activeLocationId, locations) => {
        return _.find(locations, { id: activeLocationId });
    }
);

const pharmacyHasBasicMessagingEnabled = (state) => {
    return (
        _.get(state, 'launchDarkly.npeActivePepFreeTrial', false) ||
        _.get(state, 'pharmacy.basicMessagingEnabled', false)
    );
};

const pharmacyHasFullPepEnabled = (state) => {
    return _.get(state, 'launchDarkly.npeActivePepFreeTrial', false) || _.get(state, 'pharmacy.fullPepEnabled', false);
};

const pharmacyActiveLocationCountryCodeSelector = createSelector(pharmacyActiveLocationSelector, (activeLocation) => {
    return _.toUpper(_.get(activeLocation, 'address.Main.country', 'US'));
});

const isVowEnabled = (state) => {
    return _.get(state, 'pharmacy.vowIVREnabled', false);
};

export const pharmacySelectors = {
    pharmacyActiveLocationIdSelector,
    pharmacyLocationsSelector,
    pharmacyActiveLocationSelector,
    pharmacyActiveLocationCountryCodeSelector,
    pharmacyHasBasicMessagingEnabled,
    pharmacyHasFullPepEnabled,
};
