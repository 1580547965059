import _ from 'lodash';
import { createSelector } from 'reselect';

import Actions from 'redux/actions';
import { SORT_MODE } from 'constants/Leads';

const initialState = {
    currentPage: 1,
    leadsTotalPages: null,
    leads: [],
    leadsCount: 0,
    isLeadsCountLoading: false,
    isLeadsLoading: false,
    isLoadMoreLeadsLoading: false,
    sort: {
        column: SORT_MODE.DATE,
        order: 'desc',
    },
    isLeadNotesLoading: false,
    isLeadNoteSaving: false,
    shouldFetchLatestLeads: false,
};

const fetchingLeadAudio = (state, payload, isFetching) => {
    const leads = state.leads.slice();
    const leadIndex = leads.findIndex((lead) => lead.id === payload.leadId);
    leads[leadIndex].fetchingAudio = isFetching;
    return {
        ...state,
        leads,
    };
};

const updateLeadAudio = (state, payload) => {
    const leads = state.leads.slice();
    const leadIndex = leads.findIndex((lead) => lead.id === payload.leadId);
    leads[leadIndex].fetchingAudio = false;
    leads[leadIndex].audioUrl = payload.data.url;
    return {
        ...state,
        leads,
    };
};

const updateLeadProcessing = (state, payload, isSaving) => {
    const leads = state.leads.slice();
    leads.forEach((lead, idx) => {
        const leadId = lead.id || lead.universal_session_id;
        if (leadId === payload.leadId) {
            leads[idx].isSaving = isSaving;
        }
    });
    return { ...state, leads };
};

const updateLeadNote = (state, payload) => {
    const leads = state.leads.slice();
    leads.forEach((lead, idx) => {
        const leadId = lead.id || lead.universal_session_id;
        if (leadId === payload.leadId) {
            leads[idx].isSaving = false;
            leads[idx].note = payload.data.lead.note;
            leads[idx].status = payload.data.lead.status;
        }
    });
    return { ...state, leads };
};

const setDraftNote = (state, payload) => {
    const leads = state.leads.slice();
    leads[payload.leadIdx].draftNote = payload.draftNote;
    return {
        ...state,
        leads,
    };
};

export default function LeadsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.RESET_LEADS: {
            const { sortData, ...rest } = initialState;
            const { sortData: currentSortData } = state;
            return {
                ...rest,
                sortData: currentSortData,
                shouldFetchLatestLeads: false,
            };
        }

        case Actions.FETCH_LEADS_COUNT_STARTED:
            return {
                ...state,
                isLeadsCountLoading: true,
            };
        case Actions.FETCH_LEADS_COUNT_SUCCESS:
            return {
                ...state,
                isLeadsCountLoading: false,
                leadsCount: payload.data.leads_count,
            };
        case Actions.FETCH_LEADS_COUNT_FAILED:
            return {
                ...state,
                leadsCount: 0,
                isLeadsCountLoading: false,
            };

        case Actions.FETCH_LEADS_STARTED:
            return {
                ...state,
                isLeadsLoading: !payload.loadMore,
                isLoadMoreLeadsLoading: payload.loadMore,
            };
        case Actions.FETCH_LEADS_SUCCESS:
            _.forEach(payload.data.leads, (lead) => {
                lead['date'] = new Date(lead.start_time);
            });

            const leads = payload.loadMore
                ? [...new Map(state.leads.concat(payload.data.leads).map((item) => [item['id'], item])).values()]
                : payload.data.leads;

            return {
                ...state,
                isLeadsLoading: false,
                isLoadMoreLeadsLoading: false,
                leads,
                currentPage: payload.page,
            };
        case Actions.FETCH_LEADS_FAILED:
            return {
                ...state,
                isLeadsLoading: false,
                isLoadMoreLeadsLoading: false,
                leads: [],
                currentPage: 1,
            };

        case Actions.FETCH_LEAD_AUDIO_STARTED:
            return fetchingLeadAudio(state, payload, true);
        case Actions.FETCH_LEAD_AUDIO_SUCCESS:
            return updateLeadAudio(state, payload);
        case Actions.FETCH_LEAD_AUDIO_FAILED:
            return fetchingLeadAudio(state, payload, false);
        case Actions.SORT_LEADS:
            return {
                ...state,
                sort: {
                    order: payload.order || initialState.sort.order,
                    column: payload.column || initialState.sort.column,
                },
            };
        case Actions.FETCH_LEAD_NOTES_STARTED:
            return {
                ...state,
                isLeadNotesLoading: true,
            };
        case Actions.FETCH_LEAD_NOTES_SUCCESS:
            const { leads: stateLeads } = state;
            const {
                data: { leads: responseLeads },
            } = payload;

            const leadsStatusDetails = {};
            _.forEach(responseLeads, (lead) => {
                leadsStatusDetails[lead.lead_id] = lead;
            });

            _.forEach(stateLeads, (lead, idx) => {
                const leadId = lead.id || lead.universal_session_id;
                if (leadsStatusDetails[leadId]) {
                    stateLeads[idx].note = leadsStatusDetails[leadId].note;
                    stateLeads[idx].status = leadsStatusDetails[leadId].status;
                }
            });

            return {
                ...state,
                leads: stateLeads,
                isLeadNotesLoading: false,
            };
        case Actions.FETCH_LEAD_NOTES_FAILED:
            return {
                ...state,
                isLeadNotesLoading: false,
            };
        case Actions.SET_DRAFT_NOTE:
            return setDraftNote(state, payload);
        case Actions.SAVE_LEAD_NOTE_STARTED:
            return updateLeadProcessing(state, payload, true);
        case Actions.SAVE_LEAD_NOTE_SUCCESS:
            return updateLeadNote(state, payload);
        // case Actions.SAVE_LEAD_NOTE_SUCCESS:
        //     return updateLeadProcessing(state, payload, false);
        case Actions.FETCH_LATEST_LEADS:
            return {
                ...state,
                shouldFetchLatestLeads: true,
            };
        default:
            return state;
    }
}

// Adding selector to reducer atm since they files aren't grouped by domain
// so we'd have to create a whole new selectors folder to fit this pattern
const getReduxStoreLeads = (state) => state.leads.leads;
const getReduxStoreSort = (state) => state.leads.sort;

export const getSortedLeadsSelector = createSelector([getReduxStoreLeads, getReduxStoreSort], (leads, sort) =>
    _.orderBy(leads, sort.column, sort.order)
);
