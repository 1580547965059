import React, { Component } from 'react';
import { FontIcon } from 'react-md';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Zoom, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash';

import {
    getPharmacyByAppcode,
    addUserPreferences,
    updateUserPreferences,
    clearPharmacies,
    getPharmacies,
} from 'redux/actionCreators/Auth';
import ChangeBusiness from 'components/Settings/ChangeBusiness';
import ChangeLocation from 'components/Settings/ChangeLocation';
import PharmacyList from 'components/Common/PharmacyList';
import { Can, userActions, restrictedResources } from 'casl';
import { getTextWidth, getLocationNameFromAuth } from 'utils/helper';

// Liberally cribbed from the materialUI example at https://material-ui.com/components/popover/
export class SettingsPopover extends Component {
    state = {
        anchorElement: null,
        searchValue: '',
        isChangeBusineesVisible: false,
        isChangeLocationVisible: false,
        favLocation: null,
    };

    componentDidMount() {
        const { userPreferences } = this.props;
        if (_.get(userPreferences, 'default_location_id', false)) {
            this.setState({
                favLocation: userPreferences.default_location_id,
            });
        }
    }

    componentDidUpdate(oldProps) {
        const { userPreferences } = this.props;
        if (oldProps.userPreferences.default_location_id !== userPreferences.default_location_id) {
            this.setState({
                favLocation: userPreferences.default_location_id,
            });
        }
    }

    changeFavLocation = (id) => {
        const { userPreferences, addUserPreferences, updateUserPreferences } = this.props;

        if (!_.has(userPreferences, 'default_location_id')) {
            // add the user preference
            addUserPreferences({
                defaultLocationId: id,
            });
        } else {
            // update the user preference
            updateUserPreferences({
                id: userPreferences.id,
                defaultLocationId: id,
            });
        }
    };

    handleSettingsMenuClick = (event) => {
        this.setState({
            anchorElement: event.currentTarget,
        });
    };

    handleClose = () => {
        const { clearPharmacies } = this.props;
        clearPharmacies();
        this.setState({
            anchorElement: null,
            searchValue: '',
        });
    };

    getPharmacies = _.debounce(() => {
        const { searchValue } = this.state;
        const { getPharmacies, clearPharmacies } = this.props;
        if (searchValue.length > 2) {
            clearPharmacies();
            getPharmacies(searchValue);
        }
    }, 300);

    getMorePharmacies = () => {
        const { totalPossiblePharmacies, possiblePharmacies, getPharmacies } = this.props;
        const { searchValue } = this.state;

        if (totalPossiblePharmacies > possiblePharmacies.length) {
            getPharmacies(searchValue, possiblePharmacies.length);
        }
    };

    handleChange = (e) => {
        const searchValue = e.target.value;
        const { clearPharmacies } = this.props;

        if (searchValue.length < 3) {
            clearPharmacies();
        }
        this.setState(
            {
                [e.target.name]: searchValue,
            },
            () => {
                this.getPharmacies();
            }
        );
    };

    handleEnter = (e) => {
        e.preventDefault();
        const { clearPharmacies, getPharmacyByAppcode } = this.props;
        const { searchValue } = this.state;

        clearPharmacies();
        // make a call to get the customer info based on the appCode that was entered as the search value
        getPharmacyByAppcode(searchValue).then(() => {
            this.handleClose();
        });
    };

    onPharmacyClick = (pharmacy) => {
        const { changeCustomer } = this.props;
        changeCustomer(pharmacy.id, pharmacy.name);
        this.handleClose();
    };

    showChangeBusiness = (e) => {
        e.preventDefault();
        const { isChangeBusineesVisible } = this.state;
        if (!isChangeBusineesVisible) {
            this.setState({ isChangeBusineesVisible: true });
            this.handleClose();
        }
    };

    hideChangeBusiness = () => {
        this.setState({ isChangeBusineesVisible: false });
    };

    showChangeLocation = (e) => {
        e.preventDefault();
        const { isChangeLocationVisible } = this.state;
        if (!isChangeLocationVisible) {
            this.setState({ isChangeLocationVisible: true });
            this.handleClose();
        }
    };

    hideChangeLocation = () => {
        this.setState({ isChangeLocationVisible: false });
    };

    getCustomerName = () => {
        const { customerName, customersById, selectedCustomer } = this.props;
        if (customersById[selectedCustomer.id]) {
            return customersById[selectedCustomer.id].entityName;
        } else {
            return customerName;
        }
    };

    getSettingsIcon = (userAccount) => {
        if (userAccount.first_name && userAccount.last_name) {
            return (
                <div className="avatar">
                    <span className="text">
                        {userAccount.first_name[0]}
                        {userAccount.last_name[0]}
                    </span>
                </div>
            );
        } else {
            return (
                <FontIcon id="settings-btn" className="icon">
                    settings
                </FontIcon>
            );
        }
    };

    render() {
        const { anchorElement, favLocation } = this.state;
        const open = Boolean(anchorElement);
        const id = open ? 'settings-popover' : null;
        const {
            logout,
            changeCustomer,
            changeLocation,
            customersById,
            locationsByCustomerId,
            selectedCustomer,
            selectedLocation,
            userAccount,
            userDetails,
            locationDetail,
            pharmacy,
            updatingUserPreferences,
            loadingUserPreferences,
            possiblePharmacies,
            loadingPossiblePharmacies,
            isInternal,
        } = this.props;
        const { isChangeBusineesVisible, isChangeLocationVisible } = this.state;
        const popoverWidth = anchorElement ? anchorElement.offsetWidth : '100%';
        const customerName = this.getCustomerName();
        const { view } = userActions;
        const {
            settingsPopover: { subject: caslSubject, fields: restrictedFields },
        } = restrictedResources;

        let availableLocations = [];
        if (pharmacy.location) {
            const locations = userDetails.filter((custLocDetails) => custLocDetails.entityType === 'Location');
            const locationIds = locations.map((loc) => loc.entityId);
            availableLocations = _.reject(pharmacy.location, (loc) => locationIds.indexOf(loc.id) < 0);
        }

        const maxNameWidth =
            window.innerWidth -
            802 /* current buttons + logo width */ -
            20 /* margin */ -
            72; /* rest of SettingsPopover without name */

        const nameStyle = {
            whiteSpace: 'nowrap',
            maxWidth: `${maxNameWidth}px`,
            minWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Roboto',
        };

        const locationName = getLocationNameFromAuth({ selectedLocation, locationDetail });

        const pharmacyWidth = getTextWidth(customerName, '13px 500 Roboto');
        const locationWidth = getTextWidth(locationName, '12px Roboto');
        const nameWidth = Math.max(maxNameWidth, 100);

        return (
            <div>
                {isMobileOnly ? (
                    <MoreVertIcon onClick={this.handleSettingsMenuClick} />
                ) : (
                    <div
                        className="appBar__settingsMenuTitle user-clickable"
                        onClick={this.handleSettingsMenuClick}
                        style={{
                            padding: '12px 16px 11px 16px',
                        }}
                    >
                        <div
                            className="name-and-label"
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'column',
                                marginRight: '10px',
                                fontSize: '13px',
                            }}
                        >
                            <span className="customer-name" style={{ ...nameStyle, fontWeight: 500 }}>
                                {pharmacyWidth <= nameWidth ? (
                                    <span>{customerName}</span>
                                ) : (
                                    <Tooltip title={customerName}>
                                        <span>{customerName}</span>
                                    </Tooltip>
                                )}
                            </span>
                            <span className="location-name" style={{ ...nameStyle, fontSize: '12px' }}>
                                {locationWidth <= nameWidth ? (
                                    <span>{locationName}</span>
                                ) : (
                                    <Tooltip title={locationDetail.displayName}>
                                        <span>{locationName}</span>
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                        {this.getSettingsIcon(userAccount)}
                    </div>
                )}

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorElement}
                    onClose={this.handleClose}
                    style={{ width: popoverWidth }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    classes={{ paper: isInternal ? 'popover-paper internal-user' : 'popover-paper' }}
                    TransitionComponent={Zoom}
                >
                    <div className="appBar__settingsPopdownContainer">
                        <Can I={view} this={caslSubject} field={restrictedFields.settings}>
                            <div className="appBar__settingsPopdownSettingsNavigation">
                                <Link to="/settings/general" className="appBar__settingsPopdownSettingsLink">
                                    <MenuItem id="settings" className="appBar__settingsPopdownGeneric--menuitem">
                                        Settings
                                    </MenuItem>
                                </Link>
                            </div>
                        </Can>
                        {_.size(customersById) > 1 && (
                            <div className="appBar__settingsPopdownChangeBusinessNavigation">
                                <MenuItem
                                    id="ChangeBusiness"
                                    className="appBar__settingsPopdownGeneric--menuitem"
                                    onClick={this.showChangeBusiness}
                                >
                                    <span className="user-clickable">Change Business</span>
                                </MenuItem>
                            </div>
                        )}
                        {locationsByCustomerId[selectedCustomer.id] &&
                            locationsByCustomerId[selectedCustomer.id].length > 1 && (
                                <div className="appBar__settingsPopdownChangeBusinessNavigation">
                                    <MenuItem
                                        id="ChangeLocation"
                                        className="appBar__settingsPopdownGeneric--menuitem"
                                        onClick={this.showChangeLocation}
                                    >
                                        <span className="user-clickable">Change Location</span>
                                    </MenuItem>
                                </div>
                            )}
                        <div className="appBar__settingsPopdownLogout">
                            <MenuItem id="logout" className="appBar__settingsPopdownGeneric--menuitem" onClick={logout}>
                                <span className="user-clickable">Logout</span>
                            </MenuItem>
                        </div>

                        <Can I={view} this={{ caslSubject }} field={restrictedFields.appcode}>
                            <div className="appBar__settingsPopdownAppCodeInput">
                                <form id="app-code-form" noValidate autoComplete="off" onSubmit={this.handleEnter}>
                                    <TextField
                                        type="search"
                                        id="searchValue"
                                        name="searchValue"
                                        label="App Code / Pharmacy Name"
                                        margin="dense"
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </form>
                            </div>
                            {(possiblePharmacies || loadingPossiblePharmacies) && (
                                <PharmacyList
                                    pharmacies={possiblePharmacies}
                                    getMorePharmacies={this.getMorePharmacies}
                                    onPharmacyClick={this.onPharmacyClick}
                                    loadingPossiblePharmacies={loadingPossiblePharmacies}
                                />
                            )}
                        </Can>
                    </div>
                </Popover>
                {_.size(customersById) > 1 && (
                    <ChangeBusiness
                        visible={isChangeBusineesVisible}
                        onHide={this.hideChangeBusiness}
                        changeCustomer={changeCustomer}
                        customersById={customersById}
                        selectedCustomer={selectedCustomer}
                    />
                )}
                {locationsByCustomerId[selectedCustomer.id] &&
                    locationsByCustomerId[selectedCustomer.id].length > 1 && (
                        <ChangeLocation
                            visible={isChangeLocationVisible}
                            onHide={this.hideChangeLocation}
                            changeLocation={changeLocation}
                            selectedCustomer={selectedCustomer}
                            selectedLocation={selectedLocation}
                            selectedLocationName={locationDetail.displayName}
                            allLocations={availableLocations}
                            favLocation={favLocation}
                            changeFavLocation={this.changeFavLocation}
                            updatingUserPreferences={updatingUserPreferences}
                            loadingUserPreferences={loadingUserPreferences}
                        />
                    )}
            </div>
        );
    }
}

// We're definitely going to need to pull something out of state to get all the locations and
// almost definitely something from auth to get the user type too
const mapStateToProps = (state) => {
    return {
        possiblePharmacies: state.auth.possiblePharmacies,
        totalPossiblePharmacies: state.auth.totalPossiblePharmacies,
        loadingPossiblePharmacies: state.auth.loadingPossiblePharmacies,
        userPreferences: state.auth.userPreferences,
        updatingUserPreferences: state.auth.updatingUserPreferences,
        loadingUserPreferences: state.auth.loadingUserPreferences,
    };
};

const mapDispatchToProps = {
    getPharmacies,
    getPharmacyByAppcode,
    addUserPreferences,
    updateUserPreferences,
    clearPharmacies,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPopover);
