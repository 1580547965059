import './WaitlistManageContent.sass';
import React, { useState, useEffect, useRef } from 'react';
import PatientsSelectionPane from '../PatientsSelectionPane';
import _ from 'lodash';
import LocalStorageService from '../../../utils/localStorageService';
import { getWaitlistFilterOptionsForTemplate, getWaitlistIdsFromTemplate } from './schedulingUtil';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import numeral from 'numeral';
import pluralize from 'pluralize';
import Snowplow from 'snowplow';

export default function WaitlistManageContent({
    template,
    waitlist,
    removeFromWaitlists,
    patientData,
    clearPatientOpportunities,
}) {
    const props = arguments[0];

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Manage Waitlist');
    }, []);

    const patientDataRef = useRef();

    // we
    useEffect(() => {
        patientDataRef.current = patientData;
    }, [patientData]);

    const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState({});

    const [patientsLoadingMillis, setPatientsLoadingMillis] = useState(null);
    const [loaderInterval, setLoaderInterval] = useState(null);

    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    useEffect(() => {
        if (_.isFinite(patientsLoadingMillis) && !_.isFinite(loaderInterval)) {
            // given that we don't actually know when the elasticsearch will reflect the changes we updated, we are polling
            // for the patientIndexUpdated websocket events and then waiting a second to assume that all updates are now
            // reflected in the patientsSelectionPane and then clearing the loading message.
            const interval = setInterval(() => {
                // we need to use the ref to the current patientData since otherwise we would not get a reference to the latest
                const patientData = patientDataRef.current;

                // ensure its been at least 1 second since the last index update since that will mean that the patient index
                // should have written its latest update by now and that it is later than the one we stored initially
                const now = new Date().getTime();
                if (
                    now > patientData.patientIndexUpdatedAt + 1000 &&
                    patientData.patientIndexUpdatedAt > patientsLoadingMillis
                ) {
                    window.clearInterval(interval);
                    setPatientsLoadingMillis(null);
                    // clear this so we can refetch the number of patients on the waitlists
                    clearPatientOpportunities();
                    setLoaderInterval(null);
                }
            }, 500);

            setLoaderInterval(interval);
        }
    }, [patientsLoadingMillis]);

    const waitlist_ids = getWaitlistIdsFromTemplate(template);

    const waitlistFilterOptions = getWaitlistFilterOptionsForTemplate(template, waitlist);

    return (
        <div className="waitlist-manage-content workflow-inner-content">
            <div className="waitlist-manage-heading">{template.title} Waitlist</div>
            <PatientsSelectionPane
                {...props}
                selectedPatients={selectedPatients}
                setSelectedPatients={setSelectedPatients}
                height={window.innerHeight - 240}
                hideActivityColumn
                showAgeOnly
                hidePhoneColumn={true}
                hidePatientActions
                showPatientTypeColumn
                showAddedToWaitlistColumn
                showWaitlistStatusColumn={true}
                waitlistIdToQuery={waitlist_ids}
                nameFormat={nameFormat}
                setNameFormat={setNameFormat}
                mainButtonLabel={'Remove from waitlist'}
                showConversationStatusFilter={false}
                showMessageableStatusFilter={false}
                showWaitlistStatusFilter={true}
                showPatientTypeFilter={true}
                showWaitlistLabel={false}
                showWaitlistNameFilter={false}
                waitlistFilterOptions={waitlistFilterOptions}
                showPatientsLoading={_.isFinite(patientsLoadingMillis)}
                onMainButtonClick={() => {
                    setShowingDeleteConfirm(true);
                }}
            />
            {!showingDeleteConfirm ? null : (
                <Dialog
                    className="confirm-delete-waitlist-patients-dialog"
                    open={true}
                    onClose={() => setShowingDeleteConfirm(false)}
                >
                    <DialogTitle>
                        Really delete {numeral(_.size(selectedPatients)).format('0,0')}{' '}
                        {pluralize('patient', _.size(selectedPatients))} from your waitlist?
                    </DialogTitle>

                    <DialogContent>
                        Are you sure you want to delete the selected {pluralize('patient', _.size(selectedPatients))}{' '}
                        from your waitlist? This operation can not be undone without contacting Digital Pharmacist
                        support.
                    </DialogContent>

                    <DialogActions>
                        <Button className="cancel-button" onClick={() => setShowingDeleteConfirm(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setPatientsLoadingMillis(
                                    _.isFinite(patientData.patientIndexUpdatedAt)
                                        ? patientData.patientIndexUpdatedAt
                                        : 1
                                );

                                const waitlistIdsSet = new Set(waitlist_ids);
                                const waitlistIdInboxUserIdPairs = _.map(
                                    _.values(selectedPatients),
                                    ({ waitlists, inbox_user_id }) => {
                                        const waitlistId = _.get(
                                            _.find(waitlists, ({ waitlist_id }) => waitlistIdsSet.has(waitlist_id)),
                                            'waitlist_id'
                                        );
                                        return {
                                            inbox_user_id,
                                            waitlistId,
                                        };
                                    }
                                );

                                removeFromWaitlists(waitlistIdInboxUserIdPairs).then((results) => {
                                    // clear the selection since this patients are no longer on the list
                                    setSelectedPatients({});
                                });

                                setShowingDeleteConfirm(false);
                            }}
                        >
                            Delete {pluralize('patient', _.size(selectedPatients))}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
