import './AppointmentAvailabilityRow.sass';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
    Button,
    Tooltip,
    FormControlLabel,
    Switch,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
} from '@material-ui/core';
import { getTemplateImageUrl } from 'components/Patients/patientsUtil';
import AppointmentLengthSelect from '../AppointmentLengthSelect';
import CalendarSelect from '../CalendarSelect';
import _ from 'lodash';
import PublicIcon from '@material-ui/icons/Public';
import EventIcon from '@material-ui/icons/Event';
import { createNewReservationSession, updateReservationSession } from 'redux/Appt/Reservation/action';
import DateSelectionCalendar from '../DateSelectionCalendar';
import CancelIcon from '@material-ui/icons/Cancel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import numeral from 'numeral';
import AppointmentBlocksTable from 'components/Settings/Coronavirus/AppointmentBlocksTable';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { useHistory } from 'react-router-dom';
import EditTemplateDialog from 'components/Patients/Connect/EditTemplateDialog';

function AppointmentAvailabilityRow({
    appointmentType = {},
    calendars,
    sessions,
    createNewReservationSession,
    updateReservationSession,
    isMultiSession,
    inboxConversationTemplate,
} = {}) {
    const { appt_type_id, active, title, img, description, appointmentLength, calendarId } = appointmentType;
    const getDefaultBookingDetails = () => {
        return {
            onWebsite: appointmentType.onWebsite,
            appointmentLength: appointmentLength,
            calendarId: calendarId,
            fromDate: _.isNil(_.get(sessions, '[0].start_date'))
                ? null
                : moment(_.get(sessions, '[0].start_date'), 'YYYY-MM-DD'),
            toDate: _.isNil(_.get(sessions, '[0].end_date'))
                ? null
                : moment(_.get(sessions, '[0].end_date'), 'YYYY-MM-DD'),
        };
    };

    const calendar = _.find(calendars, ({ calendar_id }) => calendar_id === calendarId);

    const history = useHistory();

    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showLimitBookable, setShowLimitBookable] = useState(false);
    const [bookingDetails, setBookingDetails] = useState(getDefaultBookingDetails());
    const [showMultiSessionConfigure, setShowMultiSessionConfigure] = useState(false);
    const [showNewWorkflowSnackbar, setShowNewWorkflowSnackbar] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);

    const recentBookedCount = _.reduce(
        sessions,
        (sum, session) => {
            return sum + _.reduce(_.values(session.recent_reservation_counts), (s, c) => s + c, 0);
        },
        0
    );

    const appointmentLengths = _.uniq(_.map(sessions, ({ expected_length }) => expected_length));

    const getLengthText = () => {
        const l = _.size(appointmentLengths);
        if (l > 2) {
            return `${_.slice(appointmentLengths, 0, l - 1).join(', ')} or ${_.last(appointmentLengths)}`;
        }
        return appointmentLengths.join(' or ');
    };

    return (
        <div className={classnames({ 'appointment-availability-row': true, active })}>
            <div className="heading-row">
                <div className="title">
                    {isMultiSession && <MultiSessionIcon size={20} color="#fff" />}
                    <span> {title}</span>
                </div>

                {isEditing ? (
                    <Button
                        style={{ width: '90px' }}
                        onClick={() => {
                            setBookingDetails(getDefaultBookingDetails());
                            setIsEditing(false);
                        }}
                    >
                        Cancel
                    </Button>
                ) : (
                    active && (
                        <Button
                            onClick={() => {
                                if (isMultiSession) {
                                    setShowMultiSessionConfigure(true);
                                } else {
                                    setIsEditing(true);
                                }
                            }}
                        >
                            Edit
                        </Button>
                    )
                )}
            </div>

            <div className="main-appt-availability-content">
                <div className="image-area">
                    <img src={getTemplateImageUrl({ image_ref: img })} alt={title} />
                </div>

                <div className="appt-availability-details">
                    <div className="top-line">
                        <div className="description">{description}</div>

                        <div className="available-date-range">
                            {_.isNil(_.get(bookingDetails, 'fromDate')) && _.isNil(_.get(bookingDetails, 'toDate')) ? (
                                isEditing && (
                                    <Button
                                        className="change-date-range-button"
                                        color="primary"
                                        onClick={() => setShowLimitBookable(true)}
                                    >
                                        Limit when bookable?
                                    </Button>
                                )
                            ) : (
                                <AvailableDateRange
                                    {...bookingDetails}
                                    editing={isEditing}
                                    onEdit={() => setShowLimitBookable(true)}
                                />
                            )}
                        </div>
                    </div>

                    {active && (
                        <div className="bottom-line">
                            {isMultiSession && _.size(appointmentLengths) > 1 ? (
                                <div className="appt-length">{getLengthText()} minute appointments</div>
                            ) : (
                                <div className="appt-length">{appointmentLength} minute appointments</div>
                            )}

                            {isMultiSession && _.size(_.uniq(_.map(sessions, ({ calendar_id }) => calendar_id))) > 1 ? (
                                <div className="calendar">
                                    on {_.size(_.uniq(_.map(sessions, ({ calendar_id }) => calendar_id)))} calendars
                                </div>
                            ) : (
                                <div className="calendar">
                                    on &nbsp;
                                    <EventIcon style={{ color: _.get(calendar, 'color') }} />
                                    &nbsp;
                                    <span className="calendar-name">{_.get(calendar, 'name')}</span>&nbsp;calendar
                                </div>
                            )}

                            <div className="number-of-bookings">
                                {numeral(recentBookedCount).format('0,0')} recent bookings
                            </div>
                        </div>
                    )}
                </div>

                <Tooltip
                    title={
                        _.get(bookingDetails, 'onWebsite', false)
                            ? 'Patients may schedule this appointment type on your website.'
                            : 'This type of appointment is not available for scheduling on your website.'
                    }
                >
                    <div className="on-web-indicator">
                        {_.get(bookingDetails, 'onWebsite', false) ? (
                            <PublicIcon className="web-enabled" />
                        ) : (
                            <>
                                <PublicIcon />
                                <div className="crossout" />
                            </>
                        )}
                    </div>
                </Tooltip>

                <FormControlLabel
                    className={classnames({ 'on-web-switch': true, editing: isEditing })}
                    control={
                        <Switch
                            checked={_.get(bookingDetails, 'onWebsite', false)}
                            onChange={(e) => setBookingDetails({ ...bookingDetails, onWebsite: e.target.checked })}
                            color="primary"
                        />
                    }
                    label="Available on web?"
                />

                {!active ? (
                    <div className={classnames({ 'configure-and-message': true, editing: isEditing })}>
                        <Button
                            className="configure-button"
                            color="primary"
                            variant="contained"
                            disabled={isEditing}
                            onClick={() => {
                                if (isMultiSession) {
                                    setShowMultiSessionConfigure(true);
                                } else {
                                    setIsEditing(true);
                                }
                            }}
                        >
                            Configure Booking Details
                        </Button>

                        <div className="configure-message">
                            You must specify the calendar for this appointment type and other details before these
                            appointments may be made available.
                        </div>
                    </div>
                ) : (
                    <div />
                )}

                <div className={classnames({ 'editing-bottom-bar': true, editing: isEditing })}>
                    <AppointmentLengthSelect
                        value={_.get(bookingDetails, 'appointmentLength')}
                        minWidth={160}
                        disablePortal={false}
                        onChange={(e) =>
                            setBookingDetails({ ...bookingDetails, appointmentLength: Number(e.target.value) })
                        }
                    />

                    <CalendarSelect
                        value={_.get(bookingDetails, 'calendarId')}
                        minWidth={260}
                        calendars={calendars}
                        onChange={(e) => setBookingDetails({ ...bookingDetails, calendarId: e.target.value })}
                    />

                    <Button
                        className="save-button"
                        color="primary"
                        variant="contained"
                        disabled={
                            isSaving ||
                            !_.isFinite(_.get(bookingDetails, 'appointmentLength')) ||
                            _.isNil(_.get(bookingDetails, 'calendarId'))
                        }
                        onClick={() => {
                            const fromDate = _.isNil(bookingDetails.fromDate)
                                ? null
                                : _.get(bookingDetails, 'fromDate').format('YYYY-MM-DD');
                            const toDate = _.isNil(bookingDetails.toDate)
                                ? null
                                : _.get(bookingDetails, 'toDate').format('YYYY-MM-DD');

                            if (_.isEmpty(sessions)) {
                                setIsSaving(true);
                                createNewReservationSession(_.get(bookingDetails, 'calendarId'), {
                                    calendar_id: _.get(bookingDetails, 'calendarId'),
                                    status: 'ACTIVE',
                                    label: title,
                                    expected_length: _.get(bookingDetails, 'appointmentLength'),
                                    start_date: fromDate,
                                    end_date: toDate,
                                    is_reusable: 1,
                                    appt_type_id,
                                    available_on_web: _.get(bookingDetails, 'onWebsite', false) ? 1 : 0,
                                })
                                    .then(() => {
                                        setIsEditing(false);
                                        setShowNewWorkflowSnackbar(true);
                                    })
                                    .finally(() => {
                                        setIsSaving(false);
                                    });
                            } else if (_.size(sessions) === 1) {
                                setIsSaving(true);
                                updateReservationSession(
                                    _.get(bookingDetails, 'calendarId'),
                                    _.get(sessions, '[0].session_id'),
                                    {
                                        ...sessions[0],
                                        calendar_id: _.get(bookingDetails, 'calendarId'),
                                        status: 'ACTIVE',
                                        label: title,
                                        expected_length: _.get(bookingDetails, 'appointmentLength'),
                                        start_date: fromDate,
                                        end_date: toDate,
                                        is_reusable: 1,
                                        appt_type_id,
                                        available_on_web: _.get(bookingDetails, 'onWebsite', false) ? 1 : 0,
                                    }
                                )
                                    .then((response) => {
                                        setIsEditing(false);
                                    })
                                    .finally(() => setIsSaving(false));
                            } else {
                                throw new Error('Unable to handle multiple sessions', sessions);
                            }
                        }}
                    >
                        {isSaving ? (
                            <span>
                                <CircularProgress size={13} /> Saving...
                            </span>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </div>

                {showNewWorkflowSnackbar && (
                    <div className="new-workflow-snackbar">
                        <div className="icon-and-text">
                            <NewReleasesIcon />

                            <div className="text">
                                <div className="main-line">
                                    <span>New {title} workflow added</span>
                                    <Button
                                        style={{ textTransform: 'none', padding: 0, marginLeft: '10px' }}
                                        color="primary"
                                        onClick={() => {
                                            const editingTemplate = _.find(
                                                _.get(inboxConversationTemplate, 'templates'),
                                                ({ message_content }) => {
                                                    return (
                                                        _.get(message_content, 'messaging_form.appt_type_id') ===
                                                        appt_type_id
                                                    );
                                                }
                                            );

                                            setEditingTemplate(editingTemplate);
                                        }}
                                    >
                                        customize workflow
                                    </Button>
                                </div>
                                <div className="sub-line">
                                    A new workflow for scheduling this appointment type has been added to your{' '}
                                    <Button
                                        style={{ textTransform: 'none', padding: 0 }}
                                        color="primary"
                                        onClick={() => history.push('/workflows')}
                                    >
                                        workflows
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <Button onClick={() => setShowNewWorkflowSnackbar(false)}>OK</Button>
                    </div>
                )}

                {editingTemplate && (
                    <EditTemplateDialog
                        open
                        editingTemplate={editingTemplate}
                        onClose={() => setEditingTemplate(null)}
                    />
                )}
            </div>

            {showLimitBookable && (
                <Dialog
                    open
                    maxWidth="md"
                    className="appointment-availability-limit-dialog"
                    onClose={() => setShowLimitBookable(false)}
                >
                    <div className="heading-row">
                        <div className="title">{title}</div>
                    </div>

                    <DialogContent>
                        <div className="main-explanation">Limit when these appointments may be booked</div>
                        <div className="explanation">
                            While these appointments will be bookable based on the weekly schedule and vacations set on
                            the configured calendar, you may further limit their availability by specifying an optional
                            start and end date below.
                        </div>

                        <div className="calendars">
                            <div className="start-calendar">
                                <div className="calendar-heading">
                                    Available
                                    <span className="focused-date-value">
                                        {_.isNil(_.get(bookingDetails, 'fromDate')) ? (
                                            'immediately'
                                        ) : (
                                            <span className="date-and-cancel">
                                                {_.get(bookingDetails, 'fromDate').format('MMM Do, YYYY')}
                                                <Tooltip title="Clear this date">
                                                    <IconButton
                                                        onClick={() => {
                                                            setBookingDetails({ ...bookingDetails, fromDate: null });
                                                        }}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        )}
                                    </span>
                                </div>
                                <DateSelectionCalendar
                                    focusedDate={_.get(bookingDetails, 'fromDate')}
                                    onDateChange={(fromDate) => setBookingDetails({ ...bookingDetails, fromDate })}
                                    maxSelectableDate={_.get(bookingDetails, 'toDate')}
                                />
                            </div>

                            <div className="end-calendar">
                                <div className="calendar-heading">
                                    Until
                                    <span className="focused-date-value">
                                        {_.isNil(_.get(bookingDetails, 'toDate')) ? (
                                            'this is disabled'
                                        ) : (
                                            <span className="date-and-cancel">
                                                {_.get(bookingDetails, 'toDate').format('MMM Do, YYYY')}
                                                <Tooltip title="Clear this date">
                                                    <IconButton
                                                        onClick={() => {
                                                            setBookingDetails({ ...bookingDetails, toDate: null });
                                                        }}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        )}
                                    </span>
                                </div>
                                <DateSelectionCalendar
                                    focusedDate={_.get(bookingDetails, 'toDate')}
                                    onDateChange={(toDate) => setBookingDetails({ ...bookingDetails, toDate })}
                                    minSelectableDate={_.get(bookingDetails, 'fromDate')}
                                />
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => setShowLimitBookable(false)}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {showMultiSessionConfigure && (
                <Dialog
                    className="multi-session-appt-type-configure-dialog"
                    open
                    maxWidth="lg"
                    onClose={() => {
                        setShowMultiSessionConfigure(false);
                        setBookingDetails(getDefaultBookingDetails());
                    }}
                >
                    <div className="configure-heading">{appointmentType.title}</div>
                    <div className="subheading">
                        The {appointmentType.title} appointment type supports multiple appointment blocks.
                        {appointmentType.title === 'COVID-19 Vaccination' &&
                            ' Defining multiple blocks may be used to control when appointments for different vaccine types are available as well as for controlling how many of each type may be scheduled.'}
                    </div>

                    <div className="appointment-blocks-wrapper">
                        <AppointmentBlocksTable loadSessionsOnMount={false} createButtonOnTop />
                    </div>
                </Dialog>
            )}
        </div>
    );
}

export function AvailableDateRange({ fromDate, toDate, editing, onEdit, emptyContent = <span /> }) {
    if (_.isNil(fromDate) && _.isNil(toDate)) {
        return emptyContent;
    }

    return (
        <span className={classnames({ 'available-date-range': true, editing })}>
            {editing ? (
                <Tooltip title="Edit this date range">
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <DateRangeIcon />
            )}
            <span className="prefix">{_.isNil(fromDate) ? 'until' : 'from'}</span>
            <span className="dates">
                {!_.isNil(fromDate) && !_.isNil(toDate)
                    ? `${fromDate.format('MMM Do')} - ${toDate.format('MMM Do')}`
                    : _.first(_.filter([fromDate, toDate])).format('MMM Do')}
            </span>
        </span>
    );
}

function mapStateToProps(state) {
    const { apptCalendar, inboxConversationTemplate } = state;
    return {
        activeAppointmentTypesCounts: 0, // todo: make me look this up from redux...
        calendars: _.filter(
            _.get(apptCalendar, 'availableCalendars.calendars', []),
            ({ status }) => status === 'ACTIVE'
        ),
        inboxConversationTemplate,
    };
}

const bindActionsToDispatch = {
    createNewReservationSession,
    updateReservationSession,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentAvailabilityRow);

function MultiSessionIcon({ size = 24, color = '#88D9CB' } = {}) {
    return (
        <Tooltip title="This appointment type supports multiple appointment blocks.">
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="multi-session-icon"
            >
                <path
                    d={
                        'M21 17V8H7V17H21ZM21 3C21.5304 3 22.0391 3.21071 22.4142 3.58579C22.7893 3.96086 23 ' +
                        '4.46957 23 5V17C23 17.5304 22.7893 18.0391 22.4142 18.4142C22.0391 18.7893 21.5304 19 21 ' +
                        '19H7C6.46957 19 5.96086 18.7893 5.58579 18.4142C5.21071 18.0391 5 17.5304 5 17V5C5 4.46957 ' +
                        '5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H8V1H10V3H18V1H20V3H21ZM3 ' +
                        '21H17V23H3C2.46957 23 1.96086 22.7893 1.58579 22.4142C1.21071 22.0391 1 21.5304 1 21V9H3V21ZM19 ' +
                        '15H15V11H19V15Z'
                    }
                    fill={color}
                />
            </svg>
        </Tooltip>
    );
}
