import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { Cell, CircularProgress } from 'react-md';

import { SectionTitle, SectionSubTitle } from 'components/Common';

const Service = (props) => (
    <div className="grid-container">
        <Cell size={10}>
            <SectionTitle text={props.title} />
            <SectionSubTitle text={props.description} />
        </Cell>

        <Cell size={2} className="flex-middle flex-right">
            {props.isUpdating ? (
                <CircularProgress id={`${_.snakeCase(props.title)}_service_spinner`} />
            ) : (
                <Button
                    variant="outlined"
                    color={props.isActive ? 'secondary' : 'primary'}
                    onClick={props.isActive ? props.onDeactivate : props.onActivate}
                    disabled={!!props.disabled}
                >
                    {props.isActive ? 'Deactivate' : 'Activate'}
                </Button>
            )}
        </Cell>
    </div>
);

export default Service;
