/*
TODO centralize & cleanup redundant password logic & implementations spread across several components:
ResetPassword.jsx
AccountDetails.jsx
ChangePasswordInput.jsx
*/

/*
Valid Rule Object:
{
  letters: false,
  numbers: false,
  special: false,
  totalChars: false,
  noSpaces: false
}
*/

export const getPasswordErrMsg = (newPassword, validRule) => {
    let errMsg = '';

    if (newPassword === '') {
        errMsg = 'Please enter your new password';
    } else if (!validRule.numbers || !validRule.totalChars || !validRule.special || !validRule.letters) {
        errMsg = "Password doesn't match rules";
    } else if (!validRule.noSpaces) {
        errMsg = "Password can't contain spaces";
    }

    return errMsg;
};
