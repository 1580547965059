import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Calendar from './Calendar';
import NewsletterCardSettings from '../Calendar/NewsletterCardSettings';

const CalendarWrapper = (props) => {
    const { isActiveCard } = props;

    return (
        <Fragment>
            <div
                className={classnames({
                    hidden: isActiveCard,
                })}
            >
                <Calendar />
            </div>
            {isActiveCard && (
                <div>
                    <NewsletterCardSettings />
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    isActiveCard: state.newsletter.isActiveCard,
});

export default connect(mapStateToProps, null)(CalendarWrapper);
