import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userAction } from 'redux/Inbox/User';
import { conversationAction } from 'redux/Inbox/Conversation';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Conversation from './Conversation';
import { isUnmessageablePhoneType } from '../../../utils/helper';
import OptOutSnackbar from '../OptOutSnackBar';
import ConversationListNoMobileState from './ConversationListNoMobileState';
import ConversationListEmptyState from './ConversationListEmptyState';
import 'react-placeholder/lib/reactPlaceholder.css';
import ReactPlaceholder from 'react-placeholder';

class ConversationList extends Component {
    componentDidMount = () => {
        this.props.getConversations();
    };

    componentDidUpdate = (prevProps) => {
        const { inbox_user_id } = this.props.contact;
        const previous_inbox_user_id = prevProps.contact.inbox_user_id;
        if (inbox_user_id && previous_inbox_user_id && inbox_user_id !== previous_inbox_user_id) {
            this.props.getConversations();
        }
    };

    render() {
        const { phone_type, opt_out } = this.props.contact;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const isUnsubscribed = !!opt_out;
        const {
            contact,
            setActiveConversation,
            clearActiveConversation,
            toggleEditUserDialog,
            activeLocationCountryCode,
        } = this.props;
        const conversationStore = this.props.conversation;
        // const messageStore = this.props.message;
        const conversationComponents = conversationStore.conversations.map((conversation) => (
            <Conversation
                key={conversation.inbox_conversation_id}
                conversation={conversation}
                contact={contact}
                active={conversation.inbox_conversation_id === conversationStore.activeConversationId}
                setActive={setActiveConversation}
                clearActive={clearActiveConversation}
                activeLocationCountryCode={activeLocationCountryCode}
            />
        ));

        return (
            <div className="contact-conversations" style={{ padding: '0 16px' }}>
                {isUnsubscribed ? <OptOutSnackbar /> : ''}
                <div className="contact-section-title">
                    <Typography variant="overline" color="inherit" style={{ fontWeight: 500, fontSize: '12px' }}>
                        Conversations
                    </Typography>
                </div>
                <div className="contact-conversations-list">
                    {conversationStore.loading ? (
                        <Fragment>
                            {conversationStore.conversations && conversationStore.conversations.length > 0 ? (
                                <CircularProgress className="contact-conversations-list__progress" />
                            ) : (
                                <Fragment>
                                    <div className="contact-conversation-item">
                                        <ReactPlaceholder
                                            type="rect"
                                            color="#E0E0E0"
                                            style={{ height: 90 }}
                                            showLoadingAnimation
                                            ready={false}
                                        >
                                            <div></div>
                                        </ReactPlaceholder>
                                    </div>
                                    <div className="contact-conversation-item">
                                        <ReactPlaceholder
                                            type="rect"
                                            color="#E0E0E0"
                                            style={{ height: 90 }}
                                            showLoadingAnimation
                                            ready={false}
                                        >
                                            <div></div>
                                        </ReactPlaceholder>
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {isUnmessageable ? (
                                <ConversationListNoMobileState
                                    contact={contact}
                                    toggleEditContactDialog={toggleEditUserDialog}
                                />
                            ) : (
                                <Fragment>
                                    {conversationStore.conversations && conversationStore.conversations.length > 0 ? (
                                        <Fragment>{conversationComponents}</Fragment>
                                    ) : (
                                        <ConversationListEmptyState contact={contact} />
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { inboxConversation } = state;
    return {
        conversation: inboxConversation,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    setActiveConversation: (conversationId) => dispatch(conversationAction.setActiveConversation(conversationId)),
    clearActiveConversation: () => dispatch(conversationAction.clearActiveConversation()),
    getConversations: (autoSelectConversation = false) =>
        dispatch(conversationAction.getConversations(autoSelectConversation)),
    toggleEditUserDialog: () => dispatch(userAction.toggleEditUserDialog()),
});

export default connect(mapStateToProps, bindActionsToDispatch)(ConversationList);
