import React, { Component, Fragment } from 'react';
import { Grid, Cell, Card, CardText, CircularProgress, Button, SelectField } from 'react-md';
import { connect } from 'react-redux';
import { TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import {
    fetchLangChoices,
    fetchIvrLang,
    saveLangChoice,
    deleteNewLangChoice,
    removeLangChoice,
    addLangChoice,
    updateLang,
    updateVoice,
    updateValidationMsg,
    fetchLangWithGreeting,
    fetchIvrTTS,
} from 'redux/actionCreators/Settings/Languages';
import { changeTTSSetting, saveGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import AudioPlayer from 'components/Common/AudioPlayer';
import goBackService from 'utils/goBackService';
import { AbilityContext, userActions, restrictedResources } from 'casl';

export class Languages extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.state = {
            track: {
                current: '',
                previous: '',
            },
        };
    }

    componentDidMount() {
        this.loadInitialData();
    }

    componentDidUpdate(prevProps) {
        const { auth } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            this.loadInitialData();
        }
    }

    loadInitialData = () => {
        const { fetchLangWithGreeting, fetchIvrTTS } = this.props;
        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);

        fetchIvrTTS();
        fetchLangWithGreeting(canCreateGreeting);
    };

    handleLangChange = (e, idx) => {
        const { changeLanguage } = this.props;
        changeLanguage({ idx, data: e.target.value });
    };

    handleVoiceChange = (e, idx) => {
        const { changeVoice } = this.props;
        changeVoice({ idx, data: e.target.value });
    };

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    validate = () => {
        const {
            languageChoices: { langChoices },
            updateValidationMsg,
        } = { ...this.props };
        let isValid = true;
        langChoices.forEach((lang, idx) => {
            if (lang.langTypeId === '' || lang.voiceTypeId === '') {
                langChoices[idx].error = 'Required';
                isValid = false;
            }
        });
        updateValidationMsg({ langChoices });
        return isValid;
    };

    onSave = () => {
        if (this.validate()) {
            this.updateLangChoice();
        } else {
            this.props.displayToast({ text: 'Fill the details', type: 'error' });
        }
    };

    getFileName = (lang) => {
        const {
            languageChoices: { ivrLangMap },
        } = this.props;
        return `/${ivrLangMap[lang.langTypeId].langName}_${
            ivrLangMap[lang.langTypeId].voiceMap[lang.voiceTypeId].voiceName
        }.mp3`;
    };

    updateLangChoice = () => {
        const {
            languageChoices: { langChoices },
            mainGreeting: { defaultGreeting },
            displayToast,
        } = this.props;
        const { fetchLanguageChoices, saveLanguageChoice } = this.props;

        langChoices.forEach((choice, idx) => {
            choice.seq = idx + 1;
        });

        saveLanguageChoice({ id: defaultGreeting.id, langChoices }).then((resp) => {
            if ('data' in resp) {
                fetchLanguageChoices(defaultGreeting);
                displayToast({ text: 'Language choices saved', type: 'success' });
            } else {
                displayToast({ text: 'Failed to save language choices', type: 'error' });
            }
        });
    };

    getAvailLang = (selectedLangId) => {
        const ivrLangList = [];
        const {
            languageChoices: { langChoices, ivrLang },
        } = this.props;

        ivrLang.forEach((lang) => {
            const langUsed = _.find(langChoices, (choice) => choice.langTypeId === lang.langTypeId);
            if (!langUsed || langUsed.langTypeId === selectedLangId) {
                ivrLangList.push(lang);
            }
        });
        return ivrLangList;
    };

    deleteLangChoice = (data) => {
        const {
            deleteNewLangChoice,
            removeLangChoice,
            languageChoices: { langChoices },
        } = this.props;
        const existinglangChoices = [...langChoices];

        if (data.lang.id === '0') {
            deleteNewLangChoice(data);
        } else {
            const removeReq = removeLangChoice(data);
            removeReq.then((resp) => {
                if ('data' in resp) {
                    existinglangChoices.splice(data.idx, 1);
                    if (existinglangChoices.length > 1 || existinglangChoices[0].seq !== 1) {
                        this.updateLangChoice();
                    }
                }
            });
        }
    };

    setCurrentTrack = (greetingId) => {
        const { track } = this.state;
        if (greetingId !== track.current) track.previous = track.current;

        track.current = greetingId;
        this.setState({ track });
    };

    updateTTS = () => {
        const {
            saveGreeting,
            displayToast,
            mainGreeting: { defaultGreeting },
        } = this.props;
        const { id, tts_id } = defaultGreeting;

        saveGreeting({ newGreeting: { id, tts_id }, type: 'MAIN' }).then((respDetail) => {
            if (!respDetail.err) {
                this.loadInitialData();
                displayToast({ text: 'Updated TTS engine preference successfully', type: 'success' });
            } else {
                displayToast({ text: 'Failed to update TTS engine', type: 'error' });
            }
        });
    };

    render() {
        const {
            mainGreeting: { defaultGreeting, newGreeting },
            languageChoices: {
                langChoices,
                ivrLangMap,
                ivrLang,
                loadingLangChoices,
                loadingGreetingWithLangChoice,
                savingInProgress,
                ttsEngines,
            },
            auth: { isInternalUser },
            addLanguageChoice,
            ivrAddLang,
            changeTTSSetting,
        } = this.props;

        const { track } = this.state;
        const { view, write } = userActions;
        const { subject: caslSubject, fields: restrictedFields } = restrictedResources.ivr.languagesAndVoices;
        const existingChoices = _.filter(langChoices, (choice) => choice.id !== '0');

        // Note, write here means the user role can create, update, delete language and voices
        const canWriteLanguages = this.context.can(write, caslSubject);
        const canViewTTSEngine = this.context.can(view, caslSubject, restrictedFields.ttsEngine);

        // TODO: This component is huge and needs refactoring
        return (
            <Grid className="language-container">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Languages and Voices"
                            disabled={savingInProgress}
                            loading={savingInProgress}
                            onBack={this.onBack}
                            onSave={() => canWriteLanguages && this.onSave()}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="Languages" />
                                    <h5 className="sub-title-text">
                                        Configure which voices your patients hear when calling the phone system.
                                        {ivrAddLang && !isInternalUser && (
                                            <b> Please call our team to add or delete language options.</b>
                                        )}
                                    </h5>
                                    {loadingGreetingWithLangChoice || loadingLangChoices ? (
                                        <CircularProgress id="language-choices-spinner" />
                                    ) : (
                                        <Fragment>
                                            {langChoices.map(
                                                (lang, idx) =>
                                                    (ivrLangMap[lang.langTypeId] !== undefined || lang.id === '0') && (
                                                        <span key={idx}>
                                                            <div className="grid-container">
                                                                <div className="grid-cell-12 label-row">
                                                                    Option {idx + 1}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="grid-container lang-setting-container"
                                                                style={{ marginTop: '5px' }}
                                                            >
                                                                <Cell size={3} style={{ marginRight: '16px' }}>
                                                                    {lang.id !== '0' && (
                                                                        <TextField
                                                                            id="outlined-lang"
                                                                            name="lang"
                                                                            label="Language"
                                                                            value={ivrLangMap[lang.langTypeId].langName}
                                                                            onChange={(e) => {}}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={!canWriteLanguages}
                                                                        />
                                                                    )}
                                                                    {lang.id === '0' && (
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            error={'error' in lang && lang.error !== ''}
                                                                        >
                                                                            <InputLabel htmlFor="outlined-lang">
                                                                                Language
                                                                            </InputLabel>
                                                                            <Select
                                                                                value={lang.langTypeId}
                                                                                disabled={
                                                                                    !canWriteLanguages ||
                                                                                    savingInProgress
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleLangChange(e, idx)
                                                                                }
                                                                                input={
                                                                                    <OutlinedInput
                                                                                        fullWidth
                                                                                        labelWidth={55}
                                                                                        name="lang"
                                                                                        id="outlined-lang"
                                                                                    />
                                                                                }
                                                                            >
                                                                                {this.getAvailLang(lang.langTypeId).map(
                                                                                    (
                                                                                        { langTypeId, langName },
                                                                                        tIdx
                                                                                    ) => (
                                                                                        <MenuItem
                                                                                            key={`lang${tIdx}`}
                                                                                            value={langTypeId}
                                                                                        >
                                                                                            {langName}
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )}
                                                                </Cell>

                                                                <Cell size={3} style={{ marginRight: '15px' }}>
                                                                    {lang.langTypeId && (
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            error={'error' in lang && lang.error !== ''}
                                                                        >
                                                                            <InputLabel htmlFor="outlined-voice">
                                                                                Voice
                                                                            </InputLabel>
                                                                            <Select
                                                                                value={lang.voiceTypeId}
                                                                                disabled={
                                                                                    !canWriteLanguages ||
                                                                                    savingInProgress
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleVoiceChange(e, idx)
                                                                                }
                                                                                input={
                                                                                    <OutlinedInput
                                                                                        fullWidth
                                                                                        labelWidth={42}
                                                                                        name="voice"
                                                                                        id="outlined-voice"
                                                                                    />
                                                                                }
                                                                            >
                                                                                {ivrLangMap[lang.langTypeId].voice.map(
                                                                                    (
                                                                                        { voiceTypeId, voiceName },
                                                                                        tIdx
                                                                                    ) => (
                                                                                        <MenuItem
                                                                                            key={`voice${tIdx}`}
                                                                                            value={voiceTypeId}
                                                                                        >
                                                                                            {voiceName}
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                            </Select>
                                                                        </FormControl>
                                                                    )}
                                                                </Cell>
                                                                <Cell size={3} className="flex-middle">
                                                                    {lang.langTypeId && lang.voiceTypeId && (
                                                                        <AudioPlayer
                                                                            fileSource={this.getFileName(lang)}
                                                                            pauseAudio={track.previous === lang.id}
                                                                            labelText="Preview"
                                                                            audioStarted={() =>
                                                                                this.setCurrentTrack(lang.id)
                                                                            }
                                                                        />
                                                                    )}
                                                                    {(existingChoices.length > 1 || lang.id === '0') &&
                                                                        canWriteLanguages && (
                                                                            <span style={{ marginLeft: '13px' }}>
                                                                                {lang.processing ? (
                                                                                    <CircularProgress id="remove-language-choices-spinner" />
                                                                                ) : (
                                                                                    <Button
                                                                                        icon
                                                                                        onClick={() =>
                                                                                            this.deleteLangChoice({
                                                                                                defaultGreeting,
                                                                                                lang,
                                                                                                idx,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        delete
                                                                                    </Button>
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                </Cell>
                                                            </div>
                                                            <div className="form-err-container standard-margin-left-10">
                                                                <FormHelperText error className="form-err-txt">
                                                                    {lang.error}
                                                                </FormHelperText>
                                                            </div>
                                                        </span>
                                                    )
                                            )}
                                            {langChoices.length < ivrLang.length && ivrAddLang && !savingInProgress && (
                                                <div className="grid-container buttons-container">
                                                    <Cell
                                                        size={6}
                                                        tabletSize={4}
                                                        phoneSize={2}
                                                        className="button-control no-margin"
                                                        style={{ marginLeft: '8px' }}
                                                    >
                                                        {canWriteLanguages ? (
                                                            <Button
                                                                flat
                                                                secondary
                                                                onClick={addLanguageChoice}
                                                                className="add-btn no-margin no-padding"
                                                            >
                                                                + ADD ANOTHER LANGUAGE
                                                            </Button>
                                                        ) : (
                                                            <div className="no-perms-note">
                                                                You don't have permission to add/update
                                                            </div>
                                                        )}
                                                    </Cell>
                                                </div>
                                            )}
                                            {canViewTTSEngine && (
                                                <Fragment>
                                                    <SectionTitle
                                                        text="TTS Engine (Internal Users Only)"
                                                        style={{ marginTop: '30px' }}
                                                    />
                                                    <SectionSubTitle text="This TTS engine will be used for this location's IVR calls" />
                                                    <div className="grid-container">
                                                        <Cell size={4} phoneSize={3} className="bordered-select">
                                                            <SelectField
                                                                id="select-overflow"
                                                                placeholder="Select a Item"
                                                                disabled={savingInProgress}
                                                                fullWidth
                                                                position={SelectField.Positions.BELOW}
                                                                itemLabel="name"
                                                                itemValue="id"
                                                                menuItems={ttsEngines}
                                                                value={defaultGreeting.tts_id}
                                                                onChange={(value) => changeTTSSetting(value)}
                                                                simplifiedMenu={false}
                                                            />
                                                        </Cell>
                                                        <Cell size={2} phoneSize={3} className="flex-middle">
                                                            {newGreeting.processing ? (
                                                                <CircularProgress id="save-tts-loader" />
                                                            ) : (
                                                                <Button
                                                                    raised
                                                                    primary
                                                                    onClick={this.updateTTS}
                                                                    className="button-blue"
                                                                    disabled={
                                                                        !defaultGreeting.old_tts_id ||
                                                                        (defaultGreeting.old_tts_id &&
                                                                            defaultGreeting.old_tts_id ===
                                                                                defaultGreeting.tts_id)
                                                                    }
                                                                >
                                                                    Update Engine
                                                                </Button>
                                                            )}
                                                        </Cell>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    mainGreeting: { ...state.settings.mainGreeting },
    languageChoices: { ...state.settings.languageChoices },
    auth: { ...state.auth },
    ivrAddLang: state.pharmacy.ivrAddLang,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLangWithGreeting: (data) => dispatch(fetchLangWithGreeting(data)),
    fetchIvrLanguages: () => dispatch(fetchIvrLang()),
    fetchIvrTTS: () => dispatch(fetchIvrTTS()),
    fetchLanguageChoices: (data) => dispatch(fetchLangChoices(data)),
    addLanguageChoice: () => dispatch(addLangChoice()),
    changeLanguage: (data) => dispatch(updateLang(data)),
    changeVoice: (data) => dispatch(updateVoice(data)),
    deleteNewLangChoice: (data) => dispatch(deleteNewLangChoice(data)),
    removeLangChoice: (data) => dispatch(removeLangChoice(data)),
    updateValidationMsg: (data) => dispatch(updateValidationMsg(data)),
    saveLanguageChoice: (data) => dispatch(saveLangChoice(data)),
    changeTTSSetting: (data) => dispatch(changeTTSSetting(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
