export default {
    FETCH_LOCATION_SUCCESS: 'FETCH_LOCATION_SUCCESS',
    FETCH_LOCATION_FAILED: 'FETCH_LOCATION_FAILED',
    FETCH_DEFAULT_HOURS_STARTED: 'FETCH_DEFAULT_HOURS_STARTED',
    FETCH_DEFAULT_HOURS_SUCCESS: 'FETCH_DEFAULT_HOURS_SUCCESS',
    FETCH_DEFAULT_HOURS_FAILED: 'FETCH_DEFAULT_HOURS_FAILED',
    FETCH_EXCEPTION_HOURS_STARTED: 'FETCH_EXCEPTION_HOURS_STARTED',
    FETCH_EXCEPTION_HOURS_SUCCESS: 'FETCH_EXCEPTION_HOURS_SUCCESS',
    FETCH_EXCEPTION_HOURS_FAILED: 'FETCH_EXCEPTION_HOURS_FAILED',
    ADD_EXCEPTION_HOURS: 'ADD_EXCEPTION_HOURS',
    UPDATE_DAYS_OF_WEEK: 'UPATE_DAYS_OF_WEEK',
    UPDATE_HOLIDAYS: 'UPDATE_HOLIDAYS',
    SAVE_DEFAULT_HOURS_STARTED: 'SAVE_DEFAULT_HOURS_STARTED',
    SAVE_DEFAULT_HOURS_FAILED: 'SAVE_DEFAULT_HOURS_FAILED',
    SAVE_DEFAULT_HOURS_SUCCESS: 'SAVE_DEFAULT_HOURS_SUCCESS',
    UPDATE_EXCEPTION_DATE: 'UPDATE_EXCEPTION_DATE',
    UPDATE_EXCEPTION_TITLE: 'UPDATE_EXCEPTION_TITLE',
    REMOVE_EXCEPTION_HOUR_STARTED: 'REMOVE_EXCEPTION_HOUR_STARTED',
    REMOVE_EXCEPTION_HOUR_SUCCESS: 'REMOVE_EXCEPTION_HOUR_SUCCESS',
    REMOVE_EXCEPTION_HOUR_FAILED: 'REMOVE_EXCEPTION_HOUR_FAILED',
    EXCEPTION_HOURS_CONFLICTS: 'EXCEPTION_HOURS_CONFLICTS',
    FETCH_EXCEPTION_HOURS_CONFLICT_STARTED: 'FETCH_EXCEPTION_HOURS_CONFLICT_STARTED',
    FETCH_EXCEPTION_HOURS_CONFLICT_SUCCESS: 'FETCH_EXCEPTION_HOURS_CONFLICT_SUCCESS',
    FETCH_EXCEPTION_HOURS_CONFLICT_FAILED: 'FETCH_EXCEPTION_HOURS_CONFLICT_FAILED',
    HOLIDAY_SELECTED: 'HOLIDAY_SELECTED',
    FETCH_HOLIDAY_LIST_STARTED: 'FETCH_HOLIDAY_LIST_STARTED',
    FETCH_HOLIDAY_LIST_SUCCESS: 'FETCH_HOLIDAY_LIST_SUCCESS',
    FETCH_HOLIDAY_LIST_FAILED: 'FETCH_HOLIDAY_LIST_FAILED',
    UPDATE_HOLIDAY_REPEAT: 'UPDATE_HOLIDAY_REPEAT',
    SAVE_CUSTOM_HOLIDAYS_STARTED: 'SAVE_CUSTOM_HOLIDAYS_STARTED',
    SAVE_CUSTOM_HOLIDAYS_SUCCESS: 'SAVE_CUSTOM_HOLIDAYS_SUCCESS',
    SAVE_CUSTOM_HOLIDAYS_FAILED: 'SAVE_CUSTOM_HOLIDAYS_FAILED',
    SAVE_EXCEPTION_HOURS_STARTED: 'SAVE_EXCEPTION_HOURS_STARTED',
    SAVE_EXCEPTION_HOURS_SUCCESS: 'SAVE_EXCEPTION_HOURS_SUCCESS',
    SAVE_EXCEPTION_HOURS_FAILED: 'SAVE_EXCEPTION_HOURS_FAILED',
    FETCH_HOLIDAY_CONFLICT_SUCCESS: 'FETCH_HOLIDAY_CONFLICT_SUCCESS',
};
