import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import NoAccess from 'components/Common/NoAccess';
import Newsletter from './Newsletter';

function NewsletterWrapper({ isNewsletterEnabled, isNewsletterPackageEnabled, ...rest }) {
    return (
        <Fragment>
            {isNewsletterEnabled ? (
                <Newsletter {...rest} isNewsletterPackageEnabled={isNewsletterPackageEnabled} />
            ) : (
                <div className="newsletter-container" style={{ width: '100%' }}>
                    <NoAccess />
                </div>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    isNewsletterEnabled: state.pharmacy.newslettersV2Enabled,
    isNewsletterPackageEnabled: state.pharmacy.newsletterReportsEnabled,
});

export default connect(mapStateToProps, null)(NewsletterWrapper);
