import React from 'react';
import Grid from '@material-ui/core/Grid';
import { BuildSteps } from './BuildSteps';
import { LiveVersions } from './LiveVersions';

export const BuildDetails = ({ app }) => {
    return (
        <div className="collapse-content">
            <Grid container spacing={1}>
                <LiveVersions app={app} />
                <BuildSteps app={app} appType="android" style={{ marginTop: '24px' }} />
                <BuildSteps app={app} appType="apple" style={{ marginTop: '24px' }} />
            </Grid>
        </div>
    );
};
