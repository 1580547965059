import messageType from './type';

export const initialState = {
    sending: false,
    loading: false,
    messages: [],
    createMessageMessage: '',
    selectedFile: '',
    error: {},
};

export const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case messageType.RESET_MESSAGE_STORE:
            return {
                ...initialState,
            };
        case messageType.CREATE_MESSAGE_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case messageType.CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                sending: false,
                createMessageMessage: '',
            };
        case messageType.CREATE_MESSAGE_FAILURE:
            return {
                ...state,
                sending: false,
                createMessageMessage: action.error.message,
                error: action.error,
            };
        case messageType.CREATE_ATTACHMENT_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case messageType.CREATE_ATTACHMENT_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case messageType.CREATE_ATTACHMENT_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case messageType.GET_MESSAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case messageType.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.messages,
            };
        case messageType.GET_MESSAGES_FAILURE:
            return {
                ...state,
                loading: false,
                messages: [],
                error: action.error,
            };
        case messageType.GET_LATEST_MESSAGE_REQUEST:
            return {
                ...state,
            };
        case messageType.GET_LATEST_MESSAGE_SUCCESS:
            return {
                ...state,
            };
        case messageType.GET_LATEST_MESSAGE_FAILURE:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_REQUEST:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS:
            return {
                ...state,
            };
        case messageType.UPDATE_MESSAGE_READ_STATUS_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default messageReducer;
