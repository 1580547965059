const menuItem = {
    id: '0',
    phone: '',
    key: '',
    seq: 0,
    maxSeconds: 15,
    transferto: 'phone',
};

export default menuItem;
