import { waitlistTypes } from './type';
import { waitlistService } from './service';
import config from '../../config';
import _ from 'lodash';

const fetchWaitlists = (waitlistName) => {
    return (dispatch) => {
        dispatch({ type: waitlistTypes.FETCH_WAITLISTS });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        return waitlistService
            .fetchWaitlists(pharmacyId, locationId, waitlistName)
            .then((response) => {
                dispatch({
                    type: waitlistTypes.FETCH_WAITLISTS_SUCCESS,
                    payload: {
                        patient_count: response.patient_count,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: waitlistTypes.FETCH_WAITLISTS_FAILED,
                    payload: {
                        error,
                    },
                });
            });
    };
};

const downloadWaitlistCsv = (waitlistName) => {
    return (dispatch) => {
        dispatch({ type: waitlistTypes.DOWNLOAD_WAITLIST_CSV, payload: { waitlistName } });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        return waitlistService
            .downloadWaitlistCsv(pharmacyId, locationId, waitlistName)
            .then(() => {
                dispatch({
                    type: waitlistTypes.DOWNLOAD_WAITLIST_CSV_SUCCESS,
                    payload: { waitlistName },
                });
            })
            .catch((error) => {
                dispatch({
                    type: waitlistTypes.DOWNLOAD_WAITLIST_CSV_FAILED,
                    payload: {
                        waitlistName,
                        error,
                    },
                });
            });
    };
};

const removeFromWaitlists = (waitlistIdInboxUserIdPairs) => {
    return async (dispatch, getState) => {
        const state = getState().waitlist;

        if (_.get(state, `removingFromWaitlists.loading`)) {
            return Promise.resolve();
        }

        const byWaitlistId = _.groupBy(waitlistIdInboxUserIdPairs, ({ waitlistId }) => waitlistId);

        dispatch({
            type: waitlistTypes.REMOVE_FROM_WAITLIST_REQUEST,
            payload: { waitlistIdInboxUserIdPairs },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const results = await Promise.allSettled(
                _.map(_.keys(byWaitlistId), (waitlistId) => {
                    return waitlistService.removeFromWaitlist(
                        pharmacyId,
                        locationId,
                        waitlistId,
                        _.map(byWaitlistId[waitlistId], ({ inbox_user_id }) => inbox_user_id)
                    );
                })
            );

            dispatch({
                type: waitlistTypes.REMOVE_FROM_WAITLIST_SUCCESS,
                payload: { waitlistIdInboxUserIdPairs },
            });

            return results;
        } catch (error) {
            dispatch({
                type: waitlistTypes.REMOVE_FROM_WAITLIST_FAILURE,
                payload: { waitlistIdInboxUserIdPairs },
            });
        }
    };
};

const updateWaitlistStatus = (waitlistIdInboxUserIdPairs, status) => {
    return async (dispatch, getState) => {
        const state = getState().waitlist;

        if (_.get(state, `updatingWaitlistStatus.loading`)) {
            return Promise.resolve();
        }

        const byWaitlistId = _.groupBy(waitlistIdInboxUserIdPairs, ({ waitlistId }) => waitlistId);

        dispatch({
            type: waitlistTypes.UPDATE_WAITLIST_STATUS_REQUEST,
            payload: { waitlistIdInboxUserIdPairs, status },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const results = await Promise.allSettled(
                _.map(_.keys(byWaitlistId), (waitlistId) => {
                    return waitlistService.updateWaitlistStatus(
                        pharmacyId,
                        locationId,
                        waitlistId,
                        _.map(byWaitlistId[waitlistId], ({ inbox_user_id }) => inbox_user_id),
                        status
                    );
                })
            );

            dispatch({
                type: waitlistTypes.UPDATE_WAITLIST_STATUS_SUCCESS,
                payload: { results, waitlistIdInboxUserIdPairs, status },
            });

            return results;
        } catch (error) {
            dispatch({
                type: waitlistTypes.UPDATE_WAITLIST_STATUS_FAILURE,
                payload: { error, waitlistIdInboxUserIdPairs, status },
            });
        }
    };
};

export const waitlistActions = {
    fetchWaitlists,
    downloadWaitlistCsv,
    removeFromWaitlists,
    updateWaitlistStatus,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
