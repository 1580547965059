import Config from '../config';

class GlobalSiteContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/site/jsonschema/1-0-0';
        this.data = {
            pharmacyId: Config.X_PharmacyID,
            locationId: Config.X_LocationID,
        };
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { GlobalSiteContext };

class ElectronContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/electron/jsonschema/1-0-0';
        this.data = {
            electronAppVersion: null,
        };
    }

    setElectronAppVersion(electronAppVersion) {
        this.data.electronAppVersion = electronAppVersion;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { ElectronContext };

class InboxMessageContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/inbox_message/jsonschema/1-0-0';
        this.data = {
            conversationId: null,
            messageId: null,
            patientInboxUserId: null,
        };
    }

    setConversationId(conversationId) {
        this.data.conversationId = conversationId;
        return this;
    }

    setMessageId(messageId) {
        this.data.messageId = messageId;
        return this;
    }

    setPatientInboxUserId(patientInboxUserId) {
        this.data.patientInboxUserId = patientInboxUserId;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { InboxMessageContext };

class EventRefContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/event_ref/jsonschema/1-0-0';
        this.data = {
            eventRef: null,
            parentEventRef: null,
        };
    }

    setEventRef(eventRef) {
        this.data.eventRef = eventRef;
        return this;
    }

    setParentEventRef(parentEventRef) {
        this.data.parentEventRef = parentEventRef;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { EventRefContext };

class NPEUserContext {
    constructor(userId = null, userType = null) {
        this.schema = 'iglu:com.digitalpharmacist/npe_user/jsonschema/1-0-0';
        this.data = {
            userId,
            userType,
        };
    }

    setUserType(userType) {
        this.data.userType = userType;
        return this;
    }

    setUserId(userId) {
        this.data.userId = userId;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { NPEUserContext };

class UserManagementContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/user_management/jsonschema/1-0-0';
        this.data = {
            userId: null,
        };
    }

    setUserId(userId) {
        this.data.userId = userId;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { UserManagementContext };

class RxConferWorkflowContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/rx_confer_workflow/jsonschema/1-0-0';
        this.data = {
            workflowTemplateId: null,
        };
    }

    setWorkflowTemplateId(workflowTemplateId) {
        this.data.workflowTemplateId = workflowTemplateId;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

class RxConferPatientContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/rx_confer_patient/jsonschema/1-0-0';
        this.data = {
            patientCount: null,
        };
    }

    setPatientCount(patientCount) {
        this.data.patientCount = patientCount;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

class RxConferPatientSearchContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/rx_confer_patient_search/jsonschema/1-0-0';
        this.data = {
            searchType: null,
            from: null,
            size: null,
            sort: null,
            filters: null,
        };
    }

    setSearchType(searchType) {
        this.data.searchType = searchType;
        return this;
    }

    setFrom(from) {
        this.data.from = from;
        return this;
    }

    setSize(size) {
        this.data.size = size;
        return this;
    }

    setSort(sort) {
        this.data.sort = sort;
        return this;
    }

    setFilters(filters) {
        this.data.filters = filters;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export { RxConferWorkflowContext, RxConferPatientContext, RxConferPatientSearchContext };

class AppointmentCalendarContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/appointment_calendar/jsonschema/1-0-0';
        this.data = {
            calendarId: null,
            name: null,
        };
    }

    setCalendarId(calendarId) {
        this.data.calendarId = calendarId;
        return this;
    }

    setName(name) {
        this.data.name = name;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

class AppointmentContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/appointment/jsonschema/1-0-0';
        this.data = {
            reservationId: null,
            calendarId: null,
            inboxUserId: null,
            label: null,
            length: null,
            oneOff: null,
        };
    }

    setReservationId(reservationId) {
        this.data.reservationId = reservationId;
        return this;
    }

    setCalendarId(calendarId) {
        this.data.calendarId = calendarId;
        return this;
    }

    setInboxUserId(inboxUserId) {
        this.data.inboxUserId = inboxUserId;
        return this;
    }

    setLabel(label) {
        this.data.label = label;
        return this;
    }

    setLength(length) {
        this.data.length = length;
        return this;
    }

    setOneOff(oneOff) {
        this.data.oneOff = oneOff;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

class AppointmentWorkflowContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/appointment_workflow/jsonschema/1-0-0';
        this.data = {
            templateId: null,
            parentTemplateId: null,
            calendarId: null,
            appointmentLength: null,
        };
    }

    setTemplateId(templateId) {
        this.data.templateId = templateId;
        return this;
    }

    setParentTemplateId(parentTemplateId) {
        this.data.parentTemplateId = parentTemplateId;
        return this;
    }

    setCalendarId(calendarId) {
        this.data.calendarId = calendarId;
        return this;
    }

    setAppointmentLength(appointmentLength) {
        this.data.appointmentLength = appointmentLength;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

class AppointmentWorkflowSessionContext {
    constructor() {
        this.schema = 'iglu:com.digitalpharmacist/appointment_workflow_session/jsonschema/1-0-0';
        this.data = {
            templateId: null,
            startDate: null,
            endDate: null,
            vaccineType: null,
            vaccineDoses: null,
            patientChooseMethod: null,
            numberOfPatientsRecommended: null,
            schedulingPercentageAssumption: null,
            numberOfPatientsContacted: null,
        };
    }

    setTemplateId(templateId) {
        this.data.templateId = templateId;
        return this;
    }

    setStartDate(startDate) {
        this.data.startDate = startDate;
        return this;
    }

    setEndDate(endDate) {
        this.data.endDate = endDate;
        return this;
    }

    setVaccineType(vaccineType) {
        this.data.vaccineType = vaccineType;
        return this;
    }

    setVaccineDoses(vaccineDoses) {
        this.data.vaccineDoses = vaccineDoses;
        return this;
    }

    setPatientChooseMethod(patientChooseMethod) {
        this.data.patientChooseMethod = patientChooseMethod;
        return this;
    }

    setNumberOfPatientsRecommended(numberOfPatientsRecommended) {
        this.data.numberOfPatientsRecommended = numberOfPatientsRecommended;
        return this;
    }

    setSchedulingPercentageAssumption(schedulingPercentageAssumption) {
        this.data.schedulingPercentageAssumption = schedulingPercentageAssumption;
        return this;
    }

    setNumberOfPatientsContacted(numberOfPatientsContacted) {
        this.data.numberOfPatientsContacted = numberOfPatientsContacted;
        return this;
    }

    build() {
        return { schema: this.schema, data: this.data };
    }
}

export {
    AppointmentCalendarContext,
    AppointmentContext,
    AppointmentWorkflowContext,
    AppointmentWorkflowSessionContext,
};
