import './AppointmentBlocksTable.sass';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AppointmentBlockSelector from 'components/Patients/scheduling/session/AppointmentBlockSelector';
import {
    createNewReservationSession,
    getLiveReusableReservationSessions,
    updateReservationSession,
} from 'redux/Appt/Reservation/action';
import _ from 'lodash';
import {
    clearAvailableAppointmentSlots,
    fetchAvailableAppointmentCalendars,
    getAvailableCalendarAppointmentSlots,
} from 'redux/Appt/Calendar/action';
import { getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import WarningIcon from '@material-ui/icons/Warning';

function AppointmentBlocksTable({
    apptCalendar,
    fetchAvailableAppointmentCalendars,
    loadSessionsOnMount = true,
    createButtonOnTop = false,
} = {}) {
    const props = arguments[0];

    const [timeRange, setTimeRange] = useState({
        from: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
        to: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
    });

    const [doseInfo, setDoseInfo] = useState(null);
    const [reservationSession, setReservationSession] = useState(null);
    const [calendarId, setCalendarId] = useState(null);
    const [appointmentLengthMinutes, setAppointmentLengthMinutes] = useState(null);

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    if (_.isNil(apptCalendar.availableCalendars)) {
        return null;
    }

    return (
        <div className="appointment-blocks-table">
            <AppointmentBlockSelector
                {...props}
                modifyInDialog
                loadSessionsOnMount={loadSessionsOnMount}
                createButtonOnTop={createButtonOnTop}
                totalWidth={900}
                {...{ timeRange, setTimeRange }}
                {...{ doseInfo, setDoseInfo }}
                {...{ reservationSession, setReservationSession }}
                {...{ calendarId, setCalendarId }}
                {...{ appointmentLengthMinutes, setAppointmentLengthMinutes }}
                onNext={() => {}}
                allowSelection={false}
                noBlocksWarningMessage={
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <WarningIcon style={{ color: '#F3616A', marginRight: '5px' }} /> With no active blocks, patients
                        will not be able to schedule on your website.
                    </span>
                }
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    apptReservation: state.apptReservation,
    apptCalendar: state.apptCalendar,
    pharmacy: state.pharmacy,
    auth: state.auth,
    calendars: _.filter(
        _.get(state.apptCalendar, 'availableCalendars.calendars', []),
        ({ status }) => status === 'ACTIVE'
    ),
    displayedDateFormat: getAbbreviatedDateInputFormatByCountryCode({
        countryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    }),
});

const bindActionsToDispatch = {
    getLiveReusableReservationSessions,
    clearAvailableAppointmentSlots,
    fetchAvailableAppointmentCalendars,
    getAvailableCalendarAppointmentSlots,
    createNewReservationSession,
    updateReservationSession,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentBlocksTable);
