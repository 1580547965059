import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { TextField, Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram/';
import CircularProgress from '@material-ui/core/CircularProgress';

import { newsletterSelectors, newsletterActions } from 'redux/Newsletter';
import { difference, validateEmail, validateUrl } from 'utils/helper';
import Snowplow, { StructEventCategories } from 'snowplow';

const Newsletter = (props) => {
    const [state, setState] = useState({
        senderName: '',
        senderEmailOverride: '',
        pharmacyFacebookURL: '',
        pharmacyTwitterURL: '',
        pharmacyInstagramURL: '',
    });
    const { config, savingNewsletterSettings, pharmacyLoading, canUpdate } = props;
    const { senderName, senderEmailOverride, pharmacyFacebookURL, pharmacyTwitterURL, pharmacyInstagramURL } = state;
    const isLoading = pharmacyLoading || savingNewsletterSettings;

    useEffect(() => {
        setState(config);
    }, [config]);

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        const { saveNewsletterSettings } = props;
        const stateDifference = difference(state, config);

        // The sender name and email need to be sent regardless of the diff state
        if (!stateDifference.senderName) stateDifference.senderName = state.senderName;
        if (!stateDifference.senderEmailOverride) stateDifference.senderEmailOverride = state.senderEmailOverride;

        saveNewsletterSettings(stateDifference);
        Snowplow.structEvent('NPE', StructEventCategories.settings, null, 'settings-newsletter-saved');
    };

    const isSaveDisabled = () => {
        return _.isEqual(config, state);
    };

    const getSenderNameErrorMessage = () => {
        if (senderName.length < 1) return 'Name is required.';
        if (senderName.length > 73) return 'Name is too long.';

        return '';
    };

    const getSenderEmailErrorMessage = () => {
        if (senderEmailOverride.length < 1) return 'Email is required.';

        if (!validateEmail(senderEmailOverride)) return 'Email must be a valid email address.';

        const senderEmailDomain = senderEmailOverride.substring(senderEmailOverride.lastIndexOf('@'));
        if (senderEmailDomain !== '@digitalpharmacist.com')
            return `Email must be a '@digitalpharmacist.com' email address.`;

        const senderEmailWithoutDomain = senderEmailOverride.substring(0, senderEmailOverride.lastIndexOf('@'));
        if (senderEmailWithoutDomain.length > 64) return 'Email is too long.';

        // These forbidden characters are specific to MailUp client, we want to match the backend validation
        const forbiddenChars = ['&', '..', '`', '=', '>', '<', ',', '+'];
        const foundForbiddenChar = forbiddenChars.find((forbChar) => senderEmailWithoutDomain.includes(forbChar));
        if (foundForbiddenChar) return `Email must not contain the '${foundForbiddenChar}' symbol.`;

        return '';
    };

    const getUrlErrorMessage = (url) => {
        if (url.length && !validateUrl(url)) return 'Must be a valid URL.';

        return '';
    };

    const isFormValid = () => {
        return (
            getSenderNameErrorMessage() === '' &&
            getSenderEmailErrorMessage() === '' &&
            getUrlErrorMessage(pharmacyTwitterURL) === '' &&
            getUrlErrorMessage(pharmacyFacebookURL) === '' &&
            getUrlErrorMessage(pharmacyInstagramURL) === ''
        );
    };

    const commonProps = {
        variant: 'outlined',
        autoComplete: 'off',
        onChange: onChange,
        fullWidth: true,
        disabled: !canUpdate,
    };

    return (
        <Card variant="outlined" className="bordered-box settings-content--card">
            <div className="grid-container newsletter-mobile-grid">
                <Grid container item md={6} xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="senderName"
                            label="From Name *"
                            value={senderName}
                            error={getSenderNameErrorMessage() !== ''}
                            helperText={getSenderNameErrorMessage()}
                            {...commonProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="senderEmailOverride"
                            label="From Email *"
                            value={senderEmailOverride}
                            error={getSenderEmailErrorMessage() !== ''}
                            helperText={getSenderEmailErrorMessage()}
                            {...commonProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="pharmacyTwitterURL"
                            label="Pharmacy's Twitter URL"
                            value={pharmacyTwitterURL}
                            error={getUrlErrorMessage(pharmacyTwitterURL) !== ''}
                            helperText={getUrlErrorMessage(pharmacyTwitterURL)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TwitterIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                            {...commonProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="pharmacyFacebookURL"
                            label="Pharmacy's Facebook URL"
                            value={pharmacyFacebookURL}
                            error={getUrlErrorMessage(pharmacyFacebookURL) !== ''}
                            helperText={getUrlErrorMessage(pharmacyFacebookURL)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FacebookIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                            {...commonProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="pharmacyInstagramURL"
                            label="Pharmacy's Instagram URL"
                            value={pharmacyInstagramURL}
                            error={getUrlErrorMessage(pharmacyInstagramURL) !== ''}
                            helperText={getUrlErrorMessage(pharmacyInstagramURL)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <InstagramIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                            {...commonProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="position-relative">
                            <Button
                                id="save"
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={onSave}
                                disabled={isSaveDisabled() || isLoading || !canUpdate || !isFormValid()}
                                fullWidth
                            >
                                Save
                            </Button>
                            {isLoading && <CircularProgress size={24} className="circular-icon-within-button" />}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    config: newsletterSelectors.getNewsletterConfig(state),
    pharmacy: state.pharmacy.pharmacy,
    savingNewsletterSettings: state.newsletter.savingNewsletterSettings,
    pharmacyLoading: state.pharmacy.loading,
});

const mapDispatchToProps = {
    saveNewsletterSettings: newsletterActions.saveNewsletterSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
