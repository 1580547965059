import _ from 'lodash';
import Config from 'config';
import { get } from 'redux/actionCreators/rest';

import { postRequest, deleteRequest } from './Requests';

export const getAttributeByName = (attributeName) => {
    const url = `${Config.config2_url}/attrTypeId`;
    const args = {
        params: {
            name: attributeName,
        },
    };

    return get(url, args).then(({ result }) => {
        return _.get(result, '[0]', {});
    });
};

export const addLocationAttribute = (cust, data, toastMessages, dispatch) => {
    const url = `${Config.config2_url}/cust/${cust.id}/location/${cust.location.id}/attr`;
    return postRequest(url, data, toastMessages, dispatch);
};

export const deleteLocationAttribute = (cust, toastMessages, dispatch) => {
    const url = `${Config.config2_url}/cust/${cust.id}/location/${cust.location.id}/attr/${cust.location.attributeID}`;
    return deleteRequest(url, toastMessages, dispatch);
};
