import React, { Fragment, useState } from 'react';
import { Menu, MenuItem, Icon, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import _ from 'lodash';

export const ActionsMenu = (props) => {
    const [anchorElement, setAnchorElement] = useState(null);
    const onIconClick = (event) => setAnchorElement(event.currentTarget);
    const closeMenu = () => setAnchorElement(null);
    const { onMoreInfo, onEdit, onDelete } = props.onAction;
    const menuItems = [
        {
            icon: 'info',
            text: 'More Info',
            onClick: () => {
                closeMenu();
                onMoreInfo(props.app);
            },
        },
    ];

    // Non branded apps cannot be edited
    if (_.get(props, 'app.appType', '') === 'branded') {
        menuItems.push({
            icon: 'edit',
            text: 'Edit',
            onClick: () => {
                closeMenu();
                onEdit(props.app);
            },
        });
    }

    menuItems.push({
        icon: 'delete',
        text: 'Delete',
        className: 'menu-item--delete',
        onClick: () => {
            closeMenu();
            onDelete(props.app);
        },
    });

    return (
        <span className="flex-right  menu-items">
            {!_.isEmpty(menuItems) && (
                <Fragment>
                    <IconButton onClick={onIconClick} size="small">
                        <Icon>more_vert</Icon>
                    </IconButton>

                    <Menu
                        id="app-action-menu"
                        keepMounted
                        anchorEl={anchorElement}
                        open={Boolean(anchorElement)}
                        onClose={closeMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        getContentAnchorEl={null}
                    >
                        {menuItems.map((menuItem) => (
                            <MenuItem
                                key={menuItem.text}
                                onClick={menuItem.onClick}
                                className={menuItem.className}
                                disabled={menuItem.disabled}
                            >
                                <ListItemIcon>
                                    <Icon>{menuItem.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>{menuItem.text}</ListItemText>
                            </MenuItem>
                        ))}
                    </Menu>
                </Fragment>
            )}
        </span>
    );
};
