import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import _ from 'lodash';
import moment from 'moment';

function createNewReservationSession(pharmacyId, locationId, calendarId, reservationSession) {
    return restActionCreators
        .post(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/session`,
            reservationSession
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function updateReservationSession(pharmacyId, locationId, calendarId, sessionId, reservationSession) {
    return restActionCreators
        .put(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/session/${sessionId}`,
            reservationSession
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getAvailableAppointmentSlots(pharmacyId, locationId, calendarId, sessionId, start_date, end_date) {
    const params = [`start_date=${start_date}`, `end_date=${end_date}`];
    return restActionCreators
        .get(
            `${config.apiweb_url}${
                api.APPT_API_BASE
            }/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/session/${sessionId}/slots?${params.join(
                '&'
            )}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function chooseWaitlistPatientsByCount(pharmacyId, locationId, waitlistId, numberToSelect, preferExisting) {
    const params = _.filter([preferExisting ? 'prefer-existing=true' : null]);
    ///patients/<pharmacy_uuid>/<location_uuid>/pull-from-waitlist/<waitlist_id>/<num_patients>
    return restActionCreators.get(
        `${config.apiweb_url}${
            api.PATIENT_QUERY_BASE
        }/patients/${pharmacyId}/${locationId}/pull-from-waitlist/${waitlistId}/${numberToSelect}?${params.join('&')}`
    );
}

function createNewReservation(pharmacyId, locationId, calendarId, reservation) {
    return restActionCreators
        .post(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation`,
            reservation
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function updateReservation(pharmacyId, locationId, calendarId, reservationId, reservation) {
    return restActionCreators
        .put(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation/${reservationId}`,
            reservation
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function checkinReservation(pharmacyId, locationId, calendarId, reservationId, user_id) {
    return restActionCreators
        .put(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation/${reservationId}/check-in`,
            { user_id, user_type: 'pharmacist', check_in_date: moment.utc().format('YYYY-MM-DD HH:mm:ss') }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function uncheckinReservation(pharmacyId, locationId, calendarId, reservationId) {
    return restActionCreators
        .put(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation/${reservationId}/check-in`,
            { user_id: null, user_type: 'pharmacist', check_in_date: null }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getLiveReusableReservationSessions(pharmacyId, locationId) {
    return restActionCreators
        .get(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/live-reusable-sessions?include_slots=true`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getReservationsForInboxUserId(pharmacyId, locationId, inboxUserId) {
    return restActionCreators
        .get(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/reservations-for-user/${inboxUserId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const apptReservationService = {
    createNewReservationSession,
    updateReservationSession,
    getAvailableAppointmentSlots,
    chooseWaitlistPatientsByCount,
    createNewReservation,
    updateReservation,
    checkinReservation,
    uncheckinReservation,
    getLiveReusableReservationSessions,
    getReservationsForInboxUserId,
};
export default apptReservationService;
