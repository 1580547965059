/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'home/';

export const homeTypes = {
    RESET_HOME_STORE: `${typePrefix}RESET_HOME_STORE`,

    GET_REFILL_COUNT_REQUEST: `${typePrefix}GET_REFILL_COUNT_REQUEST`,
    GET_REFILL_COUNT_SUCCESS: `${typePrefix}GET_REFILL_COUNT_SUCCESS`,
    GET_REFILL_COUNT_FAILED: `${typePrefix}GET_REFILL_COUNT_FAILED`,

    GET_PATIENT_TRANSFERS_REQUEST: `${typePrefix}GET_PATIENT_TRANSFERS_REQUEST`,
    GET_PATIENT_TRANSFERS_SUCCESS: `${typePrefix}GET_PATIENT_TRANSFERS_SUCCESS`,
    GET_PATIENT_TRANSFERS_FAILED: `${typePrefix}GET_PATIENT_TRANSFERS_FAILED`,

    GET_MESSAGES_SENT_REQUEST: `${typePrefix}GET_MESSAGES_SENT_REQUEST`,
    GET_MESSAGES_SENT_SUCCESS: `${typePrefix}GET_MESSAGES_SENT_SUCCESS`,
    GET_MESSAGES_SENT_FAILED: `${typePrefix}GET_MESSAGES_SENT_FAILED`,

    GET_PATIENTS_WITH_BIRTHDAY_REQUEST: `${typePrefix}GET_PATIENTS_WITH_BIRTHDAY_REQUEST`,
    GET_PATIENTS_WITH_BIRTHDAY_SUCCESS: `${typePrefix}GET_PATIENTS_WITH_BIRTHDAY_SUCCESS`,
    GET_PATIENTS_WITH_BIRTHDAY_FAILED: `${typePrefix}GET_PATIENTS_WITH_BIRTHDAY_FAILED`,

    GET_UNREAD_VOICEMAILS_REQUEST: `${typePrefix}GET_UNREAD_VOICEMAILS_REQUEST`,
    GET_UNREAD_VOICEMAILS_SUCCESS: `${typePrefix}GET_UNREAD_VOICEMAILS_SUCCESS`,
    GET_UNREAD_VOICEMAILS_FAILED: `${typePrefix}GET_UNREAD_VOICEMAILS_FAILED`,

    GET_UNREAD_MESSAGES_REQUEST: `${typePrefix}GET_UNREAD_MESSAGES_REQUEST`,
    GET_UNREAD_MESSAGES_SUCCESS: `${typePrefix}GET_UNREAD_MESSAGES_SUCCESS`,
    GET_UNREAD_MESSAGES_FAILED: `${typePrefix}GET_UNREAD_MESSAGES_FAILED`,

    GET_INTERNAL_LEADS_REQUEST: `${typePrefix}GET_INTERNAL_LEADS_REQUEST`,
    GET_INTERNAL_LEAD_COUNT_SUCCESS: `${typePrefix}GET_INTERNAL_LEAD_COUNT_SUCCESS`,
    GET_INTERNAL_LEAD_COUNT_FAILED: `${typePrefix}GET_INTERNAL_LEAD_COUNT_FAILED`,

    GET_CALLRAIL_LEADS_REQUEST: `${typePrefix}GET_CALLRAIL_LEADS_REQUEST`,
    GET_CALLRAIL_LEAD_SUCCESS: `${typePrefix}GET_CALLRAIL_LEAD_SUCCESS`,
    GET_CALLRAIL_LEAD_FAILED: `${typePrefix}GET_CALLRAIL_LEAD_FAILED`,

    GET_LEADS_STATUS_REQUEST: `${typePrefix}GET_LEADS_STATUS_REQUEST`,
    GET_LEADS_STATUS_SUCCESS: `${typePrefix}GET_LEADS_STATUS_SUCCESS`,
    GET_LEADS_STATUS_FAILED: `${typePrefix}GET_LEADS_STATUS_FAILED`,

    SEND_QUICK_REPLY_REQUEST: `${typePrefix}SEND_QUICK_REPLY_REQUEST`,
    SEND_QUICK_REPLY_SUCCESS: `${typePrefix}SEND_QUICK_REPLY_SUCCESS`,
    SEND_QUICK_REPLY_FAILED: `${typePrefix}SEND_QUICK_REPLY_FAILED`,

    GET_CONVERSATIONS_REQUEST: `${typePrefix}GET_CONVERSATIONS_REQUEST`,
    GET_CONVERSATIONS_SUCCESS: `${typePrefix}GET_CONVERSATIONS_SUCCESS`,
    GET_CONVERSATIONS_FAILURE: `${typePrefix}GET_CONVERSATIONS_FAILURE`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
