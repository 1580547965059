import './AppointmentsConfig.sass';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Config2Constants from 'constants/Config2';

import Service from '../GeneralSettings/FeaturesAndFunctions/Service';
import WithCustPackageAttribute from '../GeneralSettings/FeaturesAndFunctions/CustPackageAttribute';
import { selectNumberOfActiveApptTypes } from 'components/Appointments/types/apptTypesUtil';
import { getLiveReusableReservationSessions } from 'redux/Appt/Reservation/action';
import _ from 'lodash';
import AppointmentTypesPage from 'components/Appointments/types/AppointmentTypesPage';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { Typography, Box, Button } from '@material-ui/core';
import config from 'config';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from '../Upgrades/FullPepUpgrade/FullPepUpgrade';

const title = 'Website Appointment Scheduling';
const description = 'Enable or disable public access to appointment scheduling on your managed website.';

const AppointmentsConfig = (props) => {
    const {
        displayToast,
        pharmacy,
        getLiveReusableReservationSessions,
        apptReservation,
        auth: { selectedLocation },
        fullPepEnabled,
    } = props;

    useEffect(() => {
        if (
            !_.get(apptReservation, 'liveReusableSessions.loading') &&
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response'))
        ) {
            getLiveReusableReservationSessions();
        }
    }, [apptReservation]);

    if (!fullPepEnabled) {
        return <FullPepUpgrade />;
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(waitlistFormDirectUrl);
        displayToast({
            text: 'Copied to clipboard',
            type: 'info',
        });
    };
    const { pharmacy: currentPharmacy } = pharmacy;
    const selectedPharmacyLocation = _.find(
        currentPharmacy.location,
        (location) => location.id === selectedLocation.id
    );
    const appointmentSchedulingEnabled = _.get(
        selectedPharmacyLocation,
        'service.Website.AppointmentSchedulingEnabled'
    );
    const waitlistFormDirectUrl = `${config.app_dp_url}/assessment-form/?configId=${currentPharmacy.id}`;

    return (
        <div className="appointments-config">
            <div className="service-heading">
                <Service title={title} description={description} {...props} />
            </div>

            {appointmentSchedulingEnabled && (
                <>
                    <div className="grid-container container-row" style={{ marginBottom: '10px' }}>
                        <Typography style={{ marginBottom: '8px', display: 'block' }}>
                            If you would like to share the URL for your scheduler, click copy:
                        </Typography>
                        <Box className="code-block">
                            {waitlistFormDirectUrl}
                            <Button
                                style={{ marginLeft: '35px' }}
                                variant="contained"
                                color="primary"
                                onClick={copyToClipboard}
                            >
                                COPY
                            </Button>
                        </Box>
                    </div>

                    <div className="additional-explanation">
                        Any appointment types that are configured to be available on web will be available on your
                        website
                    </div>

                    <AppointmentTypesPage />
                </>
            )}

            {!appointmentSchedulingEnabled && (
                <div className="not-enabled-content">
                    You must activate website appointment scheduling to view additional configuration options.
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    const { auth, apptReservation, pharmacy } = state;
    return {
        auth,
        apptReservation,
        pharmacy,
        activeAppointmentTypesCounts: selectNumberOfActiveApptTypes(state),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    };
}

const bindActionsToDispatch = {
    getLiveReusableReservationSessions,
    displayToast,
};

export default WithCustPackageAttribute(connect(mapStateToProps, bindActionsToDispatch)(AppointmentsConfig), {
    package: {
        name: 'Website',
        code: 'WEBS',
        attribute: {
            name: 'AppointmentSchedulingEnabled',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
