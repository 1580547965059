import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ClipboardClockIcon = (props) => {
    return (
        <SvgIcon>
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.5 15.25L16.36 16.94L15.61 18.16L12 16V11H13.5V15.25ZM20 15C20 18.87 16.87 22
                13 22C11.09 22 9.37 21.24 8.11 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714
                19.0391 0 18.5304 0 18V4C0 2.9 0.9 2 2 2H6.18C6.6 0.84 7.7 0 9 0C10.3 0 11.4 0.84 11.82
                2H16C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V10.11C19.24
                11.37 20 13.09 20 15ZM8 3C8 3.55 8.45 4 9 4C9.55 4 10 3.55 10 3C10 2.45 9.55 2 9 2C8.45 2
                8 2.45 8 3ZM18 15C18 12.24 15.76 10 13 10C10.24 10 8 12.24 8 15C8 17.76 10.24 20 13 20C15.76
                20 18 17.76 18 15Z"
                    fill="#00B18F"
                />
            </svg>
        </SvgIcon>
    );
};

export default ClipboardClockIcon;
