import React, { Component } from 'react';
import ForumIcon from '@material-ui/icons/Forum';

class ConversationListEmptyState extends Component {
    render() {
        const { contact } = this.props;

        return (
            <div className="contact-conversation-list__empty">
                <ForumIcon className="contact-conversation-list__empty__icon" />
                <br />
                You don&apos;t have any conversations with
                <br />
                <b>
                    {contact.first_name} {contact.last_name}
                </b>
            </div>
        );
    }
}

export default ConversationListEmptyState;
