import './MessageStatusIcon.sass';
import React from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import classnames from 'classnames';
import { Tooltip, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { canMessagePatient } from './patientsUtil';

export default function MessageStatusIcon(props) {
    const { phone_type } = props.patient;
    const sent_messages_unread_count = _.get(props, 'patient.sent_messages_unread_count', 0);
    const received_messages_unread_count = _.get(props, 'patient.received_messages_unread_count', 0);

    const classes = {
        'message-status-icon': true,
        'has-unread-messages': sent_messages_unread_count > 0 || received_messages_unread_count > 0,
    };

    let tooltipTitle;
    if (sent_messages_unread_count > 0) {
        tooltipTitle = 'Patient sent a message that has not been read by pharmacy.';
    } else if (received_messages_unread_count > 0) {
        tooltipTitle = 'Pharmacy sent a message that has not been read by patient.';
    } else {
        tooltipTitle = 'No unread messages.';
    }

    const onClick = (e) => {
        e.stopPropagation();
        props.onMessagePatient(props.patient);
    };

    if (_.isNil(phone_type)) {
        return (
            <Tooltip
                title={
                    'This patient phone number has not yet been checked to ensure it is a cell phone that can receive messages. This check will occur prior to sending the first message to this patient.'
                }
            >
                <span>
                    <IconButton
                        disabled
                        className="message-status-icon unknown"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => {}}
                    >
                        <DeviceUnknownIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    if (!canMessagePatient({ phone_type })) {
        return (
            <Tooltip title={'Only patients with a valid mobile phone number may be sent messages.'}>
                <span>
                    <IconButton
                        disabled
                        className="message-status-icon disabled"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => {}}
                    >
                        <MobileOffIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={tooltipTitle}>
            <IconButton className={classnames(classes)} onMouseDown={(e) => e.stopPropagation()} onClick={onClick}>
                <ChatIcon />
                {_.isNil(sent_messages_unread_count) || sent_messages_unread_count === 0 ? null : (
                    <span className="alert-circle" />
                )}
            </IconButton>
        </Tooltip>
    );
}
