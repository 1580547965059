import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Snowplow, { StructEventCategories } from 'snowplow';

import SpeechRecognitionButton from 'components/Common/SpeechRecognitionButton';

const SearchInput = ({ onSearch, placeholder, noResult, searchTerm = '', isPharmacyChanged, debounceTime = 300 }) => {
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        const searchboxValue = document.getElementById('search-box').value; // cant reliably rely on incoming state

        if (isPharmacyChanged && searchboxValue) {
            setSearchKeyword('');
        }

        searchTerm.length && setSearchKeyword(searchTerm);
    }, [searchTerm, isPharmacyChanged]);

    const handleChange = useCallback(
        _.debounce((event) => {
            onSearch(event.target.value);
        }, debounceTime),
        []
    );

    const onChange = (event) => {
        event.persist();
        setSearchKeyword(event.target.value);
        handleChange(event);
    };

    const trackTextSearch = (event) => {
        if (event.target.value) {
            Snowplow.structEvent(StructEventCategories.newsletters, 'subscribers-search-text');
        }
    };

    const searchByRecordedText = (text) => {
        setSearchKeyword(text);
        onSearch(text);
        Snowplow.structEvent(StructEventCategories.newsletters, 'subscribers-search-voice');
    };

    return (
        <TextField
            id="search-box"
            placeholder={placeholder || ''}
            type="search"
            value={searchKeyword}
            autoComplete="off"
            margin="none"
            variant="outlined"
            fullWidth
            onChange={onChange}
            onBlur={trackTextSearch}
            isPharmacyChanged={isPharmacyChanged}
            style={{ marginTop: noResult ? '14px' : '0' }}
            error={noResult}
            helperText={noResult ? 'No results found. Please try your search again.' : ''}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <SpeechRecognitionButton sendRecordedText={searchByRecordedText} />
                    </InputAdornment>
                ),
                classes: {
                    root: 'refill-search-box__input',
                    focused: 'refill-search-box__input--focused',
                },
            }}
        />
    );
};

export default SearchInput;
