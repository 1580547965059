import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { userAction } from 'redux/Inbox/User';
import { homeSelectors } from 'redux/Home';
import { Title } from './Title';
import QuickReplyInput from './QuickReplyInput';

const ActivitiesLoading = () => (
    <Fragment>
        <div className="inbox-message-widget centered-content">
            <CircularProgress size={20} />
        </div>
        <div className="inbox-message-widget centered-content">
            <CircularProgress size={20} />
        </div>
    </Fragment>
);

class InboxMessage extends Component {
    componentDidMount = () => {
        this.props.getUsers(false);
    };

    goToInbox = () => {
        // TODO: take user to specific conversation in the inbox
        this.props.history.push('/inbox');
    };

    render() {
        const { unreadMessagesLoading } = this.props;

        const userActivities = _.filter(_.orderBy(this.props.userActivities, ['activity_date'], ['desc']), (activity) =>
            _.isString(activity.latest_message.content)
        );

        const topUserActivities = _.slice(_.values(userActivities), 0, 2);

        return (
            <div className="home-page__left_container">
                <Typography className="activity-title">Activity</Typography>
                <Fragment>
                    {unreadMessagesLoading ? (
                        <ActivitiesLoading />
                    ) : (
                        <Fragment>
                            {_.map(topUserActivities, (activity) => (
                                <div className="inbox-message-widget">
                                    <Title
                                        name={`${activity.first_name} ${activity.last_name}`}
                                        onArrowClick={this.goToInbox}
                                    />
                                    <p className="inbox-message-widget__body">{activity.latest_message.content}</p>
                                    <QuickReplyInput contact={activity} />
                                </div>
                            ))}
                        </Fragment>
                    )}
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    unreadMessagesLoading: state.home.checklist.messages.isLoading,
    userActivities: homeSelectors.getInboxActivity(state),
});

const bindActionsToDispatch = {
    getUsers: userAction.getUsers,
};

export const MessageWidget = withRouter(connect(mapStateToProps, bindActionsToDispatch)(InboxMessage));
