export default {
    FETCH_READ_VOICEMAIL_STARTED: 'FETCH_READ_VOICEMAIL_STARTED',
    FETCH_READ_VOICEMAIL_SUCCESS: 'FETCH_READ_VOICEMAIL_SUCCESS',
    FETCH_READ_VOICEMAIL_FAILED: 'FETCH_READ_VOICEMAIL_FAILED',
    FETCH_UNREAD_VOICEMAIL_STARTED: 'FETCH_UNREAD_VOICEMAIL_STARTED',
    FETCH_UNREAD_VOICEMAIL_SUCCESS: 'FETCH_UNREAD_VOICEMAIL_SUCCESS',
    FETCH_UNREAD_VOICEMAIL_FAILED: 'FETCH_UNREAD_VOICEMAIL_FAILED',
    FETCH_VOICEMAIL_AUDIO_STARTED: 'FETCH_VOICEMAIL_AUDIO_STARTED',
    FETCH_VOICEMAIL_AUDIO_SUCCESS: 'FETCH_VOICEMAIL_AUDIO_SUCCESS',
    FETCH_VOICEMAIL_AUDIO_FAILED: 'FETCH_VOICEMAIL_AUDIO_FAILED',
    READ_VOICEMAIL_STARTED: 'READ_VOICEMAIL_STARTED',
    READ_VOICEMAIL_SUCCESS: 'READ_VOICEMAIL_SUCCESS',
    READ_VOICEMAIL_FAILED: 'READ_VOICEMAIL_FAILED',
    UNREAD_VOICEMAIL_STARTED: 'UNREAD_VOICEMAIL_STARTED',
    UNREAD_VOICEMAIL_SUCCESS: 'UNREAD_VOICEMAIL_SUCCESS',
    UNREAD_VOICEMAIL_FAILED: 'UNREAD_VOICEMAIL_FAILED',
    FETCH_VOICEMAIL_DETAIL_STARTED: 'FETCH_VOICEMAIL_DETAIL_STARTED',
    FETCH_VOICEMAIL_DETAIL_SUCCESS: 'FETCH_VOICEMAIL_DETAIL_SUCCESS',
    FETCH_VOICEMAIL_DETAIL_FAILED: 'FETCH_VOICEMAIL_DETAIL_FAILED',
    SORT_VOICEMAIL: 'SORT_VOICEMAIL',
};
