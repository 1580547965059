import './OneOffAppointmentContent.sass';
import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import CalendarSelect from '../CalendarSelect';
import AppointmentLengthSelect from '../AppointmentLengthSelect';
import _ from 'lodash';

export default function OneOffAppointmentContent({ setIsOneOffType, calendars, onChange }) {
    const [name, setName] = useState('');
    const [calendarId, setCalendarId] = useState(null);
    const [lengthMinutes, setLengthMinutes] = useState(null);

    return (
        <div className="one-off-appointment-content">
            <div className="heading">
                <div className="title">Configure One-off Appointment</div>
                <div className="manual-create">
                    or{' '}
                    <Button className="one-off-button" onClick={() => setIsOneOffType(false)}>
                        choose an existing appointment type
                    </Button>
                </div>
            </div>

            <div className="custom-appt-form">
                <div className="sub-title">Please specify the following information for this one-off appointment</div>

                <div className="form-row">
                    <span className="label">Name</span>
                    <TextField
                        data-cy="one-off-appt-name"
                        className="appointment-name"
                        label="Appointment Name"
                        inputProps={{ maxLength: 100 }}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="form-row">
                    <span className="label">Calendar</span>
                    <CalendarSelect
                        calendars={calendars}
                        value={calendarId}
                        onChange={(e) => setCalendarId(e.target.value)}
                    />
                </div>

                <div className="form-row">
                    <span className="label">Length</span>
                    <AppointmentLengthSelect value={lengthMinutes} onChange={(e) => setLengthMinutes(e.target.value)} />
                </div>

                <div className="form-row">
                    <span className="label" />
                    <Button
                        data-cy="one-off-use-button"
                        className="use-button"
                        variant="contained"
                        color="primary"
                        disabled={_.isEmpty(name) || _.isNil(calendarId) || !_.isFinite(lengthMinutes)}
                        onClick={() => {
                            onChange({
                                appointment_label: _.trim(name),
                                appointment_calendar_id: calendarId,
                                appointment_length_minutes: lengthMinutes,
                                calendar: _.find(calendars, ({ calendar_id }) => calendar_id === calendarId),
                                image_ref: 'one-off-appt.v2.jpg',
                            });
                        }}
                    >
                        Use these one-off appointment details
                    </Button>
                </div>
            </div>
        </div>
    );
}
