import React, { Component } from 'react';
import { Grid, Cell, Button, CircularProgress } from 'react-md';
import 'url-search-params-polyfill';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { updateErr, changePassword } from '../redux/actionCreators/Auth';
import ChangePasswordInput from './ChangePasswordInput';
import { getPasswordErrMsg } from '../utils/password';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.validRule = {};
        this.newpassword = '';
        this.state = {
            pwdFormErrMsg: '',
        };
    }

    handlePwdChange = (validRule, newpassword) => {
        const { updateErr, errors } = this.props;
        if (errors) {
            updateErr('');
        }
        this.validRule = validRule;
        this.newpassword = newpassword;
        this.setState({
            pwdFormErrMsg: '',
        });
    };

    componentDidMount() {
        const { location, history } = this.props;
        const params = new URLSearchParams(location.search);
        if (!params.get('token')) {
            history.push('/');
        }
    }

    saveNewPassword = (e) => {
        e.preventDefault();
        const { location, changePassword, history } = this.props;
        const pwdFormErrMsg = getPasswordErrMsg(this.newpassword, this.validRule);

        if (pwdFormErrMsg) {
            this.setState({
                pwdFormErrMsg,
            });
        } else {
            const params = new URLSearchParams(location.search);
            changePassword({ user_type: 'pharmacist', token: params.get('token'), password: this.newpassword }).then(
                (resp) => {
                    if ('data' in resp) {
                        history.push('/');
                    }
                }
            );
        }
    };

    getErrMsg = (errors) => {
        let errMSg = '';

        if (errors) {
            if (errors.response && [422, 403].indexOf(errors.response.status) > -1) {
                errMSg = 'Invalid username or password entered, please try again';
            } else if (!errors.success) {
                errMSg = 'Failed to reset password. Please try again';
            } else {
                errMSg = 'System currently unavailable. Please try again';
            }
        }
        return errMSg;
    };

    render() {
        const { pwdFormErrMsg } = this.state;
        const { updatePwdInprogress, errors, location } = this.props;
        const errMsg = pwdFormErrMsg ? pwdFormErrMsg : this.getErrMsg(errors);

        return (
            <Grid>
                <Cell size={12}>
                    <div className="login-form-container centered-content">
                        {updatePwdInprogress && (
                            <div className="full-width progress-container">
                                <CircularProgress id="reset-password-loader" scale={2} />
                            </div>
                        )}
                        <div className="form-container">
                            <img style={{ marginBottom: '24px' }} src="/digital-pharmacist-larger.png" alt="" />
                            <div className="header-text" style={{ marginBottom: '40px' }}>
                                {location.pathname.indexOf('invite') < 0
                                    ? 'Reset your password below.'
                                    : 'Welcome to your new pharmacy experience. For security, we require you to reset your password.'}
                            </div>

                            <form className="login-form" noValidate autoComplete="off" onSubmit={this.saveNewPassword}>
                                <ChangePasswordInput
                                    errMsg={errMsg}
                                    handlePwdChange={(validRule, newpassword) =>
                                        this.handlePwdChange(validRule, newpassword)
                                    }
                                />

                                <div className="button-control resest-pwd-control" style={{ marginTop: '34px' }}>
                                    <NavLink to="/">Back to login</NavLink>
                                    <Button type="submit" raised primary>
                                        Change Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    updatePwdInprogress: state.auth.updatePwdInprogress,
    errors: state.auth.errors,
});

const mapDispatchToProps = (dispatch) => ({
    changePassword: (data) => dispatch(changePassword(data)),
    updateErr: (data) => dispatch(updateErr(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
