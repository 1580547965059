import apptCalendarDataTypes from './type';
import apptCalendarService from './service';
import config from '../../../config';
import _ from 'lodash';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentCalendarContext } from 'snowplow/contexts';

export function fetchAvailableAppointmentCalendars() {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (state.loadingAvailableCalendars || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.EXISTING_CALENDARS_REQUEST,
            payload: {},
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.getAvailableAppointmentCalendars(pharmacyId, locationId);
            dispatch({
                type: apptCalendarDataTypes.EXISTING_CALENDARS_SUCCESS,
                payload: { response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.EXISTING_CALENDARS_FAILURE,
                payload: { error },
            });
            return Promise.reject(error);
        }
    };
}

export function createNewCalendar(baseCalendar) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (state.creatingNewCalendar || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.CREATE_CALENDAR_REQUEST,
            payload: { baseCalendar },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.createNewCalendar(pharmacyId, locationId, baseCalendar);
            dispatch({
                type: apptCalendarDataTypes.CREATE_CALENDAR_SUCCESS,
                payload: { baseCalendar, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'create-new-calendar', [
                new AppointmentCalendarContext().setName(response.name).setCalendarId(response.calendar_id).build(),
            ]);

            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.CREATE_CALENDAR_FAILURE,
                payload: { baseCalendar, error },
            });
            return Promise.reject(error);
        }
    };
}

export function deleteCalendar(calendarId) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (state.deletingCalendarId || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.DELETE_CALENDAR_REQUEST,
            payload: { calendarId },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.deleteCalendar(pharmacyId, locationId, calendarId);
            dispatch({
                type: apptCalendarDataTypes.DELETE_CALENDAR_SUCCESS,
                payload: { calendarId, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'delete-calendar', [
                new AppointmentCalendarContext().setCalendarId(response.calendar_id).build(),
            ]);

            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.DELETE_CALENDAR_FAILURE,
                payload: { calendarId, error },
            });

            return Promise.reject(error);
        }
    };
}

export function updateCalendar(calendarId, baseCalendar, existingBaseCalendar) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (!_.isNil(_.get(state, 'updatingCalendarId')) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.UPDATE_CALENDAR_REQUEST,
            payload: { calendarId, baseCalendar },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.updateCalendar(pharmacyId, locationId, calendarId, baseCalendar);
            dispatch({
                type: apptCalendarDataTypes.UPDATE_CALENDAR_SUCCESS,
                payload: { calendarId, baseCalendar, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'calendar-updated', [
                new AppointmentCalendarContext().setName(response.name).setCalendarId(response.calendar_id).build(),
            ]);

            if (existingBaseCalendar && _.size(baseCalendar.vacations) > _.size(existingBaseCalendar.vacations)) {
                Snowplow.structEvent(StructEventCategories.appointments, 'vacation-added', [
                    new AppointmentCalendarContext().setName(response.name).setCalendarId(response.calendar_id).build(),
                ]);
            }

            const REMINDER_KEYS = ['day_reminder_text', 'hour_reminder_text', 'checkin_response_text'];
            if (!_.isEqual(_.pick(baseCalendar, REMINDER_KEYS), _.pick(existingBaseCalendar, REMINDER_KEYS))) {
                Snowplow.structEvent(StructEventCategories.appointments, 'reminders-template-updated', [
                    new AppointmentCalendarContext().setName(response.name).setCalendarId(response.calendar_id).build(),
                ]);
            }

            return response;
        } catch (error) {
            console.error(error);
            dispatch({
                type: apptCalendarDataTypes.UPDATE_CALENDAR_FAILURE,
                payload: { calendarId, baseCalendar, error },
            });

            return Promise.reject(error);
        }
    };
}

export function getAvailableCalendarAppointmentSlots(
    calendarId,
    appointmentLengthMinutes,
    startDate,
    startTime,
    endDate,
    endTime
) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (_.get(state, 'availableCalendarSlots.loading') || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_REQUEST,
            payload: { calendarId, appointmentLengthMinutes, startTime, endTime },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.getAvailableCalendarAppointmentSlots(
                pharmacyId,
                locationId,
                calendarId,
                startDate,
                startTime,
                endDate,
                endTime,
                appointmentLengthMinutes
            );
            dispatch({
                type: apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_SUCCESS,
                payload: { calendarId, appointmentLengthMinutes, startTime, endTime, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_FAILURE,
                payload: { calendarId, appointmentLengthMinutes, startTime, endTime, error },
            });

            return Promise.reject(error);
        }
    };
}

export function clearAvailableAppointmentSlots() {
    return {
        type: apptCalendarDataTypes.CLEAR_AVAILABLE_APPOINTMENT_SLOTS,
        payload: {},
    };
}

export function getCalendarReservations(calendarId, startDate, startTime, endDate, endTime) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (_.get(state, `calendarReservations.${calendarId}.loading`) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_REQUEST,
            payload: { calendarId, startDate, startTime, endDate, endTime },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.getCalendarReservations(
                pharmacyId,
                locationId,
                calendarId,
                startDate,
                startTime,
                endDate,
                endTime
            );

            if (response.reservations && response.reservations.length) {
                // Fetching all the metadata for the reservations
                const sessionInfoResponses = await Promise.allSettled(
                    _.map(response.reservations, (r) => {
                        if (r.session_id) {
                            return apptCalendarService.getSessionInfo(pharmacyId, locationId, r.session_id);
                        }

                        return Promise.resolve();
                    })
                );

                response.reservations.forEach((reservation, index) => {
                    if (reservation.session_id)
                        reservation.metadata = _.get(sessionInfoResponses[index], 'value.metadata');
                });
            }

            dispatch({
                type: apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_SUCCESS,
                payload: { calendarId, startDate, startTime, endDate, endTime, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_FAILURE,
                payload: { calendarId, startDate, startTime, endDate, endTime, error },
            });

            return Promise.reject(error);
        }
    };
}

export function clearCalendarReservations() {
    return {
        type: apptCalendarDataTypes.CLEAR_CALENDAR_RESERVATIONS,
        payload: {},
    };
}

export function clearCalendarAppointmentCounts() {
    return {
        type: apptCalendarDataTypes.CLEAR_CALENDAR_APPOINTMENT_COUNTS,
        payload: {},
    };
}

export function resetCalendars() {
    return {
        type: apptCalendarDataTypes.RESET_CALENDARS,
        payload: {},
    };
}

export function updateCalendarReservationsFromResponse(response) {
    return {
        type: apptCalendarDataTypes.UPDATE_CALENDAR_RESERVATIONS_FROM_RESPONSE,
        payload: { response },
    };
}

export function getCalendarAppointmentCounts(calendarIds, startDate, endDate, label = null) {
    return async (dispatch, getState) => {
        const state = getState().apptCalendar;

        if (_.get(state, `appointmentCounts.loading`) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_REQUEST,
            payload: { calendarIds, startDate, endDate, label },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptCalendarService.getCalendarAppointmentCounts(
                pharmacyId,
                locationId,
                calendarIds,
                startDate,
                endDate,
                label
            );
            dispatch({
                type: apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_SUCCESS,
                payload: { calendarIds, startDate, endDate, label, response },
            });

            return response;
        } catch (error) {
            dispatch({
                type: apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_FAILURE,
                payload: { calendarIds, startDate, endDate, label, error },
            });

            return Promise.reject(error);
        }
    };
}
