import React, { Fragment } from 'react';
import { Cell, CircularProgress, FontIcon } from 'react-md';
import { Button, Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

import { phoneLineOptions, refillPhoneLineOptions } from 'constants/PhoneLine';

const saveMenuSetting = (menuItem, props) => {
    const { phoneMenus, displayToast, phoneMenuEdit } = props;
    const newMenuItemVal = phoneMenus[menuItem] ? 0 : 1;
    const refillMenus = _.map(refillPhoneLineOptions, (option) => option.value);
    const editingMenuIdx = refillMenus.indexOf(menuItem);
    if (newMenuItemVal === 0 && editingMenuIdx >= 0) {
        refillMenus.splice(editingMenuIdx, 1);
        let menuCnt = 0;
        refillMenus.forEach((item) => {
            if (phoneMenus[item] === 0 || phoneMenuEdit[item]) {
                menuCnt++;
            }
        });

        if (menuCnt === refillMenus.length) {
            displayToast({ text: "Can't disable Refill -pickup/delivery/mail", type: 'error' });
            return false;
        }
    }
    props.onSave(menuItem, newMenuItemVal);
};

export const IvrMenuList = (props) => {
    const { phoneLines, phoneMenus, phoneMenuEdit, hasHuntGroups, history, displayToast, hideActions } = props;

    const activeOrHiddenLabel = (key) => (phoneMenus[key] ? 'Active' : 'Deactivated');
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

    return (
        <span style={{ marginTop: '33px' }}>
            <div className="grid-container table-title">
                <Cell size={3} tabletSize={1} phoneSize={1} className="flex-bottom">
                    Keypad Entry
                </Cell>
                <Cell
                    size={!hideActions ? 4 : 5}
                    tabletSize={!hideActions ? 3 : 4}
                    phoneSize={!hideActions ? 1 : 2}
                    className="flex-bottom"
                >
                    Menu Option
                </Cell>
                <Cell size={!hideActions ? 3 : 4} tabletSize={3} phoneSize={1} className="flex-bottom">
                    Status
                </Cell>
                {!hideActions && (
                    <Cell size={2} tabletSize={1} phoneSize={1} className="flex-bottom">
                        &nbsp;
                    </Cell>
                )}
            </div>
            <div className="bordered-table ivr-menu-items">
                {Object.keys(phoneMenus).length &&
                    phoneLines.map((item, idx) => (
                        <div key={idx} className="grid-container table-data bordered-table-item">
                            <Cell size={3} tabletSize={1} phoneSize={1} className="flex-middle">
                                {' '}
                                {item.num}{' '}
                            </Cell>
                            <Cell
                                size={!hideActions ? 4 : 5}
                                tabletSize={!hideActions ? 3 : 4}
                                phoneSize={!hideActions ? 1 : 2}
                                className="flex-middle"
                            >
                                {phoneLineOptions[item.value]}
                            </Cell>
                            <Cell size={!hideActions ? 3 : 4} tabletSize={3} phoneSize={1} className="flex-middle">
                                {activeOrHiddenLabel(item.value)}
                            </Cell>
                            <Cell size={2} tabletSize={1} phoneSize={1} className="flex-middle flex-right">
                                {!hideActions &&
                                    (phoneMenuEdit[item.value] ? (
                                        <CircularProgress id="ivr-menu-update-spinner" />
                                    ) : item.value === 'transfer' && !hasHuntGroups ? (
                                        <ConfigureHuntGroup displayToast={displayToast} history={history} />
                                    ) : !isMobile ? (
                                        <Button
                                            variant="outlined"
                                            color={phoneMenus[item.value] ? 'secondary' : 'primary'}
                                            id={`save-menu-setting-${item.value}`}
                                            onClick={() => saveMenuSetting(item.value, props)}
                                        >
                                            {phoneMenus[item.value] ? 'Deactivate' : 'Activate'}
                                        </Button>
                                    ) : (
                                        <Switch
                                            checked={!!phoneMenus[item.value]}
                                            onChange={() => saveMenuSetting(item.value, props)}
                                            color="primary"
                                            name="switch-menu"
                                        />
                                    ))}
                            </Cell>
                        </div>
                    ))}
                {phoneLines.length === 0 && 'No records found'}
            </div>
        </span>
    );
};

const ConfigureHuntGroup = ({ displayToast, history }) => {
    const navigate = () => {
        displayToast({ text: 'Not configured', type: 'error' });
        history.push({ pathname: '/settings/phone-system/phone-transfer', state: history.location.state });
    };

    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Fragment>
            {isMobile ? (
                <PhonelinkSetupIcon color="primary" onClick={navigate} />
            ) : (
                <Button variant="outlined" color="primary" onClick={navigate}>
                    Configure
                </Button>
            )}
            <Tooltip
                placement="top"
                classes={{ tooltip: 'info-tooltip' }}
                enterTouchDelay={0}
                title="In order to enable this feature, phone numbers need to be entered on the Phone Call
      Transfer page. If no numbers are entered, this feature will not be read by the IVR."
            >
                <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                    info
                </FontIcon>
            </Tooltip>
        </Fragment>
    );
};

export default IvrMenuList;
