import Actions from 'redux/actions';
import uuid4 from 'uuid/v4';

export default function Snackbar(state = { message: '', type: '' }, { type, data }) {
    switch (type) {
        case Actions.SHOW_TOAST:
            return { key: uuid4(), message: data.text, type: data.type };
        default:
            return state;
    }
}
