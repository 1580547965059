import React from 'react';
import SliderForROIMetrics from './SliderForROIMetrics';

function SlidingROIValues(props) {
    return (
        <div className="ROICalculator__SlidingROIValues">
            <div className="ROICalculator__SlidingROIValues--title">Step 2: Adjust Metrics</div>

            <div className="ROICalculator__SlidingROIValues--sliderSection">
                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.avgRevenuePerRXValue}
                    sliderTitle={'Average Revenue / RX'}
                    sliderId="avgRevenuePerRXValue"
                    min={2}
                    max={200}
                    units={'$'}
                />

                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.avgProfitPerRXValue}
                    sliderTitle={'Average Gross Profit / RX'}
                    sliderId="avgProfitPerRXValue"
                    min={2}
                    max={100}
                    units={'$'}
                />

                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.annualAvgRevenuePerPatientValue}
                    sliderTitle={'Annual Avg Revenue of a Patient'}
                    sliderId="annualAvgRevenuePerPatientValue"
                    min={20}
                    max={10000}
                    units={'$'}
                />

                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.employeeWagePerHourValue}
                    sliderTitle={'Employee Wage Per Hour'}
                    sliderId="employeeWagePerHourValue"
                    min={8}
                    max={40}
                    units={'$'}
                />

                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.minutesSavedFromAutomationValue}
                    sliderTitle={'Minutes Saved From Automation'}
                    sliderId="minutesSavedFromAutomationValue"
                    min={1}
                    max={30}
                    units={'mins'}
                />

                <SliderForROIMetrics
                    valueChange={props.valueChange}
                    value={props.hoursPerMonthWorkValue}
                    sliderTitle={'Hours/Month DP Works for You'}
                    sliderId="hoursPerMonthWorkValue"
                    min={0}
                    max={40}
                    units={'hrs'}
                />
            </div>
        </div>
    );
}

export default SlidingROIValues;
