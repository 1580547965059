const subjectTypePrefix = 'Settings Popover';

export const restrictedSettingsPopoverResources = {
    settingsPopover: {
        subject: subjectTypePrefix,
        fields: {
            appcode: 'App Code',
            settings: 'Settings',
        },
    },
};
