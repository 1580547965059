import React from 'react';
import { Button, Tooltipped } from 'react-md';

function ComputedROIValues(props) {
    // largely taken from https://css-tricks.com/snippets/javascript/format-currency/
    const formatCurrency = (amount) => {
        var i = parseFloat(amount);
        if (isNaN(i)) {
            i = 0.0;
        }
        var minus = '';
        if (i < 0) {
            minus = '-';
        }
        i = Math.abs(i);
        i = parseInt((i + 0.005) * 100);
        i = i / 100;
        let s = '';

        // case where number is above 10 billion
        if (i > 10000000000) {
            s += '10B+';
        }
        // case where number is in the billions
        else if (i >= 1000000000) {
            i = i / 1000000000;
            s = Number(i).toLocaleString('en') + s;
            if (s.indexOf('.') < 0) {
                s += '.00';
            }
            s += 'B+';
        }
        // case where number is in the millions
        else if (i >= 1000000) {
            i = i / 1000000;
            s = Number(i).toLocaleString('en') + s;
            if (s.indexOf('.') < 0) {
                s += '.00';
            }
            s += 'M+';
        } else {
            s = Number(i).toLocaleString('en') + s;
            if (s.indexOf('.') < 0) {
                s += '.00';
            }
            if (s.indexOf('.') === s.length - 2) {
                s += '0';
            }
        }
        s = minus + s;
        return '$' + s;
    };

    return (
        <div className="ROICalculator__ComputedROIValues">
            <div className="ROICalculator__ComputedROIValues--title">
                <div className="ROICalculator__ComputedROIValues--titleText">
                    <span>Step 3: View Value</span>
                </div>
            </div>
            {/* This is repetitive and should probably be refactored into a 
                loop that maps each value to a new component that takes the value,
                metric, and toolTipLabel as props...
            */}
            <div className="ROICalculator__ComputedROIValues--dataSection">
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <div className="ROICalculator__ComputedROIValues--value">
                        {formatCurrency(props.scriptsProcessedValue)}
                    </div>
                    <div className="ROICalculator__ComputedROIValues--metric">
                        {/* Tooltipped here to accomodate the wordpress theme  */}
                        <Tooltipped
                            label={
                                <div>
                                    <div>
                                        Avg revenue per Rx <br />* number of prescription refills
                                    </div>
                                </div>
                            }
                            setPosition
                        >
                            <Button flat> Value of RX Processed </Button>
                        </Tooltipped>
                    </div>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatCurrency(props.newPatientsValue)}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        {/* Tooltipped here to accomodate the wordpress theme  */}
                        <Tooltipped
                            label={
                                <div>
                                    <div>
                                        Annual average revenue of a patient <br />* number of transfers
                                    </div>
                                </div>
                            }
                            setPosition
                        >
                            <Button flat> Value of New Patients </Button>
                        </Tooltipped>
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatCurrency(props.scriptsProcessedGrossProfit)}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        {/* Tooltipped here to accomodate the wordpress theme  */}
                        <Tooltipped
                            label={
                                <div>
                                    <div>
                                        Average gross profit per prescription <br />* number of prescription refills
                                    </div>
                                </div>
                            }
                            setPosition
                        >
                            <Button flat> Gross Profit of RX Processed </Button>
                        </Tooltipped>
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatCurrency(props.automationValue)}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        {/* Multiline tooltip can't be done with normal buttons in react-md... tried <br/> \n and &#013 */}
                        <Tooltipped
                            label={
                                <div>
                                    <div>
                                        (number of prescription refills + IVR refills <br />+ transfers + outbound
                                        alerts)
                                        <br />
                                        * minutes saved with automation <br />* (employee wage per hour/60)
                                    </div>
                                </div>
                            }
                            setPosition
                        >
                            <Button flat> Value of Automation</Button>
                        </Tooltipped>
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatCurrency(props.supportValue)}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        <Button flat tooltipLabel="25 * (Hours/month DP works for you)">
                            Value of Support
                        </Button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ComputedROIValues;
