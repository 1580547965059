import { socketType } from './type';
import config from '../../config';
import io from 'socket.io-client';
import uuid4 from 'uuid/v4';
import _ from 'lodash';
import { buildRoomUri } from 'utils/helper';
import { handlePatientUpdate as connectHandlePatientUpdate } from '../Patient/Connect/action';
import { handlePatientUpdate as patientHandlePatientUpdate } from '../Patient/PatientData/action';

const inboxCreateMessageType = 'createMessage';
const inboxCreateUserType = 'createUser';
const voicemailMessageType = 'ivrVm';
const refillNewMessageType = 'newRefill';
const leadNewMessageType = 'newLead';
const patientUpdateType = 'patientUpdate';

const messageTypeList = [
    inboxCreateMessageType,
    inboxCreateUserType,
    voicemailMessageType,
    refillNewMessageType,
    patientUpdateType,
];

let socket;

const registerSocketListeners = (dispatch) => {
    socket.on('connect', () => {
        dispatch(connectSocket());
    });

    _.forEach(messageTypeList, (messageType) => {
        socket.on(messageType, function (msg) {
            const messageAttributes = JSON.parse(msg.attributes);
            const messageData = _.isEmpty(msg.data) ? null : JSON.parse(msg.data);
            dispatch(receiveMessage(messageType, messageAttributes, messageData));
        });
    });

    socket.on(leadNewMessageType, function (msg) {
        dispatch(receiveMessage(leadNewMessageType, JSON.parse(msg.attributes)));
    });

    socket.on('disconnect', () => {
        dispatch(socketDisconnected());
    });
};

const initializeSocket = () => {
    return function (dispatch) {
        socket = io(config.websocket_url);
        registerSocketListeners(dispatch);
    };
};

const connectSocket = () => {
    return function (dispatch, getState) {
        const { pharmacy } = getState();
        const newLocationId = pharmacy.activeLocationId;
        if (newLocationId) {
            const roomUri = buildRoomUri(newLocationId);
            dispatch(joinRoom(roomUri));
        }

        dispatch({
            type: socketType.SOCKET_CONNECTED,
        });
    };
};

const disconnectSocket = () => {
    return function () {
        if (socket) {
            socket.disconnect();
        }
    };
};

const socketDisconnected = () => {
    return {
        type: socketType.SOCKET_DISCONNECTED,
    };
};

const joinRoom = (roomUri) => {
    return function (dispatch) {
        socket.emit('join', roomUri);
        dispatch(roomJoined(roomUri));
    };
};

const roomJoined = (roomUri) => {
    return {
        type: socketType.SOCKET_JOIN_ROOM,
        payload: { roomUri },
    };
};

const leaveRoom = (roomUri) => {
    return function (dispatch) {
        socket.emit('leave', roomUri);
        dispatch(roomLeft(roomUri));
    };
};

const roomLeft = () => {
    return {
        type: socketType.SOCKET_LEAVE_ROOM,
    };
};

const isDisconnected = () => (socket ? socket.isDisconnected : true);

const receiveMessage = (messageType, messageAttributes, messageData) => (dispatch) => {
    // Inverting the previous middleware pattern by centralizing secondary actions here
    if (messageType === patientUpdateType) {
        dispatch(connectHandlePatientUpdate(messageData));
        dispatch(patientHandlePatientUpdate(messageData));
    }

    dispatch({
        type: socketType.SOCKET_RECEIVE_MESSAGE,
        payload: {
            messageType,
            messageAttributes,
            messageData,
            id: uuid4(),
            at: new Date().getTime(),
        },
    });
};

export const socketAction = {
    initializeSocket,
    connectSocket,
    disconnectSocket,
    socketDisconnected,
    joinRoom,
    roomJoined,
    roomLeft,
    leaveRoom,
    receiveMessage,
    isDisconnected,
};

export default socketAction;
