const typePrefix = 'patient/';

const patientDataTypes = {
    RESET_PATIENT_DATA: `${typePrefix}RESET_PATIENT_DATA`,
    PATIENT_DATA_REQUEST: `${typePrefix}PATIENT_DATA_REQUEST`,
    PATIENT_DATA_SUCCESS: `${typePrefix}PATIENT_DATA_SUCCESS`,
    PATIENT_DATA_FAILURE: `${typePrefix}PATIENT_DATA_FAILURE`,
    PATIENT_INBOX_DATA_REQUEST: `${typePrefix}PATIENT_INBOX_DATA_REQUEST`,
    PATIENT_INBOX_DATA_SUCCESS: `${typePrefix}PATIENT_INBOX_DATA_SUCCESS`,
    PATIENT_INBOX_DATA_FAILURE: `${typePrefix}PATIENT_INBOX_DATA_FAILURE`,
    FOCUS_PATIENT: `${typePrefix}FOCUS_PATIENT`,

    // since this is multiple http requests in one action, we will always just return complete
    // and the results themselves will have any failures that occurred
    BULK_PHONE_TYPE_LOOKUP_REQUEST: `${typePrefix}BULK_PHONE_TYPE_LOOKUP_REQUEST`,
    BULK_PHONE_TYPE_LOOKUP_COMPLETE: `${typePrefix}BULK_PHONE_TYPE_LOOKUP_COMPLETE`,

    UPDATE_PATIENT_DATA: `${typePrefix}UPDATE_PATIENT_DATA`,

    EXACT_DUPLICATE_NEW_PATIENTS_REQUEST: `${typePrefix}EXACT_DUPLICATE_NEW_PATIENTS_REQUEST`,
    EXACT_DUPLICATE_NEW_PATIENTS_SUCCESS: `${typePrefix}EXACT_DUPLICATE_NEW_PATIENTS_SUCCESS`,
    EXACT_DUPLICATE_NEW_PATIENTS_FAILURE: `${typePrefix}EXACT_DUPLICATE_NEW_PATIENTS_FAILURE`,

    SEARCH_PATIENT_LIST_REQUEST: `${typePrefix}SEARCH_PATIENT_LIST_REQUEST`,
    SEARCH_PATIENT_LIST_SUCCESS: `${typePrefix}SEARCH_PATIENT_LIST_SUCCESS`,
    SEARCH_PATIENT_LIST_FAILURE: `${typePrefix}SEARCH_PATIENT_LIST_FAILURE`,

    PATIENT_INDEX_UPDATED: `${typePrefix}PATIENT_INDEX_UPDATED`,

    LOAD_PATIENTS_BY_INBOX_USER_IDS_REQUEST: `${typePrefix}LOAD_PATIENTS_BY_INBOX_USER_IDS_REQUEST`,
    LOAD_PATIENTS_BY_INBOX_USER_IDS_SUCCESS: `${typePrefix}LOAD_PATIENTS_BY_INBOX_USER_IDS_SUCCESS`,
    LOAD_PATIENTS_BY_INBOX_USER_IDS_FAILURE: `${typePrefix}LOAD_PATIENTS_BY_INBOX_USER_IDS_FAILURE`,
};

export default patientDataTypes;
