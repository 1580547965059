import connectTypes from './Connect/type';
import { setPatientIndexUpdated, updatePatientData } from './PatientData/action';

import _ from 'lodash';
import patientDataTypes from './PatientData/type';
import { clearPatientOpportunities } from './Connect/action';

const patientMiddleware = (store) => (next) => (action) => {
    if (action.type === connectTypes.CONNECT_OPPORTUNITIES_SUCCESS) {
        loadPatientsFromOpportunities(store, action);
    }

    if (action.type === patientDataTypes.PATIENT_DATA_SUCCESS) {
        const patientData = store.getState().patientData;

        const latestFetchStartedAt = _.get(patientData, 'latestFetchStartedAt');
        const indexUpdatedAt = _.get(patientData, 'patientIndexUpdatedAt');

        if (_.isFinite(indexUpdatedAt) && _.isFinite(latestFetchStartedAt)) {
            if (indexUpdatedAt >= latestFetchStartedAt) {
                // if the patient index was updated after we started our last fetch, lets trigger another one
                // in a short timeout to get it out of the current cycle as this will cause us to fetch the
                // patients again
                setTimeout(() => {
                    store.dispatch(setPatientIndexUpdated());
                    store.dispatch(clearPatientOpportunities());
                }, 100);
            }
        }
    }

    next(action);
};
export default patientMiddleware;

/*
 * Puts any users returned from our opportunities into patientData so we we don't have to rely on them being loaded naturally
 * execute the opportunity
 */
function loadPatientsFromOpportunities(store, action) {
    const patients = [];
    _.forIn(_.get(action, 'payload.opportunities'), (opportunity, key) => {
        if (_.isArray(opportunity.rows)) {
            patients.push(...opportunity.rows);
        }
    });
    store.dispatch(updatePatientData(patients));
}
