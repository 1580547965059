import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import PhoneNumberLink from 'components/Common/PhoneNumberLink';
import { formatPhoneNo } from 'utils/helper';

const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageBoxWrapper: {
        margin: 'auto',
        maxWidth: '80%',
        marginTop: '40vh',
        textAlign: 'center',
        borderRadius: '10px',
        border: '2px solid #4A4A4A',
    },
    messageBox: {
        width: '100%',
        height: '100%',
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
};

const PharmacyContractReceived = ({ classes }) => {
    const dphSupportContactNumber = '18779597550';

    return (
        <div className={classes.container}>
            <div className={`square ${classes.messageBoxWrapper}`}>
                <div className={`${classes.messageBox}`}>
                    <h2>Thank you</h2>
                    <br />

                    <h4>
                        Your contract form submission was successful! One of our representatives will be in touch
                        shortly.
                    </h4>
                    <br />

                    <p>
                        You can also give our support line a call at &nbsp;
                        <PhoneNumberLink
                            numberToCall={dphSupportContactNumber}
                            numberToShow={formatPhoneNo(dphSupportContactNumber)}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

// Wrapping this with withRouter to avoid the warning.
// https://stackoverflow.com/questions/58751873/materialui-withstyles-invalid-prop-component-of-type-object-supplied-to-ro
export default withRouter(withStyles(styles)(PharmacyContractReceived));
