import _ from 'lodash';
import { createSelector } from 'reselect';
import { LocationOnboardingStepList, LocationOnboardingKeyMap } from './constants';

const inboxOnboardingLocationSelector = (state) => {
    const { locationOnboarding, locationClientSteps } = state.inboxOnboarding;
    return { locationOnboarding, locationClientSteps };
};

const locationOnboardingStepsSelector = createSelector(inboxOnboardingLocationSelector, (onboardingData) => {
    const emptyOnboardingState = {
        completedCount: 0,
        totalCount: 0,
        nextStepDisplay: null,
        steps: [],
    };

    if (_.isEmpty(onboardingData.locationOnboarding)) {
        // if there is no locationOnboarding, onboarding is not enabled for this pharmacy
        return emptyOnboardingState;
    }

    return _.reduce(
        _.cloneDeep(LocationOnboardingStepList),
        (result, step) => {
            const stepCompletionData = _.get(onboardingData, step.statePath);
            if (step.key === LocationOnboardingKeyMap.DESKTOP_NOTIFICATIONS && _.isNil(stepCompletionData)) {
                // notifications are not available for this user, don't display the step
                return result;
            }

            let stepComplete = false;
            if (_.get(step, 'count.total')) {
                step.count.completed = stepCompletionData;
                stepComplete = step.count.completed >= step.count.total;
            } else {
                stepComplete = Boolean(stepCompletionData);
            }

            if (stepComplete) {
                result.completedCount += 1;
            } else {
                result.nextStepDisplay = result.nextStepDisplay || step.display;
            }

            result.totalCount += 1;
            result.steps.push({ ...step, complete: stepComplete });

            return result;
        },
        emptyOnboardingState
    );
});

const locationOnboardingIsPendingSelector = createSelector(locationOnboardingStepsSelector, (onboardingSteps) => {
    return onboardingSteps.totalCount > 0 && onboardingSteps.completedCount < onboardingSteps.totalCount;
});

export const onboardingSelectors = {
    locationOnboardingStepsSelector,
    locationOnboardingIsPendingSelector,
};
