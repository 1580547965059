import Actions from 'redux/actions';
import menuItem from 'constants/AAMainMenu';

const initialState = {
    mainMenuItemsLoading: false,
    savingInProgress: false,
    mainMenuItems: [],
};

const setMainMenuItems = (state, payload) => {
    const mainMenuItems = payload;
    mainMenuItems.forEach((item, itemIdx) => {
        if (item.phone === null) {
            mainMenuItems[itemIdx].phone = '';
            mainMenuItems[itemIdx].transferto = 'ivrmenu';
        } else {
            mainMenuItems[itemIdx].transferto = 'phone';
        }
    });
    return { ...state, mainMenuItemsLoading: false, mainMenuItems };
};

const addNewMenuItem = (state) => {
    const { mainMenuItems } = { ...state };
    const menuItemModel = { ...menuItem };
    mainMenuItems.push(menuItemModel);
    return {
        ...state,
        mainMenuItems,
    };
};

const removeMenuItem = (state, payload) => {
    const { mainMenuItems } = { ...state };
    mainMenuItems.splice(payload.idx, 1);
    return {
        ...state,
        mainMenuItems,
    };
};

const removeMenuItemProcessing = (state, payload, isProcessing) => {
    const { mainMenuItems } = { ...state };
    mainMenuItems[payload.idx].processing = isProcessing;
    return {
        ...state,
        mainMenuItems,
    };
};

export default function AAMainMenuReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_AA_MAIN_MENU_STARTED:
            return { ...state, mainMenuItemsLoading: true };
        case Actions.FETCH_AA_MAIN_MENU_SUCCESS:
            return setMainMenuItems(state, payload);
        case Actions.FETCH_AA_MAIN_MENU_FAILED:
            return { ...state, mainMenuItemsLoading: false };
        case Actions.ADD_AA_MENU_ITEM:
            return addNewMenuItem(state);
        case Actions.SET_AA_MENU_ITEM_CHANGES:
            return { ...state, mainMenuItems: payload.mainMenuItems };
        case Actions.REMOVE_AA_MENU_ITEM_STARTED:
            return removeMenuItemProcessing(state, payload, true);
        case Actions.REMOVE_AA_MENU_ITEM_SUCCESS:
            return removeMenuItem(state, payload);
        case Actions.REMOVE_AA_MENU_ITEM_FAILED:
            return removeMenuItemProcessing(state, payload, false);
        case Actions.SAVE_AA_MAIN_MENU_STARTED:
            return { ...state, savingInProgress: true };
        case Actions.SAVE_AA_MAIN_MENU_SUCCESS:
        case Actions.SAVE_AA_MAIN_MENU_FAILED:
            return { ...state, savingInProgress: false };
        default:
            return state;
    }
}
