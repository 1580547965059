import React, { PureComponent } from 'react';
import { Grid, Cell, Card, CardText, CircularProgress, Button as RMDButton } from 'react-md';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common';
import { fetchPhoneMenuSettings, saveMenuStatus } from 'redux/actionCreators/Settings/PhoneLine';
import IvrMenuList from 'components/Settings/PhoneSystem/Common/IvrMenuList';
import { mainPhoneLineOptions, refillPhoneLineOptions } from 'constants/PhoneLine';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { MarkDefaultConfirm } from 'components/Settings/PhoneSystem/Common/MarkDefaultConfirm';
import goBackService from 'utils/goBackService';
import { IndependentMenuItem } from 'components/Settings/PhoneSystem/Common/IndependentMenuItem';
import { AbilityContext, userActions, restrictedResources } from 'casl';

export class PhoneMenu extends PureComponent {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.markDefaultData = null;
        this.state = {
            markDefaultConfirmVisible: false,
        };
    }

    componentDidMount() {
        const { loadData } = this.props;
        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);
        loadData(canCreateGreeting);
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    componentDidUpdate(prevProps) {
        const { auth, loadData } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadData();
        }
    }

    showMarkDefault = (data) => {
        this.markDefaultData = data;
        this.setState({
            markDefaultConfirmVisible: true,
        });
    };

    hideMarkDefault = () => {
        this.markDefaultData = null;
        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    confirmMarkDefault = () => {
        const { saveMenuStatus } = this.props;
        saveMenuStatus(this.markDefaultData);
        this.markDefaultData = null;

        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    updateMenu = (data) => {
        const { saveMenuStatus } = this.props;

        if (data.item === 'privacy_policy' && !data.value) {
            this.showMarkDefault(data);
        } else {
            saveMenuStatus(data);
        }
    };

    render() {
        const {
            saveMenuStatus,
            location,
            displayToast,
            ivrSMSSend,
            huntGroups,
            history,
            flags,
            mainGreeting: { defaultGreeting },
            auth: { selectedLocation },
            phoneMenuSettings: { phoneMenus, loadingMenuConfig, phoneMenuEdit },
        } = this.props;
        const { markDefaultConfirmVisible } = this.state;
        const isPhoneLineMenu = location.pathname.endsWith('phone-menu');
        const subTitleTxt = isPhoneLineMenu
            ? 'These are the options that your patients can choose while using your IVR'
            : 'These are the options that your patients can choose while refilling a prescription through your IVR';

        const IVROptions = mainPhoneLineOptions.filter((menu) => {
            if (menu.value === 'sms' && !ivrSMSSend) {
                return false;
            }

            return true;
        });
        const { view, write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.pharmacyMenuOptions;
        // Note, write here means the user role can create, update, delete pharmacy menu options
        const canWriteIVRMenuOptions = this.context.can(write, caslSubject);
        // TODO: This component needs to be refactored, two components have been implemented as one in an incorrect way
        const {
            subject: fulfillmentCaslSubject,
            fields: fulfillmentRestrictedFields,
        } = restrictedResources.ivr.prescriptionRefillFulfillment;

        const canViewPatientDobValidation = this.context.can(
            view,
            fulfillmentCaslSubject,
            fulfillmentRestrictedFields.patientDobValidation
        );

        const markDefaultActions = [];
        markDefaultActions.push(
            <RMDButton flat primary onClick={this.hideMarkDefault}>
                Cancel
            </RMDButton>
        );
        markDefaultActions.push(
            <RMDButton raised secondary onClick={this.confirmMarkDefault}>
                Confirm
            </RMDButton>
        );

        // NOTE: temp hacky solution to allow LD flag to control if additional options are displayed
        const filteredRefillOptions = _.filter(refillPhoneLineOptions, (option) => {
            if (_.includes(['refill_kiosk', 'refill_curbside'], option.value)) {
                return !!flags.npeKioskCurbsideDeliveryOptionsEnabled;
            }
            return true;
        });

        return (
            <Grid>
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title={isPhoneLineMenu ? 'Pharmacy Menu Options' : 'Prescription Refill Fulfillment'}
                            onBack={this.onBack}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text={isPhoneLineMenu ? 'Menu Items' : 'Fulfillment Options'} />
                                    <SectionSubTitle text={subTitleTxt} />
                                    {loadingMenuConfig ? (
                                        <CircularProgress id="ivr-menu-spinner" />
                                    ) : (
                                        <div style={{ marginTop: '33px' }}>
                                            <IvrMenuList
                                                phoneMenus={phoneMenus}
                                                history={history}
                                                perms={selectedLocation.perms}
                                                hasHuntGroups={huntGroups.length > 0}
                                                phoneLines={isPhoneLineMenu ? IVROptions : filteredRefillOptions}
                                                phoneMenuEdit={phoneMenuEdit}
                                                onSave={(item, value) => {
                                                    const isNew = _.isUndefined(phoneMenus[item]);
                                                    this.updateMenu({ id: defaultGreeting.id, item, value, isNew });
                                                }}
                                                displayToast={(data) => displayToast(data)}
                                                hideActions={!canWriteIVRMenuOptions}
                                            />
                                        </div>
                                    )}
                                </CardText>
                            </Card>

                            {!isPhoneLineMenu && !loadingMenuConfig && (
                                <IndependentMenuItem
                                    titleText="Voice Message Attached To Prescription Refill Requests"
                                    subTitleText="When this setting is active, callers will be offered the option to leave a voice message along with the prescription number being submitted for refilling"
                                    loading={Boolean(phoneMenuEdit['refill_messages'])}
                                    active={Boolean(phoneMenus['refill_messages'])}
                                    clickHandler={() =>
                                        saveMenuStatus({
                                            id: defaultGreeting.id,
                                            item: 'refill_messages',
                                            value: phoneMenus['refill_messages'] ? 0 : 1,
                                        })
                                    }
                                    hideActions={!canWriteIVRMenuOptions}
                                />
                            )}

                            {!isPhoneLineMenu && !loadingMenuConfig && canViewPatientDobValidation && (
                                <IndependentMenuItem
                                    titleText="Patient Date of Birth Validation (Internal Users Only)"
                                    subTitleText="When this setting is active, callers will be required to enter the patient’s DOB matching the Rx number being submitted for refilling"
                                    loading={Boolean(phoneMenuEdit['refill_dob'])}
                                    active={Boolean(phoneMenus['refill_dob'])}
                                    clickHandler={() =>
                                        saveMenuStatus({
                                            id: defaultGreeting.id,
                                            item: 'refill_dob',
                                            value: phoneMenus['refill_dob'] ? 0 : 1,
                                        })
                                    }
                                    hideActions={!canWriteIVRMenuOptions}
                                />
                            )}
                        </CardText>
                    </Card>
                </Cell>
                <MarkDefaultConfirm
                    hideMarkDefault={this.hideMarkDefault}
                    isVisible={markDefaultConfirmVisible}
                    actions={markDefaultActions}
                    title="Disable Privacy policy"
                    body="By disabling this feature, you will be no longer compliant with California Consumer Protection Act (CCPA).
            If your pharmacy is located in or you do business in California, you are required to provide access to the privacy policy.
            If you are not in or do not do business in California, you are not required to provide this feature at this time."
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    phoneMenuSettings: state.settings.phoneLine,
    mainGreeting: state.settings.mainGreeting,
    ivrSMSSend: state.pharmacy.ivrSMSSend,
    huntGroups: state.settings.speakWithPharmacists.huntGroups,
    auth: state.auth,
    flags: state.launchDarkly,
});

const mapDispatchToProps = (dispatch) => ({
    loadData: (data) => dispatch(fetchPhoneMenuSettings(data)),
    saveMenuStatus: (data) => dispatch(saveMenuStatus(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneMenu);
