import SettingsActions from 'redux/actions/Settings';
import SnackbarActions from 'redux/actions/Snackbar';
import AuthActions from 'redux/actions/Auth';
import VoicemailActions from 'redux/actions/Voicemail';
import ReportsAction from 'redux/actions/Reports';
import TigerPistolActions from 'redux/actions/TigerPistol';
import CustomLoaderActions from 'redux/actions/CustomLoader';
import GeneralSettingsActions from 'redux/actions/Settings/GeneralSettings';
import RefillsAction from 'redux/actions/Refills';
import LeadsAction from 'redux/actions/Leads';

export default {
    ...SettingsActions,
    ...SnackbarActions,
    ...AuthActions,
    ...VoicemailActions,
    ...ReportsAction,
    ...TigerPistolActions,
    ...CustomLoaderActions,
    ...GeneralSettingsActions,
    ...RefillsAction,
    ...LeadsAction,
};
