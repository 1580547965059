import { post, put, deleteReq } from 'redux/actionCreators/rest';
import { displayToast } from 'redux/actionCreators/Snackbar';

export const postRequest = (url, data, toast, dispatch, showSuccessFailure = true, showInitToast = true) => {
    showInitToast &&
        dispatch(
            displayToast({
                text: toast.initMessage,
                type: 'info',
            })
        );

    return post(url, data)
        .then((response) => {
            showSuccessFailure &&
                dispatch(
                    displayToast({
                        text: toast.successMessage,
                        type: 'success',
                    })
                );

            return response.id;
        })
        .catch(() => {
            showSuccessFailure &&
                dispatch(
                    displayToast({
                        text: toast.errorMessage,
                        type: 'error',
                    })
                );
            Promise.reject();
        });
};

export const putRequest = (url, data, toast, dispatch, showSuccessFailure = true, showInitToast = true) => {
    showInitToast &&
        dispatch(
            displayToast({
                text: toast.initMessage,
                type: 'info',
            })
        );

    return put(url, data)
        .then((response) => {
            showSuccessFailure &&
                dispatch(
                    displayToast({
                        text: toast.successMessage,
                        type: 'success',
                    })
                );
            Promise.resolve();
        })
        .catch(() => {
            showSuccessFailure &&
                dispatch(
                    displayToast({
                        text: toast.errorMessage,
                        type: 'error',
                    })
                );
            Promise.reject();
        });
};

export const deleteRequest = (url, toast, dispatch) => {
    dispatch(
        displayToast({
            text: toast.initMessage,
            type: 'info',
        })
    );

    return deleteReq(url)
        .then(() => {
            dispatch(
                displayToast({
                    text: toast.successMessage,
                    type: 'success',
                })
            );
        })
        .catch(() => {
            dispatch(
                displayToast({
                    text: toast.errorMessage,
                    type: 'error',
                })
            );
            Promise.reject();
        });
};
