import Actions from 'redux/actions';
import { isPhoneNoValid } from 'utils/helper';

const initialState = {
    count: 0,
    refills: [],
    isRefillsLoading: false,
    isRefillsCountLoading: false,
    isLoadMoreRefillsLoading: false,
    sortData: {
        created_date: 'desc',
    },
};

const DEFAULT_DATE = new Date('1900-01-01').getTime();

const getPatientName = (refill) => {
    const FName = refill.patient_info['first_name'] ? refill.patient_info['first_name'] : '';
    const LName = refill.patient_info['last_name'] ? refill.patient_info['last_name'] : '';
    return `${FName} ${LName}`;
};

const sortRefill = (state, payload) => {
    let { refills, sortData } = { ...state };

    refills = payload.refills ? payload.refills : refills;

    const { column, order } = payload;

    if (['status', 'delivery_method', 'source_type'].indexOf(column) > -1) {
        refills.sort((a, b) => {
            const aCol = a[column] ? a[column] : '';
            const bCol = b[column] ? b[column] : '';
            return order === 'asc' ? aCol.localeCompare(bCol) : bCol.localeCompare(aCol);
        });
    } else if (column === 'patient_name') {
        refills.sort((a, b) => {
            const aName = getPatientName(a);
            const bName = getPatientName(b);
            return order === 'asc' ? aName.localeCompare(bName) : bName.localeCompare(aName);
        });
    } else if (column === 'rx_num') {
        refills.sort((a, b) => {
            const aRxNum = a.rx_num ? parseInt(a.rx_num) : 0;
            const bRxNum = b.rx_num ? parseInt(b.rx_num) : 0;
            return order === 'asc' ? aRxNum - bRxNum : bRxNum - aRxNum;
        });
    } else if (column === 'created_date') {
        refills.sort((a, b) =>
            order === 'asc'
                ? new Date(a.created_date) - new Date(b.created_date)
                : new Date(b.created_date) - new Date(a.created_date)
        );
    }

    sortData = { [column]: order };

    return { ...state, refills, sortData };
};

const isDefaultDate = (date) => {
    if (!date) return false;
    return new Date(date).getTime() === DEFAULT_DATE;
};

const formatRefills = (refills) => {
    // custom modifications to refills like
    // 1. Adding "IVR CALLER" as name if source is ivr
    // 2. Make DOB as null if it matches to default date
    // 3. Add "No Caller ID" as phone if phone no is blank

    return refills.map((refill) => {
        if (refill.source_type === 'ivr2') {
            const { patient_info } = refill;

            return {
                ...refill,
                patient_info: {
                    ...patient_info,
                    first_name: 'IVR',
                    last_name: 'CALLER',
                    phone: isPhoneNoValid(patient_info.phone) ? patient_info.phone : 'No Caller ID',
                    date_of_birth: isDefaultDate(patient_info.date_of_birth) ? null : patient_info.date_of_birth,
                },
            };
        }
        return refill;
    });
};

export default function RefillsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_REFILLS_COUNT_SUCCESS:
            const { totalRefillOrders } = payload.data;
            return {
                ...state,
                count: parseInt(totalRefillOrders),
                isRefillsCountLoading: false,
            };
        case Actions.FETCH_REFILLS_COUNT_STARTED:
            return {
                ...state,
                isRefillsCountLoading: true,
            };
        case Actions.FETCH_REFILLS_COUNT_FAILED:
            return {
                ...state,
                isRefillsCountLoading: false,
                count: 0,
            };
        case Actions.FETCH_REFILLS_SUCCESS:
            const { refill_requests } = payload.data;
            const formattedRefills = formatRefills(refill_requests);
            const loadMore = payload.loadMore ? true : false;

            const refills = loadMore
                ? [
                      ...new Map(
                          state.refills
                              .concat(formattedRefills)
                              .map((item) => [`${item['submitted_refill_id']}_${item['rx_num']}`, item])
                      ).values(),
                  ]
                : formattedRefills;

            const column = Object.keys(state.sortData)[0];
            const order = state.sortData[column];
            const sortedRefills = sortRefill(state, { order, column, refills });
            return {
                ...state,
                refills: sortedRefills.refills,
                isRefillsLoading: false,
                isLoadMoreRefillsLoading: false,
            };
        case Actions.FETCH_REFILLS_STARTED:
            return {
                ...state,
                isRefillsLoading: !payload.loadMore,
                isLoadMoreRefillsLoading: payload.loadMore,
            };
        case Actions.FETCH_REFILLS_FAILED:
            return {
                ...state,
                isRefillsLoading: false,
                isLoadMoreRefillsLoading: false,
                refills: [],
            };
        case Actions.SORT_REFILL:
            return sortRefill(state, payload);
        default:
            return state;
    }
}
