import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OnboardingOverviewCard from 'components/Onboarding/OnboardingOverviewCard';
import { onboardingSelectors } from 'redux/Inbox/Onboarding/selector';
import Snowplow from 'snowplow';
import { onboardingActions } from 'redux/Inbox/Onboarding';
import { LocationOnboardingKeyMap } from 'redux/Inbox/Onboarding/constants';
import TemplateCreationModal from '../TemplateCreation/TemplateCreationModal';
import NewContactDialog from '../NewContactDialog';
import NotificationDialog from 'components/Common/NotificationDialog';
import NewConversationDialog from '../Conversation/NewConversationDialog';
import { conversationAction } from 'redux/Inbox/Conversation';
import BulkPatientUploadDialog from '../../Patients/csv/BulkPatientUploadDialog';

OnboardingContent.propTypes = {
    user: PropTypes.shape({
        activeUserId: PropTypes.string,
        userMap: PropTypes.shape({}),
    }).isRequired,
    inboxOnboarding: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
    }).isRequired,
    onboardingSteps: PropTypes.shape({
        completedCount: PropTypes.number.isRequired,
        totalCount: PropTypes.number.isRequired,
        nextStepDisplay: PropTypes.string,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                type: PropTypes.oneOf(['count', 'action']).isRequired,
                display: PropTypes.string.isRequired,
                complete: PropTypes.bool.isRequired,
                action: PropTypes.shape({
                    display: PropTypes.string.isRequired,
                }),
                count: PropTypes.shape({
                    completed: PropTypes.number.isRequired,
                    total: PropTypes.number.isRequired,
                }),
            })
        ).isRequired,
    }).isRequired,
    trackInboxLocationOnboardingAction: PropTypes.func.isRequired,
    closeCreateConversationDialog: PropTypes.func.isRequired,
    onBack: PropTypes.func,
};

OnboardingContent.defaultProps = {};

const useStyles = makeStyles({
    loadingContainer: {
        display: 'flex',
        height: '85vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentContainerMd: {
        margin: '30px 48px',
    },
    contentContainerSm: {
        marginTop: '16px',
        marginRight: '24px',
        // larger bottom margin to account for tabbar
        marginBottom: '72px',
        marginLeft: '24px',
    },
    header: {
        fontSize: '1.75rem',
        lineHeight: 1.4,
    },
    subtitle: {
        fontSize: '1.1rem',
        color: '#808080',
        marginBottom: '24px',
    },
});

export function OnboardingContent(props) {
    const {
        user,
        inboxOnboarding,
        onboardingSteps: { completedCount, totalCount, nextStepDisplay, steps },
        trackInboxLocationOnboardingAction,
        closeCreateConversationDialog,
        onBack,
    } = props;
    const classes = useStyles();
    const mobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [dialogToDisplay, setShowDialog] = useState(null);

    useEffect(() => {
        Snowplow.pageView('Inbox Onboarding');
    }, []);

    const noSteps = totalCount < 1;
    if (inboxOnboarding.loading && noSteps) {
        // prior to redirecting let the onboarding request finish
        // don't cause content to flash if it's not the initial load
        return (
            <div data-testid="loading-container" className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (!inboxOnboarding.loading && noSteps) {
        // Not fetching state and there are no steps to display.
        // redirect the user
        return <Redirect data-testid="redirect" to={{ pathname: '/inbox' }} />;
    }

    const activeContact = user.activeUserId ? user.userMap[user.activeUserId] : null;

    function onStepActionClick({ key }) {
        setShowDialog(key);
        trackInboxLocationOnboardingAction({ action: `action-${key}`, value: 'clicked' });
    }

    function onStepActionClosed({ key, success = false }) {
        setShowDialog(null);
        trackInboxLocationOnboardingAction({ action: `action-${key}`, value: success ? 'complete' : 'closed' });
    }

    return (
        <div
            data-testid="onboarding-content-container"
            className={`${classes.contentContainerMd} ${mobileScreen ? classes.contentContainerSm : ''}`}
        >
            <div className="flex-middle">
                <Hidden mdUp>
                    <ArrowBackIcon onClick={onBack} style={{ marginRight: '10px' }} />
                </Hidden>
                <Typography variant="h5" className={classes.header}>
                    Get Started with Inbox
                </Typography>
            </div>
            <Typography variant="subtitle1" className={classes.subtitle}>
                Follow the steps below to send a few messages, add your patients, and get setup with Inbox.
            </Typography>
            <OnboardingOverviewCard
                data-testid="onboarding-overview-card"
                completedCount={completedCount}
                totalCount={totalCount}
                nextStepDisplay={nextStepDisplay}
                steps={steps}
                onStepActionClick={onStepActionClick}
            />
            <>
                {dialogToDisplay === LocationOnboardingKeyMap.FIRST_TEMPLATE && (
                    <TemplateCreationModal
                        showModal
                        fromOnboarding
                        onClose={({ success } = {}) => {
                            onStepActionClosed({
                                key: LocationOnboardingKeyMap.FIRST_TEMPLATE,
                                success,
                            });
                        }}
                    />
                )}

                {dialogToDisplay === LocationOnboardingKeyMap.ADD_YOUR_PATIENTS && (
                    <BulkPatientUploadDialog
                        open
                        fromOnboarding
                        onClose={({ success } = {}) => {
                            onStepActionClosed({
                                key: LocationOnboardingKeyMap.ADD_YOUR_PATIENTS,
                                success,
                            });
                        }}
                    />
                )}

                {dialogToDisplay === LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE && (
                    <NewContactDialog
                        showNewContactModal
                        fromOnboarding
                        handleNewContactModalClose={({ success } = {}) => {
                            onStepActionClosed({ key: LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE, success });
                            if (success) {
                                onStepActionClick({
                                    key: LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE_TEMPLATE_SELECTION,
                                });
                            }
                        }}
                    />
                )}

                {activeContact &&
                    dialogToDisplay === LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE_TEMPLATE_SELECTION && (
                        <NewConversationDialog
                            contact={activeContact}
                            visible
                            fromOnboarding
                            closeDialog={({ success } = {}) => {
                                onStepActionClosed({
                                    key: LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE_TEMPLATE_SELECTION,
                                    success,
                                });
                                closeCreateConversationDialog();
                            }}
                        />
                    )}

                {dialogToDisplay === LocationOnboardingKeyMap.DESKTOP_NOTIFICATIONS && (
                    <NotificationDialog
                        dialogTitle="Turn on desktop notifications"
                        dialogMsg="Never miss another patient message with Digital Pharmacist desktop notifications."
                        dialogImage={`/desktop_notification_inbox_background.png`}
                        fromOnboarding
                        onClose={({ success } = {}) => {
                            onStepActionClosed({ key: LocationOnboardingKeyMap.DESKTOP_NOTIFICATIONS, success });
                        }}
                    />
                )}
            </>
        </div>
    );
}

function mapStateToProps(state) {
    const { inboxOnboarding, inboxUser } = state;
    return {
        user: inboxUser,
        inboxOnboarding,
        onboardingSteps: onboardingSelectors.locationOnboardingStepsSelector(state),
    };
}

const bindActionsToDispatch = {
    trackInboxLocationOnboardingAction: onboardingActions.trackInboxLocationOnboardingAction,
    closeCreateConversationDialog: conversationAction.closeCreateConversationDialog,
};

export default connect(mapStateToProps, bindActionsToDispatch)(OnboardingContent);
