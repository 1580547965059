import React from 'react';
import { Can, userActions, restrictedResources } from 'casl';
import NoAccess from 'components/Common/NoAccess';
import PatientsPage from './PatientsPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function PatientsPageWrapper() {
    const { view } = userActions;
    const { subject: caslSubject } = restrictedResources.patients;

    return (
        <Can I={view} this={caslSubject} passThrough>
            {(allowed) =>
                allowed ? (
                    <PatientsPage />
                ) : (
                    <div className="patients-grid-wrap" style={{ width: '100%', marginTop: '80px' }}>
                        <NoAccess />
                    </div>
                )
            }
        </Can>
    );
}

const mapStateToProps = (state) => {
    return {};
};
export default withRouter(connect(mapStateToProps, () => ({}))(PatientsPageWrapper));
