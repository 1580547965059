import './ConversationsDialog.sass';
import React from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import ContactSection from '../Inbox/ContactSection';
import classnames from 'classnames';

export default function ConversationsDialog(props) {
    const { loading, open } = props;

    return (
        <Dialog {...props} maxWidth="md" className={classnames({ 'conversations-dialog': true, loading: true })}>
            {
                /* Only render the conversation if we have the data to do so since it is tightly coupled with the inbox redux stuff */
                loading || !open ? (
                    <div className="loading-message">
                        <CircularProgress className="loading-indicator" /> <span>Loading conversations...</span>
                    </div>
                ) : (
                    /*<ConversationList contact={props.contact} activeLocationCountryCode={props.activeLocationCountryCode} />*/
                    <ContactSection contact={props.contact} onBack={() => {}} patientEditingDisabled={true} />
                )
            }
        </Dialog>
    );
}
