import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardTitle, Cell, Grid, CardText } from 'react-md';
import { Route, Switch } from 'react-router-dom';
import { CircularProgress, Button } from '@material-ui/core';
import { get as _get } from 'lodash';
import { isMobileOnly } from 'react-device-detect';

import AAMainMenu from './AAMainMenu';
import AAMainGreeting from './AAMainGreeting';
import Languages from './Languages';
import IVRMainGreeting from './IVRMainGreeting';
import PhoneMenu from './PhoneMenu';
import AfterHourTransfer from './AfterHourTransfer';
import PhoneTransfer from './PhoneTransfer';
import DoctorsLine from './DoctorsLine';
import GeneralLine from './GeneralLine';
import PharmacyHours from './PharmacyHours';
import NewPatientLine from './NewPatientLine';

import { updateIvrPhone, startIvrPhoneUpdate } from 'redux/actionCreators/Auth';
import { resetIVR, saveGreeting, fetchMainGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { toDigits } from 'utils/helper';
import {
    GeneralSettings,
    PhoneLineSettings,
    OnlyCallTrackingGeneralSettings,
    OnlyCallTrackingPhoneLineSettings,
} from 'constants/PhoneSystems';
import { fetchPhoneMenuSettings } from 'redux/actionCreators/Settings/PhoneLine';
import ListComponent from 'components/Common/ListComponent';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { ConfirmResetIVR } from 'components/Settings/PhoneSystem/Common/ConfirmResetIVR';
import { IVRPhoneEditor } from 'components/Settings/PhoneSystem/Common/IVRPhoneEditor';
import { Can, userActions, restrictedResources, AbilityContext } from 'casl';

export class PhoneSystemMain extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: null,
            phoneErr: null,
            conflictLocation: null,
            resetIVRConfirmVisible: false,
        };
    }

    componentDidMount() {
        const { fetchPhoneMenuSettings } = this.props;
        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);
        fetchPhoneMenuSettings(canCreateGreeting);
    }

    componentDidUpdate(prevProps) {
        const { auth, fetchPhoneMenuSettings } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            const { create } = userActions;
            const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
            const canCreateGreeting = this.context.can(create, greetingSubject);
            fetchPhoneMenuSettings(canCreateGreeting);
        }
    }

    onItemClick = (to) => {
        this.props.history.push({ pathname: to, state: this.props.location.state });
    };

    toggleResetIVRConfirm = () => {
        const { resetIVRConfirmVisible } = this.state;

        this.setState({
            resetIVRConfirmVisible: !resetIVRConfirmVisible,
        });
    };

    confirmResetIVR = () => {
        const {
            displayToast,
            resetIVR,
            mainGreeting: { defaultGreeting },
        } = this.props;

        resetIVR(defaultGreeting).then((resp) => {
            if ('data' in resp) {
                displayToast({ text: 'Your IVR is reset to default', type: 'success' });
            }
        });

        this.setState({
            resetIVRConfirmVisible: false,
        });
    };

    handleInputChange = (e) => {
        let phoneErr = null;
        if (!e.target.value) {
            phoneErr = 'Required';
        }
        this.setState({ phoneNumber: e.target.value, phoneErr, conflictLocation: null });
    };

    toggleEdit = (phone) => {
        const { isPhoneEditing } = this.state;
        if (isPhoneEditing) {
            this.updatePhone();
        } else {
            this.setState({
                isPhoneEditing: true,
                phoneNumber: phone,
            });
        }
    };

    cancelEdit = () => {
        this.setState({
            isPhoneEditing: false,
            phoneNumber: null,
            phoneErr: null,
        });
    };

    updatePhone = () => {
        const { phoneErr, phoneNumber } = this.state;
        const {
            startIvrPhoneUpdate,
            auth: { ivrPhones },
            mainGreeting: { defaultGreeting },
        } = this.props;
        const newPhoneNumber = toDigits(phoneNumber);

        if (!phoneErr) {
            if (newPhoneNumber.length < 10) {
                this.setState({
                    phoneErr: 'Enter a 10 digit phone number',
                });
            } else {
                let ivrPhoneDetails = [{ id: '0', phone: '' }];
                if (ivrPhones.length) {
                    ivrPhoneDetails = ivrPhones;
                }
                ivrPhoneDetails[0].phone = newPhoneNumber.length > 10 ? newPhoneNumber : `1${newPhoneNumber}`;
                startIvrPhoneUpdate();
                this.udpdateIvrPhoneNumber({ ivrPhoneDetails, ivrId: defaultGreeting.id });
            }
        }
    };

    udpdateIvrPhoneNumber = ({ ivrPhoneDetails, ivrId }) => {
        const { updateIvrPhone } = this.props;
        updateIvrPhone({ ivrPhoneDetails, ivrId }).then((resp) => {
            if ('data' in resp) {
                this.cancelEdit();
            } else {
                const errDetail = JSON.parse(_get(resp, 'err.response.data.description', {}));
                const conflictLocation = [];
                errDetail.conflict &&
                    errDetail.conflict.forEach((conflict) => {
                        conflict.conflictsWith.forEach((conflictsWith) => {
                            conflictLocation.push(conflictsWith.locationName);
                        });
                    });
                this.setState({ conflictLocation });
            }
        });
    };

    render() {
        const {
            callTrackingEnabled,
            onlyCallTrackingEnabled,
            autoAttendant,
            history,
            auth: { updatingIvrPhone, ivrPhones, selectedLocation },
            flags: { npeAutoAttendant },
            mainGreeting: { defaultGreeting, loadingMainGreeting, isResetIVRinProgress },
        } = this.props;
        const { resetIVRConfirmVisible, conflictLocation, isPhoneEditing, phoneNumber, phoneErr } = this.state;
        const title = !onlyCallTrackingEnabled ? 'Manage Your IVR System' : 'Manage Your System';
        const subtitle = !onlyCallTrackingEnabled
            ? 'Setup and configure your IVR system settings'
            : 'Setup and configure your new caller system settings';
        let generalSettingsList = [...GeneralSettings];
        let phoneLineSettingsList = [...PhoneLineSettings];
        const onlyCallTrackingGeneralSettings = [...OnlyCallTrackingGeneralSettings];
        const onlyCallTrackingPhoneLineSettings = [...OnlyCallTrackingPhoneLineSettings];
        const { view, update } = userActions;
        const { subject: caslSubject, fields: restrictedFields } = restrictedResources.ivr;

        let phone = '';
        if (ivrPhones.length) {
            phone = ivrPhones[0].phone;
            phone = phone.length > 10 ? phone.substring(1) : phone;
        }

        // Show/hide AA menu items based on ld AA flag
        generalSettingsList = generalSettingsList.filter((item) => {
            if (item.isAutoAttendant) {
                return npeAutoAttendant;
            }
            return true;
        });

        if (!callTrackingEnabled) {
            phoneLineSettingsList = phoneLineSettingsList.filter((item) => !item.isCallTracking);
        }

        generalSettingsList = generalSettingsList.map((gSetting) => {
            if (gSetting.hasLearnMoreMenu) {
                gSetting.learnMore = !autoAttendant;
            }
            if (gSetting.hasConfigureButton) {
                gSetting.showConfigureButton = !Boolean(defaultGreeting.aa_welcome_greeting_id);
            }
            return gSetting;
        });

        return (
            <Fragment>
                <Grid>
                    <Cell size={12}>
                        <Can I={view} this={caslSubject}>
                            <Card className="outer-card phone-setting-main-list">
                                <CardTitle title={title} subtitle={subtitle} style={{ marginLeft: '16px' }} />
                                <div className="ivr-number-reset-container">
                                    <Can I={view} this={caslSubject} field={restrictedFields.resetIVR}>
                                        {!onlyCallTrackingEnabled &&
                                            (isResetIVRinProgress ? (
                                                <CircularProgress id="reset-ivr-loader" />
                                            ) : (
                                                <div className="flex-middle">
                                                    <Button
                                                        id="resetIvr"
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="large"
                                                        fullWidth
                                                        onClick={this.toggleResetIVRConfirm}
                                                    >
                                                        Reset IVR
                                                    </Button>
                                                </div>
                                            ))}
                                    </Can>

                                    <Can
                                        I={update}
                                        this={caslSubject}
                                        field={restrictedFields.ivrPhoneNumber}
                                        passThrough
                                    >
                                        {(updateAllowed) => (
                                            <div
                                                className={!isMobileOnly ? 'standard-margin-left-10' : ''}
                                                style={{ marginTop: isMobileOnly ? '20px' : '' }}
                                            >
                                                <IVRPhoneEditor
                                                    style={{ minWidth: '200px' }}
                                                    onlyCallTrackingEnabled={onlyCallTrackingEnabled}
                                                    phoneNumber={phoneNumber}
                                                    phone={phone}
                                                    phoneErr={phoneErr || conflictLocation}
                                                    updateAllowed={updateAllowed}
                                                    isPhoneEditing={isPhoneEditing}
                                                    updatingIvrPhone={updatingIvrPhone}
                                                    selectedLocation={selectedLocation}
                                                    saveHandler={this.toggleEdit}
                                                    cancelHandler={this.cancelEdit}
                                                    onTextChange={this.handleInputChange}
                                                />
                                                {(conflictLocation || phoneErr) && (
                                                    <div className="err-note">
                                                        {phoneErr
                                                            ? phoneErr
                                                            : `Number is already in use at ${conflictLocation.join(
                                                                  ', '
                                                              )}`}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Can>
                                </div>
                                <CardText>
                                    <ListComponent
                                        title="General Menu Settings"
                                        className="setting-list"
                                        list={
                                            !onlyCallTrackingEnabled
                                                ? generalSettingsList
                                                : onlyCallTrackingGeneralSettings
                                        }
                                        onItemClick={this.onItemClick}
                                        history={history}
                                        loading={loadingMainGreeting || false}
                                    />
                                    <div style={{ marginTop: '32px' }} />
                                    <ListComponent
                                        title="Pharmacy Menu Settings"
                                        className="setting-list"
                                        list={
                                            !onlyCallTrackingEnabled
                                                ? phoneLineSettingsList
                                                : onlyCallTrackingPhoneLineSettings
                                        }
                                        onItemClick={this.onItemClick}
                                    />
                                </CardText>
                            </Card>
                        </Can>
                    </Cell>
                </Grid>
                <ConfirmResetIVR
                    showDialog={resetIVRConfirmVisible}
                    onCloseHandler={this.toggleResetIVRConfirm}
                    confirmResetHandler={this.confirmResetIVR}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    callTrackingEnabled: state.pharmacy.callTrackingEnabled,
    onlyCallTrackingEnabled: state.pharmacy.callTrackingEnabled && !state.pharmacy.ivrEnabled,
    autoAttendant: state.pharmacy.autoAttendant,
    sendNpeInterestLoading: state.reports.sendNpeInterestLoading,
    mainGreeting: state.settings.mainGreeting,
    flags: state.launchDarkly,
});

const mapDispatchToProps = {
    fetchPhoneMenuSettings,
    saveGreeting,
    fetchMainGreeting,
    startIvrPhoneUpdate,
    resetIVR,
    updateIvrPhone,
    displayToast,
};

export const phoneSystemMainConnect = connect(mapStateToProps, mapDispatchToProps)(PhoneSystemMain);

export default () => (
    <Switch>
        <Route exact path="/settings/phone-system" component={phoneSystemMainConnect} />
        <Route path="/settings/phone-system/main-menu" component={AAMainMenu} />
        <Route path="/settings/phone-system/main-greeting" component={AAMainGreeting} />
        <Route path="/settings/phone-system/languages" component={Languages} />

        <Route path="/settings/phone-system/ivr-main-greeting" component={IVRMainGreeting} />
        <Route path="/settings/phone-system/phone-menu" component={PhoneMenu} />
        <Route path="/settings/phone-system/after-hour-transfer" component={AfterHourTransfer} />
        <Route path="/settings/phone-system/phone-transfer" component={PhoneTransfer} />
        <Route path="/settings/phone-system/refill-menu" component={PhoneMenu} />
        <Route path="/settings/phone-system/doctors-line" component={DoctorsLine} />
        <Route path="/settings/phone-system/general-line" component={GeneralLine} />
        <Route path="/settings/phone-system/pharmacy-hours" component={PharmacyHours} />
        <Route path="/settings/phone-system/new-patient-line" component={NewPatientLine} />
    </Switch>
);
