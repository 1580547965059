import { Tooltip } from '@material-ui/core';
import React from 'react';

export default function NeedleIcon({ size = 24, color = '#00B18F', title = '' }) {
    return (
        <span
            className="vacation-icon"
            style={{
                display: 'inline-flex',
                alignItems: 'center',
            }}
        >
            <Tooltip title={title}>
                <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.9376 18.975L12.1626 17.2125L13.9376 15.4375L15.7001 17.2125L17.4626 15.4375L15.7001 13.675L17.4626 11.9125L19.2376 13.675L21.0001 11.9125L17.4626 8.375L8.6251 17.2125L12.1626 20.75L13.9376 18.975V18.975ZM3.8501 23.75L7.7501 19.8625L5.1001 17.2125L17.4626 4.8375L20.1251 7.5L21.8751 5.725L20.1251 3.95L21.8751 2.1875L27.1876 7.5L25.4251 9.25L23.6501 7.5L21.8751 9.25L24.5376 11.9125L12.1626 24.275L9.5126 21.625L3.8501 27.3V23.75V23.75Z"
                        fill={color}
                    />
                </svg>
            </Tooltip>
        </span>
    );
}
