import './PhonePreview.sass';
import React, { useRef, useState, useEffect } from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SendIcon from '@material-ui/icons/Send';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import { renderTemplateText } from '../Connect/ConnectCard';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, IconButton, Portal, Tooltip } from '@material-ui/core';
import moment from 'moment';
import {
    MinimizedSchedulerForm,
    MaximizedSchedulerForm,
    NotInterestedSchedulerFormPopup,
} from '@digitalpharmacist/messaging-forms';
import { getHowToScheduleConfigFromInboxConversationTemplate } from './schedulingUtil';
import CloseIcon from '@material-ui/icons/Close';
import { isYesNoQuestionAssessment } from '../ReviewBulkMessagePane';
import { InlineYesNoQuestionPrompt } from '@digitalpharmacist/messaging-forms';
import { SimulatedAssessment } from '../ReviewBulkMessagePane';
import { connect } from 'react-redux';
import { getAssessmentId } from '../ReviewBulkMessagePane';
import { isSimpleSchedulingMessage } from '../ReviewBulkMessagePane';

function PhonePreview({
    width,
    height,
    template,
    getMessageText,
    fieldKeys,
    focusedMessage,
    auth,
    pharmacy,
    slots,
    isScrollable = false,
    assessment,
}) {
    const screenRef = useRef();
    const focusedBubbleRef = useRef();
    const [messageBubblesStyle, setMessageBubblesStyle] = useState();
    const [appointmentView, setAppointmentView] = useState('minimized');
    const [assessmentInlineYesNoAnswer, setAssessmentInlineYesNoAnswer] = useState(null);

    const focusedIndex = _.findIndex(fieldKeys, (key) => key === focusedMessage);

    useEffect(() => {
        setMessageBubblesStyle({
            transform: `translateY(${(_.get(focusedBubbleRef, 'current.offsetTop') - 5) * -1}px)`,
        });
        setAppointmentView('minimized');
    }, [focusedMessage]);

    useEffect(() => {
        setTimeout(() => {
            const fullscreenDom = document.body.querySelector('.phone-preview-fullscreen');
            if (!_.isNil(fullscreenDom)) {
                const { width, height, top, left } = screenRef.current.getBoundingClientRect();

                fullscreenDom.style.width = `${width}px`;
                fullscreenDom.style.height = `${height}px`;
                fullscreenDom.style.top = `${top}px`;
                fullscreenDom.style.left = `${left}px`;
                fullscreenDom.style.display = 'block';
            }
        }, 50);
    }, [appointmentView]);

    const config = getHowToScheduleConfigFromInboxConversationTemplate(template);

    const getMessageSuffix = (key) => {
        switch (key) {
            case 'message':
                if (isSimpleSchedulingMessage(template)) {
                    return (
                        <div>
                            <Tooltip title="Clicking this button will open the appointment scheduler on the patient's mobile device.">
                                <Button fullWidth variant="contained" color="primary" style={{ padding: '6px 0px' }}>
                                    {_.get(template, 'message_content.action_title')}
                                </Button>
                            </Tooltip>
                        </div>
                    );
                }
                return null;

            case 'scheduled_appointment_text':
                if (!isSimpleSchedulingMessage(template)) {
                    return (
                        <div>
                            <Button fullWidth className="message-form-button">
                                I Am Uninsured
                            </Button>
                        </div>
                    );
                }
                return null;

            case 'appointments_available_text':
                return (
                    <div style={{ height: '144px' }}>
                        <DemoAppointmentScheduler
                            template={template}
                            setView={setAppointmentView}
                            view={appointmentView}
                            isThirdPartyScheduler={_.get(config, 'scheduling_type') === '3rd-party'}
                            thirdPartySchedulerUrl={_.get(config, 'third_party_scheduling_link')}
                            slots={slots}
                        />
                    </div>
                );

            case 'hour_reminder_text':
                return (
                    <div>
                        <Button fullWidth className="message-form-button">
                            Check-in
                        </Button>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="phone-preview" style={{ width: `${width}px`, height: `${height}px` }}>
            <div className="phone-preview-screen" ref={screenRef}>
                <div className="conversation-subject">{template.subject}</div>

                <div
                    className="main-messaging-pane"
                    style={{ height: `${height - 140}px`, overflowY: isScrollable ? 'scroll' : 'hidden' }}
                >
                    <div className="pharmacy-icon">
                        <LocalPharmacyIcon />
                    </div>

                    <div className="message-bubbles" style={messageBubblesStyle}>
                        {_.map(fieldKeys, (key, i) => {
                            return (
                                <span key={key} ref={key === focusedMessage ? focusedBubbleRef : undefined}>
                                    <div className={classnames({ 'message-bubble': true, visible: i <= focusedIndex })}>
                                        {renderTemplateText(getMessageText(key), auth, pharmacy)}

                                        {isYesNoQuestionAssessment(template) && (
                                            <InlineYesNoQuestionPrompt
                                                value={assessmentInlineYesNoAnswer}
                                                updateAnswer={(a) => setAssessmentInlineYesNoAnswer(a)}
                                            />
                                        )}
                                    </div>

                                    {!isYesNoQuestionAssessment(template) ||
                                    assessmentInlineYesNoAnswer !== 'yes' ? null : (
                                        <SimulatedAssessment
                                            selectedTemplate={template}
                                            assessment={_.get(
                                                assessment,
                                                `assessmentTemplates.${getAssessmentId(
                                                    template
                                                )}.response.assessment_template`
                                            )}
                                        />
                                    )}
                                    {_.isNil(getMessageSuffix(key)) ? null : (
                                        <div
                                            className={classnames({
                                                'message-bubble': true,
                                                visible: i <= focusedIndex,
                                            })}
                                        >
                                            {getMessageSuffix(key)}
                                        </div>
                                    )}
                                </span>
                            );
                        })}
                    </div>
                </div>

                <div className="bottom-response-area">
                    <span className="camera-and-placeholder">
                        <AddAPhotoIcon />
                        <span className="placeholder">Type your message...</span>
                    </span>
                    <SendIcon />
                </div>
            </div>
        </div>
    );
}

function DemoAppointmentScheduler({ template, setView, view, isThirdPartyScheduler, thirdPartySchedulerUrl, slots }) {
    const props = arguments[0];
    const fullscreenRef = useRef();
    const [chosenDatetime, setChosenDatetime] = useState(null);

    const showNotInterested = _.get(template, 'message_content.messaging_form.has_waitlist', false);
    const notInterestedText = _.get(template, 'message_content.messaging_form.notInterestedText');
    const appointmentLabel = _.get(template, 'message_content.messaging_form.appointment_label');

    if (view === 'minimized' || (isThirdPartyScheduler && view === 'maximized')) {
        return (
            <span>
                <MinimizedSchedulerForm {...props} showNotInterested={showNotInterested} setView={setView} />
                {view !== 'maximized' ? null : (
                    <Portal>
                        <div ref={fullscreenRef} className="phone-preview-fullscreen">
                            <div className="open-link-popup">
                                <div className="heading">
                                    <div className="title">Scheduling Tool Page</div>
                                    <IconButton onClick={() => setView('minimized')}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>

                                <div className="main-message">
                                    <div>
                                        The 3rd party scheduling tool that you provided a link to:
                                        <Tooltip title={thirdPartySchedulerUrl}>
                                            <div className="link">{thirdPartySchedulerUrl}</div>
                                        </Tooltip>
                                        will now be visible to the patient.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal>
                )}
            </span>
        );
    }

    if (view === 'not-interested') {
        return (
            <Portal>
                <div ref={fullscreenRef} className="phone-preview-fullscreen">
                    <NotInterestedSchedulerFormPopup
                        {...props}
                        setView={setView}
                        notInterestedText={notInterestedText}
                        setNotInterested={() => {}}
                    />
                </div>
            </Portal>
        );
    }

    if (view === 'maximized') {
        const slotsToRender = {};

        if (!_.isEmpty(slots)) {
            Object.assign(slotsToRender, slots);
        } else {
            const starting = moment();

            while (!starting.isAfter(moment().add(6, 'days'))) {
                const times = {};

                _.each(_.times(8 * 4), (i) => {
                    times[
                        moment('09:00:00', 'HH:mm:ss')
                            .add(i * 15, 'minutes')
                            .format('HH:mm:ss')
                    ] = { capacity: 1 };
                });
                slotsToRender[starting.format('YYYY-MM-DD')] = times;

                starting.add(1, 'day');
            }
        }

        return (
            <Portal>
                <div ref={fullscreenRef} className="phone-preview-fullscreen">
                    <MaximizedSchedulerForm
                        {...props}
                        setView={setView}
                        calendar={{ timezone: moment.tz.guess() }}
                        appointmentLabel={appointmentLabel}
                        availableSlots={{ slots: slotsToRender }}
                        setChosenDatetime={setChosenDatetime}
                        chosenDatetime={chosenDatetime}
                    />
                </div>
            </Portal>
        );
    }
}
DemoAppointmentScheduler.defaultProps = {
    primaryColor: '#00B18F',
    secondaryColor: '#DF4851',
    primaryFgColor: '#fff',
    secondaryFgColor: '#fff',
    onNotInterested: () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ success: true });
            }, 500);
        });
    },
    onScheduleAppointment: () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ success: true });
            }, 500);
        });
    },
    getPreviouslySaidNotInterested: () => false,
    getPreviouslyScheduledAppointment: () => {
        return null;
    },
};

const bindActionsToDispatch = {};
function mapStateToProps(state) {
    const { auth, patientData, assessment } = state;
    return {
        auth,
        // We must send this along even if it is not being used since it will trigger phone-type-lookup re-rendering
        patientData,
        assessment,
    };
}
export default connect(mapStateToProps, bindActionsToDispatch)(PhonePreview);
