import _ from 'lodash';
import Config from 'config';
import { get } from 'redux/actionCreators/rest';

import { postRequest, putRequest } from './Requests';

export const getPackageAttributes = (packageID, attributeName) => {
    const url = `${Config.config2_url}/package/${packageID}/packageAttr`;
    const args = {
        params: {
            numRows: 35,
        },
    };

    return get(url, args).then(({ result }) => {
        return attributeName ? _.find(result, (attribute) => attribute.name === attributeName) : result;
    });
};

export const addCustPackageAttribute = (cust, payload, showSuccessFailure = true, showInitToast = true) => (
    dispatch
) => {
    const url = `${Config.config2_url}/cust/${cust.id}/custPackage/${cust.packageID}/custPackageAttr`;
    const toastMessages = {
        initMessage: `Activating ${payload.title}...`,
        successMessage: `${payload.title} Activated!.`,
        errorMessage: `Failed To Activate ${payload.title}!`,
    };
    delete payload.title;

    return postRequest(url, payload, toastMessages, dispatch, showSuccessFailure, showInitToast);
};

export const updateCustPackageAttribute = (cust, payload, showSuccessFailure, showInitToast) => (dispatch) => {
    const url = `${Config.config2_url}/cust/${cust.id}/custPackage/${cust.packageID}/custPackageAttr/${payload.id}`;
    const activate = _.indexOf([1, '1'], payload.value) !== -1 || !_.isEmpty(payload.value);

    const toastMessages = {
        initMessage: `${activate ? 'Activating / Linking' : 'Deactivating/ DeLinking'} ${payload.title}...`,
        successMessage: `${payload.title} ${activate ? 'Activated/ Linked' : 'Deactivated/ DeLinked'}.`,
        errorMessage: `Failed To ${activate ? 'Activate/ Link' : 'Deactivate/ DeLink'} ${payload.title}!`,
    };
    delete payload.title;

    return putRequest(url, payload, toastMessages, dispatch, showSuccessFailure, showInitToast);
};
