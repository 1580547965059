import React, { useState, Fragment } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ItemsCarousel from 'react-items-carousel';
import { Button, CircularProgress } from 'react-md';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

const ImageItem = ({ idx, src, zoomPreview }) => {
    const [imageLoading, setImageLoading] = useState(true);

    const markImageLoaded = () => {
        setImageLoading(false);
    };

    return (
        <Fragment>
            <div className="standard-margin-bottom-10">Variation {idx + 1}</div>
            {imageLoading && (
                <div style={{ width: '223px', height: '226px' }} className="flex-middle flex-center">
                    <CircularProgress id="image-spinner" />
                </div>
            )}
            <img
                src={src}
                alt="Preview"
                onLoad={markImageLoaded}
                onClick={() => zoomPreview(src)}
                className="user-clickable"
                style={{ width: '223px', height: '226px', display: imageLoading ? 'none' : 'block' }}
            />
        </Fragment>
    );
};

const CampaignPreviewDialog = withStyles(styles)(({ classes, showDialog, hideZoomPreview, zoomImageURL }) => (
    <Dialog id="campPreviewZoomDialog" open={showDialog} onClose={hideZoomPreview} maxWidth="sm">
        <DialogTitle className={classes.root}>
            <IconButton aria-label="close" onClick={hideZoomPreview}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <img src={zoomImageURL} alt="Campaign Preview" className="campaing-preview-img" />
        </DialogContent>
    </Dialog>
));

export const AdsPreviews = ({ previews, activeItemIndex, setActiveItemIndex }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [zoomImageURL, setZoomImageURL] = useState('');
    const getNavBtn = (type) => <Button icon className="flex-center flex-middle">{`chevron_${type}`}</Button>;

    const showZoomPreview = (src) => {
        setShowDialog(true);
        setZoomImageURL(src);
    };

    const hideZoomPreview = () => {
        setShowDialog(false);
    };

    const getImages = () => {
        const images = [];
        Object.keys(previews).forEach((campId) => {
            previews[campId].forEach((preview, idx) => {
                images.push(<ImageItem key={idx} idx={idx} src={preview} zoomPreview={showZoomPreview} />);
            });
        });
        return images;
    };

    return (
        Object.keys(previews).length > 0 && (
            <Fragment>
                <div style={{ padding: '0 60px', margin: '0 auto' }}>
                    <ItemsCarousel
                        infiniteLoop={false}
                        gutter={12}
                        activePosition={'left'}
                        chevronWidth={60}
                        disableSwipe={false}
                        alwaysShowChevrons={false}
                        numberOfCards={3}
                        slidesToScroll={1}
                        outsideChevron={true}
                        showSlither={false}
                        firstAndLastGutter={false}
                        activeItemIndex={activeItemIndex}
                        requestToChangeActive={setActiveItemIndex}
                        leftChevron={getNavBtn('left')}
                        rightChevron={getNavBtn('right')}
                    >
                        {getImages()}
                    </ItemsCarousel>
                </div>
                <CampaignPreviewDialog
                    showDialog={showDialog}
                    hideZoomPreview={hideZoomPreview}
                    zoomImageURL={zoomImageURL}
                />
            </Fragment>
        )
    );
};
