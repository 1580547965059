import React, { Component } from 'react';
import { string, number, bool, func } from 'prop-types';
import { MicrophoneRecorder } from '../libs/MicrophoneRecorder';
import AudioContext from '../libs/AudioContext';
import Visualizer from '../libs/Visualizer';

export default class ReactMicPlus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analyser: null,
            microphoneRecorder: null,
            canvas: null,
            canvasCtx: null,
        };
    }

    componentDidMount() {
        const { onStop, onStart, onData, audioBitsPerSecond, mimeType } = this.props;
        const { visualizer } = this.refs;
        const canvas = visualizer;
        const canvasCtx = canvas.getContext('2d');
        const options = {
            audioBitsPerSecond: audioBitsPerSecond,
            mimeType: mimeType,
        };

        AudioContext.setAudioContext();
        AudioContext.setAnalyser();

        const analyser = AudioContext.getAnalyser();

        this.setState(
            {
                analyser: analyser,
                microphoneRecorder: new MicrophoneRecorder(onStart, onStop, onData, options),
                canvas: canvas,
                canvasCtx: canvasCtx,
            },
            () => {
                this.visualize(false);
            }
        );
    }

    visualize = (record) => {
        const { backgroundColor, strokeColor, width, height, visualSetting, nonstop, duration } = this.props;
        const { canvas, canvasCtx, analyser } = this.state;

        Visualizer.shouldDraw(record);

        if (visualSetting === 'sinewave') {
            Visualizer.visualizeSineWave(analyser, canvasCtx, canvas, width, height, backgroundColor, strokeColor);
        } else if (visualSetting === 'frequencyBars') {
            Visualizer.visualizeFrequencyBars(analyser, canvasCtx, canvas, width, height, backgroundColor, strokeColor);
        } else if (visualSetting === 'spectrogram') {
            Visualizer.visualizeSpectrogram(
                analyser,
                canvasCtx,
                canvas,
                width,
                height,
                backgroundColor,
                strokeColor,
                nonstop,
                duration
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        const { record, onStop } = this.props;
        const { microphoneRecorder } = this.state;

        if (record && !prevProps.record && microphoneRecorder) {
            microphoneRecorder.startRecording();
            this.visualize(true);
        } else if (!record && prevProps.record && microphoneRecorder) {
            microphoneRecorder.stopRecording(onStop);
            Visualizer.shouldDraw(false);
        }
    };

    render() {
        const { width, height } = this.props;

        return <canvas ref="visualizer" height={height} width={width} className={this.props.className}></canvas>;
    }
}

ReactMicPlus.propTypes = {
    backgroundColor: string,
    strokeColor: string,
    className: string,
    audioBitsPerSecond: number,
    mimeType: string,
    height: number,
    record: bool.isRequired,
    onStop: func,
    onData: func,
};

ReactMicPlus.defaultProps = {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    strokeColor: '#000000',
    className: 'visualizer',
    audioBitsPerSecond: 128000,
    mimeType: 'audio/wav',
    record: false,
    width: 640,
    height: 100,
    visualSetting: 'sinewave',
    nonstop: true,
    duration: 5,
};
