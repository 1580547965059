import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import phone from 'phone';

import { stripISDCode } from 'utils/helper';

const PhoneNumberLink = ({ numberToShow, numberToCall }) => {
    if (isMobileOnly) {
        const strippedPhoneNumber = stripISDCode(numberToCall);
        const phoneNumberFormatted = phone(strippedPhoneNumber, '')[0] || strippedPhoneNumber;
        return (
            <a href={'tel:' + phoneNumberFormatted} style={{ color: '#1a73e8' }}>
                {numberToShow}
            </a>
        );
    }

    return numberToShow;
};

PhoneNumberLink.propTypes = {
    numberToShow: PropTypes.string.isRequired,
    numberToCall: PropTypes.string.isRequired,
};

export default PhoneNumberLink;
