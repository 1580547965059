import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { userAction } from '../../redux/Inbox/User';
import { conversationAction } from '../../redux/Inbox/Conversation';
import ActivityList from './ActivityList/ActivityList';
import ContactSection from './ContactSection';
import ConversationMobile from './Conversation/ConversationMobile';
import Snowplow from '../../snowplow';
import NoContactSection from './NoContactSection';
import NoInboxPharmacyView from './NoInboxPharmacyView';
import NotificationDialog from '../Common/NotificationDialog';
import { onboardingSelectors } from 'redux/Inbox/Onboarding/selector';
import OnboardingContent from './Onboarding/OnboardingContent';
import { onboardingActions } from 'redux/Inbox/Onboarding';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import withMediaQuery from 'components/Common/WithMediaQuery';

const ContactWrapper = ({ match, activeUser, user, inboxOnboarding, onboardingIsPending, onBack, history, vmUser }) => (
    <Switch>
        <Route exact path={match.path}>
            <div className="contact-wrapper">
                {activeUser ? (
                    <ContactSection contact={activeUser} onBack={onBack} vmUser={vmUser} />
                ) : (
                    <NoContactSection loadingContacts={user.loading} />
                )}
                {!inboxOnboarding.loading && !onboardingIsPending && (
                    <NotificationDialog
                        dialogMsg="Never miss another patient message with Digital Pharmacist desktop notifications."
                        dialogImage="./desktop_notification_inbox_background.png"
                    />
                )}
            </div>
        </Route>
        <Route path={`${match.path}/onboarding`}>
            <OnboardingContent
                onBack={() => {
                    history.push('/inbox');
                    onBack();
                }}
            />
        </Route>
    </Switch>
);

const ActivityListWrapper = ({ location, user, vmPhone }) => (
    <>
        <ActivityList location={location} vmPhone={vmPhone} />
        {user.userList.length === 0 && <NoContactSection loadingContacts={user.loading} />}
    </>
);

const mobileInboxStepConstants = {
    ACTIVITY_LIST: 'activity_list',
    CONVERSATION_LIST: 'conversation_list',
    CONVERSATION: 'conversation',
};

class Inbox extends React.Component {
    state = {
        activeStep: mobileInboxStepConstants.ACTIVITY_LIST,
    };

    componentDidMount = () => {
        const {
            getUsers,
            resetUserStore,
            basicMessagingEnabled,
            fullPepEnabled,
            isMobile,
            location,
            setSearchQuery,
            setVMUser,
        } = this.props;
        const vmUser = _.get(location.state, 'vmUser', '');

        if (!vmUser) {
            resetUserStore(!isMobile);
            getUsers(!isMobile);
        }

        if (!basicMessagingEnabled && !fullPepEnabled) {
            Snowplow.pageView('Inbox Demo');
        } else {
            Snowplow.pageView('Inbox');
        }

        if (vmUser) {
            setVMUser(vmUser);
            setSearchQuery(`${vmUser.first_name} ${vmUser.last_name}`);
        }

        this.redirectedToOnboarding = false;

        if (isMobile && location.pathname.indexOf('onboarding') > -1) {
            this.setActiveStep(mobileInboxStepConstants.CONVERSATION_LIST);
        }
    };

    componentWillUnmount = () => {
        this.redirectedToOnboarding = false;
    };

    componentDidUpdate = (prevProps) => {
        const {
            auth,
            inboxOnboarding,
            onboardingIsPending,
            resetUserStore,
            basicMessagingEnabled,
            fullPepEnabled,
            history,
            user,
            inboxConversation,
            setActiveUser,
            isMobile,
            location,
        } = this.props;

        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            resetUserStore(true, !isMobile);
            // when changing customer or location we'll want to check for redirect logic again
            this.redirectedToOnboarding = false;

            if (isMobile) {
                history.push('/inbox');
                this.setActiveStep(mobileInboxStepConstants.ACTIVITY_LIST);
            }

            if (!basicMessagingEnabled && !fullPepEnabled) {
                Snowplow.pageView('Inbox Demo');
            } else {
                Snowplow.pageView('Inbox');
            }
        }

        if (
            onboardingIsPending &&
            !this.redirectedToOnboarding &&
            _.get(inboxOnboarding, 'locationOnboarding.message_count') === 0
        ) {
            this.redirectedToOnboarding = true;
            history.replace('/inbox/onboarding');
        }

        if (!isMobile && !user.activeUserId && isMobile !== prevProps.isMobile) {
            // Select the first user in user list
            const firstUser = user.userList[0];
            setActiveUser(firstUser);
        }

        if (isMobile && user.activeUserId && user.activeUserId !== prevProps.user.activeUserId) {
            this.setActiveStep(mobileInboxStepConstants.CONVERSATION_LIST);
        }

        if (
            isMobile &&
            inboxConversation.activeConversationId &&
            inboxConversation.activeConversationId !== prevProps.inboxConversation.activeConversationId
        ) {
            this.setActiveStep(mobileInboxStepConstants.CONVERSATION);
        }

        if (
            isMobile &&
            location.pathname !== prevProps.location.pathname &&
            location.pathname.indexOf('onboarding') > -1
        ) {
            this.setActiveStep(mobileInboxStepConstants.CONVERSATION_LIST);
        }
    };

    onConversationsBack = () => {
        this.props.clearActiveUser();
        this.setActiveStep(mobileInboxStepConstants.ACTIVITY_LIST);
    };

    onConversationBack = () => {
        this.props.clearActiveConversation();
        this.setActiveStep(mobileInboxStepConstants.CONVERSATION_LIST);
    };

    setActiveStep = (step) => {
        this.setState({ activeStep: step });
    };

    render() {
        const {
            user,
            basicMessagingEnabled,
            fullPepEnabled,
            inboxOnboarding,
            match,
            location,
            onboardingIsPending,
            pharmacyLoading,
            activeLocationCountryCode,
            inboxConversation: { activeConversation },
            auth: { selectedLocation, userAccount, selectedCustomer },
            history,
        } = this.props;

        const { activeStep } = this.state;
        const vmPhone = _.get(location.state, 'vmPhone', '');
        const vmUser = _.get(location.state, 'vmUser', '');
        const activeUser = user.activeUserId ? user.userMap[user.activeUserId] : null;

        if (!basicMessagingEnabled && !fullPepEnabled && !pharmacyLoading) {
            return (
                <Grid container alignItems="stretch" className="inbox-grid-wrap inbox-full-height-scroll-wrapper">
                    <NoInboxPharmacyView
                        userId={userAccount.user_id}
                        pharmacyId={selectedCustomer.id}
                        pharmacyName={selectedLocation.name}
                    />
                </Grid>
            );
        }

        const mobileStepperComponents = {
            [mobileInboxStepConstants.ACTIVITY_LIST]: (
                <ActivityListWrapper location={location} user={user} vmPhone={vmPhone} />
            ),
            [mobileInboxStepConstants.CONVERSATION_LIST]: (
                <ContactWrapper
                    match={match}
                    activeUser={activeUser}
                    user={user}
                    inboxOnboarding={inboxOnboarding}
                    onboardingIsPending={onboardingIsPending}
                    onBack={this.onConversationsBack}
                    history={history}
                    vmUser={vmUser}
                />
            ),
            [mobileInboxStepConstants.CONVERSATION]: (
                <ConversationMobile
                    contact={activeUser}
                    active={true}
                    activeLocationCountryCode={activeLocationCountryCode}
                    activeConversation={activeConversation}
                    onBack={this.onConversationBack}
                />
            ),
        };

        return (
            <Grid
                container
                alignItems="stretch"
                className="inbox-grid-wrap inbox-full-height-scroll-wrapper inbox-container"
            >
                <Grid item xs={12} md={3} sm={12} className="inbox-side-grid inbox-full-height-scroll-wrapper">
                    <Hidden smDown>
                        <ActivityList location={location} vmPhone={vmPhone} />
                    </Hidden>
                    <Hidden mdUp>{mobileStepperComponents[activeStep]}</Hidden>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={9} sm={12} className="inbox-main-grid inbox-full-height-scroll-wrapper">
                        <ContactWrapper
                            match={match}
                            activeUser={activeUser}
                            user={user}
                            inboxOnboarding={inboxOnboarding}
                            onboardingIsPending={onboardingIsPending}
                            vmUser={vmUser}
                        ></ContactWrapper>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const { inboxOnboarding, inboxUser, auth, pharmacy, inboxConversation } = state;

    return {
        user: inboxUser,
        auth,
        basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
        inboxOnboarding,
        onboardingIsPending: onboardingSelectors.locationOnboardingIsPendingSelector(state),
        pharmacyLoading: pharmacy.loading,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        inboxConversation,
    };
}

const bindActionsToDispatch = {
    resetUserStore: userAction.resetUserStore,
    getUsers: userAction.getUsers,
    setActiveUser: userAction.setActiveUser,
    setSearchQuery: userAction.setSearchQuery,
    clearActiveUser: userAction.clearActiveUser,
    setVMUser: userAction.setVMUser,
    getInboxLocationOnboarding: onboardingActions.getInboxLocationOnboarding,
    clearActiveConversation: conversationAction.clearActiveConversation,
};

export default withRouter(connect(mapStateToProps, bindActionsToDispatch)(withMediaQuery(Inbox)));
