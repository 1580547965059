import React, { Fragment, useEffect } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Paper,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Chip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { waitlistActions } from 'redux/Waitlist';
import ClipboardClockIcon from '../../Common/ClipboardClockIcon';

const OutlinedPaperTableComponent = (props) => {
    return <Paper {...props} variant="outlined" />;
};

const WaitlistTable = ({
    activeLocationId,
    patient_count,
    isLoading,
    downloadingWaitlistStatusById,
    fetchWaitlists,
    downloadWaitlistCsv,
    websiteIsSpacecrafted,
    websiteUrl,
}) => {
    const WAITLIST_NAME = 'coronavirus';

    useEffect(() => {
        fetchWaitlists(WAITLIST_NAME);
    }, [activeLocationId]);

    if (isLoading) {
        return (
            <div className="waitlist-loading-container">
                <CircularProgress />
            </div>
        );
    }

    let waitlistFormUrl = 'COVID-19 Vaccination Waitlist form';
    if (websiteIsSpacecrafted && websiteUrl) {
        let websiteUrlWithTracking = `${websiteUrl}/coronavirus?utm_source=npe&utm_medium=web&utm_campaign=settings-coronavirus-wailist`;
        waitlistFormUrl = (
            <a href={websiteUrlWithTracking} target="_blank" rel="noopener noreferrer">
                {waitlistFormUrl}
            </a>
        );
    }

    const backgroundClass = 'even-background';
    const isDownloading = downloadingWaitlistStatusById[WAITLIST_NAME];

    return (
        <div className="waitlist-container">
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <ClipboardClockIcon />
                <Typography variant="h6">Patient Waitlists</Typography>
            </div>

            {_.isEqual(patient_count, 0) ? (
                <Typography>You currently have no patient waitlists available.</Typography>
            ) : (
                <Fragment>
                    <Typography style={{ marginBottom: '16px', fontSize: '13px' }}>
                        You have the following patient waitlists available. These waitlists were populated by people
                        filling out the {waitlistFormUrl} on your website when Vaccination Waitlist was enabled or when
                        no active appointment blocks were available when Vaccination Scheduler was enabled.
                    </Typography>

                    <TableContainer component={OutlinedPaperTableComponent}>
                        <Table>
                            <TableBody>
                                <TableRow key={WAITLIST_NAME} className={`${backgroundClass}`}>
                                    <TableCell className="waitlist-name">
                                        <Typography>COVID-19 Vaccine</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip
                                            className="patient-count-chip"
                                            label={`${numeral(patient_count).format('0,0')} patients`}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {/* eslint-disable */}
                                        <a
                                            className="download-csv"
                                            // blob on download method href with download attribute does not name
                                            // the file appropriately with the api request download
                                            href="#"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                if (isDownloading) {
                                                    return;
                                                }
                                                downloadWaitlistCsv(WAITLIST_NAME);
                                            }}
                                        >
                                            {isDownloading ? <CircularProgress size={16} /> : 'download as CSV'}
                                        </a>
                                        {/* eslint-enable */}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        activeLocationId: state.pharmacy.activeLocationId,
        patient_count: state.waitlist.patient_count,
        isLoading: state.waitlist.isLoading,
        downloadingWaitlistStatusById: state.waitlist.downloadingWaitlistStatusById,
        websiteIsSpacecrafted: _.get(state, 'pharmacy.pharmacy.service.Website.websiteIsSpacecrafted', false),
        websiteUrl: _.get(state, 'pharmacy.pharmacy.url.Main.url', ''),
    };
}

const bindActionsToDispatch = {
    fetchWaitlists: waitlistActions.fetchWaitlists,
    downloadWaitlistCsv: waitlistActions.downloadWaitlistCsv,
};

export default connect(mapStateToProps, bindActionsToDispatch)(WaitlistTable);
