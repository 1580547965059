import React, { Component } from 'react';
import { Cell, Grid, Card, CardText } from 'react-md';
import { List } from '@material-ui/core';

import { CardHeader } from '../../Common/index';
import PackageAttributeToggle from '../PackageAttributeToggle';
import { AbilityContext, userActions, restrictedResources } from 'casl';
import goBackService from 'utils/goBackService';

export class GeneralInboxSettings extends Component {
    static contextType = AbilityContext;

    onBack = () => {
        const { history } = this.props;
        goBackService.toInboxSettings(history);
    };

    render() {
        const { selectedLocation, pharmacy } = this.props;
        const {
            generalSettings: { subject: generalSettings },
        } = restrictedResources.inbox;
        const canUpdate = this.context.can(userActions.update, generalSettings);

        return (
            <Grid style={{ position: 'relative' }}>
                <Cell size={12} phoneSize={4}>
                    <Card className="outer-card">
                        <CardHeader
                            title={`General Inbox Settings for ${selectedLocation.name}`}
                            onBack={this.onBack}
                        />
                        <Card className="inner-card">
                            <CardText
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <List>
                                    <Cell
                                        size={12}
                                        phoneSize={4}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <List>
                                            <PackageAttributeToggle
                                                pharmacy={pharmacy}
                                                packageCode="INBX"
                                                attributeName="PatientInitiatedConversationsEnabled"
                                                title="Enable Patient-Initiated Conversations"
                                                description="Allows patients to start their own conversations with the pharmacy"
                                                disabled={!canUpdate}
                                                isLocationLevelAttr={true}
                                                requiresInboxPackageAttributeName="InboxEnabled"
                                            />
                                        </List>
                                    </Cell>
                                </List>
                            </CardText>
                        </Card>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

export default GeneralInboxSettings;
