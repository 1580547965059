import React, { Fragment, PureComponent } from 'react';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import _ from 'lodash';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { newAppFormFields, pharmacyNamePlaceholder } from './Constants';

const Dialog = withStyles({
    paper: {
        width: '600px',
    },
    paperWidthMd: {
        width: '600px !important',
    },
})(DpDialog.Dialog);

export class NewBrandedAppsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { ...this.getDefaultStateConstructed() };
        this.isDirty = () => _.some(this.state, (field) => field.value !== '' && field.value !== false);
        this.resetForm = () => this.setState({ ...this.getDefaultStateConstructed(false) });
        this.handleCheckboxValueChange = (event) => this.handleChange(event, true);
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.pharmacyName, this.props.pharmacyName) ||
            (prevProps.show === false && this.props.show === true)
        ) {
            this.setState({ ...this.getDefaultStateConstructed() });
        }
    }

    getDefaultStateConstructed = (preFilled = true) => {
        const { pharmacyName } = this.props;
        const formFields = _.concat(newAppFormFields.textFields, newAppFormFields.checkboxes);

        return _.mapValues(
            _.keyBy(formFields, (field) => field.config2Key),
            (field) => {
                let value = field.value;

                if (_.isString(value)) {
                    value = preFilled ? _.trim(_.replace(value, pharmacyNamePlaceholder, pharmacyName)) : '';
                }

                return {
                    value,
                    error: '',
                    showPassword: field.showPassword ? field.showPassword : undefined,
                };
            }
        );
    };

    handleChange = (event, isCheckbox) => {
        const key = event.target.name;
        this.setState({
            ...this.state,
            [key]: {
                ...this.state[key],
                value: isCheckbox ? event.target.checked : event.target.value,
                error: '',
            },
        });
    };

    setValidationErrorsIfAny = () => {
        let hasErrors = false;
        const nextState = { ...this.state };

        _.forEach(newAppFormFields.textFields, (field) => {
            const key = field.config2Key;
            const value = _.get(this.state[key], 'value', '');

            if (field.required && _.isEmpty(value)) {
                hasErrors = true;
                nextState[key] = {
                    ...this.state[key],
                    error: 'This is a required field. Please enter the value',
                };
            } else if (!_.isEmpty(value) && field.requiredLength && value.length !== field.requiredLength) {
                hasErrors = true;
                nextState[key] = {
                    ...this.state[key],
                    error: `Required length for ${field.label} is ${field.requiredLength}`,
                };
            }
        });
        if (hasErrors) {
            this.setState(nextState);
        }
        return hasErrors;
    };

    handleSave = () => {
        const hasErrors = this.setValidationErrorsIfAny();
        if (hasErrors) return;

        const payload = _.mapValues(this.state, (field) => field.value);
        this.props.onSave(payload);
        this.resetForm();
    };

    togglePasswordVisibility = (key) => {
        this.setState({
            ...this.state,
            [key]: {
                ...this.state[key],
                showPassword: !this.state[key].showPassword,
            },
        });
    };

    render() {
        const { onClose } = this.props;
        const isDirty = this.isDirty();
        const VisibilityAdornment = ({ config2Key }) => (
            <InputAdornment position="end">
                <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => this.togglePasswordVisibility(config2Key)}
                >
                    {this.state[config2Key].showPassword ? (
                        <Tooltip title="Hide password">
                            <VisibilityOff />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Show password">
                            <Visibility />
                        </Tooltip>
                    )}
                </IconButton>
            </InputAdornment>
        );

        return (
            <Dialog id="new-branded-app-dialog" open={this.props.show} maxWidth="sm" onClose={onClose}>
                <DpDialog.Content id="new-branded-app-content" dividers>
                    <DpDialog.ContentHeader heading="New Branded App" />

                    <div className="flex-vertical-space-between" style={{ marginTop: '24px', height: '700px' }}>
                        {_.map(newAppFormFields.textFields, ({ config2Key: key, autoFocus, required, ...field }) => (
                            <Fragment key={key}>
                                <TextField
                                    type={field.isPassword && !this.state[key].showPassword ? 'password' : 'text'}
                                    id={key}
                                    name={key}
                                    label={field.label}
                                    error={!!this.state[key].error}
                                    value={this.state[key].value}
                                    onChange={this.handleChange}
                                    autoFocus={autoFocus}
                                    fullWidth
                                    required={required}
                                    variant="outlined"
                                    multiline={field.rows ? true : false}
                                    rows={field.rows}
                                    InputProps={
                                        field.isPassword
                                            ? {
                                                  endAdornment: <VisibilityAdornment config2Key={key} />,
                                              }
                                            : {}
                                    }
                                    helperText={this.state[key].error}
                                />
                            </Fragment>
                        ))}
                    </div>

                    <div className="flex-space-between" style={{ marginTop: '24px' }}>
                        {_.map(newAppFormFields.checkboxes, ({ config2Key: key, label }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state[key].value}
                                        onChange={this.handleCheckboxValueChange}
                                        name={key}
                                        color="primary"
                                    />
                                }
                                label={label}
                                key={key}
                            />
                        ))}
                    </div>
                </DpDialog.Content>

                <DpDialog.Actions style={{ padding: '15px' }}>
                    <Button onClick={onClose} color="default">
                        Cancel
                    </Button>

                    <Button onClick={this.resetForm} color="secondary" disabled={!isDirty}>
                        Clear
                    </Button>

                    <Button onClick={this.handleSave} color="primary" variant="contained" disabled={!isDirty}>
                        Save
                    </Button>
                </DpDialog.Actions>
            </Dialog>
        );
    }
}
