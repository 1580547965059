import './PatientsHeaderBar.sass';
import { TextField, InputAdornment, Link, Tooltip, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef } from 'react';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import queryString from 'query-string';
import { updateQueryParameters } from '../../utils/urlHelper';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AddPatientMenu from 'components/Inbox/AddPatientMenu';
import { userAction } from '../../redux/Inbox/User';
import { connect } from 'react-redux';
import { isPatientSelectable, getNextFocusablePatientIndex } from './PatientsTable';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import ClipboardClockIcon from '../Common/ClipboardClockIcon';

function PatientsHeaderBar(props) {
    const {
        patientData,
        location,
        history,
        focusedIndex,
        setFocusedIndex,
        selectedUsers,
        onSelectedChange,
        autoFocusSearchBox,
        onGoBack,
        user,
        auth: { selectedLocation },
        createUser,
        mayOnlySelectMessageablePatients,
        fieldToQuery,
        queryText,
        setShowPatientSelectCsvUpload,
        setShowWaitlistDialog,
        npeCoronavirusWaitlist,
        pharmacy,
        waitlistIdToQuery,
        isWrongListLoaded,
        hideViewWaitlistsButton = false,
    } = props;
    const queryParams = queryString.parse(location.search);
    const searchInputRef = useRef();

    const count = isWrongListLoaded ? undefined : _.get(patientData, 'patientCount');

    const setQuery = _.debounce((q) => {
        history.push(`${location.pathname}${updateQueryParameters(location, 'q', q.trim() === '' ? null : q)}`);
    }, 300);

    const suggestedName = _.get(patientData, 'suggestedName');

    const setQueryText = (text) => {
        history.push(`${location.pathname}${updateQueryParameters(location, 'q', text)}`);
        searchInputRef.current.value = text;
    };

    const setSuggestedNameAsQuery = (e) => {
        e.preventDefault();
        setQueryText(suggestedName);
    };

    const focusedPatientData = _.isObject(patientData.loadedPatients)
        ? _.get(patientData, 'loadedPatients', {})[focusedIndex]
        : null;

    const getFieldLabel = () => {
        switch (fieldToQuery) {
            case 'date_of_birth':
                return 'Date of Birth';
            case 'birthday':
                return 'Birthday';
            default:
                return 'Name';
        }
    };

    const getFieldTitle = () => {
        switch (fieldToQuery) {
            case 'date_of_birth':
                return (
                    <div className="field-title">
                        <div className="sub-line">
                            Matching any patients born on{' '}
                            <strong>{moment(queryText, 'YYYY-MM-DD').format('MMMM Do YYYY')}</strong>
                        </div>
                    </div>
                );
            case 'birthday':
                return (
                    <div className="field-title">
                        <div className="sub-line">
                            Matching patients with a birthday on{' '}
                            <strong>{moment(`2020-${queryText}`, 'YYYY-MM-DD').format('MMMM Do')}</strong>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="field-title">
                        <div className="sub-line">
                            Matching patients with <strong>{queryText}</strong> in their name
                        </div>
                    </div>
                );
        }
    };

    const { pharmacy: currentPharmacy } = pharmacy;
    const selectedPharmacyLocation = _.find(
        currentPharmacy.location,
        (location) => location.id === selectedLocation.id
    );
    const c2CovidWaitlistEnabled = selectedPharmacyLocation.service.Website.CovidWaitlistEnabled;

    return (
        <div className="patients-header-bar">
            <div className="main-line">
                <div className="left">
                    {!_.isFunction(onGoBack) ? null : (
                        <Button className="go-back-button" variant="text" onClick={onGoBack}>
                            <KeyboardBackspaceIcon /> Go Back
                        </Button>
                    )}
                    <TextField
                        autoFocus={autoFocusSearchBox}
                        inputRef={searchInputRef}
                        className="search-text-field"
                        style={{ width: '400px' }}
                        defaultValue={queryParams.q}
                        placeholder={
                            _.isNil(waitlistIdToQuery)
                                ? 'Search for patients by name or birthdate...'
                                : 'Search waitlist...'
                        }
                        variant="outlined"
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={() => {
                            setFocusedIndex(
                                getNextFocusablePatientIndex(mayOnlySelectMessageablePatients, -1, patientData)
                            );
                        }}
                        onBlur={() => {
                            setFocusedIndex(null);
                        }}
                        onKeyUp={(e) => {
                            // If the user presses enter, then we will toggle selection of the focused patient
                            if (e.key === 'Enter' && !_.isNil(focusedPatientData) && patientData.patientCount > 0) {
                                const rowData = focusedPatientData;
                                const clone = _.clone(selectedUsers);
                                if (
                                    !isPatientSelectable(mayOnlySelectMessageablePatients, rowData) ||
                                    _.has(clone, rowData.inbox_user_id)
                                ) {
                                    delete clone[rowData.inbox_user_id];
                                } else {
                                    clone[rowData.inbox_user_id] = rowData;
                                }
                                onSelectedChange(clone, selectedUsers);
                                e.target.select();
                            } else if (e.key === 'ArrowUp') {
                                // navigate up if possible
                                if (_.isFinite(focusedIndex) && focusedIndex > 0) {
                                    const nextIndex = getNextFocusablePatientIndex(
                                        mayOnlySelectMessageablePatients,
                                        focusedIndex,
                                        patientData,
                                        false
                                    );
                                    if (_.isFinite(nextIndex)) {
                                        setFocusedIndex(nextIndex);
                                    }
                                }
                            } else if (e.key === 'ArrowDown') {
                                // navigate down if possible
                                if (_.isFinite(focusedIndex)) {
                                    const nextIndex = getNextFocusablePatientIndex(
                                        mayOnlySelectMessageablePatients,
                                        focusedIndex,
                                        patientData
                                    );
                                    if (_.isFinite(nextIndex) && nextIndex < patientData.patientCount) {
                                        setFocusedIndex(nextIndex);
                                    }
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fontSize: '30px' }} />
                                </InputAdornment>
                            ),
                            endAdornment: _.isNil(queryParams.q) ? null : (
                                <InputAdornment position="end">
                                    <div className="field-and-close">
                                        <Tooltip title={getFieldTitle()}>
                                            <span className="field-being-queried">{getFieldLabel()}</span>
                                        </Tooltip>

                                        <Tooltip title="Clear this query">
                                            <CloseIcon
                                                className="clear-icon"
                                                style={{ fontSize: '20px', color: '#DF4851' }}
                                                onClick={() => {
                                                    setQueryText(null);
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {!_.isFinite(count) ? null : (
                        <span>
                            {numeral(count).format('0,0')} {pluralize('patient', count)}
                        </span>
                    )}
                </div>

                {/* eslint-disable */}
                {!_.isNil(waitlistIdToQuery) ? null : (
                    <Tooltip
                        title={
                            pharmacy.allowPepPatientEditing
                                ? 'Import a CSV or similar file with a list of patients to select them.'
                                : 'Patients must be edited in your PMS'
                        }
                    >
                        <span>
                            <a
                                disabled={!pharmacy.allowPepPatientEditing}
                                href="#"
                                className="import-selection-list-link"
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (!pharmacy.allowPepPatientEditing) return;

                                    setShowPatientSelectCsvUpload(true);
                                }}
                            >
                                Select patients from a file
                            </a>
                        </span>
                    </Tooltip>
                )}
                {/* eslint-enable */}

                {!hideViewWaitlistsButton &&
                    _.isNil(waitlistIdToQuery) &&
                    npeCoronavirusWaitlist &&
                    c2CovidWaitlistEnabled && (
                        <Button
                            className="view-waitlists-button"
                            variant="outlined"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowWaitlistDialog(true);
                            }}
                        >
                            <ClipboardClockIcon />
                            View Waitlists
                        </Button>
                    )}

                {!_.isNil(waitlistIdToQuery) ? null : (
                    <AddPatientMenu
                        fullButton
                        perms={selectedLocation.perms}
                        sending={user.sending}
                        requestError={user.createUserMessage}
                        createContact={createUser}
                    />
                )}
            </div>
            {_.isNil(suggestedName) || _.toLower(queryParams.q) === _.toLower(suggestedName) ? null : (
                <div className="suggested-name-option">
                    Did you mean{' '}
                    <Link href="#" onClick={setSuggestedNameAsQuery}>
                        {suggestedName}
                    </Link>
                    ?
                </div>
            )}

            {!_.isFinite(focusedIndex) || patientData.patientCount === 0 || patientData.newLoad ? null : (
                <div
                    className="keyboard-selection-instructions"
                    style={{ left: _.isFunction(onGoBack) ? '100px' : null }}
                >
                    {!isPatientSelectable(mayOnlySelectMessageablePatients, focusedPatientData) ? (
                        <span className="unmessageable-notice">
                            <WarningIcon className="alert-icon" />{' '}
                            <span>
                                Cannot message{' '}
                                <span className="name">{_.get(focusedPatientData, 'full_name', '')}</span>
                            </span>
                        </span>
                    ) : (
                        <span className="row-instructions">
                            Press Enter to{' '}
                            {_.has(selectedUsers, _.get(focusedPatientData, 'inbox_user_id')) ? 'deselect' : 'select'}
                            &nbsp;
                            <span className="name">{_.get(focusedPatientData, 'full_name', '')}</span>
                        </span>
                    )}

                    <span className="nav-instructions">
                        <ArrowUpwardIcon style={{ fontSize: '12px' }} /> &{' '}
                        <ArrowDownwardIcon style={{ fontSize: '12px' }} /> keys to navigate
                    </span>
                </div>
            )}
        </div>
    );
}

function mapStateToProps(state) {
    const { inboxUser, auth, pharmacy } = state;
    return {
        user: inboxUser,
        auth,
        npeCoronavirusWaitlist: state.launchDarkly.npeCoronavirusWaitlist,
        pharmacy,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    setActiveUser: (userId) => dispatch(userAction.setActiveUser(userId)),
    searchUsers: (nextPage = false) => dispatch(userAction.searchUsers(nextPage)),
    setSearchQuery: (searchQuery) => dispatch(userAction.setSearchQuery(searchQuery)),
    resetSearchQuery: () => dispatch(userAction.resetSearchFilter()),
    setSearchFilter: (searchFilter) => dispatch(userAction.setSearchFilter(searchFilter)),
    resetSearchFilter: () => dispatch(userAction.resetSearchFilter()),
});

export default connect(mapStateToProps, bindActionsToDispatch)(PatientsHeaderBar);
