import React, { PureComponent } from 'react';
import { Card, Cell, CardText, Grid, Button, CircularProgress } from 'react-md';
import { connect } from 'react-redux';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import { displayToast } from 'redux/actionCreators/Snackbar';
import {
    addNewPatientLineGreeting,
    updateNewPatientGreetingMsg,
    closeNewPatientLineGreetingEditor,
    editNewPatientLineGreeting,
    changeNewPatientLineGreetingType,
    changeNewPatientLineGreetingScript,
} from 'redux/actionCreators/Settings/NewPatientLine';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    saveGreeting,
    fileUpload,
    saveFilename,
} from 'redux/actionCreators/Settings/MainGreeting';
import GreetingEditor from 'components/Common/GreetingEditor';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class NewPatientLine extends PureComponent {
    componentDidMount() {
        const { loadGreetingFiles, fetchNewPatientLineGreeting } = this.props;
        loadGreetingFiles();
        fetchNewPatientLineGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, loadGreetingFiles, fetchNewPatientLineGreeting } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadGreetingFiles();
            fetchNewPatientLineGreeting();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    async onSave({ fileObj, editedFileName, filenameWithExt }) {
        const {
            newNewPatientLineGreeting,
            defaultGreeting,
            loadGreetingFiles,
            fetchNewPatientLineGreeting,
            saveFile,
            saveGreeting,
            fileUpload,
            closeGreetingEditor,
            displayToast,
        } = this.props;

        const NewPatientGreetingPayload = {
            id: defaultGreeting.id,
            new_patient_flow_greeting_id: newNewPatientLineGreeting.new_patient_flow_greeting_id,
        };

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            NewPatientGreetingPayload.new_patient_flow_greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            await saveFile({ name: newFilename, id: newNewPatientLineGreeting.new_patient_flow_greeting_id });
        }

        const saveMsgResp = await saveGreeting({
            newGreeting: { ...NewPatientGreetingPayload },
            type: 'NEW_PATIENT_LINE',
        });
        if (saveMsgResp.err) {
            displayToast({ text: 'New Patient Line Greeting save failed', type: 'error' });
        } else {
            fetchNewPatientLineGreeting();
            closeGreetingEditor();
            displayToast({ text: 'New Patient Line Greeting saved', type: 'success' });
        }
    }

    removeNewPatientLineGreeting = () => {
        const { fetchNewPatientLineGreeting, saveGreeting, displayToast, defaultGreeting } = this.props;
        const newGreeting = { id: defaultGreeting.id, new_patient_flow_greeting_id: '' };
        saveGreeting({ newGreeting, type: 'NEW_PATIENT_LINE' }).then((saveMsgResp) => {
            if (saveMsgResp.err) {
                displayToast({ text: 'New Patient Line Greeting remove failed', type: 'error' });
            } else {
                fetchNewPatientLineGreeting();
                displayToast({ text: 'New Patient Line Greeting removed', type: 'success' });
            }
        });
    };

    render() {
        const {
            addGreeting,
            newNewPatientLineGreeting,
            showNewPatientLineGreetingCreator,
            closeGreetingEditor,
            updateGreetingMessage,
            greetingFiles,
            defaultGreeting,
            loadingMainGreeting,
            editNewPatientLineGreeting,
            displayToast,
            isFilenameSaving,
            newGreeting,
            changeNewPatientLineGreetingType,
            changeNewPatientLineGreetingScript,
            auth: { selectedLocation, selectedCustomer },
        } = this.props;

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });
        const { create, update } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.newPatientVoicemail;

        return (
            <Grid>
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader title="New Patient Voicemail" onBack={this.onBack} />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="New Patient Line Voicemail Prompt " />
                                    <SectionSubTitle text="This is the prompt that first time callers will be greeted with when calling into the IVR" />
                                    {loadingMainGreeting ? (
                                        <CircularProgress id="new-patient-greeting-spinner" />
                                    ) : (
                                        <span className="greetings-list">
                                            <h5 className="sub-title-text">
                                                {' '}
                                                By default the IVR will play the prompt:
                                                <span className="italic-style">
                                                    Thank you for calling the new patient line. After the tone, please
                                                    leave your name, number and any relevant information. When you have
                                                    finished, press any key.
                                                </span>
                                            </h5>

                                            <SectionSubTitle text="You can record your own custom message below to override the default prompt" />

                                            <div className="grid-container table-title">
                                                {defaultGreeting['new_patient_flow_greeting_type'] !== 'text/plain' && (
                                                    <Cell size={1} tabletSize={1} phoneSize={1}>
                                                        &nbsp;
                                                    </Cell>
                                                )}
                                                <Cell
                                                    size={
                                                        defaultGreeting[`new_patient_flow_greeting_type`] ===
                                                        'text/plain'
                                                            ? 4
                                                            : 3
                                                    }
                                                    tabletSize={2}
                                                    phoneSize={1}
                                                >
                                                    Title
                                                </Cell>
                                                <Cell size={1} tabletSize={1} phoneHidden>
                                                    Status
                                                </Cell>
                                                <Cell size={7} tabletSize={2} phoneSize={2}>
                                                    &nbsp;
                                                </Cell>
                                            </div>

                                            {defaultGreeting && defaultGreeting.new_patient_flow_greeting_id && (
                                                <Can I={update} this={caslSubject} passThrough>
                                                    {(updateAllowed) => (
                                                        <span className="main-greeting">
                                                            <Greetings
                                                                className="blue-bordered"
                                                                type="new_patient_flow"
                                                                perms={selectedLocation.perms}
                                                                greetingDetail={defaultGreeting}
                                                                greetingFileList={greetingFileList}
                                                                handleEdit={() =>
                                                                    editNewPatientLineGreeting(defaultGreeting)
                                                                }
                                                                isDeleting={newGreeting.processing}
                                                                handleDelete={this.removeNewPatientLineGreeting}
                                                                isDefault
                                                                hideActions={!updateAllowed}
                                                            />
                                                        </span>
                                                    )}
                                                </Can>
                                            )}

                                            {defaultGreeting && !defaultGreeting.new_patient_flow_greeting_id && (
                                                <div className="grid-container">
                                                    <Cell size={12} className="centered-content no-greeting-msg">
                                                        No greetings configured
                                                    </Cell>
                                                </div>
                                            )}

                                            <Grid>
                                                <Can I={create} this={caslSubject} passThrough>
                                                    {(allowed) => (
                                                        <Cell size={12} className="centered-content button-control">
                                                            {allowed && !defaultGreeting.new_patient_flow_greeting_id && (
                                                                <Button
                                                                    className="add-btn"
                                                                    flat
                                                                    secondary
                                                                    onClick={addGreeting}
                                                                >
                                                                    + ADD A NEW GREETING
                                                                </Button>
                                                            )}
                                                            {!allowed &&
                                                                !defaultGreeting.new_patient_flow_greeting_id && (
                                                                    <div>
                                                                        You don't have permission to configure greeting
                                                                    </div>
                                                                )}
                                                        </Cell>
                                                    )}
                                                </Can>
                                            </Grid>
                                        </span>
                                    )}
                                </CardText>
                            </Card>

                            <GreetingEditor
                                custPerms={selectedCustomer.perms}
                                isSaving={isFilenameSaving || newGreeting.processing}
                                dialogTitle={
                                    newNewPatientLineGreeting.id
                                        ? 'Edit New Patients Line greeting'
                                        : 'Add a New Patients Line greeting'
                                }
                                isVisible={showNewPatientLineGreetingCreator}
                                greetingFiles={greetingFiles}
                                greeting={newNewPatientLineGreeting}
                                greetingId={newNewPatientLineGreeting.new_patient_flow_greeting_id || ''}
                                greetingUrl={newNewPatientLineGreeting.new_patient_flow_greeting_url}
                                greetingType={newNewPatientLineGreeting.new_patient_flow_greeting_type_id}
                                greetingScript={newNewPatientLineGreeting.new_patient_flow_greeting_text}
                                handleChange={(value) => updateGreetingMessage({ ...value, greetingFiles })}
                                closeHandler={() => closeGreetingEditor()}
                                saveHandler={(data) => this.onSave(data)}
                                showToastMessage={(data) => displayToast(data)}
                                changeGreetingType={(type) => changeNewPatientLineGreetingType(type)}
                                changeGreetingScript={(msg) => changeNewPatientLineGreetingScript(msg)}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.newPatientLine,
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchNewPatientLineGreeting: () => dispatch(fetchMainGreeting()),
    addGreeting: () => dispatch(addNewPatientLineGreeting()),
    updateGreetingMessage: (data) => dispatch(updateNewPatientGreetingMsg(data)),
    closeGreetingEditor: () => dispatch(closeNewPatientLineGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    editNewPatientLineGreeting: (data) => dispatch(editNewPatientLineGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changeNewPatientLineGreetingType: (data) => dispatch(changeNewPatientLineGreetingType(data)),
    changeNewPatientLineGreetingScript: (data) => dispatch(changeNewPatientLineGreetingScript(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPatientLine);
