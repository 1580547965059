import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logUtil } from '../../../utils/log';

const getAvailableAssessedAttributes = (pharmacyId, locationId) => {
    return restActionCreators
        .get(
            `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/available-assessed-attributes`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('ERROR', error);
            logUtil.logHttpError(
                `An error occurred on available-assessed-attributes for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getAssessmentTemplate = (assessmentId) => {
    return restActionCreators
        .get(`${config.apiweb_url}${api.INBOX_BASE}/assessments/${assessmentId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log('ERROR', error);
            logUtil.logHttpError(
                `An error occurred on get-assessment-template for assessmentId: ${assessmentId}`,
                error
            );
            return Promise.reject(error);
        });
};

const assessmentService = {
    getAvailableAssessedAttributes,
    getAssessmentTemplate,
};
export default assessmentService;
