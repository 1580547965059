import React from 'react';
import { Button, Icon } from '@material-ui/core';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            className={isMarkActive(editor, format) ? 'active' : ''}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export default MarkButton;
