import React, { Component, Fragment } from 'react';
import { Cell, Grid } from 'react-md';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

const barOptions = {
    scales: {
        xAxes: [
            {
                ticks: {
                    padding: 0,
                    fontColor: '#fff',
                },
                gridLines: {
                    drawTicks: false,
                    drawBorder: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    padding: 10,
                },
                gridLines: {
                    drawTicks: false,
                    drawOnChartArea: false,
                },
            },
        ],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
};

export const DemographicLegends = () => (
    <Fragment>
        <Cell size={6} className="legends flex-middle flex-right" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="female flex-space-between flex-middle">
                <div className="badge"></div>Female
            </div>
            <div className="male flex-space-between flex-middle standard-margin-left-10">
                <div className="badge"></div>Male
            </div>
        </Cell>
        <Cell size={6} style={{ marginTop: 0, marginBottom: 0 }}></Cell>
    </Fragment>
);

export class DemographicChart extends Component {
    getMaleChartData = () => {
        const { barChartData } = this.props;
        const sortedValues = Object.keys(barChartData.male)
            .sort()
            .map((x) => {
                return { label: x, value: barChartData.male[x] };
            });

        return {
            labels: sortedValues.map((x) => getLabel(x.label)),
            datasets: [
                {
                    backgroundColor: '#3793ff',
                    borderColor: '#3793ff',
                    borderWidth: 0,
                    hoverBackgroundColor: '#3793ff',
                    hoverBorderColor: '#3793ff',
                    data: sortedValues.map((x) => x.value),
                },
            ],
        };
    };

    getFemaleChartData = () => {
        const { barChartData } = this.props;
        const sortedValues = Object.keys(barChartData.female)
            .sort()
            .map((x) => {
                return { label: x, value: barChartData.female[x] };
            });

        return {
            labels: sortedValues.map((x) => getLabel(x.label)),
            datasets: [
                {
                    backgroundColor: '#ffd0cb',
                    borderColor: '#ffd0cb',
                    borderWidth: 0,
                    hoverBackgroundColor: '#ffd0cb',
                    hoverBorderColor: '#ffd0cb',
                    data: sortedValues.map((x) => x.value),
                },
            ],
        };
    };

    render() {
        const femaleBarOptions = _.cloneDeep(barOptions);
        const maleBarOptions = _.cloneDeep(barOptions);
        if (!isMobile) {
            femaleBarOptions.scales.xAxes[0].ticks.reverse = true;
        }

        return (
            <Fragment>
                <div className="grid-container">
                    <Cell size={6} offset={6}>
                        Age
                    </Cell>
                </div>
                <Grid noSpacing>
                    <Cell size={6}>
                        <HorizontalBar data={this.getFemaleChartData()} options={femaleBarOptions} />
                    </Cell>
                    <Cell size={6}>
                        <HorizontalBar data={this.getMaleChartData()} options={maleBarOptions} />
                    </Cell>
                </Grid>
                <div className="grid-container">
                    <Cell size={6} offset={6} style={{ marginTop: 0 }}>
                        Clicks
                    </Cell>
                </div>
            </Fragment>
        );
    }
}

const labelMapping = {
    age_range_18_24: '18-24',
    age_range_25_34: '25-34',
    age_range_35_44: '35-44',
    age_range_45_54: '45-54',
    age_range_55_64: '55-64',
    age_range_65_plus: '65+',
};

const getLabel = (rawLabel) => {
    return labelMapping[rawLabel] ? labelMapping[rawLabel] : rawLabel;
};
