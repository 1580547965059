import React, { Component, Fragment } from 'react';
import { Card, CardText } from 'react-md';
import _ from 'lodash';

import TabularData from 'components/Common/TabularData';
import EmptyCard from './EmptyCard';
import { mapTabularDataWithObject, sortByKeys } from './util.js';
import ReportTitle from 'components/Report/Common/ReportTitle';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';

const ATTRIBUTES = ['Review Requests', 'Requests Clicked', 'Reviews Left'];

const TITLES = ['7 Days', '30 Days', 'All Time'];

const SORTED_KEYS = ['review_requests', 'requests_clicked', 'reviews_left'];

const DataCard = ({ data, showLoader, showNote, tooltipText }) => (
    <Card className="reportCard">
        <ReportTitle title="Reputation Management" className="cardTitle" tooltipText={tooltipText} />
        <CardText className={showLoader ? 'flex-middle flex-grow-1' : 'text-align-center'}>
            {!showLoader ? (
                <Fragment>
                    {showNote && (
                        <div className="info-note standard-padding-left-10">Data from Feb 22nd to present.</div>
                    )}
                    <TabularData data={data} />
                </Fragment>
            ) : (
                <TabularTypePlaceholder legendsCount={3} />
            )}
        </CardText>
    </Card>
);

export default class ReputationManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: {},
        };
    }

    init() {
        const { data } = this.props;
        const is7DaysDataPresent = Boolean(_.keys(data.last7Days).length);
        const is30DaysDataPresent = Boolean(_.keys(data.last30Days).length);
        const isAllTimeDataPresent = Boolean(_.keys(data.allTime).length);

        if (is7DaysDataPresent && is30DaysDataPresent && isAllTimeDataPresent) {
            const mapData = mapTabularDataWithObject(
                {
                    data: {
                        data: [
                            sortByKeys(data.last7Days, SORTED_KEYS),
                            sortByKeys(data.last30Days, SORTED_KEYS),
                            sortByKeys(data.allTime, SORTED_KEYS),
                        ],
                    },
                },
                ATTRIBUTES,
                TITLES
            );
            this.setState({ mapData });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ data: oldData }) {
        const { data } = this.props;
        if (!_.isEqual(data, oldData)) {
            this.setState(
                {
                    mapData: {},
                },
                () => this.init()
            );
        }
    }

    render() {
        const { enabled, showNote, tooltipText } = this.props;
        const { mapData } = this.state;
        const showLoader = Object.keys(mapData).length === 0;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Reputation Management"
                    imgPath="/reports/reputation_management.png"
                    helperText="You don’t have Reputation Management yet."
                    appType="reputationManagement"
                    tooltipText={tooltipText}
                />
            );
        }
        return <DataCard data={mapData} showLoader={showLoader} showNote={showNote} tooltipText={tooltipText} />;
    }
}
