import Actions from 'redux/actions';

const initialState = {
    readVoicemails: [],
    unreadVoicemails: [],
    loadingReadVoicemails: false,
    loadingUnreadVoicemails: false,
    silentLoadingReadVoicemails: false,
    silentLoadingUnreadVoicemails: false,
    unreadTotalMails: 0,
    readTotalMails: 0,
    fetchingAudio: false,
    sortData: {
        read: {
            callDateUTC: 'desc',
        },
        unread: {
            callDateUTC: 'desc',
        },
    },
};

const sortVoicemail = (state, payload) => {
    let { unreadVoicemails, readVoicemails, sortData } = { ...state };

    unreadVoicemails = payload.unreadVoicemails ? payload.unreadVoicemails : unreadVoicemails;
    readVoicemails = payload.readVoicemails ? payload.readVoicemails : readVoicemails;

    const voicemails = payload.type === 'read' ? readVoicemails : unreadVoicemails;
    const column = payload.column;

    if (column === 'phone') {
        voicemails.sort((a, b) => (payload.order === 'asc' ? a.phone - b.phone : b.phone - a.phone));
    } else if (column === 'msgType') {
        voicemails.sort((a, b) => {
            const aResult = a.refillNum ? parseInt(a.refillNum) : a.messageType;
            const bResult = b.refillNum ? parseInt(b.refillNum) : b.messageType;
            return payload.order === 'asc' ? aResult - bResult : bResult - aResult;
        });
    } else if (column === 'callDateUTC') {
        voicemails.sort((a, b) =>
            payload.order === 'asc'
                ? new Date(a.callDateUTC) - new Date(b.callDateUTC)
                : new Date(b.callDateUTC) - new Date(a.callDateUTC)
        );
    }

    unreadVoicemails = payload.type === 'unread' ? voicemails : unreadVoicemails;
    readVoicemails = payload.type === 'read' ? voicemails : readVoicemails;
    sortData[payload.type] = {};
    sortData[payload.type][payload.column] = payload.order;

    return { ...state, unreadVoicemails, readVoicemails, sortData };
};

const addNewVoicemail = (state, payload) => {
    let { unreadVoicemails, readVoicemails } = { ...state };
    const newVoicemail = payload.data;

    if (payload.type === 'unread') {
        unreadVoicemails = [newVoicemail, ...unreadVoicemails];
    } else {
        readVoicemails = [newVoicemail, ...readVoicemails];
    }

    return { ...state, unreadVoicemails, readVoicemails };
};

const fetchingVoicemailAudio = (state, payload, processing) => {
    const { unreadVoicemails, readVoicemails } = { ...state };
    const voicemails = payload.type === 'unread' ? unreadVoicemails : readVoicemails;
    const vociemailIdx = voicemails.findIndex((voicemail) => voicemail.id === payload.vmId);

    if (payload.type === 'unread') {
        unreadVoicemails[vociemailIdx].fetchingAudio = processing;
    } else {
        readVoicemails[vociemailIdx].fetchingAudio = processing;
    }

    return { ...state, unreadVoicemails, readVoicemails };
};

const updateVoicemailAudio = (state, payload) => {
    const { unreadVoicemails, readVoicemails } = { ...state };
    const voicemails = payload.type === 'unread' ? unreadVoicemails : readVoicemails;
    const vociemailIdx = voicemails.findIndex((voicemail) => voicemail.id === payload.vmId);

    if (payload.type === 'unread') {
        unreadVoicemails[vociemailIdx].audioUrl = URL.createObjectURL(payload.data);
        unreadVoicemails[vociemailIdx].audioBlob = payload.data;
        unreadVoicemails[vociemailIdx].fetchingAudio = false;
    } else {
        readVoicemails[vociemailIdx].audioUrl = URL.createObjectURL(payload.data);
        readVoicemails[vociemailIdx].audioBlob = payload.data;
        readVoicemails[vociemailIdx].fetchingAudio = false;
    }

    return { ...state, unreadVoicemails, readVoicemails };
};

const updateVoicemailRead = (state, payload) => {
    let { unreadVoicemails, readVoicemails, unreadTotalMails, readTotalMails, sortData } = { ...state };
    const vociemailIdx = unreadVoicemails.findIndex((voicemail) => voicemail.id === payload.vmId);
    unreadVoicemails[vociemailIdx].isProcessing = false;
    unreadVoicemails[vociemailIdx].isRead = 1;

    readVoicemails = readVoicemails.concat(unreadVoicemails.splice(vociemailIdx, 1));
    unreadTotalMails -= 1;
    readTotalMails += 1;
    const column = Object.keys(sortData['read'])[0];
    const order = sortData['read'][column];
    const sortedVoicemails = sortVoicemail(state, { order, type: 'read', column, readVoicemails });

    return {
        ...state,
        unreadVoicemails,
        readVoicemails: sortedVoicemails.readVoicemails,
        unreadTotalMails,
        readTotalMails,
    };
};

const updateVoicemailUnread = (state, payload) => {
    let { unreadVoicemails, readVoicemails, unreadTotalMails, readTotalMails, sortData } = { ...state };
    const vociemailIdx = readVoicemails.findIndex((voicemail) => voicemail.id === payload.vmId);
    readVoicemails[vociemailIdx].isProcessing = false;
    readVoicemails[vociemailIdx].isRead = 0;

    unreadVoicemails = unreadVoicemails.concat(readVoicemails.splice(vociemailIdx, 1));
    readTotalMails -= 1;
    unreadTotalMails += 1;
    const column = Object.keys(sortData['unread'])[0];
    const order = sortData['unread'][column];
    const sortedVoicemails = sortVoicemail(state, { order, type: 'unread', column, unreadVoicemails });

    return {
        ...state,
        unreadVoicemails: sortedVoicemails.unreadVoicemails,
        readVoicemails,
        unreadTotalMails,
        readTotalMails,
    };
};

const updateVoicemailReading = (state, payload, processing) => {
    const { unreadVoicemails } = { ...state };
    const vociemailIdx = unreadVoicemails.findIndex((voicemail) => voicemail.id === payload.vmId);
    unreadVoicemails[vociemailIdx].isProcessing = processing;
    return { ...state, unreadVoicemails };
};

const updateVoicemailUnreading = (state, payload, processing) => {
    const { readVoicemails } = { ...state };
    const vociemailIdx = readVoicemails.findIndex((voicemail) => voicemail.id === payload.vmId);
    readVoicemails[vociemailIdx].isProcessing = processing;
    return { ...state, readVoicemails };
};

const setReadMails = (state, payload) => {
    let { readVoicemails, readTotalMails, sortData } = { ...state };
    readVoicemails = payload.loadMore
        ? [...new Map(readVoicemails.concat(payload.data.result).map((item) => [item['id'], item])).values()]
        : payload.data.result;

    if (payload.data.totalCount !== undefined) {
        readTotalMails = payload.data.totalCount;
    }

    const column = Object.keys(sortData['read'])[0];
    const order = sortData['read'][column];
    const sortedVoicemails = sortVoicemail(state, { order, type: 'read', column, readVoicemails });
    readVoicemails = sortedVoicemails.readVoicemails;

    return {
        ...state,
        readVoicemails,
        readTotalMails,
        loadingReadVoicemails: false,
        silentLoadingReadVoicemails: false,
    };
};

const setUnreadMails = (state, payload) => {
    let { unreadVoicemails, unreadTotalMails, sortData } = { ...state };
    unreadVoicemails = payload.loadMore
        ? [...new Map(unreadVoicemails.concat(payload.data.result).map((item) => [item['id'], item])).values()]
        : payload.data.result;

    if (payload.data.totalCount !== undefined) {
        unreadTotalMails = payload.data.totalCount;
    }

    const column = Object.keys(sortData['unread'])[0];
    const order = sortData['unread'][column];
    const sortedVoicemails = sortVoicemail(state, { order, type: 'unread', column, unreadVoicemails });
    unreadVoicemails = sortedVoicemails.unreadVoicemails;

    return {
        ...state,
        unreadVoicemails,
        unreadTotalMails,
        loadingUnreadVoicemails: false,
        silentLoadingUnreadVoicemails: false,
    };
};

export default function VoicemailReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_READ_VOICEMAIL_STARTED:
            return {
                ...state,
                readTotalMails: payload.loadMore ? state.readTotalMails : 0,
                readVoicemails: payload.loadMore ? state.readVoicemails : [],
                loadingReadVoicemails: !payload.loadMore,
                silentLoadingReadVoicemails: payload.loadMore,
            };
        case Actions.FETCH_UNREAD_VOICEMAIL_STARTED:
            return {
                ...state,
                unreadTotalMails: payload.loadMore ? state.unreadTotalMails : 0,
                unreadVoicemails: payload.loadMore ? state.unreadVoicemails : [],
                loadingUnreadVoicemails: !payload.loadMore,
                silentLoadingUnreadVoicemails: payload.loadMore,
            };
        case Actions.FETCH_READ_VOICEMAIL_SUCCESS:
            return setReadMails(state, payload);
        case Actions.FETCH_UNREAD_VOICEMAIL_SUCCESS:
            return setUnreadMails(state, payload);
        case Actions.FETCH_READ_VOICEMAIL_FAILED:
            return { ...state, loadingReadVoicemails: false, silentLoadingReadVoicemails: false };
        case Actions.FETCH_UNREAD_VOICEMAIL_FAILED:
            return { ...state, loadingUnreadVoicemails: false, silentLoadingUnreadVoicemails: false };
        case Actions.READ_VOICEMAIL_STARTED:
            return updateVoicemailReading(state, payload, true);
        case Actions.READ_VOICEMAIL_SUCCESS:
            return updateVoicemailRead(state, payload);
        case Actions.READ_VOICEMAIL_FAILED:
            return updateVoicemailReading(state, payload, false);
        case Actions.UNREAD_VOICEMAIL_STARTED:
            return updateVoicemailUnreading(state, payload, true);
        case Actions.UNREAD_VOICEMAIL_SUCCESS:
            return updateVoicemailUnread(state, payload);
        case Actions.UNREAD_VOICEMAIL_FAILED:
            return updateVoicemailUnreading(state, payload, false);
        case Actions.FETCH_VOICEMAIL_AUDIO_STARTED:
            return fetchingVoicemailAudio(state, payload, true);
        case Actions.FETCH_VOICEMAIL_AUDIO_SUCCESS:
            return updateVoicemailAudio(state, payload);
        case Actions.FETCH_VOICEMAIL_AUDIO_FAILED:
            return fetchingVoicemailAudio(state, payload, false);
        case Actions.RESET_VOICEMAIL_COUNT:
            return { ...state, unreadTotalMails: 0, readTotalMails: 0 };
        case Actions.FETCH_VOICEMAIL_DETAIL_SUCCESS:
            return addNewVoicemail(state, payload);
        case Actions.SORT_VOICEMAIL:
            return sortVoicemail(state, payload);
        default:
            return state;
    }
}
