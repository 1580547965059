import './MessageCustomizeAccordion.sass';
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function MessageCustomizeAccordion({ title, value, expanded, onExpandChange, onChange }) {
    return (
        <div className="message-customize-accordion">
            <Accordion expanded={expanded} onChange={onExpandChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
                <AccordionDetails>
                    <TextField
                        className="message-customization-text-field"
                        multiline
                        fullWidth
                        rows={10}
                        variant="outlined"
                        defaultValue={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
