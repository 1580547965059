const subjectTypePrefix = 'Inbox';

export const restrictedInboxResources = {
    inbox: {
        subject: subjectTypePrefix,
        generalSettings: {
            subject: `${subjectTypePrefix} - General Settings`,
            fields: {},
        },
        messages: {
            subject: `${subjectTypePrefix} - Messages`,
            fields: {},
        },
        patient: {
            subject: `${subjectTypePrefix} - Patient`,
            fields: {},
        },
        newConversation: {
            subject: `${subjectTypePrefix} - New Conversation`,
            fields: {},
        },
        messageTemplates: {
            subject: `${subjectTypePrefix} - Message Templates`,
            fields: {},
        },
    },
};
