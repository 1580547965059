import React from 'react';
import { Cell, Grid } from 'react-md';

export default () => (
    <Grid>
        <Cell size={12}>
            <h1> Web Presence Page</h1>
        </Cell>
    </Grid>
);
