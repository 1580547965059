import Actions from 'redux/actions';
import { getFileContent } from 'redux/actionCreators/Settings/MainGreeting';
import { GREETING_TYPE } from 'constants/MainGreeting';

export const addNewPatientLineGreeting = () => ({
    type: Actions.ADD_NEW_PATIENT_LINE_GREETING,
});

export const updateNewPatientGreetingMsg = (payload) => (dispatch) => {
    if (payload.contentType === 'text/plain') {
        dispatch(getFileContent(payload)).then((resp) => {
            dispatch(changeNewPatientLineGreetingScript(resp));
        });
    }

    dispatch({
        type: Actions.UPDATE_NEW_PATIENT_LINE_GREETING,
        payload,
    });
};

export const closeNewPatientLineGreetingEditor = () => ({
    type: Actions.CLOSE_NEW_PATIENT_LINE_GREETING_EDITOR,
});

export const editNewPatientLineGreeting = (payload) => (dispatch) => {
    dispatch({
        type: Actions.EDIT_NEW_PATIENT_LINE_GREETING,
        payload,
    });

    if (payload.new_patient_flow_greeting_type === 'text/plain') {
        dispatch(changeNewPatientLineGreetingType(GREETING_TYPE['CUSTOM_TEXT']));
        dispatch(getFileContent({ id: payload.new_patient_flow_greeting_id })).then((resp) => {
            dispatch(changeNewPatientLineGreetingScript(resp));
        });
    } else {
        dispatch(changeNewPatientLineGreetingType(GREETING_TYPE['CUSTOM_AUDIO']));
    }
};

export const changeNewPatientLineGreetingScript = (payload) => ({
    type: Actions.CHANGE_NEW_PATIENT_LINE_GREETING_SCRIPT,
    payload,
});

export const changeNewPatientLineGreetingType = (payload) => ({
    type: Actions.CHANGE_NEW_PATIENT_LINE_GREETING_TYPE,
    payload,
});
