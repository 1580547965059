import { Ability } from '@casl/ability';
import { BaseAbilityBuilder } from './BaseAbilityBuilder';
import { constants } from '../Constants';

export class ItSupportAbilityBuilder extends BaseAbilityBuilder {
    constructHiddenFieldsArray() {
        const {
            ivr,
            voicemail,
            settingsPopover,
            generalSettings,
            refills,
            leads,
            inbox,
            patients,
            pharmacyApps,
        } = this.restrictedResources;
        const { language, featuresAndFunctions, bannerConfiguration, userManagement } = generalSettings;
        const { languagesAndVoices, prescriptionRefillFulfillment } = ivr;
        const { patient, newConversation, messages } = inbox;
        const { general: patientsGeneral, bulkMessages } = patients;

        this.hiddenFields = [
            {
                subject: refills.subject,
                fields: [],
            },
            {
                subject: leads.subject,
                fields: [],
            },
            {
                subject: inbox.subject,
                fields: [],
            },
            {
                subject: patient.subject,
                fields: [],
            },
            {
                subject: newConversation.subject,
                fields: [],
            },
            {
                subject: messages.subject,
                fields: [],
            },
            {
                subject: voicemail.subject,
                fields: [],
            },
            {
                subject: language.subject,
                fields: [],
            },
            {
                subject: featuresAndFunctions.subject,
                fields: [],
            },
            {
                subject: bannerConfiguration.subject,
                fields: [],
            },
            {
                subject: userManagement.subject,
                fields: [],
            },
            {
                subject: ivr.subject,
                fields: [ivr.fields.resetIVR],
            },
            {
                subject: languagesAndVoices.subject,
                fields: [languagesAndVoices.fields.ttsEngine],
            },
            {
                subject: prescriptionRefillFulfillment.subject,
                fields: [prescriptionRefillFulfillment.fields.patientDobValidation],
            },
            {
                subject: settingsPopover.subject,
                fields: [settingsPopover.fields.appcode],
            },
            {
                subject: patients.subject,
                fields: [],
            },
            {
                subject: patientsGeneral.subject,
                fields: [],
            },
            {
                subject: bulkMessages.subject,
                fields: [],
            },
            {
                subject: pharmacyApps.subject,
                fields: [],
            },
        ];
    }

    constructReadOnlyFieldsArray() {
        const { ivr, generalSettings } = this.restrictedResources;
        const { accountDetails } = generalSettings;

        this.readOnlyFields = [
            {
                subject: accountDetails.subject,
                fields: [accountDetails.fields.email],
            },
            {
                subject: ivr.subject,
                fields: [ivr.fields.ivrPhoneNumber],
            },
        ];
    }

    build() {
        const { all, userActions } = constants;
        const { manage } = userActions;

        // Note
        // 1) Rule are in accordance with Google Sheet attached in https://rxwiki.atlassian.net/browse/PHAR-1355 and
        //    https://app.lucidchart.com/documents/view/f82ecc8e-e040-4e25-b0ac-e5c5218dd168/0_0
        // 2) We follow the opt-out approach, i.e. we allow user to manage everything except opted out features
        this.can(manage, all);
        this.optOutReadOnlyFields();
        this.optOutHiddenFields();

        return new Ability(this.rules, { detectSubjectType: this.detectAppSubjectType });
    }
}
