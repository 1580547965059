import _ from 'lodash';
import pharmacyType from './type';

export const initialState = {
    loading: false,
    sending: false,
    pharmacy: null,
    vowIVREnabled: false,
    activeLocationId: null,
    basicMessagingEnabled: false,
    fullPepEnabled: false,
    bulkUploadEnabled: false,
    allowPepPatientEditing: true,
    ivrEnabled: false,
    ivrAddLang: false,
    ivrSMSSend: false,
    callTrackingEnabled: false,
    tigerPistolEnabled: false,
    newslettersV2Enabled: false,
    dmDashboardEnabled: false,
    refillsEnabled: false,
    abTestingFeatures: {},
    allowRefillNotification: false,
    allowLeadNotification: false,
    digitalMarketingReportsEnabled: false,
    digitalPlatformReportsEnabledForMobile: false,
    digitalPlatformReportsEnabledForWeb: false,
    communicationPlatformReportsEnabled: false,
    mobileDownloadsReportsEnabled: false,
    websiteTrafficReportsEnabled: false,
    newsletterReportsEnabled: false,
    reputationManagementReportsEnabled: false,
    directMessagingReportsEnabled: false,
    classesEnabled: false,
    error: {},
    lastUpdated: Date.now(),

    upgradeToFullPep: null,
};

export const pharmacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case pharmacyType.GET_PHARMACY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case pharmacyType.GET_PHARMACY_SUCCESS:
            return {
                ...state,
                loading: false,
                pharmacy: action.pharmacy,
                lastUpdated: Date.now(),
            };
        case pharmacyType.GET_PHARMACY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case pharmacyType.RESET_PHARMACY_STORE:
            return {
                ...initialState,
            };
        case pharmacyType.UPDATE_ACTIVE_LOCATION:
            return {
                ...state,
                activeLocationId: action.activeLocationId,
            };
        case pharmacyType.UPDATE_ENABLED_SERVICES:
            /*
        Override initialState value so that if redux store is reset in current session, this new value is brought in as default
        This allows us to 'persist' some state after the user is logged-out and the redux store is reset automatically
        The main use-case currently is to help persist feature flags needed to determine whether or not to show a browser notification
        See ticket: PHAR-1219
      */
            const persistActionKeys = ['allowRefillNotification', 'allowLeadNotification'];

            persistActionKeys.forEach((key) => {
                if (action.hasOwnProperty(key)) {
                    initialState[key] = action[key];
                }
            });

            // TODO: this needs refactoring, can be written better using spread operator
            return {
                ...state,
                fullPepEnabled: action.fullPepEnabled,
                basicMessagingEnabled: action.basicMessagingEnabled,
                bulkUploadEnabled: action.bulkUploadEnabled,
                allowPepPatientEditing: action.allowPepPatientEditing,
                autoAttendant: action.autoAttendant,
                ivrEnabled: action.ivrEnabled,
                ivrSMSSend: action.ivrSMSSend,
                ivrAddLang: action.ivrAddLang,
                callTrackingEnabled: action.callTrackingEnabled,
                tigerPistolEnabled: action.tigerPistolEnabled,
                newslettersV2Enabled: action.newslettersV2Enabled,
                abTestingFeatures: action.abTestingFeatures,
                allowRefillNotification: action.allowRefillNotification,
                allowLeadNotification: action.allowLeadNotification,
                digitalMarketingReportsEnabled: action.digitalMarketingReportsEnabled,
                digitalPlatformReportsEnabledForMobile: action.digitalPlatformReportsEnabledForMobile,
                digitalPlatformReportsEnabledForWeb: action.digitalPlatformReportsEnabledForWeb,
                communicationPlatformReportsEnabled: action.communicationPlatformReportsEnabled,
                mobileDownloadsReportsEnabled: action.mobileDownloadsReportsEnabled,
                websiteTrafficReportsEnabled: action.websiteTrafficReportsEnabled,
                newsletterReportsEnabled: action.newsletterReportsEnabled,
                reputationManagementReportsEnabled: action.reputationManagementReportsEnabled,
                directMessagingReportsEnabled: action.directMessagingReportsEnabled,
                classesEnabled: action.classesEnabled,
                dmDashboardEnabled: action.dmDashboardEnabled,
                refillsEnabled: action.refillsEnabled,
                vowIVREnabled: action.vowIVREnabled,
            };
        case pharmacyType.CREATE_PACKAGE_ATTRIBUTE_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case pharmacyType.CREATE_PACKAGE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case pharmacyType.CREATE_PACKAGE_ATTRIBUTE_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };

        case pharmacyType.UPGRADE_TO_FULL_PEP_REQUEST:
        case pharmacyType.UPGRADE_TO_FULL_PEP_SUCCESS:
        case pharmacyType.UPGRADE_TO_FULL_PEP_FAILURE:
            return {
                ...state,
                upgradeToFullPep: {
                    loading: action.type === pharmacyType.UPGRADE_TO_FULL_PEP_REQUEST,
                    ...action.payload,
                },
            };

        case pharmacyType.CLEAR_UPGRADE_TO_FULL_PEP_ERROR:
            return {
                ...state,
                upgradeToFullPep: _.omit(state.upgradeToFullPep, ['error']),
            };

        default:
            return state;
    }
};

export default pharmacyReducer;
