import './AppointmentTypesPage.sass';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AppointmentTypesIcon from './AppointmentTypesIcon';
import { CircularProgress, MenuItem, Select, TextField } from '@material-ui/core';
import AppointmentTypeRow from './AppointmentAvailabilityRow';
import { getLiveReusableReservationSessions } from 'redux/Appt/Reservation/action';
import { fetchAvailableAppointmentCalendars } from 'redux/Appt/Calendar/action';
import { getAvailableAppointmentTypes } from 'redux/Appt/ApptType/action';
import _ from 'lodash';
import { selectApptTypesWithSessions } from './apptTypesUtil';
import { AutoSizer, List } from 'react-virtualized';
import EventBusyIcon from '@material-ui/icons/EventBusy';

function AppointmentTypesPage({
    apptCalendar,
    apptType,
    apptReservation,
    fetchAvailableAppointmentCalendars,
    getAvailableAppointmentTypes,
    getLiveReusableReservationSessions,
    apptTypesWithSessions,
}) {
    const [filterText, setFilterText] = useState('');
    const [filteredApptTypes, setFilteredApptTypes] = useState(apptTypesWithSessions);
    const [filterCategory, setFilterCategory] = useState('all');

    useEffect(() => {
        const categoryResults =
            filterCategory === 'all'
                ? apptTypesWithSessions
                : _.filter(apptTypesWithSessions, ({ apptType, sessions }) => {
                      if (filterCategory === 'web') {
                          return !_.isNil(_.find(sessions, ({ available_on_web }) => available_on_web === 1));
                      } else if (filterCategory === 'active') {
                          return !_.isEmpty(sessions);
                      } else if (filterCategory === 'inactive') {
                          return _.isEmpty(sessions);
                      }
                  });

        if (_.isEmpty(filterText)) {
            setFilteredApptTypes(categoryResults);
        } else {
            setFilteredApptTypes(
                _.filter(categoryResults, ({ apptType }) => {
                    return _.toLower(apptType.label).includes(filterText.toLowerCase());
                })
            );
        }
    }, [apptTypesWithSessions, filterText, filterCategory]);

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    useEffect(() => {
        if (!apptType.loadingAvailableApptTypes && _.isNil(_.get(apptType, 'availableApptTypes.response'))) {
            getAvailableAppointmentTypes();
        }
    }, [apptType]);

    useEffect(() => {
        if (
            !_.get(apptReservation, 'liveReusableSessions.loading') &&
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response'))
        ) {
            getLiveReusableReservationSessions();
        }
    }, [apptReservation]);

    const isLoading =
        apptCalendar.loadingAvailableCalendars ||
        _.isNil(apptCalendar.availableCalendars) ||
        apptType.loadingAvailableApptTypes ||
        _.isNil(_.get(apptType, 'availableApptTypes.response')) ||
        _.get(apptReservation, 'liveReusableSessions.loading') ||
        _.isNil(_.get(apptReservation, 'liveReusableSessions.response'));

    return (
        <div className="appointment-types-page">
            <div className="appointment-types-content">
                <div className="heading">
                    <div className="icon-and-text-filter">
                        <AppointmentTypesIcon size={48} />

                        <TextField
                            className="appt-types-name-filter"
                            variant="outlined"
                            defaultValue={filterText}
                            placeholder="Filter available appointment types..."
                            disabled={isLoading}
                            onChange={(e) => setFilterText(e.target.value.trim())}
                        />
                    </div>

                    <Select
                        className="appt-types-category-filter"
                        value={filterCategory}
                        variant="outlined"
                        disabled={isLoading}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={(e) => setFilterCategory(e.target.value)}
                    >
                        <MenuItem value="all">All appointment types</MenuItem>
                        <MenuItem value="active">Active appointment types</MenuItem>
                        <MenuItem value="inactive">Inactive appointment types</MenuItem>
                        <MenuItem value="web">Available on website</MenuItem>
                    </Select>
                </div>

                {isLoading ? (
                    <div className="appointment-type-rows">
                        <CircularProgress className="page-loading" />
                    </div>
                ) : (
                    <div className="appointment-type-rows">
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                        width={width + 10}
                                        height={height}
                                        rowCount={_.size(filteredApptTypes)}
                                        rowHeight={170}
                                        rowRenderer={({ key, index, style }) => {
                                            const { apptType, sessions } = filteredApptTypes[index];
                                            return (
                                                <div key={key} style={{ ...style, padding: '0 10px 0 5px' }}>
                                                    <AppointmentTypeRow
                                                        key={apptType.appt_type_id}
                                                        isCovidVaccine={apptType.label === 'COVID-19 Vaccination'}
                                                        appointmentType={{
                                                            appt_type_id: apptType.appt_type_id,
                                                            active: !_.isEmpty(sessions),
                                                            title: apptType.label,
                                                            description: apptType.description,
                                                            calendarId: _.get(sessions, '[0].calendar_id'),
                                                            appointmentLength: _.get(sessions, '[0].expected_length'),
                                                            img: apptType.image_ref,
                                                            onWebsite: !_.isNil(
                                                                _.find(
                                                                    sessions,
                                                                    ({ available_on_web }) => available_on_web === 1
                                                                )
                                                            ),
                                                        }}
                                                        sessions={sessions}
                                                        isMultiSession={apptType.multi_session === 1}
                                                        active={!_.isEmpty(sessions)}
                                                    />
                                                </div>
                                            );
                                        }}
                                        noRowsRenderer={() => {
                                            return (
                                                <div className="no-matching-appt-types-message">
                                                    <EventBusyIcon />
                                                    <div className="text">
                                                        <div className="main-line">No matching appointment types.</div>
                                                        <div className="sub-line">
                                                            You may adjust the filters you are applying to see which
                                                            appointment types are available.
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                )}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { apptCalendar, apptType, apptReservation } = state;
    return {
        apptCalendar,
        apptType,
        apptReservation,
        apptTypesWithSessions: selectApptTypesWithSessions(state),
    };
}

const bindActionsToDispatch = {
    fetchAvailableAppointmentCalendars,
    getAvailableAppointmentTypes,
    getLiveReusableReservationSessions,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentTypesPage);
