import Actions from 'redux/actions';

export const displayToast = (data) => ({
    type: Actions.SHOW_TOAST,
    data,
});

export const dismissToast = () => ({
    type: Actions.DISMISS_TOAST,
});
