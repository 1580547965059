import './ConfirmSelectedPatientsContent.sass';
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { getInvalidPatientFields, parsePatientFromFileData, capitalizeName } from '../csvUtil';
import { Button, LinearProgress, CircularProgress } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import numeral from 'numeral';
import pluralize from 'pluralize';
import classnames from 'classnames';
import ResolveDuplicatePatientMatches from './ResolveDuplicatePatientMatches';
import AddPatientsForSelection from './AddPatientsForSelection';
import { formatRawDate, getAbbreviatedDateInputFormatByCountryCode } from '../../../../utils/helper';
import ErrorIcon from '@material-ui/icons/Error';

export default function ConfirmSelectedPatientsContent({
    fileData,
    fileColumns,
    activeLocationCountryCode,
    attributesMapping,
    searchPatientList,
    onGoBack,
    onDone,
    onClose,
    selectedPatients,
    setSelectedPatients,
    searchError,
    setSearchError,
}) {
    const props = arguments[0];
    const [emptySearchMatches, setEmptySearchMatches] = useState(null);
    const [createablePatients, setCreateablePatients] = useState(null);
    const [singleSearchMatches, setSingleSearchMatches] = useState(null);
    const [multipleSearchMatches, setMultipleSearchMatches] = useState(null);
    const [addError, setAddError] = useState(null);
    const [autoCapitalize, setAutoCapitalize] = useState(true);
    const [addingPatients, setAddingPatients] = useState(false);
    const [newlySelectedPatients, setNewlySelectedPatients] = useState(null);

    const { parsedPatients, searchablePatients } = useMemo(() => {
        const parsedPatients = [];
        const searchablePatients = [];
        _.each(_.get(fileData, 'data'), (r, i) => {
            const row = _.map(fileColumns, (fc) => fc.source.data[i][fc.sourceIndex]);
            const contact = parsePatientFromFileData(row, i, fileData, activeLocationCountryCode, attributesMapping);
            if (!_.isNil(contact)) {
                parsedPatients.push(contact);
                if (_.isString(contact.first_name) && _.isString(contact.last_name)) {
                    searchablePatients.push(
                        _.pick(contact, [
                            'first_name',
                            'last_name',
                            'date_of_birth',
                            'phone',
                            'raw',
                            'pharmacy_id',
                            'location_id',
                        ])
                    );
                }
            }
        });
        return { parsedPatients, searchablePatients };
    }, [JSON.stringify(attributesMapping), fileData]);

    const doPatientSelection = (singleSearchMatches) => {
        const newlySelectedPatients = {};
        const previouslySelectedPatients = {};
        _.each(singleSearchMatches, (match) => {
            const inbox_user_id = match.inbox_user_id;
            if (_.has(selectedPatients, inbox_user_id)) {
                previouslySelectedPatients[inbox_user_id] = match;
            } else {
                newlySelectedPatients[inbox_user_id] = match;
            }
        });

        setSelectedPatients({
            ...selectedPatients,
            ...newlySelectedPatients,
        });

        return { newlySelectedPatients, previouslySelectedPatients };
    };

    useEffect(() => {
        if (!_.isEmpty(searchablePatients)) {
            searchPatientList(_.map(searchablePatients, (p) => _.omit(p, ['raw'])))
                .then((response) => {
                    if (!response.success) {
                        setSearchError('success is false');
                    } else {
                        const singleSearchMatches = [];
                        const multipleSearchMatchesByIndex = {};
                        const emptySearchMatches = [];

                        _.each(response.results, (matches, i) => {
                            if (_.size(matches) === 1) {
                                singleSearchMatches.push(_.first(matches));
                            } else if (_.size(matches) > 1) {
                                multipleSearchMatchesByIndex[i] = matches;
                            } else {
                                emptySearchMatches.push(searchablePatients[i]);
                            }
                        });

                        setEmptySearchMatches(emptySearchMatches);
                        setSingleSearchMatches(singleSearchMatches);
                        setMultipleSearchMatches(multipleSearchMatchesByIndex);

                        const createablePatients = _.filter(emptySearchMatches, (emptyPatient) => {
                            return _.isEmpty(getInvalidPatientFields(emptyPatient));
                        });
                        setCreateablePatients(createablePatients);

                        const { newlySelectedPatients, previouslySelectedPatients } = doPatientSelection(
                            singleSearchMatches
                        );

                        setNewlySelectedPatients(newlySelectedPatients);

                        if (
                            !_.isEmpty(singleSearchMatches) &&
                            _.isEmpty(createablePatients) &&
                            _.isEmpty(multipleSearchMatchesByIndex)
                        ) {
                            onDone(newlySelectedPatients, emptySearchMatches, previouslySelectedPatients);
                        }
                    }
                })
                .catch((error) => {
                    setSearchError(error);
                });
        }
    }, [searchablePatients]);

    const isLoading =
        _.isNil(searchError) &&
        _.isNil(emptySearchMatches) &&
        _.isNil(singleSearchMatches) &&
        _.isNil(multipleSearchMatches);
    const isDone = !_.isEmpty(singleSearchMatches) && _.isEmpty(createablePatients) && _.isEmpty(multipleSearchMatches);

    const getPatientAtIndex = (index) => {
        const capitalized = !autoCapitalize
            ? {}
            : {
                  first_name: capitalizeName(createablePatients[index].first_name),
                  last_name: capitalizeName(createablePatients[index].last_name),
              };
        return {
            ...createablePatients[index],
            ...capitalized,
        };
    };

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });
    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);

    return (
        <div className="confirm-selected-patients-content">
            {!isLoading && !isDone ? null : (
                <div className="loading-content">
                    <LinearProgress />
                    <div className="main-text-line">Checking records in this file against your patients...</div>
                </div>
            )}

            {isLoading || isDone ? null : (
                <div
                    className={classnames({
                        'main-content-wrapper': true,
                        disabled: addingPatients || !_.isNil(addError),
                    })}
                >
                    <div className="heading">
                        <Button variant="text" onClick={onGoBack}>
                            <KeyboardBackspaceIcon /> Go Back
                        </Button>
                        {_.isEmpty(multipleSearchMatches) ? (
                            <div className="text">
                                Selected {numeral(_.size(singleSearchMatches)).format('0,0')}{' '}
                                {pluralize('patient', _.size(singleSearchMatches))} from the{' '}
                                {numeral(_.size(parsedPatients)).format('0,0')} patient{' '}
                                {pluralize('record', _.size(parsedPatients))} in the file
                            </div>
                        ) : (
                            <div className="text">Please review the following patient matching issues</div>
                        )}
                    </div>

                    {_.isEmpty(multipleSearchMatches) ? (
                        <AddPatientsForSelection
                            {...props}
                            createablePatients={createablePatients}
                            searchablePatients={searchablePatients}
                            singleSearchMatches={singleSearchMatches}
                            emptySearchMatches={emptySearchMatches}
                            setAutoCapitalize={setAutoCapitalize}
                            autoCapitalize={autoCapitalize}
                            addingPatients={addingPatients}
                            setAddingPatients={setAddingPatients}
                            getPatientAtIndex={getPatientAtIndex}
                            setAddError={setAddError}
                            doPatientSelection={doPatientSelection}
                            formatDate={formatDate}
                        />
                    ) : (
                        <ResolveDuplicatePatientMatches
                            {...props}
                            multipleSearchMatches={multipleSearchMatches}
                            setMultipleSearchMatches={setMultipleSearchMatches}
                            doPatientSelection={doPatientSelection}
                            searchablePatients={searchablePatients}
                            formatDate={formatDate}
                            newlySelectedPatients={newlySelectedPatients}
                        />
                    )}
                </div>
            )}

            {!addingPatients ? null : (
                <div className="adding-patients-progress">
                    <CircularProgress />
                </div>
            )}

            {_.isNil(addError) ? null : (
                <div className="add-error-message">
                    <ErrorIcon />
                    <div className="text">
                        <div className="main-line">An unexpected error occurred adding patients from this file</div>
                        <div className="sub-line">
                            Please try again later or contact Digital Pharmacist support if this continues.
                        </div>
                    </div>
                    <Button className="error-ok-button" variant="text" onClick={onClose}>
                        OK
                    </Button>
                </div>
            )}
        </div>
    );
}
