import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import PharmacyApps from './PharmacyApps';
import NoAccess from 'components/Common/NoAccess';
import { Can } from 'casl/Can';
import { restrictedResources } from 'casl/RestrictedResources';
import { userActions } from 'casl/UserActions';
import { SettingTitle } from '../Common/SettingTitle';

const PharmacyAppsWrapper = (props) => {
    const subtitle =
        'This page is shown to internal users only. Internal users can add, delete, update pharmacy apps here.';
    const { view } = userActions;
    const { subject: caslSubject } = restrictedResources.pharmacyApps;
    const {
        flags: { npePharmacyApps },
    } = props;

    return (
        <Grid className="settings-content--grid-container">
            <SettingTitle title="Pharmacy Apps" subtitle={subtitle} />

            <Card variant="outlined" className="bordered-box settings-content--card">
                <Can I={view} this={caslSubject} passThrough>
                    {(allowed) => (allowed && npePharmacyApps ? <PharmacyApps /> : <NoAccess />)}
                </Can>
            </Card>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    flags: state.launchDarkly,
});

export default connect(mapStateToProps, null)(PharmacyAppsWrapper);
