let audioCtx = null;
let analyser = null;

const AudioContext = {
    getAudioContext() {
        return audioCtx;
    },

    getAnalyser() {
        return analyser;
    },

    setAudioContext() {
        audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    },

    setAnalyser() {
        analyser = audioCtx.createAnalyser();
    },
};

export default AudioContext;
