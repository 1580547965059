import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';

const Dialog = withStyles({
    paper: {
        width: '600px',
    },
    paperWidthMd: {
        width: '600px !important',
    },
})(DpDialog.Dialog);

export const NonBrandedAppsDialog = (props) => {
    const dpNonBrandedApps = _.keyBy(props.dpNonBrandedApps, (app) => app.name);
    const initialState = _.mapValues(dpNonBrandedApps, () => false);
    const [state, setState] = React.useState(initialState);
    const isDirty = _.some(state, (item) => item === true);
    const handleChange = (event) => setState({ ...state, [event.target.name]: event.target.checked });
    const handleAdd = () => props.onAdd(_.filter(dpNonBrandedApps, (app) => state[app.name] === true));
    const handleClose = () => {
        setState(initialState);
        props.onClose();
    };

    React.useEffect(() => {
        setState(initialState);
    }, [_.size(props.dpNonBrandedApps)]);

    return (
        <Dialog id="non-branded-apps-dialog" open={props.show} maxWidth="sm" onClose={handleClose}>
            <DpDialog.Content id="non-branded-apps-content" dividers>
                <DpDialog.ContentHeader heading="Non Branded Apps" onClose={handleClose}>
                    <span className="md-card-title md-text--secondary">
                        Note: Already added apps are not displayed here
                    </span>
                </DpDialog.ContentHeader>

                <div className="flex-vertical-space-between" style={{ marginTop: '24px' }}>
                    {_.map(props.dpNonBrandedApps, (app) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state[app.name]}
                                    onChange={handleChange}
                                    name={app.name}
                                    color="primary"
                                />
                            }
                            label={app.name}
                            key={`dialog-item-${app.name}`}
                        />
                    ))}
                </div>
            </DpDialog.Content>

            <DpDialog.Actions style={{ padding: '15px' }}>
                <Button onClick={handleClose} color="default">
                    Cancel
                </Button>
                <Button onClick={handleAdd} color="primary" variant="contained" disabled={!isDirty}>
                    Add
                </Button>
            </DpDialog.Actions>
        </Dialog>
    );
};
