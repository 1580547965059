import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ReportTooltip = ({ text }) => (
    <Tooltip
        placement="bottom"
        enterTouchDelay={0}
        classes={{
            tooltip: 'info-tooltip',
        }}
        title={text}
    >
        <InfoOutlinedIcon fontSize="small" className="tooltip-icon" />
    </Tooltip>
);

export default ReportTooltip;
