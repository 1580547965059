import Actions from 'redux/actions';
import { put, get, post } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { fetchLocationDetails } from 'redux/actionCreators/Auth';
import { pharmacyAction } from 'redux/Pharmacy/action';

export const updatePharmacyBasicDetails = (data) => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.UPDATE_PHARMACY_NAME}`;
    apiUrl = apiUrl.replace('{custID}', Config.X_PharmacyID).replace('{locID}', Config.X_LocationID);
    dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED });

    const pharmacyNameUpdateReq = put(apiUrl, data);
    return pharmacyNameUpdateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS, payload: data });
            dispatch(pharmacyAction.getPharmacy(Config.X_PharmacyID));
            dispatch(fetchLocationDetails());
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED, payload: error });
            return { error };
        });
};

export const updatePharmacyAttribute = (data) => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.UPDATE_PHARMACY_NCPDPID}`;
    apiUrl = apiUrl
        .replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{attrID}', data.id);

    dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED });

    const pharmacyNCPDPID_UpdateReq = put(apiUrl, data);
    return pharmacyNCPDPID_UpdateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS, payload: data });
            dispatch(pharmacyAction.getPharmacy(Config.X_PharmacyID));
            dispatch(fetchLocationDetails());
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED, payload: error });
            return { error };
        });
};

export const getAddressByZip = (addr) => (dispatch) => {
    const { zip } = addr;
    const apiUrl = `${Config.config2_url}${API_PATH.GET_ADDRESS_BY_ZIP}?address=${encodeURIComponent(zip)}`;

    dispatch({ type: Actions.GET_ADDRESS_BY_ZIP_STARTED });

    const getAddressByZipReq = get(apiUrl);
    return getAddressByZipReq
        .then((data) => {
            dispatch({ type: Actions.GET_ADDRESS_BY_ZIP_SUCCESS, payload: data });
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.GET_ADDRESS_BY_ZIP_FAILED, payload: error });
            return { error };
        });
};

export const getLocationIdentifier = () => (dispatch) => {
    const apiUrl = `${Config.config2_url}${API_PATH.GET_LOCATION_IDENTIFIER}`
        .replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID);

    dispatch({ type: Actions.GET_LOCATION_IDENTIFIER_STARTED });

    const getLocationIdentifierReq = get(apiUrl);
    return getLocationIdentifierReq
        .then((data) => {
            const result =  Array.isArray(data) ? data[0] : {}

            dispatch({ type: Actions.GET_LOCATION_IDENTIFIER_SUCCESS, payload: result });
            return result;
        })
        .catch((error) => {
            dispatch({ type: Actions.GET_LOCATION_IDENTIFIER_FAILED, payload: error });
            return { error };
        });
};

export const updatePharmacyAddress = (data) => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.UPDATE_PHARMACY_ADDRESS}`;
    apiUrl = apiUrl
        .replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{addrID}', data.id);

    dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED });

    const pharmacyAddress_UpdateReq = put(apiUrl, data);
    return pharmacyAddress_UpdateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS, payload: data });
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED, payload: error });
            return { error };
        });
};

export const updateLocationIdentifier = (data) => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.UPDATE_LOCATION_IDENTIFIER}`;
    apiUrl = apiUrl
        .replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{pmsLocationId}', data.locationIdentifier);

    dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED });

    const pharmacyLocationIdentifier_UpdateReq = put(apiUrl, {});
    return pharmacyLocationIdentifier_UpdateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS, payload: data });
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED, payload: error });
            return { error };
        });
};

export const getAttributeTypeId = (attrName) => {
    let apiUrl = `${Config.config2_url}${API_PATH.GET_ATTRIBUTE_TYPE_ID}?name=${attrName}`;
    const getAttributeTypeId_getReq = get(apiUrl);
    return getAttributeTypeId_getReq
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return { error };
        });
};

export const addPharmacyAttribute = (data) => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.ADD_PHARMACY_ATTR}`;
    apiUrl = apiUrl.replace('{custID}', Config.X_PharmacyID).replace('{locID}', Config.X_LocationID);

    dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_STARTED });

    const pharmacyNCPDPID_CreateReq = post(apiUrl, data);
    return pharmacyNCPDPID_CreateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS, payload: data });
            dispatch(pharmacyAction.getPharmacy(Config.X_PharmacyID));
            dispatch(fetchLocationDetails());
            return data;
        })
        .catch((error) => {
            dispatch({ type: Actions.UPDATE_PHARMACY_BASIC_DETAILS_FAILED, payload: error });
            return { error };
        });
};
