import React, { PureComponent } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export class SearchBar extends PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = _.debounce(this.handleChange, 300);
    }

    handleChange = (searchString) => {
        const stringLength = searchString.length;
        if (stringLength === 0 || stringLength > 2) {
            const { onSearch } = this.props;
            onSearch(searchString);
        }
    };

    render() {
        const { searchSuccess, className, onSearch, ...rest } = this.props;

        return (
            <TextField
                {...rest}
                fullWidth
                size="small"
                type="search"
                margin="none"
                variant="outlined"
                autoComplete="off"
                error={!searchSuccess}
                helperText={!searchSuccess ? 'No results found. Please try your search again.' : ''}
                onChange={(event) => this.handleChange(event.target.value)}
                classes={{
                    root: `${className}`,
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

export default SearchBar;
