import './MapFileAttributesContent.sass';
import React, { useState } from 'react';
import { Button, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import _ from 'lodash';
import { isAttributeMapped, getColumnName, getMappedCount } from './csvUtil';
import CsvColumnSummary from './CsvColumnSummary';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightIcon from '@material-ui/icons/Highlight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function MapFileAttributesContent({
    fileData,
    fileColumns,
    requiredFields,
    optionalFields,
    attributesMapping,
    setAttributesMapping,
    onDone,
    onGoBack,
    isMappingComplete,
    autoMappedAttributeCount,
    doneLabel,
    optionalFieldTooltipTitle,
}) {
    const [focusedField, setFocusedField] = useState('first_name');

    if (_.isNil(fileData)) {
        return null;
    }

    const allFields = [].concat(requiredFields);
    if (!_.isEmpty(optionalFields)) {
        allFields.push(..._.map(optionalFields, (o) => ({ ...o, optional: true })));
    }

    const mappedCount = getMappedCount(attributesMapping);
    const totalCount = _.size(allFields);

    const focusedLabel = _.get(
        _.find(allFields, ({ value }) => value === focusedField),
        'name'
    );

    const fieldNamesToLabels = {};
    _.each(allFields, ({ name, value }) => (fieldNamesToLabels[value] = name));

    return (
        <div className="map-file-attributes-content">
            <div className="heading">
                <Button variant="text" onClick={onGoBack}>
                    <KeyboardBackspaceIcon /> Go Back
                </Button>
                <div className="text">Please review/map the following patient attributes to data file columns.</div>
            </div>

            <div className="tabs-and-content">
                <div className="required-fields">
                    <div className="tabs-heading">
                        <div className="mapping-counts">
                            {mappedCount} / {totalCount} mapped attributes
                        </div>
                    </div>
                    <Tabs value={focusedField} orientation="vertical">
                        {_.map(allFields, ({ name, value, optional }) => {
                            const colIndex = _.get(attributesMapping, `${value}.colIndex`);
                            const unmapped = !_.isFinite(colIndex);

                            const optionalDom = !optional ? null : (
                                <Tooltip
                                    title={
                                        _.isNil(optionalFieldTooltipTitle)
                                            ? 'Mapping this field is not required to continue.'
                                            : optionalFieldTooltipTitle
                                    }
                                >
                                    <span className="optional-marker">(optional)</span>
                                </Tooltip>
                            );

                            return (
                                <Tab
                                    key={value}
                                    label={
                                        <span className={classnames({ 'configured-tab-label': true, unmapped })}>
                                            {unmapped ? (
                                                <span>
                                                    {name} {optionalDom}
                                                </span>
                                            ) : (
                                                <div className="mapped-tab-content">
                                                    <CheckCircleIcon />
                                                    <div className="name-and-column">
                                                        <div className="name-line">
                                                            {name} {optionalDom}
                                                        </div>
                                                        <div className="column-line">
                                                            {getColumnName(fileColumns, colIndex, fileData.hasHeaders)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </span>
                                    }
                                    value={value}
                                    onClick={() => setFocusedField(value)}
                                />
                            );
                        })}
                    </Tabs>
                </div>

                <div className="focused-attribute-content">
                    <div className="column-header">Column for {focusedLabel}</div>
                    <div className="column-options-wrapper" style={{ maxHeight: `${window.innerHeight - 350}px` }}>
                        <div className="column-options">
                            {_.map(fileColumns, (c, colIndex) => {
                                const mappedLabels = [];
                                _.forIn(attributesMapping, (m, key) => {
                                    if (m.colIndex === colIndex) {
                                        mappedLabels.push(fieldNamesToLabels[key]);
                                    }
                                });

                                return (
                                    <CsvColumnSummary
                                        key={colIndex}
                                        fileData={fileData}
                                        fileColumns={fileColumns}
                                        colIndex={colIndex}
                                        selected={colIndex === _.get(attributesMapping, `${focusedField}.colIndex`)}
                                        mappedLabels={mappedLabels}
                                        onClick={(colIndex) => {
                                            setAttributesMapping({
                                                ...attributesMapping,
                                                [focusedField]: {
                                                    ..._.get(attributesMapping, focusedField),
                                                    colIndex,
                                                },
                                            });

                                            // if the next field is unmapped, then focus it after a short timeout
                                            const focusedIndex = _.findIndex(
                                                requiredFields,
                                                ({ value }) => value === focusedField
                                            );
                                            const nextFieldName = _.get(requiredFields, `[${focusedIndex + 1}].value`);
                                            if (
                                                _.isString(nextFieldName) &&
                                                !isAttributeMapped(nextFieldName, attributesMapping)
                                            ) {
                                                setTimeout(() => setFocusedField(nextFieldName), 150);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="actions">
                <span className="notices">
                    {!_.isFinite(autoMappedAttributeCount) || autoMappedAttributeCount === 0 ? null : (
                        <div className="auto-mapping-alert">
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <HighlightIcon className="highlight-icon" />
                                    <div>Auto-mapped {autoMappedAttributeCount} attributes.</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>
                                        Based on common file column header names, we have automatically chosen likely
                                        candidates for one or more patient attributes for you.
                                    </p>
                                    <p>
                                        <strong>Note:</strong> Please ensure that these mappings were made correctly
                                        before continuing.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )}
                </span>

                <span>
                    <Button variant="contained" color="primary" disabled={!isMappingComplete} onClick={onDone}>
                        {doneLabel}
                    </Button>
                </span>
            </div>
        </div>
    );
}
