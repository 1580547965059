import React from 'react';
import { Card, CardText, CardTitle } from 'react-md';

import Sections from './Sections';

const Overview = ({ content }) => {
    return (
        <Card className="borderless-card">
            {content.map((item) => (
                <CardText key={item.title}>
                    <Card className="borderless-card md-cell md-cell--12">
                        <CardTitle title={item.title} subtitle={item.description} style={{ padding: '16px' }} />
                        <CardText>
                            <Sections sections={item.sections} />
                        </CardText>
                    </Card>
                </CardText>
            ))}
        </Card>
    );
};

export default Overview;
