const NEWSLETTER_TABS = {
    CALENDER: 'calendar',
    SUBSCRIBERS: 'subscribers',
    HISTORY: 'history',
};

const NEWSLETTER_MODE = {
    EDIT: 'edit',
    VIEW: 'view',
};

const CARD_MODE = {
    ADD: 'add',
    EDIT: 'edit',
};

const DRAWER_STATES = {
    EMPTY_SUBSCRIBER: 'empty_subscriber',
    ADD_SUBSCRIBER: 'add_subscriber',

    EMPTY_CALENDAR: 'empty_calendar',
    ADD_CARD: 'add_card',
    EDIT_CARD: 'edit_card',
    EDIT_NEWSLETTER: 'edit_newsletter',

    EMPTY_HISTORY: 'empty_history',
    NEWSLETTER_DETAILS: 'newsletter_details',
};

const MAX_ROWS_PER_PAGE = 25;

const CARDS_PAGE_SIZE = 10;

export { NEWSLETTER_TABS, DRAWER_STATES, CARD_MODE, NEWSLETTER_MODE, MAX_ROWS_PER_PAGE, CARDS_PAGE_SIZE };
