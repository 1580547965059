export default {
    status: {
        enabled: 1,
        disabled: 2,
        deleted: 3,
    },
    dataType: {
        boolean: 1,
        float: 2,
        int: 3,
        string: 4,
        stringCollection: 5,
        url: 6,
        image: 7,
        hours: 8,
    },
    entityType: {
        location: 'Location',
        customer: 'Customer',
    },
};
