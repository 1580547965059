import './UploadSelectionFileContent.sass';
import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

export default function UploadSelectionFileContent({ handleFileUpload }) {
    return (
        <div className="upload-selection-file-content">
            <div className="left">
                <img
                    width={200}
                    height={300}
                    src={require('./Upload-Selection.png')}
                    alt="Import file to select patients"
                />
            </div>
            <div className="right">
                <div className="heading">
                    <div className="main-line">Import list of patients for selection</div>
                    <div className="sub-line">
                        You can import a CSV or&nbsp;
                        <Tooltip title="Tab-separated, pipe-separated, and semicolon-separated files are also supported.">
                            <span style={{ textDecoration: 'underline dotted' }}>similar file</span>
                        </Tooltip>
                        &nbsp;from your PMS containing patients you would like to select.
                    </div>
                </div>

                <Dropzone className="file-dropzone" onDrop={(files) => handleFileUpload(files)}>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className="file-drop-area"
                            style={{
                                height: '218px',
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                            {...getRootProps()}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <input
                                    {...getInputProps()}
                                    accept=".csv, text/csv .tsv text/tsv"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e.target.files)}
                                />
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: '1.2rem',
                                        textAlign: 'center',
                                        marginBottom: '16px',
                                        maxWidth: '370px',
                                    }}
                                >
                                    Drag and drop the file you want to import here
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#3793ff',
                                        textAlign: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    or click to select a file from your computer
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#999999',
                                        textAlign: 'center',
                                        maxWidth: '370px',
                                        fontSize: '13px',
                                    }}
                                >
                                    Your file must have (at a minimum) the first name and last name of each patient to
                                    select. They can be in any order. We will map the attributes appropriately in the
                                    next step.
                                </Typography>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </div>
    );
}
