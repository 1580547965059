import React from 'react';

export default function ClipboardClockIcon({ color }) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    className="clipboard-clock-path"
                    d="M22 21.6667L25.8133 23.92L24.8133 25.5467L20 22.6667V16H22V21.6667ZM30.6667 21.3334C30.6667 26.4934 26.4933 30.6667 21.3333 30.6667C18.7867 30.6667 16.4933 29.6534 14.8133 28H6.66667C5.95942 28 5.28115 27.7191 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3334V6.66671C4 5.20004 5.2 4.00004 6.66667 4.00004H12.24C12.8 2.45337 14.2667 1.33337 16 1.33337C17.7333 1.33337 19.2 2.45337 19.76 4.00004H25.3333C26.0406 4.00004 26.7189 4.28099 27.219 4.78109C27.719 5.28119 28 5.95946 28 6.66671V14.8134C29.6533 16.4934 30.6667 18.7867 30.6667 21.3334ZM14.6667 5.33337C14.6667 6.06671 15.2667 6.66671 16 6.66671C16.7333 6.66671 17.3333 6.06671 17.3333 5.33337C17.3333 4.60004 16.7333 4.00004 16 4.00004C15.2667 4.00004 14.6667 4.60004 14.6667 5.33337ZM28 21.3334C28 17.6534 25.0133 14.6667 21.3333 14.6667C17.6533 14.6667 14.6667 17.6534 14.6667 21.3334C14.6667 25.0134 17.6533 28 21.3333 28C25.0133 28 28 25.0134 28 21.3334Z"
                />
            </g>
        </svg>
    );
}
