import './CreateOrEditAppointmentDialog.sass';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import AppointmentTypeContent from './AppointmentTypeContent';
import { getPatientsAge, getTemplateImageUrl } from 'components/Patients/patientsUtil';
import PatientSelectContent from './PatientSelectContent';
import PatientAvatar from 'components/Patients/PatientAvatar';
import { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';
import { formatPatientName } from 'components/Patients/PatientName';
import LocalStorageService from 'utils/localStorageService';
import { formatRawDate, getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import DateTimeSelectContent from './DateTimeSelectContent';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { formatAppointmentTimeSpan } from '../AppointmentRow';
import moment from 'moment';
import OneOffAppointmentContent from './OneOffAppointmentContent';
import Snowplow from 'snowplow';
import { AppointmentContext } from 'snowplow/contexts';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getAppointmentTypesFromConversationTemplates } from 'components/Patients/scheduling/schedulingUtil';
import {
    clearAvailableAppointmentSlots,
    clearCalendarReservations,
    fetchAvailableAppointmentCalendars,
    getAvailableCalendarAppointmentSlots,
    getCalendarReservations,
} from 'redux/Appt/Calendar/action';
import {
    checkinReservation,
    createNewReservation,
    createNewReservationSession,
    getLiveReusableReservationSessions,
    uncheckinReservation,
    updateReservationSession,
    updateReservation,
} from 'redux/Appt/Reservation/action';
import { loadPatientsByInboxUserIds } from 'redux/Patient/PatientData/action';
import { connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import AppointmentTypeContentMulti from './AppointmentTypeContentMulti';

function CreateOrEditAppointmentDialog({
    open,
    onClose,
    isCreate,
    initialAppointmentTypes,
    initialConfiguration,
    initialFocusedTab,
    displayedDateFormat,
    calendars,
    createNewReservation,
    updateReservation,
    editing,
    clearCalendarReservations,
    clearAvailableAppointmentSlots,
    initialSelectedDate,
    userId,
    initialReservationSession,
    initialOverwrittenCalendarId,
    showMultiAppointmentUx,
}) {
    const props = arguments[0];

    useEffect(() => {
        Snowplow.pageView(
            `Manage Appointment - ${isCreate ? 'Add new appointment' : 'Edit'}`,
            isCreate ? undefined : [new AppointmentContext().setReservationId(editing.reservation_id)]
        );
    }, [isCreate]);

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showError, setShowError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [focusedTab, setFocusedTab] = useState(_.isString(initialFocusedTab) ? initialFocusedTab : 'patient');
    const [configuration, setConfiguration] = useState(
        !_.isNil(initialConfiguration) ? _.cloneDeep(initialConfiguration) : {}
    );
    const [isOneOffType, setIsOneOffType] = useState(false);
    const [appointmentTypes, setAppointmentTypes] = useState(initialAppointmentTypes);
    const [overwrittenCalendarId, setOverwrittenCalendarId] = useState(initialOverwrittenCalendarId);

    const [showingAppointmentBlocks, setShowingAppointmentBlocks] = useState(null);

    const [reservationSession, setReservationSession] = useState(initialReservationSession);

    // track if we have loaded the appointment blocks in this dialog so we don't redo it when
    // users navigate backwards through it
    const [hasLoadedAppointmentBlocks, setHasLoadedAppointmentBlocks] = useState(false);

    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);

    useEffect(() => {
        return () => {
            clearAvailableAppointmentSlots();
        };
    }, []);

    const appointmentLengthMinutes = _.get(
        reservationSession,
        'expected_length',
        _.get(configuration, 'appointmentType.appointment_length_minutes')
    );

    return (
        <Dialog
            className="create-or-edit-appointment-dialog"
            data-cy="create-or-edit-appt-dialog"
            open={open}
            onClose={onClose}
            maxWidth="lg"
        >
            <DialogContent className="create-or-edit-appointment-content">
                <div className="left-bar">
                    <div className="heading">{isCreate ? 'Add a new appointment' : 'Edit this appointment'}</div>

                    <div className="nav-entries">
                        <NavBarEntry
                            focused={focusedTab === 'patient'}
                            disabled={!isCreate}
                            label={
                                _.isNil(configuration.patient)
                                    ? 'Patient'
                                    : formatPatientName(
                                          configuration.patient,
                                          LocalStorageService.getPreferredNameFormat()
                                      )
                            }
                            sublabel={
                                _.isNil(configuration.patient) ? (
                                    'Choose the patient for this appointment'
                                ) : (
                                    <span>
                                        <span className="date-of-birth">
                                            {formatDate(configuration.patient.date_of_birth)}
                                        </span>
                                        <span className="age" style={{ marginLeft: '30px' }}>
                                            {getPatientsAge(configuration.patient.date_of_birth, true)}
                                        </span>
                                    </span>
                                )
                            }
                            onClick={() => (isCreate ? setFocusedTab('patient') : null)}
                            icon={
                                _.isNil(configuration.patient) ? null : (
                                    <PatientAvatar
                                        seed={getPatientAvatarSeedFromContact(configuration.patient)}
                                        size={50}
                                    />
                                )
                            }
                            completed={!_.isNil(configuration.patient)}
                        />

                        <NavBarEntry
                            focused={focusedTab === 'appointment-type'}
                            label={_.get(configuration, 'appointmentType.appointment_label', 'Appointment Type')}
                            disabled={!isCreate}
                            sublabel={
                                _.isNil(configuration.appointmentType)
                                    ? 'Choose the type of appointment to schedule'
                                    : `${appointmentLengthMinutes} minute appointment`
                            }
                            onClick={() => (isCreate ? setFocusedTab('appointment-type') : null)}
                            icon={
                                _.isNil(_.get(configuration, 'appointmentType')) ? null : (
                                    <div
                                        className="image-ref-icon"
                                        style={{
                                            backgroundImage: `url(${getTemplateImageUrl(
                                                configuration.appointmentType
                                            )})`,
                                            backgroundColor: '#eee',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '50px',
                                        }}
                                    />
                                )
                            }
                            completed={!_.isNil(configuration.appointmentType)}
                        />

                        <NavBarEntry
                            disabled={_.isEmpty(configuration.appointmentType)}
                            focused={focusedTab === 'time'}
                            label={
                                _.isNil(configuration.datetime)
                                    ? 'Time'
                                    : configuration.datetime.format('ddd, MMM Do, YYYY')
                            }
                            sublabel={
                                _.isNil(configuration.datetime)
                                    ? 'Choose when this appointment should occur'
                                    : //formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat)
                                      formatAppointmentTimeSpan(
                                          configuration.datetime,
                                          moment(configuration.datetime).add(appointmentLengthMinutes, 'minutes'),
                                          displayedDateFormat
                                      )
                            }
                            onClick={() => setFocusedTab('time')}
                            icon={
                                _.isNil(configuration.datetime) ? null : (
                                    <WatchLaterIcon style={{ fontSize: '55px', color: '#53BB7C' }} />
                                )
                            }
                            completed={!_.isNil(configuration.datetime)}
                        />
                    </div>

                    {isCreate ? null : (
                        <div className="delete-button-wrapper">
                            <Button
                                className="delete-appt-button"
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowDeleteConfirm(true)}
                            >
                                Delete this appointment
                            </Button>
                        </div>
                    )}
                </div>

                <div className="main-content">
                    {focusedTab === 'patient' ? (
                        <PatientSelectContent
                            {...props}
                            selected={_.get(configuration, 'patient')}
                            onChange={(patient) => {
                                setConfiguration({ ...configuration, patient });
                                //if (_.isEmpty(configuration)) {
                                setFocusedTab('appointment-type');
                                //}
                            }}
                        />
                    ) : focusedTab === 'appointment-type' ? (
                        isOneOffType ? (
                            <OneOffAppointmentContent
                                setIsOneOffType={setIsOneOffType}
                                calendars={calendars}
                                onChange={(appointmentType) => {
                                    setConfiguration({ ...configuration, appointmentType });
                                    //if (_.isEmpty(configuration.appointmentType)) {
                                    setFocusedTab('time');
                                    //}
                                }}
                            />
                        ) : showMultiAppointmentUx ? (
                            <AppointmentTypeContentMulti
                                {...props}
                                hasLoadedAppointmentBlocks={hasLoadedAppointmentBlocks}
                                setHasLoadedAppointmentBlocks={setHasLoadedAppointmentBlocks}
                                appointmentTypes={appointmentTypes}
                                calendars={calendars}
                                selected={_.get(configuration, 'appointmentType')}
                                setIsOneOffType={setIsOneOffType}
                                setAppointmentTypes={setAppointmentTypes}
                                showingAppointmentBlocks={showingAppointmentBlocks}
                                setShowingAppointmentBlocks={setShowingAppointmentBlocks}
                                reservationSession={reservationSession}
                                setReservationSession={setReservationSession}
                                onChange={(appointmentType) => {
                                    setConfiguration({ ...configuration, appointmentType });
                                    //if (_.isEmpty(configuration.appointmentType)) {
                                    setFocusedTab('time');
                                    //}
                                }}
                            />
                        ) : (
                            <AppointmentTypeContent
                                {...props}
                                hasLoadedAppointmentBlocks={hasLoadedAppointmentBlocks}
                                setHasLoadedAppointmentBlocks={setHasLoadedAppointmentBlocks}
                                appointmentTypes={appointmentTypes}
                                calendars={calendars}
                                selected={_.get(configuration, 'appointmentType')}
                                setIsOneOffType={setIsOneOffType}
                                setAppointmentTypes={setAppointmentTypes}
                                showingAppointmentBlocks={showingAppointmentBlocks}
                                setShowingAppointmentBlocks={setShowingAppointmentBlocks}
                                reservationSession={reservationSession}
                                setReservationSession={setReservationSession}
                                onChange={(appointmentType) => {
                                    setConfiguration({ ...configuration, appointmentType });
                                    //if (_.isEmpty(configuration.appointmentType)) {
                                    setFocusedTab('time');
                                    //}
                                }}
                            />
                        )
                    ) : (
                        <DateTimeSelectContent
                            {...props}
                            calendars={calendars}
                            overwrittenCalendarId={overwrittenCalendarId}
                            setOverwrittenCalendarId={setOverwrittenCalendarId}
                            reservationSession={reservationSession}
                            appointmentType={_.get(configuration, 'appointmentType')}
                            displayedDateFormat={displayedDateFormat}
                            selected={_.get(configuration, 'datetime', initialSelectedDate)}
                            onChange={(datetime) => {
                                setConfiguration({ ...configuration, datetime });
                            }}
                        />
                    )}

                    {showingAppointmentBlocks && focusedTab === 'appointment-type' ? null : (
                        <div className="buttons">
                            <Button
                                data-cy="create-or-edit-appt-button"
                                variant="contained"
                                color="primary"
                                disabled={isSaving || !isSavable(configuration)}
                                onClick={() => {
                                    setIsSaving(true);

                                    const calendar_id = _.get(
                                        reservationSession,
                                        'calendar_id',
                                        _.isNil(overwrittenCalendarId)
                                            ? _.get(configuration, 'appointmentType.appointment_calendar_id')
                                            : overwrittenCalendarId
                                    );

                                    if (isCreate) {
                                        createNewReservation(
                                            calendar_id,
                                            {
                                                calendar_id,
                                                session_id: _.get(reservationSession, 'session_id', null),
                                                ..._.pick(configuration.patient, [
                                                    'inbox_user_id',
                                                    'pharmacy_id',
                                                    'location_id',
                                                ]),
                                                start_date: configuration.datetime
                                                    .utc()
                                                    .format('YYYY-MM-DD HH:mm:SS.sss'),
                                                end_date: moment(configuration.datetime)
                                                    .add(appointmentLengthMinutes, 'minutes')
                                                    .utc()
                                                    .format('YYYY-MM-DD HH:mm:SS.sss'),
                                                status: 'ACTIVE',
                                                label: configuration.appointmentType.appointment_label,
                                                created_by_user_type: 'pharmacist',
                                                created_by_uuid: userId,
                                            },
                                            appointmentLengthMinutes,
                                            isOneOffType
                                        )
                                            .then((response) => {
                                                clearCalendarReservations();
                                                setIsSaving(false);
                                                onClose();
                                            })
                                            .catch((error) => {
                                                setShowError(
                                                    'An error occurred creating this reservation. Please try again later or contact Digital Pharmcist support if it continues.'
                                                );
                                                setIsSaving(false);
                                            });
                                    } else {
                                        updateReservation(editing.calendar_id, editing.reservation_id, {
                                            ...editing,
                                            calendar_id,
                                            ..._.pick(configuration.patient, [
                                                'inbox_user_id',
                                                'pharmacy_id',
                                                'location_id',
                                            ]),
                                            start_date: configuration.datetime.utc().format('YYYY-MM-DD HH:mm:SS.sss'),
                                            end_date: moment(configuration.datetime)
                                                .utc()
                                                .add(appointmentLengthMinutes, 'minutes')
                                                .format('YYYY-MM-DD HH:mm:SS.sss'),
                                            status: 'ACTIVE',
                                            label: configuration.appointmentType.appointment_label,
                                        })
                                            .then((response) => {
                                                setIsSaving(false);
                                                onClose();
                                            })
                                            .catch((error) => {
                                                setShowError(
                                                    'An error occurred updating this reservation. Please try again later or contact Digital Pharmcist support if it continues.'
                                                );
                                                setIsSaving(false);
                                            });
                                    }
                                }}
                            >
                                {isSaving ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress size={18} />
                                        &nbsp;{isCreate ? 'Creating...' : 'Updating...'}
                                    </div>
                                ) : isCreate ? (
                                    'Create Appointment'
                                ) : (
                                    'Update Appointment'
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            </DialogContent>

            {!showDeleteConfirm ? null : (
                <ConfirmDeleteOverlay
                    setShowDeleteConfirm={setShowDeleteConfirm}
                    deleteAppointment={(calendarId, reservationId) => {
                        return updateReservation(calendarId, reservationId, { ...editing, status: 'DELETED' });
                    }}
                    calendar_id={editing.calendar_id}
                    reservation_id={editing.reservation_id}
                    onClose={onClose}
                />
            )}

            {!_.isNil(showError) && <ErrorSnackbar message={showError} onClose={() => setShowError(null)} />}
        </Dialog>
    );
}

function ErrorSnackbar({ message, onClose }) {
    return (
        <div className="appt-create-or-update-error-snackbar">
            <WarningIcon />
            <div className="message">{message}</div>
            <Button onClick={onClose}>OK</Button>
        </div>
    );
}

function ConfirmDeleteOverlay({ setShowDeleteConfirm, deleteAppointment, calendar_id, reservation_id, onClose }) {
    const [deleting, setDeleting] = useState(false);
    return (
        <div className="confirm-delete-overlay" onClick={() => setShowDeleteConfirm(false)}>
            <div className="confirm-delete" onClick={(e) => e.stopPropagation()}>
                <div className="heading">Really delete this Appointment?</div>
                <div className="explanation">
                    Are you sure you want to completely remove this appointment from your calendar?
                </div>
                <div className="buttons">
                    <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setDeleting(true);
                            deleteAppointment(calendar_id, reservation_id).then(() => onClose());
                        }}
                    >
                        {!deleting ? (
                            'Delete Appointment'
                        ) : (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={15} color="secondary" />
                                &nbsp;Deleting...
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function isSavable(configuration) {
    return (
        !_.isNil(configuration.patient) && !_.isNil(configuration.appointmentType) && !_.isNil(configuration.datetime)
    );
}

function NavBarEntry({ icon, label, sublabel, focused, completed, onClick, disabled }) {
    return (
        <div className={classnames({ 'nav-bar-entry': true, focused, disabled })} onClick={disabled ? null : onClick}>
            {!completed ? null : <div className="completed-bar" />}

            {_.isNil(icon) ? null : <div className="icon-wrapper">{icon}</div>}

            <div className="labels">
                <div className="main-label">{label}</div>
                <div className="sub-label">{sublabel}</div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { inboxConversationTemplate, apptCalendar, apptReservation, patientData, auth, launchDarkly } = state;

    return {
        initialAppointmentTypes: getAppointmentTypesFromConversationTemplates(
            inboxConversationTemplate,
            _.get(apptCalendar, 'availableCalendars.calendars')
        ),
        apptCalendar,
        apptReservation,
        patientData,
        userId: _.get(auth, 'userAccount.user_id'),
        displayedDateFormat: getAbbreviatedDateInputFormatByCountryCode({
            countryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        }),
        calendars: _.filter(
            _.get(state.apptCalendar, 'availableCalendars.calendars', []),
            ({ status }) => status === 'ACTIVE'
        ),
        showMultiAppointmentUx: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {
    fetchAvailableAppointmentCalendars,
    getAvailableCalendarAppointmentSlots,
    createNewReservation,
    getCalendarReservations,
    loadPatientsByInboxUserIds,
    updateReservation,
    clearCalendarReservations,
    clearAvailableAppointmentSlots,
    checkinReservation,
    uncheckinReservation,
    getLiveReusableReservationSessions,
    updateReservationSession,
    createNewReservationSession,
};

export default connect(mapStateToProps, bindActionsToDispatch)(CreateOrEditAppointmentDialog);
