import React, { Fragment, useState, useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Card, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import { isUrl } from 'utils/helper';
import { CARD_MODE } from '../../../../../constants/Newsletter';

const CardImage = ({ imageData, onLoad, onResize }) => {
    const imageEl = useRef(null);

    useEffect(() => {
        const handleResize = _.debounce(() => {
            if (imageEl.current) {
                onResize(imageEl.current.offsetWidth);
            }
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return imageData && imageData.imgDataUrl ? (
        <img
            style={{ maxWidth: '100%', maxHeight: '297px' }}
            src={imageData.imgDataUrl}
            alt="card preview"
            onLoad={onLoad}
            ref={imageEl}
        />
    ) : null;
};

const CardHeader = ({ header, width }) =>
    header ? <div style={{ fontSize: '24px', width, wordBreak: 'break-word' }}>{header}</div> : null;

const CardAdditionalText = ({ additionalText, width }) =>
    additionalText ? (
        <div style={{ width, wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: additionalText }}></div>
    ) : null;

const CardButton = ({ buttonText, buttonLink }) =>
    buttonText ? (
        <div>
            <Button href={isUrl(buttonLink) ? buttonLink : ''} color="primary" target="_blank" variant="contained">
                {buttonText}
            </Button>
        </div>
    ) : null;

const NewsletterCard = (props) => {
    const { activeCard, mode } = props;
    const [maxTextWidth, setMaxTextWidth] = useState('100%');

    useEffect(() => {
        if (!activeCard.imageData) {
            setMaxTextWidth('100%');
        }
    }, [activeCard.imageData]);

    const onCardImageLoad = (event) => {
        setMaxTextWidth(event.target.offsetWidth);
    };

    const onImageResize = (width) => {
        setMaxTextWidth(width);
    };

    const EmptyCardContainer = () => (
        <div className="empty-card-container centered-content">
            <Grid container className="empty-card-content" justify="center">
                <Grid item xs={12} className="centered-content standard-margin-bottom-10">
                    <img src="/newsletter/empty_card.png" width="222" height="222" alt="Empty" />
                </Grid>
                <Grid item xs={12} className="text-align-center standard-margin-bottom-10">
                    This card has no content
                </Grid>
                <Grid item xs={12} className="text-align-center">
                    Choose from the options on the left to begin creating your card
                </Grid>
            </Grid>
        </div>
    );

    const isCardEmpty =
        !activeCard.imageData && !activeCard.header && !activeCard.additionalText && !activeCard.buttonText;

    const title = mode === CARD_MODE.ADD ? 'Add Card' : 'Edit Card';

    return (
        <div className="newsletter-card">
            <Typography className="title"> {title} </Typography>
            <Card style={{ minHeight: '300px' }}>
                <CardContent className="content">
                    {isCardEmpty ? (
                        <EmptyCardContainer />
                    ) : (
                        <Fragment>
                            <CardHeader header={activeCard.header} width={maxTextWidth} />
                            <CardImage
                                imageData={activeCard.imageData}
                                onLoad={onCardImageLoad}
                                onResize={onImageResize}
                            />
                            <CardAdditionalText additionalText={activeCard.additionalText} width={maxTextWidth} />
                            <CardButton buttonText={activeCard.buttonText} buttonLink={activeCard.buttonLink} />
                        </Fragment>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeCard: state.newsletter.activeCard,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCard);

export { CardImage, CardHeader, CardAdditionalText, CardButton };
