import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import List from '@material-ui/core/List';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import ContactListItem from '../ContactListItem';
import ActivityListSearch from './ActivityListSearch';
import AddPatientMenu from '../AddPatientMenu';
import ActivityListFilter from './ActivityListFilter';
import { userAction } from '../../../redux/Inbox/User';
import { inboxFilterOptions } from '../../../constants/Inbox';
import ActivityOnboardingHeader from './ActivityOnboardingHeader';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

class ActivityList extends Component {
    constructor(props) {
        super(props);
        this.scrollElement = null;
    }

    handleScroll = debounce(() => {
        const {
            user: { searchQuery, searchFilter },
            getUsers,
            searchUsers,
        } = this.props;
        if (this.scrollElement) {
            const isBottom =
                this.scrollElement.scrollHeight - this.scrollElement.offsetHeight - this.scrollElement.scrollTop < 1;
            if (isBottom) {
                if (!searchQuery && !searchFilter) {
                    // Attempt to get next page of contacts
                    getUsers(false, true, true);
                } else {
                    // Attempt to get next page of search/filter
                    searchUsers(true);
                }
            }
        }
    }, 300);

    componentDidMount() {
        this.scrollElement = document.getElementById('contact-activity-list');
        this.scrollElement.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        this.scrollElement.removeEventListener('scroll', this.handleScroll);
    }

    generateContactComponentList = (contactList) => {
        const {
            user: { userMap, activeUserId },
            setActiveUser,
            location: { pathname },
            activeLocationCountryCode,
        } = this.props;
        return contactList.map((contactId) => (
            <ContactListItem
                contact={userMap[contactId]}
                key={contactId}
                active={contactId === activeUserId && pathname !== '/inbox/onboarding'}
                setActive={setActiveUser}
                activeLocationCountryCode={activeLocationCountryCode}
            />
        ));
    };

    render() {
        const {
            user,
            createUser,
            setSearchFilter,
            resetSearchFilter,
            setSearchQuery,
            resetSearchQuery,
            vmPhone,
            auth: { selectedLocation },
            location: { pathname },
        } = this.props;
        let contactComponents;
        if ((user.searchQuery && user.searchQuery.length > 2) || user.searchFilter) {
            contactComponents = this.generateContactComponentList(user.searchList);
        } else {
            contactComponents = this.generateContactComponentList(user.userList);
        }

        const activitySkeleton = [...Array(25)].map((e, i) => (
            <ReactPlaceholder
                key={i}
                type="media"
                rows={2}
                ready={false}
                showLoadingAnimation
                firstLaunchOnly
                style={{
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '16px',
                }}
            >
                <div></div>
            </ReactPlaceholder>
        ));

        return (
            <Fragment>
                <List className="activity-list-container">
                    <div className="contact-activity-header">
                        <ActivityOnboardingHeader pathname={pathname} />
                        <div className="contact-activity-header-item">
                            <Typography
                                variant="h5"
                                color="inherit"
                                style={{
                                    flexGrow: 1,
                                    fontSize: '24px',
                                    color: '#4a4a4a',
                                }}
                            >
                                Patients
                            </Typography>
                            <ActivityListFilter
                                searchFilter={user.searchFilter}
                                setSearchFilter={setSearchFilter}
                                resetSearchFilter={resetSearchFilter}
                            />
                            <AddPatientMenu
                                perms={selectedLocation.perms}
                                sending={user.sending}
                                requestError={user.createUserMessage}
                                createContact={createUser}
                                vmPhone={vmPhone}
                            />
                        </div>

                        {user.searchFilter && (
                            <div style={{ padding: '0 16px 16px' }}>
                                <Chip
                                    color="primary"
                                    size="small"
                                    label={inboxFilterOptions[user.searchFilter]['text']}
                                    onDelete={resetSearchFilter}
                                />
                            </div>
                        )}

                        <div className="contact-activity-header-item">
                            <ActivityListSearch
                                searchQuery={user.searchQuery}
                                setSearchQuery={setSearchQuery}
                                resetSearchQuery={resetSearchQuery}
                            />
                        </div>
                    </div>
                    <div className="contact-activity-list" id="contact-activity-list">
                        {user.userList.length > 0 ? (
                            <Fragment>
                                {contactComponents}
                                {user.loading && <CircularProgress className="conversation-message-list__progress" />}
                            </Fragment>
                        ) : (
                            <Fragment>{user.loading && activitySkeleton}</Fragment>
                        )}
                    </div>
                </List>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { inboxUser, auth } = state;
    return {
        user: inboxUser,
        auth,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = (dispatch) => ({
    setActiveUser: (userId) => dispatch(userAction.setActiveUser(userId)),
    getUsers: (autoSelectUser = false, nextPage = false, ignoreAlreadyLoading = false) =>
        dispatch(userAction.getUsers(autoSelectUser, nextPage, ignoreAlreadyLoading)),
    searchUsers: (nextPage = false) => dispatch(userAction.searchUsers(nextPage)),

    setSearchQuery: (searchQuery) => dispatch(userAction.setSearchQuery(searchQuery)),
    resetSearchQuery: () => dispatch(userAction.resetSearchFilter()),
    setSearchFilter: (searchFilter) => dispatch(userAction.setSearchFilter(searchFilter)),
    resetSearchFilter: () => dispatch(userAction.resetSearchFilter()),
});

export default connect(mapStateToProps, bindActionsToDispatch)(ActivityList);
