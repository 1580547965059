import './WhenToScheduleContent.sass';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import DateSelectionCalendar from 'components/Appointments/DateSelectionCalendar';
import moment from 'moment';
import classnames from 'classnames';
import { Button, Tooltip, IconButton, FormControlLabel, Switch } from '@material-ui/core';
import AppointmentLengthSelect from 'components/Appointments/AppointmentLengthSelect';
import Snowplow, { StructEventCategories } from 'snowplow';
import CalendarSelect from 'components/Appointments/CalendarSelect';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';

function WhenToScheduleContentMulti({
    timeRange,
    setTimeRange,
    calendars,
    calendar,
    appointmentLengthMinutes,
    setAppointmentLengthMinutes,
    reservationSession,
    setReservationSession,
    setCalendarId,
    onNext,
    isEdit,
}) {
    const [availableOnWeb, setAvailableOnWeb] = useState(_.get(reservationSession, 'available_on_web') === 1);
    // simple update helper that will set all of the passed paths to the passed values
    const updateTimeRange = (updates) => {
        const clone = _.cloneDeep(timeRange);
        _.each(_.filter(updates), ([path, value]) => {
            _.set(clone, path, value);
        });

        if (!_.isNil(reservationSession)) {
            const reservationSessionClone = _.cloneDeep(reservationSession);

            reservationSessionClone.start_date = _.isNil(clone.from.date) ? null : clone.from.date.format('YYYY-MM-DD');
            reservationSessionClone.end_date = _.isNil(clone.to.date) ? null : clone.to.date.format('YYYY-MM-DD');
            reservationSessionClone.available_on_web = availableOnWeb ? 1 : 0;

            setReservationSession(reservationSessionClone);
        } else {
            setTimeRange(clone);
        }

        Snowplow.structEvent(
            StructEventCategories.appointments,
            'schedule-workflow-date-change',
            null,
            '',
            'selected-dates',
            `${_.isNil(_.get(clone, 'from.date')) ? null : _.get(clone, 'from.date').format('YYYY-MM-DD')}, ` +
                `${_.isNil(_.get(clone, 'to.date')) ? null : _.get(clone, 'to.date').format('YYYY-MM-DD')}`
        );
    };

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - When');
    }, []);

    const selectedMinutes = _.isNil(reservationSession) ? appointmentLengthMinutes : reservationSession.expected_length;

    return (
        <div className="when-to-schedule-content" style={{ padding: '20px 20px 0 20px' }}>
            <div className="heading">How should these appointments be booked?</div>

            <div className="sub-heading">
                Specify the calendar, length, and if this appointment block should be offered on your website (if you
                have enabled website scheduling)
            </div>

            <div className="general-booking-controls">
                <FormControlLabel
                    className={classnames({ 'on-web-switch': true })}
                    control={
                        <Switch
                            checked={availableOnWeb}
                            onChange={(e) => {
                                setAvailableOnWeb(e.target.checked);
                                if (!_.isNil(reservationSession)) {
                                    setReservationSession({
                                        ...reservationSession,
                                        available_on_web: e.target.checked ? 1 : 0,
                                    });
                                }
                            }}
                            color="primary"
                        />
                    }
                    label="Available on web?"
                />

                <CalendarSelect
                    calendars={calendars}
                    value={_.get(calendar, 'calendar_id')}
                    disabled={isEdit}
                    onChange={(e) => {
                        setCalendarId(e.target.value);
                        if (!_.isNil(reservationSession)) {
                            const clone = _.cloneDeep(reservationSession);
                            clone.calendar_id = e.target.value;
                            setReservationSession(clone);
                        }
                    }}
                />

                <AppointmentLengthSelect
                    disabled={isEdit}
                    value={selectedMinutes}
                    onChange={(e) => {
                        setAppointmentLengthMinutes(Number(e.target.value));
                        if (!_.isNil(reservationSession)) {
                            const clone = _.cloneDeep(reservationSession);
                            clone.expected_length = Number(e.target.value);
                            setReservationSession(clone);
                        }
                    }}
                />
            </div>

            <div className="heading">Limit when these appointments may be booked?</div>

            <div className="sub-heading">
                While these appointments will be bookable based on the weekly schedule and vacations set on the
                configured calendar, you may further limit their availability by specifying an optional start and end
                date below.
            </div>

            <div className="calendars">
                <div className="start-calendar">
                    <div className="calendar-heading">
                        Available
                        <span className="focused-date-value">
                            {_.isNil(_.get(timeRange, 'from.date')) ? (
                                'immediately'
                            ) : (
                                <span className="date-and-cancel">
                                    {_.get(timeRange, 'from.date').format('MMM Do, YYYY')}
                                    <Tooltip title="Clear this date">
                                        <IconButton
                                            onClick={() => {
                                                updateTimeRange([['from.date', null]]);
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            )}
                        </span>
                    </div>
                    <DateSelectionCalendar
                        focusedDate={_.isNil(_.get(timeRange, 'from.date')) ? null : _.get(timeRange, 'from.date')}
                        onDateChange={(d) => {
                            updateTimeRange([['from.date', d]]);
                        }}
                        minSelectableDate={
                            _.isNil(reservationSession)
                                ? moment()
                                : moment(reservationSession.start_date, 'YYYY-MM-DD').isBefore(moment(), 'day')
                                ? moment(reservationSession.start_date, 'YYYY-MM-DD')
                                : moment()
                        }
                        maxSelectableDate={_.get(timeRange, 'to.date')}
                        initiallyFocusedMonth={moment()}
                    />
                </div>

                <div className="end-calendar">
                    <div className="calendar-heading">
                        Until
                        <span className="focused-date-value">
                            {_.isNil(_.get(timeRange, 'to.date')) ? (
                                'this is disabled'
                            ) : (
                                <span className="date-and-cancel">
                                    {_.get(timeRange, 'to.date').format('MMM Do, YYYY')}
                                    <Tooltip title="Clear this date">
                                        <IconButton
                                            onClick={() => {
                                                updateTimeRange([['to.date', null]]);
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            )}
                        </span>
                    </div>
                    <DateSelectionCalendar
                        focusedDate={_.isNil(_.get(timeRange, 'to.date')) ? null : _.get(timeRange, 'to.date')}
                        onDateChange={(d) => {
                            updateTimeRange([['to.date', d]]);
                        }}
                        minSelectableDate={
                            _.isNil(_.get(timeRange, 'from.date')) ? moment() : _.get(timeRange, 'from.date')
                        }
                        initiallyFocusedMonth={moment()}
                    />
                </div>
            </div>

            <div className="buttons-row">
                <Button
                    className="next-button"
                    variant="contained"
                    color="primary"
                    disabled={_.isNil(calendar) || !_.isFinite(selectedMinutes)}
                    onClick={() => {
                        /*Snowplow.structEvent(
                                StructEventCategories.appointments,
                                'schedule-workflow-when-next-clicked',
                                [
                                    new AppointmentWorkflowSessionContext()
                                        .setTemplateId(template.inbox_conversation_template_id)
                                        .setStartDate(_.get(timeRange, 'from.date').format('YYYY-MM-DD'))
                                        .setEndDate(_.get(timeRange, 'to.date').format('YYYY-MM-DD'))
                                        .build(),
                                ]
                            );*/
                        onNext();
                    }}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { launchDarkly } = state;

    return {
        showAvailableOnWeb: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(WhenToScheduleContentMulti);
