export default {
    ADD_NEW_PATIENT_LINE_GREETING: 'ADD_NEW_PATIENT_LINE_GREETING',
    UPDATE_NEW_PATIENT_LINE_GREETING: 'UPDATE_NEW_PATIENT_LINE_GREETING',
    CLOSE_NEW_PATIENT_LINE_GREETING_EDITOR: 'CLOSE_NEW_PATIENT_LINE_GREETING_EDITOR',
    SAVE_NEW_PATIENT_LINE_GREETING_STARTED: 'SAVE_NEW_PATIENT_LINE_GREETING_STARTED',
    SAVE_NEW_PATIENT_LINE_GREETING_SUCCESS: 'SAVE_NEW_PATIENT_LINE_GREETING_SUCCESS',
    SAVE_NEW_PATIENT_LINE_GREETING_FAILED: 'SAVE_NEW_PATIENT_LINE_GREETING_FAILED',
    EDIT_NEW_PATIENT_LINE_GREETING: 'EDIT_NEW_PATIENT_LINE_GREETING',
    CHANGE_NEW_PATIENT_LINE_GREETING_TYPE: 'CHANGE_NEW_PATIENT_LINE_GREETING_TYPE',
    CHANGE_NEW_PATIENT_LINE_GREETING_SCRIPT: 'CHANGE_NEW_PATIENT_LINE_GREETING_SCRIPT',
};
