import React, { Fragment } from 'react';
import { ListItem, Badge, ListItemAvatar, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { stringToColor } from '../../utils/helper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const NoInboxPharmacyActivityList = () => {
    return (
        <Grid item xs={12} md={3} className="inbox-side-grid inbox-full-height-scroll-wrapper">
            <List
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 80px)',
                    overflow: 'auto',
                }}
            >
                <div className="contact-activity-header" style={{ paddingTop: '22px' }}>
                    <div className="contact-activity-header-item">
                        <Typography
                            variant="h5"
                            color="inherit"
                            style={{
                                flexGrow: 1,
                                fontSize: '24px',
                                color: '#4a4a4a',
                            }}
                        >
                            Patients
                        </Typography>
                        <AddCircleOutlineIcon style={{ color: 'BDBDBD' }} />
                    </div>
                    <div className="contact-activity-header-item">
                        <TextField
                            id="contact-search-box"
                            placeholder="Search for a contact..."
                            type="search"
                            className="contact-search-box"
                            margin="none"
                            variant="outlined"
                            fullWidth
                            classes={{
                                root: 'contact-search-box',
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: 'contact-search-box__input',
                                    focused: 'contact-search-box__input--focused',
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="contact-activity-list" id="contact-activity-list">
                    <ListItem button divider>
                        <Badge
                            color="secondary"
                            variant="dot"
                            classes={{
                                badge: 'inbox-activity-avatar-badge',
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    style={{
                                        backgroundColor: stringToColor('Digital Pharmacist'),
                                    }}
                                >
                                    DP
                                </Avatar>
                            </ListItemAvatar>
                        </Badge>
                        <ListItemText
                            primary={
                                <div className="contact-list-item__primary-text">
                                    <Typography
                                        variant="subtitle1"
                                        noWrap
                                        className="contact-list-item__primary-name"
                                        color="inherit"
                                    >
                                        Digital Pharmacist
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        noWrap
                                        className="contact-list-item__secondary-date"
                                    ></Typography>
                                </div>
                            }
                            secondary={
                                <Fragment>
                                    <span className="contact-list-item__secondary-text"></span>
                                    Welcome to Inbox!
                                </Fragment>
                            }
                        />
                    </ListItem>
                </div>
            </List>
        </Grid>
    );
};

export default NoInboxPharmacyActivityList;
