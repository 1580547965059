const weekDayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
};

const hoursDataPlaceholder = {
    id: '0',
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0,
    dirty: true,
};

const daysOfWeek = new Array(7).fill(0).map((val, idx) => ({ day: idx + 1, ...hoursDataPlaceholder }));

const holiday = {
    id: '0',
    name: 'New Holiday',
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0,
    startDate: '',
    endDate: '',
    holidayId: '',
    locationId: '',
    repeats: false,
    dirty: true,
};

export { weekDayMap, holiday, daysOfWeek };
