import React from 'react';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import { appTypeBranded, buildStatusTypes } from './Constants';

export const AppNameWithAlert = ({ app, pharmacy }) => {
    const errorMessage = 'This app needs attention! Please visit Config2 for more information.';
    const { buildFailed, updateFailed, buildRejected, updateRejected, actionNeeded } = buildStatusTypes;
    const failureStates = [buildFailed, updateFailed, buildRejected, updateRejected, actionNeeded];

    let appNeedsAttention = false;
    if (app.appType === appTypeBranded) {
        // Note: The file information of an app can be found in pharmacy.app. It has not been included
        // as part of fetchPharmacyApps response because it called for lot of duplicate code.
        const brandedApp = _.find(pharmacy.apps, (pharmacyApp) => pharmacyApp.id === app.id) || {};
        const isAppIconMissing = _.isEmpty(_.get(brandedApp, 'file.Icon.publicURL', ''));
        const isAndroidBuildFailed = _.indexOf(failureStates, _.get(app, 'appRelease.android[0].build_status')) !== -1;
        const isAppleBuildFailed = _.indexOf(failureStates, _.get(app, 'appRelease.apple[0].build_status')) !== -1;
        appNeedsAttention = isAppIconMissing || isAndroidBuildFailed || isAppleBuildFailed;
    }

    return appNeedsAttention ? (
        <div className="flex">
            <span>{`${app.name}`}</span>
            <Tooltip title={errorMessage} classes={{ tooltip: 'info-tooltip' }}>
                <Icon color="secondary" style={{ marginLeft: '6px', fontSize: '16px' }}>
                    error_outlined
                </Icon>
            </Tooltip>
        </div>
    ) : (
        <span>{app.name}</span>
    );
};
