import React, { PureComponent, Fragment } from 'react';
import { Card, CardText, Grid, Cell } from 'react-md';
import Dialog from '@material-ui/core/Dialog';

import { formatToCurrency } from 'utils/helper';
import ROICalculator from 'components/ROICalculator';
import ReportTitle from 'components/Report/Common/ReportTitle';

const TITLES_MAP = {
    valueOfScriptsProcessed: 'Value of Scripts Processed',
    valueOfNewPatients: 'Value of New Patients',
    valueOfSupport: 'Value of Support',
    grossProfitOfScriptsProcessed: 'Gross Profit of Scripts Processed',
    valueOfAutomation: 'Value of Automation',
};

const mapData = (dataToMap) => {
    if (Object.keys(dataToMap).length === 0 || !dataToMap) return { data: [] };

    const mappedData = Object.keys(TITLES_MAP).map((t) => {
        return {
            title: TITLES_MAP[t],
            value: dataToMap[t],
        };
    });

    return mappedData;
};

const DataCard = ({ isPharmacyDataFetched, data, openROIModal, tooltipText }) => (
    <Card className="reportCard valueCalculator">
        <ReportTitle title="Your Value Calculator" className="cardTitle" tooltipText={tooltipText}>
            {isPharmacyDataFetched && (
                <div className="order1">
                    <span className="cust-btn user-clickable" onClick={openROIModal}>
                        CUSTOMIZE
                    </span>
                </div>
            )}
        </ReportTitle>
        <CardText className="flex-grow-1">
            <Grid className="height100 flex-middle">
                {data.map((d) => (
                    <Cell size={6} key={d.title} tabletSize={4}>
                        <div className="title text-align-left">{d.title}</div>
                        <div className="value text-align-left">{formatToCurrency(d.value, '$')}</div>
                    </Cell>
                ))}
            </Grid>
        </CardText>
    </Card>
);

class ValueCalculator extends PureComponent {
    defaultPharmacyDataValues = {
        refillCountValue: 34,
        outboundAlertsValue: 40,
        newPatientTransfersValue: 10,
    };

    state = {
        data: [],
        roiModalVisible: false,
        // pharmacy data values
        ...this.defaultPharmacyDataValues,
        // sliding roi values
        employeeWagePerHourValue: 10,
        avgProfitPerRXValue: 12,
        avgRevenuePerRXValue: 25,
        annualAvgRevenuePerPatientValue: 20,
        minutesSavedFromAutomationValue: 15,
        socialMediaValue: 30,
        hoursPerMonthWorkValue: 10,
        valueOfScriptsProcessed: 0,
        valueOfNewPatients: 0,
        valueOfSupport: 0,
        grossProfitOfScriptsProcessed: 0,
        valueOfAutomation: 0,
        usePharmacyData: false,
    };

    componentDidMount() {
        this.calculateROIValues();
    }

    handleROIModalClose = () => {
        this.setState({
            roiModalVisible: false,
        });
    };

    handleROIModalOpen = () => {
        this.setState({
            roiModalVisible: true,
        });
    };

    calculateROIValues = () => {
        const {
            avgRevenuePerRXValue,
            refillCountValue,
            annualAvgRevenuePerPatientValue,
            newPatientTransfersValue,
            dmLinkChecksValue,
            socialMediaValue,
            avgProfitPerRXValue,
            outboundAlertsValue,
            minutesSavedFromAutomationValue,
            employeeWagePerHourValue,
            hoursPerMonthWorkValue,
        } = this.state;

        const valueOfScriptsProcessed = avgRevenuePerRXValue * refillCountValue;

        const valueOfNewPatients = annualAvgRevenuePerPatientValue * newPatientTransfersValue;

        const mediaSalesValue = dmLinkChecksValue * socialMediaValue;

        const grossProfitOfScriptsProcessed = avgProfitPerRXValue * refillCountValue;

        const valueOfAutomation =
            ((refillCountValue + newPatientTransfersValue + outboundAlertsValue) *
                minutesSavedFromAutomationValue *
                employeeWagePerHourValue) /
            60;

        const valueOfSupport = 25 * hoursPerMonthWorkValue;

        this.setState(
            {
                valueOfScriptsProcessed,
                valueOfNewPatients,
                mediaSalesValue,
                grossProfitOfScriptsProcessed,
                valueOfAutomation,
                valueOfSupport,
            },
            () => {
                const mappedData = mapData({
                    valueOfScriptsProcessed,
                    valueOfNewPatients,
                    mediaSalesValue,
                    grossProfitOfScriptsProcessed,
                    valueOfAutomation,
                    valueOfSupport,
                });
                this.setState({ data: mappedData });
            }
        );
    };

    handleCalculatorChange = (value, event, id) => {
        const parsedValue = parseFloat(value);
        const idForStateValue = id ? id : event.target.id;
        this.setState(
            {
                ...this.state,
                [idForStateValue]: parsedValue >= 0 ? parsedValue : 0,
            },
            () => {
                this.calculateROIValues();
            }
        );
    };

    onUsePharmacyDataToggle = () => {
        const { usePharmacyData } = this.state;
        const { pharmacyData } = this.props;

        const newValues = {
            ...this.defaultPharmacyDataValues,
        };

        if (!usePharmacyData) {
            newValues.refillCountValue = pharmacyData.refillCount;
            newValues.outboundAlertsValue = pharmacyData.outboundAlertCount;
            newValues.newPatientTransfersValue = pharmacyData.patientTransferCount;
        }
        this.setState(
            {
                ...this.state,
                ...newValues,
                usePharmacyData: !usePharmacyData,
            },
            () => this.calculateROIValues()
        );
    };

    render() {
        const {
            data,
            roiModalVisible,
            refillCountValue,
            newPatientTransfersValue,
            outboundAlertsValue,
            employeeWagePerHourValue,
            avgProfitPerRXValue,
            avgRevenuePerRXValue,
            annualAvgRevenuePerPatientValue,
            minutesSavedFromAutomationValue,
            socialMediaValue,
            hoursPerMonthWorkValue,
            valueOfScriptsProcessed,
            valueOfNewPatients,
            mediaSalesValue,
            grossProfitOfScriptsProcessed,
            valueOfAutomation,
            valueOfSupport,
            usePharmacyData,
        } = this.state;
        const { tooltipText, pharmacyData, ldValueCalculatorEnabled } = this.props;

        return (
            <Fragment>
                <DataCard
                    data={data}
                    isPharmacyDataFetched={pharmacyData.isPharmacyDataFetched}
                    openROIModal={this.handleROIModalOpen}
                    tooltipText={tooltipText}
                />
                <Dialog
                    aria-labelledby="roiCalculator"
                    id="roiCalculator"
                    fullWidth={true}
                    maxWidth="lg"
                    open={roiModalVisible}
                    onClose={this.handleROIModalClose}
                    className="ROICalculator"
                >
                    <ROICalculator
                        refillCountValue={refillCountValue}
                        newPatientTransfersValue={newPatientTransfersValue}
                        outboundAlertsValue={outboundAlertsValue}
                        employeeWagePerHourValue={employeeWagePerHourValue}
                        avgProfitPerRXValue={avgProfitPerRXValue}
                        avgRevenuePerRXValue={avgRevenuePerRXValue}
                        annualAvgRevenuePerPatientValue={annualAvgRevenuePerPatientValue}
                        minutesSavedFromAutomationValue={minutesSavedFromAutomationValue}
                        socialMediaValue={socialMediaValue}
                        hoursPerMonthWorkValue={hoursPerMonthWorkValue}
                        valueOfScriptsProcessed={valueOfScriptsProcessed}
                        valueOfNewPatients={valueOfNewPatients}
                        mediaSalesValue={mediaSalesValue}
                        grossProfitOfScriptsProcessed={grossProfitOfScriptsProcessed}
                        valueOfAutomation={valueOfAutomation}
                        valueOfSupport={valueOfSupport}
                        hideModal={this.handleROIModalClose}
                        handleChange={this.handleCalculatorChange}
                        ldValueCalculatorEnabled={ldValueCalculatorEnabled}
                        usingPharmacyData={usePharmacyData}
                        pharmacyData={pharmacyData}
                        toggleUsePharmacyData={this.onUsePharmacyDataToggle}
                    />
                </Dialog>
            </Fragment>
        );
    }
}

export default ValueCalculator;
