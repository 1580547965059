export default {
    UPDATE_PHARMACY_BASIC_DETAILS_STARTED: 'UPDATE_PHARMACY_BASIC_DETAILS_STARTED',
    UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS: 'UPDATE_PHARMACY_BASIC_DETAILS_SUCCESS',
    UPDATE_PHARMACY_BASIC_DETAILS_FAILED: 'UPDATE_PHARMACY_BASIC_DETAILS_FAILED',
    GET_ADDRESS_BY_ZIP_STARTED: 'GET_ADDRESS_BY_ZIP_STARTED',
    GET_ADDRESS_BY_ZIP_SUCCESS: 'GET_ADDRESS_BY_ZIP_SUCCESS',
    GET_ADDRESS_BY_ZIP_FAILED: 'GET_ADDRESS_BY_ZIP_FAILED',
    GET_LOCATION_IDENTIFIER_STARTED: "GET_LOCATION_IDENTIFIER_STARTED",
    GET_LOCATION_IDENTIFIER_SUCCESS: "GET_LOCATION_IDENTIFIER_SUCCESS",
    GET_LOCATION_IDENTIFIER_FAILED: "GET_LOCATION_IDENTIFIER_FAILED"
};
