import { socketAction } from '.';
import { pharmacyType } from '../Pharmacy';
import authType from '../actions/Auth';
import { buildRoomUri } from 'utils/helper';
import { isMobile } from 'react-device-detect';

const socketConnectActions = [authType.FETCH_USER_ACCOUNT_SUCCESS, authType.LOGIN_SUCCESS];

const socketMiddleware = (store) => (next) => (action) => {
    if (!isMobile && socketConnectActions.indexOf(action.type) > -1 && socketAction.isDisconnected()) {
        store.dispatch(socketAction.initializeSocket());
    } else if (action.type === authType.LOGOUT) {
        // Disable disconnect on logout to allow notifications to continue
        // store.dispatch(socketAction.disconnectSocket());
    } else if (action.type === pharmacyType.UPDATE_ACTIVE_LOCATION) {
        if (action.activeLocationId) {
            const { pharmacy, auth } = store.getState();
            // Get the new locationId from pharmacy action
            const newLocationId = action.activeLocationId;
            // Get the old locationId from pharmacy store
            const oldLocationId = pharmacy.activeLocationId;
            if (auth.isAuthenticated && newLocationId !== oldLocationId && !isMobile) {
                // Build the new roomUri
                const newRoomUri = buildRoomUri(newLocationId);
                // Dispatch the actions
                store.dispatch(socketAction.joinRoom(newRoomUri));
                if (oldLocationId) {
                    // Build the old roomUri
                    const oldRoomUri = buildRoomUri(oldLocationId);
                    // Leave the old room
                    store.dispatch(socketAction.leaveRoom(oldRoomUri));
                }
            }
        }
    }

    next(action);
};

export default socketMiddleware;
