import Config from 'config';
import { logUtil } from 'utils/log';
import { restActionCreators } from 'redux/actionCreators/rest';

const fetchNonBrandedApps = () => {
    const url = `${Config.config2_url}/app`;
    const args = {
        params: {
            startRow: 0,
            numRows: 25, // Right now there are only handful # of apps, this can be revisited for pagination at a later point of time
            status: 'Enabled',
            appleAppStatus: 'live',
            androidAppStatus: 'live',
            excludeAppType: 'branded',
        },
    };

    return restActionCreators
        .get(url, args)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while fetching non branded apps.`, error);
            return Promise.reject(error);
        });
};

const fetchPharmacyApps = ({ custId }) => {
    const url = `${Config.config2_url}/cust/${custId}/app`;
    const args = {
        params: {
            startRow: 0,
            numRows: 10,
        },
    };

    return restActionCreators
        .get(url, args)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while fetching pharmacy apps.`, error);
            return Promise.reject(error);
        });
};

const addAppsToPharmacy = ({ custId, appIds }) => {
    const url = `${Config.config2_url}/cust/${custId}/app`;

    return restActionCreators
        .post(url, { appIds })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while adding apps to pharmacy.`, error);
            return Promise.reject(error);
        });
};

const addBrandedAppToPharmacy = ({ payload }) => {
    const url = `${Config.config2_url}/app`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while adding branded app to pharmacy`, error);
            return Promise.reject(error);
        });
};

const deletePharmacyApp = ({ custId, appId }) => {
    const url = `${Config.config2_url}/cust/${custId}/app/${appId}`;

    return restActionCreators
        .deleteReq(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while delete pharmacy app`, error);
            return Promise.reject(error);
        });
};

const fetchReleases = (platform) => {
    const url = `${Config.config2_url}/release`;
    const args = {
        params: {
            startRow: 0,
            numRows: 10, // Fetch the last 10 releases, we do not consider t
            mobileType: platform,
        },
    };

    return restActionCreators
        .get(url, args)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while fetching releases`, error);
            return Promise.reject(error);
        });
};

const updateBrandedAppGeneralDetails = ({ appId, payload }) => {
    const url = `${Config.config2_url}/app/${appId}`;

    return restActionCreators
        .put(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while updating branded app`, error);
            return Promise.reject(error);
        });
};

const addBrandedAppBranchInformation = ({ appId, payload }) => {
    const url = `${Config.config2_url}/app/${appId}/branch`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while adding branch info for app`, error);
            return Promise.reject(error);
        });
};

const updateBrandedAppBranchInformation = ({ appId, branchId, payload }) => {
    const url = `${Config.config2_url}/app/${appId}/branch/${branchId}`;

    return restActionCreators
        .put(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while updating branch info for app`, error);
            return Promise.reject(error);
        });
};

export const pharmacyAppsService = {
    addAppsToPharmacy,
    fetchNonBrandedApps,
    addBrandedAppToPharmacy,
    deletePharmacyApp,
    fetchReleases,
    fetchPharmacyApps,
    updateBrandedAppGeneralDetails,
    addBrandedAppBranchInformation,
    updateBrandedAppBranchInformation,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
