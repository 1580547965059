import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

export const StatsWidget = (props) => {
    const { title, icon, value, iconColor, isLoading } = props;
    return (
        <Card className="widget">
            <CardContent className="height100">
                {isLoading ? (
                    <div className="centered-content height100">
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    <Fragment>
                        <Typography className="title" gutterBottom>
                            {title}
                        </Typography>
                        <div className="centered-content flex-vertical-space-around" style={{ height: '180px' }}>
                            <Icon style={{ color: iconColor, fontSize: 100 }}> {icon}</Icon>
                            <Typography style={{ fontSize: '36px', fontWeight: 600, color: '#292929' }}>
                                {value}
                            </Typography>
                        </div>
                    </Fragment>
                )}
            </CardContent>
        </Card>
    );
};

export default StatsWidget;
