const getLeadSourceName = (source_name) => {
    if (!source_name) {
        return '-';
    }

    if (source_name === 'CallRail') {
        return 'Digital Marketing';
    }

    return source_name;
};

export const leadHelper = {
    getLeadSourceName,
};
