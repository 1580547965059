import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { getLocationNameByMConfigId } from '../../../utils/helper';
import { questionMap, ReviewCard, ReviewQuestionAnswerSet, ReviewRating } from './ReviewCommon';

function Review({ index, review, count, pharmacyData }) {
    const locationName = getLocationNameByMConfigId(review.location, pharmacyData);
    return (
        <div className="review-item" key={index}>
            {index > 0 && <Divider style={{ marginBottom: '16px' }} />}
            <div className="review-item__header">
                <span className="review-item__count">{count}</span>
                {locationName && <span className="review-item__location-name">{locationName}</span>}
                <Divider orientation={'vertical'} flexItem style={{ margin: '0 16px' }} />
                {moment(review.created * 1000).format('MM/DD/YYYY')}
            </div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <ReviewQuestionAnswerSet question={questionMap.question5} answer={review.question5} />
                    <ReviewQuestionAnswerSet question={questionMap.question6} answer={review.question6} />
                </Grid>
                <Grid item xs={12} sm={3} className="review-rating-group">
                    <ReviewRating question={questionMap.question1} rating={review.question1} />
                    <ReviewRating question={questionMap.question2} rating={review.question2} />
                </Grid>
                <Grid item xs={12} sm={3} className="review-rating-group">
                    <ReviewRating question={questionMap.question3} rating={review.question3} />
                    <ReviewRating question={questionMap.question4} rating={review.question4} />
                </Grid>
            </Grid>
        </div>
    );
}

Review.propTypes = {
    index: PropTypes.number.isRequired,
    review: PropTypes.shape({}).isRequired,
    count: PropTypes.number.isRequired,
    pharmacyData: PropTypes.shape({}).isRequired,
};

function ReviewPagination({ total, page, fetchList, loading }) {
    return (
        <Pagination
            count={Math.ceil(total / 5)}
            page={page + 1} // Convert to 1-based index
            variant="outlined"
            shape="rounded"
            disabled={loading}
            onChange={(event, page) => fetchList(page - 1)} // Convert to 0-based index
            style={{ marginLeft: 'auto' }}
        />
    );
}

function ReviewList({ list, page, total, fetchList, pharmacyData, loading }) {
    return (
        <ReviewCard
            title="Reviews"
            headerAction={<ReviewPagination total={total} page={page} fetchList={fetchList} loading={loading} />}
            footer={<ReviewPagination total={total} page={page} fetchList={fetchList} loading={loading} />}
            loadingContent={loading}
        >
            {list && list.length > 0 ? (
                list.map((review, idx) => {
                    // Note: review object doesn't contain an id/number
                    // Generate the id based on relative index compared to total items
                    const reviewCount = total - page * 5 - idx;
                    return (
                        <Review
                            key={reviewCount}
                            review={review}
                            count={reviewCount}
                            index={idx}
                            pharmacyData={pharmacyData}
                        />
                    );
                })
            ) : (
                <div>No reviews available</div>
            )}
        </ReviewCard>
    );
}

ReviewList.propTypes = {
    list: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    fetchList: PropTypes.func.isRequired,
    pharmacyData: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
};

export default ReviewList;
