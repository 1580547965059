import _ from 'lodash';

class EventHandlerClass {
    /**
     * Simplifies event removal so you don't have to maintain an exact reference to an element to remove it
     * (inspired by d3.js event system)
     */
    constructor() {
        this.functionMap = {};
    }

    addEventListener(event, func) {
        if (_.has(this.functionMap, event)) {
            this.removeEventListener(event);
        }
        this.functionMap[event] = func;
        document.addEventListener(event.split('.')[0], this.functionMap[event]);
    }

    removeEventListener(event) {
        document.removeEventListener(event.split('.')[0], this.functionMap[event]);
        delete this.functionMap[event];
    }
}

export default new EventHandlerClass();
