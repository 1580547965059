import React, { PureComponent } from 'react';
import { Card, CardTitle, Cell, Grid, CardText } from 'react-md';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import ListComponent from 'components/Common/ListComponent';
import AccountDetails from './AccountDetails';
import PharmacyDetails from './PharmacyDetails';
import RefillDetails from './RefillDetails';
import Language from './Language';
import FeaturesAndFunctions from './FeaturesAndFunctions';
import NotificationSettings from './Notification';
import UserManagement from './UserManagement';
import BannerConfiguration from './BannerConfiguration';

import { GeneralSettingsMenuItems } from 'constants/GeneralSettingsMenuItems';
import { pharmacyAction } from 'redux/Pharmacy/action';
import { updateDeliveryOptions } from 'redux/actionCreators/Settings/GeneralSettings/RefillDetails';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { Can, userActions, restrictedResources } from 'casl';

class GeneralSettings extends PureComponent {
    generalSettingsMenuItems = [...GeneralSettingsMenuItems];

    onItemClick = (to) => {
        this.props.history.push({ pathname: to, state: this.props.location.state });
    };

    render() {
        const title = 'General Settings';
        const subtitle = 'Manage pharmacy information for your app, website and IVR';
        const { view } = userActions;
        const { subject: caslSubject } = restrictedResources.generalSettings;

        return (
            <Grid>
                <Cell size={12}>
                    <Can I={view} this={caslSubject}>
                        <Card className="outer-card">
                            <CardTitle title={title} subtitle={subtitle} style={{ marginLeft: '16px' }} />
                            <CardText>
                                <ListComponent
                                    className="setting-list"
                                    list={this.generalSettingsMenuItems}
                                    onItemClick={this.onItemClick}
                                />
                            </CardText>
                        </Card>
                    </Can>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    pharmacy: {
        ...state.pharmacy.pharmacy,
        lastUpdated: state.pharmacy.lastUpdated,
        loading: state.pharmacy.loading,
    },
});

const mapDispatchToProps = (dispatch) => ({
    updateDeliveryOptions: (data) => dispatch(updateDeliveryOptions(data)),
    getPharmacy: (data) => dispatch(pharmacyAction.getPharmacy(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

const GeneralSettingsWrapper = (props) => {
    const {
        auth: { selectedLocation, isInternalUser, locationDetail },
        pharmacy,
        updateDeliveryOptions,
        getPharmacy,
        displayToast,
        history,
        location,
    } = props;
    return (
        <Switch>
            <Route
                exact
                path="/settings/general"
                render={() => <GeneralSettings isInternalUser={isInternalUser} history={history} location={location} />}
            />
            <Route
                path="/settings/general/notification"
                render={() => (
                    <NotificationSettings pharmacy={pharmacy} displayToast={displayToast} history={history} />
                )}
            />
            <Route path="/settings/general/account-details" component={AccountDetails} />
            <Route
                path="/settings/general/pharmacy-details"
                render={() => (
                    <PharmacyDetails
                        pharmacy={pharmacy}
                        selectedLocation={selectedLocation}
                        locationDetail={locationDetail}
                        displayToast={displayToast}
                    />
                )}
            />
            <Route
                path="/settings/general/refill-details"
                render={() => (
                    <RefillDetails
                        pharmacy={pharmacy}
                        selectedLocation={selectedLocation}
                        updateDeliveryOptions={updateDeliveryOptions}
                        getPharmacy={getPharmacy}
                        displayToast={displayToast}
                    />
                )}
            />
            <Route path="/settings/general/language" component={Language} />
            <Route path="/settings/general/features" component={FeaturesAndFunctions} />
            <Route path="/settings/general/users" component={UserManagement} />
            <Route
                path="/settings/general/banner-configuration"
                render={() => <BannerConfiguration pharmacy={pharmacy} history={history} />}
            />
        </Switch>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsWrapper);
