
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';

import { newsletterActions } from 'redux/Newsletter/action';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import CardPreview from 'components/Resources/Newsletter/Calendar/SelectCardDialog/CardPreview';
import CardPlaceholder from 'components/Resources/Newsletter/Calendar/SelectCardDialog/CardPlaceholder';

const CardNotFound = () => {
    return <Fragment>
        <div className="centered-content no-cards-found"> This preview is not available </div>
    </Fragment>
};

const ViewCardDialog = (props) => {
    const {
        card = {},
        visible,
        searchedCards,
        fetchCards,
        fetchingCards,
        onClose
    } = props;

    const previewCard = useMemo(() => {
        if (!searchedCards || !searchedCards.cards || !searchedCards.cards.length) {
            return null;
        }
        return searchedCards.cards.find(c => c.card_id === card.id);
    }, [searchedCards, card]);

    const noCardFound = !fetchingCards && !previewCard;
    const isCardRadyToView = !fetchingCards && Boolean(previewCard)

    useEffect(() => {
        if (card.id) {
            // fetch cards by title 
            fetchCards({
                filter_by_title: card.title,
            });
        }
    }, [card.id]);

    return (
        <DpDialog.Dialog id="viewCardDialog" open={visible} onClose={onClose} maxWidth="md">
            <DpDialog.Content className="view-card-dialog" >
                <DpDialog.ContentHeader
                    style={{ marginBottom: 20 }}
                    heading={`${card.title} Card Preview`}
                    onClose={onClose}
                />
                {noCardFound && <CardNotFound />}
                {fetchingCards &&
                    <CardPlaceholder legendsCoun={1} xs={12} height={200} />
                }
                {isCardRadyToView &&
                    <CardPreview cardData={previewCard} />
                }
            </DpDialog.Content>
        </DpDialog.Dialog>
    )
}


const mapStateToProps = (state) => ({
    fetchingCards: state.newsletter.fetchingCards,
    searchedCards: state.newsletter.searchedCards,
});

const mapDispatchToProps = {
    fetchCards: newsletterActions.fetchCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCardDialog);
