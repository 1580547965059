import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import SearchInput from 'components/Common/SearchInput';
import CardList from './CardList';
import CardPreview from './CardPreview';
import CardDelete from './CardDelete';
import { newsletterActions } from 'redux/Newsletter/action';
import { CARDS_PAGE_SIZE } from 'constants/Newsletter';

const SelectCardDialog = (props) => {
    const STEP_MAP = {
        LIST: 'list',
        PREVIEW: 'preview',
        DELETE: 'delete',
    };
    const {
        visible,
        onClose,
        allowedCardsCountToSelect = 3,
        recentCards,
        searchedCards,
        fetchingCards,
        fetchCards,
        fetchNewsletters,
        refetchNewsletters,
    } = props;
    const [currentStep, setCurrentStep] = useState(STEP_MAP.LIST);
    const [selectedCards, setSelectedCards] = useState([]);
    const [cardToPreview, setCardToPreview] = useState(null);
    const [cardToDelete, setCardToDelete] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (refetchNewsletters) {
            const { activeEvent } = props;
            const start_date = moment(activeEvent.date).startOf('month').format();
            const end_date = moment(activeEvent.date).endOf('month').format();
            fetchNewsletters({ start_date, end_date, isRefetch: true });
            fetchCards({
                page_size: CARDS_PAGE_SIZE,
            });
            setCardToDelete(null);
            setCurrentStep(STEP_MAP.LIST);
        }
    }, [refetchNewsletters]);

    useEffect(() => {
        // fetch most recent cards
        fetchCards({
            page_size: CARDS_PAGE_SIZE,
        });
    }, []);

    const checkForBottom = _.debounce((scrollElement) => {
        if (scrollElement) {
            const isBottom = scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop < 1;

            if (isBottom) {
                if (searchTerm.length > 2) {
                    // load more cards with search term
                    const { page_number, total_pages } = searchedCards;
                    if (page_number < total_pages) {
                        fetchCards({
                            page_size: CARDS_PAGE_SIZE,
                            page_number: page_number + 1,
                            filter_by_title: searchTerm,
                            loadMore: true,
                        });
                    }
                } else {
                    // load more recent cards
                    const { page_number, total_pages } = recentCards;
                    if (page_number < total_pages) {
                        fetchCards({
                            page_size: CARDS_PAGE_SIZE,
                            page_number: page_number + 1,
                            loadMore: true,
                        });
                    }
                }
            }
        }
    }, 300);

    const handleScroll = (e) => {
        const scrollElement = e.target;
        checkForBottom(scrollElement);
    };

    const getFilteredCards = (cardList) => {
        const { cardsToExcludeFromList } = props;

        return _.filter(cardList, (card) => _.indexOf(cardsToExcludeFromList, card.card_id) === -1);
    };

    const getTitle = () => {
        if (currentStep === STEP_MAP.PREVIEW) {
            return (
                <div>
                    <Button variant="text" onClick={() => setCurrentStep(STEP_MAP.LIST)}>
                        <KeyboardBackspaceIcon /> <span style={{ marginLeft: '5px' }}> Go Back </span>
                    </Button>
                    <span style={{ marginLeft: '20px' }}> {cardToPreview.card_title} Card Preview </span>
                </div>
            );
        } else if (currentStep === STEP_MAP.DELETE) {
            return (
                <div>
                    <Button variant="text" onClick={() => setCurrentStep(STEP_MAP.LIST)}>
                        <KeyboardBackspaceIcon /> <span style={{ marginLeft: '5px' }}> Go Back </span>
                    </Button>
                    <span style={{ marginLeft: '20px' }}> {cardToDelete.card_title}</span>
                </div>
            );
        } else {
            if (allowedCardsCountToSelect === 1) {
                return 'Choose 1 card to add to your eNewsletter';
            } else {
                return `Choose up to ${allowedCardsCountToSelect} cards to add to your eNewsletter`;
            }
        }
    };

    const handleCardPreview = (cardData) => {
        setCardToPreview(cardData);
        setCurrentStep(STEP_MAP.PREVIEW);
    };

    const handleCardDelete = (cardData) => {
        setCardToDelete(cardData);
        setCurrentStep(STEP_MAP.DELETE);
    };

    const selectCard = (selectedCard, isCardAlreadySelected) => {
        if (!isCardAlreadySelected) {
            setSelectedCards(_.concat(selectedCards, selectedCard));
        } else {
            setSelectedCards(_.filter(selectedCards, (card) => card.card_id !== selectedCard.card_id));
        }
    };

    const onSearch = _.debounce((searchText) => {
        if (searchText.length > 2) {
            fetchCards({
                page_size: CARDS_PAGE_SIZE,
                filter_by_title: searchText,
            });
        }
        setSearchTerm(searchText);
    }, 300);

    const addToNewsletter = () => {
        const { onSelected } = props;
        onSelected(selectedCards);
    };

    const checkForNoResult = () => {
        return !fetchingCards && searchTerm.length > 2 && searchedCards.cards.length === 0;
    };

    const handleCardDeleteReset = () => {
        setCurrentStep(STEP_MAP.LIST);
        setCardToDelete(undefined);
    };

    const getCurrentStepContent = () => {
        if (currentStep === STEP_MAP.LIST) {
            return (
                <CardList
                    handleCardPreview={handleCardPreview}
                    handleCardDelete={handleCardDelete}
                    selectCard={selectCard}
                    cardsData={
                        searchTerm.length > 2
                            ? getFilteredCards(searchedCards.cards)
                            : getFilteredCards(recentCards.cards)
                    }
                    isCardSelectable={selectedCards.length < allowedCardsCountToSelect}
                    selectedCards={selectedCards}
                    fetchingCards={fetchingCards}
                    noResult={checkForNoResult()}
                />
            );
        } else if (currentStep === STEP_MAP.PREVIEW) {
            return <CardPreview cardData={cardToPreview} />;
        } else if (currentStep === STEP_MAP.DELETE) {
            return <CardDelete handleCardDeleteReset={handleCardDeleteReset} cardData={cardToDelete} />;
        } else {
            return <div>Unknown step "{currentStep}"</div>;
        }
    };

    return (
        <DpDialog.Dialog id="selectCardDialog" open={visible} onClose={onClose} maxWidth="md">
            <DpDialog.Content className="select-card-dialog" onScroll={handleScroll}>
                <DpDialog.ContentHeader heading={getTitle()} onClose={onClose}></DpDialog.ContentHeader>
                {currentStep === STEP_MAP.LIST ? (
                    <Grid className="flex-space-between cards-search-container">
                        <Grid xs={6} item className="flex-middle">
                            <Tooltip
                                placement="bottom"
                                enterTouchDelay={0}
                                classes={{
                                    tooltip: 'info-tooltip',
                                }}
                                title="You can search by card title"
                            >
                                <InfoIcon className="info-tooltip-icon" style={{ marginRight: '10px' }} />
                            </Tooltip>
                            <div className="full-width">
                                <SearchInput
                                    onSearch={onSearch}
                                    placeholder="Card title"
                                    noResult={checkForNoResult()}
                                    searchTerm={searchTerm || ''}
                                />
                            </div>
                        </Grid>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={addToNewsletter}
                            disabled={selectedCards.length === 0}
                        >
                            Add to eNewsletter
                        </Button>
                    </Grid>
                ) : null}
                {getCurrentStepContent()}
            </DpDialog.Content>
        </DpDialog.Dialog>
    );
};

const mapStateToProps = (state) => ({
    ...state.newsletter,
    refetchNewsletters: state.newsletter.refetchNewsletters,
    activeEvent: state.newsletter.activeEvent,
});

const mapDispatchToProps = {
    fetchCards: newsletterActions.fetchCards,
    fetchNewsletters: newsletterActions.fetchNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCardDialog);
