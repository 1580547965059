import { get, post } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logHttpError } from '../../../utils/log';

const getConversations = (userId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/conversations/${userId}/activity`)
        .then((response) => {
            return response.conversations_activity;
        })
        .catch((error) => {
            logHttpError(`An error occured on getConversations for userId: ${userId}`, error);
            return Promise.reject(error);
        });
};

const createConversation = (conversationData) => {
    return post(`${config.apiweb_url}${api.CONVERSATION}`, conversationData)
        .then((response) => {
            if (response.success) {
                return response;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on createConversation for userId: ${conversationData.patient_inbox_user_id}`,
                error
            );
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

export const conversationService = {
    getConversations,
    createConversation,
};

export default conversationService;
