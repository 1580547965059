import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Auto Attendant';
const description = 'Enable or disable user access to the Auto Attendant';

const AutoAttendant = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(AutoAttendant, {
    package: {
        name: 'IVR',
        code: 'ICFG',
        attribute: {
            name: 'AutoAttendant',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
