/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'pharmacyApps';

export const pharmacyAppsTypes = {
    FETCH_NON_BRANDED_APPS: `${typePrefix}FETCH_NON_BRANDED_APPS`,
    FETCH_NON_BRANDED_APPS_SUCCESS: `${typePrefix}FETCH_NON_BRANDED_APPS_SUCCESS`,
    FETCH_NON_BRANDED_APPS_FAILED: `${typePrefix}FETCH_NON_BRANDED_APPS_FAILED`,

    FETCH_PHARMACY_APPS: `${typePrefix}FETCH_PHARMACY_APPS`,
    FETCH_PHARMACY_APPS_SUCCESS: `${typePrefix}FETCH_PHARMACY_APPS_SUCCESS`,
    FETCH_PHARMACY_APPS_FAILED: `${typePrefix}FETCH_PHARMACY_APPS_FAILED`,

    ADD_APPS: `${typePrefix}ADD_APPS`,
    ADD_APPS_SUCCESS: `${typePrefix}ADD_APPS_SUCCESS`,
    ADD_APPS_FAILED: `${typePrefix}ADD_APPS_FAILED`,

    ADD_BRANDED_APP: `${typePrefix}ADD_BRANDED_APP`,
    ADD_BRANDED_APP_SUCCESS: `${typePrefix}ADD_BRANDED_APP_SUCCESS`,
    ADD_BRANDED_APP_FAILED: `${typePrefix}ADD_BRANDED_APP_FAILED`,

    FETCH_RELEASES: `${typePrefix}FETCH_RELEASES`,
    FETCH_RELEASES_SUCCESS: `${typePrefix}FETCH_RELEASES_SUCCESS`,
    FETCH_RELEASES_FAILED: `${typePrefix}FETCH_RELEASES_FAILED`,

    DELETE_PHARMACY_APP: `${typePrefix}DELETE_PHARMACY_APP`,
    DELETE_PHARMACY_APP_SUCCESS: `${typePrefix}DELETE_PHARMACY_APP_SUCCESS`,
    DELETE_PHARMACY_APP_FAILED: `${typePrefix}DELETE_PHARMACY_APP_FAILED`,

    UPDATING_BRANDED_APP_DETAILS: `${typePrefix}UPDATING_BRANDED_APP_DETAILS`,
    UPDATING_BRANDED_APP_DETAILS_SUCCESS: `${typePrefix}UPDATING_BRANDED_APP_DETAILS_SUCCESS`,
    UPDATING_BRANDED_APP_DETAILS_FAILED: `${typePrefix}UPDATING_BRANDED_APP_DETAILS_FAILED`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
