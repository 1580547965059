import './ChoosePatientsPage.sass';
import React, { useState, useEffect } from 'react';
import PatientsSelectionPane from '../PatientsSelectionPane';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import { getTemplateTitle, getTemplateImageUrl } from '../patientsUtil';
import LocalStorageService from '../../../utils/localStorageService';
import Snowplow, { RxConferWorkflowContext } from '../../../snowplow';

function TemplateSidebarHeaderComponent({ count, template }) {
    return (
        <div className="template-sidebar-header-component">
            <div
                className="image-box"
                style={{
                    backgroundImage: `url(${getTemplateImageUrl(template)})`,
                    backgroundColor: '#eee',
                    backgroundSize: 'cover',
                    width: '75px',
                    height: '50px',
                }}
            />
            <div className="text">
                <div className="title">{getTemplateTitle(template)}</div>
                <div className="count">
                    {numeral(count).format('0,0')} selected {pluralize('patient', count)}
                </div>
            </div>
        </div>
    );
}

export default function ChoosePatientsPage({ template, onComplete, goBack }) {
    const height = window.innerHeight - 110;

    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    useEffect(() => {
        if (!_.isNil(template)) {
            Snowplow.pageView('Workflows - Patient Selection', [
                new RxConferWorkflowContext().setWorkflowTemplateId(template.inbox_conversation_template_id).build(),
            ]);
        }
    }, [template]);

    if (_.isNil(template)) {
        return null;
    }

    return (
        <div className="choose-patients-page">
            <div className="choose-patients-content">
                <PatientsSelectionPane
                    {...arguments[0]}
                    nameFormat={nameFormat}
                    setNameFormat={setNameFormat}
                    autoFocusSearchBox={true}
                    height={height}
                    mainButtonLabel="Use these patients"
                    onGoBack={goBack}
                    onMainButtonClick={(selectedPatients) => {
                        onComplete(selectedPatients);
                    }}
                    sidebarHeaderComponent={<TemplateSidebarHeaderComponent template={template} />}
                    mayOnlySelectMessageablePatients
                />
            </div>
        </div>
    );
}
