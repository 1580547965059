import './ConnectManagementRootPage.sass';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import TemplatesManagementPage from './TemplatesManagementPage';
import {
    getTargetedConnectTemplates,
    getNonTargetedConnectTemplates,
    getInboxConversationTemplates,
    getSchedulingConnectTemplates,
} from '../../patientsUtil';
import queryString from 'query-string';

import _ from 'lodash';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import Snowplow from '../../../../snowplow';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';
import { fetchAvailableAppointmentCalendars } from 'redux/Appt/Calendar/action';

function ConnectManagementRootPage({
    match,
    history,
    location,
    inboxConversationTemplate,
    launchDarkly,
    pharmacy,
    apptTypesWithSessions,
    apptCalendar,
    fetchAvailableAppointmentCalendars,
}) {
    const props = arguments[0];
    const queryParams = queryString.parse(location.search);

    const selectedTab = _.isNil(match.params.managePath) ? 'templates' : match.params.managePath;

    useEffect(() => {
        Snowplow.pageView('Workflows - Workflow Management');
    }, []);

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    const navigate = (to) => {
        if (selectedTab !== to) {
            history.push(`/workflows/manage/${to}`);
        }
    };

    const getSubtitleText = () => {
        switch (selectedTab) {
            case 'templates':
                return "A standard workflow is a common way to interact with one or more of your patients that you can send whenever you want. Below are multiple customizeable workflows to get you started, but you may create your own if you don't see one that meets your needs.";
            case 'targeted':
                return 'A targeted workflow is an extension of a standard workflow that will automatically analyze patient information to pre-select those that are eligible to receive them.';
            case 'schedule':
                return 'A scheduling workflow is a prompt for a patient to schedule an appointment at their convenience. Any scheduled appointments will appear within the Appointments section of PEP.';
            default:
                return '';
        }
    };

    const filterTemplates = (templates) => {
        if (!_.isNil(queryParams.q)) {
            return _.filter(templates, (t) => {
                const q = _.toLower(queryParams.q);
                const title = _.isNil(t.title) ? t.subject : t.title;
                return (
                    _.toLower(title).indexOf(q) > -1 ||
                    _.toLower(t.subject).indexOf(q) > -1 ||
                    _.toLower(t.message).indexOf(q) > -1
                );
            });
        }
        return templates;
    };

    return (
        <div className="connect-management-root-page" style={{ minHeight: `${window.innerHeight - 120}px` }}>
            <div className="heading">
                <div className="title">Manage Patient workflows</div>
                <div className="subtitle">{getSubtitleText()}</div>
            </div>
            <div className="left-bar-and-content">
                <div className="left-bar">
                    <Tabs orientation="vertical" value={selectedTab}>
                        <Tab
                            label="Standard Patient Workflows"
                            value="templates"
                            onClick={() => navigate('templates')}
                        />
                        <Tab label="Targeted Patient Workflows" value="targeted" onClick={() => navigate('targeted')} />
                        <Tab label="Scheduling Workflows" value="schedule" onClick={() => navigate('schedule')} />
                        {/*<Tab label='Patient Assessments' value='assessments' onClick={() => navigate('assessments')}/>
                        <Tab label='Automation' value='automation' onClick={() => navigate('automation')}/>*/}
                    </Tabs>
                </div>
                <div className="connect-management-content">
                    <Switch>
                        <Route
                            path="/workflows/manage/targeted"
                            render={({ match }) => {
                                return (
                                    <TemplatesManagementPage
                                        key="targeted"
                                        {...props}
                                        query={queryParams.q}
                                        templates={filterTemplates(
                                            getTargetedConnectTemplates(
                                                getInboxConversationTemplates(
                                                    inboxConversationTemplate,
                                                    launchDarkly,
                                                    pharmacy,
                                                    { hideFeatureAdvertisements: true, apptTypesWithSessions }
                                                ),
                                                {
                                                    includeInactive: true,
                                                }
                                            )
                                        )}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/workflows/manage/schedule"
                            render={({ match }) => {
                                return (
                                    <TemplatesManagementPage
                                        key="schedule"
                                        {...props}
                                        query={queryParams.q}
                                        templates={filterTemplates(
                                            getSchedulingConnectTemplates(
                                                getInboxConversationTemplates(
                                                    inboxConversationTemplate,
                                                    launchDarkly,
                                                    pharmacy,
                                                    { hideFeatureAdvertisements: true, apptTypesWithSessions }
                                                ),
                                                {
                                                    includeInactive: true,
                                                }
                                            )
                                        )}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/workflows/manage"
                            render={({ match }) => {
                                return (
                                    <TemplatesManagementPage
                                        key="templates"
                                        {...props}
                                        templates={filterTemplates(
                                            getNonTargetedConnectTemplates(
                                                getInboxConversationTemplates(
                                                    inboxConversationTemplate,
                                                    launchDarkly,
                                                    pharmacy,
                                                    { hideFeatureAdvertisements: true, apptTypesWithSessions }
                                                ),
                                                { includeInactive: true }
                                            )
                                        )}
                                        canCreate={true}
                                    />
                                );
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        apptTypesWithSessions: selectApptTypesWithSessions(state),
        apptCalendar: state.apptCalendar,
    };
}

const bindActionsToDispatch = {
    fetchAvailableAppointmentCalendars,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ConnectManagementRootPage);
