import { Ability } from '@casl/ability';
import { BaseAbilityBuilder } from './BaseAbilityBuilder';
import { constants } from '../Constants';

export class InternalUserAbilityBuilder extends BaseAbilityBuilder {
    constructReadOnlyFieldsArray() {
        const { generalSettings } = this.restrictedResources;

        this.readOnlyFields = [
            {
                subject: generalSettings.accountDetails.subject,
                fields: [generalSettings.accountDetails.fields.email],
            },
        ];
    }

    build() {
        const { all, userActions } = constants;
        const { manage } = userActions;

        // Note
        // 1) Rule are in accordance with Google Sheet attached in https://rxwiki.atlassian.net/browse/PHAR-1355 and
        //    https://app.lucidchart.com/documents/view/f82ecc8e-e040-4e25-b0ac-e5c5218dd168/0_0
        // 2) We follow the opt-out approach, i.e. we allow user to manage everything except opted out features
        this.can(manage, all);
        this.optOutReadOnlyFields();

        return new Ability(this.rules, { detectSubjectType: this.detectAppSubjectType });
    }
}
