import assessmentTypes from './type';
import assessmentService from './service';
import config from '../../../config';
import _ from 'lodash';

export function fetchAvailableAssessedAttributes() {
    return async (dispatch, getState) => {
        const state = getState().assessment;

        if (_.get(state, 'availableAssessedAttributes.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_REQUEST,
            payload: {},
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await assessmentService.getAvailableAssessedAttributes(pharmacyId, locationId);
            dispatch({
                type: assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_SUCCESS,
                payload: {
                    attributes: _.get(response, 'assessed_attributes'),
                    sentAssessments: _.get(response, 'sent_assessments'),
                },
            });
        } catch (error) {
            dispatch({
                type: assessmentTypes.AVAILABLE_ASSESSMENT_ATTRIBUTES_FAILURE,
                payload: { error },
            });
        }
    };
}

export function clearAvailableAssessedAttributes() {
    return {
        type: assessmentTypes.CLEAR_AVAILABLE_ASSESSED_ATTRIBUTES,
        payload: {},
    };
}

export function fetchAssessmentTemplate(assessmentId) {
    return async (dispatch, getState) => {
        const state = getState().assessment;

        if (_.get(state, `assessmentTemplates.${assessmentId}.loading`)) {
            return Promise.resolve();
        }

        dispatch({
            type: assessmentTypes.GET_ASSESSMENT_TEMPLATE_REQUEST,
            payload: { assessmentId },
        });

        try {
            const response = await assessmentService.getAssessmentTemplate(assessmentId);
            dispatch({
                type: assessmentTypes.GET_ASSESSMENT_TEMPLATE_SUCCESS,
                payload: {
                    assessmentId,
                    response,
                },
            });
        } catch (error) {
            dispatch({
                type: assessmentTypes.GET_ASSESSMENT_TEMPLATE_FAILURE,
                payload: { assessmentId, error },
            });
        }
    };
}
