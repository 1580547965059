import React, { useEffect } from 'react';
import { Card, CardText } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

import ClassesScreenshot from './ClassesScreenshot';
import ClassesDescription from './ClassesDescription';
import RequestClassesDemo from './RequestClassesDemo';

const styles = {
    container: {
        marginTop: '54px',
        paddingTop: '16px',
        paddingBottom: '54px',
        backgroundColor: 'white',
        minHeight: 'calc(100vh - 54px)',

        '@media (min-width:840px)': {
            marginTop: '64px',
            paddingBottom: '16px',
            minHeight: 'calc(100vh - 64px)',
        },
    },
    containerItem: {
        minWidth: '320px',
        maxWidth: '517px',
        alignSelf: 'center',
        marginBottom: '24px',
        boxShadow: 'none',
    },
};

const EmptyStatePage = ({ classes, classResourceEnabled, history }) => {
    useEffect(() => (classResourceEnabled ? history.push('/classes/type2Diabetes') : undefined), [
        classResourceEnabled,
    ]);

    return (
        <div className={`centered-content ${classes.container}`}>
            <Card className={classes.containerItem}>
                <CardText>
                    <ClassesScreenshot />
                    <br />
                    <br />
                    <ClassesDescription />
                    <RequestClassesDemo />
                </CardText>
            </Card>
        </div>
    );
};

const mapStateToProps = ({ pharmacy: { classesEnabled } }) => ({
    classResourceEnabled: classesEnabled,
});

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(EmptyStatePage)));
