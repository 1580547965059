import './ReviewPatientsContent.sass';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import {
    Button,
    Checkbox,
    TextField,
    InputAdornment,
    Tooltip,
    CircularProgress,
    Select,
    MenuItem,
    Snackbar,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Column, Table } from 'react-virtualized';
import _ from 'lodash';
import config from '../../../config';
import {
    cleanString,
    getDateInputFormatByCountryCode,
    formatRawDate,
    formatRawPhone,
    getAbbreviatedDateInputFormatByCountryCode,
} from '../../../utils/helper';
import { getInvalidPatientFields, isTwoDigitYearRawDate, capitalizeName, parsePatientFromFileData } from './csvUtil';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import numeral from 'numeral';
import pluralize from 'pluralize';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ReviewPatientCell from './ReviewPatientCell';
import PeopleIcon from '@material-ui/icons/People';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

const PERSISTABLE_PATIENT_FIELDS = ['pharmacy_id', 'location_id', 'first_name', 'last_name', 'phone', 'date_of_birth'];

export default function ReviewPatientsContent({
    file,
    fileData,
    fileColumns,
    attributesMapping,
    onClose,
    onDone,
    onGoBack,
    activeLocationCountryCode,
    createBulkUsers,
    uploadBulkOriginalCSV,
    locationName,
    getExactDuplicateNewPatients,
}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResultIndices, setSearchResultIndices] = useState(null);
    const [autoCapitalize, setAutoCapitalize] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [nonDuplicateContacts, setNonDuplicateContacts] = useState(null);
    const [duplicateContacts, setDuplicateContacts] = useState(null);
    const [typeFilter, setTypeFilter] = useState('all');
    const [duplicateCheckError, setDuplicateCheckError] = useState(null);

    const tableRef = useRef();
    const searchInputRef = useRef();

    const getUniqKey = (contact) => {
        return _.toLower(`${contact.first_name}-${contact.last_name}-${contact.date_of_birth}-${contact.phone}`);
    };

    const { contacts, invalidCount, twoDigitYearDateCount } = useMemo(() => {
        const contacts = [];
        const validContacts = [];
        let invalidCount = 0;
        let twoDigitYearDateCount = 0;

        const uniqueContactKeys = new Set();

        if (!_.isNil(fileData)) {
            _.each(fileData.data, (r, i) => {
                const row = _.map(fileColumns, (fc) => fc.source.data[i][fc.sourceIndex]);

                if (i === 0 && fileData.hasHeaders) {
                    return;
                }
                if (_.isEmpty(_.filter(row))) {
                    return;
                }

                const raw = {
                    date_of_birth: row[attributesMapping.date_of_birth.colIndex],
                    phone: row[attributesMapping.phone.colIndex],
                    first_name: cleanString(row[attributesMapping.first_name.colIndex]),
                    last_name: cleanString(row[attributesMapping.last_name.colIndex]),
                };

                const contact = parsePatientFromFileData(
                    row,
                    i,
                    fileData,
                    activeLocationCountryCode,
                    attributesMapping
                );

                // if we already saw this contact then ignore this duplicate one
                const uniqueKey = getUniqKey(contact);
                if (uniqueContactKeys.has(uniqueKey)) {
                    return;
                }

                uniqueContactKeys.add(getUniqKey(contact));

                const invalidFields = getInvalidPatientFields(contact);
                if (!_.isEmpty(invalidFields)) {
                    contact.invalidFields = invalidFields;
                    invalidCount++;
                    if (invalidFields.has('date_of_birth')) {
                        // track if we are getting 2 digit dates so we can show a warning
                        if (isTwoDigitYearRawDate(raw.date_of_birth)) {
                            twoDigitYearDateCount++;
                        }
                    }
                } else {
                    validContacts.push(_.pick(contact, ['first_name', 'last_name', 'phone', 'date_of_birth']));
                }
                contacts.push(contact);
            });
        }

        if (!_.isEmpty(validContacts)) {
            getExactDuplicateNewPatients(validContacts)
                .then((response) => {
                    if (response.success === true) {
                        setNonDuplicateContacts(response.non_duplicates);
                        setDuplicateContacts(_.keyBy(response.duplicates, (c) => getUniqKey(c)));
                    } else {
                        setDuplicateCheckError('success is false');
                    }
                })
                .catch((error) => {
                    setDuplicateCheckError(error);
                });
        } else {
            setNonDuplicateContacts([]);
        }

        return { contacts, invalidCount, twoDigitYearDateCount };
    }, [JSON.stringify(attributesMapping), fileData]);

    const [filteredContacts, setFilteredContacts] = useState(contacts);

    // Overlay the autoCapitalize modifications over the underlying contact in the proper order
    // Doing it this way is much more performant for large files than iterating and overwriting the contacts each time.
    const getContactAtIndex = (index) => {
        const capitalized = !autoCapitalize
            ? null
            : {
                  first_name: capitalizeName(_.get(filteredContacts, `[${index}].first_name`)),
                  last_name: capitalizeName(_.get(filteredContacts, `[${index}].last_name`)),
              };
        return {
            ...filteredContacts[index],
            ...capitalized,
        };
    };

    useEffect(() => {
        if (_.isEmpty(_.trim(searchQuery))) {
            setSearchResultIndices(null);
        } else {
            const searchResultIndices = [];
            _.each(_.times(_.size(filteredContacts)), (rowIndex) => {
                const contact = getContactAtIndex(rowIndex);
                if (_.toLower(`${contact.first_name} ${contact.last_name}`).indexOf(_.toLower(searchQuery)) > -1) {
                    searchResultIndices.push(rowIndex);
                }
            });
            setSearchResultIndices(searchResultIndices);
        }
    }, [searchQuery, filteredContacts]);

    useEffect(() => {
        if (typeFilter === 'all') {
            setFilteredContacts(contacts);
        } else if (typeFilter === 'to-be-added') {
            setFilteredContacts(
                _.filter(contacts, (c) => _.isEmpty(c.invalidFields) && _.isNil(duplicateContacts[getUniqKey(c)]))
            );
        } else if (typeFilter === 'incomplete-data') {
            setFilteredContacts(_.filter(contacts, (c) => !_.isEmpty(c.invalidFields)));
        } else if (typeFilter === 'duplicates') {
            setFilteredContacts(_.filter(contacts, (c) => !_.isNil(duplicateContacts[getUniqKey(c)])));
        }
    }, [typeFilter]);

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });
    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);

    const debounceSearchOnChange = _.debounce((v) => setSearchQuery(v), 300);

    const rowCount = _.isNil(searchResultIndices) ? _.size(filteredContacts) : _.size(searchResultIndices);

    const toBeAdded = _.size(nonDuplicateContacts);

    return (
        <div className={classnames({ 'review-patients-content': true, error: duplicateCheckError })}>
            <div className="main-content-wrapper">
                <div className="heading">
                    <Button variant="text" onClick={onGoBack}>
                        <KeyboardBackspaceIcon /> Go Back
                    </Button>
                    <div className="text">Please review the following mapped patients.</div>
                </div>

                <div className="population-stats">
                    <div className="population-arithmetic">
                        <div className="population-statistic">
                            <div className="number">{numeral(_.size(contacts)).format('0,0')}</div>
                            <div className="label">patient records</div>
                        </div>
                        <div className="operator">-</div>
                        <div className="population-statistic">
                            <div className="number">{numeral(invalidCount).format('0,0')}</div>
                            <div className="label">incomplete data</div>
                        </div>
                        <div className="operator">-</div>
                        <div className="population-statistic">
                            {!_.isNil(nonDuplicateContacts) ? (
                                <div className="number">
                                    {numeral(_.size(contacts) - invalidCount - _.size(nonDuplicateContacts)).format(
                                        '0,0'
                                    )}
                                </div>
                            ) : (
                                <div className="number">
                                    <CircularProgress size={20} color="secondary" />
                                </div>
                            )}

                            <div className="label">duplicate patients</div>
                        </div>
                    </div>

                    <div className="operator">=</div>

                    <div className="population-result">
                        <div className="population-statistic">
                            {!_.isNil(nonDuplicateContacts) ? (
                                <div className="number">{numeral(toBeAdded).format('0,0')}</div>
                            ) : (
                                <div className="number">
                                    <CircularProgress size={20} color="secondary" />
                                </div>
                            )}
                            <div className="label">patients to be added</div>
                        </div>
                    </div>
                </div>

                <div className="table-heading">
                    <span className="search-and-counts">
                        <TextField
                            inputRef={searchInputRef}
                            className="search-text-field"
                            style={{ width: '280px' }}
                            placeholder="Search new patients..."
                            variant="outlined"
                            defaultValue={searchQuery}
                            onChange={(e) => {
                                debounceSearchOnChange(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fontSize: '30px' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: _.isEmpty(searchQuery) ? null : (
                                    <InputAdornment position="end">
                                        <div className="field-and-close">
                                            <Tooltip title="Clear this search">
                                                <CloseIcon
                                                    className="clear-icon"
                                                    style={{ fontSize: '20px', color: '#DF4851' }}
                                                    onClick={() => {
                                                        setSearchQuery(null);
                                                        searchInputRef.current.value = '';
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <span className="counts">
                            {numeral(rowCount).format('0,0')} matching {pluralize('patient', rowCount)}
                        </span>
                    </span>

                    <span className="label-and-type-filter-select">
                        <span className="label">Show</span>
                        <Select
                            className="type-filter-select"
                            value={typeFilter}
                            variant="outlined"
                            onChange={(e) => {
                                setTypeFilter(e.target.value);
                            }}
                        >
                            <MenuItem value="all">all patient records</MenuItem>
                            <MenuItem value="to-be-added">patients to be added</MenuItem>
                            <MenuItem value="incomplete-data">incomplete patient records</MenuItem>
                            <MenuItem value="duplicates">duplicate patients</MenuItem>
                        </Select>
                    </span>
                </div>

                <div className="mapped-patients-table">
                    <Table
                        ref={tableRef}
                        width={700}
                        height={Math.min(
                            Math.floor((window.innerHeight - 400) / 30) * 30,
                            Math.max(150, Math.min(rowCount + 1, 15) * 30)
                        )}
                        headerHeight={30}
                        rowHeight={30}
                        rowCount={rowCount}
                        noRowsRenderer={() => {
                            return <div className="empty-table">No matching patient records.</div>;
                        }}
                        rowGetter={({ index }) => {
                            const contactIndex = !_.isNil(searchResultIndices) ? searchResultIndices[index] : index;
                            return {
                                contact: getContactAtIndex(contactIndex),
                                contactIndex,
                                raw: filteredContacts[contactIndex].raw,
                            };
                        }}
                        rowStyle={({ index }) => {
                            return index % 2 === 0 ? { background: '#fff' } : { background: '#f8f8f8' };
                        }}
                    >
                        <Column
                            label=""
                            dataKey="valid"
                            width={80}
                            cellRenderer={({ rowData, rowIndex }) => {
                                const isDuplicate = _.has(duplicateContacts, getUniqKey(rowData.contact));
                                return (
                                    <span className="row-number-and-checkbox">
                                        <span className="row-number">{numeral(rowIndex + 1).format('0,0')}</span>
                                        {!_.isEmpty(
                                            getInvalidPatientFields(getContactAtIndex(rowData.contactIndex))
                                        ) ? (
                                            <Tooltip title="Missing required patient information.">
                                                <NotInterestedIcon className="patient-checkbox disabled" />
                                            </Tooltip>
                                        ) : isDuplicate ? (
                                            <Tooltip title="This patient already exists.">
                                                <PeopleIcon className="patient-checkbox disabled" />
                                            </Tooltip>
                                        ) : _.isNil(duplicateContacts) ? (
                                            <span />
                                        ) : (
                                            <CheckCircleIcon className="patient-checkbox" />
                                        )}
                                    </span>
                                );
                            }}
                        />
                        <Column
                            label="First Name"
                            dataKey="first_name"
                            width={150}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <ReviewPatientCell
                                        value={rowData.contact.first_name}
                                        rawValue={rowData.raw.first_name}
                                    />
                                );
                            }}
                        />
                        <Column
                            label="Last Name"
                            dataKey="last_name"
                            width={150}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <ReviewPatientCell
                                        value={rowData.contact.last_name}
                                        rawValue={rowData.raw.last_name}
                                    />
                                );
                            }}
                        />
                        <Column
                            label="Birthdate"
                            dataKey="date_of_birth"
                            width={150}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <ReviewPatientCell
                                        value={
                                            rowData.contact.invalidFields &&
                                            rowData.contact.invalidFields.has('date_of_birth')
                                                ? ''
                                                : formatDate(rowData.contact.date_of_birth)
                                        }
                                        rawValue={rowData.raw.date_of_birth}
                                    />
                                );
                            }}
                        />
                        <Column
                            label="Phone"
                            dataKey="phone"
                            width={150}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <ReviewPatientCell
                                        value={
                                            rowData.contact.invalidFields && rowData.contact.invalidFields.has('phone')
                                                ? ''
                                                : formatRawPhone(rowData.contact.phone)
                                        }
                                        rawValue={rowData.raw.phone}
                                    />
                                );
                            }}
                        />
                    </Table>
                </div>

                <div className="actions">
                    <Tooltip title="Auto-capitalization will automatically correct the capitalization of the first and last name fields.">
                        <span className="auto-capitalize-toggle" onClick={() => setAutoCapitalize(!autoCapitalize)}>
                            <Checkbox
                                color="primary"
                                checked={autoCapitalize}
                                onChange={() => {
                                    setAutoCapitalize(!autoCapitalize);
                                }}
                            />{' '}
                            Auto-capitalize names
                        </span>
                    </Tooltip>

                    <Button
                        color="primary"
                        variant="contained"
                        disabled={_.isNil(nonDuplicateContacts) || isSaving}
                        onClick={() => {
                            if (toBeAdded === 0) {
                                onClose();
                                return;
                            }

                            setIsSaving(true);

                            const finalContacts = _.filter(
                                _.map(nonDuplicateContacts, (c, index) => {
                                    const capitalized = !autoCapitalize
                                        ? null
                                        : {
                                              first_name: capitalizeName(
                                                  _.get(nonDuplicateContacts, `[${index}].first_name`)
                                              ),
                                              last_name: capitalizeName(
                                                  _.get(nonDuplicateContacts, `[${index}].last_name`)
                                              ),
                                          };
                                    const contact = {
                                        ...nonDuplicateContacts[index],
                                        ...capitalized,
                                        pharmacy_id: config.X_PharmacyID,
                                        location_id: config.X_LocationID,
                                    };
                                    if (_.isEmpty(getInvalidPatientFields(contact))) {
                                        return _.pick(contact, PERSISTABLE_PATIENT_FIELDS);
                                    }
                                })
                            );

                            const uploadOriginal = (success) => {
                                uploadBulkOriginalCSV({
                                    patientCSV: file,
                                    locationName,
                                    rowCount: _.size(fileData.data),
                                    patientCount: _.size(finalContacts),
                                    success,
                                });
                            };

                            createBulkUsers(finalContacts)
                                .then((result) => {
                                    uploadOriginal(true);
                                    setIsSaving(false);
                                    onDone(result.success, result.created_users);
                                })
                                .catch((error) => {
                                    uploadOriginal(false);
                                    setIsSaving(false);
                                    onDone(false);
                                });
                        }}
                    >
                        {toBeAdded === 0
                            ? 'No new patients to add'
                            : isSaving
                            ? 'Adding patients...'
                            : 'Add these patients'}
                    </Button>
                </div>
            </div>

            <Snackbar
                className="non-specific-date-snackbar"
                open={twoDigitYearDateCount > 0}
                message={
                    <div className="non-specific-date-snackbar-content">
                        <WarningIcon />
                        <div className="text">
                            <div className="main-line">
                                Found {numeral(twoDigitYearDateCount).format('0,0')} birthdate values with 2-digit
                                years.
                            </div>
                            <div className="sub-line">
                                Please re-import these patients using the complete date format{' '}
                                {getDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode })}.
                            </div>
                        </div>
                    </div>
                }
            />

            {_.isNil(duplicateCheckError) ? null : (
                <div className="error-message">
                    <ErrorIcon />
                    <div className="text">
                        <div className="main-line">An unexpected error occurred processing this file</div>
                        <div className="sub-line">
                            Please try again later or contact Digital Pharmacist support if this continues.
                        </div>
                    </div>
                    <Button className="error-ok-button" variant="text" onClick={onClose}>
                        OK
                    </Button>
                </div>
            )}
        </div>
    );
}
