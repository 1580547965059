import './AppointmentsAgenda.sass';
import React, { useRef, useEffect, useState } from 'react';
import { getRelativeDateShortLabel } from 'components/Patients/scheduling/schedulingUtil';
import _ from 'lodash';
import AppointmentRow from './AppointmentRow';
import moment from 'moment';
import { CircularProgress, Button, Tooltip } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { formatAppointmentTimeSpan } from './AppointmentRow';
import { formatRawPhone } from 'utils/helper';
import classnames from 'classnames';

export default function AppointmentsAgenda({
    focusedDate,
    calendars,
    displayedDateFormat,
    topActionContent,
    loadPatientsByInboxUserIds,
    onEditAppointment,
    patients,
    setPatients,
    height,
    apptCalendar,
    isWorkflow,
    clearCalendarReservations,
}) {
    const props = arguments[0];

    const shortLabel = getRelativeDateShortLabel(focusedDate);
    const calendarsById = _.keyBy(calendars, ({ calendar_id }) => calendar_id);

    const appointmentsWrapperRef = useRef();

    const [updateTimestamp, setUpdateTimestamp] = useState(null);
    const [isScrollY, setIsScrollY] = useState(false);

    // In order to keep the appointments updated and to prevent flickering when appointment status is updated,
    // we are storing the appointments as internal state of this component. We then update the internal appointments
    // with any non-nil value as a nil value indicates we are loading.  We then update the calendar reservations
    // whenever the updateTimestamp changes (once per minute currently)
    const [appointments, setAppointments] = useState(getAppointmentsToShow(props));
    useEffect(() => {
        const newAppointments = getAppointmentsToShow(props);
        if (!_.isNil(newAppointments)) {
            setAppointments(newAppointments);
        }
    }, [apptCalendar]);

    useEffect(() => {
        if (!_.isNil(updateTimestamp)) {
            clearCalendarReservations();
        }
    }, [updateTimestamp]);

    useEffect(() => {
        /*
        Set an interval for every minute so we can trigger a redraw since this
        will recalculate the "Next appointment" and "Current appointment"
        positions by recomputing each appointments time status
        */
        let interval = null;
        interval = setInterval(() => {
            // use this timestamp state to force a re-render
            const timestamp = moment().toISOString();
            setUpdateTimestamp(timestamp);
        }, 60000);

        return () => {
            // clean up the interval when we unmount
            window.clearInterval(interval);
        };
    }, []);

    // determine if we have more appointments than will fit on the current screen and if so then we will
    // toggle on the scroll-y for that div since otherwise it leaves an ugly empty scroll bar when its not needed
    useEffect(() => {
        const rowsDiv = appointmentsWrapperRef.current.querySelector('.appointment-rows');
        const newIsScrollY = _.get(rowsDiv, 'clientHeight') > appointmentsWrapperRef.current.offsetHeight;
        if (isScrollY !== newIsScrollY) {
            setIsScrollY(newIsScrollY);
        }
    }, [_.size(appointments), _.size(patients), appointmentsWrapperRef.current]);

    useEffect(() => {
        if (!_.isEmpty(appointments)) {
            loadPatientsByInboxUserIds(_.uniq(_.map(appointments, ({ inbox_user_id }) => inbox_user_id))).then(
                (response) => {
                    setPatients(_.keyBy(_.get(response, 'rows'), ({ inbox_user_id }) => inbox_user_id));
                }
            );
        }
    }, [JSON.stringify(_.map(appointments, ({ inbox_user_id }) => inbox_user_id))]);

    const timeStatuses = _.map(appointments, (appt) =>
        getAppointmentTimeStatus(appt, _.get(calendarsById, `${appt.calendar_id}.timezone`))
    );
    const hasInProgressAppointment = _.find(timeStatuses, (ts) => ts === 'in-progress');
    const nextApptIndex = _.findIndex(timeStatuses, (ts) => ts === 'future');
    const allFutureAppointments = _.first(timeStatuses) === 'future';

    const isLoadingCalendars = _.get(apptCalendar, 'loadingAvailableCalendars', false);
    const isLoading = isLoadingCalendars || _.isNil(appointments) || (!_.isEmpty(appointments) && _.isNil(patients));

    const timezonesBeingShown = new Set(
        _.filter(
            _.map(appointments, ({ calendar_id }) => {
                return _.get(calendarsById[calendar_id], 'timezone');
            })
        )
    );
    timezonesBeingShown.add(moment.tz.guess());

    return (
        <div className="appointments-agenda" style={{ height: `${height}px` }}>
            <div className="heading">
                <span className={classnames({ title: true, 'short-label': !_.isNil(shortLabel) })}>
                    {!_.isNil(shortLabel)
                        ? `${shortLabel}'s Appointments`
                        : `Appointments for ${focusedDate.format('dddd')}, ${focusedDate.format(displayedDateFormat)}`}
                </span>

                {_.isNil(shortLabel) ? null : (
                    <span className="title print-label">
                        Appointments for {focusedDate.format('dddd')}, {focusedDate.format(displayedDateFormat)}
                    </span>
                )}

                {_.size(timezonesBeingShown) > 1 && (
                    <Tooltip
                        title={
                            <div style={{ fontSize: '11px' }}>
                                <div>
                                    One or more of these appointments was made on an appointment calendar with a
                                    different timezone than that of the device you are currently using.
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    All appointments are being shown in local time of this device (
                                    {moment.tz.guess().replace('_', ' ')}).
                                </div>
                            </div>
                        }
                    >
                        <span className="shown-in-local-time-message">Times shown in local timezone</span>
                    </Tooltip>
                )}

                {_.isNil(topActionContent) ? null : topActionContent}
            </div>

            <div
                ref={appointmentsWrapperRef}
                className="appointments"
                style={{ overflowY: isScrollY ? 'scroll' : null }}
            >
                {isLoading ? (
                    <div className="appointment-rows loading">
                        <CircularProgress />
                    </div>
                ) : _.isEmpty(appointments) ? (
                    <NoAppointmentsMessage {...props} />
                ) : (
                    <React.Fragment>
                        <div className="appointment-rows">
                            <div className="appointment-rows-inner">
                                {_.map(appointments, (appointment, i) => {
                                    const calendar = calendarsById[appointment.calendar_id];

                                    if (_.isNil(calendar)) {
                                        return null;
                                    }

                                    const patient = patients[appointment.inbox_user_id];

                                    const timeStatus = timeStatuses[i];

                                    return (
                                        <span key={`${appointment.start_date}-${i}`}>
                                            {timeStatus !== 'in-progress' ? null : (
                                                <div className="active-appointment-indicator">
                                                    <span>Current appointment</span>
                                                    <ForwardIcon style={{ transform: 'rotate(90deg)' }} />
                                                    <span className="now-indicator-line" />
                                                </div>
                                            )}
                                            {allFutureAppointments ||
                                            hasInProgressAppointment ||
                                            i !== nextApptIndex ? null : (
                                                <div className="next-appointment-indicator">
                                                    <span>Next appointment</span>
                                                    <ForwardIcon style={{ transform: 'rotate(90deg)' }} />
                                                    <span className="now-indicator-line" />
                                                </div>
                                            )}
                                            <AppointmentRow
                                                {...props}
                                                patient={patient}
                                                startTime={moment.tz(
                                                    moment.utc(appointment.start_date, 'YYYY-MM-DDTHH:mm:ss'),
                                                    _.get(calendar, 'timezone')
                                                )}
                                                endTime={moment.tz(
                                                    moment.utc(appointment.end_date, 'YYYY-MM-DDTHH:mm:ss'),
                                                    _.get(calendar, 'timezone')
                                                )}
                                                calendar={calendar}
                                                displayedDateFormat={displayedDateFormat}
                                                appointmentLabel={appointment.label}
                                                vaccineType={_.get(
                                                    appointment,
                                                    'metadata.covid_dose_info.vaccine_type'
                                                )}
                                                onEdit={() => onEditAppointment(appointment, patient)}
                                                timeStatus={timeStatus}
                                                reservation={appointment}
                                            />
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        <table className="print-appointment-rows">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Appointment Type</th>
                                    <th>Calendar</th>
                                    <th>Patient name</th>
                                    <th>Patient DOB</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(appointments, (appointment, i) => {
                                    const calendar = calendarsById[appointment.calendar_id];
                                    const patient = patients[appointment.inbox_user_id];

                                    const startTime = moment.tz(
                                        moment.utc(appointment.start_date, 'YYYY-MM-DDTHH:mm:ss'),
                                        _.get(calendar, 'timezone')
                                    );
                                    const endTime = moment.tz(
                                        moment.utc(appointment.end_date, 'YYYY-MM-DDTHH:mm:ss'),
                                        _.get(calendar, 'timezone')
                                    );

                                    return (
                                        <tr key={appointment.reservation_id} className="print-appointment-row">
                                            <td className="time">
                                                {formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat)}
                                            </td>
                                            <td>{appointment.label}</td>
                                            <td>{_.get(calendar, 'name')}</td>
                                            <td className="patient-name">
                                                {_.get(patient, 'first_name')} {_.get(patient, 'last_name')}
                                            </td>
                                            <td>
                                                {moment(_.get(patient, 'date_of_birth'), 'YYYY-MM-DD').format(
                                                    displayedDateFormat
                                                )}
                                            </td>
                                            <td>{formatRawPhone(_.get(patient, 'phone'))}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

function NoAppointmentsMessage({ calendars, noAppointmentsSubLineText, isWorkflow, history }) {
    if (_.isEmpty(calendars)) {
        return (
            <div className="no-appointments-message">
                <EventBusyIcon />
                <div className="text">
                    <div className="main-line">No visible calendars.</div>
                    {isWorkflow ? (
                        <div className="sub-line">
                            You have not configured a clinical appointment calendar for use on this workflow.
                            <Button
                                onClick={() =>
                                    history.push(window.location.pathname.replace('/appointments', '/customize'))
                                }
                            >
                                Click here
                            </Button>
                            to configure one.
                        </div>
                    ) : (
                        <div className="sub-line">
                            You do not have any clinical appointment calendars visible. Please unhide them in by
                            checking the box next to them on the left.
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="no-appointments-message">
            <CalendarTodayIcon />
            <div className="text">
                <div className="main-line">No appointments currently scheduled on this day.</div>
                <div className="sub-line">{noAppointmentsSubLineText}</div>
            </div>
        </div>
    );
}

function getAppointmentsToShow({ calendars, focusedDate, apptCalendar, appointmentLabelToShow }) {
    const visibleCalendarIds = new Set(_.map(calendars, ({ calendar_id }) => calendar_id));

    const startAt = moment.utc(moment(`${focusedDate.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'));
    const endAt = moment.utc(
        moment(`${moment(focusedDate).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
    );

    const reservations = [];

    let loadingCount = 0;
    _.forIn(apptCalendar.calendarReservations, (value, calendarId) => {
        if (visibleCalendarIds.has(calendarId)) {
            if (_.isNil(_.get(value, 'response.reservations'))) {
                loadingCount++;
            }
            reservations.push(
                ..._.filter(_.get(value, 'response.reservations'), ({ start_date, status, label }) => {
                    const apptStart = moment.utc(start_date);
                    return (
                        status === 'ACTIVE' &&
                        apptStart.isSameOrAfter(startAt) &&
                        apptStart.isBefore(endAt) &&
                        (_.isNil(appointmentLabelToShow) ? true : label === appointmentLabelToShow)
                    );
                })
            );
        }
    });

    return loadingCount > 0 || _.isEmpty(apptCalendar.calendarReservations)
        ? null
        : _.sortBy(reservations, [(r) => r.start_date, (r) => r.end_date]);
}

function getAppointmentTimeStatus({ start_date, end_date }, timezone) {
    if (!_.isString(timezone)) {
        return;
    }
    const now = moment.tz(moment(), timezone).utc().toISOString();

    const start = moment.tz(moment.utc(start_date, 'YYYY-MM-DDTHH:mm:SS'), timezone).utc().toISOString();
    const end = moment.tz(moment.utc(end_date, 'YYYY-MM-DDTHH:mm:SS'), timezone).utc().toISOString();

    if (now > start && now > end) {
        return 'past';
    } else if (now > start && now <= end) {
        return 'in-progress';
    } else {
        return 'future';
    }
}
