import './AppointmentStatusIcon.sass';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import { IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { formatTimeRangeWithDate } from 'components/Appointments/AppointmentRow';

function AppointmentStatusIcon({ patient, calendarsById, onClick }) {
    // generate the now string in the same format so we can do lexographical comparisons on appointment start_dates
    // instead of having to convert each one to a moment object.
    const utcNow = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    const appointments = _.sortBy(
        _.filter(_.get(patient, 'appt_reservations'), ({ status }) => status === 'ACTIVE'),
        ({ start_date }) => start_date
    );

    const futureAppointments = _.filter(appointments, ({ start_date }) => start_date >= utcNow);

    // don't render at all if there are no future appointments
    if (_.isEmpty(appointments)) {
        return (
            <Tooltip title="Patient has never had a pharmacy appointment.">
                <IconButton className="appointment-status-icon" onClick={() => onClick(patient)}>
                    <EventIcon style={{ color: '#aaa' }} />
                </IconButton>
            </Tooltip>
        );
    }

    if (_.isEmpty(futureAppointments)) {
        const { reservation_id, calendar_id, label, start_date, end_date } = _.last(appointments);
        return (
            <Tooltip
                title={
                    <div className="appointment-status-icon-tooltip-title">
                        <div className="heading">
                            <div>No upcoming appointments</div>
                        </div>

                        <div className="appointments past">
                            <div className="sub-heading">Patient's last appointment:</div>

                            <div key={reservation_id} className="appt-type-and-time">
                                <EventIcon style={{ color: _.get(calendarsById, `${calendar_id}.color`) }} />
                                <div className="text">
                                    <div className="when">{formatTimeRangeWithDate({ start_date, end_date })}</div>
                                    <div className="what">{label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            >
                <IconButton className="appointment-status-icon" onClick={() => onClick(patient)}>
                    <EventIcon />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Tooltip
            title={
                <div className="appointment-status-icon-tooltip-title">
                    <div className="heading">
                        <div>Upcoming Appointments</div>

                        <div className="count">{_.size(futureAppointments)}</div>
                    </div>

                    <div className="appointments">
                        {_.map(
                            _.slice(futureAppointments, 0, 3),
                            ({ reservation_id, calendar_id, label, start_date, end_date }) => {
                                return (
                                    <div key={reservation_id} className="appt-type-and-time">
                                        <EventIcon style={{ color: _.get(calendarsById, `${calendar_id}.color`) }} />
                                        <div className="text">
                                            <div className="when">
                                                {formatTimeRangeWithDate({ start_date, end_date })}
                                            </div>
                                            <div className="what">{label}</div>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    {_.size(futureAppointments) <= 3 ? null : (
                        <div className="more-count">
                            + {_.size(futureAppointments) - 3} more scheduled appointments...
                        </div>
                    )}
                </div>
            }
        >
            <IconButton className="appointment-status-icon with-future-appts" onClick={() => onClick(patient)}>
                <EventIcon />
            </IconButton>
        </Tooltip>
    );
}

function mapStateToProps(state) {
    const { apptCalendar } = state;

    return {
        calendarsById: _.keyBy(_.get(apptCalendar, 'availableCalendars.calendars'), ({ calendar_id }) => calendar_id),
    };
}

export default connect(mapStateToProps)(AppointmentStatusIcon);
