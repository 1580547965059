const typePrefix = 'inbox/template/';

export const templateType = {
    DEACTIVATE_TEMPLATE_REQUEST: `${typePrefix}DEACTIVATE_TEMPLATE_REQUEST`,
    DEACTIVATE_TEMPLATE_SUCCESS: `${typePrefix}DEACTIVATE_TEMPLATE_SUCCESS`,
    DEACTIVATE_TEMPLATE_FAILURE: `${typePrefix}DEACTIVATE_TEMPLATE_FAILURE`,
    ACTIVATE_TEMPLATE_SUCCESS: `${typePrefix}ACTIVATE_TEMPLATE_SUCCESS`,
    ACTIVATE_TEMPLATE_REQUEST: `${typePrefix}ACTIVATE_TEMPLATE_REQUEST`,
    ACTIVATE_TEMPLATE_FAILURE: `${typePrefix}ACTIVATE_TEMPLATE_FAILURE`,
    GET_TEMPLATES_REQUEST: `${typePrefix}GET_TEMPLATES_REQUEST`,
    GET_TEMPLATES_SUCCESS: `${typePrefix}GET_TEMPLATES_SUCCESS`,
    GET_TEMPLATES_FAILURE: `${typePrefix}GET_TEMPLATES_FAILURE`,
    CREATE_TEMPLATE_REQUEST: `${typePrefix}CREATE_TEMPLATE_REQUEST`,
    CREATE_TEMPLATE_SUCCESS: `${typePrefix}CREATE_TEMPLATE_SUCCESS`,
    CREATE_TEMPLATE_FAILURE: `${typePrefix}CREATE_TEMPLATE_FAILURE`,
    UPDATE_TEMPLATE_REQUEST: `${typePrefix}UPDATE_TEMPLATE_REQUEST`,
    UPDATE_TEMPLATE_SUCCESS: `${typePrefix}UPDATE_TEMPLATE_SUCCESS`,
    UPDATE_TEMPLATE_FAILURE: `${typePrefix}UPDATE_TEMPLATE_FAILURE`,
    DELETE_TEMPLATE_SUCCESS: `${typePrefix}DELETE_TEMPLATE_SUCCESS`,
    DELETE_TEMPLATE_REQUEST: `${typePrefix}DELETE_TEMPLATE_REQUEST`,
    DELETE_TEMPLATE_FAILURE: `${typePrefix}DELETE_TEMPLATE_FAILURE`,
};

export default templateType;
