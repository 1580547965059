import React, { Component } from 'react';
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableCell,
    Card,
    CardContent,
    CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import { newsletterActions } from 'redux/Newsletter';
import { DpTableBody, DpTableCell, DpTableRow } from 'components/Common/DpTables';
import SearchInput from 'components/Common/SearchInput';
import { numberWithCommas } from 'utils/helper';
import Snowplow from 'snowplow';

const SubscriberPlaceholder = ({ legendsCount, style }) => {
    const placeHolderStyle = style ? style : { height: '50px' };
    return (
        <Grid container spacing={2}>
            {new Array(legendsCount).fill(0).map((_, idx) => (
                <Grid item xs={12} key={idx}>
                    <ReactPlaceholder
                        type="rect"
                        rows={1}
                        ready={false}
                        showLoadingAnimation
                        firstLaunchOnly
                        style={placeHolderStyle}
                    >
                        <div></div>
                    </ReactPlaceholder>
                </Grid>
            ))}
        </Grid>
    );
};

export class SubscriberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPharmacyChanged: false, // TODO: odm: pharmacyId belongs in redux, and change detected by a pharmacyId prop to component, eg: useEffect(() => { /*dispatch here*/ }, [props.pharmacyId])
        };
    }

    componentDidMount() {
        const { fetchSubscriberList } = this.props;
        fetchSubscriberList();
        Snowplow.pageView('Newsletter Subscribers Tab');
    }

    componentDidUpdate(oldProps) {
        const {
            newsletter: { refetchSubscribersFromStart },
            fetchSubscriberList,
            selectedCustomer,
        } = this.props;
        const {
            selectedCustomer: oldSelectedCustomer,
            newsletter: { refetchSubscribersFromStart: oldRefetchSubscribersFromStart },
        } = oldProps;
        const isPharmacyChanged = selectedCustomer !== oldSelectedCustomer;

        if (
            (oldRefetchSubscribersFromStart !== refetchSubscribersFromStart && refetchSubscribersFromStart) ||
            isPharmacyChanged
        ) {
            fetchSubscriberList();
            this.setState({ isPharmacyChanged });
        }
    }

    handlePageChange = (event, newPage) => {
        const { changePage } = this.props;
        changePage(newPage + 1);
    };

    getFormattedDate = (date) => moment(date).format('MMM DD, YYYY');

    searchSubscribers = (searchString) => {
        const { changeSearch } = this.props;
        if (!searchString.length || searchString.length > 0) {
            changeSearch(searchString);
        }
    };

    deleteSubscriber = (subscriberId) => {
        const { removeSubscriber } = this.props;
        removeSubscriber(subscriberId);
    };

    render() {
        const {
            newsletter: {
                subscribersList,
                loadingSubscribers,
                totalSubscribers,
                totalSubscribed,
                subscribersListState,
            },
        } = this.props;

        const { isPharmacyChanged } = this.state;
        const { page, rowsPerPage, searchString } = subscribersListState;
        const noResult = searchString && !subscribersList.length && !loadingSubscribers;

        return (
            <div className="subscribers-list-container">
                <Card className="card">
                    <CardContent>
                        <div className="flex-middle" style={{ marginBottom: '16px' }}>
                            <div style={{ width: '400px' }}>
                                <SearchInput
                                    noResult={noResult}
                                    onSearch={this.searchSubscribers}
                                    placeholder="Email"
                                    isPharmacyChanged={isPharmacyChanged}
                                    debounceTime={600}
                                ></SearchInput>
                            </div>
                            {loadingSubscribers ? (
                                <ReactPlaceholder
                                    type="textRow"
                                    rows={1}
                                    ready={false}
                                    showLoadingAnimation
                                    firstLaunchOnly
                                    style={{ width: '100px', marginLeft: '15px', marginTop: '0px' }}
                                >
                                    <span> </span>
                                </ReactPlaceholder>
                            ) : (
                                <span style={{ marginLeft: '10px' }}>
                                    {totalSubscribed ? numberWithCommas(totalSubscribed) : 0} Subscribers
                                </span>
                            )}
                        </div>
                        {loadingSubscribers ? (
                            <SubscriberPlaceholder legendsCount={5} />
                        ) : (
                            <>
                                <TableContainer>
                                    <Table aria-label="subscriber table sdf" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <DpTableCell>Email</DpTableCell>
                                                <DpTableCell>Status</DpTableCell>
                                                <DpTableCell>Date Subscribed</DpTableCell>
                                                <DpTableCell> </DpTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <DpTableBody>
                                            {subscribersList.map((subscriber, idx) => (
                                                <DpTableRow key={idx} className="table-data-row">
                                                    <DpTableCell>{subscriber.email}</DpTableCell>
                                                    <DpTableCell>{_.capitalize(subscriber.status)}</DpTableCell>
                                                    <DpTableCell>
                                                        {subscriber.subscribed_date
                                                            ? this.getFormattedDate(subscriber.subscribed_date)
                                                            : 'N/A'}
                                                    </DpTableCell>
                                                    <DpTableCell>
                                                        {subscriber.deleting ? (
                                                            <CircularProgress size={16} />
                                                        ) : (
                                                            <DeleteIcon
                                                                className="delete-icon"
                                                                onClick={() => this.deleteSubscriber(subscriber.id)}
                                                            />
                                                        )}
                                                    </DpTableCell>
                                                </DpTableRow>
                                            ))}
                                            {!subscribersList.length && !loadingSubscribers && (
                                                <DpTableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        align="center"
                                                        style={{ fontWeight: 'bold' }}
                                                    >
                                                        {searchString
                                                            ? 'No matching subscriber found'
                                                            : 'There are no subscribers to newsletter'}
                                                    </TableCell>
                                                </DpTableRow>
                                            )}
                                        </DpTableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[25]}
                                    component="div"
                                    count={totalSubscribers}
                                    rowsPerPage={rowsPerPage}
                                    page={page - 1}
                                    onChangePage={this.handlePageChange}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, newsletter }) => {
    return {
        selectedCustomer: auth.selectedCustomer,
        newsletter,
    };
};

const mapDispatchToProps = {
    fetchSubscriberList: newsletterActions.fetchSubscriberList,
    removeSubscriber: newsletterActions.removeSubscriber,
    changePage: newsletterActions.changePage,
    changeSearch: newsletterActions.changeSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberList);
