import _ from 'lodash';
import { conversationType } from './type';
import { conversationService } from './service';
import { onboardingActions } from '../Onboarding';
import Snowplow, { InboxMessageContext, SnowplowConstants } from '../../../snowplow';

const resetConversationStore = () => {
    return function (dispatch) {
        dispatch({
            type: conversationType.RESET_CONVERSATION_STORE,
        });
    };
};

const setActiveConversation = (conversationId) => {
    return function (dispatch, getState) {
        const conversationState = getState().inboxConversation;
        const { conversations } = conversationState;
        conversations.forEach((conversation) => {
            if (conversation.inbox_conversation_id === conversationId) {
                Snowplow.structEvent('Inbox', 'pharmacist-viewed-conversation', [
                    new InboxMessageContext()
                        .setConversationId(conversation.inbox_conversation_id)
                        .setPatientInboxUserId(conversation.patient_inbox_user_id)
                        .build(),
                ]);

                dispatch({
                    type: conversationType.SET_ACTIVE_CONVERSATION,
                    activeConversation: conversation,
                    activeConversationId: conversationId,
                });
            }
        });
    };
};

const clearActiveConversation = () => {
    return {
        type: conversationType.CLEAR_ACTIVE_CONVERSATION,
    };
};

const openCreateConversationDialog = () => {
    return {
        type: conversationType.OPEN_CREATE_CONVERSATION_DIALOG,
    };
};

const closeCreateConversationDialog = () => {
    return {
        type: conversationType.CLOSE_CREATE_CONVERSATION_DIALOG,
    };
};

const toggleCreateConversationDialog = () => {
    return {
        type: conversationType.TOGGLE_CREATE_CONVERSATION_DIALOG,
    };
};

const getConversations = (autoSelectActive = false) => {
    return function (dispatch, getState) {
        dispatch({ type: conversationType.GET_CONVERSATIONS_REQUEST });
        const userId = getState().inboxUser.activeUserId;

        conversationService.getConversations(userId).then(
            (conversations) => {
                dispatch({ type: conversationType.GET_CONVERSATIONS_SUCCESS, conversations, autoSelectActive });
            },
            (error) => {
                dispatch({ type: conversationType.GET_CONVERSATIONS_FAILURE, error: error.toString() });
            }
        );
    };
};

const createConversation = (conversationData, onSuccess) => {
    return function (dispatch, getState) {
        dispatch({ type: conversationType.CREATE_CONVERSATION_REQUEST });

        return conversationService.createConversation(conversationData).then(
            (responseData) => {
                const { inboxUser } = getState();

                Snowplow.structEvent(
                    'Inbox',
                    'pharmacist-create-conversation-with-message',
                    [
                        new InboxMessageContext()
                            .setConversationId(responseData.conversation_id)
                            .setPatientInboxUserId(inboxUser.activeUserId)
                            .build(),
                    ],
                    null,
                    SnowplowConstants.success
                );

                dispatch({ type: conversationType.CREATE_CONVERSATION_SUCCESS, responseData });

                if (_.isFunction(onSuccess)) {
                    onSuccess();
                }

                dispatch(onboardingActions.getInboxLocationOnboarding());
            },
            (error) => {
                const { inboxUser } = getState();

                Snowplow.structEvent(
                    'Inbox',
                    'pharmacist-create-conversation-with-message',
                    [new InboxMessageContext().setPatientInboxUserId(inboxUser.activeUserId).build()],
                    null,
                    SnowplowConstants.failure
                );

                dispatch({ type: conversationType.CREATE_CONVERSATION_FAILURE, error });
            }
        );
    };
};

const updateActiveConversationMessageStatus = (messageId) => {
    return function (dispatch, getState) {
        const conversationState = getState().inboxConversation;
        const { conversations, activeConversation, activeConversationId } = conversationState;

        if (activeConversation.latest_message.inbox_message_id === messageId) {
            let conversationModel = null;
            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i].inbox_conversation_id === activeConversationId) {
                    conversationModel = conversations[i];
                    conversationModel.latest_message.viewed = 1;
                    break;
                }
            }

            if (conversationModel) {
                dispatch({
                    type: conversationType.UPDATE_ACTIVE_CONVERSATION_MESSAGE_STATUS,
                    conversations,
                    activeConversation: conversationModel,
                });
            }
        }
    };
};

export const conversationAction = {
    resetConversationStore,
    setActiveConversation,
    clearActiveConversation,
    openCreateConversationDialog,
    closeCreateConversationDialog,
    toggleCreateConversationDialog,
    getConversations,
    createConversation,
    updateActiveConversationMessageStatus,
};

export default conversationAction;
