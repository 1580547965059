import { get } from '../actionCreators/rest';
import Config from '../../config';
import { logHttpError } from '../../utils/log';

const fetchAverage = (pharmacyId) => {
    const averageUrl = `${Config.apiweb_url}/review/${pharmacyId}/average`;

    return get(averageUrl)
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logHttpError('An error occurred on reviews fetchAverage', err);
            return Promise.reject(err);
        });
};

const fetchSummary = (pharmacyId) => {
    const summaryUrl = `${Config.apiweb_url}/review/${pharmacyId}/summary`;

    return get(summaryUrl)
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logHttpError('An error occurred on reviews fetchSummary', err);
            return Promise.reject(err);
        });
};

const fetchList = (pharmacyId, page) => {
    const summaryUrl = `${Config.apiweb_url}/review/${pharmacyId}/list?page=${page}`;

    return get(summaryUrl)
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logHttpError('An error occurred on reviews fetchList', err);
            return Promise.reject(err);
        });
};

export const reviewService = {
    fetchAverage,
    fetchSummary,
    fetchList,
};

export default reviewService;
