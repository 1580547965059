import connectTypes from './type';

export const initialState = {
    loading: false,
    opportunities: null,
    birthdayDate: null,

    bulkMessageSending: false,
    bulkMessageResponse: null,
    bulkMessageError: null,
    bulkMessageStatusShown: false,
    bulkMessageTemplate: null,
};

export function connectReducer(state = initialState, { type, payload }) {
    switch (type) {
        case connectTypes.CONNECT_OPPORTUNITIES_REQUEST:
            return {
                ...state,
                loading: true,
                ...payload,
            };

        case connectTypes.CONNECT_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                ...payload,
            };

        case connectTypes.CONNECT_OPPORTUNITIES_FAILURE:
            return {
                ...state,
                loading: false,
                ...payload,
            };

        case connectTypes.CLEAR_OPPORTUNITIES:
            return {
                ...state,
                opportunities: null,
            };

        case connectTypes.SEND_BULK_MESSAGE_REQUEST:
            return {
                ...state,
                bulkMessageSending: true,
                bulkMessageError: null,
                bulkMessageResponse: null,
                bulkMessageTemplate: payload.template,
            };

        case connectTypes.SEND_BULK_MESSAGE_SUCCESS:
            return {
                ...state,
                bulkMessageSending: false,
                bulkMessageError: null,
                bulkMessageResponse: payload.response,
                bulkMessageStatusShown: false,
                bulkMessageTemplate: payload.template,
                opportunities: null, // clear our opportunities here so they will be refetched with latest backend changes
            };

        case connectTypes.SEND_BULK_MESSAGE_FAILURE:
            return {
                ...state,
                bulkMessageSending: false,
                bulkMessageError: payload.error,
                bulkMessageResponse: null,
                bulkMessageStatusShown: false,
                bulkMessageTemplate: payload.template,
            };

        case connectTypes.SET_BULK_MESSAGE_STATUS_SHOWN:
            return {
                ...state,
                bulkMessageStatusShown: payload.shown,
            };

        default:
            return state;
    }
}
