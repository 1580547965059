import Actions from 'redux/actions';
import { get, post, put, deleteReq } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../../utils/log';
import { GREETING_TYPE } from 'constants/MainGreeting';

const getGreetingApiUrl = () => {
    const greetingApiPath = API_PATH.MAIN_GREETING.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    return Config.config2_url + greetingApiPath;
};

export const updateGreetingMessage = (data) => (dispatch) => {
    if (data && data.contentType === 'text/plain') {
        dispatch(getFileContent(data)).then((resp) => {
            dispatch(changeGreetingScript(resp));
        });
    }

    dispatch({
        type: Actions.UPDATE_GREETING_MESSAGE,
        payload: data ? data.id : '',
    });
};

export const addGreeting = (payload) => ({
    type: Actions.ADD_GREETING,
    payload,
});

export const changeAfterHourSetting = (payload) => ({
    type: Actions.CHANGE_AFTER_HOUR_SETTING,
    payload,
});

const setGreetingType = (greeting, greetingType, dispatch) => {
    const type = greetingType === 'closed' || greetingType === 'aa_welcome' ? `${greetingType}_` : '';

    if (greeting[`${type}greeting_type`] === 'text/plain') {
        dispatch(changeGreetingType(GREETING_TYPE['CUSTOM_TEXT']));
        dispatch(getFileContent({ id: greeting[`${type}greeting_id`] })).then((resp) => {
            dispatch(changeGreetingScript(resp));
        });
    } else {
        dispatch(changeGreetingType(GREETING_TYPE['CUSTOM_AUDIO']));
    }
};

export const editGreeting = (data) => (dispatch) => {
    dispatch({
        type: Actions.EDIT_GREETING,
        payload: { type: data.type },
    });
    setGreetingType(data.defaultGreeting, data.type, dispatch);
};

export const editExceptionGreeting = (payload) => (dispatch) => {
    dispatch({
        type: Actions.EDIT_EXCEPTION_GREETING,
        payload,
    });

    setGreetingType(payload.specialGreeting, payload.type, dispatch);
};

export const closeGreetingEditor = () => ({
    type: Actions.CLOSE_GREETING_EDITOR,
});

export const updateExceptionDate = (payload) => ({
    type: Actions.UPDATE_SPECIAL_GREETING_DATERANGE,
    payload,
});

export const changeGreetingScript = (payload) => ({
    type: Actions.CHANGE_GREETING_SCRIPT,
    payload,
});

export const changeGreetingType = (payload) => ({
    type: Actions.CHANGE_GREETING_TYPE,
    payload,
});

export const changeTTSSetting = (payload) => ({
    type: Actions.CHANGE_TTS_SETTING,
    payload,
});

export const fetchGreetingFiles = () => (dispatch) => {
    const apiPath = API_PATH.GREETING_FILES.replace('{custID}', Config.X_PharmacyID);
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 9999, fileType: 'IVR Greeting' } };

    dispatch({ type: Actions.FETCH_GREETING_FILES_STARTED });
    const fetchGreetingFilesReq = get(apiUrl, header);

    return fetchGreetingFilesReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_GREETING_FILES_SUCCESS, payload: data });
            return data;
        })
        .catch((err) => {
            logHttpError('An error occured on fetchGreetingFilesReq', err);
            dispatch({ type: Actions.FETCH_GREETING_FILES_FAILED, payload: err });
            return { err };
        });
};

export const fetchSpecialGreetings = (ivrId) => (dispatch) => {
    const apiPath = API_PATH.SPECIAL_GREETING.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', ivrId);
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 99, maxAgeDays: 7 } };

    dispatch({ type: Actions.FETCH_SPECIAL_GREETING_STARTED });
    const fetchSpecialGreetingReq = get(apiUrl, header);

    fetchSpecialGreetingReq
        .then((data) => dispatch({ type: Actions.FETCH_SPECIAL_GREETING_SUCCESS, payload: data }))
        .catch((err) => {
            logHttpError('An error occured on fetchSpecialGreetingReq', err);
            dispatch({ type: Actions.FETCH_SPECIAL_GREETING_FAILED, payload: err });
        });
};

export const resetIVR = (payload) => (dispatch) => {
    dispatch({ type: Actions.RESET_IVR_STARTED });

    const apiPath = getGreetingApiUrl() + '/' + payload.id;
    const resetIVRReq = put(apiPath, { reset: true });

    return resetIVRReq
        .then((data) => {
            dispatch({ type: Actions.RESET_IVR_SUCCESS, payload: data });
            dispatch(fetchMainGreeting());
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on resetIVR', err);
            dispatch({ type: Actions.RESET_IVR_FAILED, payload: err });
            return { err };
        });
};

export const fetchMainGreeting = () => (dispatch) => {
    dispatch({ type: Actions.FETCH_MAIN_GREETING_STARTED });
    const fetchGreetingReq = get(getGreetingApiUrl());

    return fetchGreetingReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_MAIN_GREETING_SUCCESS, payload: data });
            return data;
        })
        .catch((err) => {
            logHttpError('An error occured on fetchMainGreeting', err);
            dispatch({ type: Actions.FETCH_MAIN_GREETING_FAILED, payload: err });
            return { err };
        });
};

export const markDefaultGreeting = (payload) => (dispatch) => {
    dispatch({ type: Actions.MARK_DEFAULT_GREETING_STARTED, payload });
    const defaultGreeting = { ...payload.defaultGreeting };
    const newGreeting = {};
    const saveApiUrl = `${getGreetingApiUrl()}/${defaultGreeting.id}`;
    newGreeting.greeting_id = payload.exceptionGreetings[payload.idx].greeting_id;

    const updateGreetingReq = put(saveApiUrl, newGreeting);

    return updateGreetingReq
        .then((resp) => {
            dispatch({ type: Actions.MARK_DEFAULT_GREETING_SUCCESS, payload });
            dispatch(displayToast({ text: 'Marked as default greeting', type: 'success' }));
            dispatch(deleteSpecialGreeting(payload));
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on markDefaultGreeting', err);
            dispatch({ type: Actions.MARK_DEFAULT_GREETING_FAILED, payload });
            dispatch(displayToast({ text: 'Mark as default greeting failed', type: 'error' }));
            return { err };
        });
};

export const deleteGreeting = (payload) => (dispatch) => {
    const saveApiUrl = `${getGreetingApiUrl()}/${payload.id}`;
    dispatch({ type: Actions.REMOVE_GREETING_STARTED, payload });

    const updateGreetingReq = put(saveApiUrl, payload);

    return updateGreetingReq
        .then((resp) => {
            dispatch({ type: Actions.REMOVE_GREETING_SUCCESS, payload: payload });
            dispatch(displayToast({ text: 'Default Greeting removed', type: 'success' }));
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on deleteGreetingReq', err);
            dispatch({ type: Actions.REMOVE_GREETING_FAILED, payload: payload });
            dispatch(displayToast({ text: 'Default Greeting remove failed', type: 'error' }));
            return { err };
        });
};

export const deleteSpecialGreeting = (payload) => (dispatch) => {
    dispatch({ type: Actions.REMOVE_SPECIAL_GREETING_STARTED, payload });
    const { defaultGreeting, exceptionGreetings } = payload;
    const deletedSpecialGreeting = exceptionGreetings[payload.idx];
    const apiPathCustom = API_PATH.SPECIAL_GREETING.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', defaultGreeting.id);
    const apiUrlCustom = Config.config2_url + apiPathCustom;
    const removeSpecialGreetingReq = deleteReq(`${apiUrlCustom}/${deletedSpecialGreeting.id}`);
    return removeSpecialGreetingReq
        .then((resp) => {
            dispatch({ type: Actions.REMOVE_SPECIAL_GREETING_SUCCESS, payload });
            dispatch(displayToast({ text: 'Special Greeting removed', type: 'success' }));
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on removeSpecialGreetingReq', err);
            dispatch({ type: Actions.REMOVE_SPECIAL_GREETING_FAILED, payload });
            dispatch(displayToast({ text: 'Special Greeting remove failed', type: 'error' }));
            return { err };
        });
};

export const getFileContent = (data) => (dispatch) => {
    const apiPath = API_PATH.GREETING_FILES.replace('{custID}', Config.X_PharmacyID);
    const apiUrl = Config.config2_url + `${apiPath}/${data.id}/content`;

    const fetchGreetingFilesReq = get(apiUrl);
    return fetchGreetingFilesReq
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on getFileContent', err);
            return { err };
        });
};

export const saveGreeting = (greetingData) => (dispatch) => {
    const { newGreeting, type } = { ...greetingData };
    const saveApiUrl = newGreeting.id ? `${getGreetingApiUrl()}/${newGreeting.id}` : getGreetingApiUrl();

    dispatch({ type: Actions[`SAVE_${type}_GREETING_STARTED`] });
    const saveGreetingReq = newGreeting.id ? put(saveApiUrl, newGreeting) : post(saveApiUrl, newGreeting);

    return saveGreetingReq
        .then((resp) => {
            dispatch({ type: Actions[`SAVE_${type}_GREETING_SUCCESS`] });
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on saveGreetingReq', err);
            dispatch({ type: Actions[`SAVE_${type}_GREETING_FAILED`], payload: err });
            return { err };
        });
};

export const fetchAllGreetings = (canCreateGreeting = false) => (dispatch) => {
    dispatch({ type: Actions.FETCH_IVR_LANG_GREETING_STARTED });
    dispatch(fetchMainGreeting()).then((resp) => {
        if (resp.err) {
            if (canCreateGreeting) {
                dispatch(saveGreeting({ newGreeting: {}, type: 'MAIN' })).then(() => {
                    dispatch(fetchMainGreeting()).then((data) => {
                        dispatch(fetchSpecialGreetings(data.id));
                    });
                });
            }
        } else {
            dispatch(fetchSpecialGreetings(resp.id));
        }
    });
};

export const saveSpecialGreeting = (greetingData) => (dispatch) => {
    const { defaultGreeting, newGreeting } = greetingData;
    const apiPathCustom = API_PATH.SPECIAL_GREETING.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', defaultGreeting.id);
    const saveApiUrl = Config.config2_url + apiPathCustom;
    dispatch({ type: Actions.SAVE_SPECIAL_GREETING_STARTED });
    const saveSpecialGreetingReq = newGreeting.id
        ? put(`${saveApiUrl}/${newGreeting.id}`, newGreeting)
        : post(saveApiUrl, newGreeting);

    return saveSpecialGreetingReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_SPECIAL_GREETING_SUCCESS });
            dispatch(displayToast({ text: 'Special Greeting saved', type: 'success' }));
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on saveSpecialGreetingReq', err);
            dispatch({ type: Actions.SAVE_SPECIAL_GREETING_FAILED, payload: err });
            dispatch(displayToast({ text: 'Special Greeting save failed', type: 'error' }));
            return { err };
        });
};

export const fileUpload = (greetingData) => (dispatch) => {
    const { fileObj, fileParams } = greetingData;
    const apiPath = API_PATH.GREETING_FILES.replace('{custID}', Config.X_PharmacyID);
    const apiUrl = Config.config2_url + apiPath;

    const formData = new FormData();
    formData.append('file', fileObj);
    formData.append('metadata', JSON.stringify(fileParams));
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    dispatch({ type: Actions.SAVE_GREETING_FILE_STARTED });

    const saveGreetingFileReq = post(apiUrl, formData, config);
    return saveGreetingFileReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_GREETING_FILE_SUCCESS });
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on saveGreetingFileReq', err);
            dispatch({ type: Actions.SAVE_GREETING_FILE_FAILED, payload: err });
            return err;
        });
};

export const saveFilename = (data) => (dispatch) => {
    const apiPath = API_PATH.GREETING_FILES.replace('{custID}', Config.X_PharmacyID);
    const apiUrl = `${Config.config2_url}${apiPath}/${data.id}`;

    dispatch({ type: Actions.UPDATE_GREETING_FILE_STARTED });
    const updateGreetingFileReq = put(apiUrl, data);

    return updateGreetingFileReq
        .then((data) => {
            dispatch(fetchGreetingFiles()).then(() =>
                dispatch({ type: Actions.UPDATE_GREETING_FILE_SUCCESS, payload: data })
            );
        })
        .catch((err) => {
            logHttpError('An error occured on saveFilename', err);
            dispatch({ type: Actions.UPDATE_GREETING_FILE_FAILED, payload: err });
        });
};
