import React, { Component } from 'react';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import axios from 'axios';
import ReactPlaceholder from 'react-placeholder';
import Linkify from 'react-linkify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import 'react-placeholder/lib/reactPlaceholder.css';

import config from '../../../config';
import api from '../../../api.json';
import { stringToColor } from '../../../utils/helper';
import { formatUTCToRelative, getTimeDiff } from '../../../utils/formatTime';
import MessageCTACard from './MessageCTACard';
import _ from 'lodash';
import moment from 'moment';

import { InlineYesNoQuestionPrompt } from '@digitalpharmacist/messaging-forms';
import PatientAvatar from 'components/Patients/PatientAvatar';
import { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';

const CancelToken = axios.CancelToken;

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
);

class MessageAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imageDataUri: null,
            showAttachment: false,
            isOpen: false,
        };
        this.cancelImageLoad = null;
    }

    componentDidMount() {
        this.getImage();
    }

    componentWillUnmount() {
        if (this.cancelImageLoad) {
            this.cancelImageLoad();
        }
    }

    getImage = () => {
        const { message } = this.props;
        const imageUrl = `${config.apiweb_url}${api.INBOX_BASE}/conversation/${message.inbox_conversation_id}/message/${message.inbox_message_id}/attachment`;
        const that = this;

        axios
            .get(imageUrl, {
                responseType: 'blob',
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    that.cancelImageLoad = c;
                }),
            })
            .then(function (response) {
                // Request finished, reset cancelImageLoad
                that.cancelImageLoad = null;

                const reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {
                    that.setState({
                        image: response.data,
                        imageDataUri: reader.result,
                    });
                };
            })
            .catch((error) => {
                // Eat error for now
            });
    };

    render() {
        const { showAttachment, imageDataUri } = this.state;
        return (
            <ReactPlaceholder
                type="rect"
                color="#E0E0E0"
                style={{ height: 200, width: 200, margin: 0 }}
                showLoadingAnimation
                ready={!!imageDataUri}
            >
                <img
                    alt="Show Attachment"
                    src={imageDataUri}
                    style={{
                        maxHeight: '200px',
                        maxWidth: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ showAttachment: !showAttachment })}
                />
                {showAttachment && (
                    <Lightbox
                        mainSrc={imageDataUri}
                        onCloseRequest={() => this.setState({ showAttachment: false })}
                        reactModalStyle={{ overlay: { zIndex: 1301 } }} // set this to be greater than 1300 (mui presentation z-index)
                    />
                )}
            </ReactPlaceholder>
        );
    }
}

function MessageText(props) {
    const { text } = props;
    return <Linkify componentDecorator={componentDecorator}>{text}</Linkify>;
}

function MessageContent(props) {
    const { message } = props;
    let content = null;

    if (message.content_type === 'attachment') {
        content = <MessageAttachment message={message} />;
        // prevent rendering an empty bubble for the scenario that text does not exist on the message
    } else if (message.content_type === 'call_to_action' && message.content.text) {
        if (
            _.get(message, 'content.action_type') === 'messaging_form' &&
            _.isString(_.get(message, 'content.messaging_form.yes_no_prompt_question'))
        ) {
            const inlineYesNoQuestionId = _.get(message, 'content.messaging_form.yes_no_prompt_question');
            const savedAnswerDetails = _.get(
                message,
                `content.messaging_form.saved_assessment_answers.${inlineYesNoQuestionId}`,
                {}
            );
            content = (
                <MessageText
                    text={
                        <span>
                            <div style={{ marginBottom: '10px' }}>{message.content.text}</div>
                            <span style={{ pointerEvents: 'none' }}>
                                <InlineYesNoQuestionPrompt value={savedAnswerDetails.answer} />
                            </span>
                            {_.isEmpty(savedAnswerDetails) ? null : (
                                <div className="answered-at" style={{ fontSize: '11px', margin: '5px 0 5px 0' }}>
                                    They answered{' '}
                                    {moment.utc(savedAnswerDetails.created_date, 'YYYY-MM-DDTHH:mm:ss').fromNow()}
                                </div>
                            )}
                        </span>
                    }
                />
            );
        } else {
            content = <MessageText text={message.content.text} />;
        }
    } else if (message.content_type === 'text' && message.content) {
        content = <MessageText text={message.content} />;
    }

    return (
        content && (
            <div className="conversation-message__bubble">
                <div className="conversation-message__content">{content}</div>
            </div>
        )
    );
}

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTime: false,
        };
    }

    componentDidMount() {
        this.calcShowTime();
    }

    computeAvatarStyle = (fullName) => {
        return {
            width: '24px',
            height: '24px',
            fontSize: '.7rem',
            backgroundColor: stringToColor(`${fullName}`),
        };
    };

    calcShowTime = () => {
        const { message, previousMessage, last } = this.props;
        let showTime = this.state.showTime;

        if (!previousMessage) {
            showTime = true;
        } else if (last) {
            showTime = false;
        } else {
            const secondsDiff = getTimeDiff(previousMessage.created_date, message.created_date);
            showTime = secondsDiff > 300;
        }

        this.setState({
            showTime,
        });
    };

    render() {
        const { showTime } = this.state;
        const { message, contact, lastSent, lastRead, activeLocationCountryCode } = this.props;
        const showMeta = lastRead || lastSent;
        const isPatient = message.inbox_user_id === contact.inbox_user_id;

        const relativeTime = formatUTCToRelative({
            inputTimeString: message.created_date,
            countryCode: activeLocationCountryCode,
        });
        const isCallToActionContentType = message.content_type === 'call_to_action';

        const inlineYesNoQuestionId = _.get(message, 'content.messaging_form.yes_no_prompt_question');
        const savedAnswerDetails = _.isNil(inlineYesNoQuestionId)
            ? null
            : _.get(message, `content.messaging_form.saved_assessment_answers.${inlineYesNoQuestionId}`, {});

        const showCtaBubble = _.isNil(inlineYesNoQuestionId) || savedAnswerDetails.answer === 'yes';

        return (
            <>
                {showTime && <div className="conversation-message-list__time ">{relativeTime}</div>}
                <div
                    className={`conversation-message ${isPatient ? 'patient-message' : 'pharmacist-message'} ${
                        showMeta ? 'show-meta' : 'hide-meta'
                    }`}
                >
                    <div className="conversation-message__avatar">
                        {isPatient ? (
                            <PatientAvatar seed={getPatientAvatarSeedFromContact(contact)} />
                        ) : (
                            <div className="pharmacy-icon">
                                <LocalPharmacyIcon />
                            </div>
                        )}
                    </div>

                    <div className="conversation-message__wrap">
                        <MessageContent message={message} />

                        {isCallToActionContentType && showCtaBubble && (
                            <div className="conversation-message__cta-wrapper">
                                <MessageCTACard messageContent={message.content} />
                            </div>
                        )}

                        {showMeta && (
                            <div className="conversation-message__meta__wrap">
                                <span className="conversation-message__meta__content">
                                    {lastRead ? 'Read' : 'Sent'}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Message;
