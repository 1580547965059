import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Cell, TextField, Checkbox, FontIcon } from 'react-md';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { Tooltip } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Button as MUButton } from '@material-ui/core';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';

import { ConvertStringToTitle } from 'utils/index';

const OTHER_HOLIDAY = 'other';

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holiday: props.id !== '0' && props.holidayId ? props.holidayId : '',
        };
    }

    handleChange = (event) => {
        const { onHolidaySelected, holidayList } = this.props;
        const selected = event.target.value;
        this.setState({ holiday: selected });
        const holidayDetail = holidayList.find((holiday) => holiday.id === selected);
        onHolidaySelected(holidayDetail);
    };

    render() {
        const {
            onStartHourChange,
            onEndHourChange,
            onCloseToggle,
            onTitleChange,
            onRepeatToggle,
            errMsg,
            holidayList,
            title,
            startHour,
            startMinute,
            endMinute,
            endHour,
            edit,
            presetHoliday,
            id,
            repeats,
            uuid,
            holidayId,
            startDate,
            endDate,
            isReadOnly,
        } = this.props;
        const { holiday } = this.state;
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const repeatDisabled =
            (startDateMoment.format('M') === '2' && startDateMoment.format('D') === '29') ||
            (endDateMoment.format('M') === '2' && endDateMoment.format('D') === '29');
        const isClosed = startHour === 0 && endHour === 0;
        const clockCssClassName = isClosed ? 'closed-hours-btn' : '';

        return (
            <div className="grid-container">
                {!edit && (
                    <Cell size={3} phoneSize={2} tabletSize={2} className="flex-middle day-label">
                        {ConvertStringToTitle(title)}
                    </Cell>
                )}

                {edit && (
                    <Cell size={6} phoneSize={4} tabletSize={8} className="flex-bottom editable-holiday">
                        <div className="repeat-holiday">
                            <span>
                                Repeat
                                <Tooltip
                                    classes={{ tooltip: 'info-tooltip' }}
                                    placement="top"
                                    enterTouchDelay={0}
                                    title="Repeats the holiday every year on the appropriate day(s)."
                                >
                                    <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                                        info
                                    </FontIcon>
                                </Tooltip>
                            </span>
                            <Checkbox
                                id={`repeat-holiday-${id === '0' ? uuid : id}`}
                                name="repeat-holiday"
                                aria-label="reapeat-holiday"
                                disabled={isReadOnly || (id !== '0' && !holidayId) || repeatDisabled}
                                checked={repeats ? true : false}
                                onChange={onRepeatToggle}
                            />
                        </div>
                        {(((holiday === '' || presetHoliday) && id === '0') || (id !== '0' && holidayId)) && (
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel
                                    htmlFor="holiday-select-outlined"
                                    classes={{
                                        shrink: 'holiday-select-label',
                                    }}
                                >
                                    Select holiday
                                </InputLabel>
                                <Select
                                    id="holiday-select-outlined"
                                    autoWidth
                                    value={holiday}
                                    onChange={this.handleChange}
                                    disabled={isReadOnly}
                                >
                                    {holidayList.map((holiday, idx) => (
                                        <MenuItem key={idx} value={holiday.id}>
                                            {holiday.name}
                                        </MenuItem>
                                    ))}
                                    {id === '0' && <MenuItem value={OTHER_HOLIDAY}>Select my own date</MenuItem>}
                                </Select>
                            </FormControl>
                        )}

                        {(holiday === OTHER_HOLIDAY || (id !== '0' && !holidayId)) && (
                            <TextField
                                id="holiday-name"
                                placeholder="Holiday"
                                error={errMsg !== ''}
                                readOnly={isReadOnly}
                                value={title}
                                onChange={onTitleChange}
                                disabled={isReadOnly}
                            />
                        )}
                    </Cell>
                )}

                <Cell
                    size={2}
                    phoneSize={2}
                    tabletSize={!edit ? 2 : 4}
                    phoneOrder={!edit ? 3 : 0}
                    className="store-hour-picker"
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            id={`${title}_open`}
                            label="Open"
                            minutesStep={10}
                            placeholder="Opening Hrs"
                            fullWidth={false}
                            disabled={isReadOnly || isClosed}
                            value={moment(`${startHour}:${startMinute}`, 'hh:mm a').toDate()}
                            onChange={onStartHourChange}
                        />
                    </MuiPickersUtilsProvider>
                </Cell>

                <Cell size={1} phoneHidden tabletHidden className="centered-content">
                    <div className={isReadOnly || isClosed ? 'md-text--disabled' : ''}> to </div>
                </Cell>

                <Cell
                    size={2}
                    phoneSize={2}
                    tabletSize={!edit ? 2 : 4}
                    phoneOrder={!edit ? 4 : 0}
                    className="store-hour-picker"
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            id={`${title}_close`}
                            label="Close"
                            minutesStep={10}
                            placeholder="Closing Hrs"
                            fullWidth={false}
                            disabled={isReadOnly || isClosed}
                            value={moment(`${endHour}:${endMinute}`, 'hh:mm a').toDate()}
                            onChange={onEndHourChange}
                        />
                    </MuiPickersUtilsProvider>
                </Cell>

                <Cell
                    size={1}
                    phoneSize={2}
                    phoneOrder={!edit ? 2 : 0}
                    className="flex-middle"
                    phoneHidden={edit}
                    tabletHidden={edit}
                >
                    <Button
                        id="close"
                        icon
                        disabled={isReadOnly}
                        className={isReadOnly ? '' : clockCssClassName}
                        onClick={onCloseToggle}
                    >
                        alarm_off
                    </Button>
                </Cell>

                {edit && (
                    <Cell size={1} phoneSize={4} tabletSize={8} className="close-toggle flex-middle" desktopHidden>
                        <MUButton
                            className="mobile-button"
                            variant="outlined"
                            disabled={isReadOnly}
                            color="primary"
                            onClick={onCloseToggle}
                            startIcon={<AlarmOffIcon />}
                        >
                            SET HOURS{' '}
                        </MUButton>
                    </Cell>
                )}
            </div>
        );
    }
}

Schedule.propTypes = {
    edit: PropTypes.bool,
    title: PropTypes.string.isRequired,
    startHour: PropTypes.number.isRequired,
    endHour: PropTypes.number.isRequired,
    onStartHourChange: PropTypes.func.isRequired,
    onEndHourChange: PropTypes.func.isRequired,
    onCloseToggle: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    onTitleChange: PropTypes.func,
    errMsg: PropTypes.string,
};

Schedule.defaultProps = {
    edit: false,
    errMsg: '',
};

export default Schedule;
