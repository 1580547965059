import './FilteringOptionGroup.sass';
import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

export default function FilteringOptionGroup({ options, value, onChange }) {
    return (
        <div className="filtering-option-group">
            {_.map(options, (o, i) => {
                return (
                    <div
                        key={o.value}
                        tabIndex={0}
                        className={classnames({ 'filtering-option': true, selected: o.value === value })}
                        onClick={() => {
                            onChange(o.value);
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onChange(o.value);
                            }
                        }}
                    >
                        <span className="icon">{o.icon}</span>
                        <span className="label">{o.label}</span>
                    </div>
                );
            })}
        </div>
    );
}
