import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactPlaceholder from 'react-placeholder';

import { Title } from './InboxMessage/Title';
import { fetchUnreadVoicemails } from 'redux/actionCreators/Voicemail';
import { userAction } from 'redux/Inbox/User';
import { formatPhoneNo, stripISDCode } from 'utils/helper';
import withMediaQuery from 'components/Common/WithMediaQuery';

export const VMPlaceholder = ({ legendsCount, style }) => {
    const placeHolderStyle = style ? style : { height: '50px' };
    return new Array(legendsCount).fill(0).map((item, idx) => (
        <Grid container alignItems="center" className="vm-widget-container" key={idx}>
            <Grid item xs={2} style={{ paddingLeft: '8px' }}>
                <ReactPlaceholder
                    type="round"
                    ready={false}
                    showLoadingAnimation
                    firstLaunchOnly
                    style={{ width: 50, height: 50 }}
                >
                    <div></div>
                </ReactPlaceholder>
            </Grid>
            <Grid item xs={10} style={{ paddingRight: '8px' }}>
                <ReactPlaceholder
                    type="rect"
                    rows={1}
                    ready={false}
                    showLoadingAnimation
                    firstLaunchOnly
                    style={placeHolderStyle}
                >
                    <div></div>
                </ReactPlaceholder>
            </Grid>
        </Grid>
    ));
};

class VMWidget extends Component {
    componentDidMount() {
        const { fetchUnreadVoicemails, getUsers } = this.props;
        fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 });
        getUsers();
    }

    componentDidUpdate = ({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) => {
        const { fetchUnreadVoicemails, getUsers, selectedLocation, pharmacy } = this.props;

        const isPharmacyUpdated = oldPharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isLocationChanged = selectedLocation !== oldSelectedLocation;

        if ((isLocationChanged || isPharmacyUpdated) && !pharmacy.loading) {
            fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 });
            getUsers();
        }
    };

    getTopNewVoicemails = () => {
        const latestVM = [];
        const {
            voicemailDetails: { unreadVoicemails },
            inboxUser: { allUsers },
        } = this.props;
        const newVMCount = unreadVoicemails.length < 3 ? unreadVoicemails.length : 3;
        const userMap = {};

        allUsers.forEach((user) => {
            userMap[user.phone] = user;
        });
        for (let i = 0; i < newVMCount; i++) {
            if (moment.utc(unreadVoicemails[i].callDateUTC).local().isSame(moment(), 'days')) {
                const phone = unreadVoicemails[i].phone;
                const phoneWithoutISD = stripISDCode(phone);
                const userData = userMap[phoneWithoutISD];
                const hasMobile = userData && userData.phone_type === 'mobile';

                latestVM.push({
                    msg: unreadVoicemails[i].transcription,
                    phone: phoneWithoutISD,
                    showQuickReply: !userData || hasMobile,
                    user: userData || '',
                });
            }
        }

        return latestVM;
    };

    replyInbox = (user, phone) => {
        const { history } = this.props;
        let state = '';

        if (!user) {
            state = { vmPhone: phone };
        } else {
            state = { vmUser: user };
        }

        history.push('/inbox', state);
    };

    getName = (user) => {
        return user ? `${user.first_name} ${user.last_name}` : '';
    };

    render() {
        const {
            history,
            isMobile,
            voicemailDetails: { loadingUnreadVoicemails },
        } = this.props;
        const latestVM = this.getTopNewVoicemails();

        return loadingUnreadVoicemails ? (
            <VMPlaceholder legendsCount={3} />
        ) : (
            latestVM.map((vm, idx) => (
                <div className="vm-widget-container" key={idx}>
                    <Title
                        name={this.getName(vm['user']) || formatPhoneNo(vm['phone'])}
                        noAvatarInitial={!vm['user']}
                        title="New Voicemail from"
                        onArrowClick={() => history.push('/voicemail')}
                    />
                    <p className="vm-widget__body">{vm['msg'] || 'Transcription not available'}</p>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        alignItems="center"
                        style={{ marginBottom: '16px', marginTop: '16px' }}
                        justify="flex-end"
                    >
                        {isMobile && (
                            <Button size="small" color="primary" variant="outlined">
                                Call Back
                            </Button>
                        )}
                        {vm['showQuickReply'] && (
                            <Button
                                style={{ marginLeft: '16px' }}
                                size="medium"
                                color="primary"
                                variant="outlined"
                                onClick={() => this.replyInbox(vm['user'], vm['phone'])}
                            >
                                Quick reply with inbox
                            </Button>
                        )}
                    </Grid>
                </div>
            ))
        );
    }
}

const mapStateToProps = (state) => ({
    selectedLocation: state.auth.selectedLocation.id,
    pharmacy: state.pharmacy,
    voicemailDetails: state.voicemails,
    inboxUser: state.inboxUser,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUnreadVoicemails: (data) => dispatch(fetchUnreadVoicemails(data)),
    getUsers: () => dispatch(userAction.getUsersList()),
});

export const VoicemailWidget = withRouter(connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(VMWidget)));
