import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const EmptyCalendar = () => (
    <div className="empty-calendar">
        <Typography className="title"> Calendar </Typography>
        <Grid justify="center" container className="content">
            <img
                width={148}
                height={148}
                src={`/newsletter/empty_calendar.png`}
                alt="Empty calendar icon"
                className="standard-margin-bottom-10"
            />
            <Typography className="description" gutterBottom>
                Click on an upcoming eNewsletter to edit the content
            </Typography>
        </Grid>
    </div>
);

export default EmptyCalendar;
