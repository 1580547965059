import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { isUnmessageablePhoneType } from '../../utils/helper';
import { AbilityContext, userActions, restrictedResources } from 'casl';

class UploadAttachmentPreview extends React.Component {
    static contextType = AbilityContext;

    removeAttachment = () => {
        this.props.handleAttachment('');
    };

    render() {
        const { imageUrl, sending } = this.props;
        const { opt_out, phone_type } = this.props.contact;
        const isUnsubscribed = !!opt_out;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const { send } = userActions;
        const { subject: messages } = restrictedResources.inbox.messages;
        const canSubmit = this.context.can(send, messages);

        return (
            <Fragment>
                <div className="conversation-message-input__content">
                    <IconButton className="remove" onClick={this.removeAttachment} size="small">
                        <ClearIcon style={{ fontSize: '24px' }} />
                    </IconButton>
                    <div className="img-thumbnail">
                        <img src={imageUrl} alt="upload" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    </div>
                </div>
                <div className="conversation-message-input__submit">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={sending || isUnsubscribed || isUnmessageable || !canSubmit}
                        onClick={this.props.onAttachmentSubmit}
                        style={{ boxShadow: 'none', minWidth: '75px' }}
                    >
                        {sending ? (
                            <CircularProgress size={20} className="conversation-message-input__progress" />
                        ) : (
                            'Send'
                        )}
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export default UploadAttachmentPreview;
