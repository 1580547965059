import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

export default ({ resource, onClick }) => (
    <Fragment>
        <Link to={resource.link} className="appBar__resourcesPopdownLink">
            <MenuItem dense className="appBar__resourcesPopdownMenu" onClick={onClick}>
                <div className="appBar__resourcesPopdownLink--Text">{resource.title}</div>
            </MenuItem>
        </Link>
    </Fragment>
);
