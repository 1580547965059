import './CalendarSelect.sass';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import _ from 'lodash';

export default function CalendarSelect({ calendars, value, onChange, showLabel = true, disabled }) {
    const labelRef = useRef();
    const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

    const [, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <FormControl variant="outlined">
            {!showLabel ? null : <InputLabel ref={labelRef}>Clinical Appointment Calendar...</InputLabel>}
            <Select
                className="calendar-select"
                data-cy="calendar-select"
                value={_.isNil(value) ? '' : value}
                onChange={onChange}
                labelWidth={labelWidth}
                disabled={disabled}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                {_.map(calendars, (calendar) => {
                    return (
                        <MenuItem
                            key={calendar.calendar_id}
                            value={calendar.calendar_id}
                            data-cy="calendar-select-option"
                        >
                            <span className="calendar-select-line">
                                <EventIcon style={{ color: calendar.color }} />
                                <span className="name">{calendar.name}</span>
                            </span>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
