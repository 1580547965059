import React from 'react';
import { Card, CardContent, Grid, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SectionTitle, SectionSubTitle } from 'components/Common';

export const IndependentMenuItem = (props) => {
    const { titleText, subTitleText, clickHandler, active, loading, hideActions } = props;
    const isMobile = !useMediaQuery((theme) => theme.breakpoints.up('md'));
    const buttonText = active ? 'Deactivate' : 'Activate';
    const buttonColor = active ? 'secondary' : 'primary';
    const ButtonOrSwitch = () => {
        if (hideActions) {
            return null;
        }

        return isMobile ? (
            <Switch checked={active} onChange={clickHandler} color="primary" name="switch-menu" />
        ) : (
            <Button variant="outlined" color={buttonColor} onClick={clickHandler}>
                {buttonText}
            </Button>
        );
    };

    return (
        <Card className="standard-margin-top-10" variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={10}>
                        <SectionTitle text={titleText} />
                        <SectionSubTitle text={subTitleText} />
                    </Grid>
                    <Grid container item xs={2} alignItems="center" justify="flex-end">
                        {loading ? <CircularProgress id="ivr-menu-update-spinner" /> : <ButtonOrSwitch />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

IndependentMenuItem.propTypes = {
    titleText: PropTypes.string.isRequired,
    subTitleText: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};
