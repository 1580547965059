import React from 'react';

import Service from './Service';
import WithCustPackages from './CustPackages';

const title = 'Wellness Classes';
const description = 'Enable or disable Wellness Classes for this pharmacy.';

const WellnessClasses = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackages(WellnessClasses, {
    title,
    description,
    code: 'CLSS',
});
