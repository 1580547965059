import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import useScript from 'customHooks/useScript';
import responsiveStyles from 'styles/cssInJs/responsiveBox';
import CourseContentUrls from 'constants/CourseContentUrls';

const styles = {
    ...responsiveStyles,
    fullWidthAndHeight: {
        width: '100%',
        height: '100%',
    },
};

// See https://wistia.com/support/embed-and-share/video-on-your-website#responsive-design-with-inline-embeds
// for details of the implementation. The entire code is copied from the link, and
// modified to adhere to the conventions of React.js
const WistiaVideo = ({ video, classes }) => {
    const src = `${CourseContentUrls.prefixes.wistia}${video.id}.json`;
    useScript('https://fast.wistia.com/assets/external/E-v1.js');
    useScript(src);

    return (
        <div className={`wistia_responsive_padding ${classes.outerbox}`}>
            <div className={`wistia_responsive_wrapper ${classes.innerbox}`}>
                <div
                    className={`wistia_embed wistia_async_${video.id} seo=false videoFoam=true" ${classes.fullWidthAndHeight}`}
                    style={{ height: '100%', width: '100%' }}
                >
                    &nbsp;
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(WistiaVideo);
