import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { displayToast } from 'redux/actionCreators/Snackbar';
import { pharmacyAction } from 'redux/Pharmacy/action';
import {
    deleteCallRailsAttribute,
    addCallRailID,
    getCallRailsForLocation,
} from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/CallRailsTracking';
import { getAttributeByName } from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2Attributes';

import CallRailsSelectionDialog from './CallRailsSelectionDialog';
import Service from '../Service';

class CallRailsTracking extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            openSelectionDialog: false,
        };
        this.callRailAttributeName = 'callRailId';
        this.getAttributeByName = _.memoize(getAttributeByName);
        this.resetLocalCallRailData = () => (this.callRails = undefined);
        this.title = 'CallRail - Marketing Call Tracking';
        this.description =
            'Use CallRail product to create and track phone ' +
            'numbers for marketing. Activate to link CallRail to Leads page.';
    }

    getSelectedPharmacyLocation = () => {
        const { pharmacy, selectedLocation } = this.props;
        return _.find(pharmacy.location, (location) => location.id === selectedLocation.id);
    };

    addCallRailID = async (callRailID) => {
        const { pharmacy, selectedLocation, addCallRailID } = this.props;
        const callRailAttribute = await this.getAttributeByName(this.callRailAttributeName);
        if (!_.isUndefined(callRailID)) {
            const payload = {
                value: callRailID,
                name: 'callRailId',
                attrTypeId: callRailAttribute.id,
            };
            // add call rail id to config-2
            await addCallRailID(pharmacy.id, selectedLocation.id, payload);
        }
    };

    autoAddCallRailID = async () => {
        const { getCallRailsForLocation } = this.props;
        const selectedPharmacyLocation = this.getSelectedPharmacyLocation();
        // Fetch call rail id's from https://callrails.com
        this.callRails = await getCallRailsForLocation(selectedPharmacyLocation.phone);

        if (this.callRails.length === 1) {
            await this.addCallRailID(_.get(this.callRails, '[0].id'));
            this.resetLocalCallRailData();
        } else if (this.callRails.length > 1) {
            // Given that there are inconsistencies in the naming conventions for the
            // locations on C2 and callrails.com, we would rather allow user to pick the
            // call rail id for the location instead of deciphering it ourselves using
            // string search.
            this.setState({ openSelectionDialog: true });
        }
        await this.waitUntilLocalDataIsReset();
    };

    waitUntilLocalDataIsReset = async () => {
        return await new Promise((resolve) => {
            const interval = setInterval(() => {
                if (_.isEmpty(this.callRails)) {
                    resolve();
                    clearInterval(interval);
                }
            }, 100);
        });
    };

    activateCallRails = async () => {
        const { pharmacy, getPharmacy } = this.props;

        this.setState({ isUpdating: true });
        try {
            await this.autoAddCallRailID();
        } catch (e) {
            displayToast({ text: 'CallRail Call Tracking Activation Failed.', type: 'error' });
        }
        // Refresh the pharmacy to have the latest values, irrespective of success or failure
        await getPharmacy(pharmacy.id);
        this.setState({ isUpdating: false });
    };

    deactivateCallRails = async () => {
        this.setState({ isUpdating: true });
        const { pharmacy, getPharmacy, deleteCallRailsAttribute } = this.props;
        const selectedPharmacyLocation = this.getSelectedPharmacyLocation();
        const callRails = _.get(selectedPharmacyLocation, 'attr.callRailId');

        // Deleting the Call Rail ID from C2 := deactivating it from NPE
        await deleteCallRailsAttribute(pharmacy.id, selectedPharmacyLocation.id, callRails.id);
        await getPharmacy(pharmacy.id);
        this.setState({ isUpdating: false });
    };

    onCallRailSelect = async (company) => {
        this.closeModalDialog();
        await this.addCallRailID(company.id);
        this.resetLocalCallRailData();
    };

    closeModalDialog = (e = {}) => {
        if (!_.isEmpty(e)) {
            // Could be an escape button or a hit on close button.
            this.resetLocalCallRailData();
        }
        this.setState({ openSelectionDialog: false });
    };

    render() {
        const { isUpdating, openSelectionDialog } = this.state;
        const selectedLocation = this.getSelectedPharmacyLocation();
        const callRailId = _.get(selectedLocation, 'attr.callRailId.value');
        const isActive = !_.isEmpty(callRailId);

        return (
            <Fragment>
                <CallRailsSelectionDialog
                    open={openSelectionDialog}
                    onClose={this.closeModalDialog}
                    companies={this.callRails || []}
                    onSelect={this.onCallRailSelect}
                />
                <Service
                    title={this.title}
                    description={this.description}
                    isActive={isActive}
                    isUpdating={isUpdating}
                    onActivate={this.activateCallRails}
                    onDeactivate={this.deactivateCallRails}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    pharmacy: state.pharmacy.pharmacy,
    selectedLocation: state.auth.selectedLocation,
});

const mapDispatchToProps = (dispatch) => ({
    displayToast: (data) => dispatch(displayToast(data)),
    getPharmacy: (custID) => dispatch(pharmacyAction.getPharmacy(custID)),
    getCallRailsForLocation: (locationPhoneNumber) => dispatch(getCallRailsForLocation(locationPhoneNumber)),

    deleteCallRailsAttribute: (custID, locationID, callRailsAttrID) =>
        dispatch(deleteCallRailsAttribute(custID, locationID, callRailsAttrID)),

    addCallRailID: (custID, locationID, payload) => dispatch(addCallRailID(custID, locationID, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallRailsTracking);
