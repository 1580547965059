import React, { Component, Fragment } from 'react';
import { FontIcon, Button, Slider, Cell, CircularProgress } from 'react-md';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false,
            loadingAudio: false,
            sliderValue: 0,
        };
    }

    componentDidMount() {
        this.audio.addEventListener('timeupdate', this.onAudioTimeUpdate);
        this.audio.addEventListener('ended', this.onAudioEnded);
    }

    componentWillUnmount() {
        this.audio.removeEventListener('timeupdate', this.onAudioTimeUpdate);
        this.audio.removeEventListener('ended', this.onAudioEnded);
    }

    onSliderChange = (value) => {
        this.audio.currentTime = Math.round((value * this.audio.duration) / 100);
    };

    play = () => {
        const { play } = this.state;
        const { fileSource, loadAudioFile, audioStarted } = this.props;
        if (play) {
            this.setState({ play: false });
            this.audio.pause();
        } else {
            if (fileSource) {
                this.setState({ play: true });
                this.audio.play();
                if (audioStarted) {
                    audioStarted();
                }
            } else {
                this.setState({ loadingAudio: true });
                loadAudioFile();
            }
        }
    };

    pause = () => {
        this.audio.pause();
        this.setState({ play: false });
    };

    onAudioTimeUpdate = () => {
        if (this.audio.duration) {
            const sliderValue = (this.audio.currentTime / this.audio.duration) * 100;
            this.setState({ sliderValue: Math.round(sliderValue) });
        }
    };

    onAudioEnded = () => {
        this.setState({ sliderValue: 0, play: false });
    };

    getNonProgressBtns = () => {
        const { labelText } = this.props;
        const { play } = this.state;
        return labelText ? (
            <Button
                flat
                secondary
                className="preview-lang bordered-button-grey"
                onClick={this.play}
                iconChildren={play ? 'pause' : 'play_circle_outlined'}
            >
                {labelText.toUpperCase()}
            </Button>
        ) : (
            <Button icon onClick={this.play}>
                {play ? 'pause' : 'play_circle_outlined'}
            </Button>
        );
    };

    getProgressBtns = () => {
        const { fileName, children, fileSource } = this.props;
        const { play, sliderValue } = this.state;

        return (
            <div className="grid-container">
                <Cell size={12} className="no-margin">
                    <div className="grid-container" style={{ width: '100%' }}>
                        <div className="grid-cell-12 flex-middle">
                            <span className="slider-label">{fileName}</span>
                            {children}
                        </div>
                        <div className="grid-cell-12">
                            <Slider
                                id=""
                                className="md-cell no-margin flex-middle user-clickable"
                                leftIcon={
                                    <FontIcon onClick={() => this.play()}>{play ? 'pause' : 'play_arrow'}</FontIcon>
                                }
                                disabled={!fileSource}
                                value={sliderValue}
                                onChange={this.onSliderChange}
                            />
                        </div>
                    </div>
                </Cell>
            </div>
        );
    };

    render() {
        const { fileSource, progress } = this.props;
        const { loadingAudio } = this.state;
        return (
            <Fragment>
                <audio
                    id=""
                    ref={(audio) => {
                        this.audio = audio;
                    }}
                    src={fileSource}
                    preload="metadata"
                />
                {loadingAudio ? (
                    <CircularProgress id="audio-file-loading" />
                ) : !progress ? (
                    this.getNonProgressBtns()
                ) : (
                    this.getProgressBtns()
                )}
            </Fragment>
        );
    }

    componentDidUpdate(prevProps) {
        const { loadingAudio, play } = this.state;
        const { pauseAudio, stopAudio } = this.props;
        if (!prevProps.fileSource && this.props.fileSource && loadingAudio) {
            this.setState({ loadingAudio: false });
            this.play();
        }

        if (pauseAudio && play) {
            this.pause();
        } else if (stopAudio && play) {
            this.pause();
            this.audio.currentTime = 0;
        }
    }
}

export default AudioPlayer;
