import React, { useContext } from 'react';
import { Cell, Grid, Card, CardText } from 'react-md'; // TODO need to unify around material-ui
import { List } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardHeader } from '../../Common/index';
import PackageAttributeToggle from '../PackageAttributeToggle';
import { AbilityContext, userActions, restrictedResources } from 'casl';
import goBackService from 'utils/goBackService';

const NotificationSettingsPropTypes = {
    pharmacy: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

function NotificationSettings(props) {
    const ability = useContext(AbilityContext);
    const {
        notifications: { subject: notifications },
    } = restrictedResources.generalSettings;
    const canUpdate = ability.can(userActions.update, notifications);

    return (
        <Grid style={{ position: 'relative' }}>
            <Cell size={12} phoneSize={4}>
                <Card className="outer-card">
                    <CardHeader title="Notifications" onBack={() => goBackService.toGeneralHome(props.history)} />
                    <Card className="inner-card">
                        <CardText
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <List>
                                <Cell
                                    size={12}
                                    phoneSize={4}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <List>
                                        <PackageAttributeToggle
                                            pharmacy={props.pharmacy}
                                            packageCode="FILL"
                                            attributeName="allowBrowserNotifications"
                                            title="Refill Desktop Alerts"
                                            description="Show failed refills alerts"
                                            disabled={!canUpdate}
                                            isLocationLevelAttr={true}
                                        />
                                        <PackageAttributeToggle
                                            pharmacy={props.pharmacy}
                                            packageCode="STNG"
                                            attributeName="allowBrowserNotificationsForLeads"
                                            title="Leads Desktop Alerts"
                                            description="Show new patient leads alerts"
                                            disabled={!canUpdate}
                                            isLocationLevelAttr={true}
                                        />
                                    </List>
                                </Cell>
                            </List>
                        </CardText>
                    </Card>
                </Card>
            </Cell>
        </Grid>
    );
}

NotificationSettings.propTypes = NotificationSettingsPropTypes;

export default NotificationSettings;
