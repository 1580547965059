import React, { Component } from 'react';
import { Card, CardText } from 'react-md';
import _ from 'lodash';

import TabularData from 'components/Common/TabularData';
import EmptyCard from './EmptyCard';
import { mapTabularData } from './util.js';
import ReportTitle from 'components/Report/Common/ReportTitle';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';

const DataCard = ({ data, showLoader, tooltipText }) => (
    <Card className="reportCard">
        <ReportTitle title="Mobile Downloads" className="cardTitle" tooltipText={tooltipText} />
        <CardText className={showLoader ? 'padding0 flex-middle flex-grow-1' : 'flex-grow-1'}>
            {!showLoader ? <TabularData data={data} /> : <TabularTypePlaceholder legendsCount={3} />}
        </CardText>
    </Card>
);

export default class MobileDownloads extends Component {
    state = {
        mapData: {},
    };

    init() {
        const { data } = this.props;
        // isLoading is one of the keys now, the entire logic of validation
        // and data access for Reports needs to be revisited and simplified
        if (_.keys(data).length > 1) {
            const mapData = mapTabularData(data);
            this.setState({ mapData });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ data: oldData }) {
        const { data } = this.props;
        // TODO: This logic needs to be corrected. Also, the component can be refactored to rely on props and selectors
        if (_.keys(data).length > 1 && !_.isEqual(data, oldData)) {
            this.init();
        }
    }

    render() {
        const { data, enabled, tooltipText } = this.props;
        const { mapData } = this.state;
        const showLoader = Object.keys(data).length === 0 || Object.keys(mapData).length === 0;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Mobile Downloads"
                    imgPath="/reports/mobile_downloads.png"
                    helperText="You don’t have a mobile app yet."
                    appType="mobileDownloads"
                    tooltipText={tooltipText}
                />
            );
        }
        return <DataCard data={mapData} showLoader={showLoader} tooltipText={tooltipText} />;
    }
}
