import _ from 'lodash';
import moment from 'moment';

export function selectPastAndFutureReservationsForInboxUserId(state) {
    const appointments = _.filter(
        _.get(state.apptReservation, 'reservationsForInboxUserId.response.reservations'),
        ({ status }) => status === 'ACTIVE'
    );

    const utcNow = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    const grouped = _.groupBy(
        _.sortBy(appointments, ({ start_date }) => start_date),
        ({ start_date }) => (utcNow > start_date ? 'past' : 'future')
    );

    grouped['past'] = _.reverse(grouped['past']);

    return grouped;
}
