export const sendNotification = (title, body, tag = 'default') => {
    // Only send notification if:
    // 1) their browser supports notifications
    // 2) they have allowed notifications for the website
    if ('Notification' in window && Notification.permission === 'granted') {
        const notification = new Notification(title, {
            requireInteraction: true, // Only seems to work on chrome
            body: body || '',
            tag: tag,
        });

        // TODO execute custom handler to do specific task like navigate to the page etc
        notification.onclick = function (event) {
            if (window.postMessage) {
                window.postMessage('notification-clicked', window.location.origin);
            }
            window.focus();
            this.close();
        };
    }
};
