export const webServiceURL = 'https://mobile-api.rxwiki.com';

export const appTypeBranded = 'branded';

export const brandedAppTypeId = 1;

export const pharmacyNamePlaceholder = 'CURRENT_PHARMACY_NAME_PLACEHOLDER';

const longDescription = `
The ${pharmacyNamePlaceholder} app allows you and your family to securely communicate with your local pharmacy.  Refill your prescriptions with one click, get refill reminders, get discounts at your local pharmacy, get savings on brand drugs and access Pharmacist written pages that help you understand your condition or medications.

Our App Features:

* HIPAA compliant
* Easily refill your prescriptions at your local independent pharmacy
* Access a history of your medications and Rx numbers
* Set reminders when to refill your medications
* Set reminders when to take your medications
* Save up to $450 on top brand medications with co-pay coupons
* 20,000 pharmacist written pages on conditions and medications
* 7,500 pharmacist videos about medications and side effects
`;

export const newAppFormFields = {
    textFields: [
        {
            label: 'App Name',
            value: pharmacyNamePlaceholder,
            autoFocus: true,
            config2Key: 'name',
            required: true,
        },
        {
            label: 'Display Name',
            value: pharmacyNamePlaceholder,
            config2Key: 'displayName',
            required: true,
        },
        {
            label: 'Short Description',
            value: 'Refill your medications, set reminders, and connect with your pharmacy',
            rows: 2,
            config2Key: 'shortDescription',
            required: true,
        },
        {
            label: 'Long Description',
            value: longDescription,
            rows: 5,
            config2Key: 'longDescription',
            required: true,
        },
        {
            label: 'iTunes Connect Email',
            value: '',
            config2Key: 'itunesConnectEmail',
        },
        {
            label: 'iTunes Connect Password',
            value: '',
            isPassword: true,
            showPassword: false,
            config2Key: 'itunesConnectPassword',
        },
        {
            label: 'iTunes Connect Team ID',
            value: '',
            config2Key: 'teamId',
            requiredLength: 10,
        },
        {
            label: 'iTunes Connect Team Name',
            value: '',
            config2Key: 'teamName',
        },
    ],
    checkboxes: [
        {
            label: 'Apple Paid In Chargify',
            value: false,
            config2Key: 'charged',
        },
        {
            label: 'App Is Searchable',
            value: false,
            config2Key: 'isSearchable',
        },
    ],
};

export const appPublishingSteps = {
    notReady: 'Not Ready',
    queuedForAutomator: 'Queued for Automator',
    pickedByAutomator: 'Picked by Automator',
    buildFailed: 'Automator failed to process',
    uploadedToStore: 'Uploaded To GDC & waiting for review',
    approvedOnStore: 'Version Approved by GDC/ iTC',
    rejectedOnStore: 'Version Rejected by GDC/ iTC',
    approvedByCs: 'Version Approved by Build Team',
    rejectedByCs: 'Version Rejected by Build Team',
};

export const buildStatusTypes = {
    building: 'building',
    updating: 'updating',
    complete: 'complete',
    buildFailed: 'build_failed',
    updateFailed: 'update_failed',
    actionNeeded: 'action_needed',
    buildSuccess: 'build_success',
    updateSuccess: 'update_success',
    readyForBuild: 'ready_for_build',
    readyForUpdate: 'ready_for_update',
    buildApproved: 'build_approved',
    updateApproved: 'update_approved',
    buildRejected: 'build_rejected',
    updateRejected: 'update_rejected',
};
