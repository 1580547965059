import { restrictedInboxResources } from 'casl/RestrictedResources/Inbox';

export const LocationOnboardingKeyMap = {
    FIRST_LOGIN: 'first_login',
    FIRST_TEMPLATE: 'first_template',
    ADD_YOUR_PATIENTS: 'add_your_patients',
    SEND_YOUR_FIRST_MESSAGE: 'send_your_first_message',
    // not visualized in onboarding steps, but utilized for modal flows
    SEND_YOUR_FIRST_MESSAGE_TEMPLATE_SELECTION: 'send_your_first_message_template_selection',
    DESKTOP_NOTIFICATIONS: 'desktop_notifications',
    SEND_MESSAGES: 'send_messages',
};

export const LocationOnboardingStepList = [
    {
        key: LocationOnboardingKeyMap.FIRST_LOGIN,
        type: 'action',
        display: 'Log in to your Pharmacy Experience Dashboard',
        statePath: 'locationClientSteps.hasLoggedIn',
    },
    {
        key: LocationOnboardingKeyMap.FIRST_TEMPLATE,
        type: 'action',
        display: 'Create your first template',
        action: { display: 'Add a template' },
        statePath: 'locationOnboarding.first_template_created_at',
        requiresEntityAccess: restrictedInboxResources.inbox.messageTemplates.subject,
    },
    {
        key: LocationOnboardingKeyMap.ADD_YOUR_PATIENTS,
        type: 'action',
        display: 'Add your patients',
        action: { display: 'Upload a CSV' },
        statePath: 'locationOnboarding.patient_count',
        count: {
            completed: 0,
            total: 20,
        },
        requiresEntityAccess: restrictedInboxResources.inbox.patient.subject,
    },
    {
        key: LocationOnboardingKeyMap.SEND_YOUR_FIRST_MESSAGE,
        type: 'action',
        display: 'Send your first message',
        action: { display: 'See how messaging works' },
        statePath: 'locationOnboarding.message_count',
        requiresEntityAccess: restrictedInboxResources.inbox.messages.subject,
    },
    {
        key: LocationOnboardingKeyMap.DESKTOP_NOTIFICATIONS,
        type: 'action',
        display: 'Turn on desktop notifications',
        action: { display: 'Turn on Notifications' },
        statePath: 'locationClientSteps.notificationPermission',
    },
    {
        key: LocationOnboardingKeyMap.SEND_MESSAGES,
        type: 'count',
        display: 'Send 20 Messages',
        statePath: 'locationOnboarding.message_count',
        count: {
            completed: 0,
            total: 20,
        },
    },
];
