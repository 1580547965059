export const userRoles = {
    staff: 'Staff',
    owner: 'Owner',
    manager: 'Manager',
    noAccess: 'No Access',
    readOnly: 'Read Only',
    itSupport: 'IT Support',

    // These roles are temporary for backward compatibility and will be removed here
    // https://rxwiki.atlassian.net/browse/PHAR-1459
    none: 'None',
    editor: 'Editor',
    reader: 'Reader',
};

export const primaryRoles = [userRoles.owner, userRoles.manager, userRoles.staff, userRoles.noAccess];
