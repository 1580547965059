import './EditTemplateDialog.sass';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tooltip } from '@material-ui/core';
import { templateAction } from '../../../redux/Inbox/Template';
import { connect } from 'react-redux';
import { getNextImageRef, getTemplateImageUrl } from '../patientsUtil';
import config from '../../../config';
import PhonePreview from '../scheduling/PhonePreview';
import { preprocessMessageTemplateText } from '../messageTemplateUtil';
import MessageCustomizeAccordion from 'components/Appointments/MessageCustomizeAccordion';
import { isSimpleSchedulingMessage } from '../ReviewBulkMessagePane';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';
import EventIcon from '@material-ui/icons/Event';
import { updateCalendar } from 'redux/Appt/Calendar/action';

const FIELD_KEY_LABELS = {
    waitlist_confirmation_text: 'Waitlist Confirmation Message',
    message: 'Message',
    scheduled_appointment_text: 'Scheduled Appointment Response Message',
    day_reminder_text: '24 Hour Before Reminder',
    hour_reminder_text: '1 Hour Before Check-in + Reminder',
    checkin_response_text: 'Check-in Response Message',
};

function EditTemplateDialog({
    open,
    onClose,
    editingTemplate,
    updateTemplate,
    createTemplate,
    isCreateWorkflow,
    inboxConversationTemplate,
    auth,
    pharmacy,
    apptTypesWithSessions,
    calendars,
    updateCalendar,
}) {
    // shortcircuit so i don't have to null check all the time
    if (!open) {
        return null;
    }

    const isSimpleSchedulingTemplate = !_.isNil(editingTemplate) && isSimpleSchedulingMessage(editingTemplate);
    const templateApptType = _.find(
        apptTypesWithSessions,
        ({ apptType }) =>
            _.get(editingTemplate, 'message_content.messaging_form.appt_type_id') === apptType.appt_type_id
    );
    const calendar =
        templateApptType && !_.isEmpty(calendars)
            ? _.find(
                  calendars,
                  ({ calendar_id }) => calendar_id === _.get(templateApptType, 'sessions.[0].calendar_id')
              )
            : null;

    const getDefaultEdited = () => {
        if (isCreateWorkflow) {
            /* eslint-disable */
            return { subject: '', message: 'Hi ${first_name},\n' };
            /* eslint-enable */
        }

        if (!isSimpleSchedulingTemplate) {
            return _.pick(editingTemplate, ['subject', 'message']);
        }

        return {
            subject: editingTemplate.subject,
            message: _.get(editingTemplate, 'message_content.text'),
            scheduled_appointment_text: _.get(
                editingTemplate,
                'message_content.messaging_form.scheduled_appointment_text'
            ),
            day_reminder_text: _.get(calendar, 'day_reminder_text'),
            hour_reminder_text: _.get(calendar, 'hour_reminder_text'),
            checkin_response_text: _.get(calendar, 'checkin_response_text'),
        };
    };

    const [edited, setEdited] = useState(getDefaultEdited());
    const [error, setError] = useState({});
    const [saveInitiated, setSaveInitiated] = useState(false);
    const [focusedMessageKey, setFocusedMessageKey] = useState('message');

    const calendarFields = new Set(['day_reminder_text', 'hour_reminder_text', 'checkin_response_text']);

    useEffect(() => {
        if (saveInitiated && !inboxConversationTemplate.saving && !inboxConversationTemplate.loading) {
            onClose();
        }
    }, [saveInitiated, inboxConversationTemplate.saving, inboxConversationTemplate.loading]);

    const onChange = (field, e) => {
        const value = e.target.value.trim();
        setEdited({ ...edited, [field]: value });
        if (value.length === 0) {
            setError({ ...error, [field]: `${_.capitalize(field)} must be non-empty` });
        } else if (_.has(error, field)) {
            setError(_.omit(error, [field]));
        }
    };

    const isSavable = _.isEmpty(error) && !_.isEqual(edited, getDefaultEdited());
    const isSaving = inboxConversationTemplate.saving || inboxConversationTemplate.loading;

    const title = isCreateWorkflow ? (
        'Create new Workflow template'
    ) : (
        <span>Edit {!_.isNil(editingTemplate.title) ? editingTemplate.title : editingTemplate.subject}</span>
    );
    const image_ref = isCreateWorkflow
        ? getNextImageRef(_.get(inboxConversationTemplate, 'templates'))
        : editingTemplate.image_ref;

    let fieldKeys = ['message'];
    if (isSimpleSchedulingTemplate) {
        fieldKeys = [
            'message',
            'scheduled_appointment_text',
            'day_reminder_text',
            'hour_reminder_text',
            'checkin_response_text',
        ];
    }

    return (
        <Dialog className="edit-template-dialog" open={open} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle>
                <div
                    className="image-box"
                    style={{
                        backgroundImage: `url(${getTemplateImageUrl({ image_ref })})`,
                        backgroundColor: '#eee',
                        backgroundSize: 'cover',
                        width: '120px',
                        height: '80px',
                    }}
                />

                <div className="text">
                    <div className="title-text">{title}</div>

                    <div className="clinical-note">
                        <strong>Note:</strong> Messages should be clinical in nature. Sending marketing messages through
                        this platform could violate TCPA and FCC regulations. Do not request credit card information.
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="inputs-and-preview">
                    <div className="inputs">
                        <TextField
                            type="text"
                            id="subject"
                            name="subject"
                            label="Subject"
                            defaultValue={edited.subject}
                            autoFocus
                            required
                            inputProps={{
                                maxLength: 512,
                            }}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            error={!_.isNil(error.subject)}
                            helperText={error.subject}
                            onChange={(e) => onChange('subject', e)}
                        />
                        {!isSimpleSchedulingTemplate && (
                            <TextField
                                type="text"
                                id="message"
                                name="message"
                                label="Message"
                                defaultValue={edited.message}
                                required
                                multiline
                                rows={4}
                                rowsMax={10}
                                inputProps={{}}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={!_.isNil(error.message)}
                                helperText={error.message}
                                onChange={(e) => onChange('message', e)}
                            />
                        )}

                        {isSimpleSchedulingTemplate && (
                            <>
                                {_.map(fieldKeys, (fieldKey) => {
                                    return (
                                        <MessageCustomizeAccordion
                                            //title, value, expanded, onExpandChange, onChange
                                            key={fieldKey}
                                            title={
                                                !calendarFields.has(fieldKey) ? (
                                                    FIELD_KEY_LABELS[fieldKey]
                                                ) : (
                                                    <AccordionTitle
                                                        calendar={calendar}
                                                        label={FIELD_KEY_LABELS[fieldKey]}
                                                    />
                                                )
                                            }
                                            value={_.get(edited, fieldKey)}
                                            expanded={focusedMessageKey === fieldKey}
                                            onExpandChange={() => setFocusedMessageKey(fieldKey)}
                                            onChange={(value) => onChange(fieldKey, { target: { value } })}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div className="message-preview">
                        <div className="phone-heading">The conversation patients will see on their mobile device</div>
                        <PhonePreview
                            width={300}
                            height={530}
                            template={_.isNil(editingTemplate) ? { subject: edited.subject } : editingTemplate}
                            getMessageText={(key) =>
                                preprocessMessageTemplateText(
                                    _.get(edited, key),
                                    auth,
                                    null //additionalReplacementVariables
                                )
                            }
                            fieldKeys={fieldKeys}
                            focusedMessage={focusedMessageKey}
                            auth={auth}
                            pharmacy={pharmacy}
                            slots={null}
                            isScrollable
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!isSavable || saveInitiated}
                    onClick={() => {
                        if (isCreateWorkflow) {
                            createTemplate({
                                ...edited,
                                pharmacy_id: config.X_PharmacyID,
                                location_id: config.X_LocationID,
                                workflow_status: 'active',
                                image_ref,
                            });
                        } else {
                            const prior = getDefaultEdited();
                            const calendarChanged = !_.isEqual(
                                _.pick(prior, Array.from(calendarFields)),
                                _.pick(edited, Array.from(calendarFields))
                            );
                            const templateChanged = !_.isEqual(
                                _.omit(prior, Array.from(calendarFields)),
                                _.omit(edited, Array.from(calendarFields))
                            );

                            const promises = [];

                            if (calendarChanged) {
                                promises.push(
                                    updateCalendar(
                                        calendar.calendar_id,
                                        {
                                            ...calendar,
                                            ..._.pick(edited, Array.from(calendarFields)),
                                        },
                                        calendar
                                    )
                                );
                            }
                            if (templateChanged) {
                                const templateFields = _.omit(edited, Array.from(calendarFields));
                                const keyMappings = {
                                    scheduled_appointment_text:
                                        'message_content.messaging_form.scheduled_appointment_text',
                                };

                                const newTemplate = {
                                    ...editingTemplate,
                                    pharmacy_id: config.X_PharmacyID,
                                    location_id: config.X_LocationID,
                                };
                                _.forIn(templateFields, (value, key) => {
                                    _.set(newTemplate, _.get(keyMappings, key, key), value);
                                });
                                promises.push(updateTemplate(newTemplate));
                            }
                        }
                        // Do this in a short timeout so that there is time for the redux stores to get into their loading/saving state
                        setTimeout(() => setSaveInitiated(true), 10);
                    }}
                >
                    {isSaving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function AccordionTitle({ calendar, label }) {
    return (
        <span className="accordion-title">
            {_.isNil(calendar) ? null : (
                <Tooltip
                    title={`Changes to this will affect all appointments on this calendar: ${_.get(calendar, 'name')}`}
                >
                    <EventIcon style={{ color: calendar.color }} />
                </Tooltip>
            )}
            {label}
        </span>
    );
}

const bindActionsToDispatch = {
    updateTemplate: templateAction.updateTemplate,
    createTemplate: templateAction.createTemplate,
    updateCalendar,
};
function mapStateToProps(state) {
    const { inboxConversationTemplate, auth, pharmacy } = state;
    return {
        inboxConversationTemplate,
        auth,
        pharmacy,
        calendars: _.filter(
            _.get(state, 'apptCalendar.availableCalendars.calendars'),
            ({ status }) => status === 'ACTIVE'
        ),
        apptTypesWithSessions: selectApptTypesWithSessions(state),
    };
}

export default connect(mapStateToProps, bindActionsToDispatch)(EditTemplateDialog);
