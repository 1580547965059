import React, { Component, Fragment, useState } from 'react';
import { Card, CardText, CircularProgress } from 'react-md';

import LearnMoreButton from 'components/Common/LearnMoreButton';
import ReportTitle from 'components/Report/Common/ReportTitle';

export const EmptyCardContent = ({ npeInterestLoading, imgPath, helperText, appType, variant }) => {
    const [loadingImage, setLoadingImage] = useState(true);

    return npeInterestLoading ? (
        <CircularProgress id="interest-spinner" />
    ) : (
        <Fragment>
            {loadingImage && <CircularProgress id="image-spinner" />}
            <img src={imgPath} alt="Digital platform" onLoad={setLoadingImage.bind(this, false)} />
            {helperText && <p className="helperText"> {helperText} </p>}
            <LearnMoreButton appType={appType} variant={variant || 'reports'} />
        </Fragment>
    );
};

export default class EmptyCard extends Component {
    render() {
        const { title, tooltipText } = this.props;

        return (
            <Card className="EmptyCard">
                <ReportTitle title={title} className="cardTitle" tooltipText={tooltipText} />
                <CardText className="cardTextContainer text-align-center">
                    <EmptyCardContent {...this.props} />
                </CardText>
            </Card>
        );
    }
}
