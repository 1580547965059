import Config from 'config';
import { get } from 'redux/actionCreators/rest';
import { displayToast } from 'redux/actionCreators/Snackbar';

import { deleteLocationAttribute, addLocationAttribute } from './Config2Attributes.js';

export const getCallRailsForLocation = (selectedLocationPhoneNumber) => (dispatch) => {
    const url = `${Config.apiweb_url}/third_party/call_rails/companies`;
    const args = {
        params: {
            search: selectedLocationPhoneNumber,
            status: 'active',
        },
    };

    dispatch(
        displayToast({
            text: 'Fetching Call Rails ID For Location...',
            type: 'info',
        })
    );

    return get(url, args)
        .then(({ companies, total_records }) => {
            if (total_records === 0) {
                dispatch(
                    displayToast({
                        text: 'No Call Rail Record Found For Location!',
                        type: 'error',
                    })
                );
            } else {
                return companies;
            }
        })
        .catch(() => {
            dispatch(
                displayToast({
                    text: 'Failed To Fetch Call Rail ID!',
                    type: 'error',
                })
            );
            Promise.reject();
        });
};

export const addCallRailID = (custID, locationID, data) => (dispatch) => {
    const toast = {
        initMessage: 'Linking Call Rail ID To Leads Page...',
        successMessage: 'Call Rail ID Linked To Leads Page.',
        errorMessage: 'Failed To Link Call Rail ID To Leads Page!',
    };
    const cust = {
        id: custID,
        location: {
            id: locationID,
        },
    };

    return addLocationAttribute(cust, data, toast, dispatch);
};

export const deleteCallRailsAttribute = (custID, locationID, callRailsAttrID) => (dispatch) => {
    const toast = {
        initMessage: 'De-linking Call Rails ID From Leads Page...',
        successMessage: 'De-linked Call Rails ID From Leads Page.',
        errorMessage: 'Failed To De-link Call Rails ID From Leads Page!',
    };
    const cust = {
        id: custID,
        location: {
            id: locationID,
            attributeID: callRailsAttrID,
        },
    };
    return deleteLocationAttribute(cust, toast, dispatch);
};
