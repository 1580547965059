import ActionTypes from './type';
import pharmacyContractService from './service';

const fetchContractInformation = (locationId, salesforceId) => (dispatch) => {
    dispatch({ type: ActionTypes.FETCH_INFORMATION });

    return pharmacyContractService
        .fetchContractInformation(salesforceId)
        .then((response) => {
            dispatch({
                type: ActionTypes.FETCH_INFORMATION_SUCCESS,
                payload: {
                    location_id: locationId,
                    ...response,
                },
            });
            return { response };
        })
        .catch((error) => {
            dispatch({
                type: ActionTypes.FETCH_INFORMATION_FAILED,
                payload: {
                    location_id: locationId,
                    error,
                },
            });
            return { error };
        });
};

const skipSigningContract = (locationId) => (dispatch) => {
    dispatch({
        type: ActionTypes.SKIP_SIGNING_CONTRACT,
        payload: {
            location_id: locationId,
        },
    });
};

export const pharmacyContractActions = {
    fetchContractInformation,
    skipSigningContract,
};

export default pharmacyContractActions;
