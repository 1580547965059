import React, { useState } from 'react';
import moment from 'moment';
import {
    Grid,
    MenuItem,
    FormControl,
    Select,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AudioPlayer from 'components/Common/AudioPlayer';
import { LEADS_STATUS_OPTIONS } from 'constants/Leads';
import { getDateFormatted } from 'utils/helper';
import { leadHelper } from 'utils/leadHelper';

const LeadDetailsAccordion = ({ title = 'Lead Details', children }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="lead-profile">
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export const LeadDetails = ({ leadInfo, track, setCurrentTrack, fetchLeadAudio, setStatus, canUpdate }) => {
    const formatTime = (s) => {
        return moment('2015-01-01').startOf('day').seconds(s).format('mm:ss');
    };

    return (
        <Grid container direction="column" spacing={2} className="leads-details-container">
            <Grid item container>
                <Grid item xs={4} className="title">
                    Patient Name
                </Grid>
                <Grid item xs={8} className="text-align-right">
                    {(leadInfo.customer_name && leadInfo.customer_name.toLowerCase()) || '-'}
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={4} className="title">
                    Status
                </Grid>
                <Grid item xs={8} className="text-align-right">
                    {leadInfo.isSaving ? (
                        <CircularProgress size="20px" />
                    ) : (
                        <FormControl>
                            <Select
                                value={leadInfo.status || ''}
                                onChange={(e) => setStatus(e.target.value, leadInfo)}
                                disableUnderline
                                displayEmpty
                                disabled={!canUpdate}
                            >
                                <MenuItem value="" disabled>
                                    Unassigned
                                </MenuItem>
                                {Object.keys(LEADS_STATUS_OPTIONS).map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {LEADS_STATUS_OPTIONS[status]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={4} className="title">
                    Channel
                </Grid>
                <Grid item xs={8} className="text-align-right">
                    {leadHelper.getLeadSourceName(leadInfo.source_name)}
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={4} className="title">
                    Date Added
                </Grid>
                <Grid item xs={8} className="text-align-right">
                    {(leadInfo.start_time && getDateFormatted(leadInfo.start_time)) || '-'}
                </Grid>
            </Grid>
            {leadInfo.recording && (
                <Grid item container>
                    <Grid item xs={12} style={{ marginBottom: '10px' }} className="title">
                        Call Recording
                    </Grid>
                    <Grid item xs={11}>
                        <AudioPlayer
                            progress
                            fileSource={leadInfo.audioUrl}
                            stopAudio={track.previous === leadInfo.id}
                            audioStarted={setCurrentTrack.bind(this, leadInfo.id)}
                            loadAudioFile={fetchLeadAudio.bind(this, {
                                leadId: leadInfo.id,
                                url: leadInfo.recording,
                            })}
                        />
                    </Grid>
                    {leadInfo.recording_duration && (
                        <Grid item xs={1}>
                            {formatTime(leadInfo.recording_duration)}
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default LeadDetailsAccordion;
