import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'IVR - Additional Languages';
const description =
    'When this setting is active, Internal Users can select additional languages to be made available for the pharmacy to offer on their IVR';

const AdditonalLang = (props) => {
    return <Service title={title} description={description} {...props} />;
};

export default WithCustPackageAttribute(AdditonalLang, {
    package: {
        name: 'IVR',
        code: 'ICFG',
        attribute: {
            name: 'IVRaddLang',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
