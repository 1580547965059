import _ from 'lodash';
import { bannerConfigTypes } from './banner-config-type';
import { bannerConfigService } from './banner-config-service';
import SnackbarActions from '../actions/Snackbar';
import {
    ensureCorrectTypeForAttributeValue,
    getPharmacyPackage,
    getPharmacyPackageAttributeByName,
} from 'utils/helper';
import { pharmacyAction } from 'redux/Pharmacy/action';

const saveBannerConfig = (config) => async (dispatch, getState) => {
    dispatch({ type: bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_REQUEST });

    const pharmacy = getState().pharmacy.pharmacy;
    const websitePackage = getPharmacyPackage('WEBS', pharmacy);

    const customer = {
        id: pharmacy.id,
        packageID: websitePackage.id,
    };

    const multipleCalls = [];

    for (const key of _.keys(config)) {
        const attr = getPharmacyPackageAttributeByName(websitePackage.properties, key);
        let payload = _.cloneDeep(attr);

        // Helper method to detect correct typing for API
        payload.value = ensureCorrectTypeForAttributeValue(payload, config[key]);

        if (payload.id) {
            multipleCalls.push(bannerConfigService.updateCustomerPackageAttribute(customer, payload));
        } else {
            multipleCalls.push(bannerConfigService.createCustomerPackageAttribute(customer, payload));
        }
    }

    if (multipleCalls.length) {
        Promise.all(multipleCalls)
            .then(() => {
                dispatch({ type: bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_SUCCESS });
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: 'Banner configuration settings saved.',
                        type: 'success',
                    },
                });
                dispatch(pharmacyAction.getPharmacy());
            })
            .catch((err) => {
                const errorText = _.get(err, 'response.data.detail', 'Failed to save banner configuration settings!');
                dispatch(pharmacyAction.getPharmacy());
                dispatch({ type: bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_FAILED });
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: errorText,
                        type: 'error',
                    },
                });
            });
    }
};

export const bannerConfigActions = {
    saveBannerConfig,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
