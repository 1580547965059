import React, { PureComponent, Fragment } from 'react';
import { Card, CardText, Grid, Cell } from 'react-md';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import LinearProgress from 'components/Common/LinearProgress';
import CustomTab from 'components/Common/CustomTab';
import EmptyCard from './EmptyCard';
import ReportTitle from 'components/Report/Common/ReportTitle';
import GraphTypePlaceholder from 'components/Common/GraphTypePlaceholder';

const barOptions = {
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                gridLines: {
                    display: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    legend: {
        display: false,
    },
};

const cssAttributesForChart = {
    backgroundColor: '#eff7ff',
    borderColor: '#3793ff',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: 'rgba(255,99,132,1)',
};

const DataCard = ({ chartData, linearData, tab, showLoader, tooltipText }) => {
    const isMobile = !useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Card className="reportCard">
            <ReportTitle title="Digital Platform Refills" className="cardTitle" tooltipText={tooltipText}>
                {!showLoader && <div className="order1">{tab()}</div>}
            </ReportTitle>
            <CardText className={showLoader ? 'padding0 flex-grow-1 flex-middle' : 'flex-grow-1'}>
                {!showLoader ? (
                    <Grid className="height100 flex-middle">
                        <div className={!isMobile ? 'full-width flex' : 'full-width'}>
                            <Cell size={7}>
                                <Bar data={chartData} width={360} height={250} options={barOptions} />
                            </Cell>
                            <Cell size={5}>
                                <p className="text-align-center"> {linearData.title} </p>
                                {linearData.data.map((d) => (
                                    <LinearProgress
                                        key={d.title}
                                        title={d.title}
                                        lineTitle={`${d.value} of ${linearData.total}`}
                                        width={(d.value / linearData.total) * 100}
                                    />
                                ))}
                            </Cell>
                        </div>
                    </Grid>
                ) : (
                    <GraphTypePlaceholder legendsCount={2} />
                )}
            </CardText>
        </Card>
    );
};

export default class DigitalPlatform extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0,
        };
        this.tabs = {
            Total: 0,
            Web: 0,
            Mobile: 0,
        };
        this.getActiveTab = _.memoize((activeTabIndex) => Object.keys(this.tabs)[activeTabIndex]);
        this.onTabClick = (tabIndex) => this.setState({ activeTabIndex: tabIndex });
    }

    componentDidUpdate({ data: prevData }) {
        if (!_.isEqual(this.props.data, prevData)) {
            this.computeTabData();
        }
    }

    computeTabData = () => {
        const {
            data: { months },
        } = this.props;

        this.tabs.Mobile = months.map((m) => m.mobile);
        this.tabs.Web = months.map((m) => m.web);
        this.tabs.Total = months.map((m) => m.mobile + m.web);
    };

    getChartData = () => {
        const { activeTabIndex } = this.state;
        const {
            data: { months },
        } = this.props;

        // It could be possible that the tab values are not computed at all, if it is so then compute
        // Ex: The first time getChartData is called
        if (_.every(this.tabs, (value) => _.isEmpty(value))) {
            this.computeTabData();
        }

        return {
            labels: months.map((m) => m.month),
            datasets: [
                {
                    ...cssAttributesForChart,
                    data: [...this.tabs[this.getActiveTab(activeTabIndex)]],
                },
            ],
        };
    };

    getLinearData = () => {
        const {
            data: { last7Days },
        } = this.props;

        return {
            title: 'Last 7 days',
            total: last7Days.mobile + last7Days.web,
            data: [
                {
                    title: 'Mobile',
                    value: last7Days.mobile,
                },
                {
                    title: 'Web',
                    value: last7Days.web,
                },
            ],
        };
    };

    render() {
        const {
            enabled,
            tooltipText,
            data: { months, last7Days },
        } = this.props;
        const { activeTabIndex } = this.state;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Digital Platform Refills"
                    imgPath="/reports/digital_platform.png"
                    helperText="You don't have a mobile app or a website yet"
                    appType="digitalPlatform"
                    tooltipText={tooltipText}
                />
            );
        }

        return (
            <Fragment>
                <DataCard
                    chartData={this.getChartData()}
                    linearData={this.getLinearData()}
                    showLoader={_.isEmpty(months) && _.isEmpty(last7Days)}
                    tooltipText={tooltipText}
                    tab={() => (
                        <CustomTab
                            activeTab={activeTabIndex}
                            onTabClick={this.onTabClick}
                            data={Object.keys(this.tabs)}
                        />
                    )}
                />
            </Fragment>
        );
    }
}
