import React, { PureComponent } from 'react';
import { Cell, Grid, Card, CardText, Checkbox } from 'react-md';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common';
import goBackService from 'utils/goBackService';
import { AbilityContext, userActions, restrictedResources } from 'casl';
import { connect } from 'react-redux';

class RefillDetails extends PureComponent {
    static contextType = AbilityContext;
    initialState = {};
    state = {
        allowsDeliveryOption: false,
        allowsMailOption: false,
        allowsPickupOption: false,
        allowsKioskOption: false,
        allowsCurbsideOption: false,
        isSaveDisable: true,
        isCheckboxDisabled: false,
    };

    componentDidMount() {
        this.getDeliveryOptions();
    }

    componentDidUpdate({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) {
        const { selectedLocation, pharmacy } = this.props;
        if (oldSelectedLocation.id !== selectedLocation.id || oldPharmacy.id !== pharmacy.id) {
            this.getDeliveryOptions();
        } else if (oldPharmacy.lastUpdated !== pharmacy.lastUpdated) {
            this.getDeliveryOptions();
            this.setState({ isCheckboxDisabled: false });
        }
    }

    getDeliveryOptions() {
        const { pharmacy, selectedLocation } = this.props;
        const locationDetails = _.find(pharmacy.location, (l) => l.id === selectedLocation.id);

        if (locationDetails && locationDetails.service) {
            const deliveryDetails = locationDetails.service.Refills;
            this.initialState = {
                allowsDeliveryOption: deliveryDetails.allowsDeliveryOption,
                allowsMailOption: deliveryDetails.allowsMailOption,
                allowsPickupOption: deliveryDetails.allowsPickupOption,
                allowsCurbsideOption: deliveryDetails.allowsCurbsideOption,
                allowsKioskOption: deliveryDetails.allowsKioskOption,
            };

            this.setState({ ...this.initialState, isSaveDisable: true });
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    toggleDeliveryOption = (bool, e) => {
        this.setState({ [e.target.name]: bool }, this.getStateDifference);
    };

    getStateDifference = () => {
        const diff = _.reduce(
            this.initialState,
            (result, value, key) => {
                return _.isEqual(value, this.state[key]) ? result : result.concat(key);
            },
            []
        );
        const isSaveDisable = diff.length === 0 ? true : false;

        this.setState({ isSaveDisable });
        return diff;
    };

    onSave = () => {
        const { updateDeliveryOptions, pharmacy, selectedLocation, getPharmacy, displayToast } = this.props;
        const refillPackage = _.find(pharmacy.package, (p) => p.code === 'FILL');
        const diffOptions = this.getStateDifference();
        let successCount = 0;

        this.setState({ isSaveDisable: true, isCheckboxDisabled: true });
        _.forEach(diffOptions, (o, index) => {
            const deliveryOption = _.find(
                refillPackage.properties,
                (rp) => rp.name === o && rp.locationId === selectedLocation.id
            );
            const currentDeliveryOption = { ...deliveryOption, [o]: +this.state[o], value: +this.state[o] };
            updateDeliveryOptions({ data: currentDeliveryOption, packageId: refillPackage.id }).then(
                () => {
                    successCount++;
                    if (index + 1 === diffOptions.length) {
                        getPharmacy(pharmacy.id);
                    }
                    if (successCount === diffOptions.length) {
                        displayToast({ text: `Delivery option updated successfully`, type: 'success' });
                    }
                },
                () => {
                    if (index + 1 === diffOptions.length) {
                        getPharmacy(pharmacy.id);
                    }
                    displayToast({ text: `Delivery option update failed for ${diffOptions[index]}`, type: 'error' });
                }
            );
        });
    };

    render() {
        const {
            allowsDeliveryOption,
            allowsMailOption,
            allowsPickupOption,
            allowsCurbsideOption,
            allowsKioskOption,
            isSaveDisable,
            isCheckboxDisabled,
        } = this.state;
        const { flags } = this.props;
        const {
            refillDetails: { subject: refillDetails },
        } = restrictedResources.generalSettings;
        const canUpdate = this.context.can(userActions.update, refillDetails);

        return (
            <Grid className="refill-details">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Refill Details"
                            onBack={this.onBack}
                            onSave={this.onSave}
                            disabled={isSaveDisable || !canUpdate}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="Delivery Options" />
                                    <SectionSubTitle text="These are the delivery options your patients can choose from when refilling prescriptions through the website or mobile app." />
                                    <SectionSubTitle text="When all delivery options are unchecked, patients will not be given any options on the pharmacy website or mobile app, and all prescriptions requests will be sent to the pharmacy marked for Pickup by default." />
                                    <SectionSubTitle text="If you wish to have all prescription requests to default to Mail or Delivery, please check that option below." />
                                    <Checkbox
                                        id="checkbox-allow-pickup-option"
                                        name="allowsPickupOption"
                                        label="Pick Up"
                                        checked={allowsPickupOption}
                                        onChange={this.toggleDeliveryOption}
                                        disabled={isCheckboxDisabled || !canUpdate}
                                    />
                                    <Checkbox
                                        id="checkbox-allow-delivery-option"
                                        name="allowsDeliveryOption"
                                        label="Delivery"
                                        checked={allowsDeliveryOption}
                                        onChange={this.toggleDeliveryOption}
                                        disabled={isCheckboxDisabled || !canUpdate}
                                    />
                                    <Checkbox
                                        id="checkbox-allow-mail-option"
                                        name="allowsMailOption"
                                        label="Mail"
                                        checked={allowsMailOption}
                                        onChange={this.toggleDeliveryOption}
                                        disabled={isCheckboxDisabled || !canUpdate}
                                    />
                                    {flags.npeKioskCurbsideDeliveryOptionsEnabled && (
                                        <>
                                            <Checkbox
                                                id="checkbox-allow-kiosk-option"
                                                name="allowsKioskOption"
                                                label="Kiosk"
                                                checked={allowsKioskOption}
                                                onChange={this.toggleDeliveryOption}
                                                disabled={isCheckboxDisabled || !canUpdate}
                                            />
                                            <Checkbox
                                                id="checkbox-allow-curbside-option"
                                                name="allowsCurbsideOption"
                                                label="Curbside"
                                                checked={allowsCurbsideOption}
                                                onChange={this.toggleDeliveryOption}
                                                disabled={isCheckboxDisabled || !canUpdate}
                                            />
                                        </>
                                    )}
                                    {!canUpdate && (
                                        <div className="ie-no-audiorec-note standard-margin-top standard-margin-left">
                                            You don't have permission to update
                                        </div>
                                    )}
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flags: state.launchDarkly,
    };
};

export default connect(mapStateToProps, null)(withRouter(RefillDetails));
