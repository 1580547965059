import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import _ from 'lodash';
import { logUtil } from '../../../utils/log';
import Snowplow, { RxConferPatientSearchContext, StructEventCategories } from '../../../snowplow';

const queryPatients = (
    pharmacyId,
    locationId,
    { query, queryType, listToSearch },
    sort = 'latest_activity_date:desc',
    filters,
    startIndex = 0,
    endIndex = 99
) => {
    const from = startIndex;
    const size = endIndex - startIndex + 1;

    Snowplow.structEvent(StructEventCategories.workflows, 'search-patients', [
        new RxConferPatientSearchContext()
            .setSearchType(_.isNil(query) ? null : queryType)
            .setFrom(from)
            .setSize(size)
            .setSort(sort)
            .setFilters(_.isEmpty(filters) ? null : Array.from(filters))
            .build(),
    ]);

    const complexFilters = _.filter(Array.from(filters), (f) => _.isArray(f));
    const simpleFilters = _.filter(Array.from(filters), (f) => _.isString(f));

    const params = _.filter([
        _.isNil(query) ? null : `${queryType}=${encodeURIComponent(query)}`,
        `from=${from}`,
        `size=${size}`,
        `sort=${sort}`,
        `listToSearch=${listToSearch}`,
        _.isEmpty(simpleFilters) ? null : `filters=${Array.from(simpleFilters).join(',')}`,
    ]);

    params.push(..._.map(complexFilters, ([key, value]) => `${key}=${value}`));

    return restActionCreators
        .get(
            `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/search?${params.join(
                '&'
            )}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(
                `An error occurred on patient-search for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getPatientInboxUser = (pharmacyId, locationId, inboxUserId) => {
    return restActionCreators
        .get(
            `${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity?searchType=id&search=${inboxUserId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

async function getExactDuplicateNewPatients(pharmacyId, locationId, newPatients) {
    const chunks = _.chunk(newPatients, 10000);
    const duplicates = [];
    const non_duplicates = [];
    for (let chunk of chunks) {
        try {
            const response = await restActionCreators.post(
                `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/get-non-duplicates-exact`,
                { patients: chunk }
            );

            if (!response.success) {
                return Promise.reject({ success: false });
            }

            duplicates.push(...response.duplicates);
            non_duplicates.push(...response.non_duplicates);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    return { success: true, duplicates, non_duplicates };
}

async function searchPatientList(pharmacyId, locationId, patients) {
    const chunks = _.chunk(patients, 10000);
    const results = [];
    for (let chunk of chunks) {
        try {
            const response = await restActionCreators.post(
                `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/search-patient-list`,
                { patients: chunk }
            );
            if (!response.success) {
                return Promise.reject({ success: false });
            }

            results.push(...response.results);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    return { success: true, results };
}

async function loadPatientsByInboxUserIds(pharmacyId, locationId, inbox_user_ids) {
    try {
        const response = await restActionCreators.post(
            `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/load-patients`,
            { inbox_user_ids }
        );
        if (!response.success) {
            return Promise.reject({ success: false });
        }

        return response;
    } catch (error) {
        return Promise.reject(error);
    }
}

const patientService = {
    queryPatients,
    getPatientInboxUser,
    getExactDuplicateNewPatients,
    searchPatientList,
    loadPatientsByInboxUserIds,
};
export default patientService;
