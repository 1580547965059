import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import moment from 'moment';

import config from 'config';
import LEARN_MORE, { CTA_BUTTON_TITLES, SNOWPLOW_EVENT_DETAILS } from 'constants/LearnMore';
import LearnMoreDialog from 'components/Common/LearnMoreDialog';
import { sendNpeInterest } from 'redux/actionCreators/Reports';
import { displayToast } from 'redux/actionCreators/Snackbar';

const defaultLearnMoreData = {
    valueProps: [],
    learnMore: {},
};

class LearnMoreButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            learnMoreDialogData: {
                ...defaultLearnMoreData,
            },
            learnMoreDialogVisible: false,
        };
    }

    sendNPEInterestEmail = () => {
        const { sendNpeInterest, email, selectedLocationName, appType, variant, displayToast } = this.props;
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            location_name: selectedLocationName,
            product_name: LEARN_MORE[variant][appType]['emailSubject'],
            email,
            date: moment().format('YYYY-MM-DD'),
            appType,
        };

        sendNpeInterest(payload).then((res) => {
            if (res.success) {
                displayToast({
                    text: 'Thank you for your interest! We will get in touch with you soon.',
                    type: 'success',
                });
            } else {
                displayToast({ text: 'Error while sending your interest. Please try again.', type: 'error' });
            }
        });
    };

    openLearnMoreDialog = () => {
        const { appType, variant } = this.props;
        this.setState({
            learnMoreDialogVisible: true,
            learnMoreDialogData: LEARN_MORE[variant][appType],
        });
    };

    closeLearnMoreDialog = () => {
        this.setState({
            learnMoreDialogVisible: false,
            learnMoreDialogData: {
                ...defaultLearnMoreData,
            },
        });
    };

    render() {
        const { sendNpeInterestLoading, appType, variant } = this.props;
        const { learnMoreDialogData, learnMoreDialogVisible } = this.state;

        return (
            <Fragment>
                <Button variant="contained" color="primary" onClick={this.openLearnMoreDialog}>
                    {CTA_BUTTON_TITLES[variant][appType]}
                </Button>
                <LearnMoreDialog
                    visible={learnMoreDialogVisible}
                    data={learnMoreDialogData}
                    onHide={this.closeLearnMoreDialog}
                    snowplow={SNOWPLOW_EVENT_DETAILS[variant][appType]}
                    sendNPEInterestEmail={this.sendNPEInterestEmail}
                    sendNpeInterestLoading={sendNpeInterestLoading[appType]}
                />
            </Fragment>
        );
    }
}

LearnMoreButton.propTypes = {
    appType: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const {
        pharmacy,
        reports: { sendNpeInterestLoading },
        auth: { userAccount, selectedLocation, selectedCustomer },
    } = state;

    return {
        sendNpeInterestLoading,
        email: userAccount.email,
        selectedLocationName: selectedLocation.name,
        selectedCustomer,
        pharmacy: pharmacy.pharmacy,
    };
};

const mapDispatchToProps = {
    sendNpeInterest,
    displayToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnMoreButton);
