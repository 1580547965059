import React from 'react';
import { Button, Tooltipped } from 'react-md';

import { formatToCurrency } from 'utils/helper';

function ComputedROIValues(props) {
    return (
        <div className="ROICalculator__ComputedROIValues">
            <div className="ROICalculator__ComputedROIValues--title">
                <div className="ROICalculator__ComputedROIValues--titleText">
                    <span>Step 3: View Value</span>
                </div>
            </div>
            {/* This is repetitive and should probably be refactored into a 
                loop that maps each value to a new component that takes the value,
                metric, and toolTipLabel as props...
            */}
            <div className="ROICalculator__ComputedROIValues--dataSection">
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <div className="ROICalculator__ComputedROIValues--value">
                        {formatToCurrency(props.scriptsProcessedValue, '$')}
                    </div>
                    <div className="ROICalculator__ComputedROIValues--metric">
                        <Button flat tooltipLabel="Avg revenue per Rx * number of prescription refills">
                            Value of RX Processed
                        </Button>
                    </div>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatToCurrency(props.newPatientsValue, '$')}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        <Button flat tooltipLabel="Annual average revenue of a patient * number of transfers">
                            Value of New Patients
                        </Button>
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatToCurrency(props.scriptsProcessedGrossProfit, '$')}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        <Button
                            flat
                            tooltipLabel="Average gross profit per prescription * number of prescription refills"
                        >
                            Gross Profit of RX Processed
                        </Button>
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatToCurrency(props.automationValue, '$')}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        {/* Multiline tooltip can't be done with normal buttons in react-md... tried <br/> \n and &#013 */}
                        <Tooltipped
                            label={
                                <div>
                                    <div>
                                        (number of prescription refills + IVR refills <br />+ transfers + outbound
                                        alerts)
                                        <br />
                                        * minutes saved with automation <br />* (employee wage per hour/60)
                                    </div>
                                </div>
                            }
                            setPosition
                        >
                            <Button flat> Value of Automation</Button>
                        </Tooltipped>
                        {/* <Button
                            flat
//                          tooltipLabel='(number of prescription refills + IVR refills + transfers + outbound alerts) <div> </br> \n &#013
//                                 * minutes saved with automation * (employee wage per hour/60) </div>'
                                toolTipLabel={<span><div>here</div><div>there</div></span>}
                        >
                            Value of Automation
                        </Button> */}
                    </span>
                </div>
                <div className="ROICalculator__ComputedROIValues--dataContainer">
                    <span className="ROICalculator__ComputedROIValues--value">
                        {formatToCurrency(props.supportValue, '$')}
                    </span>
                    <span className="ROICalculator__ComputedROIValues--metric">
                        <Button flat tooltipLabel="25 * (Hours/month DP works for you)">
                            Value of Support
                        </Button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ComputedROIValues;
