import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import CardPlaceholder from './CardPlaceholder';
import CardThumbnail from './CardThumbnail';

const NoCardsFound = ({ noResult }) => {
    const emptyMessage = noResult ? 'No cards found matching this search!' : 'No cards found!!';
    return <div className="centered-content no-cards-found"> {emptyMessage} </div>;
};

const CardList = (props) => {
    const {
        handleCardPreview,
        handleCardDelete,
        selectCard,
        cardsData,
        isCardSelectable,
        selectedCards,
        fetchingCards,
        noResult,
    } = props;
    const checkIfCardSelected = (cardData) =>
        Boolean(_.find(selectedCards, (card) => card.card_id === cardData.card_id));

    return (
        <Fragment>
            <Grid container>
                <Grid container item className={fetchingCards && cardsData.length === 0 ? 'hidden' : ''}>
                    {cardsData.length === 0 ? (
                        <NoCardsFound noResult={noResult} />
                    ) : (
                        <Fragment>
                            {cardsData.map((cardData) => (
                                <Grid xs={6} item key={cardData.card_id}>
                                    <CardThumbnail
                                        handleCardPreview={() => handleCardPreview(cardData)}
                                        handleCardDelete={() => handleCardDelete(cardData)}
                                        selectCard={() => selectCard(cardData, checkIfCardSelected(cardData))}
                                        isCardSelectable={isCardSelectable}
                                        cardData={cardData}
                                        isCardSelected={checkIfCardSelected(cardData)}
                                    />
                                </Grid>
                            ))}
                        </Fragment>
                    )}
                </Grid>
            </Grid>
            {fetchingCards && <CardPlaceholder legendsCount={7} />}
        </Fragment>
    );
};

export default CardList;
