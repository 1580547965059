import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent, Tooltip } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import EmailIcon from '@material-ui/icons/Email';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DRAWER_STATES } from 'constants/Newsletter';
import { newsletterActions } from 'redux/Newsletter/action';
import Snowplow from 'snowplow';

const localizer = momentLocalizer(moment);

const CustomToolbar = ({ label, onNavigate }) => {
    return (
        <Grid container spacing={2} style={{ marginBottom: '5px' }} className="calendar-toolbar">
            <Grid item xs={1}>
                <div onClick={() => onNavigate('PREV')} className="border-button centered-content">
                    <Icon className="user-clickable">keyboard_arrow_left</Icon>
                </div>
            </Grid>
            <Grid item xs={10} className="flex-center flex-middle title">
                <span>{label}</span>
            </Grid>
            <Grid item xs={1} className="flex-right">
                <div onClick={() => onNavigate('NEXT')} className="border-button centered-content">
                    <Icon className="user-clickable">keyboard_arrow_right</Icon>
                </div>
            </Grid>
        </Grid>
    );
};

const CustomEventWrapper = (props) => {
    const data = props.event;

    return (
        <Fragment>
            {data.next && <EmailIcon className="next-event-icon" fontSize="small" />}
            <div className={`rbc-event ${data.type}`} onClick={() => props.onSelect(data)}>
                <div className="rbc-event-content">
                    <div className="rbc-event-content-title">
                        <span>{data.title}</span>
                    </div>
                    <div className="rbc-event-content-marker">
                        <Tooltip
                            title={`${data.cards.length} Cards scheduled for this date`}
                            aria-label={`${data.cards.length} Cards scheduled for this date`}
                        >
                            <span>({data.cards.length})</span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const CustomDateHeader = (props) => {
    const isToday = moment(props.date).isSame(moment(), 'days');
    return (
        <div className={isToday ? 'flex-space-between' : 'flex-right'}>
            {isToday && <Icon className="dp-today">verified</Icon>}
            <span>{props.label}</span>
        </div>
    );
};

const NewsletterCalendar = (props) => {
    const [selectedDay, setSelectedDay] = useState('');

    const {
        setDrawerState,
        newsletterList,
        fetchNewsletters,
        setActiveEvent,
        refetchNewsletters,
        selectedCustomer,
    } = props;

    useEffect(() => {
        Snowplow.pageView('Newsletter Calendar Tab');
    }, []);

    useEffect(() => {
        const start_date = moment().startOf('month').format();
        const end_date = moment().endOf('month').format();
        fetchNewsletters({ start_date, end_date });
        setSelectedDay('');
    }, [selectedCustomer]);

    useEffect(() => {
        if (refetchNewsletters) {
            const { activeEvent } = props;
            const start_date = moment(activeEvent.date).startOf('month').format();
            const end_date = moment(activeEvent.date).endOf('month').format();
            fetchNewsletters({ start_date, end_date, isRefetch: true });
        }
    }, [refetchNewsletters]);

    useEffect(() => {
        if (selectedDay && newsletterList.length) {
            const activeEvent = _.filter(newsletterList, (newsletter) =>
                moment(selectedDay).isSame(newsletter.start)
            )[0];

            if (activeEvent) {
                setActiveEvent({
                    date: activeEvent.start,
                    cards: activeEvent.cards,
                    event_id: activeEvent.id,
                });
            }
        }
    }, [newsletterList]);

    const handleEventClick = (event) => {
        const selectedDate = moment(event.start).format();
        const isPreviousOrToday = moment(selectedDate).isSameOrBefore(moment(), 'day');
        setSelectedDay(selectedDate);
        setActiveEvent({
            date: event.start,
            cards: event.cards,
            event_id: event.id,
        });
        if (isPreviousOrToday) {
            setDrawerState(DRAWER_STATES.VIEW_NEWSLETTER);
        } else {
            setDrawerState(DRAWER_STATES.EDIT_NEWSLETTER);
        }
    };

    const DateCellWrapper = (props) => {
        const { value } = props;
        const style = moment(value).isSame(selectedDay, 'day') ? { border: '2px solid #3892FC' } : {};
        return <div className="rbc-day-bg" style={style}></div>;
    };

    const onNavigation = (date) => {
        const start_date = moment(date).startOf('month').format();
        const end_date = moment(date).endOf('month').format();
        fetchNewsletters({ start_date, end_date });
    };

    return (
        <div className="calendar-container">
            <Card className="card centered-content">
                <CardContent className="full-width">
                    <div className="flex-space-between">
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={newsletterList}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: '500px', width: '80%' }}
                            onNavigate={onNavigation}
                            components={{
                                toolbar: CustomToolbar,
                                eventWrapper: CustomEventWrapper,
                                dateCellWrapper: DateCellWrapper,
                                month: {
                                    dateHeader: CustomDateHeader,
                                },
                            }}
                            onSelectEvent={handleEventClick}
                        />
                        <div className="legends-container">
                            <Typography className="main-title">Calendar Legend</Typography>
                            <div className="legends">
                                <Typography className="sub-title">eNewsletter Status</Typography>
                                <div className="unpublished flex-middle">eNewsletter Draft</div>
                                <div className="published flex-middle">eNewsletter Sent</div>
                                <div className="no-newsletter flex-middle">eNewsletter Not Sent</div>
                            </div>
                            <div className="icon-legends">
                                <Typography className="sub-title">Date Status</Typography>
                                <div className="legend flex-middle">
                                    <Icon>verified</Icon>
                                    <span>Today's Date</span>
                                </div>
                                <div className="legend flex-middle">
                                    <EmailIcon fontSize="small" />
                                    <span>Next eNewsletter</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = ({ newsletter, auth }) => ({
    newsletterList: newsletter.newsletterList,
    refetchNewsletters: newsletter.refetchNewsletters,
    activeEvent: newsletter.activeEvent,
    selectedCustomer: auth.selectedCustomer,
});

const mapDispatchToProps = {
    setDrawerState: newsletterActions.setDrawerState,
    fetchNewsletters: newsletterActions.fetchNewsletters,
    setActiveEvent: newsletterActions.setActiveEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCalendar);
