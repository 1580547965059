import { restrictedResources } from 'casl';

const icon = {
    right: 'keyboard_arrow_right',
};

const { ivr } = restrictedResources;

const OnlyCallTrackingPhoneLineSettings = [
    {
        text: 'Pharmacy Hours',
        to: '/settings/phone-system/pharmacy-hours',
        icon,
        isInternal: false,
        caslSubject: ivr.pharmacyHours.subject,
    },
    {
        text: 'Call Transfer During Hours',
        to: '/settings/phone-system/phone-transfer',
        icon,
        isInternal: false,
        caslSubject: ivr.callTransferDuringHours.subject,
    },
];

const OnlyCallTrackingGeneralSettings = [
    {
        text: 'Languages and Voices',
        to: '/settings/phone-system/languages',
        icon,
        caslSubject: ivr.languagesAndVoices.subject,
    },
];

const GeneralSettings = [
    ...OnlyCallTrackingGeneralSettings,
    {
        text: 'General Greeting',
        to: '/settings/phone-system/main-greeting',
        icon,
        hasLearnMoreMenu: true,
        isAutoAttendant: true,
        learnMoreAppType: 'autoAttendant',
        caslSubject: ivr.generalGreeting.subject,
    },
    {
        text: 'General Menu Options',
        to: '/settings/phone-system/main-menu',
        icon,
        hasLearnMoreMenu: true,
        hasConfigureButton: true,
        isAutoAttendant: true,
        learnMoreAppType: 'autoAttendant',
        caslSubject: ivr.generalMenuOptions.subject,
    },
];

const PhoneLineSettings = [
    {
        text: 'Pharmacy Greeting',
        to: '/settings/phone-system/ivr-main-greeting',
        icon,
        isInternal: false,
        caslSubject: ivr.pharmacyGreeting.subject,
    },
    ...OnlyCallTrackingPhoneLineSettings,
    {
        text: 'Call Transfer After Hours',
        to: '/settings/phone-system/after-hour-transfer',
        icon,
        isInternal: false,
        caslSubject: ivr.callTransferAfterHours.subject,
    },
    {
        text: 'Pharmacy Menu Options',
        to: '/settings/phone-system/phone-menu',
        icon,
        isInternal: false,
        caslSubject: ivr.pharmacyMenuOptions.subject,
    },
    {
        text: 'Prescription Refill Fulfillment',
        to: '/settings/phone-system/refill-menu',
        icon,
        isInternal: false,
        caslSubject: ivr.prescriptionRefillFulfillment.subject,
    },
    {
        text: 'Prescriber Voicemail',
        to: '/settings/phone-system/doctors-line',
        icon,
        isInternal: false,
        caslSubject: ivr.prescriberVoicemail.subject,
    },
    {
        text: 'General Voicemail',
        to: '/settings/phone-system/general-line',
        icon,
        isInternal: false,
        caslSubject: ivr.generalVoiceMail.subject,
    },
    {
        text: 'New Patient Voicemail',
        to: '/settings/phone-system/new-patient-line',
        icon,
        isInternal: false,
        isCallTracking: true,
        caslSubject: ivr.newPatientVoicemail.subject,
    },
];

export { GeneralSettings, PhoneLineSettings, OnlyCallTrackingGeneralSettings, OnlyCallTrackingPhoneLineSettings };
