import React, { Component, Fragment } from 'react';
import { Card, Cell, CardText, Grid, CircularProgress, Divider, SelectionControlGroup, Button } from 'react-md';
import { connect } from 'react-redux';
import { TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, FormHelperText } from '@material-ui/core';

import { CardHeader, SectionSubTitle } from 'components/Common/index';
import GreetingEditor from 'components/Common/GreetingEditor';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    updateGreetingMessage,
    addGreeting,
    deleteGreeting,
    editGreeting,
    closeGreetingEditor,
    fileUpload,
    saveGreeting,
    saveFilename,
    changeGreetingScript,
    changeGreetingType,
    changeAfterHourSetting,
} from 'redux/actionCreators/Settings/MainGreeting';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { PhoneNumberMask } from 'utils/mask';
import { toDigits } from 'utils/helper';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class AfterHourTransfer extends Component {
    constructor(props) {
        super(props);
        this.timeoutList = [];
        for (let i = 15; i <= 60; ) {
            this.timeoutList.push(i);
            i += 15;
        }
        this.state = {
            afterHourOption: 'default',
        };
    }

    componentDidMount() {
        const { fetchMainGreeting, loadGreetingFiles } = this.props;
        loadGreetingFiles();
        fetchMainGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, fetchMainGreeting, defaultGreeting, loadGreetingFiles } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadGreetingFiles();
            fetchMainGreeting();
        }

        if (!prevProps.defaultGreeting && defaultGreeting.id) {
            let afterHourOption;
            if (!defaultGreeting.closed_greeting_id && !defaultGreeting.emergency_phone) {
                afterHourOption = 'default';
            } else if (defaultGreeting.closed_greeting_id) {
                afterHourOption = 'custom';
            } else if (defaultGreeting.emergency_phone) {
                afterHourOption = 'transfer';
            }
            this.setState({ afterHourOption });
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    handleRadioChange = (val) => {
        this.setState({
            afterHourOption: val,
        });
    };

    removeGreeting = () => {
        const { defaultGreeting, deleteGreeting } = this.props;
        const newGreeting = { id: defaultGreeting.id, closed_greeting_id: '', type: 'closed' };

        deleteGreeting(newGreeting).then((respData) => {
            if (!('err' in respData)) {
                fetchMainGreeting();
            }
        });
    };

    saveCustomMessage = (data) => {
        const { closeGreetingEditor, newGreeting, defaultGreeting } = this.props;
        //save all data from modal to tmp variable for custom greeting processing
        const customGreetingData = { ...data, ...newGreeting };
        if (
            customGreetingData.fileObj ||
            customGreetingData.editedFileName ||
            customGreetingData.greeting_id !== defaultGreeting.closed_greeting_id
        ) {
            this.customGreetingData = customGreetingData;
        }
        closeGreetingEditor();
    };

    onSave = () => {
        const { newGreeting, defaultGreeting, displayToast } = this.props;
        const { afterHourOption } = this.state;

        if (afterHourOption === 'default') {
            this.saveClosedGreeting({
                id: defaultGreeting.id,
                closed_greeting_id: '',
                emergency_phone: null,
                emergency_xfer_sec: 0,
            });
        } else if (afterHourOption === 'custom') {
            if (this.customGreetingData) {
                this.saveCustomGreeting();
            } else {
                displayToast({ text: 'There is no new greeting details to update', type: 'error' });
            }
        } else if (afterHourOption === 'transfer') {
            let emergencyPhone = newGreeting.emergency_phone || defaultGreeting.emergency_phone;
            const emergencyXferSec = newGreeting.emergency_xfer_sec || defaultGreeting.emergency_xfer_sec;

            if ((newGreeting.emergency_phone || newGreeting.emergency_xfer_sec) && emergencyPhone && emergencyXferSec) {
                emergencyPhone = toDigits(emergencyPhone);
                if (!(emergencyPhone.length >= 10)) {
                    displayToast({ text: 'Phone number must be 10 or 11 digits', type: 'error' });
                } else if (this.timeoutList.indexOf(emergencyXferSec) < 0) {
                    displayToast({ text: 'Select a timeout value', type: 'error' });
                } else {
                    this.saveClosedGreeting({
                        id: defaultGreeting.id,
                        closed_greeting_id: '',
                        emergency_xfer_sec: emergencyXferSec,
                        emergency_phone: emergencyPhone.length > 10 ? emergencyPhone.substring(1) : emergencyPhone,
                    });
                }
            } else {
                displayToast({ text: 'There is no emergency details to update', type: 'error' });
            }
        }
    };

    async saveCustomGreeting() {
        const { fileObj, editedFileName, filenameWithExt } = this.customGreetingData;
        const { defaultGreeting, saveFile, fileUpload, loadGreetingFiles } = this.props;

        const greetingPayload = {
            id: defaultGreeting.id,
            emergency_phone: null,
            emergency_xfer_sec: 0,
            closed_greeting_id: this.customGreetingData.greeting_id,
        };

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            greetingPayload.closed_greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            await saveFile({ name: newFilename, id: greetingPayload.closed_greeting_id });
        }

        this.saveClosedGreeting(greetingPayload);
    }

    async saveClosedGreeting(greetingPayload) {
        const { fetchMainGreeting, displayToast, saveGreeting } = this.props;

        const saveGreetingResp = await saveGreeting({ newGreeting: { ...greetingPayload }, type: 'MAIN' });
        if (saveGreetingResp.err) {
            displayToast({ text: 'Closed hours greeting update failed', type: 'error' });
        } else {
            fetchMainGreeting();
            displayToast({ text: 'Closed hours greeting updated', type: 'success' });
        }
        this.customGreetingData = null;
    }

    handleAfterHourChange = (e) => {
        const { changeAfterHourSetting } = this.props;
        const payload = { phone: e.target.value, type: 'phone' };
        changeAfterHourSetting(payload);
    };

    handleAfterHourTimeoutChange = (e) => {
        const { changeAfterHourSetting } = this.props;
        const payload = { sec: e.target.value, type: 'sec' };
        changeAfterHourSetting(payload);
    };

    render() {
        const { afterHourOption } = this.state;
        const {
            displayToast,
            greetingFiles,
            defaultGreeting,
            showGreetingCreator,
            newGreeting,
            updateGreetingMessage,
            closeGreetingEditor,
            isFilenameSaving,
            loadingMainGreeting,
            editGreeting,
            addGreeting,
            changeGreetingScript,
            changeGreetingType,
            savingInProgress,
            auth: { selectedLocation, selectedCustomer },
        } = this.props;
        const { write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.callTransferAfterHours;

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });

        const emergencyPhone =
            'emergency_phone' in newGreeting ? newGreeting.emergency_phone : defaultGreeting.emergency_phone;
        const emergencyXferSec = newGreeting.emergency_xfer_sec || defaultGreeting.emergency_xfer_sec;

        // TODO: This component should be refactored
        return (
            <Grid className="closed-greeting">
                {/* Note, write here means the user role can create, update, delete call transfer after hours */}
                <Can I={write} this={caslSubject} passThrough>
                    {(writeAllowed) => (
                        <Cell size={12}>
                            <Card className="outer-card">
                                <CardHeader
                                    title="Call Transfer After Hours"
                                    disabled={isFilenameSaving || newGreeting.processing}
                                    loading={isFilenameSaving || newGreeting.processing}
                                    onBack={this.onBack}
                                    onSave={writeAllowed && this.onSave}
                                />
                                <h5 className="sub-title-text" style={{ marginLeft: '60px' }}>
                                    This is the message that callers will hear when they press zero to transfer to the
                                    pharmacy when the store is closed
                                </h5>
                                <CardText>
                                    <Card className="inner-card">
                                        <CardText>
                                            {loadingMainGreeting ? (
                                                <CircularProgress id="main-greeting-spinner" />
                                            ) : (
                                                <Fragment>
                                                    <SelectionControlGroup
                                                        id="radio-close-default-options"
                                                        name="radio-close-default-options"
                                                        type="radio"
                                                        value={afterHourOption}
                                                        onChange={this.handleRadioChange}
                                                        controls={[
                                                            {
                                                                label: 'Default After Hours Zero Transfer Message',
                                                                value: 'default',
                                                            },
                                                        ]}
                                                        disabled={!writeAllowed}
                                                    />
                                                    <div className="grid-container">
                                                        <Cell size={12} className="align-subtext">
                                                            <h5 className="sub-title-text">
                                                                By default the IVR will say:
                                                                <span className="italic-style">
                                                                    "The pharmacy is currently closed and there is
                                                                    nobody available to answer your call.
                                                                    <br />
                                                                    If this is an emergency, please call 9-1-1 or
                                                                    proceed to your nearest emergency room."
                                                                </span>
                                                            </h5>
                                                            <SectionSubTitle text="You can record your own custom message below to override the default prompt" />
                                                        </Cell>
                                                    </div>

                                                    <div className="grid-container">
                                                        <Cell size={12}>
                                                            <Divider />
                                                        </Cell>
                                                    </div>

                                                    <SelectionControlGroup
                                                        id="radio-close-custom-options"
                                                        name="radio-close-custom-options"
                                                        type="radio"
                                                        value={afterHourOption}
                                                        onChange={this.handleRadioChange}
                                                        controls={[
                                                            {
                                                                label: 'Custom After Hours Zero Transfer Message',
                                                                value: 'custom',
                                                            },
                                                        ]}
                                                        disabled={!writeAllowed}
                                                    />

                                                    {afterHourOption === 'custom' && (
                                                        <span className="greetings-list">
                                                            <div className="grid-container table-title">
                                                                {defaultGreeting['closed_greeting_type'] !==
                                                                    'text/plain' && <Cell size={1}>&nbsp;</Cell>}
                                                                <Cell
                                                                    size={
                                                                        defaultGreeting[`closed_greeting_type`] ===
                                                                        'text/plain'
                                                                            ? 4
                                                                            : 3
                                                                    }
                                                                >
                                                                    Title
                                                                </Cell>
                                                                <Cell size={1}>Status</Cell>
                                                                <Cell size={7}>&nbsp;</Cell>
                                                            </div>

                                                            {!this.customGreetingData &&
                                                                defaultGreeting &&
                                                                defaultGreeting.closed_greeting_id && (
                                                                    <span className="main-greeting">
                                                                        <Greetings
                                                                            className="blue-bordered"
                                                                            perms={selectedLocation.perms}
                                                                            greetingDetail={defaultGreeting}
                                                                            greetingFileList={greetingFileList}
                                                                            handleEdit={editGreeting.bind(this, {
                                                                                defaultGreeting,
                                                                                type: 'closed',
                                                                            })}
                                                                            isDeleting={
                                                                                defaultGreeting.closed_processing
                                                                            }
                                                                            handleDelete={this.removeGreeting}
                                                                            type="closed"
                                                                            hideActions={!writeAllowed}
                                                                        />
                                                                    </span>
                                                                )}

                                                            {!this.customGreetingData &&
                                                                defaultGreeting &&
                                                                !defaultGreeting.closed_greeting_id && (
                                                                    <div className="grid-container blue-bordered no-closed-greeting-msg">
                                                                        <Cell
                                                                            size={12}
                                                                            className="table-data centered-content "
                                                                        >
                                                                            There is no custom transfer message
                                                                            configured for this location's IVR
                                                                        </Cell>
                                                                    </div>
                                                                )}

                                                            {
                                                                // show this message only if there is a new greeting or its edited
                                                                this.customGreetingData &&
                                                                    (this.customGreetingData.fileObj ||
                                                                        this.customGreetingData.editedFileName ||
                                                                        this.customGreetingData.greeting_id !==
                                                                            defaultGreeting.closed_greeting_id) && (
                                                                        <div className="grid-container blue-bordered">
                                                                            <Cell
                                                                                size={12}
                                                                                className="table-data centered-content"
                                                                                style={{ fontWeight: 'bold' }}
                                                                            >
                                                                                Save changes to make this greeting
                                                                                active
                                                                            </Cell>
                                                                        </div>
                                                                    )
                                                            }

                                                            <Grid>
                                                                <Cell
                                                                    size={12}
                                                                    className="centered-content button-control"
                                                                >
                                                                    {writeAllowed &&
                                                                        !defaultGreeting.closed_greeting_id && (
                                                                            <Button
                                                                                className="add-btn"
                                                                                flat
                                                                                secondary
                                                                                onClick={() => addGreeting('closed')}
                                                                            >
                                                                                + ADD A NEW GREETING
                                                                            </Button>
                                                                        )}
                                                                    {!writeAllowed &&
                                                                        !defaultGreeting.closed_greeting_id && (
                                                                            <div>
                                                                                You don't have permission to configure
                                                                                greeting
                                                                            </div>
                                                                        )}
                                                                </Cell>
                                                            </Grid>
                                                        </span>
                                                    )}

                                                    <div className="grid-container">
                                                        <Cell size={12}>
                                                            <Divider />
                                                        </Cell>
                                                    </div>

                                                    <SelectionControlGroup
                                                        id="radio-close-transfer-options"
                                                        name="radio-close-transfer-options"
                                                        type="radio"
                                                        value={afterHourOption}
                                                        onChange={this.handleRadioChange}
                                                        controls={[
                                                            {
                                                                label: 'After Hours Line',
                                                                value: 'transfer',
                                                            },
                                                        ]}
                                                        disabled={!writeAllowed}
                                                    />

                                                    {afterHourOption === 'transfer' && (
                                                        <div className="grid-container">
                                                            <Cell size={12} className="align-subtext">
                                                                <SectionSubTitle text="The IVR will send calls to this number when the store is closed" />

                                                                <h5 className="sub-title-text">
                                                                    The IVR will provide the following message when this
                                                                    option is chosen
                                                                    <br />
                                                                    <span className="italic-style">
                                                                        "The pharmacy is now closed. If you need to
                                                                        reach our emergency line, please press 1 now.
                                                                        Otherwise, please hold to use our automated
                                                                        system."
                                                                    </span>
                                                                </h5>

                                                                <h5 className="sub-title-text">
                                                                    User will be sent to overflow setting (on the Phone
                                                                    Call Transfer screen) if nobody answers the call
                                                                    within the set timeout
                                                                    <br />
                                                                    Please ensure this number will reach someone as it's
                                                                    considered an emergency line and the IVR will
                                                                    attempt the transfer when enabled
                                                                </h5>

                                                                <div className="grid-container">
                                                                    <Cell size={3} style={{ marginLeft: '0px' }}>
                                                                        <TextField
                                                                            id="outlined-er-pno"
                                                                            name="er-phone"
                                                                            label="Phone Number"
                                                                            disabled={!writeAllowed || savingInProgress}
                                                                            value={
                                                                                emergencyPhone &&
                                                                                toDigits(emergencyPhone).length > 10
                                                                                    ? emergencyPhone.substring(1)
                                                                                    : emergencyPhone
                                                                            }
                                                                            onChange={this.handleAfterHourChange}
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                inputComponent: PhoneNumberMask,
                                                                            }}
                                                                            error={
                                                                                'error' in newGreeting &&
                                                                                newGreeting.error !== ''
                                                                            }
                                                                            fullWidth
                                                                        />
                                                                    </Cell>
                                                                    <Cell size={3}>
                                                                        <FormControl variant="outlined" fullWidth>
                                                                            <InputLabel htmlFor="outlined-er-timeout">
                                                                                Timeout
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="select-er-timeout"
                                                                                value={
                                                                                    emergencyXferSec
                                                                                        ? emergencyXferSec
                                                                                        : ''
                                                                                }
                                                                                disabled={
                                                                                    !writeAllowed || savingInProgress
                                                                                }
                                                                                onChange={
                                                                                    this.handleAfterHourTimeoutChange
                                                                                }
                                                                                input={
                                                                                    <OutlinedInput
                                                                                        fullWidth
                                                                                        labelWidth={52}
                                                                                        name="er-timeout"
                                                                                        id="outlined-er-timeout"
                                                                                    />
                                                                                }
                                                                            >
                                                                                {this.timeoutList.map((tVal, tIdx) => (
                                                                                    <MenuItem
                                                                                        key={`timeout${tIdx}`}
                                                                                        value={tVal}
                                                                                    >{`${tVal} sec`}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Cell>
                                                                </div>
                                                                <FormHelperText error style={{ marginTop: '0' }}>
                                                                    {newGreeting.error}
                                                                </FormHelperText>
                                                            </Cell>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}

                                            <GreetingEditor
                                                custPerms={selectedCustomer.perms}
                                                isSaving={isFilenameSaving || newGreeting.processing}
                                                dialogTitle={
                                                    newGreeting.id
                                                        ? 'Edit after hours zero transfer message'
                                                        : 'Add after hours zero transfer message'
                                                }
                                                isVisible={showGreetingCreator}
                                                greetingFiles={greetingFiles}
                                                greeting={newGreeting}
                                                defaultGreeting={defaultGreeting}
                                                greetingId={newGreeting.greeting_id || ''}
                                                greetingUrl={newGreeting.greeting_url}
                                                greetingType={newGreeting.greeting_type_id}
                                                greetingScript={newGreeting.greeting_text}
                                                handleChange={updateGreetingMessage}
                                                closeHandler={closeGreetingEditor}
                                                saveHandler={this.saveCustomMessage}
                                                showToastMessage={displayToast}
                                                changeGreetingType={changeGreetingType}
                                                changeGreetingScript={changeGreetingScript}
                                            />
                                        </CardText>
                                    </Card>
                                </CardText>
                            </Card>
                        </Cell>
                    )}
                </Can>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchMainGreeting: () => dispatch(fetchMainGreeting()),
    addGreeting: (data) => dispatch(addGreeting(data)),
    closeGreetingEditor: () => dispatch(closeGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    deleteGreeting: (data) => dispatch(deleteGreeting(data)),
    updateGreetingMessage: (data) => dispatch(updateGreetingMessage(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    editGreeting: (data) => dispatch(editGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changeGreetingType: (data) => dispatch(changeGreetingType(data)),
    changeGreetingScript: (data) => dispatch(changeGreetingScript(data)),
    changeAfterHourSetting: (data) => dispatch(changeAfterHourSetting(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AfterHourTransfer);
