import React, { useMemo, useState } from 'react';
import _ from 'lodash';

export default function PatientAvatar(props) {
    const { seed, size, growOnHover } = props;
    const [state, setState] = useState({ hovering: false });

    const seedIndex = Math.abs(hashString(seed));
    const Skeleton = avatarSkeletons[seedIndex % avatarSkeletons.length];

    const [backgroundColor, highlightColor1, highlightColor2, highlightColor3] = useMemo(() => getColors(seed), [seed]);

    const s = growOnHover && state.hovering ? size * 1.2 : size;

    return (
        <span
            onMouseEnter={() => {
                setState({ hovering: true });
            }}
            onMouseLeave={() => {
                setState({ hovering: false });
            }}
            className="patient-avatar"
            style={{
                display: 'inline-block',
                height: `${s}px`,
            }}
        >
            {
                <Skeleton
                    size={s}
                    backgroundColor={backgroundColor}
                    highlightColor1={highlightColor1}
                    highlightColor2={highlightColor2}
                    highlightColor3={highlightColor3}
                />
            }
        </span>
    );
}
PatientAvatar.defaultProps = {
    size: 30,
    growOnHover: false,
};

export function getPatientAvatarSeedFromContact({ first_name, last_name, date_of_birth }) {
    return _.toLower(`${first_name}|${last_name}|${_.slice(date_of_birth, 0, 10).join('')}`);
}

function hashString(str) {
    var hash = 0,
        i,
        chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function AvatarOne(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle cx="30.5" cy="64.5" r="12" fill={highlightColor3} stroke={backgroundColor} />
            <circle cx="45.5" cy="76.5" r="12" fill={highlightColor3} stroke={backgroundColor} />
            <circle cx="27.4998" cy="33.5001" r="12.5" transform="rotate(45 27.4998 33.5001)" fill={highlightColor2} />
            <path d="M17.9539 23.9541L37.0457 43.046" stroke={backgroundColor} strokeWidth="2" />
            <circle cx="59.5001" cy="23.9115" r="12.5" transform="rotate(-30 59.5001 23.9115)" fill="white" />
            <circle cx="77.5001" cy="55.0884" r="12.5" transform="rotate(-30 77.5001 55.0884)" fill={highlightColor1} />
            <rect x="49.4248" y="31.4606" width="25" height="15" transform="rotate(-30 49.4248 31.4606)" fill="white" />
            <rect
                x="57.9248"
                y="46.183"
                width="25"
                height="15"
                transform="rotate(-30 57.9248 46.183)"
                fill={highlightColor1}
            />
        </svg>
    );
}
function AvatarTwo(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle
                cx="49.572"
                cy="21.8935"
                r="12"
                transform="rotate(-10 49.572 21.8935)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="66.428"
                cy="31.1065"
                r="12"
                transform="rotate(-10 66.428 31.1065)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle cx="25.4999" cy="44.5001" r="12.5" transform="rotate(-60 25.4999 44.5001)" fill={highlightColor2} />
            <path d="M18.7498 56.1914L32.2498 32.8087" stroke={backgroundColor} strokeWidth="2" />
            <circle cx="40.5859" cy="75.6563" r="12.5" transform="rotate(-110 40.5859 75.6563)" fill="white" />
            <circle
                cx="74.4148"
                cy="63.3436"
                r="12.5"
                transform="rotate(-110 74.4148 63.3436)"
                fill={highlightColor1}
            />
            <rect
                x="46.2708"
                y="86.8894"
                width="25"
                height="15"
                transform="rotate(-110 46.2708 86.8894)"
                fill="white"
            />
            <rect
                x="62.2454"
                y="81.0751"
                width="25"
                height="15"
                transform="rotate(-110 62.2454 81.0751)"
                fill={highlightColor1}
            />
        </svg>
    );
}
function AvatarThree(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle cx="23.7116" cy="41.0701" r="12.5" transform="rotate(-130 23.7116 41.0701)" fill="white" />
            <circle
                cx="51.2892"
                cy="17.9298"
                r="12.5"
                transform="rotate(-130 51.2892 17.9298)"
                fill={highlightColor1}
            />
            <rect
                x="32.8955"
                y="49.6815"
                width="25"
                height="15"
                transform="rotate(-130 32.8955 49.6815)"
                fill="white"
            />
            <rect
                x="45.9182"
                y="38.7542"
                width="25"
                height="15"
                transform="rotate(-130 45.9182 38.7542)"
                fill={highlightColor1}
            />
            <circle cx="37.4995" cy="73.4999" r="12.5" transform="rotate(90 37.4995 73.4999)" fill={highlightColor2} />
            <path d="M37.4995 59.9999V86.9999" stroke={backgroundColor} strokeWidth="2" />
            <circle
                cx="76.6064"
                cy="44.072"
                r="12"
                transform="rotate(80 76.6064 44.072)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="67.3935"
                cy="60.9278"
                r="12"
                transform="rotate(80 67.3935 60.9278)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
        </svg>
    );
}
function AvatarFour(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle cx="28.9203" cy="32.1031" r="7.5" transform="rotate(20 28.9203 32.1031)" fill={highlightColor2} />
            <circle cx="22.08" cy="50.8969" r="7.5" transform="rotate(20 22.08 50.8969)" fill="white" />
            <rect
                x="22.0437"
                y="29.0681"
                width="15"
                height="10"
                transform="rotate(20 22.0437 29.0681)"
                fill={highlightColor2}
            />
            <rect x="17.9395" y="40.3444" width="15" height="10" transform="rotate(20 17.9395 40.3444)" fill="white" />
            <circle cx="53.5" cy="18.5" r="12" fill={highlightColor3} stroke={backgroundColor} />
            <circle cx="68.5" cy="30.5" r="12" fill={highlightColor3} stroke={backgroundColor} />
            <circle cx="74.4146" cy="63.3436" r="12.5" transform="rotate(70 74.4146 63.3436)" fill="white" />
            <circle cx="40.5857" cy="75.6563" r="12.5" transform="rotate(70 40.5857 75.6563)" fill={highlightColor1} />
            <rect x="68.7297" y="52.1105" width="25" height="15" transform="rotate(70 68.7297 52.1105)" fill="white" />
            <rect
                x="52.7551"
                y="57.9248"
                width="25"
                height="15"
                transform="rotate(70 52.7551 57.9248)"
                fill={highlightColor1}
            />
        </svg>
    );
}
function AvatarFive(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle cx="19.8397" cy="62.5" r="7.5" transform="rotate(-120 19.8397 62.5)" fill={highlightColor2} />
            <circle cx="37.16" cy="52.5" r="7.5" transform="rotate(-120 37.16 52.5)" fill="white" />
            <rect
                x="23.1565"
                y="69.2451"
                width="15"
                height="10"
                transform="rotate(-120 23.1565 69.2451)"
                fill={highlightColor2}
            />
            <rect
                x="33.5488"
                y="63.2452"
                width="15"
                height="10"
                transform="rotate(-120 33.5488 63.2452)"
                fill="white"
            />
            <circle
                cx="52.7968"
                cy="72.4956"
                r="12"
                transform="rotate(-70 52.7968 72.4956)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="69.2033"
                cy="62.5046"
                r="12"
                transform="rotate(-70 69.2033 62.5046)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle cx="70.4146" cy="33.6563" r="12.5" transform="rotate(110 70.4146 33.6563)" fill="white" />
            <circle cx="36.5858" cy="21.3436" r="12.5" transform="rotate(110 36.5858 21.3436)" fill={highlightColor1} />
            <rect x="73.2803" y="21.3971" width="25" height="15" transform="rotate(110 73.2803 21.3971)" fill="white" />
            <rect
                x="57.3057"
                y="15.5828"
                width="25"
                height="15"
                transform="rotate(110 57.3057 15.5828)"
                fill={highlightColor1}
            />
        </svg>
    );
}
function AvatarSix(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle cx="71.8971" cy="26.0798" r="7.5" transform="rotate(70 71.8971 26.0798)" fill={highlightColor2} />
            <circle cx="53.1034" cy="32.9202" r="7.5" transform="rotate(70 53.1034 32.9202)" fill="white" />
            <rect
                x="69.8018"
                y="18.8612"
                width="15"
                height="10"
                transform="rotate(70 69.8018 18.8612)"
                fill={highlightColor2}
            />
            <rect x="58.5256" y="22.9653" width="15" height="10" transform="rotate(70 58.5256 22.9653)" fill="white" />
            <circle
                cx="59.7968"
                cy="67.4956"
                r="12"
                transform="rotate(-70 59.7968 67.4956)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="76.2033"
                cy="57.5046"
                r="12"
                transform="rotate(-70 76.2033 57.5046)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle cx="26.5002" cy="30.5" r="12.5" fill="white" />
            <circle cx="26.5002" cy="66.5" r="12.5" fill={highlightColor1} />
            <rect x="14.0002" y="32" width="25" height="15" fill="white" />
            <rect x="14.0002" y="49" width="25" height="15" fill={highlightColor1} />
        </svg>
    );
}
function AvatarSeven(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle
                cx="59.3934"
                cy="70.9281"
                r="12"
                transform="rotate(-100 59.3934 70.9281)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="68.6063"
                cy="54.0723"
                r="12"
                transform="rotate(-100 68.6063 54.0723)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle cx="30.4999" cy="54.5001" r="12.5" transform="rotate(-60 30.4999 54.5001)" fill={highlightColor2} />
            <path d="M23.7498 66.1914L37.2498 42.8087" stroke={backgroundColor} strokeWidth="2" />
            <circle cx="37.8394" cy="20.5" r="7.5" transform="rotate(-60 37.8394 20.5)" fill={highlightColor1} />
            <circle cx="55.16" cy="30.5" r="7.5" transform="rotate(-60 55.16 30.5)" fill="white" />
            <rect
                x="33.6565"
                y="26.7452"
                width="15"
                height="10"
                transform="rotate(-60 33.6565 26.7452)"
                fill={highlightColor1}
            />
            <rect x="44.0488" y="32.7452" width="15" height="10" transform="rotate(-60 44.0488 32.7452)" fill="white" />
        </svg>
    );
}
function AvatarEight(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle
                cx="32.2245"
                cy="69.1021"
                r="12"
                transform="rotate(-130 32.2245 69.1021)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="31.7753"
                cy="49.898"
                r="12"
                transform="rotate(-130 31.7753 49.898)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="69.4996"
                cy="57.5001"
                r="12.5"
                transform="rotate(-120 69.4996 57.5001)"
                fill={highlightColor2}
            />
            <path d="M76.2495 69.1914L62.7495 45.8087" stroke={backgroundColor} strokeWidth="2" />
            <circle cx="59.4995" cy="23.5" r="7.5" transform="rotate(90 59.4995 23.5)" fill={highlightColor1} />
            <circle cx="39.4995" cy="23.5001" r="7.5" transform="rotate(90 39.4995 23.5001)" fill="white" />
            <rect
                x="59.9995"
                y="16.0001"
                width="15"
                height="10"
                transform="rotate(90 59.9995 16.0001)"
                fill={highlightColor1}
            />
            <rect x="47.9995" y="16.0001" width="15" height="10" transform="rotate(90 47.9995 16.0001)" fill="white" />
        </svg>
    );
}
function AvatarNine(props) {
    const { size, backgroundColor, highlightColor1, highlightColor2, highlightColor3 } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill={backgroundColor} />
            <circle
                cx="33.7968"
                cy="34.4956"
                r="12"
                transform="rotate(-70 33.7968 34.4956)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle
                cx="50.2033"
                cy="24.5046"
                r="12"
                transform="rotate(-70 50.2033 24.5046)"
                fill={highlightColor3}
                stroke={backgroundColor}
            />
            <circle cx="39.4999" cy="67.5" r="12.5" transform="rotate(-70 39.4999 67.5)" fill={highlightColor2} />
            <path d="M34.8826 80.1859L44.1171 54.8142" stroke={backgroundColor} strokeWidth="2" />
            <circle cx="63.0716" cy="45.8398" r="7.5" transform="rotate(-40 63.0716 45.8398)" fill={highlightColor1} />
            <circle cx="75.9273" cy="61.1606" r="7.5" transform="rotate(-40 75.9273 61.1606)" fill="white" />
            <rect
                x="57.0049"
                y="50.2776"
                width="15"
                height="10"
                transform="rotate(-40 57.0049 50.2776)"
                fill={highlightColor1}
            />
            <rect x="64.7183" y="59.4701" width="15" height="10" transform="rotate(-40 64.7183 59.4701)" fill="white" />
        </svg>
    );
}

const avatarSkeletons = [
    AvatarOne,
    AvatarTwo,
    AvatarThree,
    AvatarFour,
    AvatarFive,
    AvatarSix,
    AvatarSeven,
    AvatarEight,
    AvatarNine,
];
const colors = ['#37a1db', '#53bb7c', '#f35937', '#ffd044', '#005679', '#9a7ac8', '#ff961b', '#86DEFB'];

function getColors(seed) {
    const seedIndex = Math.abs(hashString(seed));
    const c = _.clone(colors);
    return _.map(_.times(4), () => _.first(c.splice(seedIndex % _.size(c), 1)));
}
