import React from 'react';

import EmbeddedContent from '../EmbeddedContent';
import CourseContentUrls from 'constants/CourseContentUrls';

const GDocsFile = ({ file }) => {
    const { prefixes, suffixes } = CourseContentUrls;

    return <EmbeddedContent id={`file-${file.id}`} src={`${prefixes.gdocs.file}${file.id}${suffixes.gdocs.file}`} />;
};

export default GDocsFile;
