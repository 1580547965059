import React from 'react';
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/styles';
import { Title } from './Title';
import { appPublishingSteps, buildStatusTypes } from '../Constants.js';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    stepWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: 'solid 1px #d8d8d8',
        borderRadius: '4px',
    },
}));

const getAppPublishingSteps = (buildStatus) => {
    const commonSteps = [
        appPublishingSteps.notReady,
        appPublishingSteps.queuedForAutomator,
        appPublishingSteps.pickedByAutomator,
    ];

    const buildFailureSteps = [...commonSteps, appPublishingSteps.buildFailed];

    const appRejectedOnStoreSteps = [
        ...commonSteps,
        appPublishingSteps.uploadedToStore,
        appPublishingSteps.rejectedOnStore,
    ];

    const appRejectedByCsSteps = [
        ...commonSteps,
        appPublishingSteps.uploadedToStore,
        appPublishingSteps.approvedOnStore,
        appPublishingSteps.rejectedByCs,
    ];

    const stepsWithoutError = [
        ...commonSteps,
        appPublishingSteps.uploadedToStore,
        appPublishingSteps.approvedOnStore,
        appPublishingSteps.approvedByCs,
    ];

    switch (buildStatus) {
        case buildStatusTypes.buildFailed:
        case buildStatusTypes.updateFailed:
            return buildFailureSteps;
        case buildStatusTypes.buildRejected:
        case buildStatusTypes.updateRejected:
            return appRejectedOnStoreSteps;
        // TODO: revisit appRejectedByCsSteps
        case buildStatusTypes.actionNeeded:
            return appRejectedByCsSteps;
        default:
            return stepsWithoutError;
    }
};

const buildStatusToStepsMapping = {
    [buildStatusTypes.readyForBuild]: appPublishingSteps.queuedForAutomator,
    [buildStatusTypes.readyForUpdate]: appPublishingSteps.queuedForAutomator,

    [buildStatusTypes.building]: appPublishingSteps.pickedByAutomator,
    [buildStatusTypes.updating]: appPublishingSteps.pickedByAutomator,

    [buildStatusTypes.buildSuccess]: appPublishingSteps.uploadedToStore,
    [buildStatusTypes.updateSuccess]: appPublishingSteps.uploadedToStore,

    [buildStatusTypes.buildFailed]: appPublishingSteps.buildFailed,
    [buildStatusTypes.updateFailed]: appPublishingSteps.buildFailed,

    [buildStatusTypes.buildRejected]: appPublishingSteps.rejectedOnStore,
    [buildStatusTypes.updateRejected]: appPublishingSteps.rejectedOnStore,

    [buildStatusTypes.buildApproved]: appPublishingSteps.approvedOnStore,
    [buildStatusTypes.updateApproved]: appPublishingSteps.approvedOnStore,

    [buildStatusTypes.complete]: appPublishingSteps.approvedByCs,
    [buildStatusTypes.actionNeeded]: appPublishingSteps.rejectedByCs,
};

const erroneousSteps = [
    appPublishingSteps.buildFailed,
    appPublishingSteps.rejectedOnStore,
    appPublishingSteps.rejectedByCs,
];

export const BuildSteps = (props) => {
    const { app, appType } = props;
    const classes = useStyles();
    const buildStatus = _.get(app, `appRelease.value[${appType}][0].buildStatus`, '');
    const steps = getAppPublishingSteps(buildStatus);
    const activeStep = _.indexOf(steps, buildStatusToStepsMapping[buildStatus] || appPublishingSteps.notReady);
    const title = `Latest Build - ${_.startCase(appType)}`;
    const appRelease = _.get(app, `appRelease.value[${appType}][0]`);
    const subtitle = `${_.get(appRelease, 'versionName')}(${_.get(appRelease, 'versionCode')})`;
    const stepperPadding = steps.length < 6 ? '18%' : undefined;

    return (
        <div className={classes.root} style={props.style}>
            <div className={classes.stepWrapper}>
                <Title title={title} subtitle={subtitle} className={classes.title} />
                <Stepper activeStep={activeStep} alternativeLabel style={{ paddingRight: stepperPadding }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel error={_.indexOf(erroneousSteps, label) !== -1}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
};
