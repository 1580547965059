import patientDataTypes from './type';
import patientDataService from './service';
import { userService } from '../../Inbox/User/index';
import { PatientUpdateEventType } from '../../../constants/Patient';
import { displayToast } from 'redux/actionCreators/Snackbar';
import config from '../../../config';
import _ from 'lodash';

export function resetPatientData() {
    return {
        type: patientDataTypes.RESET_PATIENT_DATA,
    };
}

// fieldToQuery supports ["name", "date_of_birth", "birthday"]
export function fetchPatientData(
    query,
    sort,
    filters,
    startIndex,
    endIndex,
    { fieldToQuery = 'name', listToSearch = 'patients' } = {}
) {
    return async (dispatch, getState) => {
        const state = getState().patientData;
        const newLoad = state.query !== query || state.sort !== sort || !_.isEqual(state.filters, filters);

        if (_.has(state.pendingRequests, `${startIndex}-${endIndex}`)) {
            // if we already requested this data, then short circuit
            return Promise.resolve();
        }

        // store a startedAt millis timestamp so we can check this on success and compare it to the
        // patientIndexUpdatedAt timestamp and if it is larger then we can triger a reload since the index has changed
        // be we short-circuited those subsequent requests in the if statement above
        const startedAt = new Date().getTime();

        dispatch({
            type: patientDataTypes.PATIENT_DATA_REQUEST,
            payload: { startedAt, startIndex, endIndex, query, sort, filters, newLoad, listToSearch },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await patientDataService.queryPatients(
                pharmacyId,
                locationId,
                {
                    queryType: fieldToQuery,
                    query,
                    listToSearch,
                },
                sort,
                filters,
                startIndex,
                endIndex
            );
            dispatch({
                type: patientDataTypes.PATIENT_DATA_SUCCESS,
                payload: { ...response, startIndex, endIndex, query, sort, filters, listToSearch, startedAt },
            });
        } catch (error) {
            dispatch(displayToast({ text: 'An error occured while fetching patients data.', type: 'error' }));
            dispatch({
                type: patientDataTypes.PATIENT_DATA_FAILURE,
                payload: { error, startIndex, endIndex, query, sort, filters, newLoad, listToSearch, startedAt },
            });
        }
    };
}

export function fetchPatientInboxData(inboxUserId) {
    return async (dispatch, getState) => {
        dispatch({
            type: patientDataTypes.PATIENT_INBOX_DATA_REQUEST,
            payload: { inboxUserId },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await patientDataService.getPatientInboxUser(pharmacyId, locationId, inboxUserId);
            dispatch({
                type: patientDataTypes.PATIENT_INBOX_DATA_SUCCESS,
                payload: { ...response, inboxUserId },
            });
        } catch (error) {
            dispatch({
                type: patientDataTypes.PATIENT_INBOX_DATA_FAILURE,
                payload: { error },
            });
        }
    };
}

export function getExactDuplicateNewPatients(newPatients) {
    return async (dispatch, getState) => {
        const { patientData } = getState();
        // short circuit if we are in the middle of this request already
        if (_.get(patientData, 'duplicateNewPatients.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_REQUEST,
            payload: { newPatients },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await patientDataService.getExactDuplicateNewPatients(pharmacyId, locationId, newPatients);
            dispatch({
                type: patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_SUCCESS,
                payload: { newPatients, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_FAILURE,
                payload: { newPatients, error },
            });
        }
    };
}

export function searchPatientList(patients) {
    return async (dispatch, getState) => {
        const { patientData } = getState();
        // short circuit if we are in the middle of this request already
        if (_.get(patientData, 'searchPatientList.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: patientDataTypes.SEARCH_PATIENT_LIST_REQUEST,
            payload: { patients },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await patientDataService.searchPatientList(pharmacyId, locationId, patients);
            dispatch({
                type: patientDataTypes.SEARCH_PATIENT_LIST_SUCCESS,
                payload: { patients, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: patientDataTypes.SEARCH_PATIENT_LIST_FAILURE,
                payload: { patients, error },
            });
        }
    };
}

export function loadPatientsByInboxUserIds(inbox_user_ids) {
    return async (dispatch, getState) => {
        const { patientData } = getState();
        // short circuit if we are in the middle of this request already
        if (_.get(patientData, 'searchPatientList.loading')) {
            return Promise.resolve();
        }

        dispatch({
            type: patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_REQUEST,
            payload: { inbox_user_ids },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await patientDataService.loadPatientsByInboxUserIds(
                pharmacyId,
                locationId,
                inbox_user_ids
            );
            dispatch({
                type: patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_SUCCESS,
                payload: { inbox_user_ids, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_FAILURE,
                payload: { inbox_user_ids, error },
            });
        }
    };
}

export function bulkPhoneTypeLookup(inboxUserIds) {
    return async (dispatch, getState) => {
        // if any of these is pending then just short circuit
        if (getState().patientData.bulkPhoneTypeLookupLoading) {
            return Promise.resolve();
        }

        dispatch({
            type: patientDataTypes.BULK_PHONE_TYPE_LOOKUP_REQUEST,
            payload: { inboxUserIds },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        const phoneTypes = {};
        const errors = {};
        // Divide the inbox user ids into chunks so we will only send a maximum number of simultaneous requests
        const CHUNK_SIZE = 10;
        // do a for..of here to simplify awaiting
        const chunks = _.chunk(inboxUserIds, CHUNK_SIZE);
        for (const chunk of chunks) {
            // use Promise.allSettled to get both success and failures...
            const results = await Promise.allSettled(
                _.map(chunk, (userId) => userService.phoneTypeLookup({ pharmacyId, locationId, userId }))
            );
            _.each(results, ({ status, value, reason }, i) => {
                const inboxUserId = chunk[i];
                if (status === 'fulfilled') {
                    phoneTypes[inboxUserId] = value.phone_type;
                } else if (status === 'rejected') {
                    errors[inboxUserId] = reason;
                }
            });
        }
        dispatch({
            type: patientDataTypes.BULK_PHONE_TYPE_LOOKUP_COMPLETE,
            payload: { inboxUserIds, phoneTypes, errors },
        });

        return Promise.resolve({ phoneTypes, errors });
    };
}

export function setFocusedPatient(focusedPatient) {
    return {
        type: patientDataTypes.FOCUS_PATIENT,
        payload: { focusedPatient },
    };
}

export function updatePatientData(patientData) {
    return {
        type: patientDataTypes.UPDATE_PATIENT_DATA,
        payload: { patientData },
    };
}

export function setPatientIndexUpdated() {
    return {
        type: patientDataTypes.PATIENT_INDEX_UPDATED,
        payload: { at: new Date().getTime() },
    };
}

export function handlePatientUpdate(patientUpdateData) {
    return async (dispatch) => {
        const patientUpdateTriggerEvent = _.get(patientUpdateData, 'trigger_event_for_update');
        const triggerEvents = [
            PatientUpdateEventType.create_user,
            PatientUpdateEventType.create_message,
            PatientUpdateEventType.edit_user,
            PatientUpdateEventType.verify_user,
            PatientUpdateEventType.update_message_read_status,
            PatientUpdateEventType.update_user_phone_type,
        ];
        if (_.includes(triggerEvents, PatientUpdateEventType[patientUpdateTriggerEvent])) {
            dispatch(setPatientIndexUpdated());
        }
    };
}
