import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logUtil } from '../../../utils/log';

const getInboxLocationOnboarding = ({ pharmacyId, locationId }) => {
    return restActionCreators
        .get(`${config.apiweb_url}${api.INBOX_BASE}/onboarding/pharmacies/${pharmacyId}/locations/${locationId}`)
        .then(({ success, ...responseData }) => {
            if (success) {
                return responseData;
            } else {
                return Promise.reject({ message: 'An unknown error has occurred' });
            }
        })
        .catch((error) => {
            logUtil.logHttpError(`An error occurred on getInboxLocationOnboarding`, error);
            return Promise.reject(error);
        });
};

export const onboardingService = {
    getInboxLocationOnboarding,
};
