import './FullPepUpgrade.sass';
import numeral from 'numeral';
import React, { useState } from 'react';
import classnames from 'classnames';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { pharmacyAction } from 'redux/Pharmacy';
import { connect } from 'react-redux';
import _ from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function FullPepUpgrade({ asDialog = false, onClose }) {
    const props = arguments[0];

    if (asDialog) {
        return (
            <Dialog open maxWidth="lg" onClose={onClose}>
                <FullPepUpgradeContent {...props} />
            </Dialog>
        );
    }

    return <FullPepUpgradeContent {...props} />;
}

function FullPepUpgradeContent({
    upgradeToFullPep,
    clearUpgradeToFullPepError,
    upgradeError,
    justUpgraded,
    upgrading,
    hasUpgraded,
    isFreeTrial,
}) {
    const [selectedPrice, setSelectedPrice] = useState(169);
    const [showConfirm, setShowConfirm] = useState(false);

    if (hasUpgraded) {
        return <AlreadyUpgradedContent justUpgraded={justUpgraded} />;
    }

    return (
        <div className="full-pep-upgrade">
            <div className="heading">
                <div className="text">
                    {isFreeTrial ? (
                        <>
                            <div className="main-line">Upgrade to Patient Engagement Platform (PEP)</div>
                            <div className="sub-line">
                                An upgrade to Digital Pharmacist's Patient Engagement Platform is required for full
                                access to all product functionality.
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="main-line">Upgrade to Patient Engagement Platform (PEP)</div>
                            <div className="sub-line">
                                This functionality is only available by upgrading to Digital Pharmacist's Patient
                                Engagement Platform.
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="highlights-and-pricing">
                <div className="highlights">
                    <Highlight image="Secure-and-HIPAA-compliant.png" title="SECURE & HIPAA COMPLIANT" />
                    <Highlight image="Bulk_Targeted.png" title="BULK & TARGETED MESSAGING" />
                    <Highlight image="2wayMsgMulti.png" title="2-WAY MESSAGING & MULTIMEDIA SHARING" />
                    <Highlight image="AccessManagement.png" title="MULTI-PLATFORM ACCESS & MANAGEMENT" />
                    <Highlight image="Clinical-Opportunities.png" title="APPOINTMENTS & SCHEDULING" />
                </div>

                <div className="pricing">
                    <div className="price-options">
                        <PriceOption
                            amount={169}
                            billed="monthly"
                            selected={selectedPrice === 169}
                            onClick={() => setSelectedPrice(169)}
                        />
                    </div>

                    <div className="basic-messaging-disclaimer">
                        <NewReleasesIcon />
                        <div className="text">
                            If you’re already paying for Basic Messaging, this will be your total price and you’ll only
                            pay the difference.
                        </div>
                    </div>

                    <div className="buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                                setShowConfirm(true);
                            }}
                        >
                            Upgrade PEP
                        </Button>
                    </div>
                </div>
            </div>

            {!showConfirm || !_.isNil(upgradeError) ? null : (
                <Dialog maxWidth="md" open onClose={() => setShowConfirm(false)} className="confirm-upgrade-dialog">
                    <DialogTitle>Confirm Your Upgrade Of Patient Engagement Platform</DialogTitle>

                    <DialogContent>
                        <img className="pep-image" alt="" src={require('./PEP.png')} />
                        <div className="text">
                            <div>
                                You have chosen to have your credit card billed{' '}
                                <strong>
                                    ${numeral(selectedPrice * (selectedPrice === 149 ? 12 : 1)).format('0,0')}&nbsp;
                                    {selectedPrice === 149 ? 'annually' : 'monthly'}
                                </strong>{' '}
                                and will include a 12 month commitment.
                            </div>
                            <div>
                                If we do not have a credit card on file, a member of the Digital Pharmacist support team
                                will contact you.
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="upgrade-button"
                            disabled={upgrading}
                            onClick={() => {
                                upgradeToFullPep(selectedPrice === 149 ? 'annual' : 'monthly');
                            }}
                        >
                            {upgrading ? (
                                <div className="upgrading-label">
                                    <CircularProgress size={18} />
                                    &nbsp;Upgrading...
                                </div>
                            ) : (
                                'Confirm Upgrade'
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {_.isNil(upgradeError) ? null : (
                <Dialog
                    maxWidth="md"
                    open
                    onClose={() => {
                        setShowConfirm(false);
                        clearUpgradeToFullPepError();
                    }}
                    className="confirm-upgrade-dialog"
                >
                    <DialogTitle>An error occurred upgrading Patient Engagement Platform</DialogTitle>
                    <DialogContent>
                        <ErrorIcon className="error-icon" />
                        <div className="text">
                            <div>
                                We were unable to upgrade your Patient Engagement Platform at this time. You may try
                                again or contact Digital Pharmacist support if this issue continues.
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                window.Intercom('showNewMessage', `I am unable to upgrade my PEP free trial`);
                            }}
                        >
                            Contact Support
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={upgrading}
                            onClick={() => {
                                upgradeToFullPep(selectedPrice === 149 ? 'annual' : 'monthly');
                            }}
                        >
                            {upgrading ? 'Upgrading...' : 'Try Again'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

function AlreadyUpgradedContent({ justUpgraded }) {
    const [showConfirm, setShowConfirm] = useState(justUpgraded);

    return (
        <div className="full-pep-upgrade">
            <div className="heading">
                <div className="text">
                    <div className="main-line">You Already Have Full Patient Engagement Platform (PEP)</div>
                    <div className="sub-line">
                        You have already been upgraded to the full version of Patient Engagement Platform by Digital
                        Pharmacist.
                    </div>
                </div>
            </div>

            <div className="highlights-and-pricing">
                <div className="thank-you-message">
                    <div className="text">
                        <div className="main-line">Thanks for using Patient Engagement Platform!</div>
                        <div className="sub-line">The most powerful way to connect with patients</div>
                    </div>
                </div>
            </div>

            {!showConfirm ? null : (
                <Dialog maxWidth="md" open onClose={() => setShowConfirm(false)} className="confirm-upgrade-dialog">
                    <DialogTitle>Thanks for upgrading your Patient Engagement Platform</DialogTitle>

                    <DialogContent>
                        <CheckCircleIcon className="check-icon" />
                        <div className="text">
                            <div>You have been successfully upgraded to the full Patient Engagement Platform.</div>
                            <div>
                                If we do not have a credit card on file, a member of the Digital Pharmacist support team
                                will contact you.
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowConfirm(false);
                            }}
                        >
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

function Highlight({ image, title }) {
    return (
        <div className="highlight">
            <img className="highlight-image" alt="" src={require(`./${image}`)} />

            <span className="title">{title}</span>
        </div>
    );
}

function PriceOption({ amount, billed, selected, onClick }) {
    return (
        <div className={classnames({ 'price-option': true, selected })} onClick={selected ? null : onClick}>
            <div className="amount">
                <span className="dollars">${amount}</span> / month
            </div>
            <div className="billing">
                Billed <strong>{billed}</strong> with a 12 month commitment
            </div>
            <div className="credit-card-amount">
                Your credit card will be billed ${numeral(amount * (billed === 'annually' ? 12 : 1)).format('0,0')}{' '}
                {billed}
            </div>

            <div className="mini-highlights">
                <MiniHighlight title="Secure & HIPAA Compliant" />
                <MiniHighlight title="Bulk & Targeted Messaging" />
                <MiniHighlight title="2-Way Messaging & Multimedia Sharing" />
                <MiniHighlight title="Appointments & Scheduling" />
            </div>
        </div>
    );
}

function MiniHighlight({ title }) {
    return (
        <div className="mini-highlight">
            <CheckBoxIcon /> {title}
        </div>
    );
}

const mapStateToProps = (state) => ({
    hasUpgraded: getHasUpgraded(state),
    justUpgraded: !_.isNil(_.get(state, 'pharmacy.upgradeToFullPep.response')),
    upgradeError: _.get(state, 'pharmacy.upgradeToFullPep.error'),
    upgrading: _.get(state, 'pharmacy.upgradeToFullPep.loading', false),
    isFreeTrial: _.get(state, 'launchDarkly.npeActivePepFreeTrial', false),
});

const mapDispatchToProps = (dispatch) => ({
    upgradeToFullPep: (pricePoint) => dispatch(pharmacyAction.upgradeToFullPep(pricePoint)),
    clearUpgradeToFullPepError: () => dispatch(pharmacyAction.clearUpgradeToFullPepError()),
});

function getHasUpgraded(state) {
    const locationId = _.get(state, 'auth.selectedLocation.id');

    const location = _.find(_.get(state, 'pharmacy.pharmacy.location'), (p) => p.id === locationId);

    return (
        !_.isNil(_.get(state, 'pharmacy.upgradeToFullPep.response')) ||
        _.get(location, 'service.Inbox.FullPepEnabled', false)
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FullPepUpgrade);
