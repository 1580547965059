import { homeTypes } from './type';
import { homeActions } from './action';

export const homeMiddleware = (store) => (next) => (action) => {
    if (action.type === homeTypes.SEND_QUICK_REPLY_SUCCESS) {
        const {
            auth: { isAuthenticated },
        } = store.getState();

        if (isAuthenticated) {
            store.dispatch(homeActions.getUnreadMessagesCount());
        }
    }

    next(action);
};
