import React from 'react';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { FooterText, ReviewCard } from './ReviewCommon';
import moment from 'moment';

const barOptions = {
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    // Converts float labels to int (ie 5.0 -> 5)
                    callback: function (value) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    },
                },
            },
        ],
    },
    legend: {
        display: false,
    },
};

function ReviewGraph({ months, total, loading }) {
    const barChartData = [];
    const labels = _.map(months, (value, month) => {
        barChartData.push(value);
        return moment(month).format("MMM 'YY");
    });

    const chartData = {
        labels,
        datasets: [
            {
                backgroundColor: '#CCF0EE',
                borderColor: '#33b0ab',
                borderWidth: 2,
                hoverBackgroundColor: '#829cb2',
                hoverBorderColor: '#4b667d',
                data: barChartData,
            },
        ],
    };

    return (
        <ReviewCard title="Review Graph" footer={FooterText('Total Reviews', total)} loadingContent={loading}>
            <div className="review-graph-content">
                {barChartData.length > 0 ? (
                    <Bar data={chartData} options={barOptions} />
                ) : (
                    <span>No graph available</span>
                )}
            </div>
        </ReviewCard>
    );
}

ReviewGraph.propTypes = {
    months: PropTypes.shape({}).isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};

export default ReviewGraph;
