import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

export const SubscriberNotice = () => {
    const url =
        'https://docs.google.com/document/d/e/2PACX-1vRBElCszSMInTlM37iiy4za7OBbvo14gf1c52nCABDDSDttcqiA4_3Zj08ghlEiRqdKcK_koyHIc2gY/pub?embedded=false';
    const [showLoader, setLoader] = useState(true);

    const onLoad = () => {
        setLoader(false);
    };

    return (
        <div className="subscriber-notice-container page-without-sidebar-container">
            <div className="content">
                {showLoader && <CircularProgress id="notice-loader" />}
                <iframe
                    src={url}
                    frameBorder="0"
                    onLoad={onLoad}
                    style={{ display: showLoader ? 'none' : 'block' }}
                    title="Subscriber Notice"
                />
            </div>
        </div>
    );
};

export default SubscriberNotice;
