import React, { Component } from 'react';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { messageAction } from 'redux/Inbox/Message';
import Message from './Message';

class MessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastRead: null,
            lastSent: null,
        };
        this.listRef = React.createRef();
    }

    componentDidMount = () => {
        const { active, getMessages } = this.props;
        if (active) {
            getMessages();
        }
        this.scrollToBottom();
    };

    componentDidUpdate = (prevProps) => {
        const { message, active, getMessages } = this.props;
        if (active && !prevProps.active) {
            getMessages();
        }

        if (message.messages && message.messages.length > 0 && prevProps.message.messages) {
            if (message.messages.length !== prevProps.message.messages.length) {
                this.scrollToBottom();

                // Calculate last pharmacy read message & last pharmacy delivered message
                this.calcLastStatus();

                if (active) {
                    this.updateMessageReadStatus();
                }
            }
        }
    };

    calcLastStatus = () => {
        const { message, contact } = this.props;

        let lastSent = null;
        let lastRead = null;

        // Iterate backwards through messages for early exit
        for (let index = message.messages.length - 1; index >= 0; index--) {
            const currentMessage = message.messages[index];
            if (currentMessage.inbox_user_id !== contact.inbox_user_id) {
                // Message sent by pharmacy
                if (currentMessage.viewed) {
                    lastRead = currentMessage.inbox_message_id;
                    // Break out of for loop if most recent read message is found
                    // Assumption is that if there was a more recent 'Sent' message
                    // It would already have been assigned (we don't care about 'Sent' for those before the most recent 'Read')
                    break;
                } else if (!lastSent) {
                    // Since iterating backwards, find first instance of sent and don't check after
                    lastSent = currentMessage.inbox_message_id;
                }
            }
        }

        this.setState({
            lastSent,
            lastRead,
        });
    };

    scrollToBottom = () => {
        const messageList = this.listRef.current;
        const scrollHeight = messageList.scrollHeight;
        const height = messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        // Using timeout as hack to get around the async nature of rendering the conditional timestamps
        let that = this;
        setTimeout(function () {
            if (that.listRef.current) {
                that.listRef.current.scrollTop = maxScrollTop > 0 ? scrollHeight : 0;
            }
        });
    };

    updateMessageReadStatus = () => {
        const { message, updateMessageReadStatus } = this.props;
        const messageList = message.messages;
        const lastUnreadPatientMessage = _.last(
            _.filter(messageList, (msg) => {
                return msg.user_type === 'patient' && !msg.viewed;
            })
        );

        if (lastUnreadPatientMessage) {
            updateMessageReadStatus(lastUnreadPatientMessage.inbox_message_id);
        }
    };

    render() {
        const { lastSent, lastRead } = this.state;
        const { contact, activeLocationCountryCode } = this.props;
        const messageStore = this.props.message;
        const messageComponents = messageStore.messages.map((message, index, messages) => (
            <Message
                key={message.inbox_message_id}
                message={message}
                contact={contact}
                previousMessage={index > 0 ? messages[index - 1] : null}
                lastSent={message.inbox_message_id === lastSent}
                lastRead={message.inbox_message_id === lastRead}
                last={index + 1 === messages.length}
                activeLocationCountryCode={activeLocationCountryCode}
            />
        ));

        return (
            <div className="conversation-message-list" ref={this.listRef}>
                {messageComponents.length > 0 && messageComponents}
                {messageStore.loading && <CircularProgress className="conversation-message-list__progress" />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { inboxMessage } = state;
    return {
        message: inboxMessage,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    getMessages: () => dispatch(messageAction.getMessages()),
    updateMessageReadStatus: (messageId) => dispatch(messageAction.updateMessageReadStatus(messageId)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(MessageList);
