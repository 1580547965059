import React, { PureComponent } from 'react';
import { Cell, Grid, Card, CardText, Divider, Button as RMDButton, CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { TextField, OutlinedInput } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { CardHeader } from 'components/Common/index';
import { formatPhoneNo, toDigits } from 'utils/helper';
import { PhoneNumberMask } from 'utils/mask';
import {
    updatePharmacyBasicDetails,
    updatePharmacyAttribute,
    getAddressByZip,
    getLocationIdentifier,
    updatePharmacyAddress,
    updateLocationIdentifier,
    addPharmacyAttribute,
    getAttributeTypeId,
} from 'redux/actionCreators/Settings/GeneralSettings/PharmacyDetails';
import goBackService from 'utils/goBackService';
import { AbilityContext, userActions, restrictedResources } from 'casl';

const EditPharmacyDetails = (props) => {
    const {
        pharmacyName,
        ncpdpId,
        npi,
        address,
        phone,
        fax,
        locationIdentifier,
        isTextBoxDisable,
        handleInputChange,
        handleAddressChange,
        isSaveDisable,
        isInternalUser,
        onCancel,
        onSave,
        onZipBlur,
        onZipFocus,
        updatingPharmacyDetails,
    } = props;

    return (
        <div className="edit-pharmacy">
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="pharmacyName"
                    label="Pharmacy Name"
                    value={pharmacyName || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isTextBoxDisable}
                    autoComplete="off"
                    className="margin-bottom-28"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="ncpdpId"
                    label="NCPDP ID"
                    value={ncpdpId || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isTextBoxDisable}
                    autoComplete="off"
                    className="margin-bottom-28"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="npi"
                    label="NPI"
                    value={npi || ''}
                    variant="outlined"
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isTextBoxDisable}
                    autoComplete="off"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <Divider className="divider" />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="street1"
                    label="Address"
                    value={address.street1 || ''}
                    variant="outlined"
                    onChange={handleAddressChange}
                    fullWidth
                    disabled={isTextBoxDisable}
                    className="margin-bottom-28"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="street2"
                    label="Address 2"
                    value={address.street2 || ''}
                    variant="outlined"
                    onChange={handleAddressChange}
                    helperText="Optional"
                    fullWidth
                    disabled={isTextBoxDisable}
                    className="margin-bottom-28"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="zip"
                    label="Zip"
                    value={address.zip || ''}
                    variant="outlined"
                    onChange={handleAddressChange}
                    fullWidth
                    disabled={isTextBoxDisable}
                    onBlur={onZipBlur}
                    onFocus={onZipFocus}
                />
            </Cell>
            <div className="grid-container">
                <Cell size={6} tabletSize={3} phoneSize={2}>
                    {address.postCodeLocalities.length === 0 && (
                        <TextField
                            name="city"
                            label="City"
                            value={address.city || ''}
                            variant="outlined"
                            onChange={handleAddressChange}
                            fullWidth
                            disabled={address.isCityDisable || isTextBoxDisable}
                        />
                    )}
                    {address.postCodeLocalities.length > 0 && (
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>City</InputLabel>
                            <Select
                                id="city-select-outlined"
                                name="city"
                                autoWidth
                                value={address.city}
                                onChange={handleAddressChange}
                                disabled={isTextBoxDisable}
                                input={<OutlinedInput fullWidth labelWidth={25} name="city" id="outlined-city" />}
                            >
                                {address.postCodeLocalities.map((city) => (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Cell>
                <Cell size={6} tabletSize={3} phoneSize={2}>
                    <TextField
                        name="state"
                        label="State"
                        value={address.state || ''}
                        variant="outlined"
                        onChange={handleAddressChange}
                        fullWidth
                        disabled={address.isStateDisable || isTextBoxDisable}
                    />
                </Cell>
            </div>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <Divider className="divider" />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="phone"
                    label="Phone Number"
                    value={phone || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberMask,
                    }}
                    fullWidth
                    disabled={isTextBoxDisable}
                    className="margin-bottom-28"
                />
            </Cell>
            <Cell size={12} tabletSize={8} phoneSize={4}>
                <TextField
                    name="fax"
                    label="Fax"
                    value={fax || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberMask,
                    }}
                    fullWidth
                    disabled={isTextBoxDisable}
                    className="margin-bottom-28"
                />
            </Cell>
            {isInternalUser && (
                <>
                    <Cell size={12} tabletSize={8} phoneSize={4}>
                        <Divider className="divider" />
                    </Cell>
                    <Cell size={12} tabletSize={8} phoneSize={4}>
                        <TextField
                            name="locationIdentifier"
                            label="Location Identifier"
                            value={locationIdentifier || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                            helperText="Internal Users Only"
                            fullWidth
                            disabled={isTextBoxDisable}
                            className="margin-bottom-28"
                        />
                    </Cell>
                </>
            )}
            <div className="flex-right">
                <RMDButton className="cancel-btn" primary flat onClick={onCancel}>
                    Cancel
                </RMDButton>
                <RMDButton id="save-btn" secondary raised onClick={onSave} disabled={isSaveDisable}>
                    {updatingPharmacyDetails ? 'Saving..' : 'Save'}
                </RMDButton>
            </div>
        </div>
    );
};

export class PharmacyDetails extends PureComponent {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.initialState = {};
        this.zipOnFocus = null;
        this.pharmacyAttributes = {};
        this.state = {
            pharmacyName: '',
            ncpdpId: '',
            npi: '',
            address: {
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                isCityDisable: true,
                isStateDisable: true,
                postCodeLocalities: [],
            },
            phone: '',
            fax: '',
            latitude: '',
            longitude: '',
            locationIdentifier: '',
            editDetails: false,
            isSaveDisable: true,
            isTextBoxDisable: false,
        };
    }

    componentDidMount() {
        this.getPharmacyInfo();
    }

    componentDidUpdate({
        selectedLocation: oldSelectedLocation,
        pharmacy: oldPharmacy,
        pharmacyDetails: oldPharmacyDetails,
    }) {
        const { selectedLocation, pharmacy, pharmacyDetails, displayToast } = this.props;
        if (oldSelectedLocation.id !== selectedLocation.id || oldPharmacy.id !== pharmacy.id) {
            this.getPharmacyInfo();
        } else if (oldPharmacy.lastUpdated !== pharmacy.lastUpdated) {
            this.getPharmacyInfo();
            this.setState({ isTextBoxDisable: false, isSaveDisable: true });
        }

        if (!_.isEqual(oldPharmacyDetails.addressByZip, pharmacyDetails.addressByZip)) {
            const { address } = this.state;
            const { addressByZip } = pharmacyDetails;

            if (addressByZip.notFound) {
                displayToast({
                    text: `Could not find the address information with that zipcode, please enter manually!`,
                    type: 'error',
                });
                this.setState(
                    {
                        address: {
                            ...address,
                            city: '',
                            state: '',
                            isCityDisable: false,
                            isStateDisable: false,
                        },
                    },
                    () => {
                        this.checkIfSaveDisable();
                    }
                );
                return;
            }

            if (!_.isEmpty(addressByZip.city)) {
                address.city = addressByZip.city;
                address.isCityDisable = true;
            } else {
                address.isCityDisable = false;
                address.city = '';
            }
            if (!_.isEmpty(addressByZip.state)) {
                address.state = addressByZip.state;
                address.isStateDisable = true;
            } else {
                address.isStateDisable = false;
                address.state = '';
            }
            if (addressByZip.postCodeLocalities.length > 0) {
                address.postCodeLocalities = addressByZip.postCodeLocalities;
            } else {
                address.postCodeLocalities = [];
            }

            const latitude = addressByZip.latitude || '';
            const longitude = addressByZip.longitude || '';

            this.setState({ address: { ...address }, latitude, longitude }, () => this.checkIfSaveDisable());
        }
    }

    getPharmacyInfo() {
        const { pharmacy, selectedLocation } = this.props;
        const locationDetails = _.find(pharmacy.location, (l) => l.id === selectedLocation.id);
        if (_.size(locationDetails) > 0) {
            const { address, attr, displayName, phone, fax, latitude, longitude } = locationDetails;

            this.setState(
                {
                    pharmacyName: displayName,
                    ncpdpId: attr.ncpdpId ? attr.ncpdpId.value : '',
                    npi: attr.NPI ? attr.NPI.value : '',
                    address: { ...this.state.address, ...address.Main },
                    phone,
                    fax,
                    latitude,
                    longitude,
                },
                () => {
                    this.initialState = _.cloneDeep(this.state);
                    this.loadLocationIdentifier();
                }
            );
            this.pharmacyAttributes = attr;
        }
    }

    loadLocationIdentifier() {
        const { isInternalUser } = this.props;
        const { getLocationIdentifier } = this.props;

        if (!isInternalUser) return;

        getLocationIdentifier().then((data) => {
            this.setState(
                (prev) => ({
                    ...prev,
                    locationIdentifier: data.pms_identifier,
                }),
                () => {
                    this.initialState.locationIdentifier = data.pms_identifier;
                }
            );
        });
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    onCancel = () => {
        this.setState({ ...this.initialState, editDetails: false });
    };

    toggleEditDetails = () => {
        this.setState({ editDetails: true });
    };

    checkIfSaveDisable = () => {
        const { isInternalUser } = this.props;
        const { pharmacyName, phone, fax, address, locationIdentifier } = this.state;
        let isSaveDisable = false;

        if (isInternalUser && locationIdentifier.length > 0) {
            return false;
        }

        if (
            pharmacyName.length === 0 ||
            toDigits(phone).length !== 10 ||
            toDigits(fax).length !== 10 ||
            address.zip.length === 0 ||
            address.street1.length === 0
        ) {
            isSaveDisable = true;
        }

        return isSaveDisable;
    };

    handleInputChange = (e) => {
        let value = e.target.value;
        const targetName = e.target.name;

        if (targetName === 'ncpdpId' || targetName === 'npi') {
            value = toDigits(value);
        }
        this.setState(
            {
                [targetName]: value,
            },
            () => {
                this.setState({
                    isSaveDisable: this.checkIfSaveDisable(),
                });
            }
        );
    };

    handleAddressChange = (e) => {
        const { address } = _.cloneDeep(this.state);
        let value = e.target.value;
        if (e.target.name === 'zip') {
            value = toDigits(value);
        }
        address[e.target.name] = value;
        this.setState(
            {
                address,
            },
            () => {
                this.setState({
                    isSaveDisable: this.checkIfSaveDisable(),
                });
            }
        );
    };

    onZipBlur = () => {
        const { address } = this.state;
        if (!_.isEmpty(address.zip) && address.zip !== this.zipOnFocus) {
            const { getAddressByZip } = this.props;
            this.setState({ isSaveDisable: true });
            getAddressByZip(address).then(() => {
                this.setState({ isSaveDisable: false });
            });
        }
    };

    onZipFocus = (e) => {
        this.zipOnFocus = e.target.value;
    };

    createPharmacyAttribute = (payload, attrName) => {
        const { addPharmacyAttribute, displayToast } = this.props;
        getAttributeTypeId(attrName).then((res) => {
            if (res.result) {
                const attrTypeId = res.result[0].id;
                const createPayload = {
                    ...payload,
                    name: attrName,
                    attrTypeId,
                };
                addPharmacyAttribute(createPayload).then((response) => {
                    if (!response.error) {
                        displayToast({
                            text: `Pharmacy ${attrName.toUpperCase()} created successfully`,
                            type: 'success',
                        });
                    } else {
                        displayToast({
                            text: `Error while creating Pharmacy ${attrName.toUpperCase()}`,
                            type: 'error',
                        });
                        this.setState({ isTextBoxDisable: false, isSaveDisable: true });
                    }
                });
            } else {
                displayToast({ text: `Error while creating Pharmacy ${attrName.toUpperCase()}`, type: 'error' });
                this.setState({ isTextBoxDisable: false, isSaveDisable: true });
            }
        });
    };

    updatePharmacyAttribute = (payload, attrName) => {
        const { displayToast } = this.props;
        if (payload.id) {
            // update pharmacy attribute
            const { updatePharmacyAttribute } = this.props;
            updatePharmacyAttribute(payload).then((res) => {
                if (!res.error) {
                    displayToast({ text: `Pharmacy ${attrName.toUpperCase()} updated successfully`, type: 'success' });
                } else {
                    displayToast({ text: `Error while updating Pharmacy ${attrName.toUpperCase()}`, type: 'error' });
                    this.setState({ isTextBoxDisable: false, isSaveDisable: true });
                }
            });
        } else {
            // create pharmacy attribute
            this.createPharmacyAttribute(payload, attrName);
        }
    };

    updatePharmacyBasicDetails = () => {
        const { updatePharmacyBasicDetails, displayToast, locationDetail } = this.props;
        const { pharmacyName, fax, phone, latitude, longitude } = this.state;
        const payload = {
            ...locationDetail,
            displayName: pharmacyName,
            fax: toDigits(fax || ''),
            phone: toDigits(phone || ''),
            latitude,
            longitude,
        };
        updatePharmacyBasicDetails(payload).then((res) => {
            if (!res.error) {
                displayToast({ text: `Pharmacy details updated successfully`, type: 'success' });
            } else {
                displayToast({ text: `Error while updating Pharmacy details`, type: 'error' });
                this.setState({ isTextBoxDisable: false, isSaveDisable: true });
            }
        });
    };

    updatePharmacyAddress = () => {
        const { address } = this.state;
        const { updatePharmacyAddress, displayToast } = this.props;
        updatePharmacyAddress(address).then((res) => {
            if (!res.error) {
                this.updatePharmacyBasicDetails();
            } else {
                displayToast({ text: `Error while updating Pharmacy Address`, type: 'error' });
                this.setState({ isTextBoxDisable: false, isSaveDisable: true });
            }
        });
    };

    updateLocationIdentifier = () => {
        const { locationIdentifier } = this.state;
        const { isInternalUser, updateLocationIdentifier, displayToast } = this.props;

        if (!isInternalUser) return;

        updateLocationIdentifier({ locationIdentifier }).then((res) => {
            if (!res.error) {
                this.updatePharmacyBasicDetails();
            } else {
                displayToast({ text: `Error while updating Pharmacy PMS location identifier`, type: 'error' });
                this.setState({ isTextBoxDisable: false, isSaveDisable: true });
            }
        });
    };

    onSave = () => {
        const isInternalUser = this.props.isInternalUser;
        const diffAttrs = this.getStateDifference();
        let shouldDisableState = false;

        // if diffAttrs contains ncpdpid then update ncpdpid
        if (_.findIndex(diffAttrs, (a) => a === 'ncpdpId') > -1) {
            const ncpdpAttributes = this.pharmacyAttributes.ncpdpId || {};
            const payload = {
                ...ncpdpAttributes,
                value: this.state.ncpdpId,
            };
            this.updatePharmacyAttribute(payload, 'ncpdpid');
            shouldDisableState = true;
        }
        // if diffAttrs contains npi then update npi
        if (_.findIndex(diffAttrs, (a) => a === 'npi') > -1) {
            const ncpdpAttributes = this.pharmacyAttributes.NPI || {};
            const payload = {
                ...ncpdpAttributes,
                value: this.state.npi,
            };
            this.updatePharmacyAttribute(payload, 'npi');
            shouldDisableState = true;
        }
        // if diffAttrs contains address then update address only as it call updatePharmacyBasicDetails afterwards
        // else separately call updatePharmacyBasicDetails
        if (_.findIndex(diffAttrs, (a) => a === 'address') > -1) {
            this.updatePharmacyAddress();
            shouldDisableState = true;
        } else if (_.findIndex(diffAttrs, (a) => a === 'pharmacyName' || a === 'fax' || a === 'phone') > -1) {
            this.updatePharmacyBasicDetails();
            shouldDisableState = true;
        }

        if (isInternalUser && _.findIndex(diffAttrs, (a) => a === 'locationIdentifier') > -1) {
            this.updateLocationIdentifier();
            shouldDisableState = true;
        }

        if (shouldDisableState) {
            this.setState({ isSaveDisable: true, isTextBoxDisable: true });
        } else {
            const { displayToast } = this.props;
            displayToast({ text: 'No changes found to save!!', type: 'info' });
        }
    };

    getStateDifference = () => {
        const diff = _.reduce(
            this.initialState,
            (result, value, key) => {
                return _.isEqual(value, this.state[key]) ? result : result.concat(key);
            },
            []
        );
        return diff;
    };

    render() {
        const { pharmacyName, ncpdpId, npi, address, phone, fax, editDetails, locationIdentifier } = this.state;
        const {
            pharmacy,
            pharmacyDetails: { updatingPharmacyDetails },
            isInternalUser,
        } = this.props;
        const {
            pharmacyDetails: { subject: pharmacyDetails },
        } = restrictedResources.generalSettings;
        const canUpdate = this.context.can(userActions.update, pharmacyDetails);

        return (
            <Grid className="pharmacy-details">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader title="Pharmacy Details" onBack={this.onBack} />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    {pharmacy.loading ? (
                                        <CircularProgress id="pharmacy-details-spinner" />
                                    ) : (
                                        <div className="grid-container">
                                            <Cell size={6}>
                                                {!editDetails && (
                                                    <div>
                                                        <p className="pharmacy-name"> {pharmacyName} </p>
                                                        <p> NCPDP - {ncpdpId} </p>
                                                        <p> NPI - {npi} </p>
                                                        <Divider className="divider" />
                                                        <p>
                                                            {' '}
                                                            {address.street1} {address.street2}{' '}
                                                        </p>
                                                        <p>
                                                            {' '}
                                                            {address.city}, {address.state} {address.zip}{' '}
                                                        </p>
                                                        <Divider className="divider" />
                                                        <p> Phone {phone ? formatPhoneNo(phone) : '-'} </p>
                                                        <p> Fax {fax ? formatPhoneNo(fax) : '-'} </p>
                                                        {isInternalUser && (
                                                            <>
                                                                <Divider className="divider" />
                                                                <p> Location Identifier - {locationIdentifier} </p>
                                                            </>
                                                        )}
                                                        {canUpdate ? (
                                                            <RMDButton
                                                                flat
                                                                className="edit-btn"
                                                                onClick={this.toggleEditDetails}
                                                            >
                                                                Edit pharmacy details
                                                            </RMDButton>
                                                        ) : (
                                                            <div className="ie-no-audiorec-note standard-margin-top">
                                                                You don't have permission to update
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {editDetails && (
                                                    <EditPharmacyDetails
                                                        {...this.state}
                                                        isInternalUser={isInternalUser}
                                                        handleInputChange={this.handleInputChange}
                                                        handleAddressChange={this.handleAddressChange}
                                                        onSave={this.onSave}
                                                        onCancel={this.onCancel}
                                                        onZipBlur={this.onZipBlur}
                                                        onZipFocus={this.onZipFocus}
                                                        updatingPharmacyDetails={updatingPharmacyDetails}
                                                    />
                                                )}
                                            </Cell>
                                        </div>
                                    )}
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedLocation: state.auth.selectedLocation,
    isInternalUser: state.auth.isInternalUser,
    pharmacyDetails: state.generalSettings.pharmacyDetails,
});

const mapDispatchToProps = (dispatch) => ({
    updatePharmacyBasicDetails: (data) => dispatch(updatePharmacyBasicDetails(data)),
    updatePharmacyAttribute: (data) => dispatch(updatePharmacyAttribute(data)),
    addPharmacyAttribute: (data) => dispatch(addPharmacyAttribute(data)),
    getAddressByZip: (data) => dispatch(getAddressByZip(data)),
    getLocationIdentifier: (data) => dispatch(getLocationIdentifier(data)),
    updatePharmacyAddress: (data) => dispatch(updatePharmacyAddress(data)),
    updateLocationIdentifier: (data) => dispatch(updateLocationIdentifier(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmacyDetails));
