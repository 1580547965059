import React, { PureComponent } from 'react';
import { Button } from 'react-md';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';

import Resources from 'constants/ResourceLinks';
import ExternalResource from './ExternalResource';
import InternalResource from './InternalResource';
import Snowplow from 'snowplow/Snowplow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export class ResourcesPopover extends PureComponent {
    state = {
        anchorElement: null,
        searchValue: '',
    };

    handleClick = (event) => {
        this.setState({
            anchorElement: event.currentTarget,
        });
    };

    trackAndClose = (e, resource) => {
        e.stopPropagation();
        Snowplow.structEvent('Button Click', 'outbound-nav', null, 'url-click', resource.title);
        this.close();
    };

    close = () => {
        this.setState({
            anchorElement: null,
        });
    };

    getResources = () => {
        // loop over the Resources and check for the Newsletter feature flag
        const { isNewsletterEnabled } = this.props;
        const resources = Resources.map((r) => {
            if (r.title.toLowerCase() === 'enewsletter' && isNewsletterEnabled) {
                return {
                    ...r,
                    link: '/newsletter/calendar',
                };
            }
            return r;
        });
        return resources;
    };

    render() {
        const open = Boolean(this.state.anchorElement);
        const id = open ? 'settings-popover' : null;
        const resources = this.getResources();

        return (
            <div>
                <Button flat className="top-menu-button resources-button" onClick={this.handleClick}>
                    <div className="flex">
                        More <ArrowDropDownIcon />
                    </div>
                </Button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorElement}
                    onClose={this.close}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className="appBar__resourcesPopdownContainer">
                        <MenuList>
                            {resources.map((resource, index) =>
                                resource.link.startsWith('https') ? (
                                    <ExternalResource
                                        key={`external-resource-${index}`}
                                        resource={resource}
                                        onClick={(e) => this.trackAndClose(e, resource)}
                                    />
                                ) : (
                                    <InternalResource
                                        key={`internal-resource-${index}`}
                                        resource={resource}
                                        onClick={this.close}
                                    />
                                )
                            )}
                        </MenuList>
                    </div>
                </Popover>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isNewsletterEnabled: state.pharmacy.newslettersV2Enabled,
    };
};

export default connect(mapStateToProps)(ResourcesPopover);
