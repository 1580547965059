import React from 'react';
import { Grid } from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';

export const TabularTypePlaceholder = ({ legendsCount, style }) => {
    const placeHolderStyle = style ? style : { height: '50px' };
    return (
        <Grid container spacing={2} alignItems="center">
            {new Array(legendsCount).fill(0).map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <ReactPlaceholder
                        type="rect"
                        rows={1}
                        ready={false}
                        showLoadingAnimation
                        firstLaunchOnly
                        style={placeHolderStyle}
                    >
                        <div></div>
                    </ReactPlaceholder>
                </Grid>
            ))}
        </Grid>
    );
};

export default TabularTypePlaceholder;
