import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

import { DpDialog } from 'components/Common/DpDialog/DpDialog';

// DpDialog.Dialog has 800px width. Since this dialog does not have much content, a width of 600px should be enough.
const Dialog = withStyles({
    paper: {
        width: '600px',
    },
    paperWidthMd: {
        width: '600px !important',
    },
})(DpDialog.Dialog);

export const DeleteConfirmDialog = ({ show, onConfirm, onCancel, appName }) => {
    const dialogHeading = 'Are you sure you want to delete this app?';

    return (
        <Dialog id="user-deletion-dialog" open={show} onClose={onCancel}>
            <DpDialog.Content style={{ minHeight: '15%' }}>
                <DpDialog.ContentHeader heading={dialogHeading} />
                <DialogContentText style={{ paddingTop: '12px' }}>
                    {`Please make sure you want to delete this app (${appName}).`}
                </DialogContentText>
            </DpDialog.Content>
            <DpDialog.Actions>
                <Button autoFocus onClick={onCancel} color="default" style={{ marginRight: '16px' }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" color="secondary">
                    Delete App
                </Button>
            </DpDialog.Actions>
        </Dialog>
    );
};
