import apptCalendarDataTypes from './type';
import _ from 'lodash';

const initialState = {
    loadingAvailableCalendars: false,
    creatingNewCalendar: null,
    availableCalendarSlots: null,
    availableCalendars: null,
    avalaableCalendarsError: null,
    deletingCalendarId: null,
    deletingCalendarError: null,
    updatingCalendarId: null,
    updatingCalendar: null,
    updatingCalendarError: null,
    calendarReservations: {}, // store these keyed by calendar id so we can have multiple loaded at once
    appointmentCounts: null,
};

export function apptCalendarReducer(state = initialState, { type, payload }) {
    switch (type) {
        case apptCalendarDataTypes.EXISTING_CALENDARS_REQUEST:
            return {
                ...state,
                loadingAvailableCalendars: true,
            };

        case apptCalendarDataTypes.EXISTING_CALENDARS_SUCCESS:
            return {
                ...state,
                loadingAvailableCalendars: false,
                availableCalendars: payload.response,
            };

        case apptCalendarDataTypes.EXISTING_CALENDARS_FAILURE:
            return {
                ...state,
                loadingAvailableCalendars: false,
                availableCalendarsError: payload.error,
            };

        case apptCalendarDataTypes.CREATE_CALENDAR_REQUEST:
            return {
                ...state,
                creatingNewCalendar: payload.baseCalendar,
            };

        case apptCalendarDataTypes.CREATE_CALENDAR_SUCCESS:
            return {
                ...state,
                creatingNewCalendar: null,
                availableCalendars: {
                    calendars: _.get(state, 'availableCalendars.calendars', []).concat([payload.response]),
                },
            };

        case apptCalendarDataTypes.CREATE_CALENDAR_FAILURE:
            return {
                ...state,
                creatingNewCalendar: null,
                creatingNewCalendaerError: payload.error,
            };

        case apptCalendarDataTypes.DELETE_CALENDAR_REQUEST:
            return {
                ...state,
                deletingCalendarId: payload.calendarId,
            };

        case apptCalendarDataTypes.DELETE_CALENDAR_SUCCESS:
            return {
                ...state,
                deletingCalendarId: null,
                availableCalendars: {
                    calendars: _.filter(
                        _.get(state, 'availableCalendars.calendars', []),
                        ({ calendar_id }) => calendar_id !== payload.calendarId
                    ),
                },
            };

        case apptCalendarDataTypes.DELETE_CALENDAR_FAILURE:
            return {
                ...state,
                deletingCalendarId: null,
                deletingCalendarError: payload.error,
            };

        case apptCalendarDataTypes.UPDATE_CALENDAR_REQUEST:
            return {
                ...state,
                updatingCalendarId: payload.calendarId,
                updatingCalendar: payload.baseCalendar,
            };

        case apptCalendarDataTypes.UPDATE_CALENDAR_SUCCESS:
            return {
                ...state,
                updatingCalendarId: null,
                availableCalendars: {
                    calendars: _.map(_.get(state, 'availableCalendars.calendars', []), (calendar) =>
                        calendar.calendar_id === payload.calendarId ? payload.response : calendar
                    ),
                },
            };

        case apptCalendarDataTypes.UPDATE_CALENDAR_FAILURE:
            return {
                ...state,
                updatingCalendarId: null,
                updatingCalendarError: payload.error,
            };

        case apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_REQUEST:
            return {
                ...state,
                availableCalendarSlots: {
                    loading: true,
                    ...payload,
                },
            };

        case apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_SUCCESS:
            return {
                ...state,
                availableCalendarSlots: {
                    loading: false,
                    ...payload,
                },
            };

        case apptCalendarDataTypes.GET_AVAILABLE_CALENDAR_APPT_SLOTS_FAILURE:
            return {
                ...state,
                availableCalendarSlots: {
                    loading: false,
                    ...payload,
                },
            };

        case apptCalendarDataTypes.CLEAR_AVAILABLE_APPOINTMENT_SLOTS:
            return {
                ...state,
                availableCalendarSlots: null,
            };

        case apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_REQUEST:
            return {
                ...state,
                calendarReservations: {
                    ...state.calendarReservations,
                    [payload.calendarId]: { loading: true, ...payload },
                },
            };

        case apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_SUCCESS:
            return {
                ...state,
                calendarReservations: {
                    ...state.calendarReservations,
                    [payload.calendarId]: { loading: false, ...payload },
                },
            };

        case apptCalendarDataTypes.GET_CALENDAR_RESERVATIONS_FAILURE:
            return {
                ...state,
                calendarReservations: {
                    ...state.calendarReservations,
                    [payload.calendarId]: { loading: false, ...payload },
                },
            };

        case apptCalendarDataTypes.CLEAR_CALENDAR_RESERVATIONS:
            return {
                ...state,
                calendarReservations: null,
            };

        case apptCalendarDataTypes.UPDATE_CALENDAR_RESERVATIONS_FROM_RESPONSE:
            return {
                ...state,
                calendarReservations: getUpdatedCalendarReservationsFromResponse(payload.response, state),
            };

        case apptCalendarDataTypes.RESET_CALENDARS:
            return {
                ...state,
                ...initialState,
            };

        case apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_REQUEST:
        case apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_SUCCESS:
        case apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_FAILURE:
            return {
                ...state,
                appointmentCounts: {
                    loading: type === apptCalendarDataTypes.GET_CALENDAR_APPOINTMENT_COUNTS_REQUEST,
                    ...payload,
                },
            };

        case apptCalendarDataTypes.CLEAR_CALENDAR_APPOINTMENT_COUNTS:
            return {
                ...state,
                appointmentCounts: null,
            };

        default:
            return state;
    }
}

/*
    Updates the state of calendarReservations by replacing it within the reservations list with the passed response from
    any of the update calls to the reservation backend
 */
function getUpdatedCalendarReservationsFromResponse(response, state) {
    const calendarReservations = _.cloneDeep(state.calendarReservations);

    const reservations = _.get(calendarReservations, `${response.calendar_id}.response.reservations`);
    const index = _.findIndex(reservations, ({ reservation_id }) => reservation_id === response.reservation_id);

    if (_.isFinite(index) && index >= 0) {
        reservations.splice(index, 1, response);
    }

    return calendarReservations;
}
