import statusPageType from './type';
import statusPageService from './service';

const fetchSummary = () => (dispatch) => {
    dispatch({ type: statusPageType.FETCH_SUMMARY_REQUEST });

    return statusPageService
        .fetchSummary()
        .then((responseBody) => {
            dispatch({ type: statusPageType.FETCH_SUMMARY_SUCCESS, payload: responseBody });
            return { responseBody };
        })
        .catch((error) => {
            dispatch({ type: statusPageType.FETCH_SUMMARY_FAILURE, payload: { error } });
            return { error };
        });
};

export const statusPageAction = {
    fetchSummary,
};

export default statusPageAction;
