import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

const DpTableBody = withStyles({
    root: {
        border: '1px solid #d8d8d8',
    },
})((props) => <TableBody {...props} />);

const DpTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:hover': {
            cursor: 'pointer',
        },
        borderBottom: '1px solid #d8d8d8',
    },
}))((props) => <TableRow {...props} />);

const DpTableCell = withStyles({
    root: {
        borderBottom: 0,
    },
})((props) => <TableCell {...props} />);

export { DpTableBody, DpTableRow, DpTableCell };
