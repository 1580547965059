import Actions from 'redux/actions/Classes';
import Classes from 'constants/Classes';

const constructHealthConditions = (classes) => {
    const healthConditions = {};

    classes.forEach((item) => {
        healthConditions[item.id] = item;
        healthConditions[item.id].selectedCourseContent = 'overview';
    });

    return healthConditions;
};

const changeSelectedCourseContent = (currentState, payload) => {
    const nextState = { ...currentState };
    const { healthCondition, selectedCourseContent } = payload;
    nextState[healthCondition].selectedCourseContent = selectedCourseContent;
    return nextState;
};

export default (state = constructHealthConditions(Classes), action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.CHANGE_SELECTED_COURSE_CONTENT:
            return changeSelectedCourseContent(state, payload);
        default:
            return state;
    }
};
