import apptReservationDataTypes from './type';
import apptReservationService from './service';
import config from '../../../config';
import _ from 'lodash';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentContext } from 'snowplow/contexts';

export function createNewReservationSession(calendarId, reservationSession) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'creatingNewReservationSession.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.CREATE_RESERVATION_SESSION_REQUEST,
            payload: { reservationSession },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.createNewReservationSession(
                pharmacyId,
                locationId,
                calendarId,
                { ...reservationSession, pharmacy_id: pharmacyId, location_id: locationId }
            );
            dispatch({
                type: apptReservationDataTypes.CREATE_RESERVATION_SESSION_SUCCESS,
                payload: { reservationSession, response },
            });
            return Promise.resolve(response);
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.CREATE_RESERVATION_SESSION_FAILURE,
                payload: { reservationSession, error },
            });
            return Promise.reject(error);
        }
    };
}

export function updateReservationSession(calendarId, sessionId, reservationSession) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'updatingReservationSession.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.UPDATE_RESERVATION_SESSION_REQUEST,
            payload: { reservationSession },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.updateReservationSession(
                pharmacyId,
                locationId,
                calendarId,
                sessionId,
                { ...reservationSession, pharmacy_id: pharmacyId, location_id: locationId }
            );
            dispatch({
                type: apptReservationDataTypes.UPDATE_RESERVATION_SESSION_SUCCESS,
                payload: { reservationSession, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.UPDATE_RESERVATION_SESSION_FAILURE,
                payload: { reservationSession, error },
            });
        }
    };
}

export function getAvailableAppointmentSlots(calendarId, sessionId, start_date, end_date) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'availableAppointmentSlots.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_REQUEST,
            payload: { calendarId, sessionId, start_date, end_date },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.getAvailableAppointmentSlots(
                pharmacyId,
                locationId,
                calendarId,
                sessionId,
                start_date,
                end_date
            );
            dispatch({
                type: apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_SUCCESS,
                payload: { calendarId, sessionId, start_date, end_date, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_FAILURE,
                payload: { calendarId, sessionId, start_date, end_date, error },
            });
        }
    };
}

export function chooseWaitlistPatientsByCount(waitlistId, numPatients, preferExisting) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'waitlistPatientsByCount.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_REQUEST,
            payload: { waitlistId, numPatients, preferExisting },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.chooseWaitlistPatientsByCount(
                pharmacyId,
                locationId,
                waitlistId,
                numPatients,
                preferExisting
            );
            dispatch({
                type: apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_SUCCESS,
                payload: { waitlistId, numPatients, preferExisting, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_FAILURE,
                payload: { waitlistId, numPatients, preferExisting, error },
            });
        }
    };
}

export function createNewReservation(calendarId, reservation, lengthMinutes, isOneOff) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'creatingNewReservation.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.CREATE_RESERVATION_REQUEST,
            payload: { reservation },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.createNewReservation(pharmacyId, locationId, calendarId, {
                ...reservation,
                pharmacy_id: pharmacyId,
                location_id: locationId,
            });
            dispatch({
                type: apptReservationDataTypes.CREATE_RESERVATION_SUCCESS,
                payload: { reservation, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'reservation-created', [
                new AppointmentContext()
                    .setReservationId(response.reservation_id)
                    .setCalendarId(response.calendar_id)
                    .setInboxUserId(response.inbox_user_id)
                    .setLabel(response.label)
                    .setLength(lengthMinutes)
                    .setOneOff(isOneOff)
                    .build(),
            ]);

            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.CREATE_RESERVATION_FAILURE,
                payload: { reservation, error },
            });

            return Promise.reject(error);
        }
    };
}

export function updateReservation(calendarId, reservationId, reservation) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'updatingReservation.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.UPDATE_RESERVATION_REQUEST,
            payload: { calendarId, reservationId, reservation },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.updateReservation(
                pharmacyId,
                locationId,
                calendarId,
                reservationId,
                reservation
            );
            dispatch({
                type: apptReservationDataTypes.UPDATE_RESERVATION_SUCCESS,
                payload: { calendarId, reservationId, reservation, response },
            });

            Snowplow.structEvent(
                StructEventCategories.appointments,
                reservation.status === 'DELETED' ? 'reservation-deleted' : 'reservation-updated',
                [
                    new AppointmentContext()
                        .setReservationId(response.reservation_id)
                        .setCalendarId(response.calendar_id)
                        .setInboxUserId(response.inbox_user_id)
                        .build(),
                ]
            );

            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.UPDATE_RESERVATION_FAILURE,
                payload: { calendarId, reservationId, reservation, error },
            });

            return Promise.reject(error);
        }
    };
}

export function checkinReservation(calendarId, reservationId, userId) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'checkingInReservation.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.CHECKIN_RESERVATION_REQUEST,
            payload: { calendarId, reservationId, userId },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.checkinReservation(
                pharmacyId,
                locationId,
                calendarId,
                reservationId,
                userId
            );
            dispatch({
                type: apptReservationDataTypes.CHECKIN_RESERVATION_SUCCESS,
                payload: { calendarId, reservationId, userId, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'reservation-checkin-by-pharmacist', [
                new AppointmentContext().setReservationId(response.reservation_id).build(),
            ]);

            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.CHECKIN_RESERVATION_FAILURE,
                payload: { calendarId, reservationId, userId, error },
            });
        }
    };
}

export function uncheckinReservation(calendarId, reservationId, userId) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'checkingInReservation.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptReservationDataTypes.CHECKIN_RESERVATION_REQUEST,
            payload: { calendarId, reservationId, userId },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptReservationService.uncheckinReservation(
                pharmacyId,
                locationId,
                calendarId,
                reservationId
            );
            dispatch({
                type: apptReservationDataTypes.CHECKIN_RESERVATION_SUCCESS,
                payload: { calendarId, reservationId, userId, response },
            });

            Snowplow.structEvent(StructEventCategories.appointments, 'reservation-uncheckin-by-pharmacist', [
                new AppointmentContext().setReservationId(response.reservation_id).build(),
            ]);

            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.CHECKIN_RESERVATION_FAILURE,
                payload: { calendarId, reservationId, userId, error },
            });
        }
    };
}

export function getLiveReusableReservationSessions() {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'liveReusableSessions.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        dispatch({
            type: apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_REQUEST,
            payload: { pharmacyId, locationId },
        });

        try {
            const response = await apptReservationService.getLiveReusableReservationSessions(pharmacyId, locationId);
            dispatch({
                type: apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_SUCCESS,
                payload: { pharmacyId, locationId, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_FAILURE,
                payload: { pharmacyId, locationId, error },
            });
        }
    };
}

export function getReservationsForInboxUserId(inboxUserId) {
    return async (dispatch, getState) => {
        const state = getState().apptReservation;

        if (_.get(state, 'reservationsForInboxUserId.loading', false) || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        dispatch({
            type: apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_REQUEST,
            payload: { pharmacyId, locationId, inboxUserId },
        });

        try {
            const response = await apptReservationService.getReservationsForInboxUserId(
                pharmacyId,
                locationId,
                inboxUserId
            );
            dispatch({
                type: apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_SUCCESS,
                payload: { pharmacyId, locationId, inboxUserId, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_FAILURE,
                payload: { pharmacyId, locationId, inboxUserId, error },
            });
        }
    };
}

export function resetReservations() {
    return {
        type: apptReservationDataTypes.RESET_RESERVATIONS,
        payload: {},
    };
}

export function updateLiveReusableReservationSessionsFromResponse(response) {
    return {
        type: apptReservationDataTypes.UPDATE_LIVE_REUSABLE_RESERVATION_SESSIONS_FROM_RESPONSE,
        payload: { response },
    };
}
