export const phoneLineOptions = {
    refill: 'Prescription Refills',
    doctor_messages: 'Prescriber Voicemail',
    patient_messages: 'General Voicemail',
    hours: 'Pharmacy Hours',
    refill_pickup: 'Refill - Pickup',
    refill_delivery: 'Refill - Delivery',
    refill_mail: 'Refill - Mail',
    refill_curbside: 'Refill - Curbside',
    refill_kiosk: 'Refill - Kiosk',
    sms: 'Send Mobile App SMS',
    transfer: 'Transfer to Store',
    star_key: 'Return to IVR Main Menu',
    privacy_policy: 'Privacy Policy',
};

export const mainPhoneLineOptions = [
    { num: 1, value: 'refill' },
    { num: 2, value: 'patient_messages' },
    { num: 3, value: 'doctor_messages' },
    { num: 4, value: 'hours' },
    { num: 5, value: 'sms' },
    { num: 9, value: 'privacy_policy' },
    { num: 0, value: 'transfer' },
    { num: '*', value: 'star_key' },
];

export const refillPhoneLineOptions = [
    { num: 1, value: 'refill_pickup' },
    { num: 2, value: 'refill_delivery' },
    { num: 3, value: 'refill_mail' },
    { num: 4, value: 'refill_kiosk' },
    { num: 5, value: 'refill_curbside' },
];
