import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const Dialog = withStyles({
    paper: {
        width: '800px',
        maxWidth: '100%',
    },
    paperWidthMd: {
        width: '800px !important',
        maxWidth: '100%',
    },
})(MuiDialog);
const Content = withStyles({
    root: {
        paddingTop: '36px !important',
        paddingBottom: '24px',
        width: '800px',
        maxWidth: '100%',
        minHeight: '363px',
    },
})(MuiDialogContent);

const ContentHeader = withStyles({
    heading: {
        marginBottom: '8px',
        marginRight: '20px',
        fontSize: '1.44rem',
    },
    subHeading: {
        marginBottom: '24px',
    },
    closeIcon: {
        position: 'absolute',
        right: 12,
        top: 12,
        fontSize: '1.55rem',
    },
})((props) => {
    const { children, classes, heading, onClose } = props;

    return (
        <Fragment>
            <Typography className={classes.heading} variant="h5">
                {heading}
            </Typography>
            {onClose && (
                <IconButton className={classes.closeIcon} arial-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
            {children && <Typography className={classes.subHeading}>{children}</Typography>}
        </Fragment>
    );
});

const Actions = withStyles({
    root: {
        padding: '0 24px 24px 24px',
    },
})(MuiDialogActions);

export const DpDialog = {
    Dialog,
    Content,
    ContentHeader,
    Actions,
};
