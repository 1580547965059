import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';

import { newsletterActions } from 'redux/Newsletter';

const EmailPreviewPlaceholder = () => (
    <Grid container style={{ padding: '70px' }}>
        <Grid container item xs={12} justify="space-between">
            <Grid item xs={3}>
                <ReactPlaceholder
                    type="rect"
                    style={{ width: 150, height: 60 }}
                    delay={5000}
                    showLoadingAnimation
                    ready={false}
                >
                    <span></span>
                </ReactPlaceholder>
            </Grid>
            <Grid container item xs={2} className="flex" alignItems="flex-end" justify="flex-end">
                {[1, 2, 3].map((item) => (
                    <ReactPlaceholder
                        type="round"
                        ready={false}
                        color="#E0E0E0"
                        showLoadingAnimation
                        style={{ width: 24, height: 24, marginLeft: '8px' }}
                        key={item}
                    >
                        <div></div>
                    </ReactPlaceholder>
                ))}
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <ReactPlaceholder
                type="rect"
                delay={5000}
                showLoadingAnimation
                color="#E0E0E0"
                ready={false}
                style={{ width: '100%', height: '2px', marginTop: '10px' }}
            >
                <span></span>
            </ReactPlaceholder>
        </Grid>
        {[1, 2, 3].map((item) => (
            <Grid container item xs={12} key={item}>
                <Grid item xs={8}>
                    <ReactPlaceholder
                        type="textRow"
                        delay={5000}
                        showLoadingAnimation
                        color="#E0E0E0"
                        ready={false}
                        style={{ marginBottom: '10px' }}
                    >
                        <span></span>
                    </ReactPlaceholder>
                </Grid>
                <Grid item xs={12} className="flex">
                    <Grid item>
                        <ReactPlaceholder
                            type="rect"
                            delay={5000}
                            showLoadingAnimation
                            color="#E0E0E0"
                            ready={false}
                            style={{ width: '130px', height: '115px' }}
                        >
                            <span></span>
                        </ReactPlaceholder>
                    </Grid>
                    <Grid item xs={8}>
                        {[1, 2, 3].map((item) => (
                            <ReactPlaceholder
                                type="text"
                                rows={1}
                                delay={5000}
                                showLoadingAnimation
                                color="#E0E0E0"
                                ready={false}
                                style={{ width: '100%', marginBottom: '10px' }}
                                key={item}
                            >
                                <span></span>
                            </ReactPlaceholder>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ReactPlaceholder
                        type="rect"
                        delay={5000}
                        showLoadingAnimation
                        color="#E0E0E0"
                        ready={false}
                        style={{ width: '100%', height: '1px', marginTop: '10px' }}
                    >
                        <span></span>
                    </ReactPlaceholder>
                </Grid>
            </Grid>
        ))}
    </Grid>
);

const EmailPreview = (props) => {
    const { emailHtml, loadingNewsletterDetails } = props;

    const onBack = () => {
        const { resetActiveNewsletter } = props;
        resetActiveNewsletter();
    };

    if (loadingNewsletterDetails) return <EmailPreviewPlaceholder />;

    return (
        <div className="email-preview">
            <div className="flex-middle">
                <IconButton aria-label="Go Back" onClick={onBack} edge="start">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" className="title">
                    Back to History
                </Typography>
            </div>
            <div dangerouslySetInnerHTML={{ __html: emailHtml }}></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    emailHtml: state.newsletter.activeNewsletter.emailHtml,
    loadingNewsletterDetails: state.newsletter.loadingNewsletterDetails,
});

const mapDispatchToProps = {
    resetActiveNewsletter: newsletterActions.resetActiveNewsletter,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreview);
