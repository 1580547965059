import React, { Component } from 'react';
import { Card, Cell, CardText, Grid, Button, Divider, CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import moment from 'moment';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    updateGreetingMessage,
    addGreeting,
    deleteGreeting,
    changeGreetingType,
    updateExceptionDate,
    editGreeting,
    editExceptionGreeting,
    deleteSpecialGreeting,
    closeGreetingEditor,
    fetchSpecialGreetings,
    fileUpload,
    saveGreeting,
    saveSpecialGreeting,
    fetchAllGreetings,
    saveFilename,
    changeGreetingScript,
    markDefaultGreeting,
} from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import GreetingEditor from 'components/Common/GreetingEditor';
import { MarkDefaultConfirm } from 'components/Settings/PhoneSystem/Common/MarkDefaultConfirm';
import goBackService from 'utils/goBackService';
import { userActions, restrictedResources, AbilityContext } from 'casl';

export class IVRMainGreeting extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.markDefaultData = null;
        this.state = {
            markDefaultConfirmVisible: false,
            track: {
                current: '',
                previous: '',
            },
        };
    }

    componentDidMount() {
        this.loadInitialData();
    }

    componentDidUpdate(prevProps) {
        const { auth } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            this.loadInitialData();
        }
    }

    loadInitialData = () => {
        const { loadGreetingFiles, fetchAllGreetings } = this.props;
        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);

        loadGreetingFiles();
        fetchAllGreetings(canCreateGreeting);
    };

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    async onSave({ fileObj, defaultCheck, closedCheck, editedFileName, filenameWithExt, dateRangeCheck }) {
        const {
            newGreeting,
            defaultGreeting,
            closeGreetingEditor,
            fetchMainGreeting,
            displayToast,
            saveFile,
            fileUpload,
            saveGreeting,
            saveSpecialGreeting,
            loadGreetingFiles,
            fetchSpecialGreetings,
            deleteSpecialGreeting,
            exceptionGreetings,
        } = this.props;

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            newGreeting.greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            await saveFile({ name: newFilename, id: newGreeting.greeting_id });
        }

        if (defaultCheck || closedCheck) {
            const greetingPayload = { id: defaultGreeting.id };
            if (closedCheck) {
                greetingPayload['closed_greeting_id'] = newGreeting.greeting_id;
            } else {
                greetingPayload['greeting_id'] = newGreeting.greeting_id;
            }

            const saveGreetingResp = await saveGreeting({ newGreeting: { ...greetingPayload }, type: 'MAIN' });
            if (saveGreetingResp.err) {
                displayToast({ text: 'Default greeting update failed', type: 'error' });
            } else {
                if (newGreeting.id && newGreeting.id !== defaultGreeting.id) {
                    deleteSpecialGreeting(defaultGreeting, exceptionGreetings, newGreeting.idx);
                }
                fetchMainGreeting();
                displayToast({ text: 'Default greeting updated', type: 'success' });
            }
        } else {
            if (!dateRangeCheck) {
                newGreeting.startDate = moment()
                    .hour(0)
                    .minute(0)
                    .seconds(0)
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                newGreeting.endDate = moment()
                    .hour(23)
                    .minute(59)
                    .seconds(0)
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
            }
            await saveSpecialGreeting({ newGreeting, defaultGreeting });
            fetchSpecialGreetings(defaultGreeting.id);
        }
        closeGreetingEditor();
    }

    hideMarkDefault = () => {
        this.markDefaultData = null;
        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    showMarkDefault = (data) => {
        this.markDefaultData = data;
        this.setState({
            markDefaultConfirmVisible: true,
        });
    };

    confirmMarkDefault = () => {
        const { markDefaultGreeting } = this.props;

        markDefaultGreeting(this.markDefaultData);
        this.markDefaultData = null;

        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    setCurrentTrack = (greetingId) => {
        const { track } = this.state;

        if (greetingId !== track.current) track.previous = track.current;

        track.current = greetingId;
        this.setState({
            track,
        });
    };

    removeGreeting = (type) => {
        const { defaultGreeting, deleteGreeting } = this.props;
        let newGreeting = { id: defaultGreeting.id, type };

        if (type === 'default') {
            newGreeting = { ...newGreeting, greeting_id: '' };
        } else {
            newGreeting = { ...newGreeting, closed_greeting_id: '' };
        }

        deleteGreeting(newGreeting);
    };

    render() {
        const {
            displayToast,
            greetingFiles,
            defaultGreeting,
            exceptionGreetings,
            deleteSpecialGreeting,
            showGreetingCreator,
            newGreeting,
            updateGreetingMessage,
            closeGreetingEditor,
            isFilenameSaving,
            updateDateRange,
            loadingMainGreeting,
            loadingSpecialGreetings,
            editGreeting,
            editSpecialGreeting,
            addGreeting,
            changeGreetingScript,
            changeGreetingType,
            auth: { selectedLocation, selectedCustomer },
        } = this.props;
        const { markDefaultConfirmVisible, track } = this.state;
        const { create, update } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);
        const canUpdateGreeting = this.context.can(update, greetingSubject);

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });

        const markDefaultActions = [];
        markDefaultActions.push(
            <Button flat primary onClick={this.hideMarkDefault}>
                Cancel
            </Button>
        );
        markDefaultActions.push(
            <Button raised secondary onClick={this.confirmMarkDefault}>
                Confirm
            </Button>
        );

        return (
            <Grid className="greeting-container">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader title="Pharmacy Greeting" onBack={this.onBack} />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    {loadingMainGreeting || loadingSpecialGreetings ? (
                                        <CircularProgress id="main-greeting-spinner" />
                                    ) : (
                                        <span className="greetings-list">
                                            <SectionTitle text="Default Main Greeting" />
                                            <SectionSubTitle text="This is the message that callers will hear as soon as your IVR answers" />
                                            {canCreateGreeting && (
                                                <Grid
                                                    className="md-cell--desktop-hidden"
                                                    style={{ marginBottom: '16px' }}
                                                >
                                                    <Cell
                                                        size={12}
                                                        desktopHidden
                                                        className="centered-content button-control"
                                                    >
                                                        <Button
                                                            className="add-btn"
                                                            flat
                                                            secondary
                                                            onClick={addGreeting}
                                                        >
                                                            + ADD A NEW GREETING
                                                        </Button>
                                                    </Cell>
                                                </Grid>
                                            )}
                                            <div className="grid-container table-title">
                                                {defaultGreeting['greeting_type'] !== 'text/plain' && (
                                                    <Cell size={1}>&nbsp;</Cell>
                                                )}
                                                <Cell size={defaultGreeting[`greeting_type`] === 'text/plain' ? 4 : 3}>
                                                    Title
                                                </Cell>
                                                <Cell size={1}>Status</Cell>
                                                <Cell size={1}>Start Date</Cell>
                                                <Cell size={1}>End Date</Cell>
                                                <Cell size={5}>&nbsp;</Cell>
                                            </div>

                                            {defaultGreeting && defaultGreeting.greeting_id && (
                                                <span className="main-greeting">
                                                    <Greetings
                                                        className="blue-bordered"
                                                        track={track}
                                                        setCurrentTrack={this.setCurrentTrack}
                                                        perms={selectedLocation.perms}
                                                        greetingDetail={defaultGreeting}
                                                        greetingFileList={greetingFileList}
                                                        handleEdit={() =>
                                                            editGreeting({ defaultGreeting, type: 'default' })
                                                        }
                                                        isDeleting={defaultGreeting.processing}
                                                        handleDelete={() => this.removeGreeting('default')}
                                                        hideActions={!canUpdateGreeting}
                                                    />
                                                </span>
                                            )}

                                            {defaultGreeting && !defaultGreeting.greeting_id && (
                                                <div className="grid-container blue-bordered no-default-greeting-msg">
                                                    <Cell size={12} className="table-data centered-content ">
                                                        There is no default greeting configured for this location's IVR.
                                                    </Cell>
                                                </div>
                                            )}

                                            <Grid>
                                                <Cell size={12}>
                                                    <Divider />
                                                </Cell>
                                            </Grid>

                                            <SectionTitle text="Scheduled Main Greetings" />
                                            <SectionSubTitle
                                                text="Any recordings below will override your default greeting
                         on the date(s) that they are scheduled to be active"
                                            />

                                            <div className="grid-container table-title">
                                                <Cell size={1}>&nbsp;</Cell>
                                                <Cell size={3}>Title</Cell>
                                                <Cell size={1}>Status</Cell>
                                                <Cell size={1}>Start Date</Cell>
                                                <Cell size={1}>End Date</Cell>
                                                <Cell size={5}>&nbsp;</Cell>
                                            </div>

                                            {exceptionGreetings.length > 0 && (
                                                <div className="bordered-table exception-greetings-list">
                                                    {exceptionGreetings.map((specialGreeting, idx) => (
                                                        <Greetings
                                                            key={idx}
                                                            track={track}
                                                            setCurrentTrack={this.setCurrentTrack}
                                                            perms={selectedLocation.perms}
                                                            className="bordered-table-item"
                                                            greetingDetail={specialGreeting}
                                                            greetingFileList={greetingFileList}
                                                            isDeleting={specialGreeting.processing}
                                                            handleDefault={() =>
                                                                this.showMarkDefault({
                                                                    defaultGreeting,
                                                                    exceptionGreetings,
                                                                    idx,
                                                                })
                                                            }
                                                            handleEdit={() =>
                                                                editSpecialGreeting({ idx, specialGreeting })
                                                            }
                                                            handleDelete={() =>
                                                                deleteSpecialGreeting(
                                                                    defaultGreeting,
                                                                    exceptionGreetings,
                                                                    idx
                                                                )
                                                            }
                                                            isSpecial
                                                        />
                                                    ))}
                                                </div>
                                            )}

                                            {exceptionGreetings.length < 1 && (
                                                <div className="grid-container blue-bordered no-exception-greeting-msg">
                                                    <Cell size={12} className="table-data centered-content ">
                                                        There is no custom greeting configured for this location's IVR.
                                                    </Cell>
                                                </div>
                                            )}

                                            <Grid>
                                                <Cell size={12} phoneHidden className="centered-content button-control">
                                                    {canCreateGreeting ? (
                                                        <Button
                                                            className="add-btn"
                                                            flat
                                                            secondary
                                                            onClick={addGreeting}
                                                        >
                                                            + ADD A NEW GREETING
                                                        </Button>
                                                    ) : (
                                                        <div>You don't have permission to configure greeting</div>
                                                    )}
                                                </Cell>
                                            </Grid>
                                        </span>
                                    )}
                                </CardText>
                            </Card>

                            <MarkDefaultConfirm
                                hideMarkDefault={this.hideMarkDefault}
                                isVisible={markDefaultConfirmVisible}
                                actions={markDefaultActions}
                            />

                            <GreetingEditor
                                custPerms={selectedCustomer.perms}
                                isSaving={isFilenameSaving || newGreeting.processing}
                                dialogTitle={newGreeting.id ? 'Edit greeting' : 'Add a new greeting'}
                                isVisible={showGreetingCreator}
                                greetingFiles={greetingFiles}
                                greeting={newGreeting}
                                defaultGreeting={defaultGreeting}
                                exceptionGreetings={exceptionGreetings}
                                greetingId={newGreeting.greeting_id || ''}
                                greetingUrl={newGreeting.greeting_url}
                                greetingType={newGreeting.greeting_type_id}
                                greetingScript={newGreeting.greeting_text}
                                handleChange={(data) => updateGreetingMessage(data)}
                                handleRangeChange={(data) => updateDateRange(data)}
                                closeHandler={() => closeGreetingEditor()}
                                saveHandler={(data) => this.onSave(data)}
                                showToastMessage={(data) => displayToast(data)}
                                changeGreetingType={(type) => changeGreetingType(type)}
                                changeGreetingScript={(msg) => changeGreetingScript(msg)}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchAllGreetings: (data) => dispatch(fetchAllGreetings(data)),
    fetchMainGreeting: () => dispatch(fetchMainGreeting()),
    fetchSpecialGreetings: (data) => dispatch(fetchSpecialGreetings(data)),
    updateDateRange: (data) => dispatch(updateExceptionDate(data)),
    addGreeting: () => dispatch(addGreeting()),
    closeGreetingEditor: () => dispatch(closeGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    markDefaultGreeting: (data) => dispatch(markDefaultGreeting(data)),
    deleteGreeting: (data) => dispatch(deleteGreeting(data)),
    deleteSpecialGreeting: (defaultGreeting, exceptionGreetings, idx) =>
        dispatch(deleteSpecialGreeting({ defaultGreeting, exceptionGreetings, idx })),
    updateGreetingMessage: (data) => dispatch(updateGreetingMessage(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    saveSpecialGreeting: (data) => dispatch(saveSpecialGreeting(data)),
    editGreeting: (data) => dispatch(editGreeting(data)),
    editSpecialGreeting: (data) => dispatch(editExceptionGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changeGreetingType: (data) => dispatch(changeGreetingType(data)),
    changeGreetingScript: (data) => dispatch(changeGreetingScript(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IVRMainGreeting);
