import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { conversationAction } from '../../redux/Inbox/Conversation';
import { userAction } from '../../redux/Inbox/User';
import Grid from '@material-ui/core/Grid';
import ContactHeaderBar from './ContactHeaderBar';
import ConversationList from './Conversation/ConversationList';
import NewConversationDialog from './Conversation/NewConversationDialog';
import EditContactDialog from './EditContactDialog';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';
class ContactSection extends Component {
    componentDidMount() {
        const { vmUser, openCreateConversationDialog } = this.props;

        if (vmUser) {
            openCreateConversationDialog();
        }
    }

    render() {
        const {
            contact,
            onBack,
            conversation,
            user,
            toggleCreateConversationDialog,
            closeCreateConversationDialog,
            closeEditUserDialog,
            toggleEditUserDialog,
            verifyUser,
            updateUser,
            archiveUser,
            auth: { selectedLocation },
            activeLocationCountryCode,
            patientEditingDisabled,
            fullPepEnabled,
            basicMessagingEnabled,
        } = this.props;

        const fetchingUserPhoneType = _.get(user, `lookingUpPhoneTypeByUserId[${user.userId}].fetching`, false);

        return (
            <Fragment>
                <ContactHeaderBar
                    perms={selectedLocation.perms}
                    patientEditingDisabled={!!patientEditingDisabled}
                    toggleNewConversationDialog={toggleCreateConversationDialog}
                    contact={contact}
                    toggleEditContactDialog={toggleEditUserDialog}
                    verifyUser={verifyUser}
                    sending={user.sending}
                    fetchingUserPhoneType={fetchingUserPhoneType}
                    activeLocationCountryCode={activeLocationCountryCode}
                    onBack={onBack}
                />
                <Grid container style={{ padding: '0 16px' }}>
                    <Grid item xs={12} md={12}>
                        <ConversationList contact={contact} activeLocationCountryCode={activeLocationCountryCode} />
                    </Grid>
                </Grid>
                {fullPepEnabled || basicMessagingEnabled ? (
                    <NewConversationDialog
                        visible={conversation.showCreateConversationDialog}
                        closeDialog={closeCreateConversationDialog}
                        contact={contact}
                    />
                ) : !conversation.showCreateConversationDialog ? null : (
                    <FullPepUpgrade asDialog={true} onClose={closeCreateConversationDialog} />
                )}
                <EditContactDialog
                    visible={user.showEditUserDialog}
                    closeDialog={closeEditUserDialog}
                    sending={user.sending}
                    requestError={user.updateUserMessage}
                    updateContact={updateUser}
                    archiveContact={archiveUser}
                    contact={contact}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { inboxConversation, inboxUser, auth } = state;
    return {
        conversation: inboxConversation,
        user: inboxUser,
        auth,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    };
}

const bindActionsToDispatch = {
    verifyUser: userAction.verifyUser,
    toggleCreateConversationDialog: conversationAction.toggleCreateConversationDialog,
    openCreateConversationDialog: conversationAction.openCreateConversationDialog,
    closeCreateConversationDialog: conversationAction.closeCreateConversationDialog,
    toggleEditUserDialog: userAction.toggleEditUserDialog,
    updateUser: userAction.updateUser,
    archiveUser: userAction.archiveUser,
    closeEditUserDialog: userAction.closeEditUserDialog,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ContactSection);
