import _ from 'lodash';
import Config from 'config';
import ThirdPartyNames from 'constants/ThirdPartyNames';

export default {
    async getAttributeValue() {
        const { pharmacy, getExternalIdentifier } = this.props;
        const url = `${Config.apiweb_url}/third_party/tiger_pistol/customers`;
        const params = {
            Q: _.get(pharmacy, 'attr.appCode.value', undefined),
        };
        const value = await getExternalIdentifier(url, params, ThirdPartyNames.tigerPistol);

        if (_.isEmpty(value)) {
            throw new Error('No matching ID found - cannot activate this feature');
        }
        return value;
    },
};
