export const SnowplowConstants = {
    success: 'success',
    failure: 'failure',
    opened: 'opened',
    closed: 'closed',
    clicked: 'clicked',
};

export const StructEventCategories = {
    inbox: 'Inbox',
    workflows: 'Workflows',
    appointments: 'Appointments',
    newsletters: 'Newsletters',
    settings: 'Settings',
};
