import Actions from 'redux/actions';
import _ from 'lodash';

export const initialState = {
    isAuthenticated: false, // used to represent if user has active session
    isAuthorized: false, // used to represent if user roles and all other prereqs have been set in app
    userAccount: null, // User account details
    userDetails: [], // User role details - TODO think about renaming
    loginInprogress: false,
    selectedLocation: '',
    selectedCustomer: '',
    loadingUserAccount: false, // TODO is needed?
    loadingUserDetails: false,
    loadingIvrPhone: false,
    updatingIvrPhone: false,
    ivrPhones: [],
    possiblePharmacies: null,
    totalPossiblePharmacies: 0,
    loadingPossiblePharmacies: false,
    isInternalUser: false,
    foundPharmacies: [],
    foundAppCode: '',
    locationDetail: {},
    updatingUserDetail: false,
    loadingUserPreferences: false,
    updatingUserPreferences: false,
    extendedSession: false,
    userPreferences: {
        default_location_id: null,
        reports_widget_sequence: null,
    },
    errors: '',
    forgotPwdresetSentTo: '',
    vowRedirectErrorLocation: undefined,
};

const getLocationsAndCustomersById = (userDetails) => {
    let customers = _.uniqBy(userDetails, 'parentId').map((c) => {
        if (c.parentId) {
            return { parentId: c.parentId, parentName: c.parentName };
        }
        return undefined;
    });
    customers = _.compact(customers);
    const locationsByCustomerId = {};
    const customersById = {};
    _.forEach(customers, (c) => {
        const filteredLocations = _.filter(userDetails, (p) => p.parentId === c.parentId);
        locationsByCustomerId[c.parentId] = filteredLocations;
        customersById[c.parentId] = { entityName: c.parentName, entityId: c.parentId, locations: filteredLocations };
    });
    return {
        customersById,
        locationsByCustomerId,
    };
};

const setUserDetails = (state, payload) => {
    const { customersById, locationsByCustomerId } = getLocationsAndCustomersById(payload);
    return {
        ...state,
        loadingUserDetails: false,
        loadingIvrPhone: false, // TODO verify
        userDetails: payload,
        customersById,
        locationsByCustomerId,
    };
};

const appendUserDetails = (state, payload) => {
    const userDetails = [...state.userDetails, ...payload];
    const { customersById, locationsByCustomerId } = getLocationsAndCustomersById(userDetails);
    return {
        ...state,
        userDetails,
        locationsByCustomerId,
        customersById,
    };
};

const setIvrPhone = (state, payload) => {
    const ivrPhones = payload.data.result.length ? [payload.data.result[0]] : [];
    return {
        ...state,
        ivrPhones,
        loadingIvrPhone: false,
    };
};

// TODO: Move account related information to account store
export function AuthReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.UPDATE_NEWPWD_STARTED:
            return {
                ...state,
                errors: '',
                updatePwdInprogress: true,
            };
        case Actions.UPDATE_NEWPWD_SUCCESS:
            return {
                ...state,
                forgotPwdresetSentTo: payload.email,
                updatePwdInprogress: false,
            };
        case Actions.UPDATE_NEWPWD_FAILED:
            return {
                ...state,
                errors: payload,
                updatePwdInprogress: false,
            };
        case Actions.LOGIN_STARTED:
            return {
                ...state,
                errors: '',
                loginInprogress: true,
            };
        case Actions.LOGIN_SUCCESS:
            return {
                ...state,
                loginInprogress: false,
                userAccount: payload,
                // TODO: eventually this should be picked from state.auth.userAccount
                isInternalUser: payload.is_internal_user || false,
                forgotPwdresetSentTo: '',
                extendedSession: payload.extended_session,
                isAuthenticated: true,
            };
        case Actions.SET_LOGIN_ERR:
            return {
                ...state,
                errors: payload ? payload : '',
            };
        case Actions.LOGIN_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                isAuthorized: false,
                errors: payload,
                loginInprogress: false,
                userAccount: null,
            };
        case Actions.UPDATE_AUTH_STATUS:
            return {
                ...state,
                isAuthorized: payload.isAuthorized,
                errors: payload.err || '',
            };
        case Actions.FETCH_USER_ACCOUNT_STARTED:
            return {
                ...state,
                loadingUserAccount: true,
            };
        case Actions.FETCH_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingUserAccount: false,
                // TODO: eventually this should be picked from state.auth.userAccount
                isInternalUser: payload.is_internal_user,
                userAccount: payload,
                isAuthenticated: true,
            };
        case Actions.FETCH_USER_ACCOUNT_FAILED:
            return {
                ...state,
                loadingUserAccount: false,
                userAccount: null,
                isAuthenticated: false,
                isAuthorized: false,
            };
        case Actions.FETCH_USER_DETAIL_STARTED:
            return {
                ...state,
                loadingUserDetails: true,
                loadingIvrPhone: true,
            };
        case Actions.FETCH_USER_DETAIL_SUCCESS:
            return setUserDetails(state, payload);
        case Actions.FETCH_USER_DETAIL_FAILED:
            return {
                ...state,
                loadingUserDetails: false,
                loadingIvrPhone: false, // TODO verify
                userDetails: [],
            };
        case Actions.UPDATE_CUSTOMER:
            return { ...state, selectedCustomer: payload };
        case Actions.UPDATE_LOCATION:
            return { ...state, selectedLocation: payload };
        case Actions.FETCH_IVR_PHONE_STARTED:
            return {
                ...state,
                loadingIvrPhone: true,
            };
        case Actions.FETCH_IVR_PHONE_SUCCESS:
            return setIvrPhone(state, payload);
        case Actions.FETCH_IVR_PHONE_FAILED:
            return {
                ...state,
                ivrPhones: [],
                loadingIvrPhone: false,
            };
        case Actions.UPDATE_IVR_PHONE_STARTED:
            return {
                ...state,
                updatingIvrPhone: true,
            };
        case Actions.UPDATE_IVR_PHONE_SUCCESS:
        case Actions.UPDATE_IVR_PHONE_FAILED:
            return {
                ...state,
                updatingIvrPhone: false,
            };
        case Actions.FETCH_PHARMACY_STARTED:
            return {
                ...state,
                foundAppCode: '',
            };
        case Actions.FETCH_PHARMACY_SUCCESS:
            return {
                ...state,
                foundPharmacies: payload.foundPharmacies,
                foundAppCode: payload.foundAppCode,
            };
        case Actions.FETCH_PHARMACY_FAILED:
            return {
                ...state,
                foundAppCode: '',
            };
        case Actions.FETCH_PHARMACIES_STARTED:
            return {
                ...state,
                loadingPossiblePharmacies: true,
            };
        case Actions.FETCH_PHARMACIES_SUCCESS:
            if (!payload.load_more) {
                return {
                    ...state,
                    possiblePharmacies: payload.possible_pharmacies,
                    totalPossiblePharmacies: payload.total_count,
                    loadingPossiblePharmacies: false,
                };
            } else {
                const { possiblePharmacies } = state;
                return {
                    ...state,
                    possiblePharmacies: possiblePharmacies.concat(payload.possible_pharmacies),
                    loadingPossiblePharmacies: false,
                };
            }
        case Actions.FETCH_PHARMACIES_FAILED:
            if (payload.err) {
                return {
                    ...state,
                    loadingPossiblePharmacies: false,
                };
            }
            return {
                ...state,
            };
        case Actions.CLEAR_PHARMACIES:
            return {
                ...state,
                possiblePharmacies: null,
                totalPossiblePharmacies: 0,
                loadingPossiblePharmacies: false,
            };
        case Actions.FETCH_PHARMACY_LOCATIONS_SUCCESS:
            return appendUserDetails(state, payload);
        case Actions.FETCH_PHARMACY_LOCATIONS_FAILED:
            return {
                ...state,
            };
        case Actions.FETCH_LOCATION_DETAIL_SUCCESS:
            return { ...state, locationDetail: payload.data };
        case Actions.UPDATE_USER_ACCOUNT_STARTED:
            return {
                ...state,
                updatingUserDetail: true,
            };
        case Actions.UPDATE_USER_ACCOUNT_SUCCESS:
        case Actions.UPDATE_USER_ACCOUNT_FAILED:
            return {
                ...state,
                updatingUserDetail: false,
            };
        case Actions.FETCH_USER_PREFERENCES_STARTED:
            return {
                ...state,
                loadingUserPreferences: true,
            };
        case Actions.FETCH_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
                loadingUserPreferences: false,
                userPreferences: payload,
            };
        case Actions.FETCH_USER_PREFERENCES_FAILED:
            return {
                ...state,
                loadingUserPreferences: false,
                userPreferences: null,
            };
        case Actions.UPDATE_USER_PREFERENCES_STARTED:
        case Actions.ADD_USER_PREFERENCES_STARTED:
            return {
                ...state,
                updatingUserPreferences: true,
            };
        case Actions.ADD_USER_PREFERENCES_SUCCESS:
        case Actions.ADD_USER_PREFERENCES_FAILED:
        case Actions.UPDATE_USER_PREFERENCES_SUCCESS:
        case Actions.UPDATE_USER_PREFERENCES_FAILED:
            return {
                ...state,
                updatingUserPreferences: false,
            };
        case Actions.SET_VOW_REDIRECT_ERROR_LOCATION: {
            return {
                ...state,
                vowRedirectErrorLocation: payload,
            };
        }
        default:
            return state;
    }
}

export default AuthReducer;
