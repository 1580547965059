import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    image: {
        width: '100%',
        height: '264px',
        boxShadow:
            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
    },
};

const Screenshot = ({ classes }) => (
    <img src="/classes_screenshot.png" alt="Classes Screenshot" className={`bordered-box ${classes.image}`} />
);

export default withStyles(styles)(Screenshot);
