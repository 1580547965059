import React from 'react';
import { Slider, TextField } from 'react-md';

function SliderForROIMetrics(props) {
    const handleChange = (value, event, id) => {
        props.valueChange(value, event, id);
    };

    return (
        <div className="ROICalculator__SlidingROIValues_fullSliderComponent">
            <div className="ROICalculator__SlidingROIValues--metric">
                <span>{props.sliderTitle}</span>
            </div>
            <div className="ROICalculator__SlidingROIValues--editableTextValue">
                {props.units === '$' ? props.units : ''}
                <TextField
                    id={props.sliderId}
                    className={`ROICalculator__SlidingROIValues--textField ${props.units !== '$' && 'per-time'}`}
                    type="number"
                    value={props.value}
                    onChange={(value, event) => handleChange(value, event, props.sliderId)}
                    block
                />
                {props.units !== '$' ? props.units : ''}
            </div>
            <div>
                <span className="ROICalculator__SlidingROIValues--minValue">
                    {(props.units === '$' ? props.units : '') +
                        (props.value < props.min ? props.value : props.min) +
                        (props.units !== '$' ? ' ' + props.units : '')}
                </span>
                <span className="ROICalculator__SlidingROIValues--maxValue">
                    {(props.units === '$' ? props.units : '') +
                        (props.value > props.max ? props.value : props.max) +
                        (props.units !== '$' ? ' ' + props.units : '')}
                </span>
            </div>
            <div>
                <Slider
                    id={props.sliderId}
                    value={props.value}
                    onChange={(value, event) => handleChange(value, event, props.sliderId)}
                    min={props.value < props.min ? props.value : props.min}
                    max={props.value > props.max ? props.value : props.max}
                />
            </div>
        </div>
    );
}

export default SliderForROIMetrics;
