import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { TextField, Button, FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BirthdayMask, PhoneNumberMask } from '../../utils/mask';
import config from '../../config';
import {
    toDigits,
    isEmptyString,
    formatRawDate,
    cleanString,
    getDateInputFormatByCountryCode,
    getPhonePlaceholderByCountryCode,
} from '../../utils/helper';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { userAction } from 'redux/Inbox/User';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

const baseContactState = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phoneNumber: '',
    errors: {},
};

class newContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...baseContactState,
            phoneNumber: props.vmPhone || '',
        };

        this.commonProps = {
            fullWidth: true,
            variant: 'outlined',
            margin: 'normal',
            required: true,
            onChange: this.handleChange,
        };
    }

    componentDidUpdate = (prevProps) => {
        const { showNewContactModal, user } = this.props;
        if (!showNewContactModal && prevProps.showNewContactModal) {
            this.resetState();
        }

        if (prevProps.user.sending && !user.sending && !user.createUserMessage) {
            // Hacky way to handle closing on success
            this.handleClose({ success: true });
        }
    };

    resetState = () => {
        this.setState({ ...baseContactState });
    };

    handleClose = ({ success } = {}) => {
        const { handleNewContactModalClose, clearCreateUserMessage } = this.props;
        handleNewContactModalClose({ success });
        clearCreateUserMessage();
        this.resetState();
    };

    validateDate = (rawDate) => {
        const { dateFormat } = this.props;
        const date = moment(rawDate, dateFormat);
        const tomorrow = moment(new Date()).add(1, 'days');
        const baseDate = moment('01/01/1900', dateFormat);
        if (!date.isValid()) {
            return 'Please enter a valid date';
        } else if (!date.isBetween(baseDate, tomorrow)) {
            return `Date needs to be between ${baseDate.format(dateFormat)} - ${tomorrow.format(dateFormat)}`;
        }

        return false;
    };

    validatePhoneNumber = (phoneNumber) => {
        if (toDigits(phoneNumber).length < 10) {
            return 'Please enter a 10 digit phone number';
        }

        return false;
    };

    getValidationErrors = () => {
        const { firstName, lastName, dateOfBirth, phoneNumber } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.firstName = isEmptyString(cleanString(firstName)) ? emptyMessage : false;
        errors.lastName = isEmptyString(cleanString(lastName)) ? emptyMessage : false;
        errors.dateOfBirth = isEmptyString(dateOfBirth) ? emptyMessage : this.validateDate(dateOfBirth);
        errors.phoneNumber = isEmptyString(phoneNumber) ? emptyMessage : this.validatePhoneNumber(phoneNumber);

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);

        return errors;
    };

    buildPayload = () => {
        const { dateFormat } = this.props;
        const { firstName, lastName, dateOfBirth, phoneNumber } = this.state;
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            first_name: cleanString(firstName),
            last_name: cleanString(lastName),
            date_of_birth: formatRawDate(dateOfBirth, dateFormat, 'YYYY-MM-DD'),
            phone: toDigits(phoneNumber),
        };

        return payload;
    };

    handleSubmit = () => {
        const { createUser } = this.props;

        // Validate inputs
        const errors = this.getValidationErrors();
        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            // Validation passed
            createUser(this.buildPayload());
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        const {
            user,
            showNewContactModal,
            fromOnboarding,
            activeLocationCountryCode,
            phonePlaceholder,
            dateFormat,
        } = this.props;
        const { firstName, lastName, dateOfBirth, phoneNumber, errors } = this.state;

        const heading = fromOnboarding ? 'Check out how messaging works' : 'Add a new patient';
        const labelPrefix = fromOnboarding ? 'Your ' : '';
        const buttonText = fromOnboarding ? 'Send your first message' : 'Done';

        return (
            <DpDialog.Dialog id="createUserDialog" open={showNewContactModal} onClose={this.handleClose} maxWidth="md">
                <DpDialog.Content>
                    <Grid container>
                        <Hidden smDown>
                            <Grid container justify="center" md={5}>
                                {fromOnboarding ? (
                                    <img
                                        width={236}
                                        height={314}
                                        src={`/inbox/onboarding-message.png`}
                                        srcSet={`/inbox/onboarding-message.png 1x, /inbox/onboarding-message_2x.png 2x, /inbox/onboarding-message_3x.png 3x`}
                                        alt="Send Message"
                                    />
                                ) : (
                                    <img
                                        width={236}
                                        height={314}
                                        src={`/inbox/onboarding-patients.png`}
                                        srcSet={`/inbox/onboarding-patients.png 1x, /inbox/onboarding-patients_2x.png 2x, /inbox/onboarding-patients_3x.png 3x`}
                                        alt="Add Patient"
                                    />
                                )}
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={7}>
                            <DpDialog.ContentHeader heading={heading} onClose={this.handleClose}>
                                {fromOnboarding ? (
                                    <Fragment>
                                        Add yourself as a contact and send your first message. Make sure to{' '}
                                        <strong>allow browser notifications</strong> to see new messages.
                                    </Fragment>
                                ) : (
                                    ' '
                                )}
                            </DpDialog.ContentHeader>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        label={`${labelPrefix}First Name`}
                                        value={firstName}
                                        autoFocus
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                        {...this.commonProps}
                                        style={{ marginTop: 0 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        label={`${labelPrefix}Last Name`}
                                        value={lastName}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                        {...this.commonProps}
                                        style={{ marginTop: 0 }}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                type="text"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                label={`${labelPrefix}Date of Birth`}
                                placeholder={dateFormat}
                                value={dateOfBirth}
                                InputProps={{
                                    inputComponent: BirthdayMask,
                                    inputProps: {
                                        pattern: '[0-9]*',
                                        inputMode: 'numeric',
                                    },
                                }}
                                error={!!errors.dateOfBirth}
                                helperText={errors.dateOfBirth}
                                {...this.commonProps}
                            />
                            <TextField
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                label={`${labelPrefix}Phone Number`}
                                placeholder={phonePlaceholder}
                                value={phoneNumber}
                                InputProps={{
                                    inputComponent: PhoneNumberMask,
                                    inputProps: {
                                        pattern: '[0-9]*',
                                        inputMode: 'numeric',
                                        countryCode: activeLocationCountryCode,
                                    },
                                }}
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber}
                                {...this.commonProps}
                            />
                            {user.createUserMessage ? (
                                <FormHelperText error>{user.createUserMessage}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                </DpDialog.Content>
                <DpDialog.Actions>
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        disabled={user.sending}
                        variant="contained"
                        disableElevation
                        style={{ minWidth: fromOnboarding ? '200px' : '80px' }}
                    >
                        {user.sending ? (
                            <CircularProgress size={20} className="create-user-dialog__progress" />
                        ) : (
                            buttonText
                        )}
                    </Button>
                </DpDialog.Actions>
            </DpDialog.Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { inboxUser } = state;
    const activeLocationCountryCode = pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state);
    return {
        user: inboxUser,
        activeLocationCountryCode,
        dateFormat: getDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode }),
        phonePlaceholder: getPhonePlaceholderByCountryCode({ countryCode: activeLocationCountryCode }),
    };
}

const bindActionsToDispatch = {
    createUser: userAction.createUser,
    clearCreateUserMessage: userAction.clearCreateUserMessage,
};

export default connect(mapStateToProps, bindActionsToDispatch)(newContactDialog);
