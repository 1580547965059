import './AppointmentTypeContent.sass';
import React, { useState, useEffect } from 'react';
import { Button, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { getTemplateImageUrl } from 'components/Patients/patientsUtil';
import ScheduleIcon from '@material-ui/icons/Schedule';
import classnames from 'classnames';
import EventIcon from '@material-ui/icons/Event';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppointmentBlockSelector from 'components/Patients/scheduling/session/AppointmentBlockSelector';
import DateRangeIcon from '@material-ui/icons/DateRange';
import pluralize from 'pluralize';

export default function AppointmentTypeContent({
    appointmentTypes,
    setAppointmentTypes,
    selected,
    onChange,
    setIsOneOffType,
    calendars,
    setShowingAppointmentBlocks,
    showingAppointmentBlocks,
    apptReservation,
    getLiveReusableReservationSessions,
    hasLoadedAppointmentBlocks,
    setHasLoadedAppointmentBlocks,
}) {
    const props = arguments[0];

    useEffect(() => {
        if (!hasLoadedAppointmentBlocks) {
            getLiveReusableReservationSessions();
            setHasLoadedAppointmentBlocks(true);
        }
    }, []);

    const activeReservationSessions = _.get(apptReservation, 'liveReusableSessions.response.live_sessions');

    const [calendarId, setCalendarId] = useState(null);
    const [appointmentLengthMinutes, setAppointmentLengthMinutes] = useState(null);
    const [doseInfo, setDoseInfo] = useState(null);
    const [timeRange, setTimeRange] = useState({
        from: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
        to: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
    });

    return (
        <div className="appointment-type-content">
            <div className="heading">
                <div className="title">Choose type of appointment</div>
                <div className="manual-create">
                    or{' '}
                    <Button
                        data-cy="one-off-appt-button"
                        className="one-off-button"
                        onClick={() => setIsOneOffType(true)}
                    >
                        create as one-off appointment
                    </Button>
                </div>
            </div>

            <div className="sub-heading">
                Please select one of the following appointment types to see available dates & times.
            </div>

            <div className="available-types">
                {_.map(appointmentTypes, (appointmentType, i) => {
                    return (
                        <AppointmentTypeRow
                            key={i}
                            appointmentType={appointmentType}
                            onClick={(apptType) => {
                                if (supportsAppointmentBlocks(apptType)) {
                                    setShowingAppointmentBlocks(apptType);
                                } else {
                                    const clone = _.clone(appointmentTypes);
                                    clone.splice(i, 1, apptType);
                                    setAppointmentTypes(clone);
                                    onChange(apptType);
                                }
                            }}
                            selected={appointmentType === selected}
                            calendars={calendars}
                            activeReservationSessions={activeReservationSessions}
                        />
                    );
                })}
            </div>

            <div className="advertisement">
                <div className="heading">
                    <div className="title">Upcoming Appointment Types</div>
                </div>
                <Tooltip title="Additional appointment types and scheduling workflows coming soon...">
                    <img
                        className="coming-soon-advertisement"
                        src={require('./upcoming_appt_types.png')}
                        alt="Coming Soon"
                    />
                </Tooltip>
            </div>

            {_.isNil(showingAppointmentBlocks) ? null : (
                <div className="appointment-blocks-content">
                    <div className="appointment-type-and-back-button">
                        <div className="back-button-wrapper">
                            <Tooltip title="Go back to choosing an appointment type.">
                                <IconButton
                                    className="go-back-button"
                                    onClick={() => {
                                        setShowingAppointmentBlocks(null);
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <AppointmentTypeRow
                            appointmentType={showingAppointmentBlocks}
                            calendars={calendars}
                            activeReservationSessions={activeReservationSessions}
                        />
                    </div>

                    <AppointmentBlockSelector
                        {...props}
                        calendars={calendars}
                        totalWidth={870}
                        loadSessionsOnMount={false}
                        calendar={_.find(calendars, (c) => c.calendar_id === calendarId)}
                        subLabel={
                            'Please choose which appointment block you would like to schedule this appointment on.'
                        }
                        allowContinueWithoutSelection={true}
                        onAppointmentBlocksModified={() => {
                            getLiveReusableReservationSessions();
                        }}
                        onNext={() => {
                            onChange(showingAppointmentBlocks);
                        }}
                        {...{ calendarId, setCalendarId }}
                        {...{ appointmentLengthMinutes, setAppointmentLengthMinutes }}
                        {...{ timeRange, setTimeRange }}
                        {...{ doseInfo, setDoseInfo }}
                    />
                </div>
            )}
        </div>
    );
}

function AppointmentTypeRow({ appointmentType, selected, onClick, calendars, activeReservationSessions }) {
    const [chosenCalendarId] = useState(
        _.get(appointmentType, 'calendar.calendar_id', _.get(_.first(calendars), 'calendar_id'))
    );

    const calendar = _.find(
        calendars,
        ({ calendar_id }) =>
            calendar_id === _.get(appointmentType, 'calendar.calendar_id', _.get(_.first(calendars), 'calendar_id'))
    );

    const hasAppointmentBlocks = supportsAppointmentBlocks(appointmentType);
    const numberAppointmentBlocks = _.size(
        _.filter(
            activeReservationSessions,
            ({ appt_type_id }) => appt_type_id === 'a448c312-cb31-4492-87fc-6e442a2f6118'
        )
    );

    return (
        <div
            className={classnames({ 'appointment-type-row': true, selected })}
            onClick={() =>
                onClick({
                    ...appointmentType,
                    appointment_calendar_id: chosenCalendarId,
                    calendar: _.find(calendars, (c) => c.calendar_id === chosenCalendarId),
                })
            }
        >
            <div
                className="image-box"
                style={{
                    backgroundImage: `url(${getTemplateImageUrl({ image_ref: appointmentType.image_ref })})`,
                    backgroundColor: '#eee',
                    backgroundSize: 'cover',
                    width: '135px',
                    height: '90px',
                }}
            />
            <div className="text">
                <div className="title">{appointmentType.appointment_label}</div>

                {!hasAppointmentBlocks ? (
                    <div className="details">
                        <div className="length">
                            <ScheduleIcon /> {appointmentType.appointment_length_minutes} minutes
                        </div>
                        <div className="calendar">
                            <EventIcon style={{ color: _.get(calendar, 'color') }} />{' '}
                            <span className="calendar-name">{_.get(calendar, 'name')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="details">
                        {_.isNil(activeReservationSessions) ? (
                            <div className="appt-blocks-available">
                                <CircularProgress size={18} />
                                &nbsp; Loading appointment blocks...
                            </div>
                        ) : (
                            <div className="appt-blocks-available">
                                <DateRangeIcon /> {numberAppointmentBlocks} available appointment{' '}
                                {pluralize('block', numberAppointmentBlocks)}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function supportsAppointmentBlocks(apptType) {
    return apptType.appointment_label === 'COVID-19 Vaccination';
}
