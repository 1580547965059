import './PatientSelectContent.sass';
import React from 'react';
import PatientNameSearch from 'components/Patients/PatientNameSearch';

export default function PatientSelectContent({ selected, onChange }) {
    const props = arguments[0];
    return (
        <div className="patient-select-content">
            <div className="heading">
                <div className="title">Choose Patient to Schedule</div>
            </div>
            <PatientNameSearch
                {...props}
                width={550}
                height={570}
                autoFocus={true}
                onSelect={(patient) => {
                    onChange(patient);
                }}
            />
        </div>
    );
}
