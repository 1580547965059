import React, { useEffect } from 'react';

const InfiniteScrollContainer = React.forwardRef(
    (
        {
            children,
            onScrollBottom = () => console.warn('[InfiniteScrollContainer] onScrollBottom not implemented'),
            offset = 0.2,
            debounce = 100,
        },
        ref
    ) => {
        const onScroll = () => {
            if (ref.current) {
                const { scrollTop, scrollHeight, clientHeight } = ref.current;
                // check if bottom is reached
                if (scrollTop + clientHeight >= scrollHeight - offset) {
                    debounceScroll(() => onScrollBottom && onScrollBottom(), debounce);
                }
            }
        };

        const debounceScroll = (func, timeout) => {
            let timer;
            unBindScrollEvent();
            clearTimeout(timer);
            func();
            timer = setTimeout(() => {
                clearTimeout(timer);
                timer = undefined;
                bindScrollEvent();
            }, timeout);
        };

        const unBindScrollEvent = () => {
            if (ref.current) ref.current.onscroll = null;
        };

        const bindScrollEvent = () => {
            if (ref.current) ref.current.onscroll = onScroll;
        };

        useEffect(() => {
            if (ref.current) bindScrollEvent();

            return unBindScrollEvent;
        }, []);

        return children;
    }
);

export default InfiniteScrollContainer;
