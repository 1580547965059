import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import FormRowTextFields from './FormRowTextFields';
import FormRowCheckboxes from './FormRowCheckboxes';

export const GeneralDetails = (props) => {
    const { app, editMode, handleTextChange, handleCheckboxValueChange, panelId } = props;
    const onTextChange = (event) => handleTextChange(event, panelId);
    const onCheckboxChange = (event) => handleCheckboxValueChange(event, panelId);

    return (
        <div className="collapse-content">
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'name',
                            value: _.get(app, 'name.value', ''),
                            label: 'App Name',
                            required: _.get(app, 'name.required', false),
                            error: _.get(app, 'name.error', ''),
                            disabled: !editMode,
                        }}
                        field2={{
                            key: 'displayName',
                            value: _.get(app, 'displayName.value', ''),
                            label: 'Display Name',
                            required: _.get(app, 'displayName.required', false),
                            error: _.get(app, 'displayName.error', ''),
                            disabled: !editMode,
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'itunesConnectEmail',
                            value: _.get(app, 'itunesConnectEmail.value', ''),
                            label: 'iTunes Connect Email',
                            allowTextCopy: true,
                            required: _.get(app, 'itunesConnectEmail.required', false),
                            error: _.get(app, 'itunesConnectEmail.error', ''),
                            disabled: !editMode,
                        }}
                        field2={{
                            key: 'itunesConnectPassword',
                            value: _.get(app, 'itunesConnectPassword.value', ''),
                            label: 'iTunes Connect Password',
                            allowTextCopy: true,
                            isPassword: true,
                            required: _.get(app, 'itunesConnectPassword.required', false),
                            error: _.get(app, 'itunesConnectPassword.error', ''),
                            disabled: !editMode,
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'teamId',
                            value: _.get(app, 'teamId.value', ''),
                            label: 'iTunes Connect Team ID',
                            allowTextCopy: true,
                            required: _.get(app, 'teamId.required', false),
                            error: _.get(app, 'teamId.error', ''),
                            disabled: !editMode,
                        }}
                        field2={{
                            key: 'teamName',
                            value: _.get(app, 'teamName.value', ''),
                            label: 'iTunes Connect Team Name',
                            allowTextCopy: true,
                            required: _.get(app, 'teamName.required', false),
                            error: _.get(app, 'teamName.error', ''),
                            disabled: !editMode,
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'androidIdentifier',
                            value: _.get(app, 'androidIdentifier.value', ''),
                            label: 'Android Identifier',
                            allowTextCopy: true,
                            required: _.get(app, 'androidIdentifier.required', false),
                            error: _.get(app, 'androidIdentifier.error', ''),
                            disabled: !editMode,
                        }}
                        field2={{
                            key: 'appBundleIdentifier',
                            value: _.get(app, 'appBundleIdentifier.value', ''),
                            label: 'Apple Identifier',
                            allowTextCopy: true,
                            required: _.get(app, 'appBundleIdentifier.required', false),
                            error: _.get(app, 'appBundleIdentifier.error', ''),
                            disabled: !editMode,
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'shortDescription',
                            value: _.get(app, 'shortDescription.value', ''),
                            label: 'Short Description',
                            rows: 4,
                            required: _.get(app, 'shortDescription.required', false),
                            error: _.get(app, 'shortDescription.error', ''),
                            disabled: !editMode,
                        }}
                        field2={{
                            key: 'longDescription',
                            value: _.get(app, 'longDescription.value', ''),
                            label: 'Long Description',
                            rows: 4,
                            required: _.get(app, 'longDescription.required', false),
                            error: _.get(app, 'longDescription.error', ''),
                            disabled: !editMode,
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowCheckboxes
                        field1={{
                            key: 'charged',
                            value: _.get(app, 'charged.value', false),
                            label: 'Apple Paid In Chargify',
                            disabled: !editMode,
                            required: _.get(app, 'charged.required', false),
                        }}
                        field2={{
                            key: 'isSearchable',
                            value: _.get(app, 'isSearchable.value', false),
                            label: 'App Is Searchable',
                            disabled: !editMode,
                            required: _.get(app, 'isSearchable.required', false),
                        }}
                        handleCheckboxValueChange={onCheckboxChange}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
