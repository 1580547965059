import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import _ from 'lodash';
import moment from 'moment';

function getAvailableAppointmentCalendars(pharmacyId, locationId) {
    return restActionCreators
        .get(`${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function createNewCalendar(pharmacyId, locationId, baseCalendar) {
    return restActionCreators
        .post(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar`,
            baseCalendar
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function deleteCalendar(pharmacyId, locationId, calendarId) {
    return restActionCreators
        .deleteReq(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function updateCalendar(pharmacyId, locationId, calendarId, calendar) {
    return restActionCreators
        .put(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}`,
            calendar
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getAvailableCalendarAppointmentSlots(
    pharmacyId,
    locationId,
    calendarId,
    start_date,
    start_time,
    end_date,
    end_time,
    appointmentLengthMinutes
) {
    const params = _.filter([
        `start_date=${start_date}`,
        _.isNil(start_time) ? null : `start_time=${start_time}`,
        `end_date=${end_date}`,
        _.isNil(end_time) ? null : `end_time=${end_time}`,
        `expected_length=${appointmentLengthMinutes}`,
    ]);

    return restActionCreators
        .get(
            `${config.apiweb_url}${
                api.APPT_API_BASE
            }/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/slots?${params.join('&')}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getCalendarReservations(pharmacyId, locationId, calendarId, startDate, startTime, endDate, endTime) {
    const params = [`start_date=${startDate}`, `start_time=${startTime}`, `end_date=${endDate}`, `end_time=${endTime}`];
    return restActionCreators
        .get(
            `${config.apiweb_url}${
                api.APPT_API_BASE
            }/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarId}/reservation?${params.join('&')}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getCalendarAppointmentCounts(pharmacyId, locationId, calendarIds, startDate, endDate, label) {
    const params = _.filter([
        `start_date=${startDate}`,
        `end_date=${endDate}`,
        `timezone_offset=${moment().format('Z')}`,
        _.isNil(label) ? null : `label=${label}`,
    ]);
    return restActionCreators
        .get(
            `${config.apiweb_url}${
                api.APPT_API_BASE
            }/pharmacy/${pharmacyId}/location/${locationId}/calendar/${calendarIds.join(
                ','
            )}/active-reservation-counts?${params.join('&')}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getSessionInfo(pharmacyId, locationId, sessionId) {
    return restActionCreators
        .get(
            `${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/session/${sessionId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const apptCalendarService = {
    getAvailableAppointmentCalendars,
    createNewCalendar,
    deleteCalendar,
    updateCalendar,
    getAvailableCalendarAppointmentSlots,
    getCalendarReservations,
    getCalendarAppointmentCounts,
    getSessionInfo,
};
export default apptCalendarService;
