import React, { useEffect } from 'react';
import { setBulkMessageStatusShown } from '../../../redux/Patient/Connect/action';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Snackbar, Button } from '@material-ui/core';
import classnames from 'classnames';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import { getTemplateImageUrl } from '../patientsUtil';

function BulkMessageStatusSnackbar({ connect, setBulkMessageStatusShown }) {
    const showMessageStatus =
        (!_.isNil(connect.bulkMessageResponse) || !_.isNil(connect.bulkMessageError)) &&
        !connect.bulkMessageStatusShown;

    const {
        inbox_bulk_create_conversation_total_items: userCount,
        conversations_queued_successfully: successCount,
    } = _.get(connect, 'bulkMessageResponse.inbox_response.bulk_create_conversation_data', {});
    const messageStatusSuccess = userCount === successCount;

    useEffect(() => {
        // equivalent to componentWillUnmount
        return () => {
            if (!connect.bulkMessageStatusShown) {
                setBulkMessageStatusShown(true);
            }
        };
    }, []);

    return (
        <Snackbar
            className={classnames({
                'bulk-message-status-snackbar': true,
                failed: !messageStatusSuccess,
            })}
            open={showMessageStatus}
            onClose={() => setBulkMessageStatusShown(true)}
            autoHideDuration={10000}
            message={
                messageStatusSuccess ? (
                    <div className="bulk-message-status-banner">
                        <div
                            className="image-box"
                            style={{
                                backgroundImage: `url(${getTemplateImageUrl(_.get(connect, 'bulkMessageTemplate'))})`,
                                backgroundColor: '#eee',
                                backgroundSize: 'cover',
                                width: '60px',
                                height: '40px',
                            }}
                        />
                        <div className="text">
                            <div className="main-line">
                                Successfully messaged {numeral(successCount).format('0,0')}{' '}
                                {pluralize('patient', successCount)}
                            </div>
                            <div className="sub-line">Any replies to these messages will show up in your Inbox</div>
                        </div>
                    </div>
                ) : (
                    <div className="bulk-message-status-banner">
                        <PhonelinkRingIcon className="phone-icon" />
                        <div className="text">
                            <div className="main-line">
                                Unable to message {numeral(successCount).format('0,0')}{' '}
                                {pluralize('patient', successCount)}
                            </div>
                            <div className="sub-line">
                                A technical issue prevented us from sending messages. Please try again later
                            </div>
                        </div>
                    </div>
                )
            }
            action={<Button onClick={() => setBulkMessageStatusShown(true)}>OK</Button>}
        />
    );
}

function mapStateToProps(state) {
    const { connect } = state;

    return {
        connect,
    };
}

const bindActionsToDispatch = {
    setBulkMessageStatusShown,
};

export default connect(mapStateToProps, bindActionsToDispatch)(BulkMessageStatusSnackbar);
