import React, { Fragment } from 'react';
import { Card, CardText, Grid, Cell } from 'react-md';

import EmptyCard from './EmptyCard';
import ReportTitle from 'components/Report/Common/ReportTitle';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';

const TITLES_MAP = {
    sendDate: 'Send Date',
    recipients: 'Recipients',
    views: 'Views',
    unsubscribers: 'Unsubscribers',
    bounces: 'Bounces',
};

const mapData = (dataToMap) => {
    if (Object.keys(dataToMap).length === 0 || !dataToMap) return { data: [] };

    const mappedData = Object.keys(TITLES_MAP).map((t) => {
        return {
            title: TITLES_MAP[t],
            value: dataToMap[t],
        };
    });

    return {
        data: mappedData,
    };
};

const checkIfDateRow = (title) => {
    return title.toLowerCase().indexOf('date') > -1 ? true : false;
};

const getDateClassName = (data, isEmptyResponse) => {
    if (!checkIfDateRow(data.title)) return '';
    return isEmptyResponse ? 'invalid-date' : 'date';
};

const DataCard = ({ data, showLoader, isEmptyResponse, tooltipText }) => (
    <Card className="reportCard newsLetter">
        <ReportTitle title="eNewsletter" className="cardTitle" tooltipText={tooltipText} />
        <CardText className={showLoader ? 'padding0 flex-grow-1 flex-middle' : 'flex-grow-1'}>
            {!showLoader ? (
                <Fragment>
                    <Grid stacked className="height100 flex-space-around">
                        {data.data.map((d) => (
                            <Cell size={12} tabletSize={8} phoneSize={4} key={d.title}>
                                <Grid noSpacing>
                                    <Cell size={4} tabletSize={4} phoneSize={2} className="text-align-left title">
                                        {d.title}
                                    </Cell>
                                    <Cell
                                        size={8}
                                        tabletSize={4}
                                        phoneSize={2}
                                        className={`text-align-right value ${getDateClassName(d, isEmptyResponse)}`}
                                    >
                                        {d.value !== null
                                            ? d.value
                                                  .toString()
                                                  .split('\n')
                                                  .map((item, i) => <div key={i}> {item} </div>)
                                            : '-'}
                                    </Cell>
                                </Grid>
                            </Cell>
                        ))}
                    </Grid>
                    {data.footer && (
                        <div className="footer text-align-center">
                            {data.footer.title}: {data.footer.value}
                        </div>
                    )}
                </Fragment>
            ) : (
                <TabularTypePlaceholder legendsCount={6} style={{ height: '20px' }} />
            )}
        </CardText>
    </Card>
);

const Newsletter = (props) => {
    const { data, enabled, tooltipText } = props;
    const mappedData = mapData(data.data || {});
    const isEmptyResponse = data.isEmptyResponse;
    const showLoader = Object.keys(mappedData.data).length === 0;

    if (!enabled) {
        return (
            <EmptyCard
                title="eNewsletter"
                imgPath="/reports/newsletter.png"
                helperText="You don’t have eNewsletter yet."
                appType="newsLetter"
                tooltipText={tooltipText}
            />
        );
    }

    return (
        <DataCard
            data={mappedData}
            showLoader={showLoader}
            isEmptyResponse={isEmptyResponse}
            tooltipText={tooltipText}
        />
    );
};

export default Newsletter;
