const CTA_BUTTON_TITLES = {
    tabs: {
        inbox: 'Purchase 2-Way Messaging',
        voicemail: 'Purchase an IVR',
        refills: 'Purchase Prescription Refill Tools',
        leads: 'Purchase Lead Tracking',
        wellnessClasses: 'Purchase Wellness Classes',
        digitalMarketingDashboard: 'Purchase Digital Marketing',
        autoAttendant: 'Purchase an IVR',
        newsletter: 'Purchase eNewsletter',
    },
    reports: {
        digitalPlatform: 'Purchase Prescription Refill Tools',
        communicationsPlatform: 'Purchase an IVR',
        mobileDownloads: 'Purchase a Mobile App',
        websiteTraffic: 'Purchase a Healthsite',
        newsLetter: 'Purchase eNewsletter',
        digitalPlatformPatients: 'Purchase Prescription Refill Tools',
        digitalMarketing: 'Purchase Digital Marketing',
        reputationManagement: 'Purchase Reputation Management',
        directMessaging: 'Purchase 2-Way Messaging',
    },
};

const EMPTY_STATE_PAGE = 'empty-state-page';
const SNOWPLOW_EVENT_DETAILS = {
    tabs: {
        inbox: {
            property: 'Inbox',
            label: EMPTY_STATE_PAGE,
        },
        voicemail: {
            property: 'Voicemail',
            label: EMPTY_STATE_PAGE,
        },
        refills: {
            property: 'Refill Digest',
            label: EMPTY_STATE_PAGE,
        },
        leads: {
            property: 'Leads',
            label: EMPTY_STATE_PAGE,
        },
        wellnessClasses: {
            property: 'Classes',
            label: EMPTY_STATE_PAGE,
        },
        digitalMarketingDashboard: {
            property: 'DM Dashboard',
            label: EMPTY_STATE_PAGE,
        },
        autoAttendant: {
            property: 'IVR Auto Attendant',
            label: EMPTY_STATE_PAGE,
        },
        newsletter: {
            property: 'e-Newsletter',
            label: EMPTY_STATE_PAGE,
        },
    },
    reports: {
        digitalPlatform: {
            property: 'Digital Platform Refills',
            label: EMPTY_STATE_PAGE,
        },
        communicationsPlatform: {
            property: 'IVR Platform',
            label: EMPTY_STATE_PAGE,
        },
        mobileDownloads: {
            property: 'Mobile Downloads',
            label: EMPTY_STATE_PAGE,
        },
        websiteTraffic: {
            property: 'Website',
            label: EMPTY_STATE_PAGE,
        },
        newsLetter: {
            property: 'e-Newsletter',
            label: EMPTY_STATE_PAGE,
        },
        digitalPlatformPatients: {
            property: 'Platform Patients',
            label: EMPTY_STATE_PAGE,
        },
        digitalMarketing: {
            property: 'Digital Marketing',
            label: EMPTY_STATE_PAGE,
        },
        reputationManagement: {
            property: 'Reputation Management',
            label: EMPTY_STATE_PAGE,
        },
        directMessaging: {
            property: 'Direct Messaging',
            label: EMPTY_STATE_PAGE,
        },
    },
};

export default {
    tabs: {
        inbox: {
            title: 'Add 2-Way Messaging',
            subtitle: null,
            learnMore: {
                title: 'Learn About Messaging',
                link: 'https://help.digitalpharmacist.com/en/articles/3996146-why-choose-direct-messaging',
            },
            description:
                'Save time and drastically reduce your return-to-stock by having a direct line to your patients.',
            valueProps: [
                {
                    icon: 'important_devices',
                    title: 'Mobile and Web Apps',
                    body: 'Message your patients on PocketRx',
                },
                {
                    icon: 'camera_enhance',
                    title: 'Share Photos',
                    body: 'Securely collect insurance cards',
                },
                {
                    icon: 'assignment_turned_in',
                    title: 'HIPAA-Compliant',
                    body: 'Send/receive messages & images safely and securely',
                },
            ],
            additionalDetails: {
                header: 'Additional Information:',
                items: ['The patient app is not required to send messages'],
            },
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.inbox,
            emailSubject: 'Direct Messaging (inbox)',
        },
        voicemail: {
            title: 'Add an IVR',
            subtitle: null,
            learnMore: {
                title: 'Learn About IVR',
                link: 'https://help.digitalpharmacist.com/en/articles/3996153-why-choose-an-ivr',
            },
            description:
                'Support pharmacy operations with our cloud-based, HIPAA-compliant Interactive Voice Response (IVR) system.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Manage incoming calls, no matter what the volume',
                },
                {
                    icon: 'ring_volume',
                    title: 'Always Available',
                    body: 'Receive refill requests 24 hours a day',
                },
                {
                    icon: 'tune',
                    title: 'Easy To Manage',
                    body: 'Automate and simplify greetings, call forwarding, voicemail and more',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.voicemail,
            emailSubject: 'an IVR',
        },
        refills: {
            title: 'Add Prescription Refill Tools',
            subtitle: null,
            learnMore: {
                title: 'Learn About Refill Tools',
                link: 'https://help.digitalpharmacist.com/en/articles/3996111-how-to-get-more-digital-refills',
            },
            description: 'Receive refill requests both online and over the phone 24 hours a day.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Allow patients to request refills 24/7',
                },
                {
                    icon: 'devices',
                    title: 'Mobile, Web & IVR',
                    body: 'Patients can submit prescription refill requests over any channel',
                },
                {
                    icon: 'local_pharmacy',
                    title: 'View Prescription Info',
                    body: 'Use our online dashboard to view the refills submitted in real time',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.refills,
            emailSubject: 'Refill Digest',
        },
        leads: {
            title: 'Add Lead Tracking',
            subtitle: null,
            learnMore: {
                title: 'Learn About Leads',
                link: 'https://help.digitalpharmacist.com/en/articles/3616578-what-is-lead-tracking',
            },
            description: 'Check out our Digital Marketing package and start attracting new patients today!',
            valueProps: [
                {
                    icon: 'people',
                    title: 'Track New Patients',
                    body: 'Measure the number of new patients generated by different marketing strategies',
                },
                {
                    icon: 'phone_in_talk',
                    title: 'Keep It Local',
                    body: 'We set up local call lines on your Healthsite, Facebook page, and for any special campaigns',
                },
                {
                    icon: 'format_list_number',
                    title: 'View Your Leads',
                    body: "We log each call that comes in so you'll never miss any potential patients",
                },
            ],
            additionalDetails: {
                header: 'Digital Marketing program includes:',
                items: [
                    'Facebook & Instagram advertising',
                    'Monthly downloadable social posts',
                    'Wellness classes',
                    'Reviews page on Healthsite',
                    'Call and lead tracking',
                ],
            },
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.leads,
            emailSubject: 'Leads',
        },
        wellnessClasses: {
            title: 'Add Wellness Classes',
            subtitle: null,
            learnMore: {
                title: 'Learn About Classes',
                link: 'https://help.digitalpharmacist.com/en/articles/3996097-why-choose-digital-marketing',
            },
            description:
                'Deliver pharmacist-created interactive wellness classes to your patients online, in-store, or in your community.',
            valueProps: [
                {
                    icon: 'dashboard',
                    title: 'One Location',
                    body: 'Find everything you need in your dashboard',
                },
                {
                    icon: 'school',
                    title: 'Educational Flexibility',
                    body:
                        'Educate patients in weekly group classes or one-on-one for a completely personal learning experience',
                },
                {
                    icon: 'local_library',
                    title: 'Our Library',
                    body: 'Wide variety of content and material',
                },
            ],
            additionalDetails: {
                header: 'Interactive Wellness Classes cover:',
                items: [
                    'Diabetes Maintenance',
                    'Smoking Cessation',
                    'Weight Loss',
                    'High Blood Pressure',
                    'High Cholesterol',
                    'Asthma',
                ],
            },
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.wellnessClasses,
            emailSubject: 'Wellness Classes',
        },
        digitalMarketingDashboard: {
            title: 'Add Digital Marketing',
            subtitle: null,
            learnMore: {
                title: 'Learn About DM',
                link: 'https://help.digitalpharmacist.com/en/articles/3996097-why-choose-digital-marketing',
            },
            description:
                'Get started with our Digital Marketing program to start attracting new patients and engage with your community today.',
            valueProps: [
                {
                    icon: 'thumb_up_alt',
                    title: 'Facebook Ads',
                    body: 'Grow your business by placing your pharmacy in front of more people within your community',
                },
                {
                    icon: 'policy',
                    title: 'Verified Facebook Marketing Partner',
                    body: 'Experts on best Facebook ad practices & ad policies',
                },
                {
                    icon: 'trending_up',
                    title: 'Call Tracking & Lead Management',
                    body: 'Measure the number of new patients generated by your marketing',
                },
            ],
            additionalDetails: {
                header: 'Digital Marketing program includes:',
                items: [
                    'Facebook & Instagram advertising',
                    'Monthly downloadable social posts',
                    'Wellness classes',
                    'Reviews page on Healthsite',
                    'Call and lead tracking',
                ],
            },
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.digitalMarketingDashboard,
            emailSubject: 'DM Dashboard',
        },
        autoAttendant: {
            title: 'Add an IVR',
            subtitle: null,
            learnMore: {
                title: 'Learn About IVR',
                link: 'https://help.digitalpharmacist.com/en/articles/3996153-why-choose-an-ivr',
            },
            description:
                'Support pharmacy operations with our cloud-based, HIPAA-compliant Interactive Voice Response (IVR) system.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Manage incoming calls, no matter what the volume',
                },
                {
                    icon: 'ring_volume',
                    title: 'Always Available',
                    body: 'Receive refill requests 24 hours a day',
                },
                {
                    icon: 'tune',
                    title: 'Easy To Manage',
                    body: 'Automate and simplify greetings, call forwarding, voicemail and more',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.autoAttendant,
            emailSubject: 'an IVR (auto attendant)',
        },
        newsletter: {
            title: 'Add eNewsletters',
            subtitle: null,
            learnMore: {
                title: 'Learn About eNewsletter',
                link:
                    'https://help.digitalpharmacist.com/en/articles/3038238-why-email-marketing-matters-to-your-pharmacy',
            },
            description: 'Engage your customers with custom or Digital Pharmacist-created content on a weekly basis.',
            valueProps: [
                {
                    icon: 'menu_book',
                    title: 'Great Content',
                    body: 'Send a weekly newsletter containing curated content written by our pharmacist or staff',
                },
                {
                    icon: 'person_add',
                    title: 'Grow Your Subscribers',
                    body:
                        'An email signup can be added directly to your Healthsite to help you grow your subscription list',
                },
                {
                    icon: 'mail',
                    title: 'Customizable',
                    body: 'Each newsletter is customized with your pharmacy branding and logo',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.tabs.newsletter,
            emailSubject: 'a Newsletter',
        },
    },
    reports: {
        digitalPlatform: {
            title: 'Add Prescription Refill Tools',
            subtitle: null,
            learnMore: {
                title: 'Learn About Refill Tools',
                link: 'https://help.digitalpharmacist.com/en/articles/3996111-how-to-get-more-digital-refills',
            },
            description: 'Receive refill requests both online and over the phone 24 hours a day.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Allow patients to request refills 24/7',
                },
                {
                    icon: 'devices',
                    title: 'Mobile, Web & IVR',
                    body: 'Patients can submit prescription refill requests over any channel',
                },
                {
                    icon: 'local_pharmacy',
                    title: 'View Prescription Info',
                    body: 'Use our online dashboard to view the refills submitted in real time',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.digitalPlatform,
            emailSubject: 'Core (platform)',
        },
        communicationsPlatform: {
            title: 'Add an IVR',
            subtitle: null,
            learnMore: {
                title: 'Learn About IVR',
                link: 'https://help.digitalpharmacist.com/en/articles/3996153-why-choose-an-ivr',
            },
            description:
                'Support pharmacy operations with our cloud-based, HIPAA-compliant Interactive Voice Response (IVR) system.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Manage incoming calls, no matter what the volume',
                },
                {
                    icon: 'ring_volume',
                    title: 'Always Available',
                    body: 'Receive refill requests 24 hours a day',
                },
                {
                    icon: 'tune',
                    title: 'Easy To Manage',
                    body: 'Automate and simplify greetings, call forwarding, voicemail, and more',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.communicationsPlatform,
            emailSubject: 'an IVR',
        },
        mobileDownloads: {
            title: 'Add a Mobile App or Healthsite',
            subtitle: null,
            learnMore: {
                title: 'Learn About Mobile App',
                link: 'https://help.digitalpharmacist.com/en/articles/3996105-why-choose-pocketrx',
            },
            description: 'Protect and support patient health with a branded Healthsite and mobile app.',
            valueProps: [
                {
                    icon: 'devices',
                    title: 'Simple & Easy',
                    body: 'The Healthsite & app make refills and reaching patients easy',
                },
                {
                    icon: 'local_pharmacy',
                    title: 'Support Patient Health',
                    body: 'More than a website; it protects and supports patient health',
                },
                {
                    icon: 'tune',
                    title: 'Customizable',
                    body: 'Customizable branded Healthsites and apps',
                },
                {
                    icon: 'lock',
                    title: 'Safe & Secure',
                    body: 'HIPAA-compliant solutions integrate with over 70 PMSes',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.mobileDownloads,
            emailSubject: 'a Mobile App',
        },
        websiteTraffic: {
            title: 'Add a Mobile App or Healthsite',
            subtitle: null,
            learnMore: {
                title: 'Learn About Healthsites',
                link: 'https://help.digitalpharmacist.com/en/articles/3996106-why-choose-a-healthsite',
            },
            description: 'Protect and support patient health with a branded Healthsite and mobile app.',
            valueProps: [
                {
                    icon: 'devices',
                    title: 'Simple & Easy',
                    body: 'The Healthsite & app make refills and reaching patients easy',
                },
                {
                    icon: 'local_pharmacy',
                    title: 'Support Patient Health',
                    body: 'More than a website; it protects and supports patient health',
                },
                {
                    icon: 'tune',
                    title: 'Customizable',
                    body: 'Customizable branded Healthsites and apps',
                },
                {
                    icon: 'lock',
                    title: 'Safe & Secure',
                    body: 'HIPAA-compliant solutions integrate with over 70 PMSes',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.websiteTraffic,
            emailSubject: 'a Website',
        },
        newsLetter: {
            title: 'Add eNewsletters',
            subtitle: null,
            learnMore: {
                title: 'Learn About eNewsletter',
                link:
                    'https://help.digitalpharmacist.com/en/articles/3038238-why-email-marketing-matters-to-your-pharmacy',
            },
            description: 'Engage your customers with custom or Digital Pharmacist-created content on a weekly basis.',
            valueProps: [
                {
                    icon: 'menu_book',
                    title: 'Great Content',
                    body: 'Send a weekly newsletter containing curated content written by our pharmacist or staff',
                },
                {
                    icon: 'person_add',
                    title: 'Grow Your Subscribers',
                    body:
                        'An email signup can be added directly to your Healthsite to help you grow your subscription list',
                },
                {
                    icon: 'mail',
                    title: 'Customizable',
                    body: 'Each newsletter is customized with your pharmacy branding and logo',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.newsLetter,
            emailSubject: 'a Newsletter',
        },
        digitalPlatformPatients: {
            title: 'Add Prescription Refill Tools',
            subtitle: null,
            learnMore: {
                title: 'Learn About Refill Tools',
                link: 'https://help.digitalpharmacist.com/en/articles/3996111-how-to-get-more-digital-refills',
            },
            description: 'Allow customers to submit refills digitally and over the phone with our solutions.',
            valueProps: [
                {
                    icon: 'schedule',
                    title: 'Save Time',
                    body: 'Allow patients to request refills 24/7',
                },
                {
                    icon: 'devices',
                    title: 'Mobile, Web & IVR',
                    body: 'Patients can submit prescription refill requests over any channel',
                },
                {
                    icon: 'local_pharmacy',
                    title: 'View Prescription Info',
                    body: 'Use our online dashboard to view the refills submitted in real time',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.digitalPlatformPatients,
            emailSubject: 'Core (patients)',
        },
        digitalMarketing: {
            title: 'Add Digital Marketing',
            subtitle: null,
            learnMore: {
                title: 'Learn About DM',
                link: 'https://help.digitalpharmacist.com/en/articles/3996097-why-choose-digital-marketing',
            },
            description:
                'Get started with our Digital Marketing program to start attracting new patients and engage with your community today.',
            valueProps: [
                {
                    icon: 'thumb_up_alt',
                    title: 'Facebook Ads',
                    body: 'Grow your business by placing your pharmacy in front of more people within your community',
                },
                {
                    icon: 'policy',
                    title: 'Verified Facebook Marketing Partner',
                    body: 'Experts on best Facebook ad practices & ad policies',
                },
                {
                    icon: 'trending_up',
                    title: 'Call Tracking & Lead Management',
                    body: 'Measure the number of new patients generated by your marketing',
                },
            ],
            additionalDetails: {
                header: 'Digital Marketing program includes:',
                items: [
                    'Facebook & Instagram advertising',
                    'Monthly downloadable social posts',
                    'Wellness classes',
                    'Reviews page on Healthsite',
                    'Call and lead tracking',
                ],
            },
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.digitalMarketing,
            emailSubject: 'Digital Marketing',
        },
        reputationManagement: {
            title: 'Add Reputation Management',
            subtitle: null,
            learnMore: {
                title: 'Learn About RM',
                link: 'https://help.digitalpharmacist.com/en/articles/3996142-why-choose-reputation-management',
            },
            description: 'Get started with our Reputation Management program to maintain a positive online presence.',
            valueProps: [
                {
                    icon: 'trending_up',
                    title: 'Improve Your Reputation',
                    body: 'Boost your online reputation with Digital Pharmacist’s white-glove service',
                },
                {
                    icon: 'feedback',
                    title: 'Get Feedback',
                    body: 'Our team solicits and generates feedback from active patients on your behalf',
                },
                {
                    icon: 'rate_review',
                    title: 'Help Resolve Issues',
                    body: 'We direct any negative responses back to you for resolution',
                },
                {
                    icon: 'search',
                    title: 'Review Monitoring',
                    body: 'We monitor all your ratings on Google, Yelp, and Facebook',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.reputationManagement,
            emailSubject: 'Reputation Management',
        },
        directMessaging: {
            title: 'Add 2-Way Messaging',
            subtitle: null,
            learnMore: {
                title: 'Learn About Messaging',
                link: 'https://help.digitalpharmacist.com/en/articles/3996146-why-choose-direct-messaging',
            },
            description:
                'Save time and drastically reduce your return-to-stock by having a direct line to your patients.',
            valueProps: [
                {
                    icon: 'important_devices',
                    title: 'Mobile and Web Apps',
                    body: 'Message your patients on PocketRx',
                },
                {
                    icon: 'camera_enhance',
                    title: 'Share Photos',
                    body: 'Securely collect insurance cards',
                },
                {
                    icon: 'assignment_turned_in',
                    title: 'HIPAA-Compliant',
                    body: 'Send/receive messages and images safely and securely',
                },
            ],
            additionalDetails: null,
            ctaButtonTitle: CTA_BUTTON_TITLES.reports.directMessaging,
            emailSubject: 'Direct Messaging',
        },
    },
};

export { CTA_BUTTON_TITLES, SNOWPLOW_EVENT_DETAILS };
