import React, { PureComponent } from 'react';
import { Card, Cell, CardText, Grid, Button, CircularProgress } from 'react-md';
import { connect } from 'react-redux';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import { displayToast } from 'redux/actionCreators/Snackbar';
import {
    addPatientLine,
    updatePatientGreetingMsg,
    closePatientGreetingEditor,
    editPatientGreeting,
    changePatientGreetingType,
    changePatientGreetingScript,
} from 'redux/actionCreators/Settings/LeaveMessage';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    saveGreeting,
    fileUpload,
    saveFilename,
} from 'redux/actionCreators/Settings/MainGreeting';
import GreetingEditor from 'components/Common/GreetingEditor';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class GeneralLine extends PureComponent {
    componentDidMount() {
        const { loadGreetingFiles, fetchPatientGreeting } = this.props;
        loadGreetingFiles();
        fetchPatientGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, loadGreetingFiles, fetchPatientGreeting } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadGreetingFiles();
            fetchPatientGreeting();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    async onSave({ fileObj, editedFileName, filenameWithExt }) {
        const {
            newPatientGreeting,
            defaultGreeting,
            loadGreetingFiles,
            fetchPatientGreeting,
            saveFile,
            saveGreeting,
            fileUpload,
            closeGreetingEditor,
            displayToast,
        } = this.props;

        const greetingPayload = { id: defaultGreeting.id, patient_greeting_id: newPatientGreeting.patient_greeting_id };

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            greetingPayload.patient_greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            await saveFile({ name: newFilename, id: newPatientGreeting.patient_greeting_id });
        }

        const saveMsgResp = await saveGreeting({ newGreeting: { ...greetingPayload }, type: 'PATIENT' });
        if (saveMsgResp.err) {
            displayToast({ text: 'Patient line save failed', type: 'error' });
        } else {
            fetchPatientGreeting();
            closeGreetingEditor();
            displayToast({ text: 'Patient line saved', type: 'success' });
        }
    }

    removePatientGreeting = () => {
        const { fetchPatientGreeting, saveGreeting, displayToast, defaultGreeting } = this.props;
        const newGreeting = { id: defaultGreeting.id, patient_greeting_id: '' };
        saveGreeting({ newGreeting, type: 'PATIENT' }).then((saveMsgResp) => {
            if (saveMsgResp.err) {
                displayToast({ text: 'Patient line remove failed', type: 'error' });
            } else {
                fetchPatientGreeting();
                displayToast({ text: 'Patient line removed', type: 'success' });
            }
        });
    };

    render() {
        const {
            displayToast,
            newGreeting,
            changePatientGreetingType,
            changePatientGreetingScript,
            addGreeting,
            newPatientGreeting,
            showPatientGreetingCreator,
            closeGreetingEditor,
            updateGreetingMessage,
            greetingFiles,
            defaultGreeting,
            loadingMainGreeting,
            editPatientGreeting,
            isFilenameSaving,
            auth: { selectedCustomer },
        } = this.props;

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });
        const { create, update } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.generalVoiceMail;

        return (
            <Grid>
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader title="General Voicemail" onBack={this.onBack} />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="General Voicemail Prompt" />
                                    <SectionSubTitle text="This is the prompt that callers will hear when they select the option to leave a message" />
                                    {loadingMainGreeting ? (
                                        <CircularProgress id="patient-greeting-spinner" />
                                    ) : (
                                        <span className="greetings-list">
                                            <h5 className="sub-title-text">
                                                By default the IVR will play the prompt:
                                                <span className="italic-style">
                                                    {' '}
                                                    After the tone, please leave your name, phone number and a detailed
                                                    message. When you have finished, press any key.
                                                </span>
                                            </h5>

                                            <SectionSubTitle text="You can record your own custom message below to override the default prompt" />

                                            <div className="grid-container table-title">
                                                {defaultGreeting['patient_greeting_type'] !== 'text/plain' && (
                                                    <Cell size={1} tabletSize={1} phoneSize={1}>
                                                        &nbsp;
                                                    </Cell>
                                                )}
                                                <Cell
                                                    size={
                                                        defaultGreeting[`patient_greeting_type`] === 'text/plain'
                                                            ? 4
                                                            : 3
                                                    }
                                                    tabletSize={2}
                                                    phoneSize={1}
                                                >
                                                    Title
                                                </Cell>
                                                <Cell size={1} tabletSize={1} phoneHidden>
                                                    Status
                                                </Cell>
                                                <Cell size={7} tabletSize={2} phoneSize={2}>
                                                    &nbsp;
                                                </Cell>
                                            </div>

                                            {defaultGreeting && defaultGreeting.patient_greeting_id && (
                                                <Can I={update} this={caslSubject} passThrough>
                                                    {(updateAllowed) => (
                                                        <span className="main-greeting">
                                                            <Greetings
                                                                className="blue-bordered"
                                                                type="patient"
                                                                greetingDetail={defaultGreeting}
                                                                greetingFileList={greetingFileList}
                                                                handleEdit={() => editPatientGreeting(defaultGreeting)}
                                                                isDeleting={newGreeting.processing}
                                                                handleDelete={this.removePatientGreeting}
                                                                hideActions={!updateAllowed}
                                                            />
                                                        </span>
                                                    )}
                                                </Can>
                                            )}

                                            {defaultGreeting && !defaultGreeting.patient_greeting_id && (
                                                <div className="grid-container">
                                                    <Cell size={12} className="centered-content no-greeting-msg">
                                                        No greetings configured
                                                    </Cell>
                                                </div>
                                            )}

                                            <Grid>
                                                <Can I={create} this={caslSubject} passThrough>
                                                    {(allowed) => (
                                                        <Cell size={12} className="centered-content button-control">
                                                            {allowed && !defaultGreeting.patient_greeting_id && (
                                                                <Button
                                                                    className="add-btn"
                                                                    flat
                                                                    secondary
                                                                    onClick={addGreeting}
                                                                >
                                                                    + ADD A NEW GREETING
                                                                </Button>
                                                            )}
                                                            {!allowed && !defaultGreeting.patient_greeting_id && (
                                                                <div>
                                                                    You don't have permission to configure greeting
                                                                </div>
                                                            )}
                                                        </Cell>
                                                    )}
                                                </Can>
                                            </Grid>
                                        </span>
                                    )}
                                </CardText>
                            </Card>

                            <GreetingEditor
                                custPerms={selectedCustomer.perms}
                                isSaving={isFilenameSaving || newGreeting.processing}
                                dialogTitle={
                                    newPatientGreeting.id ? 'Edit voicemail greeting' : 'Add a new voicemail greeting'
                                }
                                isVisible={showPatientGreetingCreator}
                                greetingFiles={greetingFiles}
                                greeting={newPatientGreeting}
                                greetingId={newPatientGreeting.patient_greeting_id || ''}
                                greetingUrl={newPatientGreeting.patient_greeting_url}
                                greetingType={newPatientGreeting.patient_greeting_type_id}
                                greetingScript={newPatientGreeting.patient_greeting_text}
                                handleChange={(value) => updateGreetingMessage({ ...value, greetingFiles })}
                                closeHandler={() => closeGreetingEditor()}
                                saveHandler={(data) => this.onSave(data)}
                                showToastMessage={(data) => displayToast(data)}
                                changeGreetingType={(type) => changePatientGreetingType(type)}
                                changeGreetingScript={(msg) => changePatientGreetingScript(msg)}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.leaveMessage,
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchPatientGreeting: () => dispatch(fetchMainGreeting()),
    addGreeting: () => dispatch(addPatientLine()),
    updateGreetingMessage: (data) => dispatch(updatePatientGreetingMsg(data)),
    closeGreetingEditor: () => dispatch(closePatientGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    editPatientGreeting: (data) => dispatch(editPatientGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changePatientGreetingType: (data) => dispatch(changePatientGreetingType(data)),
    changePatientGreetingScript: (data) => dispatch(changePatientGreetingScript(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLine);
