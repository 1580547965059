import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import _ from 'lodash';
import { logUtil } from '../../../utils/log';
import moment from 'moment';
import { preprocessMessageTemplateText } from 'components/Patients/messageTemplateUtil';
import { getLocationNameFromAuth } from 'utils/helper';

const loadPatientConnectOpportunities = (pharmacyId, locationId, birthdayDate) => {
    const params = _.filter([`today=${encodeURIComponent(birthdayDate)}`]);
    return restActionCreators
        .get(
            `${config.apiweb_url}${
                api.PATIENT_QUERY_BASE
            }/patients/${pharmacyId}/${locationId}/opportunities?${params.join('&')}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(
                `An error occurred on patient-search for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const resetPatientOpportunitiesForSales = (pharmacyId, locationId) => {
    return restActionCreators
        .post(
            `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/reset-patient-opportunities`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(
                `An error occurred resetting patient opportunties for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const sendBulkMessage = (pharmacyId, locationId, template, inboxUserIds, auth) => {
    const locationName = getLocationNameFromAuth(auth);

    const data = {
        users: inboxUserIds,
        pharmacy_id: pharmacyId,
        location_id: locationId,
        subject: template.subject,
        message: preprocessMessageTemplateText(template.message, auth),
        inbox_conversation_template_id: template.inbox_conversation_template_id,
        template,
        location_name: locationName,
    };

    if (_.isString(template.targeting_type)) {
        data.opportunity = template.targeting_type;
    }

    const today = moment().format('YYYY-MM-DD');

    return restActionCreators
        .post(
            `${config.apiweb_url}${api.PATIENT_QUERY_BASE}/patients/${pharmacyId}/${locationId}/message?today=${today}`,
            data
        )
        .then((response) => response)
        .catch((error) => {
            logUtil.logHttpError(
                `An error occurred on patient-message for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const connectService = {
    loadPatientConnectOpportunities,
    sendBulkMessage,
    resetPatientOpportunitiesForSales,
};
export default connectService;
