import './PatientAppointments.sass';
import AppointmentRow from 'components/Appointments/AppointmentRow';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkinReservation, uncheckinReservation, getReservationsForInboxUserId } from 'redux/Appt/Reservation/action';
import _ from 'lodash';
import moment from 'moment';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import { Button, CircularProgress } from '@material-ui/core';
import { selectPastAndFutureReservationsForInboxUserId } from 'redux/Appt/appointmentSelectors';

function PatientAppointments({
    patient,
    getReservationsForInboxUserId,
    calendarsById,
    displayedDateFormat,
    appointments,
    isLoading,
    appointmentsToShow = 'upcoming',
    onEditAppointment,
    onCreateAppointment,
}) {
    const props = arguments[0];

    const visibleAppointments = appointmentsToShow === 'upcoming' ? appointments.future : appointments.past;

    // load appointments for this patient
    const inboxUserId = _.get(patient, 'inbox_user_id');
    useEffect(() => {
        if (!_.isNil(inboxUserId)) {
            getReservationsForInboxUserId(inboxUserId);
        }
    }, [inboxUserId]);

    if (isLoading) {
        return (
            <div className="patient-appointments loading">
                <CircularProgress />
            </div>
        );
    }

    if (_.isEmpty(visibleAppointments)) {
        return (
            <div className="patient-appointments empty">
                <div className="empty-message">
                    <div className="main-line">This patient has no {appointmentsToShow} appointments.</div>
                    {appointmentsToShow !== 'upcoming' ? null : (
                        <div className="sub-line">
                            <Button color="primary" onClick={() => onCreateAppointment(patient)}>
                                Click here
                            </Button>
                            &nbsp;to add one.
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="patient-appointments">
            {_.map(visibleAppointments, (appointment) => {
                const calendar = _.get(calendarsById, appointment.calendar_id);
                return (
                    <AppointmentRow
                        key={appointment.reservation_id}
                        {...props}
                        {...appointment}
                        displayedDateFormat={displayedDateFormat}
                        startTime={moment.tz(
                            moment.utc(appointment.start_date, 'YYYY-MM-DDTHH:mm:ss'),
                            _.get(calendar, 'timezone')
                        )}
                        endTime={moment.tz(
                            moment.utc(appointment.end_date, 'YYYY-MM-DDTHH:mm:ss'),
                            _.get(calendar, 'timezone')
                        )}
                        appointmentLabel={appointment.label}
                        reservation={appointment}
                        showPatientInfo={false}
                        patient={patient}
                        calendar={calendar}
                        onEdit={() => onEditAppointment(appointment)}
                    />
                );
            })}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        calendarsById: _.keyBy(
            _.get(state.apptCalendar, 'availableCalendars.calendars', []),
            ({ calendar_id }) => calendar_id
        ),
        displayedDateFormat: getAbbreviatedDateInputFormatByCountryCode({
            countryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        }),
        appointments: selectPastAndFutureReservationsForInboxUserId(state),
        isLoading: _.get(state.apptReservation, 'reservationsForInboxUserId.loading'),
    };
}

const bindActionsToDispatch = {
    getReservationsForInboxUserId,
    checkinReservation,
    uncheckinReservation,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientAppointments);
