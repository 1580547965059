import _ from 'lodash';

const parse = JSON.parse;
const stringify = JSON.stringify;

export default {
    setLastVisitedLocation: (userId, data) => {
        if (_.isUndefined(localStorage.lastVisitedLocation)) {
            localStorage.lastVisitedLocation = stringify({});
        }
        const lastVisitedLocation = parse(localStorage.lastVisitedLocation);
        lastVisitedLocation[userId] = data;
        localStorage.lastVisitedLocation = stringify(lastVisitedLocation);
    },
    getLastVisitedLocation: (userId) => {
        const lastVisitedLocation = localStorage.lastVisitedLocation
            ? parse(localStorage.lastVisitedLocation)
            : undefined;
        if (lastVisitedLocation && lastVisitedLocation[userId]) {
            return lastVisitedLocation[userId];
        }
        return null;
    },

    setPreferredNameFormat: (format) => {
        if (format === 'last_first' || format === 'first_last') {
            localStorage.preferredNameFormat = format;
        }
    },
    getPreferredNameFormat: () => {
        return _.get(localStorage, 'preferredNameFormat', 'last_first');
    },

    setCovidWaitlistDismissed: (dismissed) => {
        // Setting this here will dismiss it for any location accessed via that browser (which seems fine to me...)
        if (_.isBoolean(dismissed)) {
            localStorage.covidWaitlistDismissed = dismissed;
        }
    },
    getCovidWaitlistDismissed: () => {
        return _.get(localStorage, 'covidWaitlistDismissed') === 'true';
    },

    getApptTypesAdDismissed: () => {
        return _.get(localStorage, 'apptTypesAdDismissed') === 'true';
    },

    setApptTypesAdDismissed: (dismissed) => {
        if (_.isBoolean(dismissed)) {
            localStorage.apptTypesAdDismissed = dismissed;
        }
    },
};
