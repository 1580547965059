import { get, post, deleteReq } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import Actions from 'redux/actions';
import { fetchMainGreeting, saveGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../../utils/log';
import * as axios from 'axios';

const getLangUrl = (id) => {
    const languageApiPath = API_PATH.LANGUAGE.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', id);
    return Config.config2_url + languageApiPath;
};

export const fetchSysLangs = () => (dispatch) => {
    const sysLangApiPath = API_PATH.SYS_LANG;
    const sysLangApiUrl = Config.config2_url + sysLangApiPath;

    const header = { params: { numRows: 99 } };
    dispatch({ type: Actions.FETCH_SYS_LANGUAGE_STARTED });
    const fetchLangChoicesReq = get(sysLangApiUrl, header);

    return fetchLangChoicesReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_SYS_LANGUAGE_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchSysLangs', err);
            dispatch({ type: Actions.FETCH_SYS_LANGUAGE_FAILED });
            return { err };
        });
};

export const fetchLangChoices = (data) => (dispatch) => {
    const langChoicesApiUrl = getLangUrl(data.id);
    const header = { params: { numRows: 99 } };
    dispatch({ type: Actions.FETCH_IVR_LANGUAGE_CHOICES_STARTED });
    const fetchLangChoicesReq = get(langChoicesApiUrl, header);

    return fetchLangChoicesReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_IVR_LANGUAGE_CHOICES_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchLangChoicesReq', err);
            dispatch({ type: Actions.FETCH_IVR_LANGUAGE_CHOICES_FAILED });
            return { err };
        });
};

export const fetchLangWithGreeting = (canCreateGreeting = false) => (dispatch) => {
    dispatch({ type: Actions.FETCH_IVR_LANG_GREETING_STARTED });
    dispatch(fetchMainGreeting()).then((resp) => {
        if (resp.err) {
            if (canCreateGreeting) {
                dispatch(saveGreeting({ newGreeting: {}, type: 'MAIN' })).then(() => {
                    dispatch(fetchMainGreeting()).then((data) => {
                        dispatch(fetchLangChoices(data));
                        dispatch(fetchIvrLang(data));
                    });
                });
            } else {
                dispatch({ type: Actions.FETCH_IVR_LANGUAGE_CHOICES_FAILED });
            }
        } else {
            dispatch(fetchLangChoices(resp));
            dispatch(fetchIvrLang(resp));
        }
    });
};

export const fetchIvrLang = (ivrData) => (dispatch) => {
    const ivrLangApiPath = API_PATH.IVR_LANG.replace('{custID}', Config.X_PharmacyID);
    const ivrLangApiUrl = Config.config2_url + ivrLangApiPath;
    const header = { params: { numRows: 99, ttsId: ivrData.tts_id } };
    dispatch({ type: Actions.FETCH_IVR_LANGUAGES_STARTED });
    const fetchIvrLangReq = get(ivrLangApiUrl, header);

    return fetchIvrLangReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_IVR_LANGUAGES_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchIvrLangReq', err);
            dispatch({ type: Actions.FETCH_IVR_LANGUAGES_FAILED });
            return { err };
        });
};

export const fetchIvrTTS = () => (dispatch) => {
    const ivrTTSApiPath = API_PATH.IVR_TTS;
    const ivrTTSApiUrl = Config.config2_url + ivrTTSApiPath;
    const header = { params: { numRows: 99 } };
    dispatch({ type: Actions.FETCH_IVR_TTS_STARTED });
    const fetchIvrLangReq = get(ivrTTSApiUrl, header);

    return fetchIvrLangReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_IVR_TTS_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchIvrTTS', err);
            dispatch({ type: Actions.FETCH_IVR_TTS_FAILED });
            return { err };
        });
};

export const deleteLang = (data) => (dispatch) => {
    const ivrLangApiPath = API_PATH.IVR_LANG.replace('{custID}', Config.X_PharmacyID);
    const ivrLangApiUrl = Config.config2_url + ivrLangApiPath;

    dispatch({ type: Actions.REMOVE_LANGUAGES_STARTED });

    const delLangReq = [];
    data.forEach((val) => delLangReq.push(deleteReq(`${ivrLangApiUrl}/${val}`)));

    return axios
        .all(delLangReq)
        .then((data) => {
            dispatch({ type: Actions.REMOVE_LANGUAGES_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on deleteLang', err);
            dispatch({ type: Actions.REMOVE_LANGUAGES_FAILED });
            return { err };
        });
};

export const saveLang = (data) => (dispatch) => {
    const ivrLangApiPath = API_PATH.IVR_LANG.replace('{custID}', Config.X_PharmacyID);
    const ivrLangApiUrl = Config.config2_url + ivrLangApiPath;

    dispatch({ type: Actions.SAVE_LANGUAGES_STARTED });
    const fetchIvrLangReq = post(ivrLangApiUrl, data);

    return fetchIvrLangReq
        .then((data) => {
            dispatch({ type: Actions.SAVE_LANGUAGES_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on saveIvrLang', err);
            dispatch({ type: Actions.SAVE_LANGUAGES_FAILED });
            return { err };
        });
};

export const saveLangChoice = (payload) => (dispatch) => {
    const langChoicesApiUrl = getLangUrl(payload.id);
    dispatch({ type: Actions.SAVE_IVR_LANGUAGE_CHOICES_STARTED });
    const saveLangChoiceReq = post(langChoicesApiUrl, payload.langChoices);

    return saveLangChoiceReq
        .then((data) => {
            dispatch({ type: Actions.SAVE_IVR_LANGUAGE_CHOICES_SUCCESS });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on saveLangChoiceReq', err);
            dispatch({ type: Actions.SAVE_IVR_LANGUAGE_CHOICES_FAILED });
            return { err };
        });
};

export const removeLangChoice = (payload) => (dispatch) => {
    let langChoicesApiUrl = getLangUrl(payload.defaultGreeting.id);
    langChoicesApiUrl = `${langChoicesApiUrl}/${payload.lang.id}`;
    dispatch({ type: Actions.REMOVE_IVR_LANGUAGE_CHOICE_STARTED, payload });
    const removeChoiceReq = deleteReq(langChoicesApiUrl);

    return removeChoiceReq
        .then((data) => {
            dispatch({ type: Actions.REMOVE_IVR_LANGUAGE_CHOICE_SUCCESS, payload });
            dispatch(displayToast({ text: 'Language choice removed', type: 'success' }));
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on removeChoiceReq', err);
            dispatch({ type: Actions.REMOVE_IVR_LANGUAGE_CHOICE_FAILED, payload });
            dispatch(displayToast({ text: 'Language choice remove failed', type: 'error' }));
            return { err };
        });
};

export const deleteNewLangChoice = (payload) => (dispatch) => {
    if (payload.lang.id === '0') {
        dispatch({ type: Actions.REMOVE_IVR_LANGUAGE_CHOICE_SUCCESS, payload });
    }
};

export const addLangChoice = () => ({
    type: Actions.ADD_IVR_LANGUAGE,
});

export const updateVoice = (payload) => ({
    type: Actions.UPDATE_IVR_LANGUAGE_VOICE,
    payload,
});

export const updateLang = (payload) => ({
    type: Actions.UPDATE_IVR_LANGUAGE_CHOICE,
    payload,
});

export const updateValidationMsg = (payload) => ({
    type: Actions.UPDATE_VALIDATION_MSG,
    payload,
});

export const addLanguage = (payload) => ({
    type: Actions.ADD_LANGUAGE,
    payload,
});

export const removeLanguage = (payload) => ({
    type: Actions.REMOVE_LANGUAGE,
    payload,
});
