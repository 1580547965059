import _ from 'lodash';
import { messageType } from './type';
import { messageService } from './service';
import { onboardingActions } from '../Onboarding';
import config from '../../../config';
import Snowplow, { InboxMessageContext, SnowplowConstants } from '../../../snowplow';

const resetMessageStore = () => {
    return {
        type: messageType.RESET_MESSAGE_STORE,
    };
};

const getMessages = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const conversationId = getState().inboxConversation.activeConversationId;

        messageService.getMessages(conversationId).then(
            (messages) => {
                dispatch(success(messages));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: messageType.GET_MESSAGES_REQUEST };
    }
    function success(messages) {
        return { type: messageType.GET_MESSAGES_SUCCESS, messages };
    }
    function failure(error) {
        return { type: messageType.GET_MESSAGES_FAILURE, error };
    }
};

const createMessage = (messageContent, inUserId, inConversationId) => {
    return function (dispatch, getState) {
        dispatch({ type: messageType.CREATE_MESSAGE_REQUEST });

        const { inboxUser, inboxConversation } = getState();
        const conversationId = inConversationId || inboxConversation.activeConversationId;
        const userId = inUserId || null; // TODO in future use pharmacist's inbox_user_id
        const inboxContext = new InboxMessageContext()
            .setConversationId(conversationId)
            .setPatientInboxUserId(inUserId || inboxUser.activeUserId)
            .build();

        messageService.createMessage(conversationId, userId, messageContent.trim()).then(
            () => {
                Snowplow.structEvent(
                    'Inbox',
                    'pharmacist-create-message',
                    [inboxContext],
                    null,
                    SnowplowConstants.success
                );
                dispatch({ type: messageType.CREATE_MESSAGE_SUCCESS });
                dispatch(onboardingActions.getInboxLocationOnboarding());
            },
            (error) => {
                Snowplow.structEvent(
                    'Inbox',
                    'pharmacist-create-message',
                    [inboxContext],
                    null,
                    SnowplowConstants.failure
                );
                dispatch({ type: messageType.CREATE_MESSAGE_FAILURE, error });
            }
        );
    };
};

const createMessageAppointment = (
    appt_type_id,
    action_title,
    scheduled_appointment_text,
    inUserId,
    inConversationId
) => {
    return function (dispatch, getState) {
        dispatch({ type: messageType.CREATE_MESSAGE_APPOINTMENT_REQUEST });

        const { inboxUser, inboxConversation } = getState();
        const conversationId = inConversationId || inboxConversation.activeConversationId;
        const userId = inUserId || null; // TODO in future use pharmacist's inbox_user_id
        const inboxContext = new InboxMessageContext()
            .setConversationId(conversationId)
            .setPatientInboxUserId(inUserId || inboxUser.activeUserId)
            .build();

        messageService
            .createMessageAppointment(conversationId, userId, appt_type_id, action_title, scheduled_appointment_text)
            .then(
                () => {
                    Snowplow.structEvent(
                        'Inbox',
                        'pharmacist-create-message-appt',
                        [inboxContext],
                        null,
                        SnowplowConstants.success
                    );
                    dispatch({ type: messageType.CREATE_MESSAGE_APPOINTMENT_SUCCESS });
                    dispatch(onboardingActions.getInboxLocationOnboarding());
                },
                (error) => {
                    Snowplow.structEvent(
                        'Inbox',
                        'pharmacist-create-message-appt',
                        [inboxContext],
                        null,
                        SnowplowConstants.failure
                    );
                    dispatch({ type: messageType.CREATE_MESSAGE_APPOINTMENT_FAILURE, error });
                }
            );
    };
};

const createAttachment = (attachmentContent) => {
    return function (dispatch, getState) {
        dispatch(request());
        const { inboxConversation } = getState();
        const conversationId = inboxConversation.activeConversationId;
        const userId = null; // TODO in future use pharmacist's inbox_user_id

        messageService.createAttachment(conversationId, userId, attachmentContent).then(
            (attachment) => {
                dispatch(success());
                dispatch(onboardingActions.getInboxLocationOnboarding());
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: messageType.CREATE_ATTACHMENT_REQUEST };
    }
    function success() {
        return { type: messageType.CREATE_ATTACHMENT_SUCCESS };
    }
    function failure(error) {
        return { type: messageType.CREATE_ATTACHMENT_FAILURE, error };
    }
};

const getLatestMessage = () => {
    return function (dispatch, getState) {
        dispatch(request());
        const { inboxConversation } = getState();
        const conversationId = inboxConversation.activeConversationId;

        messageService.getLatestMessage(conversationId).then(
            (latestMessage) => {
                dispatch(success(latestMessage));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: messageType.GET_LATEST_MESSAGE_REQUEST };
    }
    function success(latestMessage) {
        return { type: messageType.GET_LATEST_MESSAGE_SUCCESS, latestMessage };
    }
    function failure(error) {
        return { type: messageType.GET_LATEST_MESSAGE_FAILURE, error };
    }
};

const updateMessageReadStatus = (messageId) => {
    return function (dispatch, getState) {
        dispatch({ type: messageType.UPDATE_MESSAGE_READ_STATUS_REQUEST });
        const { inboxConversation } = getState();
        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;
        const conversationId = inboxConversation.activeConversationId;

        messageService.updateMessageReadStatus({ pharmacyId, locationId, conversationId }).then(
            () => {
                const { inboxMessage } = getState();
                const { messages } = inboxMessage;
                const updatedMessage = _.find(messages, (message) => {
                    return message.inbox_message_id === messageId;
                });

                if (updatedMessage) {
                    Snowplow.structEvent('Inbox', 'pharmacist-viewed-message', [
                        new InboxMessageContext()
                            .setConversationId(updatedMessage.inbox_conversation_id)
                            .setMessageId(updatedMessage.inbox_message_id)
                            .setPatientInboxUserId(updatedMessage.inbox_user_id)
                            .build(),
                    ]);
                }

                dispatch({ type: messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS, messageId });
            },
            (error) => {
                dispatch({ type: messageType.UPDATE_MESSAGE_READ_STATUS_FAILURE, error });
            }
        );
    };
};

export const messageAction = {
    resetMessageStore,
    getMessages,
    createMessage,
    getLatestMessage,
    updateMessageReadStatus,
    createAttachment,
    createMessageAppointment,
};

export default messageAction;
