import './ChooseBulkMessageDialog.sass';
import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { sendBulkMessage } from '../../redux/Patient/Connect/action';
import ConnectCardGrid, { calculateWrapperSize } from './Connect/ConnectCardGrid';
import ReviewBulkMessagePane from './ReviewBulkMessagePane';
import { canMessagePatient, getInboxConversationTemplates } from './patientsUtil';
import { connect } from 'react-redux';
import Snowplow, { RxConferPatientContext, RxConferWorkflowContext } from '../../snowplow';

function ChooseBulkMessageDialog({
    selectedUsers,
    open,
    onClose,
    nameFormat,
    inboxConversationTemplate,
    launchDarkly,
    sendBulkMessage,
    connect,
    pharmacy,
}) {
    const props = arguments[0];
    const [availableTemplates, setAvailableTemplates] = useState(null);
    const [selectedTemplateUuid, setSelectedTemplateUuid] = useState(null);
    const [refreshIndex, setRefreshIndex] = useState(0);

    useEffect(() => {
        if (open) {
            if (selectedTemplateUuid) {
                Snowplow.pageView('Patients - Message Review', [
                    new RxConferPatientContext().setPatientCount(Object.keys(selectedUsers).length).build(),
                    new RxConferWorkflowContext().setWorkflowTemplateId(selectedTemplateUuid).build(),
                ]);
            } else {
                Snowplow.pageView('Patients - Workflow Selection', [
                    new RxConferPatientContext().setPatientCount(Object.keys(selectedUsers).length).build(),
                ]);
            }
        }
    }, [open, selectedUsers, selectedTemplateUuid]);

    const selectedTemplate = useMemo(() => {
        return _.find(
            availableTemplates,
            ({ inbox_conversation_template_id }) => inbox_conversation_template_id === selectedTemplateUuid
        );
    }, [selectedTemplateUuid]);

    const patientCount = _.size(selectedUsers);
    const messageable = _.filter(_.values(selectedUsers), (u) => canMessagePatient(u, false));

    useEffect(() => {
        setAvailableTemplates(
            _.filter(
                getInboxConversationTemplates(inboxConversationTemplate, launchDarkly, pharmacy),
                (t) => t.status === 'active'
            )
        );
    }, [_.get(inboxConversationTemplate, 'templates'), launchDarkly, refreshIndex]);

    const cardSize = { width: window.innerWidth > 1100 ? 500 : 450, height: 120 };
    const gridStyle = calculateWrapperSize(1030, cardSize, availableTemplates, 4);

    const onCloseWrapper = () => {
        onClose();
        // in a setTimeout so it happens post close animation
        setTimeout(() => {
            setSelectedTemplateUuid(null);
        }, 300);
    };

    // if we have a small window, then show less rows so we don't overflow the dialog
    let visibleGridRows = window.innerHeight < 800 ? 3 : 4;

    return (
        <Dialog className="choose-bulk-message-dialog" maxWidth="xl" open={open} onClose={onCloseWrapper}>
            {!_.isNil(selectedTemplateUuid) ? null : (
                <DialogTitle>
                    <span>
                        Choose message to send to {numeral(patientCount).format('0,0')}{' '}
                        {pluralize('patient', patientCount)}
                    </span>
                    <CloseIcon className="close-icon" onClick={onCloseWrapper} />
                </DialogTitle>
            )}
            {_.isNil(selectedTemplateUuid) ? (
                <DialogContent>
                    <div className="grid-wrapper" style={gridStyle}>
                        <ConnectCardGrid
                            {...props}
                            autoFocusSearch={true}
                            cardSize={cardSize}
                            width={window.innerWidth - 100}
                            maxHeight={
                                (cardSize.height + 10) /*card margin*/ * visibleGridRows +
                                ConnectCardGrid.SEARCH_BAR_HEIGHT
                            }
                            availableTemplates={availableTemplates}
                            hideTargeted
                            setSelectedCard={(uuid) => {
                                setSelectedTemplateUuid(uuid);
                            }}
                            forceUpdate={() => setRefreshIndex(refreshIndex + 1)}
                        />
                    </div>
                </DialogContent>
            ) : (
                <div>
                    <DialogContent>
                        <ReviewBulkMessagePane
                            selectedTemplate={selectedTemplate}
                            selectedUsers={selectedUsers}
                            nameFormat={nameFormat}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="change-message-button"
                            disabled={_.isEmpty(selectedUsers)}
                            onClick={() => setSelectedTemplateUuid(null)}
                            variant="outlined"
                        >
                            Go Back
                        </Button>
                        <Button
                            className="message-button"
                            disabled={_.isNil(selectedTemplate) || _.isEmpty(messageable) || connect.bulkMessageSending}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const inboxUserIds = _.map(
                                    _.filter(_.values(selectedUsers), canMessagePatient),
                                    ({ inbox_user_id }) => inbox_user_id
                                );
                                sendBulkMessage(selectedTemplate, inboxUserIds).then(() => {
                                    onClose();

                                    // do this in a timeout so it doesn't flicker during the hide dialog animation
                                    setTimeout(() => setSelectedTemplateUuid(null), 100);
                                });
                            }}
                        >
                            {connect.bulkMessageSending ? 'Sending...' : 'Send Message'}
                        </Button>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
}

function mapStateToProps(state) {
    const { connect, launchDarkly, pharmacy } = state;

    return {
        connect,
        launchDarkly,
        pharmacy,
    };
}

const bindActionsToDispatch = {
    sendBulkMessage,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ChooseBulkMessageDialog);
