import React from 'react';
import { Grid, Cell, Card } from 'react-md';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LearnMoreButton from 'components/Common/LearnMoreButton';

const appType = 'leads';

const EmptyLeads = () => {
    return (
        <Grid className="empty-state">
            <Cell size={12}>
                <Card className="outer-card leads-list-empty">
                    <AssignmentInd className="leads-list-empty-icon" />
                    <div>
                        <br />
                        You don&apos;t have any leads yet. Check out our Digital Marketing
                        <br />
                        package and start attracting new patients today!
                    </div>
                    <br />
                    <LearnMoreButton appType={appType} variant="tabs" />
                </Card>
            </Cell>
        </Grid>
    );
};

export default EmptyLeads;
