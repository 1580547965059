import './DateTimeSelectContent.sass';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import DateSelectionCalendar from '../DateSelectionCalendar';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';
import { dateEquals, getNumberOfNonPastSlotsAvailableForDate } from 'components/Patients/scheduling/schedulingUtil';
import { Button, CircularProgress, Select, MenuItem, Tooltip } from '@material-ui/core';
import { getFirstDateOfWeek } from '../DateSelectionCalendar';
import numeral from 'numeral';
import CalendarSelect from '../CalendarSelect';

export default function DateTimeSelectContent({
    displayedDateFormat,
    selected,
    onChange,
    apptCalendar,
    appointmentType,
    getAvailableCalendarAppointmentSlots,
    calendars,
    overwrittenCalendarId,
    setOverwrittenCalendarId,
    isCreate,
    reservationSession,
}) {
    const [focusedStartOfWeekDate, setFocusedStartOfWeekDate] = useState(getFirstDateOfWeek(moment(selected)));
    const [focusedDate, setFocusedDate] = useState(moment(selected));
    const [availableSlots, setAvailableSlots] = useState(null);
    const [displayTimezone, setDisplayTimezone] = useState('calendar');
    const slotButtonsRef = useRef();

    const calendarIdToUse = !_.isNil(reservationSession)
        ? reservationSession.calendar_id
        : _.isNil(overwrittenCalendarId)
        ? // get the calendar.calendar_id if it exists (known appt type) otherwise get the appointment_calendar_id (one-off)
          _.get(appointmentType, 'calendar.calendar_id', _.get(appointmentType, 'appointment_calendar_id'))
        : overwrittenCalendarId;

    const appointmentLength = !_.isNil(reservationSession)
        ? reservationSession.expected_length
        : _.get(appointmentType, 'appointment_length_minutes');

    const isLoadingSlots = _.get(apptCalendar, 'availableCalendarSlots.loading', false);

    // fetch the available calendar slots
    useEffect(() => {
        if (!_.isNil(appointmentType)) {
            getAvailableCalendarAppointmentSlots(
                calendarIdToUse,
                appointmentLength,
                focusedStartOfWeekDate.format('YYYY-MM-DD'),
                null,
                moment(focusedStartOfWeekDate).add(6, 'days').format('YYYY-MM-DD'),
                null
            ).then(({ slots }) => {
                setAvailableSlots(slots);
            });
        }
    }, [focusedStartOfWeekDate, JSON.stringify(appointmentType), calendarIdToUse]);

    const calendar = _.find(calendars, ({ calendar_id }) => calendar_id === calendarIdToUse);

    const localTimezone = moment.tz.guess();
    const calendarTimezone = _.get(calendar, 'timezone');

    // calculate how many slots from the focused day are in the past
    const slotsInPastCount = useMemo(() => {
        return _.size(
            _.filter(
                _.map(_.keys(_.get(availableSlots, moment().format('YYYY-MM-DD'))), (time) => {
                    const t = moment.tz(
                        `${moment().format('YYYY-MM-DD')} ${time}`,
                        'YYYY-MM-DD HH:mm:ss',
                        calendarTimezone
                    );

                    return t.diff(moment()) <= 0;
                })
            )
        );
    }, [availableSlots, moment().format('YYYY-MM-DD')]);

    // scroll to the first slot not in the past if this is today.
    useEffect(() => {
        setTimeout(() => {
            slotButtonsRef.current.scrollTop = slotsInPastCount * 38;
        }, 100);
    }, [slotsInPastCount]);

    return (
        <div className="date-time-select-content">
            <div className="heading">
                <div className="title">Choose Appointment Time</div>

                {!isCreate ? null : (
                    <Tooltip
                        title={
                            _.isNil(reservationSession)
                                ? ''
                                : 'This calendar may not be changed. If you would like to schedule on a different calendar, ' +
                                  'you may create a new appointment block using that calendar or schedule without an appointment block.'
                        }
                    >
                        <span>
                            <CalendarSelect
                                value={calendarIdToUse}
                                calendars={calendars}
                                showLabel={false}
                                disabled={!_.isNil(reservationSession)}
                                onChange={(e) => setOverwrittenCalendarId(e.target.value)}
                            />
                        </span>
                    </Tooltip>
                )}
            </div>

            <div className="calendar-and-time-slots">
                <div className="calendar-and-days">
                    <DateSelectionCalendar
                        minSelectableDate={moment()}
                        highlightSelectedWeek={true}
                        focusedDate={focusedDate}
                        onDateChange={(d) => {
                            setFocusedDate(d);
                            setFocusedStartOfWeekDate(getFirstDateOfWeek(d));
                        }}
                    />
                    <div className="days-and-counts">
                        {_.map(_.times(7 - Number(focusedStartOfWeekDate.format('d'))), (i) => {
                            const d = moment(focusedStartOfWeekDate).add(i, 'days');
                            const isFocused = dateEquals(d, focusedDate);

                            const numSlots = getNumberOfNonPastSlotsAvailableForDate(
                                availableSlots,
                                d.format('YYYY-MM-DD'),
                                _.get(calendar, 'timezone'),
                                0
                            );

                            return (
                                <DayOfWeekRow
                                    displayedDateFormat={displayedDateFormat}
                                    d={d}
                                    i={i}
                                    numSlots={numSlots}
                                    focused={isFocused}
                                    onClick={() => setFocusedDate(d)}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="time-slots">
                    <div className="time-slots-heading">
                        <span>
                            Available times on <span className="date">{focusedDate.format(displayedDateFormat)}</span>
                        </span>
                    </div>
                    {localTimezone !== calendarTimezone && (
                        <div className="timezone-mismatch">
                            <div className="prefix">Times shown in timezone of...</div>
                            <Select
                                value={displayTimezone}
                                fullWidth
                                variant="outlined"
                                className="timezone-display-select"
                                onChange={(e) => setDisplayTimezone(e.target.value)}
                            >
                                <MenuItem value="calendar">
                                    Appt calendar ({calendarTimezone.replace('_', ' ')})
                                </MenuItem>
                                <MenuItem value="local">This device ({localTimezone.replace('_', ' ')})</MenuItem>
                            </Select>
                        </div>
                    )}
                    <div
                        className="time-slot-buttons"
                        ref={slotButtonsRef}
                        style={{ height: `${localTimezone !== calendarTimezone ? 445 : 510}px` }}
                    >
                        {!isLoadingSlots ? null : (
                            <div className="loading-slots-message">
                                <CircularProgress size={30} />
                            </div>
                        )}
                        {_.map(_.keys(_.get(availableSlots, focusedDate.format('YYYY-MM-DD'))), (time, i) => {
                            // don't render this button if no capacity remains at this time
                            const capacity = _.get(
                                availableSlots,
                                `${focusedDate.format('YYYY-MM-DD')}.${time}.capacity`
                            );
                            if (capacity <= 0) {
                                return null;
                            }
                            const isToday = dateEquals(moment(), focusedDate);

                            const t = moment.tz(
                                `${focusedDate.format('YYYY-MM-DD')} ${time}`,
                                'YYYY-MM-DD HH:mm:ss',
                                calendarTimezone
                            );
                            
                            const selectedProps =
                                t.format('YYYY-MM-DD h:mm A') !== moment(selected).format('YYYY-MM-DD h:mm A')
                                    ? {}
                                    : {
                                          variant: 'contained',
                                          color: 'primary',
                                      };

                            return (
                                <Button
                                    key={time}
                                    data-cy={
                                        !isToday || i >= slotsInPastCount
                                            ? 'available-slot-button'
                                            : 'unavailable-slot-button'
                                    }
                                    fullWidth
                                    variant="outlined"
                                    disabled={isToday && i < slotsInPastCount}
                                    onClick={() => {
                                        onChange(t);
                                    }}
                                    {...selectedProps}
                                >
                                    <span>
                                        {displayTimezone === 'calendar'
                                            ? t.format('h:mm a')
                                            : moment.tz(t, localTimezone).format('h:mm a')}
                                    </span>
                                    {capacity === 1 ? null : (
                                        <div className="number-of-slots-at-time">
                                            <Tooltip title={`${capacity} available slots at this time`}>
                                                <span>{capacity}</span>
                                            </Tooltip>
                                        </div>
                                    )}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

function DayOfWeekRow({ displayedDateFormat, d, i, numSlots, onClick, focused }) {
    const isInPast = d.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

    return (
        <div
            className={classnames({ 'day-of-week-row': true, odd: i % 2 === 1, 'is-in-past': isInPast, focused })}
            onClick={isInPast ? null : onClick}
        >
            <span className="date">{d.format('ddd ' + displayedDateFormat)}</span>

            <Tooltip title={`${numeral(numSlots).format('0,0')} available slots on this day`}>
                <span className="available-count">{numSlots}</span>
            </Tooltip>
        </div>
    );
}
