import _ from 'lodash';
import { cleanString, toDigits, cleanPhoneNumber, getDateInputFormatByCountryCode } from '../../../utils/helper';
import config from '../../../config';
import moment from 'moment';

const headerRegex = /^[A-Za-z].*?$/;
export function doesFileSeemToHaveHeaders(fileData, emptyColumns) {
    const firstRow = _.get(fileData, 'data.[0]');

    const headers = _.filter(firstRow, (value, colIndex) => {
        if (!emptyColumns.has(colIndex)) {
            return !_.isEmpty(value) && !_.isNil(value.match(headerRegex));
        }
        return true;
    });

    if (_.size(headers) !== getColumnCount(fileData)) {
        return (
            !_.isNil(getLikelyFirstNameColIndex(fileData, emptyColumns, true)) ||
            !_.isNil(getLikelyLastNameColIndex(fileData, emptyColumns, true)) ||
            !_.isNil(getLikelyPhoneColIndex(fileData, emptyColumns, true)) ||
            !_.isNil(getLikelyDOBColIndex(fileData, emptyColumns, true))
        );
    }
    return true;
}

export function findEmptyColumns(fileData) {
    return new Set(
        _.filter(_.times(getColumnCount(fileData)), (colIndex) => {
            return _.isNil(_.find(fileData.data, (row) => !_.isEmpty(_.trim(row[colIndex]))));
        })
    );
}

export function getColumnCount(fileData) {
    return _.size(_.get(fileData, 'data.[0]'));
}

export function getLineCount(fileData) {
    return _.size(_.get(fileData, 'data'));
}

const FIRST_NAME_PATTERNS = [
    'proper first',
    /[patient]{0,1}first\s*n[a]{0,1}me/i,
    'patfname',
    'first',
    'pat first',
    'first_name',
];
export function getLikelyFirstNameColIndex(columns, hasHeaders) {
    return getLikelyColIndex(columns, hasHeaders, FIRST_NAME_PATTERNS);
}

const LAST_NAME_PATTERNS = [
    'proper last',
    /[patient]{0,1}last\s*n[a]{0,1}me/i,
    'patlname',
    'last',
    'pat last',
    'last_name',
];
export function getLikelyLastNameColIndex(columns, hasHeaders) {
    return getLikelyColIndex(columns, hasHeaders, LAST_NAME_PATTERNS);
}

const DOB_PATTERNS = [/birth\s*date/i, 'dob', /date\s*of\s*birth/i, /birth\s*day/i, 'date_of_birth'];
export function getLikelyDOBColIndex(columns, hasHeaders) {
    return getLikelyColIndex(columns, hasHeaders, DOB_PATTERNS);
}

const PHONE_PATTERNS = [/^area-xchg-nbr/i, /mobile/i, /cell\s*#/i, /phone/i];
export function getLikelyPhoneColIndex(columns, hasHeaders) {
    return getLikelyColIndex(columns, hasHeaders, PHONE_PATTERNS);
}

const EMAIL_PATTERNS = [/email\s*address/i, /email/i, /email\s*id/i, /emails/i];
export function getLikelyEmailColIndex(columns, hasHeaders) {
    return getLikelyColIndex(columns, hasHeaders, EMAIL_PATTERNS);
}

function getLikelyColIndex(columns, hasHeaders, patterns) {
    if (hasHeaders) {
        for (let pattern of patterns) {
            const matchIndex = _.findIndex(columns, (column, colIndex) => {
                const header = _.get(column, `source.data.[0].[${column.sourceIndex}]`);
                if (_.isString(pattern)) {
                    return _.toLower(header) === pattern;
                } else if (_.isRegExp(pattern)) {
                    return !_.isNil(header.match(pattern));
                }
            });

            if (_.isFinite(matchIndex) && matchIndex > -1) {
                return matchIndex;
            }
        }
    }
}

export function isAttributesMappingComplete(requiredFields, attributesMapping) {
    return (
        _.size(_.filter(requiredFields, ({ value }) => _.isObject(attributesMapping[value]))) === _.size(requiredFields)
    );
}

export function getMappedCount(attributesMapping) {
    return _.size(_.filter(_.keys(attributesMapping), (attrName) => isAttributeMapped(attrName, attributesMapping)));
}

export function isAttributeMapped(attrName, attributesMapping) {
    return _.isFinite(_.get(attributesMapping, `${attrName}.colIndex`));
}

export function getColumnName(fileColumns, colIndex, hasHeaders) {
    if (hasHeaders) {
        const header = _.get(fileColumns[colIndex], `source.data.[0].[${fileColumns[colIndex].sourceIndex}]`);
        if (!_.isEmpty(_.trim(header))) {
            return header;
        }
    }
    return `Column #${colIndex + 1}`;
}

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const LOOSE_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}$/;
const VALID_RAW_DATE_REGEX = /\d{1,2}[/-]\d{1,2}[/-]\d{4}/;
const TWO_DIGIT_RAW_DATE_REGEX = /\d{1,2}[/-]\d{1,2}[/-]\d{2}([^\d]| |$)/;

const MIN_VALID_DATE = '1900-01-01';
const MAX_VALID_DATE = moment.utc().subtract(2, 'months').format('YYYY-MM-DD');

export function getInvalidPatientFields(contact) {
    if (_.isObject(contact)) {
        const invalidFields = new Set();
        const { phone, date_of_birth, first_name, last_name } = contact;

        if (!_.isString(phone) || _.size(phone) < 10 || _.size(phone) > 12) {
            invalidFields.add('phone');
        }

        if (!_.isString(date_of_birth) || _.isNil(date_of_birth.match(DATE_REGEX))) {
            invalidFields.add('date_of_birth');
        } else if (date_of_birth < MIN_VALID_DATE || date_of_birth > MAX_VALID_DATE) {
            // only support dates in the range of MIN_VALID_DATE (1900-01-01) to MAX_VALID_DATE (now - 2 months)
            invalidFields.add('date_of_birth');
            delete contact.date_of_birth;
        }

        if (_.isEmpty(_.trim(first_name))) {
            invalidFields.add('first_name');
        }

        if (_.isEmpty(_.trim(last_name))) {
            invalidFields.add('last_name');
        }

        return invalidFields;
    }
}

export function isTwoDigitYearRawDate(rawDateOfBirth) {
    return !_.isEmpty(_.trim(rawDateOfBirth)) && !_.isNil(rawDateOfBirth.match(TWO_DIGIT_RAW_DATE_REGEX));
}

export function getValidDateOfBirthByCountryFormat({ dob, activeLocationCountryCode }) {
    if (_.isString(dob)) {
        const match = dob.match(VALID_RAW_DATE_REGEX);

        if (!_.isNil(match)) {
            const dobSubstr = _.first(match);
            const momentInitializedByCountryCode = moment.utc(
                dobSubstr,
                getDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode })
            );

            if (momentInitializedByCountryCode.isValid()) {
                return momentInitializedByCountryCode.format('YYYY-MM-DD');
            }
        } else if (!_.isNil(dob.match(LOOSE_DATE_REGEX))) {
            return moment(dob).format('YYYY-MM-DD');
        }
    }
}

export function parsePatientFromFileData(row, rowIndex, fileData, activeLocationCountryCode, attributesMapping) {
    if (rowIndex === 0 && fileData.hasHeaders) {
        return;
    }
    if (_.size(row) === 1 && _.isEmpty(row[0])) {
        return;
    }

    const contact = {
        pharmacy_id: config.X_PharmacyID,
        location_id: config.X_LocationID,
        first_name: cleanString(row[attributesMapping.first_name.colIndex]),
        last_name: cleanString(row[attributesMapping.last_name.colIndex]),
        date_of_birth: getValidDateOfBirthByCountryFormat({
            dob: row[attributesMapping.date_of_birth.colIndex],
            activeLocationCountryCode,
        }),
        phone: toDigits(cleanPhoneNumber(row[attributesMapping.phone.colIndex])),
        row,
        raw: {
            date_of_birth: row[attributesMapping.date_of_birth.colIndex],
            phone: row[attributesMapping.phone.colIndex],
            first_name: cleanString(row[attributesMapping.first_name.colIndex]),
            last_name: cleanString(row[attributesMapping.last_name.colIndex]),
        },
    };

    contact.invalidFields = getInvalidPatientFields(contact);

    return contact;
}

export function capitalizeName(name) {
    if (_.isString(name)) {
        return name.replace(/\w+/g, _.capitalize);
    }
    return name;
}

export function findDerivedCombinedColumns(fileData, hasHeaders) {
    const derivedColumns = [];
    if (hasHeaders) {
        const headerRow = _.get(fileData, 'data.[0]');

        derivedColumns.push(...findDerivedPhoneColumnFromAreaXchgNbrColumns(headerRow));
    }
    return derivedColumns;
}

function findDerivedPhoneColumnFromAreaXchgNbrColumns(headerRow) {
    const derived = [];
    let priorIndex = 0;

    let index;
    while ((index = _.findIndex(headerRow, (header) => _.toLower(header) === 'area', priorIndex)) !== -1) {
        if (_.size(headerRow) > index + 2) {
            if (_.toLower(headerRow[index + 1]) === 'xchg' && _.toLower(headerRow[index + 2] === 'nbr')) {
                derived.push({
                    name: `${_.slice(headerRow, index, index + 3).join('-')}${
                        _.isEmpty(derived) ? '' : ` ${_.size(derived) + 1}`
                    }`,
                    type: 'concat',
                    colIndices: [index, index + 1, index + 2],
                    positionAfterIndex: index + 2,
                    deriviationType: 'area-xchg-nbr',
                });
            }
        }
        priorIndex = index + 1;
    }
    return derived;
}

export function buildDerivedColumnsFromFileData(fileData, derivedColumns, hasHeaders) {
    const sortedDerivedColumns = _.sortBy(derivedColumns, ({ positionAfterIndex }) =>
        _.isFinite(positionAfterIndex) ? positionAfterIndex : Number.MAX_VALUE
    );
    const derivedFileData = [];
    _.map(sortedDerivedColumns, (derivedColumn, derivedColIndex) => {
        if (derivedColumn.type === 'concat') {
            const { name, colIndices } = derivedColumn;

            _.each(fileData.data, (row, rowIndex) => {
                if (_.isNil(derivedFileData[rowIndex])) {
                    derivedFileData[rowIndex] = [];
                }

                derivedFileData[rowIndex][derivedColIndex] =
                    rowIndex === 0 && hasHeaders ? name : _.map(colIndices, (i) => row[i]).join('');
            });
        } else {
            throw new Error(`Unknown derived column type: ${derivedColumn.type}`);
        }
    });

    return {
        data: derivedFileData,
        positionAfterIndices: _.map(sortedDerivedColumns, ({ positionAfterIndex }) => positionAfterIndex),
        derivedColumns: sortedDerivedColumns,
    };
}

export function getOrderedFileColumns(fileData, derivedFileData) {
    // map our fileData columns into column objects
    const columns = _.map(_.get(fileData, 'data.[0]'), (c, colIndex) => {
        return {
            source: fileData,
            sourceIndex: colIndex,
            sourceType: 'fileData',
        };
    });

    // splice in the derived columns in their specified places
    if (!_.isNil(derivedFileData)) {
        _.each(derivedFileData.positionAfterIndices, (positionAfterIndex, sourceIndex) => {
            // since inserting each prior derived column increments each following positionAfterIndex, we will add
            // sourceIndex here to account for it
            const afterIndex = positionAfterIndex + 1 + sourceIndex;
            columns.splice(afterIndex, 0, {
                source: derivedFileData,
                sourceIndex,
                sourceType: 'derivedFileData',
            });
        });
    }

    // filter out empty columns
    return _.filter(columns, (c) => {
        return c.sourceType !== 'fileData' || !fileData.emptyColumns.has(c.sourceIndex);
    });
}
