import './PatientsTable.sass';
import React, { useState, useEffect, useRef } from 'react';
import { AutoSizer, Column, Table, InfiniteLoader, defaultTableRowRenderer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import PatientName from './PatientName';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PatientActions from './PatientActions';
import _ from 'lodash';
import moment from 'moment';
import { Tooltip, CircularProgress, Select, MenuItem } from '@material-ui/core';
import { updateQueryParameters } from '../../utils/urlHelper';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EmptyPatientResultsPane from './EmptyPatientResultsPane';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import EventHandler from '../../utils/EventHandler';
import { canMessagePatient, getPatientsAge } from './patientsUtil';
import WaitlistStatusIcon from './scheduling/WaitlistStatusIcon';

function UnmessageablePatientCellWrapper({ phone_type, formattedPhone, children }) {
    let phoneText = '';
    switch (phone_type) {
        case 'invalid':
            phoneText = 'Invalid Phone Number';
            break;
        case 'landline':
            phoneText = 'Landline Phone Number';
            break;
        default:
            console.warn('Unknown phone type', phone_type);
    }

    return (
        <Tooltip
            title={
                <div className="unselectable-number-tooltip-title">
                    <div className="icon-wrapper">
                        <WarningIcon className="warning-icon" />
                    </div>
                    <div>
                        <div className="main-text">{phoneText}</div>
                        <div className="additional-text">
                            This patients phone number, {formattedPhone}, may not be messaged so can not be selected.
                            You may edit this patient to add a valid mobile number to fix this issue.
                        </div>
                    </div>
                </div>
            }
        >
            <span style={{ opacity: 0.5, textDecoration: 'line-through' }}>{children}</span>
        </Tooltip>
    );
}

export default function PatientsTable(props) {
    const {
        patientData,
        loadMoreRows,
        sortField,
        sortDirection,
        history,
        location,
        selectedUsers,
        onSelectedChange,
        onEditPatient,
        onMessagePatient,
        onShowAppointments,
        nameFormat,
        setNameFormat,
        mayOnlySelectMessageablePatients,
        formatDate,
        formatPhone,
        focusedIndex,
        assessment,
        isSingleSelect,
        hidePatientActions,
        hideActivityColumn,
        hidePhoneColumn,
        onSingleSelect,
        showAgeOnly,
        showPatientTypeColumn,
        waitlistIdToQuery,
        showAddedToWaitlistColumn,
        showWaitlistStatusColumn,
        offsetRight = 380,
        disableSort = false,
        showWaitlistLabel = false,
        isWrongListLoaded,
    } = props;

    const [multiselect, setMultiselect] = useState(null);

    const tableRef = useRef();

    // Update mouseup event here via useEffect so that it will be reset each time that the multiselect state changes
    // via the onMouseEnter events on the table rows.
    useEffect(() => {
        if (isSingleSelect) {
            return;
        }
        if (_.isNil(multiselect)) {
            EventHandler.removeEventListener('mouseup.PatientsTable');
        } else {
            EventHandler.addEventListener('mouseup.PatientsTable', () => {
                const clone = _.clone(selectedUsers);
                const from = Math.min(multiselect.from, multiselect.to);
                const to = Math.max(multiselect.from, multiselect.to);
                _.each(_.times(to - from + 1), (i) => {
                    const rowData = patientData.loadedPatients[from + i];

                    // if invalid phone type for this table usage, just always delete in case the number was changed during selection
                    if (!isPatientSelectable(mayOnlySelectMessageablePatients, rowData)) {
                        delete clone[rowData.inbox_user_id];
                    } else if (_.has(clone, rowData.inbox_user_id)) {
                        delete clone[rowData.inbox_user_id];
                    } else if (!_.isNil(rowData)) {
                        clone[rowData.inbox_user_id] = rowData;
                    }
                });
                onSelectedChange(clone, selectedUsers);
                setMultiselect(null);
                EventHandler.removeEventListener('mouseup.PatientsTable');
            });
        }
    }, [_.get(multiselect, 'from'), _.get(multiselect, 'to')]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollToPosition(0);
        }
    }, [patientData.newLoad]);

    const rowCount = _.isNil(patientData.patientCount) || isWrongListLoaded ? 0 : patientData.patientCount;

    const waitlistsSet = _.isArray(waitlistIdToQuery) ? new Set(waitlistIdToQuery) : null;

    return (
        <AutoSizer>
            {({ height, width }) => {
                const tableWidth = Math.min(1020, width - (isSingleSelect ? 0 : offsetRight));

                const columnWidths = calculateTableColumnWidths(tableWidth, props);

                return (
                    <div
                        className={classnames({ 'patients-table-wrapper': true, loading: patientData.newLoad })}
                        style={{ width: `${tableWidth}px`, height: `${height}px` }}
                    >
                        <InfiniteLoader
                            isRowLoaded={({ index }) => {
                                return !_.isNil(_.get(patientData, `loadedPatients`, {})[index]);
                            }}
                            loadMoreRows={loadMoreRows}
                            rowCount={rowCount}
                            minimumBatchSize={100}
                        >
                            {({ onRowsRendered, registerChild }) => {
                                return (
                                    <Table
                                        className="patients-table"
                                        overscanRowCount={20}
                                        width={tableWidth}
                                        height={height}
                                        // wrap this ref call in our own function so we can set it for the InfiniteLoader but
                                        // also keep a ref to it ourselves
                                        ref={(r) => {
                                            registerChild(r);
                                            tableRef.current = r;
                                        }}
                                        onRowsRendered={onRowsRendered}
                                        noRowsRenderer={() => {
                                            if (isWrongListLoaded || patientData.newLoad) {
                                                return (
                                                    <div className="initial-loading-message">
                                                        Loading your patients...
                                                    </div>
                                                );
                                            } else if (!_.isNil(patientData.error)) {
                                                return (
                                                    <div className="fetch-error">
                                                        <div className="graphic">
                                                            <WarningIcon className="alert-icon" />
                                                        </div>
                                                        <div className="text">
                                                            <div className="main-line">
                                                                Unable to connect to the Digital Pharmacist network
                                                            </div>
                                                            <div className="details">
                                                                This could be due to a non-working internet connection
                                                                or a temporary technical issue. Please try again later.
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return <EmptyPatientResultsPane {...props} />;
                                        }}
                                        rowRenderer={(props) => {
                                            /**
                                             * Simple higher order component (HOC) to track mouse movement for multiselection
                                             */
                                            const { index, key, style } = props;
                                            // delete the key since we are applying it to the HOC so it won't be reapplied to the defaultTableeRowRender as well
                                            delete props.key;
                                            delete props.style;
                                            const unselectable = !isPatientSelectable(
                                                mayOnlySelectMessageablePatients,
                                                _.get(patientData, `loadedPatients`, {})[index]
                                            );

                                            return (
                                                <div
                                                    className={classnames({ 'wrapper-row-box': true, unselectable })}
                                                    style={{
                                                        ...style,
                                                        userSelect: 'none',
                                                        marginRight: '2px',
                                                        boxSizing: 'border-box',
                                                        height: '40px',
                                                    }}
                                                    key={key}
                                                    onClick={
                                                        !isSingleSelect
                                                            ? null
                                                            : (e) => {
                                                                  if (_.isFunction(onSingleSelect)) {
                                                                      onSingleSelect(
                                                                          _.get(patientData, `loadedPatients`, {})[
                                                                              index
                                                                          ]
                                                                      );
                                                                  }
                                                              }
                                                    }
                                                    onMouseDown={(e) => {
                                                        if (isSingleSelect) {
                                                            return;
                                                        }
                                                        // start tracking a multiselect on mousedown
                                                        setMultiselect({ active: true, from: index, to: index });
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        if (!_.isNil(multiselect)) {
                                                            // if we are tracking a multiselect (mousedown event was fired without a mouseup), then update the to index
                                                            setMultiselect({ ...multiselect, to: index });
                                                        }
                                                    }}
                                                >
                                                    {defaultTableRowRenderer(props)}
                                                </div>
                                            );
                                        }}
                                        rowHeight={40}
                                        headerHeight={40}
                                        scrollToIndex={focusedIndex}
                                        rowCount={rowCount}
                                        rowStyle={function ({ index }) {
                                            if (props.focusedIndex === index) {
                                                return { background: '#D1EEFF' };
                                            }

                                            if (index >= 0) {
                                                if (!_.isNil(multiselect)) {
                                                    const { from, to } = multiselect;
                                                    if (
                                                        index === from ||
                                                        (_.isFinite(from) &&
                                                            _.isFinite(to) &&
                                                            index >= Math.min(from, to) &&
                                                            index <= Math.max(from, to))
                                                    ) {
                                                        const unselectable = !isPatientSelectable(
                                                            mayOnlySelectMessageablePatients,
                                                            _.get(patientData, `loadedPatients`, {})[index]
                                                        );
                                                        return { background: unselectable ? '#F7D4D6' : '#D1EEFF' };
                                                    }
                                                }
                                                return index % 2 === 0
                                                    ? { background: '#fff' }
                                                    : { background: '#f8f8f8' };
                                            }
                                            return { background: '#fff' };
                                        }}
                                        rowGetter={({ index }) => {
                                            const rowData = _.get(patientData, `loadedPatients`, {})[index];
                                            return _.isNil(rowData) ? {} : rowData;
                                        }}
                                        sortBy={sortField}
                                        sort={function ({ sortBy, sortDirection }) {
                                            history.push(
                                                `${location.pathname}${updateQueryParameters(
                                                    location,
                                                    'sort',
                                                    `${sortBy}:${_.toLower(sortDirection)}`
                                                )}`
                                            );
                                        }}
                                        sortDirection={_.toUpper(sortDirection)}
                                    >
                                        <Column
                                            label=""
                                            disableSort={true}
                                            cellRenderer={({ rowData }) => {
                                                const { inbox_user_id } = rowData;

                                                if (!isPatientSelectable(mayOnlySelectMessageablePatients, rowData)) {
                                                    return (
                                                        <UnmessageablePatientCellWrapper
                                                            phone_type={rowData.phone_type}
                                                            formattedPhone={formatPhone(rowData.phone)}
                                                        >
                                                            <NotInterestedIcon className="patient-checkbox disabled" />
                                                        </UnmessageablePatientCellWrapper>
                                                    );
                                                }
                                                if (_.has(selectedUsers, inbox_user_id)) {
                                                    return <CheckBoxIcon className="patient-checkbox checked" />;
                                                } else {
                                                    return (
                                                        <CheckBoxOutlineBlankIcon className="patient-checkbox unchecked" />
                                                    );
                                                }
                                            }}
                                            dataKey="checked"
                                            width={columnWidths.check}
                                            minWidth={columnWidths.check}
                                        />
                                        <Column
                                            {...props}
                                            label="Name"
                                            disableSort={disableSort}
                                            cellRenderer={({ rowData }) => {
                                                return (
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            opacity: isPatientSelectable(
                                                                mayOnlySelectMessageablePatients,
                                                                rowData
                                                            )
                                                                ? null
                                                                : 0.5,
                                                        }}
                                                    >
                                                        <PatientName
                                                            {...rowData}
                                                            format={nameFormat}
                                                            width={columnWidths.name}
                                                        />
                                                    </span>
                                                );
                                            }}
                                            headerRenderer={(props) => {
                                                const { sortBy, sortDirection } = props;
                                                let sortIcon = null;

                                                if (sortBy === 'last_name.keyword' || sortBy === 'full_name.keyword') {
                                                    sortIcon =
                                                        sortDirection === 'ASC' ? (
                                                            <ArrowDropUpIcon className="sort-icon" />
                                                        ) : (
                                                            <ArrowDropDownIcon className="sort-icon" />
                                                        );
                                                }

                                                return (
                                                    <div className="name-header">
                                                        Name {sortIcon}
                                                        <Select
                                                            className="name-format-select"
                                                            value={nameFormat}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => setNameFormat(e.target.value)}
                                                            style={{ textTransform: 'none', marginLeft: '20px' }}
                                                        >
                                                            <MenuItem value="last_first">Last, First</MenuItem>
                                                            <MenuItem value="first_last">First Last</MenuItem>
                                                        </Select>
                                                    </div>
                                                );
                                            }}
                                            dataKey={
                                                nameFormat === 'first_last' ? 'full_name.keyword' : 'last_name.keyword'
                                            }
                                            width={columnWidths.name}
                                        />
                                        <Column
                                            {...props}
                                            label={showAgeOnly ? 'Age' : 'Birthdate / Age'}
                                            disableSort={disableSort}
                                            cellRenderer={({ rowData }) => {
                                                return (
                                                    <span
                                                        className="birthdate-cell"
                                                        style={{
                                                            opacity: isPatientSelectable(
                                                                mayOnlySelectMessageablePatients,
                                                                rowData
                                                            )
                                                                ? null
                                                                : 0.5,
                                                        }}
                                                    >
                                                        {showAgeOnly ? null : (
                                                            <span>
                                                                {_.isNil(rowData.date_of_birth) ? (
                                                                    <span className="birthdate-loading" />
                                                                ) : (
                                                                    formatDate(rowData.date_of_birth)
                                                                )}
                                                            </span>
                                                        )}
                                                        <span className="age">
                                                            {_.isNil(rowData.date_of_birth) ? (
                                                                <span className="age-loading" />
                                                            ) : (
                                                                getPatientsAge(rowData.date_of_birth)
                                                            )}
                                                        </span>
                                                    </span>
                                                );
                                            }}
                                            dataKey="date_of_birth"
                                            width={columnWidths.birthdate}
                                        />
                                        {!showPatientTypeColumn ? null : (
                                            <Column
                                                {...props}
                                                label="Patient Type"
                                                disableSort={disableSort}
                                                cellRenderer={({ rowData }) => {
                                                    return (
                                                        <span
                                                            className="patient-type-cell"
                                                            style={{
                                                                opacity: isPatientSelectable(
                                                                    mayOnlySelectMessageablePatients,
                                                                    rowData
                                                                )
                                                                    ? null
                                                                    : 0.5,
                                                            }}
                                                        >
                                                            {rowData.is_lead ? 'Waitlist' : 'Existing'}
                                                        </span>
                                                    );
                                                }}
                                                width={columnWidths.patientType}
                                                dataKey={'is_lead'}
                                            />
                                        )}
                                        {!showWaitlistLabel ? null : (
                                            <Column
                                                {...props}
                                                label="Waitlist"
                                                disableSort={disableSort}
                                                cellRenderer={({ rowData }) => {
                                                    const waitlist = _.first(
                                                        _.filter(rowData.waitlists, ({ waitlist_id }) =>
                                                            waitlistsSet.has(waitlist_id)
                                                        )
                                                    );
                                                    return (
                                                        <span
                                                            className="patient-type-cell"
                                                            style={{
                                                                opacity: isPatientSelectable(
                                                                    mayOnlySelectMessageablePatients,
                                                                    rowData
                                                                )
                                                                    ? null
                                                                    : 0.5,
                                                            }}
                                                        >
                                                            {_.get(waitlist, 'waitlist_name')}
                                                        </span>
                                                    );
                                                }}
                                                width={columnWidths.waitlistLabel}
                                                dataKey={'waitlists.waitlist_name'}
                                            />
                                        )}
                                        {!showAddedToWaitlistColumn ? null : (
                                            <Column
                                                {...props}
                                                label="Added"
                                                disableSort={disableSort}
                                                cellRenderer={({ rowData }) => {
                                                    const waitlist = _.find(rowData.waitlists, ({ waitlist_id }) =>
                                                        waitlistsSet.has(waitlist_id)
                                                    );

                                                    return (
                                                        <span
                                                            className="patient-type-cell"
                                                            style={{
                                                                opacity: isPatientSelectable(
                                                                    mayOnlySelectMessageablePatients,
                                                                    rowData
                                                                )
                                                                    ? null
                                                                    : 0.5,
                                                            }}
                                                        >
                                                            {_.isNil(_.get(waitlist, 'added_timestamp'))
                                                                ? null
                                                                : moment(_.get(waitlist, 'added_timestamp')).format(
                                                                      'MMM D, h:mma'
                                                                  )}
                                                        </span>
                                                    );
                                                }}
                                                width={columnWidths.waitlistAddedTime}
                                                dataKey="waitlists.added_timestamp"
                                            />
                                        )}
                                        {hidePhoneColumn ? null : (
                                            <Column
                                                {...props}
                                                label="Phone #"
                                                disableSort={disableSort}
                                                cellRenderer={({ rowData }) => {
                                                    const unselectable = !isPatientSelectable(
                                                        mayOnlySelectMessageablePatients,
                                                        rowData
                                                    );

                                                    if (_.isNil(rowData.phone)) {
                                                        return <span className="phone-loading" />;
                                                    }

                                                    const number = formatPhone(rowData.phone);

                                                    if (unselectable) {
                                                        return (
                                                            <UnmessageablePatientCellWrapper
                                                                phone_type={rowData.phone_type}
                                                                formattedPhone={formatPhone(rowData.phone)}
                                                            >
                                                                {number}
                                                            </UnmessageablePatientCellWrapper>
                                                        );
                                                    } else {
                                                        return <span>{number}</span>;
                                                    }
                                                }}
                                                dataKey="phone"
                                                width={columnWidths.phone}
                                            />
                                        )}
                                        {hideActivityColumn || !showActivityColumn(tableWidth) ? null : (
                                            <Column
                                                {...props}
                                                label="Last Activity"
                                                disableSort={disableSort}
                                                cellRenderer={({ rowData }) => {
                                                    if (_.isNil(rowData.latest_activity_date)) {
                                                        return null;
                                                    }
                                                    return (
                                                        <Tooltip
                                                            title={`${rowData.latest_activity_summary} on ${moment(
                                                                rowData.latest_activity_date
                                                            ).format('MMMM Do YYYY [at] h:mm:ss a')}`}
                                                        >
                                                            <span
                                                                style={{
                                                                    opacity: isPatientSelectable(
                                                                        mayOnlySelectMessageablePatients,
                                                                        rowData
                                                                    )
                                                                        ? null
                                                                        : 0.5,
                                                                }}
                                                            >
                                                                {moment(rowData.latest_activity_date).fromNow()}
                                                            </span>
                                                        </Tooltip>
                                                    );
                                                }}
                                                dataKey="latest_activity_date"
                                                width={columnWidths.activity}
                                            />
                                        )}
                                        {!showWaitlistStatusColumn ? null : (
                                            <Column
                                                label=""
                                                disableSort={true}
                                                cellRenderer={({ rowData }) => {
                                                    const waitlist = _.find(rowData.waitlists, ({ waitlist_id }) =>
                                                        waitlistsSet.has(waitlist_id)
                                                    );

                                                    return (
                                                        <WaitlistStatusIcon
                                                            status={
                                                                _.isNil(waitlist)
                                                                    ? 'PharmacyPatient'
                                                                    : _.get(waitlist, 'status', 'Uncontacted')
                                                            }
                                                        />
                                                    );
                                                }}
                                                width={columnWidths.waitlistStatus}
                                                dataKey="waitlists.status"
                                            />
                                        )}
                                        {hidePatientActions ? null : (
                                            <Column
                                                label=""
                                                cellRenderer={({ rowData }) => (
                                                    <PatientActions
                                                        {...props}
                                                        patient={rowData}
                                                        onEditPatient={onEditPatient}
                                                        onMessagePatient={onMessagePatient}
                                                        onShowAppointments={onShowAppointments}
                                                        assessment={assessment}
                                                    />
                                                )}
                                                disableSort={true}
                                                dataKey="actions"
                                                width={columnWidths.actions}
                                                minWidth={columnWidths.actions}
                                            />
                                        )}
                                    </Table>
                                );
                            }}
                        </InfiniteLoader>
                        {!patientData.newLoad ? null : <CircularProgress className="loading-indicator" />}
                    </div>
                );
            }}
        </AutoSizer>
    );
}
PatientsTable.props = {
    sortField: PropTypes.oneOf(['date_of_birth', 'full_name', 'latest_activity_date']),
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
};

// Returns true if the phone type is messageable or if the lookup has not occurred yet (_.isNil(phone_type) === true)
export function isPatientSelectable(mayOnlySelectMessageablePatients, rowData) {
    if (_.isNil(rowData)) {
        return false;
    }
    if (!mayOnlySelectMessageablePatients) {
        return true;
    }

    return canMessagePatient(rowData, true);
}

// Will return the next index that can be focused, currentIndex +/- 1 if any patient can be selected or will look for the next index
// that can be messaged if mayOnlySelectMessageablePatients === true
export function getNextFocusablePatientIndex(
    mayOnlySelectMessageablePatients,
    currentIndex,
    patientData,
    isAscending = true
) {
    const size = _.size(patientData.loadedPatients);
    if (mayOnlySelectMessageablePatients) {
        if (_.isObject(patientData.loadedPatients)) {
            if (isAscending) {
                for (let index = currentIndex + 1; index < size; index++) {
                    if (isPatientSelectable(mayOnlySelectMessageablePatients, patientData.loadedPatients[index])) {
                        return index;
                    }
                }
            } else {
                for (let index = currentIndex - 1; index >= 0; index--) {
                    if (isPatientSelectable(mayOnlySelectMessageablePatients, patientData.loadedPatients[index])) {
                        return index;
                    }
                }
            }
        }
        // if we got here without returning already, then there is no valid index to select
        return null;
    }

    return isAscending ? Math.min(size - 1, currentIndex + 1) : Math.max(0, currentIndex - 1);
}

function calculateTableColumnWidths(
    tableWidth,
    {
        isSingleSelect,
        hidePatientActions,
        showAgeOnly,
        hidePhoneColumn,
        hideActivityColumn,
        showPatientTypeColumn,
        showAddedToWaitlistColumn,
        showWaitlistStatusColumn,
        showWaitlistLabel,
    }
) {
    // react-virtualized will flex-grow or flex-shrink any columns to fit them into the table.
    // Since we enforce a fixed width on the check and action columns, we need to subtract
    // these fixed widths + margins from the total width to get the flexTableWidth.
    // The reason we are doing this ourselves instead of just letting the library deal with
    // it is so we can pass along the column widths in case the children components need them
    // to properly render

    // determine which fields are visible based on passed props
    const fieldVisibilities = {
        check: !isSingleSelect,
        actions: !hidePatientActions,
        name: true,
        birthdate: true,
        phone: !hidePhoneColumn,
        activity: showActivityColumn(tableWidth) && !hideActivityColumn,
        patientType: showPatientTypeColumn,
        waitlistAddedTime: showAddedToWaitlistColumn,
        waitlistStatus: showWaitlistStatusColumn,
        waitlistLabel: showWaitlistLabel,
    };

    // set a default width for each flex field
    const defaultWidths = {
        name: 200,
        birthdate: showAgeOnly ? 60 : 160,
        phone: 120,
        activity: 170,
        patientType: 110,
        waitlistAddedTime: 130,
        waitlistLabel: 80,
    };

    // set width of our none-flexing fields
    const fixedColumnWidths = {
        check: fieldVisibilities.check ? 25 : 0,
        actions: fieldVisibilities.actions ? 110 : 0,
        waitlistStatus: fieldVisibilities.waitlistStatus ? 25 : 0,
    };

    const visibleFields = _.map(_.filter(_.keys(fieldVisibilities), (k) => fieldVisibilities[k]));
    const otherWidths = { margins: 10 * (_.size(visibleFields) + 1), border: 2 };

    const nonFlexWidth = _.reduce(_.values({ ...fixedColumnWidths, ...otherWidths }), (sum, w) => sum + w, 0);
    const flexTableWidth = tableWidth - nonFlexWidth;

    // get the starting width for every visible flex column
    const flexWidths = {};
    let usedFlexWidth = 0;
    _.each(visibleFields, (field) => {
        if (_.has(defaultWidths, field)) {
            flexWidths[field] = defaultWidths[field];
            usedFlexWidth += defaultWidths[field];
        }
    });

    // calculate the ratio that each flex column is of the total (so we can allocate additional space proportionally)
    const usedFlexWidthRatios = {};
    _.forIn(flexWidths, (w, key) => {
        usedFlexWidthRatios[key] = w / usedFlexWidth;
    });

    // proportionally add/subtract any remaining space for each column
    _.forIn(flexWidths, (w, key) => {
        flexWidths[key] = w + Math.floor((flexTableWidth - usedFlexWidth) * usedFlexWidthRatios[key]);
    });

    return { ...fixedColumnWidths, ...flexWidths };
}

function showActivityColumn(tableWidth) {
    return tableWidth > 660;
}
