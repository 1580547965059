const typePrefix = 'inbox/conversation/';

export const conversationType = {
    RESET_CONVERSATION_STORE: `${typePrefix}RESET_CONVERSATION_STORE`,
    SET_ACTIVE_CONVERSATION: `${typePrefix}SET_ACTIVE_CONVERSATION`,
    CLEAR_ACTIVE_CONVERSATION: `${typePrefix}CLEAR_ACTIVE_CONVERSATION`,
    CREATE_CONVERSATION_REQUEST: `${typePrefix}CREATE_CONVERSATION_REQUEST`,
    CREATE_CONVERSATION_SUCCESS: `${typePrefix}CREATE_CONVERSATION_SUCCESS`,
    CREATE_CONVERSATION_FAILURE: `${typePrefix}CREATE_CONVERSATION_FAILURE`,
    GET_CONVERSATIONS_REQUEST: `${typePrefix}GET_CONVERSATIONS_REQUEST`,
    GET_CONVERSATIONS_SUCCESS: `${typePrefix}GET_CONVERSATIONS_SUCCESS`,
    GET_CONVERSATIONS_FAILURE: `${typePrefix}GET_CONVERSATIONS_FAILURE`,
    OPEN_CREATE_CONVERSATION_DIALOG: `${typePrefix}OPEN_CREATE_CONVERSATION_DIALOG`,
    CLOSE_CREATE_CONVERSATION_DIALOG: `${typePrefix}CLOSE_CREATE_CONVERSATION_DIALOG`,
    TOGGLE_CREATE_CONVERSATION_DIALOG: `${typePrefix}TOGGLE_CREATE_CONVERSATION_DIALOG`,
    UPDATE_ACTIVE_CONVERSATION_MESSAGE_STATUS: `${typePrefix}UPDATE_ACTIVE_CONVERSATION_MESSAGE_STATUS`,
};

export default conversationType;
