import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    appBar: {
        position: 'relative',
        background: '#4b667d',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    iframe: {
        height: '100%',
        width: '100%',
    },
};

const PharmacyContract = ({ showPharmacyContract, closePharmacyContract, classes }) => (
    <Dialog fullScreen open={showPharmacyContract} onClose={closePharmacyContract}>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    Terms and Conditions
                </Typography>
                <Button color="inherit" onClick={closePharmacyContract}>
                    Skip
                </Button>
            </Toolbar>
        </AppBar>
        <iframe title="Pharmacy Contract" className={classes.iframe} src="https://formstack.io/162F2" />
    </Dialog>
);

PharmacyContract.propTypes = {
    showPharmacyContract: PropTypes.bool.isRequired,
    closePharmacyContract: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PharmacyContract);
