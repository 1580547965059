import _ from 'lodash';
import Config from 'config';
import { get } from 'redux/actionCreators/rest';

import { postRequest, putRequest } from './Requests';

export const getPackageByCode = (code) => {
    const url = `${Config.config2_url}/package`;
    const args = {
        params: {
            code,
        },
    };

    return get(url, args).then(({ result }) => {
        return _.get(result, '[0].id');
    });
};

export const getCustPackage = (cust) => {
    const url = `${Config.config2_url}/cust/${cust.id}/custPackage/${cust.packageID}`;
    return get(url).then((response) => {
        return response;
    });
};

export const addCustPackage = (custID, package_, payload = {}, showSuccessFailure = true) => (dispatch) => {
    const url = `${Config.config2_url}/cust/${custID}/custPackage`;
    const data = { packageId: package_.id, ...payload };
    const toast = {
        initMessage: `Activating ${package_.name}...`,
        successMessage: `${package_.name} Activated.`,
        errorMessage: `Failed To Activate ${package_.name}!`,
    };

    return postRequest(url, data, toast, dispatch, showSuccessFailure);
};

export const updateCustPackage = (custID, custPackage, showSuccessFailure = true) => (dispatch) => {
    const url = `${Config.config2_url}/cust/${custID}/custPackage/${custPackage.id}`;
    const activate = _.indexOf([1, '1'], custPackage.statusId) !== -1;
    const toast = {
        initMessage: `${activate ? 'Activating' : 'Deactivating'} ${custPackage.name}...`,
        successMessage: `${custPackage.name} ${activate ? 'Activated' : 'Deactivated'}.`,
        errorMessage: `Failed To ${activate ? 'Activate' : 'Deactivate'} ${custPackage.name}!`,
    };
    const data = {
        name: custPackage.name,
        promoted: custPackage.promoted,
        seq: custPackage.seq,
        statusId: custPackage.statusId,
    };

    return putRequest(url, data, toast, dispatch, showSuccessFailure);
};
