const ContentUrls = {
    prefixes: {
        youtube: 'https://www.youtube.com/embed/',
        wistia: 'https://fast.wistia.com/embed/medias/',
        gdocs: {
            presentation: 'https://docs.google.com/presentation/d/',
            file: 'https://drive.google.com/file/d/',
            document: 'https://docs.google.com/document/d/',
        },
    },

    suffixes: {
        gdocs: {
            presentation: '/embed?start=false&loop=false&delayms=3000',
            file: '/preview',
            document: '/pub?embedded=true',
        },
    },

    downlodableLinkPrefixes: {
        gdocs: {
            document: 'https://docs.google.com/document/export?format=pdf&id=',
            file: 'https://drive.google.com/uc?export=download&id=',
        },
    },
};

export default ContentUrls;
