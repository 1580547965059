import './AppointmentBlockRow.sass';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import NeedleIcon from './NeedleIcon';
import { VACCINE_COLORS, VACCINES_AND_LABELS } from '../schedule/VaccineDoseInfoContent';
import { Tooltip, Button } from '@material-ui/core';
import numeral from 'numeral';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import pluralize from 'pluralize';
import { getNumberOfNonPastSlotsAvailableForDate } from '../schedulingUtil';
import { AvailableDateRange } from 'components/Appointments/types/AppointmentAvailabilityRow';
import { connect } from 'react-redux';
import PublicIcon from '@material-ui/icons/Public';

function AppointmentBlockRow({ session, calendars, onEdit, onClick, isOdd, slots, allowSelection, showMultiApptUx }) {
    if (!_.isObject(session)) {
        return;
    }

    const calendar = _.find(calendars, ({ calendar_id }) => calendar_id === session.calendar_id);

    const numberOfAvailableSlots = _.reduce(
        _.keys(slots),
        (sum, date) => sum + getNumberOfNonPastSlotsAvailableForDate(slots, date, _.get(calendar, 'timezone')),
        0
    );

    const { metadata } = session;

    const vaccineType = _.get(metadata, 'covid_dose_info.vaccine_type');
    const doses = _.get(metadata, 'covid_dose_info.number_of_doses');

    const bookedCount = _.reduce(_.values(_.get(session, 'reservation_counts')), (sum, c) => sum + c, 0);
    const availableCount = doses - bookedCount;

    const daysDiff = moment(session.end_date, 'YYYY-MM-DD').diff(moment(), 'days');

    const endsInText =
        daysDiff === 0
            ? 'in less than 1 day'
            : `in ${numeral(daysDiff + 1).format('0,0')} ${pluralize('day', daysDiff + 1)}`;

    return (
        <div
            className={classnames({
                'appointment-block-row': true,
                'is-odd': isOdd,
                'disallow-selection': !allowSelection,
            })}
            onClick={() => (allowSelection ? onClick(session) : null)}
        >
            <div className="main-line">
                <div className="time-range">
                    <AvailableDateRange
                        fromDate={
                            _.isNil(_.get(session, 'start_date')) ? null : moment(session.start_date, 'YYYY-MM-DD')
                        }
                        toDate={_.isNil(_.get(session, 'end_date')) ? null : moment(session.end_date, 'YYYY-MM-DD')}
                        emptyContent={
                            <span className="available-date-range">
                                <DateRangeIcon /> Available anytime
                            </span>
                        }
                    />
                </div>

                <div className="vaccine-type">
                    <NeedleIcon color={_.get(VACCINE_COLORS, vaccineType)} /> {_.get(VACCINES_AND_LABELS, vaccineType)}
                </div>

                <Button
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onEdit();
                    }}
                >
                    Edit
                </Button>
            </div>

            <div className="explain-line">
                <div style={{ whiteSpace: 'nowrap' }}>
                    <ScheduleIcon /> {session.expected_length} minute appts on the{' '}
                    <span className="calendar-name">
                        <EventIcon style={{ color: calendar.color }} /> {calendar.name}
                    </span>{' '}
                    calendar
                </div>

                {showMultiApptUx && (
                    <div>
                        {session.available_on_web === 1 ? (
                            <>
                                <PublicIcon style={{ color: '#00B18F' }} /> <span>Available on website</span>
                            </>
                        ) : (
                            'Not available on website'
                        )}
                    </div>
                )}
            </div>

            <div className="stats-line">
                {_.isNil(session.end_date) ? (
                    <Tooltip title="There is no ending date specified for this appointment block.">
                        <div className="stat-value">never expires</div>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={`The ending date for this appointment block, ${moment(
                            session.end_date,
                            'YYYY-MM-DD'
                        ).format(
                            'MMMM D, YYYY'
                        )}, is ${endsInText} so this block will no longer be active after that time.`}
                    >
                        <div className="stat-value">ends {endsInText}</div>
                    </Tooltip>
                )}

                {_.isNil(session.start_date) || _.isNil(session.end_date) ? (
                    <span />
                ) : (
                    <Tooltip
                        title={
                            `${numeral(numberOfAvailableSlots).format('0,0')} ` +
                            `appointment ${pluralize('slot', numberOfAvailableSlots)} ${pluralize(
                                'is',
                                numberOfAvailableSlots
                            )} currently ` +
                            `available on the ${calendar.name} calendar during this appointment blocks active date range.`
                        }
                    >
                        <div className="stat-value">
                            {numeral(numberOfAvailableSlots).format('0,0')} available{' '}
                            {pluralize('slot', numberOfAvailableSlots)}
                        </div>
                    </Tooltip>
                )}

                <Tooltip
                    title={
                        `${numeral(doses).format(
                            '0,0'
                        )} doses were said to be available when this appointment block was created. ` +
                        `${numeral(bookedCount).format('0,0')} ${pluralize('appointment', bookedCount)} ${pluralize(
                            'has',
                            bookedCount
                        )} been scheduled against this block, so ${numeral(availableCount).format(
                            '0,0'
                        )} doses remain available.`
                    }
                >
                    <div className="stat-value">
                        {numeral(availableCount).format('0,0')} / {numeral(doses).format('0,0')} remaining{' '}
                        {pluralize('dose', availableCount)}
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { launchDarkly } = state;
    return {
        showMultiApptUx: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentBlockRow);
