import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { inboxFilterList, inboxFilterOptions } from '../../../constants/Inbox';
import Snowplow from '../../../snowplow';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableAutoFocusItem
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

class ListFilterItem extends React.Component {
    render() {
        const { setSearchFilter, searchFilter, searchFilterText, searchFilterIcon, currentSearchFilter } = this.props;
        const isSelected = currentSearchFilter === searchFilter;

        return (
            <MenuItem onClick={() => !isSelected && setSearchFilter(searchFilter)} selected={isSelected}>
                <ListItemIcon>
                    <i className="material-icons" style={{ color: isSelected ? '#3793ff' : 'inherit' }}>
                        {isSelected ? 'check' : searchFilterIcon}
                    </i>
                </ListItemIcon>
                <ListItemText style={{ color: isSelected ? '#3793ff' : 'inherit' }} primary={searchFilterText} />
            </MenuItem>
        );
    }
}

class ActivityListFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    setSearchFilter = (searchFilter) => {
        const { setSearchFilter } = this.props;
        setSearchFilter(searchFilter);
        this.handleMenuClose();

        Snowplow.structEvent(
            'Inbox',
            'search-filter-added',
            null,
            searchFilter,
            inboxFilterOptions[searchFilter]['text']
        );
    };

    resetSearchFilter = () => {
        const { resetSearchFilter } = this.props;
        resetSearchFilter();
        this.handleMenuClose();
    };

    render() {
        const { anchorEl } = this.state;
        const { searchFilter } = this.props;

        const filterMenuComponentList = inboxFilterList.map((filterOptionText) => {
            const filterOption = inboxFilterOptions[filterOptionText];
            if (filterOption) {
                return (
                    <ListFilterItem
                        key={filterOption['filter']}
                        setSearchFilter={this.setSearchFilter}
                        searchFilter={filterOption['filter']}
                        searchFilterText={filterOption['text']}
                        searchFilterIcon={filterOption['icon']}
                        currentSearchFilter={searchFilter}
                        handleMenuClose={this.handleMenuClose}
                    />
                );
            } else {
                return <></>;
            }
        });

        return (
            <Fragment>
                <div>
                    <Tooltip title="Filter patients" aria-label="Filter patient list" disableFocusListener>
                        <IconButton
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleMenuOpen}
                            color="inherit"
                            style={{ color: searchFilter ? '#3793ff' : '#4a4a4a' }}
                            id="activity-list-filter-btn"
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    <StyledMenu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}
                    >
                        <Typography variant="subtitle2" style={{ padding: '6px 16px' }}>
                            Filter by patients that have:
                        </Typography>
                        {filterMenuComponentList}
                        <Divider />
                        <MenuItem onClick={this.resetSearchFilter} disabled={!searchFilter}>
                            <ListItemIcon>
                                <i className="material-icons">clear</i>
                            </ListItemIcon>
                            <ListItemText primary="Remove Filter" />
                        </MenuItem>
                    </StyledMenu>
                </div>
            </Fragment>
        );
    }
}

export default ActivityListFilter;
