import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { RefillCountWidget } from './Widgets';
import StatsWidget from './Common/StatsWidget';
import { fetchNewReports, cancelReportsRequests } from 'redux/actionCreators/Reports';
import { homeActions } from 'redux/Home';

const last30Days = {
    begin: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
};

class StatsWidgetContainer extends React.Component {
    componentDidMount = () => {
        this.fetchWidgetsData();
    };

    componentDidUpdate = ({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) => {
        const { selectedLocation, pharmacy } = this.props;

        const isPharmacyUpdated = oldPharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isLocationChanged = selectedLocation !== oldSelectedLocation;

        if ((isLocationChanged || isPharmacyUpdated) && !pharmacy.loading) {
            cancelReportsRequests();
            this.fetchWidgetsData();
        }
    };

    componentWillUnmount = () => {
        // cancelReportsRequests();
    };

    fetchWidgetsData = () => {
        const {
            pharmacy: {
                digitalPlatformReportsEnabledForMobile,
                digitalPlatformReportsEnabledForWeb,
                directMessagingReportsEnabled,
            },
        } = this.props;
        const { selectedPharmacy, selectedLocation, getRefillCount, getPatientTransfers, getMessagesSent } = this.props;

        const payload = {
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
        };

        if (digitalPlatformReportsEnabledForMobile || digitalPlatformReportsEnabledForWeb) {
            getRefillCount({
                ...payload,
                date_ranges: [last30Days],
            });

            getPatientTransfers({
                pharmacy_id: selectedPharmacy,
                start_date: last30Days.begin,
                end_date: last30Days.end,
            });
        }

        if (directMessagingReportsEnabled) {
            getMessagesSent({
                ...payload,
                start_date: last30Days.begin,
                end_date: last30Days.end,
            });
        }
    };

    render() {
        const {
            isRefillsCountLoading,
            isPatientTransfersLoading,
            isMessagesSentLoading,
            refillsCount,
            patientTransfers,
            messagesSent,
        } = this.props;

        return (
            <div className="key-stats-container">
                <RefillCountWidget data={refillsCount} isLoading={isRefillsCountLoading} />
                <StatsWidget
                    title="Patient Transfers"
                    icon="transfer_within_a_station"
                    iconColor="#58d6fd"
                    value={patientTransfers}
                    isLoading={isPatientTransfersLoading}
                />
                <StatsWidget
                    title="Messages Sent"
                    icon="message"
                    iconColor="#7053ee"
                    value={messagesSent}
                    isLoading={isMessagesSentLoading}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedPharmacy: state.auth.selectedCustomer.id,
    selectedLocation: state.auth.selectedLocation.id,
    pharmacy: state.pharmacy,
    ...state.home,
});

const mapDispatchToProps = {
    fetchNewReports,
    getRefillCount: homeActions.getRefillCount,
    getPatientTransfers: homeActions.getPatientTransfers,
    getMessagesSent: homeActions.getMessagesSent,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsWidgetContainer);
