const allowedAudioTypeList = ['audio/wav'];
const allowedTextTypeList = ['text/plain'];
const ALL_LOCATIONS = 'allLocations';
const ALL_USERS = 'allUsers';
const INTERNAL_USERS = 'internal';
const PHARMACY_USERS = 'external';
const NO_LOCATION_ACCESS = 'No Location Access';

export {
    allowedAudioTypeList,
    allowedTextTypeList,
    ALL_LOCATIONS,
    ALL_USERS,
    INTERNAL_USERS,
    PHARMACY_USERS,
    NO_LOCATION_ACCESS,
};
