import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
    IconButton,
    InputAdornment,
    FormHelperText,
    CircularProgress,
    Button,
    Checkbox,
    Popover,
    FormGroup,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Snowplow from '../snowplow';

import { login, updateErr, authenticateAccount } from 'redux/actionCreators/Auth';
import { NO_LOCATION_ACCESS } from 'constants/Common';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

const NETWORK_ERR_MSG = 'Not connected to the Internet. Please check your connection and try again';
const NO_LOCATION_ACCESS_ERR_MSG =
    'You do not have access to any pharmacy locations at this time. Contact your system administrator.';

function ExtendedSessionPopoverContent({ onClose }) {
    return (
        <div
            style={{
                maxWidth: '600px',
                padding: '16px',
                position: 'relative',
            }}
        >
            <Typography variant="body1">
                For the protection of your patients, the federal government enacted HIPAA, the Health Insurance
                Portability and Accountability Act. In section 165.310(b) all covered entities must:
            </Typography>
            <Typography
                variant="body2"
                style={{
                    margin: '16px',
                }}
            >
                "Implement policies and procedures that specify the proper functions to be performed, the manner in
                which those functions are to be performed, and the physical attributes of the surroundings of a specific
                workstation or class of workstation that can access electronic protected health information."
            </Typography>
            <Typography variant="body1">
                Changing the length of time you may remain logged into this account without activity increases the risk
                that you may expose electronic personal health information to unauthorized individuals. For more
                information, see this{' '}
                <a
                    href="https://www.hhs.gov/sites/default/files/ocr/privacy/hipaa/administrative/securityrule/physsafeguards.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    reference
                </a>
            </Typography>
            <IconButton
                aria-label="Close dialog"
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                }}
                size="small"
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
}

ExtendedSessionPopoverContent.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showPassword: false,
            formError: '',
            extendedSession: false,
            popoverAnchorEl: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    componentDidMount() {
        const {
            authenticateAccount,
            auth: { forgotPwdresetSentTo },
        } = this.props;
        if (!forgotPwdresetSentTo) {
            authenticateAccount();
        }
    }

    componentDidUpdate() {
        const {
            auth,
            history,
            location,
            pharmacy,
            basicMessagingEnabled,
            fullPepEnabled,
            npeLandingPageEnabled,
            launchDarkly,
        } = this.props;

        // have to wait for launch darkly to load since it can affect what is considered enabled
        if (!pharmacy.loading && auth.isAuthorized && launchDarkly.isLDReady) {
            // only do this if we are on the root path, since otherwise no links in NPE actually work
            if (location.pathname === '/') {
                if (npeLandingPageEnabled) {
                    history.push('/home');
                } else {
                    const lastPageVisited = sessionStorage.getItem('lastPageVisited');

                    if (lastPageVisited && lastPageVisited !== '/home') {
                        history.push(lastPageVisited);
                    } else {
                        if (basicMessagingEnabled || fullPepEnabled) {
                            history.push('/inbox');
                        } else {
                            history.push('/reports');
                        }
                    }
                }
            }
        }
    }

    login = (e) => {
        e.preventDefault();
        const { email, password, extendedSession } = this.state;
        const { login } = this.props;
        let formError = '';

        if (_.isEmpty(email)) {
            formError = 'Please enter your username';
        } else if (_.isEmpty(password)) {
            formError = 'Please enter your password';
        } else if (!navigator.onLine) {
            formError = NETWORK_ERR_MSG;
        }

        this.setState({
            formError,
        });

        if (!formError) {
            if (extendedSession) {
                Snowplow.structEvent('Login', 'extended-session-login', null);
            }
            login({ email: (email || '').toLowerCase(), password, extended_session: extendedSession });
        }
    };

    getErrMsg = (errors) => {
        let errMSg = '';

        if (errors) {
            if (errors.message === 'Network Error') {
                errMSg = NETWORK_ERR_MSG;
            } else if (errors.message === NO_LOCATION_ACCESS) {
                errMSg = NO_LOCATION_ACCESS_ERR_MSG;
            } else if (errors.response && [422, 403].indexOf(errors.response.status) > -1) {
                errMSg = 'Invalid username or password entered, please try again';
            } else {
                errMSg = 'System currently unavailable. Please try again';
            }
        }

        return errMSg;
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handlePopoverClick = (event) => {
        this.setState({
            popoverAnchorEl: event.currentTarget,
        });

        Snowplow.structEvent('Login', 'view-session-extension-info', null);
    };

    handlePopoverClose = () => {
        this.setState({
            popoverAnchorEl: null,
        });
    };

    handleInputChange(e) {
        const {
            updateErr,
            auth: { errors },
        } = this.props;
        if (errors) {
            updateErr('');
        }
        this.setState({
            [e.target.name]: e.target.value,
            formError: '',
        });
    }

    toggleVisibility() {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    }

    render() {
        const { email, password, showPassword, formError, extendedSession, popoverAnchorEl } = this.state;
        const {
            auth: { errors, loginInprogress, loadingUserDetails, forgotPwdresetSentTo, loadingUserAccount },
            pharmacy,
        } = this.props;
        const loadingPharmacy = pharmacy.loading;
        const errMsg = formError ? formError : this.getErrMsg(errors);
        const showPopover = Boolean(popoverAnchorEl);
        const showSpinner = loginInprogress || loadingUserDetails || loadingUserAccount || loadingPharmacy;
        const showLogin = !loadingUserDetails && !loadingUserAccount && !loadingPharmacy;

        return (
            <Grid container item xs={12}>
                <div className="login-form-container centered-content">
                    {showSpinner && (
                        <div className="full-width progress-container">
                            <CircularProgress
                                id="login-loader"
                                style={{
                                    margin: '0 auto',
                                }}
                            />
                        </div>
                    )}
                    {showLogin && (
                        <div className="form-container">
                            <img src="/digital-pharmacist-larger.png" alt="" />
                            <div className="header-text">The most powerful way to connect with patients</div>
                            <form className="login-form" noValidate autoComplete="off" onSubmit={this.login}>
                                {forgotPwdresetSentTo && (
                                    <div data-cy="forgot-pwd-reset-text" className="forgot-pwd-reset-text">
                                        An email has been sent to <b>{forgotPwdresetSentTo}</b>. Follow the instructions
                                        to reset your password.
                                    </div>
                                )}

                                <div className="form-control" style={{ marginBottom: '32px' }}>
                                    <TextField
                                        name="email"
                                        data-cy="email"
                                        label="Username"
                                        value={email}
                                        onChange={this.handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="form-control" style={{ marginBottom: '16px' }}>
                                    <TextField
                                        name="password"
                                        data-cy="password"
                                        label="Password"
                                        value={password}
                                        onChange={this.handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.toggleVisibility}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText error id="component-error-text" data-cy="component-error-text">
                                        {errMsg}
                                    </FormHelperText>
                                </div>
                                <div
                                    className="button-control flex-middle flex-space-between"
                                    style={{
                                        color: '#444',
                                        marginBottom: '16px',
                                        ...(isMobile
                                            ? {
                                                  flexDirection: 'column',
                                                  alignItems: 'stretch',
                                              }
                                            : {}),
                                    }}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={extendedSession}
                                                    onChange={this.handleChange}
                                                    name="extendedSession"
                                                    data-cy="extended-session"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Keep me logged in for 12 hours
                                                    <IconButton
                                                        aria-label="Show extended session warning dialog"
                                                        size="small"
                                                        style={{
                                                            marginLeft: '6px',
                                                        }}
                                                        onClick={this.handlePopoverClick}
                                                    >
                                                        <HelpOutlineIcon fontSize="small" />
                                                    </IconButton>
                                                </span>
                                            }
                                        />
                                    </FormGroup>
                                    <Popover
                                        id="extended-session-popover"
                                        open={showPopover}
                                        anchorEl={popoverAnchorEl}
                                        onClose={this.handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <ExtendedSessionPopoverContent onClose={this.handlePopoverClose} />
                                    </Popover>
                                    <Button
                                        type="submit"
                                        data-cy="submit-button"
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                    >
                                        Sign In
                                    </Button>
                                </div>
                                <div className="button-control">
                                    <Link data-cy="forgot-password-link" to="/forgotpassword">
                                        Forgot Password?
                                    </Link>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </Grid>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.any, // eslint-disable-line
    classes: PropTypes.any, // eslint-disable-line
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    npeLandingPageEnabled: state.launchDarkly.npeLandingPageEnabled,
    basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
    fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    launchDarkly: state.launchDarkly,
});

const mapDispatchToProps = {
    login,
    updateErr,
    authenticateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
