import React, { useEffect } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import SpeechRecognition from 'react-speech-recognition';
import { motion } from 'framer-motion';

const getStringWithoutSpacesAndDashes = (str) => str.replace(/-|\s/g, '');

export const SpeechRecognitionButton = ({
    listening,
    startListening,
    abortListening,
    browserSupportsSpeechRecognition,
    finalTranscript,
    resetTranscript,
    sendRecordedText,
}) => {
    useEffect(() => {
        if (finalTranscript) {
            const formattedString = getStringWithoutSpacesAndDashes(finalTranscript);
            resetTranscript();
            abortListening();
            if (isNaN(formattedString)) {
                sendRecordedText(finalTranscript);
            } else {
                sendRecordedText(formattedString);
            }
        }
    }, [finalTranscript]);

    function startSpeech() {
        resetTranscript();
        startListening();
    }

    if (!browserSupportsSpeechRecognition) return null;

    return (
        <div className="speech-recognition-button">
            <motion.div
                key={listening}
                animate={{ scale: 1.2 }}
                transition={{ duration: 1, loop: listening ? Infinity : '' }}
                className={`centered-content user-clickable mic-btn ${listening ? 'animate' : ''}`}
                onClick={listening ? abortListening : startSpeech}
            >
                <MicIcon />
            </motion.div>
        </div>
    );
};

export default SpeechRecognition({
    autoStart: false,
    continous: false,
})(SpeechRecognitionButton);
