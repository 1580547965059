import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
import Snowplow from '../../snowplow';
import { DpDialog } from './DpDialog/DpDialog';
import { onboardingActions } from 'redux/Inbox/Onboarding';
import { logUtil } from 'utils/log';

const NOTIF_PROMPT_SESSION_KEY = 'dpViewedNotifDialog';
const SNOWPLOW_CATEGORY = 'Notification Dialog';
class notificationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount = () => {
        const { fromOnboarding } = this.props;
        // Ask the user only if:
        // 1) their browser supports notifications
        // 2) they haven't allowed or blocked notifications for us yet
        // 3) they haven't already been prompted in this session
        const promptUser =
            'Notification' in window &&
            Notification.permission === 'default' &&
            !sessionStorage.getItem(NOTIF_PROMPT_SESSION_KEY);

        if (!isMobile && (promptUser || fromOnboarding)) {
            this.setState({
                visible: true,
            });

            Snowplow.structEvent(SNOWPLOW_CATEGORY, 'viewed', null);
        }
    };

    closeDialog = ({ permission } = {}) => {
        const { onClose, updateInboxLocationOnboardingClientSteps } = this.props;
        sessionStorage.setItem(NOTIF_PROMPT_SESSION_KEY, 'true');
        this.setState({
            visible: false,
        });

        if (_.isFunction(onClose)) {
            const success = permission && permission !== 'default';
            onClose({ success });
            updateInboxLocationOnboardingClientSteps();
        }
    };

    handlePermissionResponse = (permission) => {
        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'answered', null, null, null, permission);
        this.closeDialog({ permission });
    };

    handleSubmit = () => {
        const handlePermissionResponse = this.handlePermissionResponse;
        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'prompted', null);

        // abstracting the callback to be used when requestPermission is using a callback signature (safari)
        // or to use in the .then when the browser is utilizing a promise signature (mozilla / chrome)
        function handlePermissionCb(permission) {
            handlePermissionResponse(permission);
        }

        try {
            // Most newer browsers support returning a promise for requestPermissions
            const requestPermissionsPromise = Notification.requestPermission(handlePermissionCb);

            // Safari doesn't return a promise for requestPermissions checking for .then
            // prior to a TypeError occurring check the existence of .then
            if (requestPermissionsPromise.then) {
                requestPermissionsPromise.then(handlePermissionCb);
            }
        } catch (error) {
            logUtil.logError('Error asking for push notifications', error);
        }
    };

    render() {
        const { visible } = this.state;
        const { dialogImage, dialogMsg, dialogTitle, fromOnboarding } = this.props;

        return (
            <DpDialog.Dialog id="notificationDialog" open={visible} onClose={this.closeDialog} maxWidth="md">
                <DpDialog.Content>
                    <DpDialog.ContentHeader heading={dialogTitle || 'Desktop Notifications'} onClose={this.closeDialog}>
                        <Fragment>
                            {dialogMsg}
                            <br />
                            To turn these messages on, click <strong>continue</strong> and{' '}
                            <strong>accept notifications</strong> from your browser.
                        </Fragment>
                    </DpDialog.ContentHeader>
                    <Grid container justify="center">
                        <img
                            src={dialogImage}
                            alt="Example browser notification in desktop"
                            style={{ width: '100%', maxWidth: '399px', height: '100%', maxHeight: '228px' }}
                        />
                    </Grid>
                </DpDialog.Content>
                <DpDialog.Actions>
                    {!fromOnboarding && (
                        <Button onClick={this.closeDialog} color="default">
                            Not Now
                        </Button>
                    )}
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        variant={fromOnboarding ? 'contained' : 'outlined'}
                        disableElevation
                    >
                        {fromOnboarding ? 'Turn on Notifications' : 'Continue'}
                    </Button>
                </DpDialog.Actions>
            </DpDialog.Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const bindActionsToDispatch = {
    updateInboxLocationOnboardingClientSteps: onboardingActions.updateInboxLocationOnboardingClientSteps,
};

export default connect(mapStateToProps, bindActionsToDispatch)(notificationDialog);
