import React, { useState } from 'react';
import { Card, CardContent } from '@material-ui/core';

import { CardImage, CardHeader, CardAdditionalText, CardButton } from '../../Drawer/Calendar/NewsletterCardEditPreview';

const CardPreview = (props) => {
    const { cardData } = props;
    const { image_src, card_header, additional_text, button_text, button_link } = cardData;
    const [maxTextWidth, setMaxTextWidth] = useState('100%');

    const onCardImageLoad = (event) => {
        setMaxTextWidth(event.target.offsetWidth);
    };

    const onImageResize = (width) => {
        setMaxTextWidth(width);
    };

    return (
        <Card style={{ minHeight: '200px' }} className="newsletter-review-card">
            <CardContent className="content">
                <CardHeader header={card_header} width={maxTextWidth} />
                <CardImage imageData={{ imgDataUrl: image_src }} onLoad={onCardImageLoad} onResize={onImageResize} />
                <CardAdditionalText additionalText={additional_text} width={maxTextWidth} />
                <CardButton buttonText={button_text} buttonLink={button_link} />
            </CardContent>
        </Card>
    );
};

export default CardPreview;
