import './ReviewRecipientsAndMessageContent.sass';
import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import PatientsTable from 'components/Patients/PatientsTable';
import LocalStorageService from 'utils/localStorageService';
import PhonePreview from '../PhonePreview';
import { formatTime, getWaitlistIdsFromTemplate } from '../schedulingUtil';
import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { formatRawDate, formatRawPhone, getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentWorkflowSessionContext } from 'snowplow/contexts';
import { preprocessMessageTemplateText } from 'components/Patients/messageTemplateUtil';
import { VACCINES_AND_LABELS } from './VaccineDoseInfoContent';

export default function ReviewRecipientsAndMessageContent({
    availableSlots,
    chosenPatients,
    setChosenPatients,
    template,
    auth,
    pharmacy,
    schedulingConfig,
    sendBulkMessage,
    updateWaitlistStatus,
    timeRange,
    history,
    doseInfo,
    isCovidWaitlistEnabled,
    activeLocationCountryCode,
    patientChooseMethod,
    reservationSession,
}) {
    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Review');
    }, []);

    const [isSending, setIsSending] = useState(false);
    const [sendingError, setSendingError] = useState(null);

    const [selectedPatients, setSelectedPatients] = useState({});
    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    const canSend =
        !_.isEmpty(chosenPatients) && (!_.isNil(availableSlots) || schedulingConfig.scheduling_type !== 'dp-clinical');

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);
    const formatPhone = (phone) => formatRawPhone(phone, activeLocationCountryCode);

    const tableHeight = Math.max(340, window.innerHeight - 430);

    const additionalReplacementVariables = {};
    const vaccineType = _.get(reservationSession, 'metadata.covid_dose_info.vaccine_type');
    if (_.isString(vaccineType)) {
        const vaccineTypeLabel = VACCINES_AND_LABELS[vaccineType];
        if (_.isString(vaccineTypeLabel)) {
            additionalReplacementVariables['covid_vaccine_type'] = vaccineTypeLabel;
        }
    }

    return (
        <div className="review-recipients-and-message-content">
            <div className="patients-and-phone-preview">
                <div className="patients-area">
                    <div className="heading">
                        {numeral(_.size(chosenPatients)).format('0,0')} {pluralize('Patient', _.size(chosenPatients))}{' '}
                        to be messaged
                    </div>
                    <div className="table-wrapper" style={{ height: `${tableHeight}px` }}>
                        <PatientsTable
                            patientData={adaptPatientsListIntoPatientData(chosenPatients)}
                            loadMoreRows={() => {}}
                            sortDirection={undefined}
                            formatDate={formatDate}
                            formatPhone={formatPhone}
                            disableSort
                            offsetRight={0}
                            hideActivityColumn
                            showAgeOnly={isCovidWaitlistEnabled}
                            hidePhoneColumn={isCovidWaitlistEnabled}
                            hidePatientActions
                            showPatientTypeColumn={isCovidWaitlistEnabled}
                            showAddedToWaitlistColumn={isCovidWaitlistEnabled}
                            showWaitlistStatusColumn={isCovidWaitlistEnabled}
                            nameFormat={nameFormat}
                            setNameFormat={setNameFormat}
                            showWaitlistLabel={false}
                            waitlistIdToQuery={getWaitlistIdsFromTemplate(template)}
                            selectedUsers={selectedPatients}
                            onSelectedChange={setSelectedPatients}
                        />
                    </div>

                    <div className="table-buttons">
                        <Button
                            className="unselect-button"
                            disabled={_.isEmpty(selectedPatients)}
                            onClick={() => {
                                setSelectedPatients({});
                            }}
                        >
                            Unselect all
                        </Button>
                        <Button
                            className="remove-button"
                            variant="contained"
                            color="primary"
                            disabled={_.isEmpty(selectedPatients)}
                            onClick={() => {
                                setChosenPatients(
                                    _.filter(
                                        chosenPatients,
                                        ({ inbox_user_id }) => !_.has(selectedPatients, inbox_user_id)
                                    )
                                );

                                Snowplow.structEvent(
                                    StructEventCategories.appointments,
                                    'removed-patients-during-review',
                                    null,
                                    '',
                                    'removed',
                                    `${_.size(selectedPatients)}`
                                );

                                setSelectedPatients({});
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                </div>

                <div className="message-preview">
                    <div className="phone-heading">The conversation patients will see on their mobile device</div>
                    <PhonePreview
                        width={300}
                        height={tableHeight + 30}
                        template={template}
                        getMessageText={(key) =>
                            preprocessMessageTemplateText(
                                _.get(template, `message_content.messaging_form.${key}`),
                                auth,
                                additionalReplacementVariables
                            )
                        }
                        fieldKeys={['appointments_available_text']}
                        focusedMessage={'appointments_available_text'}
                        auth={auth}
                        pharmacy={pharmacy}
                        slots={availableSlots}
                        isScrollable
                    />
                </div>
            </div>

            <div className="warning-and-button-area">
                <div className="too-many-patients-warning-outer">
                    {_.isFinite(_.get(doseInfo, 'number_of_doses')) &&
                    _.size(chosenPatients) > _.get(doseInfo, 'number_of_doses') ? (
                        <div className="too-many-patients-warning">
                            <WarningIcon />

                            <div className="text">
                                <div className="main-line">You are contacting more patients than available doses.</div>
                                <div className="sub-line">
                                    This may cause some patients to not be able to schedule an appointment if many
                                    patients choose to respond.
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSending || !canSend}
                    onClick={async () => {
                        // TODO: This 2 - 3 requests should probably be moved into a backend service so that we
                        // have a higher percentage chance they will all succeed and will have more ability to roll back
                        // changes when they don't

                        setIsSending(true);

                        const rawMessage = _.get(
                            template,
                            'message_content.messaging_form.appointments_available_text',
                            template.message
                        );

                        const message = preprocessMessageTemplateText(rawMessage, auth, additionalReplacementVariables);

                        try {
                            await sendBulkMessage(
                                {
                                    ...template,
                                    reservation_session_id: _.get(reservationSession, 'session_id'),
                                    message,
                                    message_content: {
                                        ...template.message_content,
                                        messaging_form: {
                                            ...template.message_content.messaging_form,
                                        },
                                        text: message,
                                    },
                                },
                                _.map(chosenPatients, ({ inbox_user_id }) => inbox_user_id)
                            );
                        } catch (error) {
                            // todo: show error state
                            setIsSending(false);
                            setSendingError({ step: 'message_send', error });

                            console.log('ERROR', error);

                            return;
                        }

                        if (isCovidWaitlistEnabled) {
                            // mark any uncontacted patients as contacted...
                            const waitlistIdsSet = new Set(
                                _.get(template, 'message_content.messaging_form.waitlist_ids')
                            );
                            const waitlistIdInboxUserIdPairs = _.map(chosenPatients, ({ waitlists, inbox_user_id }) => {
                                const waitlistId = _.get(
                                    _.find(waitlists, ({ waitlist_id }) => waitlistIdsSet.has(waitlist_id)),
                                    'waitlist_id'
                                );
                                return {
                                    inbox_user_id,
                                    waitlistId,
                                };
                            });

                            try {
                                await updateWaitlistStatus(waitlistIdInboxUserIdPairs, 'Contacted');
                            } catch (error) {
                                setIsSending(false);
                                setSendingError({ step: 'update_waitlist_status', error });

                                return;
                            }
                        }

                        Snowplow.structEvent(StructEventCategories.appointments, 'sent-scheduling-requests', [
                            new AppointmentWorkflowSessionContext()
                                .setTemplateId(template.inbox_conversation_template_id)
                                .setStartDate(formatTime(_.get(timeRange, 'from')))
                                .setEndDate(formatTime(_.get(timeRange, 'to')))
                                .setVaccineType(doseInfo.vaccine_type)
                                .setVaccineDoses(doseInfo.number_of_doses)
                                .setPatientChooseMethod(
                                    patientChooseMethod !== 'manual' ? 'waitlist-by-count' : 'manual'
                                )
                                .setNumberOfPatientsContacted(_.size(chosenPatients))
                                .build(),
                        ]);

                        setIsSending(false);
                        history.push('/workflows');
                    }}
                >
                    {isSending ? (
                        <span style={{ display: 'flex', alignItems: 'center ' }}>
                            <CircularProgress size={20} />
                            &nbsp;Sending requests...
                        </span>
                    ) : (
                        'Send Scheduling Requests'
                    )}
                </Button>
            </div>

            {_.isNil(sendingError) ? null : (
                <Snackbar
                    className="sending-error-snackbar"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => setSendingError(null)}
                    message={
                        <div className="sending-error-message">
                            <WarningIcon />
                            <div className="text">
                                <div className="main-line">An error occurred sending these messages.</div>
                                <div className="sub-line">
                                    Please ensure you are connected to the internet and if this continues please contact
                                    Digital Pharmacist support.
                                </div>
                            </div>
                            <Button className="ok-button" onClick={() => setSendingError(null)}>
                                OK
                            </Button>
                        </div>
                    }
                />
            )}
        </div>
    );
}

function adaptPatientsListIntoPatientData(patients) {
    const loadedPatients = {};
    _.each(patients, (p, i) => (loadedPatients[i] = p));
    return {
        newLoad: false,
        patientCount: _.size(patients),
        loadedPatients: loadedPatients,
        loadedPatientsByInboxUserId: _.keyBy(patients, ({ inbox_user_id }) => inbox_user_id),
    };
}
