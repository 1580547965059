import React, { useEffect } from 'react';
import './ConfirmMessagePage.sass';
import { Button } from '@material-ui/core';
import ReviewBulkMessagePane from '../ReviewBulkMessagePane';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import _ from 'lodash';
import { canMessagePatient } from '../patientsUtil';
import { sendBulkMessage, clearPatientOpportunities } from '../../../redux/Patient/Connect/action';
import { connect } from 'react-redux';
import Snowplow, { RxConferPatientContext, RxConferWorkflowContext } from '../../../snowplow';

function ConfirmMessagePage({
    patients,
    template,
    gridStyle,
    history,
    goBack,
    connect,
    sendBulkMessage,
    clearPatientOpportunities,
}) {
    const messageable = _.filter(patients, (p) => canMessagePatient(p, false));
    const unknownPhoneTypes = _.filter(patients, ({ phone_type }) => _.isNil(phone_type));

    useEffect(() => {
        if (!_.isNil(template)) {
            Snowplow.pageView('Workflows - Message Review', [
                new RxConferWorkflowContext().setWorkflowTemplateId(template.inbox_conversation_template_id).build(),
                new RxConferPatientContext().setPatientCount(patients.length).build(),
            ]);
        }
    }, [template, patients]);

    return (
        <div className="confirm-message-page">
            <div className="confirm-message-title-line" style={{ width: `${gridStyle.width}px` }}>
                <span className="button-and-title">
                    <Button onClick={goBack}>
                        <KeyboardBackspaceIcon /> Go Back
                    </Button>
                    <span className="title">Review message</span>
                </span>
                <span className="subtitle">
                    If you are happy with this message then click "Send Message" to send it to all of the shown
                    patients.
                </span>
            </div>

            <div className="confirm-message-content" style={{ width: `${gridStyle.width}px` }}>
                <ReviewBulkMessagePane {...arguments[0]} selectedTemplate={template} selectedUsers={patients} />

                <div className="actions">
                    <Button className="cancel-button" onClick={() => history.push('/workflows')}>
                        Cancel
                    </Button>
                    <Button
                        className="message-button"
                        variant="contained"
                        color="primary"
                        disabled={_.isEmpty(messageable) || !_.isEmpty(unknownPhoneTypes) || connect.bulkMessageSending}
                        onClick={() => {
                            const inboxUserIds = _.map(
                                _.filter(patients, canMessagePatient),
                                ({ inbox_user_id }) => inbox_user_id
                            );
                            sendBulkMessage(template, inboxUserIds).then(() => {
                                history.push('/workflows');
                            });
                        }}
                    >
                        {connect.bulkMessageSending ? 'Sending...' : 'Send Message'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { connect } = state;

    return {
        connect,
    };
}

const bindActionsToDispatch = {
    sendBulkMessage,
    clearPatientOpportunities,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ConfirmMessagePage);
