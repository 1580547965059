import React, { PureComponent, Fragment } from 'react';
import { Card, Cell, CardText, CircularProgress, Grid, Button } from 'react-md';
import { Button as MUButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormHelperText } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import axios from 'axios';

import { CardHeader, SectionTitle } from 'components/Common';
import Schedule from 'components/Common/Schedule';
import {
    fetchDefaultHours,
    fetchExceptionHours,
    addExceptionHours,
    updateExceptionTitle,
    holidaySelected,
    saveDefaultHours,
    updateDaysOfWeekSettings,
    updateHolidaysSettings,
    updateExceptionDate,
    saveHolidays,
    removeExceptionHour,
    loadExceptionConflicts,
    fetchHolidayList,
    updateHolidayRepeat,
    saveCustomHoliday,
    removeLocationHoliday,
} from 'redux/actionCreators/Settings/BusinessInformation';

import { displayToast } from 'redux/actionCreators/Snackbar';
import { weekDayMap } from 'constants/BusinessInformation';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class PharmacyHours extends PureComponent {
    constructor(props) {
        super(props);
        this.exceptionformErr = [];
    }

    componentDidMount() {
        const { loadHolidayList } = this.props;

        loadHolidayList();
        this.loadData();
    }

    loadData = () => {
        const { loadDefaultHours, loadExceptionHours, loadExceptionConflicts } = this.props;

        loadDefaultHours();
        loadExceptionHours().then((resp) => {
            if ('data' in resp && resp.data.length) {
                loadExceptionConflicts();
            }
        });
    };

    componentDidUpdate(prevProps) {
        const { auth, loadHolidayList } = this.props;

        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadHolidayList();
            this.loadData();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    onSave = async () => {
        const { defaultHours, exceptionHours, displayToast, saveHolidays, loadHolidayList } = this.props;
        const defaultHoursPayload = defaultHours.filter((hour) => hour.dirty);
        let { isValid, newHolidayPayload, customHours, locationHolidays, newHolidays } = this.getHolidayPayload(
            exceptionHours
        );

        isValid = this.exceptionformErr.length ? false : isValid;
        if (isValid) {
            let saveHolidayResp;
            if (newHolidayPayload.length) {
                saveHolidayResp = await saveHolidays(newHolidayPayload);
                if ('resp' in saveHolidayResp) {
                    const holidayList = await loadHolidayList();
                    newHolidays.forEach((newHoliday) => {
                        const holidayDetail = holidayList.resp.result.find(
                            (holiday) => holiday.name === newHoliday.name
                        );
                        locationHolidays.push(this.getLocationHolidayObject('0', holidayDetail.id, newHoliday));
                    });
                }
            }

            if (!saveHolidayResp || (saveHolidayResp && 'resp' in saveHolidayResp)) {
                this.saveStoreHours(defaultHoursPayload, locationHolidays, customHours);
            }
        } else {
            displayToast({ text: 'Fix the error(s) to save', type: 'error' });
        }
    };

    saveStoreHours = (defaultHoursPayload, locationHolidays, customHours) => {
        const { saveHours, saveCustomHoliday, displayToast } = this.props;
        const multipleCalls = [];

        if (defaultHoursPayload.length) {
            multipleCalls.push(saveHours(defaultHoursPayload));
        }
        if (locationHolidays.length) {
            multipleCalls.push(saveCustomHoliday({ locationHolidays }));
        }
        if (customHours.length) {
            multipleCalls.push(saveCustomHoliday({ customHours }));
        }

        if (multipleCalls.length) {
            axios.all(multipleCalls).then((multipleCallsResp) => {
                let hasErr = false;
                for (let idx = 0; idx < multipleCallsResp.length; idx++) {
                    if ('err' in multipleCallsResp[idx]) {
                        hasErr = true;
                        break;
                    }
                }
                if (!hasErr) {
                    this.loadData();
                }
            });
        } else {
            displayToast({ text: 'There is no change to save', type: 'info' });
        }
    };

    getHolidayPayload = (exceptionHours) => {
        const newHolidayPayload = [];
        const customHours = [];
        const locationHolidays = [];
        const newHolidays = [];
        let isValid = true;

        for (let idx = 0; idx < exceptionHours.length; idx++) {
            if (exceptionHours[idx].name === '') {
                isValid = false;
                break;
            }

            if (exceptionHours[idx].dirty && !exceptionHours[idx].holidayId) {
                if (exceptionHours[idx].id === '0' && exceptionHours[idx].repeats) {
                    const recurData =
                        moment(exceptionHours[idx].startDate).format('MM/DD') +
                        '-' +
                        moment(exceptionHours[idx].endDate).format('MM/DD');
                    newHolidayPayload.push({
                        id: '0',
                        name: exceptionHours[idx].name,
                        recurData,
                        recurTypeId: 4,
                        countryId: 1,
                    });
                    newHolidays.push(exceptionHours[idx]);
                } else {
                    customHours.push(exceptionHours[idx]);
                }
            } else if (exceptionHours[idx].dirty && exceptionHours[idx].holidayId) {
                const id = exceptionHours[idx].id === '0' ? '0' : exceptionHours[idx].locHolidayId;
                locationHolidays.push(
                    this.getLocationHolidayObject(id, exceptionHours[idx].holidayId, exceptionHours[idx])
                );
            }
        }

        return { isValid, newHolidayPayload, customHours, locationHolidays, newHolidays };
    };

    getLocationHolidayObject = (id, holidayId, newHoliday) => {
        return {
            id,
            holidayId,
            startHour: newHoliday.startHour,
            repeats: newHoliday.repeats,
            startMinute: newHoliday.startMinute,
            endHour: newHoliday.endHour,
            endMinute: newHoliday.endMinute,
        };
    };

    onHourChange = (timeString, day, hourType, isHolidaySchedule) => {
        const { updateExcecptionHours, updateDefaultHours } = this.props;
        const hourVal = parseInt(moment(timeString, 'hh:mm a').format('HH'), 10);
        const minVal = parseInt(moment(timeString, 'hh:mm a').format('mm'), 10);

        if (isHolidaySchedule) {
            updateExcecptionHours(day, hourType, hourVal, minVal);
        } else {
            updateDefaultHours(day, hourType, hourVal, minVal);
        }
    };

    onCloseToggle = (idx, weekDay, isHolidaySchedule) => {
        const startTime = weekDay.startHour || weekDay.endHour ? '0:0' : '8:0';
        const endTime = weekDay.startHour || weekDay.endHour ? '0:0' : '20:0';

        this.onHourChange(startTime, idx, 'start', isHolidaySchedule);
        this.onHourChange(endTime, idx, 'end', isHolidaySchedule);
    };

    onRepeatToggle = (idx) => {
        const { updateHolidayRepeat } = this.props;
        updateHolidayRepeat(idx);
    };

    onDeleteToggle = (idx) => {
        const { exceptionHours, removeExceptionHour, removeLocationHoliday } = this.props;
        if (exceptionHours[idx].id !== '0' && exceptionHours[idx].holidayId) {
            removeLocationHoliday({ exceptionHours, idx });
        } else {
            removeExceptionHour(exceptionHours, idx);
        }
    };

    handleDateChange = (date, type, day, idx) => {
        const { updateDateRange } = this.props;
        let { startDate, endDate } = day;
        if (type === 'start') {
            startDate = date;
        } else {
            endDate = date;
        }
        const errIDx = this.exceptionformErr.indexOf(idx);
        if (errIDx > -1) this.exceptionformErr.splice(errIDx, 1);
        if (!moment(startDate).isSameOrBefore(endDate)) {
            this.exceptionformErr.push(idx);
        }
        updateDateRange({ startDate, endDate }, idx);
    };

    updateTitleText = (val, idx) => {
        const { updateTitle } = this.props;
        updateTitle(val, idx);
    };

    render() {
        const {
            defaultHours,
            exceptionHours,
            addExceptionHours,
            loadingDefaultHours,
            loadingExceptionHours,
            savingProgress,
            savingCustomProgress,
            holidayList,
            holidaySelected,
            savingHolidayProgress,
            loadingHoliday,
            auth: { selectedLocation },
        } = this.props;
        const { write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.pharmacyHours;
        // TODO: This component should be refactored
        return (
            <Grid className="business-info-container">
                {/* Note, write here means the user role can create, update, delete pharmacy hours */}
                <Can I={write} this={caslSubject} passThrough>
                    {(writeAllowed) => (
                        <Cell size={12}>
                            <Card className="outer-card">
                                <CardHeader
                                    title="Pharmacy Hours"
                                    disabled={
                                        savingProgress ||
                                        savingCustomProgress ||
                                        savingHolidayProgress ||
                                        loadingHoliday
                                    }
                                    loading={savingProgress || savingCustomProgress || savingHolidayProgress}
                                    onBack={this.onBack}
                                    onSave={writeAllowed && this.onSave}
                                />

                                <CardText>
                                    <Card className="inner-card">
                                        {loadingDefaultHours || loadingExceptionHours ? (
                                            <CircularProgress id="business-hours-spinner" />
                                        ) : (
                                            <Fragment>
                                                <CardText>
                                                    <SectionTitle text="Regular Business Hours" />
                                                    {defaultHours.map((weekDay) => (
                                                        <div key={weekDay.day} className="grid-container">
                                                            <Cell size={8} phoneSize={4}>
                                                                <Schedule
                                                                    title={weekDayMap[weekDay.day]}
                                                                    perms={
                                                                        selectedLocation ? selectedLocation.perms : null
                                                                    }
                                                                    {...weekDay}
                                                                    onStartHourChange={(timeString) =>
                                                                        this.onHourChange(
                                                                            timeString,
                                                                            weekDay.day,
                                                                            'start'
                                                                        )
                                                                    }
                                                                    onEndHourChange={(timeString) =>
                                                                        this.onHourChange(
                                                                            timeString,
                                                                            weekDay.day,
                                                                            'end'
                                                                        )
                                                                    }
                                                                    onCloseToggle={() =>
                                                                        this.onCloseToggle(weekDay.day, weekDay)
                                                                    }
                                                                    isReadOnly={!writeAllowed}
                                                                />
                                                            </Cell>
                                                        </div>
                                                    ))}
                                                    <br />
                                                    <SectionTitle text="Upcoming Holiday Hours" />
                                                    {exceptionHours.map((day, idx) => {
                                                        // assign a variable to strip out the time from the start date
                                                        const startDayNoTime = moment
                                                            .utc(day.startDate)
                                                            .startOf('day')
                                                            .format('YYYY-MM-DD');
                                                        return (
                                                            <div
                                                                key={day.id === '0' ? day.uuid : day.id}
                                                                className="grid-container upcoming-holiday-container"
                                                            >
                                                                <Cell
                                                                    size={8}
                                                                    phoneSize={4}
                                                                    tabletSize={8}
                                                                    className="exception-hour-container flex-middle"
                                                                >
                                                                    <Schedule
                                                                        title={day.name}
                                                                        holidayList={holidayList}
                                                                        onHolidaySelected={(holidayDetail) =>
                                                                            holidaySelected({ idx, holidayDetail })
                                                                        }
                                                                        perms={
                                                                            selectedLocation
                                                                                ? selectedLocation.perms
                                                                                : null
                                                                        }
                                                                        edit
                                                                        {...day}
                                                                        onRepeatToggle={() => this.onRepeatToggle(idx)}
                                                                        onStartHourChange={(timeString) =>
                                                                            this.onHourChange(
                                                                                timeString,
                                                                                idx,
                                                                                'start',
                                                                                true
                                                                            )
                                                                        }
                                                                        onEndHourChange={(timeString) =>
                                                                            this.onHourChange(
                                                                                timeString,
                                                                                idx,
                                                                                'end',
                                                                                true
                                                                            )
                                                                        }
                                                                        onCloseToggle={() =>
                                                                            this.onCloseToggle(idx, day, true)
                                                                        }
                                                                        onTitleChange={(val) =>
                                                                            this.updateTitleText(val, idx)
                                                                        }
                                                                        isReadOnly={!writeAllowed}
                                                                    />
                                                                </Cell>
                                                                <Cell
                                                                    size={3}
                                                                    phoneSize={4}
                                                                    tabletSize={8}
                                                                    className="exception-date-container"
                                                                >
                                                                    <div className="grid-container date-range-picker">
                                                                        <Cell size={6} tabletSize={4} phoneSize={2}>
                                                                            <MuiPickersUtilsProvider
                                                                                utils={DateFnsUtils}
                                                                            >
                                                                                <DatePicker
                                                                                    label="Start"
                                                                                    value={moment(day.startDate)}
                                                                                    format="MM/dd/yyyy"
                                                                                    disablePast
                                                                                    maxDate={moment()
                                                                                        .add(1, 'year')
                                                                                        .subtract(1, 'day')}
                                                                                    disabled={
                                                                                        !writeAllowed ||
                                                                                        Boolean(day.holidayId)
                                                                                    }
                                                                                    //error is set to false to disable the issue with past holiday dates being shown as invaild due to enabling disablePast in the picker
                                                                                    error={false}
                                                                                    className="date-picker"
                                                                                    onChange={(dateVal) =>
                                                                                        this.handleDateChange(
                                                                                            dateVal,
                                                                                            'start',
                                                                                            day,
                                                                                            idx
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Cell>
                                                                        <Cell size={6} tabletSize={4} phoneSize={2}>
                                                                            <MuiPickersUtilsProvider
                                                                                utils={DateFnsUtils}
                                                                            >
                                                                                <DatePicker
                                                                                    label="End"
                                                                                    value={moment(day.endDate)}
                                                                                    format="MM/dd/yyyy"
                                                                                    minDate={moment(startDayNoTime)}
                                                                                    maxDate={moment()
                                                                                        .add(1, 'year')
                                                                                        .subtract(1, 'day')}
                                                                                    onChange={(dateVal) =>
                                                                                        this.handleDateChange(
                                                                                            dateVal,
                                                                                            'end',
                                                                                            day,
                                                                                            idx
                                                                                        )
                                                                                    }
                                                                                    minDateMessage={<span></span>}
                                                                                    className="date-picker"
                                                                                    disabled={
                                                                                        !writeAllowed ||
                                                                                        Boolean(day.holidayId)
                                                                                    }
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Cell>
                                                                    </div>
                                                                    {!moment(day.startDate).isSameOrBefore(
                                                                        day.endDate
                                                                    ) && (
                                                                        <div className="date-picker-errmsg">
                                                                            End date shouldn't be before the start date
                                                                        </div>
                                                                    )}
                                                                </Cell>
                                                                <Cell
                                                                    size={1}
                                                                    phoneSize={4}
                                                                    tabletSize={8}
                                                                    className="centered-content"
                                                                >
                                                                    {day.isDeleting && (
                                                                        <CircularProgress id="remove-exception-hour-spinner" />
                                                                    )}

                                                                    {!day.isDeleting && writeAllowed && (
                                                                        <Fragment>
                                                                            <Cell phoneHidden tabletHidden>
                                                                                <Button
                                                                                    id="delete-toggle-btn"
                                                                                    icon
                                                                                    onClick={() =>
                                                                                        this.onDeleteToggle(idx)
                                                                                    }
                                                                                    disabled={!writeAllowed}
                                                                                >
                                                                                    delete
                                                                                </Button>
                                                                            </Cell>
                                                                            <Cell
                                                                                desktopHidden
                                                                                phoneSize={4}
                                                                                tabletSize={8}
                                                                            >
                                                                                <MUButton
                                                                                    onClick={() =>
                                                                                        this.onDeleteToggle(idx)
                                                                                    }
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    className="mobile-button"
                                                                                    startIcon={<DeleteIcon />}
                                                                                    disabled={!writeAllowed}
                                                                                >
                                                                                    DELETE
                                                                                </MUButton>
                                                                            </Cell>
                                                                        </Fragment>
                                                                    )}
                                                                </Cell>
                                                                <FormHelperText
                                                                    error
                                                                    className="exception-hour-error-text"
                                                                >
                                                                    {day.errMsg}
                                                                </FormHelperText>
                                                            </div>
                                                        );
                                                    })}
                                                    {exceptionHours.length === 0 && (
                                                        <div className="grid-container">
                                                            <Cell size={12} className="centered-content">
                                                                No Holiday Configured
                                                            </Cell>
                                                        </div>
                                                    )}
                                                    <Grid>
                                                        <Cell size={12} className="centered-content">
                                                            {writeAllowed ? (
                                                                <Button
                                                                    className="add-btn"
                                                                    flat
                                                                    secondary
                                                                    onClick={addExceptionHours}
                                                                >
                                                                    + ADD SPECIAL HOURS
                                                                </Button>
                                                            ) : (
                                                                <div>You don't have permission to add/update</div>
                                                            )}
                                                        </Cell>
                                                    </Grid>
                                                </CardText>
                                            </Fragment>
                                        )}
                                    </Card>
                                </CardText>
                            </Card>
                        </Cell>
                    )}
                </Can>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.businessInformation,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadHolidayList: () => dispatch(fetchHolidayList()),
    loadDefaultHours: () => dispatch(fetchDefaultHours()),
    loadExceptionHours: () => dispatch(fetchExceptionHours()),
    loadExceptionConflicts: () => dispatch(loadExceptionConflicts()),
    addExceptionHours: () => dispatch(addExceptionHours()),
    saveHours: (data) => dispatch(saveDefaultHours(data)),
    saveCustomHoliday: (data) => dispatch(saveCustomHoliday(data)),
    updateDefaultHours: (day, key, hour, min) => dispatch(updateDaysOfWeekSettings({ day, key, hour, min })),
    updateExcecptionHours: (idx, key, hour, min) => dispatch(updateHolidaysSettings({ idx, key, hour, min })),
    displayToast: (data) => dispatch(displayToast(data)),
    updateDateRange: (data, idx) => dispatch(updateExceptionDate({ data, idx })),
    updateTitle: (data, idx) => dispatch(updateExceptionTitle({ data, idx })),
    removeExceptionHour: (exceptionHour, removeIdx) => dispatch(removeExceptionHour({ exceptionHour, removeIdx })),
    holidaySelected: (data) => dispatch(holidaySelected(data)),
    updateHolidayRepeat: (data) => dispatch(updateHolidayRepeat(data)),
    saveHolidays: (data) => dispatch(saveHolidays(data)),
    removeLocationHoliday: (data) => dispatch(removeLocationHoliday(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyHours);
