import React, { Fragment } from 'react';
import { Cell } from 'react-md';

import { DemographicChart, DemographicLegends } from 'components/DigitalMarketingDashboard/DemographicChart';
import DescriptionView from 'components/DigitalMarketingDashboard/DescriptionView';
import { SectionTitle } from 'components/Common';
import { DescriptionLabel } from 'constants/DMDashboard';

const Demographics = ({ demographicAgeGroupClickData, demographicDescription, tooltipTextMap }) => {
    return (
        <div className="grid-container demographic-chart">
            <Cell size={12}>
                <SectionTitle text="Demographics" />
                <div className="grid-container">
                    {Object.keys(demographicAgeGroupClickData).length < 1 ||
                    Object.keys(demographicDescription).length < 1 ? (
                        <Cell size={12} className="flex-center flex-middle" style={{ height: '200px' }}>
                            No Data Found
                        </Cell>
                    ) : (
                        <Fragment>
                            {(demographicDescription.clicks.male > 0 || demographicDescription.clicks.female > 0) && (
                                <DemographicLegends />
                            )}
                            <Cell size={6} className="no-margin">
                                {demographicDescription.clicks.male > 0 || demographicDescription.clicks.female > 0 ? (
                                    <DemographicChart barChartData={demographicAgeGroupClickData} />
                                ) : (
                                    <div className="full-width flex-center flex-middle" style={{ height: '100%' }}>
                                        No info available to draw chart
                                    </div>
                                )}
                            </Cell>

                            <Cell size={4} desktopOffset={2} className="description-wrapper">
                                {Object.keys(demographicDescription).length === 0 ? (
                                    <div
                                        style={{ height: '100%', minHeight: '200px' }}
                                        className="flex-center flex-middle"
                                    >
                                        No Description available
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className="md-font-medium heading">Description</div>
                                        {Object.keys(demographicDescription).map((key, idx) => (
                                            <DemographicDescriptionView
                                                key={idx}
                                                tooltipText={tooltipTextMap[key]}
                                                metricName={key}
                                                metricDetail={demographicDescription[key]}
                                            />
                                        ))}
                                    </Fragment>
                                )}
                            </Cell>
                        </Fragment>
                    )}
                </div>
            </Cell>
        </div>
    );
};

const DemographicDescriptionView = ({ metricName, metricDetail, tooltipText }) => {
    return Object.keys(metricDetail).map((key, idx) => {
        const descName = key === 'total' ? metricName : key;
        const descVal = metricDetail[key].toLocaleString('en-US');
        const isTitle = key === 'total';

        return (
            <DescriptionView
                key={idx}
                descName={DescriptionLabel[descName]}
                descVal={descVal}
                tooltipText={tooltipText}
                isTitle={isTitle}
            />
        );
    });
};

export default Demographics;
