import React from 'react';
import { Cell, Toolbar } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AutoLogout from './Common/AutoLogout';
import IconMenus from './IconMenus';
import DesktopMenuItems from './DesktopMenuItems';
import { fetchUnreadVoicemails } from 'redux/actionCreators/Voicemail';
import { fetchPatientOpportunities, clearPatientOpportunities } from '../redux/Patient/Connect/action';
import { getActiveOpportunityCountFromState } from './Patients/patientsUtil';

export const ToolbarMenu = ({
    unreadTotalMails,
    tigerPistolEnabled,
    socketMessage,
    location,
    unreadMessageCount,
    fetchPatientOpportunities,
    clearPatientOpportunities,
    activeOpportunityCount,
    history,
    npeLandingPageEnabled,
    npeAppointmentsEnabled,
    workflowOpportunities,
}) => {
    return (
        <div className="grid-container top-menu-container">
            <Cell phoneSize={1} desktopHidden className="flex-middle menu-items">
                <img src="/dp-white-icon.png" alt="" onClick={() => npeLandingPageEnabled && history.push('/home')} />
            </Cell>
            <DesktopMenuItems
                socketMessage={socketMessage}
                unreadTotalMails={unreadTotalMails}
                tigerPistolEnabled={tigerPistolEnabled}
                unreadMessageCount={unreadMessageCount}
                location={location}
                fetchPatientOpportunities={fetchPatientOpportunities}
                clearPatientOpportunities={clearPatientOpportunities}
                activeOpportunityCount={activeOpportunityCount}
                workflowOpportunities={workflowOpportunities}
                history={history}
                npeLandingPageEnabled={npeLandingPageEnabled}
                npeAppointmentsEnabled={npeAppointmentsEnabled}
            />
            <AutoLogout />
        </div>
    );
};

const mapStateToPropsToolBar = (state) => {
    const { pharmacy, voicemails, socket, inboxUser, connect, launchDarkly } = state;
    return {
        unreadTotalMails: voicemails.unreadTotalMails,
        tigerPistolEnabled: pharmacy.tigerPistolEnabled,
        socketMessage: socket.message,
        unreadMessageCount: inboxUser.unreadMessageCount,
        activeOpportunityCount: getActiveOpportunityCountFromState(state),
        workflowOpportunities: connect.opportunities,
        npeAppointmentsEnabled: launchDarkly.npeAppointmentsEnabled,
        npeLandingPageEnabled: false, //launchDarkly.npeLandingPageEnabled,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchUnreadVoicemails: (data) => dispatch(fetchUnreadVoicemails(data)),
    fetchPatientOpportunities: (birthday) => dispatch(fetchPatientOpportunities(birthday)),
    clearPatientOpportunities: () => dispatch(clearPatientOpportunities()),
});

export const ToolbarChildren = withRouter(connect(mapStateToPropsToolBar, mapDispatchToProps)(ToolbarMenu));

export const AppBar = (props) => (
    <Toolbar colored fixed actions={<IconMenus />}>
        {' '}
        <ToolbarChildren />{' '}
    </Toolbar>
);

export default AppBar;
