import * as axios from 'axios';

/**
 * A common template for the API calls for GET.
 *   - Dispatches the initial action ex: FETCHING_SOMETHING
 *   - Makes the Async API request using axios
 *   - On Success, dispatches the action for success, ex: SOMETHING_FETCHED_SUCCESSFULLY
 *   - On Failure, dispatches the action for failure, ex: FETCHING_SOMETHING_FAILED
 */
export const get = (url, args) => {
    const params = args === 'undefined' ? {} : args;

    return axios.get(url, params).then((response) => response.data);
};

export const getBlobAndDownload = ({ url, filename }) => {
    return axios
        .request({
            url,
            method: 'GET',
            responseType: 'blob',
        })
        .then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
};

/**
 * A common template for the API calls for POST.
 *   - Dispatches the initial action ex: CREATING_SOMETHING
 *   - Makes the Async API request using axios
 *   - On Success, dispatches the action for success, ex: SOMETHING_CREATED_SUCCESSFULLY
 *   - On Failure, dispatches the action for failure, ex: SOMETHING_CREATION_FAILED
 */
export const post = (url, data, headers = {}) => axios.post(url, data, headers).then((response) => response.data);

/**
 * A common template for the API calls for PUT.
 *   - Dispatches the initial action ex: UPDATING_SOMETHING
 *   - Makes the Async API request using axios
 *   - On Success, dispatches the action for success, ex: SOMETHING_UPDATED
 *   - On Failure, dispatches the action for failure, ex: UPDATING_SOMETHING_FAILED
 */

export const put = (url, data, headers = {}) => axios.put(url, data, headers).then((response) => response.data);

/**
 * A common template for the API calls for DELETE.
 *   - Dispatches the initial action ex: DELETING_SOMETHING
 *   - Makes the Async API request using axios
 *   - On Success, dispatches the action for success, ex: SOMETHING_DELETED
 *   - On Failure, dispatches the action for failure, ex: DELETING_SOMETHING_FAILED
 */
// since delete is a reserved word, we use deleteReq
export const deleteReq = (url, data, headers = {}) =>
    axios.delete(url, { headers, data }).then((response) => response.data);

export const patch = (url, data, headers = {}) => axios.patch(url, data, headers).then((response) => response.data);

// exporting an object for testability / mocking purposes
// utilizing a named export for auto import functionality
export const restActionCreators = {
    get,
    getBlobAndDownload,
    post,
    put,
    deleteReq,
    patch,
};
