import './AssessmentsFilterDropdownContent.sass';
import React, { useState, useEffect } from 'react';
import { Dialog, Select, MenuItem, InputAdornment, DialogContent } from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import { updateQueryParameters } from '../../../utils/urlHelper';
import moment from 'moment';
import { getAbbreviatedDateInputFormatByCountryCode } from '../../../utils/helper';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const TIMEFRAME_OPTIONS = [
    { value: 'any', label: 'at any time' },
    { value: '1mo', label: 'in the last month' },
    { value: '6mo', label: 'in the last 6 months' },
    { value: '1yr', label: 'in the last year' },
    { value: 'calendar-year', label: 'in this calendar year' },
    { value: 'custom', label: 'in a custom date range...' },
];
const TIMEFRAME_BY_VALUE = _.keyBy(TIMEFRAME_OPTIONS, ({ value }) => value);

export default function AssessmentsFilterDropdownContent({
    assessment,
    queryParams,
    assessmentIndex,
    history,
    location,
    activeLocationCountryCode,
}) {
    const filters = getFilterFromQueryParams(queryParams);
    const sentAssessments = _.get(assessment, 'sentAssessments');
    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    const [interactionType, setInteractionType] = useState(
        _.get(filters[assessmentIndex], 'interactionType', 'received')
    );
    const [chosenAssessment, setChosenAssessment] = useState(_.get(filters[assessmentIndex], 'assessment', 'none'));
    const [timeframe, setTimeframe] = useState(_.get(filters[assessmentIndex], 'timeframe', 'any'));
    const [customDateRangeOpen, setCustomDateRangeOpen] = useState(false);

    const formatDate = (str) => moment(str, 'YYYY-MM-DD').format(displayedDateFormat);

    useEffect(() => {
        if (chosenAssessment === 'none') {
            return;
        }
        let param = _.map(filters, (filter, i) => {
            if (i === assessmentIndex) {
                return `${interactionType}|${chosenAssessment}|${timeframe}`;
            }
            return `${filter.interactionType}|${filter.assessment}|${filter.timeframe}`;
        }).join(',');

        if (assessmentIndex === _.size(filters)) {
            param += `,${interactionType}|${chosenAssessment}|${timeframe}`;
        }

        history.push(`${location.pathname}${updateQueryParameters(location, 'assessments', param)}`);
    }, [interactionType, chosenAssessment, timeframe]);

    useEffect(() => {
        if (!_.isString(queryParams.assessments) || _.isNil(queryParams.assessments.split(',')[assessmentIndex])) {
            setInteractionType('received');
            setChosenAssessment('none');
            setTimeframe('any');
        }
    }, [queryParams.assessments]);

    return (
        <div className="assessments-filter-dropdown-content">
            <div className="explanation">
                Filter patients based on how and when they interacted with pharmacy sent assessments.
            </div>
            <div className="controls">
                <div className="controls-heading">Filter to patients that have...</div>
                <Select
                    fullWidth
                    className="interaction-type-select"
                    value={interactionType}
                    variant="outlined"
                    onChange={(e) => {
                        setInteractionType(e.target.value);
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    <MenuItem value="received">received</MenuItem>
                    <MenuItem value="not-received">not received</MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                    <MenuItem value="not-completed">not completed</MenuItem>
                    <MenuItem value="declined">declined</MenuItem>
                    <MenuItem value="not-declined">not declined</MenuItem>
                </Select>

                <Select
                    fullWidth
                    className={classnames({ 'assessment-select': true, 'is-none': chosenAssessment === 'none' })}
                    variant="outlined"
                    value={chosenAssessment}
                    onChange={(e) => setChosenAssessment(e.target.value)}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    <MenuItem value="none" disabled>
                        choose an assessment...
                    </MenuItem>
                    {_.map(sentAssessments, ({ id, title }) => {
                        return (
                            <MenuItem key={id} value={id}>
                                {title}
                            </MenuItem>
                        );
                    })}
                </Select>

                <Select
                    fullWidth
                    className="timeframe-select"
                    value={_.first(timeframe.split(':'))}
                    variant="outlined"
                    renderValue={(v) => {
                        if (v === 'custom') {
                            const { from, to } = parseCustomDateRange(timeframe);

                            /* eslint-disable */
                            return (
                                <a
                                    className="custom-date-link"
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCustomDateRangeOpen(true);
                                    }}
                                >
                                    <span className="label">from</span> {formatDate(from)}{' '}
                                    <span className="label">to</span> {formatDate(to)}
                                </a>
                            );
                            /* eslint-enable */
                        }

                        return TIMEFRAME_BY_VALUE[v].label;
                    }}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (value === 'custom') {
                            const nowStr = moment().format('YYYY-MM-DD');
                            setTimeframe(`custom:${nowStr}:${nowStr}`);
                            setCustomDateRangeOpen(true);
                        } else {
                            setTimeframe(value);
                        }
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {_.map(TIMEFRAME_OPTIONS, ({ value, label }) => {
                        return (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>

            {!customDateRangeOpen ? null : (
                <CustomDateRangeDialog
                    displayedDateFormat={displayedDateFormat}
                    setTimeframe={setTimeframe}
                    setCustomDateRangeOpen={setCustomDateRangeOpen}
                    {...parseCustomDateRange(timeframe)}
                />
            )}
        </div>
    );
}

function CustomDateRangeDialog({ from, to, displayedDateFormat, setTimeframe, setCustomDateRangeOpen }) {
    return (
        <Dialog
            className="custom-date-range-dialog"
            maxWidth="lg"
            open={true}
            onClose={() => setCustomDateRangeOpen(false)}
        >
            <DialogContent>
                <div className="custom-date-range-pickers">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            variant="static"
                            className="custom-start-date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span className="adornment-text">from</span>
                                    </InputAdornment>
                                ),
                            }}
                            format={displayedDateFormat}
                            value={moment(from, 'YYYY-MM-DD')}
                            disableFuture
                            onChange={(m) => {
                                setTimeframe(`custom:${m.format('YYYY-MM-DD')}:${to}`);
                            }}
                            maxDate={moment(to, 'YYYY-MM-DD')}
                        />

                        <div style={{ width: '20px' }} />

                        <DatePicker
                            variant="static"
                            className="custom-end-date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span className="adornment-text">to</span>
                                    </InputAdornment>
                                ),
                            }}
                            format={displayedDateFormat}
                            value={moment(to, 'YYYY-MM-DD')}
                            disableFuture
                            onChange={(m) => {
                                setTimeframe(`custom:${from}:${m.format('YYYY-MM-DD')}`);
                            }}
                            minDate={moment(from, 'YYYY-MM-DD')}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export function getNumberOfAssessmentFiltersToRender(queryParams) {
    if (!_.isString(queryParams.assessments)) {
        return 1;
    }
    return _.size(queryParams.assessments.split(','));
}

export function onAssessmentsClear(queryParams, assessmentIndex, history, location) {
    if (_.isString(queryParams.assessments)) {
        const pieces = queryParams.assessments.split(',');
        pieces.splice(assessmentIndex, 1);
        history.push(
            `${location.pathname}${updateQueryParameters(
                location,
                'assessments',
                _.isEmpty(pieces) ? null : pieces.join(',')
            )}`
        );
    }
}

export function getActiveLabel(queryParams, assessmentIndex, sentAssessments, activeLocationCountryCode) {
    const { interactionType, assessment, timeframe } = _.get(
        getFilterFromQueryParams(queryParams),
        `[${assessmentIndex}]`,
        {}
    );

    if (!_.isNil(interactionType)) {
        const title = _.get(
            _.find(sentAssessments, ({ id }) => id === assessment),
            'title'
        );

        const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({
            countryCode: activeLocationCountryCode,
        });

        const { from, to } = parseCustomDateRange(timeframe);
        const format = (d) => moment(d, 'YYYY-MM-DD').format(displayedDateFormat);

        const timeframeLabel = !timeframe.startsWith('custom:') ? (
            TIMEFRAME_BY_VALUE[_.first(timeframe.split(':'))].label
        ) : (
            <span>
                from <span className="custom-date">{format(from)}</span> to{' '}
                <span className="custom-date">{format(to)}</span>
            </span>
        );

        return (
            <div className="assessments-active-label">
                <div className="main-line">
                    <span className="interaction-type">{interactionType.replace('-', ' ')}</span>
                    &nbsp;{title}&nbsp;
                    {timeframe === 'any' ? null : <span className="timeframe">{timeframeLabel}</span>}
                </div>
            </div>
        );
    }
}

function parseCustomDateRange(timeframe) {
    if (!_.startsWith(timeframe, 'custom:')) {
        return {};
    }
    const [, from, to] = timeframe.split(':');
    return { from, to };
}

function getFilterFromQueryParams(queryParams) {
    if (_.isNil(queryParams.assessments)) {
        return [{}];
    }

    return _.map(queryParams.assessments.split(','), (rowString) => {
        const split = rowString.split('|');

        if (_.size(split) === 2 || _.size(split) === 3) {
            let timeframe = _.get(split, '[2]', 'any');

            return {
                interactionType: split[0],
                assessment: split[1],
                timeframe,
            };
        }

        return {};
    });
}
