import './Coronavirus.sass';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Grid, Button, Box, Typography, Select, MenuItem, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { pharmacyAction } from '../../../redux/Pharmacy/index';

import WaitlistTable from './WaitlistTable';
import { displayToast } from 'redux/actionCreators/Snackbar';
import config from '../../../config';
import { getLocationPackageAttribute, getPharmacyPackage } from 'utils/helper';
import AppointmentBlocksTable from './AppointmentBlocksTable';
import WarningIcon from '@material-ui/icons/Warning';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from '../Upgrades/FullPepUpgrade/FullPepUpgrade';

const C2_CONFIG = {
    packageCode: 'WEBS',
    waitlistAttribute: 'CovidWaitlistEnabled',
    schedulingAttribute: 'AppointmentSchedulingEnabled',
};

const Coronavirus = (props) => {
    const {
        displayToast,
        pharmacy,
        auth: { selectedLocation },
        updatePackageAttribute,
        createPackageAttribute,
        fullPepEnabled,
    } = props;

    const { pharmacy: currentPharmacy } = pharmacy;
    const waitlistFormDirectUrl = `${config.app_dp_url}/assessment-form/?configId=${currentPharmacy.id}`;
    const selectedPharmacyLocation = _.find(
        currentPharmacy.location,
        (location) => location.id === selectedLocation.id
    );

    const c2CovidWaitlistEnabled = _.get(selectedPharmacyLocation, 'service.Website.CovidWaitlistEnabled');
    const c2AppointmentSchedulingEnabled =
        _.get(selectedPharmacyLocation, 'service.Website.AppointmentSchedulingEnabled') && fullPepEnabled;

    const [updatingConfig, setUpdatingConfig] = useState(false);
    const [localConfig, setLocalConfig] = useState(
        c2AppointmentSchedulingEnabled ? 'scheduler' : c2CovidWaitlistEnabled ? 'waitlist' : 'off'
    );

    const [showingUpgrade, setShowingUpgrade] = useState(false);

    useEffect(() => {
        if (updatingConfig) {
            const pharmacyPackage = getPharmacyPackage(C2_CONFIG.packageCode, pharmacy.pharmacy);
            const waitlistAttr = getLocationPackageAttribute(
                C2_CONFIG.packageCode,
                selectedLocation.id,
                C2_CONFIG.waitlistAttribute,
                pharmacy.pharmacy
            );
            const schedulingAttr = getLocationPackageAttribute(
                C2_CONFIG.packageCode,
                selectedLocation.id,
                C2_CONFIG.schedulingAttribute,
                pharmacy.pharmacy
            );

            const createOrUpdatePackageAttribute = (pharmacyPackageId, attribute, enabled) => {
                if (_.isNil(attribute.id)) {
                    const clone = _.cloneDeep(attribute);
                    clone.value = enabled;
                    return createPackageAttribute(pharmacyPackageId, clone);
                } else {
                    return updatePackageAttribute(pharmacyPackageId, attribute.id, enabled);
                }
            };

            const promises = [];
            if (localConfig === 'scheduler') {
                if (!c2CovidWaitlistEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, waitlistAttr, true));
                }
                if (!c2AppointmentSchedulingEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, schedulingAttr, true));
                }
            } else if (localConfig === 'waitlist') {
                if (!c2CovidWaitlistEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, waitlistAttr, true));
                }
                if (c2AppointmentSchedulingEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, schedulingAttr, false));
                }
            } else {
                if (c2CovidWaitlistEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, waitlistAttr, false));
                }
                if (c2AppointmentSchedulingEnabled) {
                    promises.push(createOrUpdatePackageAttribute(pharmacyPackage.id, schedulingAttr, false));
                }
            }

            Promise.allSettled(promises).then((results) => {
                const hasError = !_.isEmpty(_.filter(results, ({ status }) => status === 'rejected'));

                if (!hasError) {
                    displayToast({ text: 'Update successful', type: 'success' });
                } else {
                    displayToast({ text: 'Update failed', type: 'error' });
                }

                setUpdatingConfig(false);
            });
        }
    }, [localConfig, updatingConfig]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(waitlistFormDirectUrl);
        displayToast({
            text: 'Copied to clipboard',
            type: 'info',
        });
    };

    return (
        <React.Fragment>
            <Grid container xs={12} className="grid-container coronavirus-settings">
                <Card
                    variant="outlined"
                    className="bordered-box settings-content--card"
                    style={{ maxWidth: '820px', width: '820px' }}
                >
                    <div className="covid-website-options-line">
                        <div className="text">
                            <Typography variant="body1">Coronavirus (COVID-19) Website Functionality</Typography>

                            <Typography variant="body2">
                                Specifies if you would like to have an Vaccination appointment scheduler, a vaccination
                                waitlist, or nothing embedded on your Digital Pharmacist website and at the URL below.
                            </Typography>
                        </div>

                        <Select
                            className="covid-website-option-select"
                            variant="outlined"
                            value={localConfig}
                            disabled={updatingConfig}
                            onChange={(e) => {
                                setLocalConfig(e.target.value);
                                setUpdatingConfig(true);
                            }}
                        >
                            <MenuItem value="off">None</MenuItem>
                            <MenuItem disabled={!fullPepEnabled} value="scheduler">
                                Vaccination Scheduler
                            </MenuItem>
                            <MenuItem value="waitlist">Vaccine Waitlist</MenuItem>
                        </Select>
                    </div>

                    {fullPepEnabled ? null : (
                        <div className="temporarily-disabled-message">
                            <WarningIcon />{' '}
                            <div>
                                <Button color="primary" onClick={() => setShowingUpgrade(true)}>
                                    Click here
                                </Button>{' '}
                                to upgrade to the Patient Engagement Platform to enable website scheduling.
                            </div>
                        </div>
                    )}

                    {localConfig === 'off' ? null : (
                        <div className="grid-container container-row" style={{ marginBottom: '30px' }}>
                            <Typography style={{ marginBottom: '8px', display: 'block' }}>
                                If you would like to share the URL for your {localConfig}, click copy:
                            </Typography>
                            <Box className="code-block">
                                {waitlistFormDirectUrl}
                                <Button
                                    style={{ marginLeft: '35px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={copyToClipboard}
                                >
                                    COPY
                                </Button>
                            </Box>
                        </div>
                    )}

                    {localConfig !== 'scheduler' ? null : (
                        <div className="grid-container container-row">
                            <AppointmentBlocksTable />
                        </div>
                    )}

                    <div className="grid-container container-row">
                        <WaitlistTable />
                    </div>
                </Card>
                {!updatingConfig ? null : (
                    <div className="updating-indicator">
                        <CircularProgress size={18} />
                        &nbsp;Updating...
                    </div>
                )}
            </Grid>

            {!showingUpgrade ? null : <FullPepUpgrade asDialog onClose={() => setShowingUpgrade(false)} />}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    pharmacy: state.pharmacy,
    auth: state.auth,
    fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
});

const bindActionsToDispatch = {
    createPackageAttribute: pharmacyAction.createPackageAttribute,
    updatePackageAttribute: pharmacyAction.updatePackageAttribute,
    displayToast: displayToast,
};

export default connect(mapStateToProps, bindActionsToDispatch)(Coronavirus);
