import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { SettingTitle } from '../Common/SettingTitle';
import Coronavirus from './Coronavirus';
import CoronavirusSupersededByAppts from './CoronavirusSupersededByAppts';

const CoronavirusWrapper = (props) => {
    const { npeCoronavirusWaitlist, npeMultipleAppointmentTypes } = props;
    if (!npeCoronavirusWaitlist) {
        return null;
    }

    return (
        <Grid className="settings-content--grid-container">
            <SettingTitle title="Coronavirus (COVID-19) Settings" />
            {npeMultipleAppointmentTypes ? <CoronavirusSupersededByAppts /> : <Coronavirus />}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        npeCoronavirusWaitlist: state.launchDarkly.npeCoronavirusWaitlist,
        npeMultipleAppointmentTypes: state.launchDarkly.npeMultipleAppointmentTypes,
    };
};

export default connect(mapStateToProps, null)(CoronavirusWrapper);
