import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snowplow from 'snowplow/Snowplow';

const ButtonWithTracking = (props) => {
    // For snowplow event description see sheet linked in comment on:
    // https://rxwiki.atlassian.net/browse/LIONS-991
    const { buttonText, snowplow, onClick, classes, ...rest } = props;

    const onButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Snowplow.structEvent('Button Click', snowplow.action, null, snowplow.label, snowplow.property);

        onClick();
    };

    return (
        <Button onClick={onButtonClick} {...rest}>
            {buttonText}
        </Button>
    );
};

ButtonWithTracking.propTypes = {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    snowplow: PropTypes.exact({
        label: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
    }).isRequired,
};

export default ButtonWithTracking;
