import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import config from '../../../config';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { templateAction } from 'redux/Inbox/Template';

const baseTemplateState = {
    subject: 'Picking Up Meds During Covid-19',
    /* eslint-disable */
    message:
        'Hi ${first_name}, your prescription is ready. In order to keep our staff and customers safe, we are now offering curbside. If you would rather not come inside the pharmacy, send us a message when you arrive and we will run the medication out to your vehicle.',
    /* eslint-enable */
    errors: {
        subject: null,
        message: null,
    },
};

function TemplateCreationModal(props) {
    const { showModal, onClose, template } = props;
    const [subject, updateSubject] = useState(baseTemplateState.subject);
    const [message, updateMessage] = useState(baseTemplateState.message);
    const [errors, updateErrors] = useState(baseTemplateState.errors);

    function getValidationErrors() {
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.subject = _.size(_.trim(subject)) ? null : emptyMessage;
        errors.message = _.size(_.trim(message)) ? null : emptyMessage;

        return _.omitBy(errors, _.isNil);
    }

    function addTemplate() {
        const { createTemplate } = props;
        const errors = getValidationErrors();

        if (!_.isEmpty(errors)) {
            updateErrors(errors);
        } else {
            const trimmedSubject = _.trim(subject);
            const trimmedMessage = _.trim(message);

            const payload = {
                pharmacy_id: config.X_PharmacyID,
                location_id: config.X_LocationID,
                subject: _.size(trimmedSubject) ? trimmedSubject : null,
                message: _.size(trimmedMessage) ? trimmedMessage : null,
            };

            // Validation passed
            createTemplate(payload).then((success) => {
                onClose({ success });
            });
        }
    }

    return (
        <DpDialog.Dialog id="templateCreationModal" maxWidth="md" open={showModal} onClose={onClose}>
            <DpDialog.Content>
                <Grid container>
                    <Hidden smDown>
                        <Grid container justify="center" md={5}>
                            <img
                                width={236}
                                height={314}
                                src={`/inbox/onboarding-template.png`}
                                srcSet={`/inbox/onboarding-template.png 1x, /inbox/onboarding-template_2x.png 2x, /inbox/onboarding-template_3x.png 3x`}
                                alt="Add Template"
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={7}>
                        <DpDialog.ContentHeader heading={'Create your first template'} onClose={onClose}>
                            Templates make it easy for you and your team to send a lot of messages quickly. Feel free to
                            edit our example below.
                        </DpDialog.ContentHeader>
                        <Grid container direction="column">
                            <TextField
                                required
                                id="subject"
                                type="text"
                                label="Subject"
                                name="subject"
                                variant="outlined"
                                margin="normal"
                                style={{ marginTop: 0 }}
                                value={subject}
                                onChange={({ target }) => {
                                    updateSubject(target.value);
                                }}
                                error={!!errors.subject}
                                helperText={errors.subject}
                            />
                            <TextField
                                required
                                id="message"
                                type="text"
                                label="Message"
                                name="message"
                                multiline
                                rows="6"
                                value={message}
                                variant="outlined"
                                margin="normal"
                                onChange={({ target }) => {
                                    updateMessage(target.value);
                                }}
                                error={!!errors.message}
                                helperText={errors.message}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DpDialog.Content>
            <DpDialog.Actions>
                <Button
                    onClick={addTemplate}
                    disabled={template.sending}
                    color="primary"
                    variant="contained"
                    style={{ minWidth: '136px' }}
                    disableElevation
                >
                    {template.sending ? <CircularProgress size={20} /> : 'Save Template'}
                </Button>
            </DpDialog.Actions>
        </DpDialog.Dialog>
    );
}

function mapStateToProps(state) {
    const { inboxConversationTemplate } = state;
    return {
        template: inboxConversationTemplate,
    };
}

const bindActionsToDispatch = {
    createTemplate: templateAction.createTemplate,
};

export default connect(mapStateToProps, bindActionsToDispatch)(TemplateCreationModal);
