import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

const FormRowCheckboxes = ({ field1, field2, handleCheckboxValueChange }) => (
    <React.Fragment>
        <Grid item xs={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!field1.value}
                        onChange={handleCheckboxValueChange}
                        name={field1.key}
                        color="primary"
                        disabled={field1.disabled}
                    />
                }
                label={field1.label}
                key={field1.key}
            />
        </Grid>

        <Grid item xs={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!field2.value}
                        onChange={handleCheckboxValueChange}
                        name={field2.key}
                        color="primary"
                        disabled={field2.disabled}
                    />
                }
                label={field2.label}
                key={field2.key}
            />
        </Grid>
    </React.Fragment>
);

export default FormRowCheckboxes;
