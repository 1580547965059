import apptReservationDataTypes from './type';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    creatingNewReservation: null,
    updatingReservation: null,
    creatingNewReservationSession: null,
    updatingReservationSession: null,
    availableAppointmentSlots: null,
    waitlistPatientsByCount: null,
    checkingInReservation: null,
    liveReusableSessions: null,
};

export function apptReservationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case apptReservationDataTypes.CREATE_RESERVATION_REQUEST:
        case apptReservationDataTypes.CREATE_RESERVATION_SUCCESS:
        case apptReservationDataTypes.CREATE_RESERVATION_FAILURE:
            return {
                ...state,
                creatingNewReservation: {
                    loading: type === apptReservationDataTypes.CHECKIN_RESERVATION_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.UPDATE_RESERVATION_REQUEST:
        case apptReservationDataTypes.UPDATE_RESERVATION_SUCCESS:
        case apptReservationDataTypes.UPDATE_RESERVATION_FAILURE:
            return {
                ...state,
                updatingReservation: {
                    loading: type === apptReservationDataTypes.UPDATE_RESERVATION_REQUEST,
                    ...payload,
                },
                reservationsForInboxUserId: getUpdatedReservationsForInboxUserId(payload.response, state),
            };

        case apptReservationDataTypes.CHECKIN_RESERVATION_REQUEST:
        case apptReservationDataTypes.CHECKIN_RESERVATION_SUCCESS:
        case apptReservationDataTypes.CHECKIN_RESERVATION_FAILURE:
            return {
                ...state,
                checkingInReservation: {
                    loading: type === apptReservationDataTypes.CHECKIN_RESERVATION_REQUEST,
                    ...payload,
                },
                reservationsForInboxUserId: getUpdatedReservationsForInboxUserId(payload.response, state),
            };

        case apptReservationDataTypes.CREATE_RESERVATION_SESSION_REQUEST:
        case apptReservationDataTypes.CREATE_RESERVATION_SESSION_SUCCESS:
        case apptReservationDataTypes.CREATE_RESERVATION_SESSION_FAILURE:
            return {
                ...state,
                creatingNewReservationSession: {
                    loading: type === apptReservationDataTypes.CREATE_RESERVATION_SESSION_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.UPDATE_RESERVATION_SESSION_REQUEST:
        case apptReservationDataTypes.UPDATE_RESERVATION_SESSION_SUCCESS:
        case apptReservationDataTypes.UPDATE_RESERVATION_SESSION_FAILURE:
            return {
                ...state,
                updatingReservationSession: {
                    loading: type === apptReservationDataTypes.UPDATE_RESERVATION_SESSION_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_REQUEST:
        case apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_SUCCESS:
        case apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_FAILURE:
            return {
                ...state,
                availableAppointmentSlots: {
                    loading: type === apptReservationDataTypes.GET_AVAILABLE_APPOINTMENT_SLOTS_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_REQUEST:
        case apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_SUCCESS:
        case apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_FAILURE:
            return {
                ...state,
                waitlistPatientsByCount: {
                    loading: type === apptReservationDataTypes.CHOOSE_WAITLIST_PATIENTS_BY_COUNT_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_REQUEST:
        case apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_SUCCESS:
        case apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_FAILURE:
            return {
                ...state,
                liveReusableSessions: {
                    loading: type === apptReservationDataTypes.GET_LIVE_REUSABLE_RESERVATION_SESSIONS_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_REQUEST:
        case apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_SUCCESS:
        case apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_FAILURE:
            return {
                ...state,
                reservationsForInboxUserId: {
                    loading: type === apptReservationDataTypes.GET_RESERVATIONS_FOR_INBOX_USER_ID_REQUEST,
                    ...payload,
                },
            };

        case apptReservationDataTypes.RESET_RESERVATIONS:
            return {
                ...state,
                ...initialState,
            };

        case apptReservationDataTypes.UPDATE_LIVE_REUSABLE_RESERVATION_SESSIONS_FROM_RESPONSE:
            return {
                ...state,
                liveReusableSessions: getUpdatedLiveReusableSessionsForResponse(payload.response, state),
            };

        default:
            return state;
    }
}

/*
    Updates the state of calendarReservations by replacing it within the reservations list with the passed response from
    any of the update calls to the reservation backend
 */
function getUpdatedReservationsForInboxUserId(response, state) {
    const reservationsForInboxUserId = _.cloneDeep(state.reservationsForInboxUserId);

    if (!_.isNil(response)) {
        const reservations = _.get(reservationsForInboxUserId, `response.reservations`);
        const index = _.findIndex(reservations, ({ reservation_id }) => reservation_id === response.reservation_id);

        if (_.isFinite(index) && index >= 0) {
            reservations.splice(index, 1, response);
        }
    }

    return reservationsForInboxUserId;
}

function getUpdatedLiveReusableSessionsForResponse(response, state) {
    const clone = _.cloneDeep(_.get(state, 'liveReusableSessions'));
    const currentSessions = _.get(clone, 'response.live_sessions');

    if (!_.isNil(response) && !_.isNil(currentSessions)) {
        // check if this session is still active
        if (_.isNil(response.end_date) || response.end_date >= moment().format('YYYY-MM-DD')) {
            const index = _.findIndex(currentSessions, ({ session_id }) => session_id === response.session_id);

            if (_.isFinite(index) && index >= 0) {
                if (response.status === 'DELETED') {
                    currentSessions.splice(index, 1);
                } else {
                    currentSessions.splice(index, 1, response);
                }
            } else {
                currentSessions.push(response);
            }
        }
    }

    return clone;
}
