const subjectTypePrefix = 'Communications Platform(IVR)';

export const restrictedIVRResources = {
    ivr: {
        subject: subjectTypePrefix,
        // Note, these are the top level fields
        fields: {
            resetIVR: 'Reset IVR',
            ivrPhoneNumber: 'IVR Phone Number',
        },
        generalGreeting: {
            subject: `${subjectTypePrefix} - General Greeting`,
            fields: {},
        },
        generalMenuOptions: {
            subject: `${subjectTypePrefix} - General Menu Options`,
            fields: {},
        },
        languagesAndVoices: {
            subject: `${subjectTypePrefix} - Languages And Voices`,
            fields: {
                languages: 'Languages',
                ttsEngine: 'TTS Engine',
            },
        },
        pharmacyGreeting: {
            subject: `${subjectTypePrefix} - Pharmacy Greeting`,
            fields: {},
        },
        pharmacyHours: {
            subject: `${subjectTypePrefix} - Pharmacy Hours`,
            fields: {},
        },
        callTransferDuringHours: {
            subject: `${subjectTypePrefix} - Call Transfer During Hours`,
            fields: {},
        },
        callTransferAfterHours: {
            subject: `${subjectTypePrefix} - Call Transfer After Hours`,
            fields: {},
        },
        pharmacyMenuOptions: {
            subject: `${subjectTypePrefix} - Pharmacy Menu Options`,
            fields: {},
        },
        prescriptionRefillFulfillment: {
            subject: `${subjectTypePrefix} - Prescription Refill Fulfillment`,
            fields: {
                fulfillmentOptions: 'Fulfillment Options',
                patientDobValidation: 'Patient DOB Validation',
                voiceMsgsAttachedRx: 'Voice Message Attached Rx',
            },
        },
        prescriberVoicemail: {
            subject: `${subjectTypePrefix} - Prescriber Voicemail`,
            fields: {},
        },
        generalVoiceMail: {
            subject: `${subjectTypePrefix} - General Voicemail`,
            fields: {},
        },
        newPatientVoicemail: {
            subject: `${subjectTypePrefix} - New Patient Voicemail`,
            fields: {},
        },
    },
};
