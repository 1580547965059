import SettingsReducer from 'redux/reducers/Settings';
import SnackbarReducer from 'redux/reducers/Snackbar';
import AuthReducer from 'redux/reducers/Auth';
import VoicemailReducer from 'redux/reducers/Voicemail';
import ReportsReducer from 'redux/reducers/Reports';
import { onboardingReducer as inboxOnboardingReducer } from 'redux/Inbox/Onboarding';
import { userReducer } from 'redux/Inbox/User';
import { templateReducer } from 'redux/Inbox/Template';
import { conversationReducer } from 'redux/Inbox/Conversation';
import { messageReducer } from 'redux/Inbox/Message';
import { pharmacyReducer } from 'redux/Pharmacy';
import { socketReducer } from 'redux/Socket';
import TigerPistolReducer from 'redux/reducers/TigerPistol';
import CustomLoaderReducer from 'redux/reducers/CusomLoader';
import GeneralSettingsReducer from 'redux/reducers/Settings/GeneralSettings';
import RefillsReducer from 'redux/reducers/Refills';
import LeadsReducer from 'redux/reducers/Leads';
import ClassesReducer from 'redux/reducers/Classes';
import { reviewReducer } from 'redux/Review';
import { pharmacyContractReducer } from 'redux/PharmacyContract';
import { userManagementReducer } from 'redux/UserManagement';
import { statusPageReducer } from 'redux/StatusPage';
import { newsletterReducer } from 'redux/Newsletter';
import ldRedux from 'ld-redux';
import { patientDataReducer } from 'redux/Patient/PatientData/reducer';
import { connectReducer } from 'redux/Patient/Connect/reducer';
import { assessmentReducer } from 'redux/Patient/Assessment/reducer';
import { pharmacyAppsReducer } from 'redux/PharmacyApps/reducer';
import { homeReducer } from 'redux/Home';
import { waitlistReducer } from 'redux/Waitlist';
import { apptCalendarReducer } from 'redux/Appt/Calendar/reducer';
import { apptReservationReducer } from 'redux/Appt/Reservation/reducer';
import { apptTypeReducer } from 'redux/Appt/ApptType/reducer';
import { bannerConfigReducer } from 'redux/BannerConfiguration/banner-config-reducer';

export default {
    settings: SettingsReducer,
    snackbar: SnackbarReducer,
    auth: AuthReducer,
    voicemails: VoicemailReducer,
    reports: ReportsReducer,
    inboxUser: userReducer,
    inboxConversation: conversationReducer,
    inboxMessage: messageReducer,
    inboxOnboarding: inboxOnboardingReducer,
    pharmacy: pharmacyReducer,
    socket: socketReducer,
    tigerPistol: TigerPistolReducer,
    customLoader: CustomLoaderReducer,
    generalSettings: GeneralSettingsReducer,
    refills: RefillsReducer,
    inboxConversationTemplate: templateReducer,
    leads: LeadsReducer,
    classes: ClassesReducer,
    review: reviewReducer,
    pharmacyContract: pharmacyContractReducer,
    userManagement: userManagementReducer,
    statusPage: statusPageReducer,
    newsletter: newsletterReducer,
    bannerConfig: bannerConfigReducer,
    launchDarkly: ldRedux.reducer(),
    patientData: patientDataReducer,
    connect: connectReducer,
    assessment: assessmentReducer,
    pharmacyApps: pharmacyAppsReducer,
    home: homeReducer,
    waitlist: waitlistReducer,
    apptCalendar: apptCalendarReducer,
    apptReservation: apptReservationReducer,
    apptType: apptTypeReducer,
};
