import _ from 'lodash';
import axios from 'axios';

import Actions from 'redux/actions';
import { post, get, put } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { fetchMainGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logError, logHttpError } from '../../utils/log';
import localStorageService from 'utils/localStorageService';
import Snowplow, { GlobalSiteContext, SnowplowConstants } from '../../snowplow';
import { snowplowEventLabel } from 'constants/Logout';
import { isMobileOnly } from 'react-device-detect';
import { ElectronContext, NPEUserContext } from 'snowplow/contexts';
import { socketAction } from 'redux/Socket/action';
import { userRoles } from 'casl';
import { NO_LOCATION_ACCESS } from 'constants/Common';

const MAX_LOCATIONS = 200;
const BASE_HEADERS = {
    headers: {
        'X-Pharmacy-Id': '123', //dummy val for IE fix
    },
};
const CancelToken = axios.CancelToken;
let cancelPharmacySearch;

// TODO this should probably live in IVR-specific redux store
export const fetchIvrPhone = (data) => (dispatch) => {
    const ivrPhoneApiPath = API_PATH.IVR_PHONE.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', data.id);
    const apiUrl = Config.config2_url + ivrPhoneApiPath;
    const ivrPhoneReq = get(apiUrl);
    return ivrPhoneReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_IVR_PHONE_SUCCESS, payload: { id: Config.X_LocationID, data: resp } });
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occurred on fetchIvrPhone', err);
            dispatch({ type: Actions.UPDATE_IVR_PHONE_FAILED, payload: err });
            return { err };
        });
};

export const startIvrPhoneUpdate = () => ({ type: Actions.UPDATE_IVR_PHONE_STARTED });

// TODO this should probably live in IVR-specific redux store
export const updateIvrPhone = (data) => (dispatch) => {
    const ivrPhoneApiPath = API_PATH.IVR_PHONE.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', data.ivrId);
    const apiUrl = Config.config2_url + ivrPhoneApiPath;

    const ivrPhoneReq = post(apiUrl, data.ivrPhoneDetails);

    return ivrPhoneReq
        .then((resp) => {
            dispatch({ type: Actions.UPDATE_IVR_PHONE_SUCCESS });
            dispatch(fetchIvrPhone({ id: data.ivrId }));
            dispatch(displayToast({ text: 'IVR phone number saved', type: 'success' }));
            return { data: resp };
        })
        .catch((err) => {
            logHttpError('An error occurred on updateIvrPhone', err);
            dispatch({ type: Actions.UPDATE_IVR_PHONE_FAILED, payload: err });
            dispatch(displayToast({ text: 'Failed to save IVR phone number', type: 'error' }));
            return { err };
        });
};

// TODO this should probably live somewhere else
const initIntercom = (appcode, pharmacyName, email) => {
    let intercomSettings = {
        app_id: Config.intercom_app_id,
        custom_launcher_selector: '#intercom_custom_link',
    };

    if (isMobileOnly) {
        intercomSettings = {
            ...intercomSettings,
            hide_default_launcher: true,
        };
    }

    window.intercomSettings = {
        ...intercomSettings,
    };

    window.Intercom('boot', {
        app_id: Config.intercom_app_id,
        name: email,
        email,
        appcode,
        pharm_name: pharmacyName,
        pharm_uuid_for_config2: Config.X_PharmacyID,
    });
};

// TODO this should probably live in IVR-specific redux store
export const fetchIvrDetails = () => (dispatch) => {
    dispatch({ type: Actions.FETCH_IVR_PHONE_STARTED });

    dispatch(fetchMainGreeting()).then((resp) => {
        if (resp.err) {
            dispatch({ type: Actions.FETCH_IVR_PHONE_FAILED, payload: resp.err });
        } else {
            dispatch(fetchIvrPhone(resp));
        }
    });
};

export const changePassword = (detail) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.CHANGE_PASSWORD}`;
    dispatch({ type: Actions.UPDATE_NEWPWD_STARTED });
    const updatePwdReq = post(apiUrl, detail);
    return updatePwdReq
        .then((data) => {
            if (data.success) {
                dispatch(displayToast({ text: 'Password reset successful, login with new password', type: 'success' }));
                dispatch({ type: Actions.UPDATE_NEWPWD_SUCCESS, payload: data });
                return { data };
            } else {
                const err = data;
                dispatch({ type: Actions.UPDATE_NEWPWD_FAILED, payload: err });
                return { err };
            }
        })
        .catch((err) => {
            dispatch({ type: Actions.UPDATE_NEWPWD_FAILED, payload: err });
            return { err };
        });
};

export const updatePassword = (detail) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.UPDATE_PASSWORD}`;
    dispatch({ type: Actions.UPDATE_NEWPWD_STARTED });
    const updatePwdReq = post(apiUrl, detail);
    return updatePwdReq
        .then((data) => {
            if (data.success) {
                dispatch({ type: Actions.UPDATE_NEWPWD_SUCCESS, payload: data });
                return { data };
            } else {
                const err = data;
                dispatch({ type: Actions.UPDATE_NEWPWD_FAILED, payload: err });
                return { err };
            }
        })
        .catch((err) => {
            logHttpError('An error occurred on updatePasswordReq', err);
            dispatch({ type: Actions.UPDATE_NEWPWD_FAILED, payload: err });
            return { err };
        });
};

export const fetchLocationDetails = () => (dispatch) => {
    let apiUrl = `${Config.config2_url}${API_PATH.LOCATION_DETAILS}`;
    apiUrl = apiUrl.replace('{custID}', Config.X_PharmacyID).replace('{locID}', Config.X_LocationID);
    dispatch({ type: Actions.FETCH_LOCATION_DETAIL_STARTED });
    const fetchLocDetailReq = get(apiUrl);

    return fetchLocDetailReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_LOCATION_DETAIL_SUCCESS, payload: { data } });
            return { data };
        })
        .catch((err) => {
            logHttpError(`An error occurred on fetchLocationDetails`, err);
            dispatch({ type: Actions.FETCH_LOCATION_DETAIL_FAILED, payload: err });
            return { err };
        });
};

export const sendResetLink = (detail) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.FORGOT_PASSWORD}`;
    dispatch({ type: Actions.UPDATE_NEWPWD_STARTED });
    const updatePwdReq = post(apiUrl, detail);
    return updatePwdReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_NEWPWD_SUCCESS, payload: { data, email: detail.email } });
            return { data };
        })
        .catch((err) => {
            logHttpError(`An error occurred on sendResetLink for username: ${detail.email}`, err);
            dispatch({ type: Actions.UPDATE_NEWPWD_FAILED, payload: err });
            return { err };
        });
};

export const updateErr = (data) => ({
    type: Actions.SET_LOGIN_ERR,
    payload: data,
});

export const updateCustomer = (payload) => {
    return {
        type: Actions.UPDATE_CUSTOMER,
        payload,
    };
};

export const redirectToVow = () => async (dispatch, getState) => {
    const time = new Date().getTime();
    const apiUrl = `${Config.apiweb_url}/account/sso/transfer?t=${time}`;

    try {
        const response = await axios
            .get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.data);
        const redirectUrl = response.url;
        const newWindow = window.open(redirectUrl, '_blank');
        try {
            newWindow.focus();
        } catch (e) {
            // open dialog to let the user know the browser blocked opening the tab
            dispatch({ type: Actions.SET_VOW_REDIRECT_ERROR_LOCATION, payload: redirectUrl });
        }
    } catch (err) {
        console.log(err);
        dispatch(displayToast({ text: 'Unable to open IVR', type: 'error' }));
        logHttpError('VowRedirectFailure', err);
    }
};

export const updateWithExternalCustomer = (payload) => (dispatch, getState) => {
    const { id, name } = payload;

    // make a call to get all the locations for the customer
    const apiUrl = Config.config2_url + '/cust/' + id + '/location?numRows=' + MAX_LOCATIONS;
    const locations = get(apiUrl);
    return locations
        .then((resp) => {
            const allLocations = resp.result;
            const appCode = getState().auth.foundAppCode;
            // if the call returns (implying that it was a valid id), load customer and customer's locations into state
            // by creating an array of objects that includes the customer and all the customer locations
            const appCodeCustomersAndLocations = createAppCodeCustomersAndLocations(id, name, allLocations, appCode);

            dispatch({ type: Actions.FETCH_PHARMACY_LOCATIONS_SUCCESS, payload: appCodeCustomersAndLocations });

            // find the customer
            const customer = appCodeCustomersAndLocations.find((pharmOrLocation) => pharmOrLocation.entityId === id);
            const custLocation = customer
                ? _.find(appCodeCustomersAndLocations, { parentId: customer.entityId, entityType: 'Location' })
                : _.find(appCodeCustomersAndLocations, { entityType: 'Location' });
            updateCustomerAndLocation(customer, custLocation, dispatch);

            return { resp };
        })
        .catch((err) => {
            logHttpError(`An error occurred on updateWithExternalCustomer for custId: ${id}`, err);
            dispatch({ type: Actions.FETCH_PHARMACY_LOCATIONS_FAILED, payload: err });
            return { err };
        });
};

const createAppCodeCustomersAndLocations = (id, name, allLocations, appCode) => {
    const templateForAllNewPharmaciesAndLocations = {
        role: 'Owner', // internal user, so labeling them an owner gives them broad access
        perm: [
            // all the permissions
            'GET',
            'PUT',
            'DELETE',
            'POST',
        ],
        inherited: false, // is this always going to be correct?
    };

    // create the customer
    const customerDetail = {
        entityId: id,
        entityName: name,
        entityType: 'Customer',
        ...templateForAllNewPharmaciesAndLocations,
        appCode,
    };

    // create the locations
    const formattedLocations = allLocations.map((location) => {
        return {
            entityId: location.id,
            entityName: location.name,
            entityType: 'Location',
            parentId: id,
            parentName: name,
            ...templateForAllNewPharmaciesAndLocations,
        };
    });

    return [customerDetail, ...formattedLocations];
};

export const fetchUserDetail = () => async (dispatch, getState) => {
    const apiUrl = Config.config2_url + API_PATH.MEMBERS;
    dispatch({ type: Actions.FETCH_USER_DETAIL_STARTED });
    const userMemberReq = get(apiUrl);

    const preferences = await dispatch(fetchUserPreferences());

    return userMemberReq
        .then((resp) => {
            const filterUsers = _.reject(
                resp,
                (userDetail) => userDetail.role === userRoles.noAccess || userDetail.entityName === ''
            );

            // if user has 0 location access then show error message
            if (_.filter(filterUsers, (e) => e.entityType === 'Location').length === 0) {
                dispatch({ type: Actions.FETCH_USER_DETAIL_SUCCESS, payload: [] });
                return {
                    err: {
                        response: NO_LOCATION_ACCESS,
                    },
                };
            }

            const {
                auth: {
                    userAccount: { email, user_id, is_internal_user },
                },
            } = getState();
            const lastVisitedLocation = localStorageService.getLastVisitedLocation(user_id);
            let customer;
            let custLocation;

            window.USER_EMAIL = email;

            // check for fav location in preferences
            if (preferences.default_location_id) {
                custLocation = _.find(filterUsers, ['entityId', preferences.default_location_id]);
                if (!_.isUndefined(custLocation)) {
                    customer = { entityId: custLocation.parentId, entityName: custLocation.parentName };
                }
            }

            // check for last visited location in the localstorage
            if ((!customer || !custLocation) && lastVisitedLocation) {
                custLocation = _.find(filterUsers, ['entityId', lastVisitedLocation.X_LocationID]);
                if (!_.isUndefined(custLocation)) {
                    customer = { entityId: custLocation.parentId, entityName: custLocation.parentName };
                }
            }

            if (!customer || !custLocation) {
                customer = _.find(filterUsers, ['entityType', 'Customer']);
                custLocation = customer
                    ? _.find(filterUsers, { parentId: customer.entityId, entityType: 'Location' })
                    : _.find(filterUsers, { entityType: 'Location' });
            }

            const pharmacyName = custLocation.parentName;

            updateCustomerAndLocation(customer, custLocation, dispatch);

            localStorageService.setLastVisitedLocation(user_id, {
                X_PharmacyID: Config.X_PharmacyID,
                X_LocationID: Config.X_LocationID,
            });

            dispatch({ type: Actions.FETCH_USER_DETAIL_SUCCESS, payload: filterUsers });

            const appcode = customer ? customer.appCode : '';

            // Start intercom with default pharmacy data
            initIntercom(appcode, pharmacyName, email);

            const globalContexts = [
                function () {
                    return new GlobalSiteContext().build();
                },
                function () {
                    const userType = is_internal_user ? 'Internal' : 'Pharmacist';
                    return new NPEUserContext(user_id, userType).build();
                },
            ];
            if (_.isString(window.ELECTRON_APP_VERSION)) {
                globalContexts.push(function () {
                    return new ElectronContext().setElectronAppVersion(window.ELECTRON_APP_VERSION).build();
                });
            }

            // set global mutable snowplow data
            Snowplow.clearGlobalContexts();
            Snowplow.setUserId(user_id);
            Snowplow.addGlobalContexts(globalContexts);

            return { data: filterUsers };
        })
        .catch((err) => {
            logError('An error occurred on userMemberReq', err);
            dispatch({ type: Actions.FETCH_USER_DETAIL_FAILED, payload: err });
            return { err };
        });
};

const updateCustomerAndLocation = (customer, custLocation, dispatch) => {
    Config.X_PharmacyID = customer ? customer.entityId : custLocation.parentId;
    Config.X_LocationID = custLocation.entityId;
    customer
        ? dispatch(updateCustomer({ id: customer.entityId, perms: customer.perm }))
        : dispatch(updateCustomer({ id: custLocation.parentId }));

    dispatch(updateLocation({ id: custLocation.entityId, name: custLocation.entityName, perms: custLocation.perm }));
    dispatch(fetchLocationDetails());
};

export const updateLocation = (payload) => (dispatch, getState) => {
    const userAccount = getState().auth.userAccount;
    if (window.ldClient) {
        window.ldClient.identify(
            {
                key: userAccount.user_id,
                custom: {
                    pharm_uuid: Config.X_PharmacyID,
                    loc_uuid: Config.X_LocationID,
                },
            },
            userAccount.launch_darkly_user_key_hash
        );
    }

    dispatch({
        type: Actions.UPDATE_LOCATION,
        payload,
    });
};

export const getPharmacies = (searchValue, startRow = 0) => (dispatch) => {
    const queryParamsMap = {
        startRow: startRow,
        numRows: 15,
        useOrFilter: 1,
        includeAppCode: 1,
        name: searchValue,
        sortKey: 'name',
        sortOrder: 'a',
    };

    if (/^\d+$/.test(searchValue)) {
        queryParamsMap.appCodeLike = searchValue;
    }

    const queryString = _.map(queryParamsMap, (value, key) => {
        return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
    const apiUrl = `${Config.config2_url}/cust?${queryString}`;

    // Cancel any previous search requests
    if (cancelPharmacySearch) {
        cancelPharmacySearch();
    }

    const pharmacies = get(apiUrl, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelPharmacySearch = c;
        }),
    });

    dispatch({ type: Actions.FETCH_PHARMACIES_STARTED });

    return pharmacies
        .then((resp) => {
            dispatch({
                type: Actions.FETCH_PHARMACIES_SUCCESS,
                payload: { possible_pharmacies: resp.result, total_count: resp.totalCount, load_more: startRow > 0 },
            });
        })
        .catch((err) => {
            logHttpError(`An error occurred on getPharmacies for searchValue: ${searchValue}`, err);
            dispatch({ type: Actions.FETCH_PHARMACIES_FAILED, payload: err });
        });
};

export const clearPharmacies = () => (dispatch) => {
    // Cancel any pending search requests
    if (cancelPharmacySearch) {
        cancelPharmacySearch();
    }
    dispatch({ type: Actions.CLEAR_PHARMACIES });
};

export const getPharmacyByAppcode = (searchValue) => (dispatch) => {
    const apiUrl =
        Config.config2_url +
        '/cust?startRow=0&numRows=10&useOrFilter=1' +
        '&appCodeLike=' +
        searchValue +
        '&sortKey=name&sortOrder=a';

    dispatch({ type: Actions.FETCH_PHARMACY_STARTED });
    const pharmacy = get(apiUrl);
    return pharmacy
        .then((resp) => {
            dispatch({
                type: Actions.FETCH_PHARMACY_SUCCESS,
                payload: { foundPharmacies: resp.result, foundAppCode: searchValue },
            });
        })
        .catch((err) => {
            logHttpError(`An error occurred on getPharmacyByAppcode for appcode: ${searchValue}`, err);
            dispatch({ type: Actions.FETCH_PHARMACY_FAILED, payload: err });
        });
};

export const fetchUserAccount = () => (dispatch) => {
    dispatch({ type: Actions.FETCH_USER_ACCOUNT_STARTED });

    const apiUrl = `${Config.apiweb_url}${API_PATH.USER_ACCOUNT}`;
    const userAccountReq = get(apiUrl, BASE_HEADERS);
    return userAccountReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_USER_ACCOUNT_SUCCESS, payload: data });
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_USER_ACCOUNT_FAILED, payload: err });
        });
};

export const fetchUserPreferences = () => (dispatch) => {
    dispatch({ type: Actions.FETCH_USER_PREFERENCES_STARTED });

    const apiUrl = `${Config.config2_url}${API_PATH.USER_PREFERENCES}`;
    const userPreferencesReq = get(apiUrl, BASE_HEADERS);
    return userPreferencesReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_USER_PREFERENCES_SUCCESS, payload: data });
            return {
                ...data,
            };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_USER_PREFERENCES_FAILED, payload: err });
        });
};

export const addUserPreferences = (data, showToast = true) => (dispatch) => {
    dispatch({ type: Actions.ADD_USER_PREFERENCES_STARTED });

    const apiUrl = `${Config.config2_url}${API_PATH.USER_PREFERENCES}`;
    const addUserPreferencesReq = post(apiUrl, data);
    return addUserPreferencesReq
        .then((data) => {
            dispatch({ type: Actions.ADD_USER_PREFERENCES_SUCCESS, payload: data });
            dispatch(fetchUserPreferences());
            if (showToast) {
                dispatch(displayToast({ text: 'Default location added successfully', type: 'success' }));
            }
            return {
                success: true,
            };
        })
        .catch((err) => {
            dispatch({ type: Actions.ADD_USER_PREFERENCES_FAILED, payload: err });
            if (showToast) {
                dispatch(displayToast({ text: 'Error while adding default location', type: 'error' }));
            }
            return {
                success: false,
            };
        });
};

export const updateUserPreferences = (data, showToast = true) => (dispatch) => {
    dispatch({ type: Actions.UPDATE_USER_PREFERENCES_STARTED });

    const apiUrl = `${Config.config2_url}${API_PATH.USER_PREFERENCES}`;
    const updateUserPreferencesReq = put(apiUrl, data);
    return updateUserPreferencesReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_USER_PREFERENCES_SUCCESS, payload: data });
            dispatch(fetchUserPreferences());
            if (showToast) {
                dispatch(displayToast({ text: 'Default location updated successfully', type: 'success' }));
            }
            return {
                success: true,
            };
        })
        .catch((err) => {
            dispatch({ type: Actions.UPDATE_USER_PREFERENCES_FAILED, payload: err });
            if (showToast) {
                dispatch(displayToast({ text: 'Error while updating default location', type: 'error' }));
            }
            return {
                success: false,
            };
        });
};

// TODO: Move account related information to actionCreators/Account.js
export const updateUserAccount = (data) => (dispatch) => {
    dispatch({ type: Actions.UPDATE_USER_ACCOUNT_STARTED });

    const apiUrl = `${Config.apiweb_url}${API_PATH.USER_ACCOUNT_UPDATE}`;
    const userAccountUpdateReq = put(apiUrl, data, BASE_HEADERS);
    return userAccountUpdateReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_USER_ACCOUNT_SUCCESS, payload: data });
            dispatch(fetchUserAccount());
            dispatch(authorizeAccount());
        })
        .catch((err) => {
            dispatch({ type: Actions.UPDATE_USER_ACCOUNT_FAILED, payload: err });
        });
};

const authorizeAccount = () => (dispatch) => {
    // Get user role details
    return dispatch(fetchUserDetail())
        .then((resp) => {
            if (resp.data) {
                // Do some IVR stuff
                // TODO this maybe should be triggered by middleware - is IVR store managed separately?
                // It might rely on data set by this function but
                // shouldn't be inside this unless it directly relates to authorization
                dispatch(fetchIvrDetails());

                // Finished authorization check - set isAuthorized to true
                dispatch({
                    type: Actions.UPDATE_AUTH_STATUS,
                    payload: {
                        isAuthorized: true,
                        err: '',
                    },
                });
            } else if (resp.err) {
                const error = resp.err;
                let err = error.response && error.response.status === 403 ? '' : {};
                // Failed authorization due to data error - set isAuthorized to false
                // TODO do we need specific message for this error state?
                // TODO user has authenticated but authorization failed
                if (error.response === NO_LOCATION_ACCESS) {
                    err = {
                        message: error.response,
                    };
                }
                dispatch({
                    type: Actions.UPDATE_AUTH_STATUS,
                    payload: {
                        isAuthorized: false,
                        err,
                    },
                });
            }
        })
        .catch((err) => {
            // TODO do we need specific message for this error state?
            // TODO user has authenticated but authorization request failed
            dispatch({
                type: Actions.UPDATE_AUTH_STATUS,
                payload: {
                    isAuthorized: false,
                    err: '',
                },
            });
        });
};

export const authenticateAccount = () => (dispatch, getState) => {
    // Fetch user account
    return dispatch(fetchUserAccount()).then(() => {
        const { auth } = getState();

        // If user account data is returned then backend session exists
        if (auth.userAccount) {
            return dispatch(authorizeAccount());
        } else {
            const lastLocationVisited = sessionStorage.getItem('lastLocationVisited');
            const lastPharmacyVisited = sessionStorage.getItem('lastPharmacyVisited');
            if (lastPharmacyVisited && lastLocationVisited) {
                dispatch(socketAction.initializeSocket());
                dispatch(updateCustomer({ id: lastPharmacyVisited }));
                dispatch(updateLocation({ id: lastLocationVisited }));
            }
        }
    });
};

export const login = (loginData) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.LOGIN}`;

    dispatch({ type: Actions.LOGIN_STARTED });
    // Note: api-web locks login to user_type and defaults user_type to patient if
    // user_type is not provided
    const postData = {
        ...loginData,
        user_type: 'pharmacist',
    };
    const loginReq = post(apiUrl, postData, BASE_HEADERS);
    loginReq
        .then((data) => {
            let loginUserType = _.get(data, 'is_internal_user') ? 'internal' : 'pharmacist';
            const loginText = _.get(data, 'is_first_login') ? 'first-login' : 'login';
            Snowplow.setUserId(_.get(data, 'user_id', null));
            Snowplow.structEvent('Login', loginText, null, loginUserType, SnowplowConstants.success);

            // Store account data from login response
            dispatch({ type: Actions.LOGIN_SUCCESS, payload: data });
            return dispatch(authorizeAccount());
        })
        .catch((err) => {
            logHttpError(`An error occurred on loginReq for username: ${loginData.email}`, err);

            Snowplow.structEvent('Login', 'pharmacist', null, 'login', SnowplowConstants.failure);

            dispatch({ type: Actions.LOGIN_FAILED, payload: err });
        });
};

export const logout = (reason) => (dispatch) => {
    // Submit logout request to invalidate backend session
    const apiUrl = `${Config.apiweb_url}${API_PATH.LOGOUT}`;
    const logoutReq = get(apiUrl, BASE_HEADERS);
    logoutReq.finally(() => {
        const label = snowplowEventLabel[reason];
        Snowplow.structEvent('Logout', 'logout', null, label, SnowplowConstants.success);

        dispatch({ type: Actions.LOGOUT, payload: { reason } });
    });
};
