import React from 'react';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';

const LabeledSwitchPropTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    switchValue: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const LabeledSwitch = (props) => {
    const { title, description, switchValue, onChange } = props;

    return (
        <ListItem>
            <ListItemText
                primary={
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: '400',
                            fontSize: '16px',
                            color: '#1d1d1d',
                        }}
                    >
                        {title}
                    </Typography>
                }
                secondary={
                    <Typography
                        variant="body2"
                        style={{
                            color: '#5d5d5d',
                        }}
                    >
                        {description}
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <span>
                        <Switch checked={switchValue} onChange={onChange} color="primary" />
                    </span>
                }
            />
        </ListItem>
    );
};

LabeledSwitch.propTypes = LabeledSwitchPropTypes;

export default LabeledSwitch;
