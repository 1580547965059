import moment from 'moment';
import _ from 'lodash';
import * as numeral from 'numeral';
import phone from 'phone';

import RoutesAndPageNameMap from 'constants/RoutesAndPageNameMap';
import Snowplow from 'snowplow/Snowplow';

const colorList = [
    '#9bc9ff',
    '#5b89bf',
    '#1b497f',
    '#6d84a0',
    '#0d2440',
    '#296ebf',
    '#498edf',
    '#404040',
    '#599eef',
    '#01579b',
];

numeral.zeroFormat('0'); //Custom 0 Formatting

export const ensureCorrectTypeForAttributeValue = (payload, attributeValue) => {
    // API requires that we send typed values inside the payload
    return payload.dataType === 'Boolean' ? Boolean(attributeValue) : attributeValue;
};

export const stringToColor = (str, s, l) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    if (hash < 0) {
        // make positive;
        hash = -hash;
    }

    return colorList[hash % 10];
};

export const computeAvatarStyle = (fullName, size = '24px') => {
    return {
        width: size,
        height: size,
        fontSize: '.7rem',
        backgroundColor: stringToColor(`${fullName}`),
    };
};

export const getPhonePlaceholderByCountryCode = ({ countryCode = 'US' }) => {
    if (countryCode === 'GB') {
        return '5555 555555';
    }

    return '(555) 555-5555';
};

export const getDateInputFormatByCountryCode = ({ countryCode = 'US' }) => {
    if (countryCode === 'GB') {
        return 'DD/MM/YYYY';
    }

    return 'MM/DD/YYYY';
};

export const getAbbreviatedDateInputFormatByCountryCode = ({ countryCode = 'US' }) => {
    if (countryCode === 'GB') {
        return 'D MMM YYYY';
    }

    return 'MMM D, YYYY';
};

export const formatRawDate = (rawDate, inboundFormat = 'YYYY-MM-DD', outboundFormat = 'MM/DD/YYYY') => {
    if (!rawDate) {
        return null;
    }

    const date = moment(rawDate, inboundFormat);
    if (date) {
        return date.format(outboundFormat);
    }
    return null;
};

export const formatRawPhone = (rawPhone, countryCode = 'US') => {
    if (!rawPhone) {
        return null;
    }
    const isGB = countryCode === 'GB';
    // since we only want to show the (555) 555-5555 style, we will throw away any prefixed country codes
    const nonPrefixed = _.takeRight(rawPhone, 10).join('');
    const pattern = isGB ? /^(\d{4})(\d{6})$/ : /^(\d{3})(\d{3})(\d{4})$/;
    const phoneSection = nonPrefixed.match(pattern);
    if (phoneSection) {
        return isGB
            ? `${phoneSection[1]} ${phoneSection[2]}`
            : `(${phoneSection[1]}) ${phoneSection[2]}-${phoneSection[3]}`;
    }
    return null;
};

export const toDigits = (numberString) => {
    if (_.isString(numberString)) {
        return numberString.replace(/\D+/g, '');
    }
};

/* eslint-disable */
const VALID_US_RAW_PHONE_NUMBER_REGEX = /\+{0,1}[\- ]?\d{0,2}[\- ]?\(?\d{3}\)?[\- ]?\d{3}[\- ]?\d{4}/;
// UK phone regex from https://stackoverflow.com/a/11518538
const VALID_UK_RAW_PHONE_NUMBER_REGEX = /(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?/;
/* eslint-enable */

export const cleanPhoneNumber = (numberString, countryCode = 'US') => {
    if (_.isString(numberString)) {
        const match = numberString.match(
            countryCode === 'GB' ? VALID_UK_RAW_PHONE_NUMBER_REGEX : VALID_US_RAW_PHONE_NUMBER_REGEX
        );
        if (!_.isNil(match)) {
            return _.first(match);
        }
    }
    return numberString;
};

export const cleanString = (rawString) => {
    if (_.isString(rawString)) {
        return rawString.replace(/(\r\n|\n|\r|"|,)/gm, '').trim();
    }
    return rawString;
};

export const isEmptyString = (string) => {
    return !string || string.length === 0;
};

export const phoneType = {
    mobile: 'mobile',
    voip: 'voip',
    landline: 'landline',
    invalid: 'invalid',
    unknown: 'unknown',
};

export const isUnmessageablePhoneType = ({ phone_type }) => {
    return phone_type === phoneType.landline || phone_type === phoneType.invalid || phone_type === phoneType.unknown;
};

export const getLocationData = (locationId, pharmacyData) => {
    const pharmacyLocations = _.get(pharmacyData, 'location', []);

    for (let i = 0; i < pharmacyLocations.length; i++) {
        if (pharmacyLocations[i].id === locationId) {
            return pharmacyLocations[i];
        }
    }

    return null;
};

export const getPharmacyPackage = (packageCode, pharmacyData) => {
    const pharmacyPackages = _.get(pharmacyData, 'package', []);

    for (let i = 0; i < pharmacyPackages.length; i++) {
        if (pharmacyPackages[i].code === packageCode) {
            return pharmacyPackages[i];
        }
    }

    return null;
};

export const getPharmacyPackageAttributeByName = (properties, attrName) => {
    return _.find(properties, (p) => p.name === attrName);
};

export const getPharmacyPackageById = (packageId, pharmacyData) => {
    // Note: packageId needs to be the id (which is unique) from the package object not the pkgId (which is not unique)
    const pharmacyPackages = _.get(pharmacyData, 'package', []);

    return _.find(pharmacyPackages, function (o) {
        return o.id === packageId;
    });
};

export const getPackageAttributeById = (attributeId, packageData) => {
    // Note: attributeId needs to be the id (which is unique) from the packageAttribute object not the packageAttrId (which is not unique)
    const packageProperties = _.get(packageData, 'properties', []);

    return _.find(packageProperties, function (o) {
        return o.id === attributeId;
    });
};

export const getLocationPackageAttribute = (packageCode, locationId, attributeName, pharmacyData) => {
    const pharmacyPackage = getPharmacyPackage(packageCode, pharmacyData);
    if (!pharmacyPackage || pharmacyPackage.statusId !== 1) {
        // Package not found or is not enabled
        return false;
    }

    const attr = _.find(pharmacyPackage.properties, function (o) {
        return o.name === attributeName && o.locationId === locationId;
    });

    return attr || {};
};

export const getPackageAttribute = (packageCode, attributeName, pharmacyData) => {
    const pharmacyPackage = getPharmacyPackage(packageCode, pharmacyData);
    if (!pharmacyPackage || pharmacyPackage.statusId !== 1) {
        // Package not found or is not enabled
        return false;
    }

    const attr = _.find(pharmacyPackage.properties, function (o) {
        return o.name === attributeName;
    });

    return attr || {};
};

export const isServiceEnabled = (packageCode, serviceName, serviceAttrName, locationId, pharmacyData) => {
    const pharmacyPackage = getPharmacyPackage(packageCode, pharmacyData);
    if (!pharmacyPackage || pharmacyPackage.statusId !== 1) {
        // Package not found or is not enabled
        return false;
    }

    const locationData = getLocationData(locationId, pharmacyData);
    if (!locationData) {
        // Location not found
        return false;
    }

    return _.get(locationData, `service.${serviceName}.${serviceAttrName}`, false);
};

export const isPharmacyServiceAttributeEnabled = (packageCode, serviceName, serviceAttrName, pharmacyData) => {
    const pharmacyPackage = getPharmacyPackage(packageCode, pharmacyData);
    if (!pharmacyPackage || pharmacyPackage.statusId !== 1) {
        // Package not found or is not enabled
        return false;
    }
    return _.get(pharmacyData, `service.${serviceName}.${serviceAttrName}`, false);
};

export const isPharmacyServiceEnabled = (packageCode, pharmacyData) => {
    const pharmacyPackage = getPharmacyPackage(packageCode, pharmacyData);
    if (!pharmacyPackage || pharmacyPackage.statusId !== 1) {
        // Package not found or is not enabled
        return false;
    } else if (pharmacyPackage && pharmacyPackage.statusId === 1) {
        return true;
    }
    return false;
};

export const getLocationNameByMConfigId = (mConfigId, pharmacyData) => {
    // mConfigId is the old uuid from config1
    const foundLocation = _.find(pharmacyData.location, function (location) {
        return _.get(location, 'attr.mConfigId.value', null) === mConfigId;
    });

    if (foundLocation) {
        return foundLocation.name;
    }

    return null;
};

export function difference(object, base) {
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export const formatPhoneNo = (phone) => {
    const start = phone && phone.length > 10 ? phone.length - 10 : 0;
    return phone ? `(${phone.substr(start, 3)}) ${phone.substr(start + 3, 3)}-${phone.substr(start + 6, 4)}` : '';
};

export const stripISDCode = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    return phoneNumber.length > 10 ? phoneNumber.substring(phoneNumber.length - 10) : phoneNumber;
};

export const isPhoneNoValid = (phoneNumber) => {
    const strippedPhoneNumber = stripISDCode(phoneNumber);
    return strippedPhoneNumber && phone(strippedPhoneNumber, '').length !== 0;
};

function getMomentInputAndCurrDates(utcDate, timezone = null) {
    let inputDate = null;
    let currDate = null;

    if (timezone) {
        inputDate = moment(utcDate.replace('T', ' ') + '.000+00:00').tz(timezone);
        currDate = moment().tz(timezone);
    } else {
        inputDate = moment.utc(utcDate).local();
        currDate = moment().local();
    }
    return { inputDate, currDate };
}

export function getTimelineDate(utcDate, timezone) {
    const { inputDate, currDate } = getMomentInputAndCurrDates(utcDate, timezone);
    const today = `Today ${inputDate.format('hh:mm A')}`;
    const yesterday = `Yesterday ${inputDate.format('hh:mm A')}`;
    const timeLineDate = inputDate.format('MMM D hh:mm A');
    const diffInDays = currDate.startOf('day').diff(inputDate.startOf('day'), 'days');

    if (diffInDays === 0) {
        return inputDate.isBefore(currDate, 'day') ? yesterday : today;
    } else if (diffInDays === 1) {
        return yesterday;
    }

    return timeLineDate;
}

// export function getTimelineDateInTimezone(utcDate, timezone) {
//   const inputDate =  moment(utcDate.replace('T', ' ') + '.000+00:00').tz(timezone);
//   const currDate = moment().tz(timezone);
//   const diffInDays = currDate.diff(inputDate, 'days');
//   const today = `Today ${inputDate.format('hh:mm A')}`;
//   const yesterday = `Yesterday ${inputDate.format('hh:mm A')}`;

//   if (diffInDays === 0) {
//     return inputDate.isBefore(currDate, 'day') ? yesterday : today;
//   } else if (diffInDays === 1) {
//     return yesterday;
//   }

//   return inputDate.format('MMM D hh:mm A');
// }

export const buildRoomUri = (locationId) => {
    const roomUri = `/location/${locationId}`;
    return roomUri;
};

export const debounceAction = (action, wait, options) => {
    // for options see: https://lodash.com/docs/4.17.4#debounce
    const debounced = _.debounce((dispatch, actionArgs) => dispatch(action(...actionArgs)), wait, options);

    // see: https://github.com/gaearon/redux-thunk
    const thunk = (...actionArgs) => (dispatch) => debounced(dispatch, actionArgs);

    // provide hook to _.debounce().cancel() to cancel any trailing invocations or
    // _.debounce().flush() to execute it immediately
    thunk.cancel = debounced.cancel;
    thunk.flush = debounced.flush;

    return thunk;
};

export const formatToUSD = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
};

// A listener that gets called on a change of path/ route
export const trackPageViewForPath = ({ pathname }) => {
    if (pathname !== undefined) {
        const pageName = RoutesAndPageNameMap[pathname];
        if (pageName !== undefined) {
            Snowplow.pageView(pageName);
        }
    }
};

export const formatWithCommaAndAbbr = (num) => {
    if (isNaN(num)) return num;

    if (num < 1000000) {
        return numeral(num).format('0,0.[0]');
    }
    return numeral(num).format('0,0.0a').toUpperCase();
};

export const formatToCurrency = (num, format) => {
    if (isNaN(num)) return num;

    if (num < 1000000) {
        return numeral(num).format(`${format}0,0[.]00`);
    }
    return numeral(num).format(`${format}0,0.00a`).toUpperCase();
};

export const isContractSignedForLocation = (pharmacyContract, locationId) => {
    const isContractSigned = _.get(pharmacyContract, `${locationId}.isSigned`, false);
    const isSigningSkipped = _.get(pharmacyContract, `${locationId}.skipSigning`, false);
    return isContractSigned || isSigningSkipped;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
};

export const validateUrl = (url) => {
    const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return re.test(String(url).toLowerCase());
};

export const getDateFormatted = (utcDate) => {
    if (!utcDate) return '-';
    const fDate = moment.utc(utcDate).local();
    return `${fDate.format('hh:mm A')} on ${fDate.format('MM/DD/YYYY')}`;
};

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// slightly adapted (to use modern syntax) from https://stackoverflow.com/a/21015393
export function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');

    // check if our context is null (and return 0 if it is) since our tests are run using a fake dom
    // that doesnt' support canvas properly
    if (_.isNil(context)) {
        return 0;
    }

    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export function getLocationNameFromAuth(auth) {
    return _.isString(_.get(auth, 'locationDetail.displayName'))
        ? _.get(auth, 'locationDetail.displayName')
        : _.get(auth, 'selectedLocation.name');
}

export function getLocationFromPharmacy(pharmacy) {
    const { activeLocationId } = pharmacy;
    return _.find(_.get(pharmacy, 'pharmacy.location'), (p) => p.id === activeLocationId);
}

export function getLocationPhoneNumberFromPharmacy(pharmacy) {
    return _.get(getLocationFromPharmacy(pharmacy), 'phone');
}

export function getLocationAddressFromPharmacy(pharmacy) {
    return _.get(getLocationFromPharmacy(pharmacy), 'address.Main');
}

export function formatAddress(address) {}

export const copyTextToClipboard = (elementId) => {
    const textarea = document.createElement('textarea');
    const element = document.getElementById(elementId);
    const children = Array.from(element && element.children);
    let text = '';

    if (element.tagName === 'INPUT') {
        text = element.value;
    } else {
        _.forEach(children, (child) => (text += `${child.textContent}\n`));
    }

    text = text || element.textContent;
    textarea.innerHTML = text;
    document.body.appendChild(textarea);

    textarea.select();
    // sometimes only select() doesn't work, hence the following
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNode(textarea);
    selection.addRange(range);

    document.execCommand('copy');
    textarea.remove();
    selection.removeAllRanges();
};

export const isUrl = (string) => {
    /* eslint-disable */
    const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
    const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
    const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
    /* eslint-enable */

    if (typeof string !== 'string') {
        return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
        return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
        return false;
    }

    if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
        return true;
    }

    return false;
};

export function openLinkInNewTab(url) {
    // if this is the electron app, then use shell to open an new link
    if (_.isString(window.ELECTRON_APP_VERSION)) {
        // used window.require to get webpack to ignore this as its not defined in our normal context
        const openExternal = _.get(window.require('electron'), 'shell.openExternal');
        if (_.isFunction(openExternal)) {
            openExternal(url);
        }
    } else {
        // otherwise open with _blank target
        window.open(url, '_blank');
    }
}

export function htmlToPlainString(htmlText) {
    return htmlText.replace(/(<([^>]+)>)/gi, ' ');
}

export function getFormattedMailupDate(sent_date) {
    let date;
    const mailupNotSentDateFormat = '0001-01-01T00:00:00+00:00';

    if (!sent_date || sent_date === mailupNotSentDateFormat) {
        date = '-';
    } else {
        date = moment(sent_date).format('MMM DD, YYYY');
    }

    return date;
}
