import _ from 'lodash';
import { userType } from './type';
import { userService } from './service';
import config from '../../../config';
import { debounceAction } from '../../../utils/helper';
import { searchType } from '../../../constants/Inbox';
import { determineSearchType, formatSearchDate } from '../../../utils/inboxHelper';
import { onboardingActions } from '../Onboarding';
import SnackbarActions from '../../actions/Snackbar';
import Snowplow, { SnowplowConstants } from '../../../snowplow';

const resetUserStore = (autoGetContacts = false, autoSelectActive = true) => {
    return {
        type: userType.RESET_USER_STORE,
        autoGetContacts,
        autoSelectActive,
    };
};

const setActiveUser = (userId, userMapData = null) => {
    return function (dispatch, getState) {
        const userState = getState().inboxUser;
        const { userMap, activeUserId } = userState;

        // Ignore if userId is already active
        if (activeUserId === userId) {
            return;
        }

        if (_.isObject(userMapData) || userMap[userId]) {
            const phoneType = _.isObject(userMapData)
                ? _.get(userMapData, 'phone_type')
                : _.get(userMap, `${userId}.phone_type`, null);

            Snowplow.structEvent('Inbox', 'pharmacist-clicked-user', null, phoneType, userId);

            dispatch({
                type: userType.SET_ACTIVE_USER,
                activeUserId: userId,
                userMapData,
            });
        }
    };
};

const setVMUser = (user) => {
    return function (dispatch, getState) {
        dispatch({
            type: userType.SET_VM_USER,
            user,
        });
    };
};

const clearCreateUserMessage = () => {
    return {
        type: userType.CLEAR_CREATE_USER_MESSAGE,
    };
};

const clearActiveUser = () => {
    return {
        type: userType.CLEAR_ACTIVE_USER,
    };
};

const openEditUserDialog = () => {
    return {
        type: userType.OPEN_EDIT_USER_DIALOG,
    };
};

const closeEditUserDialog = () => {
    return {
        type: userType.CLOSE_EDIT_USER_DIALOG,
    };
};

const toggleEditUserDialog = () => {
    return {
        type: userType.TOGGLE_EDIT_USER_DIALOG,
    };
};

const getUsersList = () => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;
    return function (dispatch) {
        dispatch({ type: userType.GET_USERS_LIST_REQUEST });

        userService.getUsersList(pharmacyId, locationId).then(
            (users) => {
                dispatch({ type: userType.GET_USERS_LIST_SUCCESS, users });
            },
            (error) => {
                dispatch({ type: userType.GET_USERS_LIST_FAILURE, error });
            }
        );
    };
};

const getUsers = (autoSelectActive = false, nextPage = false, ignoreAlreadyLoading = false) => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;
    return function (dispatch, getState) {
        const userState = getState().inboxUser;

        // if we are already loading this, then short circuit unless explicitly told not to
        if (userState.loading && !ignoreAlreadyLoading) {
            return;
        }

        const { userList, userPage, userPageItems } = userState;

        // Rudimentary pagination logic
        const page = nextPage ? userPage + 1 : 0;

        // Calculate if any more users can be requested
        if (nextPage && userList.length < (userPage + 1) * userPageItems) {
            // Current user list is less than full amount of users by page/page items - assume there are no more to request
            // ex: page 1 (0-based index), page items 50, 100 possible users if fewer than that exist then there are no more to fetch
            return;
        }

        dispatch(request());
        userService.getUsers(pharmacyId, locationId, page, userPageItems).then(
            (users) => {
                dispatch(success(users, autoSelectActive, page));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: userType.GET_USERS_REQUEST };
    }
    function success(users, autoSelectActive, page) {
        return {
            type: userType.GET_USERS_SUCCESS,
            users,
            autoSelectActive,
            page,
        };
    }
    function failure(error) {
        return { type: userType.GET_USERS_FAILURE, error };
    }
};

const getUsersActivityUnreadCount = (ignoreAlreadyLoading = false) => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;

    return (dispatch, getState) => {
        const userState = getState().inboxUser;

        // short circuit if we are already making this request
        if (userState.fetchingUnreadMessageCount && !ignoreAlreadyLoading) {
            return;
        }

        dispatch({ type: userType.GET_USERS_ACTIVITY_UNREAD_COUNT_REQUEST });

        userService
            .getUsersActivityUnreadCount({ pharmacyId, locationId, filterType: 'unread_patient' })
            .then((unreadMessageCount) => {
                dispatch({ type: userType.GET_USERS_ACTIVITY_UNREAD_COUNT_SUCCESS, unreadMessageCount });
            })
            // this is an error that does not need to be surfaced to the user
            .catch((error) => {
                dispatch({ type: userType.GET_USERS_ACTIVITY_UNREAD_COUNT_FAILURE, error });
            });
    };
};

// Generally I would not want to rely on a debounced activity, with how the middleware is setup currently
// This is a low risk approach to avoid over-fetching and make the request when the 'dust settles'
// A more deliberate approach of action chaining will typically result in easier code paths to follow
// With a 'wrapper' action that's sole purpose is orchestrating a distinct action chain
const debouncedGetUsersActivityUnreadCount = debounceAction(getUsersActivityUnreadCount, 500, { maxWait: 5000 });

const debouncedGetUsers = debounceAction(getUsers, 2000, { maxWait: 10000 });

const getLatestUserActivity = () => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;
    return function (dispatch) {
        dispatch(request());

        userService.getLatestUserActivity(pharmacyId, locationId).then(
            (latestActivity) => {
                dispatch(success(latestActivity));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: userType.GET_LATEST_USER_ACTIVITY_REQUEST };
    }
    function success(latestActivity) {
        return {
            type: userType.GET_LATEST_USER_ACTIVITY_SUCCESS,
            latestActivity,
        };
    }
    function failure(error) {
        return { type: userType.GET_LATEST_USER_ACTIVITY_FAILURE, error };
    }
};

const createUser = (userData) => {
    return function (dispatch) {
        dispatch(request());

        userService.createUser(userData).then(
            (responseData) => {
                dispatch(success(responseData));
                dispatch(onboardingActions.getInboxLocationOnboarding());
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: userType.CREATE_USER_REQUEST };
    }
    function success(responseData) {
        Snowplow.structEvent(
            'Inbox',
            'pharmacist-create-user-manual',
            null,
            responseData.user_id,
            SnowplowConstants.success
        );

        return { type: userType.CREATE_USER_SUCCESS, responseData };
    }
    function failure(error) {
        Snowplow.structEvent('Inbox', 'pharmacist-create-user-manual', null, null, SnowplowConstants.failure);
        return { type: userType.CREATE_USER_FAILURE, error };
    }
};

const mergeUpdatedUser = (userData, editedUser) => {
    if (_.isNil(userData)) {
        return userData;
    }
    const updatedFields = ['first_name', 'last_name', 'date_of_birth', 'phone', 'phone_type'];
    for (let i = 0; i < updatedFields.length; i++) {
        userData[updatedFields[i]] = editedUser[updatedFields[i]];
    }
    return userData;
};

const updateUser = (userData, userId, onSuccess) => {
    return function (dispatch, getState) {
        const userState = getState().inboxUser;
        const originalUserData = userState.userMap[userId];
        dispatch(request());

        userService.updateUser(userData, userId).then(
            (editedUser) => {
                dispatch(success(userId, mergeUpdatedUser(originalUserData, editedUser)));
                if (_.isFunction(onSuccess)) {
                    onSuccess(editedUser);
                }
            },
            (error) => {
                dispatch(failure(error, userId));
            }
        );
    };

    function request() {
        return { type: userType.UPDATE_USER_REQUEST };
    }
    function success(userId, mergedUserData) {
        Snowplow.structEvent('Inbox', 'pharmacist-edit-user', null, userId, SnowplowConstants.success);
        return { type: userType.UPDATE_USER_SUCCESS, userId, mergedUserData };
    }
    function failure(error) {
        Snowplow.structEvent('Inbox', 'pharmacist-edit-user', null, userId, SnowplowConstants.failure);
        return { type: userType.UPDATE_USER_FAILURE, error, userId };
    }
};

const mergedVerifiedUser = (userData) => {
    userData.verified = 1;
    return userData;
};

const verifyUser = (userId) => {
    return function (dispatch, getState) {
        const userState = getState().inboxUser;
        const originalUserData = userState.userMap[userId];
        dispatch(request());

        userService.verifyUser(userId).then(
            (verifiedUser) => {
                dispatch(success(userId, mergedVerifiedUser(originalUserData)));
            },
            (error) => {
                dispatch(failure(error, userId));
            }
        );
    };

    function request() {
        return { type: userType.VERIFY_USER_REQUEST };
    }
    function success(userId, mergedUserData) {
        Snowplow.structEvent('Inbox', 'pharmacist-verify-user', null, userId, SnowplowConstants.success);

        return { type: userType.VERIFY_USER_SUCCESS, userId, mergedUserData };
    }
    function failure(error, userId) {
        Snowplow.structEvent('Inbox', 'pharmacist-verify-user', null, userId, SnowplowConstants.failure);

        return { type: userType.VERIFY_USER_FAILURE, error, userId };
    }
};

const phoneTypeLookup = ({ userId }) => {
    return (dispatch, getState) => {
        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        const { userMap, lookingUpPhoneTypeByUserId } = getState().inboxUser;
        const activeUserData = userMap[userId];

        if (
            !activeUserData ||
            activeUserData.phone_type ||
            _.get(lookingUpPhoneTypeByUserId[userId], 'fetching', false)
        ) {
            // short circuit lookup when there is no active active user , we have the phone type, or we already are attempting to lookup the number
            return;
        }

        dispatch({ type: userType.USER_PHONE_TYPE_LOOKUP_REQUEST, userId });

        userService
            .phoneTypeLookup({ pharmacyId, locationId, userId })
            .then(({ phone_type }) => {
                const mergedUserData = { ...activeUserData, phone_type };
                dispatch({
                    type: userType.USER_PHONE_TYPE_LOOKUP_SUCCESS,
                    userId,
                    mergedUserData,
                });
            })
            .catch((error) => {
                dispatch({ type: userType.USER_PHONE_TYPE_LOOKUP_FAILURE, userId, error: error.message });
            });
    };
};

const setSearchFilter = (searchFilter) => {
    return function (dispatch) {
        dispatch(setFilter(searchFilter));

        dispatch(searchUsers());
    };

    function setFilter(searchFilter) {
        return {
            type: userType.SET_SEARCH_FILTER,
            searchFilter,
        };
    }
};

const resetSearchFilter = () => {
    return function (dispatch, getState) {
        dispatch(resetFilter());

        // Determine if we need to redo search w/out filter
        const userState = getState().inboxUser;
        const { searchQuery } = userState;

        if (searchQuery) {
            dispatch(searchUsers());
        }
    };

    function resetFilter() {
        return {
            type: userType.RESET_SEARCH_FILTER,
        };
    }
};

const setSearchQuery = (searchQuery) => {
    return function (dispatch) {
        dispatch(setQuery(searchQuery));

        dispatch(searchUsers());
    };

    function setQuery(searchQuery) {
        return {
            type: userType.SET_SEARCH_QUERY,
            searchQuery,
        };
    }
};

const resetSearchQuery = () => {
    return {
        type: userType.RESET_SEARCH_QUERY,
    };
};

const searchUsers = (nextPage = false) => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;
    return function (dispatch, getState) {
        const userState = getState().inboxUser;
        const { searchQuery, searchFilter, searchList, searchPage, searchPageItems } = userState;

        let validatedSearchString = null;
        let validatedSearchType = null;

        if ((!searchQuery || searchQuery.length < 3) && !searchFilter) {
            // No searchFilter and no valid search string
            // Don't trigger search request and return early
            return;
        }

        if (searchQuery && searchQuery.length > 2) {
            // Determine search type
            const type = determineSearchType(searchQuery);
            if (type === searchType.name) {
                // Search type is name, search immediately
                validatedSearchString = searchQuery;
                validatedSearchType = searchType.name;
            } else {
                // Search type is dob, additional logic
                if (searchQuery.length === 10) {
                    validatedSearchString = formatSearchDate(searchQuery);
                    validatedSearchType = searchType.dateOfBirth;
                } else if (!searchFilter) {
                    // DOB search string is invalid and there is no searchFilter
                    // Don't trigger search request and return early
                    return;
                }
            }
        }

        // Rudimentary pagination logic
        const page = nextPage ? searchPage + 1 : 0;

        // Calculate if any more users can be requested
        if (nextPage && searchList.length < (searchPage + 1) * searchPageItems) {
            // Current user list is less than full amount of users by page/page items - assume there are no more to request
            // ex: page 1 (0-based index), page items 50, 100 possible users if fewer than that exist then there are no more to fetch
            return;
        }

        dispatch(request());

        userService
            .searchUsers(
                pharmacyId,
                locationId,
                validatedSearchString,
                validatedSearchType,
                page,
                searchPageItems,
                searchFilter
            )
            .then(
                (users) => {
                    dispatch(success(users, page, validatedSearchType, searchFilter));
                },
                (error) => {
                    dispatch(failure(error.toString(), validatedSearchType, searchFilter));
                }
            );
    };

    function request() {
        return { type: userType.SEARCH_USERS_REQUEST };
    }
    function success(users, page, validatedSearchType, searchFilter) {
        if (validatedSearchType) {
            Snowplow.structEvent(
                'Inbox',
                'pharmacist-search-activity-type',
                null,
                validatedSearchType,
                SnowplowConstants.success
            );
        }

        if (searchFilter) {
            Snowplow.structEvent(
                'Inbox',
                'pharmacist-search-activity-filter',
                null,
                searchFilter,
                SnowplowConstants.success
            );
        }

        return { type: userType.SEARCH_USERS_SUCCESS, users, page, validatedSearchType, searchFilter };
    }
    function failure(error, validatedSearchType, searchFilter) {
        if (validatedSearchType) {
            Snowplow.structEvent(
                'Inbox',
                'pharmacist-search-activity-type',
                null,
                validatedSearchType,
                SnowplowConstants.failure
            );
        }

        if (searchFilter) {
            Snowplow.structEvent(
                'Inbox',
                'pharmacist-search-activity-filter',
                null,
                searchFilter,
                SnowplowConstants.failure
            );
        }

        return { type: userType.SEARCH_USERS_FAILURE, error, validatedSearchType, searchFilter };
    }
};

const uploadBulkOriginalCSV = ({ patientCSV, locationName, rowCount, patientCount, success }) => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;

    return (dispatch) => {
        dispatch({ type: userType.UPLOAD_BULK_ORIGINAL_CSV_REQUEST });

        userService
            .uploadBulkOriginalCSV({
                pharmacyId,
                locationId,
                patientCSV,
                locationName,
                rowCount,
                patientCount,
                success,
            })
            .then(() => {
                dispatch({ type: userType.UPLOAD_BULK_ORIGINAL_CSV_SUCCESS });
            })
            .catch((error) => {
                dispatch({ type: userType.UPLOAD_BULK_ORIGINAL_CSV_FAILURE, error });
            });
    };
};

const createBulkUsers = (usersData) => {
    return function (dispatch) {
        dispatch(request());

        const promise = userService.createBulkUsers(usersData);
        promise
            .then((responseData) => {
                dispatch(success(responseData));
                // required to optimistically update new_patient_count due to async nature of bulk patient upload
                dispatch(
                    onboardingActions.updateInboxLocationOnboardingServerSteps({
                        new_patient_count: _.size(usersData),
                    })
                );
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : 422;
                window.Intercom(
                    'showNewMessage',
                    `I'm having trouble uploading my csv to Inbox. I received a ${statusCode} error.`
                );
                dispatch(failure(error));
            });

        return promise;
    };

    function request() {
        return { type: userType.CREATE_BULK_USERS_REQUEST };
    }
    function success(responseData) {
        Snowplow.structEvent(
            'Inbox',
            'pharmacist-create-users-csv',
            null,
            'created-count',
            SnowplowConstants.success,
            responseData.created_users
        );
        return { type: userType.CREATE_BULK_USERS_SUCCESS, responseData };
    }
    function failure(error) {
        Snowplow.structEvent('Inbox', 'pharmacist-create-users-csv', null, null, SnowplowConstants.failure);
        return { type: userType.CREATE_BULK_USERS_FAILURE, error };
    }
};

const updateActiveUserMessageStatus = (messageId) => {
    return function (dispatch, getState) {
        const userState = getState().inboxUser;
        const { userMap, activeUserId } = userState;
        const activeUser = userMap[activeUserId];

        if (activeUser && activeUser.latest_message && activeUser.latest_message.inbox_message_id === messageId) {
            userMap[activeUserId].latest_message.viewed = 1;
            dispatch({
                type: userType.UPDATE_ACTIVE_USER_MESSAGE_STATUS,
                userMap,
            });
        }
    };
};

const archiveUser = (userId) => {
    return function (dispatch) {
        dispatch({ type: userType.ARCHIVE_USER_REQUEST });

        return userService.archiveUser(userId).then(
            (archivedUser) => {
                dispatch({ type: userType.ARCHIVE_USER_SUCCESS, archivedUser });
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: 'User archived',
                        type: 'success',
                    },
                });
                dispatch(getUsers(true, false, true));
                return true;
            },
            (error) => {
                dispatch({ type: userType.ARCHIVE_USER_FAILURE, error, userId });
                return false;
            }
        );
    };
};

export const userAction = {
    resetUserStore,
    setActiveUser,
    clearActiveUser,
    openEditUserDialog,
    clearCreateUserMessage,
    closeEditUserDialog,
    toggleEditUserDialog,
    getUsers,
    getUsersActivityUnreadCount,
    debouncedGetUsersActivityUnreadCount,
    debouncedGetUsers,
    getLatestUserActivity,
    phoneTypeLookup,
    searchUsers,
    setSearchQuery,
    resetSearchQuery,
    setSearchFilter,
    resetSearchFilter,
    createUser,
    updateUser,
    uploadBulkOriginalCSV,
    createBulkUsers,
    updateActiveUserMessageStatus,
    verifyUser,
    getUsersList,
    setVMUser,
    archiveUser,
};

export default userAction;
