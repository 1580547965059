import Actions from 'redux/actions';
import patientGreeting from 'constants/LeaveMessage';

const initialState = {
    newPatientGreeting: {},
    showPatientGreetingCreator: false,
};

const addPatientLine = (state) => {
    const newPatientGreeting = { ...patientGreeting };
    return {
        ...state,
        showPatientGreetingCreator: true,
        newPatientGreeting,
    };
};

const updatePatientGreetingMsg = (state, payload) => {
    const newPatientGreeting = { ...state.newPatientGreeting };
    if (payload.id) {
        const greetingFiles = [...payload.greetingFiles];
        const selectedGreetingFile = greetingFiles.find((file) => file.id === payload.id);
        newPatientGreeting.patient_greeting_id = selectedGreetingFile.id;
        newPatientGreeting.patient_greeting_url = selectedGreetingFile.publicURL;
    } else {
        newPatientGreeting.patient_greeting_id = '';
        newPatientGreeting.patient_greeting_url = '';
    }
    return {
        ...state,
        newPatientGreeting,
    };
};

const editPatientGreeting = (state, payload) => ({
    ...state,
    showPatientGreetingCreator: true,
    newPatientGreeting: { ...payload },
});

const changeGreetingType = (state, payload) => {
    const newPatientGreeting = { ...state.newPatientGreeting };
    newPatientGreeting.patient_greeting_type_id = payload;

    return {
        ...state,
        newPatientGreeting,
    };
};

const changeGreetingScript = (state, payload) => {
    const newPatientGreeting = { ...state.newPatientGreeting };
    newPatientGreeting.patient_greeting_text = payload;
    return {
        ...state,
        newPatientGreeting,
    };
};

export default function LeaveReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.ADD_PATIENT_GREETING:
            return addPatientLine(state);
        case Actions.UPDATE_PATIENT_GREETING:
            return updatePatientGreetingMsg(state, payload);
        case Actions.CLOSE_PATIENT_GREETING_EDITOR:
            return { ...state, showPatientGreetingCreator: false, newPatientGreeting: {} };
        case Actions.EDIT_PATIENT_GREETING:
            return editPatientGreeting(state, payload);
        case Actions.CHANGE_PATIENT_GREETING_TYPE:
            return changeGreetingType(state, payload);
        case Actions.CHANGE_PATIENT_GREETING_SCRIPT:
            return changeGreetingScript(state, payload);
        default:
            return state;
    }
}
