import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import MessageInput from '../Message/MessageInput';
import MessageList from '../Message/MessageList';

const ConversationMobile = (props) => {
    const { active, contact, activeLocationCountryCode, activeConversation, onBack } = props;
    return (
        <Fragment>
            {active && contact && (
                <div className="conversation-container">
                    <div className="flex-middle conversation-mobile-header" style={{ maxWidth: '90%' }}>
                        <IconButton onClick={onBack}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            variant="h5"
                            color="inherit"
                            className="overflow-elipsis"
                            style={{ fontSize: '24px', color: '#4a4a4a' }}
                        >
                            {contact.first_name} {contact.last_name} - {activeConversation.subject}
                        </Typography>
                    </div>
                    <div style={{ padding: '16px' }} className="message-list-container">
                        <MessageList
                            contact={contact}
                            active={active}
                            activeLocationCountryCode={activeLocationCountryCode}
                        />
                    </div>
                    <MessageInput contact={contact} />
                </div>
            )}
        </Fragment>
    );
};

export default ConversationMobile;
