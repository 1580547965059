import './WhenToScheduleContent.sass';
import React, { useEffect } from 'react';
import _ from 'lodash';
import DateSelectionCalendar from 'components/Appointments/DateSelectionCalendar';
import moment from 'moment';
import classnames from 'classnames';
import { List } from 'react-virtualized';
import numeral from 'numeral';
import { Button, CircularProgress, Tooltip, FormControlLabel, Switch } from '@material-ui/core';
import AppointmentLengthSelect from 'components/Appointments/AppointmentLengthSelect';
import Snowplow, { StructEventCategories } from 'snowplow';
import { getCalendarExplanationTextForDate, getNumberOfNonPastSlotsAvailableForDate } from '../schedulingUtil';
import VacationIcon from 'components/Appointments/VacationIcon';
import { getVacationTimesSummaryForDateOnCalendar } from 'components/Appointments/AppointmentsPage';
import CalendarSelect from 'components/Appointments/CalendarSelect';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

function WhenToScheduleContent({
    timeRange,
    timeRangeSelected,
    setTimeRange,
    availableSlots,
    isLoadingNewSlots,
    sortedDays,
    calendars,
    calendar,
    displayedDateFormat,
    appointmentLengthMinutes,
    setAppointmentLengthMinutes,
    reservationSession,
    setReservationSession,
    setCalendarId,
    onNext,
    isEdit,
    numberOfAvailableSlots,
    totalWidth = window.innerWidth,
    showAvailableOnWeb,
}) {
    // simple update helper that will set all of the passed paths to the passed values
    const updateTimeRange = (updates) => {
        const clone = _.cloneDeep(timeRange);
        _.each(_.filter(updates), ([path, value]) => {
            _.set(clone, path, value);
        });

        if (!_.isNil(reservationSession)) {
            const reservationSessionClone = _.cloneDeep(reservationSession);

            reservationSessionClone.start_date = clone.from.date.format('YYYY-MM-DD');
            reservationSessionClone.end_date = clone.to.date.format('YYYY-MM-DD');

            setReservationSession(reservationSessionClone);
        } else {
            setTimeRange(clone);
        }

        Snowplow.structEvent(
            StructEventCategories.appointments,
            'schedule-workflow-date-change',
            null,
            '',
            'selected-dates',
            `${_.get(clone, 'from.date').format('YYYY-MM-DD')}, ${_.get(clone, 'to.date').format('YYYY-MM-DD')}`
        );
    };
    const barWidth = Math.min(1098, totalWidth);
    const calendarsWidth = 500;

    const slotsOverviewWidth = barWidth - calendarsWidth;

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - When');
    }, []);

    const selectedMinutes = _.isNil(reservationSession) ? appointmentLengthMinutes : reservationSession.expected_length;

    return (
        <div className="when-to-schedule-content">
            <div className="heading">
                Specify exactly when patients will be allowed to schedule appointments on this block
            </div>
            <div className="sub-heading">
                Patients will be able to schedule any available appointment slots on the chosen clinical calendar from
                the starting date & time until the ending date & time.
            </div>

            <div className="calendar-and-length-selects">
                <CalendarSelect
                    calendars={calendars}
                    value={_.get(calendar, 'calendar_id')}
                    disabled={isEdit}
                    onChange={(e) => {
                        setCalendarId(e.target.value);
                        if (!_.isNil(reservationSession)) {
                            const clone = _.cloneDeep(reservationSession);
                            clone.calendar_id = e.target.value;
                            setReservationSession(clone);
                        }
                    }}
                />

                <AppointmentLengthSelect
                    disabled={isEdit}
                    value={selectedMinutes}
                    onChange={(e) => {
                        setAppointmentLengthMinutes(Number(e.target.value));
                        if (!_.isNil(reservationSession)) {
                            const clone = _.cloneDeep(reservationSession);
                            clone.expected_length = Number(e.target.value);
                            setReservationSession(clone);
                        }
                    }}
                />

                {showAvailableOnWeb && (
                    <Tooltip title="Make this appointment block available in my website.">
                        <FormControlLabel
                            className={classnames({ 'on-web-switch': true })}
                            control={
                                <Switch
                                    //checked={_.get(bookingDetails, 'onWebsite', false)}
                                    //onChange={(e) => setBookingDetails({ ...bookingDetails, onWebsite: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label="Available on web?"
                        />
                    </Tooltip>
                )}
            </div>

            <div className="calendars-and-slots">
                <div className="from-date-and-time">
                    <div className="heading">Starting at...</div>
                    <DateSelectionCalendar
                        focusedDate={_.isNil(_.get(timeRange, 'from.date')) ? null : _.get(timeRange, 'from.date')}
                        onDateChange={(d) => {
                            updateTimeRange([
                                ['from.date', d],
                                _.isNil(_.get(timeRange, 'to.date')) ? ['to.date', d] : null,
                            ]);
                        }}
                        minSelectableDate={
                            _.isNil(reservationSession)
                                ? moment()
                                : moment(reservationSession.start_date, 'YYYY-MM-DD').isBefore(moment(), 'day')
                                ? moment(reservationSession.start_date, 'YYYY-MM-DD')
                                : moment()
                        }
                        maxSelectableDate={_.get(timeRange, 'to.date')}
                        initiallyFocusedMonth={moment()}
                    />
                </div>

                <div className="to-date-and-time">
                    <div className="heading">Ending at...</div>
                    <DateSelectionCalendar
                        focusedDate={_.isNil(_.get(timeRange, 'to.date')) ? null : _.get(timeRange, 'to.date')}
                        onDateChange={(d) => {
                            updateTimeRange([
                                ['to.date', d],
                                _.isNil(_.get(timeRange, 'from.date')) ? ['from.date', d] : null,
                            ]);
                        }}
                        minSelectableDate={
                            _.isNil(_.get(timeRange, 'from.date')) ? moment() : _.get(timeRange, 'from.date')
                        }
                        initiallyFocusedMonth={moment()}
                    />
                </div>

                <div
                    className={classnames({ 'slots-overview': true, visible: !_.isEmpty(availableSlots) })}
                    style={{ width: `${slotsOverviewWidth}px` }}
                >
                    <div className="heading">
                        <div className="title">Available Calendar Slots</div>

                        {!_.isFinite(numberOfAvailableSlots) || !timeRangeSelected ? null : (
                            <div className="total-available-slots">
                                {_.isNil(availableSlots) || isLoadingNewSlots ? (
                                    <CircularProgress size={24} style={{ color: '#fff' }} />
                                ) : (
                                    `${numeral(numberOfAvailableSlots).format('0,0')} available ${pluralize(
                                        'slot',
                                        numberOfAvailableSlots
                                    )}`
                                )}
                            </div>
                        )}
                    </div>
                    <div className="day-slots-list" style={{ filter: !isLoadingNewSlots ? null : 'blur(1px)' }}>
                        <List
                            className={classnames({ 'days-virt-list': true, empty: _.isEmpty(sortedDays) })}
                            width={slotsOverviewWidth - 19}
                            height={280}
                            rowCount={_.isNil(_.get(timeRange, 'from.date')) ? 0 : _.size(sortedDays)}
                            rowHeight={40}
                            rowRenderer={({ index, key, style }) => {
                                const numberOfSlots = getNumberOfNonPastSlotsAvailableForDate(
                                    availableSlots,
                                    sortedDays[index],
                                    _.get(calendar, 'timezone')
                                );

                                const vacationSummary = getVacationTimesSummaryForDateOnCalendar(
                                    calendar,
                                    moment(sortedDays[index], 'YYYY-MM-DD')
                                );

                                return (
                                    <div
                                        className={classnames({ 'slots-day-row': true, odd: index % 2 === 1 })}
                                        key={key}
                                        style={style}
                                    >
                                        <div className="date">
                                            <span>
                                                {moment(sortedDays[index], 'YYYY-MM-DD').format(
                                                    'ddd, ' + displayedDateFormat
                                                )}
                                            </span>

                                            {_.isNil(vacationSummary) ? null : (
                                                <VacationIcon
                                                    size={20}
                                                    title={`Vacation scheduled ${vacationSummary}`}
                                                />
                                            )}
                                        </div>
                                        <div className="availability">
                                            <Tooltip
                                                title={getCalendarExplanationTextForDate(
                                                    numberOfSlots,
                                                    sortedDays[index],
                                                    calendar
                                                )}
                                            >
                                                <span>{numeral(numberOfSlots).format('0,0')} available slots</span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                    {!isLoadingNewSlots ? null : <CircularProgress className="loading-spinner" />}

                    {isLoadingNewSlots || (!_.isNil(_.get(timeRange, 'from.date')) && !_.isEmpty(sortedDays)) ? null : (
                        <div className="pick-days-message">
                            Please choose a date range to see available appointment slots.
                        </div>
                    )}
                </div>
            </div>

            <div className="buttons-row">
                <Button
                    className="next-button"
                    variant="contained"
                    color="primary"
                    disabled={!timeRangeSelected || _.isNil(calendar) || !_.isFinite(selectedMinutes)}
                    onClick={() => {
                        /*Snowplow.structEvent(
                            StructEventCategories.appointments,
                            'schedule-workflow-when-next-clicked',
                            [
                                new AppointmentWorkflowSessionContext()
                                    .setTemplateId(template.inbox_conversation_template_id)
                                    .setStartDate(_.get(timeRange, 'from.date').format('YYYY-MM-DD'))
                                    .setEndDate(_.get(timeRange, 'to.date').format('YYYY-MM-DD'))
                                    .build(),
                            ]
                        );*/
                        onNext();
                    }}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { launchDarkly } = state;

    return {
        showAvailableOnWeb: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(WhenToScheduleContent);
