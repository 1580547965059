import { get, post, deleteReq } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import Actions from 'redux/actions';
import { fetchMainGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { logHttpError } from 'utils/log';
import { displayToast } from 'redux/actionCreators/Snackbar';

const getMainMenuUrl = (id) => {
    const mainMenuApiPath = API_PATH.AA_MAIN_MENU.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', id);
    return Config.config2_url + mainMenuApiPath;
};

export const fetchMainMenu = (data) => (dispatch) => {
    const mainMenuApiUrl = getMainMenuUrl(data.id);
    const header = { params: { numRows: 99 } };
    const fetchMainMenuReq = get(mainMenuApiUrl, header);

    return fetchMainMenuReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_AA_MAIN_MENU_SUCCESS, payload: resp.result });
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on fetchMainMenu', err);
            dispatch({ type: Actions.FETCH_AA_MAIN_MENU_FAILED });
            return { err };
        });
};

export const fetchGreetingWithMainMenu = () => (dispatch) => {
    dispatch({ type: Actions.FETCH_AA_MAIN_MENU_STARTED });
    dispatch(fetchMainGreeting()).then((resp) => {
        dispatch(fetchMainMenu(resp));
    });
};

export const setMainMenuChanges = (payload) => ({
    type: Actions.SET_AA_MENU_ITEM_CHANGES,
    payload,
});

export const addMainMenuItem = () => ({
    type: Actions.ADD_AA_MENU_ITEM,
});

export const removeMenuItem = (payload) => ({
    type: Actions.REMOVE_AA_MENU_ITEM_SUCCESS,
    payload,
});

export const saveMainMenu = (data) => (dispatch) => {
    const mainMenuApiUrl = getMainMenuUrl(data.id);
    dispatch({ type: Actions.SAVE_AA_MAIN_MENU_STARTED });
    const saveMainMenuReq = post(mainMenuApiUrl, data.mainMenuItems);

    return saveMainMenuReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_AA_MAIN_MENU_SUCCESS });
            dispatch(displayToast({ text: 'Main menu saved', type: 'success' }));
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on saveMainMenuReq', err);
            const errMsg =
                err.response.data && err.response.data.title ? err.response.data.title : 'Main menu save failed';
            dispatch({ type: Actions.SAVE_AA_MAIN_MENU_FAILED });
            dispatch(displayToast({ text: errMsg, type: 'error' }));
            return { err };
        });
};

export const deleteMenuItem = (payload) => (dispatch) => {
    let mainMenuApiUrl = getMainMenuUrl(payload.id);
    mainMenuApiUrl = `${mainMenuApiUrl}/${payload.menuItem.id}`;
    dispatch({ type: Actions.REMOVE_AA_MENU_ITEM_STARTED, payload });
    const removeGroupReq = deleteReq(mainMenuApiUrl);

    return removeGroupReq
        .then((data) => {
            dispatch({ type: Actions.REMOVE_AA_MENU_ITEM_SUCCESS, payload });
            dispatch(displayToast({ text: 'Menu item removed', type: 'success' }));
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on deleteMenuItem', err);
            dispatch({ type: Actions.REMOVE_AA_MENU_ITEM_FAILED, payload });
            dispatch(displayToast({ text: 'Menu item remove failed', type: 'error' }));
            return { err };
        });
};
