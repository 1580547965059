import React, { useState, Fragment } from 'react';
import { Cell, CircularProgress } from 'react-md';

import DescriptionView from 'components/DigitalMarketingDashboard/DescriptionView';
import { DescriptionLabel } from 'constants/DMDashboard';
import { AdsPreviews } from 'components/DigitalMarketingDashboard/AdsPreviews';

const Ads = (props) => {
    const { adsDescription, previews, inboundLeads, tooltipTextMap, campaignLoading, selectedLocalLocation } = props;
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    return (
        <div className="grid-container">
            {!campaignLoading && Object.keys(previews).length < 1 && Object.keys(adsDescription).length < 1 ? (
                <Cell size={12} className="flex-center flex-middle" style={{ height: '200px' }}>
                    No Data Found
                </Cell>
            ) : (
                <Fragment>
                    <Cell size={8}>
                        {campaignLoading ? (
                            <div style={{ height: '100%' }} className="flex-middle">
                                <CircularProgress id="image-spinner" />
                            </div>
                        ) : Object.keys(previews).length === 0 ? (
                            <div style={{ height: '100%' }} className="flex-center flex-middle">
                                No Ads Preview available
                            </div>
                        ) : (
                            <AdsPreviews
                                previews={previews}
                                activeItemIndex={activeItemIndex}
                                setActiveItemIndex={setActiveItemIndex}
                            />
                        )}
                    </Cell>
                    <Cell size={4} className="description-wrapper">
                        {Object.keys(adsDescription).length === 0 ? (
                            <div style={{ height: '100%', minHeight: '250px' }} className="flex-center flex-middle">
                                No Description available
                            </div>
                        ) : (
                            <Fragment>
                                <div className="flex-middle">
                                    <div className="md-font-medium heading">Description</div>
                                    {selectedLocalLocation === 'allLocations' && (
                                        <div className="standard-margin-left-10 info-note">
                                            Select location to get ‘calls from ads’ details
                                        </div>
                                    )}
                                </div>
                                {Object.keys(adsDescription).map((key, idx) => (
                                    <DescriptionView
                                        key={idx}
                                        isTitle={true}
                                        descName={DescriptionLabel[key]}
                                        descVal={adsDescription[key]}
                                        tooltipText={tooltipTextMap[key]}
                                    />
                                ))}

                                {selectedLocalLocation !== 'allLocations' && (
                                    <DescriptionView
                                        descName={DescriptionLabel['inbound_calls']}
                                        tooltipText={tooltipTextMap['inbound_calls']}
                                        descVal={inboundLeads}
                                        isTitle={true}
                                    />
                                )}
                            </Fragment>
                        )}
                    </Cell>
                </Fragment>
            )}
        </div>
    );
};

export default Ads;
