import './TemplatesManagementPage.sass';
import React, { useState, useRef } from 'react';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { List } from 'react-virtualized';
import _ from 'lodash';
import TemplateManagementRow from './TemplateManagementRow';
import EditTemplateDialog from '../EditTemplateDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { updateQueryParameters } from 'utils/urlHelper';
import numeral from 'numeral';
import pluralize from 'pluralize';
import ResetPatientTargetingButton from './ResetPatientTargetingButton';

const UNFOCUSED_ROW_HEIGHT = 146;

const CAMERONS_ACH_LOCATION_UUID = '1ca573c9-36d8-42c3-b9bb-730c8c050d42';

function shouldShowResetTargetedOpportunitiesButton(location, pharmacy) {
    return pharmacy.activeLocationId === CAMERONS_ACH_LOCATION_UUID && location.pathname.endsWith('targeted');
}

export default function TemplatesManagementPage({ templates, canCreate, location, history, query, pharmacy }) {
    const props = arguments[0];

    const listRef = useRef();
    const [focusedUuid, setFocusedUuid] = useState(null);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [addingTemplate, setAddingTemplate] = useState(false);
    const [, setDeletingTemplate] = useState(null);

    const listWidth = Math.min(1200, window.innerWidth) - 321;
    const maxListHeight = window.innerHeight - 280;

    const rowsHeight =
        (_.size(templates) - 1) * UNFOCUSED_ROW_HEIGHT + _.get(focusedUuid, 'height', UNFOCUSED_ROW_HEIGHT);

    const onSearchChange = _.debounce((v) => {
        history.push(`${location.pathname}${updateQueryParameters(location, 'q', _.isEmpty(v) ? null : v)}`);
    }, 300);

    return (
        <div className="templates-management-page">
            <div className="templates-heading">
                <span className="search-and-count">
                    <TextField
                        className="search-text-field"
                        style={{ width: '350px' }}
                        defaultValue={_.isString(query) ? query : ''}
                        placeholder="Search workflows..."
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fontSize: '30px' }} />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            onSearchChange(e.target.value.trim());
                        }}
                    />

                    <span className="count">
                        {numeral(_.size(templates)).format('0,0')} {pluralize('workflow', _.size(templates))}
                    </span>
                </span>

                {!shouldShowResetTargetedOpportunitiesButton(location, pharmacy) ? (
                    <div />
                ) : (
                    <ResetPatientTargetingButton />
                )}
            </div>
            <div className="templates-table">
                <List
                    className="templates-list"
                    ref={listRef}
                    estimatedRowSize={UNFOCUSED_ROW_HEIGHT}
                    rowHeight={({ index }) => {
                        const rowData = templates[index];
                        if (rowData.inbox_conversation_template_id === _.get(focusedUuid, 'uuid')) {
                            return focusedUuid.height;
                        }
                        return UNFOCUSED_ROW_HEIGHT;
                    }}
                    noRowsRenderer={() => {
                        return (
                            <div className="no-rows">
                                <div>No templates matching your query.</div>
                            </div>
                        );
                    }}
                    height={_.isEmpty(templates) ? 200 : Math.min(maxListHeight, rowsHeight)}
                    width={listWidth}
                    rowCount={_.size(templates)}
                    rowRenderer={({ index, style, key }) => {
                        const rowData = templates[index];
                        return (
                            <div key={key} style={style}>
                                <TemplateManagementRow
                                    isOddRow={index % 2 === 1}
                                    template={rowData}
                                    focused={_.get(focusedUuid, 'uuid') === rowData.inbox_conversation_template_id}
                                    onSetFocused={(height) => {
                                        // don't redo this if we are already focused since this causing subpixel rounding weirdness sometimes
                                        // if you click it multiple times
                                        if (_.get(focusedUuid, 'uuid') === rowData.inbox_conversation_template_id) {
                                            return;
                                        }
                                        setFocusedUuid({ uuid: rowData.inbox_conversation_template_id, height, index });

                                        if (_.isFinite(_.get(focusedUuid, 'index'))) {
                                            listRef.current.recomputeRowHeights(_.get(focusedUuid, 'index'));
                                        }
                                        listRef.current.recomputeRowHeights(index);
                                    }}
                                    onEdit={(template) => setEditingTemplate(template)}
                                    deletable={
                                        rowData.template_type === 'managed' && _.isNil(rowData.parent_template_id)
                                    }
                                    onDelete={(template) => setDeletingTemplate(template)}
                                />
                            </div>
                        );
                    }}
                />

                {!canCreate ? null : (
                    <div className="create-action-line">
                        <Button variant="outlined" onClick={() => setAddingTemplate(true)}>
                            <AddCircleOutlineIcon />
                            &nbsp;Add new Standard Workflow
                        </Button>
                    </div>
                )}
            </div>

            {_.isNil(editingTemplate) ? null : (
                <EditTemplateDialog
                    {...props}
                    open={true}
                    onClose={() => setEditingTemplate(null)}
                    editingTemplate={editingTemplate}
                />
            )}

            {!addingTemplate ? null : (
                <EditTemplateDialog open={true} isCreateWorkflow onClose={() => setAddingTemplate(false)} />
            )}
        </div>
    );
}
