import './WeeklyScheduleConfiguration.sass';
import React from 'react';
import { Switch, IconButton, Select, MenuItem, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from 'lodash';
import HourAndMinuteSelect from './HourAndMinuteSelect';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

export default function WeeklyScheduleConfiguration({ configuration, onChange, maxSupportedCapacity = 10 }) {
    if (_.isNil(configuration)) {
        return null;
    }

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    // will look backwards to find the first previous day that has configuration
    const findNonemptyPreviousDayHours = (index) => {
        let i = index - 1;
        while (i >= 0) {
            const day = days[i];
            if (!_.isEmpty(configuration[day])) {
                return configuration[day];
            }
            i--;
        }
    };

    return (
        <div className="weekly-schedule-configuration">
            <div className="week-days-configuration" style={{ maxHeight: `${window.innerHeight - 400}px` }}>
                {_.map(days, (day, i) => {
                    return (
                        <WeekRow
                            key={day}
                            day={day}
                            odd={i % 2 === 1}
                            hours={configuration[day]}
                            previousDayHours={i === 0 ? null : findNonemptyPreviousDayHours(i)}
                            onChange={(hours) => {
                                onChange({
                                    ...configuration,
                                    [day]: hours,
                                });
                            }}
                            maxSupportedCapacity={maxSupportedCapacity}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function validateHours(hours) {
    const zeroLengthBlock = !_.isNil(
        _.find(hours, ({ startHour, startMinute, endHour, endMinute }) => {
            if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
                return true;
            }
        })
    );

    if (zeroLengthBlock) {
        return false;
    }

    if (_.size(hours) > 1) {
        let last = hours[0];
        const overlappingBlocks = !_.isNil(
            _.find(_.tail(hours), ({ startHour, startMinute, endHour, endMinute }) => {
                let overlaps = false;
                if (startHour < last.endHour || (startHour === last.endHour && startMinute < last.endMinute)) {
                    overlaps = true;
                }

                last = { endHour, endMinute };

                return overlaps;
            })
        );

        if (overlappingBlocks) {
            return false;
        }
    }

    return true;
}

const GreenSwitch = withStyles({
    switchBase: {
        color: '#E8F8F5',
        '&$checked': {
            color: '#00B18F',
        },
        '&$checked + $track': {
            backgroundColor: '#00B18F',
        },
    },
    checked: {},
    track: {},
})(Switch);

function WeekRow({ day, odd, hours, onChange, previousDayHours, maxSupportedCapacity }) {
    const enabled = !_.isEmpty(hours);

    const validHours = validateHours(hours);

    return (
        <div
            className="week-row"
            data-cy="week-row"
            style={{ background: !validHours ? '#fadcdc' : odd ? '#fafafa' : '#fff' }}
        >
            <GreenSwitch
                data-cy="week-day-switch"
                checked={enabled}
                onChange={() => {
                    if (enabled) {
                        onChange([]);
                    } else {
                        // since the common pattern is setting the same schedule on each days, we will replicate the
                        // previous days hours if you toggle a subsequent day on (otherwise we will just set it from 9am-5pm)
                        onChange(
                            _.isEmpty(previousDayHours)
                                ? [
                                      {
                                          startHour: 9,
                                          startMinute: 0,
                                          endHour: 17,
                                          endMinute: 0,
                                          capacity: 1,
                                      },
                                  ]
                                : _.cloneDeep(previousDayHours)
                        );
                    }
                }}
            />
            <div className="day">{day}</div>
            {!enabled ? (
                <div className="row-content empty">No appointments on this day.</div>
            ) : (
                <div className="row-content">
                    <IconButton
                        className="add-block-button"
                        data-cy="add-block-button"
                        onClick={() => {
                            const newHours = _.cloneDeep(hours);

                            newHours.push({
                                startHour: _.get(_.last(hours), 'endHour', 9),
                                startMinute: _.get(_.last(hours), 'endMinute', 0),
                                endHour: _.get(_.last(hours), 'endHour', 16) + 1,
                                endMinute: _.get(_.last(hours), 'endMinute', 0),
                                capacity: _.get(_.last(hours), 'capacity', 1),
                            });

                            onChange(newHours);
                        }}
                    >
                        <AddCircleIcon />
                    </IconButton>

                    <Tooltip
                        disableHoverListener={validHours}
                        disableFocusListener={validHours}
                        disableTouchListener={validHours}
                        title={
                            validHours
                                ? ''
                                : "Please ensure that the configured hours start before they end and if there is more than one, make sure they don't overlap"
                        }
                    >
                        <div className="hour-blocks">
                            {_.map(hours, ({ startHour, startMinute, endHour, endMinute, capacity }, i) => {
                                return (
                                    <div className="hour-block" key={i}>
                                        <HourAndMinuteSelect
                                            hour={startHour}
                                            minute={startMinute}
                                            onChange={({ hour, minute }) => {
                                                const newHours = _.clone(hours);
                                                newHours[i].startHour = hour;
                                                newHours[i].startMinute = minute;
                                                onChange(newHours);
                                            }}
                                        />

                                        <span className="label">To</span>

                                        <HourAndMinuteSelect
                                            hour={endHour}
                                            minute={endMinute}
                                            onChange={({ hour, minute }) => {
                                                const newHours = _.clone(hours);
                                                newHours[i].endHour = hour;
                                                newHours[i].endMinute = minute;
                                                onChange(newHours);
                                            }}
                                        />

                                        <Select
                                            className="capacity-select"
                                            data-cy="capacity-select"
                                            variant="outlined"
                                            value={capacity}
                                            onChange={(e) => {
                                                const newHours = _.clone(hours);
                                                newHours[i].capacity = e.target.value;
                                                onChange(newHours);
                                            }}
                                        >
                                            {_.map(_.times(maxSupportedCapacity), (i) => {
                                                return (
                                                    <MenuItem key={i + 1} value={i + 1}>
                                                        {i + 1} appt{i === 0 ? '' : 's'} at a time
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>

                                        {_.size(hours) === 1 ? (
                                            <span className="empty-icon-spacer" />
                                        ) : (
                                            <IconButton
                                                className="delete-button"
                                                data-cy="delete-button"
                                                onClick={() => {
                                                    const newHours = _.clone(hours);
                                                    newHours.splice(i, 1);
                                                    onChange(newHours);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
}
