const subjectTypePrefix = 'General Settings(GS)';

export const restrictedGeneralSettingResources = {
    generalSettings: {
        subject: subjectTypePrefix,
        accountDetails: {
            subject: `${subjectTypePrefix} - Account Details`,
            fields: {
                name: 'Name',
                email: 'Email',
                changePassword: 'Change Password',
            },
        },
        pharmacyDetails: {
            subject: `${subjectTypePrefix} - Pharmacy Details`,
            fields: {},
        },
        refillDetails: {
            subject: `${subjectTypePrefix} - Refill Details`,
            fields: {},
        },
        language: {
            subject: `${subjectTypePrefix} - Language`,
            fields: {},
        },
        featuresAndFunctions: {
            subject: `${subjectTypePrefix} - Features And Function`,
            fields: {},
        },
        bannerConfiguration: {
            subject: `${subjectTypePrefix} - Website Banner Configuration`,
            fields: {},
        },
        notifications: {
            subject: `${subjectTypePrefix} -  Notifications`,
            fields: {},
        },
        userManagement: {
            subject: `${subjectTypePrefix} - User Management`,
            fields: {
                internalUserToggle: 'Internal User Toggle',
                addNewUser: 'Add New User',
                editUser: 'Edit User',
                deleteUser: 'Delete User',
                viewUserLogs: 'View User Logs',
                resendInvite: 'Resend Invite',
            },
        },
    },
};
