import { homeTypes } from './type';
import { homeService } from './service';
import { displayToast } from 'redux/actionCreators/Snackbar';

const resetHomeStore = () => (dispatch) => {
    return dispatch({ type: homeTypes.RESET_HOME_STORE });
};

const getRefillCount = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_REFILL_COUNT_REQUEST });

    return homeService
        .getRefillCount(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_REFILL_COUNT_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_REFILL_COUNT_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const getPatientTransfers = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_PATIENT_TRANSFERS_REQUEST });

    return homeService
        .getPatientTransfers(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_PATIENT_TRANSFERS_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_PATIENT_TRANSFERS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const getMessagesSent = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_MESSAGES_SENT_REQUEST });

    return homeService
        .getMessagesSent(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_MESSAGES_SENT_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_MESSAGES_SENT_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const getPatientsWithBirthday = () => (dispatch) => {
    dispatch({ type: homeTypes.GET_PATIENTS_WITH_BIRTHDAY_REQUEST });

    return homeService
        .getPatientsWithBirthday()
        .then((response) => {
            dispatch({
                type: homeTypes.GET_PATIENTS_WITH_BIRTHDAY_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_PATIENTS_WITH_BIRTHDAY_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const getUnreadVoicemail = () => (dispatch) => {
    dispatch({ type: homeTypes.GET_UNREAD_VOICEMAILS_REQUEST });

    return homeService
        .getUnreadVoicemail()
        .then((response) => {
            dispatch({
                type: homeTypes.GET_UNREAD_VOICEMAILS_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_UNREAD_VOICEMAILS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const getUnreadMessagesCount = () => (dispatch) => {
    dispatch({ type: homeTypes.GET_UNREAD_MESSAGES_REQUEST });

    return homeService
        .getUnreadMessagesCount()
        .then((response) => {
            dispatch({
                type: homeTypes.GET_UNREAD_MESSAGES_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_UNREAD_MESSAGES_FAILED,
                payload: {
                    error,
                },
            });
        });
};

// TODO: leaving untouched for now but the leads & callrails methods should be removed and use the leads store/actions
const getInternalLeads = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_INTERNAL_LEADS_REQUEST });

    return homeService
        .getInternalLeads(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_INTERNAL_LEAD_COUNT_SUCCESS,
                payload: {
                    data: response,
                },
            });
            return {
                data: response,
            };
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_INTERNAL_LEAD_COUNT_FAILED,
                payload: {
                    error,
                },
            });
            return {
                error,
            };
        });
};

const getCallrailLeads = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_CALLRAIL_LEADS_REQUEST });

    return homeService
        .getCallrailLeads(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_CALLRAIL_LEAD_SUCCESS,
                payload: {
                    data: response,
                },
            });
            return {
                data: response,
            };
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_CALLRAIL_LEAD_FAILED,
                payload: {
                    error,
                },
            });
            return {
                error,
            };
        });
};

const getLeadStatus = (payload) => (dispatch) => {
    dispatch({ type: homeTypes.GET_LEADS_STATUS_REQUEST });

    return homeService
        .getLeadStatus(payload)
        .then((response) => {
            dispatch({
                type: homeTypes.GET_LEADS_STATUS_SUCCESS,
                payload: {
                    data: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: homeTypes.GET_LEADS_STATUS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const sendQuickReply = (payload) => (dispatch) => {
    const { conversationId, userId, messageContent } = payload;
    dispatch({ type: homeTypes.SEND_QUICK_REPLY_REQUEST });

    return homeService.sendQuickReply(conversationId, userId, messageContent.trim()).then(
        (response) => {
            dispatch({ type: homeTypes.SEND_QUICK_REPLY_SUCCESS });
            return {
                data: response,
            };
        },
        (error) => {
            dispatch({
                type: homeTypes.SEND_QUICK_REPLY_FAILED,
                payload: {
                    error,
                },
            });
            dispatch(displayToast({ text: 'Error while sending quick reply!!', type: 'error' }));
        }
    );
};

const getConversations = (userId) => (dispatch) => {
    dispatch({ type: homeTypes.GET_CONVERSATIONS_REQUEST });

    return homeService.getConversations(userId).then(
        (response) => {
            dispatch({ type: homeTypes.GET_CONVERSATIONS_SUCCESS });
            return {
                data: response,
            };
        },
        (error) => {
            dispatch({
                type: homeTypes.GET_CONVERSATIONS_FAILURE,
                payload: {
                    error,
                },
            });
        }
    );
};

export const homeActions = {
    resetHomeStore,
    getRefillCount,
    getPatientTransfers,
    getMessagesSent,
    getPatientsWithBirthday,
    getUnreadVoicemail,
    getUnreadMessagesCount,
    getInternalLeads,
    getCallrailLeads,
    getLeadStatus,
    sendQuickReply,
    getConversations,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
