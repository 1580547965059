import './FilteringDropdown.sass';
import React, { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Popover, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import { updateQueryParameters } from '../../../utils/urlHelper';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function FilterDropdown({
    label,
    activeLabel,
    param,
    children,
    history,
    location,
    onAdd,
    onClear,
    onClose,
    initiallyOpen,
}) {
    const [open, setOpen] = useState(false);
    const mainRef = useRef();
    const active = !_.isNil(activeLabel);

    const showAdd = active && _.isFunction(onAdd);

    useEffect(() => {
        if (initiallyOpen) {
            setOpen(true);
        }
    }, []);

    return (
        <span ref={mainRef} className={classnames({ 'filtering-dropdown': true, active })}>
            <Button className="filtering-dropdown-button" variant="outlined" onClick={() => setOpen(true)}>
                {!showAdd ? null : (
                    <Tooltip title={`Add another ${label} filter`}>
                        <AddCircleIcon
                            className="add-icon"
                            onClick={(e) => {
                                onAdd();
                                e.stopPropagation();
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                        />
                    </Tooltip>
                )}
                <span className="label">{active ? activeLabel : label}</span>
                {active ? (
                    <Tooltip title="Remove this filter">
                        <CloseIcon
                            className="close-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (_.isFunction(onClear)) {
                                    onClear();
                                } else {
                                    history.push(`${location.pathname}${updateQueryParameters(location, param, null)}`);
                                }
                            }}
                        />
                    </Tooltip>
                ) : (
                    <ArrowDropDownIcon className="dropdown-icon" />
                )}
            </Button>

            <Popover
                open={open}
                anchorEl={mainRef.current}
                onClose={() => {
                    setOpen(false);
                    if (_.isFunction(onClose)) {
                        onClose();
                    }
                }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right' }}
            >
                <div className="filtering-dropdown-popover">
                    <div className="popover-heading">
                        <span className="label">{label}</span>
                        {!active ? null : (
                            <Button
                                variant="text"
                                onClick={() => {
                                    if (_.isFunction(onClear)) {
                                        onClear();
                                    } else {
                                        history.push(
                                            `${location.pathname}${updateQueryParameters(location, param, null)}`
                                        );
                                    }
                                }}
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                    <div className="popover-content">
                        {React.cloneElement(children, { ...children.props, setOpen: setOpen })}
                    </div>
                </div>
            </Popover>
        </span>
    );
}
