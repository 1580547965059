import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const ColumnTitle = ({ title, isSortable, sortBy, onClick, className, style = {} }) => {
    const isColumnSorted = isSortable && title === sortBy.column;
    const handleClick = () => isSortable && onClick({ column: title, ascendingOrder: !sortBy.ascendingOrder });
    const getArrow = () =>
        sortBy.ascendingOrder ? (
            <ArrowUpwardIcon fontSize="inherit" className="sort-arrow" />
        ) : (
            <ArrowDownwardIcon fontSize="inherit" className="sort-arrow" />
        );

    return (
        <span className={className} onClick={handleClick} style={style}>
            <span>{title}</span>
            {isColumnSorted ? getArrow() : null}
        </span>
    );
};

export default ColumnTitle;
