import './PhoneTypeLookupPane.sass';
import React, { useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import { connect } from 'react-redux';
import { bulkPhoneTypeLookup } from '../../redux/Patient/PatientData/action';

function PhoneTypeLookupPane({ uncheckedPatients, bulkPhoneTypeLookup }) {
    const uncheckedCount = _.size(uncheckedPatients);

    // sort these as well so they are easy to jsonify check in useEffect
    const uncheckedInboxUserIds = _.sortBy(_.map(uncheckedPatients, ({ inbox_user_id }) => inbox_user_id));

    useEffect(() => {
        bulkPhoneTypeLookup(uncheckedInboxUserIds);
    }, [JSON.stringify(uncheckedInboxUserIds)]);

    return (
        <div className="phone-type-lookup-pane">
            <div className="phone-type-lookup-pane-inner">
                <PhonelinkRingIcon className="lookup-icon" />
                <div className="content">
                    <LinearProgress />
                    <div className="main-text-line">
                        Checking if {numeral(uncheckedCount).format('0,0')} patient phone{' '}
                        {pluralize('number', uncheckedCount)} can receive messages...
                    </div>
                    <div className="subtitle-line">
                        This is only done the first time you try to message a patient <br />
                        or when you change their phone number.
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { patientData, connect, inboxUser } = state;

    return {
        patientData,
        connect,
        inboxUser,
    };
}

const bindActionsToDispatch = {
    bulkPhoneTypeLookup,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PhoneTypeLookupPane);
