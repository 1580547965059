import { get, post, put } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logHttpError } from '../../../utils/log';

const createAttachment = (conversationId, userId, selectedFile) => {
    const attachmentData = new FormData();
    attachmentData.append('attachment', selectedFile, selectedFile.name);
    attachmentData.append('inbox_user_id', userId);
    const requestConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    return post(
        `${config.apiweb_url}${api.INBOX_BASE}/conversation/${conversationId}/message/attachment`,
        attachmentData,
        requestConfig
    )
        .then((response) => {
            if (response.success) {
                return true;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on createAttachment for user: ${userId} in conversationId: ${conversationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getMessages = (conversationId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/conversation/${conversationId}/messages`)
        .then((response) => {
            if (response.success) {
                return response.messages;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(`An error occured on getMessages in conversationId: ${conversationId}`, error);
            return Promise.reject(error);
        });
};

const createMessage = (conversationId, userId, content) => {
    const requestBody = {
        inbox_user_id: userId,
        content,
    };
    return post(`${config.apiweb_url}${api.INBOX_BASE}/conversation/${conversationId}/message`, requestBody)
        .then((response) => {
            // TODO need backend to change message_id to inbox_message_id
            if (response.success && response.message_id) {
                return response.message_id;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on createMessage for user: ${userId} in conversationId: ${conversationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const createMessageAppointment = (conversationId, userId, appt_type_id, action_title, scheduled_appointment_text) => {
    const requestBody = {
        inbox_user_id: userId,
        inbox_conversation_id: conversationId,
        appt_type_id,
        action_title,
        scheduled_appointment_text,
    };
    return post(`${config.apiweb_url}${api.INBOX_BASE}/conversation/${conversationId}/message/appointment`, requestBody)
        .then((response) => {
            if (response.success && response.message_id) {
                return response.message_id;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on createMessageAppointment for user: ${userId} in conversationId: ${conversationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getLatestMessage = (conversationId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/conversation/${conversationId}/message/latest`)
        .then((response) => {
            if (response.success && response.latest_message) {
                return response.latest_message;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(`An error occured on getLatestMessage in conversationId: ${conversationId}`, error);
            return Promise.reject(error);
        });
};

const updateMessageReadStatus = ({ pharmacyId, locationId, conversationId }) => {
    return put(
        `${config.apiweb_url}${api.INBOX_BASE}/message/pharmacies/${pharmacyId}/locations/${locationId}/conversation/${conversationId}/mark-messages-read`,
        {
            user_type: 'patient',
            inbox_conversation_id: conversationId,
        }
    )
        .then((response) => {
            if (response.success) {
                return true;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(`An error occured on updateMessageReadStatus in conversationId: ${conversationId}`, error);
            return Promise.reject(error);
        });
};

export const messageService = {
    getMessages,
    createMessage,
    createMessageAppointment,
    getLatestMessage,
    updateMessageReadStatus,
    createAttachment,
};

export default messageService;
