import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { userAction } from 'redux/Inbox/User';
import NewContactDialog from './NewContactDialog';
import { IconButton, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Snowplow, { SnowplowConstants } from '../../snowplow';
import { Can, userActions, restrictedResources } from 'casl';
import BulkPatientUploadDialog from '../Patients/csv/BulkPatientUploadDialog';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableAutoFocusItem
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const SNOWPLOW_CATEGORY = 'Add Patient Menu';

class AddPatientMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showNewContactModal: false,
            showCsvUploadModal: false,
        };
    }

    componentDidMount() {
        const { vmPhone } = this.props;
        if (vmPhone) {
            this.handleNewContactModalOpen();
        }
    }

    handleAddPatientMenuOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });

        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'menu', null, SnowplowConstants.closed);
    };

    handleNewContactModalOpen = () => {
        this.setState({
            showNewContactModal: true,
        });

        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'add-new-contact-modal', null, SnowplowConstants.opened);
    };

    handleCsvUploadModalOpen = () => {
        this.setState({
            showCsvUploadModal: true,
        });

        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'csv-upload-modal', null, SnowplowConstants.opened);
    };

    handleNewContactModalClose = () => {
        this.setState({
            showNewContactModal: false,
        });

        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'add-new-contact-modal', null, SnowplowConstants.closed);
    };

    handleCsvUploadModalClose = () => {
        this.setState({
            showCsvUploadModal: false,
        });

        Snowplow.structEvent(SNOWPLOW_CATEGORY, 'csv-upload-modal', null, SnowplowConstants.closed);
    };

    render() {
        const { anchorEl, showNewContactModal, showCsvUploadModal } = this.state;
        const { bulkUploadEnabled, fullButton, allowPepPatientEditing, vmPhone } = this.props;
        const { create } = userActions;
        const {
            patient: { subject: patient },
        } = restrictedResources.inbox;
        const addButtonTooltip = allowPepPatientEditing ? 'Add patients' : 'Patients must be added in your PMS';

        return (
            <Can I={create} a={patient} passThrough>
                {(allowed) => (
                    <div>
                        {bulkUploadEnabled ? (
                            <Fragment>
                                {!fullButton ? (
                                    <Tooltip
                                        title={addButtonTooltip}
                                        aria-label={addButtonTooltip}
                                        disableFocusListener
                                    >
                                        <div>
                                            <IconButton
                                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleAddPatientMenuOpen}
                                                color="inherit"
                                                style={{ color: `${allowed} ? #4a4a4a: #001D50` }}
                                                id="add-inbox-patient-btn"
                                                disabled={!allowed || !allowPepPatientEditing}
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={addButtonTooltip}
                                        aria-label={addButtonTooltip}
                                        disableFocusListener
                                    >
                                        <div>
                                            <Button
                                                variant="text"
                                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleAddPatientMenuOpen}
                                                color="inherit"
                                                style={{ color: `${allowed} ? #4a4a4a: #001D50` }}
                                                id="add-inbox-patient-btn"
                                                disabled={!allowed || !allowPepPatientEditing}
                                            >
                                                <AddCircleOutlineIcon
                                                    disabled={!allowed || !allowPepPatientEditing}
                                                    style={{
                                                        color: allowPepPatientEditing ? '#4a4a4a' : '',
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                Add new patients
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}

                                <StyledMenu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleMenuClose}
                                >
                                    <MenuItem onClick={this.handleNewContactModalOpen} onBlur={this.handleMenuClose}>
                                        <ListItemIcon>
                                            <i className="material-icons">person_add</i>
                                        </ListItemIcon>
                                        <ListItemText primary="Add a new patient" />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handleCsvUploadModalOpen}
                                        onBlur={this.handleMenuClose}
                                        id="csv-menu"
                                    >
                                        <ListItemIcon>
                                            <i className="material-icons">cloud_download</i>
                                        </ListItemIcon>
                                        <ListItemText primary="Upload a csv" />
                                    </MenuItem>
                                </StyledMenu>
                            </Fragment>
                        ) : (
                            <div>
                                <Tooltip title="Add patients" aria-label="Add patients">
                                    <IconButton
                                        aria-haspopup="true"
                                        onClick={this.handleNewContactModalOpen}
                                        color="inherit"
                                        style={{ color: `${allowed} ? #4a4a4a: #001D50` }}
                                        id="add-inbox-patient-btn"
                                        disabled={!allowed}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        <Fragment>
                            <NewContactDialog
                                showNewContactModal={showNewContactModal}
                                vmPhone={vmPhone}
                                handleNewContactModalClose={this.handleNewContactModalClose}
                            />

                            <BulkPatientUploadDialog
                                open={showCsvUploadModal}
                                onClose={this.handleCsvUploadModalClose}
                            />
                        </Fragment>
                    </div>
                )}
            </Can>
        );
    }
}

function mapStateToProps(state) {
    const { inboxUser, pharmacy } = state;

    return {
        user: inboxUser,
        bulkUploadEnabled: pharmacy.bulkUploadEnabled,
        allowPepPatientEditing: pharmacy.allowPepPatientEditing,
    };
}

const bindActionsToDispatch = {
    createUser: userAction.createUser,
    clearCreateUserMessage: userAction.clearCreateUserMessage,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AddPatientMenu);
