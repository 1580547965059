import Actions from 'redux/actions';
import doctorGreeting from 'constants/DoctorsLine';

const initialState = {
    newDoctorGreeting: {},
    showDoctorGreetingCreator: false,
};

const addDoctorLine = (state) => {
    const newDoctorGreeting = { ...doctorGreeting };
    return {
        ...state,
        showDoctorGreetingCreator: true,
        newDoctorGreeting,
    };
};

const updateDoctorGreetingMsg = (state, payload) => {
    const newDoctorGreeting = { ...state.newDoctorGreeting };
    if (payload.id) {
        const greetingFiles = [...payload.greetingFiles];
        const selectedGreetingFile = greetingFiles.find((file) => file.id === payload.id);
        newDoctorGreeting.dr_greeting_id = selectedGreetingFile.id;
        newDoctorGreeting.dr_greeting_url = selectedGreetingFile.publicURL;
    } else {
        newDoctorGreeting.dr_greeting_id = '';
        newDoctorGreeting.dr_greeting_url = '';
    }
    return {
        ...state,
        newDoctorGreeting,
    };
};

const editDoctorGreeting = (state, payload) => ({
    ...state,
    showDoctorGreetingCreator: true,
    newDoctorGreeting: { ...payload },
});

const changeGreetingType = (state, payload) => {
    const newDoctorGreeting = { ...state.newDoctorGreeting };
    newDoctorGreeting.dr_greeting_type_id = payload;

    return {
        ...state,
        newDoctorGreeting,
    };
};

const changeGreetingScript = (state, payload) => {
    const newDoctorGreeting = { ...state.newDoctorGreeting };
    newDoctorGreeting.dr_greeting_text = payload;
    return {
        ...state,
        newDoctorGreeting,
    };
};

export default function DoctorsLineReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.ADD_DOCTOR_GREETING:
            return addDoctorLine(state);
        case Actions.UPDATE_DOCTOR_GREETING:
            return updateDoctorGreetingMsg(state, payload);
        case Actions.CLOSE_DOCTOR_GREETING_EDITOR:
            return { ...state, showDoctorGreetingCreator: false, newDoctorGreeting: {} };
        case Actions.EDIT_DOCTOR_GREETING:
            return editDoctorGreeting(state, payload);
        case Actions.CHANGE_DOCTOR_GREETING_TYPE:
            return changeGreetingType(state, payload);
        case Actions.CHANGE_DOCTOR_GREETING_SCRIPT:
            return changeGreetingScript(state, payload);
        default:
            return state;
    }
}
