import _ from 'lodash';
import { onboardingTypes } from './type';

export const initialState = {
    loading: false,
    location_parent_event_ref: null,
    location_event_ref: null,
    locationOnboarding: null,
    // locationOnboarding shape when present
    // {
    //     first_template_created_at: null,
    //     first_message_sent_at: null,
    //     patient_count: 0,
    //     message_count: 0,
    // },
    locationClientSteps: {
        hasLoggedIn: true,
        notificationPermission: null,
    },
    error: null,
};

export const onboardingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case onboardingTypes.RESET_INBOX_LOCATION_ONBOARDING:
            return {
                ...initialState,
            };

        case onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_SUCCESS:
            const { event_ref, onboarding } = payload;
            return {
                ...state,
                loading: false,
                // maintain existing parent event ref actions should be
                // associated with the initial event in this session as the 'parent'
                location_parent_event_ref: state.location_parent_event_ref || event_ref,
                location_event_ref: event_ref,
                locationOnboarding: onboarding,
                error: null,
            };

        case onboardingTypes.GET_INBOX_LOCATION_ONBOARDING_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };

        case onboardingTypes.UPDATE_INBOX_LOCATION_ONBOARDING_CLIENT_STEPS:
            return {
                ...state,
                locationClientSteps: {
                    ...state.locationClientSteps,
                    ...payload,
                },
            };

        case onboardingTypes.UPDATE_INBOX_LOCATION_ONBOARDING_SERVER_STEPS:
            const { new_patient_count } = payload;
            return {
                ...state,
                locationOnboarding: {
                    ...state.locationOnboarding,
                    patient_count: _.add(state.locationOnboarding.patient_count, new_patient_count),
                },
            };

        default:
            return state;
    }
};
