import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';

export const Toolbar = ({
    className,
    brandedAppAdditionDisabled,
    nonBrandedAppAdditionDisabled,
    onAddBrandedAppsButtonClick,
    onAddNonBrandedAppButtonClick,
    pharmacyHasMissingInfo,
    isLoading,
}) => {
    const commonProps = {
        variant: 'outlined',
        color: 'primary',
    };

    const tooltipText = {
        nonBrandedApp: 'All the non branded apps have been added.',
        brandedApp: pharmacyHasMissingInfo
            ? 'Pharmacy is not fully configured. Please using Config2 UI to add the missing data.'
            : 'Cannot add more than one branded app.',
    };

    const AddNonBrandedAppButton = ({ disabled }) => (
        <Button onClick={onAddNonBrandedAppButtonClick} {...commonProps} disabled={disabled}>
            + Add Non-Branded App
        </Button>
    );

    const AddBrandedAppButton = ({ disabled }) => (
        <Button
            onClick={onAddBrandedAppsButtonClick}
            style={{ marginLeft: '16px' }}
            disabled={disabled}
            {...commonProps}
        >
            + Add Branded App
        </Button>
    );

    return (
        <div className={`flex-right ${className ? className : ''}`}>
            <div className="flex-space-around">
                {!isLoading && nonBrandedAppAdditionDisabled ? (
                    <Tooltip title={tooltipText.nonBrandedApp}>
                        <span>
                            <AddNonBrandedAppButton disabled={true} />
                        </span>
                    </Tooltip>
                ) : (
                    <AddNonBrandedAppButton disabled={isLoading} />
                )}
                {!isLoading && brandedAppAdditionDisabled ? (
                    <Tooltip title={tooltipText.brandedApp}>
                        <span>
                            <AddBrandedAppButton disabled={true} />
                        </span>
                    </Tooltip>
                ) : (
                    <AddBrandedAppButton disabled={isLoading} />
                )}
            </div>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    brandedAppAdditionDisabled: PropTypes.bool,
    pharmacyHasMissingInfo: PropTypes.bool,
    onAddNonBrandedAppButtonClick: PropTypes.func.isRequired,
    onAddBrandedAppsButtonClick: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
    className: '',
    pharmacyHasMissingInfo: false,
};
