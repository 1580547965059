import './BulkPatientUploadDialog.sass';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import { userAction } from '../../../redux/Inbox/User';
import { getExactDuplicateNewPatients } from '../../../redux/Patient/PatientData/action';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getLocationNameFromAuth } from '../../../utils/helper';
import Papa from 'papaparse';
import UploadFileContent from './UploadFileContent';
import _ from 'lodash';
import MapFileAttributesContent from './MapFileAttributesContent';
import {
    getLikelyFirstNameColIndex,
    getLikelyLastNameColIndex,
    getLikelyPhoneColIndex,
    getLikelyDOBColIndex,
    findEmptyColumns,
    doesFileSeemToHaveHeaders,
    isAttributeMapped,
    findDerivedCombinedColumns,
    buildDerivedColumnsFromFileData,
    getOrderedFileColumns,
} from './csvUtil';
import ReviewPatientsContent from './ReviewPatientsContent';
import numeral from 'numeral';
import pluralize from 'pluralize';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const REQUIRED_FIELDS = [
    {
        name: 'First Name',
        value: 'first_name',
    },
    {
        name: 'Last Name',
        value: 'last_name',
    },
    {
        name: 'Phone',
        value: 'phone',
    },
    {
        name: 'Date of Birth',
        value: 'date_of_birth',
    },
];

function BulkPatientUploadDialog({
    open,
    onClose,
    activeLocationCountryCode,
    createBulkUsers,
    uploadBulkOriginalCSV,
    locationName,
    getExactDuplicateNewPatients,
    duplicateNewPatientsState,
    fromOnboarding,
}) {
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [fileColumns, setFileColumns] = useState(null);
    const [attributesMapping, setAttributesMapping] = useState({});
    const [currentStep, setCurrentStep] = useState('upload');
    const [isMappingComplete, setIsMappingComplete] = useState(false);
    const [autoMappedCount, setAutoMappedCount] = useState(0);
    const [addedCount, setAddedCount] = useState(null);

    useEffect(() => {
        if (!_.isNil(file)) {
            Papa.parse(file, {
                complete: (results) => {
                    const emptyColumns = findEmptyColumns(results);
                    const hasHeaders = doesFileSeemToHaveHeaders(results, emptyColumns);
                    results.emptyColumns = emptyColumns;
                    results.hasHeaders = hasHeaders;

                    const derivedFileData = buildDerivedColumnsFromFileData(
                        results,
                        findDerivedCombinedColumns(results, hasHeaders),
                        hasHeaders
                    );
                    const fileColumns = getOrderedFileColumns(results, derivedFileData);

                    const likelyFirstNameIndex = getLikelyFirstNameColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyLastNameIndex = getLikelyLastNameColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyPhoneIndex = getLikelyPhoneColIndex(fileColumns, emptyColumns, hasHeaders);
                    const likelyDOBIndex = getLikelyDOBColIndex(fileColumns, emptyColumns, hasHeaders);
                    const mapping = {
                        first_name: {
                            colIndex: _.isFinite(likelyFirstNameIndex) ? likelyFirstNameIndex : undefined,
                        },
                        last_name: {
                            colIndex: _.isFinite(likelyLastNameIndex) ? likelyLastNameIndex : undefined,
                        },
                        phone: {
                            colIndex: _.isFinite(likelyPhoneIndex) ? likelyPhoneIndex : undefined,
                        },
                        date_of_birth: {
                            colIndex: _.isFinite(likelyDOBIndex) ? likelyDOBIndex : undefined,
                        },
                    };

                    setAutoMappedCount(
                        _.size(
                            _.filter([
                                _.isFinite(likelyFirstNameIndex),
                                _.isFinite(likelyLastNameIndex),
                                _.isFinite(likelyPhoneIndex),
                                _.isFinite(likelyDOBIndex),
                            ])
                        )
                    );

                    setFileColumns(fileColumns);
                    setAttributesMapping(mapping);
                    setFileData(results);
                    setCurrentStep('mapping');
                },
            });
        }
    }, [file]);

    useEffect(() => {
        setIsMappingComplete(
            _.isEmpty(_.filter(REQUIRED_FIELDS, ({ value }) => !isAttributeMapped(value, attributesMapping)))
        );
    }, [attributesMapping]);

    const onCloseWrapper = () => {
        onClose();
        // clear internal state after the close animation
        setTimeout(() => {
            setFile(null);
            setFileData(null);
            setAttributesMapping(null);
            setCurrentStep('upload');
        }, 500);
    };

    const getCurrentStepContent = () => {
        if (currentStep === 'mapping' && !_.isNil(file)) {
            return (
                <MapFileAttributesContent
                    file={file}
                    fileData={fileData}
                    fileColumns={fileColumns}
                    requiredFields={REQUIRED_FIELDS}
                    attributesMapping={attributesMapping}
                    setAttributesMapping={setAttributesMapping}
                    isMappingComplete={isMappingComplete}
                    onDone={() => setCurrentStep('review')}
                    onGoBack={() => setCurrentStep('upload')}
                    autoMappedAttributeCount={autoMappedCount}
                    doneLabel={'Review Mapped Patients'}
                />
            );
        } else if (currentStep === 'review') {
            return (
                <ReviewPatientsContent
                    file={file}
                    fileData={fileData}
                    fileColumns={fileColumns}
                    attributesMapping={attributesMapping}
                    onClose={onCloseWrapper}
                    onGoBack={() => setCurrentStep('mapping')}
                    onDone={(success, addedCount) => {
                        onClose();
                        if (success) {
                            setAddedCount(addedCount);
                            setCurrentStep('done');
                        } else {
                            setCurrentStep('error');
                        }
                    }}
                    activeLocationCountryCode={activeLocationCountryCode}
                    createBulkUsers={createBulkUsers}
                    uploadBulkOriginalCSV={uploadBulkOriginalCSV}
                    locationName={locationName}
                    getExactDuplicateNewPatients={getExactDuplicateNewPatients}
                    duplicateNewPatientsState={duplicateNewPatientsState}
                />
            );
        } else {
            return (
                <UploadFileContent
                    fromOnboarding={fromOnboarding}
                    handleFileUpload={(f) => {
                        setFile(f[0]);
                    }}
                />
            );
        }
    };

    return (
        <span>
            <Dialog
                maxWidth="lg"
                className="bulk-patient-upload-dialog"
                open={open}
                onClose={() => {
                    onCloseWrapper();
                }}
            >
                <DialogContent>{getCurrentStepContent()}</DialogContent>
            </Dialog>

            <Snackbar
                className="bulk-patient-finished-snackbar"
                open={currentStep === 'done'}
                onClose={() => setCurrentStep('post-done')}
                autoHideDuration={5000}
                message={
                    <div className="bulk-patient-finished-snackbar-content">
                        <div className="left">
                            <CheckCircleIcon />
                        </div>
                        <div className="right">
                            <div className="main-line">
                                Successfully submitted {numeral(addedCount).format('0,0')}{' '}
                                {pluralize('patient', addedCount)} to be added.
                            </div>
                            <div className="sub-line">
                                These patients will show up as soon as they are processed (usually less than 1 minute).
                            </div>
                        </div>
                    </div>
                }
            />
        </span>
    );
}

function mapStateToProps(state) {
    const { inboxUser, pharmacy, auth, patientData } = state;
    return {
        user: inboxUser,
        bulkUploadEnabled: pharmacy.bulkUploadEnabled,
        locationName: getLocationNameFromAuth(auth),
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        duplicateNewPatientsState: patientData.duplicateNewPatients,
    };
}

const bindActionsToDispatch = {
    createBulkUsers: userAction.createBulkUsers,
    clearCreateUserMessage: userAction.clearCreateUserMessage,
    uploadBulkOriginalCSV: userAction.uploadBulkOriginalCSV,
    getExactDuplicateNewPatients,
};

export default connect(mapStateToProps, bindActionsToDispatch)(BulkPatientUploadDialog);
