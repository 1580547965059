import React from 'react';
import { Card, CardText, ExpansionList, ExpansionPanel } from 'react-md';

import EmbeddedContent from './EmbeddedContent';
import CourseContentUrls from 'constants/CourseContentUrls';

const Presentations = ({ content }) => {
    const { prefixes, suffixes } = CourseContentUrls;
    const getPresentationUrl = (presentationID) =>
        `${prefixes.gdocs.presentation}${presentationID}${suffixes.gdocs.presentation}`;

    return (
        <Card className="borderless-card">
            <CardText>
                <ExpansionList className="md-cell md-cell--12">
                    {content.map((presentation) => (
                        <ExpansionPanel
                            key={presentation.id}
                            id={presentation.id}
                            label={presentation.title}
                            footer={null}
                            defaultExpanded={content.length === 1}
                        >
                            <EmbeddedContent
                                id={`presentation-${presentation.id}`}
                                src={getPresentationUrl(presentation.id)}
                            />
                        </ExpansionPanel>
                    ))}
                </ExpansionList>
            </CardText>
        </Card>
    );
};

export default Presentations;
