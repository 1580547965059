import './UploadFileContent.sass';
import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

export default function UploadFileContent({ fromOnboarding, handleFileUpload }) {
    return (
        <div className="upload-file-content">
            <div className="left">
                <img
                    width={236}
                    height={314}
                    src={`/inbox/onboarding-patients.png`}
                    srcSet={`/inbox/onboarding-patients.png 1x, /inbox/onboarding-patients_2x.png 2x, /inbox/onboarding-patients_3x.png 3x`}
                    alt="Add Patients"
                />
            </div>
            <div className="right">
                <div className="heading">
                    <div className="main-line">Add your patients</div>

                    {fromOnboarding ? (
                        <div className="sub-line">
                            Now let's add your patients. You can upload a CSV or&nbsp;
                            <Tooltip title="Tab-separated, pipe-separated, and semicolon-separated files are also supported.">
                                <span style={{ textDecoration: 'underline dotted' }}>similar file</span>
                            </Tooltip>
                            &nbsp;from your PMS or you can manually add them from the inbox page.
                        </div>
                    ) : (
                        <div className="sub-line">
                            You can upload a CSV or&nbsp;
                            <Tooltip title="Tab-separated, pipe-separated, and semicolon-separated files are also supported.">
                                <span style={{ textDecoration: 'underline dotted' }}>similar file</span>
                            </Tooltip>
                            &nbsp;from your PMS.
                        </div>
                    )}
                </div>
                <Dropzone className="file-dropzone" onDrop={(files) => handleFileUpload(files)}>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className="file-drop-area"
                            style={{
                                height: '218px',
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                            {...getRootProps()}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <input
                                    {...getInputProps()}
                                    accept=".csv, text/csv .tsv text/tsv"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e.target.files)}
                                />
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: '1.2rem',
                                        textAlign: 'center',
                                        marginBottom: '16px',
                                        maxWidth: '370px',
                                    }}
                                >
                                    Drag and drop the file you want to upload here
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#3793ff',
                                        textAlign: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    or click to select a file from your computer
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#999999',
                                        textAlign: 'center',
                                        maxWidth: '370px',
                                        fontSize: '13px',
                                    }}
                                >
                                    Your file must have (at a minimum) the first name, last name, date of birth and
                                    phone number. They can be in any order. We will map the attributes appropriately in
                                    the next step.
                                </Typography>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </div>
    );
}
