import React from 'react';
import LearnMoreButton from 'components/Common/LearnMoreButton';

const appType = 'newsletter';

// TODO: We will update the contents of this page as Newsletter feature evolves

const EmptyNewsletter = () => {
    return (
        <div className="empty-newsletter">
            <img src="/empty-newsletter-screenshot.png" alt="Empty Newsletter screenshot" className="bordered-box" />

            <p className="title">Newsletter</p>
            <p>Newsletter feature description</p>
            <p style={{ alignSelf: 'flex-start' }}> With the Newsletter you can… </p>
            <ul className="list">
                <li> Send a weekly newsletter containing curated content written by our pharmacist or staff </li>
            </ul>
            <LearnMoreButton appType={appType} variant="tabs" />
        </div>
    );
};

export default EmptyNewsletter;
