export const userActions = {
    read: 'read',
    write: 'write',
    create: 'create',
    update: 'update',
    delete: 'delete',

    view: 'view',
    send: 'send',
    start: 'start',
    click: 'click',
    manage: 'manage',
};
