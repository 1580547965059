import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

LinearProgressWithSteps.propTypes = {
    completedCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    label: PropTypes.string,
    labelColor: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
};

LinearProgressWithSteps.defaultProps = {
    label: '',
    labelColor: '#4a4a4a',
    size: 'md',
};

const barMdBgColor = '#f7f7f7';
const barSmBgColor = '#d8d8d8';
const barColor = '#3793ff';

const LinearProgressMd = withStyles({
    root: {
        height: 8,
        borderRadius: 4,
    },
    colorPrimary: {
        backgroundColor: barMdBgColor,
    },
    bar: {
        borderRadius: 4,
        backgroundColor: barColor,
    },
})(LinearProgress);

const LinearProgressSm = withStyles({
    root: {
        height: 4,
        borderRadius: 2,
    },
    colorPrimary: {
        backgroundColor: barSmBgColor,
    },
    bar: {
        borderRadius: 4,
        backgroundColor: barColor,
    },
})(LinearProgress);

function LinearProgressWithSteps(props) {
    const { completedCount, totalCount, label, labelColor, size } = props;
    const maxCompletedCount = completedCount > totalCount ? totalCount : completedCount;
    const percentage = Math.ceil((maxCompletedCount / totalCount) * 100);
    const isSmallSize = size === 'sm';
    const progressBarProps = { variant: 'determinate', value: percentage };

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: isSmallSize ? 4 : 8,
                }}
            >
                <Typography
                    style={{ fontSize: isSmallSize ? 12 : 14, fontWeight: isSmallSize ? 500 : 400, color: labelColor }}
                >
                    {label ? label : ''}
                </Typography>
                <Typography style={{ fontSize: isSmallSize ? 10 : 14, color: '#4a4a4a' }}>
                    {maxCompletedCount}/{totalCount}
                </Typography>
            </div>
            {isSmallSize ? <LinearProgressSm {...progressBarProps} /> : <LinearProgressMd {...progressBarProps} />}
        </div>
    );
}

export default LinearProgressWithSteps;
