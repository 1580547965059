import './AppointmentsContent.sass';
import React, { useState, useEffect } from 'react';
import AppointmentsAgenda from 'components/Appointments/AppointmentsAgenda';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import { getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import DateSelectionCalendar from 'components/Appointments/DateSelectionCalendar';
import { updateQueryParameters } from 'utils/urlHelper';
import { getHowToScheduleConfigFromInboxConversationTemplate } from './schedulingUtil';
import CreateOrEditAppointmentDialog from 'components/Appointments/appointment/CreateOrEditAppointmentDialog';
import { getMatchingAppointmentType } from 'components/Appointments/AppointmentsPage';
import { getDisplayedDateRange } from 'components/Appointments/DateSelectionCalendar';
import Snowplow from 'snowplow';

export default function AppointmentsContent({
    template,
    apptCalendar,
    activeLocationCountryCode,
    location,
    history,
    height,
    getCalendarReservations,
    appointmentTypes,
    getCalendarAppointmentCounts,
}) {
    const props = arguments[0];

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Who by count from waitlist');
    }, []);

    const [patients, setPatients] = useState(null);
    const [showingEditAppointment, setShowingEditAppointment] = useState(false);

    const queryParams = queryString.parse(location.search);

    const focusedDate = moment(_.get(queryParams, 'date', moment().format('YYYY-MM-DD')), 'YYYY-MM-DD');

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    const calendars = _.filter(
        _.get(apptCalendar, 'availableCalendars.calendars', []),
        ({ status }) => status === 'ACTIVE'
    );

    const calendar_id = getHowToScheduleConfigFromInboxConversationTemplate(template).appointment_calendar_id;
    const calendar = _.find(calendars, (c) => c.calendar_id === calendar_id);

    useEffect(() => {
        _.each(calendars, ({ calendar_id }) => {
            const loadedReservations = _.get(apptCalendar, `calendarReservations.${calendar_id}`);

            const startAt = moment.utc(moment(`${focusedDate.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss'));
            const endAt = moment.utc(
                moment(`${moment(focusedDate).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss')
            );

            const startDate = startAt.format('YYYY-MM-DD');
            const startTime = startAt.format('HH:mm:ss');
            const endDate = endAt.format('YYYY-MM-DD');
            const endTime = endAt.format('HH:mm:ss');

            if (
                _.isNil(_.get(loadedReservations, 'response')) ||
                loadedReservations.startDate !== startDate ||
                loadedReservations.startTime !== startTime ||
                loadedReservations.endDate !== endDate ||
                loadedReservations.endTime !== endTime
            ) {
                getCalendarReservations(calendar_id, startDate, startTime, endDate, endTime);
            }
        });
    }, [
        focusedDate.format('YYYY-MM-DD'),
        JSON.stringify(_.map(calendars, ({ calendar_id }) => calendar_id)),
        apptCalendar.calendarReservations,
    ]);

    useEffect(() => {
        if (!_.isEmpty(calendars)) {
            const { startDate, endDate } = getDisplayedDateRange(focusedDate);
            const calendarIds = _.map(calendars, ({ calendar_id }) => calendar_id);
            const label = _.get(template, 'message_content.messaging_form.appointment_label');

            if (
                !_.isEqual(_.get(apptCalendar.appointmentCounts, 'calendarIds'), calendarIds) ||
                _.get(apptCalendar.appointmentCounts, 'startDate') !== startDate ||
                _.get(apptCalendar.appointmentCounts, 'endDate') !== endDate ||
                _.get(apptCalendar.appointmentCounts, 'label') !== label
            ) {
                getCalendarAppointmentCounts(calendarIds, startDate, endDate, label);
            }
        }
    }, [apptCalendar, calendars]);

    return (
        <div className="appointments-content workflow-inner-content">
            <div className="left-bar" style={{ height: `${height - 1}px` }}>
                <DateSelectionCalendar
                    appointmentCounts={_.get(apptCalendar, 'appointmentCounts.response.appointment_counts')}
                    focusedDate={focusedDate}
                    onDateChange={(date) => {
                        history.push(
                            `${location.pathname}${updateQueryParameters(location, 'date', date.format('YYYY-MM-DD'))}`
                        );
                    }}
                />
            </div>
            <div className="agenda-content">
                <AppointmentsAgenda
                    {...props}
                    isWorkflow={true}
                    height={height - 10}
                    focusedDate={focusedDate}
                    calendars={calendars}
                    displayedDateFormat={displayedDateFormat}
                    onEditAppointment={(appointment) => {
                        setShowingEditAppointment(appointment);
                    }}
                    appointmentLabelToShow={_.get(template, 'message_content.messaging_form.appointment_label')}
                    patients={patients}
                    setPatients={setPatients}
                    noAppointmentsSubLineText={
                        'You may use the scheduling section of this workflow to allow patients to schedule appointments at their convenience.'
                    }
                />
            </div>

            {!showingEditAppointment ? null : (
                <CreateOrEditAppointmentDialog
                    {...props}
                    open={true}
                    onClose={() => setShowingEditAppointment(null)}
                    isCreate={false}
                    displayedDateFormat={displayedDateFormat}
                    appointmentTypes={appointmentTypes}
                    calendars={calendars}
                    editing={showingEditAppointment}
                    initialSelectedDate={focusedDate}
                    initialFocusedTab={'time'}
                    initialConfiguration={{
                        patient: _.get(patients, `${showingEditAppointment.inbox_user_id}`),
                        appointmentType: getMatchingAppointmentType(appointmentTypes, showingEditAppointment),
                        datetime: moment.tz(
                            moment.utc(showingEditAppointment.start_date, 'YYYY-MM-DDTHH:mm:SS'),
                            _.get(calendar, 'timezone')
                        ),
                    }}
                />
            )}
        </div>
    );
}
