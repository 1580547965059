import _ from 'lodash';

export function selectApptTypesWithSessions(state) {
    const availableApptTypes = _.get(state, 'apptType.availableApptTypes.response.reservation_types', []);

    const covidVaccineType = _.find(availableApptTypes, ({ label }) => label === 'COVID-19 Vaccination');

    const liveSessions = _.groupBy(
        _.get(state, 'apptReservation.liveReusableSessions.response.live_sessions', []),
        ({ appt_type_id }) => (_.isNil(appt_type_id) ? _.get(covidVaccineType, 'appt_type_id') : appt_type_id)
    );

    return _.map(availableApptTypes, (apptType) => ({
        apptType,
        sessions: liveSessions[apptType.appt_type_id],
    }));
}

export function selectNumberOfActiveApptTypes(state) {
    return _.size(
        _.groupBy(_.get(state, 'apptReservation.liveReusableSessions.response.live_sessions', []), ({ appt_type_id }) =>
            String(appt_type_id)
        )
    );
}
