import Actions from 'redux/actions';
import { post } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../utils/log';

export const fetchRefillsCount = (data) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.REFILLS_COUNT}`;

    dispatch({ type: Actions.FETCH_REFILLS_COUNT_STARTED });

    const refillsCountReq = post(apiUrl, data);
    return refillsCountReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_REFILLS_COUNT_SUCCESS, payload: { data: resp } });
        })
        .catch((err) => {
            logHttpError('An error occured on fetchRefillsCountReq', err);
            dispatch(displayToast({ text: 'Error while fetching refills count!!', type: 'error' }));
            dispatch({ type: Actions.FETCH_REFILLS_COUNT_FAILED, payload: err });
        });
};

export const fetchRefills = (data) => (dispatch) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.REFILLS}`;
    const loadMore = data.loadMore ? true : false;

    dispatch({ type: Actions.FETCH_REFILLS_STARTED, payload: { loadMore } });

    const refillsReq = post(apiUrl, data);
    return refillsReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_REFILLS_SUCCESS, payload: { data: resp, loadMore } });
        })
        .catch((err) => {
            logHttpError('An error occured on fetchRefillsCountReq', err);
            dispatch(displayToast({ text: 'Error while fetching refills data!!', type: 'error' }));
            dispatch({ type: Actions.FETCH_REFILLS_FAILED, payload: err });
        });
};

export const sortRefills = (data) => ({
    type: Actions.SORT_REFILL,
    payload: data,
});
