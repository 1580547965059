import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UserAuditLog from './UserAuditLog';
import { userPropType } from './helper';

class UserAudit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            userLogs: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchUserLogs();
    }

    fetchUserLogs() {
        const { user, getAuditLogs } = this.props;
        this.setState({
            loading: true,
        });
        // Currently user log data is ephemeral and only used by this component and its children
        // Because of this, we are using local state instead of a redux store
        getAuditLogs({ user_id: user.user_api_user_id })
            .then((response) => {
                this.setState({
                    loading: false,
                    userLogs: response,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    userLogs: [],
                });
            });
    }

    render() {
        const { user } = this.props;
        const { userLogs, loading } = this.state;

        return (
            <div className="user-audit-logs">
                {loading ? (
                    <CircularProgress className="user-audit-logs__spinner" />
                ) : (
                    _.reverse(userLogs).map((userLog, i) => {
                        const compositeKey = `${_.get(userLog, 'data.a_id', i)}-${userLog.date}`;
                        return <UserAuditLog key={compositeKey} user={user} log={userLog} />;
                    })
                )}
            </div>
        );
    }
}

UserAudit.propTypes = {
    user: userPropType.isRequired,
    getAuditLogs: PropTypes.func.isRequired,
};

export default UserAudit;
