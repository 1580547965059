import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { StickyContainer, Sticky } from 'react-sticky';

import ReportOverview from 'components/Report/ReportOverview';
import DigitalMarketingDashboard from 'components/DigitalMarketingDashboard';
import { PharmacyLocationSelect, TabPanel } from 'components/Common';

const style = {
    tab: {
        textTransform: 'none',
        fontSize: '14px',
    },
};

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            allLocations: true,
            locations: [],
            selectedLocalLocation: '',
            selectedPharmacyName: '',
        };
    }

    componentDidMount() {
        this.setLocations();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedPharmacy !== this.props.selectedPharmacy ||
            prevProps.pharmacy.lastUpdated !== this.props.pharmacy.lastUpdated
        ) {
            this.resetLocation();
        }
    }

    handleChange = (event, tabIdx) => {
        this.setState({ selectedTab: tabIdx });
    };

    setLocations = () => {
        const {
            userDetails,
            selectedLocation,
            pharmacy: { pharmacy },
        } = this.props;
        const { allLocations } = this.state;
        let availableLocations = [];
        if (pharmacy.location) {
            const locations = userDetails.filter((custLocDetails) => custLocDetails.entityType === 'Location');
            const locationIds = locations.map((loc) => loc.entityId);
            availableLocations = _.reject(pharmacy.location, (loc) => locationIds.indexOf(loc.id) < 0);
        }
        this.setState({
            locations: availableLocations,
            selectedLocalLocation: allLocations ? 'allLocations' : selectedLocation,
            selectedPharmacyName: pharmacy.name,
        });
    };

    resetLocation = () => {
        this.setState({ selectedLocalLocation: 'allLocations', allLocations: true });
        this.setLocations();
    };

    changeLocalLocation = (e) => {
        let allLocations = false;
        if (e.target.value === 'allLocations') allLocations = true;
        this.setState({ selectedLocalLocation: e.target.value, allLocations });
    };

    getPharmLocDropdown = () => {
        const { selectedLocalLocation, locations, selectedPharmacyName } = this.state;

        return (
            <div style={{ minWidth: '300px' }}>
                <PharmacyLocationSelect
                    title="Pharmacy Location Data"
                    labelWidth={150}
                    selectedLocalLocation={selectedLocalLocation}
                    changeLocalLocation={this.changeLocalLocation}
                    locations={locations}
                    selectedPharmacyName={selectedPharmacyName}
                />
            </div>
        );
    };

    getReportPage = () => {
        const { selectedLocalLocation, locations, selectedPharmacyName, allLocations } = this.state;

        return (
            <ReportOverview
                selectedLocalLocation={selectedLocalLocation}
                locations={locations}
                allLocations={allLocations}
                selectedPharmacyName={selectedPharmacyName}
            />
        );
    };

    render() {
        const { selectedTab, selectedLocalLocation, selectedPharmacyName } = this.state;
        const {
            classes,
            pharmacy: { dmDashboardEnabled },
        } = this.props;

        return (
            <StickyContainer>
                <div className="reports-tab-container" style={{ padding: '16px' }}>
                    <Sticky topOffset={-79}>
                        {({ style, isSticky }) => (
                            <div
                                style={{ ...style, paddingTop: isSticky ? '10px' : 0 }}
                                className="sticky-item-container"
                            >
                                {dmDashboardEnabled && (
                                    <Tabs
                                        value={selectedTab}
                                        onChange={this.handleChange}
                                        aria-label="report tabs"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        className="reports-tab"
                                    >
                                        <Tab label="Overview" className={classes.tab} />
                                        <Tab label="Digital Marketing" className={classes.tab} />
                                    </Tabs>
                                )}
                                <div
                                    className={`${
                                        !dmDashboardEnabled && 'no-dashboard'
                                    } pharmacy-loc-select flex-right flex-middle`}
                                >
                                    {this.getPharmLocDropdown()}
                                </div>
                            </div>
                        )}
                    </Sticky>

                    <TabPanel value={selectedTab} index={0}>
                        <div className="reports-container" style={{ marginTop: !dmDashboardEnabled ? '60px' : '' }}>
                            {this.getReportPage()}
                        </div>
                    </TabPanel>

                    {dmDashboardEnabled && (
                        <TabPanel value={selectedTab} index={1}>
                            <DigitalMarketingDashboard
                                selectedLocalLocation={selectedLocalLocation}
                                selectedPharmacyName={selectedPharmacyName}
                            />
                        </TabPanel>
                    )}
                </div>
            </StickyContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedLocation: state.auth.selectedLocation.id,
        selectedPharmacy: state.auth.selectedCustomer.id,
        pharmacy: state.pharmacy,
        userDetails: state.auth.userDetails,
        perms: state.auth.selectedLocation.perms,
    };
};

export default connect(mapStateToProps)(withStyles(style)(Reports));
