export default {
    FETCH_IVR_LANGUAGES_STARTED: 'FETCH_IVR_LANGUAGES_STARTED',
    FETCH_IVR_LANGUAGES_SUCCESS: 'FETCH_IVR_LANGUAGES_SUCCESS',
    FETCH_IVR_LANGUAGES_FAILED: 'FETCH_IVR_LANGUAGES_FAILED',
    FETCH_IVR_LANGUAGE_CHOICES_STARTED: 'FETCH_IVR_LANGUAGE_CHOICES_STARTED',
    FETCH_IVR_LANGUAGE_CHOICES_SUCCESS: 'FETCH_IVR_LANGUAGE_CHOICES_SUCCESS',
    FETCH_IVR_LANGUAGE_CHOICES_FAILED: 'FETCH_IVR_LANGUAGE_CHOICES_FAILED',
    ADD_IVR_LANGUAGE: 'ADD_IVR_LANGUAGE',
    REMOVE_IVR_LANGUAGE_CHOICE_STARTED: 'REMOVE_IVR_LANGUAGE_CHOICE_STARTED',
    REMOVE_IVR_LANGUAGE_CHOICE_SUCCESS: 'REMOVE_IVR_LANGUAGE_CHOICE_SUCCESS',
    REMOVE_IVR_LANGUAGE_CHOICE_FAILED: 'REMOVE_IVR_LANGUAGE_CHOICE_FAILED',
    SAVE_IVR_LANGUAGE_CHOICES_STARTED: 'SAVE_IVR_LANGUAGE_CHOICES_STARTED',
    SAVE_IVR_LANGUAGE_CHOICES_SUCCESS: 'SAVE_IVR_LANGUAGE_CHOICES_SUCCESS',
    SAVE_IVR_LANGUAGE_CHOICES_FAILED: 'SAVE_IVR_LANGUAGE_CHOICES_FAILED',
    UPDATE_IVR_LANGUAGE_VOICE: 'UPDATE_IVR_LANGUAGE_VOICE',
    UPDATE_IVR_LANGUAGE_CHOICE: 'UPDATE_IVR_LANGUAGE_CHOICE',
    UPDATE_VALIDATION_MSG: 'UPDATE_VALIDATION_MSG',
    FETCH_IVR_LANG_GREETING_STARTED: 'FETCH_IVR_LANG_GREETING_STARTED',
    FETCH_SYS_LANGUAGE_STARTED: 'FETCH_SYS_LANGUAGE_STARTED',
    FETCH_SYS_LANGUAGE_SUCCESS: 'FETCH_SYS_LANGUAGE_SUCCESS',
    FETCH_SYS_LANGUAGE_FAILED: 'FETCH_SYS_LANGUAGE_FAILED',
    REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
    ADD_LANGUAGE: 'ADD_LANGUAGE',
    REMOVE_LANGUAGES_STARTED: 'REMOVE_LANGUAGES_STARTED',
    REMOVE_LANGUAGES_SUCCESS: 'REMOVE_LANGUAGES_SUCCESS',
    REMOVE_LANGUAGES_FAILED: 'REMOVE_LANGUAGES_FAILED',
    SAVE_LANGUAGES_STARTED: 'SAVE_LANGUAGES_STARTED',
    SAVE_LANGUAGES_SUCCESS: 'SAVE_LANGUAGES_SUCCESS',
    SAVE_LANGUAGES_FAILED: 'SAVE_LANGUAGES_FAILED',
    FETCH_IVR_TTS_STARTED: 'FETCH_IVR_TTS_STARTED',
    FETCH_IVR_TTS_SUCCESS: 'FETCH_IVR_TTS_SUCCESS',
    FETCH_IVR_TTS_FAILED: 'FETCH_IVR_TTS_FAILED',
};
