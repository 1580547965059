import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';

import { homeActions } from 'redux/Home';
import { isEmptyString, isUnmessageablePhoneType } from 'utils/helper';
import { AbilityContext, userActions, restrictedResources } from 'casl';

class QuickReplyInput extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            sendingQuickReply: false,
        };
    }

    buildPayload = () => {
        const { content } = this.state;
        const { contact } = this.props;
        return {
            messageContent: content,
            conversationId: contact.latest_message.inbox_conversation_id,
            userId: null, // TODO in future use pharmacist's inbox_user_id
        };
    };

    handleSubmit = async () => {
        const { sendQuickReply, getConversations, contact } = this.props;
        const { content } = this.state;

        if (!isEmptyString(content)) {
            this.setState({ sendingQuickReply: true });
            // TODO: Needs to be handled in better way. We can remove this call from here once
            // backend api wont have dependency of this in session
            await getConversations(contact.inbox_user_id);
            await sendQuickReply(this.buildPayload());
            this.setState({ content: '', sendingQuickReply: false });
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        const placeholder = 'Quick Reply';
        const { content, sendingQuickReply } = this.state;
        const { contact, sendQuickReplyLoading } = this.props;
        const { opt_out, phone_type } = contact;
        const isUnsubscribed = !!opt_out;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const { send } = userActions;
        const { subject: messages } = restrictedResources.inbox.messages;
        const canSubmit = this.context.can(send, messages);
        const isLoading = sendQuickReplyLoading && sendingQuickReply;

        return (
            <div className="inbox-message-widget__input">
                <TextField
                    size="small"
                    id="outlined-basic"
                    label={placeholder}
                    name="content"
                    variant="outlined"
                    value={content}
                    fullWidth
                    onChange={this.handleChange}
                    disabled={!canSubmit || isLoading}
                />
                <div className="reply-btn-wrapper">
                    <Button
                        onClick={this.handleSubmit}
                        size="small"
                        color="primary"
                        style={{ textTransform: 'initial' }}
                        disabled={isLoading || isUnsubscribed || isUnmessageable || !canSubmit || _.isEmpty(content)}
                    >
                        Reply
                    </Button>
                    {isLoading && <CircularProgress size={24} className="circular-icon" />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sendQuickReplyLoading: state.home.sendQuickReplyLoading,
});

const bindActionsToDispatch = {
    sendQuickReply: homeActions.sendQuickReply,
    getConversations: homeActions.getConversations,
};

export default connect(mapStateToProps, bindActionsToDispatch)(QuickReplyInput);
