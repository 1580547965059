import Actions from 'redux/actions';

const initialState = {
    loadingDemographicMetrics: false,
    loadingDailyMetrics: false,
    demographicDescription: {},
    demographicAgeGroupClickData: {},
    adsDescription: {},
    campaigns: [],
    campaignPreviews: {},
};

const updateMetricsData = (state, payload) => {
    let adsDescription = {};

    if (payload && payload.results) {
        adsDescription = {
            impressions: 0,
            clicks: 0,
            reactions: 0, // reach: 0,
        };

        payload.results.forEach((metrics) => {
            const { clicks, post_reaction, impressions } = metrics;

            // adsDescription.reach += (reach ? reach : 0); commented for later use
            adsDescription.clicks += clicks || 0;
            adsDescription.reactions += post_reaction || 0;
            adsDescription.impressions += impressions || 0;
        });
    }

    return { ...state, adsDescription, loadingDailyMetrics: false };
};

const updateDemographicData = (state, payload) => {
    let demographicAgeGroupClickData = {};
    let demographicDescription = {};

    if (payload && payload.results) {
        demographicAgeGroupClickData = {
            male: {},
            female: {},
        };

        demographicDescription = {
            impressions: { total: 0, male: 0, female: 0 },
            clicks: { total: 0, male: 0, female: 0 },
        };

        payload.results.forEach((metrics) => {
            const { gender, age_group, clicks, impressions } = metrics;
            if (['male', 'female'].indexOf(gender) > -1) {
                if (!(age_group in demographicAgeGroupClickData[gender])) {
                    demographicAgeGroupClickData[gender][age_group] = 0;
                }
                demographicAgeGroupClickData[gender][age_group] += clicks || 0;

                demographicDescription.impressions[gender] += impressions || 0;
                demographicDescription.clicks[gender] += clicks || 0;
            }

            demographicDescription.impressions.total += impressions || 0;
            demographicDescription.clicks.total += clicks || 0;
        });
    }

    return { ...state, demographicAgeGroupClickData, demographicDescription, loadingDemographicMetrics: false };
};

export default function TigerPistolReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_TIGER_PISTOL_METRIC_STARTED:
            return {
                ...state,
                adsDescription: {},
                loadingDailyMetrics: payload,
            };
        case Actions.FETCH_TIGER_PISTOL_METRIC_SUCCESS:
            return updateMetricsData(state, payload);
        case Actions.FETCH_TIGER_PISTOL_METRIC_FAILED:
            return { ...state, loadingDailyMetrics: false };
        case Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_STARTED:
            return {
                ...state,
                demographicDescription: {},
                demographicAgeGroupClickData: {},
                loadingDemographicMetrics: payload,
            };
        case Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_SUCCESS:
            return updateDemographicData(state, payload);
        case Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_FAILED:
            return { ...state, loadingDemographicMetrics: false };
        case Actions.FETCH_TIGER_PISTOL_CAMPAIGN_STARTED:
            return { ...state, campaigns: [], campaignPreviews: {} };
        case Actions.FETCH_TIGER_PISTOL_CAMPAIGN_SUCCESS:
            return { ...state, campaigns: payload.results };
        case Actions.UPDATE_CAMPAIGN_PREVIEWS:
            return { ...state, campaignPreviews: payload };
        default:
            return state;
    }
}
