import React, { useEffect, useRef, useState } from 'react';
import './PatientName.sass';
import PatientAvatar from './PatientAvatar';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getTextWidth } from '../../utils/helper';
import { Tooltip } from '@material-ui/core';
import { getPatientAvatarSeedFromContact } from './PatientAvatar';

export default function PatientName(props) {
    const mainRef = useRef();
    const [overflowing, setOverflowing] = useState(false);
    const { first_name, last_name, date_of_birth, format, width } = props;

    useEffect(() => {
        if (_.isFinite(width)) {
            const textWidth = getTextWidth(formatPatientName(props, props.format), '13px Roboto');
            setOverflowing(textWidth > width - 40 /* PatientAvatar width + 10px margin */);
        }
    }, [width]);

    if (_.isNil(first_name) && _.isNil(last_name) && _.isNil(date_of_birth)) {
        return (
            <div className="patient-name loading">
                <div className="avatar-silhouette" />
                <div className="name-silhouette" />
            </div>
        );
    }

    const name = format === 'last_first' ? `${last_name}, ${first_name}` : `${first_name} ${last_name}`;

    return (
        <div
            ref={mainRef}
            className="patient-name"
            style={{ display: 'inline-flex', alignItems: 'center', width: `${width}px` }}
        >
            <PatientAvatar
                growOnHover={false}
                seed={getPatientAvatarSeedFromContact({ first_name, last_name, date_of_birth })}
            />{' '}
            {!overflowing ? (
                <span className="name">{name}</span>
            ) : (
                <Tooltip title={name}>
                    <span className="name">{name}</span>
                </Tooltip>
            )}
        </div>
    );
}
PatientName.props = {
    format: PropTypes.oneOf(['last_first', 'first_last']),
};
PatientName.defaultProps = {
    format: 'first_last',
};

export function formatPatientName({ first_name, last_name }, format) {
    return format === 'last_first' ? `${last_name}, ${first_name}` : `${first_name} ${last_name}`;
}
