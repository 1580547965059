import './ConnectRootPage.sass';
import React, { useState, useEffect } from 'react';
import { calculateWrapperSize } from './ConnectCardGrid';
import _ from 'lodash';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    fetchPatientOpportunities,
    setBulkMessageStatusShown,
    clearPatientOpportunities,
} from '../../../redux/Patient/Connect/action';
import {
    fetchAvailableAssessedAttributes,
    clearAvailableAssessedAttributes,
} from '../../../redux/Patient/Assessment/action';
import ConfirmMessagePage from './ConfirmMessagePage';
import ChoosePatientsPage from './ChoosePatientsPage';
import ConnectLandingPage from './ConnectLandingPage';
import queryString from 'query-string';
import ConnectManagementRootPage from './Management/ConnectManagementRootPage';
import moment from 'moment';
import { getInboxConversationTemplates } from '../patientsUtil';
import WorkflowSchedulingPage from '../scheduling/WorkflowSchedulingPage';
import { isSchedulingWaitlistEnabled } from '../scheduling/schedulingUtil';
import { getLiveReusableReservationSessions } from 'redux/Appt/Reservation/action';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';
import { getAvailableAppointmentTypes } from 'redux/Appt/ApptType/action';

function ConnectPage({
    inboxConversationTemplate,
    fetchPatientOpportunities,
    clearPatientOpportunities,
    fetchAvailableAssessedAttributes,
    clearAvailableAssessedAttributes,
    getLiveReusableReservationSessions,
    getAvailableAppointmentTypes,
    connect,
    history,
    location,
    launchDarkly,
    pharmacy,
    apptReservation,
    apptType,
    socket,
    apptTypesWithSessions,
}) {
    const props = arguments[0];
    const [selectedPatients, setSelectedPatients] = useState({});
    const [refreshIndex, setRefreshIndex] = useState(0);

    const [nameFormat, setNameFormat] = useState('last_first');
    const cardSize = { width: window.innerWidth > 1100 ? 500 : 450, height: 130 };
    const availableTemplates = _.filter(
        getInboxConversationTemplates(inboxConversationTemplate, launchDarkly, pharmacy, { apptTypesWithSessions }),
        (t) => t.status === 'active' && t.workflow_status === 'active'
    );
    const gridStyle = calculateWrapperSize(1030, cardSize, availableTemplates);
    const queryParams = queryString.parse(location.search);

    const today = getTodaysDate();

    useEffect(() => {
        if (!_.isEqual(connect.birthdayDate, today) || _.isNil(connect.opportunities)) {
            fetchPatientOpportunities(today);
        }
    }, [today, _.size(connect.opportunities)]);

    useEffect(() => {
        fetchAvailableAssessedAttributes();

        // TODO: Load these conditionally so we don't flicker if they are already loaded. we will also need to add these to
        // the loading state for the landing page

        if (
            !_.get(apptReservation, 'liveReusableSessions.loading') &&
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response'))
        ) {
            getLiveReusableReservationSessions();
        }
        if (!apptType.loadingAvailableApptTypes && _.isNil(_.get(apptType, 'availableApptTypes.response'))) {
            getAvailableAppointmentTypes();
        }
    }, []);

    // if we are informed of a socket change on this page
    useEffect(() => {
        const socketAt = _.get(socket, 'message.at');
        const fetchAt = _.get(connect, 'opportunitiesFetchStartedAt');
        if (socketAt > fetchAt) {
            fetchPatientOpportunities(today);
        }
    }, [_.get(connect, 'opportunitiesFetchStartedAt'), _.get(socket, 'message.at')]);

    // clear the patient opportunities on unmount so we will reload them fresh the next time they are needed
    useEffect(() => {
        return () => {
            clearPatientOpportunities();
            clearAvailableAssessedAttributes();
        };
    }, []);

    return (
        <div className="connect-page" style={{ minHeight: `${window.innerHeight - 80}px` }}>
            <Switch>
                <Route
                    path="/workflows/:templateId/(scheduling|appointments|customize|waitlist)"
                    render={({ match }) => {
                        const templateId = _.get(match, 'params.templateId');
                        const template = _.find(
                            availableTemplates,
                            ({ inbox_conversation_template_id }) => inbox_conversation_template_id === templateId
                        );

                        // if we can't find the template, see if we just replaced it with a "managed" one and redirect there if we did
                        if (_.isNil(template)) {
                            const managedTemplate = _.find(
                                availableTemplates,
                                ({ parent_template_id }) => templateId === parent_template_id
                            );
                            if (!_.isNil(managedTemplate)) {
                                return (
                                    <Redirect
                                        to={match.url.replace(
                                            templateId,
                                            managedTemplate.inbox_conversation_template_id
                                        )}
                                    />
                                );
                            }
                        }

                        return (
                            <div>
                                <WorkflowSchedulingPage
                                    {...props}
                                    template={template}
                                    goBack={() => history.push(`/workflows`)}
                                    history={history}
                                />
                            </div>
                        );
                    }}
                />

                <Route
                    path="/workflows/:templateId/patients"
                    render={({ match }) => {
                        const templateId = _.get(match, 'params.templateId');
                        const template = _.find(
                            availableTemplates,
                            ({ inbox_conversation_template_id }) => inbox_conversation_template_id === templateId
                        );
                        return (
                            <div>
                                <ChoosePatientsPage
                                    {...props}
                                    targeted={
                                        !_.isNil(_.get(template, 'targeting_type')) &&
                                        _.get(connect, `opportunities.${template.targeting_type}`)
                                    }
                                    template={template}
                                    gridStyle={gridStyle}
                                    onComplete={(selectedPatients) => {
                                        setSelectedPatients(selectedPatients);
                                        history.push(`/workflows/${templateId}/review`);
                                    }}
                                    goBack={() => history.push(`/workflows`)}
                                    selectedPatients={selectedPatients}
                                    setSelectedPatients={setSelectedPatients}
                                    nameFormat={nameFormat}
                                    setNameFormat={setNameFormat}
                                />
                            </div>
                        );
                    }}
                />

                <Route
                    path="/workflows/:templateId/review"
                    render={({ match }) => {
                        const templateId = _.get(match, 'params.templateId');

                        const template = _.find(
                            availableTemplates,
                            ({ inbox_conversation_template_id }) => inbox_conversation_template_id === templateId
                        );

                        if (_.isNil(template)) {
                            return null;
                        }

                        const targeted = _.isNil(template)
                            ? null
                            : _.get(connect, `opportunities.${template.targeting_type}`);
                        if (!_.isNil(targeted) && _.isEmpty(selectedPatients)) {
                            setSelectedPatients(
                                _.keyBy(
                                    _.get(connect, `opportunities.${template.targeting_type}.rows`),
                                    ({ inbox_user_id }) => inbox_user_id
                                )
                            );
                            return <div />;
                        }
                        return (
                            <div>
                                <ConfirmMessagePage
                                    {...props}
                                    patients={_.values(selectedPatients)}
                                    template={template}
                                    gridStyle={gridStyle}
                                    history={history}
                                    location={location}
                                    goBack={() =>
                                        history.push(_.get(queryParams, 'back', `/workflows/${templateId}/patients`))
                                    }
                                    nameFormat={nameFormat}
                                    setNameFormat={setNameFormat}
                                />
                            </div>
                        );
                    }}
                />

                <Route
                    path="/workflows/manage/:managePath?"
                    render={({ match }) => {
                        return <ConnectManagementRootPage {...props} match={match} />;
                    }}
                />

                <Route
                    render={() => (
                        <ConnectLandingPage
                            {...props}
                            selectedPatients={selectedPatients}
                            setSelectedPatients={setSelectedPatients}
                            gridStyle={gridStyle}
                            cardSize={cardSize}
                            availableTemplates={availableTemplates}
                            forceUpdate={() => setRefreshIndex(refreshIndex + 1)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
}

export function getTodaysDate() {
    return moment().format('YYYY-MM-DD');
}

function mapStateToProps(state) {
    const {
        auth,
        patientData,
        launchDarkly,
        connect,
        assessment,
        inboxUser,
        inboxConversationTemplate,
        pharmacy,
        socket,
        apptReservation,
        apptType,
    } = state;

    return {
        patientData,
        connect,
        assessment,
        isLDReady: _.get(launchDarkly, 'isLDReady', false),
        inboxUser,
        inboxConversationTemplate,
        pharmacy,
        launchDarkly,
        apptReservation,
        apptType,
        socket,
        apptTypesWithSessions: selectApptTypesWithSessions(state),
        isCovidWaitlistEnabled: isSchedulingWaitlistEnabled(auth, pharmacy),
    };
}

const bindActionsToDispatch = {
    fetchPatientOpportunities,
    setBulkMessageStatusShown,
    clearPatientOpportunities,
    fetchAvailableAssessedAttributes,
    clearAvailableAssessedAttributes,
    getLiveReusableReservationSessions,
    getAvailableAppointmentTypes,
};

export default withRouter(connect(mapStateToProps, bindActionsToDispatch)(ConnectPage));
