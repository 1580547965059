import _ from 'lodash';
import { createSelector } from 'reselect';

const appTypeBranded = 'branded';

const getApps = (state) => _.get(state, 'pharmacyApps.pharmacyApps.apps', []);

const getPharmacyApps = createSelector([getApps], (apps) => {
    const pharmacyApps = {
        brandedApps: [],
        nonBrandedApps: [],
    };

    _.forEach(apps, (app) => {
        if (app.appType === appTypeBranded) {
            pharmacyApps.brandedApps.push(app);
        } else {
            pharmacyApps.nonBrandedApps.push(app);
        }
    });

    return pharmacyApps;
});

export const pharmacyAppsSelector = {
    getPharmacyApps,
};
