import React, { PureComponent } from 'react';
import { Card, CardTitle, Cell, Grid } from 'react-md';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ListComponent from '../../Common/ListComponent';
import TemplateDetails from './TemplateDetails';
import { InboxSettingsMenuItems } from '../../../constants/InboxSettingsMenuItems';
import { pharmacyAction } from '../../../redux/Pharmacy/action';
import { displayToast } from '../../../redux/actionCreators/Snackbar';
import GeneralInboxSettings from './General';
import { Can, userActions, restrictedResources } from 'casl';

class InboxSettings extends PureComponent {
    inboxSettingsMenuItems = [...InboxSettingsMenuItems];

    onItemClick = (to) => {
        this.props.history.push({
            pathname: to,
            state: this.props.location.state,
        });
    };

    render() {
        const title = 'Manage Your Inbox';
        const subtitle = 'Configure your inbox and messages';
        const { view } = userActions;
        const { subject: caslSubject } = restrictedResources.inbox;

        return (
            <Grid>
                <Cell size={12}>
                    <Can I={view} this={caslSubject}>
                        <Card className="outer-card" style={{ marginLeft: '16px' }}>
                            <CardTitle title={title} subtitle={subtitle} style={{ marginBottom: '24px' }} />
                            <ListComponent
                                className="setting-list"
                                list={this.inboxSettingsMenuItems}
                                onItemClick={this.onItemClick}
                            />
                        </Card>
                    </Can>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    pharmacy: {
        ...state.pharmacy.pharmacy,
        lastUpdated: state.pharmacy.lastUpdated,
    },
});

const mapDispatchToProps = (dispatch) => ({
    getPharmacy: (data) => dispatch(pharmacyAction.getPharmacy(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

const InboxSettingsWrapper = (props) => {
    const {
        auth: { selectedLocation, isInternalUser },
        pharmacy,
        getPharmacy,
        displayToast,
        history,
        location,
    } = props;
    return (
        <Switch>
            <Route
                exact
                path="/settings/inbox"
                render={() => <InboxSettings isInternalUser={isInternalUser} history={history} location={location} />}
            />
            <Route
                path="/settings/inbox/general"
                render={() => (
                    <GeneralInboxSettings
                        pharmacy={pharmacy}
                        selectedLocation={selectedLocation}
                        getPharmacy={getPharmacy}
                        displayToast={displayToast}
                        history={history}
                        location={location}
                    />
                )}
            />
            <Route
                path="/settings/inbox/template"
                render={() => (
                    <TemplateDetails
                        pharmacy={pharmacy}
                        selectedLocation={selectedLocation}
                        displayToast={displayToast}
                        history={history}
                        location={location}
                    />
                )}
            />
        </Switch>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxSettingsWrapper);
