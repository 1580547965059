import React, { Fragment, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';

import ValueCalculator from './ValueCalculator';
import DigitalPlatform from './DigitalPlatform';
import CommunicationsPlatform from './CommunicationsPlatform';
import MobileDownloads from './MobileDownloads';
import WebsiteTraffic from './WebsiteTraffic';
import Newsletter from './Newsletter';
import DigitalPlatformPatients from './DigitalPlatformPatients';
import DigitalMarketing from './DigitalMarketing';
import ReputationManagement from './ReputationManagement';
import DirectMessaging from './DirectMessaging';
import tooltipText from 'constants/ReportsTooltipText';
import { LAYOUT_MAP, DEFAULT_LAYOUT } from 'constants/ReportsWidgetSequence';

const ResponsiveGridLayout = WidthProvider(Responsive);
const LAYOUT_REVERSE_MAP = _.invert(LAYOUT_MAP);

const NewReports = (props) => {
    const {
        digitalPlatformReports,
        communicationsPlatformReports,
        mobileDownloadsReports,
        websiteTrafficReports,
        newsletterReports,
        directMessagingReports,
        digitalMarketingReports,
        digitalPlatformPatientsReports,
        reputationManagementReports,
        digitalMarketingReportsEnabled,
        digitalPlatformReportsEnabledForMobile,
        digitalPlatformReportsEnabledForWeb,
        communicationPlatformReportsEnabled,
        ivrEnabled,
        mobileDownloadsReportsEnabled,
        websiteTrafficReportsEnabled,
        newsletterReportsEnabled,
        reputationManagementReportsEnabled,
        directMessagingReportsEnabled,
        flags: { npeReputationManagementNote, npeValueCalculatorEnabled },
        widgetSequence,
        updateWidgetSequence,
        pharmacyDataForValueCalculator,
    } = props;
    const isMobile = !useMediaQuery((theme) => theme.breakpoints.up('md'), { noSsr: true });
    const [layout, setLayout] = useState({});

    let isWidgetDragged = false;

    useEffect(() => {
        if (widgetSequence && widgetSequence.split(',').length) {
            applyOrderToLayout(widgetSequence.split(','));
        } else {
            !isMobile ? setLayout({ lg: DEFAULT_LAYOUT.lg }) : setLayout({ xs: DEFAULT_LAYOUT.xs });
        }
    }, [widgetSequence]);

    function constructOrderFromLayout(layout) {
        const sortedLayout = _.sortBy(layout, ['y', 'x']);
        const order = sortedLayout.map((item) => parseInt(LAYOUT_REVERSE_MAP[item.i]));
        applyOrderToLayout(order);
        updateWidgetSequence(order.toString());
        toggleDragStop();
    }

    function applyOrderToLayout(order) {
        const defaultLayout = _.cloneDeep(DEFAULT_LAYOUT);
        const currentLayout = !isMobile ? defaultLayout.lg : defaultLayout.xs;
        // need to take cnt outside to handle the scenario where if we remove one
        // of the widget from the LAYOUT_MAP and order still contains its index
        let cnt = 0;
        order.forEach((num) => {
            if (currentLayout[cnt] && LAYOUT_MAP[num]) {
                currentLayout[cnt].i = LAYOUT_MAP[num];
                cnt++;
            }
        });

        // need to add this fake entry to the layout to force react-grid-layout
        // to re-render the layout again.
        currentLayout.push({ i: 'dummy', x: 0, y: 0, w: 0, h: 0 });
        setLayout(!isMobile ? { lg: currentLayout } : { xs: currentLayout });

        setTimeout(() => {
            const removedLayout = _.filter(currentLayout, (item) => item.i !== 'dummy');
            setLayout(!isMobile ? { lg: removedLayout } : { xs: removedLayout });
        });
    }

    const onLayoutChange = (currentLayout) => {
        if (!_.isEmpty(layout) && !_.isEqual(currentLayout, layout) && isWidgetDragged) {
            constructOrderFromLayout(currentLayout);
        }
    };

    const toggleDragStop = () => {
        isWidgetDragged = !isWidgetDragged;
    };

    const onDragStop = () => {
        toggleDragStop();
    };

    return (
        <Fragment>
            <ResponsiveGridLayout
                className="layout"
                layouts={layout}
                isResizable={false}
                breakpoints={{ lg: 960, xs: 0 }}
                cols={{ lg: 12, xs: 2 }}
                onLayoutChange={onLayoutChange}
                onDragStop={onDragStop}
                rowHeight={30}
                draggableHandle=".drag-selector"
                compactType="vertical"
            >
                <div key="digitalPlatform">
                    <DigitalPlatform
                        data={digitalPlatformReports}
                        enabled={digitalPlatformReportsEnabledForMobile || digitalPlatformReportsEnabledForWeb}
                        tooltipText={tooltipText['digitalPlatform']}
                    />
                </div>
                <div key="valueCalculator">
                    <ValueCalculator
                        tooltipText={tooltipText['valueCalculator']}
                        ldValueCalculatorEnabled={npeValueCalculatorEnabled}
                        pharmacyData={pharmacyDataForValueCalculator}
                    />
                </div>
                <div key="communicationsPlatform">
                    <CommunicationsPlatform
                        data={communicationsPlatformReports}
                        enabled={communicationPlatformReportsEnabled && ivrEnabled}
                        tooltipText={tooltipText['communicationsPlatform']}
                    />
                </div>
                <div key="mobileDownloads">
                    <MobileDownloads
                        data={mobileDownloadsReports}
                        enabled={mobileDownloadsReportsEnabled}
                        tooltipText={tooltipText['mobileDownloads']}
                    />
                </div>
                <div key="websiteTraffic">
                    <WebsiteTraffic
                        data={websiteTrafficReports}
                        enabled={websiteTrafficReportsEnabled}
                        tooltipText={tooltipText['websiteTraffic']}
                    />
                </div>
                <div key="newsletter">
                    <Newsletter
                        data={newsletterReports || {}}
                        enabled={newsletterReportsEnabled}
                        tooltipText={tooltipText['newsletter']}
                    />
                </div>

                <div key="digitalPlatformPatients">
                    <DigitalPlatformPatients
                        data={digitalPlatformPatientsReports}
                        enabled={digitalPlatformReportsEnabledForMobile || digitalPlatformReportsEnabledForWeb}
                        tooltipText={tooltipText['digitalPlatformPatients']}
                    />
                </div>
                <div key="digitalMarketing">
                    <DigitalMarketing
                        data={digitalMarketingReports}
                        enabled={digitalMarketingReportsEnabled}
                        tooltipText={tooltipText['digitalMarketing']}
                    />
                </div>
                <div key="reputationManagement">
                    <ReputationManagement
                        data={reputationManagementReports}
                        enabled={reputationManagementReportsEnabled}
                        showNote={npeReputationManagementNote}
                        tooltipText={tooltipText['reputationManagement']}
                    />
                </div>
                <div key="directMessaging">
                    <DirectMessaging
                        data={directMessagingReports}
                        enabled={directMessagingReportsEnabled}
                        tooltipText={tooltipText['directMessaging']}
                    />
                </div>
            </ResponsiveGridLayout>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        flags: state.launchDarkly,
    };
};

export default connect(mapStateToProps)(NewReports);
