import React, { useRef } from 'react';
import { Cell, FontIcon, Collapse } from 'react-md';
import VoicemailList from 'components/Voicemail/VoicemailList';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';
import InfiniteScrollContainer from 'components/Common/InfiniteScrollContainer';

export const VoicemailContainer = (props) => {
    const {
        titleMsg,
        voicemails,
        type,
        totalMails,
        collapsed,
        toggleCollapse,
        sortColumn,
        sorting,
        noResult,
        loading,
        onLoadMore,
    } = props;
    const emptyClass = voicemails.length ? '' : '-empty';
    const sectionTitleClass = 'flex-middle section-title';

    const infiniteScrollContainerRef = useRef(null);

    return (
        <div>
            <div className="voicemail-container-header">
                <div className="grid-container" style={{}}>
                    <div>
                        <h4
                            className={
                                voicemails.length > 0 ? `user-clickable ${sectionTitleClass}` : sectionTitleClass
                            }
                            onClick={toggleCollapse.bind(this, type)}
                        >
                            {`${titleMsg} (${voicemails.length}/${totalMails})`}
                            {voicemails.length > 0 && (
                                <span className="standard-margin-left-10 flex-middle">
                                    <FontIcon>{collapsed ? 'expand_more' : 'expand_less'}</FontIcon>
                                </span>
                            )}
                        </h4>
                    </div>
                </div>
            </div>
            <Collapse collapsed={voicemails.length > 0 ? (collapsed !== undefined ? collapsed : false) : false}>
                <div className="grid-container list-container" style={{ marginTop: noResult ? '14px' : 0 }}>
                    <Cell size={12} className="header-row">
                        <div className="grid-container">
                            <Cell
                                size={2}
                                phoneHidden
                                tabletHidden
                                className="flex-middle user-clickable phone"
                                onClick={sortColumn.bind(this, { type, column: 'phone' })}
                            >
                                Caller ID
                                <FontIcon>
                                    {sorting[type]['phone']
                                        ? sorting[type]['phone'] === 'desc'
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'
                                        : ''}
                                </FontIcon>
                            </Cell>
                            <Cell
                                size={2}
                                phoneHidden
                                tabletHidden
                                className="flex-middle user-clickable msg-type"
                                onClick={sortColumn.bind(this, { type, column: 'msgType' })}
                            >
                                Type
                                <FontIcon>
                                    {sorting[type]['msgType']
                                        ? sorting[type]['msgType'] === 'desc'
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'
                                        : ''}
                                </FontIcon>
                            </Cell>
                            <Cell size={3} phoneHidden tabletHidden className="flex-middle">
                                Transcription
                            </Cell>
                            <Cell size={2} phoneHidden tabletHidden className="flex-middle"></Cell>

                            <Cell
                                size={3}
                                phoneHidden
                                tabletHidden
                                className="flex-middle flex-right user-clickable call-date"
                                onClick={sortColumn.bind(this, { type, column: 'callDateUTC' })}
                            >
                                Date/Time
                                <FontIcon>
                                    {sorting[type]['callDateUTC']
                                        ? sorting[type]['callDateUTC'] === 'desc'
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'
                                        : ''}
                                </FontIcon>
                            </Cell>
                        </div>
                    </Cell>
                    <div ref={infiniteScrollContainerRef} className="table-content">
                        <Cell size={12} className={`content-centered ${type}${emptyClass}-list`}>
                            {loading ? (
                                <TabularTypePlaceholder legendsCount={5} />
                            ) : (
                                voicemails.length > 0 && (
                                    <InfiniteScrollContainer
                                        ref={infiniteScrollContainerRef}
                                        onScrollBottom={onLoadMore}
                                        offset={10}
                                    >
                                        <VoicemailList {...props} />
                                    </InfiniteScrollContainer>
                                )
                            )}
                        </Cell>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};
