import './WaitlistStatusIcon.sass';
import React from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';
import BlockIcon from '@material-ui/icons/Block';
import MessageIcon from '@material-ui/icons/Message';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import classnames from 'classnames';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

export default function WaitlistStatusIcon({ status }) {
    return (
        <span className={classnames({ 'waitlist-status-icon': true, [_.toLower(status)]: true })}>
            {status === 'Scheduled' ? (
                <Tooltip title="Patient has scheduled appointment">
                    <EventIcon />
                </Tooltip>
            ) : status === 'Declined' ? (
                <Tooltip title="Patient has declined to schedule">
                    <BlockIcon />
                </Tooltip>
            ) : status === 'Contacted' ? (
                <Tooltip title="Patient has been contacted but has not been scheduled">
                    <MessageIcon />
                </Tooltip>
            ) : status === 'Uncontacted' ? (
                <Tooltip title="Patient has not yet been contacted regarding this waitlist">
                    <AssignmentIndIcon />
                </Tooltip>
            ) : (
                <Tooltip title="Known pharmacy patient (not on waitlist)">
                    <LocalPharmacyIcon />
                </Tooltip>
            )}
        </span>
    );
}
