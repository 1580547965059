import React from 'react';
import { Can, userActions, restrictedResources } from 'casl';
import NoAccess from 'components/Common/NoAccess';
import Leads from './Leads';

function LeadsWrapper() {
    const { view } = userActions;
    const { subject: caslSubject } = restrictedResources.leads;

    return (
        <Can I={view} this={caslSubject} passThrough>
            {(allowed) =>
                allowed ? (
                    <Leads />
                ) : (
                    <div className="leads-container" style={{ width: '100%' }}>
                        <NoAccess />
                    </div>
                )
            }
        </Can>
    );
}

export default LeadsWrapper;
