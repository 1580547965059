import moment from 'moment';
import _ from 'lodash';

import { newsletterTypes } from './type';
import { CARDS_PAGE_SIZE, MAX_ROWS_PER_PAGE } from 'constants/Newsletter';

export const initialState = {
    savingNewsletterSettings: false,
    drawerState: null,
    activeSubscriber: null,
    savingSubscriber: false,
    deletingSubscriber: false,
    loadingSubscribers: false,
    loadingHistory: false,
    subscribersList: [],
    subscribersListState: {
        page: 1,
        rowsPerPage: MAX_ROWS_PER_PAGE,
        searchString: '',
    },
    historyList: [],
    newsletterList: [],
    totalSubscribers: 0,
    totalHistory: 0,
    activeEvent: {},
    activeCard: {
        id: null,
        title: '',
        imageData: null,
        header: '',
        additionalText: '',
        buttonText: '',
        buttonLink: '',
    },
    isActiveCard: false,
    isActiveNewsletter: false,
    activeNewsletter: {
        date_sent: null,
        email: '',
        message_id: null,
        recipients: null,
        bounces: null,
        unsubscribes: null,
        emailHtml: '',
    },
    recentCards: {
        cards: [],
    },
    searchedCards: {
        cards: [],
    },
    fetchingCards: false,
    savingCard: false,
    deletingCard: false,
    fetchingNewsletter: false,
    updatingEventDetails: false,
    refetchSubscribersFromStart: false,
    loadingNewsletterDetails: false,
    refetchNewsletters: false,
    refetchingNewsletter: false,
};

/*
 * We officially send newsletters on Tuesdays. If we schedule/send on any other day, it should
 * be attributed to the prior Tuesday in the UI.
 * */
export const getNominalDate = (scheduled_date) => {
    const tuesday = 2;
    return moment(scheduled_date).day(moment(scheduled_date).day() >= tuesday ? tuesday : tuesday - 7);
};

const setNewsletter = (state, payload) => {
    const { start_date, end_date } = payload.dateRange;
    const newsletterList = [];

    let tuesday = moment(start_date).day('Tuesday');
    if (moment(tuesday).isBefore(moment(start_date).startOf('month'))) {
        tuesday = moment(tuesday).add(7, 'days');
    }

    while (moment(tuesday).isSameOrBefore(moment(end_date))) {
        /* eslint-disable */
        const existing = payload.resp.newsletters.find((newsletter) => {
            // scheduled_date has no time zone information when arriving from the back-end e.g. `2022-03-01T00:00:00`
            // the utcOffset needs to be same as for the `tuesday` date object or isSame comparison won't work
            // https://github.com/moment/moment/issues/3957
            // Passing true for utcOffset - keeps the original time for the date (00:00:00), no conversion
            const scheduledDate = moment(newsletter.scheduled_date).utcOffset(tuesday.utcOffset(), true);

            const nominalScheduledDate = getNominalDate(scheduledDate);

            return nominalScheduledDate.isSame(tuesday, 'day');
        });
        /* eslint-enable */
        const nextWeekTuesday = moment().startOf('week').add(1, 'week').add(2, 'days');
        const type = _.get(existing, 'status', 'draft');
        let cards = [];

        if (existing && existing.cards) {
            cards = _.filter(existing.cards, (card) => !_.isNil(card.id));
        }

        newsletterList.push({
            id: existing ? existing.pharmacy_newsletter_id : null,
            title: 'Weekly eNewsletter',
            type: type === 'draft' && moment(tuesday).isBefore(moment(), 'days') ? 'notsent' : type.toLowerCase(),
            start: tuesday,
            end: tuesday,
            next: moment(tuesday).isSame(nextWeekTuesday, 'day'),
            cards,
        });
        tuesday = moment(tuesday).add(7, 'days');
    }

    return {
        ...state,
        newsletterList,
        fetchingNewsletter: false,
        refetchNewsletters: false,
        refetchingNewsletter: false,
    };
};

const processRemoveSubscriber = (state, payload, processingFlag) => {
    const { subscribersList } = state;
    const matchingIdx = subscribersList.findIndex((subscriber) => subscriber.id === payload);
    subscribersList[matchingIdx].deleting = processingFlag;

    return {
        ...state,
        subscribersList,
    };
};

const removeSubscriber = (state, payload) => {
    let { subscribersList } = state;

    const matchingIdx = subscribersList.findIndex((subscriber) => subscriber.id === payload.subscriberId);
    subscribersList[matchingIdx].deleting = false;
    if (_.get(payload, 'resp.success', false)) {
        subscribersList.splice(matchingIdx, 1);
    }

    return {
        ...state,
        subscribersList,
    };
};

export const newsletterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case newsletterTypes.RESET_NEWSLETTER_STORE:
            return {
                ...initialState,
            };
        case newsletterTypes.SAVE_NEWSLETTER_SETTINGS_REQUEST:
            return {
                ...state,
                savingNewsletterSettings: true,
            };
        case newsletterTypes.SAVE_NEWSLETTER_SETTINGS_SUCCESS:
        case newsletterTypes.SAVE_NEWSLETTER_SETTINGS_FAILED:
            return {
                ...state,
                savingNewsletterSettings: false,
            };
        case newsletterTypes.SET_DRAWER_STATE:
            return {
                ...state,
                drawerState: payload.state,
            };
        case newsletterTypes.SET_ACTIVE_SUBSCRIBER:
            return {
                ...state,
                activeSubscriber: payload.activeSubscriber,
            };
        case newsletterTypes.RESET_ACTIVE_SUBSCRIBER:
            return {
                ...state,
                activeSubscriber: null,
            };
        case newsletterTypes.ADD_SUBSCRIBER_REQUEST:
            return {
                ...state,
                savingSubscriber: true,
            };
        case newsletterTypes.ADD_SUBSCRIBER_SUCCESS:
            return {
                ...state,
                savingSubscriber: false,
                refetchSubscribersFromStart: true,
            };
        case newsletterTypes.ADD_SUBSCRIBER_FAILED:
            return {
                ...state,
                savingSubscriber: false,
            };
        case newsletterTypes.DELETE_SUBSCRIBER_REQUEST:
            return processRemoveSubscriber(state, payload, true);
        case newsletterTypes.DELETE_SUBSCRIBER_SUCCESS:
            return removeSubscriber(state, payload);
        case newsletterTypes.DELETE_SUBSCRIBER_FAILED:
            return processRemoveSubscriber(state, payload, false);
        case newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_REQUEST:
            return {
                ...state,
                subscribersList: [],
                loadingSubscribers: true,
            };
        case newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_SUCCESS:
            return {
                ...state,
                subscribersList: payload.subscribers,
                totalSubscribers: payload.total_subscribers,
                totalSubscribed: payload.total_subscribed,
                loadingSubscribers: false,
                refetchSubscribersFromStart: false,
            };
        case newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_FAILED:
            return {
                ...state,
                loadingSubscribers: false,
                refetchSubscribersFromStart: false,
            };
        case newsletterTypes.UPDATE_NEWSLETTER_SUBSCRIBER_STATE:
            return {
                ...state,
                subscribersListState: payload,
            };
        case newsletterTypes.FETCH_NEWSLETTER_HISTORY_REQUEST:
            return {
                ...state,
                totalHistory: 0,
                loadingHistory: true,
            };
        case newsletterTypes.FETCH_NEWSLETTER_HISTORY_SUCCESS:
            return {
                ...state,
                historyList: payload.sent_emails,
                totalHistory: payload.total_emails,
                loadingHistory: false,
            };
        case newsletterTypes.FETCH_NEWSLETTER_HISTORY_FAILED:
            return {
                ...state,
                loadingHistory: false,
            };
        case newsletterTypes.SET_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: {
                    ...payload.activeEvent,
                },
            };
        case newsletterTypes.RESET_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: {
                    ...initialState.activeEvent,
                },
            };
        case newsletterTypes.SET_ACTIVE_CARD:
            return {
                ...state,
                activeCard: {
                    ...state.activeCard,
                    ...payload.activeCard,
                },
                isActiveCard: true,
            };
        case newsletterTypes.RESET_ACTIVE_CARD:
            return {
                ...state,
                activeCard: {
                    ...initialState.activeCard,
                },
                isActiveCard: false,
            };
        case newsletterTypes.FETCH_NEWSLETTER_DETAILS_REQUEST:
            return {
                ...state,
                loadingNewsletterDetails: true,
            };
        case newsletterTypes.FETCH_NEWSLETTER_DETAILS_SUCCESS:
            const {
                sent_date,
                sender_email,
                email_message_id,
                stats: { recipient_count, bounce_count, unsubscribe_count },
                content,
            } = payload;

            return {
                ...state,
                loadingNewsletterDetails: false,
                activeNewsletter: {
                    date_sent: sent_date,
                    email: sender_email,
                    message_id: email_message_id,
                    recipients: recipient_count,
                    bounces: bounce_count,
                    unsubscribes: unsubscribe_count,
                    emailHtml: content,
                },
            };
        case newsletterTypes.FETCH_NEWSLETTER_DETAILS_FAILED:
            return {
                ...state,
                loadingNewsletterDetails: false,
            };
        case newsletterTypes.SET_ACTIVE_NEWSLETTER:
            return {
                ...state,
                isActiveNewsletter: true,
            };
        case newsletterTypes.RESET_ACTIVE_NEWSLETTER:
            return {
                ...state,
                isActiveNewsletter: false,
            };
        case newsletterTypes.FETCH_CARDS_REQUEST:
            if (!payload.isLoadMore && payload.isSearchRequest) {
                return {
                    ...state,
                    searchedCards: initialState.searchedCards,
                    fetchingCards: true,
                };
            }
            return {
                ...state,
                fetchingCards: true,
            };
        case newsletterTypes.FETCH_CARDS_SUCCESS:
            const { total_cards } = payload.response;
            const total_pages = Math.ceil(total_cards / CARDS_PAGE_SIZE);

            if (payload.isSearchResults) {
                return {
                    ...state,
                    searchedCards: {
                        ...payload.response,
                        cards: payload.isLoadMore
                            ? _.uniqBy(_.concat(state.searchedCards.cards, payload.response.cards), 'card_id')
                            : payload.response.cards,
                        total_pages,
                    },
                    fetchingCards: false,
                };
            }
            return {
                ...state,
                recentCards: {
                    ...payload.response,
                    cards: payload.isLoadMore
                        ? _.uniqBy(_.concat(state.recentCards.cards, payload.response.cards), 'card_id')
                        : payload.response.cards,
                    total_pages,
                },
                fetchingCards: false,
            };
        case newsletterTypes.FETCH_CARDS_FAILED:
            return {
                ...state,
                fetchingCards: false,
            };
        case newsletterTypes.RESET_CARDS:
            return {
                ...state,
                searchedCards: initialState.searchedCards,
                recentCards: initialState.recentCards,
            };
        case newsletterTypes.ADD_CARDS_REQUEST:
            return {
                ...state,
                savingCard: true,
            };
        case newsletterTypes.ADD_CARDS_SUCCESS:
        case newsletterTypes.ADD_CARDS_FAILED:
            return {
                ...state,
                savingCard: false,
            };
        case newsletterTypes.FETCH_NEWSLETTER_REQUEST:
            return {
                ...state,
                newsletterList: [],
                fetchingNewsletter: true,
                refetchingNewsletter: payload.isRefetch,
            };
        case newsletterTypes.FETCH_NEWSLETTER_SUCCESS:
            return setNewsletter(state, payload);
        case newsletterTypes.FETCH_NEWSLETTER_FAILED:
            return {
                ...state,
                fetchingNewsletter: false,
                refetchNewsletters: false,
            };
        case newsletterTypes.SAVE_EVENT_DETAILS_REQUEST:
            return {
                ...state,
                updatingEventDetails: true,
            };
        case newsletterTypes.SAVE_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                updatingEventDetails: false,
                refetchNewsletters: true,
            };
        case newsletterTypes.SAVE_EVENT_DETAILS_FAILED:
            return {
                ...state,
                updatingEventDetails: false,
            };
        case newsletterTypes.SET_DELETE_CARD_STATE:
            return {
                ...state,
                deletingCard: payload.state === 'fetching',
                refetchNewsletters: payload.state === 'success',
            };
        default:
            return state;
    }
};
