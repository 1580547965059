// To make a box responsive, the solution is to have an outer box placed
// relatively with the 16:9 aspect ratio as padding top. And an inner box
// absolutely positioned with 100% width and height
export default {
    outerbox: {
        padding: '56.25% 0px 0px 0px',
        position: 'relative',
    },
    innerbox: {
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: '#DCDCDC',
    },
};
