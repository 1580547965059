import _ from 'lodash';
import { AbilityBuilder, Ability, detectSubjectType } from '@casl/ability';
import { restrictedResources } from '../RestrictedResources';
import { constants } from '../Constants';

export class BaseAbilityBuilder extends AbilityBuilder {
    // Note, since we are extending AbilityBuilder, we no longer have to do the following in any of our classes
    // const { can, cannot, rules } = new AbilityBuilder();

    constructor() {
        super();
        this.restrictedResources = restrictedResources;

        // These methods will be overridden in derived classes as desired
        this.constructHiddenFieldsArray();
        this.constructReadOnlyFieldsArray();
        this.constructNonClickableFieldsArray();
    }

    // For details, read: https://stalniy.github.io/casl/v4/en/guide/subject-type-detection
    detectAppSubjectType = (subject) => {
        if (process.env.NODE_ENV === 'development' && _.isObject(subject) && _.isEmpty(subject.caslSubject)) {
            throw new Error('Missing caslSubject in the argument for Can component.');
        }

        return _.get(subject, 'caslSubject', detectSubjectType(subject));
    };

    constructHiddenFieldsArray() {
        this.hiddenFields = [];
    }

    constructReadOnlyFieldsArray() {
        this.readOnlyFields = [];
    }

    constructNonClickableFieldsArray() {
        this.nonClickableFields = [];
    }

    optOutReadOnlyFields() {
        const { create, update, write, delete: _delete, start, send } = constants.userActions;
        _.forEach(this.readOnlyFields, (readOnlyField) => {
            _.isEmpty(readOnlyField.fields)
                ? this.cannot([create, write, update, _delete, start, send], readOnlyField.subject)
                : this.cannot(
                      [create, write, update, _delete, start, send],
                      readOnlyField.subject,
                      readOnlyField.fields
                  );
        });
    }

    optOutHiddenFields() {
        const { manage } = constants.userActions;
        _.forEach(this.hiddenFields, (hiddenField) => {
            _.isEmpty(hiddenField.fields)
                ? this.cannot(manage, hiddenField.subject)
                : this.cannot(manage, hiddenField.subject, hiddenField.fields);
        });
    }

    optOutNonClickableFields() {
        const { click } = constants.userActions;
        _.forEach(this.nonClickableFields, (nonClickableField) => {
            _.isEmpty(nonClickableField.fields)
                ? this.cannot(click, nonClickableField.subject)
                : this.cannot(click, nonClickableField.subject, nonClickableField.fields);
        });
    }

    build() {
        const { all, userActions } = constants;
        const { read, view, manage } = userActions;
        const { login } = this.restrictedResources;

        this.cannot(manage, all);
        // Note: The can is added to avoid the following warning. This is not being consumed by the system.
        // Warning: Make sure your ability has direct rules, not only inverted ones. Otherwise `ability.can` will always return `false`.
        this.can([read, view], login.subject, _.values(login.fields));

        return new Ability(this.rules, { detectSubjectType: this.detectAppSubjectType });
    }
}
