import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import UserAudit from './UserAudit';
import { userPropType, getUserName } from './helper';

const UserAuditDialog = ({ open, onClose, user, getAuditLogs }) => {
    if (!open || !user) {
        return null;
    }

    const userName = getUserName(user);
    const dialogHeading = `User logs for ${userName}`;

    return (
        <DpDialog.Dialog id="user-audit-dialog" open={open} onClose={onClose}>
            <DpDialog.Content style={{ minHeight: '15%' }}>
                <DpDialog.ContentHeader heading={dialogHeading} onClose={onClose} />
                <div style={{ paddingTop: '12px' }}>
                    <UserAudit user={user} getAuditLogs={getAuditLogs} />
                </div>
            </DpDialog.Content>
            <DpDialog.Actions>
                <Button autoFocus onClick={onClose} color="primary">
                    Close
                </Button>
            </DpDialog.Actions>
        </DpDialog.Dialog>
    );
};

UserAuditDialog.propTypes = {
    user: userPropType,
    open: PropTypes.bool.isRequired,
    getAuditLogs: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default UserAuditDialog;
