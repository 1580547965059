import React from 'react';
import { Grid } from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';

export const GraphTypePlaceholder = ({ legendsCount }) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={7}>
                <RectPlaceHolder
                    style={{
                        height: '300px',
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                {new Array(legendsCount).fill(0).map((item, idx) => (
                    <RectPlaceHolder
                        key={idx}
                        style={{
                            height: '50px',
                            marginTop: '20px',
                        }}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

const RectPlaceHolder = ({ style }) => (
    <ReactPlaceholder type="rect" rows={1} ready={false} showLoadingAnimation firstLaunchOnly style={style}>
        <div></div>
    </ReactPlaceholder>
);

export default GraphTypePlaceholder;
