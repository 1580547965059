import React, { Component } from 'react';
import { Card, CardText } from 'react-md';
import _ from 'lodash';

import TabularData from 'components/Common/TabularData';
import EmptyCard from './EmptyCard';
import { mapTabularDataWithObject, sortByKeys } from './util.js';
import ReportTitle from 'components/Report/Common/ReportTitle';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';

const ATTRIBUTES = ['Impressions', 'Reactions', 'Link Clicks', 'Engagements'];

const TITLES = ['7 Days', '30 Days', 'All Time'];

const SORTED_KEYS = ['impressions', 'reactions', 'link_clicks', 'engagements'];

const DataCard = ({ data, showLoader, tooltipText }) => (
    <Card className="reportCard">
        <ReportTitle title="Digital Marketing" className="cardTitle" tooltipText={tooltipText} />
        <CardText className={showLoader ? 'flex-grow-1 flex-middle' : 'text-align-center flex-grow-1'}>
            {!showLoader ? <TabularData data={data} /> : <TabularTypePlaceholder legendsCount={3} />}
        </CardText>
    </Card>
);

export default class DigitalMarketing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: {},
        };
    }

    init() {
        const { data } = this.props;
        const isDaysDataPresent = Boolean(_.keys(data.last7Days).length);
        const isMonthsDataPresent = Boolean(_.keys(data.last30Days).length);
        const isAllTimeDataPresent = Boolean(_.keys(data.allTime).length);

        if (isDaysDataPresent && isMonthsDataPresent && isAllTimeDataPresent) {
            const mapData = mapTabularDataWithObject(
                {
                    data: {
                        data: [
                            sortByKeys(data.last7Days, SORTED_KEYS),
                            sortByKeys(data.last30Days, SORTED_KEYS),
                            sortByKeys(data.allTime, SORTED_KEYS),
                        ],
                    },
                },
                ATTRIBUTES,
                TITLES
            );
            this.setState({ mapData });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ data: oldData }) {
        const { data } = this.props;
        if (!_.isEqual(data, oldData)) {
            this.setState(
                {
                    mapData: {},
                },
                () => this.init()
            );
        }
    }

    render() {
        const { enabled, tooltipText } = this.props;
        const { mapData } = this.state;
        const showLoader = Object.keys(mapData).length === 0;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Digital Marketing"
                    imgPath="/reports/digital_marketing.png"
                    helperText="You don’t have Digital Marketing yet."
                    appType="digitalMarketing"
                    tooltipText={tooltipText}
                />
            );
        }
        return <DataCard data={mapData} showLoader={showLoader} tooltipText={tooltipText} />;
    }
}
