import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, ListItem, FontIcon, CircularProgress, DataTable, TableRow, TableColumn, TableBody } from 'react-md';

import { SectionTitle } from '.';
import { ConfigureNavigateButton } from 'components/Common';
import LearnMoreButton from 'components/Common/LearnMoreButton';
import { Can, userActions } from 'casl';
import { connect } from 'react-redux';

const configureButtonPathMap = {
    general_menu_options: '/settings/phone-system/main-greeting',
};

const configureButtonInfoText = {
    general_menu_options:
        'In order to configure your menu options, a default greeting needs to be added in the General Greeting page.',
};

const getMenuItemKey = (text) => text.toLowerCase().replace(/ /g, '_');

function ListComponentItem({ item, loading, history, onClick, flags, isInternalUser }) {
    const internalUserBaseText = 'Internal Users Only';
    const internalUserHeaderPrimaryTextAddon = item.isInternal ? ` (${internalUserBaseText})` : '';
    const internalUserHeaderSecondaryText = item.isInternal ? internalUserBaseText : '';
    const ldAccessCheck = item.ldFlag ? flags[item.ldFlag] : true; // default to true if no ld flag is provided

    if (!ldAccessCheck && !isInternalUser) {
        return null;
    }

    return (
        <TableRow>
            <TableColumn className="no-table-column-padding">
                {item.showConfigureButton || item.learnMore ? (
                    <div className="md-list-tile table-list flex-middle flex-space-between">
                        <div className={`md-tile-text--primary md-text ${item.learnMore ? 'disabled' : ''}`}>
                            {item.text}
                            {internalUserHeaderPrimaryTextAddon}
                        </div>
                        <div>
                            {loading ? (
                                <CircularProgress id="remove-aa-menu-item-spinner" />
                            ) : item.learnMore ? (
                                <LearnMoreButton appType={item.learnMoreAppType} variant="tabs" />
                            ) : (
                                <ConfigureNavigateButton
                                    history={history}
                                    navURL={configureButtonPathMap[getMenuItemKey(item.text)]}
                                    tooltipText={configureButtonInfoText[getMenuItemKey(item.text)]}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <ListItem
                        className="table-list"
                        rightIcon={<FontIcon> {item.icon.right} </FontIcon>}
                        primaryText={item.text}
                        secondaryText={internalUserHeaderSecondaryText}
                        onClick={() => onClick(item.to)}
                    />
                )}
            </TableColumn>
        </TableRow>
    );
}

ListComponentItem.propTypes = {
    item: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onLearnMoreClick: PropTypes.func, // Only require if item.learnMore is true
};

export const ListComponent = (props) => (
    <div className={props.className}>
        {props.title ? <SectionTitle text={props.title} /> : ''}
        <Card tableCard className="inner-card ">
            <DataTable plain>
                <TableBody>
                    {props.list.map((item, idx) => (
                        <Fragment key={idx}>
                            <Can I={userActions.read} this={item.caslSubject}>
                                <ListComponentItem
                                    item={item}
                                    history={props.history}
                                    loading={props.loading}
                                    onClick={props.onItemClick}
                                    onLearnMoreClick={props.learnMoreClick}
                                    flags={props.flags}
                                    isInternalUser={props.isInternalUser}
                                />
                            </Can>
                        </Fragment>
                    ))}
                </TableBody>
            </DataTable>
        </Card>
    </div>
);

ListComponent.propTypes = {
    title: PropTypes.string,
    list: PropTypes.array.isRequired,
    onItemClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isInternalUser: state.auth.isInternalUser,
    flags: state.launchDarkly,
});

export default connect(mapStateToProps, null)(withRouter(ListComponent));
