import React from 'react';

import EmbeddedContent from '../EmbeddedContent';
import CourseContentUrls from 'constants/CourseContentUrls';

const GDocsDocument = ({ document }) => {
    const { prefixes, suffixes } = CourseContentUrls;

    return (
        <EmbeddedContent
            id={`document-${document.id}`}
            src={`${prefixes.gdocs.document}${document.id}${suffixes.gdocs.document}`}
        />
    );
};

export default GDocsDocument;
