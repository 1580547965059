import './AgeFilterPopover.sass';
import React, { useRef, useEffect } from 'react';
import { TextField, Select, MenuItem } from '@material-ui/core';
import _ from 'lodash';

export default function AgeFilterPopover({ age, isOlder, onChange }) {
    const ageInputRef = useRef();

    useEffect(() => {
        if (!_.isNil(ageInputRef.current)) {
            ageInputRef.current.value = !_.isFinite(age) ? '' : String(age);
        }
    }, [age]);

    const debouncedOnChange = _.debounce((o) => onChange(o), 350);

    return (
        <div className="filter-popover age-filter-popover">
            <TextField
                inputRef={ageInputRef}
                autoFocus
                type="number"
                className="year-input"
                variant="outlined"
                defaultValue={!_.isFinite(age) ? '' : String(age)}
                placeholder="Age"
                onChange={(e) => {
                    const age = _.isEmpty(e.target.value.trim()) ? null : parseInt(e.target.value);
                    debouncedOnChange({ age, isOlder });
                }}
            />
            <Select
                className="older-younger-select"
                value={isOlder ? 'older' : 'younger'}
                variant="outlined"
                onChange={(e) => {
                    const isOlder = e.target.value === 'older';
                    onChange({ age, isOlder });
                }}
            >
                <MenuItem value="older">years and older</MenuItem>
                <MenuItem value="younger">years and younger</MenuItem>
            </Select>
        </div>
    );
}
