import React, { Fragment, PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Settings from 'components/Settings/index';
import Patients from 'components/Patients/index';
import Connect from 'components/Patients/Connect/index';
import Inbox from 'components/Inbox';
import Voicemails from 'components/Voicemail';
import Report from 'components/Report';
import Refills from 'components/Refill';
import Leads from 'components/Leads';
import Reviews from 'components/Resources/Reviews';
import Classes from 'components/Resources/Classes';
import PharmacyContractReceived from 'components/PharmacyContractReceived';
import PharmacyContract from 'components/PharmacyContract';
import SubscriberNotice from 'components/Resources/SubscriberNotice';
import Newsletter from 'components/Resources/Newsletter';
import PharmacyContractActions from 'redux/PharmacyContract/action';
import { HomePage } from 'components/Home';
import { isContractSignedForLocation } from 'utils/helper';
import AppointmentsPage from './Appointments/AppointmentsPage';

class ProtectedRoutes extends PureComponent {
    showPharmacyContract = () => {
        const {
            userDetails,
            isInternalUser,
            pharmacyContract,
            activeLocationId,
            flags: { npePharmacyContractPage },
        } = this.props;
        const authDetailsForLocation = _.find(userDetails, (e) => e.entityId === activeLocationId) || {};
        const isOwner = !isInternalUser && authDetailsForLocation.role === 'Owner';
        return isOwner && npePharmacyContractPage && !isContractSignedForLocation(pharmacyContract, activeLocationId);
    };

    closePharmacyContract = () => {
        const { skipSigningContract, activeLocationId } = this.props;
        skipSigningContract(activeLocationId);
    };

    render() {
        const { pharmacyContract, isAuthorized } = this.props;

        if (isAuthorized && this.showPharmacyContract() && !pharmacyContract.isLoading) {
            return <PharmacyContract showPharmacyContract={true} closePharmacyContract={this.closePharmacyContract} />;
        }

        return (
            <Fragment>
                <Route path="/home" component={HomePage} />
                <Route path="/settings" component={Settings} />
                <Route path="/patients" component={Patients} />
                <Route path="/workflows" component={Connect} />
                <Route path="/appointments" component={AppointmentsPage} />

                <Route path="/inbox" component={Inbox} />
                <Route path="/voicemail" component={Voicemails} />
                <Route path="/reports" component={Report} />
                <Route path="/refills" component={Refills} />
                <Route path="/leads" component={Leads} />
                <Route path="/classes" component={Classes} />
                <Route path="/reviews" component={Reviews} />
                <Route path="/contract-received" component={PharmacyContractReceived} />
                <Route path="/notice" component={SubscriberNotice} />
                <Route path="/newsletter" component={Newsletter} />

                <Route
                    path="/rxconfer"
                    render={() => {
                        // Redirect any /rxconfer paths to the equivalent /workflows path
                        const params = _.isEmpty(window.location.search) ? '' : `?${window.location.search}`;
                        return <Redirect to={window.location.pathname.replace('rxconfer', 'workflows') + params} />;
                    }}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
        isInternalUser: state.auth.isInternalUser,
        isAuthorized: state.auth.isAuthorized,
        pharmacyContract: state.pharmacyContract,
        activeLocationId: state.pharmacy.activeLocationId,
        flags: state.launchDarkly,
    };
};

const mapDispatchToProps = {
    skipSigningContract: PharmacyContractActions.skipSigningContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);
