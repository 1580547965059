import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import FormRowTextFields from './FormRowTextFields';

export const BranchDetails = (props) => {
    const {
        app: { branch },
        editMode,
        handleTextChange,
        panelId,
    } = props;
    const onTextChange = (event) => handleTextChange(event, panelId);

    return (
        <div className="collapse-content">
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'branchAppId',
                            value: _.get(branch, 'branchAppId.value', ''),
                            label: 'Branch App ID',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'branchAppId.required', false),
                            error: _.get(branch, 'branchAppId.error', ''),
                        }}
                        field2={{
                            key: 'branchAppName',
                            value: _.get(branch, 'branchAppName.value', ''),
                            label: 'Branch App Name',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'branchAppName.required', false),
                            error: _.get(branch, 'branchAppName.error', ''),
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'title',
                            value: _.get(branch, 'title.value', ''),
                            label: 'Branch App Title',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'title.required', false),
                            error: _.get(branch, 'title.error', ''),
                        }}
                        field2={{
                            key: 'description',
                            value: _.get(branch, 'description.value', ''),
                            label: 'Branch App Description',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'description.required', false),
                            error: _.get(branch, 'description.error', ''),
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'linkDomain',
                            value: _.get(branch, 'linkDomain.value', ''),
                            label: 'Branch Link Domain',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'linkDomain.required', false),
                            error: _.get(branch, 'linkDomain.error', ''),
                        }}
                        field2={{
                            key: 'uriScheme',
                            value: _.get(branch, 'uriScheme.value', ''),
                            label: 'Branch URI Scheme',
                            allowTextCopy: true,
                            disabled: !editMode,
                            required: _.get(branch, 'uriScheme.required', false),
                            error: _.get(branch, 'uriScheme.error', ''),
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <FormRowTextFields
                        field1={{
                            key: 'liveKey',
                            value: _.get(branch, 'liveKey.value', ''),
                            label: 'Branch Live Key',
                            allowTextCopy: true,
                            isPassword: true,
                            disabled: !editMode,
                            required: _.get(branch, 'liveKey.required', false),
                            error: _.get(branch, 'liveKey.error', ''),
                        }}
                        field2={{
                            key: 'liveSecret',
                            value: _.get(branch, 'liveSecret.value', ''),
                            label: 'Branch Live Secret',
                            allowTextCopy: true,
                            isPassword: true,
                            disabled: !editMode,
                            required: _.get(branch, 'liveSecret.required', false),
                            error: _.get(branch, 'liveSecret.error', ''),
                        }}
                        handleTextChange={onTextChange}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
