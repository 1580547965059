import React from 'react';
import PropTypes from 'prop-types';
import { ReviewCard, FooterText, ReviewRating, questionMap } from './ReviewCommon';

function ReviewAverage({ average, loading }) {
    const ratings = [];
    const ratingsComponentList = [];

    Object.keys(average).forEach((question) => {
        ratings.push(average[question]);
        ratingsComponentList.push(
            <ReviewRating
                key={question}
                question={questionMap[question]}
                rating={average[question]}
                showRatingNumber={true}
            />
        );
    });

    const overallAverage = ratings.length > 0 ? (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1) : 0;

    return (
        <ReviewCard
            title="Average Ratings"
            footer={FooterText('Overall Rating', overallAverage)}
            loadingContent={loading}
        >
            <div className="review-average-rating-content">
                {ratingsComponentList.length > 0 ? ratingsComponentList : <span>No rating averages available</span>}
            </div>
        </ReviewCard>
    );
}

ReviewAverage.propTypes = {
    average: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
};

export default ReviewAverage;
