export default {
    isCustPackageAttributeActive() {
        const custPackageAttribute = this.getCustPackageAttribute();
        return custPackageAttribute && (custPackageAttribute.value === 1 || custPackageAttribute.value === '1');
    },

    getPackageAttrUpdatedWithStatus(status) {
        const custPackageAttribute = { ...this.getCustPackageAttribute() };
        custPackageAttribute.value = status === 'enabled' ? 1 : 0;
        return custPackageAttribute;
    },

    getAttributeValue() {
        // For booleans this value is going to be always 1 since getAttributeValue
        // is called while adding an attribute for the first time.
        return 1;
    },
};
