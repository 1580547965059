import './AppointmentBlockSelector.sass';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, Tooltip, IconButton, CircularProgress, Dialog } from '@material-ui/core';
import WhenToScheduleContent from '../schedule/WhenToScheduleContent';
import VaccineDoseInfoContent from '../schedule/VaccineDoseInfoContent';
import HelpIcon from '@material-ui/icons/Help';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AutoSizer, List } from 'react-virtualized';
import AppointmentBlockRow from './AppointmentBlockRow';
import { adaptReservationSessionToTimeRange } from '../schedule/ClinicalSchedulingContent';
import { formatTime } from '../schedulingUtil';
import { connect } from 'react-redux';
import WhenToScheduleMultiContent from '../schedule/WhenToScheduleMultiContent';

function AppointmentBlockSelector({
    apptReservation,
    apptCalendar,
    calendars,
    timeRange,
    reservationSession,
    appointmentLengthMinutes,
    getAvailableCalendarAppointmentSlots,
    clearAvailableAppointmentSlots,
    setDoseInfo,
    setCalendarId,
    calendarId,
    setTimeRange,
    modifyInDialog = false,
    apptTypeId = 'a448c312-cb31-4492-87fc-6e442a2f6118',
    showMultiApptUx,
}) {
    const props = arguments[0];

    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [editingSession, setEditingSession] = useState(null);
    const [priorAvailableSlots, setPriorAvailableSlots] = useState(null);

    const timeRangeSelected = !_.isNil(_.get(timeRange, 'from.date')) && !_.isNil(_.get(timeRange, 'to.date'));
    const calendar = _.find(calendars, ({ calendar_id }) => calendar_id === calendarId);

    const availableSlots = _.get(apptCalendar, 'availableCalendarSlots.response.slots', priorAvailableSlots);
    useEffect(() => {
        if (priorAvailableSlots !== availableSlots) {
            setPriorAvailableSlots(availableSlots);
        }
    }, [availableSlots]);
    const sortedDays = _.sortBy(_.keys(availableSlots));

    useEffect(() => {
        const current = _.get(apptCalendar, 'availableCalendarSlots', {});

        const loadIfChanged = (startDate, startTime, endDate, endTime, calendarId, appointmentLengthMinutes) => {
            const isDefined =
                !_.isNil(calendarId) && !_.isNil(startDate) && !_.isNil(endDate) && !_.isNil(appointmentLengthMinutes);

            if (
                isDefined &&
                (calendarId !== _.get(current, 'calendarId') ||
                    startDate !== _.get(current, 'startTime') ||
                    endDate !== _.get(current, 'endTime') ||
                    appointmentLengthMinutes !== _.get(current, 'appointmentLengthMinutes'))
            ) {
                getAvailableCalendarAppointmentSlots(
                    calendarId,
                    appointmentLengthMinutes,
                    startDate,
                    startTime,
                    endDate,
                    endTime
                );
            }
        };

        if (!_.isNil(reservationSession)) {
            const { start_date, end_date, start_time, end_time, expected_length, calendar_id } = reservationSession;

            loadIfChanged(start_date, start_time, end_date, end_time, calendar_id, expected_length);
        } else if (timeRangeSelected) {
            const adaptTime = ({ allDay, hour, minute }) => {
                if (allDay) {
                    return null;
                }
                return `${_.padStart(String(hour), 2, '0')}:${_.padStart(String(minute), 2, '0')}:00`;
            };

            const startDate = _.get(timeRange, 'from.date').format('YYYY-MM-DD');
            const startTime = adaptTime(_.get(timeRange, 'from'));
            const endDate = _.get(timeRange, 'to.date').format('YYYY-MM-DD');
            const endTime = adaptTime(_.get(timeRange, 'to'));

            loadIfChanged(
                startDate,
                startTime,
                endDate,
                endTime,
                _.get(calendar, 'calendar_id'),
                appointmentLengthMinutes
            );
        }
    }, [JSON.stringify(timeRange), reservationSession, appointmentLengthMinutes, calendarId]);

    // when a reservation session is set, update the timeRange to reflect it
    useEffect(() => {
        setTimeRange(adaptReservationSessionToTimeRange(reservationSession));
        setDoseInfo(_.get(reservationSession, 'metadata.covid_dose_info', null));
        setCalendarId(_.get(reservationSession, 'calendar_id'));
    }, [reservationSession]);

    useEffect(() => {
        // clear available appointment slots on unmount
        return () => {
            clearAvailableAppointmentSlots();
        };
    }, []);

    const activeReservationSessions = _.filter(
        _.get(apptReservation, 'liveReusableSessions.response.live_sessions'),
        ({ appt_type_id }) => appt_type_id === apptTypeId
    );
    const slotsById = _.get(apptReservation, 'liveReusableSessions.response.session_slots');

    if (!isCreatingNew && _.isNil(editingSession)) {
        return (
            <FromExistingAppointmentBlockSelector
                key={'normal'}
                {...props}
                {...{ isCreatingNew, setIsCreatingNew }}
                {...{ editingSession, setEditingSession }}
                activeReservationSessions={activeReservationSessions}
                slotsById={slotsById}
            />
        );
    }

    return modifyInDialog ? (
        <React.Fragment>
            <FromExistingAppointmentBlockSelector
                key={'under-dialog'}
                {...props}
                {...{ isCreatingNew, setIsCreatingNew }}
                {...{ editingSession, setEditingSession }}
                activeReservationSessions={activeReservationSessions}
                slotsById={slotsById}
                loadSessionsOnMount={false} // don't reload here since we already have done it
            />

            <Dialog
                open={true}
                maxWidth="lg"
                onClose={() => {
                    if (isCreatingNew) {
                        setIsCreatingNew(false);
                    } else if (!_.isNil(editingSession)) {
                        setEditingSession(null);
                    }
                }}
            >
                <div style={{ width: '900px' }}>
                    <CreateOrEditAppointmentBlockSelector
                        {...props}
                        {...{ isCreatingNew, setIsCreatingNew }}
                        {...{ editingSession, setEditingSession }}
                        activeReservationSessions={activeReservationSessions}
                        sortedDays={sortedDays}
                        availableSlots={availableSlots}
                        priorAvailableSlots={priorAvailableSlots}
                        timeRangeSelected={timeRangeSelected}
                        calendar={calendar}
                        onNext={() => {
                            if (isCreatingNew) {
                                setIsCreatingNew(false);
                            } else if (!_.isNil(editingSession)) {
                                setEditingSession(null);
                            }
                        }}
                    />
                </div>
            </Dialog>
        </React.Fragment>
    ) : (
        <CreateOrEditAppointmentBlockSelector
            {...props}
            {...{ isCreatingNew, setIsCreatingNew }}
            {...{ editingSession, setEditingSession }}
            activeReservationSessions={activeReservationSessions}
            sortedDays={sortedDays}
            availableSlots={availableSlots}
            priorAvailableSlots={priorAvailableSlots}
            timeRangeSelected={timeRangeSelected}
            calendar={calendar}
        />
    );
}

export function formatTimeRange({ start_date, start_time, end_date, end_time }, calendarTimezone) {
    if (_.isNil(start_date) && _.isNil(end_date)) {
        return 'Available anytime';
    } else {
    }

    const from = moment.tz(
        `${start_date} ${_.isNil(start_time) ? '00:00:00' : start_time}`,
        'YYYY-MM-DD HH:mm:ss',
        calendarTimezone
    );
    const to = _.isNil(end_time)
        ? moment.tz(`${end_date} 00:00:00`, 'YYYY-MM-DD HH:mm:ss', calendarTimezone)
        : moment.tz(`${end_date} ${end_time}`, 'YYYY-MM-DD HH:mm:ss', calendarTimezone);

    if (from.isSame(to, 'day')) {
        return from.format('MMM Do');
    }
    return `${from.format('MMM Do')} - ${to.format('MMM Do')}`;
}

function ConfirmDeleteOverlay({
    setShowDeleteConfirm,
    reservationSession,
    updateReservationSession,
    setEditingSession,
    setReservationSession,
    onAppointmentBlocksModified = () => {},
}) {
    const [deleting, setDeleting] = useState(false);
    return (
        <div className="confirm-delete-overlay appt-block-delete-overlay" onClick={() => setShowDeleteConfirm(false)}>
            <div className="confirm-delete">
                <div className="heading">Really delete this Appointment Block?</div>
                <div className="explanation">
                    Are you sure you want to completely remove this appointment block? This will remove the ability for
                    patients to schedule appointments on this block from messaging and from your public website?
                    <br />
                    <br />
                    This will not affect any appointments that have already been scheduled using this appointment block.
                </div>
                <div className="delete-buttons">
                    <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            setDeleting(true);

                            try {
                                await updateReservationSession(
                                    reservationSession.calendar_id,
                                    reservationSession.session_id,
                                    {
                                        ..._.cloneDeep(reservationSession),
                                        status: 'DELETED',
                                    }
                                );

                                setEditingSession(null);
                                setReservationSession(null);

                                onAppointmentBlocksModified();
                            } catch (error) {
                                // todo: handle error
                                setDeleting(false);
                            }
                        }}
                    >
                        {!deleting ? (
                            'Delete Appointment Block'
                        ) : (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={15} color="secondary" />
                                &nbsp;Deleting...
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function CreateOrEditAppointmentBlockSelector({
    isCreatingNew,
    setIsCreatingNew,
    editingSession,
    setEditingSession,
    setReservationSession,
    reservationSession,
    activeReservationSessions,
    modifyInDialog,
    createNewReservationSession,
    calendar,
    template,
    schedulingConfig,
    appointmentLengthMinutes,
    timeRange,
    doseInfo,
    updateReservationSession,
    history,
    onAppointmentBlocksModified = () => {},
    showMultiApptUx,
}) {
    const props = arguments[0];

    const [step, setStep] = useState('when');

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const originalReservationSession = _.isNil(reservationSession)
        ? null
        : _.find(activeReservationSessions, ({ session_id }) => session_id === reservationSession.session_id);

    const hasReservationSessionChanged = !_.isEqual(reservationSession, originalReservationSession);

    return (
        <div className="appointment-block-selector create-or-edit">
            <div className="main-label">
                <span>
                    {modifyInDialog || _.isEmpty(activeReservationSessions) ? null : (
                        <Tooltip title="Go back to choosing an existing appointment block.">
                            <IconButton
                                className="go-back-button"
                                onClick={() => {
                                    if (isCreatingNew) {
                                        setIsCreatingNew(false);
                                    } else if (!_.isNil(editingSession)) {
                                        setEditingSession(null);
                                        setReservationSession(null);
                                    }
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <span>{isCreatingNew ? 'Create a new Appointment Block' : 'Edit this Appointment Block'}</span>
                </span>

                <ApptBlockHelp />

                {_.isNil(reservationSession) ? null : (
                    <Button
                        className="delete-appt-block-button"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowDeleteConfirm(true)}
                    >
                        Delete this appointment block
                    </Button>
                )}
            </div>

            {step !== 'when' ? null : showMultiApptUx ? (
                <WhenToScheduleMultiContent
                    {...props}
                    onNext={() => setStep('vaccine/dose')}
                    isEdit={!_.isNil(reservationSession)}
                />
            ) : (
                <WhenToScheduleContent
                    {...props}
                    onNext={() => setStep('vaccine/dose')}
                    isEdit={!_.isNil(reservationSession)}
                />
            )}

            {step !== 'vaccine/dose' ? null : (
                <VaccineDoseInfoContent
                    {...props}
                    onBack={() => setStep('when')}
                    onNext={async () => {
                        if (isCreatingNew) {
                            try {
                                const response = await createNewReservationSession(_.get(calendar, 'calendar_id'), {
                                    calendar_id: _.get(calendar, 'calendar_id'),
                                    conversation_template_id: _.get(template, 'inbox_conversation_template_id'),
                                    status: 'ACTIVE',
                                    label: _.get(schedulingConfig, 'appointment_label', 'COVID-19 Vaccination'),
                                    expected_length: appointmentLengthMinutes,
                                    start_date: _.isNil(_.get(timeRange, 'from.date'))
                                        ? null
                                        : moment(_.get(timeRange, 'from.date')).format('YYYY-MM-DD'),
                                    end_date: _.isNil(_.get(timeRange, 'to.date'))
                                        ? null
                                        : moment(_.get(timeRange, 'to.date')).format('YYYY-MM-DD'),
                                    start_time: formatTime(_.get(timeRange, 'from')),
                                    end_time: formatTime(_.get(timeRange, 'to')),
                                    metadata: { covid_dose_info: doseInfo },
                                    total_reservations: _.get(doseInfo, 'number_of_doses', undefined),
                                    is_reusable: 1,
                                    available_on_web: 1,
                                });

                                setReservationSession(response);
                                setIsCreatingNew(false);

                                if (!_.isNil(template)) {
                                    history.push(
                                        `/workflows/${template.inbox_conversation_template_id}/scheduling/who`
                                    );
                                }

                                onAppointmentBlocksModified();
                            } catch (error) {
                                console.error('ERROR', error);
                                // todo: handle error here
                            }
                        } else {
                            try {
                                const response = await updateReservationSession(
                                    reservationSession.calendar_id,
                                    reservationSession.session_id,
                                    {
                                        ...reservationSession,
                                        metadata: {
                                            ...reservationSession.metadata,
                                            covid_dose_info: doseInfo,
                                            total_reservations: doseInfo.number_of_doses,
                                        },
                                    }
                                );
                                setReservationSession({
                                    ...response,
                                    total_reservations: reservationSession.total_reservations,
                                });
                                setEditingSession(null);

                                if (!_.isNil(template)) {
                                    history.push(
                                        `/workflows/${template.inbox_conversation_template_id}/scheduling/who`
                                    );
                                }

                                onAppointmentBlocksModified();
                            } catch (error) {
                                // todo: handle error here
                                console.log('ERROR', error);
                            }
                        }
                    }}
                    isCreatingNew={isCreatingNew}
                    hasReservationSessionChanged={hasReservationSessionChanged}
                />
            )}

            {!showDeleteConfirm ? null : (
                <ConfirmDeleteOverlay {...props} setShowDeleteConfirm={setShowDeleteConfirm} />
            )}
        </div>
    );
}

function ApptBlockHelp() {
    return (
        <Tooltip
            title={
                <div className="appointment-block-help-tooltip-content">
                    <div className="heading">What is an Appointment Block?</div>
                    <div className="explanation">
                        An appointment block allows you to specify when and how many appointments may be scheduled by
                        patients either through messaged scheduling requests or via your public website.
                        <br />
                        <br />
                        These appointment blocks may be shared between the public website scheduler and the scheduling
                        workflow to ensure that only the maximum number of appointments that you are able to service
                        (i.e. have vaccine dosages for) may be scheduled.
                    </div>
                </div>
            }
        >
            <span className="help-icon-and-text">
                <HelpIcon /> What is this?
            </span>
        </Tooltip>
    );
}

function FromExistingAppointmentBlockSelector({
    setIsCreatingNew,
    getLiveReusableReservationSessions,
    calendars,
    reservationSession,
    setReservationSession,
    onNext,
    setEditingSession,
    activeReservationSessions,
    subLabel,
    loadSessionsOnMount = true,
    slotsById,
    allowContinueWithoutSelection = false,
    allowSelection = true,
    noBlocksWarningMessage,
    apptReservation,
    createButtonOnTop,
}) {
    const [, setIsLoading] = useState(loadSessionsOnMount);
    const isLoading = _.get(apptReservation, 'liveReusableSessions.loading', false);

    useEffect(() => {
        if (loadSessionsOnMount) {
            getLiveReusableReservationSessions().then(() => {
                setIsLoading(false);
            });
        }
    }, []);

    if (isLoading) {
        return (
            <div className="appointment-block-selector from-existing loading">
                <CircularProgress className="loading-spinner" />
            </div>
        );
    }

    return (
        <div className="appointment-block-selector from-existing">
            <div className="main-label">
                {allowSelection ? (
                    <span>Please choose an Appointment Block</span>
                ) : (
                    <span>Active Appointment Blocks</span>
                )}

                {createButtonOnTop ? (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setReservationSession(null);
                            setIsCreatingNew(true);
                        }}
                    >
                        Create new appointment block
                    </Button>
                ) : (
                    <ApptBlockHelp />
                )}
            </div>

            <div className="sub-label">{subLabel}</div>

            <div
                style={{
                    width: '100%',
                    height: `${Math.max(1, Math.min(3, _.size(activeReservationSessions))) * 130}px`,
                }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className="appointment-blocks-list"
                            width={width}
                            height={height}
                            rowCount={_.size(activeReservationSessions)}
                            rowHeight={130}
                            noRowsRenderer={() => {
                                return (
                                    <div className="no-appointment-blocks-message">
                                        {_.isNil(noBlocksWarningMessage) ? null : (
                                            <div className="warning-message">{noBlocksWarningMessage}</div>
                                        )}
                                        <div className="main-no-blocks-message">
                                            <span>No active appointment blocks,</span>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setReservationSession(null);
                                                    setIsCreatingNew(true);
                                                }}
                                            >
                                                click here
                                            </Button>
                                            <span>to create one.</span>
                                        </div>
                                    </div>
                                );
                            }}
                            rowRenderer={({ index, key, style }) => {
                                const session = activeReservationSessions[index];

                                return (
                                    <div key={key} style={style}>
                                        <AppointmentBlockRow
                                            allowSelection={allowSelection}
                                            session={activeReservationSessions[index]}
                                            slots={slotsById[session.session_id]}
                                            calendars={calendars}
                                            isOdd={index % 2 === 1}
                                            onClick={() => {
                                                setReservationSession(session);
                                                onNext();
                                            }}
                                            onEdit={() => {
                                                const clone = _.cloneDeep(session);
                                                setEditingSession(clone);
                                                setReservationSession(clone);
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                </AutoSizer>
            </div>

            <div className="buttons-row">
                {!allowContinueWithoutSelection ? null : (
                    <Tooltip title="">
                        <Button
                            color="primary"
                            className="continue-without-selection-button"
                            onClick={() => {
                                if (!_.isNil(reservationSession)) {
                                    setReservationSession(null);
                                }
                                onNext();
                            }}
                        >
                            schedule without an appointment block
                        </Button>
                    </Tooltip>
                )}

                {!createButtonOnTop && (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setReservationSession(null);
                            setIsCreatingNew(true);
                        }}
                    >
                        Create new appointment block
                    </Button>
                )}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { launchDarkly } = state;
    return {
        showMultiApptUx: launchDarkly.npeMultipleAppointmentTypes,
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentBlockSelector);
