import _ from 'lodash';
import moment from 'moment';
import { constants } from './constants';

export const caseInsensitiveIncludes = (mainString, subString) => {
    if (!_.isString(mainString) || !_.isString(subString)) {
        return false;
    }

    return _.includes(mainString.toLowerCase(), subString.toLowerCase());
};

export const convertDateTimeToDate = (dateTime) => {
    return dateTime ? moment(dateTime).format(constants.dateFormat) : '—';
};

export const getFullName = ({ first_name, last_name }) => {
    return _.trim((first_name || '') + ' ' + (last_name || '')) || '—';
};
