import Config from 'config';
import { logHttpError } from 'utils/log';
import { get } from 'redux/actionCreators/rest';

const fetchContractInformation = (salesforceId) => {
    const url = `${Config.config2_url}/salesforce/${salesforceId}/contract`;

    return get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(`Error while fetching the contract details for salesforce Id ${salesforceId}`, error);
            return Promise.reject(error);
        });
};

export const pharmacyContractService = {
    fetchContractInformation,
};

export default pharmacyContractService;
