export const DATE_PRESETS = {
    YESTERDAY: 'yesterday',
    CURR_WEEK_DATE: 'curr_week_date',
    CURR_MONTH_DATE: 'curr_month_date',
    PREV_MONTH: 'prev_month',
    PREV_QUARTER: 'prev_quarter',
    CUSTOM: 'custom',
};

export const DescriptionLabel = {
    inbound_calls: 'Calls from Ads',
    impressions: 'Ad Views',
    reach: 'Potential Maximum Viewers',
    clicks: 'Clicks',
    reactions: 'Reactions',
    male: 'Male',
    female: 'Female',
};

export const DatePresetDetail = [
    { val: DATE_PRESETS.YESTERDAY, name: 'Yesterday' },
    { val: DATE_PRESETS.CURR_WEEK_DATE, name: 'Current week to date' },
    { val: DATE_PRESETS.CURR_MONTH_DATE, name: 'Current month to date' },
    { val: DATE_PRESETS.PREV_MONTH, name: 'Previous month' },
    { val: DATE_PRESETS.CUSTOM, name: 'Custom' },
];

export const TooltipTextMap = {
    reach: 'The unique number of people who saw your ads',
    impressions: 'How many times your ads were seen by people in your community',
    clicks: 'How many times your ads were clicked on and the user was directed to your website and/or mobile app',
    inbound_calls: 'How many people called the number in your ad after viewing',
    reactions: "How many people 'reacted' to your ads (like, love, haha, wow, sad, angry)",
};
