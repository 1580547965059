import React from 'react';
import { DialogContainer } from 'react-md';

export const MarkDefaultConfirm = ({ hideMarkDefault, isVisible, actions, body, title }) => {
    const bodyTxt = body
        ? body
        : "You're about to change your default greeting for the IVR. Are you sure you want to make this change?";
    const titleTxt = title ? title : 'New Default Greeting';

    return (
        <DialogContainer
            id="mark-default-confirm"
            visible={isVisible}
            onHide={hideMarkDefault}
            actions={actions}
            width={350}
            title={titleTxt}
        >
            <span>{bodyTxt}</span>
        </DialogContainer>
    );
};
