import { Ability } from '@casl/ability';
import { BaseAbilityBuilder } from './BaseAbilityBuilder';
import { constants } from '../Constants';

export class ReadOnlyAbilityBuilder extends BaseAbilityBuilder {
    constructHiddenFieldsArray() {
        const { ivr, inbox, settingsPopover, generalSettings, voicemail, pharmacyApps } = this.restrictedResources;
        const { language, featuresAndFunctions, bannerConfiguration, userManagement } = generalSettings;
        const { languagesAndVoices, prescriptionRefillFulfillment } = ivr;
        const { patient, newConversation } = inbox;

        this.hiddenFields = [
            {
                subject: language.subject,
                fields: [],
            },
            {
                subject: featuresAndFunctions.subject,
                fields: [],
            },
            {
                subject: bannerConfiguration.subject,
                fields: [],
            },
            {
                subject: userManagement.subject,
                fields: [],
            },
            {
                subject: patient.subject,
                fields: [],
            },
            {
                subject: newConversation.subject,
                fields: [],
            },
            {
                subject: ivr.subject,
                fields: [ivr.fields.resetIVR],
            },
            {
                subject: languagesAndVoices.subject,
                fields: [],
            },
            {
                subject: prescriptionRefillFulfillment.subject,
                fields: [prescriptionRefillFulfillment.fields.patientDobValidation],
            },
            {
                subject: settingsPopover.subject,
                fields: [settingsPopover.fields.appcode],
            },
            {
                subject: voicemail.subject,
                fields: [voicemail.fields.markAsRead, voicemail.fields.download],
            },
            {
                subject: pharmacyApps.subject,
                fields: [],
            },
        ];
    }

    build() {
        const { all, userActions } = constants;
        const { read, update, view, click } = userActions;
        const { voicemail, generalSettings } = this.restrictedResources;
        const { accountDetails } = generalSettings;

        // Note
        // 1) Rule are in accordance with Google Sheet attached in https://rxwiki.atlassian.net/browse/PHAR-1355 and
        //    https://app.lucidchart.com/documents/view/f82ecc8e-e040-4e25-b0ac-e5c5218dd168/0_0
        // 2) We follow the opt-out approach, i.e. we allow user to read everything except opted out features
        this.can([read, view], all);
        this.can([click], voicemail.subject, voicemail.fields.play);
        this.can([update], accountDetails.subject, accountDetails.fields.changePassword);

        this.optOutHiddenFields();
        this.optOutNonClickableFields();

        return new Ability(this.rules, { detectSubjectType: this.detectAppSubjectType });
    }
}
