const typePrefix = 'inbox/user/';

export const userType = {
    RESET_USER_STORE: `${typePrefix}RESET_USER_STORE`,
    SET_ACTIVE_USER: `${typePrefix}SET_ACTIVE_USER`,
    CLEAR_ACTIVE_USER: `${typePrefix}CLEAR_ACTIVE_USER`,
    CREATE_USER_REQUEST: `${typePrefix}CREATE_USER_REQUEST`,
    CREATE_USER_SUCCESS: `${typePrefix}CREATE_USER_SUCCESS`,
    CREATE_USER_FAILURE: `${typePrefix}CREATE_USER_FAILURE`,

    SET_VM_USER: `${typePrefix}SET_VM_USER`,

    GET_USERS_LIST_REQUEST: `${typePrefix}GET_USERS_LIST_REQUEST`,
    GET_USERS_LIST_SUCCESS: `${typePrefix}GET_USERS_LIST_SUCCESS`,
    GET_USERS_LIST_FAILURE: `${typePrefix}GET_USERS_LIST_FAILURE`,

    UPDATE_USER_REQUEST: `${typePrefix}UPDATE_USER_REQUEST`,
    UPDATE_USER_SUCCESS: `${typePrefix}UPDATE_USER_SUCCESS`,
    UPDATE_USER_FAILURE: `${typePrefix}UPDATE_USER_FAILURE`,

    VERIFY_USER_REQUEST: `${typePrefix}VERIFY_USER_REQUEST`,
    VERIFY_USER_SUCCESS: `${typePrefix}VERIFY_USER_SUCCESS`,
    VERIFY_USER_FAILURE: `${typePrefix}VERIFY_USER_FAILURE`,

    ARCHIVE_USER_REQUEST: `${typePrefix}ARCHIVE_USER_REQUEST`,
    ARCHIVE_USER_SUCCESS: `${typePrefix}ARCHIVE_USER_SUCCESS`,
    ARCHIVE_USER_FAILURE: `${typePrefix}ARCHIVE_USER_FAILURE`,

    USER_PHONE_TYPE_LOOKUP_REQUEST: `${typePrefix}USER_PHONE_TYPE_LOOKUP_REQUEST`,
    USER_PHONE_TYPE_LOOKUP_SUCCESS: `${typePrefix}USER_PHONE_TYPE_LOOKUP_SUCCESS`,
    USER_PHONE_TYPE_LOOKUP_FAILURE: `${typePrefix}USER_PHONE_TYPE_LOOKUP_FAILURE`,

    UPLOAD_BULK_ORIGINAL_CSV_REQUEST: `${typePrefix}UPLOAD_BULK_ORIGINAL_CSV_REQUEST`,
    UPLOAD_BULK_ORIGINAL_CSV_SUCCESS: `${typePrefix}UPLOAD_BULK_ORIGINAL_CSV_SUCCESS`,
    UPLOAD_BULK_ORIGINAL_CSV_FAILURE: `${typePrefix}UPLOAD_BULK_ORIGINAL_CSV_FAILURE`,

    CREATE_BULK_USERS_REQUEST: `${typePrefix}CREATE_BULK_USERS_REQUEST`,
    CREATE_BULK_USERS_SUCCESS: `${typePrefix}CREATE_BULK_USERS_SUCCESS`,
    CREATE_BULK_USERS_FAILURE: `${typePrefix}CREATE_BULK_USERS_FAILURE`,

    GET_USERS_REQUEST: `${typePrefix}GET_USERS_REQUEST`,
    GET_USERS_SUCCESS: `${typePrefix}GET_USERS_SUCCESS`,
    GET_USERS_FAILURE: `${typePrefix}GET_USERS_FAILURE`,
    GET_USERS_ACTIVITY_UNREAD_COUNT_REQUEST: `${typePrefix}USERS_ACTIVITY_UNREAD_COUNT_REQUEST`,
    GET_USERS_ACTIVITY_UNREAD_COUNT_SUCCESS: `${typePrefix}USERS_ACTIVITY_UNREAD_COUNT_SUCCESS`,
    GET_USERS_ACTIVITY_UNREAD_COUNT_FAILURE: `${typePrefix}USERS_ACTIVITY_UNREAD_COUNT_FAILURE`,
    GET_LATEST_USER_ACTIVITY_REQUEST: `${typePrefix}GET_LATEST_USERS_ACTIVITY_REQUEST`,
    GET_LATEST_USER_ACTIVITY_SUCCESS: `${typePrefix}GET_LATEST_USERS_ACTIVITY_SUCCESS`,
    GET_LATEST_USER_ACTIVITY_FAILURE: `${typePrefix}GET_LATEST_USERS_ACTIVITY_FAILURE`,

    OPEN_EDIT_USER_DIALOG: `${typePrefix}OPEN_EDIT_USER_DIALOG`,
    CLOSE_EDIT_USER_DIALOG: `${typePrefix}CLOSE_EDIT_USER_DIALOG`,
    TOGGLE_EDIT_USER_DIALOG: `${typePrefix}TOGGLE_EDIT_USER_DIALOG`,
    CLEAR_CREATE_USER_MESSAGE: `${typePrefix}CLEAR_CREATE_USER_MESSAGE`,

    UPDATE_ACTIVE_USER_MESSAGE_STATUS: `${typePrefix}UPDATE_ACTIVE_USER_MESSAGE_STATUS`,

    SEARCH_USERS_REQUEST: `${typePrefix}SEARCH_USERS_REQUEST`,
    SEARCH_USERS_SUCCESS: `${typePrefix}SEARCH_USERS_SUCCESS`,
    SEARCH_USERS_FAILURE: `${typePrefix}SEARCH_USERS_FAILURE`,
    SET_SEARCH_FILTER: `${typePrefix}SET_SEARCH_FILTER`,
    RESET_SEARCH_FILTER: `${typePrefix}RESET_SEARCH_FILTER`,
    SET_SEARCH_QUERY: `${typePrefix}SET_SEARCH_QUERY`,
    RESET_SEARCH_QUERY: `${typePrefix}RESET_SEARCH_QUERY`,

    UPDATE_USER_MAP: `${typePrefix}UPDATE_USER_MAP`,
};

export default userType;
