import { put, post } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import Actions from 'redux/actions';
import { logHttpError } from 'utils/log';

export const updateDeliveryOptions = (payload) => (dispatch) => {
    const updateDeliveryOptionsPath = API_PATH.UPDATE_DELIVERY_OPTION.replace('{custID}', Config.X_PharmacyID);
    const updatePackageIdPath = updateDeliveryOptionsPath.replace('{packageId}', payload.packageId);
    let apiUrl;
    let updateDeliveryOptionReq;

    if (payload.data.id) {
        // make put req
        const updateCustPakcageAttrIdPath = updatePackageIdPath.replace('{packageAttrId}', payload.data.id);
        apiUrl = Config.config2_url + updateCustPakcageAttrIdPath;
        updateDeliveryOptionReq = put(apiUrl, payload.data);
    } else {
        // make post req
        const updateCustPakcageAttrIdPath = updatePackageIdPath.replace('{packageAttrId}', '');
        apiUrl = Config.config2_url + updateCustPakcageAttrIdPath;
        updateDeliveryOptionReq = post(apiUrl, payload.data);
    }

    dispatch({ type: Actions.UPDATE_DELIVERY_OPTION_STARTED });

    return updateDeliveryOptionReq
        .then((data) => {
            dispatch({ type: Actions.UPDATE_DELIVERY_OPTION_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on saveIvrLang', err);
            dispatch({ type: Actions.UPDATE_DELIVERY_OPTION_FAILED });
            return { err };
        });
};
