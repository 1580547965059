import './TemplateManagementRow.sass';
import React, { useRef } from 'react';
import { Switch, Tooltip, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { renderTemplateText } from '../ConnectCard';
import classnames from 'classnames';
import { templateAction } from '../../../../redux/Inbox/Template';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import config from '../../../../config';
import { getTemplateTitle, getTemplateImageUrl } from 'components/Patients/patientsUtil';
import { preprocessMessageTemplateText } from 'components/Patients/messageTemplateUtil';

const TARGETING_EXPLANATION = {
    birthday: 'Targets patients on their birthday',
    waitlist: 'Targets patients from your waitlist',
};
const NON_MESSAGE_TEXT_HEIGHT = 98;

function TemplateManagementRow({
    template,
    updateTemplate,
    focused,
    onSetFocused,
    onEdit,
    deletable,
    deleteTemplate,
    isOddRow,
    updatingTemplateUuid,
    auth,
}) {
    const textRef = useRef();

    const getTargetingExplanation = (t) => {
        if (!_.isNil(t)) {
            if (_.has(TARGETING_EXPLANATION, t)) {
                return TARGETING_EXPLANATION[t];
            }
            return 'Targets automatically via a patients history';
        }
    };

    const onDelete = ({ inbox_conversation_template_id }) => {
        deleteTemplate({
            inbox_conversation_template_id,
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
        });
    };

    const isTemplateRestorable = (template) => {
        if (template.template_type === 'managed' && !_.isNil(template.parent_template_id)) {
            if (_.isString(template.parent_subject) && _.isString(template.parent_message)) {
                return template.parent_message !== template.message || template.parent_subject !== template.subject;
            }
        }
        return false;
    };

    const onRestore = (template) => {
        updateTemplate({
            ...template,
            subject: template.parent_subject,
            message: template.parent_message,
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
        });
    };

    const isUpdating = updatingTemplateUuid === template.inbox_conversation_template_id;

    return (
        <div
            className={classnames({
                'template-management-row': true,
                disabled: template.workflow_status === 'inactive',
                unfocused: !focused,
                'is-odd': isOddRow,
            })}
        >
            <div className="template-management-row-inner">
                <div className="switch-wrapper">
                    <Tooltip title="Toggle whether this card is visible.">
                        <Switch
                            checked={template.workflow_status === 'active'}
                            color="primary"
                            onChange={(e) => {
                                const clone = _.clone(template);
                                clone.workflow_status = template.workflow_status === 'active' ? 'inactive' : 'active';
                                updateTemplate({
                                    ...clone,
                                    pharmacy_id: config.X_PharmacyID,
                                    location_id: config.X_LocationID,
                                });
                            }}
                        />
                    </Tooltip>
                </div>

                <div
                    className="image-box"
                    style={{
                        backgroundImage: `url(${getTemplateImageUrl(template)})`,
                        backgroundColor: '#eee',
                        backgroundSize: 'cover',
                        width: '187.5px',
                        height: '125px',
                    }}
                />

                <div className="text" ref={textRef}>
                    <div className="title">
                        <span className="header">{getTemplateTitle(template)}</span>
                        {_.isNil(template.targeting_type) ? null : (
                            <span className="targeting-explanation">
                                {getTargetingExplanation(template.targeting_type)}
                            </span>
                        )}

                        <span className="actions">
                            {getTemplateTitle(template) !== 'Schedule COVID-19 Vaccine' && (
                                <Tooltip title="Customize subject and content of this message">
                                    <IconButton onClick={() => onEdit(template)}>
                                        <EditIcon className="edit-icon" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!deletable ? null : (
                                <Tooltip title="Delete this Workflow template">
                                    <IconButton onClick={() => onDelete(template)}>
                                        <DeleteIcon className="delete-icon" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!isTemplateRestorable(template) ? null : (
                                <Tooltip title="Restore original subject and content">
                                    <IconButton onClick={() => onRestore(template)}>
                                        <RestoreIcon className="restore-icon" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </span>
                    </div>
                    <div className="subject">
                        Subject: <strong>{template.subject}</strong>
                    </div>
                    <div
                        className="message"
                        onClick={(e) => {
                            onSetFocused(Math.ceil(e.target.offsetHeight + NON_MESSAGE_TEXT_HEIGHT));
                        }}
                    >
                        {renderTemplateText(preprocessMessageTemplateText(template.message, auth))}
                    </div>
                    <div className="bottom-gradient" />
                </div>

                {!isUpdating ? null : <span className="updating-message">Saving changes...</span>}
            </div>
        </div>
    );
}

const bindActionsToDispatch = {
    updateTemplate: templateAction.updateTemplate,
    deleteTemplate: templateAction.deleteTemplate,
};
function mapStateToProps(state) {
    const { inboxConversationTemplate, auth } = state;
    return {
        updatingTemplateUuid: _.get(inboxConversationTemplate, 'sendingUuid'),
        auth,
    };
}

export default connect(mapStateToProps, bindActionsToDispatch)(TemplateManagementRow);
