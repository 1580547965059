import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { getVacationTimesSummaryForDateOnCalendar } from 'components/Appointments/AppointmentsPage';
import pluralize from 'pluralize';

export const SUPPORTED_TIMEZONES = [
    'America/Anchorage',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/New_York',
    'America/Phoenix',
];

export const RESERVATION_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export function isSchedulingTemplate(template) {
    return _.get(template, 'message_content.messaging_form.has_scheduling', false);
}

export function isWaitlistedTemplate(template) {
    return _.get(template, 'message_content.messaging_form.has_waitlist', false);
}

export function getWaitlistIdsFromTemplate(template) {
    const targeting_type = _.get(template, 'targeting_type');

    if (!_.isNil(targeting_type) && targeting_type.startsWith('waitlist')) {
        return _.get(template, 'message_content.messaging_form.waitlist_ids');
    }
}

export function getRelativeDateShortLabel(date) {
    if (dateEquals(moment(), date)) {
        return 'Today';
    } else if (dateEquals(moment().add(1, 'day'), date)) {
        return 'Tomorrow';
    } else if (dateEquals(moment().subtract(1, 'day'), date)) {
        return 'Yesterday';
    }
}

export function dateEquals(m1, m2) {
    return m1.format('YYYY-MM-DD') === m2.format('YYYY-MM-DD');
}

export function monthEquals(m1, m2) {
    return m1.format('YYYY-MM') === m2.format('YYYY-MM');
}

export function getLocationFromPharmacy(pharmacy) {
    return _.find(_.get(pharmacy, `pharmacy.location`), (location) => location.id === pharmacy.activeLocationId);
}

export function getDefaultAppointmentHoursFromPharmacy(pharmacy) {
    const location = getLocationFromPharmacy(pharmacy);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    // if we have location hours for the IVR, then use them...
    if (!_.isNil(location) && !_.isEmpty(location.hours)) {
        const weeklySchedule = {};

        _.each(location.hours, ({ day, endHH, endMM, startHH, startMM }) => {
            weeklySchedule[days[day - 1]] = [];
            if (startHH !== 0 || startMM !== 0 || endHH !== 0 || endMM !== 0) {
                weeklySchedule[days[day - 1]].push({
                    startHour: startHH,
                    startMinute: startMM,
                    endHour: endHH,
                    endMinute: endMM,
                    capacity: 1,
                });
            }
        });

        return { weeklySchedule, timezone: location.timezone };
    } else {
        // otherwise just set hours to basic Monday - Friday 9am to 5pm
        const weeklySchedule = {};
        _.each(_.times(5), (i) => {
            weeklySchedule[days[i]] = [
                {
                    startHour: 9,
                    startMinute: 0,
                    endHour: 17,
                    endMinute: 0,
                    capacity: 1,
                },
            ];
        });

        return { weeklySchedule, timezone: moment.tz.guess() };
    }
}

export function getAppointmentTypesFromConversationTemplates(inboxConversationTemplate, calendars) {
    const workflowsByCalendarLabelAndLength = {};

    const byCalendarId = _.keyBy(
        _.filter(calendars, ({ status }) => status === 'ACTIVE'),
        ({ calendar_id }) => calendar_id
    );

    _.each(_.get(inboxConversationTemplate, 'templates'), (template) => {
        if (isSchedulingTemplate(template) && template.status === 'active' && template.workflow_status === 'active') {
            const { appointment_label, appointment_calendar_id, appointment_length_minutes } = _.get(
                template,
                'message_content.messaging_form',
                {}
            );

            const calendar_id =
                _.isNil(appointment_calendar_id) && _.size(byCalendarId) === 1
                    ? _.first(_.keys(byCalendarId))
                    : appointment_calendar_id;

            const key = `${calendar_id}|${appointment_label}|${appointment_length_minutes}`;

            if (!_.has(workflowsByCalendarLabelAndLength, key)) {
                workflowsByCalendarLabelAndLength[key] = [];
            }

            workflowsByCalendarLabelAndLength[key].push(template);
        }
    });

    return _.map(_.values(workflowsByCalendarLabelAndLength), (workflows) => {
        const template = _.last(workflows);

        const { appointment_label, appointment_calendar_id, appointment_length_minutes } = _.get(
            template,
            'message_content.messaging_form',
            {}
        );
        const { image_ref } = template;

        const calendar_id =
            _.isNil(appointment_calendar_id) && _.size(byCalendarId) === 1
                ? _.first(_.keys(byCalendarId))
                : appointment_calendar_id;

        return {
            appointment_label,
            appointment_calendar_id: calendar_id,
            appointment_length_minutes,
            calendar: byCalendarId[calendar_id],
            image_ref,
        };
    });
}

export function getHowToScheduleConfigFromInboxConversationTemplate(template) {
    const messagingForm = _.get(template, 'message_content.messaging_form');

    if (_.isObject(messagingForm) && (messagingForm.has_scheduling || _.isString(messagingForm.appt_type_id))) {
        const scheduling_type = _.get(messagingForm, 'scheduling_type', 'dp-clinical');

        if (scheduling_type === 'dp-clinical') {
            return {
                ..._.pick(messagingForm, [
                    'appointment_label',
                    'appointment_calendar_id',
                    'appointment_length_minutes',
                ]),
                scheduling_type,
            };
        } else if (scheduling_type === '3rd-party') {
            return {
                ..._.pick(messagingForm, ['third_party_scheduling_link']),
                scheduling_type,
            };
        } else if (scheduling_type === 'manual') {
            return {
                scheduling_type,
            };
        }
    }
}

export function getSchedulingMessagingFieldsFromInboxConversationTemplate(template) {
    const messagingForm = _.get(template, 'message_content.messaging_form');

    if (_.isObject(messagingForm) && messagingForm.has_scheduling) {
        const scheduling_type = _.get(messagingForm, 'scheduling_type', 'dp-clinical');

        if (scheduling_type === 'dp-clinical') {
            return ['appointments_available_text', 'scheduled_appointment_text'];
        } else if (scheduling_type === '3rd-party') {
            return ['appointments_available_text'];
        }
    }
}

export function isSchedulingConfigured(template, calendars) {
    const config = getHowToScheduleConfigFromInboxConversationTemplate(template);

    if (_.isObject(config)) {
        const { scheduling_type } = config;

        if (scheduling_type === 'dp-clinical') {
            return (
                _.isString(config.appointment_calendar_id) &&
                _.isFinite(config.appointment_length_minutes) &&
                _.get(
                    _.find(calendars, (c) => c.calendar_id === config.appointment_calendar_id),
                    'status'
                ) === 'ACTIVE'
            );
        } else if (scheduling_type === '3rd-party') {
            return _.isString(config.third_party_scheduling_link);
        }
    }
    return false;
}

export function getAppointmentCalendarFromTemplate(template, calendars) {
    const config = getHowToScheduleConfigFromInboxConversationTemplate(template);

    return _.find(calendars, ({ calendar_id }) => calendar_id === config.appointment_calendar_id);
}

export function getNumberOfPatientsOnWaitlistsFromTemplate(template, opportunities) {
    const waitlistIds = getWaitlistIdsFromTemplate(template);
    return _.reduce(
        waitlistIds,
        (sum, id) => {
            return sum + _.get(opportunities, `waitlist|${id}|Uncontacted.count`, 0);
        },
        0
    );
}

export function getWaitlistFilterOptionsForTemplate(template, waitlistState) {
    const waitlistIds = getWaitlistIdsFromTemplate(template);
    const allWaitlists = _.sortBy(_.get(waitlistState, 'waitlists'), ({ waitlist_name }) => waitlist_name);

    const waitlistIdsToConsider = _.isEmpty(waitlistIds) ? null : new Set(waitlistIds);

    return _.isEmpty(waitlistIds)
        ? allWaitlists
        : _.filter(allWaitlists, ({ waitlist_id }) => waitlistIdsToConsider.has(waitlist_id));
}

export function convertToUtcTimestamp({ date, hour, minute, allDay }, isStart, timezone) {
    hour = allDay ? (isStart ? 0 : 23) : hour;
    minute = allDay ? (isStart ? 0 : 59) : minute;
    const seconds = isStart ? '00' : '59';

    return moment
        .tz(`${date.format('YYYY-MM-DD')} ${hour}:${minute}:${seconds}`, 'YYYY-MM-DD H:m:ss', timezone)
        .utc()
        .toISOString();
}

export function formatTime({ allDay, hour, minute }) {
    if (allDay) {
        return null;
    }
    return moment(`${hour}:${minute}`, 'H:m').format('HH:mm:ss');
}

export function isSchedulingWaitlistEnabled(auth, pharmacy) {
    const { selectedLocation } = auth;
    const selectedPharmacyLocation = _.find(
        pharmacy.pharmacy.location,
        (location) => location.id === selectedLocation.id
    );

    const c2CovidWaitlistEnabled = _.get(selectedPharmacyLocation, 'service.Website.CovidWaitlistEnabled', false);

    return c2CovidWaitlistEnabled;
}

export function getNumberOfNonPastSlotsAvailableForDate(availableSlots, date, calendarTimezone, bufferMinutes = 60) {
    const now = moment().add(bufferMinutes, 'minutes');
    return _.reduce(
        _.keys(_.get(availableSlots, date)),
        (sum, time) => {
            const capacity = _.get(availableSlots, `${date}.${time}.capacity`);

            if (capacity > 0) {
                const isPast = moment
                    .tz(moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss', calendarTimezone), moment.tz.guess())
                    .isBefore(now);

                if (!isPast) {
                    return sum + capacity;
                }
            }
            return sum;
        },
        0
    );
}

export function getCalendarExplanationTextForDate(numberOfSlots, dateStr, calendar) {
    const date = moment(dateStr, 'YYYY-MM-DD');
    const isToday = moment().isSame(date, 'day');

    const vacationSummary = getVacationTimesSummaryForDateOnCalendar(calendar, date);

    return (
        <div>
            <div>
                There {pluralize('is', numberOfSlots)} {numberOfSlots} available appointment slots{' '}
                {isToday ? 'remaining today' : ''} on this calendar's {date.format('dddd')} schedule
                {_.isNil(vacationSummary) ? '.' : ' and taking the scheduled vacation into account.'}
            </div>
            <div style={{ marginTop: '10px' }}>
                NOTE: Patients may only schedule appointments that begin at least one hour from the time they make the
                appointment.
            </div>
        </div>
    );
}
