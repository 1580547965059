import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { isUnmessageablePhoneType } from '../../utils/helper';
import { formatUTCToRelative } from '../../utils/formatTime';
import PatientAvatar from 'components/Patients/PatientAvatar';
import { connect } from 'react-redux';
import { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';

class ContactListItem extends Component {
    static getContentPreview({ latestMessage = {} }) {
        if (latestMessage.content_type === 'attachment') {
            return `Attachment: ${latestMessage.content.file_name}`;
        }

        if (latestMessage.content_type === 'call_to_action') {
            return latestMessage.content.action_title;
        }

        if (_.isString(latestMessage.content)) {
            return latestMessage.content;
        }

        // handle unknown content format, or missing content
        return latestMessage.content ? 'n/a' : '';
    }

    setActiveContact = () => {
        const { contact, setActive, history } = this.props;
        setActive(contact.inbox_user_id);
        // TODO once inbox/:user_id pattern is established replace this with push
        history.replace('/inbox');
    };

    render() {
        const { active, contact, activeLocationCountryCode } = this.props;
        const { first_name, last_name, latest_message, phone_type, opt_out } = this.props.contact;
        const fullName = `${first_name} ${last_name}`;
        const latestMessage = latest_message;
        const unread = latestMessage.user_type === 'patient' && !latestMessage.viewed;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const isUnsubscribed = !!opt_out;
        const notMobileIcon = isUnmessageable ? (
            <i className="material-icons" style={{ color: '#9b9b9b' }}>
                mobile_off
            </i>
        ) : (
            ''
        );
        const unsubscribedIcon = (
            <i className="material-icons" style={{ color: '#9b9b9b' }}>
                block
            </i>
        );

        return (
            <ListItem
                button
                onClick={this.setActiveContact}
                selected={active}
                divider
                className={`contact-list-item ${unread ? 'unread' : ''}`}
            >
                <span style={{ display: 'inline-block', height: '40px', marginRight: '16px' }}>
                    <PatientAvatar size={40} growOnHover={false} seed={getPatientAvatarSeedFromContact(contact)} />
                </span>
                <ListItemText
                    primary={
                        <div className="contact-list-item__primary-text">
                            <Typography
                                variant="subtitle1"
                                noWrap
                                display="inline"
                                className="contact-list-item__primary-name"
                                color="inherit"
                            >
                                {fullName}
                            </Typography>
                            <Typography
                                variant="caption"
                                noWrap
                                display="inline"
                                className="contact-list-item__secondary-date"
                            >
                                {latestMessage.created_date &&
                                    formatUTCToRelative({
                                        inputTimeString: latestMessage.created_date,
                                        countryCode: activeLocationCountryCode,
                                    })}
                            </Typography>
                        </div>
                    }
                    secondary={
                        <Fragment>
                            <span className="contact-list-item__secondary-text">
                                {ContactListItem.getContentPreview({ latestMessage })}
                            </span>
                        </Fragment>
                    }
                />
                {isUnsubscribed ? unsubscribedIcon : isUnmessageable ? notMobileIcon : ''}
            </ListItem>
        );
    }
}

const mapStateToPropsToolBar = (state) => {
    return {};
};
export default withRouter(connect(mapStateToPropsToolBar, () => ({}))(ContactListItem));
