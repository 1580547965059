import './PatientAppointmentsDialog.sass';
import { Button, Dialog } from '@material-ui/core';
import ContactHeaderBar from 'components/Inbox/ContactHeaderBar';
import React, { useState } from 'react';
import PatientAppointments from './profile/PatientAppointments';
import _ from 'lodash';
import { connect } from 'react-redux';
import { selectPastAndFutureReservationsForInboxUserId } from 'redux/Appt/appointmentSelectors';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateOrEditAppointmentDialog from 'components/Appointments/appointment/CreateOrEditAppointmentDialog';
import moment from 'moment';
import { getMatchingAppointmentType } from 'components/Appointments/AppointmentsPage';
import { getAppointmentTypesFromConversationTemplates } from './scheduling/schedulingUtil';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';

function PatientAppointmentsDialog({
    onClose,
    patient,
    isLoading,
    appointments,
    calendars,
    appointmentTypes,
    fullPepEnabled,
}) {
    const props = arguments[0];

    const [appointmentsToShow, setAppointmentsToShow] = useState('upcoming');
    const [showingAddAppointment, setShowingAddAppointment] = useState(false);
    const [showingEditAppointment, setShowingEditAppointment] = useState(null);

    if (showingAddAppointment || !_.isNil(showingEditAppointment)) {
        if (!fullPepEnabled) {
            return (
                <FullPepUpgrade
                    asDialog
                    onClose={() =>
                        showingAddAppointment ? setShowingAddAppointment(false) : setShowingEditAppointment(null)
                    }
                />
            );
        }
        return (
            <CreateOrEditAppointmentDialog
                {...props}
                open={true}
                onClose={() =>
                    showingAddAppointment ? setShowingAddAppointment(false) : setShowingEditAppointment(null)
                }
                isCreate={showingAddAppointment}
                //calendars={calendars}
                editing={showingEditAppointment}
                //initialSelectedDate={focusedDate}
                initialFocusedTab={showingAddAppointment ? 'appointment-type' : 'time'}
                initialConfiguration={
                    !showingEditAppointment
                        ? { patient }
                        : {
                              patient,
                              appointmentType: {
                                  ...getMatchingAppointmentType(appointmentTypes, showingEditAppointment),
                                  appointment_calendar_id: showingEditAppointment.calendar_id,
                                  appointment_length_minutes: moment(
                                      showingEditAppointment.end_date,
                                      'YYYY-MM-DDTHH:mm:SS'
                                  ).diff(moment(showingEditAppointment.start_date, 'YYYY-MM-DDTHH:mm:SS'), 'minutes'),
                                  appointment_label: showingEditAppointment.label,
                                  image_ref: _.get(
                                      getMatchingAppointmentType(appointmentTypes, showingEditAppointment),
                                      'image_ref',
                                      'one-off-appt.v2.jpg'
                                  ),
                              },
                              datetime: moment.tz(
                                  moment.utc(showingEditAppointment.start_date, 'YYYY-MM-DDTHH:mm:SS'),
                                  _.find(calendars, (c) => c.calendar_id === showingEditAppointment.calendar_id)
                                      .timezone
                              ),
                          }
                }
            />
        );
    }

    return (
        <Dialog className="patient-appointments-dialog" maxWidth="md" open={true} onClose={onClose}>
            <ContactHeaderBar
                contact={patient}
                patientEditingDisabled={true}
                showNewConversationButton={false}
                showVerify={false}
            >
                <div className="buttons">
                    <div className="add-button">
                        <Button onClick={() => setShowingAddAppointment(true)}>
                            <AddCircleOutlineIcon style={{ fontSize: '20px' }} />
                            &nbsp;Add new appointment
                        </Button>
                    </div>

                    <div className="nav-buttons">
                        <Button
                            className="past-button"
                            color={'primary'}
                            disabled={appointmentsToShow === 'past'}
                            onClick={() => setAppointmentsToShow('past')}
                        >
                            Past Appts <span className="count">{_.size(_.get(appointments, 'past'))}</span>
                        </Button>
                        <Button
                            className="upcoming-button"
                            disabled={appointmentsToShow === 'upcoming'}
                            color={'primary'}
                            onClick={() => setAppointmentsToShow('upcoming')}
                        >
                            Upcoming Appts <span className="count">{_.size(_.get(appointments, 'future'))}</span>
                        </Button>
                    </div>
                </div>
            </ContactHeaderBar>

            <PatientAppointments
                patient={patient}
                appointmentsToShow={appointmentsToShow}
                onEditAppointment={setShowingEditAppointment}
                onCreateAppointment={() => setShowingAddAppointment(true)}
            />
        </Dialog>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: _.get(state.apptReservation, 'reservationsForInboxUserId.loading'),
        appointments: selectPastAndFutureReservationsForInboxUserId(state),
        calendars: _.get(state.apptCalendar, 'availableCalendars.calendars', []),
        appointmentTypes: getAppointmentTypesFromConversationTemplates(
            state.inboxConversationTemplate,
            _.get(state.apptCalendar, 'availableCalendars.calendars')
        ),
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientAppointmentsDialog);
