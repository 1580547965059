import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// TODO: Rename this file
const styles = {
    button: {
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        marginTop: '32px',
        marginLeft: '32px',
        width: '179px',
        height: '36px',
        color: '#7053ee',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#dddd',
        },
    },
};

let BannerWidget = ({ classes }) => (
    <div className="banner-widget">
        <p className="banner-widget__title">Get PocketRx by Digital Pharmacist</p>
        <p className="banner-widget__body">
            Everything your patients love about your digital services, on their mobile device. Platform specific
            offerings for both Android and iOS. Make it even easier for your patients to engage with PocketRx.
        </p>
        <div className="banner-widget__mobile-background" />
        <div className="banner-widget__mobile-foreground" />
        <div className="banner-widget__mobile-oval-large" />
        <div className="banner-widget__mobile-oval-medium" />
        <div className="banner-widget__mobile-oval-small" />
        {/* TODO: Discuss with Chris the redirection URL */}
        <Button className={classes.button} href="https://www.google.com" target="_blank">
            Get PocketRx
        </Button>
    </div>
);

BannerWidget = withStyles(styles)(BannerWidget);

export { BannerWidget };
