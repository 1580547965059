import React from 'react';

const AvailableClasses = ({ className }) => {
    const availableClasses = [
        'Diabetes Maintenance',
        'Smoking Cessation',
        'Weight Loss',
        'High Blood Pressure',
        'High Cholesterol',
        'Asthma',
    ];

    return (
        <ul className={className}>
            {availableClasses.map((classTitle) => (
                <li key={classTitle}> {classTitle} </li>
            ))}
        </ul>
    );
};

export default AvailableClasses;
