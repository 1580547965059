import { userManagementTypes } from './type';
import { userManagementService } from './service';
import SnackbarActions from '../actions/Snackbar';
import config from '../../config';
import Snowplow, { UserManagementContext, SnowplowConstants } from '../../snowplow';

const resetUserManagementStore = () => (dispatch) => {
    return dispatch({ type: userManagementTypes.RESET_USER_MANAGEMENT_STORE });
};

const getUsers = ({ startRow, numRows, showInternal }) => (dispatch) => {
    dispatch({ type: userManagementTypes.GET_USERS_REQUEST });

    const pharmacyId = config.X_PharmacyID;

    return userManagementService
        .getUsers({ pharmacyId, startRow, numRows, showInternal })
        .then((response) => {
            dispatch({
                type: userManagementTypes.GET_USERS_SUCCESS,
                payload: {
                    totalUsers: response.total_count,
                    users: response.result,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: userManagementTypes.GET_USERS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const sendInvite = ({ user_id, user_type, email, pharmacy_name }) => (dispatch) => {
    dispatch({ type: userManagementTypes.SEND_INVITE });

    return userManagementService
        .sendInvite({ user_type, email, pharmacy_name })
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'User invite sent.',
                    type: 'success',
                },
            });

            Snowplow.structEvent(
                'User Management',
                'invite-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.success
            );
        })
        .catch(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to send user invite!',
                    type: 'error',
                },
            });

            Snowplow.structEvent(
                'User Management',
                'invite-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.failure
            );
        });
};

const deleteUser = ({ c2_user_id, user_id }) => (dispatch) => {
    dispatch({ type: userManagementTypes.DELETE_USER });

    return userManagementService
        .deleteUser({ c2_user_id, user_id })
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'User deleted.',
                    type: 'success',
                },
            });
            dispatch({
                type: userManagementTypes.DELETE_USER_SUCCESS,
                payload: { user_id },
            });

            Snowplow.structEvent(
                'User Management',
                'delete-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.success
            );

            return {
                success: true,
            };
        })
        .catch((err) => {
            let text = 'Failed to delete user!';

            if (err && err.response.status === 409) {
                text += ' Please contact customer support.';
            }

            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text,
                    type: 'error',
                },
            });

            dispatch({
                type: userManagementTypes.DELETE_USER_FAILED,
                payload: { user_id },
            });

            Snowplow.structEvent(
                'User Management',
                'delete-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.failure
            );

            return {
                ...err,
            };
        });
};

const getUserAuditLogs = ({ user_id }) => (dispatch) => {
    return userManagementService.getUserAuditLogs({ user_id });
};

const getUserRoles = () => (dispatch) => {
    dispatch({ type: userManagementTypes.GET_USER_ROLES_REQUEST });

    return userManagementService
        .getUserRoles()
        .then((response) => {
            dispatch({
                type: userManagementTypes.GET_USER_ROLES_SUCCESS,
                payload: {
                    userRoles: response.user_role_types,
                },
            });
        })
        .catch(() => {
            dispatch({
                type: userManagementTypes.GET_USER_ROLES_FAILED,
            });
        });
};

const addNewUser = (payload) => (dispatch) => {
    dispatch({ type: userManagementTypes.ADD_OR_EDIT_USER_REQUEST });

    return userManagementService
        .addNewUser(payload)
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'User invite sent.',
                    type: 'success',
                },
            });
            dispatch({
                type: userManagementTypes.ADD_OR_EDIT_USER_SUCCESS,
            });

            Snowplow.structEvent('User Management', 'add-user', null, null, SnowplowConstants.success);

            return {
                success: true,
            };
        })
        .catch((err) => {
            let text = 'Failed to send user invite!';

            if (err && err.response.status === 409) {
                text += ' Please contact customer support.';
            }

            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text,
                    type: 'error',
                },
            });
            dispatch({
                type: userManagementTypes.ADD_OR_EDIT_USER_FAILED,
            });

            Snowplow.structEvent('User Management', 'add-user', null, null, SnowplowConstants.failure);

            return {
                ...err,
            };
        });
};

const editUser = (payload) => (dispatch) => {
    const { user_id } = payload;
    dispatch({ type: userManagementTypes.ADD_OR_EDIT_USER_REQUEST });

    return userManagementService
        .editUser(payload)
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'User details saved.',
                    type: 'success',
                },
            });
            dispatch({
                type: userManagementTypes.ADD_OR_EDIT_USER_SUCCESS,
            });

            Snowplow.structEvent(
                'User Management',
                'edit-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.success
            );

            return {
                success: true,
            };
        })
        .catch(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to save user details!',
                    type: 'error',
                },
            });
            dispatch({
                type: userManagementTypes.ADD_OR_EDIT_USER_FAILED,
            });

            Snowplow.structEvent(
                'User Management',
                'edit-user',
                [new UserManagementContext().setUserId(user_id).build()],
                null,
                SnowplowConstants.failure
            );

            return {
                success: false,
            };
        });
};

const paginate = ({ page }) => (dispatch) => {
    dispatch({ type: userManagementTypes.PAGINATE, payload: { page } });
};

const searchUser = ({ searchString }) => (dispatch) => {
    dispatch({ type: userManagementTypes.SEARCH, payload: { searchString } });
};

const clearSearch = () => (dispatch) => {
    dispatch({ type: userManagementTypes.CLEAR_SEARCH });
};

const sortUsers = (sortBy) => (dispatch) => {
    dispatch({ type: userManagementTypes.SORT_USERS, payload: { sortBy } });
};

const setLocalLocation = ({ selectedLocalLocation }) => (dispatch) => {
    dispatch({ type: userManagementTypes.SET_LOCAL_LOCATION, payload: { selectedLocalLocation } });
};

const setUserType = ({ selectedUserType }) => (dispatch) => {
    dispatch({ type: userManagementTypes.SET_USER_TYPE, payload: { selectedUserType } });
};

export const userManagementActions = {
    resetUserManagementStore,
    getUsers,
    paginate,
    sortUsers,
    searchUser,
    clearSearch,
    sendInvite,
    deleteUser,
    getUserAuditLogs,
    getUserRoles,
    addNewUser,
    editUser,
    setLocalLocation,
    setUserType,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
