import './ResetPatientTargetingButton.sass';
import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Snackbar } from '@material-ui/core';
import config from '../../../../config';
import connectService from '../../../../redux/Patient/Connect/service';
import classnames from 'classnames';
import _ from 'lodash';

export default function ResetPatientTargetingButton() {
    const [resetting, setResetting] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(null);
    const [resetFailure, setResetFailure] = useState(null);

    useEffect(() => {
        if (resetting) {
            // Calling the connectService function directly here and bypassing redux since this is simpler and this functionality
            // is very isolated and only for sales demos.
            connectService
                .resetPatientOpportunitiesForSales(config.X_PharmacyID, config.X_LocationID)
                .then((response) => {
                    setResetting(false);
                    if (response.success) {
                        setResetSuccess(true);
                    } else {
                        setResetFailure(true);
                    }
                })
                .catch((error) => {
                    console.log('Error resetting patient opportunities for sales', error);
                    setResetting(false);
                    setResetFailure(true);
                });
        }
    }, [resetting]);

    return (
        <Tooltip
            title={
                <div>
                    <div>
                        This will reset the targeted patient opportunities to assist in demoing workflow capabilities.
                    </div>
                    <div style={{ marginTop: '10px' }}>Only available for sales demos in Cameron's - ACH</div>
                </div>
            }
        >
            <span className={classnames({ 'reset-patient-targeting-button': true, failure: resetFailure })}>
                <Button variant="contained" color="secondary" disabled={resetting} onClick={() => setResetting(true)}>
                    {resetting ? 'Resetting Patient Targeting...' : 'Reset Patient Targeting'}
                </Button>

                <Snackbar
                    open={!_.isNil(resetSuccess) || !_.isNil(resetFailure)}
                    autoHideDuration={5000}
                    onClose={() => {
                        setResetSuccess(null);
                        setResetFailure(null);
                    }}
                    message={
                        <div
                            className={classnames({
                                'reset-patient-opportunities-snackbar-message': true,
                                failure: !_.isNil(resetFailure),
                            })}
                        >
                            {_.isNil(resetFailure) ? 'Successfully' : 'Failed to'} reset targeted patient opportunities
                        </div>
                    }
                />
            </span>
        </Tooltip>
    );
}
