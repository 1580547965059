import React from 'react';
import _ from 'lodash';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { Title } from './Title';
import { buildStatusTypes } from '../Constants';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {},
    stepWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: 'solid 1px #d8d8d8',
        borderRadius: '4px',
        padding: '24px',
    },
    statusWrapper: {
        display: 'flex',
    },
    androidIcon: {
        color: green[500],
        fontSize: 40,
        paddingRight: '6px',
    },
    appleIcon: {
        fontSize: '40px',
        paddingRight: '6px',
    },
}));

export const LiveVersions = (props) => {
    const { app } = props;
    const classes = useStyles();
    // TODO: we should eventually adopt the buildStatus as 'complete' to mean complete
    const isBuildComplete = (buildStatus) =>
        buildStatus === buildStatusTypes.buildApproved || buildStatus === buildStatusTypes.updateApproved;
    const getReleaseVersion = (appRelease) => `${appRelease.versionName}(${appRelease.versionCode})`;

    const androidLiveRelease = _.find(
        _.get(app, 'appRelease.value.android', []),
        (release) => app.androidAppStatus.value === 'live' && isBuildComplete(release.buildStatus)
    );
    const appleLiveRelease = _.find(
        _.get(app, 'appRelease.value.apple', []),
        (release) => app.appleAppStatus.value === 'live' && isBuildComplete(release.buildStatus)
    );
    const androidLiveVersion = !androidLiveRelease ? 'Not yet live' : getReleaseVersion(androidLiveRelease);
    const appleLiveVersion = !appleLiveRelease ? 'Not yet live' : getReleaseVersion(appleLiveRelease);

    return (
        <div className={classes.root} style={props.style}>
            <div className={classes.stepWrapper}>
                <Title title="Live Versions" className={classes.title} />
                <div className={classes.statusWrapper}>
                    <AndroidOutlinedIcon className={classes.androidIcon} />
                    <Title title="Android" subtitle={androidLiveVersion} className={classes.title} />
                </div>
                <div className={classes.statusWrapper}>
                    <AppleIcon className={classes.appleIcon} />
                    <Title title="Apple" subtitle={appleLiveVersion} className={`${classes.title}`} />
                </div>
            </div>
        </div>
    );
};
