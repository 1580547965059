import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Config2Constants from 'constants/Config2';
import { fetchGreetingWithHuntGroup } from 'redux/actionCreators/Settings/SpeakWithPharmacist';
import { displayToast } from 'redux/actionCreators/Snackbar';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';
import ConfigurableService from './ConfigurableService';
import { userActions, restrictedResources, AbilityContext } from 'casl';

const title = 'IVR - New Patient Call Tracking';
const description =
    'When this setting is active, first time callers will be asked to ' +
    'confirm if they are a new patient. ' +
    'A Digital Pharmacist Product. Will also enable Leads page.';

const tooltipText = `In order to enable this feature, a phone number needs to be added
                     in the Phone Call Transfer page.`;
const pathname = '/settings/phone-system/phone-transfer';

class NewPatientCallTracking extends Component {
    static contextType = AbilityContext;

    componentDidMount() {
        const { fetchGreetingWithHuntGroup } = this.props;

        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        const canCreateGreeting = this.context.can(create, greetingSubject);
        fetchGreetingWithHuntGroup(canCreateGreeting);
    }

    componentDidUpdate(prevProps) {
        const {
            fetchGreetingWithHuntGroup,
            auth: { selectedLocation },
        } = this.props;

        if (prevProps.auth.selectedLocation.id !== selectedLocation.id) {
            const { create } = userActions;
            const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
            const canCreateGreeting = this.context.can(create, greetingSubject);
            fetchGreetingWithHuntGroup(canCreateGreeting);
        }
    }

    render() {
        const {
            speakWithPharmacist: { huntGroups, huntGroupLoading },
        } = this.props;

        return !huntGroupLoading && huntGroups.length > 0 ? (
            <Service title={title} description={description} {...this.props} />
        ) : (
            <ConfigurableService
                title={title}
                description={description}
                tooltipText={tooltipText}
                pathname={pathname}
                loading={huntGroupLoading}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    speakWithPharmacist: state.settings.speakWithPharmacists,
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGreetingWithHuntGroup: (data) => dispatch(fetchGreetingWithHuntGroup(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

const connectNewPatientCallTracking = connect(mapStateToProps, mapDispatchToProps)(withRouter(NewPatientCallTracking));

export default WithCustPackageAttribute(connectNewPatientCallTracking, {
    package: {
        name: 'IVR',
        code: 'ICFG',
        attribute: {
            name: 'CallTrackingEnabled',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
