/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'bannerConfig/';

export const bannerConfigTypes = {
    SAVE_BANNER_CONFIG_SETTINGS_REQUEST: `${typePrefix}SAVE_BANNER_CONFIG_SETTINGS_REQUEST`,
    SAVE_BANNER_CONFIG_SETTINGS_SUCCESS: `${typePrefix}SAVE_BANNER_CONFIG_SETTINGS_SUCCESS`,
    SAVE_BANNER_CONFIG_SETTINGS_FAILED: `${typePrefix}SAVE_BANNER_CONFIG_SETTINGS_FAILED`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
