// References:
// 1) https://github.com/stalniy/casl
// 2) https://github.com/stalniy/casl/tree/master/packages/casl-ability
// 3) https://github.com/stalniy/casl/tree/master/packages/casl-react
// 4) https://rxwiki.atlassian.net/wiki/spaces/PLAT/pages/1389035843/ADR+017+-+User+Access+Management
import {
    BaseAbilityBuilder,
    OwnerAbilityBuilder,
    StaffAbilityBuilder,
    ManagerAbilityBuilder,
    NoAccessAbilityBuilder,
    ReadOnlyAbilityBuilder,
    ItSupportAbilityBuilder,
    InternalUserAbilityBuilder,
} from './UserAbilityBuilders';
import { userRoles } from './UserRoles';
import { logUtil } from 'utils/log';

export const buildAbilitiesFor = (user) => {
    try {
        const abilities = (() => {
            if (user.isInternal) {
                return new InternalUserAbilityBuilder();
            }

            switch (user.role) {
                case userRoles.staff:
                    return new StaffAbilityBuilder();
                case userRoles.owner:
                    return new OwnerAbilityBuilder();
                case userRoles.editor:
                case userRoles.manager:
                    return new ManagerAbilityBuilder();
                case userRoles.reader:
                case userRoles.readOnly:
                    return new ReadOnlyAbilityBuilder();
                case userRoles.itSupport:
                    return new ItSupportAbilityBuilder();
                case userRoles.none:
                case userRoles.noAccess:
                    return new NoAccessAbilityBuilder();
                default:
                    return new BaseAbilityBuilder();
            }
        })();
        return abilities.build();
    } catch (e) {
        logUtil.logHttpError(`Error while constructing user abilities for user role ${user.role}`, e);
    }
};
