import React from 'react';
import { Card, CardText, ExpansionList, ExpansionPanel } from 'react-md';

import YoutubeVideo from './YoutubeVideo';
import WistiaVideo from './WistiaVideo';

const Videos = ({ content }) => (
    <Card className="borderless-card">
        <CardText>
            <ExpansionList className="md-cell md-cell--12">
                {content.map((video) => (
                    <ExpansionPanel
                        key={video.id}
                        id={video.id}
                        label={video.title}
                        footer={null}
                        defaultExpanded={content.length === 1}
                    >
                        {video.type === 'youtube' ? <YoutubeVideo video={video} /> : <WistiaVideo video={video} />}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </CardText>
    </Card>
);

export default Videos;
