import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import ContactMobileHeader from './ContactMobileHeader';
import {
    formatRawDate,
    formatRawPhone,
    isUnmessageablePhoneType,
    getAbbreviatedDateInputFormatByCountryCode,
} from '../../utils/helper';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Snowplow, { SnowplowConstants } from '../../snowplow';
import { Can, userActions, restrictedResources } from 'casl';
import PatientAvatar, { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';
import { connect } from 'react-redux';

class ContactHeaderBar extends Component {
    setVerifyPatient = () => {
        const { inbox_user_id } = this.props.contact;
        const { verifyUser } = this.props;
        verifyUser(inbox_user_id);
        Snowplow.structEvent('Inbox', 'user-verified', null, SnowplowConstants.clicked);
    };

    render() {
        const {
            toggleNewConversationDialog,
            perms,
            contact,
            sending,
            toggleEditContactDialog,
            fetchingUserPhoneType,
            activeLocationCountryCode,
            onBack,
            patientEditingDisabled,
            allowPepPatientEditing,
            showVerify = true,
            showNewConversationButton = true,
        } = this.props;
        const { first_name, last_name, date_of_birth, phone, phone_type, opt_out, verified } = this.props.contact;
        const fullName = `${first_name} ${last_name}`;
        const formattedDateOfBirth = formatRawDate(
            date_of_birth,
            'YYYY-MM-DD',
            getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode })
        );
        const formattedPhone = formatRawPhone(phone, activeLocationCountryCode);
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const isUnsubscribed = !!opt_out;
        const { start, update } = userActions;
        const {
            patient: { subject: patientDetail },
            newConversation: { subject: newConversation },
        } = restrictedResources.inbox;
        const editButtonTooltip = allowPepPatientEditing ? 'Edit patient' : 'Patients must be added in your PMS';

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="contact-header-bar">
                    <Hidden smDown>
                        <span style={{ margin: '16px', display: 'inline-block', height: '64px' }}>
                            <PatientAvatar
                                growOnHover={false}
                                size={64}
                                seed={getPatientAvatarSeedFromContact(contact)}
                            />
                        </span>

                        <div className="contact-header-bar__details">
                            <div className="contact-header-bar__details-name">
                                <Typography variant="h5" color="inherit" style={{ fontSize: '24px', color: '#4a4a4a' }}>
                                    {fullName}
                                </Typography>
                                <Can I={update} a={patientDetail} passThrough>
                                    {(allowed) =>
                                        !allowed || patientEditingDisabled ? null : (
                                            <Tooltip
                                                title={editButtonTooltip}
                                                aria-label={editButtonTooltip}
                                                disableFocusListener
                                            >
                                                <div>
                                                    <IconButton
                                                        aria-label="Edit"
                                                        aria-haspopup="true"
                                                        style={{
                                                            marginLeft: '24px',
                                                            padding: '6px',
                                                        }}
                                                        onClick={() => toggleEditContactDialog()}
                                                        disabled={!allowed || !allowPepPatientEditing}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        )
                                    }
                                </Can>
                            </div>
                            <div
                                className="contact-header-bar__details-meta"
                                style={{ fontSize: '15px', color: '#808080' }}
                            >
                                <span>{formattedDateOfBirth}</span>
                                <span>{formattedPhone}</span>
                            </div>
                        </div>
                    </Hidden>

                    <Can I={start} a={newConversation} passThrough>
                        {(allowed) => (
                            <Fragment>
                                <Hidden mdUp>
                                    <div style={{ width: '100%' }}>
                                        <ContactMobileHeader
                                            fullName={fullName}
                                            onBack={onBack}
                                            perms={perms}
                                            newConversationDisabled={
                                                !allowed || isUnmessageable || isUnsubscribed || fetchingUserPhoneType
                                            }
                                            toggleNewConversationDialog={toggleNewConversationDialog}
                                            toggleEditContactDialog={toggleEditContactDialog}
                                        />
                                    </div>
                                </Hidden>

                                {!showNewConversationButton ? (
                                    this.props.children
                                ) : (
                                    <div className="contact-header-bar__conversation">
                                        <Hidden smDown>
                                            <Button
                                                disabled={
                                                    !allowed ||
                                                    isUnmessageable ||
                                                    isUnsubscribed ||
                                                    fetchingUserPhoneType
                                                }
                                                variant="contained"
                                                color="primary"
                                                onClick={() => toggleNewConversationDialog()}
                                                style={{ boxShadow: 'none' }}
                                            >
                                                Start a new conversation
                                            </Button>
                                        </Hidden>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </Can>
                </div>
                {!verified && showVerify && (
                    <div className="not-verified-container">
                        <ReportProblemIcon fontSize="medium" className="report-problem-icon" />
                        <div
                            style={{
                                flex: 1,
                                margin: '0 16px',
                            }}
                        >
                            <Typography variant="body1" className="report-problem-message">
                                Please verify this patient's name, date of birth, and phone number before disclosing any
                                personal health information.
                            </Typography>
                        </div>

                        <Button
                            size="medium"
                            variant="outlined"
                            color="primary"
                            onClick={this.setVerifyPatient}
                            className="verify-patient-btn"
                        >
                            {sending ? (
                                <CircularProgress size={20} className="create-conversation-dialog__progress" />
                            ) : (
                                'Verify Patient'
                            )}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToPropsToolBar = (state) => {
    const { pharmacy } = state;

    return {
        allowPepPatientEditing: pharmacy.allowPepPatientEditing,
    };
};
export default connect(mapStateToPropsToolBar, () => ({}))(ContactHeaderBar);
