import React, { useState, useEffect, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Papa from 'papaparse';
import {
    getLikelyEmailColIndex,
    findEmptyColumns,
    doesFileSeemToHaveHeaders,
    isAttributeMapped,
    findDerivedCombinedColumns,
    buildDerivedColumnsFromFileData,
    getOrderedFileColumns,
} from 'components/Patients/csv/csvUtil';
import _ from 'lodash';

import UploadSelectionFileContent from './UploadSelectionFileContent';
import MapFileAttributesContent from './MapFileAttributesContent';

const REQUIRED_FIELDS = [
    {
        name: 'Email',
        value: 'email',
    },
];

const UploadSubscribersDialog = (props) => {
    const { open, onCloseWrapper } = props;
    const [currentStep, setCurrentStep] = useState('upload');
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [attributesMapping, setAttributesMapping] = useState({});
    const [autoMappedCount, setAutoMappedCount] = useState(0);
    const [isMappingComplete, setIsMappingComplete] = useState(false);
    const [fileColumns, setFileColumns] = useState(null);

    useEffect(() => {
        if (!_.isNil(file)) {
            Papa.parse(file, {
                complete: (results) => {
                    const emptyColumns = findEmptyColumns(results);
                    const hasHeaders = doesFileSeemToHaveHeaders(results, emptyColumns);

                    results.emptyColumns = emptyColumns;
                    results.hasHeaders = hasHeaders;

                    const derivedFileData = buildDerivedColumnsFromFileData(
                        results,
                        findDerivedCombinedColumns(results, hasHeaders),
                        hasHeaders
                    );
                    const fileColumns = getOrderedFileColumns(results, derivedFileData);
                    setFileColumns(fileColumns);

                    const likelyEmailIndex = getLikelyEmailColIndex(fileColumns, emptyColumns, hasHeaders);
                    const mapping = {
                        email: {
                            colIndex: _.isFinite(likelyEmailIndex) ? likelyEmailIndex : undefined,
                        },
                    };

                    setAutoMappedCount(_.size(_.filter([_.isFinite(likelyEmailIndex)])));

                    setAttributesMapping(mapping);
                    setFileData(results);
                    setCurrentStep('mapping');
                },
            });
        }
    }, [file]);

    useEffect(() => {
        setIsMappingComplete(
            _.isEmpty(_.filter(REQUIRED_FIELDS, ({ value }) => !isAttributeMapped(value, attributesMapping)))
        );
    }, [attributesMapping]);

    const handleFileUpload = (f) => setFile(f[0]);

    const uploadSubscribers = () => {
        // TODO: Upload fileData through API's
        onCloseWrapper();
    };

    const getCurrentStepContent = () => {
        if (currentStep === 'upload') {
            return <UploadSelectionFileContent handleFileUpload={handleFileUpload} />;
        } else if (currentStep === 'mapping') {
            return (
                <MapFileAttributesContent
                    file={file}
                    fileData={fileData}
                    fileColumns={fileColumns}
                    requiredFields={REQUIRED_FIELDS}
                    attributesMapping={attributesMapping}
                    setAttributesMapping={setAttributesMapping}
                    isMappingComplete={isMappingComplete}
                    onDone={uploadSubscribers}
                    onGoBack={() => setCurrentStep('upload')}
                    autoMappedAttributeCount={autoMappedCount}
                />
            );
        }
    };

    return (
        <Fragment>
            <Dialog
                maxWidth="lg"
                className="dp-csv-upload-dialog"
                open={open}
                onClose={() => {
                    onCloseWrapper();
                }}
            >
                <DialogContent>{getCurrentStepContent()}</DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default UploadSubscribersDialog;
