import Actions from 'redux/actions';

const initialState = {
    updatingInProgress: false,
};

export default function RefillDetailsReducer(state = initialState, { type }) {
    switch (type) {
        case Actions.UPDATE_DELIVERY_OPTION_STARTED:
            return { ...state, updatingInProgress: true };
        case Actions.UPDATE_DELIVERY_OPTION_SUCCESS:
        case Actions.UPDATE_DELIVERY_OPTION_FAILED:
            return { ...state, updatingInProgress: false };
        default:
            return state;
    }
}
