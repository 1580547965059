import React from 'react';
import { TextField } from '@material-ui/core';

import { PhoneNumberMask } from 'utils/mask';
import { ActionButtons } from 'components/Common';

export const IVRPhoneEditor = ({
    onlyCallTrackingEnabled,
    phoneNumber,
    phone,
    phoneErr,
    updateAllowed,
    isPhoneEditing,
    updatingIvrPhone,
    saveHandler,
    cancelHandler,
    onTextChange,
    style,
}) => {
    return (
        <div className="flex-middle">
            <TextField
                type="tel"
                name="phone"
                label={!onlyCallTrackingEnabled ? 'IVR Phone Number' : 'New Call Tracking Number'}
                value={phoneNumber !== null ? phoneNumber : phone}
                onChange={onTextChange}
                variant="outlined"
                fullWidth
                InputProps={{
                    readOnly: !isPhoneEditing || updatingIvrPhone,
                    inputComponent: PhoneNumberMask,
                }}
                error={phoneErr !== null}
                disabled={!updateAllowed}
                style={style}
            />
            {updateAllowed && (
                <div className="flex-middle" style={{ width: updatingIvrPhone ? '50px' : 'auto' }}>
                    <ActionButtons
                        isEditing={isPhoneEditing}
                        isSaving={updatingIvrPhone}
                        onSave={saveHandler}
                        onCancel={cancelHandler}
                        markEditing={() => saveHandler(phone)}
                    />
                </div>
            )}
        </div>
    );
};
