// List all the Page Names to be tracked
// Refer to https://docs.google.com/spreadsheets/d/1IhhMSpCKQcxsXygoONBDb3I0OIN3LpxoJrKncPe1L5k/edit#gid=0

import AppBarRoutesAndPageNameMap from './AppBar';
import ClassesRoutesAndPageNameMap from './Classes';
import SettingsRoutesAndPageNameMap from './Settings';
import CommunicationPlatformRoutesAndPageNameMap from './CommunicationPlatform';

export default {
    ...AppBarRoutesAndPageNameMap,
    ...ClassesRoutesAndPageNameMap,
    ...SettingsRoutesAndPageNameMap,
    ...CommunicationPlatformRoutesAndPageNameMap,
};
