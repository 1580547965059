import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { StickyContainer, Sticky } from 'react-sticky';

import goBackService from 'utils/goBackService';
import UserManagement from './UserManagement';
import Snowplow from '../../../../snowplow';
import withMediaQuery from 'components/Common/WithMediaQuery';
import { Can } from 'casl/Can';
import { restrictedResources } from 'casl/RestrictedResources';
import { userActions } from 'casl/UserActions';
import NoAccess from 'components/Common/NoAccess';

class UserManagementWrapper extends PureComponent {
    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    componentDidMount() {
        Snowplow.pageView('User Management');
    }

    render() {
        const {
            isMobile,
            isInternalUser,
            flags: { npeUserManagementEnabled },
        } = this.props;
        const {
            generalSettings: {
                userManagement: { subject: caslSubject },
            },
        } = restrictedResources;
        const { view } = userActions;

        return (
            <StickyContainer>
                <Grid className="user-management-container">
                    <Sticky topOffset={-80} disableCompensation>
                        {({ style, isSticky }) => (
                            <div
                                style={{
                                    ...style,
                                    position: isSticky && !isMobile ? 'fixed' : 'inherit',
                                    paddingTop: isSticky && !isMobile ? '24px' : 0,
                                    borderBottom: isSticky && !isMobile ? '1px solid #d8d8d8' : 0,
                                }}
                                className="sticky-item-container"
                            >
                                <div className="flex-middle">
                                    <IconButton
                                        aria-label="Go Back"
                                        onClick={this.onBack}
                                        edge="start"
                                        style={{
                                            marginRight: '12px',
                                        }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: '#4a4a4a',
                                        }}
                                    >
                                        User Management
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </Sticky>
                    <Sticky topOffset={-80} disableCompensation>
                        {({ style, isSticky }) => (
                            <Card
                                variant="outlined"
                                style={{
                                    marginTop: !isMobile && isSticky ? '69px' : 0,
                                    paddingTop: !isMobile && isSticky ? '115px' : '24px',
                                }}
                                className="user-management"
                            >
                                <Can I={view} this={caslSubject} passThrough>
                                    {(allowed) =>
                                        allowed && (npeUserManagementEnabled || isInternalUser) ? (
                                            <UserManagement />
                                        ) : (
                                            <NoAccess />
                                        )
                                    }
                                </Can>
                            </Card>
                        )}
                    </Sticky>
                </Grid>
            </StickyContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    isInternalUser: state.auth.isInternalUser,
    flags: state.launchDarkly,
});

export default connect(mapStateToProps, null)(withMediaQuery(UserManagementWrapper));
