import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Cell } from 'react-md';
import SettingsPopover from './SettingsPopover';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { fetchUnreadVoicemails } from 'redux/actionCreators/Voicemail';
import { LOG_OUT_ON_USER_CLICK } from 'constants/Logout';

import {
    logout,
    fetchIvrDetails,
    updateCustomer,
    updateWithExternalCustomer,
    updateLocation,
    fetchLocationDetails,
} from 'redux/actionCreators/Auth';
import Config from 'config';
import LocalStorageService from 'utils/localStorageService';

export class IconMenus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custName: null,
            locName: null,
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.auth.isAuthorized && !this.props.auth.isAuthorized) {
            this.props.history.push('/');
        }
        if (prevProps.auth.foundPharmacies !== this.props.auth.foundPharmacies) {
            if (this.props.auth.foundPharmacies.length !== 1) {
                // error handling... appcode search doesn't match any customers
                // or there are multiple pharmacies that are assigned to individual appcodes
                const { displayToast } = this.props;
                displayToast({ text: 'Appcode cannot be mapped to customer', type: 'error' });
            } else {
                const foundPharmacy = this.props.auth.foundPharmacies[0];
                this.handleCustomerChange(foundPharmacy.id, foundPharmacy.name);
            }
        }
    };

    logout = () => {
        const { logout } = this.props;
        logout();
    };

    getCustomerList = () => {
        const {
            auth: { userDetails },
        } = this.props;
        const customers = [];
        userDetails.forEach((detail) => {
            if (detail.entityType === 'Customer') {
                if (Config.X_PharmacyID === detail.entityId) {
                    this.selectedCustomer = detail.entityName;
                }
                customers.push({ id: detail.entityId, name: detail.entityName });
            }
        });
        return customers;
    };

    getLocationList = () => {
        const {
            auth: { userDetails },
        } = this.props;
        const locations = [];
        userDetails.forEach((detail) => {
            if (detail.entityType === 'Location' && detail.parentId === Config.X_PharmacyID) {
                if (Config.X_LocationID === detail.entityId) {
                    this.selectedLocation = detail.entityName;
                }
                locations.push({ id: detail.entityId, name: detail.entityName });
            }
        });
        return locations;
    };

    updateLocation = (payload) => {
        // set last visited location in localstorage
        const {
            history,
            updateLocation,
            auth: {
                userAccount: { user_id },
            },
        } = this.props;

        LocalStorageService.setLastVisitedLocation(user_id, {
            X_PharmacyID: Config.X_PharmacyID,
            X_LocationID: Config.X_LocationID,
        });

        // currentPathname is used to generalize the page view tracking when
        // there is a change in location / cust
        payload.currentPathname = history.location.pathname;
        updateLocation(payload);
    };

    handleCustomerChange = (suggestion, name) => {
        // TODO need to turn config object into a redux store so that any number of components can subscribe to its updates
        const {
            fetchIvrDetails,
            updateCustomer,
            updateWithExternalCustomer,
            fetchLocationDetails,
            auth: { userDetails, isInternalUser },
        } = this.props;
        Config.X_PharmacyID = suggestion;

        // find out if the array has the id
        const idInUserDetails = userDetails.some(
            (locationOrCustomer) =>
                locationOrCustomer.entityId === suggestion || locationOrCustomer.parentId === suggestion
        );
        if (idInUserDetails) {
            const custLocation = _.find(userDetails, { parentId: suggestion, entityType: 'Location' });
            const customer = _.find(userDetails, { entityId: suggestion, entityType: 'Customer' });
            const custDetails = { id: suggestion, isInternal: isInternalUser, idInUserDetails: idInUserDetails };
            Config.X_LocationID = custLocation.entityId;
            if (customer) {
                custDetails['perms'] = customer.perm;
            }
            updateCustomer(custDetails);
            this.updateLocation({ id: custLocation.entityId, name: custLocation.entityName, perms: custLocation.perm });
            fetchLocationDetails();
            fetchIvrDetails();
        }
        // handle the situation where it's an internal user that's requesting the data
        else if (isInternalUser) {
            // you're going to be updating from a user that isn't currently loaded into userDetails
            updateWithExternalCustomer({ id: suggestion, name: name }).then(() => {
                fetchIvrDetails();
            });
        }
        // external user trying to access a pharmacy that they shouldn't have access to
        else {
        }
    };

    handleLocationChange = (suggestion) => {
        const {
            fetchIvrDetails,
            fetchLocationDetails,
            auth: { userDetails },
        } = this.props;
        Config.X_LocationID = suggestion;
        const locDetails = _.find(userDetails, { entityId: Config.X_LocationID, entityType: 'Location' });
        this.updateLocation({ id: locDetails.entityId, name: locDetails.entityName, perms: locDetails.perm });
        fetchLocationDetails();
        fetchIvrDetails();
    };

    resetValue = () => {
        this.setState({ custName: null, locName: null });
    };

    updateText = (val, e) => {
        this.setState({
            [e.target.name]: val,
        });
    };

    render() {
        const {
            customerName,
            auth: {
                selectedCustomer,
                selectedLocation,
                customersById,
                locationsByCustomerId,
                isInternalUser,
                userAccount,
                locationDetail,
                userDetails,
            },
            pharmacy,
        } = this.props;

        return (
            <div style={{ height: '100%' }} className="grid-container flex-middle flex-right">
                <Cell style={{ width: '100%' }} className="flex-right flex-middle">
                    <SettingsPopover
                        logout={this.logout}
                        changeCustomer={this.handleCustomerChange}
                        changeLocation={this.handleLocationChange}
                        isInternal={isInternalUser}
                        customersById={customersById}
                        locationsByCustomerId={locationsByCustomerId}
                        selectedCustomer={selectedCustomer}
                        selectedLocation={selectedLocation}
                        customerName={customerName}
                        userAccount={userAccount}
                        userDetails={userDetails}
                        locationDetail={locationDetail}
                        pharmacy={pharmacy}
                    />
                </Cell>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        ivr: { ...state.settings.mainGreeting },
        customerName: state.pharmacy.pharmacy ? state.pharmacy.pharmacy.name : '',
        pharmacy: state.pharmacy.pharmacy ? state.pharmacy.pharmacy : {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout(LOG_OUT_ON_USER_CLICK)),
    fetchIvrDetails: () => dispatch(fetchIvrDetails()),
    updateCustomer: (data) => dispatch(updateCustomer(data)),
    updateWithExternalCustomer: (data) => dispatch(updateWithExternalCustomer(data)),
    updateLocation: (data) => dispatch(updateLocation(data)),
    fetchLocationDetails: () => dispatch(fetchLocationDetails()),
    fetchUnreadVoicemails: (data) => dispatch(fetchUnreadVoicemails(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IconMenus));
