/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'waitlist/';

export const waitlistTypes = {
    FETCH_WAITLISTS: `${typePrefix}FETCH_WAITLISTS`,
    FETCH_WAITLISTS_SUCCESS: `${typePrefix}FETCH_WAITLISTS_SUCCESS`,
    FETCH_WAITLISTS_FAILED: `${typePrefix}FETCH_WAITLISTS_FAILED`,

    DOWNLOAD_WAITLIST_CSV: `${typePrefix}DOWNLOAD_WAITLIST_CSV`,
    DOWNLOAD_WAITLIST_CSV_SUCCESS: `${typePrefix}DOWNLOAD_WAITLIST_CSV_SUCCESS`,
    DOWNLOAD_WAITLIST_CSV_FAILED: `${typePrefix}DOWNLOAD_WAITLIST_CSV_FAILED`,

    REMOVE_FROM_WAITLIST_REQUEST: `${typePrefix}REMOVE_FROM_WAITLIST_REQUEST`,
    REMOVE_FROM_WAITLIST_SUCCESS: `${typePrefix}REMOVE_FROM_WAITLIST_SUCCESS`,
    REMOVE_FROM_WAITLIST_FAILURE: `${typePrefix}REMOVE_FROM_WAITLIST_FAILURE`,

    UPDATE_WAITLIST_STATUS_REQUEST: `${typePrefix}UPDATE_WAITLIST_STATUS_REQUEST`,
    UPDATE_WAITLIST_STATUS_SUCCESS: `${typePrefix}UPDATE_WAITLIST_STATUS_SUCCESS`,
    UPDATE_WAITLIST_STATUS_FAILURE: `${typePrefix}UPDATE_WAITLIST_STATUS_FAILURE`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
