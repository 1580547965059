import _ from 'lodash';

import { userType, userAction } from './User';
import { conversationType, conversationAction } from './Conversation';
import { messageType, messageAction } from './Message';
import { socketType } from '../Socket';
import authType from '../actions/Auth';
import { sendNotification } from '../../utils/notification';
import { templateAction } from './Template';
import { NotificationGroupType } from '../../constants/Notification';
import { onboardingActions } from './Onboarding';

const inboxMiddleware = (store) => (next) => (action) => {
    let nextAction = true;

    if (action.type === userType.RESET_USER_STORE) {
        // Trigger next action early so that next dispatch has access to updated user store
        // TODO probably a better way to handle this
        nextAction = false;
        next(action);

        // Clearing conversation since user is reset
        store.dispatch(conversationAction.resetConversationStore());

        // Automatically get users after reset event
        if (action.autoGetContacts) {
            store.dispatch(userAction.getUsers(action.autoSelectActive, false, true));
        }
    } else if (action.type === authType.UPDATE_CUSTOMER) {
        const {
            auth: { isAuthenticated },
        } = store.getState();

        if (isAuthenticated) {
            store.dispatch(userAction.clearActiveUser());
        }
    } else if (action.type === authType.UPDATE_LOCATION) {
        const {
            auth: { isAuthenticated },
        } = store.getState();

        if (isAuthenticated) {
            store.dispatch(userAction.clearActiveUser());
            store.dispatch(templateAction.getTemplates());
            store.dispatch(onboardingActions.getInboxLocationOnboarding({ locationChanged: true }));
        }
    } else if (action.type === userType.SEARCH_USERS_SUCCESS) {
        const {
            inboxUser: { vmUser },
        } = store.getState();
        nextAction = false;
        next(action);

        const firstUser = _.find(action.users, function (obj) {
            return vmUser && obj.inbox_user_id === vmUser.inbox_user_id;
        });

        if (firstUser) {
            store.dispatch(userAction.setActiveUser(firstUser.inbox_user_id));
        }
    } else if (action.type === userType.GET_USERS_SUCCESS) {
        if (action.users && action.autoSelectActive && action.users.length > 0) {
            // Trigger next action early so that next dispatch has access to updated user store
            // TODO probably a better way to handle this
            nextAction = false;
            next(action);

            // Auto select the first user in user list
            const firstUser = action.users[0];
            store.dispatch(userAction.setActiveUser(firstUser.inbox_user_id));
        }
    } else if (action.type === userType.UPDATE_USER_SUCCESS) {
        nextAction = false;
        next(action);
        store.dispatch(conversationAction.getConversations());
    } else if (action.type === userType.SET_ACTIVE_USER) {
        // Clearing conversation since active user has changed
        store.dispatch(conversationAction.resetConversationStore());
        store.dispatch(userAction.phoneTypeLookup({ userId: action.activeUserId }));
    } else if (action.type === userType.CLEAR_ACTIVE_USER) {
        // Clearing conversation since active user has changed
        store.dispatch(conversationAction.resetConversationStore());
    } else if (action.type === userType.CREATE_USER_SUCCESS) {
        // Reset user store to close dialog and refresh user list
        store.dispatch(userAction.resetUserStore(true));
    }
    // else if (action.type === userType.CREATE_BULK_USERS_SUCCESS) {
    // Reset user store to close dialog and refresh user list
    // store.dispatch(userAction.resetUserStore(true));
    // Refresh users list after creating new user
    // }
    else if (action.type === conversationType.RESET_CONVERSATION_STORE) {
        // Clearing message since conversation is being reset
        store.dispatch(messageAction.resetMessageStore());
        store.dispatch(userAction.debouncedGetUsersActivityUnreadCount());
    } else if (action.type === conversationType.SET_ACTIVE_CONVERSATION) {
        // Clearing message since active conversation has changed
        store.dispatch(messageAction.resetMessageStore());
    } else if (action.type === conversationType.GET_CONVERSATIONS_SUCCESS) {
        if (action.conversations && action.autoSelectActive && action.conversations.length > 0) {
            // Auto select the first conversation in conversation list
            const firstConversation = action.conversations[0];
            store.dispatch(conversationAction.setActiveConversation(firstConversation.inbox_conversation_id));
        }
    } else if (action.type === conversationType.CREATE_CONVERSATION_SUCCESS) {
        // Refresh conversation list after creating new conversation
        store.dispatch(conversationAction.getConversations(true));
        // Ensure create conversation dialog is closed
        store.dispatch(conversationAction.closeCreateConversationDialog());
    } else if (action.type === messageType.CREATE_MESSAGE_SUCCESS) {
        // Refresh message list after creating new message
        store.dispatch(messageAction.getMessages());
    } else if (action.type === messageType.GET_LATEST_MESSAGE_SUCCESS) {
        const { inboxMessage } = store.getState();
        if (
            inboxMessage.messages &&
            inboxMessage.messages[inboxMessage.messages.length - 1].inbox_message_id !==
                action.latestMessage.inbox_message_id
        ) {
            // Refresh message list since latest message doesn't match current model
            store.dispatch(messageAction.getMessages());
        }
    } else if (action.type === messageType.UPDATE_MESSAGE_READ_STATUS_SUCCESS) {
        const { inboxUser, inboxConversation } = store.getState();
        const { userMap, activeUserId } = inboxUser;
        const { activeConversation } = inboxConversation;
        const activeUser = userMap[activeUserId];

        // Update local representation of message viewed status in patient list
        if (_.get(activeUser, 'latest_message.inbox_message_id') === action.messageId) {
            store.dispatch(userAction.updateActiveUserMessageStatus(action.messageId));
        }

        // Update local representation of message viewed status in conversation list
        if (_.get(activeConversation, 'latest_message.inbox_message_id') === action.messageId) {
            store.dispatch(conversationAction.updateActiveConversationMessageStatus(action.messageId));
        }
    } else if (action.type === userType.UPDATE_ACTIVE_USER_MESSAGE_STATUS) {
        store.dispatch(userAction.debouncedGetUsersActivityUnreadCount());
    } else if (action.type === userType.GET_LATEST_USER_ACTIVITY_SUCCESS) {
        const { inboxUser } = store.getState();
        const { latestActivity } = action;

        if (latestActivity) {
            const localUser = inboxUser.userMap[latestActivity.patient_inbox_user_id];
            if (
                !localUser ||
                (localUser.latest_message &&
                    localUser.latest_message.inbox_message_id !== latestActivity.latest_message_inbox_message_id)
            ) {
                store.dispatch(userAction.getUsers(false, false, true));
            }
        }
    } else if (action.type === socketType.SOCKET_RECEIVE_MESSAGE) {
        if (action.payload && action.payload.messageType === 'createMessage') {
            const { inboxUser, inboxConversation } = store.getState();
            const { activeUserId } = inboxUser;
            const { activeConversationId } = inboxConversation;
            const { messageAttributes } = action.payload;
            const messagePatientId = messageAttributes.patient_inbox_user_id;
            const messageConversationId = messageAttributes.conversation_id;
            const messageUserType = messageAttributes.user_type;
            const isPatientMessage = messageUserType === 'patient';
            const isActiveConversation = activeConversationId === messageConversationId;

            // Trigger activity list refresh
            store.dispatch(userAction.getUsers(false, false, true));

            if (isPatientMessage && !isActiveConversation) {
                store.dispatch(userAction.debouncedGetUsersActivityUnreadCount(true));
            }

            // Check if patient is active patient
            if (activeUserId === messagePatientId) {
                // Check if conversation is active conversation
                if (isActiveConversation) {
                    store.dispatch(messageAction.getMessages());
                } else {
                    store.dispatch(conversationAction.getConversations());
                }
            }

            // Send browser notification if message is from patient
            if (isPatientMessage) {
                sendNotification(
                    'New Patient Message',
                    'Click here to view your new message',
                    NotificationGroupType.inbox
                );
            }
        } else if (action.payload && action.payload.messageType === 'createUser') {
            // Trigger debounced activity list refresh
            store.dispatch(userAction.debouncedGetUsers(false, false, true));
        }
    }

    if (nextAction) {
        next(action);
    }
};

export default inboxMiddleware;
