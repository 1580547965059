import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import LearnMoreIcon from './LearnMoreIcon';
import ButtonWithTracking from 'components/Common/ButtonWithTracking';

const styles = (theme) => ({
    learnMoreDialog: {
        display: 'flex',
        fontSize: '16px',
        fontWeight: 600,
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#292929',
    },
    subTitle: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#9b9b9b',
    },
    hdivider: {
        margin: '24px 0 24px 0',
    },
    description: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#101010',
    },
    valueProp: {
        fontSize: '10px',
        color: '#101010',
    },
    valuePropIcon: {
        width: '48px',
        height: '48px',
        marginRight: '15px',
    },
    additionalDetailsHeader: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#101010',
    },
    marginBottom24: {
        marginBottom: '24px',
    },
    ctaContainer: {
        paddingLeft: '24px',
    },
    closeButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
    btnWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    btnContainer: {
        [theme.breakpoints.up('xs')]: {
            marginTop: '0px',
            marginBottom: '24px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '55px',
        },
    },
});

function LearnMoreDialog(props) {
    const {
        visible,
        onHide,
        data: { title, subTitle, description, valueProps, learnMore, ctaButtonTitle, additionalDetails },
        sendNPEInterestEmail,
        sendNpeInterestLoading,
        classes,
        snowplow,
    } = props;

    const openLearnMoreLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <Dialog open={visible} onClose={onHide} fullWidth maxWidth="md">
            <DialogContent classes={{ root: classes.learnMoreDialog }}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onHide}>
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item md={8}>
                        <Typography gutterBottom className={classes.title}>
                            {title}
                        </Typography>
                        {subTitle && (
                            <Typography gutterBottom className={classes.subTitle}>
                                {subTitle}
                            </Typography>
                        )}
                        <Divider className={classes.hdivider} />
                        <Typography gutterBottom className={`${classes.marginBottom24} ${classes.description}`}>
                            {description}
                        </Typography>
                        <Grid xs={12} md={12} item container justify="space-between" spacing={2}>
                            {valueProps.map((prop) => (
                                <Grid
                                    md={6}
                                    key={prop.icon}
                                    item
                                    container
                                    wrap="nowrap"
                                    className={classes.marginBottom24}
                                >
                                    <Grid item>
                                        <LearnMoreIcon variant={prop.icon} />
                                    </Grid>
                                    <Grid item className={classes.valueProp}>
                                        <Typography style={{ fontWeight: 'bold' }}> {prop.title} </Typography>
                                        <Typography>{prop.body}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid xs={12} md={12} item container direction="column" className={classes.marginBottom24}>
                            {additionalDetails && (
                                <Fragment>
                                    <Typography
                                        className={classes.additionalDetailsHeader}
                                        style={{ marginBottom: '16px' }}
                                    >
                                        {additionalDetails.header}
                                    </Typography>
                                    {additionalDetails.items.map((item, i) => (
                                        <Grid key={i} item md={12} alignItems="center" container>
                                            <Icon style={{ marginRight: '15px', color: 'grey' }} fontSize="small">
                                                check
                                            </Icon>
                                            <Typography> {item} </Typography>
                                        </Grid>
                                    ))}
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item md={4} className={classes.ctaContainer} container>
                        <Grid item md={1}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item container direction="column" md={11} className={classes.btnContainer}>
                            <ButtonWithTracking
                                variant="outlined"
                                color="primary"
                                onClick={() => openLearnMoreLink(learnMore.link)}
                                style={{ marginBottom: '8px' }}
                                buttonText={learnMore.title || ''}
                                snowplow={{
                                    ...snowplow,
                                    action: 'learn-more',
                                }}
                            />

                            <div className={classes.btnWrapper}>
                                <ButtonWithTracking
                                    variant="contained"
                                    color="primary"
                                    onClick={sendNPEInterestEmail}
                                    disabled={sendNpeInterestLoading}
                                    fullWidth={true}
                                    buttonText={ctaButtonTitle || ''}
                                    snowplow={{
                                        ...snowplow,
                                        action: 'get-feature',
                                    }}
                                />
                                {sendNpeInterestLoading && (
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

LearnMoreDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        title: PropTypes.string,
        subTitle: PropTypes.string,
        description: PropTypes.string,
        valueProps: PropTypes.array,
        learnMore: PropTypes.object,
        ctaButtonTitle: PropTypes.string,
        additionalDetails: PropTypes.object,
    }),
    sendNPEInterestEmail: PropTypes.func,
    sendNpeInterestLoading: PropTypes.bool,
};

export default withStyles(styles)(LearnMoreDialog);
