import React from 'react';
import { Tooltip } from '@material-ui/core';
import { FontIcon } from 'react-md';

const DescriptionView = ({ descName, descVal, isTitle, tooltipText }) => (
    <div className="flex-space-between">
        <div className={`${isTitle ? 'md-font-medium' : 'standard-margin-left-10'}`}>
            {descName}
            {isTitle ? (
                <Tooltip title={tooltipText} placement="top" classes={{ tooltip: 'info-tooltip' }} enterTouchDelay={0}>
                    <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                        info
                    </FontIcon>
                </Tooltip>
            ) : (
                ''
            )}
        </div>
        <div>{descVal}</div>
    </div>
);

export default DescriptionView;
