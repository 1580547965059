import './MultiselectSidebar.sass';
import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { Paper, Button, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import PatientsList from './PatientsList';

export default function MultiselectSidebar(props) {
    const {
        SlideTransition,
        selectedUsers,
        messageablePatients,
        onSelectedChange,
        onMainButtonClick,
        nameFormat,
        mainButtonLabel,
        headerComponent,
        formatDate,
    } = props;
    const [tableHeight, setTableHeight] = useState(120);

    useEffect(() => {
        if (!_.isEmpty(selectedUsers)) {
            setTableHeight(Math.min(_.size(selectedUsers) * 40, window.innerHeight - 400));
        } else {
            setTableHeight(120);
        }
    }, [_.size(selectedUsers)]);

    return (
        <SlideTransition in={!_.isEmpty(selectedUsers)}>
            <Paper className={classnames({ 'multiselect-sidebar': true })}>
                <div className="heading">
                    {!_.isNil(headerComponent) ? (
                        React.cloneElement(headerComponent, { count: _.size(selectedUsers) })
                    ) : (
                        <div className="default-heading-wrapper">
                            {numeral(_.size(selectedUsers)).format('0,0')} Selected{' '}
                            {pluralize('Patient', _.size(selectedUsers))}
                            {_.isEmpty(selectedUsers) ? null : (
                                <Tooltip title="Only patients with a valid mobile phone number may be sent messages.">
                                    <span className="messagable-count">
                                        {_.size(messageablePatients) === _.size(selectedUsers)
                                            ? 'All'
                                            : numeral(_.size(messageablePatients)).format('0,0')}{' '}
                                        messageable
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
                <div className="table-wrapper">
                    <PatientsList
                        selectedUsers={selectedUsers}
                        onSelectedChange={onSelectedChange}
                        width={320}
                        height={tableHeight}
                        nameFormat={nameFormat}
                        formatDate={formatDate}
                    />
                    <div className="sidebar-actions">
                        <Button
                            className="unselect-button"
                            disabled={_.isEmpty(selectedUsers)}
                            onClick={() => {
                                onSelectedChange({});
                            }}
                        >
                            Unselect all
                        </Button>
                        <Button
                            className="message-button"
                            disabled={_.isEmpty(messageablePatients)}
                            variant="contained"
                            color="primary"
                            onClick={() => onMainButtonClick(selectedUsers)}
                        >
                            {mainButtonLabel}
                        </Button>
                    </div>
                </div>
            </Paper>
        </SlideTransition>
    );
}
MultiselectSidebar.defaultProps = {
    mainButtonLabel: 'Message',
};
