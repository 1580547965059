import React, { useState } from 'react';
import { Button, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightIcon from '@material-ui/icons/Highlight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

import { isAttributeMapped, getColumnName, getMappedCount } from 'components/Patients/csv/csvUtil';
import CsvColumnSummary from './CsvColumnSummary';

export default function MapFileAttributesContent({
    fileData,
    fileColumns,
    requiredFields,
    attributesMapping,
    setAttributesMapping,
    onDone,
    onGoBack,
    isMappingComplete,
    autoMappedAttributeCount,
}) {
    const [focusedField, setFocusedField] = useState('email');

    if (_.isNil(fileData)) {
        return null;
    }

    const allFields = [].concat(requiredFields);

    const mappedCount = getMappedCount(attributesMapping);
    const totalCount = _.size(allFields);

    const fieldNamesToLabels = {};
    _.each(allFields, ({ name, value }) => (fieldNamesToLabels[value] = name));

    return (
        <div className="map-file-attributes-content">
            <div className="heading">
                <Button variant="text" onClick={onGoBack}>
                    <KeyboardBackspaceIcon /> Go Back
                </Button>
                <div className="text">Please review/map the subscriber email to data file column</div>
            </div>

            <div className="tabs-and-content">
                <div className="required-fields">
                    <div className="tabs-heading">
                        <div className="mapping-counts">
                            {mappedCount} / {totalCount} mapped attributes
                        </div>
                    </div>
                    <Tabs value={focusedField} orientation="vertical">
                        {_.map(allFields, ({ name, value, optional }) => {
                            const colIndex = _.get(attributesMapping, `${value}.colIndex`);
                            const unmapped = !_.isFinite(colIndex);

                            return (
                                <Tab
                                    key={value}
                                    label={
                                        <span className={classnames({ 'configured-tab-label': true, unmapped })}>
                                            {unmapped ? (
                                                <span>{name}</span>
                                            ) : (
                                                <div className="mapped-tab-content">
                                                    <CheckCircleIcon />
                                                    <div className="name-and-column">
                                                        <div className="name-line">{name}</div>
                                                        <div className="column-line">
                                                            {getColumnName(fileColumns, colIndex, fileData.hasHeaders)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </span>
                                    }
                                    value={value}
                                    onClick={() => setFocusedField(value)}
                                />
                            );
                        })}
                    </Tabs>
                </div>

                <div className="focused-attribute-content">
                    <div className="column-header">Column for Email</div>
                    <div className="column-options-wrapper" style={{ maxHeight: `${window.innerHeight - 350}px` }}>
                        <div className="column-options">
                            {_.map(fileColumns, (c, colIndex) => {
                                const mappedLabels = [];
                                _.forIn(attributesMapping, (m, key) => {
                                    if (m.colIndex === colIndex) {
                                        mappedLabels.push(fieldNamesToLabels[key]);
                                    }
                                });

                                return (
                                    <CsvColumnSummary
                                        key={colIndex}
                                        fileData={fileData}
                                        fileColumns={fileColumns}
                                        colIndex={colIndex}
                                        selected={colIndex === _.get(attributesMapping, `${focusedField}.colIndex`)}
                                        mappedLabels={mappedLabels}
                                        onClick={(colIndex) => {
                                            setAttributesMapping({
                                                ...attributesMapping,
                                                [focusedField]: {
                                                    ..._.get(attributesMapping, focusedField),
                                                    colIndex,
                                                },
                                            });

                                            // if the next field is unmapped, then focus it after a short timeout
                                            const focusedIndex = _.findIndex(
                                                requiredFields,
                                                ({ value }) => value === focusedField
                                            );
                                            const nextFieldName = _.get(requiredFields, `[${focusedIndex + 1}].value`);
                                            if (
                                                _.isString(nextFieldName) &&
                                                !isAttributeMapped(nextFieldName, attributesMapping)
                                            ) {
                                                setTimeout(() => setFocusedField(nextFieldName), 150);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="actions">
                <span className="notices">
                    {!_.isFinite(autoMappedAttributeCount) || autoMappedAttributeCount === 0 ? null : (
                        <div className="auto-mapping-alert">
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <HighlightIcon className="highlight-icon" />
                                    <div>Auto-mapped {autoMappedAttributeCount} attributes.</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>
                                        Based on common file column header names, we have automatically chosen likely
                                        candidates email.
                                    </p>
                                    <p>
                                        <strong>Note:</strong> Please ensure that these mappings were made correctly
                                        before continuing.
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )}
                </span>

                <span>
                    <Button variant="contained" color="primary" disabled={!isMappingComplete} onClick={onDone}>
                        Upload subscribers from this file
                    </Button>
                </span>
            </div>
        </div>
    );
}
