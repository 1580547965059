import React, { useState, useRef, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import { DpDialog } from './DpDialog/DpDialog';
import { displayToast } from 'redux/actionCreators/Snackbar';

const DpImageCropDialog = (props) => {
    const {
        onClose,
        onComplete,
        minImageWidth,
        minImageHeight,
        minCropWidth,
        minCropHeight,
        maxCropHeight,
        visible = true,
    } = props;
    const [uploadImage, setUploadImage] = useState();
    const [crop, setCrop] = useState({ width: minCropWidth, height: minCropHeight });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const [isDropZoneVisible, setDropZoneVisibility] = useState(true);
    const selectedImgMIME = useRef('image/jpeg');

    const checkImageDimensions = (imgBlob) => {
        const { displayToast } = props;
        return new Promise((resolve) => {
            const tempImage = new Image();
            tempImage.src = imgBlob;
            tempImage.onload = () => {
                if (tempImage.width < minImageWidth || tempImage.height < minImageHeight) {
                    displayToast({
                        text: `Minimum image dimensions should be ${minImageWidth}W X ${minImageHeight}H`,
                        type: 'error',
                    });
                    resolve(false);
                }
                resolve(true);
            };
        });
    };

    const onSelectFile = (e) => {
        if (_.get(e, 'length', 0) > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                const isValidImage = await checkImageDimensions(reader.result);
                if (isValidImage) {
                    selectedImgMIME.current = e[0].type;
                    setUploadImage(reader.result);
                    setDropZoneVisibility(false);
                }
            });
            reader.readAsDataURL(e[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const getCroppedImg = () => {
        const image = imgRef.current;
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const context = canvas.getContext('2d');
        context.imageSmoothingQuality = 'high';

        context.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
        );

        return canvas;
    };

    const onBack = () => {
        setDropZoneVisibility(true);
        setUploadImage(null);
    };

    const addImage = () => {
        const croppedImageCanvas = getCroppedImg();
        croppedImageCanvas.toBlob((imgBlob) => {
            onComplete({ imgDataUrl: croppedImageCanvas.toDataURL(selectedImgMIME.current), imgBlob });
        }, selectedImgMIME.current);
    };

    const checkIfAddImageDisabled = () => {
        const cropWidth = _.get(completedCrop, 'width', 0);
        return !uploadImage || cropWidth === 0;
    };

    return (
        <DpDialog.Dialog id="imageCropDialog" open={visible} onClose={onClose} maxWidth="md">
            <DpDialog.Content className="dp-image-crop-dialog">
                <DpDialog.ContentHeader heading="Image uploader" onClose={onClose}></DpDialog.ContentHeader>
                {isDropZoneVisible ? (
                    <Dropzone
                        maxFiles={1}
                        multiple={false}
                        className="file-dropzone"
                        onDrop={(file) => onSelectFile(file)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="file-drop-area centered-content" {...getRootProps()}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <input
                                        {...getInputProps()}
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        onChange={(e) => onSelectFile(e.target.files)}
                                    />
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontSize: '1.2rem',
                                            textAlign: 'center',
                                            marginBottom: '16px',
                                            maxWidth: '370px',
                                        }}
                                    >
                                        Drag and drop the file you want to upload
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: '#3793ff',
                                            textAlign: 'center',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        or click to select a file from your computer
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: '#999999',
                                            textAlign: 'center',
                                            maxWidth: '370px',
                                            fontSize: '13px',
                                        }}
                                    >
                                        (Only *.jpeg and *.png images will be accepted)
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                ) : (
                    <Fragment>
                        <div className="flex-middle" style={{ marginBottom: '5px' }}>
                            <IconButton aria-label="Go Back" onClick={onBack} edge="start">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h5" className="title">
                                Back to Select File
                            </Typography>
                        </div>
                        <ReactCrop
                            src={uploadImage}
                            crop={crop}
                            onChange={(cropState) => setCrop(cropState)}
                            onComplete={(cropState) => setCompletedCrop(cropState)}
                            onImageLoaded={onLoad}
                            minHeight={minCropHeight}
                            minWidth={minCropWidth}
                            maxHeight={maxCropHeight}
                        />
                    </Fragment>
                )}
            </DpDialog.Content>
            <DpDialog.Actions>
                <Button onClick={onClose} color="primary" variant="text">
                    Close
                </Button>
                <Button onClick={addImage} color="primary" variant="contained" disabled={checkIfAddImageDisabled()}>
                    Add
                </Button>
            </DpDialog.Actions>
        </DpDialog.Dialog>
    );
};

const mapDispatchToProps = {
    displayToast,
};

export default connect(null, mapDispatchToProps)(DpImageCropDialog);
