import React from 'react';
import { CardTitle } from 'react-md';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import ReportTooltip from 'components/Report/Common/ReportTooltip';

const ReportTitle = ({ tooltipText, children, ...rest }) => (
    <CardTitle {...rest} avatar={<DragIndicatorIcon fontSize="large" className="drag-selector" />}>
        <ReportTooltip text={tooltipText} />
        {children}
        <span className="flex1"></span>
    </CardTitle>
);

export default ReportTitle;
