import _ from 'lodash';
import { pharmacyType, pharmacyAction } from '.';
import authType from '../actions/Auth';
import { pharmacyContractActions } from 'redux/PharmacyContract/action';
import { isContractSignedForLocation } from 'utils/helper';

const fetchPharmacyContractInformation = (pharmacy, locationId, store) => {
    const { pharmacyContract } = store.getState();
    const { skipSigningContract, fetchContractInformation } = pharmacyContractActions;

    if (!isContractSignedForLocation(pharmacyContract, locationId)) {
        const location = _.find(pharmacy.location, (l) => l.id === locationId);
        const salesforceId = _.get(location, 'attr.salesforceId.value');

        if (_.isEmpty(salesforceId)) {
            store.dispatch(skipSigningContract(locationId));
        } else {
            store.dispatch(fetchContractInformation(locationId, salesforceId));
        }
    }
};

const pharmacyMiddleware = (store) => (next) => (action) => {
    if (action.type === authType.UPDATE_CUSTOMER) {
        const { pharmacy } = store.getState();
        if (action.payload && action.payload.id) {
            const pharmacyId = action.payload.id;
            // Check if pharmacy changed
            if (!pharmacy.pharmacy || pharmacy.pharmacy.id !== pharmacyId) {
                store.dispatch(pharmacyAction.getPharmacy(pharmacyId));
            }
        }
    } else if (action.type === authType.UPDATE_LOCATION) {
        const { pharmacy, auth } = store.getState();
        if (action.payload && action.payload.id) {
            const locationId = action.payload.id;
            // Check if location changed
            if (pharmacy.activeLocationId !== locationId) {
                store.dispatch(pharmacyAction.updateActiveLocation(locationId));
                store.dispatch(pharmacyAction.updateEnabledServices(locationId, pharmacy.pharmacy));
                if (pharmacy.pharmacy !== null && auth.isAuthenticated) {
                    fetchPharmacyContractInformation(pharmacy.pharmacy, locationId, store);
                }
            }
        }
    } else if (action.type === pharmacyType.GET_PHARMACY_SUCCESS) {
        const { pharmacy, auth } = store.getState();
        if (auth.isAuthenticated) {
            fetchPharmacyContractInformation(action.pharmacy, pharmacy.activeLocationId, store);
        }
        store.dispatch(pharmacyAction.updateEnabledServices(pharmacy.activeLocationId, action.pharmacy));
    }

    next(action);
};

export default pharmacyMiddleware;
