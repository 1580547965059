import BusinessInformationActions from './BusinessInformation';
import DoctorsLineActions from './DoctorsLine';
import LanguagesActions from './Languages';
import LeaveMessageActions from './LeaveMessage';
import MainGreetingActions from './MainGreeting';
import PhoneLineActions from './PhoneLine';
import SpeakWithPharmacistActions from './SpeakWithPharmacist';
import NewPatientLineActions from './NewPatientLine';
import AAMainMenuActions from './AAMainMenu';

export default {
    FETCH_SETTINGS: 'FETCH_SETTINGS',
    ...BusinessInformationActions,
    ...DoctorsLineActions,
    ...LanguagesActions,
    ...LeaveMessageActions,
    ...MainGreetingActions,
    ...PhoneLineActions,
    ...SpeakWithPharmacistActions,
    ...NewPatientLineActions,
    ...AAMainMenuActions,
};
