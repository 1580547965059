import _ from 'lodash';

import { bannerConfigTypes } from './banner-config-type';

export const initialState = {
    savingBannerConfigSettings: false,
};

export const bannerConfigReducer = (state = initialState, { type }) => {
    switch (type) {
        case bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_REQUEST:
            return {
                ...state,
                savingBannerConfigSettings: true,
            };
        case bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_SUCCESS:
        case bannerConfigTypes.SAVE_BANNER_CONFIG_SETTINGS_FAILED:
            return {
                ...state,
                savingBannerConfigSettings: false,
            };
        default:
            return state;
    }
};
