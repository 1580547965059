import userType from './type';
import _ from 'lodash';

// TODO this live in action
const updateUserMap = (newUserList, currentUserMap) => {
    // Update any existing users and add new users
    const updatedUserMap = { ...currentUserMap };
    newUserList.forEach((user) => {
        updatedUserMap[user.inbox_user_id] = user;
    });

    return updatedUserMap;
};

// TODO this live in action
const updateUserList = (newUserList, currentUserList) => {
    // Update any existing users and add new users
    const updatedUserList = currentUserList;
    newUserList.forEach((user) => {
        updatedUserList.push(user.inbox_user_id);
    });

    return updatedUserList;
};

// TODO this live in action
const overrideUserList = (newUserList) => {
    // Override existing users with new user list
    const updatedUserList = [];
    newUserList.forEach((user) => {
        updatedUserList.push(user.inbox_user_id);
    });

    return updatedUserList;
};

const shouldUpdateUserList = (currentPage, responsePage) => {
    return responsePage > currentPage;
};

export const initialState = {
    sending: false,
    loading: false,
    lookingUpPhoneTypeByUserId: {},
    fetchingUnreadMessageCount: false,
    activeUserId: null,
    userMap: {},
    userId: null,
    userList: [],
    allUsers: [],
    userPage: 0,
    userPageItems: 50,
    searchList: [],
    searchPage: 0,
    searchPageItems: 50,
    searchQuery: null,
    searchFilter: null,
    unreadMessageCount: 0,
    createUserMessage: '',
    updateUserMessage: '',
    showEditUserDialog: false,
    error: {},
    vmUser: null,
};

export const userReducer = (state = initialState, action) => {
    let { userMap, lookingUpPhoneTypeByUserId } = state;
    switch (action.type) {
        case userType.RESET_USER_STORE:
            return {
                ...initialState,
            };
        case userType.SET_ACTIVE_USER:
            return {
                ...state,
                activeUserId: action.activeUserId,
                userMap: {
                    ...state.userMap,
                    [action.activeUserId]: _.isNil(action.userMapData)
                        ? state.userMap[action.activeUserId]
                        : action.userMapData,
                },
            };
        case userType.CLEAR_ACTIVE_USER:
            return {
                ...state,
                activeUserId: null,
            };
        case userType.CREATE_USER_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case userType.CREATE_USER_SUCCESS:
            return {
                ...state,
                sending: false,
                createUserMessage: '',
            };
        case userType.CREATE_USER_FAILURE:
            return {
                ...state,
                sending: false,
                createUserMessage: action.error.message,
                error: action.error,
            };
        case userType.UPDATE_USER_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case userType.UPDATE_USER_SUCCESS:
            userMap[action.userId] = action.mergedUserData;
            return {
                ...state,
                sending: false,
                updateUserMessage: '',
                showEditUserDialog: false,
            };
        case userType.UPDATE_USER_FAILURE:
            return {
                ...state,
                sending: false,
                updateUserMessage: action.error.message,
                error: action.error,
            };
        case userType.VERIFY_USER_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case userType.VERIFY_USER_SUCCESS:
            userMap[action.userId] = action.mergedUserData;
            return {
                ...state,
                sending: false,
            };
        case userType.VERIFY_USER_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case userType.ARCHIVE_USER_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case userType.ARCHIVE_USER_SUCCESS:
            userMap[action.userId] = action.mergedUserData;
            return {
                ...state,
                sending: false,
                updateUserMessage: '',
                showEditUserDialog: false,
            };
        case userType.ARCHIVE_USER_FAILURE:
            return {
                ...state,
                sending: false,
                updateUserMessage: action.error.message,
                error: action.error,
            };
        case userType.USER_PHONE_TYPE_LOOKUP_REQUEST:
            return {
                ...state,
                lookingUpPhoneTypeByUserId: {
                    ...lookingUpPhoneTypeByUserId,
                    [action.userId]: { fetching: true, error: null },
                },
            };
        case userType.USER_PHONE_TYPE_LOOKUP_SUCCESS:
            return {
                ...state,
                userMap: {
                    ...userMap,
                    [action.userId]: action.mergedUserData,
                },
                lookingUpPhoneTypeByUserId: {
                    ...lookingUpPhoneTypeByUserId,
                    [action.userId]: { fetching: false, error: null },
                },
            };
        case userType.USER_PHONE_TYPE_LOOKUP_FAILURE:
            return {
                ...state,
                lookingUpPhoneTypeByUserId: {
                    ...lookingUpPhoneTypeByUserId,
                    [action.userId]: { fetching: false, error: action.error },
                },
            };

        case userType.CREATE_BULK_USERS_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case userType.CREATE_BULK_USERS_SUCCESS:
            return {
                ...state,
                sending: false,
                createUserMessage: '',
            };
        case userType.CREATE_BULK_USERS_FAILURE:
            return {
                ...state,
                sending: false,
                createUserMessage: action.error.message,
                error: action.error,
            };
        case userType.GET_USERS_LIST_REQUEST:
            return {
                ...state,
                loadingUsers: true,
            };
        case userType.GET_USERS_LIST_SUCCESS:
            return {
                ...state,
                allUsers: action.users,
                loadingUsers: false,
            };
        case userType.GET_USERS_LIST_FAILURE:
            return {
                ...state,
                loadingUsers: false,
            };
        case userType.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userType.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                userMap: updateUserMap(action.users, state.userMap), // TODO move logic to action
                userList: shouldUpdateUserList(state.userPage, action.page)
                    ? updateUserList(action.users, state.userList)
                    : overrideUserList(action.users), // TODO move logic to action
                userPage: action.page,
            };
        case userType.GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userType.SET_VM_USER:
            return {
                ...state,
                vmUser: action.user,
            };
        case userType.RESET_VM_USER:
            return {
                ...state,
                vmUser: null,
            };
        case userType.GET_USERS_ACTIVITY_UNREAD_COUNT_REQUEST:
            return {
                ...state,
                fetchingUnreadMessageCount: true,
            };
        case userType.GET_USERS_ACTIVITY_UNREAD_COUNT_SUCCESS:
            return {
                ...state,
                fetchingUnreadMessageCount: false,
                unreadMessageCount: action.unreadMessageCount,
            };
        case userType.GET_USERS_ACTIVITY_UNREAD_COUNT_FAILURE:
            return {
                ...state,
                fetchingUnreadMessageCount: false,
                // not setting error here, as it does not need to be surfaced to the user
            };
        case userType.SEARCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userType.SEARCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                userMap: updateUserMap(action.users, state.userMap), // TODO move logic to action
                searchList: shouldUpdateUserList(state.searchPage, action.page)
                    ? updateUserList(action.users, state.searchList)
                    : overrideUserList(action.users), // TODO move logic to action
                searchPage: action.page,
            };
        case userType.SEARCH_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userType.SET_SEARCH_QUERY:
            return {
                ...state,
                // searchList: [],
                searchPage: 0,
                searchQuery: action.searchQuery,
            };
        case userType.RESET_SEARCH_QUERY:
            return {
                ...state,
                searchList: [],
                searchPage: 0,
                searchQuery: null,
            };
        case userType.SET_SEARCH_FILTER:
            return {
                ...state,
                searchFilter: action.searchFilter,
                searchPage: 0,
            };
        case userType.RESET_SEARCH_FILTER:
            return {
                ...state,
                searchList: [],
                searchFilter: null,
                searchPage: 0,
            };
        case userType.OPEN_EDIT_USER_DIALOG:
            return {
                ...state,
                showEditUserDialog: true,
                updateUserMessage: '',
            };
        case userType.CLOSE_EDIT_USER_DIALOG:
            return {
                ...state,
                showEditUserDialog: false,
                updateUserMessage: '',
            };
        case userType.TOGGLE_EDIT_USER_DIALOG:
            return {
                ...state,
                showEditUserDialog: !state.showEditUserDialog,
                updateUserMessage: '',
            };
        case userType.UPDATE_ACTIVE_USER_MESSAGE_STATUS:
            return {
                ...state,
                userMap: action.userMap,
            };
        case userType.GET_LATEST_USER_ACTIVITY_REQUEST:
            return {
                ...state,
            };
        case userType.GET_LATEST_USER_ACTIVITY_SUCCESS:
            return {
                ...state,
            };
        case userType.GET_LATEST_USER_ACTIVITY_FAILURE:
            return {
                ...state,
            };
        case userType.CLEAR_CREATE_USER_MESSAGE:
            return {
                ...state,
                createUserMessage: '',
            };

        case userType.UPDATE_USER_MAP:
            return {
                ...state,
                userMap: (() => {
                    const newState = { ...state };
                    newState.userMap = { ...state.userMap };
                    newState.userMap[action.userId] = action.userData;
                    return newState;
                })(),
            };
        default:
            return state;
    }
};

export default userReducer;
