export default [
    // Starting with Month
    'MM/DD/YYYY',
    'YYYY/MM/DD',
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'MM/DD/YY',
    'YY/MM/DD',
    'MM-DD-YY',
    'YY-MM-DD',

    'M/DD/YYYY',
    'YYYY/M/DD',
    'M-DD-YYYY',
    'YYYY-M-DD',
    'M/DD/YY',
    'YY/M/DD',
    'M-DD-YY',
    'YY-M-DD',

    'MM/D/YYYY',
    'YYYY/MM/D',
    'MM-D-YYYY',
    'YYYY-MM-D',
    'MM/D/YY',
    'YY/MM/D',
    'MM-D-YY',
    'YY-MM-D',

    'M/D/YYYY',
    'YYYY/M/D',
    'M-D-YYYY',
    'YYYY-M-D',
    'M/D/YY',
    'YY/M/D',
    'M-D-YY',
    'YY-M-D',

    // Starting with Date and Month in string
    'D MMM YYYY',
    'D MMM YY',
    'Do MMM YYYY',
    'Do MMM YY',
    'D MMMM YYYY',
    'D MMMM YY',
    'Do MMMM YYYY',
    'Do MMMM YY',

    'MMM D YYYY',
    'MMM DD YYYY',
    'MMMM D YYYY',
    'MMMM DD YYYY',
    'MMM D YY',
    'MMM DD YY',
    'MMMM D YY',
    'MMMM DD YY',
];
