import React from 'react';
import LearnMoreButton from 'components/Common/LearnMoreButton';

const appType = 'wellnessClasses';

const RequestClassesDemo = () => {
    return (
        <div className="md-text-center">
            <LearnMoreButton appType={appType} variant="tabs" />
        </div>
    );
};

export default RequestClassesDemo;
