import Config from 'config';
import { restActionCreators } from 'redux/actionCreators/rest';
import { logHttpError } from '../../utils/log';

const createCustomerPackageAttribute = (customer, payload) => {
    const url = `${Config.config2_url}/cust/${customer.id}/custPackage/${customer.packageID}/custPackageAttr`;

    return restActionCreators
        .post(url, payload)
        .then(({ result }) => result)
        .catch((error) => {
            logHttpError(
                `An error occurred on createCustomerPackageAttribute for pharmacyId: ${customer.id}, packageId: ${customer.packageID}`,
                error
            );
            return Promise.reject(error);
        });
};

const updateCustomerPackageAttribute = (customer, payload) => {
    const url = `${Config.config2_url}/cust/${customer.id}/custPackage/${customer.packageID}/custPackageAttr/${payload.id}`;

    return restActionCreators
        .put(url, payload)
        .then(({ result }) => result)
        .catch((error) => {
            logHttpError(
                `An error occurred on updateCustomerPackageAttribute for pharmacyId: ${customer.id}, packageId: ${customer.packageID}`,
                error
            );
            return Promise.reject(error);
        });
};

export const bannerConfigService = {
    createCustomerPackageAttribute,
    updateCustomerPackageAttribute,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
