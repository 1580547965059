import Config from 'config';
import { logUtil } from 'utils/log';
import { restActionCreators } from 'redux/actionCreators/rest';

const getUsers = ({ pharmacyId, startRow, numRows, showInternal }) => {
    const args = {
        params: {
            start_row: startRow,
            num_rows: numRows,
            show_internal: showInternal,
        },
    };
    const url = `${Config.apiweb_url}/user-management/${pharmacyId}`;

    return restActionCreators
        .get(url, args)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while calling user management getUsers', error);
            return Promise.reject(error);
        });
};

const sendInvite = ({ user_type, email, pharmacy_name }) => {
    // TODO: the api endpoint in api-web needs to be moved out of inbox blueprint
    const url = `${Config.apiweb_url}/inbox/pharmacy/invite_user`;

    return restActionCreators
        .post(url, { user_type, email, pharmacy_name })
        .then((response) => {
            if (!response.success) {
                return Promise.reject(response);
            }
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while sending user invite', error);
            return Promise.reject(error);
        });
};

const deleteUser = ({ user_id }) => {
    const url = `${Config.apiweb_url}/user-management/user/${user_id}`;

    return restActionCreators
        .deleteReq(url)
        .then((response) => {
            if (!response.deleted) {
                return Promise.reject(response);
            }
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while deleting user', error);
            return Promise.reject(error);
        });
};

const getUserAuditLogs = ({ user_id }) => {
    const url = `${Config.apiweb_url}/user-management/user/${user_id}/logs`;

    return restActionCreators
        .get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while calling user management getUserAuditLogs', error);
            return Promise.reject(error);
        });
};

const getUserRoles = () => {
    const url = `${Config.config2_url}/userRoleTypes`;

    return restActionCreators
        .get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while calling get user roles', error);
            return Promise.reject(error);
        });
};

const addNewUser = (payload) => {
    const url = `${Config.apiweb_url}/user-management/user`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            if (!response.success) {
                return Promise.reject(response);
            }
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while creating new user', error);
            return Promise.reject(error);
        });
};

const editUser = (data) => {
    const url = `${Config.apiweb_url}/user-management/user/${data.user_id}`;

    return restActionCreators
        .put(url, data.payload)
        .then((response) => {
            if (!response.success) {
                return Promise.reject(response);
            }
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while editing user', error);
            return Promise.reject(error);
        });
};

export const userManagementService = {
    getUsers,
    sendInvite,
    deleteUser,
    getUserAuditLogs,
    getUserRoles,
    addNewUser,
    editUser,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
