import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { Sticky } from 'react-sticky';
import {
    IconButton,
    Chip,
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Tooltip,
    MenuItem,
} from '@material-ui/core';
import UserDialog from './UserDialog';
import FilterListIcon from '@material-ui/icons/FilterList';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import withMediaQuery from 'components/Common/WithMediaQuery';
import { userManagementActions, userManagementSelectors } from 'redux/UserManagement';
import User, { UserSkeleton } from './User';
import ColumnTitle from './ColumnTitle';
import UserDeletionConfirmDialog from './UserDeletionConfirmDialog';
import UserAuditDialog from './UserAudit';
import MoreInfoDialog from './UserInfo';
import SearchBar from 'components/Common/SearchBar';
import { ALL_LOCATIONS, ALL_USERS, INTERNAL_USERS, PHARMACY_USERS } from 'constants/Common';
import { AbilityContext } from 'casl/Can';
import { restrictedResources } from 'casl/RestrictedResources';
import { userActions } from 'casl/UserActions';
import Config from 'config';
import Snowplow from 'snowplow/Snowplow';

const userSkeletonList = _.times(5, (i) => <UserSkeleton key={i} />);

const USER_DIALOG_TYPE = {
    ADD: 'add',
    EDIT: 'edit',
};

class UserManagement extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDialog: false,
            showAuditDialog: false,
            showMoreInfoDialog: false,
            moreInfoUser: {},
            auditUser: null,
            showUserDialog: false,
            userToDelete: null,
            userToEdit: null,
            userDialogType: USER_DIALOG_TYPE.ADD,
            selectedFilter: [],
            showFilterList: false,
            showLocationList: false,
            showUserTypeList: false,
            filterListEl: null,
            locationListEl: null,
            userTypeListEl: null,
        };
    }

    componentDidMount() {
        const {
            getUsers,
            getUserRoles,
            userManagement: { userRoles },
            auth: { isInternalUser },
        } = this.props;

        if (_.isEmpty(userRoles)) {
            getUserRoles();
        }
        getUsers({ startRow: 0, numRows: 10000, showInternal: isInternalUser });
    }

    componentDidUpdate(prevProps) {
        const {
            auth: { selectedCustomer, isInternalUser },
            getUserRoles,
            userManagement: { userRoles },
            pharmacy,
            getUsers,
        } = this.props;

        if (
            prevProps.auth.selectedCustomer.id !== selectedCustomer.id ||
            prevProps.pharmacy.lastUpdated !== pharmacy.lastUpdated
        ) {
            this.resetLocation();
            if (_.isEmpty(userRoles)) {
                getUserRoles();
            }
            getUsers({ startRow: 0, numRows: 10000, showInternal: isInternalUser });
        }
    }

    componentWillUnmount() {
        const { resetUserManagementStore } = this.props;
        resetUserManagementStore();
    }

    resetLocation = () => {
        const { setLocalLocation } = this.props;
        setLocalLocation({ selectedLocalLocation: ALL_LOCATIONS });
    };

    getFooterText = () => {
        const {
            users,
            userManagement: { page, pageLength },
        } = this.props;
        const numberOfChunks = users.length;
        const total = numberOfChunks === 0 ? 0 : (numberOfChunks - 1) * pageLength + users[numberOfChunks - 1].length;
        const from = total > 0 ? page * pageLength + 1 : 0;
        const to = _.min([from + pageLength - 1, total]);

        return `${from} - ${to} of ${total}`;
    };

    hideConfirmDialog = () => this.setState({ showConfirmDialog: false, userToDelete: null });

    viewAuditLogs = ({ user }) => {
        this.setState({ showAuditDialog: true, auditUser: user });
    };

    hideAuditLogs = () => {
        this.setState({ showAuditDialog: false, auditUser: null });
    };

    viewMoreInfo = ({ user }) => {
        this.setState({ showMoreInfoDialog: true, moreInfoUser: user });
    };

    hideMoreInfo = () => {
        this.setState({ showMoreInfoDialog: false, moreInfoUser: {} });
    };

    openDeleteAction = () => {
        const { moreInfoUser } = this.state;
        this.confirmUserDeletion({ user: moreInfoUser });
        this.hideMoreInfo();
    };

    openEditAction = () => {
        const { moreInfoUser } = this.state;
        this.editPharmacyUser({ user: moreInfoUser });
        this.hideMoreInfo();
    };

    sendWelcomeEmail = ({ user }) => {
        const { pharmacyName: pharmacy_name, sendInvite } = this.props;
        sendInvite({
            user_type: 'pharmacist',
            email: user.email,
            user_id: user.user_api_user_id,
            pharmacy_name,
        });
    };

    deletePharmacyUser = () => {
        const { deleteUser } = this.props;
        const { userToDelete } = this.state;

        if (userToDelete) {
            deleteUser({
                user_id: userToDelete.user_api_user_id,
            }).then((data) => {
                if (data && data.response && data.response.status === 409) {
                    window.Intercom(
                        'showNewMessage',
                        `Having trouble deleting ${userToDelete.email} from user management`
                    );
                    Snowplow.structEvent('User Management', 'Intercom delete user error', null);
                }
            });
            this.hideConfirmDialog();
        }
    };

    editPharmacyUser = ({ user }) => {
        this.setState({ showUserDialog: true, userToEdit: user, userDialogType: USER_DIALOG_TYPE.EDIT });
    };

    addPharmacyUser = () => {
        this.setState({ showUserDialog: true, userDialogType: USER_DIALOG_TYPE.ADD });
    };

    onSubmit = (userData) => {
        const {
            addNewUser,
            editUser,
            getUsers,
            auth: { isInternalUser },
        } = this.props;
        const { userDialogType } = this.state;

        if (userDialogType === USER_DIALOG_TYPE.ADD) {
            const payload = { ...userData, pharmacy_id: Config.X_PharmacyID };

            addNewUser(payload).then((data) => {
                if (data.success) {
                    this.closeUserDialog();
                    getUsers({ startRow: 0, numRows: 10000, showInternal: isInternalUser });
                }
                if (data && data.response && data.response.status === 409) {
                    window.Intercom('showNewMessage', `Having trouble adding ${userData.email} from user management`);
                    Snowplow.structEvent('User Management', 'Intercom add user error', null);
                }
            });
        } else {
            const data = {
                user_id: userData.user_id,
                payload: _.omit(userData, 'email'),
            };
            editUser(data).then((res) => {
                if (res.success) {
                    this.closeUserDialog();
                    getUsers({ startRow: 0, numRows: 10000, showInternal: isInternalUser });
                }
            });
        }
    };

    confirmUserDeletion = ({ user }) => {
        this.setState({ showConfirmDialog: true, userToDelete: user });
    };

    paginate = (event, selectedPage) => {
        const { paginate } = this.props;
        paginate({ page: selectedPage - 1 });
        window.scrollTo(0, 0);
    };

    buildUserMenuActions = () => {
        const {
            generalSettings: {
                userManagement: { subject: caslSubject, fields: restrictedFields },
            },
        } = restrictedResources;
        const { view } = userActions;

        const canViewUserLogs = this.context.can(view, caslSubject, restrictedFields.viewUserLogs);
        const canEditUser = this.context.can(view, caslSubject, restrictedFields.editUser);
        const canDeleteUser = this.context.can(view, caslSubject, restrictedFields.deleteUser);
        const canResendInvite = this.context.can(view, caslSubject, restrictedFields.resendInvite);
        const { isMobile } = this.props;
        const actions = [];

        if (isMobile) {
            actions.push({
                text: 'More Info',
                icon: 'info',
                onClick: this.viewMoreInfo,
            });
        }

        if (canViewUserLogs) {
            actions.push({
                text: 'View User Logs',
                icon: 'policy',
                onClick: this.viewAuditLogs,
            });
        }

        if (canEditUser) {
            actions.push({
                text: 'Edit',
                icon: 'edit',
                onClick: this.editPharmacyUser,
            });
        }

        if (canResendInvite) {
            // TODO: we might want to make this action trigger a confirmation modal similar to delete
            actions.push({
                text: 'Resend Invite',
                icon: 'send',
                onClick: this.sendWelcomeEmail,
            });
        }

        if (canDeleteUser) {
            actions.push({
                text: 'Delete',
                icon: 'delete',
                className: 'user-management-actions__delete-user',
                onClick: this.confirmUserDeletion,
            });
        }

        return actions;
    };

    getUserMenuOptions = (userId, actions) => {
        const {
            flags: { npeUserManagementActions },
            auth: {
                userAccount: { user_id: loginUserId },
            },
        } = this.props;

        if (!npeUserManagementActions) {
            return [];
        }

        if (userId === loginUserId) {
            // hide resend invite and delete button
            const actionsToExclude = ['delete', 'resend invite'];
            return _.filter(actions, (action) => _.indexOf(actionsToExclude, action.text.toLowerCase()) === -1);
        }

        return actions;
    };

    closeUserDialog = () => {
        this.setState({ showUserDialog: false, userToEdit: null, userDialogType: USER_DIALOG_TYPE.ADD });
    };

    showPopoverList = (event, showFlag, anchorEl) => {
        this.setState({ [showFlag]: true, [anchorEl]: event.target });
    };

    hidePopoverList = (showFlag, anchorEl) => {
        this.setState({ [showFlag]: false, [anchorEl]: null });
    };

    getCheckBoxListItem = (name, value, selectedValue, clickHandler) => {
        return (
            <ListItem key={value} role="listitem" button onClick={() => clickHandler(value)}>
                <ListItemIcon>
                    <Checkbox
                        checked={selectedValue === value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': name }}
                    />
                </ListItemIcon>
                <ListItemText id={value} primary={name} />
            </ListItem>
        );
    };

    getDropDownListItem = (name, value, selectedValue, clickHandler) => {
        return (
            <MenuItem key={value} onClick={() => clickHandler(value)} selected={selectedValue === value}>
                {name}
            </MenuItem>
        );
    };

    getIconListItem = (name, value, clickHandler) => {
        return (
            <ListItem key={value} role="listitem" button onClick={() => clickHandler(value)}>
                <ListItemIcon>
                    {value === 'location' && <LocationOnOutlinedIcon />}
                    {value === 'userType' && <PersonOutlineIcon />}
                </ListItemIcon>
                <ListItemText id={value} primary={name} />
            </ListItem>
        );
    };

    getFilterIcon = () => {
        const { showFilterList, filterListEl } = this.state;
        const {
            generalSettings: {
                userManagement: { subject: caslSubject, fields: restrictedFields },
            },
        } = restrictedResources;
        const { view } = userActions;

        const canViewUserTypeFilter = this.context.can(view, caslSubject, restrictedFields.internalUserToggle);
        return (
            <Fragment>
                <Tooltip title="Apply a filter">
                    <IconButton onClick={(event) => this.showPopoverList(event, 'showFilterList', 'filterListEl')}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                <FilterDropDown
                    showList={showFilterList}
                    anchorEl={filterListEl}
                    onHideHandler={() => this.hidePopoverList('showFilterList', 'filterListEl')}
                >
                    {this.getIconListItem('Locations', 'location', this.setFilter)}
                    {canViewUserTypeFilter && this.getIconListItem('User Type', 'userType', this.setFilter)}
                </FilterDropDown>
            </Fragment>
        );
    };

    getLocationChipLabel = () => {
        const { showLocationList, locationListEl } = this.state;
        const {
            userManagement: { selectedLocalLocation },
            filteredLocationMap,
        } = this.props;

        let labelText = 'All Locations';
        const locationDetail = _.get(filteredLocationMap, selectedLocalLocation, false);
        if (locationDetail) {
            labelText = locationDetail.displayName || locationDetail.name;
        }

        return (
            <Fragment>
                <Chip
                    label={labelText}
                    onClick={(event) => this.showPopoverList(event, 'showLocationList', 'locationListEl')}
                    icon={<LocationOnOutlinedIcon />}
                />
                <FilterDropDown
                    showList={showLocationList}
                    anchorEl={locationListEl}
                    onHideHandler={() => this.hidePopoverList('showLocationList', 'locationListEl')}
                >
                    {this.getDropDownListItem(
                        'All Locations',
                        ALL_LOCATIONS,
                        selectedLocalLocation,
                        this.setLocationValue
                    )}
                    {_.map(filteredLocationMap, (locationDetails, locationId) =>
                        this.getDropDownListItem(
                            locationDetails.displayName || locationDetails.name,
                            locationId,
                            selectedLocalLocation,
                            this.setLocationValue
                        )
                    )}
                </FilterDropDown>
            </Fragment>
        );
    };

    getUserTypeChipLabel = () => {
        const {
            userManagement: { selectedUserType },
        } = this.props;
        const { showUserTypeList, userTypeListEl } = this.state;
        const userTypeList = {
            [ALL_USERS]: 'All users',
            [INTERNAL_USERS]: 'Internal users',
            [PHARMACY_USERS]: 'Pharmacy users',
        };

        const {
            generalSettings: {
                userManagement: { subject: caslSubject, fields: restrictedFields },
            },
        } = restrictedResources;
        const { view } = userActions;

        const canViewUserTypeFilter = this.context.can(view, caslSubject, restrictedFields.internalUserToggle);

        return canViewUserTypeFilter ? (
            <Fragment>
                <Chip
                    label={userTypeList[selectedUserType]}
                    onClick={(event) => this.showPopoverList(event, 'showUserTypeList', 'userTypeListEl')}
                    icon={<PersonOutlineIcon />}
                />
                <FilterDropDown
                    showList={showUserTypeList}
                    anchorEl={userTypeListEl}
                    onHideHandler={() => this.hidePopoverList('showUserTypeList', 'userTypeListEl')}
                >
                    {this.getDropDownListItem(userTypeList[ALL_USERS], ALL_USERS, selectedUserType, this.setUserType)}
                    {this.getDropDownListItem(
                        userTypeList[INTERNAL_USERS],
                        INTERNAL_USERS,
                        selectedUserType,
                        this.setUserType
                    )}
                    {this.getDropDownListItem(
                        userTypeList[PHARMACY_USERS],
                        PHARMACY_USERS,
                        selectedUserType,
                        this.setUserType
                    )}
                </FilterDropDown>
            </Fragment>
        ) : null;
    };

    setFilter = (value) => {
        const { selectedFilter } = this.state;
        if (selectedFilter.indexOf(value) < 0) {
            selectedFilter.push(value);
        }
        this.setState({ selectedFilter }, () => this.hidePopoverList('showFilterList', 'filterListEl'));
    };

    setLocationValue = (value) => {
        const { setLocalLocation } = this.props;
        setLocalLocation({ selectedLocalLocation: value });
        this.hidePopoverList('showLocationList', 'locationListEl');
    };

    setUserType = (value) => {
        const { setUserType } = this.props;
        setUserType({ selectedUserType: value });
        this.hidePopoverList('showUserTypeList', 'userTypeListEl');
    };

    getAddButton = (btnText) => {
        const {
            flags: { npeUserManagementActions },
        } = this.props;

        if (!npeUserManagementActions) {
            return null;
        }

        return (
            <Button className="add-new-user-btn" variant="outlined" color="primary" onClick={this.addPharmacyUser}>
                {btnText}
            </Button>
        );
    };

    render() {
        const { selectedFilter, userDialogType, showUserDialog, userToEdit } = this.state;
        const {
            auth: { selectedCustomer, isInternalUser },
            userManagement: {
                page,
                isLoading,
                searchString,
                sortBy,
                selectedLocalLocation,
                userRoles,
                addOrEditUserLoading,
            },
            users,
            searchUser,
            sortUsers,
            isMobile,
            getUserAuditLogs,
            filteredLocationMap,
        } = this.props;

        const sortingProps = {
            sortBy,
            isSortable: true,
            onClick: sortUsers,
            className: 'sortable-column',
        };
        const usersOnPage = users[page] || [];
        const userMenuActions = this.buildUserMenuActions();

        return (
            <Fragment>
                <Sticky topOffset={-175} disableCompensation>
                    {({ style, isSticky }) => (
                        <div
                            style={{
                                ...style,
                                position: isSticky && !isMobile ? 'fixed' : 'inherit',
                                paddingTop: isSticky && !isMobile ? '24px' : 0,
                            }}
                            className="user-management-sticky-container"
                        >
                            <div className="flex-middle search-toggle-add-container">
                                {!isLoading && (
                                    <Fragment>
                                        <div className="flex-middle search-bar">
                                            <SearchBar
                                                searchSuccess // we do not want to display helper text when search is not found
                                                className={`user-management-header__search-bar ${
                                                    isInternalUser ? 'is-internal' : ''
                                                }`}
                                                placeholder="Search"
                                                defaultValue={searchString}
                                                onSearch={(searchString) => searchUser({ searchString })}
                                            />

                                            {isMobile && this.getAddButton('Add')}
                                        </div>

                                        <div className="chips-container" style={{ marginLeft: isMobile ? 0 : 15 }}>
                                            {this.getLocationChipLabel()}
                                            {this.getUserTypeChipLabel()}
                                        </div>
                                        {!isMobile && this.getAddButton('+ Add New User')}
                                    </Fragment>
                                )}
                            </div>

                            <div className="user-management-list__row user-management-list__header">
                                <ColumnTitle title="Name" {...sortingProps} />
                                <ColumnTitle title="Email" {...sortingProps} />
                                {!isMobile && (
                                    <Fragment>
                                        {selectedLocalLocation === 'allLocations' && (
                                            <ColumnTitle title="Store Access" isSortable={false} />
                                        )}
                                        <ColumnTitle title="Last Active" {...sortingProps} />
                                    </Fragment>
                                )}
                                {/* empty element to act as invisible last column */}
                                <span />
                            </div>
                        </div>
                    )}
                </Sticky>

                <div className="user-management-list">
                    {isLoading
                        ? userSkeletonList
                        : usersOnPage.map((user) => (
                              <User
                                  key={user.user_api_user_id}
                                  name={user.name}
                                  email={user.email}
                                  roles={user.roles}
                                  pharmacyId={selectedCustomer.id}
                                  lastActive={user.lastActive}
                                  actions={this.getUserMenuOptions(user.user_api_user_id, userMenuActions)}
                                  // Ideally we should be able to call action.onClick() from User.jsx but that would
                                  // involve including more(unnecessary) props and then sending those back in
                                  // to avoid that I am adding in an extra prop
                                  onAction={(index) => userMenuActions[index].onClick({ user })}
                                  selectedLocalLocation={selectedLocalLocation}
                                  isInternalUser={Boolean(user.isInternal)}
                              />
                          ))}
                </div>

                <div className="user-management-footer">
                    <div className="user-management-footer__user-count user-management-footer__bookend">
                        {this.getFooterText()}
                    </div>

                    <Pagination
                        className="user-management-footer__pagination"
                        count={users.length}
                        page={page + 1} // Convert to 1-based index
                        variant="text"
                        shape="round"
                        disabled={isLoading}
                        onChange={this.paginate} // Convert to 0-based index
                    />

                    <div className="user-management-footer__bookend" />
                </div>

                <UserAuditDialog
                    open={this.state.showAuditDialog}
                    onClose={this.hideAuditLogs}
                    user={this.state.auditUser}
                    getAuditLogs={getUserAuditLogs}
                />

                <UserDeletionConfirmDialog
                    open={this.state.showConfirmDialog}
                    email={_.get(this, 'state.userToDelete.email')}
                    onConfirm={this.deletePharmacyUser}
                    onCancel={this.hideConfirmDialog}
                />

                <UserDialog
                    showUserDialog={showUserDialog}
                    closeDialog={this.closeUserDialog}
                    filteredLocationMap={filteredLocationMap}
                    type={userDialogType}
                    userToEdit={userToEdit}
                    userRoles={userRoles}
                    onSubmit={this.onSubmit}
                    addOrEditUserLoading={addOrEditUserLoading}
                />

                <MoreInfoDialog
                    open={this.state.showMoreInfoDialog}
                    user={this.state.moreInfoUser}
                    onDelete={this.openDeleteAction}
                    onEdit={this.openEditAction}
                    onCancel={this.hideMoreInfo}
                    pharmacyId={selectedCustomer.id}
                    selectedLocalLocation={selectedLocalLocation}
                />
            </Fragment>
        );
    }
}

UserManagement.contextType = AbilityContext;

const mapStateToProps = (state) => ({
    auth: state.auth,
    flags: state.launchDarkly,
    pharmacyName: _.get(state, 'pharmacy.pharmacy.name', ''),
    userManagement: state.userManagement,
    users: userManagementSelectors.getSortedUsers(state),
    filteredLocationMap: userManagementSelectors.getFilteredLocationMap(state),
    pharmacy: state.pharmacy,
});

const mapDispatchToProps = {
    resetUserManagementStore: userManagementActions.resetUserManagementStore,
    getUsers: userManagementActions.getUsers,
    sendInvite: userManagementActions.sendInvite,
    setLocalLocation: userManagementActions.setLocalLocation,
    setUserType: userManagementActions.setUserType,
    searchUser: userManagementActions.searchUser,
    // Note: According to the discussion in leads meeting, we'd be paginating, searching &
    //       sorting in the frontend. Currently, there are technical challenges to carry out
    //       these actions in the backend because the user data is divided among c2 and user-api.
    paginate: userManagementActions.paginate,
    sortUsers: userManagementActions.sortUsers,
    deleteUser: userManagementActions.deleteUser,
    getUserAuditLogs: userManagementActions.getUserAuditLogs,
    getUserRoles: userManagementActions.getUserRoles,
    addNewUser: userManagementActions.addNewUser,
    editUser: userManagementActions.editUser,
};

const FilterDropDown = ({ children, showList, anchorEl, onHideHandler }) => {
    return (
        <Popover
            open={showList}
            anchorEl={anchorEl}
            onClose={onHideHandler}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <List dense component="div" role="list" className="user-filter-list">
                {children}
            </List>
        </Popover>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(UserManagement));
