import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

export default function AppointmentLengthSelect({
    value,
    onChange,
    disabled,
    minWidth = 300,
    showLabel = true,
    disablePortal = true,
}) {
    const labelRef = useRef();
    const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

    // hack to make the material-ui InputLabel render correctly
    const [, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <FormControl variant="outlined" style={{ minWidth: `${minWidth}px` }}>
            {!showLabel ? null : <InputLabel ref={labelRef}>Appointment Length...</InputLabel>}
            <Select
                disabled={disabled}
                className="appointment-length-select"
                data-cy="appt-length-select"
                value={_.isNil(value) ? '' : value}
                onChange={onChange}
                labelWidth={labelWidth}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    disablePortal,
                }}
            >
                <MenuItem value={5}>5 minutes</MenuItem>
                <MenuItem value={10}>10 minutes</MenuItem>
                <MenuItem value={15}>15 minutes</MenuItem>
                <MenuItem value={20}>20 minutes</MenuItem>
                <MenuItem value={30}>30 minutes</MenuItem>
                <MenuItem value={45}>45 minutes</MenuItem>
                <MenuItem value={60}>60 minutes</MenuItem>
                <MenuItem value={75}>90 minutes</MenuItem>
                <MenuItem value={120}>120 minutes</MenuItem>
            </Select>
        </FormControl>
    );
}
