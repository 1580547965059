import React from 'react';

export const NoApps = () => (
    <div className="table--row table--body">
        <span />
        <span />
        <span>No apps configured</span>
        <span />
        <span />
        <span />
    </div>
);
