import '@babel/polyfill';
import 'proxy-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import WebFontLoader from 'webfontloader';
import thunk from 'redux-thunk';
import axios from 'axios';
import 'react-placeholder/lib/reactPlaceholder.css';

import reducers from 'redux/reducers';
import App from 'components/App';
import inboxMiddleware from 'redux/Inbox/middleware';
import pharmacyMiddleware from 'redux/Pharmacy/middleware';
import socketMiddleware from 'redux/Socket/middleware';
import socketMessagesMiddleware from 'redux/middlewares/SocketMessages';
import { userManagementMiddleware } from 'redux/UserManagement';
import NetworkService from 'utils/networkService';
import Actions from 'redux/actions';
import * as Sentry from '@sentry/browser';
import './styles/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AppErrorBoundary } from 'components/AppErrorBoundary';
import Config from 'config';
import patientMiddleware from 'redux/Patient/patientMiddleware';
import appointmentMiddleware from 'redux/Appt/appointmentMiddleware';

import { homeMiddleware } from 'redux/Home';
import { newsletterMiddleware } from 'redux/Newsletter';

WebFontLoader.load({
    google: {
        families: ['Roboto:300,400,500,700', 'Material Icons'],
    },
});

const logger = (store) => (next) => (action) => {
    if (process.env.NODE_ENV === 'development') {
        console.group(action.type);
        console.log('dispatching', action);
        const result = next(action);
        console.log('next state', store.getState());
        console.groupEnd();
        return result;
    }
    return next(action);
};

const composeEnhancers = composeWithDevTools({
    // Specify custom devTools options
});

const appReducer = combineReducers(reducers);

let initialLogoutAction = true;
const rootReducer = (state, action) => {
    if (action.type === Actions.LOGIN_SUCCESS) {
        const lastPharmacyVisited = sessionStorage.getItem('lastPharmacyVisited');
        const lastLocationVisited = sessionStorage.getItem('lastLocationVisited');
        if (lastPharmacyVisited && lastLocationVisited) {
            sessionStorage.removeItem('lastPharmacyVisited');
            sessionStorage.removeItem('lastLocationVisited');
        }
    }

    if (action.type === Actions.FETCH_USER_ACCOUNT_SUCCESS) {
        // On page reload w/ active session, we need to mark initial logout action as false
        // Otherwise user will have to click logout twice to actually logout of ui
        initialLogoutAction = false;
    }

    if (action.type === Actions.LOGOUT) {
        // Resets all stores to initial state
        // state = undefined; // we dont need this as on first vist to webpage, and if the user session is invalid then
        // refetch pharmacy details into store, and on subsequent logouts the page is hard reloaded which will anyway reset store

        // Reset sessionStorage for last visited page
        sessionStorage.removeItem('lastPageVisited');

        // on initial page load with an invalid session
        // backend inactivity logout event will fire
        // by preventing reload on this initial logout action
        // we are preventing an infinite reload cycle
        if (!initialLogoutAction) {
            if (Config.X_PharmacyID && Config.X_LocationID) {
                sessionStorage.setItem('lastPharmacyVisited', Config.X_PharmacyID);
                sessionStorage.setItem('lastLocationVisited', Config.X_LocationID);
            }
            window.location.reload(true);
        }
        // Clear local data related to session
        Config.X_PharmacyID = ''; // TODO need to move to using pharmacy store to manage active pharmacy_id
        Config.X_LocationID = ''; // TODO need to move to using pharmacy store to manage active location_id
        delete window.USER_EMAIL;
        initialLogoutAction = false;
    }

    return appReducer(state, action);
};

const store = createStore(
    rootReducer, // app
    composeEnhancers(
        applyMiddleware(
            logger,
            thunk,
            inboxMiddleware,
            pharmacyMiddleware,
            socketMessagesMiddleware,
            socketMiddleware,
            userManagementMiddleware,
            patientMiddleware,
            homeMiddleware,
            newsletterMiddleware,
            appointmentMiddleware
        )
    )
);

NetworkService.setupInterceptors(store);

axios.defaults.withCredentials = true;

if (Config.environ !== 'local') {
    // Don't send error logs to Sentry for local dev, also Sentry hijacks console.log which is detrimental for local development
    Sentry.init({ dsn: Config.sentry_dsn });
}
if (window.Cypress) {
    window.store = store;
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <SnackbarProvider maxSnack={3}>
                <AppErrorBoundary>
                    <App />
                </AppErrorBoundary>
            </SnackbarProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);
