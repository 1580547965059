import './EmptyPatientsResultsPane.sass';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

export default function EmptyPatientResultsPane(props) {
    return (
        <div className="empty-patients-results-pane">
            <div className="graphic">
                <div className="graphic-inner">
                    <SearchIcon className="large-icon" />
                    <CloseIcon className="small-icon" />
                </div>
            </div>
            <div className="text">
                <div className="main-line">No patients found matching this search!</div>
                <div className="explanation-line">
                    Please ensure that you have spelled the patients name correctly and you have removed any other
                    filters that may have excluded them.
                </div>
            </div>
        </div>
    );
}
