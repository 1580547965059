import React, { useState, useCallback, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { TextField, CircularProgress } from '@material-ui/core';

const LeadNotesAccordion = ({ children }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="lead-profile">
                <Typography>Notes</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export const LeadNotes = ({ isLeadNoteSaving, note, draftNote, onSave, setDraftNotes, canUpdate }) => {
    const [isNoteEditing, setIsNoteEditing] = useState(false);
    const [noteText, setNoteText] = useState(draftNote ? draftNote : note ? note : '');
    const handleChange = useCallback(
        _.debounce((event) => {
            setDraftNotes(event.target.value);
        }, 300),
        []
    );

    const onNotesChange = (event) => {
        event.persist();
        const newNote = event.target.value;
        if (newNote.length <= 1000) {
            setNoteText(newNote);
            handleChange(event);
        }
    };

    const saveNote = () => {
        setIsNoteEditing(false);
        onSave();
    };

    const resetNote = () => {
        if (note) {
            setNoteText(note);
        } else {
            setNoteText('');
        }
        setDraftNotes('');
        setIsNoteEditing(false);
    };

    const getNotes = () => {
        return note ? note : <span className="info-note">'There is no note added yet. Click on Edit to add note'</span>;
    };

    return (
        // Do not add direction  column as it is causing weird issue on mac os safari
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {isNoteEditing ? (
                    <Fragment>
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            rowsMax={4}
                            value={noteText}
                            onChange={onNotesChange}
                        />
                        <span className="info-note">{`${noteText.length}/1000`}</span>
                    </Fragment>
                ) : (
                    getNotes()
                )}
            </Grid>
            <Grid container item xs={12} justify="flex-end">
                {isLeadNoteSaving && <CircularProgress />}
                {!isNoteEditing && !isLeadNoteSaving && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsNoteEditing(true)}
                        disabled={!canUpdate}
                    >
                        Edit
                    </Button>
                )}
                {isNoteEditing && !isLeadNoteSaving && (
                    <Fragment>
                        <Button color="primary" onClick={resetNote}>
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={saveNote}
                            style={{ marginLeft: '16px' }}
                            disabled={!noteText}
                        >
                            Save
                        </Button>
                    </Fragment>
                )}
            </Grid>
        </Grid>
    );
};

export default LeadNotesAccordion;
