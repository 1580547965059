import { Component } from 'react';
import debounce from 'lodash/debounce';
import { logout } from 'redux/actionCreators/Auth';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { connect } from 'react-redux';
import { LOG_OUT_ON_UI_INACTIVITY } from 'constants/Logout';

const MINUTES_UNTIL_AUTO_LOGOUT = 25; // in mins
const AUTO_LOGOUT = 30; // in mins

class AutoLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningTime: 1000 * 60 * MINUTES_UNTIL_AUTO_LOGOUT,
            signoutTime: 1000 * 60 * AUTO_LOGOUT,
            lastAction: Date.now(),
        };
    }

    getLastAction = () => {
        const { lastAction } = this.state;
        return lastAction;
    };

    setLastAction = (lastAction) => {
        this.setState({
            lastAction,
        });
    };

    componentDidMount() {
        const { extendedSession } = this.props;
        if (!extendedSession) {
            this.initialize();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { extendedSession } = this.props;
        if (!extendedSession && prevProps.extendedSession) {
            this.initialize();
        } else if (extendedSession && !prevProps.extendedSession) {
            this.destroy();
        }
    }

    initialize() {
        this.events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();
    }

    clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = debounce(() => {
        this.setLastAction(Date.now());
        this.clearTimeoutFunc();
        this.setTimeout();
    }, 500);

    check = (time) => {
        const now = Date.now();
        const timeleft = this.getLastAction() + time * 60 * 1000;
        const diff = timeleft - now;
        return diff <= 0;
    };

    warn = () => {
        if (this.check(MINUTES_UNTIL_AUTO_LOGOUT)) {
            const leftTime = AUTO_LOGOUT - MINUTES_UNTIL_AUTO_LOGOUT;
            const { displayToast } = this.props;
            displayToast({
                text: `Due to inactivity your session will expire in ${leftTime} min`,
                type: 'warning',
            });
        }
    };

    logout = () => {
        if (this.check(AUTO_LOGOUT)) {
            this.props.logout();
        }
    };

    destroy = () => {
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
        this.clearTimeoutFunc();
        // Cancel any ongoing debounced calls
        this.resetTimeout.cancel();
    };

    componentWillUnmount() {
        this.destroy();
    }

    render() {
        return '';
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        extendedSession: auth.extendedSession,
    };
};

const bindActionsToDispatch = (dispatch) => ({
    logout: () => dispatch(logout(LOG_OUT_ON_UI_INACTIVITY)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(AutoLogout);
