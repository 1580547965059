import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgressWithSteps from 'components/Onboarding/LinearProgressWithSteps';
import { onboardingSelectors } from 'redux/Inbox/Onboarding/selector';
import { onboardingActions } from 'redux/Inbox/Onboarding';

ActivityOnboardingHeader.propTypes = {
    onboardingSteps: PropTypes.shape({
        completedCount: PropTypes.number.isRequired,
        totalCount: PropTypes.number.isRequired,
        nextStepDisplay: PropTypes.string,
    }).isRequired,
    onboardingIsPending: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
    trackInboxLocationOnboardingAction: PropTypes.func.isRequired,
};

ActivityOnboardingHeader.defaultProps = {};

const useStyles = makeStyles({
    linkOverride: {
        padding: '16px 16px 24px 16px',
        borderBottom: '1px solid #DEE5EB',
        marginTop: '-8px',
        marginBottom: '8px',
        textDecoration: 'none !important',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, 0.04)',
        },
    },
    linkActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    headerText: {
        fontWeight: 500,
        fontSize: '1.15rem',
        letterSpacing: '-0.27px',
    },
});

export function ActivityOnboardingHeader(props) {
    const {
        onboardingSteps: { completedCount, totalCount, nextStepDisplay },
        pathname,
        onboardingIsPending,
        trackInboxLocationOnboardingAction,
    } = props;
    const classes = useStyles();
    const linkPath = '/inbox/onboarding';

    if (!onboardingIsPending) {
        return null;
    }

    function onClick() {
        trackInboxLocationOnboardingAction({ action: 'header-activity-list', value: 'clicked' });
    }

    return (
        <Link
            data-testid="activity-onboarding-header-link"
            className={`${classes.linkOverride} ${pathname === linkPath ? classes.linkActive : ''}`}
            to={linkPath}
            onClick={onClick}
        >
            <Typography className={classes.headerText}>Get Started</Typography>
            <LinearProgressWithSteps
                completedCount={completedCount}
                totalCount={totalCount}
                label={nextStepDisplay && `Up next : ${nextStepDisplay}`}
                labelColor="#808080"
                size="sm"
            />
        </Link>
    );
}

function mapStateToProps(state) {
    return {
        onboardingSteps: onboardingSelectors.locationOnboardingStepsSelector(state),
        onboardingIsPending: onboardingSelectors.locationOnboardingIsPendingSelector(state),
    };
}

const bindActionsToDispatch = {
    trackInboxLocationOnboardingAction: onboardingActions.trackInboxLocationOnboardingAction,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ActivityOnboardingHeader);
