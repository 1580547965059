import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

const getLeadsWithStatus = (state) => state.home.checklist.leads.withStatus;
const getVoicemails = (state) => state.home.checklist.voicemails.unread;
const getMessages = (state) => state.home.checklist.messages.unread;

const getNewLeadCount = createSelector([getLeadsWithStatus], (leadsWithStatus) => {
    const leads = _.get(leadsWithStatus, 'data.leads', []);
    const leadsWithNewStatus = _.filter(
        leads,
        (l) => l.status === 'new_leads' && moment().isSame(moment(l.created_date), 'day')
    );

    return leadsWithNewStatus.length;
});

const getUnreadVoicemail = createSelector([getVoicemails], (voicemails) => {
    const allVoicemails = _.get(voicemails, 'data.result', []);
    const localDate = (utcDate) => moment.utc(utcDate).local();
    const todaysVoicemail = _.filter(allVoicemails, (v) => moment().isSame(localDate(v.callDateUTC), 'day'));

    return todaysVoicemail.length;
});

const getUnreadMessagesCount = createSelector([getMessages], (messages) => {
    const allUnreadMessages = _.get(messages, 'users_activity', []);
    // updated to list all unread messages and not just for today as per the suggestion of Chris P
    return allUnreadMessages.length;
});

const getInboxActivity = createSelector([getMessages], (messages) => {
    const allUnreadMessages = _.get(messages, 'users_activity', []);
    return allUnreadMessages;
});

export const homeSelectors = {
    getNewLeadCount,
    getUnreadVoicemail,
    getUnreadMessagesCount,
    getInboxActivity,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
