import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { CircularProgress } from 'react-md';
import moment from 'moment';
import _ from 'lodash';

import { fetchNewReports, cancelReportsRequests } from 'redux/actionCreators/Reports';
import { addUserPreferences, updateUserPreferences } from 'redux/actionCreators/Auth';
import NewReports from './NewReports';
import { pharmacyAction } from '../../redux/Pharmacy/action';

const DATE_FORMAT = 'YYYY-MM-DD';
const ALL_TIME_DATE = '2010-01-01';

class ReportOverview extends React.Component {
    componentDidUpdate = (prevProps) => {
        const isLocalLocationChanged = prevProps.selectedLocalLocation !== this.props.selectedLocalLocation;
        const isPharmacyChanged = prevProps.selectedPharmacyName !== this.props.selectedPharmacyName;

        if (isPharmacyChanged || isLocalLocationChanged) {
            // refresh page if location is changes at report page container level
            cancelReportsRequests();
            this.fetchNewReportsApi(isPharmacyChanged);
        }
    };

    componentWillUnmount = () => {
        cancelReportsRequests();
    };

    getLast7MonthsPayload = () => {
        const startMonthDate = moment().startOf('month').format(DATE_FORMAT);
        const endMonthDate = moment().endOf('month').format(DATE_FORMAT);
        const last7MonthsPayload = [];
        const monthLabels = [];

        for (let i = 6; i > 0; i--) {
            const startDate = moment().startOf('month').subtract(i, 'months');
            const startDateFormated = startDate.format(DATE_FORMAT);
            const endDateFormatted = startDate.endOf('month').format(DATE_FORMAT);
            last7MonthsPayload.push({
                begin: startDateFormated,
                end: endDateFormatted,
            });
            monthLabels.push(startDate.format('MMM'));
        }

        last7MonthsPayload.push({
            begin: startMonthDate,
            end: endMonthDate,
        });
        monthLabels.push(moment().startOf('month').format('MMM'));

        return {
            dates: last7MonthsPayload,
            labels: monthLabels,
        };
    };

    fetchNewReportsApi = (isPharmacyChanged) => {
        const {
            selectedLocalLocation,
            allLocations,
            pharmacy: {
                digitalMarketingReportsEnabled,
                digitalPlatformReportsEnabledForMobile,
                digitalPlatformReportsEnabledForWeb,
                communicationPlatformReportsEnabled,
                ivrEnabled,
                mobileDownloadsReportsEnabled,
                websiteTrafficReportsEnabled,
                newsletterReportsEnabled,
                reputationManagementReportsEnabled,
                directMessagingReportsEnabled,
            },
        } = this.props;

        const { selectedPharmacy, fetchNewReports } = this.props;

        const newPayLoad = {
            pharmacy_id: selectedPharmacy,
        };

        if (!allLocations) {
            newPayLoad['location_id'] = selectedLocalLocation;
        }

        const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');

        const last7Days = {
            begin: moment().subtract(6, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        };

        const last30Days = {
            begin: moment().subtract(29, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        };

        const last90Days = {
            begin: moment().subtract(90, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        };

        const allTime = {
            begin: ALL_TIME_DATE,
            end: moment().format('YYYY-MM-DD'),
        };

        if (isPharmacyChanged) {
            mobileDownloadsReportsEnabled &&
                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    report_type: 'mobile_downloads',
                    titles: ['Last 7 Days', 'Last 30 Days', 'All Time'],
                    date_ranges: [{ ...last7Days }, { ...last30Days }, { ...allTime }],
                });

            websiteTrafficReportsEnabled &&
                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    report_type: 'website_traffic',
                    date_ranges: [{ ...last7Days }, { ...last30Days }, { ...allTime }],
                });

            newsletterReportsEnabled &&
                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    report_type: 'newsletter',
                });

            // Digital Platform Patients
            if (digitalPlatformReportsEnabledForMobile || digitalPlatformReportsEnabledForWeb) {
                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    start_date: yesterday,
                    end_date: today,
                    filter_type: 'yesterday',
                    report_type: 'digital_platform_patients',
                });

                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    start_date: last90Days.begin,
                    end_date: last90Days.end,
                    filter_type: 'last90Days',
                    report_type: 'digital_platform_patients',
                });

                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    start_date: allTime.begin,
                    end_date: allTime.end,
                    filter_type: 'allTime',
                    report_type: 'digital_platform_patients',
                });

                fetchNewReports({
                    selectedLocalLocation,
                    end_date: last30Days.end,
                    start_date: last30Days.begin,
                    filter_type: 'last30Days',
                    pharmacy_id: selectedPharmacy,
                    report_type: 'digital_platform_patients',
                });
            }

            // Reputation management
            reputationManagementReportsEnabled &&
                fetchNewReports({
                    pharmacy_id: selectedPharmacy,
                    selectedLocalLocation,
                    report_type: 'reputation_management',
                    date_ranges: [{ ...last7Days }, { ...last30Days }, { ...allTime }],
                });
        }

        const last7Months = this.getLast7MonthsPayload();

        if (digitalPlatformReportsEnabledForMobile || digitalPlatformReportsEnabledForWeb) {
            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                labels: last7Months.labels,
                date_ranges: [...last7Months.dates, last7Days, last30Days],
                report_type: 'digital_platform',
            });
        }

        if (directMessagingReportsEnabled) {
            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: last7Days.begin,
                end_date: last7Days.end,
                filter_type: 'last7Days',
                report_type: 'direct_messaging',
            });

            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: last30Days.begin,
                end_date: last30Days.end,
                filter_type: 'last30Days',
                report_type: 'direct_messaging',
            });

            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: allTime.begin,
                end_date: allTime.end,
                filter_type: 'allTime',
                report_type: 'direct_messaging',
            });
        }

        // Digital Marketing

        if (digitalMarketingReportsEnabled) {
            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: last7Days.begin,
                end_date: last7Days.end,
                filter_type: 'last7Days',
                report_type: 'digital_marketing',
            });

            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: last30Days.begin,
                end_date: last30Days.end,
                filter_type: 'last30Days',
                report_type: 'digital_marketing',
            });

            fetchNewReports({
                ...newPayLoad,
                selectedLocalLocation,
                start_date: allTime.begin,
                end_date: allTime.end,
                filter_type: 'allTime',
                report_type: 'digital_marketing',
            });
        }

        if (communicationPlatformReportsEnabled && ivrEnabled) {
            // reports for ivr last 6 months
            // Note: ivr report requires key to be 'uuid' instead of 'id'
            const ivrPayLoad = {
                pharmacy_uuid: newPayLoad['pharmacy_id'],
            };

            if (newPayLoad['location_id']) {
                ivrPayLoad['location_uuid'] = newPayLoad['location_id'];
            }

            fetchNewReports({
                ...ivrPayLoad,
                selectedLocalLocation,
                start_date: moment().subtract(6, 'months').startOf('month').format(DATE_FORMAT),
                end_date: moment().format('YYYY-MM-DD'),
                report_type: 'ivr',
            });
        }

        fetchNewReports({
            selectedLocalLocation,
            end_date: last30Days.end,
            start_date: last30Days.begin,
            filter_type: 'last30Days',
            pharmacy_id: selectedPharmacy,
            report_type: 'outbound_alert_count',
        });
    };

    updateWidgetSequence = (widgetSequence) => {
        const { userPreferences, addUserPreferences, updateUserPreferences } = this.props;

        if (!userPreferences.id) {
            // add the user preference
            addUserPreferences({
                reportsWidgetSequence: widgetSequence,
            });
        } else {
            // update the user preference
            updateUserPreferences({
                id: userPreferences.id,
                reportsWidgetSequence: widgetSequence,
            });
        }
    };

    getRefillCountForLast30Days = () => {
        const { digitalPlatformReports, communicationsPlatformReports } = this.props;
        const last30DaysRefills = _.get(digitalPlatformReports, 'last30Days', {});
        return (
            _.get(last30DaysRefills, 'mobile', 0) +
            _.get(last30DaysRefills, 'web', 0) +
            _.get(communicationsPlatformReports, 'last_30_days.refills', 0)
        );
    };

    render() {
        const {
            digitalPlatformReports,
            communicationsPlatformReports,
            mobileDownloadsReports,
            newsletterReports,
            digitalPlatformPatientsReports,
            digitalMarketingReports,
            reputationManagementReports,
            directMessagingReports,
            websiteTrafficReports,
            outboundAlertsCount,
            userPreferences: { reports_widget_sequence },
            pharmacy: {
                digitalMarketingReportsEnabled,
                digitalPlatformReportsEnabledForMobile,
                digitalPlatformReportsEnabledForWeb,
                communicationPlatformReportsEnabled,
                ivrEnabled,
                mobileDownloadsReportsEnabled,
                websiteTrafficReportsEnabled,
                newsletterReportsEnabled,
                reputationManagementReportsEnabled,
                directMessagingReportsEnabled,
                loading,
            },
        } = _cloneDeep(this.props);

        const pharmacyDataForValueCalculator = {
            isPharmacyDataFetched:
                !digitalPlatformReports.isLoading &&
                !outboundAlertsCount.isLoading &&
                !communicationsPlatformReports.isLoading &&
                !digitalPlatformPatientsReports.isLoading,
            refillCount: this.getRefillCountForLast30Days(),
            outboundAlertCount: _.get(outboundAlertsCount, 'last30Days.outbound_count', 0),
            patientTransferCount: _.get(digitalPlatformPatientsReports, 'last30Days.transfers', 0),
        };

        return (
            <Fragment>
                {loading ? (
                    <CircularProgress id="reports-loading" />
                ) : (
                    <NewReports
                        digitalPlatformReports={digitalPlatformReports}
                        communicationsPlatformReports={communicationsPlatformReports}
                        mobileDownloadsReports={mobileDownloadsReports}
                        websiteTrafficReports={websiteTrafficReports}
                        newsletterReports={newsletterReports}
                        directMessagingReports={directMessagingReports}
                        digitalMarketingReports={digitalMarketingReports}
                        digitalPlatformPatientsReports={digitalPlatformPatientsReports}
                        reputationManagementReports={reputationManagementReports}
                        digitalMarketingReportsEnabled={digitalMarketingReportsEnabled}
                        digitalPlatformReportsEnabledForMobile={digitalPlatformReportsEnabledForMobile}
                        digitalPlatformReportsEnabledForWeb={digitalPlatformReportsEnabledForWeb}
                        communicationPlatformReportsEnabled={communicationPlatformReportsEnabled}
                        ivrEnabled={ivrEnabled}
                        mobileDownloadsReportsEnabled={mobileDownloadsReportsEnabled}
                        websiteTrafficReportsEnabled={websiteTrafficReportsEnabled}
                        newsletterReportsEnabled={newsletterReportsEnabled}
                        reputationManagementReportsEnabled={reputationManagementReportsEnabled}
                        directMessagingReportsEnabled={directMessagingReportsEnabled}
                        widgetSequence={reports_widget_sequence}
                        updateWidgetSequence={this.updateWidgetSequence}
                        pharmacyDataForValueCalculator={pharmacyDataForValueCalculator}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.reports,
        pharmacy: state.pharmacy,
        selectedPharmacy: state.auth.selectedCustomer.id,
        userPreferences: state.auth.userPreferences,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchNewReports: (data) => dispatch(fetchNewReports(data)),
    getPharmacy: (data) => dispatch(pharmacyAction.getPharmacy(data)),
    addUserPreferences: (data) => dispatch(addUserPreferences(data, false)),
    updateUserPreferences: (data) => dispatch(updateUserPreferences(data, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportOverview);
