import { useEffect } from 'react';

const useScript = (src) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = src;
        script.async = true;

        document.body.appendChild(script);

        // Specify how to clean up after this effect:
        return () => document.body.removeChild(script);
    }, [src]);
};

export default useScript;
