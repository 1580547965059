import Actions from 'redux/actions';

export default function CustomLoader(state = { visible: false }, { type }) {
    switch (type) {
        case Actions.SHOW_LOADER:
            return { visible: true };
        case Actions.DISMISS_LOADER:
            return { visible: false };
        default:
            return state;
    }
}
