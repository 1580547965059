import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import NoInboxPharmacyActivityList from './NoInboxPharmacyActivityList';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';

class NoInboxPharmacyView extends React.Component {
    _render() {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <NoInboxPharmacyActivityList />
                <div style={{ margin: 'auto', padding: '20px' }}>
                    <FullPepUpgrade />
                </div>
            </div>
        );
    }
    render() {
        return (
            <Fragment>
                <Hidden smDown>
                    <NoInboxPharmacyActivityList />
                </Hidden>
                <Grid item xs={12} md={9} className="inbox-main-grid inbox-full-height-scroll-wrapper">
                    <div className="contact-header-bar contact-header-bar-no-inbox"></div>
                    <Grid container style={{ padding: '0 12px' }}>
                        <Grid item xs={12} md={12}>
                            <FullPepUpgrade />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default NoInboxPharmacyView;
