import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ListItem from './ListItem';
import { homeActions, homeSelectors } from 'redux/Home';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

const listMap = {
    patients: {
        action: 'Wish {n} patient(s) a happy birthday!',
        subActions: [
            <span>
                You can see this and more info on the <Link to="/patients">Patients</Link> page
            </span>,
        ],
        navigationLink: '/patients',
        emptyMessage: 'No patients have a birthday today.',
    },
    voicemail: {
        action: 'Listen to {n} voicemail message(s)',
        subActions: [
            <span>
                You can see this and more info on the <Link to="/voicemail">Voicemail</Link> page
            </span>,
        ],
        navigationLink: '/voicemail',
        emptyMessage: "Woohoo! You've listened to all of your voice messages for today.",
    },
    inbox: {
        action: 'Respond to {n} Inbox message(s)',
        subActions: [
            <span>
                You can see this and more info on the <Link to="/inbox">Inbox</Link> page
            </span>,
        ],
        navigationLink: '/inbox',
        emptyMessage: "Woohoo! You've read all of your Inbox messages!",
    },
    lead: {
        action: 'View {n} new lead(s)',
        subActions: [
            <span>
                You can see this and more info on the <Link to="/leads">Leads</Link> page
            </span>,
        ],
        navigationLink: '/leads',
        emptyMessage: "Woohoo! You've viewed all of your new leads for today.",
    },
};

class ChecklistWidgetContainer extends React.Component {
    componentDidMount = () => {
        this.fetchWidgetsData();
    };

    componentDidUpdate = ({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) => {
        const { selectedLocation, pharmacy, resetHomeStore } = this.props;

        const isPharmacyUpdated = oldPharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isLocationChanged = selectedLocation !== oldSelectedLocation;

        if ((isLocationChanged || isPharmacyUpdated) && !pharmacy.loading) {
            resetHomeStore();
            this.fetchWidgetsData();
        }
    };

    fetchWidgetsData = async () => {
        const {
            selectedPharmacy,
            selectedLocation,
            getPatientsWithBirthday,
            getUnreadVoicemail,
            getUnreadMessagesCount,
            pharmacy: { callTrackingEnabled: isInternalLeadsEnabled },
            callRailId,
            getInternalLeads,
            getCallrailLeads,
        } = this.props;

        getPatientsWithBirthday();
        getUnreadVoicemail();
        getUnreadMessagesCount();

        const today = moment().format('YYYY-MM-DD');
        const isCallrailEnabled = Boolean(callRailId);
        const internalPayload = {
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
            start_datetime: moment(),
            number_of_rows: 1000, // keeping it at higher number as this is a required field
        };
        const callRailPayload = {
            callRailId,
            start_date: today,
            end_date: today,
        };

        if (isInternalLeadsEnabled || isCallrailEnabled) {
            const { getLeadStatus } = this.props;
            const getInternalLeadIds = (response) =>
                _.get(response, 'data.leads', []).map((l) => l.universal_session_id);
            const getCallrailLeadIds = (response) => _.get(response, 'data.calls', []).map((l) => l.id);
            let leadIds = [];

            if (isInternalLeadsEnabled && isCallrailEnabled) {
                const internalLeadsResponse = await getInternalLeads(internalPayload);
                const callrailLeadsResponse = await getCallrailLeads(callRailPayload);
                leadIds = _.concat(
                    getInternalLeadIds(internalLeadsResponse),
                    getCallrailLeadIds(callrailLeadsResponse)
                );
            } else if (isInternalLeadsEnabled) {
                const internalLeadsResponse = await getInternalLeads(internalPayload);
                leadIds = getInternalLeadIds(internalLeadsResponse);
            } else if (isCallrailEnabled) {
                const callrailLeadsResponse = await getCallrailLeads(callRailPayload);
                leadIds = getCallrailLeadIds(callrailLeadsResponse);
            }

            // call leadStatus api with all leadsId's
            getLeadStatus({ leads: leadIds });
        }
    };

    buildMap = () => {
        const {
            checklist: {
                patients: { isLoading: patientsWithBirthdayLoading, birthdaysToday },
                voicemails: { isLoading: unreadVoicemailsLoading },
                messages: { isLoading: unreadMessagesLoading },
                leads: { isLoading: leadsLoading },
            },
            pharmacy: { callTrackingEnabled: isInternalLeadsEnabled, ivrEnabled },
            callRailId,
            newLeadCount,
            unreadVoicemails,
            unreadMessages,
            basicMessagingEnabled,
            fullPepEnabled,
        } = this.props;
        const { patients, voicemail, inbox, lead } = listMap;
        const isLeadsEnabled = isInternalLeadsEnabled || Boolean(callRailId);

        return [
            {
                ...patients,
                action: birthdaysToday ? patients.action.replace('{n}', birthdaysToday) : patients.emptyMessage,
                isLoading: patientsWithBirthdayLoading,
                isEnabled: true,
            },
            {
                ...voicemail,
                action: unreadVoicemails ? voicemail.action.replace('{n}', unreadVoicemails) : voicemail.emptyMessage,
                isLoading: unreadVoicemailsLoading,
                isEnabled: ivrEnabled,
            },
            {
                ...inbox,
                action: unreadMessages ? inbox.action.replace('{n}', unreadMessages) : inbox.emptyMessage,
                isLoading: unreadMessagesLoading,
                isEnabled: basicMessagingEnabled || fullPepEnabled,
            },
            {
                ...lead,
                action: newLeadCount ? lead.action.replace('{n}', newLeadCount) : lead.emptyMessage,
                isLoading: leadsLoading,
                isEnabled: isLeadsEnabled,
            },
        ];
    };

    render() {
        const checklistItems = this.buildMap() || [];

        return (
            <div className="checklist-widget-container">
                <Typography className="title" gutterBottom>
                    Checklist for Today
                </Typography>
                <div className="list-items-container">
                    {checklistItems.map(
                        (list) =>
                            list.isEnabled && (
                                <ListItem
                                    key={list.action}
                                    action={list.action}
                                    subActions={list.subActions}
                                    navigationLink={list.navigationLink}
                                    isLoading={list.isLoading}
                                />
                            )
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { pharmacy, auth } = state;
    const { pharmacy: currentPharmacy } = pharmacy;
    const selectedPharmacyLocation = _.find(
        currentPharmacy.location,
        (location) => location.id === auth.selectedLocation.id
    );
    const callRailId = _.get(selectedPharmacyLocation, 'attr.callRailId.value', null);

    return {
        selectedPharmacy: auth.selectedCustomer.id,
        selectedLocation: auth.selectedLocation.id,
        pharmacy: state.pharmacy,
        callRailId,
        newLeadCount: homeSelectors.getNewLeadCount(state),
        unreadVoicemails: homeSelectors.getUnreadVoicemail(state),
        unreadMessages: homeSelectors.getUnreadMessagesCount(state),
        ...state.home,
        basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    };
};

const mapDispatchToProps = {
    getPatientsWithBirthday: homeActions.getPatientsWithBirthday,
    getUnreadVoicemail: homeActions.getUnreadVoicemail,
    getUnreadMessagesCount: homeActions.getUnreadMessagesCount,
    getInternalLeads: homeActions.getInternalLeads,
    getCallrailLeads: homeActions.getCallrailLeads,
    getLeadStatus: homeActions.getLeadStatus,
    resetHomeStore: homeActions.resetHomeStore,
};

const ChecklistWidget = connect(mapStateToProps, mapDispatchToProps)(ChecklistWidgetContainer);

export { ChecklistWidget };
