import './CreateOrEditAppointmentCalendarDialog.sass';
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    DialogActions,
    CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import classnames from 'classnames';
import WeeklyScheduleConfiguration from './WeeklyScheduleConfiguration';
import _ from 'lodash';
import VacationConfiguration from './VacationConfiguration';
import RemindersConfiguration from './RemindersConfiguration';
import config from 'config';
import EventIcon from '@material-ui/icons/Event';
import { SUPPORTED_TIMEZONES } from 'components/Patients/scheduling/schedulingUtil';

const TAB_TITLES = {
    'weekly-schedule': 'Regularly Scheduled Weekly Appointment Hours',
    vacation: 'Specific dates/times when appointments are not available',
    reminders: 'Customize reminders sent for appointments on this calendar',
};

/* eslint-disable */
const DEFAULT_TEXTS = {
    day_reminder_text:
        'You have an appointment at ${appointment_time}.\n\nWe will send you an additional reminder 1 hour before your appointment that will include a Check-in button that you should tap when you arrive at the pharmacy.',
    hour_reminder_text:
        'You have an appointment soon at ${location_name} at ${appointment_time}.\n\nOnce you arrive at the pharmacy, please tap the Check-In button below to let us know you have arrived and we will send you further instructions here.',
    checkin_response_text:
        "You're now checked in and we've notified the pharmacist.\n\nSomeone will be out shortly. If you haven't received a response or you have any questions, please call ${location_phone_number}.",
};
/* eslint-enable */

export default function CreateOrEditAppointmentCalendarDialog({
    open,
    onClose,
    isCreate,
    isSaving,
    initialConfiguration,
    createNewCalendar,
    deleteCalendar,
    updateCalendar,
    newCalendarColor,
    canDelete,
}) {
    const props = arguments[0];
    const [currentTab, setCurrentTab] = useState('weekly-schedule');
    const [configuration, setConfiguration] = useState(_.cloneDeep(initialConfiguration));
    const [nameError, setNameError] = useState(isCreate);
    const [savable, setIsSavable] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const updateConfiguration = _.debounce((config) => setConfiguration(config), 300);

    useEffect(() => {
        const nowSavable = isConfigurationSavable(isCreate, initialConfiguration, configuration);
        if (nowSavable !== savable) {
            setIsSavable(nowSavable);
        }
    }, [configuration]);

    const getRemindersConfiguration = () => {
        const keys = ['day_reminder_text', 'hour_reminder_text', 'checkin_response_text'];
        const config = {};
        _.each(keys, (key) => {
            config[key] = _.isString(configuration[key]) ? configuration[key] : DEFAULT_TEXTS[key];
        });
        return config;
    };

    return (
        <Dialog className="create-or-edit-appointment-calendar-dialog" open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <span className="title">
                    <EventIcon style={{ color: configuration.color }} /> {isCreate ? 'Create new' : 'Edit'} Clinical
                    Appointment Calendar
                </span>
                {isCreate || !canDelete ? null : (
                    <Button
                        data-cy="delete-calendar-button"
                        className="delete-calendar-button"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowDeleteConfirm(true)}
                    >
                        Delete this calendar
                    </Button>
                )}
            </DialogTitle>
            <DialogContent>
                <div className="explanation">
                    A clinical appointment calendar specifies when the pharmacy will be available to perform clinical
                    appointments. One or more calendars may be created to control when patients and/or pharmacy staff
                    may schedule appointments.
                </div>

                <div className="name-and-tz">
                    <TextField
                        className="name-text-field"
                        data-cy="calendar-name-input"
                        variant="outlined"
                        label="Name"
                        required
                        autoFocus
                        error={nameError}
                        defaultValue={configuration.name}
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => {
                            const error = _.trim(e.target.value) === '';
                            if (error !== nameError) {
                                setNameError(error);
                            }
                            updateConfiguration({ ...configuration, name: e.target.value.trim() });
                        }}
                    />

                    <div className="label-and-tz-select">
                        <span className="label">Timezone</span>
                        <Autocomplete
                            data-cy="timezone-autocomplete"
                            className="timezone-autocomplete"
                            value={configuration.timezone}
                            options={SUPPORTED_TIMEZONES}
                            getOptionLabel={(o) => o.replace('_', ' ')}
                            style={{ width: 240 }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            onChange={function (e, newValue) {
                                setConfiguration({ ...configuration, timezone: newValue });
                            }}
                        />
                    </div>
                </div>

                <div className="title-and-tabs">
                    <div className="tab-title">{TAB_TITLES[currentTab]}</div>

                    <div className="tabs">
                        <Button
                            data-cy="weekly-schedule-button"
                            className={classnames({ 'tab-button': true, selected: currentTab === 'weekly-schedule' })}
                            onClick={() => setCurrentTab('weekly-schedule')}
                        >
                            Weekly Schedule
                        </Button>
                        <Button
                            data-cy="vacation-button"
                            className={classnames({ 'tab-button': true, selected: currentTab === 'vacation' })}
                            onClick={() => setCurrentTab('vacation')}
                        >
                            Vacation
                        </Button>
                        <Button
                            data-cy="reminders-button"
                            className={classnames({ 'tab-button': true, selected: currentTab === 'reminders' })}
                            onClick={() => setCurrentTab('reminders')}
                        >
                            Reminders
                        </Button>
                    </div>
                </div>

                <div className="main-dialog-content">
                    {currentTab === 'weekly-schedule' ? (
                        <WeeklyScheduleConfiguration
                            {...props}
                            configuration={_.get(configuration, 'weeklySchedule')}
                            onChange={(weeklySchedule) => setConfiguration({ ...configuration, weeklySchedule })}
                        />
                    ) : currentTab === 'vacation' ? (
                        <VacationConfiguration
                            {...props}
                            configuration={_.get(configuration, 'vacation')}
                            onChange={(vacation) => setConfiguration({ ...configuration, vacation })}
                        />
                    ) : (
                        <RemindersConfiguration
                            {...props}
                            configuration={getRemindersConfiguration()}
                            onChange={(reminders) => setConfiguration({ ...configuration, ...reminders })}
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} data-cy="cancel-button">
                    Cancel
                </Button>

                <Button
                    data-cy="create-or-edit-calendar-button"
                    variant="contained"
                    color="primary"
                    disabled={!savable || isSaving}
                    onClick={
                        isCreate
                            ? () => {
                                  const baseCalendar = adaptCalendarConfigurationToBaseCalendar(configuration);
                                  baseCalendar.color = newCalendarColor;
                                  createNewCalendar(baseCalendar).then(() => onClose());
                              }
                            : () => {
                                  updateCalendar(
                                      initialConfiguration.calendar_id,
                                      adaptCalendarConfigurationToBaseCalendar(
                                          configuration,
                                          initialConfiguration.calendar_id
                                      ),
                                      adaptCalendarConfigurationToBaseCalendar(initialConfiguration)
                                  ).then(() => onClose());
                              }
                    }
                >
                    {isSaving ? (
                        <span className="saving-button-label">
                            <CircularProgress size={15} />
                            &nbsp; Saving...
                        </span>
                    ) : isCreate ? (
                        'Create new calendar'
                    ) : (
                        'Save changes'
                    )}
                </Button>
            </DialogActions>

            {!showDeleteConfirm ? null : (
                <ConfirmDeleteOverlay
                    setShowDeleteConfirm={setShowDeleteConfirm}
                    deleteCalendar={deleteCalendar}
                    calendar_id={configuration.calendar_id}
                    onClose={onClose}
                />
            )}
        </Dialog>
    );
}

function ConfirmDeleteOverlay({ setShowDeleteConfirm, deleteCalendar, calendar_id, onClose }) {
    const [deleting, setDeleting] = useState(false);
    return (
        <div className="confirm-delete-overlay" onClick={() => setShowDeleteConfirm(false)}>
            <div className="confirm-delete">
                <div className="heading">Really delete this Appointment Calendar?</div>
                <div className="explanation">
                    If you delete this calendar, any appointments upon it will no longer be accessible to you.
                </div>
                <div className="delete-buttons">
                    <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
                    <Button
                        data-cy="confirm-delete-button"
                        variant="contained"
                        onClick={() => {
                            setDeleting(true);
                            deleteCalendar(calendar_id).then(() => onClose());
                        }}
                    >
                        {!deleting ? (
                            'Delete Calendar'
                        ) : (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={15} color="secondary" />
                                &nbsp;Deleting...
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function isConfigurationSavable(isCreate, initialConfiguration, configuration) {
    const { name, timezone } = configuration;

    const hasChanged = isCreate ? true : !_.isEqual(initialConfiguration, configuration);

    return hasChanged && !_.isEmpty(_.trim(name)) && !_.isEmpty(timezone);
}

export function adaptSavedCalendarToInitialConfiguration(savedCalendar) {
    const { timezone } = savedCalendar;
    return {
        ..._.omit(savedCalendar, ['vacations', 'hours']),
        weeklySchedule: adaptSavedHoursToWeeklySchedule(savedCalendar.hours),
        vacation: adaptSavedVacationsToVacation(savedCalendar.vacations, timezone),
    };
}

export function adaptCalendarConfigurationToBaseCalendar(configuration, calendarId) {
    return {
        ..._.omit(configuration, ['vacation', 'hours']),
        pharmacy_id: config.X_PharmacyID,
        location_id: config.X_LocationID,
        hours: adaptWeeklyScheduleToBaseHours(configuration.weeklySchedule, calendarId),
        vacations: adaptVacationToBaseVacation(configuration.vacation, calendarId, configuration.timezone),
        calendar_id: calendarId,
        status: _.isNil(calendarId) ? undefined : 'ACTIVE',
    };
}

function parseTime(ts, defaultValue = [null, null]) {
    return _.isString(ts) ? _.slice(_.map(ts.split(':'), Number), 0, 2) : defaultValue;
}

function adaptSavedHoursToWeeklySchedule(hours) {
    const weeklySchedule = {};

    const adaptBlock = ({ start_time, end_time, capacity }) => {
        const [startHour, startMinute] = parseTime(start_time);
        const [endHour, endMinute] = parseTime(end_time);

        return {
            startHour,
            startMinute,
            endHour,
            endMinute,
            capacity,
        };
    };

    _.forIn(hours, (blocks, day) => {
        const key = _.capitalize(day);
        weeklySchedule[key] = _.map(blocks, adaptBlock);
    });
    return weeklySchedule;
}

function adaptSavedVacationsToVacation(vacations, timezone) {
    return _.map(vacations, ({ start_date, end_date, capacity }) => {
        const local_start_date = moment
            .tz(moment.utc(start_date, 'YYYY-MM-DDTHH:mm:ss'), timezone)
            .format('YYYY-MM-DDTHH:mm:ss');
        const [fromDate, fromTime] = local_start_date.split('T');
        const [fromHour, fromMinute] = parseTime(fromTime, [0, 0]);

        const fromAllDay = fromHour === 0 && fromMinute === 0;

        const local_end_date = moment
            .tz(moment.utc(end_date, 'YYYY-MM-DDTHH:mm:ss'), timezone)
            .format('YYYY-MM-DDTHH:mm:ss');
        const [toDate, toTime] = local_end_date.split('T');
        const [toHour, toMinute] = parseTime(toTime, [23, 59]);

        const toAllDay = toHour === 23 && toMinute === 59;

        return {
            fromDate,
            fromAllDay,
            fromHour: !fromAllDay ? fromHour : '',
            fromMinute: !fromAllDay ? fromMinute : '',
            toDate,
            toAllDay,
            toHour: !toAllDay ? toHour : '',
            toMinute: !toAllDay ? toMinute : '',
            capacity: _.isNil(capacity) ? 'all' : capacity,
        };
    });
}

const DAYS_TO_ISO_WEEKDAY = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
};
function padTime(n) {
    return String(n).padStart(2, '0');
}

function adaptWeeklyScheduleToBaseHours(weeklySchedule, calendarId) {
    const hours = {};
    const adaptTime = (hour, minute) => `${padTime(hour)}:${padTime(minute)}:00`;

    _.forIn(weeklySchedule, (blocks, day) => {
        hours[_.toLower(day)] = _.map(blocks, ({ startHour, startMinute, endHour, endMinute, capacity }) => {
            return {
                iso_weekday: DAYS_TO_ISO_WEEKDAY[day],
                start_time: adaptTime(startHour, startMinute),
                end_time: adaptTime(endHour, endMinute),
                capacity,
                calendar_id: calendarId,
            };
        });
    });

    return hours;
}

function adaptVacationToBaseVacation(vacation, calendar_id, timezone) {
    return _.map(
        vacation,
        ({ fromAllDay, fromDate, fromHour, fromMinute, toAllDay, toDate, toHour, toMinute, capacity }) => {
            const start_date = moment
                .tz(
                    `${fromDate} ${fromAllDay ? '00:00:00' : `${padTime(fromHour)}:${padTime(fromMinute)}:00`}`,
                    'YYYY-MM-DD HH:mm:ss',
                    timezone
                )
                .utc()
                .toISOString();
            const end_date = moment
                .tz(
                    `${toDate} ${toAllDay ? '23:59:59' : `${padTime(toHour)}:${padTime(toMinute)}:00`}`,
                    'YYYY-MM-DD HH:mm:ss',
                    timezone
                )
                .utc()
                .toISOString();

            return {
                start_date,
                end_date,
                capacity: capacity === 'all' || _.isNil(capacity) ? null : capacity,
                calendar_id,
            };
        }
    );
}
