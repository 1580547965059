import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import { Avatar, Icon, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import _ from 'lodash';

import { getPharmacyUserRoleDetails } from './helper';
import { pluralize } from '../../../../utils/formatTime';
import withMediaQuery from 'components/Common/WithMediaQuery';

export function UserSkeletonLoader({ isMobile }) {
    // React placeholder adds inline styles so add wrapper span as buffer
    const columnSkeleton = _.times(isMobile ? 2 : 5, (i) => (
        <span key={i}>
            <ReactPlaceholder type="text" rows={1} ready={false} showLoadingAnimation firstLaunchOnly>
                <span />
            </ReactPlaceholder>
        </span>
    ));

    return <div className="user-management-list__row user-management-list__body">{columnSkeleton}</div>;
}

export function User(props) {
    const {
        name,
        email,
        roles,
        pharmacyId,
        lastActive,
        currentUser,
        actions,
        onAction,
        selectedLocalLocation,
        isInternalUser,
        isMobile,
    } = props;
    const [anchorElement, setAnchorElement] = React.useState(null);
    const onIconClick = (event) => {
        setAnchorElement(event.currentTarget);
    };
    const pharmacyUserRoleDetails = getPharmacyUserRoleDetails(pharmacyId, roles);
    const locationAccessCount =
        pharmacyUserRoleDetails.locationRoles.length - pharmacyUserRoleDetails.locationNoneRoleCount;
    const locationAccessText =
        pharmacyUserRoleDetails.pharmacyRole && pharmacyUserRoleDetails.locationNoneRoleCount === 0
            ? 'All locations'
            : `${locationAccessCount} location${pluralize(locationAccessCount)}`;

    return (
        <div
            className={`user-management-list__row user-management-list__body ${
                currentUser ? 'user-management-list__current-user' : ''
            }`}
        >
            <div title={name} className="flex-middle name">
                {isInternalUser && <Avatar className="avatar" src="/favicon.ico" />}
                {name || '—'}
            </div>
            <span title={email} className="email">
                {email}
            </span>
            {!isMobile && (
                <Fragment>
                    {selectedLocalLocation === 'allLocations' && <span>{locationAccessText}</span>}
                    <span title={lastActive === '—' ? '' : lastActive}>
                        {currentUser ? 'Current User' : lastActive}
                    </span>
                </Fragment>
            )}
            <span className="flex-right  menu-items">
                {!_.isEmpty(actions) && (
                    <Fragment>
                        <IconButton onClick={onIconClick} size="small">
                            <Icon>{isMobile ? 'more_horiz' : 'more_vert'}</Icon>
                        </IconButton>
                        <Menu
                            id="user-action-menu"
                            keepMounted
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={() => setAnchorElement(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            getContentAnchorEl={null}
                        >
                            {actions.map((action, index) => (
                                <MenuItem
                                    key={action.text}
                                    onClick={() => {
                                        onAction(index);
                                        setAnchorElement(null);
                                    }}
                                    className={action.className}
                                >
                                    <ListItemIcon>
                                        <Icon>{action.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText>{action.text}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Fragment>
                )}
            </span>
        </div>
    );
}

User.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            entityId: PropTypes.string.isRequired,
            parentId: PropTypes.string.isRequired,
            entityName: PropTypes.string.isRequired,
            entityType: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            inherited: PropTypes.bool.isRequired,
        })
    ).isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            className: PropTypes.string,
            onClick: PropTypes.func.isRequired,
        })
    ),
    pharmacyId: PropTypes.string.isRequired,
    onAction: PropTypes.func,
    lastActive: PropTypes.string,
    currentUser: PropTypes.bool,
};

User.defaultProps = {
    currentUser: false,
    lastActive: '—',
};

export const UserSkeleton = withMediaQuery(UserSkeletonLoader);
export default withMediaQuery(User);
