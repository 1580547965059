import './PatientsList.sass';
import React, { useState, useEffect } from 'react';
import { Table, Column } from 'react-virtualized';
import PatientName from './PatientName';
import _ from 'lodash';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { userAction } from '../../redux/Inbox/User';
import { connect } from 'react-redux';
import EditContactDialog from '../Inbox/EditContactDialog';
import { updatePatientData } from '../../redux/Patient/PatientData/action';
import { updatePatientFromInboxUserData } from './patientsUtil';
import { formatRawDate, getAbbreviatedDateInputFormatByCountryCode } from '../../utils/helper';
import { pharmacySelectors } from 'redux/Pharmacy/selector';

function PatientsList({
    height,
    width,
    selectedUsers,
    nameFormat,
    onSelectedChange,
    removeDisabled,
    showUnmessageable,
    inboxUser,
    updateUser,
    archiveUser,
    openEditUserDialog,
    closeEditUserDialog,
    patientData,
    setActiveUser,
    updatePatientData,
    activeLocationCountryCode,
}) {
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [patientBeingEdited, setPatientBeingEdited] = useState(null);

    const isUnmessageable = ({ phone_type } = {}) => phone_type === 'invalid' || phone_type === 'landline';

    const formatDate = (dob) =>
        formatRawDate(
            dob,
            'YYYY-MM-DD',
            getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode })
        );

    const sorted = _.sortBy(
        _.values(selectedUsers),
        _.filter([
            // if showUnmessageable, then sort the unmessageable users to the top (so the user can see them and fix them if possible)
            // since sortBy is a stable sort, this will result in alphabetically sorted unmessageable users followed by alphabetically
            // sorted messageable users
            showUnmessageable ? (d) => (isUnmessageable(d) ? 0 : 1) : null,
            // then sort by name
            ({ full_name, last_name, first_name }) => {
                return nameFormat === 'last_first' ? _.toLower(`${last_name} ${first_name}`) : _.toLower(full_name);
            },
        ])
    );

    useEffect(() => {
        const { patientInboxData } = patientData;

        if (_.isObject(patientInboxData)) {
            setActiveUser(patientInboxData.inbox_user_id, patientInboxData);
        }
    }, [_.get(patientData, 'patientInboxData.inbox_user_id')]);

    const unmessablePatients = _.filter(sorted, isUnmessageable);

    const nameWidth = removeDisabled && _.isEmpty(unmessablePatients) ? 180 : 150;

    return (
        <div className="patients-list">
            <Table
                width={width}
                height={height}
                rowCount={_.size(selectedUsers)}
                rowHeight={40}
                rowGetter={({ index }) => {
                    return sorted[index];
                }}
                rowStyle={function ({ index }) {
                    const style = index % 2 === 0 ? { background: '#fff' } : { background: '#f8f8f8' };
                    if (index === deletingIndex) {
                        style.opacity = 0;
                    }

                    if (isUnmessageable(sorted[index])) {
                        style.cursor = 'not-allowed';
                    }

                    return style;
                }}
                noRowsRenderer={(props) => {
                    return (
                        <div className="none-selected-message">
                            Select one or more patients from the patients table to the left to continue.
                        </div>
                    );
                }}
            >
                <Column
                    dataKey="name"
                    width={nameWidth}
                    cellRenderer={({ rowData }) => {
                        if (isUnmessageable(rowData)) {
                            return (
                                <Tooltip title="This phone number associated with this patient can not receive messages.">
                                    <div style={{ display: 'flex', opacity: 0.5, textDecoration: 'line-through' }}>
                                        <PatientName {...rowData} format={nameFormat} width={nameWidth} />
                                    </div>
                                </Tooltip>
                            );
                        }
                        return <PatientName {...rowData} format={nameFormat} width={nameWidth} />;
                    }}
                />
                <Column
                    dataKey="dob"
                    width={80}
                    cellRenderer={({ rowData }) => {
                        return (
                            <div style={{ opacity: isUnmessageable(rowData) ? 0.5 : null }}>
                                {formatDate(rowData.date_of_birth)}
                            </div>
                        );
                    }}
                />
                {removeDisabled && _.isEmpty(unmessablePatients) ? null : (
                    <Column
                        dataKey="actions"
                        className="actions-column"
                        width={38}
                        cellRenderer={function ({ rowData, rowIndex }) {
                            return (
                                <div className="remove-cell">
                                    <Tooltip title="Edit patient details">
                                        <IconButton
                                            onClick={() => {
                                                setPatientBeingEdited(rowData);
                                                openEditUserDialog();
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>

                                    {removeDisabled ? null : (
                                        <Tooltip title="Remove patient from selected">
                                            <IconButton
                                                onClick={() => {
                                                    setDeletingIndex(rowIndex);
                                                    // Do this in a short timeout to give the fade out animation run
                                                    // before removing the row
                                                    setTimeout(() => {
                                                        setDeletingIndex(null);
                                                        const clone = _.clone(selectedUsers);
                                                        delete clone[rowData.inbox_user_id];
                                                        onSelectedChange(clone, selectedUsers);
                                                    }, 100);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            );
                        }}
                    />
                )}
            </Table>

            <EditContactDialog
                visible={inboxUser.showEditUserDialog}
                closeDialog={closeEditUserDialog}
                sending={inboxUser.sending}
                requestError={inboxUser.updateUserMessage}
                updateContact={(userData, userId) => {
                    updateUser(userData, userId, (updatedUserData) => {
                        const updated = updatePatientFromInboxUserData(
                            _.find(sorted, ({ inbox_user_id }) => inbox_user_id === userId),
                            updatedUserData
                        );
                        updatePatientData(updated);
                    });
                }}
                archiveContact={archiveUser}
                contact={_.isNil(patientBeingEdited) ? {} : patientBeingEdited}
            />
        </div>
    );
}

function mapStateToProps(state) {
    const { inboxUser, patientData } = state;

    return {
        inboxUser,
        patientData,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = {
    updateUser: userAction.updateUser,
    archiveUser: userAction.archiveUser,
    openEditUserDialog: userAction.openEditUserDialog,
    closeEditUserDialog: userAction.closeEditUserDialog,
    setActiveUser: userAction.setActiveUser,
    updatePatientData,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientsList);
