import axios from 'axios';

const getUserType = (isInternalUser) => (isInternalUser ? 'Internal' : 'Pharmacy');

const convertUTCEpocToLocalDate = (epocSeconds, long = false) => {
    const d = new Date(0);
    d.setUTCSeconds(epocSeconds);

    //use long format for message bubbles
    if (long) {
        //if the message was not sent this year, include the year
        if (d.getFullYear < new Date().getFullYear())
            return d.toLocaleTimeString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        //if it was sent this year, exclude the year
        else
            return d.toLocaleTimeString('en-US', {
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
    } else return d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
};

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = token;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
};

const ConvertStringToTitle = (string) => string.replace(/([A-Z])/g, ' $1').replace(/(.)/, (c) => c.toUpperCase());

function handleWindowClickListeners(cb, enabled = false) {
    let mobileSafari;
    if (typeof mobileSafari === 'undefined' && typeof window !== 'undefined') {
        const ua = window.navigator.userAgent;
        const iOS = ua.match(/iP(ad|hone)/i);
        const webkit = ua.match(/WebKit/i);
        mobileSafari = iOS && webkit && !ua.match(/CriOS/i);

        if (mobileSafari) {
            document.body.style.cursor = 'pointer';
        }
    }

    const listener = window[`${enabled ? 'add' : 'remove'}EventListener`];
    listener('click', cb);
}

export { getUserType, setAuthToken, ConvertStringToTitle, handleWindowClickListeners, convertUTCEpocToLocalDate };
