import React, { useRef } from 'react';
import { Cell } from 'react-md';
import { Icon, MenuItem, FormControl, Select, CircularProgress } from '@material-ui/core';

import { formatPhoneNo, isPhoneNoValid } from 'utils/helper';
import SORT_MODE, { LEAD_STATUS, LEADS_STATUS_OPTIONS } from 'constants/Leads';
import PhoneNumberLink from 'components/Common/PhoneNumberLink';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';
import CallRecordingStatus from './CallRecordingStatus';
import { getDateFormatted } from 'utils/helper';
import { leadHelper } from 'utils/leadHelper';
import InfiniteScrollContainer from 'components/Common/InfiniteScrollContainer';

export const LeadsTable = ({
    leadsData,
    sortColumn,
    sort,
    loading,
    selectedLead,
    selectLead,
    setStatus,
    selectedTab,
    canUpdate,
    onLoadMore,
}) => {
    const contentContainer = useRef(null);

    const canShowLead = (lead) => {
        return (
            selectedTab.value === LEAD_STATUS.UNASSIGNED ||
            (lead.status && selectedTab.value === LEAD_STATUS.NEW_LEADS && lead.status === LEAD_STATUS.NEW_LEADS) ||
            (lead.status && selectedTab.value === LEAD_STATUS.IN_PROGRESS && lead.status === LEAD_STATUS.IN_PROGRESS) ||
            (lead.status && selectedTab.value === LEAD_STATUS.COMPLETED && lead.status === LEAD_STATUS.COMPLETED)
        );
    };

    const getClassNames = (index) => {
        let classNames = 'grid-container user-clickable';

        if (index % 2 === 0) {
            classNames += ' even-background';
        }
        if (index === selectedLead) {
            classNames += ' selected-lead';
        }

        return classNames;
    };

    const setSelectedLead = (e, lead) => {
        e.stopPropagation();
        selectLead(lead);
    };

    return (
        <div className="grid-container leads-table">
            <Cell
                size={3}
                phoneHidden
                tabletHidden
                className="flex-middle title name-title user-clickable"
                onClick={sortColumn.bind(this, { column: SORT_MODE.PATIENT_NAME })}
            >
                Patient Name
                <Icon>
                    {sort.column === SORT_MODE.PATIENT_NAME &&
                        (sort.order === 'desc' ? 'arrow_downward' : 'arrow_upward')}
                </Icon>
            </Cell>
            <Cell
                size={2}
                phoneHidden
                tabletHidden
                className="flex-middle user-clickable title"
                onClick={sortColumn.bind(this, { column: SORT_MODE.PHONE })}
            >
                Phone Number
                <Icon>
                    {sort.column === SORT_MODE.PHONE && (sort.order === 'desc' ? 'arrow_downward' : 'arrow_upward')}
                </Icon>
            </Cell>
            <Cell
                size={2}
                phoneHidden
                tabletHidden
                className="flex-middle title user-clickable"
                onClick={sortColumn.bind(this, { column: SORT_MODE.CHANNEL })}
            >
                Channel
                <Icon>
                    {sort.column === SORT_MODE.CHANNEL && (sort.order === 'desc' ? 'arrow_downward' : 'arrow_upward')}
                </Icon>
            </Cell>
            <Cell size={1} phoneHidden tabletHidden className="flex-middle title">
                Call Recording
            </Cell>
            <Cell
                size={2}
                phoneHidden
                tabletHidden
                className="flex-middle title user-clickable"
                onClick={sortColumn.bind(this, { column: SORT_MODE.DATE })}
            >
                Date Submitted
                <Icon>
                    {sort.column === SORT_MODE.DATE && (sort.order === 'desc' ? 'arrow_downward' : 'arrow_upward')}
                </Icon>
            </Cell>
            <Cell size={2} phoneHidden tabletHidden className="flex-middle title">
                Status
            </Cell>
            <Cell size={12} className="body">
                <div className="infinite-scroll-container" ref={contentContainer}>
                    <InfiniteScrollContainer ref={contentContainer} offset={10} onScrollBottom={onLoadMore}>
                        {loading ? (
                            <TabularTypePlaceholder legendsCount={7} />
                        ) : (
                            leadsData.map(
                                (lead, i) =>
                                    canShowLead(lead) && (
                                        <div
                                            key={i}
                                            className={getClassNames(i)}
                                            onClick={(e) => setSelectedLead(e, i)}
                                        >
                                            <Cell
                                                size={3}
                                                phoneSize={2}
                                                tabletSize={2}
                                                className="patient-name flex-middle"
                                                data-name
                                            >
                                                {(lead.customer_name && lead.customer_name.toLowerCase()) || '-'}
                                            </Cell>
                                            <Cell
                                                desktopHidden
                                                phoneSize={2}
                                                tabletHidden
                                                className="flex-middle date-submitted"
                                                data-date-submitted
                                            >
                                                {(lead.start_time && getDateFormatted(lead.start_time)) || '-'}
                                            </Cell>
                                            <Cell
                                                size={2}
                                                phoneSize={2}
                                                tabletSize={2}
                                                className="flex-middle phone-number"
                                                data-phone-number
                                            >
                                                {isPhoneNoValid(lead.customer_phone_number) ? (
                                                    <PhoneNumberLink
                                                        numberToCall={lead.customer_phone_number}
                                                        numberToShow={formatPhoneNo(lead.customer_phone_number)}
                                                    />
                                                ) : (
                                                    'No Caller ID'
                                                )}
                                            </Cell>
                                            <Cell
                                                desktopHidden
                                                phoneSize={2}
                                                tabletHidden
                                                className="flex-middle flex-right"
                                            >
                                                <Icon>arrow_forward_ios</Icon>
                                            </Cell>
                                            <Cell
                                                size={2}
                                                phoneSize={2}
                                                tabletSize={2}
                                                className="channel flex-middle"
                                                data-channel
                                            >
                                                {leadHelper.getLeadSourceName(lead.source_name)}
                                            </Cell>
                                            <Cell size={1} phoneHidden className="call-recording flex-middle">
                                                <CallRecordingStatus isAvailable={Boolean(lead.recording)} />{' '}
                                            </Cell>
                                            <Cell
                                                size={2}
                                                phoneHidden
                                                tabletHidden
                                                className="flex-middle date-submitted"
                                                data-date
                                            >
                                                {(lead.start_time && getDateFormatted(lead.start_time)) || '-'}
                                            </Cell>
                                            <Cell size={2} phoneHidden className="status">
                                                {lead.isSaving ? (
                                                    <CircularProgress size="20px" />
                                                ) : (
                                                    <FormControl>
                                                        <Select
                                                            value={lead.status || ''}
                                                            onChange={(e) => setStatus(e.target.value, lead)}
                                                            onClick={(e) => e.stopPropagation()}
                                                            disableUnderline
                                                            displayEmpty
                                                            disabled={!canUpdate}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                Unassigned
                                                            </MenuItem>
                                                            {Object.keys(LEADS_STATUS_OPTIONS).map((status) => (
                                                                <MenuItem key={status} value={status}>
                                                                    {LEADS_STATUS_OPTIONS[status]}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Cell>
                                        </div>
                                    )
                            )
                        )}
                    </InfiniteScrollContainer>
                </div>
            </Cell>
        </div>
    );
};

export default LeadsTable;
