import patientDataTypes from './type';
import _ from 'lodash';

export const initialState = {
    pendingRequests: new Set(),
    query: null,
    sort: null,
    loadedPatients: null, // map of row index to patient so we don't have to rely on ordering in an array to
    loadedPatientsByInboxUserId: null,
    patientCount: null,
    patientInboxDataId: null,
    patientInboxData: null,

    bulkPhoneTypeLookupLoading: null,
    bulkPhoneTypeLookupInboxUserIds: null,
    bulkPhoneTypeLookupResults: null,

    duplicateNewPatients: null,
    searchPatientList: null,

    patientIndexUpdatedAt: null,

    loadingPatientsByInboxUserIds: null,
};

export function patientDataReducer(state = initialState, { type, payload }) {
    switch (type) {
        case patientDataTypes.FOCUS_PATIENT:
            return {
                ...state,
                focusedPatient: payload.focusedPatient,
            };

        case patientDataTypes.RESET_PATIENT_DATA:
            return {
                ...initialState,
            };

        case patientDataTypes.PATIENT_DATA_REQUEST:
            return {
                ...state,
                pendingRequests: (() => {
                    const clone = new Set(state.pendingRequests);
                    clone.add(`${payload.startIndex}-${payload.endIndex}`);
                    return clone;
                })(),
                query: payload.query,
                sort: payload.sort,
                filters: payload.filters,
                newLoad: payload.newLoad,
                latestFetchStartedAt: payload.startedAt,
            };

        case patientDataTypes.PATIENT_DATA_SUCCESS:
            return {
                ...state,
                pendingRequests: (() => {
                    // remove this key from the pending requests object
                    const clone = new Set(state.pendingRequests);
                    clone.delete(`${payload.startIndex}-${payload.endIndex}`);
                    return clone;
                })(),
                loadedPatients: (() => {
                    const { startIndex, rows } = payload;
                    const clone = Object.assign({}, state.loadedPatients);
                    // add each row into the loadedPatients map (mapped by row index)
                    _.each(rows, (row, i) => {
                        clone[i + startIndex] = row;
                    });
                    return clone;
                })(),
                loadedPatientsByInboxUserId: (() => {
                    // store these mapped by inbox user id as well to facilitate quickly looking them up
                    // since we are sharing references to the actual patient documents, this will not be space inefficient...
                    const { rows } = payload;
                    const clone = Object.assign({}, state.loadedPatientsByInboxUserId);
                    _.each(rows, (row) => {
                        clone[row.inbox_user_id] = row;
                    });
                    return clone;
                })(),
                suggestedName: payload.suggested_name,
                patientCount: payload.count,
                query: payload.query,
                sort: payload.sort,
                filters: payload.filters,
                newLoad: false,
                error: null,
                listToSearch: payload.listToSearch,
            };

        case patientDataTypes.PATIENT_DATA_FAILURE:
            return {
                ...state,
                pendingRequests: (() => {
                    // remove this key from the pending requests object
                    const clone = new Set(state.pendingRequests);
                    clone.delete(`${payload.startIndex}-${payload.endIndex}`);
                    return clone;
                })(),
                query: payload.query,
                sort: payload.sort,
                error: payload.error,
                newLoad: false,
                listToSearch: payload.listToSearch,
            };

        case patientDataTypes.PATIENT_INBOX_DATA_REQUEST:
            return {
                ...state,
                patientInboxDataId: payload.inboxUserId,
                patientInboxDataLoading: true,
            };

        case patientDataTypes.PATIENT_INBOX_DATA_SUCCESS:
            return {
                ...state,
                patientInboxDataId: payload.inboxUserId,
                patientInboxDataLoading: false,
                patientInboxData: _.get(payload, 'users_activity.[0]'),
            };

        case patientDataTypes.BULK_PHONE_TYPE_LOOKUP_REQUEST:
            return {
                ...state,
                bulkPhoneTypeLookupLoading: true,
                bulkPhoneTypeLookupInboxUserIds: payload.inboxUserIds,
            };

        case patientDataTypes.BULK_PHONE_TYPE_LOOKUP_COMPLETE:
            return {
                ...state,
                bulkPhoneTypeLookupLoading: false,
                bulkPhoneTypeLookupInboxUserIds: payload.inboxUserIds,
                bulkPhoneTypeLookupResults: { errors: payload.errors, phoneTypes: payload.phoneTypes },
                // update the phone_types of any successful lookups
                loadedPatientsByInboxUserId: (() => {
                    const clone = Object.assign({}, state.loadedPatientsByInboxUserId);
                    _.forIn(payload.phoneTypes, (phoneType, inboxUserId) => {
                        if (_.isObject(clone[inboxUserId])) {
                            clone[inboxUserId].phone_type = phoneType;
                        }
                    });
                    return clone;
                })(),
            };

        case patientDataTypes.UPDATE_PATIENT_DATA:
            return {
                ...state,
                loadedPatientsByInboxUserId: (() => {
                    const clone = { ...state.loadedPatientsByInboxUserId };
                    const patients = !_.isArray(payload.patientData) ? [payload.patientData] : payload.patientData;
                    _.each(patients, (patient) => {
                        const id = _.get(patient, 'inbox_user_id');
                        if (!_.isNil(id)) {
                            if (_.isNil(clone[id])) {
                                clone[id] = patient;
                            } else {
                                Object.assign(clone[id], patient);
                            }
                        }
                    });
                    return clone;
                })(),
            };

        case patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_REQUEST:
            return {
                ...state,
                duplicateNewPatients: { loading: true, newPatients: payload.newPatients },
            };

        case patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_SUCCESS:
            return {
                ...state,
                duplicateNewPatients: {
                    loading: false,
                    newPatients: payload.newPatients,
                    response: payload.response,
                },
            };

        case patientDataTypes.EXACT_DUPLICATE_NEW_PATIENTS_FAILURE:
            return {
                ...state,
                duplicateNewPatients: {
                    loading: false,
                    newPatients: payload.newPatients,
                    error: payload.error,
                },
            };

        case patientDataTypes.SEARCH_PATIENT_LIST_REQUEST:
            return {
                ...state,
                searchPatientList: { loading: true, patients: payload.patients },
            };

        case patientDataTypes.SEARCH_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                searchPatientList: { loading: false, patients: payload.patients, response: payload.response },
            };

        case patientDataTypes.SEARCH_PATIENT_LIST_FAILURE:
            return {
                ...state,
                searchPatientList: { loading: false, patients: payload.patients, error: payload.error },
            };

        case patientDataTypes.PATIENT_INDEX_UPDATED:
            return {
                ...state,
                patientIndexUpdatedAt: payload.at,
            };

        case patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_REQUEST:
            return {
                ...state,
                loadingPatientsByInboxUserIds: { loading: true, ...payload },
            };

        case patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_SUCCESS:
            return {
                ...state,
                loadingPatientsByInboxUserIds: { loading: false, ...payload },
            };

        case patientDataTypes.LOAD_PATIENTS_BY_INBOX_USER_IDS_FAILURE:
            return {
                ...state,
                loadingPatientsByInboxUserIds: { loading: false, ...payload },
            };

        default:
            return state;
    }
}
