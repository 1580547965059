import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Basic Messaging';
const description = 'Enable or disable Basic Messaging for this location';

const BasicMessaging = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(BasicMessaging, {
    package: {
        name: 'Inbox',
        code: 'INBX',
        attribute: {
            name: 'BasicMessagingEnabled',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.boolean,
        },
    },
    title,
    description,
});
