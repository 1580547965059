import templateType from './type';

export const initialState = {
    sending: false,
    loading: false,
    createTemplateMessage: '',
    updateTemplateMessage: '',
    templates: [],
    error: {},
};

export const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case templateType.ACTIVATE_TEMPLATE_REQUEST:
            return {
                ...state,
                sending: true,
                templates: action.templates,
            };
        case templateType.ACTIVATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case templateType.ACTIVATE_TEMPLATE_FAILURE:
            return {
                ...state,
                sending: false,
                createTemplateMessage: action.error.message,
                error: action.error,
            };
        case templateType.DEACTIVATE_TEMPLATE_FAILURE:
            return {
                ...state,
                sending: false,
                createTemplateMessage: action.error.message,
                error: action.error,
            };
        case templateType.DEACTIVATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case templateType.DEACTIVATE_TEMPLATE_REQUEST:
            return {
                ...state,
                sending: true,
                templates: action.templates,
            };
        case templateType.CREATE_TEMPLATE_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case templateType.CREATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                sending: false,
                createTemplateMessage: '',
            };
        case templateType.CREATE_TEMPLATE_FAILURE:
            return {
                ...state,
                sending: false,
                createTemplateMessage: action.error.message,
                error: action.error,
            };
        case templateType.UPDATE_TEMPLATE_REQUEST:
            return {
                ...state,
                sendingUuid: action.templateData.inbox_conversation_template_id,
                sending: true,
            };
        case templateType.UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                sending: false,
                updateTemplateMessage: '',
            };
        case templateType.UPDATE_TEMPLATE_FAILURE:
            return {
                ...state,
                sending: false,
                updatetemplateMessage: action.error.message,
                error: action.error,
            };
        case templateType.GET_TEMPLATES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case templateType.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                // since we currently follow the pattern of updating a template and then refetching the new state,
                // setting sendingUuid to null here after setting it on UPDATE_TEMPLATE_REQUEST will cause
                // any saving indicators to not go away until the new state (with the saved changes) makes
                // it back from the server
                sendingUuid: null,
                templates: action.templates,
            };
        case templateType.GET_TEMPLATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case templateType.DELETE_TEMPLATE_REQUEST:
            return {
                ...state,
                sending: true,
                templates: action.templates,
            };
        case templateType.DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                sending: false,
            };
        case templateType.DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default templateReducer;
