import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import HistoryList from './HistoryList';
import EmailPreview from './EmailPreview';

const HistoryWrapper = (props) => {
    const { isActiveNewsletter } = props;

    return (
        <Fragment>
            <div
                className={classnames({
                    hidden: isActiveNewsletter,
                })}
            >
                <HistoryList />
            </div>
            <div
                className={classnames({
                    hidden: !isActiveNewsletter,
                })}
            >
                <EmailPreview />
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    isActiveNewsletter: state.newsletter.isActiveNewsletter,
});

export default connect(mapStateToProps, null)(HistoryWrapper);
