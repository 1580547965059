import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CircularProgress, Fab, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import RefillTable from './RefillTable';
import SearchInput from 'components/Common/SearchInput';
import EmptyRefills from './EmptyRefills';
import { fetchRefills, fetchRefillsCount, sortRefills } from 'redux/actionCreators/Refills';
import DATE_SEARCH_FORMATS from 'constants/DateSearchFormats';
import NotificationDialog from 'components/Common/NotificationDialog';
import { fabStyles } from 'components/Common';
import RefillDetails from './RefillDetails';

const MAX_RECORDS = 25;

export class Refill extends PureComponent {
    state = {
        filteredResult: [],
        searchTerm: '',
        selectedRefill: null,
    };

    componentDidMount() {
        const { refillsEnabled } = this.props;
        if (refillsEnabled) this.getData();
    }

    componentDidUpdate({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) {
        const { selectedLocation, refillsEnabled, pharmacy } = this.props;

        const isPharmacyUpdated = oldPharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isLocationChanged = selectedLocation !== oldSelectedLocation;

        if ((isLocationChanged || isPharmacyUpdated) && !pharmacy.loading) {
            this.setState({ searchTerm: '', selectedRefill: null }, () => {
                if (refillsEnabled) this.getData();
            });
        }
    }

    fetchRefillsCount = () => {
        const { fetchRefillsCount, selectedLocation, selectedPharmacy } = this.props;

        fetchRefillsCount({
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
        });
    };

    fetchRefills = (payload) => {
        const { fetchRefills, selectedLocation, selectedPharmacy } = this.props;

        fetchRefills({
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
            numberOfOrders: MAX_RECORDS,
            ...payload,
        });
    };

    getData = () => {
        this.fetchRefillsCount();
        this.fetchRefills({ latestOrderTimestamp: moment.utc().format() });
    };

    closeDialog = () => {
        this.setState({
            patientAnchorEl: null,
            commentsAnchorEl: null,
            medicationAnchorEl: null,
        });
    };

    onLoadMoreClick = () => {
        const { refills } = this.props;
        const lastRefillRecord = refills[refills.length - 1];
        const latestOrderTimestamp = lastRefillRecord.created_date;
        const filterByMedicationId = refills.filter(
            (r) => r.submitted_refill_id === lastRefillRecord.submitted_refill_id
        );
        const ignoreSubmittedRefillMedicationIds = filterByMedicationId.map((f) => f.submitted_refill_medication_id);
        this.fetchRefills({ loadMore: true, latestOrderTimestamp, ignoreSubmittedRefillMedicationIds });
    };

    onSearch = (term) => {
        if (term.length) {
            this.setState({ searchTerm: term.toLowerCase().trim() });
        } else {
            this.setState({ searchTerm: '' });
        }
    };

    getRefills = () => {
        const { refills } = this.props;
        const { searchTerm } = this.state;
        let filteredResult = [];
        if (searchTerm.length > 2) {
            const dateInput = moment(searchTerm, DATE_SEARCH_FORMATS, true);
            if (dateInput.isValid()) {
                // search by date
                filteredResult = _.filter(refills, (refill) => {
                    const refillDate = moment.utc(refill['created_date']).local().startOf('day');
                    return dateInput.startOf('day').isSame(refillDate);
                });
            } else {
                // search by Rx Name or Patient Name
                filteredResult = _.filter(refills, (refill) => {
                    let firstName = refill.patient_info.first_name;
                    let lastName = refill.patient_info.last_name;
                    let rxName = refill.rx_name;
                    let rxNum = refill.rx_num;
                    let fullName;

                    firstName = !firstName ? '' : firstName.toLowerCase();
                    lastName = !lastName ? '' : lastName.toLowerCase();
                    fullName = `${firstName} ${lastName}`;
                    rxName = !rxName ? '' : rxName.toLowerCase();
                    rxNum = !rxNum ? '' : rxNum;

                    return (
                        fullName.indexOf(searchTerm) !== -1 ||
                        rxName.indexOf(searchTerm) !== -1 ||
                        rxNum.indexOf(searchTerm.toString()) !== -1
                    );
                });
            }
            window.scrollTo(0, 0);
        } else {
            filteredResult = refills.slice();
        }
        return filteredResult;
    };

    sortColumn = (data) => {
        const { sortRefills, sortData } = this.props;
        let order = 'asc';
        if (sortData[data.column]) {
            order = sortData[data.column] === 'desc' ? 'asc' : 'desc';
        }

        sortRefills({ order, column: data.column });
    };

    selectRefill = (refillData) => {
        this.setState({
            selectedRefill: refillData,
        });
    };

    render() {
        const { searchTerm, selectedRefill } = this.state;

        const {
            count,
            isRefillsLoading,
            isRefillsCountLoading,
            isLoadMoreRefillsLoading,
            isInternalUser,
            classes,
            refills: allRefills,
            sortData,
            refillsEnabled,
        } = this.props;

        const refills = this.getRefills();
        const isLoading = isRefillsLoading || isRefillsCountLoading;
        const noResult = allRefills.length > 0 && allRefills.length === count && refills.length === 0;

        return (
            <div className="refills-container">
                {!refillsEnabled || (!isLoading && allRefills.length === 0) ? (
                    <EmptyRefills refillsEnabled={refillsEnabled} />
                ) : (
                    <>
                        <div className="refills-header-container">
                            <div className="total-refill-text">
                                Digital Refills ({refills.length}/{count})
                            </div>

                            {!isLoading && (
                                <div className="refills-search-container">
                                    <Tooltip
                                        placement="bottom"
                                        enterTouchDelay={0}
                                        classes={{
                                            tooltip: 'info-tooltip',
                                        }}
                                        title={`You can search for Rx Number, Medication name
                                                        Patient name or date in the following format:
                                Date: MM/DD/YYYY, YYYY/MM/DD.
                                The search will be performed on the records shown.
                                To add more records to the screen select 'load more'.`}
                                    >
                                        <InfoIcon className="info-tooltip-icon" style={{ marginRight: '10px' }} />
                                    </Tooltip>
                                    <div className="refills-search-input">
                                        <SearchInput
                                            onSearch={this.onSearch}
                                            placeholder="Rx number, Medication name, patient name, date submitted"
                                            noResult={noResult}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="refills-header-buffer" />
                        </div>

                        <div className="refills-content-container">
                            <div className="refills-table-container">
                                <RefillTable
                                    data-component-name="RefillTable"
                                    refillRequests={refills}
                                    isInternalUser={isInternalUser}
                                    sortColumn={this.sortColumn}
                                    sorting={sortData}
                                    loading={isLoading}
                                    selectRefill={this.selectRefill}
                                    selectedRefill={selectedRefill}
                                    onLoadMore={() => this.onLoadMoreClick(allRefills)}
                                />
                            </div>
                            <div className="refill-details-container">
                                <RefillDetails refillData={selectedRefill} onClose={() => this.selectRefill(null)} />
                            </div>
                        </div>

                        {allRefills.length > 0 && allRefills.length < count && (
                            <div className="refill-actions-container">
                                <div className="centered-content button-control">
                                    {isLoadMoreRefillsLoading ? (
                                        <CircularProgress id="more-refills-loading" />
                                    ) : searchTerm ? (
                                        <Fab
                                            variant="extended"
                                            size="medium"
                                            aria-label="Load More"
                                            className={classNames(classes.margin, classes.cssRoot)}
                                            onClick={() => this.onLoadMoreClick(allRefills)}
                                        >
                                            Continue searching on server
                                        </Fab>
                                    ) : null}
                                </div>
                            </div>
                        )}

                        {allRefills.length === count && <div className="centered-content info-note">End of list</div>}
                    </>
                )}
                <NotificationDialog
                    dialogMsg="Never miss another Digital Refill with Digital Pharmacist desktop notifications."
                    dialogImage="./desktop_notification_refill_background.png"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.refills,
    selectedLocation: state.auth.selectedLocation.id,
    selectedPharmacy: state.auth.selectedCustomer.id,
    isInternalUser: state.auth.isInternalUser,
    refillsEnabled: state.pharmacy.refillsEnabled,
    pharmacy: state.pharmacy,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRefills: (data) => dispatch(fetchRefills(data)),
    fetchRefillsCount: (data) => dispatch(fetchRefillsCount(data)),
    sortRefills: (data) => dispatch(sortRefills(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(fabStyles)(Refill));
