import { getDefaultAppointmentHoursFromPharmacy } from 'components/Patients/scheduling/schedulingUtil';
import { getNextCalendarColor } from 'components/Appointments/AppointmentsPage';
import {
    updateCalendarReservationsFromResponse,
    createNewCalendar,
    resetCalendars,
    clearCalendarAppointmentCounts,
} from './Calendar/action';
import apptCalendarDataTypes from './Calendar/type';
import apptReservationDataTypes from './Reservation/type';
import Auth from 'redux/actions/Auth';
import _ from 'lodash';
import { adaptCalendarConfigurationToBaseCalendar } from 'components/Appointments/CreateOrEditAppointmentCalendarDialog';
import { resetReservations, updateLiveReusableReservationSessionsFromResponse } from './Reservation/action';

const UPDATEABLE_CALENDAR_RESERVATION_TYPES = new Set([
    apptReservationDataTypes.CHECKIN_RESERVATION_SUCCESS,
    apptReservationDataTypes.UPDATE_RESERVATION_SUCCESS,
]);

const appointmentMiddleware = (store) => (next) => (action) => {
    // update the list of calendar reservations by finding the one with the id of the response and replacing it (useful
    // after updating an existing entry so its new value is properly updated in the collection of all reservations)
    if (UPDATEABLE_CALENDAR_RESERVATION_TYPES.has(action.type)) {
        store.dispatch(updateCalendarReservationsFromResponse(action.payload.response, store.getState()));
        store.dispatch(clearCalendarAppointmentCounts());
    }

    // create a default calendar if none exist (this should only ever happen the very first time a user visits a calendar
    // reliant page since deleted calendars are still returned)
    if (action.type === apptCalendarDataTypes.EXISTING_CALENDARS_SUCCESS) {
        const allReturnedCalendars = _.get(action, 'payload.response.calendars');
        if (_.isArray(allReturnedCalendars) && _.isEmpty(allReturnedCalendars)) {
            const defaultNewCalendar = {
                ...adaptCalendarConfigurationToBaseCalendar(
                    getDefaultAppointmentHoursFromPharmacy(store.getState().pharmacy)
                ),
                color: getNextCalendarColor([]),
                name: 'Default Calendar',
            };
            store.dispatch(createNewCalendar(defaultNewCalendar));
        }
    }

    if (action.type === apptReservationDataTypes.CREATE_RESERVATION_SUCCESS) {
        store.dispatch(clearCalendarAppointmentCounts());
    }

    if (action.type === Auth.UPDATE_LOCATION) {
        store.dispatch(resetCalendars());
        store.dispatch(resetReservations());
    }

    if (
        action.type === apptReservationDataTypes.CREATE_RESERVATION_SESSION_SUCCESS ||
        action.type === apptReservationDataTypes.UPDATE_RESERVATION_SESSION_SUCCESS
    ) {
        store.dispatch(updateLiveReusableReservationSessionsFromResponse(action.payload.response));
    }

    next(action);
};
export default appointmentMiddleware;
