import PropTypes from 'prop-types';
import _ from 'lodash';

export const OPERATION_TYPE = {
    Insert: 'Insert',
    Update: 'Update',
    Delete: 'Delete',
};

export const ENTITY_TYPE = {
    users: 'users',
    grp: 'grp',
    grp_mbr: 'grp_mbr',
};

export const userPropType = PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            entityId: PropTypes.string.isRequired,
            parentId: PropTypes.string.isRequired,
            entityName: PropTypes.string.isRequired,
            entityType: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            inherited: PropTypes.bool.isRequired,
        })
    ).isRequired,
    lastActive: PropTypes.string,
});

export const logPropType = PropTypes.shape({
    entity_type: PropTypes.oneOf(_.values(ENTITY_TYPE)).isRequired,
    date: PropTypes.string.isRequired,
    operation: PropTypes.oneOf(_.values(OPERATION_TYPE)).isRequired,
    operator: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({}).isRequired, // Dict keys/values vary depending on table being logged
    previous_data: PropTypes.shape({}), // Dict might/might not exist depending on operation and table being logged
});

export function getUserName(user) {
    // User management selector fills in display value for invalid names as em dash
    // I'd probably prefer display value logic to be kept in components so as to avoid the following
    return user.name === '—' ? user.email : user.name;
}

function generateUsersLogMessage(userName, log) {
    let logMessage = null;

    switch (log.operation) {
        case OPERATION_TYPE.Insert:
            logMessage = `${userName}'s account was created`;
            break;
        case OPERATION_TYPE.Update:
            logMessage = `${userName}'s account was updated`;
            break;
        case OPERATION_TYPE.Delete:
            logMessage = `${userName}'s account was deleted`;
            break;
        default:
            // do nothing
            console.warn('Unknown operation for entity type users', log);
    }

    return logMessage;
}

function generateGroupMemberUpdateLogMessage(userName, log) {
    // Specific function to house logic for update operations which are slightly more involved
    // Determine which field was updated - currently either role_type or status_type
    const oldRole = _.get(log, 'previous_data.users_role_type', '');
    const newRole = _.get(log, 'data.users_role_type', '');
    const roleChange = newRole && newRole !== oldRole;
    if (roleChange) {
        return `${userName}'s access to  
            ${_.get(log, 'data.entity.name', 'invalid pharmacy name')} 
            was updated from 
            ${oldRole} 
            to
            ${newRole}`;
    }

    const oldStatus = _.get(log, 'previous_data.status_type', '');
    const newStatus = _.get(log, 'data.status_type', '');
    const statusChange = newStatus && newStatus !== oldStatus;
    if (statusChange) {
        return `${userName}'s 
            ${_.get(log, 'data.users_role_type', '')}
            access to  
            ${_.get(log, 'data.entity.name', 'invalid pharmacy name')} 
            was
            ${newStatus}`;
    }

    // No log message generated
    return null;
}

function generateGroupMemberLogMessage(userName, log) {
    let logMessage = null;

    switch (log.operation) {
        case OPERATION_TYPE.Insert:
            // “<user_name> was given <user_role_type> access to <pharmacy/location> by <modifier user_name>”
            logMessage = `${userName} was given 
                    ${_.get(log, 'data.users_role_type', '')} 
                    access to 
                    ${_.get(log, 'data.entity.name', 'invalid pharmacy name')}`;
            break;
        case OPERATION_TYPE.Update:
            // <user_name>’s access to <pharmacy/location> was updated from <old user_role_type> to <new user_role_type> by <modifier user_name>
            logMessage = generateGroupMemberUpdateLogMessage(userName, log);
            break;
        case OPERATION_TYPE.Delete:
            // <user_names>’s <user_role_type> access to <pharmacy/location> was revoked by  <modifier user_name>
            logMessage = `${userName}'s 
                    ${_.get(log, 'data.users_role_type', '')} 
                    access to  
                    ${_.get(log, 'data.entity.name', 'invalid pharmacy name')} 
                    was revoked`;
            break;
        default:
            // do nothing
            console.warn('Unknown operation for entity type grp_mbr', log);
    }

    return logMessage;
}

export function determineLogMessage(user, log) {
    let logMessage = null;
    const userName = getUserName(user);
    // Note: disabling use of operatorName due to issues with data on backend
    // const operatorName = _.get(log, 'operator.name', 'unknown');
    if (log.entity_type === ENTITY_TYPE.users) {
        logMessage = generateUsersLogMessage(userName, log);
    }

    if (log.entity_type === ENTITY_TYPE.grp_mbr) {
        logMessage = generateGroupMemberLogMessage(userName, log);
    }

    return logMessage;
}
