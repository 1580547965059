import { get, put, post } from '../../redux/actionCreators/rest';
import config from '../../config';
import { logHttpError } from '../../utils/log';
import api from '../../api.json';

const getPharmacy = (pharmacyId) => {
    return get(`${config.apiweb_url}/config/${pharmacyId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(`An error occured on getPharmacy for pharmacyId: ${pharmacyId}`, error);
            return Promise.reject(error);
        });
};

const createPackageAttribute = (pharmacyId, packageId, attributePayload) => {
    const requestUri = api.CREATE_PACKAGE_ATTRIBUTE.replace('{custID}', pharmacyId).replace('{packageId}', packageId);

    return post(`${config.config2_url}${requestUri}`, attributePayload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on createPackageAttribute for pharmacyId: ${pharmacyId}, packageId: ${packageId}, attribute name: ${attributePayload.name}`,
                error
            );
            return Promise.reject(error);
        });
};

const updatePackageAttribute = (pharmacyId, packageId, attributeId, attributePayload) => {
    const requestUri = api.UPDATE_PACKAGE_ATTRIBUTE.replace('{custID}', pharmacyId)
        .replace('{packageId}', packageId)
        .replace('{packageAttrId}', attributeId);

    return put(`${config.config2_url}${requestUri}`, attributePayload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on updatePackageAttribute for pharmacyId: ${pharmacyId}, packageId: ${packageId}, attributeId: ${attributeId}`,
                error
            );
            return Promise.reject(error);
        });
};

const createPackage = (pharmacyId, packageId, payload) => {
    const url = `${config.config2_url}/cust/${pharmacyId}/custPackage`;

    const data = { packageId: packageId, ...payload };

    return post(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on createPackage for pharmacyId: ${pharmacyId}, packageId: ${packageId}`,
                error
            );
            return Promise.reject(error);
        });
};

const updatePackage = (pharmacyId, custPackage) => {
    const url = `${config.config2_url}/cust/${pharmacyId}/custPackage/${custPackage.id}`;

    const data = {
        name: custPackage.name,
        promoted: custPackage.promoted,
        seq: custPackage.seq,
        statusId: custPackage.statusId,
    };

    return put(url, data)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on updatePackage for pharmacyId: ${pharmacyId}, packageId: ${custPackage.id}`,
                error
            );
            return Promise.reject(error);
        });
};

const upgradeToFullPep = (pharmacy_id, location_id, price_point, requester_email, ncpdp_id) => {
    return post(`${config.apiweb_url}/pharmacy-onboarding/enable-full-pep`, {
        pharmacy: { pharmacy_id, location_id },
        price_point,
        requester_email,
        ncpdp_id,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred upgrading to full PEP for pharmacyId: ${pharmacy_id}, locationId: ${location_id}, pricePoint: ${price_point}`,
                error
            );
            return Promise.reject(error);
        });
};

window.upgradeToFullPep = upgradeToFullPep;

export const pharmacyService = {
    getPharmacy,
    createPackageAttribute,
    updatePackageAttribute,
    createPackage,
    updatePackage,
    upgradeToFullPep,
};

export default pharmacyService;
