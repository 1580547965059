import React, { useState } from 'react';
import { Cell, DialogContainer, Checkbox, Button } from 'react-md';

export const ConfirmDownload = ({ onHide, isVisible, actions, setDnd, onConfirm }) => {
    const [dndCheck, setDndCheck] = useState(false);

    const toggleDndCheck = (check) => {
        setDndCheck(check);
    };

    const submitForm = () => {
        if (dndCheck) {
            setDnd(dndCheck);
        }
        onConfirm();
    };

    const closeDialog = () => {
        setDndCheck(false);
        onHide();
    };

    return (
        <DialogContainer
            id="download-vm-confirm"
            visible={isVisible}
            onHide={onHide}
            actions={actions}
            width={495}
            title="Download Voicemail"
        >
            <div className="grid-container">
                <Cell size={12}>
                    This voicemail file may contain sensitive patient information including PII & PHI. Please make sure
                    to protect any confidential information contained in this file and avoid downloading to insecure
                    systems.
                </Cell>
                <Cell size={12} style={{ marginTop: '30px' }} className="vm-download-dnd-chkbox">
                    <Checkbox
                        id="checkbox-dnd-download-voicemail"
                        name="download-voicemail"
                        label="Don't show me this warning again"
                        checked={dndCheck}
                        onChange={toggleDndCheck}
                    />
                </Cell>
                <Cell size={12} className="flex-right">
                    <Button data-close-btn flat primary onClick={closeDialog}>
                        CANCEL
                    </Button>
                    <Button data-submit-btn raised secondary onClick={submitForm}>
                        OKAY
                    </Button>
                </Cell>
            </div>
        </DialogContainer>
    );
};
