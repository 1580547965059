import React, { Component } from 'react';
import { Card, CardText } from 'react-md';
import _ from 'lodash';

import TabularData from 'components/Common/TabularData';
import EmptyCard from './EmptyCard';
import { mapTabularDataWithObject, sortByKeys, TIME_FORMAT, formatTime } from './util.js';
import ReportTitle from 'components/Report/Common/ReportTitle';
import TabularTypePlaceholder from 'components/Common/TabularTypePlaceholder';

const ATTRIBUTES = ['Visitors', 'Page views per visitor'];

const TITLES = ['7 Days', '30 Days', 'All Time'];

const SORTED_KEYS = ['visitors', 'pageViewsPerVisitor', 'secondsPerVisitor'];

const DataCard = ({ data, showLoader, tooltipText }) => (
    <Card className="reportCard">
        <ReportTitle title="Healthsite Traffic" className="cardTitle" tooltipText={tooltipText} />
        <CardText className={showLoader ? 'flex-grow-1 flex-middle' : 'text-align-center flex-grow-1'}>
            {!showLoader ? (
                <TabularData data={data} customAttributes={true} />
            ) : (
                <TabularTypePlaceholder legendsCount={3} />
            )}
        </CardText>
    </Card>
);

export default class WebsiteTraffic extends Component {
    state = {
        mapData: {},
    };

    init() {
        const { data } = this.props;
        const attributes = [];
        // isLoading is one of the keys now, the entire logic of validation
        // and data access for Reports needs to be revisited and simplified
        if (_.keys(data).length > 1) {
            const responseData = data.data.data; // TODO Refactor this to better keynames
            responseData.forEach((d, i) => {
                responseData[i] = sortByKeys(d, SORTED_KEYS);
                const secondsPerVisitor = d.secondsPerVisitor || 0;
                const formattedTime = formatTime(secondsPerVisitor);

                responseData[i].secondsPerVisitor = formattedTime.value;

                switch (formattedTime.type) {
                    case TIME_FORMAT.MINUTES:
                        attributes[i] = ATTRIBUTES.concat(['Minutes per visitor']);
                        break;
                    case TIME_FORMAT.HOURS:
                        attributes[i] = ATTRIBUTES.concat(['Hours per visitor']);
                        break;
                    case TIME_FORMAT.DAYS:
                        attributes[i] = ATTRIBUTES.concat(['Days per visitor']);
                        break;
                    default:
                        attributes[i] = ATTRIBUTES.concat(['Seconds per visitor']);
                }
            });
            const mapData = mapTabularDataWithObject(data, attributes, TITLES);
            this.setState({ mapData });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ data: oldData }) {
        const { data } = this.props;
        // TODO: This logic needs to be corrected. Also, the component can be refactored to rely on props and selectors
        if (_.keys(data).length > 1 && !_.isEqual(data, oldData)) {
            this.init();
        }
    }

    render() {
        const { data, enabled, tooltipText } = this.props;
        const { mapData } = this.state;
        const showLoader = Object.keys(data).length === 0 || Object.keys(mapData).length === 0;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Healthsite Traffic"
                    imgPath="/reports/website_traffic.png"
                    helperText="You don’t have a Healthsite yet."
                    appType="websiteTraffic"
                    tooltipText={tooltipText}
                />
            );
        }
        return <DataCard data={mapData} showLoader={showLoader} tooltipText={tooltipText} />;
    }
}
