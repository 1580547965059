import { templateType } from './type';
import { templateService } from './service';
import config from '../../../config';
import { onboardingActions } from '../Onboarding';
import Snowplow, { RxConferWorkflowContext, StructEventCategories } from '../../../snowplow';

const activateTemplate = (templateData) => {
    return function (dispatch, getState) {
        const templateState = getState().inboxConversationTemplate;
        const { templates } = templateState;

        // Change status immediately for local state
        templates.forEach((item) => {
            if (item.inbox_conversation_template_id === templateData.inbox_conversation_template_id) {
                item.status = 'active';
            }
        });
        dispatch(request(templates));

        Snowplow.structEvent(StructEventCategories.workflows, 'activate-workflow-template', [
            new RxConferWorkflowContext().setWorkflowTemplateId(templateData.inbox_conversation_template_id).build(),
        ]);

        templateService.activateTemplate(templateData).then(
            () => {
                dispatch(success());
                dispatch(getTemplates());
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(templates) {
        return { type: templateType.ACTIVATE_TEMPLATE_REQUEST, templates };
    }
    function success() {
        return { type: templateType.ACTIVATE_TEMPLATE_SUCCESS };
    }
    function failure(error) {
        return { type: templateType.ACTIVATE_TEMPLATE_FAILURE, error };
    }
};

const deactivateTemplate = (templateData) => {
    return function (dispatch, getState) {
        const templateState = getState().inboxConversationTemplate;
        const { templates } = templateState;

        // Change status immediately for local state
        templates.forEach((item) => {
            if (item.inbox_conversation_template_id === templateData.inbox_conversation_template_id) {
                item.status = 'inactive';
            }
        });
        dispatch(request(templates));

        Snowplow.structEvent(StructEventCategories.workflows, 'deactivate-workflow-template', [
            new RxConferWorkflowContext().setWorkflowTemplateId(templateData.inbox_conversation_template_id).build(),
        ]);

        templateService.deactivateTemplate(templateData).then(
            () => {
                dispatch(success());
                dispatch(getTemplates());
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(templates) {
        return { type: templateType.DEACTIVATE_TEMPLATE_REQUEST, templates };
    }
    function success() {
        return { type: templateType.DEACTIVATE_TEMPLATE_SUCCESS };
    }
    function failure(error) {
        return { type: templateType.DEACTIVATE_TEMPLATE_FAILURE, error };
    }
};

const deleteTemplate = (templateData) => {
    return function (dispatch, getState) {
        const templateState = getState().inboxConversationTemplate;
        const { templates } = templateState;

        // Change status immediately for local state
        templates.forEach((item) => {
            if (item.inbox_conversation_template_id === templateData.inbox_conversation_template_id) {
                item.status = 'deleted';
            }
        });
        dispatch(request(templates));

        Snowplow.structEvent(StructEventCategories.workflows, 'delete-workflow-template', [
            new RxConferWorkflowContext().setWorkflowTemplateId(templateData.inbox_conversation_template_id).build(),
        ]);

        templateService.deleteTemplate(templateData).then(
            () => {
                dispatch(success());
                dispatch(getTemplates());
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function request(templates) {
        return { type: templateType.DELETE_TEMPLATE_REQUEST, templates };
    }
    function success() {
        return { type: templateType.DELETE_TEMPLATE_SUCCESS };
    }
    function failure(error) {
        return { type: templateType.DELETE_TEMPLATE_FAILURE, error };
    }
};

const getTemplates = () => {
    const pharmacyId = config.X_PharmacyID;
    const locationId = config.X_LocationID;
    return function (dispatch) {
        dispatch(request());

        templateService.getTemplates(pharmacyId, locationId).then(
            (templates) => {
                dispatch(success(templates));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: templateType.GET_TEMPLATES_REQUEST };
    }
    function success(templates) {
        return { type: templateType.GET_TEMPLATES_SUCCESS, templates };
    }
    function failure(error) {
        return { type: templateType.GET_TEMPLATES_FAILURE, error };
    }
};

const createTemplate = (templateData) => {
    return function (dispatch) {
        dispatch(request());

        Snowplow.structEvent(StructEventCategories.workflows, 'create-workflow-template');

        return templateService.createTemplate(templateData).then(
            () => {
                dispatch(success());
                dispatch(getTemplates());
                dispatch(onboardingActions.getInboxLocationOnboarding());
                return true;
            },
            (error) => {
                dispatch(failure(error));
                return false;
            }
        );
    };

    function request() {
        return { type: templateType.CREATE_TEMPLATE_REQUEST };
    }
    function success() {
        return { type: templateType.CREATE_TEMPLATE_SUCCESS };
    }
    function failure(error) {
        return { type: templateType.CREATE_TEMPLATE_FAILURE, error };
    }
};

const updateTemplate = (templateData) => {
    return function (dispatch) {
        dispatch(request());

        Snowplow.structEvent(StructEventCategories.workflows, 'update-workflow-template', [
            new RxConferWorkflowContext().setWorkflowTemplateId(templateData.inbox_conversation_template_id).build(),
        ]);

        return templateService.updateTemplate(templateData).then(
            () => {
                dispatch(success());
                dispatch(getTemplates());
                return true;
            },
            (error) => {
                dispatch(failure(error));
                return false;
            }
        );
    };

    function request() {
        return { type: templateType.UPDATE_TEMPLATE_REQUEST, templateData };
    }
    function success() {
        return { type: templateType.UPDATE_TEMPLATE_SUCCESS };
    }
    function failure(error) {
        return { type: templateType.UPDATE_TEMPLATE_FAILURE, error };
    }
};

export const templateAction = {
    activateTemplate,
    deactivateTemplate,
    getTemplates,
    createTemplate,
    updateTemplate,
    deleteTemplate,
};

export default templateAction;
