import './RemindersConfiguration.sass';
import React, { useState } from 'react';
import _ from 'lodash';
import MessageCustomizeAccordion from './MessageCustomizeAccordion';

export default function RemindersConfiguration({ configuration, onChange }) {
    const [expandedPanel, setExpandedPanel] = useState('twenty_four_hour_reminder');

    return (
        <div className="reminders-configuration">
            <MessageCustomizeAccordion
                title="24 Hour Before Reminder"
                value={_.get(configuration, 'day_reminder_text')}
                expanded={expandedPanel === 'twenty_four_hour_reminder'}
                onExpandChange={() => setExpandedPanel('twenty_four_hour_reminder')}
                onChange={(day_reminder_text) => onChange({ ...configuration, day_reminder_text })}
            />

            <MessageCustomizeAccordion
                title="1 Hour Before Check-in + Reminder"
                value={_.get(configuration, 'hour_reminder_text')}
                expanded={expandedPanel === 'one_hour_reminder'}
                onExpandChange={() => setExpandedPanel('one_hour_reminder')}
                onChange={(hour_reminder_text) => onChange({ ...configuration, hour_reminder_text })}
            />

            <MessageCustomizeAccordion
                title="Check-in Response Message"
                value={_.get(configuration, 'checkin_response_text')}
                expanded={expandedPanel === 'checkin_response'}
                onExpandChange={() => setExpandedPanel('checkin_response')}
                onChange={(checkin_response_text) => onChange({ ...configuration, checkin_response_text })}
            />
        </div>
    );
}
