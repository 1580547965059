import React, { Fragment, useState, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';
import { AbilityContext, userActions } from 'casl';

import LinearProgressWithSteps from './LinearProgressWithSteps';

OnboardingOverviewCard.propTypes = {
    completedCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    nextStepDisplay: PropTypes.string,
    onStepActionClick: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['count', 'action']).isRequired,
            display: PropTypes.string.isRequired,
            complete: PropTypes.bool.isRequired,
            action: PropTypes.shape({
                display: PropTypes.string.isRequired,
            }),
            count: PropTypes.shape({
                completed: PropTypes.number,
                total: PropTypes.number,
            }),
            requiresEntityAccess: PropTypes.string,
        })
    ),
};

OnboardingOverviewCard.defaultProps = {
    nextStepDisplay: null,
    steps: [],
};

const useStyles = makeStyles({
    overviewHeaderContainer: {
        padding: '24px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    overviewHeaderLinearProgressContainer: {
        width: '390px',
    },

    actionButton: {
        width: '100%',
        marginTop: '8px',
    },

    expandIcon: {
        paddingBottom: '2px',
    },

    stepContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '24px',
    },

    stepContainerCompleted: {
        backgroundColor: '#f7f7f7',
    },

    stepPrimaryContent: {
        display: 'flex',
        flex: 1,
    },

    stepIcon: {
        marginRight: '8px',
    },

    stepLinearProgressContainer: {
        width: '274px',
        marginLeft: '10px',
        marginTop: '-3px',
    },
});

function WrapBreak() {
    return <div style={{ flexBasis: '100%', height: 0 }} />;
}

function OnboardingOverviewCard(props) {
    const { completedCount, totalCount, nextStepDisplay, onStepActionClick, steps } = props;
    const classes = useStyles();
    const mobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [hideComplete, setShowComplete] = useState(false);
    const ability = useContext(AbilityContext);

    const toggleShowComplete = () => {
        setShowComplete((previousValue) => {
            return !previousValue;
        });
    };

    const buttonStyle = mobileScreen ? classes.actionButton : '';

    const overviewHeader = (
        <div className={classes.overviewHeaderContainer}>
            <div className={classes.overviewHeaderLinearProgressContainer}>
                <LinearProgressWithSteps
                    completedCount={completedCount}
                    totalCount={totalCount}
                    label={nextStepDisplay ? `Up next : ${nextStepDisplay}` : 'Onboarding Completed'}
                />
            </div>
            <Button
                data-testid="hide-completed-button"
                className={buttonStyle}
                style={{ color: '#808080' }}
                onClick={toggleShowComplete}
                endIcon={
                    hideComplete ? (
                        <ExpandMoreIcon className={classes.expandIcon} />
                    ) : (
                        <ExpandLessIcon className={classes.expandIcon} />
                    )
                }
            >
                {hideComplete ? 'Show Completed' : 'Hide Completed'}
            </Button>
        </div>
    );

    return (
        <Paper style={{ maxWidth: '800px' }} variant="outlined">
            {overviewHeader}
            {_.map(steps, ({ key, type, display, complete, action = {}, count = {}, requiresEntityAccess }) => {
                if (hideComplete && complete) {
                    return null;
                }

                // Notes:
                // * defaults to true if no required entity is defined
                // * currently only uses manage action but can be altered if more granularity is needed
                const hasEntityAccess = requiresEntityAccess
                    ? ability.can(userActions.manage, requiresEntityAccess)
                    : true;

                const stepIcon = complete ? (
                    <CheckCircleIcon color="primary" className={classes.stepIcon} />
                ) : (
                    <RadioButtonUncheckedIcon color="primary" className={classes.stepIcon} />
                );

                let stepContent;
                if (type === 'count') {
                    stepContent = (
                        <Fragment>
                            <div className={classes.stepPrimaryContent}>
                                {stepIcon}
                                <Typography>{display}</Typography>
                            </div>
                            <WrapBreak />
                            <div className={classes.stepLinearProgressContainer}>
                                <LinearProgressWithSteps
                                    size="sm"
                                    completedCount={count.completed}
                                    totalCount={count.total}
                                />
                            </div>
                        </Fragment>
                    );
                } else {
                    stepContent = (
                        <Fragment>
                            <div className={classes.stepPrimaryContent}>
                                {stepIcon}
                                <Typography>{display}</Typography>
                            </div>
                            {mobileScreen && <WrapBreak />}
                            {action.display && !complete && (
                                <Button
                                    data-testid={`action-button-${key}`}
                                    variant="outlined"
                                    color="primary"
                                    className={buttonStyle}
                                    disabled={!hasEntityAccess}
                                    onClick={() => {
                                        onStepActionClick({ key });
                                    }}
                                >
                                    {action.display}
                                </Button>
                            )}
                        </Fragment>
                    );
                }

                return (
                    <Fragment key={key}>
                        <Divider />
                        <div
                            data-testid={`step-container-${key}`}
                            className={`${classes.stepContainer} ${complete ? classes.stepContainerCompleted : ''}`}
                        >
                            {stepContent}
                        </div>
                    </Fragment>
                );
            })}
        </Paper>
    );
}

export default OnboardingOverviewCard;
