import React from 'react';
import _ from 'lodash';
import FilteringOptionGroup from './FilteringOptionGroup';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import numeral from 'numeral';
import { Tooltip } from '@material-ui/core';
import pluralize from 'pluralize';

export default function WaitlistNamePopover({ value, onChange, waitlistFilterOptions }) {
    return (
        <div className="filter-popover waitlist-status-popover">
            <FilteringOptionGroup
                value={value}
                onChange={onChange}
                options={_.map(waitlistFilterOptions, ({ waitlist_id, waitlist_name, patient_count }) => {
                    return {
                        value: waitlist_id,
                        icon: <AssignmentIndIcon />,
                        label: (
                            <div className="waitlist-name-and-count">
                                <span className="name">{waitlist_name}</span>
                                <Tooltip
                                    title={`${numeral(patient_count).format('0,0')} ${pluralize(
                                        'patient',
                                        patient_count
                                    )} on this waitlist`}
                                >
                                    <span className="count">{numeral(patient_count).format('0,0')}</span>
                                </Tooltip>
                            </div>
                        ),
                    };
                })}
            />
        </div>
    );
}
