const typePrefix = 'patient/connect/';

const connectTypes = {
    CONNECT_OPPORTUNITIES_REQUEST: `${typePrefix}CONNECT_OPPORTUNITES_REQUEST`,
    CONNECT_OPPORTUNITIES_SUCCESS: `${typePrefix}CONNECT_OPPORTUNITES_SUCCESS`,
    CONNECT_OPPORTUNITIES_FAILURE: `${typePrefix}CONNECT_OPPORTUNITES_FAILURE`,

    CLEAR_OPPORTUNITIES: `${typePrefix}CLEAR_OPPORTUNITIES`,

    SEND_BULK_MESSAGE_REQUEST: `${typePrefix}SEND_BULK_MESSAGE_REQUEST`,
    SEND_BULK_MESSAGE_SUCCESS: `${typePrefix}SEND_BULK_MESSAGE_SUCCESS`,
    SEND_BULK_MESSAGE_FAILURE: `${typePrefix}SEND_BULK_MESSAGE_FAILURE`,
    SET_BULK_MESSAGE_STATUS_SHOWN: `${typePrefix}SET_BULK_MESSAGE_STATUS_SHOWN`,
};

export default connectTypes;
