export default {
    ADD_PATIENT_GREETING: 'ADD_PATIENT_GREETING',
    UPDATE_PATIENT_GREETING: 'UPDATE_PATIENT_GREETING',
    CLOSE_PATIENT_GREETING_EDITOR: 'CLOSE_PATIENT_GREETING_EDITOR',
    SAVE_PATIENT_GREETING_STARTED: 'SAVE_PATIENT_GREETING_STARTED',
    SAVE_PATIENT_GREETING_SUCCESS: 'SAVE_PATIENT_GREETING_SUCCESS',
    SAVE_PATIENT_GREETING_FAILED: 'SAVE_PATIENT_GREETING_FAILED',
    EDIT_PATIENT_GREETING: 'EDIT_PATIENT_GREETING',
    CHANGE_PATIENT_GREETING_TYPE: 'CHANGE_PATIENT_GREETING_TYPE',
    CHANGE_PATIENT_GREETING_SCRIPT: 'CHANGE_PATIENT_GREETING_SCRIPT',
};
