import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, Tooltip } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import _ from 'lodash';

import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { validateEmail } from 'utils/helper';
import { primaryRoles, userRoles as userRoleType } from 'casl/UserRoles';
import Config2Constants from 'constants/Config2';
import Snowplow, { UserManagementContext } from '../../../../snowplow';

const Dialog = withStyles({
    paper: {
        width: '600px',
    },
    paperWidthMd: {
        width: '600px !important',
    },
})(DpDialog.Dialog);

const UserDialog = (props) => {
    const commonProps = {
        fullWidth: true,
        variant: 'outlined',
        margin: 'normal',
        required: true,
    };
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [roleMap, setRoleMap] = useState({});
    const [allLocationsNoAccess, setAllLocationsNoAccess] = useState(false);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    const {
        showUserDialog = false,
        closeDialog,
        type,
        userToEdit,
        userRoles,
        onSubmit,
        addOrEditUserLoading,
        filteredLocationMap,
    } = props;
    let isInvite = type === 'add';
    const noAccessRoleId = getRoleId(userRoleType.noAccess);

    useEffect(() => {
        resetState();
        setFilteredLocationList(_.values(filteredLocationMap));

        if (showUserDialog && type === 'add') {
            // New user - set all location roles to No Access by default
            const roleMap = {};
            _.forEach(filteredLocationMap, (locationDetails) => {
                roleMap[locationDetails.id] = noAccessRoleId;
            });
            setRoleMap({ ...roleMap });
        } else if (showUserDialog && type === 'edit') {
            const { first_name, last_name, email, roles } = userToEdit;
            setFirstName(first_name);
            setLastName(last_name);
            setEmail(email);

            // Edit user - find all existing location roles
            // Fill in non-existent location roles with No Access
            const selectedUserLocationRoleMap = {};
            _.forEach(roles, (locationRole) => {
                if (locationRole.entityType === Config2Constants.entityType.location) {
                    selectedUserLocationRoleMap[locationRole.entityId] = locationRole;
                }
            });

            const roleMap = {};
            _.forEach(filteredLocationMap, (locationDetails) => {
                if (locationDetails.id in selectedUserLocationRoleMap) {
                    const selectedUserLocationRole = selectedUserLocationRoleMap[locationDetails.id];
                    roleMap[locationDetails.id] = getRoleId(selectedUserLocationRole.role);
                } else {
                    roleMap[locationDetails.id] = noAccessRoleId;
                }
            });
            setRoleMap({ ...roleMap });
        }
    }, [showUserDialog]);

    useEffect(() => {
        if (type === 'edit') {
            const { user_api_user_id } = userToEdit;
            Snowplow.pageView('User Management - Edit User', [
                new UserManagementContext().setUserId(user_api_user_id).build(),
            ]);
        } else {
            Snowplow.pageView('User Management - Add User');
        }
    }, []);

    function resetState() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setAllLocationsNoAccess(false);
    }

    function scrollTop() {
        const dialogEl = document.getElementById('userDialogContent');
        dialogEl.scrollTo(0, 0);
    }

    function getRoleId(roleType) {
        const filteredRole = _.find(userRoles, (role) => role.role === roleType);
        return filteredRole ? filteredRole.id : null;
    }

    const handleRoleChange = (locId, e) => {
        const roleType = e.target.value;
        const updatedRoleMap = {
            ...roleMap,
            [locId]: roleType,
        };

        if (isInvite) {
            const noAccessCheck = _.every(updatedRoleMap, (value) => value === noAccessRoleId);
            setAllLocationsNoAccess(noAccessCheck);
        }
        setRoleMap(updatedRoleMap);
    };

    const checkIfDirty = () => {
        if (firstName && lastName && validateEmail(email) && !addOrEditUserLoading) {
            return false;
        }
        return true;
    };

    const onSave = () => {
        const base_payload = {
            first_name: firstName,
            last_name: lastName,
            email,
        };

        if (isInvite) {
            // Strip any NoAccess roles
            const filteredRoles = [];
            _.forEach(roleMap, (value, key) => {
                if (value !== noAccessRoleId) {
                    filteredRoles.push({
                        entityId: key,
                        usersRoleTypeId: value,
                    });
                }
            });

            // If no roles are leftover after filter then all roles must be NoAccess still
            if (filteredRoles.length === 0) {
                setAllLocationsNoAccess(true);
                scrollTop();
                return;
            }

            // Included only roles other than NoAccess in initial user creation
            const payload = {
                ...base_payload,
                roles: filteredRoles,
            };
            onSubmit(payload);
        } else {
            const { user_api_user_id, roles: originalUserRoles } = userToEdit;

            const originalRoleMap = {};
            _.forEach(originalUserRoles, (loc) => {
                originalRoleMap[loc.entityId] = getRoleId(loc.role);
            });

            const updatedRoles = [];
            _.forEach(roleMap, (value, key) => {
                if (key in originalRoleMap) {
                    // Ignore any unchanged roles
                    if (value !== originalRoleMap[key]) {
                        updatedRoles.push({
                            entityId: key,
                            usersRoleTypeId: value,
                        });
                    }
                } else if (value !== noAccessRoleId) {
                    // Only add new roles if they aren't No Access
                    updatedRoles.push({
                        entityId: key,
                        usersRoleTypeId: value,
                    });
                }
            });

            const payload = {
                ...base_payload,
                roles: updatedRoles,
                user_id: user_api_user_id,
            };
            onSubmit(payload);
        }
    };

    return (
        <Dialog id="userDialog" open={showUserDialog} maxWidth="sm" className="user-dialog" onClose={closeDialog}>
            <DpDialog.Content id="userDialogContent" dividers>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <DpDialog.ContentHeader
                            heading={isInvite ? 'Invite a new User' : 'Edit User'}
                            onClose={closeDialog}
                        >
                            <span style={{ fontSize: '12px' }}>
                                {isInvite ? 'Invite a new team member to access your pharmacy dashboard' : ''}
                            </span>
                            {allLocationsNoAccess && (
                                <Alert variant="outlined" severity="error" style={{ marginTop: '10px' }}>
                                    You must give this user access to at least one location to create the account
                                </Alert>
                            )}
                        </DpDialog.ContentHeader>
                    </Grid>
                    <form autoComplete="off" className="full-width">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autoFocus
                                    {...commonProps}
                                    style={{ marginTop: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    {...commonProps}
                                    style={{ marginTop: 0 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="email"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail((e.target.value || '').toLowerCase())}
                                    {...commonProps}
                                    style={{ marginTop: 0 }}
                                    disabled={!isInvite}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Accordion className="expansion-panel" defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className="header">Location details</Typography>
                            <Typography className="sub-header">{filteredLocationList.length} locations</Typography>
                            <Typography className="info-header">
                                <UserInfoTooltip />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                            {filteredLocationList.length > 0 &&
                                filteredLocationList.map((location) => (
                                    <Grid container key={location.id} className="role-item">
                                        <Grid item xs={12} sm={8} className="location-name overflow-elipsis">
                                            {location.name}
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl variant="outlined" className="role-select">
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    id="role-select-outlined"
                                                    name="role"
                                                    fullWidth
                                                    value={roleMap[location.id]}
                                                    onChange={(e) => handleRoleChange(location.id, e)}
                                                    input={
                                                        <OutlinedInput
                                                            fullWidth
                                                            labelWidth={25}
                                                            name="role"
                                                            id={`outlined-role-${location.id}`}
                                                        />
                                                    }
                                                >
                                                    {userRoles.map((role) => {
                                                        const isPrimaryRole = _.includes(primaryRoles, role.role);
                                                        if (roleMap[location.id] !== role.id && !isPrimaryRole) {
                                                            // Don't show non-primary role if it isn't the current value
                                                            return null;
                                                        }
                                                        return (
                                                            <MenuItem
                                                                key={role.id}
                                                                value={role.id}
                                                                disabled={!isPrimaryRole}
                                                            >
                                                                {role.role}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </DpDialog.Content>
            <DpDialog.Actions style={{ padding: '15px' }}>
                <Button onClick={closeDialog} color="default">
                    Cancel
                </Button>
                <div className="save-btn-container">
                    <Button onClick={onSave} color="primary" variant="contained" disabled={checkIfDirty()}>
                        {isInvite ? 'Send Invite' : 'Save'}
                    </Button>
                    {addOrEditUserLoading && <CircularProgress size={24} className="progress" />}
                </div>
            </DpDialog.Actions>
        </Dialog>
    );
};

const UserInfoTooltip = () => (
    <Tooltip
        interactive
        placement="bottom-start"
        title={
            <div className="user-roles-info-tooltip">
                <div className="role-title">Owner</div>
                <div className="role-info">
                    Full access to all aspects of the dashboard. Can manage user access and view PHI.
                </div>

                <div className="role-title">Manager</div>
                <div className="role-info">
                    {' '}
                    Can manage all aspects of the dashboard with the following exceptions: cannot manage users and
                    cannot download voicemails. Can view PHI.
                </div>

                <div className="role-title">Staff</div>
                <div className="role-info">
                    Can send and read 2-way messages, change greetings, and view and listen to voicemails. Can also view
                    the refill and reports pages. Cannot manage users, change items such as IVR menu options, pharmacy
                    hours, or mark voicemails as read. Can view PHI.
                </div>

                <div className="role-title">No Access</div>
                <div className="role-info">
                    This user will not have access to any information for a specified location.
                </div>
            </div>
        }
    >
        <span className="flex-middle">
            <HelpOutlineOutlinedIcon />
            Learn about user roles
        </span>
    </Tooltip>
);

export default UserDialog;
