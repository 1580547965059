import React, { Component } from 'react';
import { Grid, Cell, Button, CircularProgress } from 'react-md';
import { TextField, FormHelperText } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { sendResetLink } from 'redux/actionCreators/Auth';

export class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            emailErr: '',
        };
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        return re.test(String(email).toLowerCase());
    };

    resetPassword = (e) => {
        e.preventDefault();

        const { username } = this.state;
        const { sendResetLink, history } = this.props;
        let emailErr = '';

        if (!username) {
            emailErr = 'Provide username';
        } else if (!this.validateEmail(username)) {
            emailErr = 'Provide a valid email id';
        }

        if (emailErr) {
            this.setState({
                emailErr,
            });
        } else {
            sendResetLink({ user_type: 'pharmacist', email: username }).then((resp) => {
                if ('data' in resp) {
                    history.push('/');
                }
            });
        }
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            emailErr: '',
        });
    };

    render() {
        const { username, emailErr } = this.state;
        const { updatePwdInprogress } = this.props;

        return (
            <Grid>
                <Cell size={12}>
                    <div className="login-form-container centered-content">
                        {updatePwdInprogress && (
                            <div className="full-width progress-container">
                                <CircularProgress id="reset-password-loader" scale={2} />
                            </div>
                        )}
                        <div className="form-container">
                            <img style={{ marginBottom: '20px' }} src="/digital-pharmacist-larger.png" alt="" />
                            <div className="header-text">Enter your email to reset your password.</div>

                            <form className="login-form" noValidate autoComplete="off" onSubmit={this.resetPassword}>
                                <div className="form-control">
                                    <TextField
                                        name="username"
                                        data-cy="email"
                                        label="Email Address"
                                        value={username}
                                        onChange={this.handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <FormHelperText error>{emailErr}</FormHelperText>
                                </div>
                                <div className="button-control resest-pwd-control" style={{ marginTop: '58px' }}>
                                    <Link to="/">Back to login</Link>
                                    <Button data-cy="submit-button" type="submit" raised primary>
                                        CONTINUE
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    updatePwdInprogress: state.auth.updatePwdInprogress,
});

const mapDispatchToProps = (dispatch) => ({
    sendResetLink: (data) => dispatch(sendResetLink(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
