export default {
    digitalPlatform:
        'The Digital Platform Refills widget shows a count of all refill requests that have been processed through your website and mobile app. Data is shown per location.',
    valueCalculator:
        "The Value Calculator helps crunch some numbers to show how much you're getting out of your Digital Pharmacist products. Data is shown per pharmacy.",
    communicationsPlatform:
        'The Interactive Voice Response (IVR) widget shows a count of all refills that have been processed through your IVR. Data is shown per location.',
    mobileDownloads:
        'The Mobile Downloads widget shows a count of all mobile app downloads on both iOS and Android platforms. Data is shown per pharmacy.',
    websiteTraffic:
        'The Healthsite Traffic widget shows a detailed view of how people are interacting with your website. Data is shown per pharmacy.',
    newsletter:
        'The eNewsletter widget shows details about your pharmacy newsletter and how subscribers are interacting with it. Data is shown per pharmacy.',
    digitalPlatformPatients:
        'The Digital Platform Patients widget shows a count of active users and medication transfers by patients. Data is shown per pharmacy.',
    digitalMarketing:
        'The Digital Marketing widget provides some insight on how people are engaging with your ads. See more on your Digital Marketing dashboard. Data is shown per pharmacy.',
    reputationManagement:
        'The Reputation Management widget shows how many reviews your customers are leaving. Data is shown per pharmacy.',
    directMessaging:
        'The Direct Messaging widget shows metrics on how you and your patients are using the Digital Pharmacist Inbox. Data is shown per location.',
};
