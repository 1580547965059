import './ResolveDuplicatePatientMatches.sass';
import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { Button, Checkbox, TextField } from '@material-ui/core';
import { Table, Column } from 'react-virtualized';
import PatientName from 'components/Patients/PatientName';
import { formatRawPhone } from '../../../../utils/helper';
import classnames from 'classnames';

const NON_MATCHES_ROW_HEIGHT = 142;

export default function ResolveDuplicatePatientMatches({
    multipleSearchMatches,
    doPatientSelection,
    searchablePatients,
    formatDate,
    newlySelectedPatients,
    onClose,
    onDone,
}) {
    const [multiSelectsToAdd, setMultiSelectsToAdd] = useState(
        _.keyBy(_.flatten(_.values(multipleSearchMatches)), ({ inbox_user_id }) => inbox_user_id)
    );

    const calcRowHeight = (matches) => NON_MATCHES_ROW_HEIGHT + _.size(matches) * 40;

    const { sortedKeys, totalRowsHeight } = useMemo(() => {
        return {
            sortedKeys: _.sortBy(_.keys(multipleSearchMatches)),
            totalRowsHeight: _.reduce(multipleSearchMatches, (sum, matches) => sum + calcRowHeight(matches), 0),
        };
    }, [multipleSearchMatches]);

    return (
        <div className="resolve-duplicate-patient-matches">
            <div className="options-wrapper">
                <div className="resolve-sub-heading">
                    {_.size(newlySelectedPatients) > 0 ? (
                        <p>
                            {numeral(_.size(newlySelectedPatients)).format('0,0')}{' '}
                            {pluralize('patient', _.size(newlySelectedPatients))}{' '}
                            {pluralize('has', _.size(newlySelectedPatients))} been selected from this file, but while
                            checking the files records against your patients, we discovered the following records
                            matched more than one patient.
                        </p>
                    ) : (
                        <p>
                            While checking the files records against your patients, we discovered the following records
                            matched more than one patient.
                        </p>
                    )}
                    <p>
                        Review the following multi-patient matches and check/uncheck the ones you would like to select:
                    </p>
                </div>
                <Table
                    width={650}
                    height={Math.min(totalRowsHeight, 450)}
                    rowHeight={({ index }) => calcRowHeight(multipleSearchMatches[sortedKeys[index]])}
                    headerHeight={0}
                    rowCount={_.size(multipleSearchMatches)}
                    rowGetter={({ index }) => {
                        return {
                            matches: multipleSearchMatches[sortedKeys[index]],
                            patientRecord: searchablePatients[sortedKeys[index]],
                        };
                    }}
                >
                    <Column
                        label="First Name"
                        dataKey="first_name"
                        width={650}
                        cellRenderer={({ rowData, rowIndex }) => {
                            const { matches, patientRecord } = rowData;
                            const render = (v) => (_.isEmpty(_.trim(v)) ? '(empty)' : v);
                            return (
                                <div className="multiple-match-row">
                                    <div className="left" style={{ height: `${calcRowHeight(rowData.matches)}px` }}>
                                        {rowIndex + 1}
                                    </div>
                                    <div className="right">
                                        <div className="patient-record-header">
                                            The following patient record matched{' '}
                                            {numeral(_.size(matches)).format('0,0')} patients:
                                            <div className="patient-record">
                                                <span className="text-field-wrapper">
                                                    <TextField
                                                        disabled
                                                        label="First Name"
                                                        value={render(patientRecord.first_name)}
                                                    />
                                                </span>
                                                <span className="text-field-wrapper">
                                                    <TextField
                                                        disabled
                                                        label="Last Name"
                                                        value={render(patientRecord.last_name)}
                                                    />
                                                </span>
                                                <span className="text-field-wrapper">
                                                    <TextField
                                                        disabled
                                                        label="Birthdate"
                                                        value={render(patientRecord.date_of_birth)}
                                                    />
                                                </span>
                                                <span className="text-field-wrapper">
                                                    <TextField
                                                        disabled
                                                        label="Phone #"
                                                        value={render(patientRecord.phone)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="patients-heading">The matched patients:</div>
                                        <div className="matching-patients">
                                            {_.map(matches, (match, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        className={classnames({ 'match-row': true, odd: i % 2 === 1 })}
                                                    >
                                                        <span className="check-and-name">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={_.has(multiSelectsToAdd, match.inbox_user_id)}
                                                                onChange={(e) => {
                                                                    const clone = { ...multiSelectsToAdd };
                                                                    if (_.has(clone, match.inbox_user_id)) {
                                                                        delete clone[match.inbox_user_id];
                                                                    } else {
                                                                        clone[match.inbox_user_id] = match;
                                                                    }
                                                                    setMultiSelectsToAdd(clone);
                                                                }}
                                                            />
                                                            <PatientName {...match} />
                                                        </span>

                                                        <span className="birthdate">
                                                            {formatDate(match.date_of_birth)}
                                                        </span>

                                                        <span className="phone">{formatRawPhone(match.phone)}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </Table>
            </div>
            <div className="resolve-actions">
                <Button
                    variant="outlined"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Do not include these patients
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={_.isEmpty(multiSelectsToAdd)}
                    onClick={() => {
                        doPatientSelection(_.values(multiSelectsToAdd));
                        onClose();
                        onDone();
                    }}
                >
                    Include {numeral(_.size(multiSelectsToAdd)).format('0,0')}{' '}
                    {pluralize('patient', _.size(multiSelectsToAdd))}
                </Button>
            </div>
        </div>
    );
}
