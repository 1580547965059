import './ClinicalSchedulingContent.sass';
import React, { useState, useRef, useEffect } from 'react';
import {
    getAppointmentCalendarFromTemplate,
    getHowToScheduleConfigFromInboxConversationTemplate,
    getNumberOfNonPastSlotsAvailableForDate,
    isSchedulingConfigured,
} from '../schedulingUtil';
import moment from 'moment';
import _ from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReviewRecipientsAndMessageContent from './ReviewRecipientsAndMessageContent';
import ScheduleByManualSelectContent from './ScheduleByManualSelectContent';
import WaitlistSchedulingNavBar from './WaitlistSchedulingNavBar';
import VaccineDoseInfoContent from './VaccineDoseInfoContent';
import WhoToContactContent from './WhoToContactContent';
import AppointmentBlockSelector from '../session/AppointmentBlockSelector';

export default function ClinicalSchedulingContent({
    template,
    height,
    apptCalendar,
    apptReservation,
    calendars,
    history,
    waitlistCount,
    isCovidWaitlistEnabled,
}) {
    const props = arguments[0];
    const isConfigured = isSchedulingConfigured(template, calendars);

    const innerRef = useRef();

    const schedulingConfig = getHowToScheduleConfigFromInboxConversationTemplate(template);

    const [timeRange, setTimeRange] = useState({
        from: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
        to: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
    });
    const [chosenPatients, setChosenPatients] = useState(null);
    const [patientChooseMethod, setPatientChooseMethod] = useState(null);
    const [appointmentLengthMinutes, setAppointmentLengthMinutes] = useState(
        _.get(schedulingConfig, 'appointment_length_minutes')
    );
    const [doseInfo, setDoseInfo] = useState(null);
    const [orderingOption, setOrderingOption] = useState('prioritize-known');

    const [calendarId, setCalendarId] = useState(
        _.get(getAppointmentCalendarFromTemplate(template, calendars), 'calendar_id')
    );

    const [reservationSession, setReservationSession] = useState(null);

    useEffect(() => {
        setDoseInfo(_.get(reservationSession, 'metadata.covid_dose_info'));
    }, [reservationSession]);

    const savedReservationSession = _.isNil(reservationSession)
        ? null
        : _.find(
              _.get(apptReservation, `liveReusableSessions.response.live_sessions`),
              (liveSession) => liveSession.session_id === reservationSession.session_id
          );

    const slotsFromLiveSession =
        _.isNil(reservationSession) || !_.isEqual(reservationSession, savedReservationSession)
            ? null
            : _.get(apptReservation, `liveReusableSessions.response.session_slots.${reservationSession.session_id}`);

    // track prior available slots so we don't flicker into not having any slots while reloading
    const [priorAvailableSlots, setPriorAvailableSlots] = useState(null);
    const availableSlots = _.isNil(slotsFromLiveSession)
        ? _.get(apptCalendar, 'availableCalendarSlots.response.slots', priorAvailableSlots)
        : slotsFromLiveSession;
    useEffect(() => {
        if (priorAvailableSlots !== availableSlots) {
            setPriorAvailableSlots(availableSlots);
        }
    }, [availableSlots]);
    const isLoadingNewSlots =
        _.isNil(_.get(apptCalendar, 'availableCalendarSlots.response.slots')) && !_.isNil(priorAvailableSlots);

    const timeRangeSelected = !_.isNil(_.get(timeRange, 'from.date')) && !_.isNil(_.get(timeRange, 'to.date'));

    if (_.isNil(template) || _.isEmpty(calendars)) {
        return null;
    }

    if (!isConfigured) {
        return <Redirect to={`/workflows/${template.inbox_conversation_template_id}/customize`} />;
    }

    const { scheduling_type } = getHowToScheduleConfigFromInboxConversationTemplate(template);

    const getFocusedTab = () => {
        const { pathname } = window.location;
        if (pathname.endsWith('/scheduling')) {
            return scheduling_type === '3rd-party' ? 'vaccine-dose-info' : 'when';
        } else if (pathname.endsWith('dose-info')) {
            return 'vaccine-dose-info';
        } else if (pathname.endsWith('who') || pathname.endsWith('who/waitlist') || pathname.endsWith('who/pharmacy')) {
            return 'who';
        } else if (pathname.endsWith('review')) {
            return 'review';
        }
    };

    const calendar = !_.isNil(calendarId)
        ? _.find(calendars, ({ calendar_id }) => calendar_id === calendarId)
        : !_.isNil(reservationSession)
        ? _.find(calendars, ({ calendar_id }) => calendar_id === reservationSession.calendar_id)
        : getAppointmentCalendarFromTemplate(template, calendars);

    const numberOfAvailableSlots = _.reduce(
        _.keys(availableSlots),
        (sum, date) => sum + getNumberOfNonPastSlotsAvailableForDate(availableSlots, date, _.get(calendar, 'timezone')),
        0
    );

    return (
        <div className="clinical-scheduling-content workflow-inner-content" style={{ height: `${height}px` }}>
            <div ref={innerRef} className="scheduling-content-inner">
                <WaitlistSchedulingNavBar
                    focusedTab={getFocusedTab()}
                    timeRangeSelected={timeRangeSelected}
                    timeRange={timeRange}
                    availableSlots={numberOfAvailableSlots}
                    doseInfo={doseInfo}
                    chosenPatients={chosenPatients}
                    patientChooseMethod={patientChooseMethod}
                    orderingOption={orderingOption}
                    reservationSession={reservationSession}
                    history={history}
                    template={template}
                />

                <div className="main-scheduling-content">
                    <Switch>
                        <Route
                            path="/workflows/:templateId/scheduling/review"
                            render={() => {
                                return (
                                    <ReviewRecipientsAndMessageContent
                                        {...props}
                                        chosenPatients={chosenPatients}
                                        setChosenPatients={setChosenPatients}
                                        availableSlots={availableSlots}
                                        schedulingConfig={schedulingConfig}
                                        timeRange={timeRange}
                                        doseInfo={doseInfo}
                                        calendar={calendar}
                                        patientChooseMethod={patientChooseMethod}
                                        reservationSession={reservationSession}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/workflows/:templateId/scheduling/who/:listToSearch"
                            render={({ match }) => {
                                const listToSearch = _.get(match, 'params.listToSearch', 'pharmacy');
                                return (
                                    <ScheduleByManualSelectContent
                                        {...props}
                                        setChosenPatients={setChosenPatients}
                                        setPatientChooseMethod={setPatientChooseMethod}
                                        listToSearch={listToSearch}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/workflows/:templateId/scheduling/who"
                            render={() => {
                                return (
                                    <WhoToContactContent
                                        {...props}
                                        numberOfSlots={numberOfAvailableSlots}
                                        availableDoses={_.get(doseInfo, 'number_of_doses')}
                                        orderingOption={orderingOption}
                                        setOrderingOption={setOrderingOption}
                                        setChosenPatients={setChosenPatients}
                                        setPatientChooseMethod={setPatientChooseMethod}
                                        onBack={() =>
                                            history.push(
                                                `/workflows/${template.inbox_conversation_template_id}/scheduling/dose-info`
                                            )
                                        }
                                    />
                                );
                            }}
                        />

                        <Route
                            path={
                                scheduling_type === '3rd-party'
                                    ? '/workflows/:templateId/scheduling'
                                    : '/workflows/:templateId/scheduling/dose-info'
                            }
                            render={() => {
                                return (
                                    <VaccineDoseInfoContent
                                        {...props}
                                        doseInfo={doseInfo}
                                        setDoseInfo={setDoseInfo}
                                        isCreatingNew={true}
                                        onBack={() =>
                                            history.push(
                                                `/workflows/${template.inbox_conversation_template_id}/scheduling`
                                            )
                                        }
                                        onNext={() => {
                                            const suffix =
                                                isCovidWaitlistEnabled && waitlistCount > 0 ? '' : '/pharmacy';
                                            history.push(
                                                `/workflows/${template.inbox_conversation_template_id}/scheduling/who${suffix}`
                                            );
                                        }}
                                    />
                                );
                            }}
                        />

                        <Route
                            path="/workflows/:templateId/scheduling"
                            render={() => {
                                return (
                                    <AppointmentBlockSelector
                                        {...props}
                                        subLabel="Please choose which appointment block you would like the patients to schedule their appointments on."
                                        timeRange={timeRange}
                                        setTimeRange={setTimeRange}
                                        timeRangeSelected={timeRangeSelected}
                                        schedulingConfig={schedulingConfig}
                                        availableSlots={availableSlots}
                                        isLoadingNewSlots={isLoadingNewSlots}
                                        loadSessionsOnMount={false}
                                        sortedDays={_.sortBy(_.keys(availableSlots))}
                                        calendar={calendar}
                                        appointmentLengthMinutes={appointmentLengthMinutes}
                                        setAppointmentLengthMinutes={setAppointmentLengthMinutes}
                                        doseInfo={doseInfo}
                                        setDoseInfo={setDoseInfo}
                                        reservationSession={reservationSession}
                                        setReservationSession={setReservationSession}
                                        calendarId={
                                            _.isNil(calendarId) && !_.isNil(calendar)
                                                ? calendar.calendar_id
                                                : calendarId
                                        }
                                        setCalendarId={setCalendarId}
                                        numberOfAvailableSlots={numberOfAvailableSlots}
                                        onNext={() => {
                                            const suffix =
                                                isCovidWaitlistEnabled && waitlistCount > 0 ? '' : '/pharmacy';
                                            setTimeout(
                                                () =>
                                                    history.push(
                                                        `/workflows/${template.inbox_conversation_template_id}/scheduling/who${suffix}`
                                                    ),
                                                10
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export function adaptReservationSessionToTimeRange(reservationSession) {
    const { start_date, end_date } = !_.isNil(reservationSession) ? reservationSession : {};

    return {
        from: {
            date: _.isNil(_.get(reservationSession, 'start_date')) ? null : moment(start_date, 'YYYY-MM-DD'),
            allDay: true,
            hour: null,
            minute: null,
        },
        to: {
            date: _.isNil(_.get(reservationSession, 'end_date')) ? null : moment(end_date, 'YYYY-MM-DD'),
            allDay: true,
            hour: null,
            minute: null,
        },
    };
}
