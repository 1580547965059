import React from 'react';
import { Grid, Cell } from 'react-md';

import { formatWithCommaAndAbbr } from 'utils/helper';

const TabularData = (props) => {
    const { data, customAttributes } = props;
    const tabularData = data.data;

    return (
        <Grid stacked className="tabularData">
            {tabularData.map((d, j) => (
                <Cell size={12} key={j}>
                    <div className="title"> {d.title} </div>
                    <Grid noSpacing className="valueGrid">
                        {d.values.map((v, i) => (
                            <Cell className="ValueCell" key={i} size={12} phoneSize={2} tabletSize={4}>
                                <div className="value">{formatWithCommaAndAbbr(v)}</div>
                                <span className="attribute">
                                    {customAttributes ? data.attributes[j][i] : data.attributes[i]}
                                </span>
                            </Cell>
                        ))}
                    </Grid>
                </Cell>
            ))}
            {data.footer && (
                <div className="footer text-align-center">
                    {data.footer.title}: {data.footer.value}
                </div>
            )}
        </Grid>
    );
};

export default TabularData;
