import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import ConnectCardGrid from './ConnectCardGrid';
import BulkMessageStatusSnackbar from './BulkMessageStatusSnackbar';
import Snowplow from '../../../snowplow';
import { isSchedulingTemplate } from '../scheduling/schedulingUtil';
import { connect } from 'react-redux';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';

function ConnectLandingPage({
    history,
    gridStyle,
    connect,
    cardSize,
    availableTemplates,
    setSelectedPatients,
    pharmacy,
    forceUpdate,
    fullPepEnabled,
}) {
    const props = arguments[0];

    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    useEffect(() => {
        Snowplow.pageView('Workflows');
    }, []);

    return (
        <div>
            <div className="connect-title-line" style={{ width: `${gridStyle.width}px` }}>
                <span className="title">Workflows</span>
                <span className="subtitle">Simple workflows for engaging with your patients.</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (fullPepEnabled) {
                            history.push('/workflows/manage');
                        } else {
                            setShowUpgradeDialog(true);
                        }
                    }}
                >
                    Manage Patient Workflows
                </Button>
            </div>
            <div className="grid-wrapper" style={{ width: `${gridStyle.width}px` }}>
                <ConnectCardGrid
                    {...props}
                    cardSize={cardSize}
                    connect={connect}
                    pharmacy={pharmacy}
                    maxHeight={window.innerHeight - 173}
                    width={window.innerWidth - 100}
                    availableTemplates={availableTemplates}
                    setSelectedCard={(uuid) => {
                        setSelectedPatients({});
                        const template = _.find(
                            availableTemplates,
                            ({ inbox_conversation_template_id }) => inbox_conversation_template_id === uuid
                        );

                        if (isSchedulingTemplate(template)) {
                            history.push(`/workflows/${uuid}/scheduling?back=/workflows`);
                            return;
                        } else if (!_.isNil(template.targeting_type)) {
                            const targetedCount = _.get(connect, `opportunities.${template.targeting_type}.count`, 0);
                            if (targetedCount > 0) {
                                setSelectedPatients(
                                    _.keyBy(
                                        _.get(connect, `opportunities.${template.targeting_type}.rows`),
                                        ({ inbox_user_id }) => inbox_user_id
                                    )
                                );
                                history.push(`/workflows/${uuid}/review?back=/workflows`);

                                return;
                            }
                        }
                        history.push(`/workflows/${uuid}/patients`);
                    }}
                    forceUpdate={forceUpdate}
                />
            </div>

            <BulkMessageStatusSnackbar />

            {!showUpgradeDialog ? null : <FullPepUpgrade asDialog onClose={() => setShowUpgradeDialog(false)} />}
        </div>
    );
}

const mapStateToProps = (state) => ({
    fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
});

export default connect(mapStateToProps, null)(ConnectLandingPage);
