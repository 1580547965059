import './InsertMenu.sass';
import { IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ImageIcon from '@material-ui/icons/Image';
import EventIcon from '@material-ui/icons/Event';
import { connect } from 'react-redux';
import { getLiveReusableReservationSessions } from 'redux/Appt/Reservation/action';
import { getAvailableAppointmentTypes } from 'redux/Appt/ApptType/action';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';
import _ from 'lodash';
import { List } from 'react-virtualized';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';
import { useHistory } from 'react-router-dom';

function InsertMenu({
    handleAttachment,
    disabled,
    apptType,
    apptReservation,
    getAvailableAppointmentTypes,
    getLiveReusableReservationSessions,
    apptTypesWithSessions,
    handleApptRequest,
    fullPepEnabled,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
    const attachmentInput = useRef();
    const history = useHistory();

    useEffect(() => {
        if (!apptType.loadingAvailableApptTypes && _.isNil(_.get(apptType, 'availableApptTypes.response'))) {
            getAvailableAppointmentTypes();
        }
    }, [apptType]);

    useEffect(() => {
        if (
            !_.get(apptReservation, 'liveReusableSessions.loading') &&
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response'))
        ) {
            getLiveReusableReservationSessions();
        }
    }, [apptReservation]);

    const apptTypesToShow = _.filter(apptTypesWithSessions, ({ sessions }) => !_.isEmpty(sessions));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onAttachmentChange = (event) => {
        const selectedFile = event.target.files[0];
        const reader = new FileReader();

        reader.onabort = () => {
            handleAttachment(null, null);
        };
        reader.onerror = () => {
            handleAttachment(null, null);
        };
        reader.onload = () => {
            handleAttachment(reader.result, selectedFile);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            handleAttachment(null, null);
        }
    };

    return (
        <>
            <IconButton className="insert-menu-button" onClick={handleClick}>
                <AddIcon />
            </IconButton>

            <form>
                <input
                    id="contained-button-file"
                    type="file"
                    ref={attachmentInput}
                    name="selectedFile"
                    onChange={onAttachmentChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                    disabled={disabled}
                />
            </form>

            <Menu
                className="attachment-option-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                keepMounted
                onClose={handleClose}
            >
                <MenuItem className="attachment-option" value="file" onClick={() => handleClose()}>
                    <label htmlFor="contained-button-file">
                        <ImageIcon /> Send an image
                    </label>
                </MenuItem>
                <MenuItem
                    className="attachment-option"
                    value="appt"
                    onClick={() => {
                        handleClose();

                        setAppointmentDialogOpen(true);
                    }}
                >
                    <EventIcon /> Send an appointment request
                </MenuItem>
            </Menu>

            {!fullPepEnabled && appointmentDialogOpen && (
                <FullPepUpgrade asDialog onClose={() => setAppointmentDialogOpen(false)} />
            )}

            {fullPepEnabled && appointmentDialogOpen && (
                <Dialog
                    className="choose-appt-type-to-insert-dialog"
                    maxWidth="lg"
                    open={appointmentDialogOpen}
                    onClose={() => setAppointmentDialogOpen(false)}
                >
                    <DialogTitle>
                        <div>Choose Type of Appointment Request to Send</div>
                        <Button
                            color="primary"
                            className="manage-appointments-button"
                            onClick={() => history.push('/appointments/types')}
                        >
                            Manage appointments
                        </Button>
                    </DialogTitle>

                    <DialogContent>
                        {_.isEmpty(apptTypesToShow) && (
                            <div className="no-appointments-configured">
                                No appointment types are currently active,
                                <Button
                                    color="primary"
                                    style={{ textTransform: 'none' }}
                                    onClick={() => history.push('/appointments/types')}
                                >
                                    Click here
                                </Button>{' '}
                                to configure them.
                            </div>
                        )}

                        {!_.isEmpty(apptTypesToShow) && (
                            <>
                                <div className="explanation">
                                    Choosing an option below will insert an appointment scheduling button that the
                                    patient may use to choose a convenient time for them to schedule the selected
                                    appointment type.
                                </div>
                                <List
                                    width={600}
                                    height={Math.min(450, _.size(apptTypesToShow) * 90)}
                                    rowCount={_.size(apptTypesToShow)}
                                    rowHeight={90}
                                    rowRenderer={({ key, index, style }) => {
                                        const { apptType } = apptTypesToShow[index];

                                        return (
                                            <div key={key} className="appt-type-row" style={style}>
                                                <div
                                                    className="appt-type-row-inner"
                                                    onClick={() => {
                                                        setAppointmentDialogOpen(false);
                                                        handleApptRequest(apptType);
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        src={`https://static.digitalpharmacist.com/workflows/${apptType.image_ref}`}
                                                        width={120}
                                                        height={80}
                                                    />

                                                    <div className="text">
                                                        <div className="label">{apptType.label}</div>

                                                        <div className="description">{apptType.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}

function mapStateToProps(state) {
    const { apptType, apptReservation } = state;
    return {
        apptTypesWithSessions: selectApptTypesWithSessions(state),
        apptType,
        apptReservation,
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    };
}

const bindActionsToDispatch = {
    getLiveReusableReservationSessions,
    getAvailableAppointmentTypes,
};

export default connect(mapStateToProps, bindActionsToDispatch)(InsertMenu);
