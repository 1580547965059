import React, { Component, Fragment } from 'react';
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableCell,
    Card,
    CardContent,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getFormattedMailupDate } from 'utils/helper';
import ReactPlaceholder from 'react-placeholder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classnames from 'classnames';

import { newsletterActions } from 'redux/Newsletter';
import { DpTableBody, DpTableCell, DpTableRow } from 'components/Common/DpTables';
import { DRAWER_STATES } from 'constants/Newsletter';
import Snowplow from 'snowplow';

const HistoryPlaceholder = ({ legendsCount, style }) => {
    const placeHolderStyle = style ? style : { height: '50px' };
    return (
        <Grid container spacing={2}>
            {new Array(legendsCount).fill(0).map((item, idx) => (
                <Grid item xs={12} key={idx}>
                    <ReactPlaceholder
                        type="rect"
                        rows={1}
                        ready={false}
                        showLoadingAnimation
                        firstLaunchOnly
                        style={placeHolderStyle}
                    >
                        <div></div>
                    </ReactPlaceholder>
                </Grid>
            ))}
        </Grid>
    );
};

export class HistoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 25,
            selectedMessageId: '',
        };
    }

    componentDidMount() {
        const { fetchHistoryList } = this.props;
        const { page, rowsPerPage } = this.state;
        fetchHistoryList({ page: page + 1, rowsPerPage });
        Snowplow.pageView('Newsletter History Tab');
    }

    componentDidUpdate({ selectedCustomer: oldSelectedCustomer }) {
        const { selectedCustomer } = this.props;
        const isPharmacyChanged = selectedCustomer !== oldSelectedCustomer;

        if (isPharmacyChanged) {
            const { rowsPerPage } = this.state;
            const { fetchHistoryList } = this.props;

            fetchHistoryList({ page: 1, rowsPerPage });
            this.setState({ page: 0 });
        }
    }

    handlePageChange = (event, newPage) => {
        const { fetchHistoryList } = this.props;
        const { rowsPerPage } = this.state;

        fetchHistoryList({ page: newPage + 1, rowsPerPage });
        this.setState({ page: newPage });
    };

    selectNewsletter = (newsletter) => {
        const { getNewsletterDetails, setDrawerState } = this.props;
        getNewsletterDetails(newsletter);
        setDrawerState(DRAWER_STATES.NEWSLETTER_DETAILS);
        this.setState({
            selectedMessageId: newsletter.email_message_id,
        });
    };

    showNewsletterPreview = (newsletter) => {
        const { getNewsletterDetails, setActiveNewsletter } = this.props;
        getNewsletterDetails(newsletter);
        setActiveNewsletter();
        this.setState({
            selectedMessageId: newsletter.email_message_id,
        });
    };

    render() {
        const {
            newsletter: { historyList, loadingHistory, totalHistory },
        } = this.props;

        const { page, rowsPerPage, selectedMessageId } = this.state;

        return (
            <div className="history-list-container">
                <Card className="card">
                    <CardContent>
                        {loadingHistory ? (
                            <HistoryPlaceholder legendsCount={6} />
                        ) : (
                            <Fragment>
                                <TableContainer>
                                    <Table aria-label="history table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <DpTableCell>Date Sent</DpTableCell>
                                                <DpTableCell>Subject</DpTableCell>
                                                <DpTableCell>Sender Email</DpTableCell>
                                                <DpTableCell>Recipients</DpTableCell>
                                                <DpTableCell> </DpTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <DpTableBody>
                                            {historyList.map((history, idx) => (
                                                <DpTableRow
                                                    key={history.email_message_id}
                                                    onClick={() => this.selectNewsletter(history)}
                                                    className={classnames({
                                                        selected: history.email_message_id === selectedMessageId,
                                                    })}
                                                >
                                                    <DpTableCell>
                                                        {getFormattedMailupDate(history.sent_date)}
                                                    </DpTableCell>
                                                    <DpTableCell>{history.subject}</DpTableCell>
                                                    <DpTableCell>{history.sender_email || '-'}</DpTableCell>
                                                    <DpTableCell>{history.recipient_count}</DpTableCell>
                                                    <DpTableCell>
                                                        <VisibilityIcon
                                                            onClick={() => this.showNewsletterPreview(history)}
                                                        />
                                                    </DpTableCell>
                                                </DpTableRow>
                                            ))}
                                            {!historyList.length && !loadingHistory && (
                                                <DpTableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                        style={{ fontWeight: 'bold' }}
                                                    >
                                                        There are no past newsletters for this Pharmacy
                                                    </TableCell>
                                                </DpTableRow>
                                            )}
                                        </DpTableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[25]}
                                    component="div"
                                    count={totalHistory}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handlePageChange}
                                />
                            </Fragment>
                        )}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ newsletter, auth }) => {
    return {
        newsletter,
        selectedCustomer: auth.selectedCustomer,
    };
};

const mapDispatchToProps = {
    fetchHistoryList: newsletterActions.fetchHistoryList,
    getNewsletterDetails: newsletterActions.getNewsletterDetails,
    setDrawerState: newsletterActions.setDrawerState,
    setActiveNewsletter: newsletterActions.setActiveNewsletter,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryList);
