import React from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { Button, Checkbox, Tooltip } from '@material-ui/core';
import { Table, Column } from 'react-virtualized';
import ReviewPatientCell from '../ReviewPatientCell';
import { formatRawPhone } from '../../../../utils/helper';

export default function AddPatientsForSelection({
    createablePatients,
    setAutoCapitalize,
    autoCapitalize,
    onClose,
    singleSearchMatches,
    addingPatients,
    setAddingPatients,
    getPatientAtIndex,
    createBulkUsers,
    searchPatientList,
    selectedPatients,
    setSelectedPatients,
    setAddError,
    formatDate,
}) {
    return (
        <div className="add-patients-for-selection">
            {_.isEmpty(createablePatients) ? (
                <div>
                    <div className="no-createable-patients">
                        <div className="no-patients-heading">
                            In addition to not matching any patients, none of the parsed patient records contained all
                            of the required information to create new patients.
                        </div>
                        <div>
                            If you would like to be able to add patients automatically when selecting via a file, ensure
                            the following fields are present and properly mapped:
                        </div>
                        <ul>
                            <li>First name</li>
                            <li>Last name</li>
                            <li>Birthdate</li>
                            <li>Phone # (Cell preferred for messaging)</li>
                        </ul>
                    </div>
                    <div className="actions">
                        <span />
                        <Button variant="contained" color="primary" onClick={() => onClose()}>
                            OK
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="add-patients-section">
                        <div className="table-heading">
                            {!_.isEmpty(singleSearchMatches) ? 'Additionally, this file ' : 'However, this file '}
                            contains {numeral(_.size(createablePatients)).format('0,0')} other{' '}
                            {pluralize('patient', _.size(createablePatients))} that may be added & then selected.
                        </div>
                        <Table
                            width={650}
                            height={Math.min(
                                510,
                                Math.min(
                                    Math.floor((window.innerHeight - 250) / 30) * 30,
                                    Math.max(150, (_.size(createablePatients) + 1) * 30)
                                )
                            )}
                            headerHeight={30}
                            rowHeight={30}
                            rowCount={_.size(createablePatients)}
                            rowGetter={({ index }) => {
                                return getPatientAtIndex(index);
                            }}
                            rowStyle={({ index }) => {
                                return index % 2 === 0 ? { background: '#fff' } : { background: '#f8f8f8' };
                            }}
                        >
                            <Column
                                label="First Name"
                                dataKey="first_name"
                                width={150}
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <ReviewPatientCell
                                            value={rowData.first_name}
                                            rawValue={rowData.raw.first_name}
                                        />
                                    );
                                }}
                            />
                            <Column
                                label="Last Name"
                                dataKey="last_name"
                                width={150}
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <ReviewPatientCell value={rowData.last_name} rawValue={rowData.raw.last_name} />
                                    );
                                }}
                            />
                            <Column
                                label="Birthdate"
                                dataKey="date_of_birth"
                                width={150}
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <ReviewPatientCell
                                            value={
                                                rowData.invalidFields && rowData.invalidFields.has('date_of_birth')
                                                    ? ''
                                                    : formatDate(rowData.date_of_birth)
                                            }
                                            rawValue={rowData.raw.date_of_birth}
                                        />
                                    );
                                }}
                            />
                            <Column
                                label="Phone"
                                dataKey="phone"
                                width={150}
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <ReviewPatientCell
                                            value={
                                                rowData.invalidFields && rowData.invalidFields.has('phone')
                                                    ? ''
                                                    : formatRawPhone(rowData.phone)
                                            }
                                            rawValue={rowData.raw.phone}
                                        />
                                    );
                                }}
                            />
                        </Table>
                    </div>
                    <div className="actions">
                        <Tooltip title="Auto-capitalization will automatically correct the capitalization of the first and last name fields.">
                            <span className="auto-capitalize-toggle" onClick={() => setAutoCapitalize(!autoCapitalize)}>
                                <Checkbox
                                    color="primary"
                                    checked={autoCapitalize}
                                    onChange={() => {
                                        setAutoCapitalize(!autoCapitalize);
                                    }}
                                />{' '}
                                Auto-capitalize names
                            </span>
                        </Tooltip>
                        <span className="buttons">
                            <Button
                                className="no-thanks-button"
                                variant="outlined"
                                disabled={addingPatients}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No Thanks
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                disabled={addingPatients}
                                onClick={() => {
                                    setAddingPatients(true);
                                    const finalPatients = _.map(createablePatients, (p, i) =>
                                        _.omit(getPatientAtIndex(i), ['raw'])
                                    );
                                    addPatientsAndWaitForThemToBeSearchable(
                                        finalPatients,
                                        createBulkUsers,
                                        searchPatientList
                                    )
                                        .then((response) => {
                                            setAddingPatients(false);
                                            setSelectedPatients({
                                                ...selectedPatients,
                                                ..._.keyBy(response, ({ inbox_user_id }) => inbox_user_id),
                                            });
                                            setAddingPatients(false);
                                            onClose();
                                        })
                                        .catch((error) => {
                                            setAddingPatients(false);
                                            setAddError(error);
                                        });
                                }}
                            >
                                Add & Select these patients
                            </Button>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

// Creates the passed patients and will check every 2 seconds until it sees they have propagated to the patients index
async function addPatientsAndWaitForThemToBeSearchable(createablePatients, createBulkUsers, searchPatientList) {
    try {
        await createBulkUsers(createablePatients);

        // return a manually created promise
        return new Promise((resolve, reject) => {
            const searchFunction = async () => {
                try {
                    const searchResponse = await searchPatientList(createablePatients);

                    if (!searchResponse.success) {
                        reject('success is false');
                    } else {
                        // If we found the same number of patients as the ones we created, we are done.
                        if (
                            _.size(_.filter(searchResponse.results, (matches) => _.size(matches) === 1)) ===
                            _.size(createablePatients)
                        ) {
                            resolve(_.map(searchResponse.results, (matches) => _.first(matches)));
                        } else {
                            // if we didn't find the proper number of results, then sleep and try again
                            setTimeout(searchFunction, 2000);
                        }
                    }
                } catch (error) {
                    reject(error);
                }
            };
            setTimeout(searchFunction, 2000);
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
