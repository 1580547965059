import './VacationConfiguration.sass';
import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Button, Checkbox, IconButton, InputAdornment, MenuItem, Select, Tooltip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HourAndMinuteSelect from './HourAndMinuteSelect';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';

export default function VacationConfiguration({ configuration, onChange, displayedDateFormat }) {
    const isEmpty = _.isEmpty(configuration);

    const addNew = () => {
        const clone = _.isArray(configuration) ? _.cloneDeep(configuration) : [];

        clone.push({
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            fromAllDay: true,
            toAllDay: true,
        });

        onChange(clone);
    };

    return (
        <div className={classnames({ 'vacation-configuration': true, empty: isEmpty })}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {isEmpty ? (
                    <div className="empty-message">
                        <span>No vacation blocks currently scheduled,</span>{' '}
                        <Button onClick={addNew} data-cy="add-initial-vacation-button">
                            click here
                        </Button>{' '}
                        <span>to add one.</span>
                    </div>
                ) : (
                    <div>
                        <div className="vacation-blocks">
                            {_.map(configuration, (vacationBlock, i) => {
                                return (
                                    <VacationBlock
                                        key={i}
                                        odd={i % 2 === 1}
                                        block={{ ...vacationBlock }}
                                        displayedDateFormat={displayedDateFormat}
                                        onChange={(block) => {
                                            const clone = _.cloneDeep(configuration);
                                            clone[i] = block;
                                            onChange(_.filter(clone));
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div className="buttons">
                            <Button className="add-button" data-cy="add-vacation-block-button" onClick={addNew}>
                                <AddCircleOutlineIcon /> Add new vacation block
                            </Button>
                        </div>
                    </div>
                )}
            </MuiPickersUtilsProvider>
        </div>
    );
}

function isBlockInternallyValid({ fromAllDay, toAllDay, fromDate, toDate, fromHour, toHour, fromMinute, toMinute }) {
    if (toDate < fromDate) {
        return false;
    }

    if (!fromAllDay && (_.isNil(fromHour) || _.isNil(fromMinute))) {
        return false;
    }

    if (!toAllDay && (_.isNil(toHour) || _.isNil(toMinute))) {
        return false;
    }

    if (toDate === fromDate && !fromAllDay && !toAllDay) {
        if (fromHour > toHour || (fromHour === toHour && fromMinute >= toMinute)) {
            return false;
        }
    }

    return true;
}

function VacationBlock(props) {
    const { block, onChange } = props;

    const internallyValid = isBlockInternallyValid(props.block);

    return (
        <div
            className={classnames({ 'vacation-block': true, odd: props.odd, invalid: !internallyValid })}
            data-cy="vacation-block"
        >
            <div className="extent">
                <DateLine {...props} prefix="from" />
                <DateLine {...props} prefix="to" />
            </div>

            <Select
                className="capacity-select"
                data-cy="capacity-select"
                value={_.get(block, 'capacity', 'all')}
                variant="outlined"
                onChange={(e) => {
                    onChange({ ...block, capacity: e.target.value });
                }}
            >
                <MenuItem value="all">no appointments available</MenuItem>
                {_.map(_.times(10), (i) => {
                    return (
                        <MenuItem key={i} value={i + 1}>
                            reduce by {i + 1}
                        </MenuItem>
                    );
                })}
            </Select>

            <Tooltip title="Remove this vacation block">
                <IconButton
                    className="delete-button"
                    onClick={() => {
                        onChange(null);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
}

function DateLine({ prefix, block, displayedDateFormat, onChange }) {
    const allDay = _.get(block, `${prefix}AllDay`, true);
    const label = prefix === 'from' ? 'From' : 'To';

    return (
        <div className="date-line">
            <span className="main-label">{label}</span>
            <DatePicker
                className="date-picker"
                data-cy="date-picker"
                value={moment(_.get(block, `${prefix}Date`), 'YYYY-MM-DD')}
                variant="inline"
                inputVariant="outlined"
                labelFunc={(m) => m.format(displayedDateFormat)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CalendarTodayIcon className="calendar-icon" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <ArrowDropDownIcon className="dropdown-icon" />
                        </InputAdornment>
                    ),
                }}
                onChange={(date) => {
                    const updates = { [`${prefix}Date`]: date.format('YYYY-MM-DD') };
                    if (prefix === 'from') {
                        if (block.toDate < date.format('YYYY-MM-DD')) {
                            updates.toDate = updates.fromDate;
                        }
                    }
                    onChange({ ...block, ...updates });
                }}
            />
            <HourAndMinuteSelect
                hour={_.get(block, `${prefix}Hour`, '')}
                minute={_.get(block, `${prefix}Minute`, '')}
                disabled={allDay}
                onChange={({ hour, minute }) => {
                    onChange({ ...block, [`${prefix}Hour`]: hour, [`${prefix}Minute`]: minute });
                }}
            />

            <span
                className="all-day"
                onClick={() => {
                    onChange({ ...block, [`${prefix}AllDay`]: !allDay });
                }}
            >
                <Checkbox color="primary" checked={allDay} />
                <span className="label">All day</span>
            </span>
        </div>
    );
}
