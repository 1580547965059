import React, { Component } from 'react';
import { Button, Grid, Cell } from 'react-md';

import EditableBackendValues from './EditableBackendValues';
import SlidingROIValues from './SlidingROIValues';
import ComputedROIValues from './ComputedROIValues';

class ROICalculator extends Component {
    handleChange = (value, event, id) => {
        this.props.handleChange(value, event, id);
    };

    render() {
        const {
            refillCountValue,
            newPatientTransfersValue,
            outboundAlertsValue,
            employeeWagePerHourValue,
            avgProfitPerRXValue,
            avgRevenuePerRXValue,
            annualAvgRevenuePerPatientValue,
            minutesSavedFromAutomationValue,
            socialMediaValue,
            hoursPerMonthWorkValue,
            valueOfScriptsProcessed,
            valueOfNewPatients,
            mediaSalesValue,
            grossProfitOfScriptsProcessed,
            valueOfAutomation,
            valueOfSupport,
            usingPharmacyData,
            toggleUsePharmacyData,
            ldValueCalculatorEnabled,
        } = this.props;
        return (
            <div className="ROICalculator__container">
                <div>
                    <Grid>
                        <Cell size={4}>
                            <EditableBackendValues
                                valueChange={this.handleChange}
                                refillCountValue={refillCountValue}
                                newPatientTransfersValue={newPatientTransfersValue}
                                outboundAlertsValue={outboundAlertsValue}
                                usingPharmacyData={usingPharmacyData}
                                toggleUsePharmacyData={toggleUsePharmacyData}
                                ldValueCalculatorEnabled={ldValueCalculatorEnabled}
                            />
                        </Cell>
                        <Cell size={4}>
                            <SlidingROIValues
                                valueChange={this.handleChange}
                                employeeWagePerHourValue={employeeWagePerHourValue}
                                avgProfitPerRXValue={avgProfitPerRXValue}
                                avgRevenuePerRXValue={avgRevenuePerRXValue}
                                annualAvgRevenuePerPatientValue={annualAvgRevenuePerPatientValue}
                                minutesSavedFromAutomationValue={minutesSavedFromAutomationValue}
                                socialMediaValue={socialMediaValue}
                                hoursPerMonthWorkValue={hoursPerMonthWorkValue}
                            />
                        </Cell>
                        <Cell size={4}>
                            <ComputedROIValues
                                scriptsProcessedValue={valueOfScriptsProcessed}
                                newPatientsValue={valueOfNewPatients}
                                mediaSalesValue={mediaSalesValue}
                                scriptsProcessedGrossProfit={grossProfitOfScriptsProcessed}
                                automationValue={valueOfAutomation}
                                supportValue={valueOfSupport}
                            />
                        </Cell>
                    </Grid>
                </div>
                <div className="ROICalculator__Footer">
                    <Button
                        flat={true}
                        title="Close"
                        className="ROICalculator__Footer--Button"
                        onClick={() => this.props.hideModal()}
                    >
                        CLOSE
                    </Button>
                </div>
            </div>
        );
    }
}

export default ROICalculator;
