import _ from 'lodash';
import { createSelector } from 'reselect';

const getPharmacyDetails = (state) => state.pharmacy.pharmacy;

const getNewsletterConfig = createSelector([getPharmacyDetails], (pharmacy) => {
    const newsletters = _.get(pharmacy, 'service.Newsletter', {});

    return {
        senderName: _.get(newsletters, 'senderName', ''),
        senderEmailOverride: _.get(newsletters, 'senderEmailOverride', ''),
        pharmacyFacebookURL: _.get(newsletters, 'pharmacyFacebookURL', ''),
        pharmacyTwitterURL: _.get(newsletters, 'pharmacyTwitterURL', ''),
        pharmacyInstagramURL: _.get(newsletters, 'pharmacyInstagramURL', ''),
    };
});

export const newsletterSelectors = {
    getNewsletterConfig,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
