import apptTypeDataTypes from './type';
import apptTypeService from './service';
import config from 'config';
import _ from 'lodash';

export function getAvailableAppointmentTypes() {
    return async (dispatch, getState) => {
        const state = getState().apptType;

        if (state.loadingAvailableApptTypes || _.isEmpty(config.X_PharmacyID)) {
            return Promise.resolve();
        }

        dispatch({
            type: apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_REQUEST,
            payload: {},
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await apptTypeService.getAvailableAppointmentTypes(pharmacyId, locationId);
            dispatch({
                type: apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_SUCCESS,
                payload: { pharmacyId, locationId, response },
            });
            return response;
        } catch (error) {
            dispatch({
                type: apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_FAILURE,
                payload: { pharmacyId, locationId, error },
            });
            return Promise.reject(error);
        }
    };
}
