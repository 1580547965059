import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { reviewAction } from '../../../redux/Review';
import ReviewGraph from './ReviewGraph';
import ReviewAverage from './ReviewAverage';
import ReviewList from './ReviewList';

export class Reviews extends PureComponent {
    componentDidMount() {
        this.initReviews();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedPharmacy } = this.props;
        if (prevProps.selectedPharmacy !== selectedPharmacy) {
            // Re-initialize when pharmacy changes
            // Currently reviews are only available at a pharmacy-level so location changes don't matter
            this.initReviews();
        }
    }

    initReviews() {
        const { fetchSummary, fetchAverage } = this.props;
        // Summary response includes initial page of reviews list as well as monthly graph data
        fetchSummary();
        fetchAverage();
    }

    render() {
        const {
            review: { average, list, months, total, page, loadingSummary, loadingAverage, loadingList },
            fetchList,
            pharmacyData,
        } = this.props;

        return (
            <div className="reviews-container page-without-sidebar-container">
                <div className="reviews-container__content">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8}>
                            <ReviewGraph months={months} total={total} loading={loadingSummary} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <ReviewAverage average={average} loading={loadingAverage} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <ReviewList
                                list={list}
                                fetchList={fetchList}
                                page={page}
                                total={total}
                                pharmacyData={pharmacyData}
                                loading={loadingSummary || loadingList}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { pharmacy, auth, review } = state;
    return {
        review,
        pharmacyData: pharmacy.pharmacy,
        isPharmacyLoading: pharmacy.loading,
        selectedLocation: auth.selectedLocation,
        selectedPharmacy: auth.selectedCustomer,
        isInternalUser: state.auth.isInternalUser,
    };
};

const mapDispatchToProps = {
    fetchSummary: reviewAction.fetchSummary,
    fetchAverage: reviewAction.fetchAverage,
    fetchList: reviewAction.fetchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
