export default {
    '/inbox': 'Inbox',
    '/reports': 'Reports',
    '/voicemail': 'Voicemail',
    '/refills': 'Refill Digest',
    '/leads': 'Digital Pharmacist Leads',
    '/classes': 'Classes',
    '/reviews': 'Reviews',
    '/notice': 'Subscriber Notice',
};
