import './HourAndMinuteSelect.sass';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { MenuItem, Select } from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';

export default function HourAndMinuteSelect({ hour, minute, onChange, disabled }) {
    return (
        <span className={classnames({ 'hour-and-minute-select': true, disabled })}>
            <ScheduleIcon />

            <Select
                className="hour-select"
                data-cy="hour-select"
                value={_.isNil(hour) ? '' : hour}
                disabled={disabled}
                MenuProps={{ disablePortal: true }}
                onChange={(e) => {
                    onChange({ hour: e.target.value, minute: minute === '' ? 0 : minute });
                }}
            >
                {_.map(_.times(24), (i) => {
                    return (
                        <MenuItem key={i} value={i}>
                            {formatHourOfDayIndexToTwelveHourTime(i)}
                        </MenuItem>
                    );
                })}
            </Select>

            <Select
                className="minute-select"
                data-cy="minute-select"
                value={_.isNil(minute) ? '' : minute}
                disabled={disabled}
                MenuProps={{ disablePortal: true }}
                onChange={(e) => {
                    onChange({ hour, minute: e.target.value });
                }}
            >
                {_.map(_.times(12), (i) => {
                    return (
                        <MenuItem key={i} value={i * 5}>
                            :{String(i * 5).padStart(2, '0')}
                        </MenuItem>
                    );
                })}
            </Select>
        </span>
    );
}

function formatHourOfDayIndexToTwelveHourTime(hourOfDay) {
    return `${hourOfDay % 12 === 0 ? 12 : hourOfDay % 12} ${hourOfDay < 12 ? 'AM' : 'PM'}`;
}
