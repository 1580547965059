import './AppointmentTypeContent.sass';
import React, { useState, useEffect } from 'react';
import { Button, Tooltip, IconButton, CircularProgress, TextField } from '@material-ui/core';
import _ from 'lodash';
import { getTemplateImageUrl } from 'components/Patients/patientsUtil';
import ScheduleIcon from '@material-ui/icons/Schedule';
import classnames from 'classnames';
import EventIcon from '@material-ui/icons/Event';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppointmentBlockSelector from 'components/Patients/scheduling/session/AppointmentBlockSelector';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { List } from 'react-virtualized';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { getAvailableAppointmentTypes } from 'redux/Appt/ApptType/action';
import { selectApptTypesWithSessions } from '../types/apptTypesUtil';
import { useHistory } from 'react-router';

function AppointmentTypeContent({
    selected,
    onChange,
    setIsOneOffType,
    calendars,
    setShowingAppointmentBlocks,
    showingAppointmentBlocks,
    apptReservation,
    getLiveReusableReservationSessions,
    hasLoadedAppointmentBlocks,
    setHasLoadedAppointmentBlocks,
    getAvailableAppointmentTypes,
    availableApptTypes,
    isLoading,
}) {
    const history = useHistory();
    const props = arguments[0];

    useEffect(() => {
        if (!hasLoadedAppointmentBlocks) {
            getLiveReusableReservationSessions();
            setHasLoadedAppointmentBlocks(true);
        }
    }, []);

    useEffect(() => {
        if (_.isEmpty(availableApptTypes)) {
            getAvailableAppointmentTypes();
        }
    }, []);

    const [filterText, setFilterText] = useState('');
    const [filteredResults, setFilteredResults] = useState(availableApptTypes);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [isFilterTextFocused, setIsFilterTextFocused] = useState(false);

    useEffect(() => {
        setFilteredResults(
            _.filter(availableApptTypes, ({ apptType }) => _.toLower(apptType.label).includes(_.toLower(filterText)))
        );
    }, [filterText, availableApptTypes]);

    const activeReservationSessions = _.get(apptReservation, 'liveReusableSessions.response.live_sessions');

    const [calendarId, setCalendarId] = useState(null);
    const [appointmentLengthMinutes, setAppointmentLengthMinutes] = useState(null);
    const [doseInfo, setDoseInfo] = useState(null);
    const [timeRange, setTimeRange] = useState({
        from: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
        to: {
            date: null,
            allDay: true,
            hour: null,
            minute: null,
        },
    });

    return (
        <div className="appointment-type-content">
            <div className="heading">
                <div className="title">Choose type of appointment</div>
                <div className="manual-create">
                    or{' '}
                    <Button
                        data-cy="one-off-appt-button"
                        className="one-off-button"
                        onClick={() => setIsOneOffType(true)}
                    >
                        create as one-off appointment
                    </Button>
                </div>
            </div>

            {isLoading ? (
                <CircularProgress className="appt-types-loading" />
            ) : (
                <>
                    <div className="sub-heading">
                        <TextField
                            className="appt-type-name-filter"
                            autoFocus
                            placeholder="Filter available appointment types..."
                            variant="outlined"
                            defaultValue={filterText}
                            onChange={(e) => {
                                setFocusedIndex(0);
                                setFilterText(_.trim(e.target.value));
                            }}
                            onFocus={() => setIsFilterTextFocused(true)}
                            onBlur={() => setIsFilterTextFocused(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'ArrowDown' && focusedIndex < _.size(filteredResults) - 1) {
                                    setFocusedIndex(focusedIndex + 1);
                                } else if (e.key === 'ArrowUp' && focusedIndex > 0) {
                                    setFocusedIndex(focusedIndex - 1);
                                } else if (e.key === 'Enter') {
                                    const { apptType, sessions } = filteredResults[focusedIndex];
                                    if (supportsAppointmentBlocks(apptType)) {
                                        setShowingAppointmentBlocks(apptType);
                                    } else {
                                        onChange({
                                            appointment_label: apptType.label,
                                            appointment_calendar_id: _.get(sessions, '[0].calendar_id'),
                                            appointment_length_minutes: _.get(sessions, '[0].expected_length'),
                                            calendar: null,
                                            image_ref: apptType.image_ref,
                                        });
                                    }
                                }
                            }}
                        />
                    </div>

                    {isFilterTextFocused && !_.isEmpty(filteredResults) && (
                        <div className="focus-instructions">
                            <div className="enter-instructions">
                                Press <strong>Enter</strong> to select{' '}
                                <strong>{filteredResults[focusedIndex].apptType.label}</strong>
                            </div>
                            <div className="nav-instructions">
                                <ArrowUpwardIcon style={{ fontSize: '12px' }} /> &{' '}
                                <ArrowDownwardIcon style={{ fontSize: '12px' }} /> keys to navigate
                            </div>
                        </div>
                    )}

                    <div className="available-types">
                        <List
                            width={550}
                            height={500}
                            rowCount={_.size(filteredResults)}
                            rowHeight={102}
                            rowRenderer={({ key, index, style }) => {
                                const { apptType, sessions } = filteredResults[index];

                                const appointmentType = {
                                    appointment_label: apptType.label,
                                    appointment_calendar_id: _.get(sessions, '[0].calendar_id'),
                                    appointment_length_minutes: _.get(sessions, '[0].expected_length'),
                                    calendar: null,
                                    image_ref: apptType.image_ref,
                                };

                                return (
                                    <div key={key} style={{ ...style, padding: '0 10px 0 5px' }}>
                                        <AppointmentTypeRow
                                            appointmentType={appointmentType}
                                            calendars={calendars}
                                            activeReservationSessions={sessions}
                                            focused={focusedIndex === index}
                                            onClick={(apptType) => {
                                                if (supportsAppointmentBlocks(apptType)) {
                                                    setShowingAppointmentBlocks(apptType);
                                                } else {
                                                    onChange(appointmentType);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            }}
                            noRowsRenderer={() => {
                                return (
                                    <div className="no-matching-appt-types-message">
                                        <EventBusyIcon />
                                        <div className="text">
                                            <div className="main-line">No matching appointment types found.</div>
                                            <div className="sub-line">
                                                <Button
                                                    className="manage-shortcut-button"
                                                    color="primary"
                                                    onClick={() => history.push('/appointments/types')}
                                                >
                                                    Click here
                                                </Button>
                                                to manage which appointment types are available.
                                            </div>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </>
            )}

            {_.isNil(showingAppointmentBlocks) ? null : (
                <div className="appointment-blocks-content">
                    <div className="appointment-type-and-back-button">
                        <div className="back-button-wrapper">
                            <Tooltip title="Go back to choosing an appointment type.">
                                <IconButton
                                    className="go-back-button"
                                    onClick={() => {
                                        setShowingAppointmentBlocks(null);
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <AppointmentTypeRow
                            appointmentType={showingAppointmentBlocks}
                            calendars={calendars}
                            activeReservationSessions={activeReservationSessions}
                        />
                    </div>

                    <AppointmentBlockSelector
                        {...props}
                        calendars={calendars}
                        totalWidth={870}
                        loadSessionsOnMount={false}
                        calendar={_.find(calendars, (c) => c.calendar_id === calendarId)}
                        subLabel={
                            'Please choose which appointment block you would like to schedule this appointment on.'
                        }
                        allowContinueWithoutSelection={true}
                        onAppointmentBlocksModified={() => {
                            getLiveReusableReservationSessions();
                        }}
                        onNext={() => {
                            onChange(showingAppointmentBlocks);
                        }}
                        {...{ calendarId, setCalendarId }}
                        {...{ appointmentLengthMinutes, setAppointmentLengthMinutes }}
                        {...{ timeRange, setTimeRange }}
                        {...{ doseInfo, setDoseInfo }}
                    />
                </div>
            )}
        </div>
    );
}

function AppointmentTypeRow({ appointmentType, onClick, calendars, activeReservationSessions, focused }) {
    const [chosenCalendarId] = useState(
        _.get(appointmentType, 'calendar.calendar_id', _.get(_.first(calendars), 'calendar_id'))
    );

    const calendar = _.find(
        calendars,
        ({ calendar_id }) =>
            calendar_id === _.get(appointmentType, 'appointment_calendar_id', _.get(_.first(calendars), 'calendar_id'))
    );

    const hasAppointmentBlocks = supportsAppointmentBlocks(appointmentType);
    const numberAppointmentBlocks = _.size(
        _.filter(
            activeReservationSessions,
            ({ appt_type_id }) => appt_type_id === 'a448c312-cb31-4492-87fc-6e442a2f6118'
        )
    );

    return (
        <div
            className={classnames({ 'appointment-type-row': true, focused })}
            onClick={() =>
                onClick({
                    ...appointmentType,
                    appointment_calendar_id: chosenCalendarId,
                    calendar: _.find(calendars, (c) => c.calendar_id === chosenCalendarId),
                })
            }
        >
            <div
                className="image-box"
                style={{
                    backgroundImage: `url(${getTemplateImageUrl({ image_ref: appointmentType.image_ref })})`,
                    backgroundColor: '#eee',
                    backgroundSize: 'cover',
                    width: '135px',
                    height: '90px',
                }}
            />
            <div className="text">
                <div className="title">{appointmentType.appointment_label}</div>

                {!hasAppointmentBlocks ? (
                    <div className="details">
                        <div className="length">
                            <ScheduleIcon /> {appointmentType.appointment_length_minutes} minutes
                        </div>
                        <div className="calendar">
                            <EventIcon style={{ color: _.get(calendar, 'color') }} />{' '}
                            <span className="calendar-name">{_.get(calendar, 'name')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="details">
                        <div className="appt-blocks-available">
                            <DateRangeIcon /> {numberAppointmentBlocks} available appointment{' '}
                            {pluralize('block', numberAppointmentBlocks)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function supportsAppointmentBlocks(apptType) {
    return apptType.appointment_label === 'COVID-19 Vaccination';
}

function mapStateToProps(state) {
    const { apptCalendar, apptType, apptReservation } = state;

    return {
        availableApptTypes: _.filter(
            selectApptTypesWithSessions(state),
            ({ sessions, apptType }) => apptType['multi_session'] === 1 || !_.isEmpty(sessions)
        ),
        isLoading:
            apptCalendar.loadingAvailableCalendars ||
            _.isNil(apptCalendar.availableCalendars) ||
            apptType.loadingAvailableApptTypes ||
            _.isNil(_.get(apptType, 'availableApptTypes.response')) ||
            _.get(apptReservation, 'liveReusableSessions.loading') ||
            _.isNil(_.get(apptReservation, 'liveReusableSessions.response')),
    };
}

const bindActionsToDispatch = {
    getAvailableAppointmentTypes,
};

export default connect(mapStateToProps, bindActionsToDispatch)(AppointmentTypeContent);
