import _ from 'lodash';

/*
 * Sort by most recently completed assessed attributes, but let prioritized ones dominate the sort
 */
export function sortAssessedAttributes(patient, prioritizedAttributes = new Set()) {
    const attrs = _.keys(patient.assessed_attributes);

    const attributeSetTimestamps = {};
    const completedAssessments = _.sortBy(
        _.filter(patient.assessments, ({ complete, declined }) => !declined && complete),
        ({ completed_date }) => completed_date
    );

    _.each(completedAssessments, ({ completed_date, assessed_attributes }) => {
        if (_.isString(completed_date)) {
            _.each(assessed_attributes, (attr) => {
                attributeSetTimestamps[attr] = completed_date;
            });
        }
    });

    return _.sortBy(attrs, (attr) => {
        // if this is a prioritized on then just return "0-{attr}" since this will lexagraphically sort the prioritized ones
        // before any completed date timestamps (and in alphabetical order)
        if (prioritizedAttributes.has(attr)) {
            return `0-${_.toLower(attr)}`;
        }
        return _.toLower(attributeSetTimestamps[attr]);
    });
}

export function sortAvailableAssessedAttributesAlphabetically(availableAssessedAttributes) {
    const attrs = [];
    _.forIn(availableAssessedAttributes, ({ label, values }, id) => {
        attrs.push({ id, label, values });
    });

    return _.sortBy(attrs, ({ label }) => label);
}
