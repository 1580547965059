import { restrictedResources } from 'casl';

const icon = {
    right: 'keyboard_arrow_right',
};

const { inbox } = restrictedResources;
export const InboxSettingsMenuItems = [
    {
        text: 'General Settings',
        to: '/settings/inbox/general',
        icon,
        isInternal: false,
        caslSubject: inbox.generalSettings.subject,
    },
    {
        text: 'Message Templates',
        to: '/settings/inbox/template',
        icon,
        isInternal: false,
        caslSubject: inbox.messageTemplates.subject,
    },
];
