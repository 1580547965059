import './PatientNameSearch.sass';
import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { fetchPatientData } from 'redux/Patient/PatientData/action';
import { connect } from 'react-redux';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import _ from 'lodash';
import PatientsTable from './PatientsTable';
import { formatRawPhone, formatRawDate, getAbbreviatedDateInputFormatByCountryCode } from 'utils/helper';
import LocalStorageService from '../../utils/localStorageService';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { getNextFocusablePatientIndex } from './PatientsTable';
import queryString from 'query-string';

function PatientNameSearch({
    fetchPatientData,
    patientData,
    initialValue,
    activeLocationCountryCode,
    width,
    height,
    onSelect,
    location,
}) {
    const props = arguments[0];

    const [query, setQuery] = useState(_.isString(initialValue) ? initialValue : undefined);
    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    const [focusedIndex, setFocusedIndex] = useState(0);

    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    const queryParams = queryString.parse(location.search);
    const sort = _.get(
        queryParams,
        'sort',
        nameFormat.startsWith('last') ? 'last_name.keyword:asc' : 'full_name.keyword:asc'
    );
    const [sortField, sortDirection] = sort.split(':');

    useEffect(() => {
        fetchPatientData(_.isEmpty(query) ? undefined : query, sort, new Set(), 0, 99, { fieldToQuery: 'name' });
    }, [query, sort]);

    const updateQuery = _.debounce((v) => setQuery(v), 300);

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });
    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);
    const formatPhone = (phone) => formatRawPhone(phone, activeLocationCountryCode);

    const count = _.get(patientData, 'patientCount');

    return (
        <div className="patient-name-search">
            <div className="search-and-count">
                <TextField
                    data-cy="name-search-text-field"
                    className="search-text-field"
                    style={{ width: '400px' }}
                    placeholder="Search for patients by name..."
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ fontSize: '30px' }} />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => updateQuery(_.trim(e.target.value))}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            const focusedPatient = _.get(patientData, `loadedPatients`, {})[focusedIndex];

                            if (!_.isNil(focusedPatient)) {
                                onSelect(focusedPatient);
                            }
                        } else if (e.key === 'ArrowDown') {
                            if (_.isFinite(focusedIndex)) {
                                const nextIndex = getNextFocusablePatientIndex(false, focusedIndex, patientData);
                                if (_.isFinite(nextIndex) && nextIndex < patientData.patientCount) {
                                    setFocusedIndex(nextIndex);
                                }
                            }
                        } else if (e.key === 'ArrowUp') {
                            if (_.isFinite(focusedIndex) && focusedIndex > 0) {
                                const nextIndex = getNextFocusablePatientIndex(false, focusedIndex, patientData, false);
                                if (_.isFinite(nextIndex)) {
                                    setFocusedIndex(nextIndex);
                                }
                            }
                        }
                    }}
                    {..._.pick(props, ['autoFocus'])}
                />

                {!_.isFinite(count) ? null : (
                    <span data-cy="name-search-patient-count">
                        {numeral(count).format('0,0')} {pluralize('patient', count)}
                    </span>
                )}
            </div>
            <div className="table-wrapper" style={{ width: `${width}px`, height: `${height - 80}px` }}>
                <PatientsTable
                    {...props}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    isSingleSelect
                    hidePatientActions
                    formatDate={formatDate}
                    formatPhone={formatPhone}
                    onSingleSelect={onSelect}
                    nameFormat={nameFormat}
                    setNameFormat={setNameFormat}
                    focusedIndex={focusedIndex}
                    loadMoreRows={async ({ startIndex, stopIndex }) => {
                        fetchPatientData(
                            _.isEmpty(query) ? undefined : query,
                            undefined,
                            new Set(),
                            startIndex,
                            stopIndex,
                            { fieldToQuery: 'name' }
                        );
                    }}
                />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { patientData } = state;

    return {
        patientData,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
    };
}

const bindActionsToDispatch = {
    fetchPatientData,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientNameSearch);
