import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { logUtil } from 'utils/log';
import Snowplow from 'snowplow';

export class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logUtil.logError('App error boundary hit', error);
    }

    handleReload = () => {
        Snowplow.structEvent('NPE', 'site-reload', null, 'app-error-boundary');
        // pass true for forceGet param to load from non cache
        window.location.reload(true);
    };

    render() {
        const { children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            // utilizing vanilla Material-UI components to reduce risk
            // of our fallback alert having bugs introduced into it
            return (
                <Dialog
                    aria-labelledby="app-error-dialog-title"
                    aria-describedby="app-error-dialog-description"
                    open={hasError}
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                >
                    <DialogTitle id="app-error-dialog-title">Unexpected issue</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="app-error-dialog-description">
                            Please reload the application. There was an unexpected error.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleReload}
                            style={{ backgroundColor: '#3793ff', color: '#fff' }}
                            variant="contained"
                            disableElevation
                        >
                            Reload
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return children;
    }
}
