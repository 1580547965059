import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import { AppDetails } from './AppDetails';

const Dialog = withStyles({
    paper: {
        width: '100vw',
        height: '100vh',
    },
    paperWidthMd: {
        width: '100vw !important',
        height: '100vh !important',
    },
})(DpDialog.Dialog);

class ExistingBrandedAppsDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.addAppInformationToState();
        this.panels = {
            generalDetailsPanel: 0,
            branchDetailsPanel: 1,
            buildDetailsPanel: 2,
        };
        this.requiredFields = {
            generalDetails: [
                'name',
                'displayName',
                'shortDescription',
                'longDescription',
                'androidIdentifier',
                'appBundleIdentifier',
            ],
            branchDetails: [],
        };

        this.state = {
            panelsUpdated: {
                [this.panels.generalDetailsPanel]: false,
                [this.panels.branchDetailsPanel]: false,
                [this.panels.buildDetailsPanel]: false,
            },
            erroneousPanels: [],
        };
        this.handleTextChange = (event, panelId) => this.handleChange(event.target.name, event.target.value, panelId);
        this.handleCheckboxValueChange = (event, panelId) =>
            this.handleChange(event.target.name, event.target.checked, panelId);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.app, this.props.app)) {
            this.addAppInformationToState();
        }
        if (prevProps.isProcessing && !this.props.isProcessing) {
            // Clear the panelsUpdate information so that the form is not marked dirty
            this.setState({ panelsUpdated: { ..._.get(this, 'defaultState.panelsUpdated', {}) } });
        }
    }

    updateErroneousPanels = () => {
        const generalDetailsHasErrors = _.some(this.state.app, (field) => !_.isEmpty(field.error));
        const branchDetailsHasErrors = _.some(this.state.app.branch, (field) => !_.isEmpty(field.error));
        const erroneousPanels = [];

        if (generalDetailsHasErrors) {
            erroneousPanels.push(this.panels.generalDetailsPanel);
        }
        if (branchDetailsHasErrors) {
            erroneousPanels.push(this.panels.branchDetailsPanel);
        }
        this.setState({ ...this.state, erroneousPanels });
    };

    handleChange = (key, newValue, panelId) => {
        if (panelId === this.panels.branchDetailsPanel) {
            this.setState(
                {
                    panelsUpdated: {
                        ...this.state.panelsUpdated,
                        [panelId]: true,
                    },
                    app: {
                        ...this.state.app,
                        branch: {
                            ...this.state.app.branch,
                            [key]: {
                                ...this.state.app.branch[key],
                                value: newValue,
                                error: '',
                            },
                        },
                    },
                },
                () => this.updateErroneousPanels()
            );
        } else {
            this.setState(
                {
                    panelsUpdated: {
                        ...this.state.panelsUpdated,
                        [panelId]: true,
                    },
                    app: {
                        ...this.state.app,
                        [key]: {
                            ...this.state.app[key],
                            value: newValue,
                            error: '',
                        },
                    },
                },
                () => this.updateErroneousPanels()
            );
        }
    };

    addAppInformationToState = () => {
        const { app } = this.props;
        if (app) {
            const getMappedValues = (object, requiredFields) => {
                // Fill in the missing required fields with empty values, for the required
                // attribute to be set for that field
                _.forEach(requiredFields, (field) => (object[field] = object[field] || ''));

                return _.mapValues(object, (value, key) => ({
                    value,
                    required: _.indexOf(requiredFields, key) !== -1,
                }));
            };

            const appInformation = getMappedValues(app, this.requiredFields.generalDetails);
            _.set(appInformation, 'teamId.requiredLength', 10);
            appInformation.branch = getMappedValues(app.branch, this.requiredFields.branchDetails);
            this.setState({ ...this.state, app: appInformation }, () => {
                this.defaultState = _.cloneDeep(this.state);
            });
        }
    };

    setValidationErrorsIfAny = () => {
        const nextState = { ...this.state };

        const getErrorsPopulated = (object) => {
            let hasErrors = false;

            _.forEach(object, (field, key) => {
                const value = _.get(field, 'value', '');

                if (field.required && _.isEmpty(value)) {
                    hasErrors = true;
                    object[key] = {
                        ...object[key],
                        error: 'This is a required field. Please enter the value',
                    };
                } else if (!_.isEmpty(value) && field.requiredLength && value.length !== field.requiredLength) {
                    hasErrors = true;
                    object[key] = {
                        ...object[key],
                        error: `Required length for ${field.label} is ${field.requiredLength}`,
                    };
                }
            });
            return hasErrors;
        };

        const generalDetailsHasError = getErrorsPopulated(nextState.app);
        const branchDetailsHasError = getErrorsPopulated(nextState.app.branch);
        const hasErrors = generalDetailsHasError || branchDetailsHasError;
        if (hasErrors) {
            this.setState(_.cloneDeep(nextState), () => this.updateErroneousPanels());
        }
        return hasErrors;
    };

    handleSave = () => {
        const hasErrors = this.setValidationErrorsIfAny();
        if (hasErrors) return;

        const { panelsUpdated, app } = this.state;
        const isGeneralDetailsUpdated = panelsUpdated[this.panels.generalDetailsPanel];
        const isBranchDetailsUpdated = panelsUpdated[this.panels.branchDetailsPanel];
        const payload = {};

        if (isGeneralDetailsUpdated) {
            payload.generalDetailsPayload = _.mapValues(app, (value) => value.value);
        }
        if (isBranchDetailsUpdated) {
            payload.branchPayload = _.mapValues(app.branch, (value) => value.value);
        }
        this.setState({
            erroneousPanels: [],
        });
        this.props.onSave({ appId: app.id.value, payload });
    };

    handleClose = () => {
        this.props.onClose();
        this.setState(this.defaultState);
    };

    render() {
        const { show, onEdit, editMode, app: passedInApp } = this.props;
        const { app, erroneousPanels } = this.state;
        const hasErrors = erroneousPanels.length !== 0;
        const isDirty = _.some(_.values(this.state.panelsUpdated), (value) => value === true);
        const isBrandedApp = _.get(passedInApp, 'appType', '') === 'branded';
        const handleEdit = () => onEdit(passedInApp);

        return (
            show && (
                <Dialog id="non-branded-apps-dialog" open={show} onClose={this.handleClose} style={{ width: '100%' }}>
                    <DpDialog.Content id="non-branded-apps-content" style={{ width: '100%' }}>
                        <DpDialog.ContentHeader heading={_.get(app, 'name.value', '')} />
                        <AppDetails
                            app={app || {}}
                            editMode={editMode}
                            panels={this.panels}
                            erroneousPanels={erroneousPanels}
                            handleTextChange={this.handleTextChange}
                            handleCheckboxValueChange={this.handleCheckboxValueChange}
                        />
                    </DpDialog.Content>

                    <DpDialog.Actions style={{ padding: '15px', marginRight: '48px' }}>
                        <Button onClick={this.handleClose} color="default">
                            {editMode ? 'Cancel' : 'Close'}
                        </Button>

                        {editMode ? (
                            <Button
                                onClick={this.handleSave}
                                color={hasErrors ? 'secondary' : 'primary'}
                                disabled={!isDirty}
                            >
                                Save
                            </Button>
                        ) : (
                            isBrandedApp && (
                                <Button
                                    onClick={handleEdit}
                                    color="primary"
                                    disabled={_.get(app, 'appType.value', '') !== 'branded'}
                                >
                                    Edit
                                </Button>
                            )
                        )}
                    </DpDialog.Actions>
                </Dialog>
            )
        );
    }
}

export { ExistingBrandedAppsDialog };
