import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Snowplow from 'snowplow';

import { SettingTitle } from '../Common/SettingTitle';
import Newsletter from './Newsletter';
import NoAccess from 'components/Common/NoAccess';
import { userActions, restrictedResources, AbilityContext } from 'casl';

const NewsletterWrapper = ({ isNewsletterEnabled, isNewsletterPackageEnabled }) => {
    const subtitle =
        'This page is shown to Internal Users only. Adding or changing the From Name or From Email here will not apply until the email account is activated/verified by the Build Team.';
    const abilityContext = useContext(AbilityContext);
    const canUpdate = abilityContext.can(userActions.update, restrictedResources.newsletter.subject);
    const canView = abilityContext.can(userActions.view, restrictedResources.newsletter.subject);
    const isNewsletterVisible = isNewsletterEnabled && isNewsletterPackageEnabled && canView;

    useEffect(() => {
        Snowplow.pageView('Settings Newsletter');
    }, []);

    return (
        <Grid className="settings-content--grid-container">
            <SettingTitle title="Newsletter" subtitle={subtitle} />
            {isNewsletterVisible ? <Newsletter canUpdate={canUpdate} /> : <NoAccess />}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    isNewsletterEnabled: state.pharmacy.newslettersV2Enabled,
    isNewsletterPackageEnabled: state.pharmacy.newsletterReportsEnabled,
});

export default connect(mapStateToProps, null)(NewsletterWrapper);
