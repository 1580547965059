import React, { Component } from 'react';
import { Card, Cell, CardText, Grid, Button, CircularProgress } from 'react-md';
import { connect } from 'react-redux';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    updateGreetingMessage,
    addGreeting,
    deleteGreeting,
    changeGreetingType,
    editGreeting,
    closeGreetingEditor,
    fileUpload,
    saveGreeting,
    saveFilename,
    changeGreetingScript,
} from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import GreetingEditor from 'components/Common/GreetingEditor';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class AAMainGreeting extends Component {
    componentDidMount() {
        const { fetchMainGreeting, loadGreetingFiles } = this.props;
        loadGreetingFiles();
        fetchMainGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, loadGreetingFiles, fetchMainGreeting } = this.props;
        if (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id) {
            loadGreetingFiles();
            fetchMainGreeting();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    removeGreeting = () => {
        const { defaultGreeting, deleteGreeting, fetchMainGreeting } = this.props;
        const newGreeting = { id: defaultGreeting.id, aa_welcome_greeting_id: '', type: 'aa_welcome' };

        deleteGreeting(newGreeting).then((respData) => {
            if (!('err' in respData)) {
                fetchMainGreeting();
            }
        });
    };

    async onSave({ fileObj, editedFileName, filenameWithExt }) {
        const {
            newGreeting,
            defaultGreeting,
            loadGreetingFiles,
            fetchMainGreeting,
            saveFile,
            saveGreeting,
            fileUpload,
            closeGreetingEditor,
            displayToast,
        } = this.props;
        const greetingPayload = { id: defaultGreeting.id, aa_welcome_greeting_id: newGreeting.greeting_id };

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            greetingPayload.aa_welcome_greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            saveFile({ name: newFilename, id: greetingPayload.aa_welcome_greeting_id });
        }

        const saveMsgResp = await saveGreeting({ newGreeting: { ...greetingPayload }, type: 'MAIN' });
        if (saveMsgResp.err) {
            displayToast({ text: 'Auto Attendant greeting update failed', type: 'error' });
        } else {
            fetchMainGreeting();
            closeGreetingEditor();
            displayToast({ text: 'Auto Attendant greeting updated', type: 'success' });
        }
    }

    render() {
        const {
            displayToast,
            greetingFiles,
            defaultGreeting,
            showGreetingCreator,
            newGreeting,
            updateGreetingMessage,
            closeGreetingEditor,
            isFilenameSaving,
            loadingMainGreeting,
            editGreeting,
            addGreeting,
            changeGreetingScript,
            changeGreetingType,
            auth: { selectedLocation, selectedCustomer },
        } = this.props;
        const { write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.generalGreeting;

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });

        return (
            <Grid className="greeting-container">
                {/* Note, write here means the user role can create, update, delete general greetings */}
                <Can I={write} this={caslSubject} passThrough>
                    {(writeAllowed) => (
                        <Cell size={12}>
                            <Card className="outer-card">
                                <CardHeader title="General Greeting" onBack={this.onBack} />
                                <CardText>
                                    <Card className="inner-card">
                                        <CardText>
                                            {loadingMainGreeting ? (
                                                <CircularProgress id="main-greeting-spinner" />
                                            ) : (
                                                <span className="greetings-list">
                                                    <SectionTitle text="Default Main Greeting" />
                                                    <SectionSubTitle text="This is the message that callers will hear as soon as your IVR answers" />

                                                    <div className="grid-container table-title">
                                                        {defaultGreeting['aa_welcome_greeting_type'] !==
                                                            'text/plain' && <Cell size={1}>&nbsp;</Cell>}
                                                        <Cell
                                                            size={
                                                                defaultGreeting['aa_welcome_greeting_type'] ===
                                                                'text/plain'
                                                                    ? 4
                                                                    : 3
                                                            }
                                                        >
                                                            Title
                                                        </Cell>
                                                        <Cell size={1}>Status</Cell>
                                                        <Cell size={7}>&nbsp;</Cell>
                                                    </div>

                                                    {defaultGreeting && defaultGreeting.aa_welcome_greeting_id && (
                                                        <span className="main-greeting">
                                                            <Greetings
                                                                className="blue-bordered"
                                                                perms={selectedLocation.perms}
                                                                greetingDetail={defaultGreeting}
                                                                greetingFileList={greetingFileList}
                                                                handleEdit={editGreeting.bind(this, {
                                                                    defaultGreeting,
                                                                    type: 'aa_welcome',
                                                                })}
                                                                isDeleting={defaultGreeting.aa_welcome_processing}
                                                                handleDelete={this.removeGreeting}
                                                                type="aa_welcome"
                                                                hideActions={!writeAllowed}
                                                            />
                                                        </span>
                                                    )}

                                                    {defaultGreeting && !defaultGreeting.aa_welcome_greeting_id && (
                                                        <div className="grid-container blue-bordered no-default-greeting-msg">
                                                            <Cell size={12} className="table-data centered-content ">
                                                                There is no default greeting configured for this
                                                                location's IVR.
                                                            </Cell>
                                                        </div>
                                                    )}

                                                    <Grid>
                                                        <Cell size={12} className="centered-content button-control">
                                                            {writeAllowed && !defaultGreeting.aa_welcome_greeting_id && (
                                                                <Button
                                                                    className="add-btn"
                                                                    flat
                                                                    secondary
                                                                    onClick={() => addGreeting('aa_welcome')}
                                                                >
                                                                    + ADD A NEW GREETING
                                                                </Button>
                                                            )}
                                                            {!writeAllowed &&
                                                                !defaultGreeting.aa_welcome_greeting_id && (
                                                                    <div>
                                                                        You don't have permission to configure greeting
                                                                    </div>
                                                                )}
                                                        </Cell>
                                                    </Grid>
                                                </span>
                                            )}
                                        </CardText>
                                    </Card>

                                    <GreetingEditor
                                        custPerms={selectedCustomer.perms}
                                        isSaving={isFilenameSaving || newGreeting.processing}
                                        dialogTitle={newGreeting.id ? 'Edit greeting' : 'Add a new greeting'}
                                        isVisible={showGreetingCreator}
                                        greetingFiles={greetingFiles}
                                        greeting={newGreeting}
                                        defaultGreeting={defaultGreeting}
                                        greetingId={newGreeting.greeting_id || ''}
                                        greetingUrl={newGreeting.greeting_url}
                                        greetingType={newGreeting.greeting_type_id}
                                        greetingScript={newGreeting.greeting_text}
                                        handleChange={updateGreetingMessage}
                                        closeHandler={closeGreetingEditor}
                                        saveHandler={(data) => this.onSave(data)}
                                        showToastMessage={displayToast}
                                        changeGreetingType={changeGreetingType}
                                        changeGreetingScript={changeGreetingScript}
                                    />
                                </CardText>
                            </Card>
                        </Cell>
                    )}
                </Can>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchMainGreeting: () => dispatch(fetchMainGreeting()),
    addGreeting: (data) => dispatch(addGreeting(data)),
    closeGreetingEditor: () => dispatch(closeGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    deleteGreeting: (data) => dispatch(deleteGreeting(data)),
    updateGreetingMessage: (data) => dispatch(updateGreetingMessage(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    editGreeting: (data) => dispatch(editGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changeGreetingType: (data) => dispatch(changeGreetingType(data)),
    changeGreetingScript: (data) => dispatch(changeGreetingScript(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AAMainGreeting);
