import React, { Component } from 'react';
import { TextField } from 'react-md';

class EditableBackendValues extends Component {
    state = {};

    handleChange = (value, event) => {
        this.props.valueChange(value, event);
    };

    // Within TextField, we have to convert the props to strings using .toString() for the value
    // attribute because there are certain numbers that are seen as identical by React's diffing engine
    // such as 012 and 12. Since strings are immutable, generating a new string guarantees a rerender
    render() {
        return (
            <div className="ROICalculator__EditableBackendValues">
                <div className="ROICalculator__EditableBackendValues--title">Step 1: Add Data</div>
                <div className="ROICalculator__EditableBackendValues--data">
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="refillCountValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.refillCountValue.toString()}
                                onChange={this.handleChange}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">Refill Count</div>
                    </div>
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="newPatientTransfersValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.newPatientTransfersValue.toString()}
                                onChange={this.handleChange}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">New Patient Transfers</div>
                    </div>
                    <div className="ROICalculator__EditableBackendValues--dataContainer">
                        <div className="ROICalculator__EditableBackendValues--value">
                            <TextField
                                id="outboundAlertsValue"
                                type="number"
                                className="ROICalculator__EditableBackendValues--value"
                                value={this.props.outboundAlertsValue.toString()}
                                onChange={this.handleChange}
                                block
                            />
                        </div>
                        <div className="ROICalculator__EditableBackendValues--metric">Outbound Alerts</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditableBackendValues;
