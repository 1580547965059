import React from 'react';
import LearnMoreButton from 'components/Common/LearnMoreButton';

const appType = 'voicemail';

const EmptyVoicemail = () => {
    return (
        <div className="empty-voicemail">
            <img src="/empty-voicemail-screenshot.png" alt="Empty Voicemail screenshot" className="bordered-box" />

            <p className="title">
                <span> NEW </span> Cloud IVR
            </p>

            <p>
                Our new Cloud IVR is an easy solution for fielding incoming calls and sending outgoing reminders. Save
                time for you and your team with our state-of-the-art Interactive Voice Response System.
            </p>
            <p style={{ alignSelf: 'flex-start' }}> With the Cloud IVR you can… </p>

            <ul className="list">
                <li> View, listen and download voicemails </li>
                <li> Receive refill requests at anytime </li>
                <li> Set up transfers and reminders from anywhere </li>
            </ul>

            <LearnMoreButton appType={appType} variant="tabs" />
        </div>
    );
};

export default EmptyVoicemail;
