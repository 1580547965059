import React, { Component, Fragment } from 'react';
import { Grid, Cell, Divider, CircularProgress, Card, CardText, FontIcon } from 'react-md';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { stripISDCode } from 'utils/helper';
import {
    fetchReadVoicemails,
    fetchUnreadVoicemails,
    fetchVoicemailAudio,
    markRead,
    sortVoicemail,
} from 'redux/actionCreators/Voicemail';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { ConfirmDownload } from 'components/Voicemail/ConfirmDownload';
import NotificationDialog from 'components/Common/NotificationDialog';
import { VoicemailContainer } from 'components/Voicemail/VoicemailContainer';
import EmptyVoicemail from './EmptyVoicemail';
import { toDigits } from 'utils/helper';
import DATE_SEARCH_FORMATS from 'constants/DateSearchFormats';
import { fabStyles } from 'components/Common';
import SearchInput from 'components/Common/SearchInput';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

export class Voicemail extends Component {
    constructor(props) {
        super(props);
        this.downloadData = null;
        this.state = {
            listenListCollapse: true,
            newListCollapse: false,
            isDownloadConfirmVisible: false,
            filterData: {},
            track: {
                current: '',
                previous: '',
            },
        };
    }

    componentDidMount() {
        const { fetchReadVoicemails, fetchUnreadVoicemails, ivrEnabled } = this.props;
        if (ivrEnabled) {
            fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 });
            fetchReadVoicemails({ isRead: 1, startRow: 0, numRows: 25 });
        }
    }

    componentDidUpdate(prevProps) {
        const { auth, fetchReadVoicemails, fetchUnreadVoicemails, ivrEnabled } = this.props;

        if (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id) {
            if (ivrEnabled) {
                fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 });
                fetchReadVoicemails({ isRead: 1, startRow: 0, numRows: 25 });
            }
            this.resetFilter();
        }

        // This will work for Pharmacy change and avoid unnecessary call to fetch voicemail
        if (prevProps.ivrEnabled !== ivrEnabled && ivrEnabled) {
            fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 });
            fetchReadVoicemails({ isRead: 1, startRow: 0, numRows: 25 });
            this.resetFilter();
        }
    }

    resetFilter() {
        this.setState({ filterData: {} });
    }

    setCurrentTrack = (voicemailId) => {
        const { track } = this.state;
        if (voicemailId !== track.current) {
            track.previous = track.current;
        }

        track.current = voicemailId;
        this.setState({ track });
    };

    sortColumn = (data) => {
        const {
            sortVoicemail,
            voicemailDetails: { sortData },
        } = this.props;
        let order = 'desc';
        if (sortData[data.type][data.column]) {
            order = sortData[data.type][data.column] === 'desc' ? 'asc' : 'desc';
        }

        sortVoicemail({ order, type: data.type, column: data.column });
    };

    searchVoicemails = (searchString) => {
        let filterData = {};

        if (searchString.length > 2) {
            // eslint-disable-next-line
            const dateInput = moment(searchString, DATE_SEARCH_FORMATS, true);

            if (dateInput.isValid()) {
                filterData['callDateUTC'] = dateInput.startOf('day');
            } else {
                filterData['searchString'] = searchString.toLowerCase();
            }
            window.scrollTo(0, 0);
        }

        this.setState({
            filterData,
            listenListCollapse: false,
        });
    };

    filterVoicemail = (voicemail) => {
        const { filterData } = this.state;
        const msgTypeList = {
            general: 0,
            prescriber: 1,
            'new patient': 5,
            doctor: 1,
            dr: 1,
        };

        if (filterData && Object.keys(filterData).length) {
            const searchString = filterData['searchString'] || '';
            const matchingMsgType = Object.keys(msgTypeList).find(
                (type) => searchString && type.indexOf(searchString) > -1
            );

            /* eslint-disable */
            const matchUSPhone = searchString.match(/^[\+]?[1]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/);
            /* eslint-enable */
            const phoneString = matchUSPhone ? toDigits(searchString) : searchString;

            return voicemail.filter((vm) => {
                let result = false;
                if (filterData.hasOwnProperty('callDateUTC')) {
                    const vmDate = moment.utc(vm['callDateUTC']).local().startOf('day');
                    result = filterData['callDateUTC'].isSame(vmDate);
                } else {
                    const phone = vm['phone'] ? stripISDCode(vm['phone']) : '';
                    const rxNumber = vm['refillNum'] || '';

                    result =
                        rxNumber.indexOf(searchString) > -1 ||
                        (!rxNumber && matchingMsgType && msgTypeList[matchingMsgType] === vm['messageType']) ||
                        phone.indexOf(phoneString) > -1;
                }

                return result;
            });
        } else {
            return voicemail;
        }
    };

    getVoicemailContainerParams = () => {
        const {
            auth: { selectedCustomer, selectedLocation },
            voicemailDetails: { unreadVoicemails, readVoicemails, sortData, readTotalMails, unreadTotalMails },
            fetchVoicemailAudio,
            markRead,
        } = this.props;
        const { track, listenListCollapse, newListCollapse } = this.state;

        let filteredReadVoicemails = this.filterVoicemail(readVoicemails);
        let filteredUnreadVoicemails = this.filterVoicemail(unreadVoicemails);
        const noResult =
            (readVoicemails.length > 0 || unreadVoicemails.length > 0) &&
            readVoicemails.length === readTotalMails &&
            unreadVoicemails.length === unreadTotalMails &&
            filteredReadVoicemails.length === 0 &&
            filteredUnreadVoicemails.length === 0;

        const commonDetails = {
            fetchVoicemailAudio,
            markRead,
            setCurrentTrack: this.setCurrentTrack,
            track,
            custPerms: selectedCustomer.perms,
            perms: selectedLocation.perms,
            toggleCollapse: this.toggleCollapse,
            showConfirmDownload: this.showConfirmDownload,
            sorting: sortData,
            sortColumn: this.sortColumn,
            searchVoicemails: this.searchVoicemails,
            noResult: noResult,
        };

        const unreadVoicemailsContainerDetails = {
            titleMsg: 'New Messages',
            voicemails: filteredUnreadVoicemails,
            type: 'unread',
            icon: 'ring_volume',
            msg: 'You don’t have any new messages right now',
            collapsed: newListCollapse,
            totalMails: unreadTotalMails,
            ...commonDetails,
        };

        const readVoicemailsContainerDetails = {
            titleMsg: 'Listened',
            voicemails: filteredReadVoicemails,
            type: 'read',
            icon: 'phone',
            msg: "You don't have any messages in this list",
            collapsed: listenListCollapse,
            totalMails: readTotalMails,
            ...commonDetails,
        };

        return { unreadVoicemailsContainerDetails, readVoicemailsContainerDetails };
    };

    toggleCollapse = (type) => {
        let { listenListCollapse, newListCollapse } = this.state;

        if (type === 'read') {
            listenListCollapse = !listenListCollapse;
        } else {
            newListCollapse = !newListCollapse;
        }

        this.setState({
            newListCollapse,
            listenListCollapse,
        });
    };

    showConfirmDownload = (data) => {
        const {
            auth: {
                userAccount: { user_id },
            },
        } = this.props;
        this.downloadData = data;
        const localStore = localStorage.getItem('dnd_vm_download');
        const userDnd = localStore ? JSON.parse(localStore) : {};

        if (userDnd[user_id]) {
            this.startDownload();
        } else {
            this.setState({
                isDownloadConfirmVisible: true,
            });
        }
    };

    hideConfirmDownload = () => {
        this.downloadData = null;
        this.setState({
            isDownloadConfirmVisible: false,
        });
    };

    startDownload = () => {
        const { voicemail, type } = this.downloadData;
        const { fetchVoicemailAudio, displayToast } = this.props;

        const filename = this.composeFileName(voicemail);
        this.hideConfirmDownload();

        if (voicemail.audioBlob) {
            this.downloadAudio(filename, voicemail.audioBlob);
        } else {
            displayToast({ text: 'Preparing download', type: 'info' });
            fetchVoicemailAudio({ vmId: voicemail.id, type }).then((resp) => {
                this.downloadAudio(filename, resp.data);
            });
        }
    };

    downloadAudio = (filename, blobData) => {
        if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(new Blob([blobData], { type: 'audio/wav' }), filename);
        } else {
            const blobUrl = window.URL.createObjectURL(blobData);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = blobUrl;
            a.download = filename;
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
            }, 0);
        }
    };

    composeFileName = (voicemailData) => {
        const callerId = stripISDCode(voicemailData.phone);
        const date = moment.utc(voicemailData.callDateUTC).local().format('MM-DD-YYYY_HHmm');
        return `VM_${callerId}_${date}.wav`;
    };

    setDnd = (check) => {
        const {
            auth: {
                userAccount: { user_id },
            },
        } = this.props;
        const localStore = localStorage.getItem('dnd_vm_download');
        const dndInfo = localStore ? JSON.parse(localStore) : {};
        dndInfo[user_id] = check;
        localStorage.setItem('dnd_vm_download', JSON.stringify(dndInfo));
    };

    render() {
        const {
            voicemailDetails: {
                loadingReadVoicemails,
                loadingUnreadVoicemails,
                readVoicemails,
                unreadVoicemails,
                silentLoadingReadVoicemails,
                silentLoadingUnreadVoicemails,
                readTotalMails,
                unreadTotalMails,
            },
            fetchReadVoicemails,
            fetchUnreadVoicemails,
            classes,
            ivrEnabled,
            isPharmacyLoading,
        } = this.props;

        const { listenListCollapse, newListCollapse, isDownloadConfirmVisible, filterData } = this.state;
        const { unreadVoicemailsContainerDetails, readVoicemailsContainerDetails } = this.getVoicemailContainerParams();
        const isLoading = loadingReadVoicemails || loadingUnreadVoicemails || isPharmacyLoading;

        return (
            <div className="voicemail-container">
                {!ivrEnabled ? (
                    <div className="centered-content">
                        <EmptyVoicemail />
                    </div>
                ) : (
                    <Fragment>
                        <div className="header">
                            <div className="header-inner-wrapper">
                                <div className="search-bar">
                                    <div className="voicemail-info">
                                        <Tooltip
                                            placement="top"
                                            classes={{
                                                tooltip: 'info-tooltip',
                                            }}
                                            enterTouchDelay={0}
                                            title="You can search for phone number, date or prescription number in the following format:
																Date: MM/DD/YYYY, YYYY/MM/DD, phone number: (XXX) YYY-ZZZZ, XXX-YYY-ZZZZ, XXXYYYZZZZ.
																The search will be performed on the records shown.
																To add more records to the screen select 'load more'."
                                        >
                                            <FontIcon
                                                className="info-tooltip-icon"
                                                style={{ marginLeft: '5px', padding: '5px' }}
                                            >
                                                info
                                            </FontIcon>
                                        </Tooltip>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <SearchInput
                                            noResult={readVoicemailsContainerDetails.noResult}
                                            onSearch={readVoicemailsContainerDetails.searchVoicemails}
                                            placeholder="Phone, date, type, prescription number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ivr-link">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to="/settings/phone-system"
                                >
                                    IVR Settings
                                </Button>
                            </div>
                        </div>
                        <div class="voicemail-accordion-container">
                            <VoicemailContainer
                                loading={isLoading}
                                {...unreadVoicemailsContainerDetails}
                                onLoadMore={() => {
                                    const {
                                        voicemailDetails: { unreadVoicemails, unreadTotalMails },
                                    } = this.props;
                                    const loadMore = unreadVoicemails.length < unreadTotalMails;

                                    if (!loadMore) return;

                                    const dispatchUnreadVoiceMailData = {
                                        isRead: 0,
                                        startRow: unreadVoicemails.length,
                                        numRows:
                                            unreadTotalMails - unreadVoicemails.length - 25 > 0
                                                ? 25
                                                : unreadTotalMails - unreadVoicemails.length,
                                        loadMore,
                                    };

                                    fetchUnreadVoicemails(dispatchUnreadVoiceMailData);
                                }}
                            />

                            {unreadVoicemails.length < unreadTotalMails && !newListCollapse && (
                                <Grid>
                                    <Cell size={12} className="centered-content button-control">
                                        {silentLoadingUnreadVoicemails ? (
                                            <CircularProgress id="load-more-voicemail" />
                                        ) : filterData && Object.keys(filterData).length ? (
                                            <Fab
                                                variant="extended"
                                                size="medium"
                                                aria-label="Load More"
                                                id="load-read-btn"
                                                className={classNames(classes.margin, classes.cssRoot)}
                                                onClick={fetchUnreadVoicemails.bind(this, {
                                                    isRead: 0,
                                                    startRow: unreadVoicemails.length,
                                                    numRows: 25,
                                                    loadMore: true,
                                                })}
                                            >
                                                Continue searching on server
                                            </Fab>
                                        ) : null}
                                    </Cell>
                                </Grid>
                            )}

                            {unreadVoicemails.length === unreadTotalMails && !newListCollapse && filterData && (
                                <Grid>
                                    <Cell size={12} className="centered-content info-note">
                                        End of list
                                    </Cell>
                                </Grid>
                            )}

                            <div className="grid-container">
                                <Cell size={12}>
                                    <Divider />
                                </Cell>
                            </div>

                            <VoicemailContainer
                                loading={isLoading}
                                {...readVoicemailsContainerDetails}
                                // onLoadMore={fetchReadVoicemails.bind(this, dispatchUnreadVoiceMailData)}
                                onLoadMore={() => {
                                    const {
                                        voicemailDetails: { readVoicemails, readTotalMails },
                                    } = this.props;
                                    const loadMore = readVoicemails.length < readTotalMails;

                                    if (!loadMore) return;

                                    const dispatchReadVoiceMailData = {
                                        isRead: 1,
                                        startRow: readVoicemails.length,
                                        numRows:
                                            readTotalMails - readVoicemails.length - 25 > 0
                                                ? 25
                                                : readTotalMails - readVoicemails.length,
                                        loadMore,
                                    };

                                    fetchReadVoicemails(dispatchReadVoiceMailData);
                                }}
                            />

                            {readVoicemails.length < readTotalMails && !listenListCollapse && (
                                <Grid>
                                    <Cell size={12} className="centered-content button-control">
                                        {silentLoadingReadVoicemails ? (
                                            <CircularProgress id="load-more-voicemail" />
                                        ) : filterData && Object.keys(filterData).length ? (
                                            <Fab
                                                variant="extended"
                                                size="medium"
                                                aria-label="Load More"
                                                id="load-read-btn"
                                                className={classNames(classes.margin, classes.cssRoot)}
                                                onClick={fetchReadVoicemails.bind(this, {
                                                    isRead: 1,
                                                    startRow: readVoicemails.length,
                                                    numRows: 25,
                                                    loadMore: true,
                                                })}
                                            >
                                                Continue searching on server
                                            </Fab>
                                        ) : null}
                                    </Cell>
                                </Grid>
                            )}

                            {readVoicemails.length === readTotalMails && !listenListCollapse && filterData && (
                                <Grid>
                                    <Cell size={12} className="centered-content info-note">
                                        End of list
                                    </Cell>
                                </Grid>
                            )}
                        </div>
                    </Fragment>
                )}

                <ConfirmDownload
                    isVisible={isDownloadConfirmVisible}
                    onConfirm={this.startDownload}
                    onHide={this.hideConfirmDownload}
                    setDnd={this.setDnd}
                />
                <NotificationDialog
                    dialogMsg="Never miss another voicemail with Digital Pharmacist desktop notifications."
                    dialogImage="./desktop_notification_vm_background.png"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    voicemailDetails: state.voicemails,
    ivrEnabled: state.pharmacy.ivrEnabled,
    auth: { ...state.auth },
    isPharmacyLoading: state.pharmacy.loading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchReadVoicemails: (data) => dispatch(fetchReadVoicemails(data)),
    fetchUnreadVoicemails: (data) => dispatch(fetchUnreadVoicemails(data)),
    fetchVoicemailAudio: (data) => dispatch(fetchVoicemailAudio(data)),
    markRead: (data) => dispatch(markRead(data)),
    sortVoicemail: (data) => dispatch(sortVoicemail(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(fabStyles)(Voicemail));
