import connectTypes from './type';
import connectService from './service';
import config from '../../../config';
import Snowplow, { RxConferPatientContext, RxConferWorkflowContext, StructEventCategories } from '../../../snowplow';
import moment from 'moment';
import _ from 'lodash';
import { PatientUpdateEventType } from '../../../constants/Patient';

export function fetchPatientOpportunities(birthdayDate) {
    return async (dispatch, getState) => {
        const state = getState().connect;

        if (state.loading) {
            return Promise.resolve();
        }

        const opportunitiesFetchStartedAt = new Date().getTime();

        dispatch({
            type: connectTypes.CONNECT_OPPORTUNITIES_REQUEST,
            payload: { birthdayDate, opportunitiesFetchStartedAt },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        try {
            const response = await connectService.loadPatientConnectOpportunities(pharmacyId, locationId, birthdayDate);
            dispatch({
                type: connectTypes.CONNECT_OPPORTUNITIES_SUCCESS,
                payload: { opportunities: response, birthdayDate, opportunitiesFetchStartedAt },
            });
        } catch (error) {
            dispatch({
                type: connectTypes.CONNECT_OPPORTUNITIES_FAILURE,
                payload: { error, birthdayDate, opportunitiesFetchStartedAt },
            });
        }
    };
}

export function clearPatientOpportunities() {
    return {
        type: connectTypes.CLEAR_OPPORTUNITIES,
        payload: {},
    };
}

export function sendBulkMessage(template, inboxUserIds) {
    return async (dispatch, getState) => {
        const { connect, auth } = getState();

        if (connect.bulkMessageSending) {
            return Promise.resolve();
        }

        dispatch({
            type: connectTypes.SEND_BULK_MESSAGE_REQUEST,
            payload: {
                template,
                inboxUserIds,
            },
        });

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;

        Snowplow.structEvent(StructEventCategories.workflows, 'send-bulk-message', [
            new RxConferPatientContext().setPatientCount(inboxUserIds.length).build(),
            new RxConferWorkflowContext().setWorkflowTemplateId(template.inbox_conversation_template_id).build(),
        ]);

        try {
            const response = await connectService.sendBulkMessage(pharmacyId, locationId, template, inboxUserIds, auth);
            dispatch({
                type: connectTypes.SEND_BULK_MESSAGE_SUCCESS,
                payload: {
                    response,
                    template,
                    inboxUserIds,
                },
            });
            return { success: true, response };
        } catch (error) {
            dispatch({
                type: connectTypes.SEND_BULK_MESSAGE_FAILURE,
                payload: {
                    error,
                    template,
                    inboxUserIds,
                },
            });
            return { success: false, error };
        }
    };
}

export function setBulkMessageStatusShown(shown) {
    return {
        type: connectTypes.SET_BULK_MESSAGE_STATUS_SHOWN,
        payload: { shown },
    };
}

export function handlePatientUpdate(patientUpdateData) {
    return async (dispatch) => {
        const patientUpdateTriggerEvent = _.get(patientUpdateData, 'trigger_event_for_update');
        const triggerEvents = [
            PatientUpdateEventType.birthday_opportunities_completed,
            PatientUpdateEventType.opportunities_completed,
            PatientUpdateEventType.update_patient_opportunities,
        ];
        if (_.includes(triggerEvents, PatientUpdateEventType[patientUpdateTriggerEvent])) {
            dispatch(fetchPatientOpportunities(moment().format('YYYY-MM-DD')));
        }
    };
}
