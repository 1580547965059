import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import NavigationDrawer from 'react-md/lib/NavigationDrawers';
import { connect } from 'react-redux';
import InboxSettings from './Inbox/index';
import { default as GeneralSettingComponent } from './GeneralSettings';
import PhoneSystem from './PhoneSystem';
import WebPresence from './WebPresence';
import { ToolbarChildren } from '../AppBar';
import IconMenus from '../IconMenus';
import NavLink from '../NavLink';
import { OnlyCallTrackingGeneralSettings, OnlyCallTrackingPhoneLineSettings } from 'constants/PhoneSystems';
import { Can, AbilityContext, userActions, restrictedResources } from 'casl';
import NoAccess from 'components/Common/NoAccess';
import PharmacyApps from './PharmacyApps';
import Newsletter from './Newsletter';
import Coronavirus from './Coronavirus';
import _ from 'lodash';
import UpgradesSettingsPage from './Upgrades/UpgradesSettingsPage';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import AppointmentsConfig from './Appointments/AppointmentsConfig';

class Settings extends PureComponent {
    static contextType = AbilityContext;
    lastRoute = null;

    componentDidUpdate({ selectedLocation: oldSelectedLocation, pharmacy: oldPharmacy }) {
        const { selectedLocation, pharmacy, ivrEnabled, basicMessagingEnabled, fullPepEnabled, history } = this.props;
        if (oldSelectedLocation.id !== selectedLocation.id && oldPharmacy.pharmacy.id === pharmacy.pharmacy.id) {
            this.checkAndChangeRoute();
        } else if (oldPharmacy.lastUpdated !== pharmacy.lastUpdated) {
            if ((ivrEnabled || basicMessagingEnabled || fullPepEnabled) && this.lastRoute) history.push(this.lastRoute);
            this.lastRoute = null;
        }
    }

    checkAndChangeRoute = () => {
        const {
            ivrEnabled,
            onlyCallTrackingEnabled,
            basicMessagingEnabled,
            fullPepEnabled,
            location,
            history,
        } = this.props;
        if (onlyCallTrackingEnabled || !ivrEnabled) {
            const reRoutePath = onlyCallTrackingEnabled ? '/settings/phone-system' : '/settings/general';
            const routeDetails = OnlyCallTrackingGeneralSettings.concat(OnlyCallTrackingPhoneLineSettings);

            const routePaths = routeDetails.map((route) => route.to);

            if (
                location.pathname.indexOf('phone-system') > -1 &&
                ((onlyCallTrackingEnabled && routePaths.indexOf(location.pathname) < 0) ||
                    (!onlyCallTrackingEnabled && !ivrEnabled))
            ) {
                this.lastRoute = location.pathname;
                history.push(reRoutePath);
            }
        }

        if (location.pathname.indexOf('settings/inbox') > -1 && !(basicMessagingEnabled || fullPepEnabled)) {
            this.lastRoute = location.pathname;
            history.push('/settings/general');
        }
    };

    getNavItems = () => {
        const navItems = [];
        const { view } = userActions;
        const { generalSettings, inbox, ivr, pharmacyApps } = restrictedResources;
        const {
            ivrEnabled,
            onlyCallTrackingEnabled,
            basicMessagingEnabled,
            fullPepEnabled,
            isNewsletterPackageEnabled,
            canSeeUpgrades,
            npeMultipleAppointmentTypes,
            newslettersV2Enabled,
            isVowIVREnabled,
        } = this.props;
        const {
            flags: { npePharmacyApps, npeCoronavirusWaitlist },
        } = this.props;

        if (this.context.can(view, generalSettings.subject)) {
            navItems.push({
                primaryText: 'General Settings',
                to: '/settings/general',
            });
        }

        if (this.context.can(view, inbox.subject) && (basicMessagingEnabled || fullPepEnabled)) {
            navItems.push({
                primaryText: 'Inbox',
                to: '/settings/inbox',
            });
        }

        if (this.context.can(view, inbox.subject) && fullPepEnabled && npeMultipleAppointmentTypes) {
            navItems.push({
                primaryText: 'Appointment Scheduling',
                to: '/settings/appointments',
            });
        }

        if (this.context.can(view, ivr.subject) && (ivrEnabled || onlyCallTrackingEnabled) && !isVowIVREnabled) {
            navItems.push({
                primaryText: `
                  ${!onlyCallTrackingEnabled ? 'Communications Platform (IVR)' : 'New Caller Tracking'}
                `,
                to: '/settings/phone-system',
            });
        }

        if (isNewsletterPackageEnabled && newslettersV2Enabled) {
            navItems.push({
                primaryText: 'Newsletter',
                to: '/settings/newsletter',
            });
        }

        if (npeCoronavirusWaitlist) {
            navItems.push({
                primaryText: 'Coronavirus (COVID-19)',
                to: '/settings/coronavirus',
            });
        }

        if (canSeeUpgrades) {
            navItems.push({
                primaryText: 'Upgrades / Add-ons',
                to: '/settings/upgrades',
            });
        }

        return navItems;
    };

    render() {
        const { view } = userActions;
        const { subject: caslSubject, fields: restrictedFields } = restrictedResources.settingsPopover;
        const {
            basicMessagingEnabled,
            fullPepEnabled,
            ivrEnabled,
            onlyCallTrackingEnabled,
            canSeeUpgrades,
            npeMultipleAppointmentTypes,
        } = this.props;

        return (
            <Route
                render={() => (
                    <NavigationDrawer
                        className="settings__menu"
                        includeDrawerHeader={false}
                        tabletDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
                        toolbarActions={<IconMenus />}
                        toolbarChildren={<ToolbarChildren />}
                        navItems={this.getNavItems().map((props) => (
                            <NavLink {...props} key={props.to} />
                        ))}
                    >
                        <Can I={view} this={caslSubject} field={restrictedFields.settings} passThrough>
                            {(allowed) =>
                                allowed ? (
                                    <Switch>
                                        <Route path="/settings/general" component={GeneralSettingComponent} />
                                        {(basicMessagingEnabled || fullPepEnabled) && (
                                            <Route path="/settings/inbox" component={InboxSettings} />
                                        )}

                                        {fullPepEnabled && npeMultipleAppointmentTypes && (
                                            <Route path="/settings/appointments" component={AppointmentsConfig} />
                                        )}

                                        {(ivrEnabled || onlyCallTrackingEnabled) && (
                                            <Route path="/settings/phone-system" component={PhoneSystem} />
                                        )}
                                        <Route path="/settings/web-presense" component={WebPresence} />
                                        <Route path="/settings/pharmacy-apps" component={PharmacyApps} />
                                        <Route path="/settings/newsletter" component={Newsletter} />
                                        <Route path="/settings/coronavirus" component={Coronavirus} />

                                        {canSeeUpgrades && (
                                            <Route path="/settings/upgrades" component={UpgradesSettingsPage} />
                                        )}
                                    </Switch>
                                ) : (
                                    <NoAccess />
                                )
                            }
                        </Can>
                    </NavigationDrawer>
                )}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isInternalUser: state.auth.isInternalUser,
    ivrEnabled: state.pharmacy.ivrEnabled,
    onlyCallTrackingEnabled: state.pharmacy.callTrackingEnabled && !state.pharmacy.ivrEnabled,
    pharmacy: state.pharmacy,
    selectedLocation: state.auth.selectedLocation,
    selectedPharmacy: state.auth.selectedCustomer,
    flags: state.launchDarkly,
    newslettersV2Enabled: state.pharmacy.newslettersV2Enabled,
    isNewsletterPackageEnabled: state.pharmacy.newsletterReportsEnabled,
    basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
    fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    canSeeUpgrades: _.get(state, 'launchDarkly.npeShowFullPepUpgrade') && canSeeUpgrades(state),
    npeMultipleAppointmentTypes: state.launchDarkly.npeMultipleAppointmentTypes,
    isVowIVREnabled: state.pharmacy.vowIVREnabled,
});

export default connect(mapStateToProps)(Settings);

function canSeeUpgrades(state) {
    if (_.get(state, 'auth.isInternalUser')) {
        return true;
    }

    const locationId = _.get(state, 'auth.selectedLocation.id');

    const locationDetails = _.find(_.get(state, 'auth.userDetails'), ({ entityId, entityType }) => {
        return entityType === 'Location' && entityId === locationId;
    });

    return _.get(locationDetails, 'role') === 'Owner';
}
