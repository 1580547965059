import Config from '../config';

const snowplow = window.snowplow;

function snowplowInit() {
    // Initialise named tracker
    snowplow('newTracker', 'cfnpe', Config.snowplow_url, {
        appId: 'NPE',
        platform: 'web',
        respectDoNotTrack: true,
        // encodeBase64: false,
        // cookieDomain: null,
        // discoverRootDomain: true,
        // pageUnloadTimer: 0, // may not catch link clicks at 0
        // forceSecureTracker: false,
        // eventMethod: "post",
        // bufferSize: 1,
        // maxPostBytes: 45000,
        // crossDomainLinker: function (linkElement) {
        //   return (linkElement.href === "http://acme.de" || linkElement.id === "crossDomainLink");
        // },
        // cookieName: "_sp534_",
        // cookieLifetime: '', default 2 years
        // stateStorageStrategy: "cookie",
        contexts: {
            performanceTiming: true,
            webPage: true,
            gaCookies: true,
            // geolocation: false
        },
    });
}

snowplowInit();

const actions = {
    addGlobalContexts: (context) => {
        try {
            snowplow('addGlobalContexts', context);
        } catch (error) {
            console.log(error);
        }
    },
    clearGlobalContexts: () => {
        try {
            snowplow('clearGlobalContexts');
        } catch (error) {
            console.log(error);
        }
    },
    setUserId: (userId) => {
        try {
            snowplow('setUserId', userId);
        } catch (error) {
            console.log(error);
        }
    },
    pageView: (pageName, schema) => {
        try {
            if (schema) {
                snowplow('trackPageView', pageName, schema);
            } else {
                snowplow('trackPageView', pageName);
            }
        } catch (error) {
            console.log(error);
        }
    },
    structEvent: (category, action, schema, label = '', property = '', value = '') => {
        try {
            snowplow('trackStructEvent', category, action, label, property, value, schema);
        } catch (error) {
            console.log(error);
        }
    },
};

export default actions;
