import { userRoles } from 'casl';

const RoleEntityType = {
    customer: 'Customer',
    location: 'Location',
};

export function getPharmacyUserRoleDetails(pharmacyId, exisingUserRoles) {
    const locationRoleMap = {};
    let locationNoneRoleCount = 0;
    let pharmacyRole = null;
    // TODO think through how to handle the role 'None'
    // Location-level role 'None' maybe shouldn't make it into location count
    // Pharmacy-level role 'None' maybe shouldn't make it into pharmacyRole var
    // Or maybe the statements above should be handled elsewhere in the code/ui
    // Depends on how opinionated we want this function
    const locationRoles = exisingUserRoles.filter((userRole) => {
        if (userRole.entityType === RoleEntityType.customer && userRole.entityId === pharmacyId) {
            pharmacyRole = userRole;
            return false;
        }

        if (userRole.entityType === RoleEntityType.location && userRole.parentId === pharmacyId) {
            if (userRole.role === userRoles.none || userRole.role === userRoles.noAccess) {
                locationNoneRoleCount += 1;
            }
            locationRoleMap[userRole.entityId] = userRole;
            return true;
        }

        return false;
    });

    return {
        pharmacyRole,
        locationRoles,
        locationRoleMap,
        locationNoneRoleCount,
    };
}
