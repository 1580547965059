export default {
    '/settings/phone-system': 'CP IVR - Main Page',
    '/settings/phone-system/main-menu': 'CP IVR - Main Menu',
    '/settings/phone-system/main-greeting': 'CP IVR - Main Greeting',
    '/settings/phone-system/languages': 'CP IVR - Languages and Voices',

    '/settings/phone-system/ivr-main-greeting': 'CP IVR - IVR Main Greeting',
    '/settings/phone-system/after-hour-transfer': 'CP IVR - After Hours Zero Transfer',
    '/settings/phone-system/phone-menu': 'CP IVR - IVR Phone Menu',
    '/settings/phone-system/phone-transfer': 'CP IVR - Phone Call Transfer',
    '/settings/phone-system/refill-menu': 'CP IVR - Prescription Refill Fufillment',
    '/settings/phone-system/doctors-line': 'CP IVR - Prescriber Voicemail',
    '/settings/phone-system/general-line': 'CP IVR - General Voicemail',
    '/settings/phone-system/pharmacy-hours': 'CP IVR - Pharmacy Hours',
};
