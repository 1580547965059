import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class ConversationListNoMobileState extends Component {
    render() {
        const { contact, toggleEditContactDialog } = this.props;

        return (
            <div className="contact-conversation-list__empty">
                <i className="material-icons" style={{ fontSize: '100px' }}>
                    mobile_off
                </i>
                <br />
                <br />
                <b>
                    {contact.first_name} {contact.last_name}
                </b>
                &nbsp;doesn't have a mobile number.
                <br />
                To send a message, add a mobile number to their account.
                <br />
                <br />
                <Button variant="outlined" color="primary" onClick={() => toggleEditContactDialog()}>
                    + Add a mobile number
                </Button>
            </div>
        );
    }
}

export default ConversationListNoMobileState;
