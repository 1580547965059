import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Cell, Card, CardText } from 'react-md';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Fab, CircularProgress, Drawer, Icon, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Config from 'config';
import LeadsTable from './LeadsTable';
import EmptyLeads from './EmptyLeads';
import LeadDetailsAccordion, { LeadDetails } from './LeadDetails';
import LeadNotesAccordion, { LeadNotes } from './LeadNotes';
import {
    fetchLeads,
    fetchLeadAudio,
    sortLeads,
    resetLeads,
    fetchLeadNotes,
    saveLeadNote,
    setDraftNotes,
    fetchLeadsCount,
} from 'redux/actionCreators/Leads';
import { getSortedLeadsSelector } from 'redux/reducers/Leads';
import SearchInput from 'components/Common/SearchInput';
import { LEAD_STATUS } from 'constants/Leads';
import { fabStyles } from 'components/Common';
import { LeadsTabs, LeadsTab } from './LeadsTabs';
import DATE_SEARCH_FORMATS from 'constants/DateSearchFormats';
import { stripISDCode, toDigits, numberWithCommas } from 'utils/helper';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { DpDialog } from 'components/Common/DpDialog/DpDialog';
import withMediaQuery from 'components/Common/WithMediaQuery';
import Snowplow from 'snowplow/Snowplow';
import { AbilityContext, userActions, restrictedResources } from 'casl';

const ALL_TIME_DATE = '2010-01-01';
const Dialog = withStyles({
    paper: {
        width: '600px',
    },
    paperWidthMd: {
        width: '600px !important',
    },
})(DpDialog.Dialog);

export class Leads extends PureComponent {
    state = {
        track: {
            previous: '',
            current: '',
        },
        selectedTab: 0,
        selectedLead: null,
        searchTerm: '',
    };
    tabs = [
        {
            label: 'All Leads',
            value: LEAD_STATUS.UNASSIGNED,
        },
        {
            label: 'New Leads',
            value: LEAD_STATUS.NEW_LEADS,
        },
        {
            label: 'In Progress',
            value: LEAD_STATUS.IN_PROGRESS,
        },
        {
            label: 'Completed',
            value: LEAD_STATUS.COMPLETED,
        },
    ];

    async componentDidMount() {
        const { resetLeads } = this.props;
        resetLeads();
        this.resetCurrentPage();

        this.getLeadsCount();
        this.getLeadsData();
    }

    componentDidUpdate({
        selectedLocation: oldSelectedLocation,
        callRailId: oldCallRailId,
        pharmacy: oldPharmacy,
        leads: oldLeads,
    }) {
        const {
            selectedLocation,
            callRailId,
            pharmacy,
            isInternalLeadsEnabled,
            resetLeads,
            leads,
            fetchLeadNotes,
            shouldFetchLatestLeads,
        } = this.props;
        const isLocationChanged = selectedLocation !== oldSelectedLocation;
        const isPharmacyUpdated = oldPharmacy.lastUpdated !== pharmacy.lastUpdated;
        const isCallRailIdChanged = !_.isEmpty(callRailId) && callRailId !== oldCallRailId;

        if (
            ((isLocationChanged || isPharmacyUpdated) && (isCallRailIdChanged || isInternalLeadsEnabled)) ||
            shouldFetchLatestLeads
        ) {
            resetLeads();
            this.resetCurrentPage();
            this.getLeadsCount();
            this.getLeadsData();
            this.setState({ searchTerm: '' });
        }

        if (oldLeads.length !== leads.length && leads.length > 0) {
            let newLeads = [...leads];
            if (oldLeads.length > 0) {
                newLeads = _.differenceBy(leads, oldLeads, 'id');
            }
            const leadIds = newLeads.map((lead) => (lead.id ? lead.id : lead.universal_session_id));
            fetchLeadNotes({
                leads: leadIds,
            }).then((resp) => {
                if (resp.data) {
                    this.updateLeadStatus(newLeads, resp.data.leads);
                }
            });
        }
    }

    updateLeadStatus = (newLeads, zootLeads) => {
        const { saveLeadNote } = this.props;

        const leadsStatusDetails = {};
        _.forEach(zootLeads, (leadData) => {
            leadsStatusDetails[leadData.lead_id] = leadData;
        });

        _.forEach(newLeads, (lead, idx) => {
            const leadId = lead.id || lead.universal_session_id;

            if (!leadsStatusDetails[leadId] && moment(lead.start_time).isAfter('09/27/2020')) {
                const payload = {
                    leadId,
                    action: 'create',
                    data: {
                        lead: {
                            note: '',
                            status: LEAD_STATUS.NEW_LEADS,
                            location_id: Config.X_LocationID,
                        },
                    },
                };
                saveLeadNote(payload);
            }
        });
    };

    resetCurrentPage = () => {
        this.currentPage = 1;
    };

    setSelectedLead = (lead) => {
        this.setState({ selectedLead: lead });
    };

    unselectLead = () => {
        this.setState({ selectedLead: null });
    };

    getLeadsCount = () => {
        const { fetchLeadsCount, selectedPharmacy, selectedLocation } = this.props;

        const leadsCountPayload = {
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
            start_datetime: ALL_TIME_DATE,
            end_datetime: moment(),
        };

        fetchLeadsCount(leadsCountPayload);
    };

    getLeadsData = (loadMore = false) => {
        const { fetchLeads, selectedPharmacy, selectedLocation } = this.props;

        const leadsPayload = {
            pharmacy_id: selectedPharmacy,
            location_id: selectedLocation,
            start_datetime: ALL_TIME_DATE,
            end_datetime: moment(),
            loadMore,
        };

        fetchLeads(leadsPayload);
    };

    setCurrentTrack = (leadId) => {
        const { track } = this.state;
        if (leadId !== track.current) {
            track.previous = track.current;
        }

        track.current = leadId;
        this.setState({ track: { ...track } });
    };

    sortColumn = (data) => {
        const { sortLeads, sort } = this.props;

        if (!data.order) {
            data.order = 'asc';
            if (sort.column === data.column) {
                data.order = sort.order === 'desc' ? 'asc' : 'desc';
            }
        }

        sortLeads({ order: data.order, column: data.column });
    };

    handleTabChange = (e, newValue) => {
        this.setState({ selectedTab: newValue });
    };

    onSearch = (term) => {
        if (term.length) {
            this.setState({ searchTerm: term.toLowerCase().trim() });
        } else {
            this.setState({ searchTerm: '' });
        }
    };

    getFilteredLeads = () => {
        const { sortedLeads } = this.props;
        const { searchTerm } = this.state;
        let filteredResult = [];
        if (searchTerm.length > 2) {
            const dateInput = moment(searchTerm, DATE_SEARCH_FORMATS, true);
            if (dateInput.isValid()) {
                // search by date
                filteredResult = _.filter(sortedLeads, (lead) => {
                    const leadDate = moment.utc(lead['start_time']).local().startOf('day');
                    return dateInput.startOf('day').isSame(leadDate);
                });
            } else {
                /* eslint-disable */
                const matchUSPhone = searchTerm.match(/^[\+]?[1]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/);
                /* eslint-enable */
                const phoneString = matchUSPhone ? toDigits(searchTerm) : searchTerm;
                // search by Name or number
                filteredResult = _.filter(sortedLeads, (lead) => {
                    let custName = lead.customer_name;
                    let phoneNum = lead.customer_phone_number;

                    custName = custName ? custName.toLowerCase() : '';
                    phoneNum = phoneNum ? stripISDCode(phoneNum) : '';

                    return custName.indexOf(searchTerm) !== -1 || phoneNum.indexOf(phoneString) !== -1;
                });
            }
            window.scrollTo(0, 0);
        } else {
            filteredResult = sortedLeads.slice();
        }
        return filteredResult;
    };

    setStatus = (status, lead) => {
        const leadData = {
            note: lead.note || '',
            status,
        };
        this.saveLeadData(lead, leadData);
    };

    onNoteSave = (lead) => {
        const leadData = {
            note: lead.draftNote,
            status: lead.status || LEAD_STATUS.NEW_LEADS,
        };
        this.saveLeadData(lead, leadData);
    };

    saveLeadData = (lead, leadData) => {
        const { saveLeadNote, displayToast } = this.props;

        const payload = {
            leadId: lead.id || lead.universal_session_id,
            action: lead.note || lead.status ? 'update' : 'create',
            data: {
                lead: {
                    ...leadData,
                    location_id: Config.X_LocationID,
                },
            },
        };

        saveLeadNote(payload).then((resp) => {
            const action = lead.status ? 'update' : 'create';
            if (resp.err) {
                displayToast({ text: `Lead status/note ${action} failed`, type: 'error' });
            } else {
                Snowplow.structEvent('Lead Notes', action, null, 'lead-zoot-note', 'Success');
                displayToast({
                    text: `Lead status/note ${lead.status ? 'updated' : 'created'} successfully`,
                    type: 'success',
                });
            }
        });
    };

    getLeadInfo = (filteredLeads, canUpdate) => {
        const { fetchLeadAudio } = this.props;
        const { track, selectedLead } = this.state;

        return (
            <LeadDetails
                track={track}
                setCurrentTrack={this.setCurrentTrack}
                fetchLeadAudio={fetchLeadAudio}
                leadInfo={selectedLead != null ? filteredLeads[selectedLead] : {}}
                setStatus={this.setStatus}
                canUpdate={canUpdate}
            />
        );
    };

    getLeadNote = (filteredLeads, canUpdate) => {
        const { setDraftNotes } = this.props;
        const { selectedLead } = this.state;

        return (
            <LeadNotes
                isLeadNoteSaving={selectedLead != null ? filteredLeads[selectedLead].isSaving : false}
                note={selectedLead != null ? filteredLeads[selectedLead].note : ''}
                draftNote={selectedLead != null ? filteredLeads[selectedLead].draftNote : ''}
                onSave={() => this.onNoteSave(filteredLeads[selectedLead])}
                setDraftNotes={(draftNote) => setDraftNotes({ leadIdx: selectedLead, draftNote })}
                canUpdate={canUpdate}
            />
        );
    };

    getDisplayedLeadCount = (filteredLeads) => {
        const { selectedTab, searchTerm } = this.state;
        const { leadsCount } = this.props;

        let count = searchTerm ? filteredLeads.length : leadsCount;

        if (selectedTab > 0) {
            const leads = _.filter(
                filteredLeads,
                (lead) => lead.status && lead.status === this.tabs[selectedTab].value
            );
            count = leads.length;
        }
        return count;
    };

    render() {
        const {
            leads,
            leadsCount,
            sort,
            isPharmacyLoading,
            isLeadsLoading,
            isLoadMoreLeadsLoading,
            callRailId,
            isInternalUser,
            isInternalLeadsEnabled,
            classes,
            isLeadNotesLoading,
            isMobile,
            isLeadsCountLoading,
        } = this.props;
        const { selectedTab, selectedLead, searchTerm } = this.state;

        const isLoading = isLeadsLoading || isLeadsCountLoading;
        const filteredLeads = this.getFilteredLeads();
        const leadCountDisplayed = this.getDisplayedLeadCount(filteredLeads);
        const canUpdate = this.context.can(userActions.update, restrictedResources.leads.subject);
        const noResult = leads.length > 0 && leads.length === leadsCount && filteredLeads.length === 0;

        return (
            <div className="leads-container">
                <Grid noSpacing>
                    <Cell size={12}>
                        {!callRailId && !isInternalLeadsEnabled ? (
                            <EmptyLeads />
                        ) : (
                            <Card className="outer-card">
                                <CardText>
                                    <div className="grid-container">
                                        <Cell size={3} phoneSize={4} tabletSize={8} className="page-title">
                                            Digital Pharmacist Leads
                                        </Cell>
                                        {!isLoading && (
                                            <Fragment>
                                                <Cell size={5} phoneSize={3} className="leads-search-container">
                                                    <Tooltip
                                                        placement="top"
                                                        classes={{
                                                            tooltip: 'info-tooltip',
                                                        }}
                                                        enterTouchDelay={0}
                                                        title="You can search for Phone Number, Patient Name, Date in the following format:
                                        Date: MM/DD/YYYY, YYYY/MM/DD, Phone Number: (XXX) YYY-ZZZZ, XXX-YYY-ZZZZ, XXXYYYZZZZ.
                                        The search will be performed on the records shown.
                                        To add more records to the screen select 'load more'."
                                                    >
                                                        <Icon
                                                            className="info-tooltip-icon"
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            info
                                                        </Icon>
                                                    </Tooltip>
                                                    <SearchInput
                                                        onSearch={this.onSearch}
                                                        placeholder="Patient Name, Phone Number, Date Submitted"
                                                        searchTerm={searchTerm || ''}
                                                        noResult={noResult}
                                                    />
                                                </Cell>
                                                <Cell size={1} className="flex-middle total-leads-text">
                                                    {leadCountDisplayed ? numberWithCommas(leadCountDisplayed) : 0}{' '}
                                                    patients
                                                </Cell>
                                            </Fragment>
                                        )}
                                    </div>

                                    <div className="grid-container leads-table-container">
                                        <Cell size={12} phoneSize={4} tabletSize={8} className="mobile-cell">
                                            <LeadsTabs
                                                variant="scrollable"
                                                scrollButtons="on"
                                                value={selectedTab}
                                                onChange={this.handleTabChange}
                                            >
                                                {this.tabs.map((tab) => (
                                                    <LeadsTab label={tab.label} key={tab.value} />
                                                ))}
                                            </LeadsTabs>
                                            <Grid noSpacing>
                                                <Cell size={12}>
                                                    <LeadsTable
                                                        leadsData={filteredLeads}
                                                        isInternalUser={isInternalUser}
                                                        sortColumn={this.sortColumn}
                                                        sort={sort}
                                                        loading={isLoading}
                                                        selectedLead={selectedLead}
                                                        selectedTab={this.tabs[selectedTab]}
                                                        selectLead={this.setSelectedLead}
                                                        setStatus={this.setStatus}
                                                        canUpdate={canUpdate}
                                                        onLoadMore={() => {
                                                            // check if we have already completed the search
                                                            // because the api will return 404 for requests bigger then leadsCount
                                                            const { leads, leadsCount } = this.props;
                                                            if (leads.length > 0 && leadsCount == leads.length) return;
                                                            this.getLeadsData(true);
                                                        }}
                                                    />
                                                </Cell>
                                            </Grid>
                                        </Cell>
                                    </div>
                                    {!isLoading && leads.length < leadCountDisplayed ? (
                                        <Grid noSpacing>
                                            <Cell size={12} className="centered-content button-control">
                                                {isLoadMoreLeadsLoading ? (
                                                    <CircularProgress id="more-leads-loading" />
                                                ) : null}
                                            </Cell>
                                        </Grid>
                                    ) : leads.length > 0 && leadsCount == leads.length ? (
                                        <Grid noSpacing>
                                            <Cell size={12} className="centered-content info-note">
                                                End of list
                                            </Cell>
                                        </Grid>
                                    ) : (
                                        <Grid noSpacing>
                                            <Cell size={12} className="centered-content button-control">
                                                {searchTerm && (
                                                    <Fab
                                                        variant="extended"
                                                        size="medium"
                                                        aria-label="Load More"
                                                        className={classNames(classes.margin, classes.cssRoot)}
                                                        onClick={() => this.getLeadsData(true)}
                                                    >
                                                        Continue searching on server
                                                    </Fab>
                                                )}
                                            </Cell>
                                        </Grid>
                                    )}
                                    {isMobile ? (
                                        <Dialog
                                            id="leadInfoDialog"
                                            open={selectedLead != null}
                                            maxWidth="sm"
                                            className="lead-info-dialog"
                                            onClose={this.unselectLead}
                                        >
                                            <DpDialog.Content id="LeadInfoDialogContent" dividers>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ margin: 0, paddingLeft: 0 }}>
                                                        <DpDialog.ContentHeader
                                                            heading="Lead Details"
                                                            onClose={this.unselectLead}
                                                        />
                                                    </Grid>
                                                    {this.getLeadInfo(filteredLeads, canUpdate)}
                                                    <Grid item xs={12} className="note-section">
                                                        Notes
                                                    </Grid>
                                                    {this.getLeadNote(filteredLeads, canUpdate)}
                                                </Grid>
                                            </DpDialog.Content>
                                        </Dialog>
                                    ) : (
                                        <Drawer
                                            className="leads-info-drawer"
                                            anchor={'right'}
                                            open={selectedLead != null}
                                            onClose={this.unselectLead}
                                        >
                                            <LeadDetailsAccordion>
                                                {this.getLeadInfo(filteredLeads, canUpdate)}
                                            </LeadDetailsAccordion>
                                            <LeadNotesAccordion>
                                                {this.getLeadNote(filteredLeads, canUpdate)}
                                            </LeadNotesAccordion>
                                        </Drawer>
                                    )}
                                </CardText>
                            </Card>
                        )}
                    </Cell>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { pharmacy, auth } = state;
    const { pharmacy: currentPharmacy } = pharmacy;
    const selectedPharmacyLocation = _.find(
        currentPharmacy.location,
        (location) => location.id === auth.selectedLocation.id
    );
    const callRailId = _.get(selectedPharmacyLocation, 'attr.callRailId.value', null);

    return {
        ...state.leads,
        pharmacy,
        isPharmacyLoading: pharmacy.loading,
        selectedLocation: auth.selectedLocation.id,
        selectedPharmacy: auth.selectedCustomer.id,
        isInternalUser: state.auth.isInternalUser,
        isInternalLeadsEnabled: pharmacy.callTrackingEnabled,
        callRailId,
        sortedLeads: getSortedLeadsSelector(state),
    };
};

Leads.contextType = AbilityContext;

const mapDispatchToProps = (dispatch) => ({
    fetchLeads: (data) => dispatch(fetchLeads(data)),
    fetchLeadsCount: (data) => dispatch(fetchLeadsCount(data)),
    fetchLeadAudio: (data) => dispatch(fetchLeadAudio(data)),
    sortLeads: (data) => dispatch(sortLeads(data)),
    resetLeads: () => dispatch(resetLeads()),
    fetchLeadNotes: (leadId) => dispatch(fetchLeadNotes(leadId)),
    saveLeadNote: (payload) => dispatch(saveLeadNote(payload)),
    setDraftNotes: (payload) => dispatch(setDraftNotes(payload)),
    displayToast: (payload) => dispatch(displayToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(fabStyles)(withMediaQuery(Leads)));
