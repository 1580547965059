import { Ability } from '@casl/ability';
import { BaseAbilityBuilder } from './BaseAbilityBuilder';
import { constants } from '../Constants';

export class StaffAbilityBuilder extends BaseAbilityBuilder {
    constructHiddenFieldsArray() {
        const { ivr, settingsPopover, generalSettings, voicemail, pharmacyApps } = this.restrictedResources;
        const { language, userManagement, featuresAndFunctions, bannerConfiguration } = generalSettings;
        const { languagesAndVoices, prescriptionRefillFulfillment } = ivr;

        this.hiddenFields = [
            {
                subject: language.subject,
                fields: [],
            },
            {
                subject: featuresAndFunctions.subject,
                fields: [],
            },
            {
                subject: bannerConfiguration.subject,
                fields: [],
            },
            {
                subject: userManagement.subject,
                fields: [],
            },
            {
                subject: ivr.subject,
                fields: [ivr.fields.resetIVR],
            },
            {
                subject: languagesAndVoices.subject,
                fields: [],
            },
            {
                subject: prescriptionRefillFulfillment.subject,
                fields: [prescriptionRefillFulfillment.fields.patientDobValidation],
            },
            {
                subject: settingsPopover.subject,
                fields: [settingsPopover.fields.appcode],
            },
            {
                subject: voicemail.subject,
                fields: [voicemail.fields.markAsRead, voicemail.fields.download],
            },
            {
                subject: pharmacyApps.subject,
                fields: [],
            },
        ];
    }

    constructReadOnlyFieldsArray() {
        const { ivr, generalSettings, pharmacyApps } = this.restrictedResources;
        const { refillDetails, accountDetails, pharmacyDetails } = generalSettings;
        const {
            pharmacyHours,
            generalMenuOptions,
            pharmacyMenuOptions,
            callTransferAfterHours,
            callTransferDuringHours,
            prescriptionRefillFulfillment,
            prescriberVoicemail,
            generalVoiceMail,
            newPatientVoicemail,
        } = ivr;

        this.readOnlyFields = [
            {
                subject: accountDetails.subject,
                fields: [accountDetails.fields.email],
            },
            {
                subject: pharmacyDetails.subject,
                fields: [],
            },
            {
                subject: refillDetails.subject,
                fields: [],
            },
            {
                subject: ivr.subject,
                fields: [ivr.fields.ivrPhoneNumber],
            },
            {
                subject: prescriberVoicemail.subject,
                fields: [],
            },
            {
                subject: generalVoiceMail.subject,
                fields: [],
            },
            {
                subject: newPatientVoicemail.subject,
                fields: [],
            },
            {
                subject: generalMenuOptions.subject,
                fields: [],
            },
            {
                subject: pharmacyHours.subject,
                fields: [],
            },
            {
                subject: callTransferAfterHours.subject,
                fields: [],
            },
            {
                subject: callTransferDuringHours.subject,
                fields: [],
            },
            {
                subject: pharmacyMenuOptions.subject,
                fields: [],
            },
            {
                subject: prescriptionRefillFulfillment.subject,
                fields: [
                    prescriptionRefillFulfillment.fields.fulfillmentOptions,
                    prescriptionRefillFulfillment.fields.voiceMsgsAttachedRx,
                ],
            },
            {
                subject: pharmacyApps.subject,
                fields: [],
            },
        ];
    }

    build() {
        const { all, userActions } = constants;
        const { manage } = userActions;

        // Note
        // 1) Rule are in accordance with Google Sheet attached in https://rxwiki.atlassian.net/browse/PHAR-1355 and
        //    https://app.lucidchart.com/documents/view/f82ecc8e-e040-4e25-b0ac-e5c5218dd168/0_0
        // 2) We follow the opt-out approach, i.e. we allow user to manage everything except opted out features
        this.can(manage, all);
        this.optOutHiddenFields();
        this.optOutReadOnlyFields();
        this.optOutNonClickableFields();

        return new Ability(this.rules, { detectSubjectType: this.detectAppSubjectType });
    }
}
