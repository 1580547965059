import { restActionCreators } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';

function getAvailableAppointmentTypes(pharmacyId, locationId) {
    return restActionCreators
        .get(`${config.apiweb_url}${api.APPT_API_BASE}/pharmacy/${pharmacyId}/location/${locationId}/reservation-type`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const apptTypeService = {
    getAvailableAppointmentTypes,
};
export default apptTypeService;
