export const pharmacyType = {
    GET_PHARMACY_REQUEST: 'GET_PHARMACY_REQUEST',
    GET_PHARMACY_SUCCESS: 'GET_PHARMACY_SUCCESS',
    GET_PHARMACY_FAILURE: 'GET_PHARMACY_FAILURE',

    RESET_PHARMACY_STORE: 'RESET_PHARMACY_STORE',
    UPDATE_ACTIVE_LOCATION: 'UPDATE_ACTIVE_LOCATION',
    UPDATE_ENABLED_SERVICES: 'UPDATE_ENABLED_SERVICES',

    CREATE_PACKAGE_ATTRIBUTE_REQUEST: 'CREATE_PACKAGE_ATTRIBUTE_REQUEST',
    CREATE_PACKAGE_ATTRIBUTE_SUCCESS: 'CREATE_PACKAGE_ATTRIBUTE_SUCCESS',
    CREATE_PACKAGE_ATTRIBUTE_FAILURE: 'CREATE_PACKAGE_ATTRIBUTE_FAILURE',

    UPDATE_PACKAGE_ATTRIBUTE_REQUEST: 'UPDATE_PACKAGE_ATTRIBUTE_REQUEST',
    UPDATE_PACKAGE_ATTRIBUTE_SUCCESS: 'UPDATE_PACKAGE_ATTRIBUTE_SUCCESS',
    UPDATE_PACKAGE_ATTRIBUTE_FAILURE: 'UPDATE_PACKAGE_ATTRIBUTE_FAILURE',

    UPGRADE_TO_FULL_PEP_REQUEST: 'UPGRADE_TO_FULL_PEP_REQUEST',
    UPGRADE_TO_FULL_PEP_SUCCESS: 'UPGRADE_TO_FULL_PEP_SUCCESS',
    UPGRADE_TO_FULL_PEP_FAILURE: 'UPGRADE_TO_FULL_PEP_FAILURE',

    CLEAR_UPGRADE_TO_FULL_PEP_ERROR: 'CLEAR_UPGRADE_TO_FULL_PEP_ERROR',
};

export default pharmacyType;
