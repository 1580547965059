import React, { PureComponent } from 'react';
import { Card, Cell, CardText, Grid, Button, CircularProgress } from 'react-md';
import { connect } from 'react-redux';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common';
import { displayToast } from 'redux/actionCreators/Snackbar';
import {
    addDoctorLine,
    updateDoctorGreetingMsg,
    closeDoctorGreetingEditor,
    editDoctorGreeting,
    changeDoctorGreetingType,
    changeDoctorGreetingScript,
} from 'redux/actionCreators/Settings/DoctorsLine';
import {
    fetchGreetingFiles,
    fetchMainGreeting,
    saveGreeting,
    fileUpload,
    saveFilename,
} from 'redux/actionCreators/Settings/MainGreeting';
import GreetingEditor from 'components/Common/GreetingEditor';
import Greetings from 'components/Settings/PhoneSystem/Greetings';
import goBackService from 'utils/goBackService';
import { Can, userActions, restrictedResources } from 'casl';

export class DoctorsLine extends PureComponent {
    componentDidMount() {
        const { loadGreetingFiles, fetchDoctorGreeting } = this.props;
        loadGreetingFiles();
        fetchDoctorGreeting();
    }

    componentDidUpdate(prevProps) {
        const { auth, loadGreetingFiles, fetchDoctorGreeting } = this.props;
        if (
            (auth.selectedCustomer && prevProps.auth.selectedCustomer.id !== auth.selectedCustomer.id) ||
            (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id)
        ) {
            loadGreetingFiles();
            fetchDoctorGreeting();
        }
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    async onSave({ fileObj, editedFileName, filenameWithExt }) {
        const {
            newDoctorGreeting,
            defaultGreeting,
            loadGreetingFiles,
            fetchDoctorGreeting,
            saveFile,
            saveGreeting,
            fileUpload,
            closeGreetingEditor,
            displayToast,
        } = this.props;
        const doctorGreetingPayload = { id: defaultGreeting.id, dr_greeting_id: newDoctorGreeting.dr_greeting_id };

        if (fileObj) {
            const newFileName =
                editedFileName !== null
                    ? editedFileName + fileObj.name.substr(fileObj.name.lastIndexOf('.'))
                    : fileObj.name;
            const fileParams = {
                contentType: fileObj.file.type,
                name: newFileName,
                fileTypeId: fileObj.file.type === 'text/plain' ? '9' : '8',
                public: 1,
            };
            const fileDetails = await fileUpload({ fileObj: fileObj.file, fileParams });
            doctorGreetingPayload.dr_greeting_id = fileDetails.id;
            loadGreetingFiles();
        } else if (editedFileName !== null) {
            const newFilename = editedFileName + filenameWithExt.substr(filenameWithExt.lastIndexOf('.'));
            await saveFile({ name: newFilename, id: newDoctorGreeting.dr_greeting_id });
        }

        const saveMsgResp = await saveGreeting({ newGreeting: { ...doctorGreetingPayload }, type: 'DOCTOR' });
        if (saveMsgResp.err) {
            displayToast({ text: 'Doctor line save failed', type: 'error' });
        } else {
            fetchDoctorGreeting();
            closeGreetingEditor();
            displayToast({ text: 'Doctor line saved', type: 'success' });
        }
    }

    removeDoctorGreeting = () => {
        const { fetchDoctorGreeting, saveGreeting, displayToast, defaultGreeting } = this.props;
        const newGreeting = { id: defaultGreeting.id, dr_greeting_id: '' };
        saveGreeting({ newGreeting, type: 'DOCTOR' }).then((saveMsgResp) => {
            if (saveMsgResp.err) {
                displayToast({ text: 'Doctor line remove failed', type: 'error' });
            } else {
                fetchDoctorGreeting();
                displayToast({ text: 'Doctor line removed', type: 'success' });
            }
        });
    };

    render() {
        const {
            addGreeting,
            newDoctorGreeting,
            showDoctorGreetingCreator,
            closeGreetingEditor,
            updateGreetingMessage,
            greetingFiles,
            defaultGreeting,
            loadingMainGreeting,
            editDoctorGreeting,
            displayToast,
            isFilenameSaving,
            newGreeting,
            changeDoctorGreetingType,
            changeDoctorGreetingScript,
            auth: { selectedCustomer },
        } = this.props;

        const greetingFileList = {};
        greetingFiles.forEach((element) => {
            const { name, id } = element;
            greetingFileList[id] = name;
        });
        const { create, update } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.prescriberVoicemail;

        return (
            <Grid>
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader title="Prescriber Voicemail" onBack={this.onBack} />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <SectionTitle text="Prescriber Voicemail Prompt" />
                                    <SectionSubTitle text="This is the prompt that callers will hear when they select the option for the doctor's office" />
                                    {loadingMainGreeting ? (
                                        <CircularProgress id="doctor-greeting-spinner" />
                                    ) : (
                                        <span className="greetings-list">
                                            <h5 className="sub-title-text">
                                                By default the IVR will play the prompt:
                                                <span className="italic-style">
                                                    {' '}
                                                    After the tone, please clearly state the prescriber's full name and
                                                    phone number, the patient's full name and date of birth, and all
                                                    other relevant information. When you have finished, press any key.
                                                </span>
                                            </h5>

                                            <SectionSubTitle text="You can record your own custom message below to override the default prompt" />

                                            <div className="grid-container table-title">
                                                {defaultGreeting['dr_greeting_type'] !== 'text/plain' && (
                                                    <Cell size={1} tabletSize={1} phoneSize={1}>
                                                        &nbsp;
                                                    </Cell>
                                                )}
                                                <Cell
                                                    size={defaultGreeting[`dr_greeting_type`] === 'text/plain' ? 4 : 3}
                                                    tabletSize={2}
                                                    phoneSize={1}
                                                >
                                                    Title
                                                </Cell>
                                                <Cell size={1} tabletSize={1} phoneHidden>
                                                    Status
                                                </Cell>
                                                <Cell size={7} tabletSize={2} phoneSize={2}>
                                                    &nbsp;
                                                </Cell>
                                            </div>

                                            {defaultGreeting && defaultGreeting.dr_greeting_id && (
                                                <span className="main-greeting">
                                                    <Can I={update} this={caslSubject} passThrough>
                                                        {(updateAllowed) => (
                                                            <Greetings
                                                                className="blue-bordered"
                                                                type="dr"
                                                                greetingDetail={defaultGreeting}
                                                                greetingFileList={greetingFileList}
                                                                handleEdit={() => editDoctorGreeting(defaultGreeting)}
                                                                isDeleting={newGreeting.processing}
                                                                handleDelete={this.removeDoctorGreeting}
                                                                hideActions={!updateAllowed}
                                                            />
                                                        )}
                                                    </Can>
                                                </span>
                                            )}

                                            {defaultGreeting && !defaultGreeting.dr_greeting_id && (
                                                <div className="grid-container">
                                                    <Cell size={12} className="centered-content no-greeting-msg">
                                                        No greetings configured
                                                    </Cell>
                                                </div>
                                            )}

                                            <Grid>
                                                <Can I={create} this={caslSubject} passThrough>
                                                    {(allowed) => (
                                                        <Cell size={12} className="centered-content button-control">
                                                            {allowed && !defaultGreeting.dr_greeting_id && (
                                                                <Button
                                                                    className="add-btn"
                                                                    flat
                                                                    secondary
                                                                    onClick={addGreeting}
                                                                >
                                                                    + ADD A NEW GREETING
                                                                </Button>
                                                            )}
                                                            {!allowed && !defaultGreeting.dr_greeting_id && (
                                                                <div>
                                                                    You don't have permission to configure greeting
                                                                </div>
                                                            )}
                                                        </Cell>
                                                    )}
                                                </Can>
                                            </Grid>
                                        </span>
                                    )}
                                </CardText>
                            </Card>

                            <GreetingEditor
                                custPerms={selectedCustomer.perms}
                                isSaving={isFilenameSaving || newGreeting.processing}
                                dialogTitle={
                                    newDoctorGreeting.id ? "Edit doctor's greeting" : "Add a new doctor's message"
                                }
                                isVisible={showDoctorGreetingCreator}
                                greetingFiles={greetingFiles}
                                greeting={newDoctorGreeting}
                                greetingId={newDoctorGreeting.dr_greeting_id || ''}
                                greetingUrl={newDoctorGreeting.dr_greeting_url}
                                greetingType={newDoctorGreeting.dr_greeting_type_id}
                                greetingScript={newDoctorGreeting.dr_greeting_text}
                                handleChange={(value) => updateGreetingMessage({ ...value, greetingFiles })}
                                closeHandler={() => closeGreetingEditor()}
                                saveHandler={(data) => this.onSave(data)}
                                showToastMessage={(data) => displayToast(data)}
                                changeGreetingType={(type) => changeDoctorGreetingType(type)}
                                changeGreetingScript={(msg) => changeDoctorGreetingScript(msg)}
                            />
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings.doctorsLine,
    ...state.settings.mainGreeting,
    auth: { ...state.auth },
});

const mapDispatchToProps = (dispatch) => ({
    loadGreetingFiles: () => dispatch(fetchGreetingFiles()),
    fetchDoctorGreeting: () => dispatch(fetchMainGreeting()),
    addGreeting: () => dispatch(addDoctorLine()),
    updateGreetingMessage: (data) => dispatch(updateDoctorGreetingMsg(data)),
    closeGreetingEditor: () => dispatch(closeDoctorGreetingEditor()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    saveGreeting: (data) => dispatch(saveGreeting(data)),
    editDoctorGreeting: (data) => dispatch(editDoctorGreeting(data)),
    saveFile: (data) => dispatch(saveFilename(data)),
    displayToast: (data) => dispatch(displayToast(data)),
    changeDoctorGreetingType: (data) => dispatch(changeDoctorGreetingType(data)),
    changeDoctorGreetingScript: (data) => dispatch(changeDoctorGreetingScript(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsLine);
