import React from 'react';
import { TextField, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

class TemplateAdd extends React.Component {
    render() {
        const { onChange, addTemplate, newSubject, newMessage, sending, errors, cancelAddItem } = this.props;

        return (
            <div className="template-add-container">
                <TextField
                    required
                    id="newSubject"
                    type="text"
                    label="Subject"
                    name="newSubject"
                    variant="outlined"
                    value={newSubject || ''}
                    onChange={onChange}
                    className="new-subject"
                    autoFocus
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors.newSubject}
                    helperText={errors.newSubject}
                />
                <TextField
                    required
                    id="newMessage"
                    type="text"
                    label="Message"
                    name="newMessage"
                    multiline
                    rows="6"
                    value={newMessage || ''}
                    variant="outlined"
                    onChange={onChange}
                    style={{
                        width: '100%',
                        marginTop: '32px',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors.newMessage}
                    helperText={errors.newMessage}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={cancelAddItem}
                        style={{
                            marginTop: '24px',
                            marginBottom: '24px',
                            alignSelf: 'flex-end',
                            color: 'red',
                            marginRight: '16px',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => addTemplate()}
                        className="template-add-button"
                        disabled={sending}
                        style={{
                            marginTop: '24px',
                            marginBottom: '24px',
                            alignSelf: 'flex-end',
                            minWidth: '134px',
                        }}
                    >
                        {!sending ? 'Save template' : <CircularProgress size={20} />}
                    </Button>
                </div>
            </div>
        );
    }
}

export default TemplateAdd;
