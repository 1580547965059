import React from 'react';
import Config2Constants from 'constants/Config2';
import { CAMERONS_PHARMACY_ID, CAMERONS_LOCATION_ID } from 'constants/PharmacyIds';
import Config from 'config';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Digital Marketing Dashboard';
const description = 'Enable or disable user access to the Digital Marketing Dashboard via the Reports page.';

const DigitalMarketingDashboard = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(DigitalMarketingDashboard, {
    package: {
        name: 'Digital Marketing',
        code: 'DGMK',
        attribute: {
            name: 'enableDashboard',
            dataType: Config2Constants.dataType.boolean,
        },
    },
    checkDisabled: (pharmacyId, locationId) =>
        // prevent accidents see ATLAS-1483
        Config.environ === 'prod' && pharmacyId === CAMERONS_PHARMACY_ID && locationId === CAMERONS_LOCATION_ID,
    title,
    description,
});
