import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

class ActivityListSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: props.searchQuery || '',
        };
    }
    handleChange = debounce((event) => {
        const { setSearchQuery } = this.props;
        const searchString = event.target.value;

        setSearchQuery(searchString);
    }, 300);

    onChange = (event) => {
        event.persist();
        this.setState({ searchText: event.target.value }, () => {
            this.handleChange(event);
        });
    };

    render() {
        const { searchText } = this.state;
        return (
            <TextField
                id="contact-search-box"
                placeholder="Search for a contact..."
                type="search"
                className="contact-search-box"
                margin="none"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={this.onChange}
                classes={{
                    root: 'contact-search-box',
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    classes: {
                        root: 'contact-search-box__input',
                        focused: 'contact-search-box__input--focused',
                    },
                }}
            />
        );
    }
}

export default ActivityListSearch;
