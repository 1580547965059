import React, { Fragment, Component } from 'react';
import { TextField, IconButton, InputAdornment, FormHelperText } from '@material-ui/core';
import { FontIcon } from 'react-md';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export class ChangePasswordInput extends Component {
    constructor(props) {
        super(props);
        this.unallowedSpecialChars = [];
        this.state = {
            newPassword: '',
            showPassword: false,
            validRule: {
                letters: false,
                numbers: false,
                special: false,
                totalChars: false,
                noSpaces: false,
            },
        };
    }

    checkSpecialChars = (value, allowedList) => {
        let foundSpecial = false;
        let foundNotAllowedSpecial = false;
        this.unallowedSpecialChars = [];
        for (let i = 0; i < value.length; i++) {
            const valCode = value.charCodeAt(i);
            if (
                (valCode >= 33 && valCode <= 47) ||
                (valCode >= 58 && valCode <= 64) ||
                (valCode >= 91 && valCode <= 96) ||
                (valCode >= 123 && valCode <= 126)
            ) {
                foundSpecial = true;
                if (allowedList.indexOf(value.charAt(i)) < 0) {
                    foundNotAllowedSpecial = true;
                    this.unallowedSpecialChars.push(value.charAt(i));
                }
            }
        }

        return foundNotAllowedSpecial ? false : foundSpecial;
    };

    checkPasswordValidity = (value) => {
        const validRule = {};
        validRule.letters = /[A-Za-z]/.test(value);
        validRule.numbers = /\d/.test(value);
        validRule.special = this.checkSpecialChars(value, ['@', '$', '!', '%', '*', '#', '?', '&']);
        validRule.totalChars = value.length - this.unallowedSpecialChars.length >= 6;
        validRule.noSpaces = value.indexOf(' ') === -1;
        return validRule;
    };

    handleInputChange = (e) => {
        let { validRule } = this.state;
        const { handlePwdChange } = this.props;
        const newPassword = e.target.value;

        if (e.target.name === 'newPassword') {
            validRule = this.checkPasswordValidity(newPassword);
        }
        this.setState({
            newPassword,
            validRule,
        });
        handlePwdChange(validRule, newPassword);
    };

    toggleVisibility = () => {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    };

    render() {
        const { errMsg } = this.props;
        const { newPassword, showPassword, validRule } = this.state;

        return (
            <Fragment>
                <div className="form-control">
                    <TextField
                        name="newPassword"
                        label="New Password"
                        value={newPassword}
                        onChange={this.handleInputChange}
                        variant="outlined"
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={this.toggleVisibility}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText error id="component-error-text">
                        {errMsg}
                    </FormHelperText>
                </div>
                <div className="password-rule">
                    <ul>
                        <li className={validRule.totalChars ? 'valid' : 'invalid'}>
                            <FontIcon>{validRule.totalChars ? 'check' : 'close'}</FontIcon>
                            At least 6 characters
                        </li>
                        <li className={validRule.letters ? 'valid' : 'invalid'}>
                            <FontIcon>{validRule.letters ? 'check' : 'close'}</FontIcon>
                            At least 1 letter
                        </li>
                        <li className={validRule.special ? 'valid' : 'invalid'}>
                            <FontIcon>{validRule.special ? 'check' : 'close'}</FontIcon>
                            At least 1 special character @,$,!,%,*,#,?,&
                        </li>
                        <li className={validRule.numbers ? 'valid' : 'invalid'}>
                            <FontIcon>{validRule.numbers ? 'check' : 'close'}</FontIcon>
                            At least 1 number
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default ChangePasswordInput;
