import Actions from 'redux/actions';
import { get } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';

export const fetchMetrics = (reqDetails) => (dispatch) => {
    dispatch({ type: Actions.FETCH_TIGER_PISTOL_METRIC_STARTED, payload: true });
    const { tigerPistolId, params } = reqDetails;
    const apiUrl = `${Config.apiweb_url}${API_PATH.TIGER_PISTOL_DAILY_METRICS.replace('{custID}', tigerPistolId)}`;
    const tigerPistolReq = get(apiUrl, { params });

    return tigerPistolReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_METRIC_SUCCESS, payload: resp });
            return { resp };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_METRIC_FAILED, payload: err });
            return { err };
        });
};

export const fetchDemographicMetrics = (reqDetails) => (dispatch) => {
    dispatch({ type: Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_STARTED, payload: true });
    const { tigerPistolId, params } = reqDetails;
    const apiUrl = `${Config.apiweb_url}${API_PATH.TIGER_PISTOL_DEMOGRAPHIC_METRICS.replace(
        '{custID}',
        tigerPistolId
    )}`;
    const tigerPistolReq = get(apiUrl, { params });

    return tigerPistolReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_SUCCESS, payload: resp });
            return { resp };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_FAILED, payload: err });
            return { err };
        });
};

export const resetMetric = () => ({
    type: Actions.FETCH_TIGER_PISTOL_METRIC_STARTED,
    payload: false,
});

export const resetDemographicMetric = () => ({
    type: Actions.FETCH_TIGER_PISTOL_DEMOGRAPHIC_METRIC_STARTED,
    payload: false,
});

export const fetchCampaigns = (reqDetails) => (dispatch) => {
    dispatch({ type: Actions.FETCH_TIGER_PISTOL_CAMPAIGN_STARTED });
    const { tigerPistolId, params } = reqDetails;
    const apiUrl = `${Config.apiweb_url}${API_PATH.TIGER_PISTOL_CAMPAIGN.replace('{custID}', tigerPistolId)}`;
    const tigerPistolReq = get(apiUrl, { params });

    return tigerPistolReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_CAMPAIGN_SUCCESS, payload: resp });
            return { resp };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_CAMPAIGN_FAILED, payload: err });
            return { err };
        });
};

export const resetCampaigns = () => ({
    type: Actions.FETCH_TIGER_PISTOL_CAMPAIGN_STARTED,
});

export const fetchCampaignPreviews = (campaignID) => (dispatch) => {
    dispatch({ type: Actions.FETCH_TIGER_PISTOL_PREVIEW_STARTED });
    const apiUrl = `${Config.apiweb_url}${API_PATH.TIGER_PISTOL_PREVIEW.replace('{campID}', campaignID)}`;
    const tigerPistolReq = get(apiUrl);

    return tigerPistolReq
        .then((resp) => {
            dispatch({
                type: Actions.FETCH_TIGER_PISTOL_PREVIEW_SUCCESS,
                payload: { results: resp.results, id: campaignID },
            });
            return { resp };
        })
        .catch((err) => {
            dispatch({ type: Actions.FETCH_TIGER_PISTOL_PREVIEW_FAILED, payload: err });
            return { err };
        });
};

export const updateCampaignPreview = (payload) => ({
    type: Actions.UPDATE_CAMPAIGN_PREVIEWS,
    payload,
});
