import React from 'react';
import { Divider, DialogContainer, Button } from 'react-md';
import _ from 'lodash';

const ChangeBusiness = (props) => {
    const { visible, onHide, changeCustomer, selectedCustomer, customersById } = props;
    const selectedCustomerName = customersById[selectedCustomer.id].entityName;
    const customers = _.filter(_.values(customersById), (v) => v.entityId !== selectedCustomer.id);

    const onSelect = (cus) => {
        changeCustomer(cus.entityId, cus.entityName);
        onHide();
    };

    return (
        <DialogContainer
            id="change-business-location-modal"
            visible={visible}
            onHide={onHide}
            title="Change Business"
            focusOnMount={false}
            width={520}
        >
            <Button icon onClick={onHide} className="dialog-close-btn">
                close
            </Button>
            <div className="sub-title">Select which business you would like to work from.</div>

            <div className="current-entity-text"> CURRENT BUSINESS </div>

            <div className="selected-entity flex-space-between">
                <div className="selected-customer">{selectedCustomerName}</div>
            </div>
            <Divider />
            <div className="all-entity">
                {customers.map((cus) => (
                    <div className="entity flex-space-between" key={cus.entityId} onClick={() => onSelect(cus)}>
                        <div> {cus.entityName} </div>
                        <div className="loc-cnt-txt"> {cus.locations.length} Locations </div>
                    </div>
                ))}
            </div>
        </DialogContainer>
    );
};

export default ChangeBusiness;
