import React, { useCallback, useMemo, useState } from 'react';

import { Toolbar } from '@material-ui/core';
import { createEditor, Text, Node } from 'slate';
import { Editable, withReact, Slate } from 'slate-react';
import { jsx } from 'slate-hyperscript';
import { withHistory } from 'slate-history';
import escapeHtml from 'escape-html';
import _ from 'lodash';

import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import LinkButton from './LinkButton';
import { withLinks } from './LinkButton';
import { Element, Leaf } from './RenderElements';

const initialValue = [
    {
        type: 'div',
        children: [
            {
                text: '',
            },
        ],
    },
];

const CHAR_LIMIT = 2000;

const serializeLeaf = (leaf, children) => {
    if (leaf.bold) {
        children = `<strong>${children}</strong>`;
    }
    if (leaf.italic) {
        children = `<em>${children}</em>`;
    }
    if (leaf.underline) {
        children = `<u>${children}</u>`;
    }

    return children;
};

const serializeToHTML = (node) => {
    if (Text.isText(node)) {
        return serializeLeaf(node, node.text);
    }
    const children = node.children.map((n) => serializeToHTML(n)).join('');

    switch (node.type) {
        case 'div':
            return `<div>${children || '<br/>'}</div>`;
        case 'heading-two':
            return `<h2>${children}</h2>`;
        case 'heading-three':
            return `<h3>${children}</h3>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'link':
            return `<a href="${escapeHtml(node.url)}" target="_blank">${children}</a>`;
        default:
            return children;
    }
};

const deserializeHTML = (el) => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    }

    const children = Array.from(el.childNodes).map(deserializeHTML);

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children);
        case 'DIV':
            return jsx('element', { type: 'div' }, children);
        case 'H2':
            return jsx('element', { type: 'heading-two' }, children);
        case 'H3':
            return jsx('element', { type: 'heading-three' }, children);
        case 'LI':
            return jsx('element', { type: 'list-item' }, children);
        case 'OL':
            return jsx('element', { type: 'numbered-list' }, children);
        case 'UL':
            return jsx('element', { type: 'bulleted-list' }, children);
        case 'A':
            return jsx('element', { type: 'link', url: el.getAttribute('href'), target: '_blank' }, children);
        case 'STRONG':
            return jsx('text', { bold: true }, children);
        case 'EM':
            return jsx('text', { italic: true }, children);
        case 'U':
            return jsx('text', { underline: true }, children);
        default:
            return el.textContent;
    }
};

const serializeToText = (nodes) => {
    return nodes.map((n) => Node.string(n)).join('');
};

const DPRichTextEditor = (props) => {
    const { updateText } = props;

    const [value, setValue] = useState(initialValue);
    const [textCount, setTextCount] = useState(0);
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []);
    const handleChange = useCallback(
        _.debounce((valueObj) => {
            const html = serializeToHTML(valueObj);
            updateText(html);
        }, 300),
        []
    );

    const setUpdateText = (newValue) => {
        const plainText = serializeToText(newValue);
        handleChange({ children: newValue });
        setTextCount(plainText.length);
        setValue(newValue);
    };

    const handleKeyDown = (event) => {
        const { keyCode, ctrlKey } = event;
        const plainText = serializeToText(value);
        const allowedNonCharKeys = [8, 13, 37, 38, 39, 40, 46];
        const selAll = ctrlKey && keyCode === 65;
        const copy = ctrlKey && keyCode === 67;

        if (plainText.length >= CHAR_LIMIT && allowedNonCharKeys.indexOf(keyCode) < 0 && !copy && !selAll) {
            event.preventDefault();
        }
    };

    return (
        <div className="dp-rte-container">
            <div className="rte-label">Additional Text</div>
            <Slate editor={editor} value={value} onChange={setUpdateText}>
                <Editable
                    onKeyDown={handleKeyDown}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Enter some text"
                    autoFocus
                    className="rte-text"
                />
                <Toolbar className="rte-buttons-container flex-space-between">
                    <MarkButton format="bold" icon="format_bold" />
                    <MarkButton format="italic" icon="format_italic" />
                    <MarkButton format="underline" icon="format_underlined" />

                    <BlockButton format="heading-two" icon="looks_one" />
                    <BlockButton format="heading-three" icon="looks_two" />
                    <BlockButton format="numbered-list" icon="format_list_numbered" />
                    <BlockButton format="bulleted-list" icon="format_list_bulleted" />

                    <LinkButton />
                </Toolbar>
            </Slate>
            {textCount > CHAR_LIMIT && <div className="rte-text-err-msg">Warning:Too much text!</div>}
            <div className="rte-text-count">
                <span className={textCount > CHAR_LIMIT ? 'rte-text-err' : ''}>{textCount}</span>/{CHAR_LIMIT}
            </div>
        </div>
    );
};

export default DPRichTextEditor;
