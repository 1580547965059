import _ from 'lodash';
import { pharmacyAppsTypes } from './type';

export const initialState = {
    pharmacyApps: {
        isLoading: false, // for get request
        isProcessing: false, // for put, post and delete
        apps: [],
    },
    dpNonBrandedApps: {
        isLoading: false,
        apps: [],
    },
};

export const pharmacyAppsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case pharmacyAppsTypes.FETCH_NON_BRANDED_APPS:
            return {
                ...state,
                dpNonBrandedApps: {
                    ...state.dpNonBrandedApps,
                    isLoading: true,
                },
            };
        case pharmacyAppsTypes.FETCH_NON_BRANDED_APPS_SUCCESS:
            return {
                ...state,
                dpNonBrandedApps: {
                    isLoading: false,
                    apps: _.get(payload, 'result', []),
                },
            };
        case pharmacyAppsTypes.FETCH_NON_BRANDED_APPS_FAILED:
            return {
                ...state,
                dpNonBrandedApps: {
                    ...state.dpNonBrandedApps,
                    isLoading: false,
                },
            };
        case pharmacyAppsTypes.FETCH_PHARMACY_APPS:
            return {
                ...state,
                pharmacyApps: {
                    ...state.pharmacyApps,
                    isLoading: true,
                },
            };
        case pharmacyAppsTypes.FETCH_PHARMACY_APPS_SUCCESS:
            return {
                ...state,
                pharmacyApps: {
                    ...state.pharmacyApps,
                    isLoading: false,
                    apps: _.get(payload, 'result', []),
                },
            };
        case pharmacyAppsTypes.ADD_APPS:
        case pharmacyAppsTypes.ADD_BRANDED_APP:
        case pharmacyAppsTypes.DELETE_PHARMACY_APP:
        case pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS:
            return {
                ...state,
                pharmacyApps: {
                    ...state.pharmacyApps,
                    isProcessing: true,
                },
            };
        case pharmacyAppsTypes.ADD_APPS_SUCCESS:
        case pharmacyAppsTypes.ADD_APPS_FAILED:
        case pharmacyAppsTypes.ADD_BRANDED_APP_SUCCESS:
        case pharmacyAppsTypes.ADD_BRANDED_APP_FAILED:
        case pharmacyAppsTypes.FETCH_PHARMACY_APPS_FAILED:
        case pharmacyAppsTypes.DELETE_PHARMACY_APP_SUCCESS:
        case pharmacyAppsTypes.DELETE_PHARMACY_APP_FAILED:
        case pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS_SUCCESS:
        case pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS_FAILED:
            return {
                ...state,
                pharmacyApps: {
                    ...state.pharmacyApps,
                    isProcessing: false,
                },
            };
        default:
            return state;
    }
};
