import _ from 'lodash';
import ThirdPartyNames from 'constants/ThirdPartyNames';

import TigerPistol from './ThirdParty/TigerPistol';

export default (thirdPartyName) => {
    const mixinFunctions = {
        isCustPackageAttributeActive() {
            const custPackageAttribute = this.getCustPackageAttribute();
            return custPackageAttribute && !_.isEmpty(custPackageAttribute.value);
        },

        async getPackageAttrUpdatedWithStatus(status) {
            const custPackageAttribute = { ...this.getCustPackageAttribute() };
            custPackageAttribute.value = '';
            if (status === 'enabled') {
                custPackageAttribute.value = this.getAttributeValue
                    ? await this.getAttributeValue()
                    : await this.getValueFromTextbox();
            }
            return custPackageAttribute;
        },

        ...(thirdPartyName === ThirdPartyNames.tigerPistol ? TigerPistol : {}),
    };

    return mixinFunctions;
};
