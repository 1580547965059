import moment from 'moment';
import _ from 'lodash';

import { socketType } from 'redux/Socket';
import { sendNotification } from 'utils/notification';
import { fetchUnreadVoicemails } from 'redux/actionCreators/Voicemail';
import { fetchRefills, fetchRefillsCount } from 'redux/actionCreators/Refills';
import { fetchLatestLeads } from 'redux/actionCreators/Leads';
import { NotificationGroupType } from '../../constants/Notification';

const socketMessagesMiddleware = (store) => (next) => (action) => {
    if (action.type === socketType.SOCKET_RECEIVE_MESSAGE) {
        if (_.get(action, 'payload.messageType', '') === 'ivrVm') {
            const {
                pharmacy: { ivrEnabled },
                auth: { isAuthenticated },
            } = store.getState();

            if (ivrEnabled) {
                store.dispatch(fetchUnreadVoicemails({ isRead: 0, startRow: 0, numRows: 25 }));
            }

            if (!isAuthenticated || (isAuthenticated && ivrEnabled)) {
                sendNotification('New Voicemail', 'Click here to view your new message', NotificationGroupType.ivr);
            }
        } else if (_.get(action, 'payload.messageType', '') === 'newRefill') {
            const { messageAttributes } = action.payload;
            const {
                pharmacy: { allowRefillNotification },
                auth: { isAuthenticated },
            } = store.getState();

            if (isAuthenticated) {
                const payload = {
                    pharmacy_id: messageAttributes.pharmacy_id,
                    location_id: messageAttributes.location_id,
                };
                const MAX_RECORDS = 25;
                store.dispatch(fetchRefillsCount(payload));
                store.dispatch(
                    fetchRefills({
                        ...payload,
                        numberOfOrders: MAX_RECORDS,
                        latestOrderTimestamp: moment.utc().format(),
                    })
                );
            }

            if (messageAttributes.notify_of_failure === 'True' && allowRefillNotification) {
                sendNotification(
                    'Issue with a refill submission',
                    'Click here to view your refills',
                    NotificationGroupType.refill
                );
            }
        } else if (_.get(action, 'payload.messageType', '') === 'newLead') {
            const { messageData } = action.payload;
            const {
                auth: { isAuthenticated },
                pharmacy: { allowLeadNotification },
            } = store.getState();

            if (isAuthenticated) {
                store.dispatch(fetchLatestLeads(messageData));
            }

            if (allowLeadNotification) {
                sendNotification('New Patient Lead', 'Click here to view your new lead', NotificationGroupType.lead);
            }
        }
    }
    next(action);
};

export default socketMessagesMiddleware;
