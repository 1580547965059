import apptTypeDataTypes from './type';

const initialState = {
    availableApptTypes: null,
};

export function apptTypeReducer(state = initialState, { type, payload }) {
    switch (type) {
        case apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_REQUEST:
        case apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_SUCCESS:
        case apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_FAILURE:
            return {
                ...state,
                loadingAvailableApptTypes: type === apptTypeDataTypes.GET_AVAILABLE_APPOINTMENT_TYPES_REQUEST,
                availableApptTypes: {
                    ...payload,
                },
            };
        default:
            return state;
    }
}
