import React, { PureComponent } from 'react';

import Service from './Service';
import WithCustPackages from './CustPackages';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pharmacyAction } from '../../../../redux/Pharmacy/action';
import {
    updateCustPackageAttribute,
    addCustPackageAttribute,
    getPackageAttributes,
} from '../../../../redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2PackageAttributes';
import _ from 'lodash';
import Snowplow, { SnowplowConstants, StructEventCategories } from '../../../../snowplow';

const title = 'Newsletter Stats';
const description =
    'To have the stats show on the report page, activate this ' +
    'feature if the customer has been set up with newsletter.';

const NewsLetters = (props) => <Service title={title} description={description} {...props} />;

const NewslettersPackageToggle = WithCustPackages(NewsLetters, {
    title,
    description,
    code: 'NSLT',
});

/*
Based on CustPackageAttribute.jsx
This component add a simple shim around the package toggle in order to enable the Version2 attribute when the
Newsletter package is enabled #justKeepShimming
Intended to only be needed/used until all customers are migrated to V2
*/
// TODO remove this after migration to Newsletter V2
class NewslettersWithVersion2Activation extends PureComponent {
    constructor(props) {
        super(props);
        this.package = {
            code: 'NSLT',
            attribute: {
                name: 'Version2',
                isLocationLevelAttr: false,
            },
        };
    }

    getCustPackage = () => _.find(this.props.pharmacy.package, (pkg) => pkg.code === this.package.code);

    getCustPackageAttribute = () => {
        const { activeLocationId } = this.props;
        const custPackage = this.getCustPackage() || {};
        const { name, isLocationLevelAttr } = this.package.attribute;
        const isDesiredProperty = (p) => p.name === name && (!isLocationLevelAttr || p.locationId === activeLocationId);
        return _.find(custPackage.properties, (p) => isDesiredProperty(p));
    };

    getPackageAttrUpdatedWithStatus(status) {
        const custPackageAttribute = { ...this.getCustPackageAttribute() };
        custPackageAttribute.value = status === 'enabled' ? 1 : 0;
        return custPackageAttribute;
    }

    updateCustPackageAttribute = async (status) => {
        const { pharmacy, updateCustPackageAttribute } = this.props;
        const custPackage = this.getCustPackage();
        const custPackageAttribute = await this.getPackageAttrUpdatedWithStatus(status);

        const cust = {
            id: pharmacy.id,
            packageID: custPackage.id,
        };
        const payload = {
            title: this.package.attribute.name,
            id: custPackageAttribute.id,
            value: custPackageAttribute.value,
        };

        !_.isUndefined(payload.value) && (await updateCustPackageAttribute(cust, payload, false, false));
    };

    addCustPackageAttribute = async () => {
        const { pharmacy, addCustPackageAttribute, activeLocationId } = this.props;
        const { thirdPartyName } = this.package.attribute;
        const title = thirdPartyName ? `${thirdPartyName} ID` : this.title;
        const custPackage = this.getCustPackage();
        const packageAttribute = await getPackageAttributes(custPackage.pkgId, this.package.attribute.name);
        const cust = {
            id: pharmacy.id,
            packageID: custPackage.id,
        };

        const payload = {
            title: title,
            dataTypeId: packageAttribute.dataTypeId,
            packageAttrId: packageAttribute.id,
            value: 1,
        };

        if (this.package.attribute.isLocationLevelAttr) {
            payload.locationId = activeLocationId;
        }

        !_.isUndefined(payload.value) && (await addCustPackageAttribute(cust, payload, false, false));
    };

    activateNewslettersVersion2Attribute = async () => {
        const { getPharmacy } = this.props;
        const pharmacy = await getPharmacy(this.props.pharmacy.id);

        try {
            const custPackage = _.find(pharmacy.package, (pkg) => pkg.code === this.package.code);
            const v2Attribute = _.find(custPackage.properties, ({ name }) => name === 'Version2');
            // if the v2Attribute does not exist, then lets create a new one, otherwise just enable it
            if (_.isNil(v2Attribute.id)) {
                await this.addCustPackageAttribute();
            } else {
                await this.updateCustPackageAttribute('enabled');
            }

            Snowplow.structEvent(
                StructEventCategories.newsletters,
                'activate-version-2',
                null,
                null,
                SnowplowConstants.success
            );
        } catch (e) {
            console.error('ERROR activating newsletter v2 attribute', e);

            Snowplow.structEvent(
                StructEventCategories.newsletters,
                'activate-version-2',
                null,
                null,
                SnowplowConstants.failure
            );
        }

        // Refresh the pharmacy to have the latest values, irrespective of success or failure
        await getPharmacy(pharmacy.id);
    };

    render() {
        return <NewslettersPackageToggle onCustPackageActivated={this.activateNewslettersVersion2Attribute} />;
    }
}

NewslettersWithVersion2Activation.propTypes = {
    pharmacy: PropTypes.object,
    getPharmacy: PropTypes.func,
    updateCustPackageAttribute: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        pharmacy: state.pharmacy.pharmacy,
    };
};

const getPharmacy = pharmacyAction.getPharmacy;

const mapDispatchToProps = {
    getPharmacy,
    updateCustPackageAttribute,
    addCustPackageAttribute,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewslettersWithVersion2Activation);
