import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

import { newsletterActions } from 'redux/Newsletter/action';
import { DRAWER_STATES } from 'constants/Newsletter';
import UploadSubscribersDialog from 'components/Resources/Newsletter/UploadSubscribersDialog';

const EmptySubscriber = (props) => {
    const [openCsvUploadDialog, setOpenCsvUploadDialog] = useState(false);
    const { isUploadSubscribersEnabled } = props;

    const handleAddNewSubscriberClick = () => {
        const { setDrawerState, resetActiveSubscriber } = props;
        setDrawerState(DRAWER_STATES.ADD_SUBSCRIBER);
        resetActiveSubscriber();
    };

    const handleCsvUploadClick = () => {
        // TODO: Handle csv upload
        setOpenCsvUploadDialog(true);
    };

    return (
        <div className="empty-subscriber">
            <Typography className="title"> Subscribers </Typography>
            <Grid container className="flex-vertical-centered-content content">
                <img
                    width={148}
                    height={148}
                    className="standard-margin-bottom-10"
                    src={`/newsletter/empty_subscriber.png`}
                    alt="Empty subscriber icon"
                />
                <div className="flex-vertical-centered-content">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewSubscriberClick}
                        className="standard-margin-bottom-10 full-width"
                    >
                        + Add a subscriber
                    </Button>
                    {isUploadSubscribersEnabled && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCsvUploadClick}
                            className="full-width"
                        >
                            + Upload a CSV
                        </Button>
                    )}
                </div>
                {openCsvUploadDialog && (
                    <UploadSubscribersDialog
                        onCloseWrapper={() => setOpenCsvUploadDialog(false)}
                        open={openCsvUploadDialog}
                    />
                )}
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isUploadSubscribersEnabled: state.launchDarkly.npeNewsletterUploadSubscribers,
});

const mapDispatchToProps = {
    setDrawerState: newsletterActions.setDrawerState,
    resetActiveSubscriber: newsletterActions.resetActiveSubscriber,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptySubscriber);
