export default [
    {
        link: 'https://dashboard.rxwiki.com/newsletter',
        title: 'eNewsletter',
    },
    {
        link: '/reviews',
        title: 'Reviews',
    },
    {
        link: '/classes/type2Diabetes',
        title: 'Classes',
    },
    {
        link: 'https://help.digitalpharmacist.com/en',
        title: 'Help Center',
    },
    {
        link: 'https://status.digitalpharmacist.com/',
        title: 'Status',
    },
    {
        link: 'https://www.digitalpharmacist.com/blog/',
        title: 'Blog',
    },
    {
        link: 'https://www.digitalpharmacist.com/print-marketing/',
        title: 'Print Marketing',
    },
    {
        link: '/notice',
        title: 'Subscriber Notice',
    },
];
