import { get, post, deleteReq } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import Actions from 'redux/actions';
import { fetchMainGreeting, saveGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from '../../../utils/log';

const getHuntGroupUrl = (id) => {
    const huntGroupApiPath = API_PATH.HUNT_GROUP.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', id);
    return Config.config2_url + huntGroupApiPath;
};

export const saveOverflowSetting = (data) => (dispatch) => {
    dispatch({ type: Actions.SAVE_OVERFLOW_STARTED });
    return dispatch(saveGreeting(data))
        .then((resp) => {
            dispatch({ type: Actions.SAVE_OVERFLOW_FINISHED });
            return { data: resp };
        })
        .catch((err) => {
            return { err };
        });
};

export const fetchHuntGroup = (data) => (dispatch) => {
    const huntGroupApiUrl = getHuntGroupUrl(data.id);
    const header = { params: { numRows: 99 } };
    const fetchHuntGroupReq = get(huntGroupApiUrl, header);

    return fetchHuntGroupReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_HUNT_GROUP_SUCCESS, payload: { huntGroups: resp.result } });
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on fetchHuntGroupReq', err);
            dispatch({ type: Actions.FETCH_HUNT_GROUP_FAILED });
            return { err };
        });
};

export const fetchGreetingWithHuntGroup = (canCreateGreeting = false) => (dispatch) => {
    dispatch({ type: Actions.FETCH_HUNT_GROUP_STARTED });
    dispatch(fetchMainGreeting()).then((resp) => {
        if (resp.err) {
            if (canCreateGreeting) {
                dispatch(saveGreeting({ newGreeting: {}, type: 'MAIN' })).then(() => {
                    dispatch(fetchMainGreeting()).then((data) => {
                        dispatch({ type: Actions.SET_OVERFLOW_SETTING, payload: { ...data } });
                        dispatch(fetchHuntGroup(data));
                    });
                });
            } else {
                dispatch({ type: Actions.FETCH_HUNT_GROUP_FAILED });
            }
        } else {
            dispatch({ type: Actions.SET_OVERFLOW_SETTING, payload: resp });
            dispatch(fetchHuntGroup(resp));
        }
    });
};

export const saveHuntGroup = (data) => (dispatch) => {
    const huntGroupApiUrl = getHuntGroupUrl(data.id);
    dispatch({ type: Actions.SAVE_HUNT_GROUP_STARTED });
    const saveHuntGroupReq = post(huntGroupApiUrl, data.huntGroups);

    return saveHuntGroupReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_HUNT_GROUP_SUCCESS });
            dispatch(displayToast({ text: 'Hunt group saved', type: 'success' }));
            return resp;
        })
        .catch((err) => {
            logHttpError('An error occured on saveHuntGroupReq', err);
            dispatch({ type: Actions.SAVE_HUNT_GROUP_FAILED });
            dispatch(displayToast({ text: 'Hunt group save failed', type: 'error' }));
            return { err };
        });
};

export const addHuntGroup = () => ({
    type: Actions.ADD_HUNT_GROUP,
});

export const setHuntGroupChanges = (payload) => ({
    type: Actions.SET_HUNT_GROUP_CHANGES,
    payload,
});

export const changeOverflowSetting = (payload) => ({
    type: Actions.CHANGE_OVERFLOW_SETTING,
    payload,
});

export const deleteHuntGroup = (payload) => (dispatch) => {
    let huntGroupApiUrl = getHuntGroupUrl(payload.overflowSetting.id);
    huntGroupApiUrl = `${huntGroupApiUrl}/${payload.huntGroup.id}`;
    dispatch({ type: Actions.REMOVE_HUNT_GROUP_STARTED, payload });
    const removeGroupReq = deleteReq(huntGroupApiUrl);

    return removeGroupReq
        .then((data) => {
            dispatch({ type: Actions.REMOVE_HUNT_GROUP_SUCCESS, payload });
            dispatch(displayToast({ text: 'Hunt group removed', type: 'success' }));
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on removeGroupReq', err);
            dispatch({ type: Actions.REMOVE_HUNT_GROUP_FAILED, payload });
            dispatch(displayToast({ text: 'Hunt group remove failed', type: 'error' }));
            return { err };
        });
};

export const removeGroup = (payload) => ({ type: Actions.REMOVE_HUNT_GROUP_SUCCESS, payload });
