import './DateSelectionCalendar.sass';
import React, { useState } from 'react';
import moment from 'moment';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import ForwardIcon from '@material-ui/icons/Forward';
import pluralize from 'pluralize';
import numeral from 'numeral';

const FOCUSED_MONTH_FORMAT = 'MMMM YYYY';

export default function DateSelectionCalendar({
    focusedDate,
    onDateChange,
    isWeekSelection,
    highlightSelectedWeek,
    minSelectableDate,
    maxSelectableDate,
    initiallyFocusedMonth,
    appointmentCounts,
}) {
    const [focusedMonth, setFocusedMonth] = useState(
        !_.isNil(focusedDate) ? moment(focusedDate) : initiallyFocusedMonth ? initiallyFocusedMonth : moment()
    );

    const firstOfMonth = moment(`${focusedMonth.format('YYYY-MM')}-01`, 'YYYY-MM-DD');

    const firstOfMonthDayOfWeekIndex = Number(firstOfMonth.format('d'));
    const cellDate = moment(firstOfMonth).subtract(firstOfMonthDayOfWeekIndex, 'day');

    // get the number of days to the end of the month from the cellDate
    const rows = Math.ceil(moment(firstOfMonth).add(1, 'month').diff(cellDate, 'days') / 7);

    // subtract one here since we will increment prior to each cell render
    cellDate.subtract(1, 'day');

    const updateFocus = (date, updateMonth = true) => {
        //setFocusedDate(date);
        if (updateMonth) {
            setFocusedMonth(date);
        }
        if (_.isFunction(onDateChange)) {
            // if this is a weekly selection, then we will return the sunday at the start of the week instead of the
            // wednesday (middle of the week) that we actually track internally
            onDateChange(isWeekSelection ? moment(date).subtract(3, 'days') : date);
        }
    };

    const isDateSelectable = (d) => {
        if (!_.isNil(minSelectableDate) && d.format('YYYY-MM-DD') < minSelectableDate.format('YYYY-MM-DD')) {
            return false;
        }
        if (!_.isNil(maxSelectableDate) && d.format('YYYY-MM-DD') > maxSelectableDate.format('YYYY-MM-DD')) {
            return false;
        }
        return true;
    };

    const getCell = (cellDate) => {
        cellDate = moment(cellDate);
        const isFocused = !isWeekSelection && dateEquals(cellDate, focusedDate);
        const focusable = isDateSelectable(cellDate);
        const appointmentCount = _.get(appointmentCounts, cellDate.format('YYYY-MM-DD'), 0);
        return (
            <div>
                {appointmentCount === 0 ? null : <div className="has-appointment-background" />}
                <Tooltip
                    title={
                        appointmentCount === 0
                            ? ''
                            : `${numeral(appointmentCount).format('0,0')} scheduled ${pluralize(
                                  'appointment',
                                  appointmentCount
                              )}`
                    }
                >
                    <div
                        className={classnames({
                            cell: true,
                            'current-month':
                                cellDate.format(FOCUSED_MONTH_FORMAT) === focusedMonth.format(FOCUSED_MONTH_FORMAT),
                            today: dateEquals(moment(), cellDate),
                            'focused-date': isFocused,
                            focusable,
                            'has-appointments': appointmentCount > 0,
                        })}
                        onClick={() => {
                            if (focusable && !isFocused && !isWeekSelection) {
                                updateFocus(cellDate, true);
                            }
                        }}
                    >
                        {cellDate.format('D')}
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <div className={classnames({ 'date-selection-calendar': true, 'is-week-selection': isWeekSelection })}>
            {!isWeekSelection ? (
                <div className="navigation-shortcuts">
                    <Button
                        className={classnames({
                            'nav-button': true,
                            selected: dateEquals(moment().subtract(1, 'day'), focusedDate),
                        })}
                        onClick={() => updateFocus(moment().subtract(1, 'day'), true)}
                        disabled={!isDateSelectable(moment().subtract(1, 'day'))}
                    >
                        Yesterday
                    </Button>

                    <Button
                        className={classnames({ 'nav-button': true, selected: dateEquals(moment(), focusedDate) })}
                        onClick={() => updateFocus(moment(), true)}
                        disabled={!isDateSelectable(moment())}
                    >
                        Today
                    </Button>

                    <Button
                        className={classnames({
                            'nav-button': true,
                            selected: dateEquals(moment().add(1, 'day'), focusedDate),
                        })}
                        onClick={() => updateFocus(moment().add(1, 'day'), true)}
                        disabled={!isDateSelectable(moment().add(1, 'day'))}
                    >
                        Tomorrow
                    </Button>
                </div>
            ) : (
                <div className="navigation-shortcuts">
                    <Button
                        className={classnames({
                            'nav-button': true,
                            selected: dateEquals(getMiddleDateOfWeek(moment()), focusedDate),
                        })}
                        onClick={() => updateFocus(getMiddleDateOfWeek(moment()), true)}
                    >
                        This Week
                    </Button>
                    <Button
                        className={classnames({
                            'nav-button': true,
                            selected: dateEquals(getMiddleDateOfWeek(moment().add(7, 'days')), focusedDate),
                        })}
                        onClick={() => updateFocus(getMiddleDateOfWeek(moment().add(7, 'days')), true)}
                    >
                        Next Week
                    </Button>
                </div>
            )}
            <div className="heading-bar">
                <div className="focused-month-label">{focusedMonth.format(FOCUSED_MONTH_FORMAT)}</div>
                <div className="arrows">
                    <IconButton onClick={() => setFocusedMonth(moment(focusedMonth).subtract(1, 'month'))}>
                        <ForwardIcon style={{ transform: 'rotate(180deg)' }} />
                    </IconButton>

                    <IconButton onClick={() => setFocusedMonth(moment(focusedMonth).add(1, 'month'))}>
                        <ForwardIcon />
                    </IconButton>
                </div>
            </div>

            <div className="calendar-cells">
                <div className="calendar-row day-of-week-letters">
                    <div className="cell header">S</div>
                    <div className="cell header">M</div>
                    <div className="cell header">T</div>
                    <div className="cell header">W</div>
                    <div className="cell header">T</div>
                    <div className="cell header">F</div>
                    <div className="cell header">S</div>
                </div>

                {_.map(_.times(rows), (i) => {
                    let isFocusedWeek = false;
                    const firstDayOfWeek = moment(cellDate).add(1, 'day');
                    if (isWeekSelection || highlightSelectedWeek) {
                        const daysDiff = focusedDate.diff(firstDayOfWeek, 'days');
                        if (daysDiff >= 0 && daysDiff < 7) {
                            isFocusedWeek = true;
                        }
                    }

                    return (
                        <div
                            key={i}
                            className={classnames({
                                'calendar-row': true,
                                'focused-week': isWeekSelection && isFocusedWeek,
                                'highlighted-week': highlightSelectedWeek && isFocusedWeek,
                            })}
                            onClick={
                                !isWeekSelection
                                    ? null
                                    : (e) => {
                                          updateFocus(getMiddleDateOfWeek(firstDayOfWeek), true);
                                      }
                            }
                        >
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                            {getCell(cellDate.add(1, 'day'))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export function dateEquals(m1, m2) {
    if (_.isNil(m1) || _.isNil(m2)) {
        return false;
    }
    return m1.format('YYYY-MM-DD') === m2.format('YYYY-MM-DD');
}

export function getMiddleDateOfWeek(m) {
    const dayOfWeekIndex = Number(m.format('d'));
    return moment(m).add(3 - dayOfWeekIndex, 'days');
}

export function getFirstDateOfWeek(m) {
    return moment(m).subtract(Number(m.format('d')), 'days');
}

export function getDisplayedDateRange(focusedDate) {
    const firstOfMonth = moment(`${focusedDate.format('YYYY-MM')}-01`, 'YYYY-MM-DD');
    const endOfMonth = moment(firstOfMonth).add(1, 'month').subtract(1, 'day');

    const firstOfMonthDayOfWeekIndex = Number(firstOfMonth.format('d'));
    const endOfMonthDayOfWeekIndex = Number(endOfMonth.format('d'));

    const startDate = moment(firstOfMonth).subtract(firstOfMonthDayOfWeekIndex, 'day').format('YYYY-MM-DD');
    const endDate = moment(endOfMonth)
        .add(6 - endOfMonthDayOfWeekIndex, 'day')
        .format('YYYY-MM-DD');

    return { startDate, endDate };
}
