import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'IVR - New Patient Call Tracking - Call Recording';
const description =
    'Enable or disable call recording in New Patient Call Tracking feature. Does not affect CallRail ' +
    '- Marketing Call Tracking feature.';

const NewPatientCallRecording = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(NewPatientCallRecording, {
    package: {
        name: 'IVR',
        code: 'ICFG',
        attribute: {
            name: 'CallRecording',
            dataType: Config2Constants.dataType.boolean,
            isLocationLevelAttr: true,
        },
    },
    title,
    description,
});
