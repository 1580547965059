import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReactPlaceholder from 'react-placeholder';

const CardPlaceholder = ({ legendsCount, xs = 6, height = 50 }) => {
    return (
        <Grid item container spacing={2} style={{ marginTop: '10px' }}>
            {new Array(legendsCount).fill(0).map((item, idx) => (
                <Grid item xs={xs} key={idx} container>
                    <Grid item xs={3}>
                        <ReactPlaceholder
                            type="rect"
                            rows={1}
                            ready={false}
                            showLoadingAnimation
                            firstLaunchOnly
                            style={{ height }}
                        >
                            <div></div>
                        </ReactPlaceholder>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: '5px' }}>
                        <ReactPlaceholder type="textRow" rows={1} ready={false} showLoadingAnimation firstLaunchOnly>
                            <div></div>
                        </ReactPlaceholder>
                        <ReactPlaceholder type="textRow" rows={1} ready={false} showLoadingAnimation firstLaunchOnly>
                            <div></div>
                        </ReactPlaceholder>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default CardPlaceholder;
