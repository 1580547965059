import './PatientsSelectionPane.sass';
import React, { useState, useEffect } from 'react';
import PatientsHeaderBar from './PatientsHeaderBar';
import PatientsTable, { getNextFocusablePatientIndex } from './PatientsTable';
import MultiselectSidebar from './MultiselectSidebar';
import { connect } from 'react-redux';
import { userAction } from '../../redux/Inbox/User';
import {
    fetchPatientData,
    setFocusedPatient,
    fetchPatientInboxData,
    updatePatientData,
} from '../../redux/Patient/PatientData/action';
import { fetchPatientOpportunities } from '../../redux/Patient/Connect/action';
import _ from 'lodash';
import { CircularProgress, Slide } from '@material-ui/core';
import queryString from 'query-string';
import EditContactDialog from '../Inbox/EditContactDialog';
import ConversationsDialog from './ConversationsDialog';
import {
    updatePatientFromInboxUserData,
    isLikelyADateOfBirthQuery,
    isLikelyABirthdayQuery,
    adaptQueryParamsIntoFilters,
} from './patientsUtil';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { formatRawDate, formatRawPhone, getAbbreviatedDateInputFormatByCountryCode } from '../../utils/helper';
import FilterControls from './filtering/FilterControls';
import PatientSelectionImportDialog from './csv/selection/PatientSelectionImportDialog';
import ViewWaitlistsDialog from '../Patients/ViewWaitlistsDialog';
import classnames from 'classnames';
import { fetchAvailableAppointmentCalendars } from 'redux/Appt/Calendar/action';
import PatientAppointmentsDialog from './PatientAppointmentsDialog';

// this transition must be declared outside of the parent component so that it will maintain its state and
// have an exit animation
const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
};
const NoOpTransition = (props) => {
    return <div>{props.children}</div>;
};

function PatientsSelectionPane({
    nameFormat,
    setNameFormat,
    location,
    openEditUserDialog,
    height,
    fetchPatientData,
    fetchPatientInboxData,
    selectedPatients,
    setSelectedPatients,
    sidebarHeaderComponent,
    inboxUser,
    patientData,
    closeEditUserDialog,
    updateUser,
    archiveUser,
    setActiveUser,
    mayOnlySelectMessageablePatients,
    autoFocusSearchBox,
    messageablePatients,
    updatePatientData,
    activeLocationCountryCode,
    waitlistIdToQuery,
    locationUuid,
    showPatientsLoading,
    apptCalendar,
    fetchAvailableAppointmentCalendars,
}) {
    const props = arguments[0];
    // it is important that this is initialized to undefined (and not null) since react-virtualized treats null as 0 and
    // causes our scrolling to jump back to the first row after loading otherwise
    const [focusedIndex, setFocusedIndex] = useState(undefined);
    const [patientBeingEdited, setPatientBeingEdited] = useState(null);
    const [patientBeingMessaged, setPatientBeingMessaged] = useState(null);
    const [showingPatientAppointments, setShowingPatientAppointments] = useState(null);
    const [showPatientSelectCsvUpload, setShowPatientSelectCsvUpload] = useState(false);
    const [showWaitlistDialog, setShowWaitlistDialog] = useState(false);
    const [filtersHeight, setFiltersHeight] = useState(45);

    // simple counter that will be incremented when the patients index should be reloaded due to having changed in the backend
    const [indexRefreshCounter, setIndexRefreshCounter] = useState(0);

    const displayedDateFormat = getAbbreviatedDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode });

    const formatDate = (dob) => formatRawDate(dob, 'YYYY-MM-DD', displayedDateFormat);
    const unformatDate = (d) => formatRawDate(d, displayedDateFormat, 'YYYY-MM-DD');
    const formatPhone = (phone) => formatRawPhone(phone, activeLocationCountryCode);

    const queryParams = queryString.parse(location.search);
    const sort = _.get(queryParams, 'sort', 'latest_activity_date:desc');

    const filters = adaptQueryParamsIntoFilters(queryParams);

    const getQueryTextAndFieldToQuery = () => {
        const listToSearch = _.isNil(waitlistIdToQuery) ? 'patients' : `waitlist:${waitlistIdToQuery}`;
        if (isLikelyADateOfBirthQuery(queryParams.q, displayedDateFormat)) {
            return { queryText: unformatDate(queryParams.q), fieldToQuery: 'date_of_birth', listToSearch };
        } else if (isLikelyABirthdayQuery(queryParams.q, displayedDateFormat)) {
            // moment will use current year to format the date if we don't put it,
            // so we will just need to chop the year back off of it
            const fullDateString = unformatDate(queryParams.q);
            const queryText = _.tail(fullDateString.split('-')).join('-');
            return { queryText, fieldToQuery: 'birthday', listToSearch };
        }

        return { queryText: queryParams.q, fieldToQuery: 'name', listToSearch };
    };

    const { listToSearch } = getQueryTextAndFieldToQuery();

    const isWrongListLoaded = listToSearch !== patientData.listToSearch;

    useEffect(() => {
        const { queryText, fieldToQuery, listToSearch } = getQueryTextAndFieldToQuery();

        fetchPatientData(queryText, queryParams.sort, filters, 0, 99, { fieldToQuery, listToSearch });
    }, [
        queryParams.q,
        queryParams.sort,
        queryParams.filter,
        queryParams.age,
        queryParams.canMessage,
        queryParams.convStatus,
        queryParams.assessments,
        queryParams.attributes,
        queryParams.patientType,
        queryParams.waitlistStatus,
        queryParams.waitlist,
        queryParams.appts,
        indexRefreshCounter,
        locationUuid,
    ]);

    useEffect(() => {
        if (!apptCalendar.loadingAvailableCalendars && _.isNil(apptCalendar.availableCalendars)) {
            fetchAvailableAppointmentCalendars();
        }
    }, [apptCalendar]);

    useEffect(() => {
        // reset the focusedIndex if it is non-null or  we are autofocusing
        if (autoFocusSearchBox || _.isFinite(focusedIndex)) {
            setFocusedIndex(getNextFocusablePatientIndex(mayOnlySelectMessageablePatients, -1, patientData));
        }
    }, [queryParams.q, queryParams.sort, queryParams.filter, patientData.patientCount, queryParams.age]);

    useEffect(() => {
        const { patientInboxData } = patientData;

        if (_.isObject(patientInboxData)) {
            setActiveUser(patientInboxData.inbox_user_id, patientInboxData);
        }
    }, [_.get(patientData, 'patientInboxData.inbox_user_id')]);

    useEffect(() => {
        if (_.isFinite(patientData.patientIndexUpdatedAt) && !patientData.pendingRequests.has('0-99')) {
            setTimeout(() => setIndexRefreshCounter(indexRefreshCounter + 1), 1000);
        }
    }, [patientData.patientIndexUpdatedAt]);

    return (
        <div
            className={classnames({ 'patients-selection-pane': true, loading: showPatientsLoading })}
            style={{ height: `${height}px` }}
        >
            <div className="patients-selection-pane-inner">
                <PatientsHeaderBar
                    {...props}
                    selectedUsers={selectedPatients}
                    onSelectedChange={(selectedPatients) => {
                        setSelectedPatients(selectedPatients);
                    }}
                    focusedIndex={focusedIndex}
                    setFocusedIndex={setFocusedIndex}
                    displayedDateFormat={displayedDateFormat}
                    setShowPatientSelectCsvUpload={setShowPatientSelectCsvUpload}
                    setShowWaitlistDialog={setShowWaitlistDialog}
                    isWrongListLoaded={isWrongListLoaded}
                    {...getQueryTextAndFieldToQuery()}
                />
                <FilterControls
                    {...props}
                    queryParams={queryParams}
                    onHeightChange={(height) => {
                        setFiltersHeight(height);
                    }}
                />
                <div className="table-and-sidebar" style={{ height: `calc(100% - ${15 + 50 + filtersHeight}px)` }}>
                    <PatientsTable
                        {...props}
                        nameFormat={nameFormat}
                        setNameFormat={setNameFormat}
                        formatDate={formatDate}
                        formatPhone={formatPhone}
                        queryParams={queryParams}
                        sortField={_.first(sort.split(':'))}
                        sortDirection={_.last(sort.split(':'))}
                        isWrongListLoaded={isWrongListLoaded}
                        loadMoreRows={async ({ startIndex, stopIndex }) => {
                            const { queryText, fieldToQuery, listToSearch } = getQueryTextAndFieldToQuery();
                            fetchPatientData(queryText, queryParams.sort, filters, startIndex, stopIndex, {
                                fieldToQuery,
                                listToSearch,
                            });
                        }}
                        selectedUsers={selectedPatients}
                        onSelectedChange={(selectedPatients) => {
                            setSelectedPatients(selectedPatients);
                        }}
                        onEditPatient={(patient) => {
                            setPatientBeingEdited(patient);
                            fetchPatientInboxData(patient.inbox_user_id);
                            openEditUserDialog();
                        }}
                        onMessagePatient={(patient) => {
                            setPatientBeingMessaged(patient);
                            fetchPatientInboxData(patient.inbox_user_id);
                        }}
                        onShowAppointments={(patient) => {
                            setShowingPatientAppointments(patient);
                        }}
                        focusedIndex={focusedIndex}
                    />
                    <div>
                        <MultiselectSidebar
                            {...props}
                            headerComponent={sidebarHeaderComponent}
                            nameFormat={nameFormat}
                            formatDate={formatDate}
                            SlideTransition={window.innerWidth >= 1000 ? NoOpTransition : SlideTransition}
                            selectedUsers={selectedPatients}
                            messageablePatients={_.isNil(messageablePatients) ? selectedPatients : messageablePatients}
                            onSelectedChange={(selectedPatients) => {
                                setSelectedPatients(selectedPatients);
                            }}
                        />
                    </div>
                </div>
            </div>

            {!showPatientsLoading ? null : (
                <div className="loading-message">
                    <CircularProgress size={20} /> <span>Processing patient changes...</span>
                </div>
            )}

            <EditContactDialog
                visible={inboxUser.showEditUserDialog}
                closeDialog={closeEditUserDialog}
                sending={inboxUser.sending}
                requestError={inboxUser.updateUserMessage}
                updateContact={(userData, userId) => {
                    updateUser(userData, userId, (updatedUserData) => {
                        const updated = updatePatientFromInboxUserData(
                            patientData.loadedPatientsByInboxUserId[updatedUserData.inbox_user_id],
                            updatedUserData
                        );
                        updatePatientData(updated);
                    });
                }}
                archiveContact={archiveUser}
                contact={_.isNil(patientBeingEdited) ? {} : patientBeingEdited}
            />

            <ConversationsDialog
                {...props}
                loading={
                    _.isNil(patientData.patientInboxData) ||
                    inboxUser.activeUserId !== _.get(patientData, 'patientInboxData.inbox_user_id')
                }
                open={!_.isNil(patientBeingMessaged)}
                onClose={() => setPatientBeingMessaged(null)}
                contact={_.isNil(patientData.patientInboxData) ? {} : patientData.patientInboxData}
            />

            <PatientSelectionImportDialog
                open={showPatientSelectCsvUpload}
                onClose={() => setShowPatientSelectCsvUpload(false)}
                activeLocationCountryCode={activeLocationCountryCode}
                selectedPatients={selectedPatients}
                setSelectedPatients={setSelectedPatients}
                te
            />
            <ViewWaitlistsDialog open={showWaitlistDialog} onClose={() => setShowWaitlistDialog(false)} />

            {_.isNil(showingPatientAppointments) ? null : (
                <PatientAppointmentsDialog
                    {...props}
                    onClose={() => setShowingPatientAppointments(null)}
                    patient={showingPatientAppointments}
                />
            )}
        </div>
    );
}

function mapStateToProps(state) {
    const { patientData, connect, inboxUser, inboxConversationTemplate, pharmacy, apptCalendar } = state;

    return {
        apptCalendar,
        patientData,
        connect,
        inboxUser,
        inboxConversationTemplate,
        activeLocationCountryCode: pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state),
        basicMessagingEnabled: pharmacySelectors.pharmacyHasBasicMessagingEnabled(state),
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
        locationUuid: pharmacy.activeLocationId,
    };
}

const bindActionsToDispatch = {
    fetchPatientData,
    fetchPatientInboxData,
    setFocusedPatient,
    fetchPatientOpportunities,
    updatePatientData,
    fetchAvailableAppointmentCalendars,
    updateUser: userAction.updateUser,
    archiveUser: userAction.archiveUser,
    openEditUserDialog: userAction.openEditUserDialog,
    closeEditUserDialog: userAction.closeEditUserDialog,
    setActiveUser: userAction.setActiveUser,
};

export default connect(mapStateToProps, bindActionsToDispatch)(PatientsSelectionPane);
