import moment from 'moment';

import Actions from 'redux/actions';
import { newGreetingTemplate } from 'constants/MainGreeting';

const initialState = {
    greetingFiles: [],
    defaultGreeting: '',
    exceptionGreetings: [],
    newGreeting: {},
    showGreetingCreator: false,
    loadingMainGreeting: false,
    loadingSpecialGreetings: false,
    tts_id: '',
    isFilenameSaving: false,
    isResetIVRinProgress: false,
};

const changeAfterHourSetting = (state, payload) => {
    const { newGreeting } = { ...state };
    if (payload.type === 'phone') {
        newGreeting.emergency_phone = payload.phone;
        newGreeting.error = payload.phone ? '' : 'Required';
    } else {
        newGreeting.emergency_xfer_sec = payload.sec;
    }
    return {
        ...state,
        newGreeting,
    };
};

const addGreeting = (state, payload) => {
    const newGreeting = { ...newGreetingTemplate };
    if (!payload) {
        newGreeting.startDate = moment().hour(0).minute(0).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
        newGreeting.endDate = moment().hour(23).minute(59).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    }
    return {
        ...state,
        showGreetingCreator: true,
        newGreeting,
    };
};

const editGreeting = (state, payload) => {
    const newGreeting = { ...state.defaultGreeting };
    newGreeting.msgType = payload.type;
    if (payload.type === 'closed' || payload.type === 'aa_welcome') {
        newGreeting.greeting_id = newGreeting[`${payload.type}_greeting_id`];
        newGreeting.greeting_url = newGreeting[`${payload.type}_greeting_url`];
    }
    return {
        ...state,
        showGreetingCreator: true,
        newGreeting,
    };
};

const editExceptionGreeting = (state, payload) => {
    const exceptionGreetings = { ...state.exceptionGreetings };
    const newGreeting = { ...exceptionGreetings[payload.idx] };
    newGreeting.msgType = 'special';
    newGreeting.oldStartDate = newGreeting.startDate;
    newGreeting.oldEndDate = newGreeting.endDate;
    newGreeting.idx = payload.idx;

    return {
        ...state,
        showGreetingCreator: true,
        newGreeting,
    };
};

const updateGreetingMsg = (state, payload) => {
    const newGreeting = { ...state.newGreeting };
    if (payload) {
        const greetingFiles = [...state.greetingFiles];
        const selectedGreetingFile = greetingFiles.find((file) => file.id === payload);
        newGreeting.greeting_id = selectedGreetingFile.id;
        newGreeting.greeting_url = selectedGreetingFile.publicURL;
    } else {
        newGreeting.greeting_id = '';
        newGreeting.greeting_url = '';
    }

    return {
        ...state,
        newGreeting,
    };
};

const updateExceptionDate = (state, payload) => {
    const newGreeting = { ...state.newGreeting };
    newGreeting.startDate = moment(payload.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    newGreeting.endDate = moment(payload.endDate)
        .hour(23)
        .minute(59)
        .seconds(0)
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    return {
        ...state,
        newGreeting,
    };
};

const markDefault = (state, payload) => {
    const exceptionGreetings = [...state.exceptionGreetings];
    const defaultGreeting = { ...state.defaultGreeting };
    exceptionGreetings[payload.idx].processing = false;
    defaultGreeting.greeting_id = exceptionGreetings[payload.idx].greeting_id;
    defaultGreeting.greeting_url = exceptionGreetings[payload.idx].greeting_url;

    return {
        ...state,
        defaultGreeting,
        exceptionGreetings,
    };
};

const removedGreeting = (state, payload) => {
    const defaultGreeting = { ...state.defaultGreeting };
    if (payload.type === 'default') {
        defaultGreeting.greeting_id = '';
        defaultGreeting.greeting_url = '';
        defaultGreeting.processing = false;
    } else if (payload.type === 'closed' || payload.type === 'aa_welcome') {
        defaultGreeting[`${payload.type}_greeting_id`] = '';
        defaultGreeting[`${payload.type}_greeting_url`] = '';
        defaultGreeting[`${payload.type}_processing`] = false;
    }

    return {
        ...state,
        defaultGreeting,
    };
};

const removedSpecialGreeting = (state, payload) => {
    const exceptionGreetings = [...state.exceptionGreetings];
    exceptionGreetings.splice(payload.idx, 1);
    return {
        ...state,
        exceptionGreetings,
    };
};

const removedGreetingLoading = (state, payload, loading) => {
    const defaultGreeting = { ...state.defaultGreeting };

    if (payload.type === 'default') {
        defaultGreeting.processing = loading;
    } else if (payload.type === 'closed' || payload.type === 'aa_welcome') {
        defaultGreeting[`${payload.type}_processing`] = loading;
    }

    return {
        ...state,
        defaultGreeting,
    };
};

const removedSpecialGreetingLoading = (state, payload, loading) => {
    const exceptionGreetings = [...state.exceptionGreetings];
    exceptionGreetings[payload.idx].processing = loading;
    return {
        ...state,
        exceptionGreetings,
    };
};

const saveGreetingLoading = (state, loading) => {
    const newGreeting = { ...state.newGreeting };
    newGreeting.processing = loading;
    return {
        ...state,
        newGreeting,
    };
};

const changeGreetingType = (state, payload) => {
    const newGreeting = { ...state.newGreeting };
    newGreeting.greeting_type_id = payload;
    return {
        ...state,
        newGreeting,
    };
};

const changeGreetingScript = (state, payload) => {
    const newGreeting = { ...state.newGreeting };
    newGreeting.greeting_text = payload;
    return {
        ...state,
        newGreeting,
    };
};

const changeTTSSetting = (state, payload) => {
    const defaultGreeting = { ...state.defaultGreeting };
    if (!defaultGreeting.old_tts_id) {
        defaultGreeting.old_tts_id = defaultGreeting.tts_id;
    }
    defaultGreeting.tts_id = payload;
    return {
        ...state,
        defaultGreeting,
    };
};

export default function MainGreetingReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_GREETING_FILES_SUCCESS:
            return { ...state, greetingFiles: payload.result };
        case Actions.FETCH_MAIN_GREETING_STARTED:
            return { ...state, loadingMainGreeting: true, defaultGreeting: '', newGreeting: {} };
        case Actions.FETCH_MAIN_GREETING_SUCCESS:
            return { ...state, loadingMainGreeting: false, defaultGreeting: payload };
        case Actions.FETCH_MAIN_GREETING_FAILED:
            return { ...state, loadingMainGreeting: false };
        case Actions.FETCH_SPECIAL_GREETING_STARTED:
            return { ...state, loadingSpecialGreetings: true };
        case Actions.FETCH_SPECIAL_GREETING_SUCCESS:
            return { ...state, loadingSpecialGreetings: false, exceptionGreetings: payload.result };
        case Actions.FETCH_SPECIAL_GREETING_FAILED:
            return { ...state, loadingSpecialGreetings: false };
        case Actions.ADD_GREETING:
            return addGreeting(state, payload);
        case Actions.CLOSE_GREETING_EDITOR:
            return { ...state, showGreetingCreator: false, newGreeting: {} };
        case Actions.UPDATE_GREETING_MESSAGE:
            return updateGreetingMsg(state, payload);
        case Actions.UPDATE_SPECIAL_GREETING_DATERANGE:
            return updateExceptionDate(state, payload);
        case Actions.REMOVE_GREETING_STARTED:
            return removedGreetingLoading(state, payload, true);
        case Actions.REMOVE_GREETING_SUCCESS:
            return removedGreeting(state, payload);
        case Actions.REMOVE_GREETING_FAILED:
            return removedGreetingLoading(state, payload, false);
        case Actions.REMOVE_SPECIAL_GREETING_SUCCESS:
            return removedSpecialGreeting(state, payload);
        case Actions.MARK_DEFAULT_GREETING_SUCCESS:
            return markDefault(state, payload);
        case Actions.REMOVE_SPECIAL_GREETING_STARTED:
        case Actions.MARK_DEFAULT_GREETING_STARTED:
            return removedSpecialGreetingLoading(state, payload, true);
        case Actions.REMOVE_SPECIAL_GREETING_FAILED:
        case Actions.MARK_DEFAULT_GREETING_FAILED:
            return removedSpecialGreetingLoading(state, payload, false);
        case Actions.SAVE_MAIN_GREETING_STARTED:
        case Actions.SAVE_GREETING_FILE_STARTED:
        case Actions.SAVE_SPECIAL_GREETING_STARTED:
        case Actions.SAVE_DOCTOR_GREETING_STARTED:
        case Actions.SAVE_PATIENT_GREETING_STARTED:
        case Actions.SAVE_NEW_PATIENT_LINE_GREETING_STARTED:
            return saveGreetingLoading(state, true);
        case Actions.SAVE_MAIN_GREETING_SUCCESS:
        case Actions.SAVE_SPECIAL_GREETING_SUCCESS:
        case Actions.SAVE_DOCTOR_GREETING_SUCCESS:
        case Actions.SAVE_PATIENT_GREETING_SUCCESS:
        case Actions.SAVE_NEW_PATIENT_LINE_GREETING_SUCCESS:
        case Actions.SAVE_GREETING_FILE_FAILED:
        case Actions.SAVE_MAIN_GREETING_FAILED:
        case Actions.SAVE_SPECIAL_GREETING_FAILED:
        case Actions.SAVE_DOCTOR_GREETING_FAILED:
        case Actions.SAVE_PATIENT_GREETING_FAILED:
        case Actions.SAVE_NEW_PATIENT_LINE_GREETING_FAILED:
            return saveGreetingLoading(state, false);
        case Actions.EDIT_GREETING:
            return editGreeting(state, payload);
        case Actions.EDIT_EXCEPTION_GREETING:
            return editExceptionGreeting(state, payload);
        case Actions.UPDATE_GREETING_FILE_STARTED:
            return { ...state, isFilenameSaving: true };
        case Actions.UPDATE_GREETING_FILE_SUCCESS:
        case Actions.UPDATE_GREETING_FILE_FAILED:
            return { ...state, isFilenameSaving: false };
        case Actions.RESET_IVR_STARTED:
            return { ...state, isResetIVRinProgress: true };
        case Actions.RESET_IVR_SUCCESS:
        case Actions.RESET_IVR_FAILED:
            return { ...state, isResetIVRinProgress: false };
        case Actions.CHANGE_GREETING_TYPE:
            return changeGreetingType(state, payload);
        case Actions.CHANGE_GREETING_SCRIPT:
            return changeGreetingScript(state, payload);
        case Actions.CHANGE_AFTER_HOUR_SETTING:
            return changeAfterHourSetting(state, payload);
        case Actions.CHANGE_TTS_SETTING:
            return changeTTSSetting(state, payload);
        default:
            return state;
    }
}
