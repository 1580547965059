import React from 'react';
import Config2Constants from 'constants/Config2';
import { CAMERONS_PHARMACY_ID, CAMERONS_LOCATION_ID } from 'constants/PharmacyIds';
import Config from 'config';
import ThirdPartyNames from 'constants/ThirdPartyNames';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Digital Marketing';
const description = 'Enable or disable Digital Marketing for this pharmacy.';

const DigitalMarketingWithTigerPistol = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(DigitalMarketingWithTigerPistol, {
    package: {
        name: 'Digital Marketing',
        code: 'DGMK',
        attribute: {
            name: 'tigerPistolId',
            dataType: Config2Constants.dataType.string,
            thirdPartyName: ThirdPartyNames.tigerPistol,
        },
    },
    checkDisabled: (pharmacyId, locationId) =>
        // prevent accidents see ATLAS-1483
        Config.environ === 'prod' && pharmacyId === CAMERONS_PHARMACY_ID && locationId === CAMERONS_LOCATION_ID,
    title,
    description,
});
