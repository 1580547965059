import './ConnectCard.sass';
import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Tooltip, Button } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarRateIcon from '@material-ui/icons/StarRate';
import { templateAction } from '../../../redux/Inbox/Template';
import { connect } from 'react-redux';
import numeral from 'numeral';
import pluralize from 'pluralize';
import { preprocessMessageTemplateText } from '../messageTemplateUtil';
import { openLinkInNewTab } from 'utils/helper';
import BlockIcon from '@material-ui/icons/Block';
import config from 'config';
import {
    formatRawPhone,
    getLocationAddressFromPharmacy,
    getLocationNameFromAuth,
    getLocationPhoneNumberFromPharmacy,
} from 'utils/helper';

function ConnectCard({
    name,
    templateText,
    width,
    height,
    icon,
    selected,
    id,
    onClick,
    starred,
    updateTemplate,
    templateData,
    nextStarCount,
    targetedPatientCount,
    auth,
    isFeatureAdvertisement,
    advertisementUrl,
    onDismissAdvertisement,
    additionalAdvertisementText,
    onAdvertisementClick,
    isWaitlistCard,
}) {
    const targetedPatientTitle =
        _.isFinite(targetedPatientCount) && targetedPatientCount > 0
            ? `${numeral(targetedPatientCount).format('0,0')} ${pluralize('patient', targetedPatientCount)} ` +
              `${pluralize('has', targetedPatientCount)} been targeted to receive this message.`
            : 'No patients currently targeted to receive this message';

    const handleClick = !isFeatureAdvertisement ? onClick : onAdvertisementClick;

    const waitlistPatientTitle =
        _.isFinite(targetedPatientCount) && targetedPatientCount > 0
            ? `${numeral(targetedPatientCount).format('0,0')} uncontacted ${pluralize(
                  'patient',
                  targetedPatientCount
              )} on this waitlist`
            : 'No uncontacted patients on this waitlist';

    return (
        <div
            className={classnames({
                'connect-card': true,
                selected,
                'feature-advertisement': isFeatureAdvertisement && _.isString(advertisementUrl),
            })}
            style={{ width: `${width}px`, height: `${height}px` }}
            onClick={() => handleClick(id)}
        >
            <div className="icon" style={{ height: `${height - 2}px`, width: `${Math.round(height * 1.5)}px` }}>
                {_.isNil(icon) ? null : (
                    <img src={icon} width={Math.round(height * 1.5)} height={height - 2} alt="card icon" />
                )}
                {!isFeatureAdvertisement ? null : (
                    <div className="advertisement-buttons" style={{ width: `${Math.round(height * 1.5)}px` }}>
                        <Tooltip title="Hide this informational workflow card">
                            <Button
                                className="dismiss-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (_.isFunction(onDismissAdvertisement)) {
                                        onDismissAdvertisement();
                                    }
                                }}
                            >
                                Hide
                            </Button>
                        </Tooltip>
                        <Tooltip title="Learn more about this workflow feature including how to enable it">
                            <Button
                                className="learn-more-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openLinkInNewTab(advertisementUrl);
                                }}
                            >
                                Learn More
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div className="text" style={{ width: `${width - height * 1.5 - 20}px` }}>
                <div className="title">
                    <span>
                        {!_.isFinite(targetedPatientCount) ? null : (
                            <Tooltip title={isWaitlistCard ? waitlistPatientTitle : targetedPatientTitle}>
                                <span className="targeted-patient-count">
                                    {numeral(targetedPatientCount).format('0,0')}
                                </span>
                            </Tooltip>
                        )}
                        <span className="name">{name}</span>
                    </span>

                    <span className="actions">
                        <Tooltip
                            title={
                                !starred
                                    ? 'Star this message to keep it at the top of the available messages. This is useful for frequently used messages.'
                                    : 'Unstar this message to remove it from the top of the available messages.'
                            }
                        >
                            <span
                                className={classnames({ 'star-wrapper': true, starred })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const clone = _.clone(templateData);
                                    clone.pharmacy_id = config.X_PharmacyID;
                                    clone.location_id = config.X_LocationID;

                                    clone.starred = starred ? 0 : nextStarCount;
                                    updateTemplate(clone);
                                }}
                            >
                                <StarBorderIcon className="star-border" />
                                <StarRateIcon className="star-inner" />
                            </span>
                        </Tooltip>
                    </span>
                </div>
                <div className="template-snippet">
                    {renderTemplateText(preprocessMessageTemplateText(templateText, auth))}
                </div>

                <div className="bottom-gradient" />
                <div className="bottom-gradient-hover" />

                {!_.isString(additionalAdvertisementText) ? null : (
                    <div className="additional-advertisement-text">
                        <BlockIcon /> <span>{additionalAdvertisementText}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

const bindActionsToDispatch = {
    updateTemplate: templateAction.updateTemplate,
};
function mapStateToProps(state) {
    const { auth } = state;
    return {
        auth,
    };
}

export default connect(mapStateToProps, bindActionsToDispatch)(ConnectCard);

export function templateTextToHtml(templateText) {
    if (!_.isString(templateText)) {
        return templateText;
    }
    return templateText
        .replace(/(\n){3,}/g, '\n') // replace multiple newlines with a single one
        .replace(/\n/g, '<br/>') // replace newline with <br/>
        .replace(/^(<br\/>)+/, '') // replace any prefix newlines with ""
        .replace(/(<br\/>)+$/, ''); // replace any prefix newlines with ""
}

/*
 *  Convert the template text into an array of react components by simp;y setting the html of most of them, but wrapping
 *  the firstname variable in a nicer presentation with a tooltip
 */
export function renderTemplateText(templateText, auth, pharmacy) {
    if (!_.isString(templateText)) {
        return templateText;
    }

    const locationName = _.isNil(auth) ? 'Location Name' : getLocationNameFromAuth(auth);
    const address = _.isNil(pharmacy) ? null : getLocationAddressFromPharmacy(pharmacy);
    const phoneNumber = _.isNil(pharmacy)
        ? 'Phone Number'
        : formatRawPhone(getLocationPhoneNumberFromPharmacy(pharmacy), _.get(address, 'country', 'US'));
    const formattedAddress = _.isNil(address)
        ? 'Location Address'
        : `${address.street1}${_.isNil(address.street2) ? '' : ' ' + address.street2}, ${address.city}, ${
              address.state
          } ${address.zip}`;

    /* eslint-disable */
    const replacements = {
        '${first_name}': (
            <Tooltip title="This will be replaced with each patient's first name in the message that is sent.">
                <span className="template-variable">First Name</span>
            </Tooltip>
        ),
        '${appointment_datetime}': (
            <Tooltip title="This will be replaced with the date and time of the appointment in the message that is sent.">
                <span className="template-variable">Appt. Date and Time</span>
            </Tooltip>
        ),
        '${appointment_time}': (
            <Tooltip title="This will be replaced with the time of the appointment in the message that is sent.">
                <span className="template-variable">Appt. Time</span>
            </Tooltip>
        ),
        '${location_name}': (
            <Tooltip title="This will be replaced with the pharmacy location name in the message that is sent.">
                <span className="template-variable">{locationName}</span>
            </Tooltip>
        ),
        '${location_phone_number}': (
            <Tooltip title="This will be replaced with the pharmacy phone number in the message that is sent.">
                <span className="template-variable">{phoneNumber}</span>
            </Tooltip>
        ),
        '${location_address}': (
            <Tooltip title="This will be replaced with the pharmacy address in the message that is sent.">
                <span className="template-variable">{formattedAddress}</span>
            </Tooltip>
        ),
        '${covid_vaccine_type}': (
            <Tooltip title="This will be replaced with the vaccine type specified in the Appointment Block in the message that is sent.">
                <span className="template-variable">Covid Vaccine Type</span>
            </Tooltip>
        ),
    };
    /* eslint-enable */

    let from = 0;
    let index = -1;
    let key = 0;
    const components = [];
    const regex = /\$\{[a-z_]+\}/gi;
    let match = null;
    while ((match = regex.exec(templateText)) !== null) {
        index = match.index;
        //    while ((index = templateText.indexOf('${first_name}', from)) > -1) {
        components.push(
            <span
                key={key++}
                dangerouslySetInnerHTML={{ __html: templateTextToHtml(templateText.substring(from, index)) }}
            />
        );

        components.push(replacements[match[0]]);

        from = index + match[0].length;
    }
    components.push(
        <span key={key++} dangerouslySetInnerHTML={{ __html: templateTextToHtml(templateText.substring(from)) }} />
    );

    return components;
}
