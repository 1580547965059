import _ from 'lodash';

export function preprocessMessageTemplateText(templateText, auth, additionalReplacements) {
    const variableReplacements = {
        pharmacy_name: getPharmacyName(auth),
        location_name: getLocationName(auth),
        ...additionalReplacements,
    };

    return _.reduce(
        _.keys(variableReplacements),
        (replaced, key) => {
            return _.replace(replaced, new RegExp(`\\$\\{${key}\\}`, 'g'), variableReplacements[key]);
        },
        templateText
    );
}

function getLocationName(auth) {
    return _.get(auth, 'locationDetail.displayName', '');
}

function getPharmacyName(auth) {
    if (_.isObject(auth)) {
        const { selectedCustomer, customersById } = auth;
        const customerId = _.get(selectedCustomer, 'id');
        return _.get(customersById, `${customerId}.entityName`, '');
    }
}
