import React from 'react';
import { CircularProgress } from 'react-md';

const CustomScreenLoader = ({ scale }) => (
    <div className="customScreenLoaderContainer">
        <div className="positionAbsoluteCenter">
            <CircularProgress id="custom-spinner" scale={scale ? scale : 1} />
        </div>
    </div>
);

export default CustomScreenLoader;
