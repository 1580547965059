import React from 'react';

export default function AppointmentTypesIcon({ size = 42, color = '#555555' }) {
    return (
        <svg width={size} height={size} viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    d={
                        'M37.975 35.455L35.5075 37.9225L29.855 32.375L28 36.6975L24.5 24.5L36.75 28L32.3225 29.8025L37.975 ' +
                        '35.455ZM33.25 14H8.75V33.25H23.4675L24.4475 36.75H8.75C7.82174 36.75 6.9315 36.3812 6.27513 ' +
                        '35.7249C5.61875 35.0685 5.25 34.1783 5.25 33.25V8.75C5.25 7.82174 5.61875 6.9315 6.27513 ' +
                        '6.27513C6.9315 5.61875 7.82174 5.25 8.75 5.25H10.5V1.75H14V5.25H28V1.75H31.5V5.25H33.25C35.175 ' +
                        '5.25 36.75 6.825 36.75 8.75V24.4125L33.25 23.3625V14ZM21 17.5V26.25H12.25V17.5H21Z'
                    }
                    fill={color}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="-4"
                    y="0"
                    width="50"
                    height="50"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
