import './WhoToContactContent.sass';
import React, { useState, useEffect } from 'react';
import { Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import numeral from 'numeral';
import pluralize from 'pluralize';
import Slider from '@material-ui/core/Slider';
import PeopleIcon from '@material-ui/icons/People';
import _ from 'lodash';
import classnames from 'classnames';
import { getHowToScheduleConfigFromInboxConversationTemplate, getWaitlistIdsFromTemplate } from '../schedulingUtil';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentWorkflowSessionContext } from 'snowplow/contexts';

export default function WhoToContactContent({
    numberOfSlots,
    availableDoses,
    waitlistCount,
    history,
    template,
    orderingOption,
    setOrderingOption,
    setChosenPatients,
    chooseWaitlistPatientsByCount,
    setPatientChooseMethod,
    onBack,
}) {
    const [schedulingAssumption, setSchedulingAssumption] = useState(0.75);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showOrderingDetails, setShowOrderingDetails] = useState(false);
    const [loadingPatients, setLoadingPatients] = useState(false);

    const { scheduling_type } = getHowToScheduleConfigFromInboxConversationTemplate(template);
    const ignoreAppointmentSlots = scheduling_type === '3rd-party';

    const { recommendation, limitingFactor } = computeRecommendation(
        numberOfSlots,
        availableDoses,
        waitlistCount,
        schedulingAssumption,
        ignoreAppointmentSlots
    );
    const [priorRecommendation, setPriorRecommendation] = useState(recommendation);
    const [numberToContact, setNumberToContact] = useState(recommendation);

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Who by count from waitlist');
    }, []);

    useEffect(() => {
        if (numberToContact === priorRecommendation && recommendation !== priorRecommendation) {
            setNumberToContact(recommendation);
        }

        if (recommendation !== priorRecommendation) {
            setPriorRecommendation(recommendation);
        }
    }, [recommendation, priorRecommendation, numberToContact]);

    return (
        <div className="who-to-contact-content">
            <div className="heading">
                <div className="title">Determine how many patients from waitlist to contact</div>

                <Button
                    className="manual-button"
                    onClick={() =>
                        history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling/who/waitlist`)
                    }
                >
                    I will specify which patients to contact manually
                </Button>
            </div>

            <div className="sub-heading">
                In order to contact the optimal number of patients from your waitlist without frustrating too many of
                them due to you not having any available {scheduling_type === '3rd-party' ? null : 'slots or'} doses, we
                will recommend how many patients to contact.
            </div>

            <div className="questions-and-buttons">
                <div className="patient-count-question">
                    <div className="question-heading">
                        <div className="question">How many patients should be contacted?</div>

                        <div className="recommendation-summary">
                            We recommend contacting&nbsp;
                            <span className="patient-count">
                                {numeral(recommendation).format('0,0')} {pluralize('patient', recommendation)}
                            </span>
                            &nbsp;if&nbsp;
                            <TextField
                                className="scheduling-assumption-text-field"
                                value={parseInt(String(Math.round(schedulingAssumption * 100)))}
                                variant="outlined"
                                type="number"
                                onChange={(e) => {
                                    setSchedulingAssumption(Math.max(0, Math.min(1.0, Number(e.target.value) / 100.0)));
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { min: 0, max: 100 },
                                }}
                            />
                            &nbsp;will schedule&nbsp;
                            <Button className="explain-button" onClick={() => setShowExplanation(true)}>
                                explain
                            </Button>
                        </div>
                    </div>

                    <div className="question-sub-heading">
                        While we recommend contacting {numeral(recommendation).format('0,0')}{' '}
                        {pluralize('patient', recommendation)} based on the information that you provided, you may
                        specify any number from your waitlist to contact.
                    </div>

                    <TextField
                        className="patient-count-text-field"
                        variant="outlined"
                        label="Number of patients to contact"
                        value={_.isFinite(numberToContact) ? numberToContact : ''}
                        type="number"
                        InputProps={{
                            inputProps: { min: 1, max: waitlistCount, step: 1 },
                        }}
                        onChange={(e) => setNumberToContact(parseInt(e.target.value))}
                    />
                </div>

                <div className="buttons">
                    <div className="ordering-explanation">
                        {orderingOption === 'prioritize-known' ? (
                            <span>
                                Patients will be contacted by priority group with known patients contacted first
                            </span>
                        ) : (
                            <span>
                                Patients will be contacted by priority group in the order they signed up for the
                                waitlist
                            </span>
                        )}

                        <Button className="change-order-button" onClick={() => setShowOrderingDetails(true)}>
                            change
                        </Button>
                    </div>

                    <span>
                        <Button className="back-button" variant="outlined" onClick={onBack}>
                            Back
                        </Button>
                        <Button
                            className="next-button"
                            variant="contained"
                            color="primary"
                            disabled={!_.isFinite(numberToContact) || loadingPatients}
                            onClick={() => {
                                setLoadingPatients(true);

                                const waitlistId = getWaitlistIdsFromTemplate(template);
                                chooseWaitlistPatientsByCount(
                                    waitlistId,
                                    numberToContact,
                                    orderingOption === 'prioritize-known'
                                )
                                    .then((response) => {
                                        setPatientChooseMethod('by-count');
                                        setChosenPatients(_.get(response, 'rows'));

                                        Snowplow.structEvent(
                                            StructEventCategories.appointments,
                                            'schedule-workflow-who-next-clicked',
                                            [
                                                new AppointmentWorkflowSessionContext()
                                                    .setTemplateId(template.inbox_conversation_template_id)
                                                    .setPatientChooseMethod('waitlist-by-count')
                                                    .setSchedulingPercentageAssumption(
                                                        parseInt(Math.round(schedulingAssumption * 100))
                                                    )
                                                    .setNumberOfPatientsRecommended(recommendation)
                                                    .setNumberOfPatientsContacted(numberToContact)
                                                    .build(),
                                            ]
                                        );

                                        history.push(
                                            `/workflows/${template.inbox_conversation_template_id}/scheduling/review`
                                        );
                                    })
                                    .finally(() => {
                                        setLoadingPatients(false);
                                    });
                            }}
                        >
                            {loadingPatients ? 'Loading...' : 'Next'}
                        </Button>
                    </span>
                </div>
            </div>

            {!showExplanation ? null : (
                <RecommendationExplanationDialog
                    setShowExplanation={setShowExplanation}
                    setSchedulingAssumption={setSchedulingAssumption}
                    recommendation={recommendation}
                    limitingFactor={limitingFactor}
                    schedulingAssumption={schedulingAssumption}
                    numberOfSlots={numberOfSlots}
                    availableDoses={availableDoses}
                    waitlistCount={waitlistCount}
                    ignoreAppointmentSlots={ignoreAppointmentSlots}
                />
            )}

            {!showOrderingDetails ? null : (
                <OrderingDetailsDialog
                    setShowOrderingDetails={setShowOrderingDetails}
                    orderingOption={orderingOption}
                    setOrderingOption={setOrderingOption}
                />
            )}
        </div>
    );
}

function OrderingDetailsDialog({ setShowOrderingDetails, orderingOption, setOrderingOption }) {
    const handleChange = (order) => {
        if (order !== orderingOption) {
            setOrderingOption(order);
        }
    };

    return (
        <Dialog className="ordering-details-dialog" open={true} onClose={() => setShowOrderingDetails(false)}>
            <DialogTitle>In what order will patients on the waitlist be contacted?</DialogTitle>
            <DialogContent>
                <div className="heading">
                    Patients will be prioritized based on the determined CDC Priority groups, but within each group you
                    can control in what order patients will be contacted.
                </div>

                <div
                    className={classnames({ 'ordering-option': true, selected: orderingOption === 'prioritize-known' })}
                    onClick={() => handleChange('prioritize-known')}
                >
                    <div className="label">Prioritize Known Patients</div>
                    <div className="explanation">
                        This option will choose patients known to Digital Pharmacist (those that were added via the Join
                        Waitlist workflow or those matched from patient records when they signed up on the public
                        waitlist) before moving on to other people that signed up via the public waitlist hosted on your
                        website.
                    </div>
                </div>

                <div
                    className={classnames({ 'ordering-option': true, selected: orderingOption === 'in-order' })}
                    onClick={() => handleChange('in-order')}
                >
                    <div className="label">In the order they signed up for the waitlist</div>
                    <div className="explanation">
                        This option will contact patients in the order that they signed up for the waitlist regardless
                        of if they are known patients of the pharmacy or not.
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function RecommendationExplanationDialog({
    setShowExplanation,
    recommendation,
    limitingFactor,
    schedulingAssumption,
    numberOfSlots,
    availableDoses,
    waitlistCount,
    setSchedulingAssumption,
    ignoreAppointmentSlots,
}) {
    return (
        <Dialog
            className="recommendation-explanation-dialog"
            open={true}
            onClose={() => setShowExplanation(false)}
            maxWidth="md"
        >
            <DialogTitle>
                Why do we recommend contacting {numeral(recommendation).format('0,0')}{' '}
                {pluralize('patient', recommendation)}?
            </DialogTitle>
            <DialogContent>
                <div className="known-facts">
                    <div className="heading">Given that we have...</div>
                    <div className="facts">
                        <div className="fact">a {numeral(waitlistCount).format('0,0')} patient waitlist</div>
                        {ignoreAppointmentSlots ? null : (
                            <div className="fact">
                                {numeral(numberOfSlots).format('0,0')} available appointment slots
                            </div>
                        )}
                        <div className="fact">{numeral(availableDoses).format('0,0')} available vaccine doses</div>
                    </div>
                </div>

                <div className="assumptions">
                    <div className="heading">and assuming...</div>
                    <div className="scheduling-assumption">
                        <div className="heading">
                            <div className="title">
                                {Math.round(schedulingAssumption * 100)}% of contacted patients will schedule an
                                appointment
                            </div>
                            <div className="change-it-text">You may control this assumption below</div>
                        </div>
                        <div className="slider-and-text-field">
                            <Slider
                                className="scheduling-assumption-slider"
                                value={schedulingAssumption}
                                min={0.01}
                                max={1.0}
                                step={0.01}
                                marks={[
                                    { value: 0.25, label: '25%' },
                                    { value: 0.5, label: '50%' },
                                    { value: 0.75, label: '75%' },
                                ]}
                                onChange={(e, value) => {
                                    setSchedulingAssumption(value);
                                }}
                            />
                            <TextField
                                className="scheduling-assumption-text-field"
                                value={parseInt(String(Math.round(schedulingAssumption * 100)))}
                                variant="outlined"
                                type="number"
                                onChange={(e) => {
                                    setSchedulingAssumption(Math.max(0, Math.min(1.0, Number(e.target.value) / 100.0)));
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { min: 0, max: 100 },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="recommendation">
                    <div className="heading">we recommend contacting...</div>

                    <div className="count-and-explanation">
                        <div className="recommendation-count">
                            <div className="count">
                                <PeopleIcon /> {numeral(recommendation).format('0,0')}{' '}
                                {pluralize('patient', recommendation)} from your waitlist
                            </div>
                        </div>
                        <div className="explanation">
                            {getLimitingFactorExplanation(limitingFactor, numberOfSlots, availableDoses, waitlistCount)}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function getLimitingFactorExplanation(limitingFactor, slots, doses, waitlistCount) {
    switch (limitingFactor) {
        case 'slots':
            return `If the percentage of patients accepting to schedule an appointment assumption is accurate, this will result in filling all ${numeral(
                slots
            ).format('0,0')} available appointment slots (which is the limiting factor).`;
        case 'doses':
            return `If the percentage of patients accepting to schedule an appointment assumption is accurate, this will result in using all ${numeral(
                doses
            ).format('0,0')} available doses (which is the limiting factor).`;
        case 'waitlistCount':
            return `You have more than enough appointment slots and available doses for the patients on the waitlist so we recommend contacting all ${numeral(
                waitlistCount
            ).format('0,0')} patients on the waitlist.`;
        default:
            return '';
    }
}

function computeRecommendation(slots, doses, waitlistCount, schedulingAssumption, ignoreAppointmentSlots) {
    const limitingFactor = ignoreAppointmentSlots ? 'doses' : doses < slots ? 'doses' : 'slots';

    const min = ignoreAppointmentSlots ? doses : Math.min(slots, doses);

    const recommendation = Math.ceil(min / Math.min(1.0, schedulingAssumption));

    if (recommendation >= waitlistCount) {
        return {
            recommendation: waitlistCount,
            limitingFactor: 'waitlistCount',
        };
    }

    return { recommendation, limitingFactor };
}
