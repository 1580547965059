import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const style = {
    innerbox: {
        width: '550px',
        height: '320px',
        backgroundColor: '#DCDCDC',
    },
};

const EmbeddedContent = ({ src, id, classes }) => {
    return (
        <div className="flex-center">
            <iframe
                id={id}
                title={id}
                src={src}
                frameBorder="0"
                allowFullScreen
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                oallowfullscreen="true"
                msallowfullscreen="true"
                className={`md-cell md-cell--12 ${classes.innerbox}`}
            />
        </div>
    );
};

export default withStyles(style)(EmbeddedContent);
