import _ from 'lodash';
import { pharmacyType } from './type';
import { pharmacyService } from './service';
import {
    isServiceEnabled,
    isPharmacyServiceEnabled,
    getLocationPackageAttribute,
    getPharmacyPackageById,
    getPackageAttributeById,
    getPackageAttribute,
} from '../../utils/helper';
import Config2Constants from '../../constants/Config2';
import config from '../../config';
import { pharmacySelectors } from './selector';

const getPharmacy = (pharmacyId = null) => {
    return function (dispatch, getState) {
        dispatch(request());

        if (!pharmacyId) {
            pharmacyId = getState().pharmacy.pharmacy.id;
        }

        return pharmacyService.getPharmacy(pharmacyId).then(
            (pharmacy) => {
                dispatch(success(pharmacy));

                return pharmacy;
            },
            (error) => {
                dispatch(failure(error.toString()));
                return Promise.reject(error);
            }
        );
    };

    function request() {
        return { type: pharmacyType.GET_PHARMACY_REQUEST };
    }
    function success(pharmacy) {
        return { type: pharmacyType.GET_PHARMACY_SUCCESS, pharmacy };
    }
    function failure(error) {
        return { type: pharmacyType.GET_PHARMACY_FAILURE, error };
    }
};

const resetPharmacyStore = () => {
    return {
        type: pharmacyType.RESET_PHARMACY_STORE,
    };
};

const updateActiveLocation = (locationId) => {
    return {
        type: pharmacyType.UPDATE_ACTIVE_LOCATION,
        activeLocationId: locationId,
    };
};

const checkTigerPistolEnabled = (pharmacyData) => {
    const dmPackage = pharmacyData.package.filter((item) => item.code === 'DGMK');
    const dmIsActive = dmPackage.length === 1 && dmPackage[0].statusId === 1;
    const tigerPistolIdExists =
        'DigitalMarketing' in pharmacyData.service &&
        'tigerPistolId' in pharmacyData.service['DigitalMarketing'] &&
        Boolean(pharmacyData.service['DigitalMarketing']['tigerPistolId']);
    return dmIsActive && tigerPistolIdExists;
};

// A helper method for the enable full pep functionality
// it ensures that the inbox package exists and is activated
const activateInboxPackage = async (pharmacyData) => {
    const inboxPackage = pharmacyData.package.filter((item) => item.code === 'INBX');
    const inboxActive = inboxPackage.length === 1 && inboxPackage[0].statusId === 1;
    const {
        status: { enabled, disabled },
    } = Config2Constants;

    if (inboxPackage.length === 0) {
        try {
            await pharmacyService.createPackage(pharmacyData.id, 'INBX', {
                statusId: enabled,
            });
        } catch (e) {
            throw e;
        }
    } else if (!inboxActive) {
        inboxPackage[0].statusId = enabled;
        try {
            await pharmacyService.updatePackage(pharmacyData.id, inboxPackage[0]);
        } catch (e) {
            throw e;
        }
    }
};

const isABTestingEnabledForPharmacy = (pharmacy) => {
    const abTestPackage = _.find(pharmacy.package, (pkg) => pkg.code === 'ABTEST');
    return (
        abTestPackage &&
        abTestPackage.statusId === Config2Constants.status.enabled &&
        _.get(pharmacy, 'service.FeatureABTesting.Product-NPE', false)
    );
};

const getABTestingFeaturesForPharmacy = (pharmacy) => {
    const abTestingFeatures = {};
    if (isABTestingEnabledForPharmacy(pharmacy)) {
        const abTestingService = _.get(pharmacy, 'service.FeatureABTesting', {});
        _.forEach(abTestingService, (value, key) => {
            if (key.startsWith('NPE')) {
                abTestingFeatures[key] = value;
            }
        });
    }
    return abTestingFeatures;
};

const updateEnabledServices = (locationId, pharmacyData) => {
    let tigerPistolEnabled = false;
    let reputationManagementEnabled = false;
    let abTestingFeatures = {};
    let newslettersV2Enabled = false;

    if (pharmacyData) {
        const birdEyeBusinessId = getLocationPackageAttribute('RPMT', locationId, 'birdEyeBusinessId', pharmacyData);
        reputationManagementEnabled = !_.isEmpty(_.get(birdEyeBusinessId, 'value'));
        tigerPistolEnabled = checkTigerPistolEnabled(pharmacyData);
        abTestingFeatures = getABTestingFeaturesForPharmacy(pharmacyData);
        const newslettersV2attribute = getPackageAttribute('NSLT', 'Version2', pharmacyData);
        if (newslettersV2attribute) newslettersV2Enabled = newslettersV2attribute['value'] === '1';
    }

    return {
        // TODO: this needs refactoring, needs to have payload so that reducer can
        // using spread operator.
        type: pharmacyType.UPDATE_ENABLED_SERVICES,
        bulkUploadEnabled: isServiceEnabled('INBX', 'Inbox', 'BulkUploadEnabled', locationId, pharmacyData),
        allowPepPatientEditing: isServiceEnabled('INBX', 'Inbox', 'allowPepPatientEditing', locationId, pharmacyData),
        patientManagementEnabled: isServiceEnabled(
            'INBX',
            'Inbox',
            'PatientManagementEnabled',
            locationId,
            pharmacyData
        ),
        basicMessagingEnabled: isServiceEnabled('INBX', 'Inbox', 'BasicMessagingEnabled', locationId, pharmacyData),
        fullPepEnabled: isServiceEnabled('INBX', 'Inbox', 'FullPepEnabled', locationId, pharmacyData),
        ivrEnabled: isServiceEnabled('ICFG', 'IVR', 'IVRenabled', locationId, pharmacyData),
        vowIVREnabled: isServiceEnabled('ICFG', 'IVR', 'IVRType', locationId, pharmacyData) === 4,
        autoAttendant: isServiceEnabled('ICFG', 'IVR', 'AutoAttendant', locationId, pharmacyData),
        ivrAddLang: isServiceEnabled('ICFG', 'IVR', 'IVRaddLang', locationId, pharmacyData),
        ivrSMSSend: isServiceEnabled('ICFG', 'IVR', 'IVRSMSSend', locationId, pharmacyData),
        callTrackingEnabled: isServiceEnabled('ICFG', 'IVR', 'CallTrackingEnabled', locationId, pharmacyData),
        allowRefillNotification: isServiceEnabled(
            'FILL',
            'Refills',
            'allowBrowserNotifications',
            locationId,
            pharmacyData
        ),
        allowLeadNotification: isServiceEnabled(
            'STNG',
            'Settings',
            'allowBrowserNotificationsForLeads',
            locationId,
            pharmacyData
        ),
        digitalMarketingReportsEnabled:
            isPharmacyServiceEnabled('DGMK', pharmacyData) &&
            !!_.get(pharmacyData, 'service.DigitalMarketing.tigerPistolId'),
        digitalPlatformReportsEnabledForMobile: isPharmacyServiceEnabled('MOBL', pharmacyData),
        digitalPlatformReportsEnabledForWeb: isPharmacyServiceEnabled('WEBS', pharmacyData),
        communicationPlatformReportsEnabled: isPharmacyServiceEnabled('ICFG', pharmacyData),
        mobileDownloadsReportsEnabled: isPharmacyServiceEnabled('MOBL', pharmacyData),
        websiteTrafficReportsEnabled: isPharmacyServiceEnabled('WEBS', pharmacyData),
        newsletterReportsEnabled: isPharmacyServiceEnabled('NSLT', pharmacyData),
        reputationManagementReportsEnabled: reputationManagementEnabled,
        directMessagingReportsEnabled: isPharmacyServiceEnabled('INBX', pharmacyData),
        classesEnabled: isPharmacyServiceEnabled('CLSS', pharmacyData),
        dmDashboardEnabled:
            isPharmacyServiceEnabled('DGMK', pharmacyData) &&
            _.get(pharmacyData, 'service.DigitalMarketing.enableDashboard', false),
        refillsEnabled: isPharmacyServiceEnabled('FILL', pharmacyData),
        tigerPistolEnabled,
        abTestingFeatures,
        newslettersV2Enabled,
    };
};

const createPackageAttribute = (packageId, attributePayload) => {
    return function (dispatch, getState) {
        const { pharmacy } = getState().pharmacy;
        const pharmacyId = pharmacy.id;

        dispatch(request());

        // Replace package attribute value - need to convert bool value to int and then string for config2 reasons
        // TODO refactor - boolean-specific logic
        attributePayload.value = attributePayload.value ? 1 : 0;

        return pharmacyService.createPackageAttribute(pharmacyId, packageId, attributePayload).then(
            () => {
                dispatch(getPharmacy());

                return dispatch(success());
            },
            (error) => {
                dispatch(getPharmacy());

                return dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: pharmacyType.CREATE_PACKAGE_ATTRIBUTE_REQUEST };
    }
    function success() {
        return { type: pharmacyType.CREATE_PACKAGE_ATTRIBUTE_SUCCESS };
    }
    function failure(error) {
        return { type: pharmacyType.CREATE_PACKAGE_ATTRIBUTE_FAILURE, error };
    }
};

const updatePackageAttribute = (packageId, attributeId, updatedValue) => {
    return function (dispatch, getState) {
        const { pharmacy } = getState().pharmacy;
        const pharmacyId = pharmacy.id;

        // Get current package attribute data
        const packageData = getPharmacyPackageById(packageId, pharmacy);
        const packageAttributeData = getPackageAttributeById(attributeId, packageData);
        if (!packageAttributeData) {
            console.error("Can't update package attribute", attributeId);
            console.error('No attribute object found in', packageData);
            return Promise.reject(
                new Error(`No attribute object found in packageData for attributeId: ${attributeId}`)
            );
        }

        dispatch(request());

        // Replace package attribute value - need to convert bool value to int and then string for config2 reasons
        // TODO refactor - boolean-specific logic
        packageAttributeData.value = updatedValue ? 1 : 0;

        return pharmacyService.updatePackageAttribute(pharmacyId, packageId, attributeId, packageAttributeData).then(
            () => {
                dispatch(getPharmacy());

                return dispatch(success());
            },
            (error) => {
                dispatch(getPharmacy());

                return dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_REQUEST };
    }
    function success() {
        return { type: pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_SUCCESS };
    }
    function failure(error) {
        return { type: pharmacyType.UPDATE_PACKAGE_ATTRIBUTE_FAILURE, error };
    }
};

const upgradeToFullPep = (pricePoint) => {
    return (dispatch, getState) => {
        if (_.get(getState(), 'pharmacy.upgradeToFullPep.loading', false)) {
            return Promise.resolve();
        }

        const pharmacyId = config.X_PharmacyID;
        const locationId = config.X_LocationID;
        const email = _.get(getState(), 'auth.userAccount.email');
        const ncpdpId = _.get(pharmacySelectors.pharmacyActiveLocationSelector(getState()), 'attr.ncpdpId.value', '');
        const pharmacyData = _.get(getState(), 'pharmacy.pharmacy');

        dispatch({
            type: pharmacyType.UPGRADE_TO_FULL_PEP_REQUEST,
            payload: { pharmacyId, locationId, pricePoint },
        });

        return pharmacyService
            .upgradeToFullPep(pharmacyId, locationId, pricePoint, email, ncpdpId)
            .then(async (response) => {
                try {
                    await activateInboxPackage(pharmacyData);
                } catch (e) {
                    dispatch({
                        type: pharmacyType.UPGRADE_TO_FULL_PEP_FAILURE,
                        payload: { pharmacyId, locationId, pricePoint, e },
                    });
                    return Promise.reject(e);
                }

                dispatch({
                    type: pharmacyType.UPGRADE_TO_FULL_PEP_SUCCESS,
                    payload: { pharmacyId, locationId, pricePoint, response },
                });

                dispatch(getPharmacy(config.X_PharmacyID));

                return Promise.resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: pharmacyType.UPGRADE_TO_FULL_PEP_FAILURE,
                    payload: { pharmacyId, locationId, pricePoint, error },
                });
                return Promise.reject(error);
            });
    };
};

const clearUpgradeToFullPepError = () => {
    return {
        type: pharmacyType.CLEAR_UPGRADE_TO_FULL_PEP_ERROR,
        payload: {},
    };
};

export const pharmacyAction = {
    getPharmacy,
    resetPharmacyStore,
    updateActiveLocation,
    updateEnabledServices,
    createPackageAttribute,
    updatePackageAttribute,
    upgradeToFullPep,
    clearUpgradeToFullPepError,
};

export default pharmacyAction;
