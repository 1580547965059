import React, { Fragment } from 'react';
import {
    Button,
    TextField,
    FormHelperText,
    Typography,
    IconButton,
    Switch,
    FormControlLabel,
    ListItemText,
    ListItem,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { isEmptyString } from '../../../utils/helper';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';
import MessageCTACard from 'components/Inbox/Message/MessageCTACard';

class TemplateItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            anchorEl: null,
        };
    }

    setInitialState = () => {
        const { template } = this.props;
        const initialTemplate = {
            templateSubject: template.subject,
            templateMessage: template.message,
            anchorEl: null,
            errors: {},
        };

        this.setState({
            ...initialTemplate,
        });
    };

    componentDidMount = () => {
        this.setInitialState();
    };

    componentDidUpdate = (prevProps) => {
        const { template } = this.props;
        if (
            template &&
            prevProps.template &&
            template.inbox_conversation_template_id !== prevProps.template.inbox_conversation_template_id
        ) {
            this.setInitialState();
        }
    };

    toggleTemplateEditing = (templateId) => {
        const { setEditTemplate } = this.props;
        this.setState({
            anchorEl: null,
        });
        setEditTemplate(templateId);
    };

    cancelTemplateEditing = () => {
        const { cancelEditTemplate } = this.props;
        this.setInitialState();
        cancelEditTemplate();
    };

    getValidationErrors = () => {
        const { templateSubject, templateMessage } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.templateSubject = isEmptyString(templateSubject) ? emptyMessage : false;
        errors.templateMessage = isEmptyString(templateMessage) ? emptyMessage : false;

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);
        return errors;
    };

    handleEditInputChange = (event) => {
        const { name, value } = event.target;

        // this.getValidationErrors();
        this.setState({
            [name]: value,
        });
    };

    buildPayload = (templateId) => {
        const { templateSubject, templateMessage } = this.state;
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            inbox_conversation_template_id: templateId,
            subject: templateSubject,
            message: templateMessage,
        };
        return payload;
    };

    handleSubmit = (templateId) => {
        const { updateTemplate, cancelEditTemplate } = this.props;

        // Validate inputs
        const errors = this.getValidationErrors();
        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            // Validation passed
            updateTemplate(this.buildPayload(templateId)).then(() => {
                this.setState({
                    errors: {},
                });
                cancelEditTemplate();
            });
        }
    };

    buildDeletePayload = (templateId) => {
        const payload = {
            inbox_conversation_template_id: templateId,
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
        };
        return payload;
    };

    deleteTemplate = (templateId) => {
        const { deleteTemplate } = this.props;
        deleteTemplate(this.buildDeletePayload(templateId));
        this.setState({
            anchorEl: null,
        });
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    // these are good candidates for separate components
    renderEditTemplate = () => {
        const { sending, templateId, requestError } = this.props;

        const { templateMessage, templateSubject, errors } = this.state;

        return (
            <Fragment>
                <div className="template-item-container">
                    <div
                        style={{
                            marginTop: '32px',
                        }}
                    >
                        <TextField
                            id="subject"
                            className="subject"
                            type="text"
                            label="Subject"
                            name="templateSubject"
                            variant="outlined"
                            value={templateSubject}
                            error={!!errors.templateSubject}
                            helperText={errors.templateSubject}
                            onChange={this.handleEditInputChange}
                        />
                        <TextField
                            id="message"
                            type="text"
                            label="Message"
                            name="templateMessage"
                            multiline
                            rows="6"
                            variant="outlined"
                            value={templateMessage}
                            error={!!errors.templateMessage}
                            helperText={errors.templateMessage}
                            onChange={this.handleEditInputChange}
                            style={{
                                width: '100%',
                                marginTop: '32px',
                            }}
                        />
                    </div>
                    <div> {requestError && <FormHelperText error>{requestError}</FormHelperText>}</div>
                    <div className="button-container">
                        <Button
                            variant="outlined"
                            onClick={() => this.cancelTemplateEditing()}
                            className="cancel-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.handleSubmit(templateId)}
                            disabled={sending}
                            className="save-template-button"
                        >
                            {sending ? <CircularProgress size={20} /> : ' Save template'}
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    };

    getReadOnlyTooltipText = () => {
        const { hasWritePermissions } = this.props;
        const readOnlyTooltip = 'This template is read only';
        const noPermsTooltip = 'You do not have permissions to update this template';
        return !hasWritePermissions ? noPermsTooltip : readOnlyTooltip;
    };

    renderStatusToggle = () => {
        const { sending, template, templateId, toggleActiveState, hasWritePermissions } = this.props;
        const isActive = template.status === 'active';
        const isReadOnly = template.read_only || !hasWritePermissions;

        const classOverride =
            isActive && isReadOnly
                ? {
                      root: 'disabled-checked-switch__override',
                      thumb: 'disabled-checked-switch__override-thumb',
                      track: 'disabled-checked-switch__override-track',
                  }
                : undefined;

        const toggle = (
            <FormControlLabel
                control={
                    <Switch
                        checked={isActive}
                        classes={classOverride}
                        disabled={isReadOnly}
                        onChange={() => !sending && toggleActiveState(templateId)}
                        color="primary"
                    />
                }
            />
        );

        if (isReadOnly) {
            const toolTipText = this.getReadOnlyTooltipText();
            return (
                <Tooltip title={toolTipText} aria-label={toolTipText} arrow>
                    {toggle}
                </Tooltip>
            );
        }

        return toggle;
    };

    renderEditButton = () => {
        const { template, hasWritePermissions } = this.props;
        const isReadOnly = template.read_only || !hasWritePermissions;

        const button = (
            <IconButton disabled={isReadOnly} onClick={this.handleClick}>
                <MoreHorizIcon />
            </IconButton>
        );

        if (isReadOnly) {
            const toolTipText = this.getReadOnlyTooltipText();
            return (
                <Tooltip title={toolTipText} aria-label={toolTipText} arrow>
                    <div>{button}</div>
                </Tooltip>
            );
        }

        return button;
    };

    // these are good candidates for separate components
    renderTemplatePreview = () => {
        const { sending, loading, template, templateId } = this.props;

        const { anchorEl } = this.state;

        const isActive = template.status === 'active';

        return (
            <Fragment>
                <ListItem
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                    }}
                >
                    {this.renderStatusToggle()}
                    <div style={{ flex: 1 }}>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: !isActive ? 'rgba(0,0,0,.5)' : 'rgba(0, 0, 0, 0.87)',
                                    }}
                                >
                                    {template.subject}
                                </Typography>
                            }
                            secondary={
                                <Fragment>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: !isActive ? 'rgba(0,0,0,.5)' : 'rgba(0, 0, 0, 0.87)',
                                            wordBreak: 'break-word',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {template.message}
                                    </Typography>
                                </Fragment>
                            }
                        />
                        {template.message_content_type === 'call_to_action' && (
                            <div style={{ marginTop: '16px' }}>
                                <MessageCTACard messageContent={template.message_content} />
                            </div>
                        )}
                    </div>
                    {this.renderEditButton()}
                    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                        <MenuItem
                            className="template-menu-item"
                            disabled={sending || loading}
                            onClick={() => this.toggleTemplateEditing(templateId)}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            style={{ color: '#FF0000' }}
                            disabled={sending || loading}
                            onClick={() => this.deleteTemplate(templateId)}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </ListItem>
            </Fragment>
        );
    };

    render() {
        const { templateId, editTemplateId } = this.props;

        return (
            <Fragment>
                {editTemplateId === templateId ? this.renderEditTemplate() : this.renderTemplatePreview()}
            </Fragment>
        );
    }
}
export default TemplateItem;
