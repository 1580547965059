import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

function NoAccess() {
    return (
        <Alert severity="warning">
            <AlertTitle>Access Restricted</AlertTitle>
            You do not have permission to view this section
        </Alert>
    );
}

export default NoAccess;
