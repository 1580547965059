export default {
    FETCH_GREETING_FILES_STARTED: 'FETCH_GREETING_FILES_STARTED',
    FETCH_GREETING_FILES_SUCCESS: 'FETCH_GREETING_FILES_SUCCESS',
    FETCH_GREETING_FILES_FAILED: 'FETCH_GREETING_FILES_FAILED',
    FETCH_MAIN_GREETING_STARTED: 'FETCH_MAIN_GREETING_STARTED',
    FETCH_MAIN_GREETING_SUCCESS: 'FETCH_MAIN_GREETING_SUCCESS',
    FETCH_MAIN_GREETING_FAILED: 'FETCH_MAIN_GREETING_FAILED',
    FETCH_SPECIAL_GREETING_STARTED: 'FETCH_SPECIAL_GREETING_STARTED',
    FETCH_SPECIAL_GREETING_SUCCESS: 'FETCH_SPECIAL_GREETING_SUCCESS',
    FETCH_SPECIAL_GREETING_FAILED: 'FETCH_SPECIAL_GREETING_FAILED',
    UPDATE_GREETING_MESSAGE: 'UPDATE_GREETING_MESSAGE',
    SAVE_MAIN_GREETING_STARTED: 'SAVE_MAIN_GREETING_STARTED',
    SAVE_MAIN_GREETING_SUCCESS: 'SAVE_MAIN_GREETING_SUCCESS',
    SAVE_MAIN_GREETING_FAILED: 'SAVE_MAIN_GREETING_FAILED',
    SAVE_SPECIAL_GREETING_STARTED: 'SAVE_SPECIAL_GREETING_STARTED',
    SAVE_SPECIAL_GREETING_SUCCESS: 'SAVE_SPECIAL_GREETING_SUCCESS',
    SAVE_SPECIAL_GREETING_FAILED: 'SAVE_SPECIAL_GREETING_FAILED',
    ADD_GREETING: 'ADD_GREETING',
    ADD_EXCEPTION_GREETING: 'ADD_EXCEPTION_GREETING',
    CLOSE_GREETING_EDITOR: 'CLOSE_GREETING_EDITOR',
    UPDATE_SPECIAL_GREETING_DATERANGE: 'UPDATE_SPECIAL_GREETING_DATERANGE',
    UPDATE_SPECIAL_GREETING_MESSAGE: 'UPDATE_SPECIAL_GREETING_MESSAGE',
    SAVE_GREETING_FILE_STARTED: 'SAVE_GREETING_FILE_STARTED',
    SAVE_GREETING_FILE_SUCCESS: 'SAVE_GREETING_FILE_SUCCESS',
    SAVE_GREETING_FILE_FAILED: 'SAVE_GREETING_FILE_FAILED',
    REMOVE_GREETING_STARTED: 'REMOVE_GREETING_STARTED',
    REMOVE_GREETING_SUCCESS: 'REMOVE_GREETING_SUCCESS',
    REMOVE_GREETING_FAILED: 'REMOVE_GREETING_FAILED',
    REMOVE_SPECIAL_GREETING_STARTED: 'REMOVE_SPECIAL_GREETING_STARTED',
    REMOVE_SPECIAL_GREETING_SUCCESS: 'REMOVE_SPECIAL_GREETING_SUCCESS',
    REMOVE_SPECIAL_GREETING_FAILED: 'REMOVE_SPECIAL_GREETING_FAILED',
    EDIT_GREETING: 'EDIT_GREETING',
    EDIT_EXCEPTION_GREETING: 'EDIT_EXCEPTION_GREETING',
    UPDATE_GREETING_FILE_STARTED: 'UPDATE_GREETING_FILE_STARTED',
    UPDATE_GREETING_FILE_SUCCESS: 'UPDATE_GREETING_FILE_SUCCESS',
    UPDATE_GREETING_FILE_FAILED: 'UPDATE_GREETING_FILE_FAILED',
    MARK_DEFAULT_GREETING_STARTED: 'MARK_DEFAULT_GREETING_STARTED',
    MARK_DEFAULT_GREETING_SUCCESS: 'MARK_DEFAULT_GREETING_SUCCESS',
    MARK_DEFAULT_GREETING_FAILED: 'MARK_DEFAULT_GREETING_FAILED',
    RESET_IVR_STARTED: 'RESET_IVR_STARTED',
    RESET_IVR_SUCCESS: 'RESET_IVR_SUCCESS',
    RESET_IVR_FAILED: 'RESET_IVR_FAILED',
    CHANGE_GREETING_SCRIPT: 'CHANGE_GREETING_SCRIPT',
    CHANGE_GREETING_TYPE: 'CHANGE_GREETING_TYPE',
    CHANGE_TTS_SETTING: 'CHANGE_TTS_SETTING',
};
