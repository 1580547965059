import './FilterControls.sass';
import React, { useState, useRef, useEffect } from 'react';
import FilteringDropdown from './FilteringDropdown';
import AgeFilterPopover from './AgeFilterPopover';
import { updateQueryParameters } from '../../../utils/urlHelper';
import _ from 'lodash';
import CanMessagePopover from './CanMessagePopover';
import ConversationStatusPopover from './ConversationStatusPopover';
import AssessmentsFilterDropdownContent, {
    getActiveLabel,
    onAssessmentsClear,
    getNumberOfAssessmentFiltersToRender,
} from '../assessments/AssessmentsFilterDropdownContent';
import AssessedAttributesDropdownContent, {
    onAttributesClear,
    getAttributesActiveLabel,
    getNumberOfAttributeFiltersToRender,
} from '../assessments/AssessedAttributesDropdownContent';
import PatientTypePopover from './PatientTypePopover';
import WaitlistStatusPopover from './WaitlistStatusPopover';
import WaitlistNamePopover from './WaitlistNamePopover';
import AppointmentsPopover from './AppointmentsPopover';
import { formatAppointmentsDateRange } from './AppointmentsPopover';

export default function FilterControls({
    queryParams,
    history,
    location,
    onHeightChange,
    showAssessmentFilters,
    showPatientTypeFilter = false,
    showWaitlistStatusFilter = false,
    showConversationStatusFilter = true,
    showMessageableStatusFilter = true,
    showWaitlistNameFilter = false,
    waitlistFilterOptions,
}) {
    const props = arguments[0];
    const mainRef = useRef();

    const [assessmentAddedIndex, setAssessmentAddedIndex] = useState(null);
    const [attributeAddedIndex, setAttributeAddedIndex] = useState(null);
    const [height, setHeight] = useState(null);

    const numAssessmentFilters = _.isFinite(assessmentAddedIndex)
        ? assessmentAddedIndex
        : getNumberOfAssessmentFiltersToRender(queryParams);
    const numAttributeFilters = _.isFinite(attributeAddedIndex)
        ? attributeAddedIndex
        : getNumberOfAttributeFiltersToRender(queryParams);

    useEffect(() => {
        const { clientHeight } = mainRef.current;

        if (clientHeight !== height) {
            setHeight(height);
            onHeightChange(clientHeight);
        }
    }, [assessmentAddedIndex, JSON.stringify(queryParams)]);

    return (
        <div className="filter-controls" ref={mainRef}>
            <div className="filters-area">
                <FilteringDropdown
                    {...props}
                    label="Age"
                    param="age"
                    activeLabel={
                        !_.isString(queryParams.age)
                            ? null
                            : `${parseInt(queryParams.age)} years and ${
                                  _.endsWith(queryParams.age, '+') ? 'older' : 'younger'
                              }`
                    }
                >
                    <AgeFilterPopover
                        age={_.isString(queryParams.age) ? parseInt(queryParams.age) : null}
                        isOlder={_.isNil(queryParams.age) || _.endsWith(queryParams.age, '+')}
                        onChange={({ age, isOlder }) => {
                            const value = !_.isFinite(age) ? null : `${age}${isOlder ? '+' : '-'}`;
                            history.push(`${location.pathname}${updateQueryParameters(location, 'age', value)}`);
                        }}
                    />
                </FilteringDropdown>

                {!showMessageableStatusFilter ? null : (
                    <FilteringDropdown
                        {...props}
                        label={'Messageable status'}
                        param={'canMessage'}
                        activeLabel={
                            !_.isString(queryParams.canMessage)
                                ? null
                                : CanMessagePopover.OPTION_LABELS[queryParams.canMessage]
                        }
                    >
                        <CanMessagePopover
                            {...props}
                            value={queryParams.canMessage}
                            onChange={(value) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(location, 'canMessage', value)}`
                                );
                            }}
                        />
                    </FilteringDropdown>
                )}

                {!showConversationStatusFilter ? null : (
                    <FilteringDropdown
                        {...props}
                        label={'Conversation status'}
                        param={'convStatus'}
                        activeLabel={
                            !_.isString(queryParams.convStatus)
                                ? null
                                : ConversationStatusPopover.OPTION_LABELS[queryParams.convStatus]
                        }
                    >
                        <ConversationStatusPopover
                            {...props}
                            value={queryParams.convStatus}
                            onChange={(value) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(location, 'convStatus', value)}`
                                );
                            }}
                        />
                    </FilteringDropdown>
                )}

                {!showAssessmentFilters
                    ? null
                    : _.map(_.times(numAssessmentFilters), (i) => {
                          const isAddedNewFilter = _.isFinite(assessmentAddedIndex) && i === numAssessmentFilters - 1;
                          return (
                              <FilteringDropdown
                                  key={`assessment-${i}`}
                                  {...props}
                                  label={'Assessments'}
                                  param={'assessments'}
                                  activeLabel={getActiveLabel(
                                      queryParams,
                                      i,
                                      _.get(props, 'assessment.sentAssessments'),
                                      props.activeLocationCountryCode
                                  )}
                                  onClear={() => {
                                      onAssessmentsClear(queryParams, i, history, location);
                                  }}
                                  onAdd={
                                      i !== numAssessmentFilters - 1
                                          ? null
                                          : () => {
                                                setAssessmentAddedIndex(numAssessmentFilters + 1);
                                            }
                                  }
                                  initiallyOpen={isAddedNewFilter}
                                  onClose={() => {
                                      setAssessmentAddedIndex(null);
                                  }}
                              >
                                  <AssessmentsFilterDropdownContent {...props} assessmentIndex={i} />
                              </FilteringDropdown>
                          );
                      })}

                {!showAssessmentFilters
                    ? null
                    : _.map(_.times(numAttributeFilters), (i) => {
                          const isAddedNewFilter = _.isFinite(attributeAddedIndex) && i === numAttributeFilters - 1;
                          return (
                              <FilteringDropdown
                                  key={`attribute-${i}`}
                                  {...props}
                                  label={'Attributes'}
                                  param={'attributes'}
                                  activeLabel={getAttributesActiveLabel(
                                      queryParams,
                                      i,
                                      _.get(props, 'assessment.availableAssessedAttributes.attributes')
                                  )}
                                  onClear={() => {
                                      onAttributesClear(queryParams, i, history, location);
                                  }}
                                  onAdd={
                                      i !== numAttributeFilters - 1
                                          ? null
                                          : () => {
                                                setAttributeAddedIndex(numAttributeFilters + 1);
                                            }
                                  }
                                  initiallyOpen={isAddedNewFilter}
                                  onClose={() => {
                                      setAttributeAddedIndex(null);
                                  }}
                              >
                                  <AssessedAttributesDropdownContent
                                      {...props}
                                      value={queryParams.attributes}
                                      onChange={(value) => {
                                          history.push(
                                              `${location.pathname}${updateQueryParameters(
                                                  location,
                                                  'convStatus',
                                                  value
                                              )}`
                                          );
                                      }}
                                      attributeIndex={i}
                                  />
                              </FilteringDropdown>
                          );
                      })}

                {!showPatientTypeFilter ? null : (
                    <FilteringDropdown
                        {...props}
                        label={'Patient Type'}
                        param={'patientType'}
                        activeLabel={
                            !_.isString(queryParams.patientType)
                                ? null
                                : PatientTypePopover.OPTION_LABELS[queryParams.patientType]
                        }
                    >
                        <PatientTypePopover
                            {...props}
                            value={queryParams.patientType}
                            onChange={(value) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(location, 'patientType', value)}`
                                );
                            }}
                        />
                    </FilteringDropdown>
                )}

                {!showWaitlistNameFilter ? null : (
                    <FilteringDropdown
                        {...props}
                        label={'Waitlist'}
                        param={'waitlist'}
                        activeLabel={
                            !_.isString(queryParams.waitlist)
                                ? null
                                : `Waitlist: ${_.get(
                                      _.find(
                                          waitlistFilterOptions,
                                          ({ waitlist_id }) => queryParams.waitlist === waitlist_id
                                      ),
                                      'waitlist_name'
                                  )}`
                        }
                    >
                        <WaitlistNamePopover
                            {...props}
                            value={queryParams.waitlist}
                            onChange={(value) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(location, 'waitlist', value)}`
                                );
                            }}
                        />
                    </FilteringDropdown>
                )}

                {!showWaitlistStatusFilter ? null : (
                    <FilteringDropdown
                        {...props}
                        label={'Waitlist Status'}
                        param={'waitlistStatus'}
                        activeLabel={
                            !_.isString(queryParams.waitlistStatus)
                                ? null
                                : WaitlistStatusPopover.OPTION_LABELS[queryParams.waitlistStatus]
                        }
                    >
                        <WaitlistStatusPopover
                            {...props}
                            value={queryParams.waitlistStatus}
                            onChange={(value) => {
                                history.push(
                                    `${location.pathname}${updateQueryParameters(location, 'waitlistStatus', value)}`
                                );
                            }}
                        />
                    </FilteringDropdown>
                )}

                <FilteringDropdown
                    {...props}
                    label="Appointments"
                    param="appts"
                    activeLabel={
                        !_.isString(queryParams.appts) ? null : (
                            <span style={{ fontWeight: '400' }}>
                                Appointments:{' '}
                                <strong>
                                    {queryParams.appts.startsWith('custom:')
                                        ? formatAppointmentsDateRange(queryParams.appts)
                                        : queryParams.appts.startsWith('none')
                                        ? 'None'
                                        : AppointmentsPopover.OPTION_LABELS[queryParams.appts]}
                                </strong>
                            </span>
                        )
                    }
                >
                    <AppointmentsPopover
                        value={queryParams.appts}
                        onChange={(value) => {
                            history.push(`${location.pathname}${updateQueryParameters(location, 'appts', value)}`);
                        }}
                    />
                </FilteringDropdown>
            </div>
        </div>
    );
}
