import reviewType from './type';

export const initialState = {
    loadingAverage: false,
    loadingList: false,
    loadingSummary: false,
    average: {},
    months: {},
    list: [],
    total: 0,
    page: 0,
};

export const reviewReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case reviewType.FETCH_SUMMARY_REQUEST:
            return {
                ...state,
                loadingSummary: true,
            };
        case reviewType.FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingSummary: false,
                months: payload.months || initialState.months,
                list: payload.rows.data || initialState.list,
                total: payload.total || initialState.total,
                page: initialState.page,
            };
        case reviewType.FETCH_SUMMARY_FAILURE:
            return {
                ...state,
                loadingSummary: false,
                months: initialState.months,
                list: initialState.list,
                total: initialState.total,
            };
        case reviewType.FETCH_AVERAGE_REQUEST:
            return {
                ...state,
                loadingAverage: true,
            };
        case reviewType.FETCH_AVERAGE_SUCCESS:
            return {
                ...state,
                loadingAverage: false,
                average: payload,
            };
        case reviewType.FETCH_AVERAGE_FAILURE:
            return {
                ...state,
                loadingAverage: false,
                average: initialState.average,
            };
        case reviewType.FETCH_LIST_REQUEST:
            return {
                ...state,
                loadingList: true,
            };
        case reviewType.FETCH_LIST_SUCCESS:
            return {
                ...state,
                loadingList: false,
                list: payload.data.rows.data,
                total: payload.data.total,
                page: payload.page,
            };
        case reviewType.FETCH_LIST_FAILURE:
            return {
                ...state,
                loadingList: false,
                list: initialState.list,
                page: initialState.page,
            };
        default:
            return state;
    }
};

export default reviewReducer;
