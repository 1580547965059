import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { DRAWER_STATES, NEWSLETTER_MODE } from 'constants/Newsletter';
import { newsletterActions } from 'redux/Newsletter/action';
import SelectCardDialog from 'components/Resources/Newsletter/Calendar/SelectCardDialog/SelectCardDialog';
import ViewCardDialog from 'components/Resources/Newsletter/Calendar/ViewCardDialog/ViewCardDialog';
import Snowplow, { StructEventCategories } from 'snowplow';

const EditNewsletter = (props) => {
    const { activeEvent, setActiveCard, mode, updatingEventDetails, refetchingNewsletter } = props;
    const { date, cards } = activeEvent;
    const [selectingCard, setSelectingCard] = useState(false);
    const [cardToView, setCardToView] = useState(null);
    const [cardToDelete, setCardToDelete] = useState('');
    const MAX_CARDS = 3;
    const allowedCardsCountToSelect = MAX_CARDS - cards.length;
    const isLoading = updatingEventDetails || refetchingNewsletter;

    const handleAddCardClick = () => {
        const { setDrawerState } = props;
        setDrawerState(DRAWER_STATES.ADD_CARD);
        setActiveCard();
        Snowplow.structEvent(StructEventCategories.newsletters, 'clicked-create-new-card');
    };

    const handleViewCardClick = (card) => {
        setCardToView(card);
        Snowplow.structEvent(StructEventCategories.newsletters, 'clicked-view-card');
    }

    const handleSelectCardClick = () => {
        setSelectingCard(true);
        Snowplow.structEvent(StructEventCategories.newsletters, 'clicked-add-existing-card');
    };

    const closeSelectCardDialog = () => {
        const { resetCards } = props;
        setSelectingCard(false);
        resetCards();
    };

    const onCardsSelected = (cardsData) => {
        const { saveEventDetails, activeEvent } = props;

        saveEventDetails({
            cards: _.concat(
                cards.map((card) => card.id),
                cardsData.map((card) => card.card_id)
            ),
            event_id: activeEvent.event_id,
            scheduled_date: moment(activeEvent.date).format(),
        })
            .then(() => Snowplow.structEvent(StructEventCategories.newsletters, 'saved-selected-card'))
            .catch((error) => {
                Snowplow.structEvent(
                    StructEventCategories.newsletters,
                    'error-saving-selected-card',
                    null,
                    'error',
                    'exception',
                    error
                );
            });
        closeSelectCardDialog();
    };

    const deleteCard = (id, event) => {
        event.stopPropagation();
        
        const { saveEventDetails, activeEvent } = props;
        const filteredCards = cards.map((card) => card.id).filter((fCardId) => fCardId !== id);
        setCardToDelete(id);
        saveEventDetails({
            cards: filteredCards,
            event_id: activeEvent.event_id,
            scheduled_date: moment(activeEvent.date).format(),
        })
            .then(() => Snowplow.structEvent(StructEventCategories.newsletters, 'deleted-selected-card'))
            .catch((error) => {
                Snowplow.structEvent(
                    StructEventCategories.newsletters,
                    'error-deleting-selected-card',
                    null,
                    'error',
                    'exception',
                    error
                );
            });
    };

    const getTitle = () => (mode === NEWSLETTER_MODE.EDIT ? 'Edit eNewsletter' : 'eNewsletter');

    const getScheduledDate = () => (date ? moment(date).format('MMM DD, YYYY') : '');

    return (
        <div className="edit-newsletter">
            <Typography className="title"> {getTitle()} </Typography>
            <Grid justify="center" container>
                {isLoading ? (
                    <div className="flex-center full-width" style={{ marginTop: '50px', marginBottom: '50px' }}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    <Fragment>
                        <Grid
                            item
                            xs={12}
                            className="flex-space-between standard-margin-bottom-10"
                            style={{ marginTop: '18px' }}
                        >
                            <Typography> Date Scheduled </Typography>
                            <Typography> {getScheduledDate()} </Typography>
                        </Grid>
                        <Grid item xs={12} className="flex-space-between standard-margin-bottom-10">
                            <Typography> Cards </Typography>
                            <Typography> {`${cards.length} / 3`} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {cards.map((card) => (
                                <Card
                                    className="flex-space-between flex-middle standard-margin-bottom-10"
                                    style={{ cursor: 'pointer' }}
                                    key={card.id}
                                    onClick={() => handleViewCardClick(card)}
                                >
                                    <span className="card-title"> {card.title} </span>
                                    {mode === NEWSLETTER_MODE.EDIT && (
                                        <Fragment>
                                            {updatingEventDetails && cardToDelete === card.id ? (
                                                <CircularProgress size={20} style={{ marginRight: '10px' }} />
                                            ) : (
                                                <IconButton
                                                    disabled={updatingEventDetails}
                                                    onClick={(e) => deleteCard(card.id, e)}
                                                    className="delete-icon"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Fragment>
                                    )}
                                </Card>
                            ))}
                        </Grid>
                    </Fragment>
                )}
                {mode === NEWSLETTER_MODE.EDIT && (
                    <div className="btn-container">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSelectCardClick}
                            className="standard-margin-bottom-10"
                            disabled={allowedCardsCountToSelect === 0}
                        >
                            + Add existing card
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleAddCardClick}>
                            + Create new card
                        </Button>
                    </div>
                )}
            </Grid>
            {selectingCard && (
                <SelectCardDialog
                    visible={selectingCard}
                    onClose={closeSelectCardDialog}
                    allowedCardsCountToSelect={allowedCardsCountToSelect}
                    onSelected={onCardsSelected}
                    cardsToExcludeFromList={cards.map((card) => card.id)}
                />
            )}
            {Boolean(cardToView) && (
                <ViewCardDialog
                    visible={Boolean(cardToView)}
                    card={cardToView}
                    onClose={() => setCardToView(false)}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeEvent: state.newsletter.activeEvent,
    updatingEventDetails: state.newsletter.updatingEventDetails,
    refetchingNewsletter: state.newsletter.refetchingNewsletter,
});

const mapDispatchToProps = {
    setDrawerState: newsletterActions.setDrawerState,
    setActiveSubscriber: newsletterActions.setActiveSubscriber,
    setActiveCard: newsletterActions.setActiveCard,
    resetActiveCard: newsletterActions.resetActiveCard,
    saveEventDetails: newsletterActions.saveEventDetails,
    resetCards: newsletterActions.resetCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNewsletter);
