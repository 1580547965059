import React from 'react';
import { Grid, Cell, Card } from 'react-md';
import Button from '@material-ui/core/Button';
import Assignment from '@material-ui/icons/Assignment';
import LearnMoreButton from 'components/Common/LearnMoreButton';

const appType = 'refills';
const bestPracticesURL = 'https://help.digitalpharmacist.com/en/collections/1761011-digital-pharmacist-best-practices';

const EmptyRefills = ({ refillsEnabled }) => {
    return (
        <Grid className="empty-state">
            <Cell size={12}>
                <Card className="outer-card refills-list-empty">
                    <Assignment className="refills-list-empty-icon" />
                    <div>
                        <br />
                        You don&apos;t have any refills yet. Check out our Best Practices
                        <br />
                        guide to get more patients using your digital services
                    </div>
                    <br />
                    {!refillsEnabled ? (
                        <LearnMoreButton appType={appType} variant="tabs" />
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: 'none' }}
                            className="best-practices-btn"
                            href={bestPracticesURL}
                            target="_blank"
                        >
                            See Best Practices
                        </Button>
                    )}
                </Card>
            </Cell>
        </Grid>
    );
};

export default EmptyRefills;
