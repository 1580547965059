import './AppointmentRow.sass';
import PatientAvatar from 'components/Patients/PatientAvatar';
import { getPatientAvatarSeedFromContact } from 'components/Patients/PatientAvatar';
import React, { useState } from 'react';
import { getPatientsAge } from 'components/Patients/patientsUtil';
import moment from 'moment';
import { formatRawPhone } from 'utils/helper';
import {
    dateEquals,
    getRelativeDateShortLabel,
    RESERVATION_TIME_FORMAT,
} from 'components/Patients/scheduling/schedulingUtil';
import EventIcon from '@material-ui/icons/Event';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import RoomIcon from '@material-ui/icons/Room';
import PatientName from 'components/Patients/PatientName';
import NeedleIcon from 'components/Patients/scheduling/session/NeedleIcon';
import { VACCINE_COLORS, VACCINES_AND_LABELS } from 'components/Patients/scheduling/schedule/VaccineDoseInfoContent';

export default function AppointmentRow({
    patient,
    format,
    startTime,
    endTime,
    appointmentLabel,
    vaccineType,
    displayedDateFormat,
    hideAppointmentType,
    calendar,
    onEdit,
    timeStatus,
    reservation,
    uncheckinReservation,
    showPatientInfo = true,
}) {
    const props = arguments[0];
    const { first_name, last_name } = _.get(props, 'patient', {});
    const name = format === 'last_first' ? `${last_name}, ${first_name}` : `${first_name} ${last_name}`;

    const [isCheckingIn, setIsCheckingIn] = useState(false);
    const [isUncheckingIn, setIsUncheckingIn] = useState(false);

    const calendarTimezone = _.get(calendar, 'timezone');

    const tzMismatch = calendarTimezone !== moment.tz.guess();

    if (_.isNil(patient)) {
        return null;
    }

    const isCheckedIn = !_.isNil(reservation.check_in_date);

    // may check in if less than or equal to 1 hour before the appointment
    const mayCheckIn = moment.utc(reservation.start_date, RESERVATION_TIME_FORMAT).diff(moment.utc(), 'minutes') <= 60;

    return (
        <div
            data-cy="appt-row"
            className={classnames({
                'appointment-row': true,
                [timeStatus]: true,
                'with-patient-info': showPatientInfo,
            })}
        >
            {!showPatientInfo ? (
                <div className="date-and-time">
                    <div className="date-line">{formatTimeRangeDate({ start_date: startTime, end_date: endTime })}</div>
                    <div className="time-line">
                        {formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat)}
                    </div>
                </div>
            ) : (
                <div className="avatar-and-patient-info">
                    <PatientAvatar
                        size={55}
                        growOnHover={false}
                        seed={getPatientAvatarSeedFromContact(_.isNil(patient) ? {} : patient)}
                    />

                    <div className="patient-and-time-info">
                        <div className="top-line">
                            <span className="patient-name">{name}</span>
                            <Tooltip
                                title={
                                    !tzMismatch
                                        ? ''
                                        : `This appointment was made for ${formatAppointmentTimeSpan(
                                              startTime,
                                              endTime,
                                              displayedDateFormat,
                                              calendarTimezone
                                          )} in the timezone of this calendar (${calendarTimezone})`
                                }
                            >
                                <span className="appointment-time">
                                    {formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat)}
                                </span>
                            </Tooltip>
                        </div>
                        <div className="bottom-line">
                            <span className="date-of-birth">
                                {moment(patient.date_of_birth, 'YYYY-MM-DD').format(displayedDateFormat)}
                            </span>
                            <span className="age">{getPatientsAge(patient.date_of_birth, true)}</span>
                            <span className="phone">{formatRawPhone(patient.phone)}</span>
                        </div>
                    </div>
                </div>
            )}

            {hideAppointmentType ? null : (
                <div className="appointment-calendar-and-vaccine-type">
                    <div className="appointment-calendar">
                        <EventIcon style={{ color: calendar.color }} />
                        <div className="appointment-type-and-calendar-name">
                            <div className="appointment-type">{appointmentLabel}</div>
                            <div className="calendar-name">{calendar.name}</div>
                        </div>
                    </div>

                    {vaccineType ? (
                        <div className="vaccine-type">
                            <NeedleIcon size="20" color={_.get(VACCINE_COLORS, vaccineType)} />{' '}
                            <span className="vaccine-name">{_.get(VACCINES_AND_LABELS, vaccineType)}</span>
                        </div>
                    ) : null}
                </div>
            )}

            {isCheckedIn ? (
                <div className="controls checked-in">
                    <RoomIcon />
                    <div className="text">
                        <div className="main-line">
                            {reservation.check_in_user_type !== 'pharmacist' ? 'Patient checked in' : 'Checked in'}
                            {reservation.check_in_user_type !== 'pharmacist' ? null : isUncheckingIn ? (
                                <CircularProgress className="unchecking-in-progress" size={12} />
                            ) : (
                                <Tooltip title="This patient was checked in by the pharmacy, click here if this was done by mistake.">
                                    <Button
                                        onClick={() => {
                                            setIsUncheckingIn(true);
                                            uncheckinReservation(
                                                calendar.calendar_id,
                                                reservation.reservation_id
                                            ).then(() => setIsUncheckingIn(false));
                                        }}
                                    >
                                        undo
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                        <div className="sub-line">
                            {formatTime(
                                moment.tz(
                                    moment.utc(reservation.check_in_date, RESERVATION_TIME_FORMAT),
                                    _.get(calendar, 'timezone')
                                ),
                                displayedDateFormat
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="controls">
                    <Tooltip title="Edit this appointments time or delete it from your calendar.">
                        <Button className="edit-button" onClick={onEdit}>
                            Edit
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title={
                            mayCheckIn
                                ? 'Check-in on this patients behalf.'
                                : 'Check-in becomes available 1 hour before an appointment begins.'
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!mayCheckIn}
                                onClick={() => {
                                    setIsCheckingIn(true);
                                }}
                            >
                                Check-in
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            )}

            {!isCheckingIn ? null : <AppointmentCheckinDialog open onClose={() => setIsCheckingIn(false)} {...props} />}
        </div>
    );
}

export function AppointmentCheckinDialog({
    open,
    patient,
    reservation,
    calendar,
    displayedDateFormat,
    checkinReservation,
    userId,
    onClose,
}) {
    const [isCheckingIn, setIsCheckingIn] = useState(false);

    const startTime = moment.tz(
        moment.utc(reservation.start_date, RESERVATION_TIME_FORMAT),
        _.get(calendar, 'timezone')
    );
    const endTime = moment.tz(moment.utc(reservation.end_date, RESERVATION_TIME_FORMAT), _.get(calendar, 'timezone'));

    return (
        <Dialog className="appointment-checkin-dialog" open={open} onClose={onClose}>
            <DialogTitle>Really check-in patient for their appointment?</DialogTitle>
            <DialogContent>
                <div className="patient-and-appt-info">
                    <PatientName {...patient} />

                    <div className="reservation-type">{reservation.label}</div>

                    <div className="appointment-time">
                        {formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat)}
                    </div>
                </div>
                <div className="explanation">
                    Patients should be reminded to check-in themselves from the "Check-in" button on the 1 hour reminder
                    that was sent to their phones. However, if they did not do so, you may check them in yourself by
                    clicking the button below.
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setIsCheckingIn(true);
                        checkinReservation(calendar.calendar_id, reservation.reservation_id, userId).then(() => {
                            setIsCheckingIn(false);
                            onClose();
                        });
                    }}
                >
                    {isCheckingIn ? 'Checking in...' : 'Check-in Patient'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function formatAppointmentTimeSpan(startTime, endTime, displayedDateFormat, timezone = null) {
    startTime = _.isNil(timezone) ? moment(startTime).local() : moment.tz(startTime, timezone);
    endTime = _.isNil(timezone) ? moment(endTime).local() : moment.tz(endTime, timezone);
    // check if start and ends on the same day so we can omit date
    if (dateEquals(startTime, endTime)) {
        // if they are both in am or pm, we can simply put that on the endtime suffix
        if (startTime.format('a') === endTime.format('a')) {
            return `${startTime.format('h:mm')} - ${endTime.format('h:mma')}`;
        } else {
            return `${startTime.format('h:mma')} - ${endTime.format('h:mma')}`;
        }
    } else {
        // the appointment crosses days so put full date and time in timespan
        return `${startTime.format(`${displayedDateFormat} h:mma`)} - ${endTime.format(
            `${displayedDateFormat} h:mma`
        )}`;
    }
}

export function formatTimeRangeWithDate({ start_date, end_date }) {
    const start = moment.tz(moment.utc(start_date, 'YYYY-MM-DDTHH:mm:ss'), moment.tz.guess());
    const end = moment.tz(moment.utc(end_date, 'YYYY-MM-DDTHH:mm:ss'), moment.tz.guess());

    const shortLabel = getRelativeDateShortLabel(start);
    const dateLabel = _.isNil(shortLabel) ? start.format('ddd MMM Do') : shortLabel;

    const startAmPm = start.format('a') === end.format('a') ? '' : start.format('a');

    return `${dateLabel} ${start.format('h:mm')}${startAmPm} - ${end.format('h:mma')}`;
}

export function formatTimeRangeDate({ start_date /*, end_date */ }) {
    const start = moment.tz(moment.utc(start_date, 'YYYY-MM-DDTHH:mm:ss'), moment.tz.guess());
    // const end = moment.tz(moment.utc(end_date, 'YYYY-MM-DDTHH:mm:ss'), moment.tz.guess());

    const shortLabel = getRelativeDateShortLabel(start);
    return _.isNil(shortLabel) ? start.format('dddd, MMM Do') : shortLabel;
}

export function formatTime(d, displayedDateFormat) {
    if (moment().isSame(d, 'day')) {
        return d.fromNow();
    } else {
        return d.format(`${displayedDateFormat} h:mma`);
    }
}
