import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AssignmentTurnedIn } from 'svgIcons/learn_more/assignment_turned_in.svg';
import { ReactComponent as CameraEnhance } from 'svgIcons/learn_more/camera_enhance.svg';
import { ReactComponent as Dashboard } from 'svgIcons/learn_more/dashboard.svg';
import { ReactComponent as Devices } from 'svgIcons/learn_more/devices.svg';
import { ReactComponent as Feedback } from 'svgIcons/learn_more/feedback.svg';
import { ReactComponent as FormatListNumber } from 'svgIcons/learn_more/format_list_number.svg';
import { ReactComponent as ImportantDevices } from 'svgIcons/learn_more/important_devices.svg';
import { ReactComponent as LocalLibrary } from 'svgIcons/learn_more/local_library.svg';
import { ReactComponent as LocalPharmacy } from 'svgIcons/learn_more/local_pharmacy.svg';
import { ReactComponent as Lock } from 'svgIcons/learn_more/lock.svg';
import { ReactComponent as Mail } from 'svgIcons/learn_more/mail.svg';
import { ReactComponent as MenuBook } from 'svgIcons/learn_more/menu_book.svg';
import { ReactComponent as People } from 'svgIcons/learn_more/people.svg';
import { ReactComponent as PersonAdd } from 'svgIcons/learn_more/person_add.svg';
import { ReactComponent as PhoneInTalk } from 'svgIcons/learn_more/phone_in_talk.svg';
import { ReactComponent as Policy } from 'svgIcons/learn_more/policy.svg';
import { ReactComponent as RateReview } from 'svgIcons/learn_more/rate_review.svg';
import { ReactComponent as RingVolume } from 'svgIcons/learn_more/ring_volume.svg';
import { ReactComponent as Schedule } from 'svgIcons/learn_more/schedule.svg';
import { ReactComponent as School } from 'svgIcons/learn_more/school.svg';
import { ReactComponent as Search } from 'svgIcons/learn_more/search.svg';
import { ReactComponent as ThumpUpAlt } from 'svgIcons/learn_more/thumb_up_alt.svg';
import { ReactComponent as TrendingUp } from 'svgIcons/learn_more/trending_up.svg';
import { ReactComponent as Tune } from 'svgIcons/learn_more/tune.svg';

const LearnMoreIcon = ({ variant }) => {
    const iconMap = {
        assignment_turned_in: AssignmentTurnedIn,
        camera_enhance: CameraEnhance,
        dashboard: Dashboard,
        devices: Devices,
        feedback: Feedback,
        format_list_number: FormatListNumber,
        important_devices: ImportantDevices,
        local_library: LocalLibrary,
        local_pharmacy: LocalPharmacy,
        lock: Lock,
        mail: Mail,
        menu_book: MenuBook,
        people: People,
        person_add: PersonAdd,
        phone_in_talk: PhoneInTalk,
        policy: Policy,
        rate_review: RateReview,
        ring_volume: RingVolume,
        schedule: Schedule,
        school: School,
        search: Search,
        thumb_up_alt: ThumpUpAlt,
        trending_up: TrendingUp,
        tune: Tune,
    };

    const Icon = iconMap[variant];

    return <Icon style={{ width: '48px', height: '48px', marginRight: '15px' }} />;
};

LearnMoreIcon.propTypes = {
    variant: PropTypes.string.isRequired,
};

export default LearnMoreIcon;
