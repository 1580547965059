import Actions from 'redux/actions';
import { restActionCreators } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { fetchMainGreeting, saveGreeting } from 'redux/actionCreators/Settings/MainGreeting';
import { logHttpError } from '../../../utils/log';
import { fetchHuntGroup } from 'redux/actionCreators/Settings/SpeakWithPharmacist';

const getIvrApiPath = (ivrId) => {
    const apiPath = API_PATH.MENU_CONFIG.replace('{custID}', Config.X_PharmacyID)
        .replace('{locID}', Config.X_LocationID)
        .replace('{ivrID}', ivrId);
    return Config.config2_url + apiPath;
};

export const fetchPhoneMenu = (greeting) => (dispatch) => {
    const apiUrl = getIvrApiPath(greeting.id);
    const header = { params: { numRows: 99 } };

    return restActionCreators
        .get(apiUrl, header)
        .then((data) => {
            dispatch({ type: Actions.FETCH_PHONE_MENU_SUCCESS, payload: data });
            return { ...data };
        })
        .catch((err) => {
            logHttpError('An error occurred while fetching ivr phone menu config', err);
            dispatch({ type: Actions.FETCH_PHONE_MENU_FAILED, payload: err });
        });
};

export const fetchPhoneMenuSettings = (canCreateGreeting = false) => (dispatch) => {
    dispatch({ type: Actions.FETCH_PHONE_MENU_STARTED });
    dispatch(fetchMainGreeting()).then((resp) => {
        if (resp.err) {
            if (canCreateGreeting) {
                dispatch(saveGreeting({ newGreeting: {}, type: 'MAIN' })).then(() => {
                    dispatch(fetchMainGreeting()).then((data) => {
                        dispatch(fetchPhoneMenu(data));
                        dispatch(fetchHuntGroup(data));
                    });
                });
            } else {
                dispatch({ type: Actions.FETCH_PHONE_MENU_FAILED, payload: resp.err });
            }
        } else {
            dispatch(fetchPhoneMenu(resp));
            dispatch(fetchHuntGroup(resp));
        }
    });
};

export const saveMenuStatus = ({ isNew, ...payload }) => (dispatch) => {
    const apiUrl = getIvrApiPath(payload.id);
    const data = {};
    let httpMethod = restActionCreators.put;

    data[payload.item] = payload.value;

    if (isNew) {
        data['menuOption'] = payload.item;
        data['isEnabled'] = payload.value;
        httpMethod = restActionCreators.post;
    }
    dispatch({ type: Actions.SAVE_MENU_STATUS_STARTED, payload });

    return httpMethod(apiUrl, data)
        .then((data) => {
            dispatch({ type: Actions.SAVE_MENU_STATUS_SUCCESS, payload });
            dispatch(displayToast({ text: 'Update phone menu completed', type: 'success' }));
            return data;
        })
        .catch((err) => {
            logHttpError('An error occurred while updating ivr menu config', err);
            dispatch({ type: Actions.SAVE_MENU_STATUS_FAILED, payload });
            dispatch(displayToast({ text: 'Update phone menu failed', type: 'error' }));
            return { err };
        });
};
