import Actions from 'redux/actions';
import NewPatientLineGreeting from 'constants/NewPatientLine';

const initialState = {
    newNewPatientLineGreeting: {},
    showNewPatientLineGreetingCreator: false,
};

const addNewPatientLineGreeting = (state) => {
    const newNewPatientLineGreeting = { ...NewPatientLineGreeting };
    return {
        ...state,
        showNewPatientLineGreetingCreator: true,
        newNewPatientLineGreeting,
    };
};

const updateNewPatientLineGreetingMsg = (state, payload) => {
    const newNewPatientLineGreeting = { ...state.newNewPatientLineGreeting };
    if (payload.id) {
        const greetingFiles = [...payload.greetingFiles];
        const selectedGreetingFile = greetingFiles.find((file) => file.id === payload.id);
        newNewPatientLineGreeting.new_patient_flow_greeting_id = selectedGreetingFile.id;
        newNewPatientLineGreeting.new_patient_flow_greeting_url = selectedGreetingFile.publicURL;
    } else {
        newNewPatientLineGreeting.new_patient_flow_greeting_id = '';
        newNewPatientLineGreeting.new_patient_flow_greeting_url = '';
    }
    return {
        ...state,
        newNewPatientLineGreeting,
    };
};

const editNewPatientLineGreeting = (state, payload) => ({
    ...state,
    showNewPatientLineGreetingCreator: true,
    newNewPatientLineGreeting: { ...payload },
});

const changeGreetingType = (state, payload) => {
    const newNewPatientLineGreeting = { ...state.newNewPatientLineGreeting };
    newNewPatientLineGreeting.new_patient_flow_greeting_type_id = payload;

    return {
        ...state,
        newNewPatientLineGreeting,
    };
};

const changeGreetingScript = (state, payload) => {
    const newNewPatientLineGreeting = { ...state.newNewPatientLineGreeting };
    newNewPatientLineGreeting.new_patient_flow_greeting_text = payload;

    return {
        ...state,
        newNewPatientLineGreeting,
    };
};

export default function NewPatientLineReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.ADD_NEW_PATIENT_LINE_GREETING:
            return addNewPatientLineGreeting(state);
        case Actions.UPDATE_NEW_PATIENT_LINE_GREETING:
            return updateNewPatientLineGreetingMsg(state, payload);
        case Actions.CLOSE_NEW_PATIENT_LINE_GREETING_EDITOR:
            return { ...state, showNewPatientLineGreetingCreator: false, newNewPatientLineGreeting: {} };
        case Actions.EDIT_NEW_PATIENT_LINE_GREETING:
            return editNewPatientLineGreeting(state, payload);
        case Actions.CHANGE_NEW_PATIENT_LINE_GREETING_TYPE:
            return changeGreetingType(state, payload);
        case Actions.CHANGE_NEW_PATIENT_LINE_GREETING_SCRIPT:
            return changeGreetingScript(state, payload);
        default:
            return state;
    }
}
