import _ from 'lodash';
import ActionTypes from './type';

export const defaultState = {
    isLoading: false,
};

// A contract is considered signed if baa is signed and terms and conditions
// signed date is not null/ empty. Rusty confirmed this.
const isContractSigned = (payload) => {
    const { is_baa_signed, terms_and_conditions_signed_date } = payload;
    return !_.isEmpty(terms_and_conditions_signed_date) && is_baa_signed;
};

// In reality the Pharmacy Contracts are signed for each locations and yet they are
// referred as Pharmacy Contracts. Therefore I am naming these as PharmacyContract and
// not as PharmacyLocationContract
export const pharmacyContractReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_INFORMATION:
            return { ...state, isLoading: true };
        case ActionTypes.FETCH_INFORMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                [`${payload.location_id}`]: {
                    ...state[payload.location_id],
                    isSigned: isContractSigned(payload),
                },
            };
        case ActionTypes.SKIP_SIGNING_CONTRACT:
        case ActionTypes.FETCH_INFORMATION_FAILED:
            return {
                ...state,
                isLoading: false,
                [`${payload.location_id}`]: {
                    ...state[payload.location_id],
                    skipSigning: true,
                },
            };
        default:
            return state;
    }
};

export default pharmacyContractReducer;
