import conversationType from './type';

export const initialState = {
    sending: false,
    loading: false,
    activeConversationId: null,
    activeConversation: null,
    conversations: [],
    createConversationMessage: '',
    showCreateConversationDialog: false,
    error: {},
};

export const conversationReducer = (state = initialState, action) => {
    switch (action.type) {
        case conversationType.RESET_CONVERSATION_STORE:
            return {
                ...initialState,
            };
        case conversationType.SET_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: action.activeConversation,
                activeConversationId: action.activeConversationId,
            };
        case conversationType.CLEAR_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: {},
                activeConversationId: null,
            };
        case conversationType.CREATE_CONVERSATION_REQUEST:
            return {
                ...state,
                sending: true,
            };
        case conversationType.CREATE_CONVERSATION_SUCCESS:
            return {
                ...state,
                sending: false,
                createConversationMessage: '',
            };
        case conversationType.CREATE_CONVERSATION_FAILURE:
            return {
                ...state,
                sending: false,
                createConversationMessage: action.error.message,
                error: action.error,
            };
        case conversationType.GET_CONVERSATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case conversationType.GET_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conversations: action.conversations,
            };
        case conversationType.GET_CONVERSATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                conversations: [],
                error: action.error,
            };
        case conversationType.OPEN_CREATE_CONVERSATION_DIALOG:
            return {
                ...state,
                showCreateConversationDialog: true,
                createConversationMessage: '',
            };
        case conversationType.CLOSE_CREATE_CONVERSATION_DIALOG:
            return {
                ...state,
                showCreateConversationDialog: false,
                createConversationMessage: '',
            };
        case conversationType.TOGGLE_CREATE_CONVERSATION_DIALOG:
            return {
                ...state,
                showCreateConversationDialog: !state.showCreateConversationDialog,
                createConversationMessage: '',
            };
        case conversationType.UPDATE_ACTIVE_CONVERSATION_MESSAGE_STATUS:
            return {
                ...state,
                conversations: action.conversations,
                activeConversation: action.activeConversation,
            };
        default:
            return state;
    }
};

export default conversationReducer;
