import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { templateAction } from '../../../redux/Inbox/Template/index';
import { Button, Cell, Grid, Card, CardText } from 'react-md';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CardHeader } from '../../Common/index';
import TemplateAdd from './TemplateAdd';
import TemplateItem from './TemplateItem';
import config from '../../../config';
import { isEmptyString } from '../../../utils/helper';
import { List, Typography } from '@material-ui/core';
import Snowplow, { SnowplowConstants } from '../../../snowplow';
import { AbilityContext, userActions, restrictedResources } from 'casl';
import goBackService from 'utils/goBackService';

export class TemplateDetails extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.state = {
            isAdding: false,
            editTemplateId: null,
            newSubject: '',
            newMessage: '',
            errors: {},
        };
    }

    componentDidMount = () => {
        this.props.getTemplates();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    setEditTemplate = (templateId) => {
        this.setState({
            editTemplateId: templateId,
        });
    };

    cancelEditTemplate = () => {
        this.setState({
            editTemplateId: null,
        });
    };

    buildCreatePayload = () => {
        const { newSubject, newMessage } = this.state;
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            subject: newSubject,
            message: newMessage.trim().length > 0 ? newMessage.trim() : null,
        };
        return payload;
    };

    addTemplate = () => {
        const { createTemplate } = this.props;
        const errors = this.getValidationErrors();

        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            // Validation passed
            createTemplate(this.buildCreatePayload()).then(() => {
                this.setState({
                    isAdding: false,
                });
            });

            Snowplow.structEvent('Inbox', 'new-template-created', null, SnowplowConstants.clicked);
        }
    };

    getValidationErrors = () => {
        const { newSubject, newMessage } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.newSubject = isEmptyString(newSubject) ? emptyMessage : false;
        errors.newMessage = isEmptyString(newMessage) ? emptyMessage : false;

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);
        return errors;
    };

    toggleAddItem = () => {
        this.setState({
            isAdding: true,
            newSubject: '',
            /* eslint-disable */
            newMessage: 'Hi ${first_name}, ',
            /* eslint-enable */
        });
    };

    cancelAddItem = () => {
        this.setState({
            isAdding: false,
            newSubject: '',
            newMessage: '',
            errors: {},
        });
    };

    onBack = () => {
        const { history } = this.props;
        goBackService.toInboxSettings(history);
    };

    buildActivatePayload = (templateId) => {
        const payload = {
            inbox_conversation_template_id: templateId,
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
        };
        return payload;
    };

    toggleActiveState = (templateId) => {
        const templateStore = this.props.template;
        templateStore.templates.forEach((template) => {
            if (template.inbox_conversation_template_id === templateId) {
                if (template.status === 'active') {
                    this.props.deactivateTemplate(this.buildActivatePayload(templateId));
                } else {
                    this.props.activateTemplate(this.buildActivatePayload(templateId));
                }
            }
        });
    };

    render() {
        const { isAdding, newSubject, newMessage, errors, editTemplateId } = this.state;
        const { selectedLocation, template, updateTemplate, deleteTemplate } = this.props;
        const templateStore = this.props.template;
        const {
            messageTemplates: { subject: messageTemplates },
        } = restrictedResources.inbox;
        const hasWritePermissions = this.context.can(userActions.write, messageTemplates);
        const templateComponents = templateStore.templates.map(
            (templateItem) =>
                templateItem.status !== 'deleted' &&
                // If this is a workflow template, then ignore it since it will be managed by workflows
                _.isNil(templateItem.workflow_status) && (
                    <TemplateItem
                        key={templateItem.inbox_conversation_template_id}
                        template={templateItem}
                        toggleActiveState={this.toggleActiveState}
                        templateId={templateItem.inbox_conversation_template_id}
                        sending={template.sending}
                        loading={template.loading}
                        updateTemplate={updateTemplate}
                        requestError={template.updateTemplateMessage}
                        deleteTemplate={deleteTemplate}
                        setEditTemplate={this.setEditTemplate}
                        cancelEditTemplate={this.cancelEditTemplate}
                        editTemplateId={editTemplateId}
                        hasWritePermissions={hasWritePermissions}
                    />
                )
        );
        return (
            <Grid style={{ position: 'relative' }}>
                <Cell size={12} phoneSize={4}>
                    <Card className="outer-card">
                        <CardHeader title={`Templated Messages for ${selectedLocation.name}`} onBack={this.onBack} />
                        <Card className="inner-card" style={{ paddingLeft: '10px', height: '100%' }}>
                            <CardText
                                style={{
                                    padding: '0px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Cell size={12} phoneSize={4}>
                                    <CardText className="template-info-section">
                                        <Typography variant="h6" style={{ marginBottom: '0px' }}>
                                            Add and edit templated messages
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            These are the template options you can select from when creating a new
                                            conversation
                                        </Typography>
                                    </CardText>
                                </Cell>
                                <List
                                    style={{
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <Cell
                                        size={12}
                                        phoneSize={4}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <List>{templateComponents}</List>
                                    </Cell>
                                </List>
                                <Button
                                    style={{
                                        marginBottom: '16px',
                                        width: '200px',
                                        alignSelf: 'center',
                                    }}
                                    flat
                                    primary
                                    onClick={this.toggleAddItem}
                                    disabled={!hasWritePermissions}
                                >
                                    + ADD A NEW TEMPLATE
                                </Button>
                                {isAdding && (
                                    <TemplateAdd
                                        onChange={this.handleInputChange}
                                        toggleAddItem={this.toggleAddItem}
                                        newSubject={newSubject}
                                        newMessage={newMessage}
                                        addTemplate={this.addTemplate}
                                        sending={template.sending}
                                        requestError={template.createTemplateMessage}
                                        errors={errors}
                                        cancelAddItem={this.cancelAddItem}
                                    />
                                )}
                            </CardText>
                        </Card>
                    </Card>
                </Cell>
                {template.loading && <CircularProgress className="conversation-message-list__progress" />}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const { inboxConversationTemplate, pharmacy } = state;
    return {
        template: inboxConversationTemplate,
        pharmacyId: pharmacy.pharmacy.id,
        locationId: pharmacy.pharmacy.activeLocationId,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    activateTemplate: (templateData) => dispatch(templateAction.activateTemplate(templateData)),
    deactivateTemplate: (templateData) => dispatch(templateAction.deactivateTemplate(templateData)),
    getTemplates: () => dispatch(templateAction.getTemplates()),
    createTemplate: (templateData) => {
        return dispatch(templateAction.createTemplate(templateData));
    },
    deleteTemplate: (templateData) => dispatch(templateAction.deleteTemplate(templateData)),
    updateTemplate: (templateData) => {
        return dispatch(templateAction.updateTemplate(templateData));
    },
});

export default connect(mapStateToProps, bindActionsToDispatch)(TemplateDetails);
