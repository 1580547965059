const typePrefix = 'apptCalendar/';

const apptCalendarDataTypes = {
    EXISTING_CALENDARS_REQUEST: `${typePrefix}EXISTING_CALENDARS_REQUEST`,
    EXISTING_CALENDARS_SUCCESS: `${typePrefix}EXISTING_CALENDARS_SUCCESS`,
    EXISTING_CALENDARS_FAILURE: `${typePrefix}EXISTING_CALENDARS_FAILURE`,

    CREATE_CALENDAR_REQUEST: `${typePrefix}CREATE_CALENDAR_REQUEST`,
    CREATE_CALENDAR_SUCCESS: `${typePrefix}CREATE_CALENDAR_SUCCESS`,
    CREATE_CALENDAR_FAILURE: `${typePrefix}CREATE_CALENDAR_FAILURE`,

    DELETE_CALENDAR_REQUEST: `${typePrefix}DELETE_CALENDAR_REQUEST`,
    DELETE_CALENDAR_SUCCESS: `${typePrefix}DELETE_CALENDAR_SUCCESS`,
    DELETE_CALENDAR_FAILURE: `${typePrefix}DELETE_CALENDAR_FAILURE`,

    UPDATE_CALENDAR_REQUEST: `${typePrefix}UPDATE_CALENDAR_REQUEST`,
    UPDATE_CALENDAR_SUCCESS: `${typePrefix}UPDATE_CALENDAR_SUCCESS`,
    UPDATE_CALENDAR_FAILURE: `${typePrefix}UPDATE_CALENDAR_FAILURE`,

    CLEAR_AVAILABLE_APPOINTMENT_SLOTS: `${typePrefix}CLEAR_AVAILABLE_APPOINTMENT_SLOTS`,

    GET_AVAILABLE_CALENDAR_APPT_SLOTS_REQUEST: `${typePrefix}GET_AVAILABLE_CALENDAR_APPT_SLOTS_REQUEST`,
    GET_AVAILABLE_CALENDAR_APPT_SLOTS_SUCCESS: `${typePrefix}GET_AVAILABLE_CALENDAR_APPT_SLOTS_SUCCESS`,
    GET_AVAILABLE_CALENDAR_APPT_SLOTS_FAILURE: `${typePrefix}GET_AVAILABLE_CALENDAR_APPT_SLOTS_FAILURE`,

    GET_CALENDAR_RESERVATIONS_REQUEST: `${typePrefix}GET_CALENDAR_RESERVATIONS_REQUEST`,
    GET_CALENDAR_RESERVATIONS_SUCCESS: `${typePrefix}GET_CALENDAR_RESERVATIONS_SUCCESS`,
    GET_CALENDAR_RESERVATIONS_FAILURE: `${typePrefix}GET_CALENDAR_RESERVATIONS_FAILURE`,

    GET_CALENDAR_APPOINTMENT_COUNTS_REQUEST: `${typePrefix}GET_CALENDAR_APPOINTMENT_COUNTS_REQUEST`,
    GET_CALENDAR_APPOINTMENT_COUNTS_SUCCESS: `${typePrefix}GET_CALENDAR_APPOINTMENT_COUNTS_SUCCESS`,
    GET_CALENDAR_APPOINTMENT_COUNTS_FAILURE: `${typePrefix}GET_CALENDAR_APPOINTMENT_COUNTS_FAILURE`,

    CLEAR_CALENDAR_RESERVATIONS: `${typePrefix}CLEAR_CALENDAR_RESERVATIONS`,

    CLEAR_CALENDAR_APPOINTMENT_COUNTS: `${typePrefix}CLEAR_CALENDAR_APPOINTMENT_COUNTS`,

    UPDATE_CALENDAR_RESERVATIONS_FROM_RESPONSE: `${typePrefix}UPDATE_CALENDAR_RESERVATIONS_FROM_RESPONSE`,

    RESET_CALENDARS: `${typePrefix}RESET_CALENDARS`,
};

export default apptCalendarDataTypes;
