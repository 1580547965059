import Actions from 'redux/actions';
import _ from 'lodash';
import { daysOfWeek, holiday } from 'constants/BusinessInformation';
import moment from 'moment';
import uuid4 from 'uuid/v4';

const initialState = {
    exceptionHours: [],
    defaultHours: [],
    loadingDefaultHours: false,
    loadingExceptionHours: false,
    savingProgress: false,
    loadingHoliday: false,
    savingCustomProgress: false,
    savingHolidayProgress: false,
    holidayList: [],
};

const updateDaysAndGetNextState = (state, { day, key, hour, min }) => {
    const defaultHours = _.cloneDeep(state.defaultHours);
    const idx = defaultHours.findIndex((element) => element.day === day);
    defaultHours[idx][key + 'Hour'] = hour;
    defaultHours[idx][key + 'Minute'] = min;
    defaultHours[idx].dirty = true;
    return { ...state, defaultHours };
};

const updateHolidaysAndGetNextState = (state, { idx, key, hour, min }) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    exceptionHours[idx][key + 'Hour'] = hour;
    exceptionHours[idx][key + 'Minute'] = min;
    exceptionHours[idx].dirty = true;
    return { ...state, exceptionHours };
};

const addExceptionHours = (state) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    const holidayObj = { ...holiday };
    holidayObj.startDate = moment().hour(0).minute(0).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    holidayObj.endDate = moment().hour(23).minute(59).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    holidayObj.uuid = uuid4();
    exceptionHours.push(holidayObj);
    return {
        ...state,
        exceptionHours,
    };
};

const updateLatestDefaultHour = (payload, state) => {
    const defaultHours = _.cloneDeep(daysOfWeek);
    if (payload.totalCount) {
        defaultHours.forEach((hour, hIdx) => {
            const idx = payload.result.findIndex((element) => element.day === hour.day);
            if (idx > -1) {
                defaultHours[hIdx] = payload.result[idx];
                defaultHours[hIdx].dirty = false;
            }
        });
    }

    return {
        ...state,
        defaultHours,
        loadingDefaultHours: false,
    };
};

const updateLatestExceptionHour = (payload, state) => {
    const exceptionHours = _.cloneDeep(payload.result);
    return {
        ...state,
        exceptionHours,
        loadingExceptionHours: false,
    };
};

const updateExceptionDate = (payload, state) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    const startDateMoment = moment(payload.data.startDate);
    const endDateMoment = moment(payload.data.endDate);

    exceptionHours[payload.idx].startDate = startDateMoment.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    exceptionHours[payload.idx].endDate = endDateMoment
        .hour(23)
        .minute(59)
        .seconds(0)
        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    exceptionHours[payload.idx].dirty = true;

    if (
        (startDateMoment.format('M') === '2' && startDateMoment.format('D') === '29') ||
        (endDateMoment.format('M') === '2' && endDateMoment.format('D') === '29')
    ) {
        exceptionHours[payload.idx].repeats = false;
    }

    return {
        ...state,
        exceptionHours,
    };
};

const updateExceptionTitle = (payload, state) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    exceptionHours[payload.idx].errMsg = '';
    exceptionHours[payload.idx].name = payload.data;
    exceptionHours[payload.idx].dirty = true;
    if (payload.data === '') {
        exceptionHours[payload.idx].errMsg = 'Holiday name is required';
    }
    return {
        ...state,
        exceptionHours,
    };
};

const updateExceptionHoliday = (payload, state) => {
    const { exceptionHours } = { ...state };
    const { idx, holidayDetail } = payload;
    const holidayDate = holidayDetail ? moment(holidayDetail.nextDate) : '';
    const defaultStartDate = moment().hour(0).minute(0).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    const defaultEndDate = moment().hour(23).minute(59).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

    exceptionHours[idx].holidayId = holidayDetail ? holidayDetail.id : '';
    exceptionHours[idx].name = holidayDetail ? holidayDetail.name : 'New Holiday';
    exceptionHours[idx].startDate = holidayDetail
        ? holidayDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
        : defaultStartDate;
    exceptionHours[idx].endDate = holidayDetail
        ? holidayDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
        : defaultEndDate;
    exceptionHours[idx].presetHoliday = holidayDetail ? true : false;
    exceptionHours[payload.idx].dirty = true;
    return {
        ...state,
        exceptionHours,
    };
};

const updateDeletedExceptionHour = (payload, state) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    exceptionHours.splice(payload.removeIdx, 1);
    return {
        ...state,
        exceptionHours,
    };
};

const toggleExceptionHour = (payload, state, status) => {
    const exceptionHours = _.cloneDeep(state.exceptionHours);
    exceptionHours[payload.removeIdx].isDeleting = status;
    return {
        ...state,
        exceptionHours,
    };
};

const updateHolidayRepeat = (payload, state) => {
    const { exceptionHours } = { ...state };

    exceptionHours[payload].repeats = exceptionHours[payload].repeats === 1 ? 0 : 1;
    exceptionHours[payload].dirty = true;
    return {
        ...state,
        exceptionHours,
    };
};

const updateConflictErrMsg = (state, payload) => {
    const { exceptionHours } = { ...state };
    exceptionHours.forEach((hour, idx) => {
        if (payload.conflictMsg[hour.id]) {
            exceptionHours[idx].errMsg = payload.conflictMsg[hour.id];
        }
    });
    return { ...state, exceptionHours };
};

const updateHolidayConflictErrMsg = (payload, state) => {
    const { exceptionHours } = { ...state };
    exceptionHours.forEach((hour, idx) => {
        const conflict = payload.conflictMsg.find(
            (conflict) => conflict.holidayName === hour.name && conflict.withId.indexOf(hour.id) < 0
        );
        if (conflict) {
            exceptionHours[idx].errMsg = conflict.withMsg;
        }
    });
    return { ...state, exceptionHours };
};

export default function BusinessInformationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case Actions.FETCH_DEFAULT_HOURS_SUCCESS:
            return updateLatestDefaultHour(payload, state);
        case Actions.FETCH_EXCEPTION_HOURS_SUCCESS:
            return updateLatestExceptionHour(payload, state);
        case Actions.ADD_EXCEPTION_HOURS:
            return addExceptionHours(state);
        case Actions.FETCH_LOCATION_SUCCESS:
            return { ...state, custLocation: payload };
        case Actions.UPDATE_EXCEPTION_DATE:
            return updateExceptionDate(payload, state);
        case Actions.UPDATE_EXCEPTION_TITLE:
            return updateExceptionTitle(payload, state);
        case Actions.REMOVE_EXCEPTION_HOUR_SUCCESS:
            return updateDeletedExceptionHour(payload, state);
        case Actions.REMOVE_EXCEPTION_HOUR_STARTED:
            return toggleExceptionHour(payload, state, true);
        case Actions.REMOVE_EXCEPTION_HOUR_FAILED:
            return toggleExceptionHour(payload, state, false);
        case Actions.FETCH_DEFAULT_HOURS_STARTED:
            return { ...state, loadingDefaultHours: true };
        case Actions.FETCH_EXCEPTION_HOURS_STARTED:
            return { ...state, loadingExceptionHours: true };
        case Actions.FETCH_DEFAULT_HOURS_FAILED:
            return { ...state, loadingDefaultHours: false };
        case Actions.FETCH_EXCEPTION_HOURS_FAILED:
            return { ...state, loadingExceptionHours: false };
        case Actions.UPDATE_DAYS_OF_WEEK:
            return updateDaysAndGetNextState(state, payload);
        case Actions.UPDATE_HOLIDAYS:
            return updateHolidaysAndGetNextState(state, payload);
        case Actions.SAVE_DEFAULT_HOURS_STARTED:
            return { ...state, savingProgress: true };
        case Actions.SAVE_DEFAULT_HOURS_SUCCESS:
        case Actions.SAVE_DEFAULT_HOURS_FAILED:
            return { ...state, savingProgress: false };
        case Actions.SAVE_EXCEPTION_HOURS_STARTED:
            return { ...state, savingCustomProgress: true };
        case Actions.SAVE_EXCEPTION_HOURS_SUCCESS:
        case Actions.SAVE_EXCEPTION_HOURS_FAILED:
            return { ...state, savingCustomProgress: false };
        case Actions.SAVE_CUSTOM_HOLIDAYS_STARTED:
            return { ...state, savingHolidayProgress: true };
        case Actions.SAVE_CUSTOM_HOLIDAYS_SUCCESS:
        case Actions.SAVE_CUSTOM_HOLIDAYS_FAILED:
            return { ...state, savingHolidayProgress: false };
        case Actions.FETCH_EXCEPTION_HOURS_CONFLICT_SUCCESS:
            return updateConflictErrMsg(state, payload);
        case Actions.HOLIDAY_SELECTED:
            return updateExceptionHoliday(payload, state);
        case Actions.UPDATE_HOLIDAY_REPEAT:
            return updateHolidayRepeat(payload, state);
        case Actions.FETCH_HOLIDAY_LIST_SUCCESS:
            return { ...state, loadingHoliday: false, holidayList: payload };
        case Actions.FETCH_HOLIDAY_LIST_STARTED:
            return { ...state, loadingHoliday: true };
        case Actions.FETCH_HOLIDAY_LIST_FAILED:
            return { ...state, loadingHoliday: false };
        case Actions.FETCH_HOLIDAY_CONFLICT_SUCCESS:
            return updateHolidayConflictErrMsg(payload, state);
        default:
            return state;
    }
}
