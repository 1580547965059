import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from 'react-redux';
import _ from 'lodash';

import { newsletterActions } from 'redux/Newsletter/action';
import DpImageCropDialog from 'components/Common/DpImageCropDialog';
import { isEmptyString, isUrl } from 'utils/helper';
import DPRichTextEditor from 'components/Common/DPRichTextEditor';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Card, CardContent } from '@material-ui/core';

const NewsletterCardSettings = (props) => {
    const {
        setActiveCard,
        activeCard: { imageData, title, header, additionalText, buttonText, buttonLink },
        savingCard,
    } = props;
    const [uploadingImage, setUploadingImage] = useState(false);
    const [visibility, setVisibility] = useState({
        header: false,
        additionalText: false,
        buttonText: false,
    });
    const [validationError, setValidationError] = useState({});
    const imageDimensions = {
        minWidth: 598,
        minHeight: 280,
        minCropWidth: 491,
        minCropHeight: 244,
        maxCropHeight: 500,
    };

    const onBack = () => {
        const { resetActiveCard } = props;
        resetActiveCard();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setActiveCard({
            [name]: value,
        });
        setValidationError({});
    };

    const onCancelClick = () => {
        const { resetActiveCard } = props;
        resetActiveCard();
    };

    const uploadImageClick = () => {
        setUploadingImage(true);
    };

    const closeImageUploadDialog = () => {
        setUploadingImage(false);
    };

    const enableSectionVisibility = (type) => {
        setVisibility({
            ...visibility,
            [type]: true,
        });
    };

    const onCropComplete = (imageData) => {
        setActiveCard({
            imageData,
        });
        closeImageUploadDialog();
    };

    const deleteCroppedImage = () => {
        setActiveCard({
            imageData: null,
        });
    };

    const getValidationError = () => {
        let errMsg = {};

        if (isEmptyString(title)) {
            errMsg['title'] = 'Title is required';
        }
        if (!isEmptyString(buttonText) && isEmptyString(buttonLink)) {
            errMsg['buttonLink'] = 'URL is required for button';
        } else if (!isEmptyString(buttonLink) && !isUrl(buttonLink)) {
            errMsg['buttonLink'] = 'Enter a valid URL';
        }
        if (isEmptyString(buttonText) && !isEmptyString(buttonLink)) {
            errMsg['buttonText'] = 'Button text is required';
        }

        return errMsg;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const validationError = getValidationError();

        if (Object.keys(validationError).length) {
            setValidationError(validationError);
        } else {
            props.addCard({
                imageData: imageData ? imageData.imgBlob : '',
                metaData: {
                    card_title: title,
                    card_header: header,
                    additional_text: additionalText,
                    button_text: buttonText,
                    button_link: buttonLink,
                },
            });
        }
    };

    return (
        <div className="add-edit-card">
            <div className="flex-middle">
                <IconButton aria-label="Go Back" onClick={onBack} edge="start">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" className="title">
                    Back to Calendar
                </Typography>
            </div>
            <Card style={{ minHeight: '300px' }}>
                <CardContent className="full-width">
                    <form noValidate autoComplete="off" onSubmit={onSubmit}>
                        <Grid xs={12} item container className="content" spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="title"
                                    label="Title"
                                    value={title}
                                    variant="outlined"
                                    fullWidth
                                    disabled={savingCard}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                    inputProps={{ maxLength: 128 }}
                                    error={Boolean(validationError['title'])}
                                    helperText={validationError['title']}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {visibility.header ? (
                                    <TextField
                                        name="header"
                                        label="Header"
                                        value={header}
                                        variant="outlined"
                                        fullWidth
                                        disabled={savingCard}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => enableSectionVisibility('header')}
                                    >
                                        + Add header text
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {_.isNull(imageData) ? (
                                    <Button color="primary" variant="outlined" onClick={uploadImageClick}>
                                        + Add Promo Image
                                    </Button>
                                ) : (
                                    <div className="promo-image-container">
                                        <div className="promo-label">Promo Image</div>
                                        <div className="delete-icon centered-content">
                                            <DeleteOutlineIcon onClick={deleteCroppedImage} />
                                        </div>
                                        <img alt="promo" style={{ maxWidth: '100%' }} src={imageData.imgDataUrl} />
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                {visibility.additionalText ? (
                                    <DPRichTextEditor
                                        updateText={(value) =>
                                            handleInputChange({ target: { name: 'additionalText', value } })
                                        }
                                    />
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => enableSectionVisibility('additionalText')}
                                    >
                                        + Add additional text
                                    </Button>
                                )}
                            </Grid>
                            {visibility.buttonText ? (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="buttonText"
                                            label="Button"
                                            value={buttonText}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                            inputProps={{ maxLength: 50 }}
                                            disabled={savingCard}
                                            error={Boolean(validationError['buttonText'])}
                                            helperText={validationError['buttonText']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="buttonLink"
                                            label="URL"
                                            value={buttonLink}
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                            disabled={savingCard}
                                            error={Boolean(validationError['buttonLink'])}
                                            helperText={validationError['buttonLink']}
                                        />
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => enableSectionVisibility('buttonText')}
                                    >
                                        + Add a button
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid spacing={2} container item xs={12} justify="flex-end" className="btn-container">
                            <Button
                                variant="text"
                                onClick={onCancelClick}
                                color="primary"
                                style={{ marginRight: '15px' }}
                            >
                                Cancel
                            </Button>
                            <div className="position-relative">
                                <Button variant="contained" color="primary" type="submit" disabled={savingCard}>
                                    Create Card
                                </Button>
                                {savingCard && <CircularProgress size={24} className="circular-icon-within-button" />}
                            </div>
                        </Grid>
                    </form>
                    {uploadingImage && (
                        <DpImageCropDialog
                            onClose={closeImageUploadDialog}
                            onComplete={onCropComplete}
                            minImageWidth={imageDimensions.minWidth}
                            minImageHeight={imageDimensions.minHeight}
                            minCropWidth={imageDimensions.minCropWidth}
                            minCropHeight={imageDimensions.minCropHeight}
                            maxCropHeight={imageDimensions.maxCropHeight}
                        />
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = ({ newsletter }) => ({
    ...newsletter,
});

const mapDispatchToProps = {
    setActiveCard: newsletterActions.setActiveCard,
    resetActiveCard: newsletterActions.resetActiveCard,
    addCard: newsletterActions.addCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCardSettings);
