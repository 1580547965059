import React from 'react';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import BuildIcon from '@material-ui/icons/Build';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import InfoIcon from '@material-ui/icons/Info';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralDetails } from './GeneralDetails';
import { BranchDetails } from './BranchDetails';
import { BuildDetails } from './BuildDetails';

const useStyles = makeStyles(() => ({
    listItem: {
        backgroundColor: '#fafafa',
    },
}));

export const AppDetails = ({ app, erroneousPanels, panels, editMode, handleTextChange, handleCheckboxValueChange }) => {
    const classes = useStyles();
    const [openedPanel, setOpenedPanel] = React.useState(panels.generalDetailsPanel);
    const togglePanel = (panel) => setOpenedPanel(openedPanel === panel ? null : panel);
    const openGeneralDetailsPanel = () => togglePanel(panels.generalDetailsPanel);
    const openBranchDetailsPanel = () => togglePanel(panels.branchDetailsPanel);
    const openBuildDetailsPanel = () => togglePanel(panels.buildDetailsPanel);
    const generalDetailsHasErrors = _.indexOf(erroneousPanels, panels.generalDetailsPanel) !== -1;
    const branchDetailsHasErrors = _.indexOf(erroneousPanels, panels.branchDetailsPanel) !== -1;

    const commonProps = {
        app,
        editMode,
        handleTextChange,
        handleCheckboxValueChange,
    };

    return (
        <List component="nav">
            <ListItem button onClick={openGeneralDetailsPanel} className={classes.listItem}>
                <ListItemIcon>
                    <Badge variant="dot" color="error" invisible={!generalDetailsHasErrors}>
                        <InfoIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary="General Details" />
                {openedPanel === panels.generalDetailsPanel ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openedPanel === panels.generalDetailsPanel} timeout="auto" unmountOnExit>
                <GeneralDetails panelId={panels.generalDetailsPanel} {...commonProps} />
            </Collapse>

            <ListItem button onClick={openBranchDetailsPanel} className={classes.listItem}>
                <ListItemIcon>
                    <Badge variant="dot" color="error" invisible={!branchDetailsHasErrors}>
                        <AcUnitIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary="Branch Details" />
                {openedPanel === panels.branchDetailsPanel ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openedPanel === panels.branchDetailsPanel} timeout="auto" unmountOnExit>
                <BranchDetails panelId={panels.branchDetailsPanel} {...commonProps} />
            </Collapse>

            <ListItem button onClick={openBuildDetailsPanel} className={classes.listItem}>
                <ListItemIcon>
                    <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Build Details" />
                {openedPanel === panels.buildDetailsPanel ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openedPanel === panels.buildDetailsPanel} timeout="auto" unmountOnExit>
                <BuildDetails panelId={panels.buildDetailsPanel} {...commonProps} />
            </Collapse>
        </List>
    );
};
