import _ from 'lodash';
import { createSelector } from 'reselect';

const isAuthorized = (state) => state.auth.isAuthorized;
const isAuthenticated = (state) => state.auth.isAuthenticated;
const getUserAccount = (state) => state.auth.userAccount;
const getUserDetails = (state) => state.auth.userDetails;
const getSelectedLocation = (state) => state.auth.selectedLocation;
const getSelectedCustomer = (state) => state.auth.selectedCustomer;
const getVowRedirectErrorLocation = (state) => state.auth.vowRedirectErrorLocation;

const getLoggedInUser = createSelector(
    [getUserAccount, getUserDetails, getSelectedLocation, getSelectedCustomer, isAuthenticated, isAuthorized],
    (account, userDetails, selectedLocation, selectedCustomer, isAuthenticated, isAuthorized) => {
        const details = _.find(
            userDetails,
            (data) =>
                data.entityType === 'Location' &&
                data.entityId === selectedLocation.id &&
                data.parentId === selectedCustomer.id
        );
        const userAccount = account || {};
        const user = {
            id: userAccount.user_id,
            email: userAccount.email,
            firstName: userAccount.first_name,
            lastName: userAccount.last_name,
            isAuthenticated: isAuthenticated,
            isAuthorized: isAuthorized,
            isFirstLogin: userAccount.is_firstLogin,
            isInternal: userAccount.is_internal_user,
            role: _.get(details, 'role', 'No Access'),
        };

        return user;
    }
);

export const authSelectors = {
    getLoggedInUser,
    getVowRedirectErrorLocation,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
