import _ from 'lodash';
import { get } from 'redux/actionCreators/rest';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { logHttpError } from 'utils/log';

export const getExternalIdentifier = (url, params, thirdPartyName) => (dispatch) => {
    const args = { params };
    const displayError = (idCount) => {
        if (idCount === 0) {
            dispatch(
                displayToast({
                    text: `No Matching ${thirdPartyName} ID Found!`,
                    type: 'error',
                })
            );
        } else if (idCount > 1) {
            dispatch(
                displayToast({
                    text: `Multiple ${thirdPartyName} ID Found!`,
                    type: 'error',
                })
            );
        }
    };

    dispatch(
        displayToast({
            text: `Querying For ${thirdPartyName} ID...`,
            type: 'info',
        })
    );

    return get(url, args)
        .then(({ results }) => {
            const idCount = results.length;
            if (idCount === 0 || idCount > 1) {
                displayError(idCount);
                return undefined;
            }
            return _.get(results, '[0].id');
        })
        .catch((error) => {
            logHttpError(`Error while fetching ${thirdPartyName} ID`, error);
            dispatch(
                displayToast({
                    text: `Failed To Fetch ${thirdPartyName} ID!`,
                    type: 'error',
                })
            );
        });
};
