import _ from 'lodash';
import moment from 'moment';
import config from '../../config';
import { logUtil } from 'utils/log';
import { restActionCreators } from 'redux/actionCreators/rest';
import api from '../../api.json';

const fetchWaitlists = (pharmacyId, locationId, waitlistName = 'coronavirus') => {
    const url = `${config.apiweb_url}${api.INBOX_WAITLIST}/pharmacies/${pharmacyId}/locations/${locationId}/waitlists/${waitlistName}/patient_count`;
    return restActionCreators
        .get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while fetching ${waitlistName} waitlist`, error);
            return Promise.reject(error);
        });
};

const downloadWaitlistCsv = (pharmacyId, locationId, waitlistName) => {
    const url = `${config.apiweb_url}${api.INBOX_WAITLIST}/pharmacies/${pharmacyId}/locations/${locationId}/waitlists/${waitlistName}/csv`;
    return restActionCreators
        .getBlobAndDownload({ url, filename: `${_.snakeCase(waitlistName)}_${moment().format()}.csv` })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while fetching ${waitlistName} waitlist`, error);
            return Promise.reject(error);
        });
};

const removeFromWaitlist = (pharmacyId, locationId, waitlistId, inbox_user_ids) => {
    const url = `${config.apiweb_url}${api.INBOX_WAITLIST}/pharmacies/${pharmacyId}/locations/${locationId}/waitlist/${waitlistId}/remove/inbox_user/bulk`;
    return restActionCreators
        .deleteReq(url, { inbox_user_ids }, { 'Content-Type': 'application/json' })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while removing patients from waitlist`, error);
            return Promise.reject(error);
        });
};

const updateWaitlistStatus = (pharmacyId, locationId, waitlistId, inbox_user_ids, status) => {
    const url = `${config.apiweb_url}${api.INBOX_WAITLIST}/pharmacies/${pharmacyId}/locations/${locationId}/waitlist/${waitlistId}/update/inbox_user/bulk`;
    return restActionCreators
        .post(url, { inbox_user_ids, desired_status: status })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while changing status of patients on waitlist`, error);
            return Promise.reject(error);
        });
};

export const waitlistService = {
    fetchWaitlists,
    downloadWaitlistCsv,
    removeFromWaitlist,
    updateWaitlistStatus,
};

// Note: do not add default exports, we'll be relying on the named export as best practices

// TODO this is imported into actions.js and consumed there
