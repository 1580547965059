import React from 'react';

const Description = ({ className }) => (
    <div className={`${className} md-text-justify`}>
        <p>
            Deliver pharmacist created interactive wellness classes to your patients online, in-store or in your
            community. Find everything you need, in your dashboard, to educate patients in weekly group classes or
            one-on-one for a completely personal learning experience.
        </p>
        <br />

        <p>Interactive Wellness Classes cover…</p>
    </div>
);

export default Description;
