import _ from 'lodash';
import reviewType from './type';
import reviewService from './service';
import Config from '../../config';
import { logError } from '../../utils/log';

const fetchAverage = () => (dispatch) => {
    dispatch({ type: reviewType.FETCH_AVERAGE_REQUEST });

    return reviewService
        .fetchAverage(Config.X_PharmacyID)
        .then((responseBody) => {
            dispatch({ type: reviewType.FETCH_AVERAGE_SUCCESS, payload: responseBody });
            return { responseBody };
        })
        .catch((error) => {
            dispatch({ type: reviewType.FETCH_AVERAGE_FAILURE, payload: { error } });
            return { error };
        });
};

const fetchSummary = () => (dispatch) => {
    dispatch({ type: reviewType.FETCH_SUMMARY_REQUEST });

    return reviewService
        .fetchSummary(Config.X_PharmacyID)
        .then((responseBody) => {
            if (_.get(responseBody, 'rows.data', false)) {
                dispatch({ type: reviewType.FETCH_SUMMARY_SUCCESS, payload: responseBody });
                return { responseBody };
            } else {
                const errorMessage = `Empty body response from fetchSummary for pharmacy ${Config.X_PharmacyID}`;
                const error = new Error(errorMessage);
                logError(errorMessage, error);
                throw error;
            }
        })
        .catch((error) => {
            dispatch({ type: reviewType.FETCH_SUMMARY_FAILURE, payload: { error } });
            return { error };
        });
};

let fetchListSignature = null;

const fetchList = (page) => (dispatch) => {
    const localFetchListSignature = new Date().getTime();
    fetchListSignature = localFetchListSignature;

    dispatch({ type: reviewType.FETCH_LIST_REQUEST });

    return reviewService
        .fetchList(Config.X_PharmacyID, page)
        .then((responseBody) => {
            if (localFetchListSignature === fetchListSignature) {
                if (_.get(responseBody, 'rows.data', false)) {
                    dispatch({ type: reviewType.FETCH_LIST_SUCCESS, payload: { data: responseBody, page: page } });
                    return { responseBody };
                } else {
                    const errorMessage = `Empty body response from fetchList for pharmacy ${Config.X_PharmacyID}`;
                    const error = new Error(errorMessage);
                    logError(errorMessage, error);
                    throw error;
                }
            }
        })
        .catch((error) => {
            if (localFetchListSignature === fetchListSignature) {
                dispatch({ type: reviewType.FETCH_LIST_FAILURE, payload: { error } });
                return { error };
            }
        });
};

export const reviewAction = {
    fetchAverage,
    fetchSummary,
    fetchList,
};

export default reviewAction;
