/*
    Note: Do not remove typePrefix, this will help avoid name collisions across modules
          Also, make sure that every action type in this file is prefixed with typePrefix
*/
const typePrefix = 'userManagement/';

export const userManagementTypes = {
    RESET_USER_MANAGEMENT_STORE: `${typePrefix}RESET_USER_MANAGEMENT_STORE`,

    SET_LOCAL_LOCATION: `${typePrefix}SET_LOCAL_LOCATION`,
    SET_USER_TYPE: `${typePrefix}SET_USER_TYPE`,

    SEARCH: `${typePrefix}SEARCH`,
    PAGINATE: `${typePrefix}PAGINATE`,
    SORT_USERS: `${typePrefix}SORT_USERS`,
    SEND_INVITE: `${typePrefix}SEND_INVITE`,
    CLEAR_SEARCH: `${typePrefix}CLEAR_SEARCH`,

    GET_USERS_REQUEST: `${typePrefix}GET_USERS_REQUEST`,
    GET_USERS_SUCCESS: `${typePrefix}GET_USERS_SUCCESS`,
    GET_USERS_FAILED: `${typePrefix}GET_USERS_FAILED`,

    DELETE_USER: `${typePrefix}DELETE_USER`,
    DELETE_USER_SUCCESS: `${typePrefix}DELETE_USER_SUCCESS`,
    DELETE_USER_FAILED: `${typePrefix}DELETE_USER_FAILED`,

    GET_USER_ROLES_REQUEST: `${typePrefix}GET_USER_ROLES_REQUEST`,
    GET_USER_ROLES_SUCCESS: `${typePrefix}GET_USER_ROLES_SUCCESS`,
    GET_USER_ROLES_FAILED: `${typePrefix}GET_USER_ROLES_FAILED`,

    ADD_OR_EDIT_USER_REQUEST: `${typePrefix}ADD_OR_EDIT_USER_REQUEST`,
    ADD_OR_EDIT_USER_SUCCESS: `${typePrefix}ADD_OR_EDIT_USER_SUCCESS`,
    ADD_OR_EDIT_USER_FAILED: `${typePrefix}ADD_OR_EDIT_USER_FAILED`,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
