import './ConfigureContent.sass';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Button, Radio, TextField, Tooltip } from '@material-ui/core';
import CalendarSelect from 'components/Appointments/CalendarSelect';
import AppointmentLengthSelect from 'components/Appointments/AppointmentLengthSelect';
import {
    getHowToScheduleConfigFromInboxConversationTemplate,
    getSchedulingMessagingFieldsFromInboxConversationTemplate,
} from './schedulingUtil';
import _ from 'lodash';
import { isUrl } from 'utils/helper';
import { templateAction } from '../../../redux/Inbox/Template';
import { connect } from 'react-redux';
import config from 'config';
import PhonePreview from './PhonePreview';
import MessageCustomizeAccordion from 'components/Appointments/MessageCustomizeAccordion';
import EventIcon from '@material-ui/icons/Event';
import { updateCalendar } from 'redux/Appt/Calendar/action';
import Snowplow, { StructEventCategories } from 'snowplow';
import { AppointmentWorkflowContext } from 'snowplow/contexts';
import { displayToast } from 'redux/actionCreators/Snackbar';

function ConfigureContent({ template }) {
    const props = arguments[0];

    const [selectedSection, setSelectedSection] = useState('how-to-schedule');
    const [schedulingConfig, setSchedulingConfig] = useState(
        getHowToScheduleConfigFromInboxConversationTemplate(template)
    );

    return (
        <div className="configure-content workflow-inner-content" style={{ height: `${window.innerHeight - 181}px` }}>
            <div className="left-bar">
                <div className="left-bar-heading">Configure Workflow</div>
                <div
                    className={classnames({ 'left-bar-option': true, selected: selectedSection === 'how-to-schedule' })}
                    onClick={() => setSelectedSection('how-to-schedule')}
                >
                    How to Schedule
                </div>
                <div
                    className={classnames({
                        'left-bar-option': true,
                        selected: selectedSection === 'messaging-templates',
                    })}
                    onClick={() => setSelectedSection('messaging-templates')}
                >
                    Messaging Templates
                </div>
            </div>
            <div className="main-configure-content">
                {selectedSection === 'how-to-schedule' ? (
                    <ConfigureAppointmentSchedulingContent
                        {...props}
                        schedulingConfig={schedulingConfig}
                        setSchedulingConfig={setSchedulingConfig}
                        onSave={() => {
                            if (selectedSection === 'how-to-schedule') {
                                // do this in a short timeout to avoid the accordion options flickering
                                setTimeout(() => setSelectedSection('messaging-templates'), 100);
                            }
                        }}
                    />
                ) : (
                    <ConfigureMessagingTemplatesContent {...props} schedulingConfig={schedulingConfig} />
                )}
            </div>
        </div>
    );
}

function ConfigureAppointmentSchedulingContent({
    template,
    calendars,
    schedulingConfig,
    setSchedulingConfig,
    onSave,
    updateTemplate,
}) {
    const schedulingType = _.get(schedulingConfig, 'scheduling_type');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Customize - How to schedule');
    }, []);

    const isSavable = isHowToScheduleConfigSavable(schedulingConfig, calendars);

    const setSchedulingType = (newSchedulingType) => {
        if (schedulingType !== newSchedulingType) {
            setSchedulingConfig({ ...schedulingConfig, scheduling_type: newSchedulingType });
        }
    };

    // check if we can find the configured calendar id on the currently active calendars since if we can not
    // this indicates that the calendar has probably been deleted.
    const selectedCalendarId = _.get(
        _.find(calendars, ({ calendar_id }) => calendar_id === schedulingConfig.appointment_calendar_id),
        'calendar_id'
    );

    return (
        <div className="configure-appointment-scheduling-content">
            <div className="heading">How would you like to schedule appointments?</div>

            <div
                className={classnames({ 'scheduling-option': true, selected: schedulingType === 'dp-clinical' })}
                onClick={() => setSchedulingType('dp-clinical')}
            >
                <div className="radio-area">
                    <Radio checked={schedulingType === 'dp-clinical'} color="primary" />
                </div>
                <div className="text">
                    <div className="main-line">Using Digital Pharmacist’s Clinical Appointment Scheduling</div>

                    <div className="sub-line">
                        Manage scheduling your appointments by messaging patients with appointment scheduling tools they
                        can use directly on their mobile device to populate a clinical appointment calendar provided by
                        Digital Pharmacist.
                    </div>

                    <div className="additional-configuration">
                        <div className="explain-config">
                            Please configure which calendar you would like these appointments to appear on and
                            appointment length to get started.
                        </div>
                        <div className="config-options">
                            <CalendarSelect
                                calendars={calendars}
                                value={selectedCalendarId}
                                onChange={function (e) {
                                    setSchedulingConfig({
                                        ...schedulingConfig,
                                        appointment_calendar_id: e.target.value,
                                    });
                                }}
                            />

                            <AppointmentLengthSelect
                                value={schedulingConfig.appointment_length_minutes}
                                onChange={function (e) {
                                    setSchedulingConfig({
                                        ...schedulingConfig,
                                        appointment_length_minutes: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={classnames({ 'scheduling-option': true, selected: schedulingType === '3rd-party' })}
                onClick={() => setSchedulingType('3rd-party')}
            >
                <div className="radio-area">
                    <Radio checked={schedulingType === '3rd-party'} color="primary" />
                </div>
                <div className="text">
                    <div className="main-line">By sending a 3rd party scheduling tool link</div>

                    <div className="additional-configuration">
                        <div className="explain-config">
                            If you are already using a 3rd party scheduling tool with a scheduling link, please provide
                            it below.
                        </div>
                        <div className="config-options">
                            <TextField
                                variant="outlined"
                                style={{ width: '640px' }}
                                placeholder="Link to schedule an appointment using my 3rd party tool"
                                value={schedulingConfig.third_party_scheduling_link}
                                onChange={(e) =>
                                    setSchedulingConfig({
                                        ...schedulingConfig,
                                        third_party_scheduling_link: _.trim(e.target.value),
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="buttons">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSaving || !isSavable}
                    onClick={() => {
                        setIsSaving(true);
                        const clone = _.cloneDeep(template);
                        clone.pharmacy_id = config.X_PharmacyID;
                        clone.location_id = config.X_LocationID;
                        const messaging_form = _.get(clone, 'message_content.messaging_form');
                        clone.message_content.messaging_form = {
                            ...messaging_form,
                            ...schedulingConfig,
                        };
                        updateTemplate(clone).then((response) => {
                            setIsSaving(false);

                            Snowplow.structEvent(
                                StructEventCategories.appointments,
                                'scheduling-workflow-how-to-schedule-updated',
                                [
                                    new AppointmentWorkflowContext()
                                        .setTemplateId(template.inbox_conversation_template_id)
                                        .setParentTemplateId(template.parent_template_id)
                                        .setCalendarId(schedulingConfig.appointment_calendar_id)
                                        .setAppointmentLength(schedulingConfig.appointment_length_minutes)
                                        .build(),
                                ]
                            );

                            onSave();
                        });
                    }}
                >
                    {isSaving ? 'Saving...' : 'Save'}
                </Button>
            </div>
        </div>
    );
}

function isHowToScheduleConfigSavable(schedulingConfig, activeCalendars) {
    const { scheduling_type } = schedulingConfig;

    if (scheduling_type === 'dp-clinical') {
        const { appointment_calendar_id, appointment_length_minutes } = schedulingConfig;

        return (
            !_.isNil(appointment_calendar_id) &&
            _.isFinite(appointment_length_minutes) &&
            !_.isNil(_.find(activeCalendars, ({ calendar_id }) => calendar_id === appointment_calendar_id))
        );
    } else if (scheduling_type === '3rd-party') {
        const { third_party_scheduling_link } = schedulingConfig;

        return _.isString(third_party_scheduling_link) && isUrl(third_party_scheduling_link);
    } else if (scheduling_type === 'manual') {
        return true;
    }

    return false;
}

const FIELD_KEY_LABELS = {
    waitlist_confirmation_text: 'Waitlist Confirmation Message',
    appointments_available_text: 'Appointments Available Message',
    scheduled_appointment_text: 'Scheduled Appointment Response Message',
    manual_appointments_available_text: 'Appointments Available Message',
    day_reminder_text: '24 Hour Before Reminder',
    hour_reminder_text: '1 Hour Before Check-in + Reminder',
    checkin_response_text: 'Check-in Response Message',
};
const CALENDAR_FIELDS = new Set(['day_reminder_text', 'hour_reminder_text', 'checkin_response_text']);
const HEADINGS = {
    'dp-clinical': 'via Clinical Appointment Scheduling',
    '3rd-party': 'via a 3rd party scheduling tool',
    manual: 'via manual scheduling',
};

function AccordionTitle({ calendar, label }) {
    return (
        <span className="accordion-title">
            {_.isNil(calendar) ? null : (
                <Tooltip
                    title={`Changes to this will affect all appointments on this calendar: ${_.get(calendar, 'name')}`}
                >
                    <EventIcon style={{ color: calendar.color }} />
                </Tooltip>
            )}
            {label}
        </span>
    );
}

function ConfigureMessagingTemplatesContent({
    template,
    auth,
    pharmacy,
    calendars,
    updateTemplate,
    updateCalendar,
    schedulingConfig,
    displayToast,
}) {
    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Customize - Messaging Templates');
    }, []);

    const { scheduling_type, appointment_calendar_id } = getHowToScheduleConfigFromInboxConversationTemplate(template);
    const templateMessagingFieldKeys = getSchedulingMessagingFieldsFromInboxConversationTemplate(template);
    const calendar =
        scheduling_type !== 'dp-clinical'
            ? null
            : _.find(calendars, ({ calendar_id }) => calendar_id === appointment_calendar_id);

    const messagingFieldKeys = [].concat(templateMessagingFieldKeys);
    if (scheduling_type === 'dp-clinical') {
        messagingFieldKeys.push(...['day_reminder_text', 'hour_reminder_text', 'checkin_response_text']);
    }

    const savedMessageTemplates = _.pick(
        { ..._.get(template, 'message_content.messaging_form'), ...calendar },
        messagingFieldKeys
    );

    const [focusedMessageKey, setFocusedMessageKey] = useState(_.first(messagingFieldKeys));
    const [messageTemplates, setMessageTemplates] = useState(savedMessageTemplates);
    const [isSaving, setIsSaving] = useState(false);

    const hasTemplateChanged = !_.isEqual(
        _.pick(savedMessageTemplates, templateMessagingFieldKeys),
        _.pick(messageTemplates, templateMessagingFieldKeys)
    );
    const hasCalendarChanged =
        scheduling_type !== 'dp-clinical'
            ? false
            : !_.isEqual(
                  _.pick(messageTemplates, Array.from(CALENDAR_FIELDS)),
                  _.pick(calendar, Array.from(CALENDAR_FIELDS))
              );

    return (
        <div className="configure-messaging-templates-content">
            <div className="messages-and-preview">
                <div className="message-accordions">
                    <div className="heading">
                        <div className="title">Customize messages to be sent</div>
                        <div className="scheduling-method">{HEADINGS[scheduling_type]}</div>
                    </div>

                    {_.map(messagingFieldKeys, (key) => {
                        return (
                            <MessageCustomizeAccordion
                                key={key}
                                expanded={focusedMessageKey === key}
                                onExpandChange={() => setFocusedMessageKey(key)}
                                title={
                                    <AccordionTitle
                                        calendar={CALENDAR_FIELDS.has(key) ? calendar : null}
                                        label={FIELD_KEY_LABELS[key]}
                                    />
                                }
                                value={_.get(messageTemplates, key, '')}
                                onChange={(value) => setMessageTemplates({ ...messageTemplates, [key]: _.trim(value) })}
                            />
                        );
                    })}
                    <div className="buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSaving || (!hasTemplateChanged && !hasCalendarChanged)}
                            onClick={() => {
                                if (!calendar) {
                                    displayToast({
                                        text: `Changes cannot be saved without a calendar selected in How To Schedule.`,
                                        type: 'error',
                                    });
                                    return;
                                }
                                setIsSaving(true);
                                const updateCalendarPromise = !hasCalendarChanged
                                    ? null
                                    : updateCalendar(
                                          calendar.calendar_id,
                                          {
                                              ...calendar,
                                              ..._.pick(messageTemplates, Array.from(CALENDAR_FIELDS)),
                                          },
                                          calendar
                                      );
                                let updateTemplatePromise = null;
                                if (hasTemplateChanged) {
                                    const clone = _.cloneDeep(template);
                                    clone.pharmacy_id = config.X_PharmacyID;
                                    clone.location_id = config.X_LocationID;
                                    const messaging_form = _.get(clone, 'message_content.messaging_form');
                                    Object.assign(messaging_form, _.pick(messageTemplates, templateMessagingFieldKeys));

                                    updateTemplatePromise = updateTemplate(clone);
                                }

                                Promise.allSettled(_.filter([updateCalendarPromise, updateTemplatePromise])).then(
                                    function () {
                                        setIsSaving(false);

                                        if (!_.isNil(updateTemplatePromise)) {
                                            Snowplow.structEvent(
                                                StructEventCategories.appointments,
                                                'scheduling-workflow-messaging-templates-updated',
                                                [
                                                    new AppointmentWorkflowContext()
                                                        .setTemplateId(template.inbox_conversation_template_id)
                                                        .setParentTemplateId(template.parent_template_id)
                                                        .setCalendarId(schedulingConfig.appointment_calendar_id)
                                                        .setAppointmentLength(
                                                            schedulingConfig.appointment_length_minutes
                                                        )
                                                        .build(),
                                                ]
                                            );
                                        }
                                    }
                                );
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>

                <div className="preview">
                    <div className="phone-heading">The conversation patients will see on their mobile device</div>
                    <PhonePreview
                        width={300}
                        height={550}
                        template={template}
                        getMessageText={(key) => messageTemplates[key]}
                        fieldKeys={messagingFieldKeys}
                        focusedMessage={focusedMessageKey}
                        auth={auth}
                        pharmacy={pharmacy}
                    />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { auth, pharmacy } = state;
    return {
        auth,
        pharmacy,
    };
}

const bindActionsToDispatch = {
    updateTemplate: templateAction.updateTemplate,
    updateCalendar,
    displayToast: displayToast,
};

export default connect(mapStateToProps, bindActionsToDispatch)(ConfigureContent);
