import './WorkflowTopBar.sass';
import React from 'react';
import { getTemplateImageUrl } from '../patientsUtil';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings';
import _ from 'lodash';
import ClipboardClockIcon from './ClipboardClockIcon';
import classnames from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import numeral from 'numeral';
import { getHowToScheduleConfigFromInboxConversationTemplate } from './schedulingUtil';

export default function WorkflowTopBar({ template, history, goBack, path, waitlistCount, isCovidWaitlistEnabled }) {
    const imageUrl = getTemplateImageUrl(template);

    if (_.isNil(template)) {
        return null;
    }

    const { scheduling_type } = getHowToScheduleConfigFromInboxConversationTemplate(template);

    const tabBackgroundStyles = {
        waitlist: {
            opacity: 0,
        },
        scheduling: null,
        appointments: {
            transform: 'translateX(calc(100% + 10px))',
        },
        customize: {
            transform:
                scheduling_type === '3rd-party' ? 'translateX(calc(100% + 10px))' : 'translateX(calc(200% + 20px))',
        },
    };

    return (
        <div className="workflow-top-bar">
            <span className="left">
                <Tooltip title="Go back to my workflows">
                    <IconButton className="back-button" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <img className="workflow-image" src={imageUrl} width={117} height={78} alt="workflow icon" />

                <div className="title-and-waitlist-or-invite">
                    <div className={classnames({ title: true, 'full-height': !isCovidWaitlistEnabled })}>
                        {_.get(template, 'title')}
                    </div>
                    {!_.get(template, 'message_content.messaging_form.has_waitlist') ? (
                        <Button>Invite patients</Button>
                    ) : !isCovidWaitlistEnabled ? null : (
                        <span>
                            <Button className="waitlist-button" disabled>
                                {numeral(waitlistCount).format('0,0')} patient waitlist
                            </Button>
                            <Button
                                className="manage-waitlist-link-button"
                                onClick={() => {
                                    history.push(`/workflows/${template.inbox_conversation_template_id}/waitlist`);
                                }}
                            >
                                manage waitlist
                            </Button>
                        </span>
                    )}
                </div>
            </span>

            <span className="right">
                <div className="tab-background" style={tabBackgroundStyles[path]} />
                <div
                    className={classnames({ tab: true, selected: path === 'scheduling' })}
                    onClick={() => history.push(`/workflows/${template.inbox_conversation_template_id}/scheduling`)}
                >
                    <ClipboardClockIcon />
                    <div className="label">Scheduling</div>
                </div>
                {scheduling_type === '3rd-party' ? null : (
                    <div
                        className={classnames({ tab: true, selected: path === 'appointments' })}
                        onClick={() =>
                            history.push(`/workflows/${template.inbox_conversation_template_id}/appointments`)
                        }
                    >
                        <EventIcon />
                        <div className="label">Appointments</div>
                    </div>
                )}
                <div
                    className={classnames({ tab: true, selected: path === 'customize' })}
                    onClick={() => history.push(`/workflows/${template.inbox_conversation_template_id}/customize`)}
                >
                    <SettingsIcon />
                    <div className="label">Customize</div>
                </div>
            </span>
        </div>
    );
}
