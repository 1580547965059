import _ from 'lodash';
import { createSelector } from 'reselect';

const getPharmacyDetails = (state) => state.pharmacy.pharmacy;

const getBannerConfig = createSelector([getPharmacyDetails], (pharmacy) => {
    const bannerConfig = _.get(pharmacy, 'service.Website', {});

    return {
        HealthsiteBannerEnabled: _.get(bannerConfig, 'HealthsiteBannerEnabled', false),
        HealthsiteBannerTitle: _.get(bannerConfig, 'HealthsiteBannerTitle', ''),
        HealthsiteBannerCTA: _.get(bannerConfig, 'HealthsiteBannerCTA', ''),
    };
});

export const bannerConfigSelectors = {
    getBannerConfig,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
