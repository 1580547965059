import React from 'react';
import Config2Constants from 'constants/Config2';

import Service from './Service';
import WithCustPackageAttribute from './CustPackageAttribute';

const title = 'Reputation Management Stats';
const description =
    'To have the stats show on the report page, activate ' +
    'this feature if the customer has been set up with ' +
    'reputation management for this location.';

const ReputationManagementWithBirdEye = (props) => <Service title={title} description={description} {...props} />;

export default WithCustPackageAttribute(ReputationManagementWithBirdEye, {
    package: {
        name: 'Reputation Management',
        code: 'RPMT',
        attribute: {
            name: 'birdEyeBusinessId',
            isLocationLevelAttr: true,
            dataType: Config2Constants.dataType.string,
            dialogBox: {
                label: 'Bird Eye Business ID',
                title: 'Enter the Bird Eye Business ID associated with this location.',
            },
        },
    },
    title,
    description,
});
