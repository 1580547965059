const DEFAULT_LAYOUT = {
    lg: [
        { i: 'digitalPlatform', x: 0, y: 0, w: 6, h: 12 },
        { i: 'valueCalculator', x: 6, y: 0, w: 6, h: 12 },
        { i: 'communicationsPlatform', x: 0, y: 6, w: 6, h: 12 },
        { i: 'mobileDownloads', x: 6, y: 6, w: 6, h: 12 },
        { i: 'websiteTraffic', x: 0, y: 12, w: 6, h: 12 },
        { i: 'newsletter', x: 6, y: 12, w: 6, h: 12 },
        { i: 'digitalPlatformPatients', x: 0, y: 18, w: 6, h: 12 },
        { i: 'digitalMarketing', x: 6, y: 18, w: 6, h: 12 },
        { i: 'reputationManagement', x: 0, y: 24, w: 6, h: 12 },
        { i: 'directMessaging', x: 6, y: 24, w: 6, h: 12 },
    ],
    xs: [
        { i: 'digitalPlatform', x: 0, y: 0, w: 7, h: 19 },
        { i: 'valueCalculator', x: 0, y: 10, w: 5, h: 19 },
        { i: 'communicationsPlatform', x: 0, y: 20, w: 7, h: 19 },
        { i: 'mobileDownloads', x: 0, y: 40, w: 5, h: 19 },
        { i: 'websiteTraffic', x: 0, y: 80, w: 6, h: 19 },
        { i: 'newsletter', x: 0, y: 160, w: 3, h: 19 },
        { i: 'digitalPlatformPatients', x: 0, y: 320, w: 3, h: 19 },
        { i: 'digitalMarketing', x: 0, y: 640, w: 6, h: 19 },
        { i: 'reputationManagement', x: 0, y: 1280, w: 6, h: 19 },
        { i: 'directMessaging', x: 0, y: 2560, w: 6, h: 19 },
    ],
};

const LAYOUT_MAP = {
    0: 'digitalPlatform',
    1: 'valueCalculator',
    2: 'communicationsPlatform',
    3: 'mobileDownloads',
    4: 'websiteTraffic',
    5: 'newsletter',
    6: 'digitalPlatformPatients',
    7: 'digitalMarketing',
    8: 'reputationManagement',
    9: 'directMessaging',
};

export { DEFAULT_LAYOUT, LAYOUT_MAP };
