import React, { PureComponent, Fragment } from 'react';
import { Card, Cell, Divider, Grid, CardText } from 'react-md'; // TODO migrate to material-ui
import { connect } from 'react-redux';
import { CardHeader } from 'components/Common';
import _ from 'lodash';

import goBackService from 'utils/goBackService';
import CallRailsTracking from './CallRailsTracking';
import DigitalMarketing from './DigitalMarketing';
import DigitalMarketingDashboard from './DigitalMarketingDashboard';
import WellnessClasses from './WellnessClasses';
import NewsLetters from './NewsLetters';
import NewPatientCallTracking from './NewPatientCallTracking';
import NewPatientCallRecording from './NewPatientCallRecording';
import ReputationManagement from './ReputationManagement';
import AutoAttendant from './AutoAttendant';
import AdditionalLang from './AdditionalLang';
import FullPepToggle from './FullPepToggle';
import BasicMessaging from './BasicMessaging';

export class FeaturesAndFunctions extends PureComponent {
    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    render() {
        const {
            auth: { isInternalUser },
            flags: { npeAutoAttendant, npeCallRecordingActivation },
        } = this.props;
        let components = [
            WellnessClasses,
            NewsLetters,
            ReputationManagement,
            DigitalMarketing,
            DigitalMarketingDashboard,
            npeAutoAttendant && AutoAttendant,
            AdditionalLang,
            CallRailsTracking,
            NewPatientCallTracking,
            npeCallRecordingActivation && NewPatientCallRecording,
            BasicMessaging,
            FullPepToggle,
        ];
        components = _.filter(components, (component) => component);

        return (
            <Grid>
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Features and Functions"
                            subtitle="These items are for our internal users only. They turn features on and off and will update config 2 as appropriate. They take effect immediately so please use care when activating or deactivating these items."
                            onBack={this.onBack}
                        />
                        <CardText>
                            {isInternalUser && (
                                <Card className="inner-card">
                                    <CardText>
                                        {components.map((Component, index) => (
                                            <Fragment key={`feature-${index}`}>
                                                <Component />
                                                {index < components.length - 1 ? <Divider /> : ''}
                                            </Fragment>
                                        ))}
                                    </CardText>
                                </Card>
                            )}
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    flags: state.launchDarkly,
});

export default connect(mapStateToProps, null)(FeaturesAndFunctions);
