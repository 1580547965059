import React, { Component, Fragment } from 'react';
import { Grid, Cell, Card, CardText, CircularProgress, Button } from 'react-md';
import { connect } from 'react-redux';
import { TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import { CardHeader, SectionTitle, SectionSubTitle } from 'components/Common/index';
import goBackService from 'utils/goBackService';

import {
    fetchGreetingWithMainMenu,
    fetchMainMenu,
    setMainMenuChanges,
    addMainMenuItem,
    saveMainMenu,
    removeMenuItem,
    deleteMenuItem,
} from 'redux/actionCreators/Settings/AAMainMenu';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { PhoneNumberMask } from 'utils/mask';
import { toDigits } from 'utils/helper';
import { Can, userActions, restrictedResources } from 'casl';

const AA_MENU_LIMIT = 9;

export class AAMainMenu extends Component {
    constructor(props) {
        super(props);
        this.timeoutList = [15, 30, 45, 60];
        this.mainMenuItemsToSave = [];
    }

    componentDidMount() {
        const {
            fetchGreetingWithMainMenu,
            auth: { selectedLocation },
        } = this.props;
        fetchGreetingWithMainMenu(selectedLocation.perms);
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toIVRHome(history);
    };

    componentDidUpdate(prevProps) {
        const { auth, fetchGreetingWithMainMenu } = this.props;
        if (auth.selectedLocation && prevProps.auth.selectedLocation.id !== auth.selectedLocation.id) {
            fetchGreetingWithMainMenu(auth.selectedLocation.perms);
        }
    }

    handleChange = (e, idx, keyName) => {
        const {
            aaMainMenu: { mainMenuItems },
            setMainMenuChanges,
        } = this.props;
        mainMenuItems[idx][keyName] = e.target.value;

        mainMenuItems[idx][`${keyName}Error`] = '';
        if (keyName === 'phone' && !e.target.value) {
            mainMenuItems[idx][`${keyName}Error`] = 'Phone required';
        }

        if (keyName === 'transferto' && e.target.value === 'phone' && mainMenuItems[idx]['maxSeconds'] === 0) {
            mainMenuItems[idx]['maxSeconds'] = 15;
        }
        setMainMenuChanges({ mainMenuItems });
    };

    deleteMenuItem = (details) => {
        const {
            removeMenuItem,
            deleteMenuItem,
            mainGreeting: { defaultGreeting },
        } = this.props;

        if (details.menuItem.id === '0') {
            removeMenuItem(details);
        } else {
            deleteMenuItem({ ...details, id: defaultGreeting.id });
        }
    };

    addMenuItem = () => {
        const {
            addMainMenuItem,
            aaMainMenu: { mainMenuItems },
        } = this.props;
        if (mainMenuItems.length < AA_MENU_LIMIT) {
            addMainMenuItem();
        }
    };

    validate = () => {
        const {
            displayToast,
            aaMainMenu: { mainMenuItems },
            setMainMenuChanges,
        } = this.props;
        const selKeys = [];
        let transferToPharmacyCnt = 0;
        let isValid = mainMenuItems.length > 0;
        this.mainMenuItemsToSave = [];
        let errToastMsg = 'Fix the issues to save changes';

        if (mainMenuItems.length === 0) {
            errToastMsg = 'No records to save';
        }

        for (let grpIdx = 0; grpIdx < mainMenuItems.length; grpIdx++) {
            if (mainMenuItems[grpIdx].key === '') {
                mainMenuItems[grpIdx].keyError = 'Select Keypad number';
                isValid = false;
            } else if (selKeys.indexOf(mainMenuItems[grpIdx].key) > -1) {
                mainMenuItems[grpIdx].keyError = 'Keypad number already selected';
                isValid = false;
            } else if (mainMenuItems[grpIdx].transferto === 'phone' && mainMenuItems[grpIdx].phone === '') {
                mainMenuItems[grpIdx].phoneError = 'Phone required';
                isValid = false;
            } else if (
                mainMenuItems[grpIdx].transferto === 'phone' &&
                toDigits(mainMenuItems[grpIdx].phone).length < 10
            ) {
                mainMenuItems[grpIdx].phoneError = 'Enter a 10 digit Phone number';
                isValid = false;
            } else if (mainMenuItems[grpIdx].transferto === 'ivrmenu') {
                transferToPharmacyCnt++;
            }
            selKeys.push(mainMenuItems[grpIdx].key);

            this.mainMenuItemsToSave.push({ ...mainMenuItems[grpIdx] });
            this.mainMenuItemsToSave[grpIdx].seq = mainMenuItems[grpIdx].key;
            this.mainMenuItemsToSave[grpIdx].phone =
                mainMenuItems[grpIdx].transferto === 'ivrmenu' ? null : toDigits(mainMenuItems[grpIdx].phone);
            this.mainMenuItemsToSave[grpIdx].maxSeconds =
                mainMenuItems[grpIdx].transferto === 'ivrmenu' ? 0 : mainMenuItems[grpIdx].maxSeconds;
        }

        if (isValid && transferToPharmacyCnt > 1) {
            isValid = false;
            errToastMsg = 'There has to be exactly one entry for navigation Pharmacy Menu';
        }

        if (!isValid) {
            setMainMenuChanges({ mainMenuItems });
            displayToast({ text: errToastMsg, type: 'error' });
        }

        return isValid;
    };

    onSave = () => {
        const {
            saveMainMenu,
            fetchMainMenu,
            mainGreeting: { defaultGreeting },
        } = this.props;

        if (this.validate()) {
            saveMainMenu({ id: defaultGreeting.id, mainMenuItems: this.mainMenuItemsToSave }).then((saveResp) => {
                if (!('err' in saveResp)) {
                    fetchMainMenu(defaultGreeting);
                }
            });
        }
    };

    render() {
        const {
            aaMainMenu: { mainMenuItems, mainMenuItemsLoading, savingInProgress },
        } = this.props;
        const { write } = userActions;
        const { subject: caslSubject } = restrictedResources.ivr.generalMenuOptions;

        // TODO: This component should be refactored
        return (
            <Grid className="main-menu-container">
                {/* Note, write here means the user role can create, update, delete general menu options */}
                <Can I={write} this={caslSubject} passThrough>
                    {(writeAllowed) => (
                        <Cell size={12}>
                            <Card className="outer-card">
                                <CardHeader
                                    title="General Menu Options"
                                    disabled={mainMenuItemsLoading || savingInProgress}
                                    loading={savingInProgress}
                                    onBack={this.onBack}
                                    onSave={writeAllowed && this.onSave}
                                />
                                <CardText>
                                    <Card className="inner-card">
                                        <CardText>
                                            <SectionTitle text="Configure Your Menu Options" />
                                            <SectionSubTitle
                                                text="This is what will be heard when patients first call.
                                    Make sure to include the options they can select with their keypad."
                                            />
                                            {mainMenuItemsLoading ? (
                                                <CircularProgress id="main-menu-spinner" />
                                            ) : (
                                                <div className="aa-main-menu-container">
                                                    {mainMenuItems.map((menuItem, idx) => (
                                                        <div key={idx} style={{ marginTop: idx > 0 ? 0 : '14px' }}>
                                                            <div className="grid-container">
                                                                <Cell size={3} className="keypad">
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <InputLabel htmlFor="outlined-keypad">
                                                                            Selection
                                                                        </InputLabel>
                                                                        <Select
                                                                            id="select-keypad"
                                                                            value={menuItem.key}
                                                                            disabled={!writeAllowed || savingInProgress}
                                                                            onChange={(e) =>
                                                                                this.handleChange(e, idx, 'key')
                                                                            }
                                                                            error={
                                                                                'keyError' in menuItem &&
                                                                                menuItem.keyError !== ''
                                                                            }
                                                                            input={
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    labelWidth={60}
                                                                                    name="keypad"
                                                                                    id="outlined-keypad"
                                                                                />
                                                                            }
                                                                        >
                                                                            {[...Array(AA_MENU_LIMIT).keys()].map(
                                                                                (tVal, tIdx) => (
                                                                                    <MenuItem
                                                                                        key={`keypad${tVal + 1}`}
                                                                                        value={tVal + 1}
                                                                                    >{`Presses ${tVal + 1}`}</MenuItem>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Cell>

                                                                <Cell size={3} className="transferto">
                                                                    <FormControl variant="outlined" fullWidth>
                                                                        <InputLabel htmlFor="outlined-transferto">
                                                                            Transfer to
                                                                        </InputLabel>
                                                                        <Select
                                                                            id="select-seconds"
                                                                            value={menuItem.transferto}
                                                                            disabled={!writeAllowed || savingInProgress}
                                                                            onChange={(e) =>
                                                                                this.handleChange(e, idx, 'transferto')
                                                                            }
                                                                            input={
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    labelWidth={70}
                                                                                    name="transferto"
                                                                                    id="outlined-transferto"
                                                                                />
                                                                            }
                                                                        >
                                                                            <MenuItem
                                                                                key={`transfertonumber`}
                                                                                value={'phone'}
                                                                            >
                                                                                Phone Number
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                key={`transfertoivr`}
                                                                                value={'ivrmenu'}
                                                                            >
                                                                                Pharmacy Menu
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Cell>

                                                                {menuItem.transferto === 'phone' && (
                                                                    <Fragment>
                                                                        <Cell size={3} className="phoneno">
                                                                            <TextField
                                                                                id="outlined-pno"
                                                                                name="phone"
                                                                                label="Phone Number"
                                                                                disabled={
                                                                                    !writeAllowed || savingInProgress
                                                                                }
                                                                                value={
                                                                                    menuItem.phone.length > 10
                                                                                        ? menuItem.phone.substring(1)
                                                                                        : menuItem.phone
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleChange(e, idx, 'phone')
                                                                                }
                                                                                variant="outlined"
                                                                                InputProps={{
                                                                                    inputComponent: PhoneNumberMask,
                                                                                }}
                                                                                error={
                                                                                    'phoneError' in menuItem &&
                                                                                    menuItem.phoneError !== ''
                                                                                }
                                                                                fullWidth
                                                                            />
                                                                        </Cell>

                                                                        <Cell size={2} className="timeout">
                                                                            <FormControl variant="outlined" fullWidth>
                                                                                <InputLabel htmlFor="outlined-timeout">
                                                                                    Timeout
                                                                                </InputLabel>
                                                                                <Select
                                                                                    id="select-seconds"
                                                                                    value={menuItem.maxSeconds}
                                                                                    disabled={
                                                                                        !writeAllowed ||
                                                                                        savingInProgress
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            idx,
                                                                                            'maxSeconds'
                                                                                        )
                                                                                    }
                                                                                    input={
                                                                                        <OutlinedInput
                                                                                            fullWidth
                                                                                            labelWidth={55}
                                                                                            name="timeout"
                                                                                            id="outlined-timeout"
                                                                                        />
                                                                                    }
                                                                                >
                                                                                    {this.timeoutList.map(
                                                                                        (tVal, tIdx) => (
                                                                                            <MenuItem
                                                                                                key={`timeout${tIdx}`}
                                                                                                value={tVal}
                                                                                            >{`${tVal} sec`}</MenuItem>
                                                                                        )
                                                                                    )}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Cell>
                                                                    </Fragment>
                                                                )}

                                                                <Cell size={1} className="flex-middle">
                                                                    {writeAllowed &&
                                                                        (menuItem.processing ? (
                                                                            <CircularProgress id="remove-aa-menu-item-spinner" />
                                                                        ) : (
                                                                            <Button
                                                                                className="delete-menu-btn"
                                                                                icon
                                                                                onClick={() =>
                                                                                    this.deleteMenuItem({
                                                                                        menuItem,
                                                                                        idx,
                                                                                    })
                                                                                }
                                                                            >
                                                                                delete
                                                                            </Button>
                                                                        ))}
                                                                </Cell>
                                                            </div>
                                                            <FormHelperText error className="form-error">
                                                                &nbsp;{menuItem.phoneError || menuItem.keyError}
                                                            </FormHelperText>
                                                        </div>
                                                    ))}

                                                    {mainMenuItems.length === 0 && (
                                                        <div className="grid-container">
                                                            <Cell
                                                                size={12}
                                                                className="centered-content no-aa-main-menu-msg"
                                                            >
                                                                No Menu Item Configured
                                                            </Cell>
                                                        </div>
                                                    )}

                                                    {writeAllowed ? (
                                                        mainMenuItems.length < AA_MENU_LIMIT && (
                                                            <div className="grid-container add-line-btn">
                                                                <div className="grid-cell-12 button-control">
                                                                    <Button
                                                                        className="add-btn"
                                                                        flat
                                                                        secondary
                                                                        onClick={this.addMenuItem}
                                                                    >
                                                                        + ADD ANOTHER LINE
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="grid-container">
                                                            <Cell size={12} className="centered-content">
                                                                You don't have permission to add/update Main Menu{' '}
                                                            </Cell>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </CardText>
                                    </Card>
                                </CardText>
                            </Card>
                        </Cell>
                    )}
                </Can>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    mainGreeting: state.settings.mainGreeting,
    aaMainMenu: state.settings.aaMainMenu,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGreetingWithMainMenu: () => dispatch(fetchGreetingWithMainMenu()),
    fetchMainMenu: (data) => dispatch(fetchMainMenu(data)),
    setMainMenuChanges: (data) => dispatch(setMainMenuChanges(data)),
    addMainMenuItem: () => dispatch(addMainMenuItem()),
    saveMainMenu: (data) => dispatch(saveMainMenu(data)),
    removeMenuItem: (data) => dispatch(removeMenuItem(data)),
    deleteMenuItem: (data) => dispatch(deleteMenuItem(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AAMainMenu);
