import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Note to wrap this in <Grid className="settings-content--grid-container">
export const SettingTitle = ({ title, subtitle, onBack }) => (
    <div className="flex-vertical-space-between title-wrapper">
        <div className="flex-middle">
            {onBack && (
                <IconButton aria-label="Go Back" onClick={onBack} edge="start" className="back-arrow--button-icon">
                    <ArrowBackIcon />
                </IconButton>
            )}
            <Typography variant="h5" className="title">
                {title}
            </Typography>
        </div>
        {subtitle && (
            <div className="flex-middle">
                <Typography variant="subtitle1" className="subtitle" style={{ marginLeft: onBack ? '44px' : '0px' }}>
                    {subtitle}
                </Typography>
            </div>
        )}
    </div>
);

SettingTitle.propTypes = {
    title: PropTypes.string.isRequired,
    onBack: PropTypes.func,
    subtitle: PropTypes.string,
};
