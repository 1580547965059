import _ from 'lodash';

import { newsletterTypes } from './type';
import { newsletterService } from './service';
import SnackbarActions from '../actions/Snackbar';
import { getPharmacyPackage, getPharmacyPackageAttributeByName } from 'utils/helper';
import { pharmacyAction } from 'redux/Pharmacy/action';
import { DRAWER_STATES } from 'constants/Newsletter';
import Config from 'config';
import Snowplow, { StructEventCategories } from 'snowplow';

const resetNewsletterStore = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_NEWSLETTER_STORE });
};

const saveNewsletterSettings = (config) => async (dispatch, getState) => {
    dispatch({ type: newsletterTypes.SAVE_NEWSLETTER_SETTINGS_REQUEST });

    const pharmacy = getState().pharmacy.pharmacy;
    const newsletterPackage = getPharmacyPackage('NSLT', pharmacy);
    let newsletterPackageAttributes = null;
    const cust = {
        id: pharmacy.id,
        packageID: newsletterPackage.id,
    };
    const multipleCalls = [];
    let newSenderName,
        newSenderEmailOverride = null;

    for (const key of _.keys(config)) {
        // name and email must be set via a specific endpoint on the Newsletters service
        if (key === 'senderName') {
            newSenderName = config[key];
            continue;
        } else if (key === 'senderEmailOverride') {
            newSenderEmailOverride = config[key];
            continue;
        }

        // check if attribute present or not
        const attr = getPharmacyPackageAttributeByName(newsletterPackage.properties, key);
        if (attr) {
            // put req
            const payload = {
                id: attr.id,
                value: config[key],
                statusTypeId: attr.statusId,
            };
            multipleCalls.push(newsletterService.updateCustPackageAttribute(cust, payload));
        } else {
            //post req
            if (!newsletterPackageAttributes) {
                newsletterPackageAttributes = await newsletterService
                    .getPackageAttributes(newsletterPackage.pkgId)
                    .catch(() => {
                        dispatch({ type: newsletterTypes.SAVE_NEWSLETTER_SETTINGS_FAILED });
                        dispatch({
                            type: SnackbarActions.SHOW_TOAST,
                            data: {
                                text: 'Failed to save some newsletter settings!',
                                type: 'error',
                            },
                        });
                    });
            }
            if (newsletterPackageAttributes) {
                const attr = _.find(newsletterPackageAttributes, (a) => a.name === key);
                const payload = {
                    dataTypeId: attr.dataTypeId,
                    packageAttrId: attr.id,
                    value: config[key],
                };
                multipleCalls.push(newsletterService.addCustPackageAttribute(cust, payload));
            }
        }
    }

    if (newSenderName || newSenderEmailOverride) {
        multipleCalls.push(
            newsletterService.updateSettings({
                pharmacy_id: pharmacy.id,
                from_name: newSenderName,
                from_email: newSenderEmailOverride,
            })
        );
    }

    if (multipleCalls.length) {
        Promise.all(multipleCalls)
            .then(() => {
                dispatch({ type: newsletterTypes.SAVE_NEWSLETTER_SETTINGS_SUCCESS });
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: 'Newsletter settings saved.',
                        type: 'success',
                    },
                });
                dispatch(pharmacyAction.getPharmacy());
            })
            .catch((err) => {
                const errorText = _.get(err, 'response.data.detail', 'Failed to save newsletter settings!');
                dispatch({ type: newsletterTypes.SAVE_NEWSLETTER_SETTINGS_FAILED });
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: errorText,
                        type: 'error',
                    },
                });
            });
    }
};

const setDrawerState = (state) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_DRAWER_STATE, payload: { state } });
};

const setActiveSubscriber = (subscriber) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_ACTIVE_SUBSCRIBER, payload: { activeSubscriber: subscriber } });
};

const resetActiveSubscriber = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_ACTIVE_SUBSCRIBER });
};

const saveSubscriber = (payload) => (dispatch) => {
    dispatch({ type: newsletterTypes.ADD_SUBSCRIBER_REQUEST });

    return newsletterService
        .saveSubscriber(payload)
        .then((response) => {
            if (response.success === false) {
                throw new Error('Server returned error');
            }
            return response;
        })
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Subscriber saved',
                    type: 'success',
                },
            });
            dispatch({ type: newsletterTypes.ADD_SUBSCRIBER_SUCCESS });
            return { success: true };
        })
        .catch((err) => {
            const errorText = _.get(err, 'response.data.detail', 'Failed to save subscriber!');
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: errorText,
                    type: 'error',
                },
            });
            dispatch({ type: newsletterTypes.ADD_SUBSCRIBER_FAILED });
            return { success: false };
        });
};

const removeSubscriber = (subscriberId) => (dispatch) => {
    dispatch({ type: newsletterTypes.DELETE_SUBSCRIBER_REQUEST, payload: subscriberId });

    return newsletterService
        .removeSubscriber(subscriberId)
        .then((response) => {
            if (response.success === false) {
                throw new Error('Server returned error');
            }
            return response;
        })
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Subscriber removed successfully!',
                    type: 'success',
                },
            });
            dispatch(fetchSubscriberList());
            Snowplow.structEvent(StructEventCategories.newsletters, 'removed-subscriber');
        })
        .catch((err) => {
            dispatch({ type: newsletterTypes.DELETE_SUBSCRIBER_FAILED, payload: subscriberId });
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to remove subscriber!',
                    type: 'error',
                },
            });
            Snowplow.structEvent(
                StructEventCategories.newsletters,
                'error-removing-subscriber',
                null,
                'error',
                'exception',
                err
            );
        });
};

const changePage = (page) => (dispatch, getState) => {
    const state = {
        ...getState().newsletter.subscribersListState,
        page: page,
    };
    dispatch({ type: newsletterTypes.UPDATE_NEWSLETTER_SUBSCRIBER_STATE, payload: state });
    dispatch(fetchSubscriberList());
};

const changeSearch = (searchString) => (dispatch, getState) => {
    const state = {
        ...getState().newsletter.subscribersListState,
        searchString: searchString,
    };
    dispatch({ type: newsletterTypes.UPDATE_NEWSLETTER_SUBSCRIBER_STATE, payload: state });
    dispatch(fetchSubscriberList());
};

const fetchSubscriberList = () => (dispatch, getState) => {
    const state = getState().newsletter.subscribersListState;
    const requestParams = {
        params: {
            page_number: state.page,
            page_size: state.rowsPerPage,
            filter_by_email: state.searchString,
        },
    };

    dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_REQUEST });

    newsletterService
        .getSubscriberList(requestParams, Config.X_PharmacyID)
        .then((resp) => {
            dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_SUCCESS, payload: resp });
        })
        .catch((err) => {
            if (err.message !== 'cancel') {
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: 'Failed to get subscribers!',
                        type: 'error',
                    },
                });
                dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_SUBSCRIBER_FAILED });
            }
        });
};

const fetchHistoryList = ({ page, rowsPerPage }) => (dispatch) => {
    const configObj = { params: { page_number: page, page_size: rowsPerPage } };
    dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_HISTORY_REQUEST });

    newsletterService
        .getHistoryList(configObj)
        .then((resp) => {
            dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_HISTORY_SUCCESS, payload: resp });
        })
        .catch((err) => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to get historical data!',
                    type: 'error',
                },
            });
            dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_HISTORY_FAILED });
        });
};

const setActiveEvent = (event) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_ACTIVE_EVENT, payload: { activeEvent: event } });
};

const resetActiveEvent = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_ACTIVE_EVENT });
};

const setActiveCard = (cardData = {}) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_ACTIVE_CARD, payload: { activeCard: cardData } });
};

const resetActiveCard = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_ACTIVE_CARD });
    dispatch(setDrawerState(DRAWER_STATES.EDIT_NEWSLETTER));
};

const setActiveNewsletter = (newsletterData = {}) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_ACTIVE_NEWSLETTER, payload: { activeNewsletter: newsletterData } });
};

const resetActiveNewsletter = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_ACTIVE_NEWSLETTER });
};

const fetchCards = (payload) => (dispatch) => {
    const isSearchResults = _.get(payload, 'filter_by_title', '').length > 2;
    const isLoadMore = payload.loadMore;

    dispatch({ type: newsletterTypes.FETCH_CARDS_REQUEST, payload: { isSearchRequest: isSearchResults, isLoadMore } });

    delete payload.loadMore;

    newsletterService
        .getCards(payload)
        .then((resp) => {
            dispatch({
                type: newsletterTypes.FETCH_CARDS_SUCCESS,
                payload: {
                    isSearchResults,
                    isLoadMore,
                    response: {
                        ...resp,
                    },
                },
            });
        })
        .catch((err) => {
            if (err.message !== 'cancel_request') {
                dispatch({ type: newsletterTypes.FETCH_CARDS_FAILED });
            }
        });
};

const resetCards = () => (dispatch) => {
    dispatch({ type: newsletterTypes.RESET_CARDS });
};

const fetchNewsletters = (payload) => (dispatch) => {
    dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_REQUEST, payload: { isRefetch: Boolean(payload.isRefetch) } });

    delete payload.isRefetch;
    const { start_date, end_date } = payload;
    const configObj = { params: { start_date, end_date } };

    newsletterService
        .getNewsletters(configObj)
        .then((resp) => {
            dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_SUCCESS, payload: { resp, dateRange: payload } });
        })
        .catch((err) => {
            if (err.message !== 'cancel') {
                dispatch({
                    type: SnackbarActions.SHOW_TOAST,
                    data: {
                        text: 'Failed to get newsletters',
                        type: 'error',
                    },
                });
                dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_FAILED, payload: err });
            }
        });
};

const getNewsletterDetails = (newsletter) => (dispatch) => {
    dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_DETAILS_REQUEST });
    newsletterService
        .getNewsletterDetails(newsletter.email_message_id)
        .then((resp) => {
            dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_DETAILS_SUCCESS, payload: { ...newsletter, ...resp } });
        })
        .catch((err) => {
            if (err.message !== 'cancel') dispatch({ type: newsletterTypes.FETCH_NEWSLETTER_DETAILS_FAILED });
        });
};

const deleteCard = (card_id) => (dispatch) => {
    dispatch({ type: newsletterTypes.SET_DELETE_CARD_STATE, payload: { state: 'fetching' } });

    newsletterService
        .deleteCard(card_id)
        .then((resp) => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Card deleted successfully',
                    type: 'success',
                },
            });
            dispatch({ type: newsletterTypes.SET_DELETE_CARD_STATE, payload: { state: 'success' } });
        })
        .catch((err) => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Error deleting card',
                    type: 'error',
                },
            });
            dispatch({ type: newsletterTypes.SET_DELETE_CARD_STATE, payload: { state: 'error' } });
        });
};

const addCard = (cardDetails) => (dispatch) => {
    dispatch({ type: newsletterTypes.ADD_CARDS_REQUEST });

    const formData = new FormData();
    formData.append('file_blob', cardDetails.imageData);
    formData.append('meta_data', JSON.stringify(cardDetails.metaData));

    newsletterService
        .addCard(formData)
        .then((resp) => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Card created successfully',
                    type: 'success',
                },
            });
            dispatch({ type: newsletterTypes.ADD_CARDS_SUCCESS, payload: resp });
            dispatch(resetActiveCard());
        })
        .catch((err) => {
            const errMsg =
                err.response.data && err.response.data.detail ? err.response.data.detail : 'Failed to create card';
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: errMsg,
                    type: 'error',
                },
            });
            dispatch({ type: newsletterTypes.ADD_CARDS_FAILED });
        });
};

const saveEventDetails = (payload) => async (dispatch) => {
    dispatch({ type: newsletterTypes.SAVE_EVENT_DETAILS_REQUEST });
    try {
        const resp = await newsletterService.saveEventDetails(payload);
        dispatch({ type: newsletterTypes.SAVE_EVENT_DETAILS_SUCCESS, payload: { ...resp } });
        return Promise.resolve();
    } catch (err) {
        dispatch({ type: newsletterTypes.SAVE_EVENT_DETAILS_FAILED });
        return Promise.reject(err);
    }
};

export const newsletterActions = {
    resetNewsletterStore,
    saveNewsletterSettings,
    setDrawerState,
    setActiveSubscriber,
    resetActiveSubscriber,
    saveSubscriber,
    removeSubscriber,
    fetchSubscriberList,
    setActiveEvent,
    resetActiveEvent,
    setActiveCard,
    resetActiveCard,
    fetchHistoryList,
    setActiveNewsletter,
    resetActiveNewsletter,
    fetchCards,
    addCard,
    deleteCard,
    fetchNewsletters,
    getNewsletterDetails,
    saveEventDetails,
    resetCards,
    changePage,
    changeSearch,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
