import './ConnectCardGrid.sass';
import React, { useState, useEffect, useRef } from 'react';
import { AutoSizer } from 'react-virtualized';
import _ from 'lodash';
import classnames from 'classnames';
import ConnectCard from './ConnectCard';
import { TextField, InputAdornment, Tooltip, CircularProgress } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import numeral from 'numeral';
import pluralize from 'pluralize';
import {
    getVisibleConnectGridTemplates,
    getTemplateTitle,
    getTemplateImageUrl,
    getFeatureAdvertisementConfigForTemplate,
} from '../patientsUtil';
import ViewColumnIcon from '@material-ui/icons/ViewColumnRounded';
import { Link } from 'react-router-dom';
import { getNumberOfPatientsOnWaitlistsFromTemplate } from '../scheduling/schedulingUtil';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { connect } from 'react-redux';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';

function GridCardHolder({ children, width, height }) {
    return (
        <div
            className={classnames({ 'grid-card-holder': true, 'is-over': false })}
            style={{ width: `${width}px`, height: `${height}px` }}
        >
            {children}
        </div>
    );
}

function GridCard({ children, width, height, filteredOut }) {
    return (
        <div
            className={classnames({ 'grid-card': true, 'is-dragging': false, 'filtered-out': filteredOut })}
            style={{ width: `${width}px`, height: `${height}px` }}
        >
            {children}
        </div>
    );
}

function ConnectCardGrid(props) {
    const {
        cardSize,
        setSelectedCard,
        availableTemplates,
        autoFocusSearch,
        setEditingTemplate,
        connect,
        hideTargeted,
        maxHeight,
        pharmacy,
        forceUpdate,
        history,
        isCovidWaitlistEnabled,
        fullPepEnabled,
        isLoading,
        apptTypesWithSessions,
    } = props;
    const [layout, setLayout] = useState(null);
    const [hoverPosition, setHoverPosition] = useState(null);
    const [hovering] = useState(false);
    const [sortedCards, setSortedCards] = useState(null);
    const [filteredTemplateIds, setFilteredTemplateIds] = useState(null);
    const [focusedTemplateIndex, setFocusedTemplateIndex] = useState(null);
    const [connectOpportunities, setConnectOpportunities] = useState(null);
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    // store a non-null version of opportunities so we don't flash while reloading them
    useEffect(() => {
        if (!_.isNil(_.get(connect, 'opportunities'))) {
            setConnectOpportunities(_.get(connect, 'opportunities'));
        }
    }, [_.get(connect, 'opportunities')]);

    const searchBoxRef = useRef();
    const gridRef = useRef();

    const cards = adaptCardLayoutToComponents(
        sortedCards,
        !fullPepEnabled ? () => setShowUpgradeDialog(true) : setSelectedCard,
        focusedTemplateIndex,
        setEditingTemplate,
        connectOpportunities,
        pharmacy,
        forceUpdate,
        history,
        isCovidWaitlistEnabled,
        apptTypesWithSessions
    );

    const outerCardSize = ConnectCardGrid.getOuterCardSize(cardSize);
    // Not all available templates are visible initially - targeted ones might be hidden
    const initiallyVisibleTemplates = getVisibleConnectGridTemplates(
        _.get(props, 'availableTemplates'),
        connectOpportunities || {},
        { hideTargeted: !!hideTargeted }
    );

    useEffect(() => {
        if (!hovering && !_.isNil(hoverPosition) && !_.isNil(hoverPosition.hoverLayout)) {
            setLayout(hoverPosition.hoverLayout);
            setHoverPosition(null);
        }
    }, [hovering]);

    useEffect(() => {
        const sorted = sortCardsForFiltering(
            _.get(props, 'availableTemplates'),
            filteredTemplateIds,
            connectOpportunities || {},
            pharmacy,
            apptTypesWithSessions,
            { hideTargeted: !!hideTargeted }
        );
        setSortedCards(sorted);
        setLayout(null);
    }, [
        _.isNil(availableTemplates)
            ? null
            : JSON.stringify(
                  _.map(availableTemplates, (t) =>
                      _.pick(t, ['starred', 'inbox_conversation_template_id', 'subject', 'message'])
                  )
              ),
        _.isNil(filteredTemplateIds) ? null : JSON.stringify(Array.from(filteredTemplateIds)),
        _.size(connectOpportunities),
    ]);

    // set the scrollTop of the cards grid to keep the focused one in view when doing keyboard navigation
    useEffect(() => {
        if (_.isFinite(focusedTemplateIndex) && !_.isNil(gridRef.current) && _.isFinite(maxHeight)) {
            const gridEl = gridRef.current;
            const focusedRow = Math.floor(focusedTemplateIndex / 2);
            const card = {
                top: outerCardSize.height * focusedRow,
                bottom: outerCardSize.height * (focusedRow + 1),
            };

            const viewport = {
                top: gridEl.scrollTop,
                bottom: gridEl.scrollTop + maxHeight,
            };

            if (card.top < viewport.top) {
                gridEl.scrollTop = card.top;
            } else if (card.bottom > viewport.bottom) {
                gridEl.scrollTop = gridEl.scrollTop + outerCardSize.height;
            }
        }
    }, [focusedTemplateIndex, maxHeight]);

    const setFocusedTemplateIndexIfValid = (index) => {
        if (index >= 0 && index < _.size(sortedCards)) {
            if (
                _.isNil(filteredTemplateIds) ||
                filteredTemplateIds.has(sortedCards[index].inbox_conversation_template_id)
            ) {
                setFocusedTemplateIndex(index);
            }
        }
    };

    // If we don't have opportunities loaded, then show loading to avoid the screen flickering
    if (isLoading || _.isEmpty(connectOpportunities)) {
        return (
            <div className="connect-card-grid loading">
                <div className="progress-wrapper">
                    <CircularProgress />
                </div>
            </div>
        );
    }

    const getFocusedTitle = () => {
        if (_.isFinite(focusedTemplateIndex)) {
            const { title, subject } = sortedCards[focusedTemplateIndex];
            return !_.isNil(title) ? title : subject;
        }
    };

    return (
        <div
            className="connect-card-grid"
            style={{
                maxHeight: _.isFinite(maxHeight) ? `${maxHeight}px` : null,
            }}
        >
            <div className="filter-line">
                <span className="left-content">
                    <TextField
                        autoFocus={autoFocusSearch}
                        inputRef={searchBoxRef}
                        className="search-text-field"
                        style={{ width: '400px' }}
                        defaultValue={''}
                        placeholder={`Search available ${hideTargeted ? 'standard' : ''} workflows...`}
                        variant="outlined"
                        onFocus={() => setFocusedTemplateIndex(!_.isEmpty(sortedCards) ? 0 : null)}
                        onBlur={() => setFocusedTemplateIndex(null)}
                        onKeyUp={(e) => {
                            if (e.key === 'ArrowRight' && focusedTemplateIndex % 2 === 0) {
                                setFocusedTemplateIndexIfValid(focusedTemplateIndex + 1);
                            } else if (e.key === 'ArrowDown') {
                                setFocusedTemplateIndexIfValid(focusedTemplateIndex + 2);
                            } else if (e.key === 'ArrowLeft' && focusedTemplateIndex % 2 === 1) {
                                setFocusedTemplateIndexIfValid(focusedTemplateIndex - 1);
                            } else if (e.key === 'ArrowUp') {
                                setFocusedTemplateIndexIfValid(focusedTemplateIndex - 2);
                            } else if (e.key === 'Enter') {
                                if (!fullPepEnabled) {
                                    setShowUpgradeDialog(true);
                                } else {
                                    const card = sortedCards[focusedTemplateIndex];
                                    if (
                                        _.isNil(
                                            getFeatureAdvertisementConfigForTemplate(
                                                card,
                                                pharmacy,
                                                apptTypesWithSessions
                                            )
                                        )
                                    ) {
                                        setSelectedCard(card.inbox_conversation_template_id);
                                    }
                                }
                            }
                        }}
                        onChange={(e) => {
                            if (_.isEmpty(e.target.value.trim())) {
                                setFilteredTemplateIds(null);
                            } else {
                                const matches = _.filter(initiallyVisibleTemplates, (template) => {
                                    return (
                                        getTemplateTitle(template)
                                            .toLowerCase()
                                            .indexOf(e.target.value.trim().toLowerCase()) > -1
                                    );
                                });
                                setFilteredTemplateIds(
                                    new Set(
                                        _.map(
                                            matches,
                                            ({ inbox_conversation_template_id }) => inbox_conversation_template_id
                                        )
                                    )
                                );
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fontSize: '30px' }} />
                                </InputAdornment>
                            ),
                            endAdornment: _.isNil(filteredTemplateIds) ? null : (
                                <InputAdornment position="end">
                                    <Tooltip title="Clear patient query">
                                        <CloseIcon
                                            className="clear-icon"
                                            style={{ fontSize: '24px', color: '#DF4851' }}
                                            onClick={() => {
                                                if (searchBoxRef.current) {
                                                    searchBoxRef.current.value = '';
                                                }
                                                setFilteredTemplateIds(null);
                                            }}
                                        />
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <span className="counts">
                        {_.isNil(filteredTemplateIds) ? null : (
                            <span>
                                {numeral(_.size(filteredTemplateIds)).format('0,0')}{' '}
                                {pluralize('match', _.size(filteredTemplateIds))} out of&nbsp;
                            </span>
                        )}
                        {numeral(_.size(initiallyVisibleTemplates)).format('0,0')} {hideTargeted ? 'standard' : ''}{' '}
                        {pluralize('workflow', _.size(initiallyVisibleTemplates))}
                    </span>
                </span>
                {!_.isFinite(focusedTemplateIndex) ? null : (
                    <div className="keyboard-directions">
                        <div className="card-instructions">
                            Press Enter to select <strong>{getFocusedTitle()}</strong>
                        </div>
                        <div className="nav-instructions">Arrow keys to navigate</div>
                    </div>
                )}
            </div>
            <AutoSizer>
                {({ width, height }) => {
                    width = Math.floor(width / outerCardSize.width) * outerCardSize.width;

                    const gridProps = {
                        width,
                        cols: Math.floor(width / outerCardSize.width),
                        rowHeight: outerCardSize.height,
                    };
                    gridProps.rows = Math.ceil(_.size(cards) / gridProps.cols);

                    const gridHeight = outerCardSize.height * gridProps.rows;

                    if (_.isNil(layout)) {
                        setTimeout(() => setLayout(computeGridLayout(gridProps, cards)), 1);
                        return null;
                    }

                    const displayLayout = _.get(hoverPosition, 'hoverLayout', layout);
                    const layoutMap = _.keyBy(displayLayout, ({ x, y }) => `${x}-${y}`);

                    const viewportHeight = !_.isFinite(maxHeight)
                        ? gridHeight
                        : Math.min(gridHeight, maxHeight - ConnectCardGrid.SEARCH_BAR_HEIGHT);

                    return (
                        <div
                            ref={gridRef}
                            className="grid-contents"
                            style={{
                                width: `${width + 20}px`,
                                height: `${viewportHeight === 0 ? 200 : viewportHeight}px`,
                            }}
                        >
                            {!_.isEmpty(sortedCards) ? null : (
                                <div className="empty-grid-info">
                                    <ViewColumnIcon className="grid-icon" />
                                    <ViewColumnIcon className="grid-icon" style={{ marginLeft: '-47px' }} />
                                    <div className="manage-info">
                                        <div className="main-line">No workflows are currently enabled.</div>
                                        <div className="sub-line">
                                            You may enable some or create a new one by&nbsp;
                                            <Link className="manage-link" to="/workflows/manage">
                                                managing your workflows
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {_.flatMap(_.times(gridProps.rows), (x) => {
                                return _.map(_.times(gridProps.cols), (y) => {
                                    const item = _.get(layoutMap, `${x}-${y}`);
                                    return (
                                        <GridCardHolder
                                            key={`${x}-${y}`}
                                            x={x}
                                            y={y}
                                            width={cardSize.width}
                                            height={cardSize.height}
                                        >
                                            {!_.has(layoutMap, `${x}-${y}`) ? null : (
                                                <GridCard
                                                    key={`${item.uuid}-${x}-${y}`}
                                                    item={item}
                                                    x={x}
                                                    y={y}
                                                    width={cardSize.width}
                                                    height={cardSize.height}
                                                    filteredOut={
                                                        _.isNil(filteredTemplateIds) || _.isNil(item)
                                                            ? false
                                                            : !filteredTemplateIds.has(item.uuid)
                                                    }
                                                >
                                                    {React.cloneElement(cards[Number(item.i)], {
                                                        width: cardSize.width,
                                                        height: cardSize.height,
                                                    })}
                                                </GridCard>
                                            )}
                                        </GridCardHolder>
                                    );
                                });
                            })}
                        </div>
                    );
                }}
            </AutoSizer>

            {!showUpgradeDialog ? null : <FullPepUpgrade asDialog onClose={() => setShowUpgradeDialog(false)} />}
        </div>
    );
}
ConnectCardGrid.SEARCH_BAR_HEIGHT = 66;
ConnectCardGrid.MARGIN = 10;

ConnectCardGrid.getOuterCardSize = function (cardSize) {
    if (_.isObject(cardSize) && _.isFinite(cardSize.width) && _.isFinite(cardSize.height)) {
        return {
            width: cardSize.width + ConnectCardGrid.MARGIN,
            height: cardSize.height + ConnectCardGrid.MARGIN,
        };
    }
};

const mapStateToProps = (state) => ({
    fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    isLoading: _.get(state.apptReservation, 'liveReusableSessions.loading') || state.apptType.loadingAvailableApptTypes,
    apptTypesWithSessions: selectApptTypesWithSessions(state),
});

export default connect(mapStateToProps, null)(ConnectCardGrid);

export function adaptCardLayoutToComponents(
    cardsLayout,
    setSelectedCard,
    focusedTemplateIndex,
    setEditingTemplate,
    connectOpportunities,
    pharmacy,
    forceUpdate,
    history,
    isCovidWaitlistEnabled,
    apptTypesWithSessions
) {
    const nextStarCount =
        _.get(
            _.maxBy(cardsLayout, ({ starred }) => starred),
            'starred',
            0
        ) + 1;

    const getOpportuntyCount = (targetingType, card) => {
        if (!_.isNil(targetingType)) {
            // special case handle waitlist since we will need to look up all of the appropriate waitlist ids from
            // the template message_content.messaging_form
            if (targetingType === 'waitlist') {
                if (isCovidWaitlistEnabled) {
                    return getNumberOfPatientsOnWaitlistsFromTemplate(card, connectOpportunities);
                }
                return undefined;
            }
            // return 0 if we don't find any opportunities but there is a targeting type, otherwise return the count
            return _.get(connectOpportunities, `${targetingType}.count`, 0);
        }
    };

    return _.map(cardsLayout, (card, i) => {
        const advertisementConfig = getFeatureAdvertisementConfigForTemplate(card, pharmacy, apptTypesWithSessions);
        const isFeatureAdvertisement = _.isObject(advertisementConfig) && !advertisementConfig.dismissed;
        return (
            <ConnectCard
                key={card.inbox_conversation_template_id}
                id={card.inbox_conversation_template_id}
                name={
                    !_.isNil(_.get(advertisementConfig, 'title'))
                        ? advertisementConfig.title
                        : !_.isNil(card.title)
                        ? card.title
                        : card.subject
                }
                starred={card.starred > 0}
                selected={focusedTemplateIndex === i}
                onClick={() => setSelectedCard(card.inbox_conversation_template_id)}
                opportunityTypeId={card.inbox_conversation_template_id}
                icon={_.isNil(card.image_ref) ? null : getTemplateImageUrl(card)}
                templateText={
                    !_.isNil(_.get(advertisementConfig, 'description'))
                        ? advertisementConfig.description
                        : !_.isNil(card.workflow_explanation)
                        ? card.workflow_explanation
                        : card.message
                }
                templateData={card}
                nextStarCount={nextStarCount}
                readOnly={card.read_only === 1}
                targetedPatientCount={getOpportuntyCount(card.targeting_type, card)}
                isWaitlistCard={card.targeting_type === 'waitlist'}
                onEditTemplate={() => setEditingTemplate(card)}
                isFeatureAdvertisement={isFeatureAdvertisement}
                advertisementUrl={_.get(advertisementConfig, 'url')}
                onDismissAdvertisement={() => {
                    const onDismiss = _.get(advertisementConfig, 'onDismiss');
                    if (_.isFunction(onDismiss)) {
                        onDismiss();
                    }
                    forceUpdate();
                }}
                additionalAdvertisementText={_.get(advertisementConfig, 'additionalText')}
                onAdvertisementClick={() => {
                    const path = _.get(advertisementConfig, 'onClickPath');
                    if (_.isString(path)) {
                        history.push(path);
                    }
                }}
            />
        );
    });
}

export function calculateWrapperSize(maxWidth, cardSize, cards, maxRows = Number.MAX_SAFE_INTEGER) {
    const outerCardSize = { width: cardSize.width + 10, height: cardSize.height + 10 };
    // The 20px offset is there to match the width of the Autosizer component - makes the scrollbar visible
    const width = Math.floor(maxWidth / outerCardSize.width) * outerCardSize.width + 20;

    const gridProps = {
        width,
        cols: Math.floor(width / outerCardSize.width),
        rowHeight: outerCardSize.height,
    };
    gridProps.rows = Math.min(maxRows, Math.ceil(_.size(cards) / gridProps.cols));

    const height = outerCardSize.height * gridProps.rows + 65;

    return { width, height };
}

function sortCardsForFiltering(
    cards,
    filteredTemplateIds,
    connectOpportunities,
    pharmacy,
    apptTypesWithSessions,
    { hideTargeted = false }
) {
    const sortedForStars = _.sortBy(getVisibleConnectGridTemplates(cards, connectOpportunities, { hideTargeted }), [
        // sort undismissed feature advertisements to be at the very top...
        (card) => {
            const config = getFeatureAdvertisementConfigForTemplate(card, pharmacy, apptTypesWithSessions);

            if (_.isObject(config) && !config.dismissed) {
                return 0;
            }
            return 1;
        },
        // sort starred cards to always be first
        ({ starred }) => (starred === 0 ? Number.MAX_VALUE : starred),
        // then sort targeted workflows by most patients first (targeted count * -1)
        ({ targeting_type }) => {
            // If not targeted, then set to 0 as this will place it at the end since we are multiplying all counts by -1
            if (_.isNil(targeting_type)) {
                return 0;
            }

            const count = _.get(connectOpportunities, `${targeting_type}.count`, 0);

            // if this is targeted, but with no patients then move to the end
            if (count === 0) {
                return 1;
            }
            // multiplying this by -1 effectively makes this a descending sort
            return count * -1;
        },
    ]);

    if (_.isEmpty(filteredTemplateIds)) {
        return sortedForStars;
    }
    return _.sortBy(sortedForStars, ({ inbox_conversation_template_id }) =>
        filteredTemplateIds.has(inbox_conversation_template_id) ? 0 : 1
    );
}

function computeGridLayout(gridProps, cards) {
    const { cols } = gridProps;

    const layout = _.map(cards, (card, i) => {
        return {
            i: String(i),
            y: i % cols,
            x: Math.floor(i / cols),
            w: 1,
            h: 1,
            uuid: card.props.id,
        };
    });

    return layout;
}
