import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Config2Constants from 'constants/Config2';
import { displayToast } from 'redux/actionCreators/Snackbar';
import { pharmacyAction } from 'redux/Pharmacy/action';
import {
    getPackageByCode,
    addCustPackage,
    updateCustPackage,
} from 'redux/actionCreators/Settings/GeneralSettings/FeaturesAndFunctions/Config2Packages';

const WithCustPackages = (PackageComponent, package_) => {
    class CustPackages extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                isUpdating: false,
            };
            this.packageName = package_.title;
            this.packageCode = package_.code;
            this.description = package_.description;
            this.isPackageAddedToCust = () => !!this.getCustPackage();
            this.getPackageByCode = _.memoize(getPackageByCode);
            this.onCustPackageActivated = props.onCustPackageActivated;
        }

        getCustPackage = () => _.find(this.props.pharmacy.package, (pkg) => pkg.code === this.packageCode);

        getPackageUpdatedWithStatus = (status) => {
            const custPackage = { ...this.getCustPackage() };
            const {
                status: { enabled, disabled },
            } = Config2Constants;

            custPackage.statusId = status === 'enabled' ? enabled : disabled;
            return custPackage;
        };

        addCustPackage = async () => {
            const { pharmacy, addCustPackage } = this.props;
            const payload = {
                statusId: Config2Constants.status.enabled,
            };
            const package_ = {
                id: await this.getPackageByCode(this.packageCode),
                name: this.packageName,
            };

            return await addCustPackage(pharmacy.id, package_, payload);
        };

        isCustPackageActive = () => {
            const custPackage = this.getCustPackage();
            return custPackage && custPackage.statusId === Config2Constants.status.enabled;
        };

        activateCustPackage = async () => {
            const { pharmacy, getPharmacy, updateCustPackage } = this.props;
            this.setState({ isUpdating: true });

            try {
                if (!this.isPackageAddedToCust()) {
                    await this.addCustPackage();
                } else {
                    const custPackage = this.getPackageUpdatedWithStatus('enabled');
                    await updateCustPackage(pharmacy.id, custPackage);
                }
                _.isFunction(this.onCustPackageActivated) && this.onCustPackageActivated();
            } catch (e) {
                displayToast({ text: `Failed To Activate ${this.packageName}!`, type: 'error' });
            }

            // Refresh the pharmacy to have the latest values, irrespective of success or failure
            await getPharmacy(pharmacy.id);
            this.setState({ isUpdating: false });
        };

        deactivateCustPackage = async () => {
            const { pharmacy, getPharmacy, updateCustPackage } = this.props;
            this.setState({ isUpdating: true });

            try {
                const custPackage = this.getPackageUpdatedWithStatus('disabled');
                await updateCustPackage(pharmacy.id, custPackage);
            } catch (e) {
                displayToast({ text: `Failed To Deactivate ${this.packageName}!`, type: 'error' });
            }

            // Refresh the pharmacy to have the latest values, irrespective of success or failure
            await getPharmacy(pharmacy.id);
            this.setState({ isUpdating: false });
        };

        render = () => {
            const { isUpdating } = this.state;
            const isActive = this.isCustPackageActive();

            return (
                <PackageComponent
                    isActive={isActive}
                    isUpdating={isUpdating}
                    onActivate={this.activateCustPackage}
                    onDeactivate={this.deactivateCustPackage}
                />
            );
        };
    }

    const mapStateToProps = (state) => ({
        pharmacy: state.pharmacy.pharmacy,
    });

    const mapDispatchToProps = (dispatch) => ({
        displayToast: (data) => dispatch(displayToast(data)),
        getPharmacy: (custID) => dispatch(pharmacyAction.getPharmacy(custID)),

        addCustPackage: (custID, package_, payload) => dispatch(addCustPackage(custID, package_, payload)),

        updateCustPackage: (custID, custPackage) => dispatch(updateCustPackage(custID, custPackage)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(CustPackages);
};

export default WithCustPackages;
