import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { FontIcon, ListItem } from 'react-md';

const NavLink = ({ primaryText, secondaryText, to, exact, icon }) => (
    <Route path={to} exact={exact}>
        {({ match }) => (
            <ListItem
                component={RouterLink}
                leftAvatar={<FontIcon>{icon}</FontIcon>}
                active={!!match}
                to={to}
                primaryText={primaryText}
                secondaryText={secondaryText}
                primaryTextClassName={!!match ? 'nav-primary-text-active' : 'nav-primary-text'}
                secondaryTextClassName="md-list-item-secondary"
                threeLines
            />
        )}
    </Route>
);

export default NavLink;
