import React, { Component } from 'react';
import { Card, CardText, Grid, Cell } from 'react-md';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EmptyCard from './EmptyCard';
import CustomTab from 'components/Common/CustomTab';
import ReportTitle from 'components/Report/Common/ReportTitle';
import GraphTypePlaceholder from 'components/Common/GraphTypePlaceholder';
import { formatWithCommaAndAbbr } from 'utils/helper';

const barOptions = {
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                gridLines: {
                    display: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    legend: {
        display: false,
    },
};

const sampleChartData = {
    labels: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            backgroundColor: '#eff7ff',
            borderColor: '#3793ff',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: {},
        },
    ],
};

const TAB_KEYS = {
    REFILLS: 'Refills',
    CALLS: 'Calls',
    MINUTES: 'Minutes',
};

const tabData = [TAB_KEYS.REFILLS, TAB_KEYS.CALLS, TAB_KEYS.MINUTES];

const DataCard = ({ chartData, linearData, showLoader, tab, activeTab, tooltipText }) => {
    const isRefillsTabActive = tabData[activeTab] === TAB_KEYS.REFILLS;
    const isCallsTabActive = tabData[activeTab] === TAB_KEYS.CALLS;
    const isMinutesTabActive = tabData[activeTab] === TAB_KEYS.MINUTES;
    const isMobile = !useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Card className="reportCard communicationsPlatform">
            <ReportTitle title="Interactive Voice Response (IVR)" className="cardTitle" tooltipText={tooltipText}>
                {!showLoader && <div className="order1">{tab()}</div>}
            </ReportTitle>
            <CardText className={showLoader ? 'flex-grow-1 flex-middle' : 'flex-grow-1'}>
                {!showLoader ? (
                    <Grid className="height100 flex-middle">
                        <div className={!isMobile ? 'full-width flex' : 'full-width'}>
                            <Cell size={7}>
                                <Bar data={chartData} width={360} height={250} options={barOptions} />
                            </Cell>
                            <Cell size={5}>
                                <p className="text-align-center"> Last 7 Days </p>
                                <div className="sevenDaysContainer">
                                    <div
                                        className={`standard-margin-bottom-10 ${!isRefillsTabActive ? 'disable' : ''}`}
                                    >
                                        <p className="title"> Refills </p>
                                        <p className="value"> {formatWithCommaAndAbbr(linearData.refills)} Refills</p>
                                    </div>
                                    <div className={`standard-margin-bottom-10 ${!isCallsTabActive ? 'disable' : ''}`}>
                                        <p className="title"> Calls </p>
                                        <p className="value">{formatWithCommaAndAbbr(linearData.inboundCalls)} Calls</p>
                                    </div>
                                    <div className={`${!isMinutesTabActive ? 'disable' : ''}`}>
                                        <p className="title"> Minutes </p>
                                        <p className="value">
                                            {formatWithCommaAndAbbr(linearData.callDuration)} Minutes Total
                                        </p>
                                        <p className="sub-value">
                                            {formatWithCommaAndAbbr(linearData.nonTransferSec)} Inbound
                                        </p>
                                        <p className="sub-value">
                                            {formatWithCommaAndAbbr(linearData.transferSec)} Transfer
                                        </p>
                                    </div>
                                </div>
                            </Cell>
                        </div>
                    </Grid>
                ) : (
                    <GraphTypePlaceholder legendsCount={2} />
                )}
            </CardText>
        </Card>
    );
};

export default class CommunicationsPlatform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            chartData: {},
            linearData: {
                refills: null,
                inboundCalls: null,
                callDuration: null,
                transferSec: null,
                nonTransferSec: null,
            },
            refillsData: null,
            inboundCallsData: null,
            callDurationData: null,
        };
    }

    mapChartData = (dataToMap) => {
        dataToMap = _.cloneDeep(dataToMap);

        const refills = dataToMap.refills || [];
        const inboundCalls = dataToMap.inbound_calls || [];
        const callDuration = dataToMap.call_duration || [];

        const refillsLabels = refills.map((item) => {
            return moment(item.month, 'MM').format('MMM');
        });
        const inboundCallsLabels = inboundCalls.map((item) => {
            return moment(item.month, 'MM').format('MMM');
        });
        const callDurationLabels = callDuration.map((item) => {
            return moment(item.month, 'MM').format('MMM');
        });

        const refillsData = refills.map((item) => item.refills);
        const inboundCallsData = inboundCalls.map((item) => item.inbound_calls);
        const callDurationData = callDuration.map((item) => item.call_duration);

        const chartData = {
            labels: refillsLabels,
            datasets: [
                {
                    ...sampleChartData.datasets[0],
                    data: refillsData,
                },
            ],
        };

        this.setState({
            chartData,
            refillsData,
            inboundCallsData,
            callDurationData,
            refillsLabels,
            inboundCallsLabels,
            callDurationLabels,
        });
    };

    mapLinearData = (dataToMap) => {
        const { last_7_days } = dataToMap;
        this.setState({
            linearData: {
                refills: last_7_days.refills,
                inboundCalls: last_7_days.inbound_calls,
                callDuration: last_7_days.call_duration,
                transferSec: last_7_days.transfer_sec,
                nonTransferSec: last_7_days.non_transfer_sec,
            },
        });
    };

    onTabClick = (i) => {
        let selectedTabData = null;
        let selectedLabel = null;
        const {
            refillsData,
            inboundCallsData,
            chartData,
            callDurationData,
            refillsLabels,
            inboundCallsLabels,
            callDurationLabels,
        } = this.state;

        switch (tabData[i]) {
            case TAB_KEYS.REFILLS:
                selectedTabData = [...refillsData];
                selectedLabel = refillsLabels;
                break;
            case TAB_KEYS.CALLS:
                selectedTabData = [...inboundCallsData];
                selectedLabel = inboundCallsLabels;
                break;
            case TAB_KEYS.MINUTES:
                selectedTabData = [...callDurationData];
                selectedLabel = callDurationLabels;
                break;
            default:
                selectedTabData = [...refillsData];
                selectedLabel = refillsLabels;
        }

        chartData.datasets[0].data = selectedTabData;
        chartData.labels = selectedLabel;

        this.setState({ activeTab: i, chartData });
    };

    init() {
        const { data } = this.props;
        // TODO: This logic needs to be corrected. Also, the component can be refactored to rely on props and selectors
        if (_.keys(data).length > 1) {
            this.mapChartData(data);
            this.mapLinearData(data);
        }
    }

    resetState = (cb) => {
        this.setState(
            {
                chartData: {},
                activeTab: 0,
                linearData: {
                    refills: null,
                    inboundCalls: null,
                    transferSec: null,
                    nonTransferSec: null,
                },
                refillsData: null,
                inboundCallsData: null,
                callDurationData: null,
                refillsLabels: null,
                inboundCallsLabels: null,
                callDurationLabels: null,
            },
            () => cb()
        );
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate({ data: oldData }) {
        const { data } = this.props;
        if (!_.isEqual(data, oldData)) {
            this.resetState(this.init.bind(this));
        }
    }

    render() {
        const { enabled, tooltipText } = this.props;
        const { chartData, linearData, activeTab } = this.state;
        const showLoader = Object.keys(chartData).length === 0;

        if (!enabled) {
            return (
                <EmptyCard
                    title="Interactive Voice Response (IVR)"
                    imgPath="/reports/communications_platform.png"
                    helperText="You don’t have our IVR yet."
                    appType="communicationsPlatform"
                    tooltipText={tooltipText}
                />
            );
        }

        return (
            <DataCard
                chartData={chartData}
                linearData={linearData}
                showLoader={showLoader}
                infoIconClick={this.onInfoIconClick}
                activeTab={activeTab}
                tab={() => <CustomTab activeTab={activeTab} onTabClick={this.onTabClick} data={tabData} />}
                tooltipText={tooltipText}
            />
        );
    }
}
