import Actions from 'redux/actions';
import { get, post, deleteReq } from 'redux/actionCreators/rest';
import Config from 'config';
import API_PATH from 'api.json';
import { displayToast } from 'redux/actionCreators/Snackbar';
import moment from 'moment';
import { logHttpError } from '../../../utils/log';

const processDeleteExceptionHr = (dispatch, deletedId, removeIdx) => {
    const apiPathCustomHour = API_PATH.EXCEPTION_HOURS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.config2_url + apiPathCustomHour;
    dispatch({
        type: Actions.REMOVE_EXCEPTION_HOUR_STARTED,
        payload: { removeIdx },
    });

    const deleteExceptionHrReq = deleteReq(`${apiUrl}/${deletedId}`);
    deleteExceptionHrReq
        .then(() => {
            dispatch({ type: Actions.REMOVE_EXCEPTION_HOUR_SUCCESS, payload: { removeIdx } });
            dispatch(displayToast({ text: 'Exception Hour removed', type: 'success' }));
        })
        .catch((err) => {
            logHttpError('An error occured on deleteExceptionHrReq', err);
            dispatch({ type: Actions.REMOVE_EXCEPTION_HOUR_FAILED, payload: { removeIdx } });
            dispatch(displayToast({ text: 'Exception Hour remove failed', type: 'error' }));
        });
};

export const fetchHolidayList = () => (dispatch) => {
    const apiPath = API_PATH.HOLIDAY_LIST.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 9999 } };

    dispatch({ type: Actions.FETCH_HOLIDAY_LIST_STARTED });
    const fetchDefaultHrsReq = get(apiUrl, header);
    return fetchDefaultHrsReq
        .then((resp) => {
            dispatch({ type: Actions.FETCH_HOLIDAY_LIST_SUCCESS, payload: resp.result });
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchHolidayList', err);
            dispatch({ type: Actions.FETCH_HOLIDAY_LIST_FAILED, error: err });
            return { err };
        });
};

export const fetchDefaultHours = () => (dispatch) => {
    const apiPath = API_PATH.HOURS.replace('{custID}', Config.X_PharmacyID).replace('{locID}', Config.X_LocationID);
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 9999 } };

    dispatch({ type: Actions.FETCH_DEFAULT_HOURS_STARTED });
    const fetchDefaultHrsReq = get(apiUrl, header);
    fetchDefaultHrsReq
        .then((data) => dispatch({ type: Actions.FETCH_DEFAULT_HOURS_SUCCESS, payload: data }))
        .catch((err) => {
            logHttpError('An error occured on fetchDefaultHrsReq', err);
            dispatch({ type: Actions.FETCH_DEFAULT_HOURS_FAILED, error: err });
        });
};

export const fetchExceptionHours = () => (dispatch) => {
    const apiPath = API_PATH.EXCEPTION_HOURS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 9999, maxAgeDays: 7 } };

    const fetchExceptionHrsReq = get(apiUrl, header);
    return fetchExceptionHrsReq
        .then((data) => {
            dispatch({ type: Actions.FETCH_EXCEPTION_HOURS_SUCCESS, payload: data });
            return { data };
        })
        .catch((err) => {
            logHttpError('An error occured on fetchExceptionHrsReq', err);
            dispatch({ type: Actions.FETCH_EXCEPTION_HOURS_FAILED, error: err });
            return { err };
        });
};

const prepareConflictMessage = (conflictData) => {
    const conflictMsg = {};
    conflictData.forEach((conflict) => {
        const conflictWithMessages = [];
        conflict.conflictsWith.forEach((conflictWith) => {
            const msg =
                'Conflicts with ' +
                conflictWith.name +
                ' : ' +
                moment(conflictWith.startDate).format('M/D/YY hh:mm a') +
                ' - ' +
                moment(conflictWith.endDate).format('M/D/YY hh:mm a');
            conflictWithMessages.push(msg);
        });
        conflictMsg[conflict.entity.id] = conflictWithMessages.join(', ');
    });
    return conflictMsg;
};

export const loadExceptionConflicts = () => (dispatch) => {
    const apiPath = API_PATH.EXCEPTION_HOURS_CONFLICT.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.config2_url + apiPath;
    const header = { params: { numRows: 999 } };
    dispatch({ type: Actions.FETCH_EXCEPTION_HOURS_CONFLICT_STARTED });

    const fetchExceptionHrsConflictReq = get(apiUrl, header);
    fetchExceptionHrsConflictReq
        .then((data) => {
            const conflictMsg = prepareConflictMessage(data);
            dispatch({ type: Actions.FETCH_EXCEPTION_HOURS_CONFLICT_SUCCESS, payload: { data, conflictMsg } });
        })
        .catch((err) => {
            logHttpError('An error occured on fetchExceptionHrsConflictReq', err);
            dispatch({ type: Actions.FETCH_EXCEPTION_HOURS_CONFLICT_FAILED, error: err });
        });
};

export const addExceptionHours = () => ({
    type: Actions.ADD_EXCEPTION_HOURS,
});

export const updateExceptionDate = (payload) => ({
    type: Actions.UPDATE_EXCEPTION_DATE,
    payload,
});

export const updateExceptionTitle = (payload) => ({
    type: Actions.UPDATE_EXCEPTION_TITLE,
    payload,
});

export const updateDaysOfWeekSettings = (payload) => ({
    type: Actions.UPDATE_DAYS_OF_WEEK,
    payload,
});

export const updateHolidayRepeat = (payload) => ({
    type: Actions.UPDATE_HOLIDAY_REPEAT,
    payload,
});

export const updateHolidaysSettings = (payload) => ({
    type: Actions.UPDATE_HOLIDAYS,
    payload,
});

export const holidaySelected = (payload) => ({
    type: Actions.HOLIDAY_SELECTED,
    payload,
});

export const removeExceptionHour = (data) => (dispatch) => {
    const { exceptionHour, removeIdx } = data;
    const deletedExceptionHour = exceptionHour[removeIdx];

    if (deletedExceptionHour.id === '0') {
        dispatch({
            type: Actions.REMOVE_EXCEPTION_HOUR_SUCCESS,
            payload: { removeIdx },
        });
    } else {
        processDeleteExceptionHr(dispatch, deletedExceptionHour.id, removeIdx);
    }
};

export const removeLocationHoliday = (data) => (dispatch) => {
    const apiPathLocationHoliday = API_PATH.LOCATION_HOLIDAY.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const { exceptionHours, idx } = data;
    const apiUrl = Config.config2_url + apiPathLocationHoliday;
    dispatch({ type: Actions.REMOVE_EXCEPTION_HOUR_STARTED, payload: { removeIdx: idx } });

    const deleteExceptionHrReq = deleteReq(`${apiUrl}/${exceptionHours[idx].locHolidayId}`);
    deleteExceptionHrReq
        .then(() => {
            dispatch({ type: Actions.REMOVE_EXCEPTION_HOUR_SUCCESS, payload: { removeIdx: idx } });
            dispatch(displayToast({ text: 'Exception Hour removed', type: 'success' }));
        })
        .catch((err) => {
            logHttpError('An error occured on deleteExceptionHrReq', err);
            dispatch({ type: Actions.REMOVE_EXCEPTION_HOUR_FAILED, payload: { removeIdx: idx } });
            dispatch(displayToast({ text: 'Exception Hour remove failed', type: 'error' }));
        });
};

export const saveCustomHoliday = (data) => (disptach) => {
    const apiPathCustomHoliday = API_PATH.EXCEPTION_HOURS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiPathLocationHoliday = API_PATH.LOCATION_HOLIDAY.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const { customHours, locationHolidays } = data;

    if (customHours && customHours.length) {
        return saveCustomHour(Config.config2_url + apiPathCustomHoliday, customHours, disptach);
    } else if (locationHolidays && locationHolidays.length) {
        return saveCustomHour(Config.config2_url + apiPathLocationHoliday, locationHolidays, disptach);
    }
};

const saveCustomHour = (apiURL, payload, dispatch) => {
    dispatch({ type: Actions.SAVE_EXCEPTION_HOURS_STARTED });
    const saveCustomHolidayReq = post(apiURL, payload);

    return saveCustomHolidayReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_EXCEPTION_HOURS_SUCCESS });
            dispatch(displayToast({ text: 'Store Custom holiday saved', type: 'success' }));
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on saveCustomHour', err);
            dispatch({ type: Actions.SAVE_EXCEPTION_HOURS_FAILED, error: err });
            const errMsg =
                err.response.data && err.response.data.title
                    ? err.response.data.title
                    : 'Failed to save store custom holiday';
            dispatch(displayToast({ text: errMsg, type: 'error' }));
            if (err.response.data && err.response.data.description) {
                const conflictData = JSON.parse(err.response.data.description);
                const conflictMsg = getHolidayConflictData(conflictData.conflict);
                dispatch({ type: Actions.FETCH_HOLIDAY_CONFLICT_SUCCESS, payload: { conflictMsg } });
            }
            return { err };
        });
};

export const saveDefaultHours = (defaultHours) => (dispatch) => {
    const apiPath = API_PATH.HOURS.replace('{custID}', Config.X_PharmacyID).replace('{locID}', Config.X_LocationID);

    dispatch({ type: Actions.SAVE_DEFAULT_HOURS_STARTED });

    const saveHoursReq = post(Config.config2_url + apiPath, defaultHours);
    return saveHoursReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_DEFAULT_HOURS_SUCCESS });
            dispatch(displayToast({ text: 'Store hours saved', type: 'success' }));
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on saveDefaultHours', err);
            dispatch({ type: Actions.SAVE_DEFAULT_HOURS_FAILED, error: err });
            dispatch(displayToast({ text: 'Failed to save store hours', type: 'error' }));
            return { err };
        });
};

export const saveHolidays = (holidays) => (dispatch) => {
    const apiPath = API_PATH.HOLIDAY_LIST.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );

    dispatch({ type: Actions.SAVE_CUSTOM_HOLIDAYS_STARTED });

    const saveHolidaysReq = post(Config.config2_url + apiPath, holidays);
    return saveHolidaysReq
        .then((resp) => {
            dispatch({ type: Actions.SAVE_CUSTOM_HOLIDAYS_SUCCESS });
            dispatch(displayToast({ text: 'New holiday saved', type: 'success' }));
            return { resp };
        })
        .catch((err) => {
            logHttpError('An error occured on saveHolidays', err);
            if (err.response.data) {
                const conflictData = JSON.parse(err.response.data.description);
                const conflictMsg = getHolidayConflictData(conflictData.conflict);
                dispatch({ type: Actions.FETCH_HOLIDAY_CONFLICT_SUCCESS, payload: { conflictMsg } });
            }
            dispatch({ type: Actions.SAVE_CUSTOM_HOLIDAYS_FAILED, error: err });
            dispatch(displayToast({ text: 'Failed to save new holiday', type: 'error' }));
            return { err };
        });
};

const getHolidayConflictData = (conflictData) => {
    const conflictMsg = [];
    conflictData.forEach((conflict) => {
        const conflictWithHolidays = [];
        const conflictWithHolidayIds = [];
        conflict.conflictsWith.forEach((conflictWith) => {
            conflictWithHolidays.push(`'${conflictWith.name}'`);
            conflictWithHolidayIds.push(conflictWith.id);
        });
        conflictMsg.push({
            holidayName: conflict.entity.name,
            withId: conflictWithHolidayIds,
            withMsg: 'Conflicts with existing holiday ' + conflictWithHolidays.join(', '),
        });
    });
    return conflictMsg;
};
