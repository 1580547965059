import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, Button, TextField, Cell, CircularProgress } from 'react-md';
import {
    Tooltip,
    Button as MUIButton,
    Typography,
    Select,
    FormControl,
    OutlinedInput,
    InputLabel,
    MenuItem,
} from '@material-ui/core';

import { ALL_LOCATIONS } from 'constants/Common';

const CardHeader = ({ title, onBack, onSave, disabled, loading, subtitle = false }) => {
    const className = disabled ? 'md-cell--right' : 'md-cell--right button-blue';
    return (
        <div className="grid-container card-title">
            <Cell size={9} tabletSize={6} phoneSize={4} className="md-card-title">
                <FontIcon id="back-btn" className="user-clickable md-avatar--card" onClick={onBack}>
                    arrow_back
                </FontIcon>
                <div>
                    <h2 id="title" className="md-card-title--title md-text">
                        {title}
                    </h2>
                    {subtitle && (
                        <h3 id="sub-title" className="md-card-title--title md-text--secondary">
                            {subtitle}
                        </h3>
                    )}
                </div>
            </Cell>
            {onSave && (
                <Cell size={3} tabletSize={2} phoneSize={4}>
                    <Button
                        id="save-btn"
                        className={`${className} flex-right button-full-width-mobile`}
                        raised
                        primary
                        disabled={disabled}
                        onClick={onSave}
                    >
                        {loading ? 'Saving..' : 'SAVE CHANGES'}
                    </Button>
                </Cell>
            )}
        </div>
    );
};

CardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    subtitle: PropTypes.string,
};

const SectionTitle = (props) => <h4 className="section-title"> {props.text} </h4>;
SectionTitle.propTypes = { text: PropTypes.string.isRequired };

const SectionSubTitle = (props) => <h5 className="sub-title-text"> {props.text} </h5>;
SectionSubTitle.propTypes = { text: PropTypes.string.isRequired };

const PageTitle = (props) => <h3 className="page-title"> {props.text} </h3>;
PageTitle.propTypes = { text: PropTypes.string.isRequired };

const PageSubTitle = (props) => <h5 className="md-text--secondary page-sub-title"> {props.text} </h5>;
PageSubTitle.propTypes = { text: PropTypes.string.isRequired };

const AudioPlayerEditor = (props) => {
    const {
        isFilenameEditing,
        editedFileName,
        onFilenameChange,
        saveEditedFilename,
        toggleFilenameEdit,
        children,
    } = props;
    return isFilenameEditing ? (
        <div className="grid-container">
            <Cell size={6} tabletSize={5} phoneSize={4} className="no-margin">
                <TextField
                    id="text-file-name"
                    placeholder="Greeting Filename"
                    required
                    value={editedFileName}
                    onChange={onFilenameChange}
                />
            </Cell>
            <Cell size={5} tabletSize={3} phoneSize={4} className="no-margin">
                <Button icon onClick={saveEditedFilename} className="update-menu-btn">
                    check_circle_outline
                </Button>
                <Button icon onClick={toggleFilenameEdit} id="close-btn">
                    close
                </Button>
            </Cell>
        </div>
    ) : (
        children
    );
};

const ActionButtons = ({ isEditing, isSaving, hideEdit, onSave, onCancel, markEditing }) => {
    if (isEditing) {
        if (isSaving) {
            return <CircularProgress id="save-ivr-menu-status-spinner" />;
        }
        return (
            <Fragment>
                <Button icon className="update-menu-btn" onClick={onSave}>
                    check_circle_outline
                </Button>
                <Button icon onClick={onCancel} id="close-btn">
                    close
                </Button>
            </Fragment>
        );
    }
    return hideEdit ? null : (
        <Button icon onClick={markEditing} id="edit-btn">
            edit
        </Button>
    );
};

const ConfigureNavigateButton = ({ navURL, history, tooltipText }) => {
    const navigate = () => {
        history.push({ pathname: navURL, state: history.location.state });
    };

    return (
        <Fragment>
            <MUIButton variant="outlined" color="primary" onClick={navigate}>
                Configure
            </MUIButton>
            <Tooltip placement="top" classes={{ tooltip: 'info-tooltip' }} enterTouchDelay={0} title={tooltipText}>
                <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                    info
                </FontIcon>
            </Tooltip>
        </Fragment>
    );
};

const PharmacyLocationSelect = ({
    title,
    labelWidth,
    selectedLocalLocation,
    changeLocalLocation,
    locations,
    selectedPharmacyName,
}) => (
    <FormControl variant="outlined" fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
            value={selectedLocalLocation}
            onChange={changeLocalLocation}
            input={<OutlinedInput fullWidth labelWidth={labelWidth} name="location" id="outlined-location" />}
        >
            <MenuItem value={ALL_LOCATIONS}> All Locations </MenuItem>
            {locations.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                    {`${selectedPharmacyName} - `}
                    {l.displayName}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`report-tabpanel-${index}`}>
            {value === index ? children : ''}
        </Typography>
    );
}

const fabStyles = (theme) => ({
    cssRoot: {
        color: theme.palette.getContrastText('#fff'),
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
});

export {
    CardHeader,
    SectionTitle,
    SectionSubTitle,
    PageTitle,
    PageSubTitle,
    TabPanel,
    AudioPlayerEditor,
    ActionButtons,
    ConfigureNavigateButton,
    fabStyles,
    PharmacyLocationSelect,
};
