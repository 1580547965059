import './ActiveAppointmentsIndicator.sass';
import React from 'react';
import { connect } from 'react-redux';
import AppointmentTypesIcon from './AppointmentTypesIcon';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { selectNumberOfActiveApptTypes } from './apptTypesUtil';

function ActiveAppointmentsIndicator({ activeAppointmentTypesCounts }) {
    const history = useHistory();

    return (
        <div className="active-appointments-indicator">
            <AppointmentTypesIcon size={45} color="#219653" />

            <div className="text">
                <div className="main-line">{activeAppointmentTypesCounts} active appt types</div>
                <Button className="sub-line-button" color="primary" onClick={() => history.push('/appointments/types')}>
                    Manage appointment types
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        activeAppointmentTypesCounts: selectNumberOfActiveApptTypes(state),
    };
}

const bindActionsToDispatch = {};

export default connect(mapStateToProps, bindActionsToDispatch)(ActiveAppointmentsIndicator);
