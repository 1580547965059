import { pharmacyAppsTypes } from './type';
import { pharmacyAppsService } from './service';
import SnackbarActions from '../actions/Snackbar';

// Add your action creators here
const fetchNonBrandedApps = () => (dispatch) => {
    dispatch({ type: pharmacyAppsTypes.FETCH_NON_BRANDED_APPS });

    return pharmacyAppsService
        .fetchNonBrandedApps()
        .then((response) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_NON_BRANDED_APPS_SUCCESS,
                payload: {
                    ...response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_NON_BRANDED_APPS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const fetchPharmacyApps = () => (dispatch, getState) => {
    dispatch({ type: pharmacyAppsTypes.FETCH_PHARMACY_APPS });

    return pharmacyAppsService
        .fetchPharmacyApps({ custId: getState().pharmacy.pharmacy.id })
        .then((response) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_PHARMACY_APPS_SUCCESS,
                payload: {
                    ...response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_PHARMACY_APPS_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const addAppsToPharmacy = ({ appIds }) => (dispatch, getState) => {
    dispatch({ type: pharmacyAppsTypes.ADD_APPS });

    const custId = getState().pharmacy.pharmacy.id;
    return pharmacyAppsService
        .addAppsToPharmacy({ custId, appIds })
        .then(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'App(s) added to the pharmacy.',
                    type: 'success',
                },
            });

            dispatch(fetchPharmacyApps({ custId }));
            dispatch({ type: pharmacyAppsTypes.ADD_APPS_SUCCESS });
        })
        .catch(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to add app(s) to the pharmacy.',
                    type: 'error',
                },
            });
            dispatch({ type: pharmacyAppsTypes.ADD_APPS_FAILED });
        });
};

const addBrandedAppToPharmacy = ({ payload, branchPayload }) => (dispatch, getState) => {
    dispatch({ type: pharmacyAppsTypes.ADD_BRANDED_APP });

    const custId = getState().pharmacy.pharmacy.id;
    return pharmacyAppsService
        .addBrandedAppToPharmacy({ payload })
        .then(async (response) => {
            dispatch(addAppsToPharmacy({ custId, appIds: [response.id] }));
            dispatch({ type: pharmacyAppsTypes.ADD_BRANDED_APP_SUCCESS });
        })
        .catch(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to add app to the pharmacy.',
                    type: 'error',
                },
            });
            dispatch({ type: pharmacyAppsTypes.ADD_BRANDED_APP_FAILED });
        });
};

const deletePharmacyApp = ({ appId }) => (dispatch, getState) => {
    dispatch({ type: pharmacyAppsTypes.DELETE_PHARMACY_APP });

    const custId = getState().pharmacy.pharmacy.id;
    return pharmacyAppsService
        .deletePharmacyApp({ custId, appId })
        .then(() => {
            dispatch(fetchPharmacyApps({ custId }));
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'App deleted/ removed from pharmacy.',
                    type: 'success',
                },
            });
            dispatch({ type: pharmacyAppsTypes.DELETE_PHARMACY_APP_SUCCESS });
        })
        .catch(() => {
            dispatch({
                type: SnackbarActions.SHOW_TOAST,
                data: {
                    text: 'Failed to delete app from pharmacy.',
                    type: 'error',
                },
            });
            dispatch({ type: pharmacyAppsTypes.DELETE_PHARMACY_APP_FAILED });
        });
};

const fetchReleases = () => (dispatch) => {
    dispatch({ type: pharmacyAppsTypes.FETCH_RELEASES });

    return pharmacyAppsService
        .fetchReleases()
        .then((response) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_RELEASES_SUCCESS,
                payload: {
                    ...response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: pharmacyAppsTypes.FETCH_RELEASES_FAILED,
                payload: {
                    error,
                },
            });
        });
};

const updateBrandedApp = ({ appId, payload }) => (dispatch, getState) => {
    const custId = getState().pharmacy.pharmacy.id;
    const showSuccessToast = () => {
        dispatch({
            type: SnackbarActions.SHOW_TOAST,
            data: {
                text: 'App updated successfully.',
                type: 'success',
            },
        });
        dispatch({ type: pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS_SUCCESS });
    };

    const showFailureToast = () => {
        dispatch({
            type: SnackbarActions.SHOW_TOAST,
            data: {
                text: 'Failed to update app.',
                type: 'error',
            },
        });
        dispatch({ type: pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS_FAILED });
    };

    const updateBranchAppInfo = () => {
        const branchId = payload.branchPayload.id;
        if (branchId) {
            return pharmacyAppsService
                .updateBrandedAppBranchInformation({ appId, branchId, payload: payload.branchPayload })
                .then(() => {
                    dispatch(fetchPharmacyApps({ custId }));
                    showSuccessToast();
                })
                .catch(() => showFailureToast());
        } else {
            return pharmacyAppsService
                .addBrandedAppBranchInformation({ appId, payload: payload.branchPayload })
                .then(() => {
                    dispatch(fetchPharmacyApps({ custId }));
                    showSuccessToast();
                })
                .catch(() => showFailureToast());
        }
    };

    dispatch({ type: pharmacyAppsTypes.UPDATING_BRANDED_APP_DETAILS });

    if (payload.generalDetailsPayload) {
        return pharmacyAppsService
            .updateBrandedAppGeneralDetails({ appId, payload: payload.generalDetailsPayload })
            .then(() => {
                if (payload.branchPayload) {
                    updateBranchAppInfo();
                } else {
                    dispatch(fetchPharmacyApps({ custId }));
                    showSuccessToast();
                }
            })
            .catch(() => showFailureToast());
    }

    return updateBranchAppInfo();
};

export const pharmacyAppsActions = {
    addAppsToPharmacy,
    fetchNonBrandedApps,
    addBrandedAppToPharmacy,
    deletePharmacyApp,
    fetchReleases,
    fetchPharmacyApps,
    updateBrandedApp,
};

// Note: do not add default exports, we'll be relying on the named export as best practices
