import axios from 'axios';
import { restActionCreators, get, post, put } from 'redux/actionCreators/rest';
import config from '../../../config';
import api from '../../../api.json';
import { logUtil, logHttpError } from '../../../utils/log';
import _ from 'lodash';

const CancelToken = axios.CancelToken;
let cancelSearch;

const getUsersList = (pharmacyId, locationId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}`)
        .then((response) => {
            return response.users;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on getUsersList for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getUsers = (pharmacyId, locationId, page = 0, pageItems = 50) => {
    const queryString = `page=${page}&pageItems=${pageItems}`;
    return get(`${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity?${queryString}`)
        .then((response) => {
            return response.users_activity;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on getUsers for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getUsersActivityUnreadCount = ({ pharmacyId, locationId, filterType = null }) => {
    const filterQueryString = filterType ? `filterType=${filterType}` : '';
    return get(
        `${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity/unread-count?${filterQueryString}`
    )
        .then(({ unread_count }) => {
            return unread_count;
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on getUsersUnreadCount for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const getLatestUserActivity = (pharmacyId, locationId) => {
    return get(`${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity/latest`)
        .then((response) => {
            if (response.success) {
                return response.latest_message;
            } else {
                return Promise.reject({
                    message: 'failed to get latest user activity',
                });
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occurred on getLatestUserActivity for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const searchUsers = (pharmacyId, locationId, searchString, searchType, page = 0, pageItems = 50, filterType = null) => {
    const searchQueryString = searchString ? `&search=${searchString}&searchType=${searchType}` : '';
    const filterQueryString = filterType ? `&filterType=${filterType}` : '';
    const queryString = `page=${page}&pageItems=${pageItems}${searchQueryString}${filterQueryString}`;
    // Cancel any previous search requests
    if (cancelSearch) {
        cancelSearch();
    }

    return get(`${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity?${queryString}`, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelSearch = c;
        }),
    })
        .then((response) => {
            return response.users_activity;
        })
        .catch((error) => {
            logHttpError(
                `An error occured on searchUsers for pharmacyId: ${pharmacyId} on locationId: ${locationId}`,
                error
            );
            return Promise.reject(error);
        });
};

const createUser = (userData) => {
    return post(`${config.apiweb_url}${api.INBOX_USER}`, userData)
        .then((response) => {
            if (response.success) {
                return response;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError('An error occured on createUser', error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const updateUser = (userData, userId) => {
    return put(`${config.apiweb_url}${api.INBOX_USER}/${userId}`, userData)
        .then((response) => {
            if (response.success) {
                return response.edited_user;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on updateUser for userId: ${userId} at pharmacyId: ${userData.pharmacy_id}
                 and locationId: ${userData.location_id}`,
                error
            );
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const archiveUser = (userId) => {
    return post(`${config.apiweb_url}${api.INBOX_USER}/${userId}/archive`)
        .then((response) => {
            if (response.success) {
                return response;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(`An error occurred on archiveUser for userId: ${userId}`, error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const uploadBulkOriginalCSV = ({
    pharmacyId,
    locationId,
    patientCSV,
    locationName,
    rowCount,
    patientCount,
    success,
}) => {
    const formDataRequestConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const data = new FormData();
    data.append('patient_csv', patientCSV, patientCSV.name);

    const params = _.filter([
        !_.isNil(success) ? `success=${success}` : null,
        _.isFinite(patientCount) ? `patientCount=${patientCount}` : null,
        _.isFinite(rowCount) ? `rowCount=${rowCount}` : null,
        _.isString(locationName) ? `locationName=${encodeURIComponent(locationName)}` : null,
    ]);

    return restActionCreators
        .post(
            `${config.apiweb_url}${api.INBOX_BASE}/users/${pharmacyId}/${locationId}/bulk-original?${params.join('&')}`,
            data,
            formDataRequestConfig
        )
        .then((response) => {
            if (response.success) {
                return true;
            }
            return Promise.reject({});
        })
        .catch((error) => {
            logUtil.logHttpError(`An error occurred on uploadBulkOriginalCSV`, error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const createBulkUsers = (usersData) => {
    return post(`${config.apiweb_url}${api.INBOX_BASE}/users`, usersData)
        .then((response) => {
            if (response.success) {
                return response;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(
                `An error occured on createBulkUsers for pharmacyId: ${usersData[0].pharmacy_id} on locationId: ${usersData[0].location_id}`,
                error
            );
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

const phoneTypeLookup = ({ pharmacyId, locationId, userId }) => {
    return post(
        `${config.apiweb_url}${api.INBOX_USER}/pharmacies/${pharmacyId}/locations/${locationId}/${userId}/phone-type-lookup`
    )
        .then(({ success, phone_type, message }) => {
            if (success) {
                return { phone_type };
            }

            if (message) {
                return Promise.reject({ message });
            }

            return Promise.reject({});
        })
        .catch((error) => {
            logHttpError(`An error occured on phoneTypeLookup for userId: ${userId}`, error);
            error.message = error.message || "There was an error verifying the user's phone type.";
            return Promise.reject(error);
        });
};

const verifyUser = (userId) => {
    return put(`${config.apiweb_url}${api.INBOX_USER}/${userId}/verify`)
        .then((response) => {
            if (response.success) {
                return response.verified_user;
            } else if (response.message) {
                return Promise.reject({
                    message: response.message,
                });
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logHttpError(`An error occured on updateUser for userId: ${userId}`, error);
            error.message = error.message || 'An unknown error has occurred';
            return Promise.reject(error);
        });
};

export const userService = {
    getUsers,
    getUsersList,
    getUsersActivityUnreadCount,
    getLatestUserActivity,
    searchUsers,
    createUser,
    updateUser,
    uploadBulkOriginalCSV,
    createBulkUsers,
    phoneTypeLookup,
    verifyUser,
    archiveUser,
};

export default userService;
