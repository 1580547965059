import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Cell,
    Grid,
    Card,
    CardText,
    CardTitle,
    DialogContainer,
    Button as RMDButton,
} from 'react-md';
import { TextField, Button } from '@material-ui/core';

import { updatePassword, updateUserAccount } from 'redux/actionCreators/Auth';
import { CardHeader } from 'components/Common/index';
import ChangePasswordInput from 'components/ChangePasswordInput';
import { displayToast } from 'redux/actionCreators/Snackbar';
import goBackService from 'utils/goBackService';
import { getPasswordErrMsg } from '../../../utils/password';
import { Can, userActions, restrictedResources } from 'casl';

export class AccountDetails extends Component {
    constructor(props) {
        super(props);
        const {
            auth: {
                userAccount: { first_name, last_name },
            },
        } = props;
        this.validRule = {};
        this.state = {
            resetPwdVisible: false,
            pwdFormErrMsg: '',
            firstName: first_name,
            lastName: last_name,
            isDirty: false,
            isBeingSaved: false,
        };
    }

    onBack = () => {
        const { history } = this.props;
        goBackService.toGeneralHome(history);
    };

    toggleResetPwd = () => {
        const { resetPwdVisible } = this.state;
        this.setState({ resetPwdVisible: !resetPwdVisible });
    };

    handlePwdChange = (validRule, newpassword) => {
        const { updateErr, errors } = this.props;
        if (errors) {
            updateErr('');
        }
        this.validRule = validRule;
        this.newpassword = newpassword;
        this.setState({
            pwdFormErrMsg: '',
        });
    };

    saveNewPassword = (e) => {
        e.preventDefault();
        const { updatePassword, displayToast } = this.props;

        const pwdFormErrMsg = getPasswordErrMsg(this.newpassword, this.validRule);

        if (pwdFormErrMsg) {
            this.setState({
                pwdFormErrMsg,
            });
        } else {
            updatePassword({ password: this.newpassword }).then((resp) => {
                if ('data' in resp) {
                    this.toggleResetPwd();
                    displayToast({ text: 'Your password has been updated', type: 'success' });
                }
            });
        }
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            isDirty: true,
        });
    };

    onSave = () => {
        const {
            updateUserAccount,
            auth: { userAccount },
            displayToast,
        } = this.props;
        const { firstName, lastName } = this.state;
        this.setState({ isBeingSaved: true, isDirty: false });
        updateUserAccount({
            user_id: userAccount.user_id,
            first_name: firstName,
            last_name: lastName,
        }).then(
            () => {
                displayToast({ text: `User details updated successfully`, type: 'success' });
                this.setState({ isBeingSaved: false });
            },
            () => {
                displayToast({ text: `User details update failed`, type: 'error' });
                this.setState({ isBeingSaved: false });
            }
        );
    };

    render() {
        const {
            auth: { userAccount, updatePwdInprogress },
        } = this.props;
        const { resetPwdVisible, pwdFormErrMsg, firstName, lastName, isDirty, isBeingSaved } = this.state;
        const { update } = userActions;
        const { subject: caslSubject, fields: restrictedFields } = restrictedResources.generalSettings.accountDetails;

        return (
            <Grid className="account-details">
                <Cell size={12}>
                    <Card className="outer-card">
                        <CardHeader
                            title="Account Details"
                            onBack={this.onBack}
                            onSave={this.onSave}
                            disabled={!isDirty}
                        />
                        <CardText>
                            <Card className="inner-card">
                                <CardText>
                                    <div className="grid-container">
                                        <Can I={update} this={caslSubject} field={restrictedFields.name} passThrough>
                                            {(allowed) => (
                                                <Fragment>
                                                    <Cell size={3} tabletSize={3} phoneSize={4}>
                                                        <TextField
                                                            name="firstName"
                                                            label="First Name"
                                                            value={firstName || undefined}
                                                            variant="outlined"
                                                            onChange={this.handleInputChange}
                                                            fullWidth
                                                            disabled={isBeingSaved || !allowed}
                                                        />
                                                    </Cell>
                                                    <Cell size={3} tabletSize={3} phoneSize={4}>
                                                        <TextField
                                                            name="lastName"
                                                            label="Last Name"
                                                            value={lastName || undefined}
                                                            variant="outlined"
                                                            onChange={this.handleInputChange}
                                                            fullWidth
                                                            disabled={isBeingSaved || !allowed}
                                                        />
                                                    </Cell>
                                                </Fragment>
                                            )}
                                        </Can>
                                    </div>
                                    <div className="grid-container">
                                        <Cell size={6} tabletSize={6} phoneSize={4}>
                                            <TextField
                                                name="email"
                                                label="Email"
                                                defaultValue={userAccount ? userAccount.email : ''}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                            />
                                        </Cell>
                                    </div>
                                    <div className="grid-container">
                                        <Can
                                            I={update}
                                            this={caslSubject}
                                            field={restrictedFields.changePassword}
                                            passThrough
                                        >
                                            {(allowed) => (
                                                <Cell size={4} tabletSize={3} phoneSize={4}>
                                                    <Button
                                                        id="changePassword"
                                                        variant="outlined"
                                                        color="primary"
                                                        size="large"
                                                        onClick={this.toggleResetPwd}
                                                        disabled={!allowed}
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Cell>
                                            )}
                                        </Can>
                                    </div>
                                </CardText>
                            </Card>
                        </CardText>
                    </Card>
                    <DialogContainer
                        id="change-pwd-dialog"
                        aria-describedby="change-pwd-dialog"
                        visible={resetPwdVisible}
                        onHide={this.toggleResetPwd}
                        closeOnEsc={false}
                        modal
                    >
                        {updatePwdInprogress && (
                            <div className="full-width progress-container">
                                <CircularProgress id="reset-password-loader" scale={2} />
                            </div>
                        )}
                        <CardTitle title="Change Password" subtitle="Enter new password to continue" />
                        <form noValidate autoComplete="off" onSubmit={this.saveNewPassword}>
                            <div className="change-pwd-form-container">
                                <ChangePasswordInput
                                    errMsg={pwdFormErrMsg}
                                    handlePwdChange={(validRule, newpassword) =>
                                        this.handlePwdChange(validRule, newpassword)
                                    }
                                />
                            </div>
                            <div style={{ marginTop: '34px' }} className="flex-right pwd-btn">
                                <RMDButton flat primary onClick={this.toggleResetPwd}>
                                    Cancel
                                </RMDButton>
                                <RMDButton type="submit" raised primary>
                                    Finish
                                </RMDButton>
                            </div>
                        </form>
                        <RMDButton icon onClick={this.toggleResetPwd} className="dialog-close-btn">
                            close
                        </RMDButton>
                    </DialogContainer>
                </Cell>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    updatePassword: (data) => dispatch(updatePassword(data)),
    updateUserAccount: (data) => dispatch(updateUserAccount(data)),
    displayToast: (data) => dispatch(displayToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
