import React from 'react';
import { Button, Icon } from '@material-ui/core';
import { isUrl } from 'utils/helper';
import { useSlate } from 'slate-react';
import { Editor, Transforms, Range, Element as SlateElement } from 'slate';

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};

const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    return !!link;
};

const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
};

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export const withLinks = (editor) => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = (element) => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.insertText = (text) => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = (data) => {
        const text = data.getData('text/plain');

        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

const LinkButton = () => {
    const editor = useSlate();
    const isActive = isLinkActive(editor);

    return (
        <Button
            className={isActive ? 'active' : ''}
            onMouseDown={(event) => {
                if (isActive) {
                    unwrapLink(editor);
                } else {
                    event.preventDefault();
                    const url = window.prompt('Enter the URL of the link:');
                    if (url && isUrl(url)) {
                        insertLink(editor, url);
                    } else {
                        return;
                    }
                }
            }}
        >
            <Icon>link</Icon>
        </Button>
    );
};

export default LinkButton;
