import moment from 'moment';

export const mapTabularData = (dataToMap) => {
    const {
        data: { data },
        requestParams,
    } = dataToMap;
    const keys = Object.keys(data);
    const values = Object.values(data);
    const mappedData = {
        attributes: keys,
        data: [],
    };

    requestParams.titles.forEach((title, i) => {
        mappedData.data.push({
            title,
            values: values.map((v) => v[i]),
        });
    });

    return mappedData;
};

export const mapTabularDataWithObject = (dataToMap, attributes, titles) => {
    const {
        data: { data },
    } = dataToMap;
    const mappedData = {
        attributes,
        data: [],
    };

    titles.forEach((title, i) => {
        mappedData.data.push({
            title,
            values: Object.values(data[i]),
        });
    });

    return mappedData;
};

export const sortByKeys = (objToSort, sortedArray) => {
    return JSON.parse(JSON.stringify(objToSort, sortedArray));
};

export const TIME_FORMAT = {
    SECONDS: 'seconds',
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days',
};

export const formatTime = (timeInSeconds) => {
    if (timeInSeconds < 60) {
        return {
            type: TIME_FORMAT.SECONDS,
            value: timeInSeconds,
        };
    }

    const duration = moment.duration(timeInSeconds, 'seconds');

    if (duration.asDays() >= 1) {
        return {
            type: TIME_FORMAT.DAYS,
            value: duration.asDays(),
        };
    } else if (duration.asHours() >= 1) {
        return {
            type: TIME_FORMAT.HOURS,
            value: duration.asHours(),
        };
    } else if (duration.asMinutes() >= 1) {
        return {
            type: TIME_FORMAT.MINUTES,
            value: duration.asMinutes(),
        };
    }
};
