import './EditContactDialog.sass';
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    DialogContentText,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BirthdayMask, PhoneNumberMask } from '../../utils/mask';
import config from '../../config';
import {
    toDigits,
    isEmptyString,
    formatRawDate,
    formatRawPhone,
    isUnmessageablePhoneType,
    getDateInputFormatByCountryCode,
} from '../../utils/helper';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import { getReservationsForInboxUserId } from 'redux/Appt/Reservation/action';
import DeleteIcon from '@material-ui/icons/Delete';
import pluralize from 'pluralize';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const EditDialogMode = {
    edit: 'edit',
    archive: 'archive',
};

function EditContactDialogContent(props) {
    return (
        <>
            <DialogTitle style={{ paddingBottom: '5px' }}>Edit patient</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: 'left' }}>
                    Before making any changes, be sure to verify the details as it is used to confirm the patient's
                    identity. Also, if you change the phone number, the patient will be able to see the previous
                    conversations.
                </DialogContentText>
                <TextField
                    type="text"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={props.firstName}
                    error={!!props.errors.firstName}
                    helperText={props.errors.firstName}
                    {...props.commonProps}
                />
                <TextField
                    type="text"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={props.lastName}
                    error={!!props.errors.lastName}
                    helperText={props.errors.lastName}
                    {...props.commonProps}
                />
                <TextField
                    type="text"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    label="Date Of Birth"
                    placeholder={props.dateFormat}
                    value={props.dateOfBirth}
                    InputProps={{
                        inputComponent: BirthdayMask,
                        inputProps: {
                            pattern: '[0-9]*',
                            inputMode: 'numeric',
                        },
                    }}
                    error={!!props.errors.dateOfBirth}
                    helperText={props.errors.dateOfBirth}
                    {...props.commonProps}
                />
                <TextField
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Cell Phone Number"
                    value={props.phoneNumber}
                    InputProps={{
                        inputComponent: PhoneNumberMask,
                        inputProps: {
                            pattern: '[0-9]*',
                            inputMode: 'numeric',
                            countryCode: props.activeLocationCountryCode,
                        },
                    }}
                    focused={!!props.unmessageableMessage}
                    error={!!props.errors.phoneNumber}
                    helperText={props.errors.phoneNumber || props.unmessageableMessage}
                    {...props.commonProps}
                />
            </DialogContent>
            <DialogContent>
                {props.requestError ? <FormHelperText error>{props.requestError}</FormHelperText> : ''}
                <DialogActions>
                    <Button onClick={() => props.changeMode(EditDialogMode.archive)} color="secondary">
                        Archive
                    </Button>
                    <div style={{ flex: '1 0 0' }} />
                    <Button onClick={props.handleClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={props.handleSubmit} color="primary" disabled={props.sending} variant="outlined">
                        {props.sending ? <CircularProgress size={20} /> : 'Done'}
                    </Button>
                </DialogActions>
            </DialogContent>
        </>
    );
}

function ArchiveContactDialogContent(props) {
    const { upcomingAppointments } = props;

    /* eslint-disable */
    return (
        <>
            <DialogTitle style={{ paddingBottom: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteIcon style={{ color: '#E87A80', marginRight: '5px' }} />
                    <span>Really Archive this patient?</span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: 'left' }}>
                    By archiving{' '}
                    <strong>
                        {props.firstName} {props.lastName}
                    </strong>
                    , you will remove them from your list of active patients. Archiving does not delete patient data,
                    conversations, or appointments, it just hides them.
                </DialogContentText>
                <DialogContentText>
                    Please{' '}
                    <a
                        onClick={props.handleContactSupport}
                        style={{
                            textDecoration: 'underline',
                            color: '#3793ff',
                            cursor: 'pointer',
                        }}
                    >
                        contact support
                    </a>{' '}
                    if a patient needs to be permanently deleted.
                </DialogContentText>

                {_.isEmpty(upcomingAppointments) ? null : (
                    <div className="upcoming-appointments-warning">
                        <WarningIcon />
                        <div className="text">
                            This patient has {_.size(upcomingAppointments)} future{' '}
                            {pluralize('appointment', _.size(upcomingAppointments))} that will be hidden from you if you
                            archive them.
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogContent>
                {props.requestError ? <FormHelperText error>{props.requestError}</FormHelperText> : ''}
                <DialogActions>
                    <Button onClick={() => props.changeMode(EditDialogMode.edit)} color="default">
                        <ArrowBackIcon style={{ fontSize: '18px' }} />
                        &nbsp;Back to Edit
                    </Button>
                    <div style={{ flex: '1 0 0' }} />
                    <Button onClick={props.handleClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={props.archiveUser} color="secondary" disabled={props.sending} variant="outlined">
                        {props.sending ? <CircularProgress size={20} /> : 'Archive'}
                    </Button>
                </DialogActions>
            </DialogContent>
        </>
    );
    /* eslint-enable */
}

class EditContactDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: EditDialogMode.edit,
            errors: {},
        };

        this.commonProps = {
            fullWidth: true,
            variant: 'outlined',
            margin: 'normal',
            required: true,
            onChange: this.handleInputChange,
        };
    }

    setInitialState = () => {
        const initialContact = {
            active: this.props.active,
            inboxUser: this.props.contact.inbox_user_id,
            firstName: this.props.contact.first_name,
            lastName: this.props.contact.last_name,
            dateOfBirth: formatRawDate(this.props.contact.date_of_birth, 'YYYY-MM-DD', this.props.dateFormat),
            phoneNumber: this.props.contact.phone,
            phoneType: this.props.contact.phone_type,
            mode: EditDialogMode.edit,
            upcomingAppointments: null,
            errors: {},
        };

        this.setState({
            ...initialContact,
        });

        this.props.getReservationsForInboxUserId(this.props.contact.inbox_user_id).then((response) => {
            const utcNow = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
            const upcomingAppointments = _.filter(
                _.get(response, 'reservations'),
                ({ status, start_date }) => status === 'ACTIVE' && utcNow <= start_date
            );

            this.setState({ upcomingAppointments });
        });
    };

    componentDidMount = () => {
        this.setInitialState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.getValidationErrors();
        this.setState({
            [name]: value,
        });
    };

    componentDidUpdate = (prevProps) => {
        const { contact } = this.props;
        if (contact && prevProps.contact && contact.inbox_user_id !== prevProps.contact.inbox_user_id) {
            this.setInitialState();
        }
    };

    changeMode = (modeType) => {
        this.setState({
            mode: EditDialogMode[modeType] || EditDialogMode.edit,
        });
    };

    validateDate = (rawDate) => {
        const { dateFormat } = this.props;
        const date = moment(rawDate, dateFormat);
        const tomorrow = moment(new Date()).add(1, 'days');
        const baseDate = moment('01/01/1900', dateFormat);
        if (!date.isValid()) {
            return 'Please enter a valid date';
        } else if (!date.isBetween(baseDate, tomorrow)) {
            return `Date needs to be between ${baseDate.format(dateFormat)} - ${tomorrow.format(dateFormat)}`;
        }

        return false;
    };

    validatePhoneNumber = (phoneNumber) => {
        if (toDigits(phoneNumber).length < 10) {
            return 'Please enter a 10 digit phone number';
        }

        return false;
    };

    getValidationErrors = () => {
        const { firstName, lastName, dateOfBirth, phoneNumber } = this.state;
        const errors = {};
        const emptyMessage = 'This field is required';

        errors.firstName = isEmptyString(firstName) ? emptyMessage : false;
        errors.lastName = isEmptyString(lastName) ? emptyMessage : false;
        errors.dateOfBirth = isEmptyString(dateOfBirth) ? emptyMessage : this.validateDate(dateOfBirth);
        errors.phoneNumber = isEmptyString(phoneNumber) ? emptyMessage : this.validatePhoneNumber(phoneNumber);

        // Remove all values that are false
        Object.keys(errors).forEach((key) => errors[key] === false && delete errors[key]);
        return errors;
    };

    checkInputChanges = () => {
        const { contact, dateFormat } = this.props;
        const { first_name, last_name, date_of_birth, phone } = contact;
        const { firstName, lastName, dateOfBirth, phoneNumber } = this.state;
        const edited_values = {};
        if (firstName !== first_name) {
            edited_values['first_name'] = firstName;
        }
        if (lastName !== last_name) {
            edited_values['last_name'] = lastName;
        }
        const formattedDate = formatRawDate(dateOfBirth, dateFormat, 'YYYY-MM-DD');
        if (formattedDate !== date_of_birth) {
            edited_values['date_of_birth'] = formattedDate;
        }
        if (phoneNumber !== phone) {
            edited_values['phone'] = toDigits(phoneNumber);
        }

        return edited_values;
    };

    buildPayload = (edited_values) => {
        const payload = {
            pharmacy_id: config.X_PharmacyID,
            location_id: config.X_LocationID,
            edited_values: edited_values,
        };
        return payload;
    };

    handleSubmit = () => {
        const { updateContact, contact } = this.props;
        const userId = contact.inbox_user_id;
        // Validate inputs
        const errors = this.getValidationErrors();
        if (!_.isEmpty(errors)) {
            // Validation failed
            this.setState({ errors });
        } else {
            const edited_values = this.checkInputChanges();
            if (Object.keys(edited_values).length === 0) {
                //TODO: we need to message the user in this case
            } else {
                // Validation passed
                updateContact(this.buildPayload(edited_values), userId);
                this.setState({
                    errors: {},
                });
            }
        }
    };

    handleClose = () => {
        const { closeDialog } = this.props;
        this.setInitialState();
        closeDialog();
    };

    handleContactSupport = () => {
        const { firstName, lastName, phoneNumber } = this.state;
        window.Intercom(
            'showNewMessage',
            `We'd like to permanently delete ${firstName} ${lastName} [${formatRawPhone(
                phoneNumber
            )}] from our patient list.`
        );
    };

    archiveUser = () => {
        const { archiveContact } = this.props;
        const { inboxUser } = this.state;
        archiveContact(inboxUser);
    };

    render() {
        const {
            visible,
            sending,
            requestError,
            activeLocationCountryCode,
            dateFormat,
            getReservationsForInboxUserId,
        } = this.props;
        const { phone_type, phone } = this.props.contact;
        const { firstName, lastName, phoneNumber, dateOfBirth, errors, mode } = this.state;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const unmessageableMessage =
            isUnmessageable && phoneNumber && toDigits(phoneNumber) === phone
                ? 'The provided number is not a cell phone number'
                : '';

        const sharedContentProps = {
            firstName,
            lastName,
            sending,
            requestError,
            errors,
            handleClose: this.handleClose,
            changeMode: this.changeMode,
            commonProps: this.commonProps,
        };

        const editContentProps = {
            phoneNumber,
            dateOfBirth,
            activeLocationCountryCode,
            dateFormat,
            isUnmessageable,
            unmessageableMessage,
            handleSubmit: this.handleSubmit,
            ...sharedContentProps,
        };

        const archiveContentProps = {
            handleContactSupport: this.handleContactSupport,
            archiveUser: this.archiveUser,
            ...sharedContentProps,
            getReservationsForInboxUserId,
            inbox_user_id: this.props.contact.inbox_user_id,
            upcomingAppointments: this.state.upcomingAppointments,
        };

        return (
            <Fragment>
                <Dialog id="editUserDialog" open={visible} onClose={this.handleClose} maxWidth="xs">
                    {mode === EditDialogMode.edit && <EditContactDialogContent {...editContentProps} />}
                    {mode === EditDialogMode.archive && <ArchiveContactDialogContent {...archiveContentProps} />}
                </Dialog>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const activeLocationCountryCode = pharmacySelectors.pharmacyActiveLocationCountryCodeSelector(state);
    return {
        activeLocationCountryCode,
        dateFormat: getDateInputFormatByCountryCode({ countryCode: activeLocationCountryCode }),
    };
}

const bindActionsToDispatch = {
    getReservationsForInboxUserId,
};

export default connect(mapStateToProps, bindActionsToDispatch)(EditContactDialog);
