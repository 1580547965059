import './VaccineDoseInfoContent.sass';
import { Select, MenuItem, Button, FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { getHowToScheduleConfigFromInboxConversationTemplate } from '../schedulingUtil';
import Snowplow from 'snowplow';

export const VACCINE_COLORS = {
    pfizer: '#017bc2',
    'johnson-and-johnson': '#f44336',
    moderna: '#4dd0e1',
};

export const VACCINES_AND_LABELS = {
    pfizer: 'Pfizer',
    moderna: 'Moderna',
    'johnson-and-johnson': 'Johnson & Johnson',
};

export default function VaccineDoseInfoContent({
    doseInfo,
    setDoseInfo,
    onBack,
    onNext,
    template,
    isCreatingNew,
    reservationSession,
    setReservationSession,
    hasReservationSessionChanged,
    nextButtonText = 'Next',
}) {
    const labelRef = useRef();
    const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

    const [isCreating, setIsCreating] = useState(false);
    const [numberOfDoses, setNumberOfDoses] = useState(_.get(doseInfo, 'number_of_doses', ''));

    useEffect(() => {
        Snowplow.pageView('Schedule Workflow - Vaccine Info');
    }, []);

    const [, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    const schedulingConfig = _.isNil(template)
        ? {
              scheduling_type: 'dp-clinical',
              appointment_label: 'COVID-19 Vaccination',
          }
        : getHowToScheduleConfigFromInboxConversationTemplate(template);

    const debouncedSetNumberOfDoses = useMemo(
        () =>
            _.debounce((val) => {
                setDoseInfo({
                    ...doseInfo,
                    number_of_doses: val,
                });
                if (!_.isNil(reservationSession)) {
                    const clone = _.cloneDeep(reservationSession);
                    clone.metadata.covid_dose_info = {
                        ...doseInfo,
                        number_of_doses: val,
                    };
                    clone.total_reservations = val;
                    setReservationSession(clone);
                }
            }, 500),
        [doseInfo, reservationSession]
    );

    return (
        <div className="vaccine-dose-info-content">
            <div className="question">
                <div className="heading">Which type of COVID-19 Vaccine will you be administering?</div>
                <div className="sub-heading">
                    Please choose which type of vaccine you will be administering
                    {schedulingConfig.scheduling_type === '3rd-party'
                        ? ' during the dates that may be booked on your scheduling tool.'
                        : ' on the specified scheduling dates. If you have more than one type, you can create multiple Appointment Blocks.'}
                </div>
                <FormControl variant="outlined">
                    <InputLabel ref={labelRef}>Vaccine Type</InputLabel>
                    <Select
                        className="vaccine-type-select"
                        variant="outlined"
                        labelWidth={labelWidth}
                        value={_.get(doseInfo, 'vaccine_type', '')}
                        disabled={!isCreatingNew}
                        onChange={(e) => {
                            setDoseInfo({
                                ...doseInfo,
                                vaccine_type: e.target.value,
                            });
                        }}
                    >
                        {_.map(_.keys(VACCINES_AND_LABELS), (key) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {VACCINES_AND_LABELS[key]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>

            <div className="question">
                <div className="heading">How many doses of this vaccine will be available?</div>
                <div className="sub-heading">
                    Please specify the maximum number of doses of this vaccine you will be able to administer
                    {schedulingConfig.scheduling_type === '3rd-party'
                        ? ' during the dates that may be booked on your scheduling tool.'
                        : ' during the specified scheduling dates. Once this number of appointments have been made, no more will be allowed on this Appointment Block.'}
                </div>

                <TextField
                    className="dose-count-text-field"
                    variant="outlined"
                    label="Number of doses"
                    type="number"
                    value={numberOfDoses}
                    onChange={(e) => {
                        const val = Number(e.target.value);
                        setNumberOfDoses(val);
                        debouncedSetNumberOfDoses(val);
                    }}
                />
            </div>

            <div className="buttons">
                <Button className="back-button" variant="outlined" onClick={onBack}>
                    Back
                </Button>
                <Button
                    className="next-button"
                    variant="contained"
                    color="primary"
                    disabled={
                        _.isNil(_.get(doseInfo, 'vaccine_type')) ||
                        !_.isFinite(_.get(doseInfo, 'number_of_doses')) ||
                        isCreating ||
                        (!_.isNil(reservationSession) && !hasReservationSessionChanged)
                    }
                    onClick={() => {
                        setIsCreating(true);
                        onNext();
                    }}
                >
                    {nextButtonText}
                </Button>
            </div>
        </div>
    );
}
