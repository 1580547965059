import './ReviewBulkMessagePane.sass';
import React, { useState, useEffect } from 'react';
import { renderTemplateText } from './Connect/ConnectCard';
import PatientsList from './PatientsList';
import numeral from 'numeral';
import pluralize from 'pluralize';
import _ from 'lodash';
import PhoneTypeLookupPane from './PhoneTypeLookupPane';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import { Snackbar, Button, Tooltip } from '@material-ui/core';
import { canMessagePatient, getTemplateTitle, getTemplateImageUrl } from './patientsUtil';
import { preprocessMessageTemplateText } from './messageTemplateUtil';
import { connect } from 'react-redux';

import { InlineYesNoQuestionPrompt, FullScreenStackedForm } from '@digitalpharmacist/messaging-forms';
import { fetchAssessmentTemplate } from 'redux/Patient/Assessment/action';

function ReviewBulkMessagePane({
    selectedTemplate,
    selectedUsers,
    nameFormat,
    auth,
    fetchAssessmentTemplate,
    assessment,
}) {
    const [tableHeight, setTableHeight] = useState(120);
    const [acknowledgedUnmessaged, setAcknowledgedUnmessaged] = useState(false);
    const [assessmentInlineYesNoAnswer, setAssessmentInlineYesNoAnswer] = useState(undefined);

    const assessmentId = getAssessmentId(selectedTemplate);

    useEffect(() => {
        if (!_.isEmpty(selectedUsers)) {
            setTableHeight(Math.min(_.size(selectedUsers) * 40, window.innerHeight - 400));
        } else {
            setTableHeight(120);
        }
    }, [_.size(selectedUsers)]);

    useEffect(() => {
        if (!_.isNil(assessmentId) && _.isNil(_.get(assessment, `assessmentTemplates.${assessmentId}`))) {
            fetchAssessmentTemplate(assessmentId);
        }
    }, [assessmentId]);

    const uncheckedPhoneTypePatients = _.filter(selectedUsers, ({ phone_type }) => _.isNil(phone_type));
    const unmessageablePhoneTypePatients = _.filter(selectedUsers, (p) => !canMessagePatient(p));

    if (!_.isEmpty(uncheckedPhoneTypePatients)) {
        return <PhoneTypeLookupPane patients={selectedUsers} uncheckedPatients={uncheckedPhoneTypePatients} />;
    }

    const patientCount = _.size(selectedUsers);

    const unmessageableCount = _.size(unmessageablePhoneTypePatients);

    return (
        <div className="review-bulk-message-pane">
            {
                <Snackbar
                    className="unmessageable-snackbar"
                    open={!_.isEmpty(unmessageablePhoneTypePatients) && !acknowledgedUnmessaged}
                    onClose={() => setAcknowledgedUnmessaged(true)}
                    message={
                        <div className="unmessageable-patients-banner">
                            <PhonelinkRingIcon className="phone-lookup-icon" />
                            <div className="text">
                                <div className="main-line">
                                    Disabled {numeral(unmessageableCount).format('0,0')} message{' '}
                                    {pluralize('recipient', unmessageableCount)} due to having non-mobile numbers.
                                </div>
                                <div className="sub-line">
                                    You can fix this by editing and adding a valid mobile number for each disabled
                                    patient
                                </div>
                            </div>
                        </div>
                    }
                    action={<Button onClick={() => setAcknowledgedUnmessaged(true)}>OK</Button>}
                />
            }
            <div className="review-bulk-message-pane-content">
                <div className="selected-template-pane">
                    <div className="selected-template-pane-inner">
                        <div className="subject-and-message">
                            <div className="subject">
                                <div
                                    className="image-box"
                                    style={{
                                        backgroundImage: `url(${getTemplateImageUrl(selectedTemplate)})`,
                                        backgroundColor: '#eee',
                                        backgroundSize: 'cover',
                                        width: '150px',
                                        height: '100px',
                                    }}
                                />

                                <div className="title-text">
                                    <div className="title">{getTemplateTitle(selectedTemplate)}</div>
                                    <div className="how-it-works">
                                        <strong>Note:</strong> Sending this message will start a new inbox conversation
                                        with each selected patient with the following subject and message. Any replies
                                        to these messages will show up in your Inbox.
                                    </div>
                                </div>
                            </div>
                            <div className="subject-text">
                                <span className="label">Subject: </span>
                                {selectedTemplate.subject}
                            </div>
                            <div className="message-text">
                                {renderTemplateText(preprocessMessageTemplateText(selectedTemplate.message, auth))}
                                {!isYesNoQuestionAssessment(selectedTemplate) ? null : (
                                    <InlineYesNoQuestionPrompt
                                        value={assessmentInlineYesNoAnswer}
                                        updateAnswer={(a) => setAssessmentInlineYesNoAnswer(a)}
                                    />
                                )}
                            </div>

                            {!isYesNoQuestionAssessment(selectedTemplate) ||
                            assessmentInlineYesNoAnswer !== 'yes' ? null : (
                                <SimulatedAssessment
                                    selectedTemplate={selectedTemplate}
                                    assessment={_.get(
                                        assessment,
                                        `assessmentTemplates.${assessmentId}.response.assessment_template`
                                    )}
                                />
                            )}

                            {isSimpleSchedulingMessage(selectedTemplate) && (
                                <SimulatedSimpleScheduler selectedTemplate={selectedTemplate} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="patients-area">
                    <div className="patients-title">
                        {numeral(patientCount - unmessageableCount).format('0,0')} Message{' '}
                        {pluralize('Recipient', patientCount - unmessageableCount)}
                    </div>
                    <PatientsList
                        key={'review-patient-list'}
                        showUnmessageable
                        selectedUsers={selectedUsers}
                        width={300}
                        height={tableHeight}
                        nameFormat={nameFormat}
                        removeDisabled={true}
                    />
                </div>
            </div>
        </div>
    );
}

export function isYesNoQuestionAssessment(selectedTemplate) {
    return _.isString(_.get(selectedTemplate, 'message_content.messaging_form.yes_no_prompt_question'));
}

export function isSimpleSchedulingMessage(selectedTemplate) {
    return _.isString(_.get(selectedTemplate, 'message_content.messaging_form.appt_type_id'));
}

export function getAssessmentId(selectedTemplate) {
    return _.get(selectedTemplate, 'message_content.messaging_form.assessment_id');
}

export function SimulatedAssessment({ selectedTemplate, assessment }) {
    const [isOpen, setIsOpen] = useState(false);

    const ignoreFirst = isYesNoQuestionAssessment(selectedTemplate);

    const formDefinition = {
        questions: _.filter(
            _.map(_.get(assessment, 'questions'), (q, i) => {
                if (ignoreFirst && i === 0) {
                    return;
                }
                return {
                    id: q.patient_assessment_template_question_id,
                    type: q.question_type,
                    prompt: q.question_text,
                };
            })
        ),
        answers: {},
    };

    return (
        <div className="message-text" style={{ marginTop: '10px' }}>
            {!isOpen ? null : (
                <div className="messaging-form-background-wrapper">
                    <div className="phone-wrapper">
                        <div className="explanation">What patients will see fullscreen on their mobile device</div>
                    </div>
                </div>
            )}
            <FullScreenStackedForm
                title={_.get(selectedTemplate, 'message_content.action_title')}
                formDefinition={{ ...formDefinition, answers: {} }}
                onFullScreenOpen={setIsOpen}
                onSend={() => {
                    return Promise.resolve();
                }}
            />
        </div>
    );
}

function SimulatedSimpleScheduler({ selectedTemplate }) {
    return (
        <div className="message-text" style={{ marginTop: '10px' }}>
            <Tooltip title="Clicking this button will open the appointment scheduler on the patients mobile device">
                <Button variant="contained" color="primary" fullWidth>
                    {_.get(selectedTemplate, 'message_content.action_title', 'Schedule an appointment')}
                </Button>
            </Tooltip>
        </div>
    );
}

const bindActionsToDispatch = {
    fetchAssessmentTemplate,
};
function mapStateToProps(state) {
    const { auth, patientData, assessment } = state;
    return {
        auth,
        // We must send this along even if it is not being used since it will trigger phone-type-lookup re-rendering
        patientData,
        assessment,
    };
}
export default connect(mapStateToProps, bindActionsToDispatch)(ReviewBulkMessagePane);
