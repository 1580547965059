import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

export const ConfirmResetIVR = ({ showDialog, onCloseHandler, confirmResetHandler }) => {
    return (
        <Dialog fullWidth maxWidth="xs" open={showDialog} onClose={onCloseHandler}>
            <DialogTitle>Reset IVR</DialogTitle>
            <DialogContent>
                You're about to reset all IVR configuration. Are you sure you want to make this change?
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseHandler}>Cancel</Button>
                <Button variant="contained" color="primary" autoFocus onClick={confirmResetHandler}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
