import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Doughnut } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const donutOptions = {
    cutoutPercentage: 75,
};

const refillCountData = {
    labels: ['Web', 'Mobile'],
    datasets: [
        {
            data: [],
            backgroundColor: ['#f7b522', '#1bb489', '#377bff'],
            hoverBackgroundColor: ['#f7b522', '#1bb489', '#377bff'],
        },
    ],
};

export const RefillCountWidget = ({ data, isLoading }) => {
    return (
        <Card className="widget">
            <CardContent className="height100">
                {isLoading ? (
                    <div className="centered-content height100">
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    <Fragment>
                        <Typography className="title" gutterBottom>
                            Refill Count
                        </Typography>
                        <Doughnut
                            data={{
                                ...refillCountData,
                                datasets: [
                                    {
                                        ...refillCountData.datasets[0],
                                        data,
                                    },
                                ],
                            }}
                            options={donutOptions}
                            legend={{ position: 'bottom' }}
                            width={212}
                            height={212}
                        />
                    </Fragment>
                )}
            </CardContent>
        </Card>
    );
};

export default RefillCountWidget;
