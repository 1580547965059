import React, { useState, useEffect } from 'react';
import { Divider, DialogContainer, Button, CircularProgress } from 'react-md';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import _ from 'lodash';
import Snowplow from 'snowplow/Snowplow';

const ChangeLocation = (props) => {
    const {
        visible,
        onHide,
        changeLocation,
        selectedLocation,
        selectedLocationName,
        changeFavLocation,
        favLocation,
        allLocations,
        updatingUserPreferences,
        loadingUserPreferences,
    } = props;
    const locations = _.filter(_.values(allLocations), (v) => v.id !== selectedLocation.id);
    const [localFavLocationId, setLocalFavLocationId] = useState(null);

    useEffect(() => {
        setLocalFavLocationId(favLocation);
    }, [favLocation]);

    const onSelect = (cus) => {
        changeLocation(cus.id, cus.name);
        onHide();
    };

    const onFavClick = (e, newFavoriteLocationId) => {
        e.stopPropagation();
        if (updatingUserPreferences) return;

        let snowplowProperty = 'Selected Favorite Location';
        // Current favorite location was toggled. deselect location
        if (newFavoriteLocationId === favLocation) {
            newFavoriteLocationId = null;
            snowplowProperty = 'Removed Favorite Location';
        } else {
            // avoid setting localFavLocationId to null from here, we'd want this to display spinner for the right row
            setLocalFavLocationId(newFavoriteLocationId);
        }
        changeFavLocation(newFavoriteLocationId);
        Snowplow.structEvent('Button Click', 'favorite-select', null, 'location-favorite', snowplowProperty);
    };

    return (
        <DialogContainer
            id="change-business-location-modal"
            className="change-location"
            visible={visible}
            onHide={onHide}
            title="Change Location"
            focusOnMount={false}
            width={520}
        >
            <Button icon onClick={onHide} className="dialog-close-btn">
                close
            </Button>
            <div className="sub-title">
                Select which location you would like to work from. Changing the location will show the Inbox messages
                and voicemails for that specific store.
            </div>

            <div className="current-entity-text"> CURRENT LOCATION </div>

            <div className="selected-entity flex-middle flex-space-between">
                <div className="selected-location">{selectedLocationName}</div>
                {localFavLocationId === selectedLocation.id && (updatingUserPreferences || loadingUserPreferences) ? (
                    <CircularProgress id="fav-loc-loader" style={{ margin: 0 }} />
                ) : favLocation === selectedLocation.id ? (
                    <FavoriteIcon
                        className="fav-icon user-clickable"
                        onClick={(e) => onFavClick(e, selectedLocation.id)}
                    />
                ) : (
                    <FavoriteBorderIcon
                        className="fav-icon"
                        style={{ cursor: updatingUserPreferences ? 'default' : 'pointer' }}
                        onClick={(e) => onFavClick(e, selectedLocation.id)}
                    />
                )}
            </div>
            <Divider />
            <div className="all-entity">
                {locations.map((cus) => (
                    <div className="entity flex-middle flex-space-between" key={cus.id} onClick={() => onSelect(cus)}>
                        <div> {cus.displayName} </div>
                        {localFavLocationId === cus.id && (updatingUserPreferences || loadingUserPreferences) ? (
                            <CircularProgress id="fav-loc-loader" style={{ margin: 0 }} />
                        ) : favLocation === cus.id ? (
                            <FavoriteIcon className="fav-icon user-clickable" onClick={(e) => onFavClick(e, cus.id)} />
                        ) : (
                            <FavoriteBorderIcon
                                className="fav-icon"
                                style={{ cursor: updatingUserPreferences ? 'default' : 'pointer' }}
                                onClick={(e) => onFavClick(e, cus.id)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </DialogContainer>
    );
};

export default ChangeLocation;
