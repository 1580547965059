import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import Popover from '@material-ui/core/Popover';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { messageAction } from '../../../redux/Inbox/Message';
import { isEmptyString, isUnmessageablePhoneType } from '../../../utils/helper';
import UploadAttachmentPreview from '../UploadAttachmentPreview';
import { AbilityContext, userActions, restrictedResources } from 'casl';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';
import { selectApptTypesWithSessions } from 'components/Appointments/types/apptTypesUtil';
import InsertMenu from './InsertMenu';
import _ from 'lodash';

class MessageInput extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            anchorEl: null,
            showEmojiPicker: false,
            imageUrl: null,
            selectedFile: null,
            selectionStart: 0,
            selectionEnd: 0,
        };

        this.inputRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        const { sending } = this.props;
        if (prevProps.sending && !sending) {
            this.setState({
                content: '',
                selectedFile: null,
                imageUrl: null,
            });
        }
    };

    buildPayload = () => {
        const { content } = this.state;
        return content;
    };

    handleSubmit = () => {
        const { createMessage } = this.props;
        const { content } = this.state;
        // Validate inputs
        if (!isEmptyString(content)) {
            // Validation passed
            createMessage(this.buildPayload());
            this.setState({ content: '' });
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleAttachment = (imageUrl, selectedFile) => {
        this.setState({
            imageUrl,
            selectedFile,
        });
    };

    handleApptRequest = (apptTypeToSend) => {
        const { inboxConversationTemplate, createMessageAppointment } = this.props;
        const schedulingTemplate = _.find(_.get(inboxConversationTemplate, 'templates'), (template) => {
            return _.get(template, 'message_content.messaging_form.appt_type_id') === apptTypeToSend.appt_type_id;
        });

        createMessageAppointment(
            apptTypeToSend.appt_type_id,
            _.get(schedulingTemplate, 'message_content.action_title'),
            _.get(schedulingTemplate, 'message_content.messaging_form.scheduled_appointment_text')
        );
    };

    onAttachmentSubmit = () => {
        const { selectedFile } = this.state;
        // Validate inputs
        if (selectedFile) {
            // Validation passed
            this.props.createAttachment(selectedFile);
        }
    };

    handleEmojiSelection = (emojiData) => {
        const { content, selectionStart, selectionEnd } = this.state;

        // Calculate new content str based on previous cursor position
        const newContent = `${content.substring(0, selectionStart)}${emojiData.native}${content.substring(
            selectionEnd,
            content.length
        )}`;

        this.setState({
            content: newContent,
            showEmojiPicker: false,
            selectionStart: 0,
            selectionEnd: 0,
        });
    };

    openPicker = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            showEmojiPicker: true,
            selectionStart: this.inputRef.current.selectionStart,
            selectionEnd: this.inputRef.current.selectionEnd,
        });
    };

    closePicker = () => {
        this.setState({
            anchorEl: null,
            showEmojiPicker: false,
            selectionStart: 0,
            selectionEnd: 0,
        });
    };

    render() {
        const { content, showEmojiPicker, anchorEl, showUpgrade } = this.state;
        const {
            contact,
            sending,
            auth: { selectedLocation },
            canMessage,
        } = this.props;
        const { opt_out, phone_type } = this.props.contact;
        const placeholder = `Type your message to ${contact.first_name} ...`;
        const isUnsubscribed = !!opt_out;
        const isUnmessageable = isUnmessageablePhoneType({ phone_type });
        const { send } = userActions;
        const { subject: messages } = restrictedResources.inbox.messages;
        const canSubmit = this.context.can(send, messages);

        return (
            <div className="conversation-message-input">
                <InsertMenu
                    handleAttachment={this.handleAttachment}
                    handleApptRequest={this.handleApptRequest}
                    imageUrl={this.state.imageUrl}
                    selectedFile={this.state.selectedFile}
                    disabled={!canSubmit}
                />
                <IconButton
                    variant="contained"
                    disabled={!canSubmit}
                    onClick={this.openPicker}
                    style={{ padding: '0px', margin: '0 8px 3px' }}
                >
                    <InsertEmoticonIcon />
                </IconButton>
                <Popover
                    id="emoji-picker-popover"
                    open={showEmojiPicker}
                    anchorEl={anchorEl}
                    onClose={this.closePicker}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transitionDuration={0}
                >
                    <Picker color="#3793ff" title="Pick an emoji …" emoji="pill" onSelect={this.handleEmojiSelection} />
                </Popover>
                {this.state.imageUrl ? (
                    <UploadAttachmentPreview
                        selectedLocation={selectedLocation}
                        contact={contact}
                        imageUrl={this.state.imageUrl}
                        handleAttachment={this.handleAttachment}
                        onAttachmentSubmit={this.onAttachmentSubmit}
                        selectedFile={this.state.selectedFile}
                        sending={sending}
                    />
                ) : (
                    <Fragment>
                        <div className="conversation-message-input__content">
                            <TextField
                                type="text"
                                id="content"
                                name="content"
                                label="content"
                                placeholder={placeholder}
                                value={content}
                                required
                                fullWidth
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    classes: { root: 'message-content-input-label' },
                                }}
                                InputProps={{
                                    readOnly: !canSubmit,
                                    classes: { root: 'message-content-input' },
                                    disableUnderline: true,
                                }}
                                inputRef={this.inputRef}
                                variant="standard"
                                margin="none"
                                onChange={this.handleChange}
                                disabled={!canSubmit}
                            />
                        </div>
                        <div className="conversation-message-input__submit">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={sending || isUnsubscribed || isUnmessageable || !canSubmit}
                                onClick={!canMessage ? () => this.setState({ showUpgrade: true }) : this.handleSubmit}
                                style={{ boxShadow: 'none', minWidth: '75px' }}
                            >
                                {sending ? (
                                    <CircularProgress size={20} className="conversation-message-input__progress" />
                                ) : (
                                    'Send'
                                )}
                            </Button>
                        </div>
                    </Fragment>
                )}
                {!showUpgrade ? null : (
                    <FullPepUpgrade asDialog onClose={() => this.setState({ showUpgrade: false })} />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { inboxMessage, auth, inboxConversationTemplate } = state;
    return {
        sending: inboxMessage.sending,
        auth,
        apptTypesWithSessions: selectApptTypesWithSessions(state),
        inboxConversationTemplate,
    };
}

const bindActionsToDispatch = (dispatch) => ({
    createMessage: (messageContent) => dispatch(messageAction.createMessage(messageContent)),
    createAttachment: (attachmentContent) => dispatch(messageAction.createAttachment(attachmentContent)),
    createMessageAppointment: (appt_type_id, action_title, scheduled_appointment_text) =>
        dispatch(messageAction.createMessageAppointment(appt_type_id, action_title, scheduled_appointment_text)),
});

export default connect(mapStateToProps, bindActionsToDispatch)(MessageInput);
