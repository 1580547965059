import React, { useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';

const List = ({ pharmacies, onPharmacyClick }) => (
    <Fragment>
        {pharmacies.map((pharmacy) => (
            <div key={pharmacy.id} className="item-container" onClick={() => onPharmacyClick(pharmacy)}>
                <Grid item xs={7} className="pharmacy-name" title={pharmacy.name}>
                    {pharmacy.name}
                </Grid>
                <Grid item xs={5} className="app-code">
                    {pharmacy.appCode}
                </Grid>
            </div>
        ))}
    </Fragment>
);

const PharmacyList = (props) => {
    const { pharmacies, getMorePharmacies, onPharmacyClick, loadingPossiblePharmacies } = props;
    let scrollElement;

    const handleScroll = _.debounce(() => {
        if (scrollElement) {
            const isBottom = scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop < 1;
            if (isBottom) {
                getMorePharmacies();
            }
        }
    }, 300);

    useEffect(() => {
        scrollElement = document.getElementById('pharmacy-list-container');
        scrollElement.addEventListener('scroll', handleScroll);

        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Card id="pharmacy-list-container" className="pharmacy-list-container">
            <CardContent className="content">
                <Grid container className="flex-center">
                    {pharmacies && pharmacies.length > 0 ? (
                        <List pharmacies={pharmacies} onPharmacyClick={onPharmacyClick} />
                    ) : (
                        <div> {!loadingPossiblePharmacies && 'No Results Found'} </div>
                    )}
                    {loadingPossiblePharmacies && <CircularProgress id="pharmacy-list-loader" />}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PharmacyList;
