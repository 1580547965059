import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { DpTabs, DpTab } from 'components/Common/DpTabs';
import { NEWSLETTER_TABS, DRAWER_STATES } from 'constants/Newsletter';
import EmptyNewsletter from './EmptyNewsletter';
import Calendar from './Calendar/';
import Drawer from './Drawer';
import HistoryWrapper from './HistoryWrapper';
import { newsletterActions } from 'redux/Newsletter/action';
import SubscriberList from './SubscriberList';

const Newsletter = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const {
        isNewsletterPackageEnabled,
        selectedCustomer,
        history,
        setDrawerState,
        isActiveCard,
        isActiveNewsletter,
    } = props;

    const tabs = [
        {
            label: 'Calendar',
            value: NEWSLETTER_TABS.CALENDER,
            route: '/newsletter/calendar',
            defaultDrawerState: DRAWER_STATES.EMPTY_CALENDAR,
        },
        {
            label: 'Subscribers',
            value: NEWSLETTER_TABS.SUBSCRIBERS,
            route: '/newsletter/subscriber',
            defaultDrawerState: DRAWER_STATES.EMPTY_SUBSCRIBER,
        },
        {
            label: 'History',
            value: NEWSLETTER_TABS.HISTORY,
            route: '/newsletter/history',
            defaultDrawerState: DRAWER_STATES.EMPTY_HISTORY,
        },
    ];

    useEffect(() => {
        setDrawerState(tabs[selectedTab].defaultDrawerState);
    }, [selectedCustomer]);

    useEffect(() => {
        const tabIndex = tabs.findIndex((tab) => tab.route === history.location.pathname);

        if (tabIndex > -1 && tabIndex !== selectedTab) {
            setSelectedTab(tabIndex);
            setDrawerState(tabs[tabIndex].defaultDrawerState);
        }

        if (tabIndex === 0 && isActiveCard) {
            // if we are navigating back to calendar then reset active card
            const { resetActiveCard } = props;
            resetActiveCard();
        }
    }, [history.location.pathname]);

    useEffect(() => {
        // reset newsletter state after component unmount
        return () => {
            const { resetNewsletterStore } = props;
            resetNewsletterStore();
        };
    }, []);

    const handleTabChange = (e, selectedValue) => {
        history.push(tabs[selectedValue].route);
    };

    return (
        <div className="newsletter-container">
            <Grid container className="height100">
                {!isNewsletterPackageEnabled ? (
                    <div className="centered-content full-width">
                        <EmptyNewsletter />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={9} className="left-wrapper">
                            {!isActiveCard && !isActiveNewsletter && (
                                <Fragment>
                                    <div className="title"> eNewsletter </div>
                                    <DpTabs value={selectedTab} onChange={handleTabChange}>
                                        {tabs.map((tab) => (
                                            <DpTab label={tab.label} key={tab.value} />
                                        ))}
                                    </DpTabs>
                                </Fragment>
                            )}
                            <Switch>
                                <Route path="/newsletter/calendar" component={Calendar} />
                                <Route path="/newsletter/subscriber" render={() => <SubscriberList />} />
                                <Route path="/newsletter/history" component={HistoryWrapper} />
                            </Switch>
                        </Grid>
                        <Grid item xs={3} className="right-wrapper">
                            <Drawer />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedCustomer: state.auth.selectedCustomer,
    isActiveCard: state.newsletter.isActiveCard,
    isActiveNewsletter: state.newsletter.isActiveNewsletter,
});

const mapDispatchToProps = {
    setDrawerState: newsletterActions.setDrawerState,
    setActiveSubscriber: newsletterActions.setActiveSubscriber,
    resetNewsletterStore: newsletterActions.resetNewsletterStore,
    resetActiveCard: newsletterActions.resetActiveCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
