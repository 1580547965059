import React from 'react';
import './ReviewPatientCell.sass';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

export default function ReviewPatientCell({ value, rawValue }) {
    return (
        <div className="review-patient-cell">
            <Tooltip
                title={
                    <div className="review-patient-cell-tooltip">
                        Parsed from raw value:
                        <div className="raw-area">
                            <code>{rawValue}</code>
                        </div>
                    </div>
                }
            >
                <span className="non-edit-value">
                    {!_.isEmpty(_.trim(value)) ? value : <span className="empty-value">(empty)</span>}
                </span>
            </Tooltip>
        </div>
    );
}
