import BusinessInformationReducer from './BusinessInformation';
import DoctorsLineReducer from './DoctorsLine';
import LanguagesReducer from './Languages';
import LeaveMessagesReducer from './LeaveMessage';
import MainGreetingReducer from './MainGreeting';
import PhoneLineReducer from './PhoneLine';
import SpeakWithPharmacistReducer from './SpeakWithPharmacist';
import NewPatientLineReducer from './NewPatientLine';
import AAMainMenuReducer from './AAMainMenu';

export default function SettingsReducer(state = [], actions) {
    return {
        businessInformation: BusinessInformationReducer(state.businessInformation, actions),
        doctorsLine: DoctorsLineReducer(state.doctorsLine, actions),
        languageChoices: LanguagesReducer(state.languageChoices, actions),
        leaveMessage: LeaveMessagesReducer(state.leaveMessage, actions),
        mainGreeting: MainGreetingReducer(state.mainGreeting, actions),
        phoneLine: PhoneLineReducer(state.phoneLine, actions),
        speakWithPharmacists: SpeakWithPharmacistReducer(state.speakWithPharmacists, actions),
        newPatientLine: NewPatientLineReducer(state.newPatientLine, actions),
        aaMainMenu: AAMainMenuReducer(state.aaMainMenu, actions),
    };
}
