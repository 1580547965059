export default {
    FETCH_LEADS_COUNT_STARTED: 'FETCH_LEADS_COUNT_STARTED',
    FETCH_LEADS_COUNT_SUCCESS: 'FETCH_LEADS_COUNT_SUCCESS',
    FETCH_LEADS_COUNT_FAILED: 'FETCH_LEADS_COUNT_FAILED',
    FETCH_LEADS_STARTED: 'FETCH_LEADS_STARTED',
    FETCH_LEADS_SUCCESS: 'FETCH_LEADS_SUCCESS',
    FETCH_LEADS_FAILED: 'FETCH_LEADS_FAILED',
    FETCH_LEAD_AUDIO_STARTED: 'FETCH_LEAD_AUDIO_STARTED',
    FETCH_LEAD_AUDIO_SUCCESS: 'FETCH_LEAD_AUDIO_SUCCESS',
    FETCH_LEAD_AUDIO_FAILED: 'FETCH_LEAD_AUDIO_FAILED',
    SORT_LEADS: 'SORT_LEADS',
    RESET_LEADS: 'RESET_LEADS',
    FETCH_LEAD_NOTES_STARTED: 'FETCH_LEAD_NOTES_STARTED',
    FETCH_LEAD_NOTES_SUCCESS: 'FETCH_LEAD_NOTES_SUCCESS',
    FETCH_LEAD_NOTES_FAILED: 'FETCH_LEAD_NOTES_FAILED',
    SAVE_LEAD_NOTE_STARTED: 'SAVE_LEAD_NOTE_STARTED',
    SAVE_LEAD_NOTE_SUCCESS: 'SAVE_LEAD_NOTE_SUCCESS',
    SAVE_LEAD_NOTE_FAILED: 'SAVE_LEAD_NOTE_FAILED',
    SET_DRAFT_NOTE: 'SET_DRAFT_NOTE',
    FETCH_LATEST_LEADS: 'FETCH_LATEST_LEADS',
};
