import React from 'react';
import FilteringOptionGroup from './FilteringOptionGroup';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';
import BlockIcon from '@material-ui/icons/Block';
import MessageIcon from '@material-ui/icons/Message';

export default function WaitlistStatusPopover({ value, onChange }) {
    return (
        <div className="filter-popover waitlist-status-popover">
            <FilteringOptionGroup
                value={value}
                onChange={onChange}
                options={[
                    {
                        value: 'Uncontacted',
                        icon: <AssignmentIndIcon />,
                        label: WaitlistStatusPopover.OPTION_LABELS['Uncontacted'],
                    },
                    {
                        value: 'Contacted',
                        icon: <MessageIcon />,
                        label: WaitlistStatusPopover.OPTION_LABELS['Contacted'],
                    },
                    {
                        value: 'Declined',
                        icon: <BlockIcon />,
                        label: WaitlistStatusPopover.OPTION_LABELS['Declined'],
                    },
                    {
                        value: 'Scheduled',
                        icon: <EventIcon />,
                        label: WaitlistStatusPopover.OPTION_LABELS['Scheduled'],
                    },
                ]}
            />
        </div>
    );
}

WaitlistStatusPopover.OPTION_LABELS = {
    Uncontacted: 'Not yet contacted',
    Contacted: 'Patient has been contacted',
    Declined: 'Patient declined to schedule',
    Scheduled: 'Patient has scheduled appointment',
};
