export default {
    FETCH_HUNT_GROUP_STARTED: 'FETCH_HUNT_GROUP_STARTED',
    FETCH_HUNT_GROUP_SUCCESS: 'FETCH_HUNT_GROUP_SUCCESS',
    FETCH_HUNT_GROUP_FAILED: 'FETCH_HUNT_GROUP_FAILED',
    SAVE_HUNT_GROUP_STARTED: 'SAVE_HUNT_GROUP_STARTED',
    SAVE_HUNT_GROUP_SUCCESS: 'SAVE_HUNT_GROUP_SUCCESS',
    SAVE_HUNT_GROUP_FAILED: 'SAVE_HUNT_GROUP_FAILED',
    ADD_HUNT_GROUP: 'ADD_HUNT_GROUP',
    SET_HUNT_GROUP_CHANGES: 'SET_HUNT_GROUP_CHANGES',
    SET_OVERFLOW_SETTING: 'SET_OVERFLOW_SETTING',
    CHANGE_OVERFLOW_SETTING: 'CHANGE_OVERFLOW_SETTING',
    REMOVE_HUNT_GROUP_STARTED: 'REMOVE_HUNT_GROUP_STARTED',
    REMOVE_HUNT_GROUP_SUCCESS: 'REMOVE_HUNT_GROUP_SUCCESS',
    REMOVE_HUNT_GROUP_FAILED: 'REMOVE_HUNT_GROUP_FAILED',
    SAVE_OVERFLOW_STARTED: 'SAVE_OVERFLOW_STARTED',
    SAVE_OVERFLOW_FINISHED: 'SAVE_OVERFLOW_FINISHED',
    CHANGE_AFTER_HOUR_SETTING: 'CHANGE_AFTER_HOUR_SETTING',
};
