import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Cell, CircularProgress, Divider } from 'react-md';
import { Select, FormControl, OutlinedInput, InputLabel, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Snowplow from 'snowplow';
import moment from 'moment';
import _ from 'lodash';

import { DATE_PRESETS, DatePresetDetail, TooltipTextMap } from 'constants/DMDashboard';
import {
    fetchMetrics,
    fetchCampaigns,
    fetchCampaignPreviews,
    fetchDemographicMetrics,
    updateCampaignPreview,
    resetMetric,
    resetDemographicMetric,
    resetCampaigns,
} from 'redux/actionCreators/TigerPistol';
import Ads from 'components/DigitalMarketingDashboard/Ads';
import Demographics from 'components/DigitalMarketingDashboard/Demographics';
import { EmptyCardContent } from 'components/Report/EmptyCard';
import { fetchLeadsCount, resetLeadsCount } from 'redux/actionCreators/Leads';

const ONE_MONTH_DAYS = 30;
const YESTERDAY = moment().subtract(1, 'days');

export class DigitalMarketingDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(ONE_MONTH_DAYS, 'days'),
            endDate: YESTERDAY,
            campaignLoading: false,
            selectedDatePreset: DATE_PRESETS.CUSTOM,
        };
    }

    componentDidMount() {
        Snowplow.pageView('Digital Marketing Dashboard');
        this.getDescriptionData();
        this.getCampaignAndPreviews();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedLocalLocation !== this.props.selectedLocalLocation) {
            this.getLeadsCount();
        } else if (prevProps.selectedPharmacyName !== this.props.selectedPharmacyName) {
            this.getDescriptionData();
            this.getCampaignAndPreviews();
        }
    }

    getDateRange = () => {
        const { startDate, endDate } = this.state;

        const from = moment(startDate).format(moment.HTML5_FMT.DATE);
        const to = moment(endDate).format(moment.HTML5_FMT.DATE);

        return { from, to };
    };

    getDescriptionData = () => {
        const {
            fetchMetrics,
            fetchDemographicMetrics,
            tigerPistolId,
            resetMetric,
            resetDemographicMetric,
        } = this.props;

        if (tigerPistolId) {
            const { from, to } = this.getDateRange();
            fetchMetrics({ tigerPistolId, params: { start: from, end: to } });
            fetchDemographicMetrics({ tigerPistolId, params: { start: from, end: to } });
            this.getLeadsCount();
        } else {
            resetMetric();
            resetDemographicMetric();
        }
    };

    getLeadsCount = () => {
        const { fetchLeadsCount, selectedCustomer, selectedLocalLocation, resetLeadsCount } = this.props;
        const { from, to } = this.getDateRange();

        if (selectedLocalLocation !== 'allLocations') {
            const internalPayload = {
                pharmacy_id: selectedCustomer.id,
                location_id: selectedLocalLocation,
                start_datetime: from,
                end_datetime: to,
            };
            fetchLeadsCount(internalPayload);
        } else {
            resetLeadsCount();
        }
    };

    getCampaignAndPreviews = () => {
        const {
            fetchCampaigns,
            fetchCampaignPreviews,
            resetCampaigns,
            tigerPistolId,
            updateCampaignPreview,
        } = this.props;
        const { from, to } = this.getDateRange();

        if (tigerPistolId) {
            this.setState({ campaignLoading: true });
            fetchCampaigns({ tigerPistolId, params: { start: from, end: to } }).then(async (data) => {
                const campaignPreview = {};

                if ('resp' in data && data.resp.results.length) {
                    await Promise.all(
                        data.resp.results.map(async (campaign) => {
                            const previewResp = await fetchCampaignPreviews(campaign.id);
                            campaignPreview[campaign.id] = _.get(previewResp, 'resp.results', []);
                        })
                    );
                    updateCampaignPreview(campaignPreview);
                    this.setState({ campaignLoading: false });
                } else {
                    this.setState({ campaignLoading: false });
                }
            });
        } else {
            resetCampaigns();
        }
    };

    handleDateChange = (date, type) => {
        let { startDate, endDate } = this.state;
        if (type === 'start') {
            startDate = moment(date);
            if (moment(startDate).isAfter(endDate)) {
                endDate = moment(date);
            }
        } else {
            endDate = moment(date);
        }
        this.setState({ startDate, endDate, selectedDatePreset: DATE_PRESETS.CUSTOM }, () => {
            this.getDescriptionData();
            this.getCampaignAndPreviews();
        });
    };

    getAdsTitle = () => {
        const learnUrl = 'https://help.digitalpharmacist.com/en/collections/1767216-digital-marketing';

        return (
            <Fragment>
                <h4 className="section-title" style={{ marginBottom: 0 }}>
                    Ads
                </h4>
                <a
                    className="standard-margin-left-10"
                    target="_new"
                    href={learnUrl}
                    style={{ textDecoration: 'none', color: '#3793ff' }}
                >
                    Learn More
                </a>
            </Fragment>
        );
    };

    changeDatePreset = (e) => {
        const { selectedDatePreset } = this.state;

        const newSelectedDatePreset = e.target.value;
        let startDate = '';
        let endDate = '';

        if (selectedDatePreset === newSelectedDatePreset) {
            return;
        }
        switch (newSelectedDatePreset) {
            case DATE_PRESETS.YESTERDAY:
                startDate = YESTERDAY;
                endDate = YESTERDAY;
                break;
            case DATE_PRESETS.CURR_WEEK_DATE:
                startDate = moment().startOf('week');
                endDate = moment(YESTERDAY).isBefore(startDate) ? startDate : YESTERDAY;
                break;
            case DATE_PRESETS.CURR_MONTH_DATE:
                startDate = moment().startOf('month');
                endDate = moment(YESTERDAY).isBefore(startDate) ? startDate : YESTERDAY;
                break;
            case DATE_PRESETS.PREV_MONTH:
                startDate = moment().subtract(1, 'months').startOf('month');
                endDate = moment().subtract(1, 'months').endOf('month');
                break;
            case DATE_PRESETS.PREV_QUARTER:
                startDate = moment().subtract(1, 'Q').startOf('quarter');
                endDate = moment().subtract(1, 'Q').endOf('quarter');
                break;
            default:
                startDate = moment().subtract(ONE_MONTH_DAYS, 'days');
                endDate = YESTERDAY;
        }

        this.setState({ startDate, endDate, selectedDatePreset: newSelectedDatePreset }, () => {
            this.getDescriptionData();
            this.getCampaignAndPreviews();
        });
    };

    getDatePresetSelector = () => {
        const { selectedDatePreset } = this.state;

        return (
            <Fragment>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Date Range</InputLabel>
                    <Select
                        value={selectedDatePreset}
                        onChange={this.changeDatePreset}
                        input={<OutlinedInput fullWidth labelWidth={80} name="date-preset" id="outlined-date-preset" />}
                    >
                        {DatePresetDetail.map((presetDetail, idx) => (
                            <MenuItem key={idx} value={presetDetail.val}>
                                {presetDetail.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Fragment>
        );
    };

    getDatePicker = () => {
        const { startDate, endDate } = this.state;

        return (
            <Fragment>
                <div className="grid-container date-range-picker">
                    <Cell size={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="Start"
                                value={startDate}
                                format="MM/dd/yyyy"
                                onChange={(dateVal) => this.handleDateChange(dateVal, 'start')}
                                maxDate={YESTERDAY}
                                maxDateMessage={<span></span>}
                            />
                        </MuiPickersUtilsProvider>
                    </Cell>
                    <Cell size={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="End"
                                value={endDate}
                                format="MM/dd/yyyy"
                                minDate={startDate}
                                minDateMessage={<span></span>}
                                maxDate={YESTERDAY}
                                maxDateMessage={<span></span>}
                                onChange={(dateVal) => this.handleDateChange(dateVal, 'end')}
                            />
                        </MuiPickersUtilsProvider>
                    </Cell>
                </div>
                {!moment(startDate).isSameOrBefore(endDate) && (
                    <div className="err-msg">End date shouldn't be before the start date</div>
                )}
            </Fragment>
        );
    };

    render() {
        const {
            digitalMarketingReportsEnabled,
            selectedLocalLocation,
            leads: { leadsCount, isLeadsCountLoading },
            tigerPistol: {
                demographicDescription,
                demographicAgeGroupClickData,
                adsDescription,
                loadingDemographicMetrics,
                loadingDailyMetrics,
                campaignPreviews,
            },
        } = this.props;

        const { campaignLoading } = this.state;

        return digitalMarketingReportsEnabled ? (
            <div className="dm-dashboard-container">
                {loadingDemographicMetrics || loadingDailyMetrics || isLeadsCountLoading ? (
                    <CircularProgress id="dm-dashboard-loader" />
                ) : (
                    <Fragment>
                        <div className="grid-container">
                            <Cell size={12}>
                                <div className="grid-container">
                                    <Cell size={2} className="flex-middle">
                                        {this.getAdsTitle()}
                                    </Cell>
                                    <Cell size={6} className="flex-middle flex-right learn-more">
                                        Data may take up to 24 hours to be updated
                                    </Cell>
                                    <Cell size={2} className="flex-middle">
                                        {this.getDatePresetSelector()}
                                    </Cell>
                                    <Cell size={2} className="flex-middle flex-right">
                                        {this.getDatePicker()}
                                    </Cell>
                                </div>
                                <Ads
                                    campaignLoading={campaignLoading}
                                    selectedLocalLocation={selectedLocalLocation}
                                    adsDescription={adsDescription}
                                    previews={campaignPreviews}
                                    inboundLeads={leadsCount}
                                    tooltipTextMap={TooltipTextMap}
                                />
                            </Cell>
                        </div>
                        <div className="standard-margin-top-10 standard-margin-bottom-10">
                            <Divider />
                        </div>
                        <Demographics
                            demographicAgeGroupClickData={demographicAgeGroupClickData}
                            demographicDescription={demographicDescription}
                            tooltipTextMap={TooltipTextMap}
                        />
                    </Fragment>
                )}
            </div>
        ) : (
            <Fragment>
                <div style={{ height: 'calc(100vh - 160px' }}>
                    <div className="flex-middle flex-center EmptyCard">
                        <EmptyCardContent
                            imgPath="/reports/digital_marketing.png"
                            helperText="You don’t have Digital Marketing yet."
                            appType="digitalMarketingDashboard"
                            variant="tabs"
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        pharmacy,
        tigerPistol,
        leads,
        callRails,
        auth: { selectedCustomer },
    } = state;
    const { tigerPistolEnabled, digitalMarketingReportsEnabled, pharmacy: currentPharmacy } = pharmacy;
    const tigerPistolId = tigerPistolEnabled ? currentPharmacy.service['DigitalMarketing']['tigerPistolId'] : '';

    return {
        digitalMarketingReportsEnabled,
        tigerPistolId,
        tigerPistol,
        callRails,
        leads,
        selectedCustomer,
        pharmacy: currentPharmacy,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchMetrics: (data) => dispatch(fetchMetrics(data)),
    fetchCampaigns: (data) => dispatch(fetchCampaigns(data)),
    fetchCampaignPreviews: (data) => dispatch(fetchCampaignPreviews(data)),
    fetchDemographicMetrics: (data) => dispatch(fetchDemographicMetrics(data)),
    updateCampaignPreview: (data) => dispatch(updateCampaignPreview(data)),
    fetchLeadsCount: (data) => dispatch(fetchLeadsCount(data)),
    resetCampaigns: () => dispatch(resetCampaigns()),
    resetLeadsCount: () => dispatch(resetLeadsCount()),
    resetMetric: () => dispatch(resetMetric()),
    resetDemographicMetric: () => dispatch(resetDemographicMetric()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DigitalMarketingDashboard);
