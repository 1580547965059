import _ from 'lodash';

import Config from 'config';
import { logUtil } from 'utils/log';
import { restActionCreators } from 'redux/actionCreators/rest';
import API_PATH from 'api.json';

const getRefillCount = (payload) => {
    const url = `${Config.apiweb_url}${API_PATH.NEW_REPORTS}/digital_platform`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting refill count', error);
            return Promise.reject(error);
        });
};

const getPatientTransfers = (payload) => {
    const url = `${Config.apiweb_url}${API_PATH.NEW_REPORTS}/digital_platform_patients`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting patient transfers', error);
            return Promise.reject(error);
        });
};

const getMessagesSent = (payload) => {
    const url = `${Config.apiweb_url}${API_PATH.NEW_REPORTS}/direct_messaging`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting messages sent', error);
            return Promise.reject(error);
        });
};

const getPatientsWithBirthday = () => {
    const today = new Date();
    const params = _.filter([`birthday=${today.getMonth() + 1}-${today.getDate()}`]);

    return restActionCreators
        .get(
            `${Config.apiweb_url}${API_PATH.PATIENT_QUERY_BASE}/patients/${Config.X_PharmacyID}/${
                Config.X_LocationID
            }/search?${params.join('&')}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error occured while getting patients with birthday`, error);
            return Promise.reject(error);
        });
};

const getUnreadVoicemail = () => {
    const apiPathVoicemail = API_PATH.VOICE_MAILS.replace('{custID}', Config.X_PharmacyID).replace(
        '{locID}',
        Config.X_LocationID
    );
    const apiUrl = Config.msg_url + apiPathVoicemail;
    // TODO: Keeping this as 100 for now we need proper backend api in future to get accurate count
    const header = { params: { isRead: 0, startRow: 0, numRows: 100 } };

    return restActionCreators
        .get(apiUrl, header)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            logUtil.logHttpError('An error occured while getting unread voicemails', err);
            return { err };
        });
};

const getUnreadMessagesCount = () => {
    const pharmacyId = Config.X_PharmacyID;
    const locationId = Config.X_LocationID;

    const queryString = 'page=0&pageItems=50&filterType=unread_patient';
    return restActionCreators
        .get(`${Config.apiweb_url}${API_PATH.INBOX_BASE}/users/${pharmacyId}/${locationId}/activity?${queryString}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError(`An error occurred while getting unread messages`, error);
            return Promise.reject(error);
        });
};

const getInternalLeads = (payload) => {
    const url = `${Config.apiweb_url}${API_PATH.INTERNAL_LEADS}`;

    return restActionCreators
        .post(url, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting internal leads', error);
            return Promise.reject(error);
        });
};

const getCallrailLeads = (payload) => {
    const apiUrl = `${Config.apiweb_url}${API_PATH.CALLRAIL_CALLS}?company_id=${payload.callRailId}&start_date=${payload.start_date}&end_date=${payload.end_date}`;

    return restActionCreators
        .get(apiUrl)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting callrail leads', error);
            return Promise.reject(error);
        });
};

const getLeadStatus = (payload) => {
    const apiUrl = `${Config.apiweb_url}/leads/read/${Config.X_PharmacyID}`;

    return restActionCreators
        .post(apiUrl, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            logUtil.logHttpError('Error while getting lead status', error);
            return Promise.reject(error);
        });
};

const sendQuickReply = (conversationId, userId, content) => {
    const requestBody = {
        inbox_user_id: userId,
        content,
    };
    const apiUrl = `${Config.apiweb_url}${API_PATH.INBOX_BASE}/conversation/${conversationId}/message`;

    return restActionCreators
        .post(apiUrl, requestBody)
        .then((response) => {
            // TODO need backend to change message_id to inbox_message_id
            if (response.success && response.message_id) {
                return response.message_id;
            } else {
                return Promise.reject({});
            }
        })
        .catch((error) => {
            logUtil.logHttpError('Error while sending quick reply', error);
            return Promise.reject(error);
        });
};

const getConversations = (userId) => {
    return restActionCreators
        .get(`${Config.apiweb_url}${API_PATH.INBOX_BASE}/conversations/${userId}/activity`)
        .then((response) => {
            return response.conversations_activity;
        })
        .catch((error) => {
            logUtil.logHttpError(`Error while getting conversations`, error);
            return Promise.reject(error);
        });
};

export const homeService = {
    getRefillCount,
    getPatientTransfers,
    getMessagesSent,
    getPatientsWithBirthday,
    getUnreadVoicemail,
    getUnreadMessagesCount,
    getInternalLeads,
    getCallrailLeads,
    getLeadStatus,
    sendQuickReply,
    getConversations,
};
