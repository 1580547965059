import React from 'react';
import { Grid } from 'react-md';

const LinearProgress = (props) => {
    let { showReferenceLine, width, title, lineTitle, color } = props;

    color = color || '#3793ff';
    width = `${width}%`;
    showReferenceLine = showReferenceLine === undefined && true;

    return (
        <Grid stacked className="linearProgress standard-margin-bottom-10">
            <div className="title text-align-left">{title}</div>
            <div className="lineTitle text-align-left">{lineTitle}</div>
            <div className="lineContainer">
                {showReferenceLine && <div className="line width100 grey" />}
                <div className="line" style={{ width, backgroundColor: color }} />
            </div>
        </Grid>
    );
};

export default LinearProgress;
