import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';

import EditableBackendValues from './EditableBackendValues';
import SlidingROIValues from './SlidingROIValues';
import ComputedROIValues from './ComputedROIValues';

class ROICalculatorPage extends Component {
    state = {
        // pharmacy data values
        refillCountValue: 34,
        newPatientTransfersValue: 10,
        outboundAlertsValue: 40,
        // sliding roi values
        employeeWagePerHourValue: 10,
        avgProfitPerRXValue: 12,
        avgRevenuePerRXValue: 25,
        annualAvgRevenuePerPatientValue: 20,
        minutesSavedFromAutomationValue: 15,
        socialMediaValue: 30,
        hoursPerMonthWorkValue: 10,
    };

    // slider events don't have a target where you can take an id, so we have to send one in manually
    handleChange = (value, event, id) => {
        const parsedValue = parseFloat(value);
        const idForStateValue = id ? id : event.target.id;
        this.setState({
            ...this.state,
            [idForStateValue]: parsedValue >= 0 ? parsedValue : 0,
        });
    };

    render() {
        return (
            <div className="ROICalculatorPage__container">
                <div>
                    <Grid>
                        <Cell size={4}>
                            <EditableBackendValues
                                valueChange={this.handleChange}
                                refillCountValue={this.state.refillCountValue}
                                newPatientTransfersValue={this.state.newPatientTransfersValue}
                                outboundAlertsValue={this.state.outboundAlertsValue}
                            />
                        </Cell>
                        <Cell size={4}>
                            <SlidingROIValues
                                valueChange={this.handleChange}
                                employeeWagePerHourValue={this.state.employeeWagePerHourValue}
                                avgProfitPerRXValue={this.state.avgProfitPerRXValue}
                                avgRevenuePerRXValue={this.state.avgRevenuePerRXValue}
                                annualAvgRevenuePerPatientValue={this.state.annualAvgRevenuePerPatientValue}
                                minutesSavedFromAutomationValue={this.state.minutesSavedFromAutomationValue}
                                socialMediaValue={this.state.socialMediaValue}
                                hoursPerMonthWorkValue={this.state.hoursPerMonthWorkValue}
                            />
                        </Cell>
                        <Cell size={4}>
                            <ComputedROIValues
                                scriptsProcessedValue={this.state.avgRevenuePerRXValue * this.state.refillCountValue}
                                newPatientsValue={
                                    this.state.annualAvgRevenuePerPatientValue * this.state.newPatientTransfersValue
                                }
                                mediaSalesValue={this.state.dmLinkChecksValue * this.state.socialMediaValue}
                                scriptsProcessedGrossProfit={
                                    this.state.avgProfitPerRXValue * this.state.refillCountValue
                                }
                                automationValue={
                                    ((this.state.refillCountValue +
                                        this.state.newPatientTransfersValue +
                                        this.state.outboundAlertsValue) *
                                        this.state.minutesSavedFromAutomationValue *
                                        this.state.employeeWagePerHourValue) /
                                    60
                                }
                                supportValue={25 * this.state.hoursPerMonthWorkValue}
                            />
                        </Cell>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default ROICalculatorPage;
