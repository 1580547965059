import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';

import { statusPageAction } from 'redux/StatusPage';
import Config from 'config';
import {
    ALLOWED_INCIDENTS_STATUS_LIST,
    LOCAL_STORE_INCIDENT_KEY,
    LOCAL_STORE_MAINTENANCE_KEY,
    featureFlagComponentNameMap,
} from 'constants/StatusPage';

const POLLING_MIN = 30;
const POLLING_TIME = 1000 * 60 * POLLING_MIN;

export class StatusPage extends PureComponent {
    constructor(props) {
        super(props);
        this.pollingHandle = null;
        const maintenanceItems = sessionStorage.getItem(LOCAL_STORE_MAINTENANCE_KEY);
        const incidentItems = sessionStorage.getItem(LOCAL_STORE_INCIDENT_KEY);
        this.state = {
            statusPageMaintenanceViewed: maintenanceItems ? maintenanceItems.split(',') : null,
            statusPageIncidentViewed: incidentItems ? incidentItems.split(',') : null,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            fetchSummary,
            flags: { npeStatusWidget },
        } = this.props;

        if (!prevProps.flags.npeStatusWidget && npeStatusWidget && !this.pollingHandle) {
            // Summary response includes incidents and scheduled_maintenance data
            fetchSummary();
            this.pollingHandle = setInterval(() => {
                fetchSummary();
            }, POLLING_TIME);
        }
    }

    componentWillUnmount() {
        clearInterval(this.pollingHandle);
    }

    getSnackBar = (type) => {
        const { summary } = this.props;
        const { statusPageIncidentViewed, statusPageMaintenanceViewed } = this.state;

        const localStoreItems = type === 'incidents' ? statusPageIncidentViewed : statusPageMaintenanceViewed;
        const itemsToShow = this.filterAllowedItems(summary[type], type);
        const items = itemsToShow.map((item) => item.id);
        const hasNewItems =
            items.length && localStoreItems && !items.every((item) => localStoreItems.indexOf(item) > -1);
        const showSnackBar = itemsToShow.length && (!localStoreItems || hasNewItems);

        return showSnackBar ? this.getSnackBarElement(this.getSnackBarData(itemsToShow, type)) : '';
    };

    filterAllowedItems = (items, type) => {
        const { pharmacy } = this.props;
        let statusItems = items;

        if (type === 'incidents') {
            statusItems = items.filter((incident) => ALLOWED_INCIDENTS_STATUS_LIST.indexOf(incident.impact) > -1);
        }

        return statusItems.filter((item) => {
            const componentNames = item.components.map((component) => component.name);
            if (componentNames.length) {
                let showItem = true;
                // check for any flag name in the components
                Object.keys(featureFlagComponentNameMap).forEach((flagName) => {
                    const found = featureFlagComponentNameMap[flagName].some((fName) =>
                        componentNames.find((cName) => cName.indexOf(fName) > -1)
                    );

                    if (found) {
                        // if found then check for flag's value for the current location
                        showItem = pharmacy[flagName];
                    }
                });
                return showItem;
            } else {
                // if there is no component assoc then show that item
                return true;
            }
        });
    };

    getSnackBarData = (statuPageItems, type) => {
        let statusDate = '';

        if (type === 'incidents') {
            const incidentUpdatedAt = _.get(statuPageItems, '[0].updated_at', '');
            statusDate = incidentUpdatedAt ? moment.utc(incidentUpdatedAt).format('DD MMM YYYY HH:mm') : '';
        } else {
            const scheduledFor = _.get(statuPageItems, '[0].scheduled_for', '');
            const scheduledUntil = _.get(statuPageItems, '[0].scheduled_until', '');
            const forDate =
                scheduledFor && scheduledUntil ? moment.utc(scheduledFor).format('DD MMM YYYY [from] HH:mm') : '';
            const untilDate = scheduledFor && scheduledUntil ? moment.utc(scheduledFor).format('[to] HH:mm') : '';
            statusDate = `${forDate} ${untilDate}`;
        }

        return {
            title: _.get(statuPageItems, '[0].name', ''),
            subTitle: statuPageItems.length > 1 ? `+${statuPageItems.length - 1} more item(s)` : statusDate,
            type,
        };
    };

    getSnackBarElement = (snackBarData) => {
        return (
            <div className={`frame-wrapper ${snackBarData.type === 'incidents' ? 'error' : 'info'}`}>
                <div className="frame-icon">{snackBarData.type === 'incidents' ? <WarningIcon /> : <InfoIcon />}</div>
                <div className="frame-content">
                    <h1 className="title">{snackBarData.title}</h1>
                    <div className="sub-title">{snackBarData.subTitle}</div>
                    <div className="statuspage-link">
                        <a href={Config.status_page_url} target="_blank" rel="noopener noreferrer">
                            View Latest Updates
                        </a>
                    </div>
                </div>
                <div className="frame-close">
                    <CloseIcon className="user-clickable" onClick={() => this.suspendStatusPage(snackBarData.type)} />
                </div>
            </div>
        );
    };

    suspendStatusPage = (type) => {
        const { summary } = this.props;
        const localStoreName = type === 'incidents' ? LOCAL_STORE_INCIDENT_KEY : LOCAL_STORE_MAINTENANCE_KEY;

        const itemsToShow = this.filterAllowedItems(summary[type], type);
        const itemsIdList = itemsToShow.map((item) => item.id);
        this.setState({ [localStoreName]: itemsIdList });
        sessionStorage.setItem(localStoreName, itemsIdList);
    };

    render() {
        const {
            summary,
            flags: { npeStatusWidget },
        } = this.props;

        // short circuit when LD flag is off
        if (!npeStatusWidget) {
            return null;
        }

        return (summary.incidents && summary.incidents.length > 0) ||
            (summary.scheduled_maintenances && summary.scheduled_maintenances.length > 0) ? (
            <div className="status-page-container">
                {this.getSnackBar('incidents')}
                {this.getSnackBar('scheduled_maintenances')}
            </div>
        ) : (
            ''
        );
    }
}

const mapStateToProps = (state) => {
    const { statusPage, pharmacy } = state;
    return {
        summary: statusPage.summary,
        pharmacy,
        flags: state.launchDarkly,
    };
};

const mapDispatchToProps = {
    fetchSummary: statusPageAction.fetchSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusPage);
