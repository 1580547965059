import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, IconButton } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { htmlToPlainString } from 'utils/helper';

const CardThumbnail = (props) => {
    const {
        cardData: { card_title, image_src, card_header, additional_text },
        handleCardPreview,
        handleCardDelete,
        selectCard,
        isCardSelectable,
        isCardSelected,
    } = props;

    return (
        <Card className={`card-thumbnail ${isCardSelected ? 'selected' : ''}`}>
            <CardHeader
                title={card_title}
                titleTypographyProps={{
                    variant: 'body1',
                }}
                action={
                    <div>
                        <IconButton className="icon-btn" style={{ marginRight: '5px' }} onClick={handleCardDelete}>
                            <DeleteOutlineIcon />
                        </IconButton>
                        <IconButton className="icon-btn" style={{ marginRight: '5px' }} onClick={handleCardPreview}>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                        <IconButton
                            className="icon-btn"
                            onClick={selectCard}
                            disabled={!isCardSelectable && !isCardSelected}
                        >
                            {isCardSelected ? <CheckCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                        </IconButton>
                    </div>
                }
            />
            <CardContent>
                <Grid className="flex height100">
                    <Grid item xs={5} className="centered-content">
                        {image_src ? (
                            <img style={{ maxWidth: '100%' }} src={image_src} alt="Promo" />
                        ) : (
                            <div className="height100 no-image">
                                <ImageOutlinedIcon />
                                <span> No Promo Image </span>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={7} style={{ marginLeft: '10px' }}>
                        <Grid item style={{ padding: '5px' }} className="flex-space-between">
                            <Typography className="header" title={card_header}>
                                {card_header}
                            </Typography>
                        </Grid>
                        <Grid item style={{ padding: '5px' }}>
                            <Typography className="additional-text" title={htmlToPlainString(additional_text)}>
                                {htmlToPlainString(additional_text)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CardThumbnail;
