import React, { Fragment, useContext } from 'react';
import { FontIcon, CircularProgress, Cell } from 'react-md';
import Button from '@material-ui/core/Button';

import AudioPlayer from 'components/Common/AudioPlayer';
import PhoneNumberLink from 'components/Common/PhoneNumberLink';
import { formatPhoneNo, isPhoneNoValid, getTimelineDate } from 'utils/helper';
import { AbilityContext, Can, userActions, restrictedResources } from 'casl';

const getVoicemailMessageType = (vmMsgType) => {
    switch (vmMsgType) {
        case 0:
            return 'General';
        case 1:
            return 'Prescriber';
        case 5:
            return 'New Patient';
        default:
            return 'General';
    }
};

export const VoicemailList = ({ voicemails, fetchVoicemailAudio, markRead, type, setCurrentTrack, ...rest }) => {
    let lastCellClassName = 'flex-space-between flex-middle';
    const ability = useContext(AbilityContext);
    const { view } = userActions;
    const { subject: caslSubject, fields: restrictedFields } = restrictedResources.voicemail;

    if (
        ability &&
        ability.cannot(view, caslSubject, restrictedFields.markAsRead) &&
        ability.cannot(view, caslSubject, restrictedFields.download)
    ) {
        lastCellClassName = 'flex-right flex-middle';
    }

    return voicemails.map((voicemailData, i) => (
        <div className="grid-container" key={voicemailData.id}>
            <Cell size={2} phoneSize={2} tabletSize={4} className="flex-middle">
                <div className="phone-val" x-ms-format-detection="none">
                    {isPhoneNoValid(voicemailData.phone) ? (
                        <PhoneNumberLink
                            numberToCall={voicemailData.phone}
                            numberToShow={formatPhoneNo(voicemailData.phone)}
                        />
                    ) : (
                        'No Caller ID'
                    )}
                </div>
            </Cell>
            <Cell size={2} phoneHidden tabletHidden className="flex-middle msg-type">
                {voicemailData.refillNum
                    ? 'Rx ' + voicemailData.refillNum
                    : getVoicemailMessageType(voicemailData.messageType)}
            </Cell>
            <Cell desktopHidden tabletSize={4} phoneSize={2} className="flex-middle date-val">
                {getTimelineDate(voicemailData.callDateUTC)}
            </Cell>
            <Cell size={3} tabletSize={8} phoneSize={4} className="flex-middle voicemail-text">
                {voicemailData.transcription ? '"' + voicemailData.transcription + '"' : 'Transcription not available'}
            </Cell>
            <Can I={view} this={caslSubject} field={restrictedFields.play}>
                <Cell size={2} tabletSize={8} phoneSize={4} className="flex-middle audio-player desktop-view">
                    {voicemailData.audioId ? (
                        <AudioPlayer
                            fileSource={voicemailData.audioUrl}
                            progress
                            pauseAudio={rest.track.previous === voicemailData.id}
                            audioStarted={setCurrentTrack.bind(this, voicemailData.id)}
                            loadAudioFile={fetchVoicemailAudio.bind(this, { vmId: voicemailData.id, type })}
                        />
                    ) : (
                        ''
                    )}
                </Cell>
            </Can>

            <Can I={view} this={caslSubject} field={restrictedFields.markAsRead}>
                <Cell desktopHidden phoneSize={4} tabletSize={8} className="flex-middle">
                    {voicemailData.isProcessing ? (
                        <CircularProgress id="mark-read-processing" />
                    ) : (
                        <Button
                            id="btn-edit"
                            onClick={markRead.bind(this, { vmId: voicemailData.id, type })}
                            variant="outlined"
                        >
                            Mark as {type === 'read' ? 'Unread' : 'Read'}
                        </Button>
                    )}
                </Cell>
            </Can>

            <Cell size={3} phoneHidden tabletHidden className={lastCellClassName}>
                <Can I={view} this={caslSubject} field={restrictedFields.markAsRead}>
                    <MarkreadButton voicemailData={voicemailData} type={type} markRead={markRead} />
                </Can>

                <Can I={view} this={caslSubject} field={restrictedFields.download}>
                    {voicemailData.fetchingAudio ? (
                        <CircularProgress id="voicemails-loading" />
                    ) : (
                        <div
                            className="user-clickable mark-read-btn flex-middle"
                            style={{ flexDirection: 'column' }}
                            onClick={rest.showConfirmDownload.bind(this, { voicemail: voicemailData, type })}
                        >
                            <FontIcon>get_app</FontIcon>
                            <div className="btn-text">Download</div>
                        </div>
                    )}
                </Can>
                <div className="date-val">{getTimelineDate(voicemailData.callDateUTC)}</div>
            </Cell>
        </div>
    ));
};

export default VoicemailList;

export const MarkreadButton = ({ type, voicemailData, markRead }) => {
    return (
        <div
            className="mark-read-btn flex-middle user-clickable"
            style={{ flexDirection: 'column' }}
            onClick={markRead.bind(this, { vmId: voicemailData.id, type })}
        >
            {voicemailData.isProcessing ? (
                <CircularProgress id="mark-read-processing" />
            ) : (
                <Fragment>
                    <FontIcon>{type === 'read' ? 'markunread' : 'drafts'}</FontIcon>
                    <div className="btn-text">Mark as {type === 'read' ? 'Unread' : 'Read'}</div>
                </Fragment>
            )}
        </div>
    );
};
