import React, { PureComponent, Fragment } from 'react';

import _ from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Card, CardTitle, Grid, CardText } from 'react-md';

import Videos from './Videos';
import Overview from './Overview';
import Handouts from './Handouts';
import Presentations from './Presentations';
import { Divider } from '@material-ui/core';

class CourseContent extends PureComponent {
    constructor(props) {
        super(props);
        this.contentAndTitleMap = {
            overview: 'Overview',
            slideshow: 'Presentations',
            video: 'Videos',
            handout: 'Handouts',
        };
        this.contentDisplayOrder = Object.keys(this.contentAndTitleMap);
        this.availableCourseContent = _.memoize(this.availableCourseContent);
    }

    mapCourseContentToDisplayOrder = () => {
        const { courseContent } = this.props;
        const courseContentCopy = {};

        this.contentDisplayOrder.forEach((contentTitle) => {
            if (courseContent[contentTitle] !== undefined) {
                courseContentCopy[contentTitle] = courseContent[contentTitle];
            }
        });
        return courseContentCopy;
    };

    availableCourseContent = () => {
        const courseContent = this.mapCourseContentToDisplayOrder();
        return Object.keys(courseContent) || [];
    };

    getIndexForCourseContent = (courseContent) => {
        const index = _.indexOf(this.availableCourseContent(), courseContent);
        return index === -1 ? 0 : index;
    };

    getCourseContentForIndex = (index) => {
        return this.availableCourseContent()[index] || 'overview';
    };

    overview = () => <Overview content={_.get(this, 'props.courseContent.overview', [])} />;

    video = () => <Videos content={_.get(this, 'props.courseContent.video', [])} />;

    handout = () => <Handouts content={_.get(this, 'props.courseContent.handout', [])} />;

    slideshow = () => <Presentations content={_.get(this, 'props.courseContent.slideshow', [])} />;

    handleTabChange = (e, newTabIndex) => {
        const { changeSelectedCourseContent } = this.props;
        changeSelectedCourseContent(this.getCourseContentForIndex(newTabIndex));
    };

    tabs = () => {
        const { selectedCourseContent } = this.props;

        return (
            <Tabs
                value={this.getIndexForCourseContent(selectedCourseContent)}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleTabChange}
            >
                {this.availableCourseContent().map((contentTitle) => (
                    <Tab
                        key={contentTitle}
                        label={this.contentAndTitleMap[contentTitle]}
                        style={{
                            textTransform: 'none',
                            fontSize: '14px',
                        }}
                    />
                ))}
            </Tabs>
        );
    };

    tabPanels = () => {
        const { selectedCourseContent } = this.props;
        const selectedContentTabIndex = this.getIndexForCourseContent(selectedCourseContent);

        return this.availableCourseContent().map((contentTitle, index) => (
            <Typography
                component="div"
                role="tabpanel"
                hidden={index !== selectedContentTabIndex}
                id={`tabpanel-${contentTitle}`}
                key={`tabpanel-${contentTitle}`}
            >
                {index === selectedContentTabIndex && ((this[contentTitle] && this[contentTitle]()) || null)}
            </Typography>
        ));
    };

    render() {
        const { courseTitle } = this.props;

        return (
            <Grid>
                <Card className="outer-card md-cell md-cell--12">
                    <CardTitle
                        title="Interactive Wellness Classes"
                        subtitle={courseTitle}
                        style={{ marginLeft: '16px' }}
                    />
                    <CardText>
                        <Fragment>
                            {this.tabs()}
                            <Divider />
                            {this.tabPanels()}
                        </Fragment>
                    </CardText>
                </Card>
            </Grid>
        );
    }
}

export default CourseContent;
