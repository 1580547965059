export const PatientUpdateEventType = {
    birthday_opportunities_completed: 'birthday_opportunities_completed',
    opportunities_completed: 'opportunities_completed',
    update_patient_opportunities: 'update_patient_opportunities',
    create_user: 'create_user',
    create_message: 'create_message',
    edit_user: 'edit_user',
    verify_user: 'verify_user',
    update_message_read_status: 'update_message_read_status',
    sms_sent: 'sms_sent',
    update_user_phone_type: 'update_user_phone_type',
};
