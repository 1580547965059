import React, { Component, Fragment } from 'react';
import { Button, DialogContainer, CircularProgress, Cell, FontIcon, SelectionControlGroup, Divider } from 'react-md';
import { ReactMicPlus } from 'components/Common/MicPlus';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as musicMetadata from 'music-metadata-browser';

import AudioPlayer from 'components/Common/AudioPlayer';
import { allowedAudioTypeList, allowedTextTypeList } from 'constants/Common';
import convertWav from 'components/Common/MicPlus/libs/audioConvertWav';
import WebWorker from 'components/Common/MicPlus/libs/mp3worker';
import { MarkDefaultConfirm } from 'components/Settings/PhoneSystem/Common/MarkDefaultConfirm';
import { GREETING_TYPE } from 'constants/MainGreeting';
import { AbilityContext, userActions, restrictedResources } from 'casl';

const styles = (theme) => ({
    root: {
        maxHeight: '53px',
    },
});

export class GreetingEditor extends Component {
    static contextType = AbilityContext;

    constructor(props) {
        super(props);
        this.mp3Worker = null;
        this.dateRangeErr = false;
        this.markDefaultData = null;
        this.greetingFileList = [];
        this.fileList = [];

        // Internet Explorer 6-11
        this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
        this.noMediaRecorderSupport = false;
        if (typeof MediaRecorder === 'undefined') {
            this.noMediaRecorderSupport = true;
        }
        let dateOption = 'today';

        if (props.greeting.msgType === 'default') {
            dateOption = 'default';
        } else if (props.greeting.msgType === 'special') {
            dateOption = 'future';
        }

        this.state = {
            showRecording: false,
            selectedFileBlob: null,
            countDownStarted: false,
            recordStatus: false, //'inactive',
            isDialogVisible: false,
            editedFileName: null,
            hasAudioSupport: false,
            markDefaultConfirmVisible: false,
            dateOption,
            greetingConflictPromptVisible: false,
            greetingScriptErr: false,
            invalidCharsErr: false,
        };
        this.allowedAudioTypes = allowedAudioTypeList.join(',');
    }

    componentDidMount = () => {
        const { showToastMessage } = this.props;
        // check for audio recording support on device
        if (navigator.mediaDevices && !this.noMediaRecorderSupport) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    this.setState({
                        hasAudioSupport: true,
                    });
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                })
                .catch((e) => {
                    showToastMessage({ text: 'No mic detected to record greeting', type: 'error' });
                });
        } else {
            showToastMessage({ text: 'Audio Recording not supported in browser', type: 'error' });
        }
        this.mp3Worker = new Worker(WebWorker);
    };

    onStop = (recordedObj) => {
        const { greetingFiles, handleChange, isVisible } = this.props;
        if (isVisible) {
            const selectedFileBlob = { file: recordedObj.blob, name: '' };
            const greetingFileNames = greetingFiles.map((file) => file.name);
            selectedFileBlob.name = this.getNewFileName(greetingFileNames);
            this.setState({
                selectedFileBlob,
                showRecording: false,
                greetingScriptErr: false,
            });
            handleChange('');
        }
    };

    isSameDate = ({ startDate, endDate, newStartDate, newEndDate }) =>
        startDate.isSame(newStartDate) || endDate.isSame(newEndDate);

    isNewInBetweenOldDates = ({ startDate, endDate, newStartDate, newEndDate }) =>
        newStartDate.isBetween(startDate, endDate) || newEndDate.isBetween(startDate, endDate);

    isOldInBetweenNewDates = ({ startDate, endDate, newStartDate, newEndDate }) =>
        startDate.isBetween(newStartDate, newEndDate) || endDate.isBetween(newStartDate, newEndDate);

    checkGreetingOverlap = () => {
        const { exceptionGreetings, greeting } = this.props;
        const { dateOption } = this.state;
        let hasConflict = false;

        if (exceptionGreetings) {
            const startDate =
                dateOption === 'today'
                    ? moment().hour(0).minute(0).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                    : greeting.startDate;
            const endDate =
                dateOption === 'today'
                    ? moment().hour(23).minute(59).seconds(0).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                    : greeting.endDate;

            exceptionGreetings.forEach((specialGreeting) => {
                if (specialGreeting.statusId === 1 && specialGreeting.id !== greeting.id) {
                    const newStartDate = moment(specialGreeting.startDate);
                    const newEndDate = moment(specialGreeting.endDate);
                    const data = { startDate: moment(startDate), endDate: moment(endDate), newStartDate, newEndDate };

                    if (
                        this.isSameDate(data) ||
                        this.isNewInBetweenOldDates(data) ||
                        this.isOldInBetweenNewDates(data)
                    ) {
                        hasConflict = true;
                    }
                }
            });
        }

        return hasConflict;
    };

    saveGreeting = (filenameWithExt) => {
        const {
            greeting,
            greetingId,
            saveHandler,
            showToastMessage,
            defaultGreeting,
            greetingType,
            greetingScript,
        } = this.props;
        const { selectedFileBlob, dateOption, editedFileName } = this.state;
        let isValid = true;

        if (editedFileName === '') return;

        if (!greetingId && !selectedFileBlob && !greetingScript) {
            isValid = false;
            showToastMessage({ text: 'Select a greeting message to save', type: 'error' });
        } else if (dateOption === 'future' && this.dateRangeErr) {
            isValid = false;
            showToastMessage({ text: 'Fix the date error(s) to save', type: 'error' });
        } else if ((dateOption === 'future' || dateOption === 'today') && this.checkGreetingOverlap()) {
            isValid = false;
            this.toggleGreetingConflictPrompt();
        } else if (this.state.greetingScriptErr || this.state.invalidCharsErr) {
            isValid = false;
            showToastMessage({ text: 'Fix script error(s) before saving', type: 'error' });
        }

        if (isValid) {
            const fileObj =
                greetingType === GREETING_TYPE['CUSTOM_TEXT'] && !greetingId
                    ? { file: new Blob([greetingScript], { type: 'text/plain' }), name: `${editedFileName}.txt` }
                    : selectedFileBlob;
            const fullFileName =
                greetingType === GREETING_TYPE['CUSTOM_TEXT'] && editedFileName
                    ? `${editedFileName}.txt`
                    : filenameWithExt;
            const data = {
                fileObj,
                editedFileName,
                filenameWithExt: fullFileName,
                defaultCheck: dateOption === 'default',
                dateRangeCheck: dateOption === 'future',
            };
            // check if default greeting is overwritten
            const notEditingIVRGreeting = defaultGreeting && greeting.id !== defaultGreeting.id;
            if (dateOption === 'default' && defaultGreeting.greeting_id && notEditingIVRGreeting) {
                this.showMarkDefault(data);
            } else {
                saveHandler(data);
            }
        }
    };

    hideDialog = () => {
        const { closeHandler } = this.props;
        closeHandler();
    };

    handleGreetingChange = async (e) => {
        const value = e.target.value;
        const { handleChange, changeGreetingType, changeGreetingScript } = this.props;
        this.setState({
            selectedFileBlob: null,
            editedFileName: null,
            greetingScriptErr: false,
        });
        if (value === 'upload_file') {
            this.fileControl.click();
        } else {
            const { contentType } = this.fileList[value];
            if (this.fileList[value].contentType === 'text/plain') {
                changeGreetingType(GREETING_TYPE['CUSTOM_TEXT']);
            } else {
                changeGreetingType(GREETING_TYPE['CUSTOM_AUDIO']);
                changeGreetingScript('');
            }
            handleChange({ id: value, contentType });
        }
    };

    handleFileUpload = async (e) => {
        const { showToastMessage } = this.props;
        let toastMsg;

        if (e.target.files.length) {
            const selFile = e.target.files[0];
            e.target.value = '';
            const metadata = await musicMetadata.parseBlob(selFile);
            // check if the file is wave container and PCM/a-law/mu-law codec
            if (metadata.format.container === 'WAVE' && metadata.format.codec.indexOf('PCM') > -1) {
                //chk if samplerate is 8khz and mono channel then upload
                if (metadata.format.sampleRate === 8000 && metadata.format.numberOfChannels === 1) {
                    this.updateFileUpload(selFile, selFile);
                } else {
                    //otherwise resample
                    this.makePlumCompatibleAudio(selFile);
                }
            } else {
                toastMsg = `Only .wav file with PCM/a-law/mu-law encoding is supported`;
                showToastMessage({ text: toastMsg, type: 'error' });
            }
        }
    };

    // encode to mp3 format
    audioMp3 = (renderedBuffer, selFile) => {
        const wavBuf = convertWav(renderedBuffer);
        this.mp3Worker.postMessage({
            cmd: 'init',
            config: { bitRate: 128 },
        });
        this.mp3Worker.postMessage({ cmd: 'encode', rawInput: wavBuf });
        this.mp3Worker.postMessage({ cmd: 'finish' });

        this.mp3Worker.onmessage = (e) => {
            if (e.data.cmd === 'end') {
                const mp3Blob = new Blob(e.data.buf, { type: 'audio/mp3' });
                this.updateFileUpload(mp3Blob, selFile);
            }
        };
    };

    audioWav = (renderedBuffer, selFile) => {
        const wavBlob = new Blob([new DataView(convertWav(renderedBuffer))], { type: 'audio/wav' });
        this.updateFileUpload(wavBlob, selFile);
    };

    makePlumCompatibleAudio = (selFile) => {
        let fileReader = new FileReader();
        // 8K sample rate for wav and 32k for mp3
        const desiredSampleRate = selFile.type.indexOf('wav') > -1 ? 8000 : 32000;
        fileReader.onloadend = (evt) => {
            const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

            audioCtx.decodeAudioData(evt.target.result).then((sourceAudioBuffer) => {
                // resample the sample rate
                var offlineCtx = new OfflineAudioContext({
                    numberOfChannels: 1,
                    length: desiredSampleRate * sourceAudioBuffer.duration,
                    sampleRate: desiredSampleRate,
                });
                var soundSource = offlineCtx.createBufferSource();
                soundSource.buffer = sourceAudioBuffer;
                soundSource.connect(offlineCtx.destination);

                offlineCtx.oncomplete = (ctxEvt) => {
                    // get the resampled audioBuffer
                    if (selFile.type.indexOf('wav') > -1) {
                        this.audioWav(ctxEvt.renderedBuffer, selFile);
                    } else if (selFile.type.indexOf('mp3') > -1) {
                        this.audioMp3(ctxEvt.renderedBuffer, selFile);
                    }
                };
                offlineCtx.startRendering();
                soundSource.start(0);
            });
        };

        fileReader.readAsArrayBuffer(selFile);
    };

    updateFileUpload = (fileBlob, selFile) => {
        const { showToastMessage, greetingFiles, handleChange, changeGreetingType, changeGreetingScript } = this.props;

        const selectedFileBlob = { file: fileBlob, name: selFile.name };
        const greetingFileNames = greetingFiles.map((file) => file.name);
        if (greetingFileNames.indexOf(selectedFileBlob.name) !== -1) {
            showToastMessage({ text: 'File with this name already exists', type: 'error' });
        }
        this.setState({
            selectedFileBlob,
            editedFileName: null,
            greetingScriptErr: false,
        });
        changeGreetingType(GREETING_TYPE['CUSTOM_AUDIO']);
        changeGreetingScript('');
        handleChange('');
    };

    toggleRecording = () => {
        const { changeGreetingType, changeGreetingScript } = this.props;
        const { showRecording } = this.state;
        if (showRecording) {
            this.setState({
                recordStatus: false, //'inactive'
            });
        } else {
            changeGreetingType(GREETING_TYPE['CUSTOM_AUDIO']);
            changeGreetingScript('');
            this.setState({
                countDownStarted: true,
                showRecording: true,
                counterVal: 3,
                editedFileName: null,
            });
            setTimeout(() => {
                this.updateCounter();
            }, 1000);
        }
    };

    getSelectBoxFileList = () => {
        this.greetingFileList = [];
        this.fileList = [];
        const { greetingFiles } = this.props;
        const { create } = userActions;
        const { subject: greeting } = restrictedResources.ivr.generalGreeting;
        // NOTE: currently using general greeting as catchall; there are multiple greeting types that this common comp is used to render
        // So some context of the particular greeting type will be needed to get more granular control
        const canCreateGreeting = this.context.can(create, greeting);

        if (canCreateGreeting) {
            this.greetingFileList.push({ name: 'Upload a new greeting', id: 'upload_file' });
        }

        greetingFiles.forEach((element) => {
            const allowedFileType = allowedAudioTypeList.concat(allowedTextTypeList);
            if (allowedFileType.indexOf(element.contentType) !== -1) {
                let { name, id } = element;
                this.greetingFileList.push({ name, id });
                this.fileList[id] = element;
            }
        });
    };

    getNewFileName = (greetingFileNames) => {
        let newFilename = '';
        let i = 1;
        while (!newFilename) {
            const filename = `Recording ${i}.wav`;
            newFilename = greetingFileNames.indexOf(filename) !== -1 ? '' : filename;
            i += 1;
        }
        return newFilename;
    };

    getNewTextFileName = () => {
        const { greetingFiles } = this.props;
        const greetingFileNames = greetingFiles.map((file) => file.name);

        let newFilename = '';
        let i = 1;

        while (!newFilename) {
            const filename = `Text Greeting ${i}.txt`;
            newFilename =
                greetingFileNames.indexOf(filename) !== -1 ? '' : filename.substring(0, filename.lastIndexOf('.'));
            i += 1;
        }
        return newFilename;
    };

    onFilenameChange = (event) => {
        const val = event.target.value;
        if (val.length <= 25) {
            this.setState({
                editedFileName: val,
            });
        }
    };

    updateCounter() {
        let { counterVal } = this.state;
        counterVal -= 1;
        if (!counterVal) {
            this.setState({
                recordStatus: true, //'recording',
                countDownStarted: false,
            });
        } else {
            this.setState({
                counterVal,
            });
            setTimeout(() => {
                this.updateCounter();
            }, 1000);
        }
    }

    handleRadioChange = (val) => {
        this.setState({
            dateOption: val,
        });
    };

    componentDidUpdate = (prevProps) => {
        const { isVisible, greeting } = this.props;
        let dateOption = 'today';

        if (!isVisible && prevProps.isVisible) {
            this.setState(
                {
                    selectedFileBlob: null,
                    editedFileName: null,
                    dateOption,
                    recordStatus: false,
                    greetingScriptErr: false,
                },
                () => {
                    this.setState({ showRecording: false, isDialogVisible: false });
                }
            );
        } else if (isVisible && !prevProps.isVisible) {
            if (greeting.msgType === 'special') {
                dateOption = 'future';
            } else if (greeting.msgType === 'default') {
                dateOption = 'default';
            }

            this.setState({
                isDialogVisible: true,
                dateOption,
            });
        }
    };

    handleDateChange = (date, type, day) => {
        const { handleRangeChange, greeting } = this.props;
        let { startDate, endDate } = greeting;
        if (type === 'start') {
            startDate = date;
        } else {
            endDate = date;
        }
        this.dateRangeErr = false;
        if (!moment(startDate).isSameOrBefore(endDate)) {
            this.dateRangeErr = true;
        }
        handleRangeChange({ startDate, endDate });
    };

    showMarkDefault = (data) => {
        this.markDefaultData = data;
        this.setState({
            markDefaultConfirmVisible: true,
        });
    };

    hideMarkDefault = () => {
        this.markDefaultData = null;
        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    confirmMarkDefault = () => {
        const { saveHandler } = this.props;

        saveHandler(this.markDefaultData);
        this.markDefaultData = null;

        this.setState({
            markDefaultConfirmVisible: false,
        });
    };

    toggleGreetingConflictPrompt = () => {
        const { greetingConflictPromptVisible } = this.state;
        this.setState({
            greetingConflictPromptVisible: !greetingConflictPromptVisible,
        });
    };

    updateGreetingScript = (event) => {
        const { changeGreetingScript } = this.props;
        const script = event.target.value;
        if (script.length <= 2500) {
            this.setState({ greetingScriptErr: script === '' });
            changeGreetingScript(script);
        }

        this.setState({ invalidCharsErr: false }); // reset helper text
        const regex = /([&<>])+/;
        if (script.match(regex)) {
            this.setState({ invalidCharsErr: true });
        }
    };

    addGreetingScript = () => {
        const { changeGreetingType, handleChange } = this.props;
        const newFilename = this.getNewTextFileName();
        this.setState({
            selectedFileBlob: null,
            editedFileName: newFilename,
        });
        handleChange('');
        changeGreetingType(GREETING_TYPE['CUSTOM_TEXT']);
    };

    render() {
        this.getSelectBoxFileList();
        const {
            greeting,
            greetingId,
            dialogTitle,
            greetingUrl,
            isSaving,
            classes,
            greetingType,
            greetingScript,
        } = this.props;

        const {
            showRecording,
            selectedFileBlob,
            countDownStarted,
            counterVal,
            recordStatus,
            editedFileName,
            dateOption,
            hasAudioSupport,
            greetingScriptErr,
            invalidCharsErr,
            isDialogVisible,
            markDefaultConfirmVisible,
            greetingConflictPromptVisible,
        } = this.state;

        const { create } = userActions;
        const { subject: greetingSubject } = restrictedResources.ivr.generalGreeting;
        // NOTE: currently using general greeting as catchall; there are multiple greeting types that this common comp is used to render
        // So some context of the particular greeting type will be needed to get more granular control
        const canCreateGreeting = this.context.can(create, greetingSubject);

        const filename = greetingId && this.fileList[greetingId] ? this.fileList[greetingId].name : '';
        const fileUrl =
            greetingId && this.fileList[greetingId] && this.fileList[greetingId].contentType !== 'text/plain'
                ? greetingUrl
                : '';
        const audioUrl = selectedFileBlob ? URL.createObjectURL(selectedFileBlob.file) : fileUrl;
        const filenameWithExt = selectedFileBlob ? selectedFileBlob.name : filename;
        const plainFileName = filenameWithExt.substring(0, filenameWithExt.lastIndexOf('.'));

        const markDefaultActions = [];
        markDefaultActions.push(
            <Button flat primary onClick={this.hideMarkDefault}>
                Cancel
            </Button>
        );
        markDefaultActions.push(
            <Button raised secondary onClick={this.confirmMarkDefault}>
                Confirm
            </Button>
        );

        const radioOptions = [
            {
                label: 'Active for today only',
                value: 'today',
            },
            {
                label: 'Active for future date(s)',
                value: 'future',
            },
        ];

        if (!greeting.id) {
            radioOptions.push({
                label: 'Make default',
                value: 'default',
            });
        }

        return (
            <Fragment>
                <DialogContainer
                    id="greeting-editor-dialog"
                    className={countDownStarted ? 'greeting-editor-dialog counter' : 'greeting-editor-dialog'}
                    visible={isDialogVisible}
                    modal
                    aria-label="greeting editor"
                >
                    {countDownStarted && (
                        <div className="md-overlay md-overlay--active countdown centered-content">{counterVal}</div>
                    )}

                    <div className="dialogTitle">{dialogTitle}</div>
                    <div className="dialogSubTitle" style={{ marginBottom: '8px' }}>
                        Upload, record or type your greeting here. To record, ensure your microphone is enabled.
                        <Tooltip
                            classes={{ tooltip: 'info-tooltip' }}
                            placement="top"
                            enterTouchDelay={0}
                            title="Upload .wav file with 8Khz sample rate and 16-bit PCM encoding"
                        >
                            <FontIcon className="info-tooltip-icon" style={{ marginLeft: '5px' }}>
                                info
                            </FontIcon>
                        </Tooltip>
                    </div>
                    <input
                        ref={(field) => {
                            this.fileControl = field;
                        }}
                        type="file"
                        style={{ display: 'none' }}
                        accept={this.allowedAudioTypes}
                        onChange={this.handleFileUpload}
                    />

                    {(editedFileName !== null || filenameWithExt) && (
                        <div className="grid-container">
                            <Cell size={6} tabletSize={4} phoneSize={4}>
                                <TextField
                                    id="greeting-name"
                                    label="Greeting Name"
                                    fullWidth
                                    disabled={isSaving}
                                    value={editedFileName !== null ? editedFileName : plainFileName}
                                    onChange={this.onFilenameChange}
                                    margin="normal"
                                    variant="outlined"
                                    error={editedFileName === ''}
                                />
                                {editedFileName === '' && (
                                    <FormHelperText error>Please provide a filename</FormHelperText>
                                )}
                            </Cell>
                        </div>
                    )}

                    <div className="grid-container">
                        <Cell size={6} tabletSize={4} phoneSize={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-lang">Select a file</InputLabel>
                                <Select
                                    value={greetingId}
                                    disabled={isSaving}
                                    className={classes.root}
                                    onChange={this.handleGreetingChange}
                                    input={<OutlinedInput fullWidth labelWidth={75} name="lang" id="outlined-lang" />}
                                >
                                    {this.greetingFileList.map(({ id, name }, tIdx) => (
                                        <MenuItem key={`greeting_${tIdx}`} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Cell>

                        {audioUrl && (
                            <Cell size={6} tabletSize={4} phoneSize={4} className="flex-middle audio-player">
                                <AudioPlayer labelText="Preview" fileName={filenameWithExt} fileSource={audioUrl} />
                            </Cell>
                        )}
                    </div>

                    {canCreateGreeting && hasAudioSupport && (
                        <Fragment>
                            <div className="grid-container">
                                <Cell size={6} tabletSize={1} phoneSize={4}>
                                    <Divider />
                                </Cell>
                            </div>
                            <div className="grid-container">
                                <Cell size={6} tabletSize={3} phoneSize={4}>
                                    <Button
                                        primary
                                        raised
                                        swapTheming
                                        disabled={isSaving}
                                        className={
                                            recordStatus ? 'bordered-button-red recording' : 'bordered-button-red'
                                        }
                                        onClick={() => this.toggleRecording()}
                                    >
                                        {recordStatus ? 'Stop recording' : 'Record a greeting'}
                                    </Button>
                                </Cell>

                                <Cell size={6} tabletSize={3} phoneSize={4}>
                                    {showRecording && (
                                        <ReactMicPlus
                                            audioType="audio/wav"
                                            record={recordStatus}
                                            strokeColor="#d0021b"
                                            backgroundColor="#fff"
                                            width={100}
                                            height={50}
                                            audioBitsPerSecond={44100}
                                            onStop={this.onStop}
                                        />
                                    )}
                                </Cell>
                            </div>
                        </Fragment>
                    )}

                    {canCreateGreeting && (
                        <Fragment>
                            <div className="grid-container">
                                <Cell size={6}>
                                    <Divider />
                                </Cell>
                            </div>

                            <div className="grid-container">
                                <Cell size={6}>
                                    <Button
                                        primary
                                        raised
                                        onClick={() => this.addGreetingScript()}
                                        disabled={greetingType === GREETING_TYPE['CUSTOM_TEXT'] && greetingId !== ''}
                                        className={`bordered-button-blue type-greeting-button
                  ${greetingType === GREETING_TYPE['CUSTOM_TEXT'] ? 'active' : ''}`}
                                    >
                                        Type a greeting
                                    </Button>
                                </Cell>
                            </div>
                        </Fragment>
                    )}

                    {greetingType === GREETING_TYPE['CUSTOM_TEXT'] && (
                        <div className="grid-container">
                            <Cell size={10} tabletSize={4} phoneSize={4}>
                                <TextField
                                    id="greeting-script"
                                    label="Greeting Text"
                                    fullWidth
                                    disabled={isSaving}
                                    value={greetingScript === undefined ? '' : greetingScript}
                                    onChange={this.updateGreetingScript}
                                    margin="normal"
                                    variant="outlined"
                                    error={greetingScriptErr}
                                    multiline
                                    rowsMax="4"
                                    InputProps={{
                                        readOnly: greetingId !== '',
                                    }}
                                />
                                <FormHelperText
                                    error={invalidCharsErr}
                                    style={{ fontWeight: 700 }}
                                >{`Special Characters Not Allowed: & < >`}</FormHelperText>
                                {greetingScriptErr && <FormHelperText error>Please provide a script</FormHelperText>}
                                {!greetingScriptErr && (
                                    <FormHelperText>
                                        {greetingScript !== undefined && greetingScript.length
                                            ? greetingScript.length + ' Characters'
                                            : 'Max 2500 characters'}
                                    </FormHelperText>
                                )}
                            </Cell>
                        </div>
                    )}

                    {greeting.startDate && (
                        <Fragment>
                            <div className="grid-container greeting-date-option">
                                <Cell size={8} style={{ marginBottom: 0 }}>
                                    <SelectionControlGroup
                                        id="radio-greeting-date-options"
                                        name="radio-greeting-date-options"
                                        type="radio"
                                        value={dateOption}
                                        onChange={this.handleRadioChange}
                                        controls={radioOptions}
                                    />
                                </Cell>
                            </div>
                        </Fragment>
                    )}

                    {greeting.startDate && dateOption === 'future' && (
                        <div className="grid-container">
                            <Cell size={6} style={{ marginTop: 0, position: 'relative' }}>
                                <div
                                    className="grid-container date-range-picker"
                                    ref={(field) => {
                                        this.container = field;
                                    }}
                                >
                                    <Cell size={6} tabletSize={4} phoneSize={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                label="Start"
                                                value={moment(greeting.startDate)}
                                                format="MM/dd/yyyy"
                                                disablePast
                                                onChange={(dateVal) => this.handleDateChange(dateVal, 'start')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Cell>
                                    <Cell size={6} tabletSize={4} phoneSize={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                label="End"
                                                value={moment(greeting.endDate)}
                                                format="MM/dd/yyyy"
                                                minDate={moment(greeting.startDate)}
                                                onChange={(dateVal) => this.handleDateChange(dateVal, 'end')}
                                                minDateMessage={<span></span>}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Cell>
                                </div>
                                {!moment(greeting.startDate).isSameOrBefore(greeting.endDate) && (
                                    <div className="date-picker-errmsg">
                                        End date shouldn't be before the start date
                                    </div>
                                )}
                            </Cell>
                        </div>
                    )}

                    {isSaving ? (
                        <CircularProgress id="greeting-editor-spinner" />
                    ) : (
                        <div className="flex-right">
                            <Button flat primary onClick={this.hideDialog} className="standard-margin-right-10">
                                Cancel
                            </Button>
                            <Button raised secondary onClick={() => this.saveGreeting(filenameWithExt)}>
                                Save Greeting
                            </Button>
                        </div>
                    )}
                </DialogContainer>

                <DialogContainer
                    id="conflict-greeting-prompt"
                    visible={greetingConflictPromptVisible}
                    onHide={this.toggleGreetingConflictPrompt}
                    actions={[
                        <Button flat primary onClick={this.toggleGreetingConflictPrompt}>
                            Ok
                        </Button>,
                    ]}
                    title="Conflict Greeting"
                >
                    <span>
                        Date conflict detected. Please make sure that the selected dates do not overlap with an existing
                        scheduled greeting prior to saving.
                    </span>
                </DialogContainer>

                <MarkDefaultConfirm
                    hideMarkDefault={this.hideMarkDefault}
                    isVisible={markDefaultConfirmVisible}
                    actions={markDefaultActions}
                />
            </Fragment>
        );
    }
}

export default withStyles(styles)(GreetingEditor);
