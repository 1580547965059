import './App.sass';
import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BottomNavigation, FontIcon, Cell, Toolbar, Button, SVGIcon } from 'react-md';
import _ from 'lodash';
import { connect } from 'react-redux';
import ldRedux from 'ld-redux';

import Config from 'config';
import Login from 'components/Login';
import Snackbar from 'components/Snackbar';
import ProtectedRoutes from 'components/ProtectedRoutes';
import ROICalculatorPage from 'components/ROICalculatorPage';
import ForgotPassword from 'components/ForgotPassword';
import ResetPassword from 'components/ResetPassword';
import StatusPage from 'components/StatusPage';
import { ToolbarChildren } from './AppBar';
import IconMenus from './IconMenus';
import CustomScreenLoader from 'components/Common/CustomScreenLoader';
import { trackPageViewForPath } from '../utils/helper';
import Hidden from '@material-ui/core/Hidden';

import { authSelectors } from 'redux/Auth';
import { AbilityContext, buildAbilitiesFor, userActions, restrictedResources } from 'casl';
import Snowplow from 'snowplow';
import 'moment-timezone'; // have to load this get the plugin to work

const IntercomSvgIcon = () => (
    <SVGIcon>
        <svg viewBox="0 0 28 32">
            <path
                fill="#fff"
                d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"
            ></path>
        </svg>
    </SVGIcon>
);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#3793ff',
        },
        secondary: {
            main: '#d0021b',
        },
    },
});

let ldProvider;

class App extends Component {
    constructor(props) {
        super(props);
        this.appBottomNavlinks = [];
        this.unlistenHistory = props.history.listen(trackPageViewForPath);
    }

    componentDidMount() {
        Snowplow.structEvent('App', 'init', null);
    }

    componentWillUnmount() {
        this.unlistenHistory();
    }

    handleNavChange = (activeIndex) => {
        this.props.history.push(this.appBottomNavlinks[activeIndex].path);
    };

    setBottomNavLinks = (userAbilities) => {
        const { view } = userActions;
        const { inbox, refills, reports, voicemail, leads } = restrictedResources;

        this.appBottomNavlinks = [];

        if (userAbilities.can(view, inbox.subject)) {
            this.appBottomNavlinks.push({
                label: 'Inbox',
                icon: <FontIcon>chat_bubble</FontIcon>,
                path: '/inbox',
            });
        }

        if (userAbilities.can(view, reports.subject)) {
            this.appBottomNavlinks.push({
                label: 'Reports',
                icon: <FontIcon>timeline</FontIcon>,
                path: '/reports',
            });
        }

        if (userAbilities.can(view, voicemail.subject)) {
            this.appBottomNavlinks.push({
                label: 'Voicemail',
                icon: <FontIcon>voicemail</FontIcon>,
                path: '/voicemail',
            });
        }

        if (userAbilities.can(view, refills.subject)) {
            this.appBottomNavlinks.push({
                label: 'Refills',
                icon: <FontIcon>view_list</FontIcon>,
                path: '/refills',
            });
        }

        if (userAbilities.can(view, leads.subject)) {
            this.appBottomNavlinks.push({
                label: 'Leads',
                icon: <FontIcon>public</FontIcon>,
                path: '/leads',
            });
        }
    };

    showToolbar = () => {
        const {
            isAuthorized,
            location: { pathname },
        } = this.props;
        // Show toolbar when
        // 1: User is authorized
        // 2: The path does not include settings, because settings has a toolbar of its own :D
        return isAuthorized && !pathname.includes('settings');
    };

    setLastVisitedPage = () => {
        const {
            location: { pathname, search },
        } = this.props;

        if (pathname !== '/' && !pathname.endsWith('contract-received')) {
            // Following is required because it causing issues on inbox mobile
            // page to reload initially when user clicks on contact (Framer motion issue)
            const strippedPathName = pathname.replace(/\/$/, '');
            sessionStorage.setItem('lastPageVisited', `${strippedPathName}${search}`);
        }
    };

    initiateLaunchDarkly = () => {
        const { userAccount } = this.props;

        ldRedux.init({
            clientSideId: Config.ld_key,
            dispatch: this.props.dispatch,
            user: {
                key: userAccount.user_id,
                custom: {
                    pharm_uuid: Config.X_PharmacyID,
                    loc_uuid: Config.X_LocationID,
                },
            },
            options: {
                hash: userAccount.launch_darkly_user_key_hash,
            },
        });

        ldProvider = true;
    };

    render() {
        const { user, isAuthorized, isLoaderVisible, location, isInboxConversationActive } = this.props;
        const currentRouteIdx = _.findIndex(this.appBottomNavlinks, (link) => {
            return location.pathname.indexOf(link.path) > -1;
        });
        const userAbilities = buildAbilitiesFor(user);

        this.setLastVisitedPage();
        if (isAuthorized && !ldProvider) {
            // initiate Launch darkly only after authentication and once
            this.initiateLaunchDarkly();
        }
        this.setBottomNavLinks(userAbilities);

        return (
            <AbilityContext.Provider value={userAbilities}>
                <MuiThemeProvider theme={theme}>
                    {isAuthorized && <StatusPage />}
                    <Snackbar />
                    {isLoaderVisible && <CustomScreenLoader scale={2} />}
                    {this.showToolbar() && (
                        <Toolbar
                            fixed
                            colored
                            className="no-drawer-toolbar"
                            actions={<IconMenus />}
                            children={<ToolbarChildren />}
                        />
                    )}

                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route path="/invite" component={ResetPassword} />
                        <Route path="/forgotpassword" component={ForgotPassword} />
                        <Route path="/resetpassword" component={ResetPassword} />
                        <Route path="/calculator" component={ROICalculatorPage} />
                        {isAuthorized ? <ProtectedRoutes {...this.props} /> : <Redirect to="/" />}
                    </Switch>

                    {isAuthorized && (
                        <Cell phoneSize={4} desktopHidden style={{ margin: 0 }} key={this.appBottomNavlinks.length}>
                            <Hidden mdUp>
                                {!isInboxConversationActive && (
                                    <div className="mobile-custom-intercom">
                                        <Button floating id="intercom_custom_link">
                                            <IntercomSvgIcon />
                                        </Button>
                                    </div>
                                )}
                            </Hidden>
                            <BottomNavigation
                                lastChild
                                links={this.appBottomNavlinks}
                                activeIndex={currentRouteIdx}
                                onNavChange={this.handleNavChange}
                            />
                        </Cell>
                    )}
                </MuiThemeProvider>
            </AbilityContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthorized: state.auth.isAuthorized,
        userAccount: state.auth.userAccount,
        ivrEnabled: state.pharmacy.ivrEnabled,
        isLoaderVisible: state.customLoader.visible,
        isInboxConversationActive: Boolean(state.inboxConversation.activeConversationId),
        user: authSelectors.getLoggedInUser(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));
