import _ from 'lodash';
import moment from 'moment';
import localStorageService from 'utils/localStorageService';

export function updatePatientFromInboxUserData(patientData, inboxUserData) {
    if (patientData.inbox_user_id !== inboxUserData.inbox_user_id) {
        throw new Error('May only update patients from inboxUserDatas of the same id');
    }
    const clone = _.clone(patientData);

    Object.assign(clone, _.pick(inboxUserData, ['first_name', 'last_name', 'phone', 'phone_type', 'date_of_birth']));

    clone.full_name = `${clone.first_name} ${clone.last_name}`;
    clone.birthday = clone.date_of_birth.substr(5);

    return clone;
}

export function canMessagePatient(patient, includeUnknown = false) {
    if (_.isObject(patient)) {
        const { phone_type } = patient;
        if (phone_type === 'mobile' || phone_type === 'voip') {
            return true;
        }
        if (includeUnknown && _.isNil(phone_type)) {
            return true;
        }
    }
    return false;
}

// Returns the subset of non-deleted templates that are managed by connect
export function getConnectTemplates(availableTemplates, { includeInactive = false } = {}) {
    return _.filter(
        availableTemplates,
        ({ status, workflow_status }) =>
            status !== 'deleted' && (includeInactive ? !_.isNil(workflow_status) : workflow_status === 'active')
    );
}

export function getNonTargetedConnectTemplates(availableTemplates, opts) {
    return _.filter(
        getConnectTemplates(availableTemplates, opts),
        ({ targeting_type, message_content }) =>
            _.isNil(targeting_type) && _.isNil(_.get(message_content, 'messaging_form.appt_type_id'))
    );
}

export function getTargetedConnectTemplates(availableTemplates, opts) {
    return _.filter(
        getConnectTemplates(availableTemplates, opts),
        ({ targeting_type }) => !_.isNil(targeting_type) && targeting_type !== 'waitlist'
    );
}

export function getSchedulingConnectTemplates(availableTemplates, opts) {
    return _.filter(
        getConnectTemplates(availableTemplates, opts),
        ({ targeting_type, message_content }) =>
            targeting_type === 'waitlist' || !_.isNil(_.get(message_content, 'messaging_form.appt_type_id'))
    );
}

// Returns the templates that should be visible on the card grid
export function getVisibleConnectGridTemplates(
    availableTemplates,
    connectOpportunities,
    { hideTargeted = false } = {}
) {
    return _.filter(getConnectTemplates(availableTemplates), (template) => {
        if (!_.isNil(template.targeting_type)) {
            return !hideTargeted;
        }
        return true;
    });
}

/*
 * Cycle through the next generic image by looking through all of our templates to see what has been used
 * and take the first one from the generic images list that has been used the least number of times.
 */
const GENERIC_IMAGES = [
    'generic1.v2.jpg',
    'generic2.v2.jpg',
    'generic3.v2.jpg',
    'generic4.v2.jpg',
    'generic5.v2.jpg',
    'generic6.v2.jpg',
    'generic7.v2.jpg',
    'generic8.v2.jpg',
];
export function getNextImageRef(availableTemplates) {
    const counts = {};
    _.each(getConnectTemplates(availableTemplates, true), ({ image_ref }) => {
        if (!_.isNil(image_ref)) {
            counts[image_ref] = _.get(counts, image_ref, 0) + 1;
        }
    });
    return _.first(_.sortBy(GENERIC_IMAGES, (image_ref) => _.get(counts, image_ref, 0)));
}

export function getTemplateTitle(template) {
    if (_.isObject(template)) {
        return !_.isNil(template.title) ? template.title : template.subject;
    }
}

// This method does a more explicit check than moment to see if the passed queryText seems likely to be a date_of_birth
export function isLikelyADateOfBirthQuery(queryText, dateFormat) {
    const formatTokens = _.words(dateFormat);
    const queryTokens = _.words(queryText);
    return doDateTokensMatch(formatTokens, queryTokens);
}

// This method does a more explicit check than moment to see if the passed queryText seems likely to be a birthday
export function isLikelyABirthdayQuery(queryText, dateFormat) {
    const formatTokens = _.filter(_.words(dateFormat), (w) => !_.startsWith(w, 'Y'));
    const queryTokens = _.words(queryText);
    return doDateTokensMatch(formatTokens, queryTokens);
}

// Actually do the checks to determine if the passed format tokens and query tokens match
// The currently supported date formats are 'MMM D, YYYY' and 'D MMM YYYY' (UK-only).
// *** NOTE: This is a bit brittle since any new date format tokens will need to be added here
const DATE_TOKEN_REGEXES = {
    MMM: /^[a-zA-Z]{3,}$/, // matches months as a full word or short version (examples: Sep, Sept, September, etc...)
    D: /^\d{1,2}$/, // matches day of months both padded and not
    YYYY: /^\d{1,4}$/, // matches full years but allow from 1-4 numbers so it doesn't stop working while typing
};
function doDateTokensMatch(formatTokens, queryTokens) {
    if (_.size(queryTokens) === _.size(formatTokens)) {
        // find the tokens that have a corresponding regex and the token matches the regex
        const validTokens = _.filter(queryTokens, (queryToken, i) => {
            const regex = DATE_TOKEN_REGEXES[formatTokens[i]];
            return !_.isNil(regex) && regex.test(queryToken);
        });
        // if all tokens are valid, then return true
        return _.size(validTokens) === _.size(queryTokens);
    }
    return false;
}

export function getPatientsAge(date_of_birth, longForm = false) {
    if (_.isNil(date_of_birth)) {
        return '';
    }

    const dob = moment(date_of_birth, 'YYYY-MM-DD');
    const now = moment();

    const years = now.diff(dob, 'years');
    if (years === 0) {
        return longForm ? `${now.diff(dob, 'months')} months old` : `${now.diff(dob, 'months')}mo`;
    }
    return longForm ? `${years} years old` : `${years}`;
}

const FILTER_ADAPTIONS = {
    canMessage: {
        yes: 'can-message',
        no: 'cannot-message',
        unknown: 'unknown-messageability',
    },
    convStatus: {
        'unread-pharmacy': 'unread-pharmacy',
        'unread-patient': 'unread-patient',
        messages: 'has-messages',
        'no-messages': 'has-no-messages',
    },
    patientType: {
        existing: 'patientType:existing',
        waitlist: 'patientType:waitlist',
    },
};
export function adaptQueryParamsIntoFilters(queryParams) {
    const filters = new Set();
    if (!_.isNil(queryParams.age)) {
        const op = _.endsWith(queryParams.age, '+') ? 'lte' : 'gt';
        const dob =
            op === 'gt'
                ? moment()
                      .subtract(parseInt(queryParams.age) + 1, 'years')
                      .format('YYYY-MM-DD')
                : moment().subtract(parseInt(queryParams.age), 'years').format('YYYY-MM-DD');
        filters.add(`dob|${op}:${dob}`);
    }
    _.each(_.keys(queryParams), (key) => {
        if (_.has(FILTER_ADAPTIONS, key)) {
            const value = FILTER_ADAPTIONS[key][queryParams[key]];
            if (!_.isNil(value)) {
                filters.add(value);
            }
        }
    });

    if (_.has(queryParams, 'assessments')) {
        filters.add(['assessments', queryParams.assessments]);
    }

    if (_.has(queryParams, 'appts')) {
        // normalize to actual dates and pass along timezone offset
        let start = null,
            end = null;
        const now = moment();
        switch (queryParams.appts) {
            case 'today':
                start = end = now;
                break;
            case 'tomorrow':
                start = end = now.add(1, 'day');
                break;
            case 'this_week':
                start = moment(now).subtract(Number(now.format('d')), 'days');
                end = moment(start).add(6, 'days');
                break;
            case 'next_week':
                start = moment(now)
                    .subtract(Number(now.format('d')), 'days')
                    .add(7, 'days');
                end = moment(start).add(6, 'days');
                break;
            case 'none':
                start = null;
                end = null;
                break;
            default:
                start = moment(queryParams.appts.split(':')[1], 'YYYY-MM-DD');
                end = moment(queryParams.appts.split(':')[2], 'YYYY-MM-DD');
                break;
        }

        if (start === null && end === null) {
            filters.add(['appts', 'none']);
        } else {
            filters.add([
                'appts',
                `${start.format('YYYY-MM-DD')}:${end.format('YYYY-MM-DD')}:${encodeURIComponent(moment().format('Z'))}`,
            ]);
        }
    }

    if (_.has(queryParams, 'attributes')) {
        filters.add(['attributes', queryParams.attributes]);
    }

    if (_.has(queryParams, 'waitlist')) {
        filters.add(['waitlist', queryParams.waitlist]);
    }

    if (_.has(queryParams, 'waitlistStatus')) {
        filters.add(['waitlistStatus', queryParams.waitlistStatus]);
    }

    return filters;
}

export function getTemplateImageUrl(template) {
    if (_.isObject(template) && !_.isNil(template.image_ref)) {
        return `https://static.digitalpharmacist.com/workflows/${template.image_ref}`;
    }
}

export function getActiveOpportunityCountFromState(state) {
    const { connect, inboxConversationTemplate } = state;

    const visibleOpportunityKeys = _.filter(
        _.map(
            getVisibleConnectGridTemplates(_.get(inboxConversationTemplate, 'templates')),
            ({ targeting_type }) => targeting_type
        )
    );

    if (!_.isEmpty(visibleOpportunityKeys) && !_.isNil(connect.opportunities)) {
        return _.reduce(
            visibleOpportunityKeys,
            (sum, key) => {
                return sum + _.get(connect, `opportunities.${key}.count`, 0);
            },
            0
        );
    }
}

export function getCovidWaitlistEnabledInC2(pharmacy) {
    const selectedPharmacyLocation = _.find(
        _.get(pharmacy, 'pharmacy.location'),
        (location) => location.id === pharmacy.activeLocationId
    );
    return _.get(selectedPharmacyLocation, 'service.Website.CovidWaitlistEnabled', false);
}

export function getInboxConversationTemplates(
    inboxConversationTemplate,
    launchDarkly,
    pharmacy,
    { apptTypesWithSessions = null, hideFeatureAdvertisements = false } = {}
) {
    // This function is a bit brittle since it is trying to use an LD flag that might cease to exist
    // to remove a workflow based on its title. This is the first time we are trying to link availability
    // of a workflow to an LD & C2 flag, if we continue doing this, we should add one or more columns to the inbox template
    // table that represents the flags to check and make this type of check more robust to things
    // like title changes.
    const c2CovidWaitlistEnabled = getCovidWaitlistEnabledInC2(pharmacy);
    const isCovidWaitlistAdDismissed = hideFeatureAdvertisements || localStorageService.getCovidWaitlistDismissed();
    const ldCovidWaitlistEnabled = _.get(launchDarkly, 'npeCoronavirusWaitlist', false);

    const ldApptTypesEnabled = _.get(launchDarkly, 'npeMultipleAppointmentTypes', false);
    const apptTypesById = _.keyBy(apptTypesWithSessions, ({ apptType }) => apptType.appt_type_id);
    const hasAnyActiveSimpleApptTypes = !_.isNil(
        _.find(apptTypesWithSessions, ({ apptType, sessions }) => {
            return apptType.multi_session === 0 && !_.isEmpty(sessions);
        })
    );
    const isApptTypesAdDismissed = hideFeatureAdvertisements || localStorageService.getApptTypesAdDismissed();

    return _.filter(_.get(inboxConversationTemplate, 'templates'), (template) => {
        if (template.title === 'Join COVID-19 Vaccine Waitlist') {
            return ldCovidWaitlistEnabled && (c2CovidWaitlistEnabled || !isCovidWaitlistAdDismissed);
        }

        // if this is a simple scheduling type, only show it if it has an active session
        const apptTypeId = _.get(template, 'message_content.messaging_form.appt_type_id');
        const waitlistIds = _.get(template, 'message_content.messaging_form.waitlist_ids');
        if (_.isEmpty(waitlistIds) && _.isString(apptTypeId)) {
            const hasActiveSession = !_.isEmpty(_.get(apptTypesById, `${apptTypeId}.sessions`));

            if (template.title === 'Flu Shot Reminder & Scheduling' && !hasAnyActiveSimpleApptTypes) {
                return ldApptTypesEnabled && (hasActiveSession || !isApptTypesAdDismissed);
            }

            return hasActiveSession;
        }

        return true;
    });
}

export function getFeatureAdvertisementConfigForTemplate(template, pharmacy, apptTypesWithSessions) {
    const c2CovidWaitlistEnabled = getCovidWaitlistEnabledInC2(pharmacy);
    const hasAnyActiveSimpleApptTypes = !_.isNil(
        _.find(apptTypesWithSessions, ({ apptType, sessions }) => {
            return apptType.multi_session === 0 && !_.isEmpty(sessions);
        })
    );

    if (!c2CovidWaitlistEnabled && _.get(template, 'title') === 'Join COVID-19 Vaccine Waitlist') {
        return {
            url: 'https://learn.digitalpharmacist.com/waitlists',
            dismissed: localStorageService.getCovidWaitlistDismissed(),
            additionalText: 'COVID-19 Vaccine Waitlist disabled',
            onDismiss: () => localStorageService.setCovidWaitlistDismissed(true),
            onClickPath: '/settings/coronavirus',
        };
    }

    if (!hasAnyActiveSimpleApptTypes && _.get(template, 'title') === 'Flu Shot Reminder & Scheduling') {
        return {
            url: '/appointments/types',
            dismissed: localStorageService.getApptTypesAdDismissed(),
            onDismiss: () => localStorageService.setApptTypesAdDismissed(true),
            onClickPath: '/appointments/types',
            title: 'New appointment types available',
            description:
                'Flu shots, MTM Scheduling, Covid Testing and more now available. Configure which ones you would like to offer to your patients',
        };
    }
}
