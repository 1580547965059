import './PatientsPage.sass';
import React, { useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchPatientData, setFocusedPatient, fetchPatientInboxData } from '../../redux/Patient/PatientData/action';
import { fetchPatientOpportunities, clearPatientOpportunities } from '../../redux/Patient/Connect/action';
import {
    fetchAvailableAssessedAttributes,
    clearAvailableAssessedAttributes,
} from '../../redux/Patient/Assessment/action';
import ChooseBulkMessageDialog from './ChooseBulkMessageDialog';
import { userAction } from '../../redux/Inbox/User';
import PatientsSelectionPane from './PatientsSelectionPane';
import { canMessagePatient } from './patientsUtil';
import { getTodaysDate } from './Connect/ConnectRootPage';
import BulkMessageStatusSnackbar from './Connect/BulkMessageStatusSnackbar';
import LocalStorageService from '../../utils/localStorageService';
import Snowplow from '../../snowplow';
import { pharmacySelectors } from 'redux/Pharmacy/selector';
import FullPepUpgrade from 'components/Settings/Upgrades/FullPepUpgrade/FullPepUpgrade';

function PatientsPage(props) {
    const {
        connect,
        fetchPatientOpportunities,
        clearPatientOpportunities,
        fetchAvailableAssessedAttributes,
        clearAvailableAssessedAttributes,
        fullPepEnabled,
    } = props;
    const [selectedPatients, setSelectedPatients] = useState({});
    const [connectDialogOpen, setConnectDialogOpen] = useState(false);
    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    const [nameFormat, setNameFormat] = useState(LocalStorageService.getPreferredNameFormat());
    useEffect(() => {
        LocalStorageService.setPreferredNameFormat(nameFormat);
    }, [nameFormat]);

    const messageablePatients = {};
    _.forIn(selectedPatients, (patient) => {
        if (canMessagePatient(patient, true)) {
            messageablePatients[patient.inbox_user_id] = patient;
        }
    });

    const today = getTodaysDate();
    useEffect(() => {
        if (!_.isEqual(connect.birthdayDate, today) || _.isNil(connect.opportunities)) {
            fetchPatientOpportunities(today);
        }
    }, [today, _.size(connect.opportunities)]);

    // clear the patient opportunities and assessed attributes on unmount so we will reload them fresh the next time they are needed
    useEffect(() => {
        return () => {
            clearPatientOpportunities();
            clearAvailableAssessedAttributes();
        };
    }, []);

    useEffect(() => {
        fetchAvailableAssessedAttributes();
    }, []);

    useEffect(() => {
        Snowplow.pageView('Patients');
    }, []);

    const height = window.innerHeight - 110;

    return (
        <Route
            render={() => (
                <div className="patients-page" style={{ height: `${window.innerHeight - 80}px` }}>
                    <div className="patients-page-inner">
                        <PatientsSelectionPane
                            {...props}
                            selectedPatients={selectedPatients}
                            setSelectedPatients={setSelectedPatients}
                            nameFormat={nameFormat}
                            setNameFormat={setNameFormat}
                            height={height}
                            mainButtonLabel="Message"
                            messageablePatients={messageablePatients}
                            onMainButtonClick={(selectedPatients) => {
                                if (fullPepEnabled) {
                                    setConnectDialogOpen(true);
                                } else {
                                    setShowUpgradeDialog(true);
                                }
                            }}
                        />
                    </div>
                    <ChooseBulkMessageDialog
                        {...props}
                        selectedUsers={messageablePatients}
                        open={connectDialogOpen}
                        onClose={() => setConnectDialogOpen(false)}
                        nameFormat={nameFormat}
                    />

                    {!showUpgradeDialog ? null : (
                        <FullPepUpgrade asDialog onClose={() => setShowUpgradeDialog(false)} />
                    )}

                    <BulkMessageStatusSnackbar />
                </div>
            )}
        />
    );
}

function mapStateToProps(state) {
    const { patientData, connect, inboxUser, assessment, inboxConversationTemplate } = state;

    return {
        patientData,
        connect,
        assessment,
        inboxUser,
        inboxConversationTemplate,
        fullPepEnabled: pharmacySelectors.pharmacyHasFullPepEnabled(state),
    };
}

const bindActionsToDispatch = {
    fetchPatientData,
    fetchPatientInboxData,
    setFocusedPatient,
    fetchPatientOpportunities,
    fetchAvailableAssessedAttributes,
    clearAvailableAssessedAttributes,
    updateUser: userAction.updateUser,
    openEditUserDialog: userAction.openEditUserDialog,
    closeEditUserDialog: userAction.closeEditUserDialog,
    setActiveUser: userAction.setActiveUser,
    clearPatientOpportunities,
};

export default withRouter(connect(mapStateToProps, bindActionsToDispatch)(PatientsPage));
